import { Box, Grid, Tooltip, useTooltip } from '@candisio/design-system';
import { Link } from '@candisio/design-system';
import { HookFormRadioGroupField } from 'components/HookFormFields/HookFormRadioGroupField';
import { ContactRelationshipType } from 'generated-types/graphql.types';
import { Trans } from 'providers/LocaleProvider';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountsPayableNumberField } from '../AccountsPayableNumberField/AccountsPayableNumberField';
import { AccountsReceivableNumberField } from '../AccountsRecivableNumberField/AccountsReceivableNumberField';
import { ContactFormProps } from '../ContactForm';
import { CustomerNumberField } from '../CustomerNumberField';
import { ContactFormValues } from '../contactFormSchema';

export interface ContactRelationshipTypeFieldsProps
  extends Pick<ContactFormProps, 'suggestedAccountsPayableNumber'> {
  /** If parent form is for reacting new contact */
  isCreatingNewContact?: boolean;
  disabled?: boolean;
}

export const ContactRelationshipTypeFields = ({
  isCreatingNewContact,
  disabled,
}: ContactRelationshipTypeFieldsProps) => {
  const { isOpen, tooltipProps, tooltipRef } = useTooltip();

  const [t] = useTranslation();

  const relationshipType = useWatch<ContactFormValues, 'relationshipType'>({
    name: 'relationshipType',
  });

  const { trigger } = useFormContext<ContactFormValues>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void trigger('accountsPayableNumber');
    void trigger('accountsReceivableNumber');
    void trigger('customerNumber');
  }, [relationshipType, trigger]);

  const isSupplier =
    relationshipType === ContactRelationshipType.Supplier ||
    !Boolean(relationshipType);

  const isCustomer = relationshipType === ContactRelationshipType.Customer;
  const isBoth = relationshipType === ContactRelationshipType.SupplierCustomer;

  return (
    <Grid gap="space16">
      <Box fontSize="basic" paddingX="space8">
        <HookFormRadioGroupField<ContactFormValues>
          name="relationshipType"
          label={t('settings.contacts.details.edit.relationshipType.label')}
          options={[
            {
              value: ContactRelationshipType.Supplier,
              children: t(
                'settings.contacts.details.edit.relationshipType.options.supplier'
              ),
            },
            {
              value: ContactRelationshipType.Customer,
              children: t(
                'settings.contacts.details.edit.relationshipType.options.customer'
              ),
            },
            {
              value: ContactRelationshipType.SupplierCustomer,
              children: t(
                'settings.contacts.details.edit.relationshipType.options.both'
              ),
            },
          ]}
          orientation="horizontal"
          disabled={disabled}
        />
      </Box>

      {isSupplier || isBoth ? (
        <Grid autoFlow="column" alignItems="center" gap="space14">
          <AccountsPayableNumberField<ContactFormValues>
            name="accountsPayableNumber"
            label={t(
              'settings.contacts.details.edit.accountsPayableNumber.label'
            )}
            isCreatingNewContact={isCreatingNewContact}
            disabled={disabled}
          />
        </Grid>
      ) : null}
      {(isCustomer || isBoth) && (
        <AccountsReceivableNumberField<ContactFormValues>
          name="accountsReceivableNumber"
          label={t(
            'settings.contacts.details.edit.accountsReceivableNumber.label'
          )}
          disabled={disabled}
        />
      )}
      {(isSupplier || isBoth) && <CustomerNumberField disabled={disabled} />}
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Trans i18nKey="settings.contacts.details.edit.accountsPayableNumber.helpCenterArticle.text">
            Please enter the accounts payable number exactly as you find it in
            your accounting software.
            <Link
              external
              href={t(
                'settings.contacts.details.edit.accountsPayableNumber.helpCenterArticle.url'
              )}
            >
              More info
            </Link>
          </Trans>
        </Tooltip>
      )}
    </Grid>
  );
};
