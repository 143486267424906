import { Tag } from '@candisio/design-system';
import { Maybe, TransactionType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const TypeCell = ({
  value,
}: {
  value: Maybe<TransactionType> | undefined;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  if (!value) {
    return;
  }

  return (
    <Tag color="gray" variant="secondary">
      {t(`transactionDetailsCard.type.${value.toLowerCase()}`)}
    </Tag>
  );
};
