import {
  Text,
  Grid,
  Button,
  Icon,
  Card,
  AvatarStack,
} from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Trans, useTranslation } from 'react-i18next';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';

interface CreateReimbursementCardProps {
  isLoading: boolean;
  isDuplicateErrorVisible: boolean;
  administrators: User[];
  onCreateReimbursement: () => Promise<void>;
}

export const CreateReimbursementCard = ({
  isLoading,
  isDuplicateErrorVisible,
  administrators,
  onCreateReimbursement,
}: CreateReimbursementCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Grid
      background="gray0"
      padding="space16"
      borderRadius="medium"
      placeContent="center"
    >
      <Grid
        padding="space16"
        gap="space24"
        borderRadius="medium"
        alignContent="start"
        justifyItems="center"
        justifyContent="center"
      >
        <Grid gap="space12" templateColumns="repeat(3, auto)">
          <Grid
            background="green100"
            padding="space16"
            placeContent="center"
            borderRadius="medium"
          >
            <Icon icon="receipt" size="space20" />
          </Grid>
          <Grid
            background="pink100"
            padding="space16"
            paddingLeft="space18"
            placeContent="center"
            borderRadius="medium"
          >
            <Icon icon="hospitality" size="space20" />
          </Grid>
        </Grid>
        <Text textAlign="center" fontSize="basic">
          {t('dashboard.createExpense.text')}
        </Text>
        <Button onClick={onCreateReimbursement} loading={isLoading}>
          {t('dashboard.createExpense.createCTA')}
        </Button>
      </Grid>
      {isDuplicateErrorVisible && (
        <Card background="redbg" border="1px solid red500" alignSelf="start">
          <Grid templateColumns="auto 1fr" gap="space8">
            <Icon icon="warning" color="red500" size="space20" />
            <Grid gap="space8">
              <Grid gap="space4">
                <Text fontSize="basic" fontWeight="semibold">
                  {t('dashboard.createExpense.duplicateContactError.title')}
                </Text>
                <Trans
                  t={t}
                  parent="span"
                  i18nKey="dashboard.createExpense.duplicateContactError.text"
                >
                  A new expense couldn't be created because a
                  <Text fontWeight="semibold">contact</Text> with the
                  <Text fontWeight="semibold">same name</Text> exists in Candis.
                  Please contact a Candis Administrator to resolve this issue
                  and try again.
                </Trans>
              </Grid>
              <Grid gap="space4">
                <Text fontSize="basic" fontWeight="semibold">
                  {t(
                    'dashboard.createExpense.duplicateContactError.candisAdmins'
                  )}
                </Text>
                <AvatarStack size="small" alignSelf="baseline">
                  {administrators.map(admin => (
                    <AvatarWithStatusContainer
                      key={admin.id}
                      hasTooltip
                      img={admin?.avatarUrl ?? undefined}
                      name={admin?.name}
                      size="small"
                      userId={admin?.id}
                    />
                  ))}
                </AvatarStack>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
    </Grid>
  );
};
