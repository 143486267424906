import {
  Button,
  Flex,
  Grid,
  Text,
  TruncatedText,
  defaultTheme,
} from '@candisio/design-system';
import { AvailableEcmDocumentFiltersKeys } from 'components/EcmDocumentsTable/constants';
import { motion } from 'framer-motion';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { Routes } from 'models';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { MouseEvent, useState } from 'react';
import { useHover } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { DocumentTags } from 'views/Settings/DocumentTags//toolkit/hooks/useDocumentTags';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { MAX_DOCUMENT_TAG_NAME_LENGTH } from '../../toolkit/utils';

export const TAGS_FILTER_NAME: AvailableEcmDocumentFiltersKeys = 'tags';

export interface DocumentTagsListItemProps {
  tag: DocumentTags[number];
  queryString: string;
  isActive: boolean;
}

const StyledLink = motion(Button);
const colorOverride = { color: defaultTheme.colors.blue500 };

const preventDrawerOpen = (e: MouseEvent<HTMLAnchorElement>) => {
  e.stopPropagation();
};

const fallbackStyles = {
  borderBottom: '1px solid gray200',
  paddingX: 'space16',
  height: '65px',
};

export const DocumentTagsListItem = ({
  tag: { id, name, description, documentCount },
  queryString,
  isActive,
}: DocumentTagsListItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const [documentTagsMergeFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.documentTagsMerge,
  ]);

  const showFallbackStyles = !isActive && documentTagsMergeFF;

  const organizationSlug = useOrganizationId();
  const [isHovered, setIsHovered] = useState(false);

  const { hoverProps } = useHover({
    onHoverChange: isHovering => setIsHovered(isHovering),
  });

  const archiveWithFilterUrl = `/${organizationSlug}${Routes.ECM_DOCUMENTS}?${TAGS_FILTER_NAME}=${id}`;

  const hasAssignedDocuments =
    typeof documentCount === 'number' && documentCount > 0;

  return (
    <Grid
      autoFlow="column"
      lineHeight="20px"
      alignItems="center"
      gap="space32"
      color="gray500"
      fontSize="small"
      {...hoverProps}
      {...(showFallbackStyles && fallbackStyles)}
      templateColumns={`[name] ${MAX_DOCUMENT_TAG_NAME_LENGTH}ch [description] auto`}
    >
      <Flex direction="column">
        {/* @ts-expect-error for some reason TrucatedText complains about JSX.Element[] */}
        <TruncatedText fontWeight="semibold" fontSize="basic" color="gray800">
          {renderQueryHighlight({
            value: name ?? '',
            queryString: queryString,
          })}
        </TruncatedText>
        <Text>
          {t('documentTags.list.documentCount', { count: documentCount })}
        </Text>
      </Flex>
      {/* @ts-expect-error for some reason TrucatedText complains about JSX.Element[] */}
      <TruncatedText lineClamp={2}>
        {renderQueryHighlight({
          value: description ?? '',
          queryString: queryString,
        })}
      </TruncatedText>
      {hasAssignedDocuments && isHovered && (
        <Flex
          position="absolute"
          alignItems="center"
          right="0"
          height="57px"
          paddingLeft="space32"
          paddingRight="space16"
          transition="background-image 0.2s ease-in-out"
          background="linear-gradient(90deg, #EBEDF780 0, #EBEDF7FF 10%)"
          style={{ marginRight: -16 }}
        >
          <StyledLink
            initial={colorOverride}
            whileHover={colorOverride}
            as="a"
            variant="tertiary"
            color="blue"
            size="small"
            icon="newTab"
            target="_blank"
            href={archiveWithFilterUrl}
            onClick={preventDrawerOpen}
            justifySelf="end"
          >
            {t('documentTags.list.viewDocuments')}
          </StyledLink>
        </Flex>
      )}
    </Grid>
  );
};
