import { Link } from '@candisio/design-system';
import { ReactNode } from 'react';

interface DownloadCSVTemplateLinkProps {
  downloadUrl: string;
  onClick?: () => void;
  children: ReactNode;
}

export const DownloadCSVTemplateLink = ({
  downloadUrl,
  onClick,
  children,
}: DownloadCSVTemplateLinkProps) => {
  return (
    <Link
      href={downloadUrl}
      onClick={onClick}
      icon="arrowDownCircle"
      iconPosition="left"
      download
    >
      {children}
    </Link>
  );
};
