import {
  Box,
  Button,
  Grid,
  Separator,
  useLabel,
} from '@candisio/design-system';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { IbanFormValues } from 'components/IbanListView/ibanFormSchema';
import { IbanItem } from 'components/IbanListView/IbanListView';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateIban } from './CreateIban';
import { IbanItem as IbanItemComponent } from './IbanItem';

export interface IbanSelectFieldProps {
  /** Field name */
  name: string;
  /** Field label */
  label: string;
  /** Field placeholder */
  placeholder: string;
  /** IBAN select items */
  items?: IbanItem[];
  /** Called when new IBAN should be created */
  onCreateIban: (values: IbanFormValues) => void;
  readOnly: boolean;
}

export const IbanSelectField = ({
  items = [],
  label,
  onCreateIban,
  name,
  placeholder,
  readOnly,
}: IbanSelectFieldProps) => {
  const [t] = useTranslation('payments');

  const { fieldProps, labelProps } = useLabel({ label });

  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [dropdownState, setDropdownState] = useState<'default' | 'create'>(
    'default'
  );

  const onCreateNewIban = async (values: IbanFormValues) => {
    setSubmitting(true);
    await onCreateIban(values);
    setSubmitting(false);

    setIsOpen(false);
    setDropdownState('default');
  };

  return (
    <Grid>
      <Box
        as="label"
        color="gray500"
        arial-label={label}
        arial-describedby={label}
        {...labelProps}
      >
        {label}
      </Box>
      <HookFormSelectField
        name={name}
        data-cy="payment-form-iban"
        items={items.map(item => ({
          key: item.key,
          children: <IbanItemComponent item={item} />,
          textValue: item.iban,
        }))}
        placeholder={readOnly ? '-' : placeholder}
        readOnly={readOnly}
        isOpen={isOpen}
        renderCustomDropdown={listbox => (
          <Box
            onClick={
              // @TODO explicit MouseEvent<HTMLDivElement> type should not be
              // required.
              // See: https://github.com/microsoft/TypeScript/issues/44596
              (e: MouseEvent<HTMLDivElement>) => {
                e.stopPropagation(); // prevent dropdown from closing
              }
            }
            maxHeight="inherit"
          >
            {dropdownState === 'default' ? (
              <Grid
                templateRows="1fr auto auto"
                overflow="hidden"
                maxHeight="inherit"
              >
                {listbox}
                <Separator />
                <Grid padding="space16">
                  <Button
                    color="blue"
                    onClick={() => {
                      setDropdownState('create');
                    }}
                    variant="secondary"
                  >
                    {t('details.addNewIban')}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <CreateIban
                onSubmit={onCreateNewIban}
                isSubmitting={submitting}
                handleDropdownState={() => setDropdownState('default')}
              />
            )}
          </Box>
        )}
        onOpenChange={open => {
          if (!open) {
            // reset to default state when dropdown closes
            setDropdownState('default');
          }

          setIsOpen(open);
        }}
        {...fieldProps}
      />
    </Grid>
  );
};
