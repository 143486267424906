import {
  Box,
  CustomEmptyStateProps,
  Flex,
  SelectionOptions,
  useTheme,
} from '@candisio/design-system';
import { AttachCard } from 'components/DocumentViewer/AttachmentsTable/AttachCard';
import { AttachmentsTableEmptyState } from 'components/DocumentViewer/AttachmentsTable/AttachmentsTableEmptyState';
import { AttachmentTableErrorPanel } from 'components/DocumentViewer/AttachmentsTable/AttachmentsTableErrorPanel';
import { NoItemsFoundCta } from 'components/DocumentViewer/AttachmentsTable/NoItemsFoundCta';
import { useAttachmentsValidations } from 'components/DocumentViewer/AttachmentsTable/useAttachmentsValidations';
import { isXRechnung } from 'components/DocumentViewer/AttachmentsTable/utils';
import { DocumentsTable } from 'components/DocumentsTable/DocumentsTable';
import {
  ColumnWidths,
  DocumentTableColumnKeys,
  DocumentsTableData,
} from 'components/DocumentsTable/types';
import { AnimatePresence } from 'framer-motion';
import { useLocalSortAndFilters } from 'hooks/table/useLocalSortAndFilters';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useDocumentFile } from 'hooks/useDocumentFile';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';
import { useAttachDocuments } from './useAttachDocuments';
import { useAttachmentsTableData } from './useAttachmentsTableData';
import { useAttachmentsTableDataDeprecated } from './useAttachmentsTableDataDeprecated';

export interface AttachmentsTableProps {
  documentId: string;
  onDocumentsAttached: () => void;
  onUploadFileClick?: () => void;
}

interface AttachmentsTableComponentProps extends AttachmentsTableProps {
  useAttachmentsTableProps: ReturnType<typeof useAttachmentsTableData>;
  useLocalSortAndFilterProps: ReturnType<typeof useLocalSortAndFilters>;
}

export const AttachmentsTableContainer = (props: AttachmentsTableProps) => {
  const enableNewIndexInInboxViewFF = useCandisFeatureFlags(
    FEATURE_FLAGS.enableNewIndexInInboxView
  );
  if (enableNewIndexInInboxViewFF) {
    return <AttachmentsTable {...props} />;
  }
  return <AttachmentsTableDeprecated {...props} />;
};

/**
 * @deprecated
 */
const AttachmentsTableDeprecated = (props: AttachmentsTableProps) => {
  const useLocalSortAndFilterProps =
    useLocalSortAndFilters<DocumentsTableData>();

  const useAttachmentsTableProps = useAttachmentsTableDataDeprecated({
    documentId: props.documentId,
    filters: useLocalSortAndFilterProps.filters,
    sort: useLocalSortAndFilterProps.sortBy,
  });
  return (
    <AttachmentTableComponent
      {...props}
      useAttachmentsTableProps={useAttachmentsTableProps}
      useLocalSortAndFilterProps={useLocalSortAndFilterProps}
    />
  );
};

const AttachmentsTable = (props: AttachmentsTableProps) => {
  const useLocalSortAndFilterProps =
    useLocalSortAndFilters<DocumentsTableData>();

  const useAttachmentsTableProps = useAttachmentsTableData({
    documentId: props.documentId,
    filters: useLocalSortAndFilterProps.filters,
    sort: useLocalSortAndFilterProps.sortBy,
  });
  return (
    <AttachmentTableComponent
      {...props}
      useAttachmentsTableProps={useAttachmentsTableProps}
      useLocalSortAndFilterProps={useLocalSortAndFilterProps}
    />
  );
};

const AttachmentTableComponent = ({
  documentId,
  onDocumentsAttached,
  onUploadFileClick,
  useAttachmentsTableProps,
  useLocalSortAndFilterProps,
}: AttachmentsTableComponentProps) => {
  const { showDocumentTags } = useEcm();
  const { space } = useTheme();
  const [t] = useTranslation();
  const { filters, sortBy, onSort, onFilter } = useLocalSortAndFilterProps;

  const [allowProcessingOfXrechnungEinvoicesFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.allowProcessingOfXrechnungEinvoices,
  ]);

  const {
    hasMore,
    loadingDocuments,
    onLoadMore,
    tableData,
    filterOptions,
    isTableEmpty,
    isTableFiltered,
    setTableData,
  } = useAttachmentsTableProps;

  const handleSort = useCallback(
    (sortBy: SortingRule<DocumentsTableData>[]) => {
      setTableData([]);
      onSort(sortBy);
    },
    [onSort, setTableData]
  );

  const handleFilters = useCallback(
    (filters: Filters<DocumentsTableData>) => {
      setTableData([]);
      onFilter(filters);
    },
    [onFilter, setTableData]
  );

  const { documentFile, documentAttachments } = useDocumentFile({ documentId });

  const columnWidths: ColumnWidths = {
    contact: 'auto',
    fileName: space.space256,
  };

  const documentSize = documentFile?.size ?? 0;
  const [selectedDocuments, setSelectedDocuments] = useState<
    DocumentsTableData[]
  >([]);

  const resetSelection = () => {
    setSelectedDocuments([]);
  };

  const { errorType } = useAttachmentsValidations(
    documentSize,
    documentAttachments ?? [],
    selectedDocuments
  );

  const { attachDocuments, isAttachingDocuments } =
    useAttachDocuments(errorType);

  const handleAttachDocuments = async () => {
    await attachDocuments(
      documentId,
      selectedDocuments.map(doc => doc.id)
    );

    onDocumentsAttached();
  };

  const canBeSelectedTooltipText = allowProcessingOfXrechnungEinvoicesFF
    ? t('document.attachments.cantBeSelectedTooltipText')
    : t('document.attachments.alreadyHasAttachments');

  const selectionOptions: SelectionOptions<DocumentsTableData> = {
    onSelectionRowChanged: setSelectedDocuments,

    selectedRowsIds: selectedDocuments.map(selDoc => selDoc.id),
    canBeSelected: document => {
      return (
        !Boolean(document.status?.hasAttachments) &&
        !isXRechnung(document.eInvoice?.format)
      );
    },
    canBeSelectedTooltipText: canBeSelectedTooltipText,
  };

  const showTableLoadingSpinner =
    isAttachingDocuments || (loadingDocuments && tableData.length < 1);

  const areDocumentsSelected = selectedDocuments.length > 0;

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <AttachmentsTableEmptyState
        isTableFiltered={isTableFiltered}
        areDocumentsSelected={areDocumentsSelected}
        isTableEmpty={isTableEmpty}
        selectedDocumentsLength={selectedDocuments.length}
        resetFilters={resetFilters}
      />
    ) : null;

  const shouldDisplayCta = onUploadFileClick && !loadingDocuments;
  const showLoadingMoreSpinner = tableData.length > 1;

  const infiniteScrollOptions = {
    dataLength: tableData?.length,
    hasMore,
    showLoadingMoreSpinner: !showTableLoadingSpinner && showLoadingMoreSpinner,
    next: onLoadMore,
  };

  const columns = useMemo(
    () =>
      [
        'status',
        'contact',
        showDocumentTags && 'tags',
        'createdAt',
        'fileSize',
        'fileName',
      ].filter((s): s is DocumentTableColumnKeys => Boolean(s)),
    [showDocumentTags]
  );

  return (
    <Box height="100%">
      <Flex
        height="100%"
        direction="column"
        paddingTop="space16"
        paddingBottom="space32"
      >
        {isAttachingDocuments && (
          <Box
            background="gray200"
            position="absolute"
            inset={0}
            opacity={0.5}
            zIndex={1}
          />
        )}
        {errorType !== null && (
          <Box paddingBottom="space16" paddingX="space36" flex="none">
            <AttachmentTableErrorPanel
              type={errorType.type}
              maxValue={errorType.maxValue}
            />
          </Box>
        )}

        <Flex
          direction="column"
          justifyContent="space-between"
          paddingX="space36"
        >
          <DocumentsTable
            columns={columns}
            columnWidths={columnWidths}
            customEmptyState={emptyState}
            data={tableData}
            defaultFilters={filters}
            defaultSortBy={sortBy}
            height="80vh"
            isLoading={showTableLoadingSpinner}
            filterOptions={filterOptions}
            onEndReached={hasMore ? onLoadMore : undefined}
            onFilter={handleFilters}
            onSort={handleSort}
            selectionOptions={selectionOptions}
            infiniteScrollOptions={infiniteScrollOptions}
            tableFooter={
              shouldDisplayCta && (
                <Flex
                  direction="row"
                  justifyContent="center"
                  background="gray0"
                  borderBottomRadius="medium"
                  paddingBottom="space8"
                  paddingTop="space36"
                >
                  <NoItemsFoundCta onCtaClick={onUploadFileClick} />
                </Flex>
              )
            }
          />
        </Flex>
      </Flex>
      <AnimatePresence>
        {selectedDocuments.length > 0 && (
          <AttachCard
            flex="none"
            position="absolute"
            width="100%"
            bottom={0}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: '50%', opacity: 0 },
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            canAttach={errorType === null}
            isActionPending={isAttachingDocuments}
            count={selectedDocuments.length}
            onAttach={handleAttachDocuments}
            onDeselect={resetSelection}
          />
        )}
      </AnimatePresence>
    </Box>
  );
};
