import { Grid, Image, Text } from '@candisio/design-system';
import { ReactNode } from 'react';
import { ExportType } from 'views/Integrations/Export/types';
import addisonIcon from '../images/icon-addison.svg';
import candisApiIcon from '../images/icon-candis-api.svg';
import candisCsvIcon from '../images/icon-candis-csv.svg';
import datevApiIcon from '../images/icon-datev-api.svg';
import datevConnectedClientIcon from '../images/icon-datev-connected-client.svg';
import datevCsvIcon from '../images/icon-datev-csv.svg';
import datevXmlIcon from '../images/icon-datev-xml.svg';
import sapB1Icon from '../images/icon-sap-b1.svg';
import sapCsvIcon from '../images/icon-sap-csv.svg';
import zipIcon from '../images/icon-zip.svg';

export const ZipIconImage = () => (
  <Image src={zipIcon} alt="zip logo" width="84px" height="84px" />
);
export const DatevCsvImage = () => (
  <Image
    alignSelf="center"
    src={datevCsvIcon}
    alt="datev dxso zip logo"
    width="84px"
    height="84px"
  />
);
export const SapCsvImage = () => (
  <Image
    alignSelf="center"
    src={sapCsvIcon}
    alt="sap csv logo"
    width="84px"
    height="84px"
  />
);
export const CandisCsvImage = () => (
  <Image
    alignSelf="center"
    src={candisCsvIcon}
    alt="candis csv logo"
    width="84px"
    height="84px"
  />
);
export const DatevDxsoZipImage = () => (
  <Image
    alignSelf="center"
    src={datevXmlIcon}
    alt="datev dxso zip logo"
    width="84px"
    height="84px"
  />
);

export const AddisonImage = () => (
  <Image
    alignSelf="center"
    src={addisonIcon}
    alt="addison zip logo"
    width="84px"
    height="84px"
  />
);

export const DatevDcoImage = () => (
  <Image
    alignSelf="center"
    src={datevApiIcon}
    alt="DATEV logo"
    width="84px"
    height="84px"
  />
);

export const SapB1Image = () => (
  <Image
    alignSelf="center"
    src={sapB1Icon}
    alt="SAP B1 logo"
    width="84px"
    height="84px"
  />
);

export const CandisApiImage = () => (
  <Image
    alignSelf="center"
    src={candisApiIcon}
    alt={ExportType.API}
    width="84px"
    height="84px"
  />
);

export const DatevConnectedClient = ({ text }: { text: string }) => {
  return (
    <Grid
      autoFlow="column"
      gap="space4"
      justifyContent="center"
      fontSize="xsmall"
    >
      <Image
        src={datevConnectedClientIcon}
        alt="datev conected"
        width="space16"
        height="space16"
      />
      <Text>{text}</Text>
    </Grid>
  );
};

interface ActionBarProps {
  children: ReactNode;
}

export const ActionBar = ({ children }: ActionBarProps) => {
  return (
    <Grid
      gap="space16"
      width="100%"
      alignSelf="end"
      height="max-content"
      borderRadius="medium"
      padding="space16"
      overflow="hidden"
      boxShadow="elevatedShadow1"
    >
      {children}
    </Grid>
  );
};
