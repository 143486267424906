import { SplitBookingsFormValues } from 'components/Form/SplitBookingsForm/schema';
import {
  HookFormNumberField,
  HookFormNumberFieldProps,
} from 'components/HookFormFields/HookFormNumberField';
import { maximumPrecisionForSapItemInvoices } from 'constants/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const Quantity = ({
  readOnly,
  ...props
}: Partial<HookFormNumberFieldProps<SplitBookingsFormValues>>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);

  const formatOptions = {
    maximumFractionDigits: maximumPrecisionForSapItemInvoices,
    style: 'decimal' as const,
  };

  return (
    <HookFormNumberField
      name="quantity"
      label={t('inputs.quantity.label')}
      readOnly={readOnly}
      placeholder={
        readOnly
          ? t('inputs.quantity.placeholder.notSet')
          : t('inputs.quantity.placeholder.enabled')
      }
      formatOptions={formatOptions}
      {...props}
    />
  );
};
