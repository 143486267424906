import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useGetHandleUpdateConfigurations } from 'hooks/table/useGetHandleUpdateConfigurations';
import { useGetTableConfigurations } from 'hooks/table/useGetTableConfigurations';
import { useUpdateColumnsBE } from 'hooks/table/useUpdateColumnsBE';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useResetTableConfigurations } from 'hooks/useResetTableConfigurations';
import { useSaveTableConfigurations } from 'hooks/useSaveTableConfigurations';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getUpdatedConfigurationsTable } from 'utils/table/getUpdatedConfigurationsTable';
import {
  useShowAccountsReceivableNumberColumn,
  useShowCreateTransferField,
  useShowPaymentMediumColumn,
} from 'views/utils/useShouldShowField';
import { ContactTableColumnKeys, ContactsTableData } from '../ContactTableDS';
import { availableFilters } from '../utils/const';
import { getAvailableColumns } from '../utils/getAvailableColumns';

export const contactsTableId = 'contacts-table-configurations';

export const defaultColumnsConfigurations: Configuration[] = [
  {
    id: 'contact',
    isVisible: true,
    label: 'settings.contacts.columns.name',
    isFixed: true,
  },
  {
    id: 'paymentInfo',
    isVisible: true,
    label: 'settings.contacts.columns.paymentInfo',
  },
  {
    id: 'taxNumber',
    isVisible: true,
    label: 'settings.contacts.columns.tax',
  },
  {
    id: 'vatId',
    isVisible: true,
    label: 'settings.contacts.columns.vat',
  },
  {
    id: 'createTransfer',
    isVisible: true,
    label: 'settings.contacts.columns.payment',
  },
  {
    id: 'paymentMedium',
    isVisible: true,
    label: 'settings.contacts.columns.paymentMedium',
  },
  {
    id: 'paymentCondition',
    isVisible: true,
    label: 'settings.contacts.columns.paymentCondition',
  },
  {
    id: 'dueDateOffset',
    isVisible: true,
    label: 'settings.contacts.columns.date',
  },
  {
    id: 'accountsPayableNumber',
    isVisible: true,
    label: 'settings.contacts.columns.accountsPayableNumber',
  },
  {
    id: 'accountsReceivableNumber',
    isVisible: true,
    label: 'settings.contacts.columns.accountsReceivableNumber',
  },
  {
    id: 'createdAt',
    isVisible: true,
    label: 'settings.contacts.columns.createdAt',
  },
];

export const useContactColumnsConfigurations = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const { success, error } = useToastMessage();

  const { sortBy, filters } = useUrlBasedSortAndFilter<ContactsTableData>({
    availableFilters,
  });

  const showAccountsReceivableNumberColumn =
    useShowAccountsReceivableNumberColumn();

  const showCreateTransferColumn = useShowCreateTransferField();

  const showPaymentMediumColumn = useShowPaymentMediumColumn();

  const { isLoadingDatev } = useDatev(); // BDS-checked

  const { configurations } = useGetTableConfigurations({
    tableId: contactsTableId,
    defaultColumnsConfigurations,
  });

  const { isSavingConfigurations, saveTableConfigurations } =
    useSaveTableConfigurations();

  const { isResetPending, resetTableConfigurations } =
    useResetTableConfigurations();

  const handleSaveTableConfigurations = async (
    configurations: Configuration[]
  ) => {
    const response = await saveTableConfigurations({
      configurations,
      tableId: contactsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.successMessage'));
    } else {
      error(t('configurations.errorMessage'));
    }
  };

  const handleResetTableConfigurations = async () => {
    const response = await resetTableConfigurations({
      tableId: contactsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.resetSuccessMessage'));
    } else {
      error(t('configurations.resetErrorMessage'));
    }
  };

  useUpdateColumnsBE({
    tableId: contactsTableId,
    defaultColumnsConfigurations,
  });

  const availableColumnsBasedOnOrgSettings = useMemo(
    () =>
      getAvailableColumns({
        columns: [
          'contact',
          'paymentInfo',
          'taxNumber',
          'vatId',
          'createTransfer',
          'paymentMedium',
          'paymentCondition',
          'dueDateOffset',
          'accountsPayableNumber',
          'accountsReceivableNumber',
          'createdAt',
        ],
        showAccountsReceivableNumberColumn,
        showCreateTransferColumn,
        showPaymentMediumColumn,
      }),
    [
      showAccountsReceivableNumberColumn,
      showCreateTransferColumn,
      showPaymentMediumColumn,
    ]
  );

  const { updatedConfigBasedOnOrgSettings, visibleColumnIds } =
    getUpdatedConfigurationsTable(
      configurations,
      availableColumnsBasedOnOrgSettings
    );

  const { handleUpdateConfigurations } = useGetHandleUpdateConfigurations({
    filters,
    sortBy,
    updateConfigurationsTable: handleSaveTableConfigurations,
  });

  return {
    configurationsTable: updatedConfigBasedOnOrgSettings,
    visibleColumnIds: visibleColumnIds as ContactTableColumnKeys[],
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    isLoadingConfigs:
      isLoadingDatev || isSavingConfigurations || isResetPending,
  };
};
