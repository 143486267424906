import { Box, Button, Drawer, Flex, Heading } from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  useArchiveWorkflowTemplateMutation,
  useCreateWorkflowTemplateMutation,
  useGetWorkflowTemplateQuery,
  useRestoreWorkflowTemplateMutation,
  useUpdateWorkflowTemplateMutation,
} from 'generated-types/graphql.types';
import { Maybe, WorkflowTemplate } from 'generated-types/graphql.types';
import { GQLError } from 'gql';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// Use v5 version of useParams because we’re inside a v5 route
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MatchParams } from 'router/Match';
import { useShowError } from 'utils/error_message';
import { GetProps } from 'utils/extractors';
import { validationErrorsExtractor } from 'utils/forms';
import { usePath } from 'utils/hooks';
import { WorkflowRouteParams } from '../../WorkFlowTemplates';
import { WorkflowTemplatesFormContainer } from '../WorkflowTemplateForm/WorkflowTemplatesFormContainer';
import { refetchWorkflowTemplates } from './queries';
import { formToSubmitValues } from './utils/formToSubmitValues';

type Props = MatchParams<WorkflowRouteParams> &
  RouteComponentProps<WorkflowRouteParams>;

export const WorkflowTemplateDetails = withRouter<
  Props,
  // biome-ignore lint/nursery/noRestrictedTypes: <explanation>
  FunctionComponent<Props>
>(({ matched, history: { push, replace }, location: { search } }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const showError = useShowError();
  const { success, error } = useToastMessage();
  const { url, pathGenerator } = usePath<WorkflowRouteParams>({
    workflowId: undefined,
  });

  const onClose = useCallback(() => {
    replace({
      pathname: url,
      search,
    });
  }, [search, url, replace]);

  const [createTemplate] = useCreateWorkflowTemplateMutation({
    refetchQueries: refetchWorkflowTemplates(matched?.workflowId),
  });

  const [updateTemplate] = useUpdateWorkflowTemplateMutation({
    refetchQueries: refetchWorkflowTemplates(matched?.workflowId),
  });

  const [archiveTemplate, { loading: archiveLoading }] =
    useArchiveWorkflowTemplateMutation({
      refetchQueries: refetchWorkflowTemplates(matched?.workflowId),
    });

  const [restoreTemplate, { loading: restoreLoading }] =
    useRestoreWorkflowTemplateMutation({
      refetchQueries: refetchWorkflowTemplates(matched?.workflowId),
    });

  const onArchive = useCallback(async () => {
    if (!matched || !matched.workflowId) {
      error(t('workflows.errors.notExist'));

      return;
    }

    try {
      const response = await archiveTemplate({
        variables: {
          id: matched.workflowId,
        },
      });

      push({
        pathname: pathGenerator.stringify({ workflowId: undefined }),
        search,
      });
      const name = response.data?.archiveWorkflowTemplate.name;
      const content = (
        <Trans
          values={{ name }}
          i18nKey="workflows.archive.successfullyArchived"
        >
          Workflow
          <Button
            variant="tertiary"
            onClick={() => {
              push({
                pathname: pathGenerator.stringify({
                  workflowId: response.data?.archiveWorkflowTemplate.id,
                }),
                search: qs.stringify({ archive: true }),
              });
            }}
          >
            {{ name } as any}
          </Button>
          was successfully archived
        </Trans>
      );

      success(content);

      return response;
    } catch (e) {
      showError(e as GQLError);
    }
  }, [
    matched,
    error,
    t,
    archiveTemplate,
    push,
    pathGenerator,
    search,
    success,
    showError,
  ]);

  const onRestore = useCallback(async () => {
    if (!matched || !matched.workflowId) {
      error(t('workflows.errors.notExist'));

      return;
    }

    try {
      const response = await restoreTemplate({
        variables: {
          id: matched.workflowId,
        },
      });

      push({
        pathname: pathGenerator.stringify({ workflowId: undefined }),
        search,
      });
      const name = response.data?.restoreWorkflowTemplate.name;
      const content = (
        <Trans i18nKey="workflows.archive.successfullyRestored">
          Workflow
          <Button
            variant="tertiary"
            onClick={() => {
              push({
                pathname: pathGenerator.stringify({
                  workflowId: response.data?.restoreWorkflowTemplate.id,
                }),
                search: qs.stringify({ archive: false }),
              });
            }}
          >
            {{ name } as any}
          </Button>
          was successfully activated
        </Trans>
      );

      success(content);
    } catch (e) {
      showError(e as GQLError);
    }
  }, [
    matched,
    error,
    t,
    restoreTemplate,
    push,
    pathGenerator,
    search,
    success,
    showError,
  ]);

  const onSubmit = useCallback<
    GetProps<typeof WorkflowTemplatesFormContainer>['onSubmit']
  >(
    async template => {
      const input = formToSubmitValues(template);

      const submit = matched?.workflowId
        ? updateTemplate({
            variables: {
              input,
              id: matched?.workflowId,
            },
          }).then(() => {
            success(t('workflows.details.messages.updated'));
          })
        : createTemplate({
            variables: {
              input,
            },
          }).then(() => {
            success(t('workflows.details.messages.created'));
          });

      try {
        await submit;
        push(url);
      } catch (e) {
        showError(e as GQLError);

        return validationErrorsExtractor(e);
      }
    },
    [
      matched?.workflowId,
      updateTemplate,
      createTemplate,
      success,
      t,
      push,
      url,
      showError,
    ]
  );

  const { data, loading } = useGetWorkflowTemplateQuery({
    skip: !matched?.workflowId,
    variables: {
      id: matched?.workflowId as string,
    },
  });

  const titleName =
    matched && matched.workflowId
      ? 'workflows.details.tabs.edit'
      : 'workflows.details.tabs.create';

  return (
    <Drawer width="50rem" isOpen={!!matched} onClose={onClose}>
      <Flex
        borderBottom="1px solid gray300"
        alignItems="center"
        justifyContent="space-between"
        padding="space16 space40 space8"
      >
        <Heading as="h3">{t(titleName)}</Heading>
        <Button
          icon="close"
          label={t('workflows.close')}
          onClick={onClose}
          zIndex={10}
          variant="tertiary"
        />
      </Flex>
      <Box height="100%" overflow="scroll">
        <WorkflowTemplatesFormContainer
          onArchive={onArchive}
          onRestore={onRestore}
          onSubmit={onSubmit}
          isLoading={loading}
          isSubmitting={archiveLoading || restoreLoading}
          template={data?.workflowTemplate as Maybe<WorkflowTemplate>}
        />
      </Box>
    </Drawer>
  );
});
