import { Button, defaultTheme, Flex, Link } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useDeferredValue } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchTypeSelect } from './SearchTypeSelect';
import { SearchScopeSelect } from './SearchScopeSelect';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { queryParameter } from 'components/Table/consts';
import { IntercomLink } from 'components/InfoPanel/components/IntercomLink';
import { useSearchFilter } from './useSearchFilter';
import { SearchOverlayUpsell } from './SearchFilterUpsell';
import { useEcm } from 'orgConfig/ecm/useEcm';

interface EcmSearchFieldFilterOverlayProps {
  onApply: () => void;
  onShowPromoModal: () => void;
}

export function EcmSearchFieldFilterOverlay({
  onApply,
  onShowPromoModal,
}: EcmSearchFieldFilterOverlayProps) {
  const [search] = useSearchParams();
  const query = useDeferredValue(search.get(queryParameter) ?? '');

  const { showSearchOverlayPromo } = useEcm();

  const {
    searchType,
    searchScope,
    setSearchType,
    setSearchScope,
    applySearchInputFilters,
  } = useSearchFilter();

  const applyFilters = () => {
    applySearchInputFilters();
    onApply();
  };

  return (
    <Flex direction="column" gap="space12" width="22.25rem">
      <Flex direction="column" gap="space8">
        <SearchTypeSelect
          query={query}
          value={searchType}
          onChange={setSearchType}
          disabled={showSearchOverlayPromo}
        />
        <SearchScopeSelect
          value={searchScope}
          onChange={setSearchScope}
          disabled={showSearchOverlayPromo}
        />
      </Flex>
      {showSearchOverlayPromo ? (
        <SearchOverlayUpsell openModal={onShowPromoModal} />
      ) : (
        <FilterActions applyFilters={applyFilters} />
      )}
    </Flex>
  );
}

function FilterActions({ applyFilters }: { applyFilters: () => void }) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <Flex gap="space20" justifyContent="space-between">
      <Flex gap="space20" alignItems="center">
        <Link
          href={t('searchFilter.popover.help.url')}
          fontSize="small"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('searchFilter.popover.help.text')}
        </Link>
        <IntercomLink
          id="search-filter-overlay-intercom-link"
          variant="noIcon"
          style={{
            fontSize: defaultTheme.fontSizes.small,
            lineHeight: defaultTheme.lineHeights.body,
          }}
        >
          {t('searchFilter.popover.feedback.text')}
        </IntercomLink>
      </Flex>

      <Button size="small" color="blue" onClick={applyFilters}>
        {t('searchFilter.popover.actions.apply')}
      </Button>
    </Flex>
  );
}
