import { Grid } from '@candisio/design-system';
import { ReactNode } from 'react';
import { useExportContext } from 'views/Integrations/Export/Context';
import { Table, View } from 'views/Integrations/Export/types';
import { HistoryDocuments } from './components/HistoryDocuments';
import { HistoryProvisionReversal } from './components/HistoryProvisionReversal';
import { ExportIcons } from './components/Icons';
import { Init } from './components/Init';
import { Loading } from './components/Loading';
import { NotReady } from './components/NotReady';

interface EmptyExportViewProps {
  loading?: boolean;
  displayExportOptionIcons?: boolean;
}

export const EmptyExportViewBase = ({
  Body,
  loading = false,
  displayExportOptionIcons,
}: {
  Body: ReactNode;
  loading?: boolean;
  displayExportOptionIcons: boolean;
}) => {
  return (
    <Grid
      alignContent="center"
      justifyContent="center"
      height="100%"
      overflowY="auto"
      width="100%"
    >
      {loading ? (
        <Loading />
      ) : (
        <Grid justifyItems="center" width="100%" maxWidth={380}>
          {Body}
          {displayExportOptionIcons && <ExportIcons />}
        </Grid>
      )}
    </Grid>
  );
};

export const EmptyExportViewComponent = ({
  loading,
  displayExportOptionIcons = true,
}: EmptyExportViewProps) => {
  const { view, table } = useExportContext();

  const isHistoryView = view === View.HISTORY;
  const isNotReadyView = view === View.NOT_READY;

  const isProvisionReversal =
    table === Table.PROVISIONS || table === Table.REVERSALS;

  let Body = <Init />;

  if (isNotReadyView) {
    Body = <NotReady />;
  }

  if (isHistoryView && isProvisionReversal) {
    Body = <HistoryProvisionReversal />;
  }

  if (isHistoryView && !isProvisionReversal) {
    Body = <HistoryDocuments />;
  }

  return (
    <EmptyExportViewBase
      loading={loading}
      displayExportOptionIcons={displayExportOptionIcons}
      Body={Body}
    />
  );
};

export const EmptyExportView = ({
  displayExportOptionIcons = false,
  isLoading = false,
}: {
  displayExportOptionIcons?: boolean;
  isLoading?: boolean;
}) => {
  return (
    <EmptyExportViewComponent
      displayExportOptionIcons={displayExportOptionIcons}
      loading={isLoading}
    />
  );
};
