import { Flex, Text, Link, Tag, Color, Modal } from '@candisio/design-system';
import { usePromotionDetailsModalContent } from 'components/NewPromotions/hooks/usePromotionDetailsModalContent';
import { PromotionDetailsModalContent } from 'components/NewPromotions/PromotionCard/PromotionDetailsModalContent/PromotionDetailsModalContent';
import { PromotionLabel } from 'components/NewPromotions/PromotionCard/PromotionHeader/PromotionHeader';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

type InitialCardProps = {
  onAddWidget: () => void;
  title: string | ReactNode;
  imageUrl?: string;
  tag?: string;
  tagColor?: Color;
  openModal?: boolean;
  linkText: string;
};

export const InitialCard = ({
  onAddWidget,
  title,
  imageUrl,
  tag,
  tagColor = 'purple',
  openModal = false,
  linkText,
}: InitialCardProps) => {
  const [t] = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { promotionDetails } = usePromotionDetailsModalContent('upsellMax');

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Flex direction="column" gap="space20">
      <Flex
        justifyContent="flex-end"
        direction="column"
        border="1px solid gray300"
        gap="space12"
        alignItems="left"
        height="space200"
        padding="1rem"
        borderRadius="medium"
        background="gray50"
      >
        {tag && (
          <Tag
            color={tagColor}
            variant="secondary"
            position="absolute"
            top="space16"
          >
            {tag}
          </Tag>
        )}
        <Text fontSize="large" color="gray600" width="200px" zIndex={10}>
          {title}
        </Text>
        {openModal ? (
          <Link
            fontSize="basic"
            onClick={onOpenModal}
            width="fit-content"
            style={{ padding: '0' }}
          >
            {linkText}
          </Link>
        ) : (
          <Link
            icon="plus"
            fontSize="basic"
            iconPosition="left"
            onClick={onAddWidget}
            width="fit-content"
            style={{ padding: '0' }}
          >
            {linkText}
          </Link>
        )}
        {imageUrl && (
          <img
            src={imageUrl}
            width="190px"
            style={{
              position: 'absolute',
              top: '-10px',
              right: 0,
            }}
            alt="widget"
          />
        )}
      </Flex>

      <Modal
        width="1000px"
        background="gray100"
        style={{
          background:
            'linear-gradient(90deg, rgba(255,255,255,1) 60%, rgba(248,246,245,1) 40%)',
        }}
        padding="0 0"
        titleInfo={
          <PromotionLabel label={{ text: tag || 'upgrade', color: tagColor }} />
        }
        closeLabel={t('common.close')}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <PromotionDetailsModalContent promotionDetails={promotionDetails} />
      </Modal>
    </Flex>
  );
};
