import {
  DocumentCurrency,
  ExportableReimbursementItemSort,
  ExportableReimbursementItemSortInput,
  ReimbursementItemType,
  SortDirection,
} from 'generated-types/graphql.types';
import { SortingRule } from 'react-table';
import { ExportEntityRow } from 'views/Integrations/Export/types';
import {
  ExportReimbursementItemsTableColumnKeys,
  ExportReimbursementItemsTableRow,
} from './types';

export const toReimbursementItemsTableData =
  (failedReimbursementItems: { id: string; invoiceNumber: string }[]) =>
  (reimbursementItem: ExportEntityRow): ExportReimbursementItemsTableRow => {
    const exportErrorStatus = {
      exportError: {
        ...reimbursementItem.exportStatus?.exportError,
        failedReimbursementItems,
      },
    };

    return {
      expenseId: reimbursementItem.id,
      grossAmount: reimbursementItem.amount,
      id: reimbursementItem.id,
      exportMethod: reimbursementItem.exportStatus?.exportMethod,
      reasonExpense: reimbursementItem.reason ?? '',
      reimbursementItemDate: reimbursementItem.invoiceDate,
      reimbursementColletionId: reimbursementItem.reimbursementColletionId,
      isDisabled: reimbursementItem.isDisabled,
      canBeSelectedTooltipText: reimbursementItem.disableReason,
      exportStatus: reimbursementItem.exportStatus
        ? {
            ...reimbursementItem.exportStatus,
            ...(reimbursementItem.exportStatus?.exportError &&
              exportErrorStatus),
          }
        : undefined,
      type:
        reimbursementItem.reimbusementItemType ??
        ReimbursementItemType.GeneralExpense,
      targetedMembership: {
        avatarUrl: reimbursementItem.targetMembership?.avatarUrl ?? undefined,
        firstName: reimbursementItem.targetMembership?.firstName,
        lastName: reimbursementItem.targetMembership?.lastName,
        membershipId: reimbursementItem.targetMembership?.id,
      },
      titleReimbursement: reimbursementItem.reimbursementCollectionTitle ?? '',
    };
  };

const mappedColumnsToSortFields: Record<
  string,
  ExportableReimbursementItemSort
> = {
  grossAmount: ExportableReimbursementItemSort.TotalAmount,
  reasonExpense: ExportableReimbursementItemSort.Title,
  reimbursementItemDate: ExportableReimbursementItemSort.ReimbursementItemDate,
  targetedMembership: ExportableReimbursementItemSort.TargetMembershipFullName,
  titleReimbursement: ExportableReimbursementItemSort.ReimbursementCaseTitle,
  type: ExportableReimbursementItemSort.Type,
};

export const mapToSortInput = (
  sort: Array<SortingRule<ExportReimbursementItemsTableRow>>
): ExportableReimbursementItemSortInput | undefined => {
  if (sort.length < 1) {
    return undefined;
  }

  const [firstSort] = sort;

  const mappedColumnsToSortField = mappedColumnsToSortFields[firstSort.id];

  if (!mappedColumnsToSortField) {
    return;
  }

  return {
    field: mappedColumnsToSortFields[firstSort.id],
    direction: firstSort.desc ? SortDirection.Desc : SortDirection.Asc,
  };
};

export const availableFiltersAndSorts: ExportReimbursementItemsTableColumnKeys[] =
  [
    'grossAmount',
    'reasonExpense',
    'reimbursementItemDate',
    'targetedMembership',
    'titleReimbursement',
    'type',
    'exportMethod',
    'exportStatus',
  ] as const;

export const dataEn: ExportReimbursementItemsTableRow[] = [
  {
    expenseId: 'de305d54-75b4-431b-adb2-eb6b9e546013',
    reasonExpense: 'Client meeting',
    reimbursementItemDate: new Date('10/1/24'),
    id: '1',
    targetedMembership: {
      firstName: 'Arnold',
      lastName: 'Wagner',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'Client meeting',
    grossAmount: {
      amount: 2089,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '9104e158-6be7-4c58-b156-bcc3ce71ae7d',
    reasonExpense: 'Lunch',
    reimbursementItemDate: new Date('10/5/24'),
    id: '2',
    targetedMembership: {
      firstName: 'Yara',
      lastName: 'Kimura',
    },
    type: ReimbursementItemType.HospitalityExpense,
    titleReimbursement: 'Hospitality_Firm_Kraus',
    grossAmount: {
      amount: 12354,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '9104e158-6be7-4c58-b156-bcc3ce71ae7d',
    reasonExpense: 'Dinner',
    reimbursementItemDate: new Date('10/5/24'),
    id: '3',
    targetedMembership: {
      firstName: 'Yara',
      lastName: 'Kimura',
    },
    type: ReimbursementItemType.HospitalityExpense,
    titleReimbursement: 'Hospitality_Firm_Kraus',
    grossAmount: {
      amount: 17323,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: 'dd057c8b-124f-4f05-927c-712003bd9d45',
    reasonExpense: 'Snacks',
    reimbursementItemDate: new Date('10/5/24'),
    id: '4',
    targetedMembership: {
      firstName: 'Yara',
      lastName: 'Kimura',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'Hospitality_Firm_Kraus',
    grossAmount: {
      amount: 12354,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: 'e534c15d-2d9d-4500-9846-e95423119040',
    reasonExpense: 'Office drinks',
    reimbursementItemDate: new Date('10/3/24'),
    id: '5',
    targetedMembership: {
      firstName: 'Mateo',
      lastName: ' Fernández',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'Office drinks',
    grossAmount: {
      amount: 3450,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '93bd28be-7d5d-4154-9d20-caf09c580ba6',
    reasonExpense: 'MacBook',
    reimbursementItemDate: new Date('10/10/24'),
    id: '6',
    targetedMembership: {
      firstName: 'David',
      lastName: 'Müller',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'MacBook',
    grossAmount: {
      amount: 83000,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '84e2b903-ee4b-425f-aeac-d59adeed7b31',
    reasonExpense: 'Workshop materials',
    reimbursementItemDate: new Date('10/12/24'),
    id: '7',
    targetedMembership: {
      firstName: 'Zara',
      lastName: 'Patel',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'Workshop materials',
    grossAmount: {
      amount: 23444,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '30999236-e06f-4c42-9234-74b6d306dbab',
    reasonExpense: 'Train ticket',
    reimbursementItemDate: new Date('10/12/24'),
    id: '8',
    targetedMembership: {
      firstName: 'Zara',
      lastName: 'Patel',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'Train ticket',
    grossAmount: {
      amount: 14535,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: 'bbf4d338-293c-4930-abf6-ca479a772eab',
    reasonExpense: 'Laptop',
    reimbursementItemDate: new Date('10/7/24'),
    id: '9',
    targetedMembership: {
      firstName: 'Svenja',
      lastName: 'Johansson',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'Laptop',
    grossAmount: {
      amount: 98000,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '0d098af4-2e71-44c8-ade5-c8fe13fadcf5',
    reasonExpense: 'Office snacks',
    reimbursementItemDate: new Date('10/9/24'),
    id: '10',
    targetedMembership: {
      firstName: 'Sophie',
      lastName: 'Kramer',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'Office snacks',
    grossAmount: {
      amount: 4360,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '6ceaec85-a98e-4908-93ab-0893d7aa2964',
    reasonExpense: 'Catering_Event',
    reimbursementItemDate: new Date('10/5/24'),
    id: '11',
    targetedMembership: {
      firstName: 'Julia',
      lastName: 'Schäfer',
    },
    type: ReimbursementItemType.HospitalityExpense,
    titleReimbursement: 'Catering_Event',
    grossAmount: {
      amount: 3867,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '73177612-f4a5-479b-a0f6-abd57ee49a43',
    reasonExpense: 'Business meal_Firm_ABC',
    reimbursementItemDate: new Date('10/27/24'),
    id: '12',
    targetedMembership: {
      firstName: 'Hannah',
      lastName: 'Beck',
    },
    type: ReimbursementItemType.HospitalityExpense,
    titleReimbursement: 'Business meal_Firm_ABC',
    grossAmount: {
      amount: 14876,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '7f58d735-6634-4e04-b5c3-25b68bf18826',
    reasonExpense: 'Client_meeting',
    reimbursementItemDate: new Date('10/1/24'),
    id: '13',
    targetedMembership: {
      firstName: 'Marie',
      lastName: 'Braun',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'Client_meeting',
    grossAmount: {
      amount: 1258,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
];

export const dataDe: ExportReimbursementItemsTableRow[] = [
  {
    expenseId: 'de305d54-75b4-431b-adb2-eb6b9e546013',
    reasonExpense: 'Kundentermin',
    reimbursementItemDate: new Date('10/1/24'),
    id: '1',
    targetedMembership: {
      firstName: 'Arnold',
      lastName: 'Wagner',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'Kundentermin',
    grossAmount: {
      amount: 2089,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '9104e158-6be7-4c58-b156-bcc3ce71ae7d',
    reasonExpense: 'Mittagessen',
    reimbursementItemDate: new Date('10/5/24'),
    id: '2',
    targetedMembership: {
      firstName: 'Yara',
      lastName: 'Kimura',
    },
    type: ReimbursementItemType.HospitalityExpense,
    titleReimbursement: 'Bewirtung_Firm_Kraus_Firm_Kraus',
    grossAmount: {
      amount: 12354,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '9104e158-6be7-4c58-b156-bcc3ce71ae7d',
    reasonExpense: 'Abendessen',
    id: '3',
    reimbursementItemDate: new Date('10/5/24'),
    targetedMembership: {
      firstName: 'Yara',
      lastName: 'Kimura',
    },
    type: ReimbursementItemType.HospitalityExpense,
    titleReimbursement: 'Bewirtung_Firm_Kraus',
    grossAmount: {
      amount: 17323,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: 'dd057c8b-124f-4f05-927c-712003bd9d45',
    reasonExpense: 'Snacks',
    reimbursementItemDate: new Date('10/5/24'),
    id: '4',
    targetedMembership: {
      firstName: 'Yara',
      lastName: 'Kimura',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'Bewirtung_Firm_Kraus',
    grossAmount: {
      amount: 12354,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: 'e534c15d-2d9d-4500-9846-e95423119040',
    reasonExpense: 'Getränke Büro',
    id: '5',
    reimbursementItemDate: new Date('10/3/24'),
    targetedMembership: {
      firstName: 'Mateo',
      lastName: ' Fernández',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'Getränke Büro',
    grossAmount: {
      amount: 3450,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '93bd28be-7d5d-4154-9d20-caf09c580ba6',
    reasonExpense: 'MacBook',
    reimbursementItemDate: new Date('10/3/24'),
    id: '6',
    targetedMembership: {
      firstName: 'David',
      lastName: 'Müller',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'MacBook',
    grossAmount: {
      amount: 83000,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '84e2b903-ee4b-425f-aeac-d59adeed7b31',
    reasonExpense: 'Material Workshop',
    reimbursementItemDate: new Date('10/12/24'),
    id: '7',
    targetedMembership: {
      firstName: 'Zara',
      lastName: 'Patel',
    },
    type: ReimbursementItemType.HospitalityExpense,
    titleReimbursement: 'Material Workshop',
    grossAmount: {
      amount: 23444,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '30999236-e06f-4c42-9234-74b6d306dbab',
    reasonExpense: 'Zugticket',
    reimbursementItemDate: new Date('10/12/24'),
    id: '8',
    targetedMembership: {
      firstName: 'Zara',
      lastName: 'Patel',
    },
    type: ReimbursementItemType.HospitalityExpense,
    titleReimbursement: 'Zugticket',
    grossAmount: {
      amount: 14535,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: 'bbf4d338-293c-4930-abf6-ca479a772eab',
    reasonExpense: 'Laptop',
    id: '9',
    targetedMembership: {
      firstName: 'Svenja',
      lastName: 'Johansson',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'Laptop',
    reimbursementItemDate: new Date('10/7/24'),
    grossAmount: {
      amount: 98000,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '0d098af4-2e71-44c8-ade5-c8fe13fadcf5',
    reasonExpense: 'Snacks Büro',
    id: '10',
    targetedMembership: {
      firstName: 'Sophie',
      lastName: 'Kramer',
    },
    type: ReimbursementItemType.GeneralExpense,
    reimbursementItemDate: new Date('10/1/24'),
    titleReimbursement: 'Snacks Büro',
    grossAmount: {
      amount: 4360,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '6ceaec85-a98e-4908-93ab-0893d7aa2964',
    reasonExpense: 'Verpflegung_Event',
    id: '11',
    targetedMembership: {
      firstName: 'Julia',
      lastName: 'Schäfer',
    },
    type: ReimbursementItemType.HospitalityExpense,
    titleReimbursement: 'Verpflegung_Event',
    reimbursementItemDate: new Date('10/5/24'),
    grossAmount: {
      amount: 3867,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '73177612-f4a5-479b-a0f6-abd57ee49a43',
    reasonExpense: 'Geschäftsessen_Firma_ABC',
    id: '12',
    targetedMembership: {
      firstName: 'Hannah',
      lastName: 'Beck',
    },
    reimbursementItemDate: new Date('10/27/24'),
    type: ReimbursementItemType.HospitalityExpense,
    titleReimbursement: 'Geschäftsessen_Firma_ABC',
    grossAmount: {
      amount: 14876,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
  {
    expenseId: '7f58d735-6634-4e04-b5c3-25b68bf18826',
    reasonExpense: 'Kundentermin',
    reimbursementItemDate: new Date('10/1/24'),
    id: '13',
    targetedMembership: {
      firstName: 'Marie',
      lastName: 'Braun',
    },
    type: ReimbursementItemType.GeneralExpense,
    titleReimbursement: 'Kundentermin',
    grossAmount: {
      amount: 1258,
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
  },
];
