import { useEffect } from 'react';
import { useExportContext } from 'views/Integrations/Export/Context';
import { useExportContactsFactory } from 'views/Integrations/Export/toolkit/hooks/useExportContactsFactory';
import { ExportContactTable } from './ContactTable';
import { ContactsTableData } from './types';

type ContactsTableProps = {
  data: ContactsTableData;
  hasWarning: boolean;
  isMasked: boolean;
  showAll: boolean;
};

export const Contacts = ({
  data,
  hasWarning,
  isMasked,
  showAll,
}: ContactsTableProps) => {
  return (
    <ExportContactTable
      data={data}
      hasWarning={hasWarning}
      isMasked={isMasked}
      showAll={showAll}
    />
  );
};

const WARNING_TRESHOLD = 100;

export const ContactsContainer = () => {
  const { view, includeAllContacts } = useExportContext();
  const {
    isMasked,
    isAllShown,
    contacts: { tableData, refetch },
    count: { all, newAndUpdated },
  } = useExportContactsFactory();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void refetch();
  }, [refetch, view]);

  const hasWarning = includeAllContacts
    ? all > WARNING_TRESHOLD
    : newAndUpdated > WARNING_TRESHOLD;

  return (
    <Contacts
      data={tableData}
      hasWarning={hasWarning}
      isMasked={isMasked}
      showAll={isAllShown}
    />
  );
};
