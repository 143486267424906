import { defaultTheme } from '@candisio/design-system';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { createGlobalStyle } from 'styled-components';
import { scrollbarsStyles } from './scrollbar';
import { DefaultTheme } from './themes/DefaultTheme';

import '@candis/design/fonts/inter.css';
import '@candis/design/fonts/itc-garamond.css';

export const ROW_HEIGHT = 32;
export const HEADER_HEIGHT = '48px';
export const SIDEBAR_WIDTH = '8rem';
export const APP_MIN_WIDTH = '1280px';

/**
 * @deprecated Please use the design system instead
 * @see https://candisio.github.io/design-system
 */
const GlobalStyles = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: auto;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  ::selection {
    color: ${defaultTheme.colors.gray0};
    background-color: ${defaultTheme.colors.gray800};
  }

  body {
    font-family: ${defaultTheme.fontFamilies.body};
    font-size: ${defaultTheme.fontSizes.small};
    font-variant-numeric: tabular-nums;
    color: ${defaultTheme.colors.gray800};
    box-sizing: border-box;
    background: ${defaultTheme.colors.gray200};
    min-width: ${APP_MIN_WIDTH};
    overflow: hidden;
  }

  #root, [data-overlay-container="true"] {
    height: 100%;
  }

  p {
    color: ${DefaultTheme.colors.defaultText}
  }
  body h1, h2, h3 {
    color: ${DefaultTheme.colors.defaultText};
  }

  body h1 {
    font-size: ${DefaultTheme.typography.headingFontSize};
    font-weight: 500;
  }

  body h2 {
    font-size: ${DefaultTheme.typography.headingFontSize};
    font-weight: normal;
  }

  body h3 {
      font-size: ${DefaultTheme.typography.headingSmallFontSize};
      font-weight: normal;
  }

  hr {
    border: 0;
    border-top: 1px solid ${DefaultTheme.colors.border};
  }

  a {
    text-decoration: none
  }

  *:focus-visible {
    outline: none
  }

  button {
    font-family: inherit
  }

  fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0;
  }

  ${scrollbarsStyles}

  /* put Intercom icon below app banner */
  .intercom-lightweight-app {
    z-index: 99 !important;
  }
`;

export { GlobalStyles };
