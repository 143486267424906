import { Flex, Grid, Image, Text } from '@candisio/design-system';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportContext } from '../../../Context';
import { ExportType } from '../../../types';
import { useDatevSettings } from '../../hooks/useDatevSettings';
import datevConnectedClientIcon from '../../images/icon-datev-connected-client.svg';

const DatevConnectedClient = ({ text }: { text: string }) => {
  return (
    <Grid
      autoFlow="column"
      gap="space4"
      justifyContent="center"
      fontSize="xsmall"
    >
      <Image
        src={datevConnectedClientIcon}
        alt="datev conected"
        width="space16"
        height="space16"
      />
      <Text>{text}</Text>
    </Grid>
  );
};

export const InitExportTypeLabel = () => {
  const [t] = useTranslation();
  const { exportType } = useContext(ExportContext);
  const { datevSettings } = useDatevSettings();

  const datevClient = datevSettings?.client;

  switch (exportType) {
    case ExportType.DATEV:
    case ExportType.DATEV_BDS:
      return (
        <Grid gap="space2" paddingTop="space12">
          {datevClient ? (
            <>
              <Text as="h4">
                {t('export.exportForm.exportDatev.connectedClient')}
              </Text>

              <DatevConnectedClient
                text={`${datevClient?.name} (${datevClient?.id})`}
              />
            </>
          ) : (
            <Text as="h4">
              {t('export.exportForm.exportDatev.notConnected.description')}
            </Text>
          )}
        </Grid>
      );
    case ExportType.SAP_B1:
      return (
        <Flex padding="space12" justifyContent="center">
          <Text as="h4">{t('export.exportForm.exportType.SAP_B1')}</Text>
        </Flex>
      );
    default:
      return (
        <Flex padding="space12" justifyContent="center">
          <Text as="h4">
            {t(`export.exportForm.exportType.${exportType}`)}{' '}
          </Text>
        </Flex>
      );
  }
};
