import { Button, Card, Flex, Text } from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { UploadDocumentMenuButton } from 'components/UploadDocumentMenuButton';
import { UserRole } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { TaskBreakdown } from 'views/AppContainer/components/Header/components/MultiOrganizationOverview/components/DataTable/utils';
import { OrganizationDetails } from 'views/AppContainer/components/Header/components/MultiOrganizationOverview/components/OrganizationDetails';
import { useVendorSuggestionsCounters } from '../hooks/useVendorSuggestionsCounters';

type OrganizationTodosPanelProps = {
  membershipId: string;
  organizationId: string;
  loading: boolean;
  taskBreakdown: TaskBreakdown;
  onClickTask: (path: string) => void;
  onClickUploadDocument: () => void;
};

export const OrganizationTodosPanel = ({
  membershipId,
  organizationId,
  taskBreakdown,
  loading,
  onClickTask,
  onClickUploadDocument,
}: OrganizationTodosPanelProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  const { counters } = useVendorSuggestionsCounters();
  const { isCardManager, isAdmin } = useUserRoles();
  const { showAllExtraFeatures, isInUse } = useCreditCardsSetup();
  const { showEcmSensitiveDocuments } = useEcm();

  const hasVendorsToConvert =
    showAllExtraFeatures &&
    counters.vendorsToConvert > 0 &&
    isInUse &&
    (isCardManager || isAdmin);

  return (
    <Flex
      gap="space16"
      direction="column"
      borderBottomRadius={hasVendorsToConvert ? 'none' : 'medium'}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text color="gray800" fontWeight="regular" fontSize="xlarge">
          {t('organizationTaskPanel.organizationTaskPanelHeading')}
        </Text>
        <UserRoleAccessWrapper
          allowedRoles={[
            UserRole.Admin,
            UserRole.Requester,
            UserRole.Accountant,
          ]}
          mode="hidden"
        >
          {showEcmSensitiveDocuments ? (
            <UploadDocumentMenuButton variant="secondary" />
          ) : (
            <Button
              size="small"
              variant="secondary"
              icon="plus"
              onClick={onClickUploadDocument}
            >
              {t('organizationTaskPanel.uploadButton')}
            </Button>
          )}
        </UserRoleAccessWrapper>
      </Flex>

      <Card
        padding={0}
        boxShadow="noShadow"
        background="gray50"
        borderRadius="basic"
        borderBottomRadius={hasVendorsToConvert ? 'none' : 'medium'}
      >
        <OrganizationDetails
          showTransactionCounts
          loading={loading}
          membershipId={membershipId}
          onClickTask={onClickTask}
          organizationId={organizationId}
          taskBreakdown={taskBreakdown}
        />
      </Card>
    </Flex>
  );
};
