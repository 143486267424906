import { Grid, Item } from '@candisio/design-system';
import { EmptyState } from 'components/FilterableList/components/EmptyState';
import {
  FilterableList,
  FilterableListProps,
} from 'components/FilterableList/FilterableList';
import { nohistoryData } from 'components/Lottie/Lottie';
import { motion } from 'framer-motion';
import { Trans } from 'providers/LocaleProvider';
import {
  GoodsReceiptImportHistoryItem,
  GoodsReceiptImportHistoryItemProps,
} from './GoodsReceiptsImportHistoryItem';

export interface GoodsReceiptsImportHistoryListProps {
  isLoading: boolean;
  data: GoodsReceiptImportHistoryItemProps[];
  onEndReached?: FilterableListProps['onEndReached'];
}
const MotionGrid = motion(Grid);

const renderItem = (item: GoodsReceiptImportHistoryItemProps) => (
  <Item key={item.id} textValue={item.username}>
    <MotionGrid
      borderBottom="1px solid gray200"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeOut', duration: 0.25 }}
    >
      <GoodsReceiptImportHistoryItem {...item} />
    </MotionGrid>
  </Item>
);

export const GoodsReceiptsImportHistoryList = ({
  data,
  isLoading,
  onEndReached,
}: GoodsReceiptsImportHistoryListProps) => {
  return (
    <FilterableList
      width="50rem"
      isLoading={isLoading}
      children={data.map(renderItem)}
      emptyDataState={
        !isLoading && (
          <EmptyState animationData={nohistoryData}>
            <Trans i18nKey="purchase-orders:history.noData">
              You do not have any imports yet.
            </Trans>
          </EmptyState>
        )
      }
      onEndReached={onEndReached}
    />
  );
};
