import { Box, Grid, Heading, ScrollBox } from '@candisio/design-system';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface TransactionReadOnlySideBarProps {
  children: ReactNode;
  isTxExported: boolean;
  isInvoicedNotNeeded: boolean;
  actionBox?: ReactNode;
}

export const TransactionReadOnlySideBar = ({
  children,
  isTxExported,
  isInvoicedNotNeeded,
  actionBox,
}: TransactionReadOnlySideBarProps) => {
  const [t] = useTranslation();

  const heading = isTxExported
    ? t('common:document.details.exportedAndPaid.title')
    : isInvoicedNotNeeded
      ? t('transactions:transactionAssociation.noInvoiceNeeded.title')
      : t('transactions:accountingDataReadOnlyForm.heading');

  return (
    <ScrollBox scrollDirection="y" height="100%">
      <Grid
        paddingTop="space32"
        paddingLeft="space16"
        templateRows="auto 1fr"
        position="static"
        height="100%"
        paddingRight="space24"
        gap="space16"
      >
        <Heading as="h2" color="gray800">
          {heading}
        </Heading>

        <Box position="static" height="100%">
          {children}
        </Box>

        {actionBox ? (
          <Box alignSelf="end" bottom="0" position="sticky" zIndex="2">
            {actionBox}
          </Box>
        ) : null}
      </Grid>
    </ScrollBox>
  );
};
