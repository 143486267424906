import { Flex, Icon, IconKey, Text } from '@candisio/design-system';
import { isMobileClient } from 'components/DocumentHistory/history-components/utils';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface EventWithMobilePromoProps {
  clientName?: string | null;
  iconName: IconKey;
  mobilePromoTranslation?: string;
  eventComponent: ReactElement;
  biggerPadding?: boolean;
}

export const TimelineEventWithMobileIndication = ({
  clientName,
  iconName,
  eventComponent,
  mobilePromoTranslation = 'common:mobileAppPromotion.timelineEvents.onMobile',
  biggerPadding = false,
}: EventWithMobilePromoProps) => {
  const [t] = useTranslation();

  const isMobileEvent = isMobileClient(clientName);

  return (
    <>
      {eventComponent}
      {isMobileEvent && (
        <Flex
          alignItems="center"
          gap="space4"
          paddingTop={biggerPadding ? 'space8' : 'space4'}
        >
          <Icon icon={iconName} size="space14" />
          <Text lineHeight="paragraph">{t(mobilePromoTranslation)}</Text>
        </Flex>
      )}
    </>
  );
};
