import { HeaderProps, TruncatedText } from '@candisio/design-system';
import { getTranslationContext } from 'orgConfig';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentsTableData } from './types';

export const Header = ({
  column,
  isFilteredOrSorted,
}: HeaderProps<DocumentsTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  return (
    <TruncatedText
      wordBreak="break-all"
      color={isFilteredOrSorted ? 'gray800' : 'gray500'}
    >
      {t(`columns.${column.id}`, {
        context: getTranslationContext(),
      })}
    </TruncatedText>
  );
};
