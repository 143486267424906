import {
  Organization,
  OrganizationEmailAccount,
} from 'generated-types/graphql.types';

// Hence we support several email domains including
// migrated ones from wf1.0 customers, we would need to
// prioritize current email domain, so customers
// will import their invoices via current domain only
const PRIORITY_IMPORT_EMAIL_DOMAIN = 'candis-import.com';

class EmailLabels {
  private _activeEmailAccounts = new Array<OrganizationEmailAccount>();

  constructor(
    organizations: Organization[] | null | undefined,
    organizationId: string
  ) {
    const activeOrganization = (organizations || []).find(
      org => org.slug === organizationId
    );

    if (activeOrganization?.emailAccounts) {
      const { records: orgEmailAccounts } = activeOrganization.emailAccounts;

      if (orgEmailAccounts) {
        const isEnabledAccount = ({ isEnabled }: any) => isEnabled;

        const sortByPriority = ({ address }: any): number =>
          // new Email Domain registered for current platform should be displayed last
          address.endsWith(PRIORITY_IMPORT_EMAIL_DOMAIN) ? 1 : -1;

        this._activeEmailAccounts = orgEmailAccounts
          .filter(isEnabledAccount)
          .sort(sortByPriority) as OrganizationEmailAccount[];
      }
    }
  }

  hasEmail(): boolean {
    return this._activeEmailAccounts.length > 0;
  }

  listAll(): string[] {
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    return this._activeEmailAccounts.map(e => e.address!);
  }

  getOneForCopy(): string | null {
    const allEmails = this.listAll();
    if (allEmails.length) {
      // First try to find prio domain emails
      // so customers use it instead of other options
      const copyEmails = allEmails.filter(e =>
        e.endsWith(PRIORITY_IMPORT_EMAIL_DOMAIN)
      );

      // make surre only one email is returned
      return copyEmails.length
        ? copyEmails[copyEmails.length - 1]
        : allEmails[allEmails.length - 1];
    }

    return null;
  }
}

export function emailLabels(organizations?: Organization[] | null) {
  return {
    for: (organizationId: string) =>
      new EmailLabels(organizations, organizationId),
  };
}
