import { Grid, GridProps } from '@candisio/design-system';
import { Transition, Variants, motion } from 'framer-motion';

interface FilterableListItemProps
  extends Omit<GridProps<'div'>, 'as' | 'onSelect'> {
  itemId: string;
  onSelect?: (itemId: string) => void;
  hasAction?: boolean;
  showDefaultStyling?: boolean;
}

const MotionGrid = motion(Grid);

const defaultStyling = {
  templateColumns: '1fr',
  autoFlow: 'column',
  gap: 'space16',
  justifyContent: 'start',
  alignItems: 'center',
  padding: 'space12 space20',
  borderBottom: '1px solid gray200',
};

const variants: Variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const transition: Transition = { ease: 'easeOut', duration: 0.25 };

export const FilterableListItem = ({
  children,
  itemId,
  onSelect,
  hasAction = false,
  showDefaultStyling = true,
  style = {},
  ...gridProps
}: FilterableListItemProps) => {
  return (
    <MotionGrid
      width="100%"
      {...(showDefaultStyling && defaultStyling)}
      style={onSelect || hasAction ? { ...style, cursor: 'pointer' } : style}
      {...gridProps}
      onClick={() => onSelect?.(itemId)}
      variants={variants}
      initial="hidden"
      animate="visible"
      transition={transition}
    >
      {children}
    </MotionGrid>
  );
};
