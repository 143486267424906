import { GlobalDocumentType } from 'components/Form/hooks/useDocumentTypeFieldOptions';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { noop } from 'lodash';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

interface DocumentTypeContextState {
  documentType: GlobalDocumentType | undefined | null;
  documentTypeOld: string | undefined | null;
  setDocumentType: Dispatch<
    SetStateAction<DocumentTypeContextState['documentType']>
  >;
  isInvoiceDocumentType: boolean;
  showConversionNeededWarning: boolean;
  setDocumentTypeOld: Dispatch<
    SetStateAction<DocumentTypeContextState['documentTypeOld']>
  >;
}

const initialState: DocumentTypeContextState = {
  documentTypeOld: undefined,
  documentType: undefined,
  isInvoiceDocumentType: true,
  showConversionNeededWarning: false,
  setDocumentType: noop,
  setDocumentTypeOld: noop,
};

export const DocumentTypeContext =
  createContext<DocumentTypeContextState>(initialState);

export const DocumentTypeContextProvider = ({
  children,
  initiallyAnInvoice,
}: {
  children: ReactNode;
  initiallyAnInvoice: boolean;
}) => {
  const [llmClassificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.llmClassification,
  ]);

  const [documentType, setDocumentType] =
    useState<DocumentTypeContextState['documentType']>();

  const isInvoiceDocumentType = Boolean(
    !documentType || documentType?.documentCategory
  );
  // can be removed once classificationFrontend FF is enabled
  const [documentTypeOld, setDocumentTypeOld] =
    useState<DocumentTypeContextState['documentTypeOld']>();

  const hasTypeChangeFromInvoiceToNonInvoiceOrViceVersa =
    isInvoiceDocumentType !== initiallyAnInvoice;

  return (
    <DocumentTypeContext.Provider
      value={{
        documentType,
        setDocumentType,
        isInvoiceDocumentType,
        showConversionNeededWarning: llmClassificationFF
          ? hasTypeChangeFromInvoiceToNonInvoiceOrViceVersa
          : false,
        documentTypeOld,
        setDocumentTypeOld,
      }}
    >
      {children}
    </DocumentTypeContext.Provider>
  );
};

export const useDocumentTypeContext = () => useContext(DocumentTypeContext);
