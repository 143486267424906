import {
  Button,
  Card,
  Flex,
  Grid,
  Icon,
  Skeleton,
  Text,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from 'views/Settings/utils';
import {
  AnimatedAccordion,
  AnimatedCaret,
  AnimatedProgressBar,
} from './Animations';

interface AccordionItemProps<STEP> {
  step: STEP;
  title: string;
  order: number;
  isOpen: boolean;
  isCompleted: boolean;
  handleOpen: (stepId: STEP) => void;
  isLastStep: boolean;
  children: ReactNode;
}

const AccordionItem = <STEP extends Key>({
  step,
  title,
  order,
  isOpen,
  isCompleted,
  handleOpen,
  isLastStep,
  children,
}: AccordionItemProps<STEP>) => {
  return (
    <Grid
      overflow="hidden"
      key={step}
      background={isOpen ? 'gray0' : 'gray50'}
      borderBottom={isLastStep ? 'none' : '1px solid gray250'}
    >
      <Grid
        padding="space18"
        alignItems="center"
        gap="space16"
        templateColumns="24px 1fr auto"
        onClick={() => handleOpen(step)}
        style={{ cursor: 'pointer' }}
      >
        {isCompleted ? (
          <Icon icon="checkCircle" color="green500" size="space32" />
        ) : (
          <Flex
            placeContent="center"
            placeItems="center"
            width="space32"
            height="space32"
          >
            <Flex
              placeContent="center"
              placeItems="center"
              borderRadius="100%"
              background="gray250"
              width="space24"
              height="space24"
            >
              <Text fontSize="basic">{order}</Text>
            </Flex>
          </Flex>
        )}
        <Text fontSize="large" color={isCompleted ? 'gray500' : 'gray800'}>
          {title}
        </Text>
        <AnimatedCaret open={isOpen} />
      </Grid>
      <AnimatedAccordion collapsed={!isOpen}>{children}</AnimatedAccordion>
    </Grid>
  );
};

export const AccordionWizard = <STEP extends Key>({
  wizardSteps,
  completedStepsCount,
  wizardDismissed,
  setWizardDismissed,
  loading,
}: {
  wizardSteps?: {
    step: STEP;
    completed: boolean;
    order: number;
    title: string;
    children: ReactNode;
  }[];
  completedStepsCount: number;
  wizardDismissed: boolean;
  setWizardDismissed: (value: boolean) => void;
  loading: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const [activeStep, setActiveStep] = useState<STEP | null>(null);

  const handleOpen = (step: STEP) => {
    if (activeStep === step) {
      setActiveStep(null);
    } else {
      setActiveStep(step);
    }
  };

  if (!wizardSteps) {
    return null;
  }

  const stepsCount = wizardSteps.length;
  const currentProgress = Math.round((100 / stepsCount) * completedStepsCount);
  const allStepsCompleted = completedStepsCount === stepsCount;

  return (
    <Grid
      templateColumns={`${SETTINGS_VIEW_DEFAULT_WIDTH} 1fr`}
      aria-label="setup-wizard"
    >
      {loading && (
        <Card borderRadius="basic">
          <Grid gap="space16" alignContent="start">
            <Skeleton width="100%" height="space64" borderRadius="small" />
            {wizardSteps.map((step, key) => (
              <Skeleton
                key={key}
                width="100%"
                height="space32"
                borderRadius="small"
              />
            ))}
          </Grid>
        </Card>
      )}
      {!loading && (
        <Card padding={0} borderRadius="basic">
          <Grid
            alignItems="center"
            paddingX="space18"
            paddingY="space24"
            gap="space12"
            templateColumns={allStepsCompleted ? 'auto 1fr auto' : '1fr auto'}
          >
            {allStepsCompleted ? (
              <Icon icon="checkCircle" color="green500" size="space32" />
            ) : null}
            <Text fontSize="xlarge">{t('datev.setupWizard.title')}</Text>
            {allStepsCompleted ? (
              <Button
                variant="secondary"
                onClick={() => setWizardDismissed(!wizardDismissed)}
              >
                {wizardDismissed
                  ? t('datev.setupWizard.openWizard')
                  : t('datev.setupWizard.closeWizard')}
              </Button>
            ) : (
              <Text color="gray500" fontSize="basic">
                {t('datev.setupWizard.completed', {
                  progress: currentProgress,
                })}
              </Text>
            )}
          </Grid>

          <AnimatedAccordion collapsed={wizardDismissed && allStepsCompleted}>
            <AnimatedProgressBar progress={currentProgress} />
            {wizardSteps.map((wizardStep, index) => {
              const { step, order, title, completed } = wizardStep;
              const isOpen = activeStep === step;
              const isLastStep = index === stepsCount - 1;

              return (
                <AccordionItem<STEP>
                  step={step}
                  title={title}
                  key={index}
                  order={order}
                  isOpen={isOpen}
                  isCompleted={completed}
                  handleOpen={handleOpen}
                  isLastStep={isLastStep}
                >
                  {wizardStep.children}
                </AccordionItem>
              );
            })}
          </AnimatedAccordion>
        </Card>
      )}
    </Grid>
  );
};
