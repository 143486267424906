import { Box, Flex } from '@candisio/design-system';
import { TokenOrCSSValue } from '@candisio/design-system/src/types';
import { ReactNode } from 'react';

export const Wrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Flex
      height="100%"
      width="100%"
      position="relative"
      alignItems="center"
      justifyContent="center"
      direction="column"
      overflow="hidden"
    >
      {children}
    </Flex>
  );
};

export const TableWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      height="100%"
      width="100%"
      opacity="0.7"
      position="relative"
      style={{
        maskImage: 'linear-gradient(to top, transparent 5%, #000000 15%)',
        pointerEvents: 'none',
        userSelect: 'none',
      }}
    >
      {children}
    </Box>
  );
};

export const PromotionWrapper = ({
  children,
  width = '46rem',
  height = '28rem',
}: {
  children: ReactNode;
  width?: TokenOrCSSValue<'space', 'width'>;
  height?: TokenOrCSSValue<'space', 'height'>;
}) => {
  return (
    <Box
      width={width}
      height={height}
      position="absolute"
      alignSelf="center"
      justifySelf="center"
    >
      {children}
    </Box>
  );
};
