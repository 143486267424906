import { useContext, useEffect, useRef } from 'react';
import { useExportQuery } from '../../../../../../generated-types/graphql.types';
import { usePolling } from '../../../../../../hooks/usePolling';
import { ExportContext } from '../../../Context';
import { ApiExport } from '../../../toolkit/components/ApiExport';
import { FileExport } from '../../../toolkit/components/FileExport';
import { useExportDataGigaFactory } from '../../../toolkit/hooks/useExportDataGigaFactory';
import { useIsExporting } from '../../../toolkit/hooks/useIsExporting';
import { ExportType } from '../../../types';

const EXPORT_STATUS_POLLING_INTERVAL = 3000;

export const exportTypesWithAutomaticFileTransfer = [
  ExportType.DATEV,
  ExportType.DATEV_BDS,
  ExportType.SAP_B1,
  ExportType.API,
].map(String);

export const ExportHistoryDetails = () => {
  const state = useContext(ExportContext);

  const {
    isExporting: isAnyExportingInPaginatedQuery,
    refetchAllExportEntities,
    refetchExporteds,
  } = useIsExporting();

  const { exportDetails } = useExportDataGigaFactory(state);

  const { exportId } = state;

  const { startPolling: startPollingExport, stopPolling: stopPollingExport } =
    useExportQuery({
      variables: { id: exportId || '' },
      skip: !exportId,
    });

  const { isPolling } = usePolling({
    pollInterval: EXPORT_STATUS_POLLING_INTERVAL,
    startPolling: startPollingExport,
    stopPolling: stopPollingExport,
    skip: exportDetails?.status !== 'EXPORTING',
    pollOnlyOnFocus: false,
  });

  const isPollingInterrupted = useRef(false);

  useEffect(() => {
    // when user left the export page while exporting is in progress
    if (!isPolling && isAnyExportingInPaginatedQuery) {
      isPollingInterrupted.current = true;
    }
    // when user comes back to the export page
    if (isPollingInterrupted.current && exportDetails?.status !== 'EXPORTING') {
      refetchAllExportEntities();
      refetchExporteds();
      isPollingInterrupted.current = false;
    }
  }, [
    exportDetails,
    isPolling,
    isAnyExportingInPaginatedQuery,
    refetchExporteds,
    refetchAllExportEntities,
  ]);

  if (exportDetails) {
    const isExportTypeWithAutomaticFileTransfer =
      exportTypesWithAutomaticFileTransfer.includes(exportDetails.type);

    return isExportTypeWithAutomaticFileTransfer ? (
      <ApiExport exportDetails={exportDetails} />
    ) : (
      <FileExport exportDetails={exportDetails} />
    );
  }

  return null;
};
