import { Grid, useTheme } from '@candisio/design-system';
import { UserWithAbsence } from 'containers/absence/UserWithAbsence';
import { User } from 'generated-types/graphql.types';

type Props = Pick<User, 'avatarUrl' | 'id' | 'name'> & {
  isFocused: boolean;
};

export const CommentMentionsSuggestion = ({
  avatarUrl,
  id,
  isFocused,
  name,
}: Props) => {
  const theme = useTheme();

  return (
    <Grid
      background={isFocused ? theme.menuListItem.focused.background : undefined}
      paddingX="space16"
      paddingY="space8"
    >
      <UserWithAbsence image={avatarUrl} label={name} value={id} />
    </Grid>
  );
};
