import { ProcessingFormPaymentConditionFieldDefault } from 'components/Form/ProcessingForm/ProcessingFormPaymentConditionFieldDefault';
import { ProcessingFormPaymentFieldsProps } from 'components/Form/ProcessingForm/ProcessingFormPaymentFields';
import { useUserRoles } from 'hooks/useUserRoles';
import { useOtherIntegration } from 'orgConfig/other';
import { useTranslation } from 'react-i18next';
import { ProcessingFormPaymentConditionFieldCoreDataApi } from 'views/Contacts/ContactDetails/ContactForm/CoreDataApi/ProcessinFormPaymentConditionFieldCoreDataApi';
/**
 * A `ProcessingFormSelectField` for selecting a payment condition
 *
 * Returns `null` if direction of currently-selected document type is not
 * incoming
 */
export const ProcessingFormPaymentConditionField = ({
  fieldOptions,
  readOnly,
  ...restProps
}: ProcessingFormPaymentFieldsProps) => {
  const { shouldUseCoreDataApi } = useOtherIntegration();
  const [t] = useTranslation();
  const { isAccountant, isRequester, isAdmin } = useUserRoles();
  const isAllowedToChange = isAccountant || isRequester || isAdmin;

  return shouldUseCoreDataApi ? (
    <ProcessingFormPaymentConditionFieldCoreDataApi
      name="paymentCondition"
      label={t('settings.contacts.details.edit.paymentCondition.label')}
      placeholder={t(
        'settings.contacts.details.edit.paymentCondition.placeholder'
      )}
      typeFieldItems={fieldOptions?.type?.items}
      {...fieldOptions?.paymentCondition}
      readOnly={
        !isAllowedToChange &&
        (readOnly || fieldOptions?.paymentCondition?.readOnly)
      }
      {...restProps}
    />
  ) : (
    <ProcessingFormPaymentConditionFieldDefault
      name="paymentCondition"
      label={t('settings.contacts.details.edit.paymentCondition.label')}
      placeholder={t(
        'settings.contacts.details.edit.paymentCondition.placeholder'
      )}
      typeFieldItems={fieldOptions?.type?.items}
      {...fieldOptions?.paymentCondition}
      readOnly={readOnly || fieldOptions?.paymentCondition?.readOnly}
      {...restProps}
    />
  );
};
