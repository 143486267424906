import { Drawer, Grid, Heading } from '@candisio/design-system';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { useTranslation } from 'react-i18next';
import { PaymentConditionsForm } from 'views/Settings/PaymentConditions/CoreDataApi/PaymentConditionsForm';
import { PaymentConditionsFormValues } from 'views/Settings/PaymentConditions/CoreDataApi/paymentConditionsFormFormShema';

export interface PaymentConditionsDrawerProps {
  /** Open state of the drawer */
  isOpen?: boolean;
  /** Called when the drawer is closed */
  onClose?: () => void;
  /** Initial values when the form is first rendered  */
  defaultValues?: PaymentConditionsFormValues;
  /** Is loading payment condition */
  loading?: boolean;
}

export const PaymentConditionsDrawer = ({
  defaultValues,
  isOpen = false,
  loading = false,
  onClose,
}: PaymentConditionsDrawerProps) => {
  const [t] = useTranslation('settings');

  const titleName = t('paymentConditions.drawer.title.view');

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerLayout
        header={<Heading as="h3">{titleName}</Heading>}
        onClose={onClose}
      >
        {!loading && (
          <Grid gap="space32">
            <PaymentConditionsForm defaultValues={defaultValues} />
          </Grid>
        )}
      </DrawerLayout>
    </Drawer>
  );
};
