import { Flex, Link, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

interface NoItemsFoundCtaProps {
  onCtaClick: () => void;
}

export const NoItemsFoundCta = ({ onCtaClick }: NoItemsFoundCtaProps) => {
  const [t] = useTranslation();

  return (
    <Flex
      direction="row"
      paddingY="space8"
      paddingX="space16"
      borderRadius="large"
      background="white"
      gap="space8"
    >
      <Text color="gray800">
        {t('document.attachments.noInvoicesFoundCta')}
      </Text>
      <Link as="button" onClick={onCtaClick}>
        {t('document.attachments.attachPdfFromDisk')}
      </Link>
    </Flex>
  );
};
