import { ApolloError } from '@apollo/client';
import { Button, Grid, Link, Switch, Tag, Text } from '@candisio/design-system';
import {
  DatevClientInfoQuery,
  LedgerState,
  useDatevClientInfoLazyQuery,
} from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { useDatevAvailableServices } from 'orgConfig/datev/datevAvailableServices';
import {
  DatevErrors,
  useDatevErrorsMapping,
} from 'orgConfig/datev/datevErrorsMapping';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { i18n } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { SendEmailModal } from '../../../components/SendEmailModal';
import { DcsContainer } from '../../DcsContainers/DcsContainer/DcsContainer';
import { DETAILS_MAX_WIDTH, delayForLoadingInMillSec } from '../../consts';
import { EXPORT_TYPE_IMAGES } from '../../images';
import { getChecks } from '../states';
import { CheckProps } from '../types';
import { computeModalProperties } from '../utils';
import { InfoList } from './InfoList';

export const CHECKS_ANIMATION_DELAY = 3 * delayForLoadingInMillSec;
const OPEN_EMAIL_MODAL = 'emailModal';

type DxsoJobsProps = {
  clientHasPermissionForDXSO: boolean;
  clientIsConnectedToDXSO: boolean;
  onDisabled: () => void;
  onEnabled: () => void;
};

// Document Data
export const DxsoJobs = ({
  clientHasPermissionForDXSO,
  clientIsConnectedToDXSO,
  onDisabled,
  onEnabled,
}: DxsoJobsProps) => {
  const [t] = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();
  const { hasDxsoWithoutPermissions, dxsoService, availableServicesLoading } =
    useDatevAvailableServices();

  const [isToggleActive, setIsToggleActive] = useState(clientIsConnectedToDXSO);
  const [isLoading, setIsLoading] = useState(false);
  const [isToggleDisabled, setIsToggleDisabled] = useState(false);
  const [retries, setRetries] = useState(0);
  const [checks, setChecks] = useState<CheckProps>(
    clientIsConnectedToDXSO ? [getChecks(t).connected.success] : []
  );

  const datevErrors = useDatevErrorsMapping();

  const [isServiceCheckedButNotConnected, setIsServiceCheckedButNotConnected] =
    useState(false);

  const openModal = () => {
    navigate({ hash: OPEN_EMAIL_MODAL });
  };

  const [
    getDatevClientInfo,
    { data: clientInfo, loading: datevClientInfoLoading, error },
  ] = useDatevClientInfoLazyQuery({
    fetchPolicy: 'no-cache',
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const evaluateChecks = useCallback(
    ({
      clientInfo,
      error,
    }: {
      clientInfo?: DatevClientInfoQuery;
      error?: ApolloError;
    }) => {
      const serviceWarning = dxsoService?.warning;

      // this indicates that permissions for the api are missing
      if (serviceWarning) {
        setChecks([
          {
            ...getChecks(t).customError,
            text: (
              <Text>
                {serviceWarning}{' '}
                <Link
                  external
                  href={t(
                    'settings.datev.connect.exportTypes.dxsoJobs.helpLink'
                  )}
                >
                  {t('settings.datev.connect.exportTypes.dxsoJobs.moreInfo')}
                </Link>
              </Text>
            ),
          },
        ]);

        setIsToggleDisabled(true);
        setIsToggleActive(false);
        setIsLoading(false);

        if (clientIsConnectedToDXSO) {
          onDisabled();
        }

        return;
      }

      if (error) {
        const gqlErrorCode = error?.graphQLErrors[0].extensions
          .code as DatevErrors;

        if (gqlErrorCode && datevErrors[gqlErrorCode])
          setChecks([
            {
              ...getChecks(t).customError,
              text: (
                <Text>
                  {datevErrors[gqlErrorCode]}{' '}
                  <Link
                    external
                    href={t(
                      'settings.datev.connect.exportTypes.dxsoJobs.helpLink'
                    )}
                  >
                    {t('settings.datev.connect.exportTypes.dxsoJobs.moreInfo')}
                  </Link>
                </Text>
              ),
            },
          ]);
        else setChecks([getChecks(t).network.failure]);

        setIsToggleActive(false);
        setIsLoading(false);

        return;
      }

      if (clientInfo) {
        const hasDocumentManagement =
          clientInfo?.datevClientInfo.is_document_management_available &&
          clientHasPermissionForDXSO;

        const info = clientInfo?.datevClientInfo.ledgerState || {
          accountsReceivable: LedgerState.NotFound,
          accountsPayable: LedgerState.NotFound,
        };

        const { accountsPayable, accountsReceivable } = info;

        setChecks([
          getChecks(t).isDocumentManagementAvailable[
            hasDocumentManagement ? 'success' : 'error'
          ],
          getChecks(t).accountsPayable[accountsPayable],
          getChecks(t).accountsReceivable[accountsReceivable],
        ]);

        const allChecksSuccess =
          hasDocumentManagement &&
          accountsPayable === LedgerState.Found &&
          accountsReceivable === LedgerState.Found;

        setIsServiceCheckedButNotConnected(
          clientIsConnectedToDXSO && !allChecksSuccess
        );

        if (allChecksSuccess) {
          setIsToggleActive(true);
          if (!clientIsConnectedToDXSO) onEnabled();

          // If all the checks are met we show the actual result to the user so it doesn't disappear
          // and after 3 seconds we communicate the success message
          setTimeout(() => {
            setChecks([getChecks(t).connected.success]);
          }, CHECKS_ANIMATION_DELAY);
        } else {
          if (clientIsConnectedToDXSO) onDisabled();
          setIsToggleActive(false);
        }
      }
    },
    [
      dxsoService,
      clientHasPermissionForDXSO,
      clientIsConnectedToDXSO,
      datevErrors,
      onDisabled,
      onEnabled,
      setIsToggleDisabled,
      t,
    ]
  );

  const onToggle = useCallback(
    (isActive: boolean) => {
      setRetries(retries + 1);
      if (isActive) {
        setIsToggleActive(true);
        void getDatevClientInfo();
      } else {
        setChecks([]);
        setIsToggleActive(false);
        onDisabled();
      }
    },
    [retries, onDisabled, getDatevClientInfo]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (clientIsConnectedToDXSO && !isLoading) void getDatevClientInfo();
  }, [hasDxsoWithoutPermissions, clientIsConnectedToDXSO]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (datevClientInfoLoading) {
      setIsLoading(true);
      setChecks([
        getChecks(t).isDocumentManagementAvailable.loading,
        getChecks(t).accountsPayable.loading,
        getChecks(t).accountsReceivable.loading,
      ]);
    } else {
      evaluateChecks({ clientInfo, error });
      setTimeout(() => {
        setIsLoading(false);
      }, CHECKS_ANIMATION_DELAY);
    }
  }, [
    clientInfo,
    datevClientInfoLoading,
    hasDxsoWithoutPermissions,
    availableServicesLoading,
  ]);

  return (
    <>
      <DcsContainer
        icon={EXPORT_TYPE_IMAGES.dxsoJobs[language]}
        iconAlt={t('settings.datev.imageAltTexts.dxsoJobs')}
        title={t('settings.datev.connect.exportTypes.dxsoJobs.title')}
        iconHeight="120px"
        iconWidth="100.8px"
        tag={
          clientIsConnectedToDXSO && (
            <Tag color="green" variant="secondary" height="max-content">
              {t('settings.datev.connect.exportTypes.dxsoJobs.recommended')}
            </Tag>
          )
        }
        details={
          <Grid justifyItems="left" gap="space8">
            <Text maxWidth={DETAILS_MAX_WIDTH}>
              {t('settings.datev.connect.exportTypes.dxsoJobs.info')}
            </Text>

            <InfoList checks={checks} animate retries={retries} />
            {!isLoading && isServiceCheckedButNotConnected && (
              <Grid>
                <Button
                  variant="secondary"
                  onClick={() => {
                    openModal();
                  }}
                >
                  {t('settings.datev.connect.exportTypes.dxsoJobs.sendEmail')}
                </Button>
              </Grid>
            )}
          </Grid>
        }
        actions={
          <Switch
            name={t('settings.datev.connect.exportTypes.dxsoJobs.switchName')}
            disabled={isLoading || isToggleDisabled}
            label={t('settings.datev.connect.exportTypes.dxsoJobs.switchLabel')}
            checked={isToggleActive}
            onChange={onToggle}
          />
        }
      />

      <EmailModalContainer checks={checks}></EmailModalContainer>
    </>
  );
};

const EmailModalContainer = ({ checks }: { checks: CheckProps }) => {
  const org = useFullOrganization();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { client } = useDatev();

  const visible = window.location.hash === '#' + OPEN_EMAIL_MODAL;

  const closeModal = () => {
    navigate({ hash: '' });
  };

  const { clientNumber, consultantNumber } = client || {};
  const modalProperties = computeModalProperties({
    t,
    checks,
    organizationName: org?.name,
    clientNumber,
    consultantNumber,
    currentUser,
  });

  const sendEmailModalTitle = t(
    'settings.datev.connect.exportTypes.dxsoJobs.taxAdvisorEmail.modalTitle'
  );

  return (
    <SendEmailModal
      modalTitle={sendEmailModalTitle}
      isOpen={visible}
      subjectText={modalProperties.subjectText}
      body={modalProperties.emailText}
      senderEmail={modalProperties.senderEmail}
      senderName={modalProperties.senderName}
      onCancel={closeModal}
    />
  );
};
