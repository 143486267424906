import { useProjectCodeQuery } from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom-v5-compat';

export const useProjectCodeData = () => {
  const { id } = useParams();
  const { data, loading } = useProjectCodeQuery({
    variables: { id: id || '' },
    skip: !id,
  });

  const projectCodeInitialValue = useMemo(
    () => ({
      projectCode: data?.projectCode?.code ?? '',
      projectName: data?.projectCode?.name ?? '',
    }),
    [data]
  );
  return {
    data: projectCodeInitialValue,
    loading,
  };
};
