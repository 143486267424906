import { Flex } from '@candisio/design-system';
import { EmptyState } from 'components/EmptyViews/EmptyState';
import { EmptyDataText } from 'components/EmptyViews/styles';
import { nodocumentData } from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportContext } from '../../../../Context';
import { Table } from '../../../../types';

export const HistoryProvisionReversal = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const { table } = useContext(ExportContext);

  const translationKeysTables = {
    [Table.REVERSALS]: {
      title: 'exportedEmptyState.reversals.title',
      description: 'exportedEmptyState.reversals.description',
    },
    [Table.PROVISIONS]: {
      title: 'exportedEmptyState.provisions.title',
      description: 'exportedEmptyState.provisions.description',
    },
  };

  if (table === Table.REVERSALS || table === Table.PROVISIONS) {
    return (
      <EmptyState
        animationData={nodocumentData}
        emptyDataText={t(translationKeysTables[table].title)}
      >
        <Flex justifyContent="center">
          <EmptyDataText>
            <Trans
              t={t}
              i18nKey={translationKeysTables[table].description}
            ></Trans>
          </EmptyDataText>
        </Flex>
      </EmptyState>
    );
  }

  return null;
};
