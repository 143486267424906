import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { EcmSortInput, SortDirection } from 'generated-types/graphql.types';
import { SortingRule } from 'react-table';

const mapColumnIdsToSortInput: Partial<
  Record<keyof EcmDocumentsTableData, keyof EcmSortInput>
> = {
  costCenter: 'ecmDocumentCostCenter',
  contractType: 'documentSubCategory',
  contractStatus: 'documentStatus',
};

export const mapToEcmDocumentSortInput = (
  sort: Array<SortingRule<keyof EcmDocumentsTableData>>
) => {
  if (sort.length < 1) {
    return undefined;
  }

  const [firstSort] = sort;

  const firstSortId = firstSort.id;

  const sortField =
    mapColumnIdsToSortInput[firstSortId as keyof EcmDocumentsTableData] ??
    firstSortId;
  return {
    [sortField]: firstSort.desc ? SortDirection.Desc : SortDirection.Asc,
  };
};
