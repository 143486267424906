import {
  PROCESSING_FORM_FIELD_METADATA_SOURCES,
  ProcessingFormFieldMetadata,
  ProcessingFormFieldMetadataSource,
} from 'components/Form/ProcessingForm/ProcessingFormMetadataContext';
import { Maybe } from 'generated-types/graphql.types';

const isProcessingFormFieldMetadataSource = (
  source?: Maybe<string>
): source is ProcessingFormFieldMetadataSource => {
  return PROCESSING_FORM_FIELD_METADATA_SOURCES.includes(
    source as ProcessingFormFieldMetadataSource
  );
};

/**
 * Maps `*Field` to `ProcessingFormFieldMetadata`
 *
 * If field has a value and valid source, we use the confidence and source from
 * the field.
 *
 * If field has a value but source is not valid, we don’t set metadata.
 *
 * If field has no value but suggestion has a value, we the use the confidence
 * from the suggestion and set source to `smartFieldSuggestions`
 */
export const toFieldMetadata = <
  TField extends {
    confidence?: Maybe<number>;
    source?: Maybe<string>;
    value?: Maybe<unknown>;
  },
  TSuggestion extends { confidence?: Maybe<number>; value?: Maybe<unknown> },
>(
  field?: Maybe<TField>,
  suggestion?: Maybe<TSuggestion>
): ProcessingFormFieldMetadata | undefined => {
  if (field?.value) {
    if (field.confidence && isProcessingFormFieldMetadataSource(field.source)) {
      return { confidence: field.confidence, source: field.source };
    }

    return;
  }

  if (suggestion?.value && suggestion.confidence) {
    return {
      confidence: suggestion.confidence,
      source: 'smartFieldSuggestions',
    };
  }
};
