import { Box, Flex, Paragraph } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { InfoIcon } from 'components/Icons/InfoIcon';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { QuickSaveButtons } from 'views/Settings/components/QuickSaveButtons/QuickSaveButtons';
import { getPlaceholderBookingAccount } from 'views/Settings/CreditCards/CreditCardExportingCard/CreditCardExportingForm/BookingAccountForm/BookingAccountForm.helper';
import { useDatevProvisionsAccountValidation } from '../../hooks/useDatevProvisionsAccountValidation';

interface OtherAssetsAccountNumberProps {
  onSubmit: ({
    otherAssetsAccountNumber,
  }: {
    otherAssetsAccountNumber: string | undefined;
  }) => void;
  otherAssetsAccountNumber: string | undefined;
}

export const OtherAssetsAccountNumber = ({
  onSubmit,
  otherAssetsAccountNumber,
}: OtherAssetsAccountNumberProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const {
    schema,
    errorMessages,
    maxLength: accountLength,
  } = useDatevProvisionsAccountValidation('otherAssetsAccountNumber');

  const form = useForm({
    defaultValues: { otherAssetsAccountNumber },
    context: { t },
    resolver: zodResolver({
      zodSchema: schema({ t }),
      errorMessages: errorMessages({
        t,
        label: 'provisions.setupCard.otherAssetsAccountLabel',
      }),
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
    }),
    mode: 'onSubmit',
  });

  const handleSubmit = ({
    otherAssetsAccountNumber,
  }: {
    otherAssetsAccountNumber: string | undefined;
  }) => {
    if (!otherAssetsAccountNumber) {
      onSubmit({ otherAssetsAccountNumber: '' });
    } else {
      onSubmit({ otherAssetsAccountNumber });
    }

    form.resetField('otherAssetsAccountNumber', {
      defaultValue: otherAssetsAccountNumber,
    });
  };

  const isFormDirty = form.formState.isDirty;

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        onSubmit={form.handleSubmit(handleSubmit)}
        gap="space16"
        alignItems="end"
      >
        <Box>
          <InfoIcon
            disableTabbing
            size="space16"
            position="absolute"
            right={0}
            top={-2}
            zIndex="1"
            message={
              <Paragraph width="300px">
                {t('provisions.setupCard.otherAssetsAccountInfo')}
              </Paragraph>
            }
          />
          <HookFormTextField
            label={
              <Flex width="95%">
                <Box>{t('provisions.setupCard.otherAssetsAccountLabel')}</Box>
              </Flex>
            }
            name="otherAssetsAccountNumber"
            placeholder={t('provisions.setupCard.accountPlaceholder', {
              accountNumber: getPlaceholderBookingAccount(accountLength),
            })}
            clearable={false}
          />
        </Box>
        <Box paddingTop="space16">
          <QuickSaveButtons
            isFormDirty={isFormDirty}
            resetField={() => form.resetField('otherAssetsAccountNumber')}
          />
        </Box>
      </Flex>
    </FormProvider>
  );
};
