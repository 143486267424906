import { Box, Button, Card, Flex, Grid, Link } from '@candisio/design-system';
import { HookFormCheckboxField } from 'components/HookFormFields/HookFormCheckboxField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface RequesterInfoStepProps {
  onPrevStep: () => void;
  handleSubmit: () => void;
  isApplyForCreditCardsPending?: boolean;
}

export const RequesterInfoStep = ({
  onPrevStep,
  handleSubmit,
  isApplyForCreditCardsPending = false,
}: RequesterInfoStepProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid gap="space20">
      <Grid templateColumns="1fr 1fr" rows={2} gap="space16">
        <HookFormTextField
          label={t('modal.form.requesterInfoForm.contactFirstName.label')}
          name="contactFirstName"
          placeholder={t(
            'modal.form.requesterInfoForm.contactFirstName.placeholder'
          )}
          autoFocus
        />
        <HookFormTextField
          label={t('modal.form.requesterInfoForm.contactLastName.label')}
          name="contactLastName"
          placeholder={t(
            'modal.form.requesterInfoForm.contactLastName.placeholder'
          )}
        />
        <HookFormTextField
          label={t('modal.form.requesterInfoForm.phoneNumber.label')}
          name="contactPhone"
          placeholder={t(
            'modal.form.requesterInfoForm.phoneNumber.placeholder'
          )}
        />
        <HookFormTextField
          label={t('modal.form.requesterInfoForm.email.label')}
          name="contactEmail"
          placeholder={t('modal.form.requesterInfoForm.email.placeholder')}
          readOnly
        />
      </Grid>
      <Card background="bluebg" boxShadow="noShadow" padding="space8">
        <Flex gap="space24">
          <HookFormCheckboxField
            name="acceptTerms"
            ariaLabel="Accept terms checkbox"
            label=""
          />
          <Box>
            <Trans t={t} i18nKey="modal.form.acceptTerms">
              I hereby authorise CANDIS to support us in the registration
              process with pliant and in particular to transmit my data to
              pliant by e-mail. I have read and agree to CANDIS’{' '}
              <Link href={t('modal.form.privacyPolicyLink')} external>
                privacy policy
              </Link>
              . I can find information on data processing by pliant{' '}
              <Link href={t('modal.form.termsAndConditionsLink')} external>
                {' '}
                here
              </Link>
              .
            </Trans>
          </Box>
        </Flex>
      </Card>
      <Flex gap="space16" justifyContent="end" paddingTop="space20">
        <Button
          onClick={onPrevStep}
          variant="secondary"
          disabled={isApplyForCreditCardsPending}
        >
          {t('modal.form.requesterInfoForm.ctaPrevButton')}
        </Button>
        <Button
          onClick={handleSubmit}
          loading={isApplyForCreditCardsPending}
          disabled={isApplyForCreditCardsPending}
        >
          {t('modal.form.requesterInfoForm.ctaNextButton')}
        </Button>
      </Flex>
    </Grid>
  );
};
