import { Tooltip, useTooltip } from '@candisio/design-system';
import { HookFormPercentField } from 'components/HookFormFields/HookFormPercentField';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const TaxPercentageField = ({ disabled }: { disabled: boolean }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TAX_CODES);
  const { isActiveIntegration: shouldUseSapTaxCodes } = useSap();

  // Should be moved to DS
  const { isOpen, triggerRef, triggerProps, tooltipRef, tooltipProps } =
    useTooltip({
      placement: 'top',
      isDisabled: !shouldUseSapTaxCodes,
    });

  const isReadOnly = shouldUseSapTaxCodes || disabled;

  return (
    <>
      <div
        ref={triggerRef}
        {...(shouldUseSapTaxCodes ? triggerProps : {})}
        data-cy="taxPercentageTooltipTrigger"
      >
        <HookFormPercentField
          autoFocus={!isReadOnly}
          name="taxPercentage"
          placeholder={t('form.taxPercentage.placeholder')}
          label={t('form.taxPercentage.label')}
          readOnly={isReadOnly}
        />
      </div>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {t('form.readOnlySyncHint')}
        </Tooltip>
      )}
    </>
  );
};
