import { Button, Heading } from '@candisio/design-system';
import { getMemberships } from 'components/Comment/gql';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useActivateMembershipMutation } from 'generated-types/graphql.types';
import { membershipsQueryFilter } from 'hooks/useUsersFieldOptions';
import { useTeams } from 'orgConfig/teams/useTeams';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import useRouter from 'use-react-router';
import { LocalMembershipStatus } from 'views/Settings/TeamMembers/UsersListView';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import {
  getApproversQuery,
  organizationMembershipCountByStatusQuery,
} from 'views/Settings/TeamMembers/queries';
import { generateTeamMembersPath, getUserInfo } from '../../../utils';
import { ToastMessage } from '../components/ToastMessage';
import { InactiveTeamMemberForm } from './InactiveTeamMemberForm';

type Props = {
  closeDrawer: () => void;
  loading?: boolean;
  selectedUser: User;
};

export const InactiveFormContainer = ({
  closeDrawer,
  loading,
  selectedUser,
}: Props) => {
  const { isTeamsFeatureEnabled } = useTeams();

  const {
    history,
    match: {
      url,
      path,
      params: { organizationSlug },
    },
  } = useRouter<{ organizationSlug: string }>();

  const [t] = useTranslation();
  const { error, info } = useToastMessage();

  const [activateMembership, { loading: isSubmittingActivateMembership }] =
    useActivateMembershipMutation();

  const onActivateMembership = useCallback(async () => {
    try {
      if (!selectedUser) {
        return;
      }

      const { data } = await activateMembership({
        variables: { membershipId: selectedUser.id },
        refetchQueries: [
          'organizationMemberships',
          { query: organizationMembershipCountByStatusQuery },
          {
            query: getApproversQuery,
            variables: { name: '' },
          },
          {
            query: getMemberships,
            variables: membershipsQueryFilter.Active,
          },
        ],
      });

      const result = data?.activateMembership;
      const submitError = result === false;

      if (!submitError) {
        const nameAndEmail = getUserInfo(selectedUser);
        const pathname = generateTeamMembersPath({
          organizationSlug,
          path,
          url,
          userId: selectedUser.id,
        });

        const content = (
          <ToastMessage
            history={history}
            nameAndEmail={nameAndEmail}
            pathname={pathname}
            status={LocalMembershipStatus.ACTIVE}
            translationKey="settings.teamMembers.actions.activatedMembership"
          />
        );

        info(content);

        closeDrawer();
      }
    } catch (e) {
      error(t(`${(e as Error).message}`));
    }
  }, [
    selectedUser,
    activateMembership,
    organizationSlug,
    path,
    url,
    history,
    info,
    closeDrawer,
    error,
    t,
  ]);

  return (
    <DrawerLayout
      onClose={closeDrawer}
      header={
        <Heading as="h3">
          {isTeamsFeatureEnabled
            ? t('settings.users.details.tabs.edit')
            : t('settings.teamMembers.details.tabs.edit')}
        </Heading>
      }
      footer={
        <Button type="submit" loading={loading} disabled>
          {t('settings.teamMembers.form.actions.update')}
        </Button>
      }
    >
      <InactiveTeamMemberForm
        isSubmittingActivateMembership={isSubmittingActivateMembership}
        onActivateMembership={onActivateMembership}
        selectedUser={selectedUser}
      />
    </DrawerLayout>
  );
};
