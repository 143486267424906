import { Box, Grid, Skeleton } from '@candisio/design-system';
import { SETTINGS_CARDS_MAX_WIDTH } from 'views/Settings/Integrations/DATEV/consts';

export const DcsSettingsSkeleton = () => (
  <Grid gap="space36">
    <DcsCardSkeleton />
    <DcsCardSkeleton />
    <DcsCardSkeleton />
    <DcsCardSkeleton />
  </Grid>
);

const DcsCardSkeleton = () => {
  return (
    <Grid
      gap="space16"
      templateColumns="auto 1fr"
      alignItems="center"
      maxWidth={SETTINGS_CARDS_MAX_WIDTH}
    >
      <Grid placeContent="center" minWidth="space128">
        <Box
          background="white"
          width="6rem"
          height="space128"
          borderRadius="small"
        />
      </Grid>

      <Grid
        gap="space36"
        templateColumns="1fr auto"
        background="white"
        height="11rem"
        padding="space24"
      >
        <Grid gap="space8" alignContent="start">
          <Skeleton width="12.5rem" height="space16" borderRadius="small" />
          <Skeleton width="12.5rem" height="space16" borderRadius="small" />
        </Grid>
        <Skeleton width="space48" height="space24" borderRadius="massive" />
      </Grid>
    </Grid>
  );
};
