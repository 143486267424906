import {
  EcmDocumentStatus,
  useGetInboxOtherDocumentsQuery,
} from 'generated-types/graphql.types';
import { OTHER_DOCUMENT_TYPES } from './useInboxOtherDocumentsData';

export const useInboxOtherDocumentsCount = ({ skip }: { skip: boolean }) => {
  const { data } = useGetInboxOtherDocumentsQuery({
    variables: {
      filter: {
        documentStatus: [EcmDocumentStatus.New],
        documentType: OTHER_DOCUMENT_TYPES,
      },
    },
    skip,
  });

  return {
    count: data?.getEcmDocuments.pageInfo.totalCount,
  };
};
