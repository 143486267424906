import { CSSObject } from '@emotion/react';
import React from 'react';
import { useFocusVisible } from 'react-aria';
import { useTheme } from '../../Theme';
import { ColorProps, LayoutProps, StandardHTMLAttributes } from '../../types';
import { Flex } from '../Flex';

export interface AvatarCircleProps
  extends LayoutProps,
    ColorProps,
    StandardHTMLAttributes<HTMLDivElement> {
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  img?: string;
  children?: React.ReactNode;
}

/**
 * The AvatarCircle component is used to create different Avatar variations.
 */
export const AvatarCircle = React.forwardRef<HTMLDivElement, AvatarCircleProps>(
  (
    { size = 'medium', img, background, color, children, ...restProps },
    forwardedRef
  ) => {
    const { avatar, space } = useTheme();

    const { isFocusVisible } = useFocusVisible();

    const sizesMap: Record<string, string> = {
      xsmall: space.space18,
      small: space.space24,
      medium: space.space32,
      large: space.space80,
    };

    const fontSizeRatio = size === 'xsmall' ? 0.5 : 0.4;

    const baseStyle: CSSObject = {
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: `calc(${sizesMap[size]} * ${fontSizeRatio})`,
      letterSpacing: '0.01rem',
      borderRadius: '50%',
      height: sizesMap[size],
      width: sizesMap[size],
      backgroundImage: img && `url(${img})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      '&:focus': {
        outline: 'none',
      },
    };

    const focusStyle: CSSObject = {
      '&:focus': avatar.focus,
    };

    return (
      <Flex
        css={[baseStyle, isFocusVisible && focusStyle]}
        background={background ?? 'gray200'}
        color={color ?? 'gray0'}
        {...restProps}
        ref={forwardedRef}
      >
        {children}
      </Flex>
    );
  }
);
