import { Grid, Skeleton, Flex } from '@candisio/design-system';
import { AcceptedValuesDS } from 'components/Form/SplitBookingsForm/types';
import { Actions } from 'containers/SplitBookings/components/Actions/Actions';
import { SplitBookingsLayout } from 'containers/SplitBookings/components/SplitBookingsLayout';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

export const Loading = ({
  bookingCount,
  readOnly,
  onAcceptBookings,
  onDiscardBookings,
  openedIndex,
}: {
  openedIndex: number;
  bookingCount: number;
  readOnly: boolean;
  onDiscardBookings?: () => void;
  onAcceptBookings?: (values: AcceptedValuesDS) => void;
}) => {
  const [t] = useTranslation('split-bookings');

  const SummaryRowLoading = () => <Skeleton height="40px" width="100%" />;
  const FormLoading = () => (
    <Flex alignItems="center" direction="column" gap="0.1rem" width="100%">
      <Skeleton height="50px" width="95%" />
      <Skeleton height="50px" width="95%" />
      <Skeleton height="50px" width="95%" />
      <Skeleton height="50px" width="95%" />
      <Skeleton height="50px" width="95%" />
      <Skeleton height="50px" width="95%" />
    </Flex>
  );

  return (
    <SplitBookingsLayout
      title={t('title')}
      header={<Skeleton height="60px" width="100%" right={0} />}
    >
      <Grid
        alignContent="space-between"
        gap="space16"
        height="max-content"
        minHeight="100%"
      >
        <Flex direction="column" gap="0.1rem">
          {bookingCount === 1 ? (
            <FormLoading />
          ) : (
            Array.from({ length: bookingCount }, (_, index) =>
              index === openedIndex ? (
                <Flex key={index} direction="column" gap="0.1rem">
                  <SummaryRowLoading /> <FormLoading />
                </Flex>
              ) : (
                <SummaryRowLoading key={index} />
              )
            )
          )}
        </Flex>
        {onAcceptBookings && (
          <Actions
            readOnly={readOnly}
            onDiscardBookings={onDiscardBookings}
            onAcceptBookings={noop}
            onAddNewSplit={noop}
            isLoading
          />
        )}
      </Grid>
    </SplitBookingsLayout>
  );
};
