import { Grid, ScrollBox } from '@candisio/design-system';
import { ListNavigator } from 'components/ListNavigator/ListNavigator';
import { TransactionDetailsCardContextMenu } from 'components/Transactions/TransactionDetailsCard/TransactionDetailsCardContextMenu';
import { dataToTransactionDetails } from 'components/Transactions/TransactionDetailsCard/utils';
import {
  DocumentHistoryComponent,
  DocumentSummarySection,
} from 'containers/document-summary/DocumentSummarySection';
import { useTranslation } from 'react-i18next';
import { PurchaseOrderSectionContainer } from 'views/DocumentDetails/components/PurchaseOrderSection/PurchaseOrderSectionContainer';
import { TransactionSection } from 'views/DocumentDetails/components/TransactionSection';
import { useInboxContext } from '../Context';
import { DocumentSummaryInformationAdapter } from './components/DocumentSummaryInformation';
import {
  LeftSectionOuterWrapper,
  LeftSectionContentWrapper,
} from 'components/Layouts/styles';

export const LeftSection = () => {
  const [t] = useTranslation();

  const {
    globalDocumentId,
    documentId,
    mainDocumentFile,
    documentStatus,
    documentAmount,
    contactItem,
    contactName,
    transaction,
    accountsPayableNumber,
    unlinkTransaction,
    isUnlinkPending,
    showTransactionSection,
    useDocumentNavigationResult: {
      listNavigatorTitle,
      gotoNextDocument,
      gotoPrevDocument,
      handleBackToList,
      isNavigationLoading,
    },
    isDocumentDataLoading,
    canUseStoredContactInfo,
    showPurchaseOrderSection,
    hasContextMenu,
    canAddTransaction,
  } = useInboxContext();

  return (
    <LeftSectionOuterWrapper>
      <ListNavigator
        backToListText={t('document.backToDocumentList')}
        arrowLeftTooltip={t('document.previousDocument')}
        arrowRightTooltip={t('document.nextDocument')}
        listNavigatorCounter={listNavigatorTitle}
        onBackToList={handleBackToList}
        onNext={gotoNextDocument}
        onPrev={gotoPrevDocument}
        loading={isNavigationLoading}
      />
      <LeftSectionContentWrapper>
        {/*
        // cursed: Windows Chrome renders scrollbars outside component's border
        // we add static ScrollBox to reserve same gutter space as for
        // scrollable DocumentSummarySection 
        */}
        <ScrollBox scrollDirection="none" scrollbarGutter="stable">
          <DocumentSummaryInformationAdapter />
        </ScrollBox>
        <ScrollBox scrollDirection="y" scrollbarGutter="stable">
          <Grid gap="space8">
            {showPurchaseOrderSection && (
              <PurchaseOrderSectionContainer
                loading={isDocumentDataLoading}
                documentId={documentId}
                documentStatus={documentStatus}
                documentAmount={documentAmount}
                contactName={
                  canUseStoredContactInfo ? contactName : contactItem?.children
                }
                accountsPayableNumber={
                  canUseStoredContactInfo
                    ? accountsPayableNumber
                    : contactItem?.accountsPayableNumber
                }
              />
            )}
            {showTransactionSection && (
              <TransactionSection
                loading={isDocumentDataLoading}
                documentId={documentId}
                transaction={dataToTransactionDetails(transaction)}
                canAddTransaction={canAddTransaction}
                contextMenu={
                  hasContextMenu &&
                  documentStatus && (
                    <TransactionDetailsCardContextMenu
                      documentStatus={documentStatus}
                      onUnlinkTransaction={unlinkTransaction}
                      isUnlinkPending={isUnlinkPending}
                    />
                  )
                }
              />
            )}
            <DocumentSummarySection
              globalDocumentId={globalDocumentId}
              documentId={documentId}
              documentName={mainDocumentFile.name}
              documentStatus={documentStatus}
              canAddTransaction={canAddTransaction}
            />
            <DocumentHistoryComponent documentId={documentId} />
          </Grid>
        </ScrollBox>
      </LeftSectionContentWrapper>
    </LeftSectionOuterWrapper>
  );
};
