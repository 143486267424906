import { Flex, Heading, Link, Paragraph } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export const SessionActionCardHeader = () => {
  const [t] = useTranslation();

  return (
    <Flex direction="column" gap="space16" fontSize="large">
      <Flex justifyContent="space-between" maxWidth="672px">
        <Heading as="h3">{t('settings:apiSettings.actionCard.title')}</Heading>
        <Link
          as="button"
          id="candis-api-feedback-button"
          icon="megaphone"
          fontSize="small"
          width="fit-content"
          color="blue"
        >
          {t('common:common.feedback')}
        </Link>
      </Flex>
      <Paragraph fontSize="basic">
        {t('settings:apiSettings.actionCard.description')}
      </Paragraph>
    </Flex>
  );
};
