import { defaultTheme } from '@candisio/design-system';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { HEADER_HEIGHT } from 'styles/GlobalStyles';
import { hoverScrollbar } from 'styles/scrollbar';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

export const DuplicateLayout = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${defaultTheme.colors.gray200};
`;

export const DuplicateRowLayout = styled.section`
  display: flex;
  flex-direction: row;
`;

export const DocumentLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  overflow: auto;
  height: calc(100vh - ${HEADER_HEIGHT} - 40px);
  ${hoverScrollbar}
`;

export const DocumentViewerStyled = styled(DocumentViewer)`
  flex: 1;
`;
