// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';

const {
  REACT_APP_DATEV_SECURE_HOST: datevSecureHost,
  REACT_APP_DATEV_DEFAULT_HOST: datevDefaultHost,
} = import.meta.env || {};

export const buildDatevProfileLink = ({
  consultantNumber,
  clientNumber,
}: {
  consultantNumber?: string | null;
  clientNumber?: string | null;
}) => {
  if (clientNumber && clientNumber) {
    const query = qs.stringify({
      mid: 5250,
      GET_MENU: 1114,
      startBNR: consultantNumber,
      startMNR: clientNumber,
    });

    return `${datevSecureHost}/wopl/FC/FC?${query}`;
  }

  return datevDefaultHost;
};
