export type SvgProps = {
  className?: string;
  width?: string;
  height?: string;
  viewBox?: string;
  fill?: string;
  stroke?: string;
  style?: object;
  children?: any;
};

/**
 * @deprecated please use Icon from design system instead.
 * @see https://candisio.github.io/design-system/
 */
export const SvgIcon = (props: SvgProps) => {
  const {
    width = '24',
    height = '24',
    viewBox = '0 0 24 24',
    fill = '#333',
    children,
    ...otherProps
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      {children}
    </svg>
  );
};
