import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useCurrentUser } from 'providers/CurrentUserProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { useApprovalDocumentsData } from 'views/Approvals/hooks/useApprovalDocumentsData';
import { availableFilters } from 'views/Archive/ArchiveDocumentsTable/consts';

export const useShouldShowMobileAppPromotion = () => {
  const { filters: gqlFilters } = useUrlBasedSortAndFilter({
    availableFilters,
  });

  const currentUser = useCurrentUser();
  const currentUserId = currentUser?.id;

  const isAllDone = Boolean(qs.parse(window.location.search).allDone);

  const { isTableEmpty } = useApprovalDocumentsData();

  const [isPersonalFilterActive, setIsPersonalFilterActive] = useState(false);

  useEffect(() => {
    const isOnlyOneFilterApplied = gqlFilters.length === 1;
    if (!isOnlyOneFilterApplied || !currentUserId) {
      setIsPersonalFilterActive(false);

      return;
    }

    const approverFilter = gqlFilters.find(filter => filter.id === 'approvers');
    const requesterFilter = gqlFilters.find(
      filter => filter.id === 'requester'
    );

    const isAssignedToMe = Boolean(
      approverFilter &&
        approverFilter.value &&
        (approverFilter.value as string[]).includes(currentUserId)
    );

    const isRequestedByMe = Boolean(
      requesterFilter &&
        requesterFilter.value &&
        (requesterFilter.value as string[]).includes(currentUserId)
    );

    const personalFilterActive = isAssignedToMe || isRequestedByMe;

    setIsPersonalFilterActive(personalFilterActive);
  }, [gqlFilters, currentUserId]);

  const shouldShowMobileAppPromotion =
    isTableEmpty && (isPersonalFilterActive || isAllDone);

  return { shouldShowMobileAppPromotion };
};
