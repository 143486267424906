import { HTMLAttributes, ReactNode } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';
import { Collapse } from './Collapse';
import { HistoryItem } from './HistoryItem/HistoryItem';

interface ItemProps extends HTMLAttributes<HTMLDivElement> {
  isCollapsible?: boolean;
  opened?: string;
  dot?: ReactNode;
}

export const StyledHistoryItem = styled(({ ...rest }: ItemProps) => (
  <HistoryItem {...rest} />
))`
  width: 100%;

  ${p =>
    p.isCollapsible &&
    `
      cursor: pointer;
      ${Collapse} {
        display: block;
        max-height: ${
          p.opened ? '100rem' : '0'
        }; /* css animations require a fixed value for max-height */
        overflow-y: hidden;
        transition: max-height .5s ${
          p.opened ? 'ease-in-out' : 'cubic-bezier(0, 1, 0, 1)'
        };
        &.inverted {
          max-height: ${p.opened ? '0' : '100rem'};
          transition: max-height .5s ${
            p.opened ? 'cubic-bezier(0, 1, 0, 1)' : 'ease-in-out'
          };
        }
      }
    `}
`;
