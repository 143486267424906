import {
  EmptyDataS,
  EmptyDataText,
  EmptyDataWrapper,
} from 'components/EmptyViews/styles';
import { LottieAnimation, noresultsData } from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';

export const NoFilteredData = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TAX_CODES);
  const { push } = useHistory();

  return (
    <EmptyDataWrapper>
      <LottieAnimation options={{ animationData: noresultsData }} />
      <EmptyDataText>{t('table.nodataWithFilter.title')}</EmptyDataText>
      <EmptyDataText>
        <EmptyDataS
          onClick={e => {
            e.preventDefault();
            push({ search: '' });
          }}
        >
          {t('table.nodataWithFilter.pleaseResetCTA')}
        </EmptyDataS>
      </EmptyDataText>
    </EmptyDataWrapper>
  );
};
