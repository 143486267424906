import { Grid, Link, Text } from '@candisio/design-system';
import { EmailInboxMessageAttachment } from 'generated-types/graphql.types';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { AttachmentsList } from '../AttachmentsList/AttachmentsList';

type MailItemProps =
  | {
      variant: 'children';
      label: string;
      children: ReactNode;
    }
  | {
      variant: 'copy';
      label: string;
      copy: string;
    }
  | {
      variant: 'link';
      label: string;
      link: string;
    };

const MailItem = (props: MailItemProps) => {
  const [t] = useTranslation();
  const { label, variant } = props;

  return (
    <Grid>
      <Text color="gray600" fontSize="small">
        {t(label)}
      </Text>
      {variant === 'children' && props.children}
      {variant === 'copy' && (
        <Text color="gray800" fontSize="basic">
          {props.copy}
        </Text>
      )}
      {variant === 'link' && (
        <Link download fontSize="basic" href={props.link} external>
          {t('settings.mailSync.details.tabs.metadata.actions.download')}
        </Link>
      )}
    </Grid>
  );
};

type MailMetadataProps = {
  subject?: string | null;
  from?: string | null;
  to?: string | null;
  receivedAt?: Date | string | null;
  htmlLink?: string | null;
  textLink?: string | null;
  attachments?: EmailInboxMessageAttachment[] | null;
  isLoading: boolean;
};

export const MailMetadata = ({
  subject,
  receivedAt,
  to,
  from,
  htmlLink,
  textLink,
  attachments,
  isLoading,
}: MailMetadataProps) => (
  <Grid gap="space16">
    {subject && (
      <MailItem
        label="settings.mailSync.details.tabs.metadata.subject"
        variant="children"
      >
        <Text fontSize="basic" fontWeight="bold">
          {subject}
        </Text>
      </MailItem>
    )}
    {receivedAt && (
      <MailItem
        label="settings.mailSync.details.tabs.metadata.date"
        copy={DateFormatters.compactDatetime(new Date(receivedAt))}
        variant="copy"
      />
    )}
    {to && (
      <MailItem
        label="settings.mailSync.details.tabs.metadata.recipient"
        variant="copy"
        copy={to}
      />
    )}
    {from && (
      <MailItem
        label="settings.mailSync.details.tabs.metadata.sender"
        copy={from}
        variant="copy"
      />
    )}
    {htmlLink && (
      <MailItem
        label="settings.mailSync.details.tabs.metadata.html"
        variant="link"
        link={htmlLink}
      />
    )}
    {textLink && (
      <MailItem
        label="settings.mailSync.details.tabs.metadata.text"
        link={textLink}
        variant="link"
      />
    )}
    {attachments && (
      <MailItem
        label="settings.mailSync.details.tabs.metadata.attachments.title"
        variant="children"
      >
        <AttachmentsList attachments={attachments} isLoading={isLoading} />
      </MailItem>
    )}
  </Grid>
);
