import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import {
  defaultSortByFields,
  EcmDocumentsTable,
  EcmDocumentsTableProps,
} from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { DocumentPreviewDrawer } from 'containers/DocumentPreview/DocumentPreviewDrawer';
import { DocumentTableRowActionsOverlay } from 'containers/DocumentPreview/DocumentTableRowActionsOverlay';
import { ACTIVATION_LOCAL_STORAGE_KEY } from 'containers/DocumentPreview/useDocumentPreviewActivationPopover';
import { DocumentPreviewUpsellDrawer } from 'containers/DocumentPreviewUpsell/DocumentPreviewUpsellDrawer';
import { usePreviewUpsellSeen } from 'containers/DocumentPreviewUpsell/usePreviewUpsellSeen';
import { useEcmDocumentsDataWithExpenses } from 'containers/document-relationships/useEcmDocumentsDataWithExpenses';
import { DocumentType } from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { AppRouteParams, Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider/feature-flag-names';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps } from 'react-router-dom';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { TabView } from 'views/Inbox/models';
import { isEcmDocumentId } from 'views/utils/isEcmDocumentId';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';
import { DocumentPreviewEventLocations } from 'providers/AnalyticsProvider/events';
import { DocumentPreviewForExpenseContainer } from 'containers/DocumentPreviewForExpense/DocumentPreviewForExpenseContainer';

type Props = RouteComponentProps<{ organizationSlug: string }>;

export const EcmDocuments = ({
  match: {
    params: { organizationSlug },
  },
}: Props) => {
  const [tableRowLinkFF, consistentSortAndFilteringFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.tableRowLink,
    FEATURE_FLAGS.consistentSortAndFiltering,
  ]);
  const {
    showDocumentPreview,
    showDocumentPreviewPromo,
    showDocumentTags: shouldUseNewEs,
  } = useEcm();
  const showPreview = showDocumentPreview || showDocumentPreviewPromo;
  const { previewUpsellSeen, markPreviewUpsellSeen } = usePreviewUpsellSeen();

  const [wasActivationSeen] = useLocalStorage(
    ACTIVATION_LOCAL_STORAGE_KEY,
    false
  );

  const isAutoOpened = useRef(wasActivationSeen);

  const [previewDocument, setPreviewDocument] = useState<
    { id: string; type: string | undefined } | undefined
  >(undefined);

  const previewDocumentId = previewDocument?.id;

  const navigate = useNavigate();

  const {
    availableDocumentColumnIds,
    searchQuery,
    searchParams,
    data,
    isLoading,
    filters,
    isTableFiltered,
    configurationsTable,
    totalCount,
    isSavingConfigurations,
    isResetPending,
    customEmptyState,
    sortBy,
    handleSearchChange,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    onFilter,
    onLoadMore,
    onSort,
  } = useEcmDocumentsDataWithExpenses({
    filterParamsSource: 'url',
    previewDocumentId,
  });

  const handleRowClick = (
    documentId: string,
    documentType: DocumentType,
    cursor?: string
  ) => {
    if (tableRowLinkFF) return;

    const path = getPath({
      id: documentId,
      globalDocumentId: documentId,
      documentType,
      cursor,
    });

    navigate(path);
  };

  const getTableRowDocumentType = useCallback(
    (documentId: string) => {
      const tableRow = data.find(
        row => row.id === documentId || row.invoiceId === documentId
      );

      if (
        tableRow === undefined ||
        tableRow.documentType === DocumentType.Invoice
      ) {
        return 'invoice';
      }

      return 'ecmDocument';
    },
    [data]
  );

  const isDocumentPreviewReady =
    !isLoading && previewDocumentId === undefined && !!data[0]?.id;

  useEffect(() => {
    if (!isDocumentPreviewReady) return;

    const shouldAutoOpenPreview =
      (showDocumentPreviewPromo && !previewUpsellSeen) ||
      (!showDocumentPreviewPromo && !isAutoOpened.current);

    if (!shouldAutoOpenPreview) return;

    setPreviewDocument({ id: data?.[0].id, type: data?.[0].documentType });
    if (!showDocumentPreviewPromo) isAutoOpened.current = true;
  }, [
    data,
    isDocumentPreviewReady,
    previewUpsellSeen,
    showDocumentPreviewPromo,
  ]);

  const documentPreviewRowOverlay: EcmDocumentsTableProps['rowOverlay'] =
    useMemo(
      () =>
        showPreview
          ? ({ data }) => {
              if (data.selected) return <></>; // TODO: Fix DS type to allow to return null

              return (
                <DocumentTableRowActionsOverlay
                  data={data}
                  onQuickViewClick={data => {
                    const isEcmDocument =
                      getTableRowDocumentType(data.id) === 'ecmDocument';

                    setPreviewDocument({
                      id: isEcmDocument ? data.id : (data.invoiceId ?? ''),
                      type: data.documentType,
                    });
                  }}
                  isSelected={
                    data.id === previewDocumentId ||
                    data.invoiceId === previewDocumentId
                  }
                />
              );
            }
          : undefined,
      [showPreview, getTableRowDocumentType, previewDocumentId]
    );

  const openDocument = useCallback(
    ({
      documentId,
      cursor,
      documentType,
    }: {
      documentId: string | null;
      cursor: string | null;
      documentType: string | undefined;
    }): void => {
      if (!documentId) return;

      const params = new URLSearchParams();
      if (cursor) params.set('cursor', cursor);
      // This should go into the reimbursement view with the reimbursementId
      if (documentType === 'EXPENSE_REIMBURSEMENT_ITEM') return;
      if (isEcmDocumentId(documentId)) params.set('isInvoice', 'true');

      const path = `/${organizationSlug}${Routes.ECM_DOCUMENTS}/${documentId}?${params}`;

      window.open(path, '_blank');
    },
    [organizationSlug]
  );

  const closePreview = useCallback(() => {
    setPreviewDocument(undefined);
    if (!previewUpsellSeen) {
      markPreviewUpsellSeen();
    }
  }, [markPreviewUpsellSeen, previewUpsellSeen]);

  const cursor =
    data.find(
      d => d.id === previewDocumentId || d.invoiceId === previewDocumentId
    )?.cursor ?? null;

  const documentPreviewDrawer = useMemo(() => {
    if (showDocumentPreviewPromo) {
      return (
        <DocumentPreviewUpsellDrawer
          isOpen={!!previewDocumentId}
          closePreviewDrawer={closePreview}
        />
      );
    }

    if (showDocumentPreview) {
      const isExpenseReimbursementItem =
        previewDocument?.type === 'EXPENSE_REIMBURSEMENT_ITEM';
      return (
        <DocumentPreviewDrawer
          documentPreviewEventLocation={DocumentPreviewEventLocations.ARCHIVE}
          closePreviewDrawer={closePreview}
          documentPreviewView={
            isExpenseReimbursementItem
              ? DocumentPreviewForExpenseContainer
              : undefined
          }
          openDocument={() =>
            openDocument({
              documentId: previewDocumentId ?? null,
              cursor,
              documentType: previewDocument?.type,
            })
          }
          documentId={previewDocumentId ?? null}
        />
      );
    }

    return null;
  }, [
    showDocumentPreviewPromo,
    showDocumentPreview,
    previewDocumentId,
    previewDocument,
    closePreview,
    openDocument,
    cursor,
  ]);

  const getPath = useCallback(
    ({ id, cursor, documentType, invoiceId }: EcmDocumentsTableData) => {
      if (!organizationSlug) return '';

      const isInvoice = documentType === DocumentType.Invoice;

      const documentId = invoiceId && isInvoice ? invoiceId : id;

      const pathname = generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.ECM_DOCUMENTS}/:documentId`,
        { organizationSlug, documentId }
      );

      if (isInvoice) {
        searchParams.set('isInvoice', 'true');
      } else {
        searchParams.delete('isInvoice');
      }

      if (cursor) searchParams.set('cursor', cursor);

      return { pathname, search: searchParams.toString() };
    },
    [organizationSlug, searchParams]
  );

  const cellWrapper = useMemo(() => {
    if (!tableRowLinkFF) return;

    return getCellWrapperLink(getPath);
  }, [tableRowLinkFF, getPath]);

  const cellStyle = tableRowLinkFF ? () => ({ padding: 'unset' }) : undefined;

  const sortByFields: (keyof EcmDocumentsTableData)[] =
    consistentSortAndFilteringFF && shouldUseNewEs
      ? [...defaultSortByFields, 'contact', 'documentNumber', 'documentName']
      : defaultSortByFields;

  return (
    <ArchiveViewLayout
      activeTab={TabView.ARCHIVE_ECM_DOCUMENTS}
      rightSide={documentPreviewDrawer}
    >
      <EcmDocumentsTable
        context="documents"
        columns={availableDocumentColumnIds}
        data={data}
        isLoading={isLoading}
        defaultFilters={filters}
        isTableFiltered={isTableFiltered}
        configurationsTable={configurationsTable}
        searchQuery={searchQuery}
        selectedDocumentsCount={totalCount}
        defaultSortBy={sortBy}
        isLoadingConfigurations={isSavingConfigurations || isResetPending}
        onRowClick={handleRowClick}
        onSearchChange={handleSearchChange}
        onUpdateConfigurations={handleUpdateConfigurations}
        onFilter={onFilter}
        onEndReached={onLoadMore}
        onSort={onSort}
        customEmptyState={customEmptyState}
        onResetTableConfigurations={handleResetTableConfigurations}
        rowOverlay={documentPreviewRowOverlay}
        cellWrapper={cellWrapper}
        getCellStyles={cellStyle}
        sortByFields={sortByFields}
      />
    </ArchiveViewLayout>
  );
};
