import { Flex, Grid, Link } from '@candisio/design-system';

import { Refresh } from 'components/Icons/Refresh';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useImportDatevCoreDataMutation } from 'generated-types/graphql.types';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { refetchDatevData } from '../../../gql';
import { ExportConfigurationFields } from './ExportConfigurationFields';

export interface AccountingCoreDataContainerProps {
  hideConfigurationFields?: boolean;
}

export const AccountingCoreDataContainer = ({
  hideConfigurationFields,
}: AccountingCoreDataContainerProps) => {
  const [t] = useTranslation();
  const { success } = useToastMessage();

  const [isReloadingCoreData, setIsReloadingCoreData] = useState(false);

  const [importDatevCoreData] = useImportDatevCoreDataMutation();

  const reloadCoreData = useCallback(async () => {
    setIsReloadingCoreData(true);
    await importDatevCoreData({
      refetchQueries: [
        { query: getFullOrganizationQuery },
        ...refetchDatevData,
      ],
    });

    setTimeout(() => {
      setIsReloadingCoreData(false);
      success(
        t('settings.datev.connect.connectedClient.refreshCoreData.success')
      );
    }, 2000);
  }, [importDatevCoreData, success, t]);

  return (
    <Grid gap="space16" width="fit-content" justifyItems="left">
      {!hideConfigurationFields && (
        <Grid
          width="100%"
          gap="space24"
          paddingRight="space40"
          templateColumns="repeat(3, 1fr)"
        >
          <ExportConfigurationFields />
        </Grid>
      )}

      <Link
        external
        href={t(
          'settings.datev.connect.connectedClient.updateCoreDataInDuo.url'
        )}
      >
        {t('settings.datev.connect.connectedClient.updateCoreDataInDuo.text')}
      </Link>
      <Grid gap="space24" templateColumns="repeat(2, 1fr)">
        {!hideConfigurationFields && (
          <Flex gap="space4" alignItems="center">
            <Link
              as="button"
              onClick={() => {
                if (!isReloadingCoreData) {
                  void reloadCoreData();
                }
              }}
            >
              {t('settings.datev.connect.connectedClient.refreshCoreData.text')}
            </Link>
            <Refresh
              style={{ marginLeft: '0.1rem' }}
              animated={isReloadingCoreData}
            />
          </Flex>
        )}
      </Grid>
    </Grid>
  );
};
