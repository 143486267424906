import { EmailInboxMessageAttachment } from 'generated-types/graphql.types';

export const isEmailAttachmentEInvoice = (
  attachments?: EmailInboxMessageAttachment[] | null
): boolean => {
  return Boolean(
    attachments?.some(attachment => {
      return (
        attachment?.__typename === 'EmailInboxMessageAttachmentWithDocument' &&
        attachment.isEInvoice
      );
    })
  );
};
