import { Drawer, Button, Text, Grid, useTheme } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface StatementDetailsDrawerLayoutProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const StatementDetailsDrawerLayout = ({
  isOpen,
  onClose,
  children,
}: StatementDetailsDrawerLayoutProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { colors } = useTheme();

  return (
    <Drawer onClose={onClose} isOpen={isOpen} width="37rem">
      <Grid templateRows="auto 1fr" height="100%">
        <Grid
          autoFlow="column"
          placeItems="center"
          placeContent="space-between"
          padding="space16 space40"
          borderBottom={`1px solid ${colors.gray300}`}
        >
          <Text fontSize="xlarge">{t('statements.detail.title')}</Text>
          <Button
            icon="close"
            variant="tertiary"
            label={t('drawer.close')}
            onClick={onClose}
          />
        </Grid>
        {children}
      </Grid>
    </Drawer>
  );
};
