import { Box } from '@candisio/design-system';
import { escapeRegex } from 'utils/regex';

export const renderQueryHighlight = ({
  value = '',
  queryString,
}: {
  value?: string;
  queryString: string;
}) => {
  // splitting string by '' makes it an array of chars which is fruitless for the case;
  if (queryString === '' || value === '') return value;

  const querySlices = value.split(
    new RegExp(`(${escapeRegex(queryString)})`, 'gi')
  );

  return querySlices.map((slice, i) => (
    <Box
      as="span"
      background={
        slice.toLowerCase() === queryString.toLowerCase()
          ? 'yellow400'
          : undefined
      }
      key={i}
    >
      {slice}
    </Box>
  ));
};
