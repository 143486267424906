import {
  Avatar,
  Flex,
  Grid,
  Tooltip,
  useTooltip,
  TruncatedText,
} from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { useState, MouseEvent } from 'react';
import { useNumberFormat } from 'utils/useNumberFormat';
import { Vendor } from 'views/CreditCards/hooks/useVendorSuggestionsData';
import { formatFromCents } from 'views/CreditCards/utils/formatFromCents';
import { LinkCardToVendorAction } from './LinkCardToVendorAction';
import { RemoveVendorItem } from './RemoveVendorItem';
import { VendorType, useVendorText } from './utils';

const amazonVendors = ['AWS', 'Amazon Marketplace'];
const speciaVendors = [
  'Google Ads',
  'Google Cloud',
  'Google GSuite',
  'Google Workspace',
  'Amazon Business',
];

interface VendorItemProps {
  details: Vendor['details'];
  aggregate: Vendor['aggregate']['value'];
  removeVendor: {
    onClick: () => Promise<void>;
    loading: boolean;
  };
  onButtonClick: () => void;
  onRowClick: (contactIds: string[]) => void;
}

export const VendorItem = ({
  details,
  aggregate,
  removeVendor,
  onButtonClick,
  onRowClick,
}: VendorItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const { isCardManager } = useUserRoles();

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  const amount = formatFromCents(aggregate.currency, aggregate.amount) ?? 0;

  const estimatedAmount = useNumberFormat({
    notation: 'compact',
    style: 'currency',
    useGrouping: true,
    currency: aggregate.currency,
  }).format(amount);

  const { mapVendorToText } = useVendorText({
    vendorName: details.vendorName,
    estimatedAmount,
  });

  const isAmazonVendor = amazonVendors.includes(details.vendorName);
  const isSpecialVendor = speciaVendors.includes(details.vendorName);

  const vendor: VendorType = isAmazonVendor
    ? 'amazon'
    : isSpecialVendor
      ? 'specialVendors'
      : 'otherVendors';

  const { icon, invoiceAmount, tooltip } = mapVendorToText[vendor];

  return (
    <Grid
      height="60px"
      transition="0.5"
      padding="space8 space16"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onRowClick(details.contactIds);
      }}
      alignItems="center"
      templateColumns="1.9fr 1fr"
      borderBottom="1px solid gray200"
      style={{ cursor: isHovered ? 'pointer' : 'default' }}
    >
      <Grid
        autoFlow="column"
        alignItems="center"
        justifyContent="start"
        gap="space64"
      >
        <Flex alignItems="center" gap="space10">
          <Avatar
            img={details.logoUrl}
            hasTooltip={false}
            name={details.vendorName}
            size="medium"
          />
          <TruncatedText maxWidth="50ch" fontSize="basic" fontWeight="semibold">
            {details.vendorName}
          </TruncatedText>
        </Flex>
        {(isHovered || isPopoverOpen) && (
          <RemoveVendorItem
            isPopoverOpen={isPopoverOpen}
            setIsPopoverOpen={setIsPopoverOpen}
            removeVendor={removeVendor}
          />
        )}
      </Grid>
      <Grid
        alignItems="center"
        justifyItems="center"
        templateColumns={isCardManager ? '1fr .5fr' : undefined}
      >
        {isCardManager && (
          <LinkCardToVendorAction
            onClick={onButtonClick}
            vendorName={details.vendorName}
            hasTransactions={details.hasTransactions}
          />
        )}
        <Flex
          {...triggerProps}
          onClick={(e: MouseEvent<HTMLDivElement>) => e.preventDefault()}
          ref={triggerRef}
          alignContent="center"
          justifySelf="end"
        >
          {invoiceAmount ?? icon}
        </Flex>
        {isOpen && (
          <Tooltip
            {...tooltipProps}
            ref={tooltipRef}
            width={isSpecialVendor ? 'auto' : '350px'}
          >
            {tooltip}
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};
