import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { AriaNumberFieldProps, useNumberField } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { useNumberFieldState } from 'react-stately';
import {
  FieldContainer,
  FieldContainerProps,
  Variant,
} from '../../Atoms/FieldContainer';
import { LayoutProps } from '../../types';
import * as TextField from '../TextField';

export interface NumberFieldProps
  extends Omit<FieldContainerProps, 'children'>,
    LayoutProps {
  input?: NumberFieldInputProps;
  inputRef?: React.Ref<HTMLInputElement>;
  /** Show message on focus */
  showMessageOnFocus?: boolean;
}

/**
 * An input field for numeric values such as currency, percentage or other units
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/molecules-forms-numberfield}
 *
 * @param {NumberFieldInputProps} input Props to be passed directly to NumberField.NumberInput
 * @param {React.Ref<HTMLInputElement>} inputRef Ref for NumberField.NumberInput
 */
export const NumberField = React.forwardRef<HTMLDivElement, NumberFieldProps>(
  (
    {
      input,
      label,
      inputRef,
      readOnly,
      message,
      variant,
      showMessageOnFocus = false,
      ...restProps
    },
    forwardedRef
  ) => {
    return (
      <FieldContainer
        label={label}
        readOnly={readOnly}
        message={message}
        variant={variant}
        {...restProps}
        ref={forwardedRef}
      >
        <NumberInput
          label={label}
          readOnly={readOnly}
          message={message}
          variant={variant}
          {...input}
          ref={inputRef}
          showMessageOnFocus={showMessageOnFocus}
        />
      </FieldContainer>
    );
  }
);

export interface NumberFieldInputProps
  extends Omit<
    AriaNumberFieldProps,
    'isDisabled' | 'isReadOnly' | 'onBlur' | 'onFocus' | 'onChange' | 'value'
  > {
  /**
   * Locale to use when displaying and typing the value
   */
  locale?: string;
  /**
   * Handler that is called when the element loses focus
   */
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  /**
   * Handler that is called when the element receives focus
   */
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  /**
   * Numeric value, or null to represent an empty field
   */
  value?: number | null;
  /**
   * Handler that is called when the user types a value and blurs the input.
   * Called with numeric value, or null to represent an empty field
   */
  onChange?: (value: number | null) => void;
  /**
   * Whether the input can be selected but not changed by the user
   */
  readOnly?: boolean;
  /**
   * Whether the input is disabled
   * */
  disabled?: boolean;
  /** status variant i.e success, warning, error */
  variant?: Variant;
  /** message to display in popover of status */
  message?: ReactNode;
  /** Allow to clear value */
  clearable?: boolean;
  /** message to display in popover of clear button */
  clearLabel?: string;
  /** Show message on focus*/
  showMessageOnFocus?: boolean;
  /** Handler to call when clear button is clicked */
  onClear?: () => void;
}

/**
 * NumberField.NumberInput
 */
export const NumberInput = React.forwardRef<
  HTMLInputElement,
  NumberFieldInputProps
>(
  (
    {
      locale = 'en-US',
      onBlur,
      onChange,
      onFocus,
      readOnly = false,
      disabled = false,
      value,
      placeholder,
      message,
      variant,
      clearable,
      clearLabel,
      onClear,
      showMessageOnFocus = false,
      ...restProps
    },
    forwardedRef
  ) => {
    const [inputFocused, setInputFocused] = useState(false);

    const mappedProps: AriaNumberFieldProps = {
      // map our disabled and readOnly props to React Aria’s isDisabled and
      // isReadOnly, respectively
      isDisabled: disabled,
      isReadOnly: readOnly,
      // We redeclare focus/blur handlers, as the types from React Aria only
      // expose Element, not HTMLInputElement, which caused trouble down the line
      // when passing in event handlers from react-final-form, which expect at
      // least HTMLElements
      // see: https://github.com/adobe/react-spectrum/issues/1526
      onBlur: event => {
        if (onBlur) {
          //@ts-expect-error
          onBlur(event);
        }

        setInputFocused(false);
      },
      onFocus: event => {
        if (onFocus) {
          //@ts-expect-error
          onFocus(event);
        }

        setInputFocused(true);
      },
      onChange: newValue => {
        // we represent an empty field as null but React Stately uses NaN
        onChange?.(Number.isNaN(newValue) ? null : newValue);
      },
      // as above we represent an empty field as null, not NaN
      value: value === null ? NaN : value,
      ...restProps,
    };

    const state = useNumberFieldState({ ...mappedProps, locale });
    const inputRef = useRef<HTMLInputElement | null>(null);
    const {
      inputProps: { id, 'aria-labelledby': ariaLabelledBy, ...inputProps },
    } = useNumberField(mappedProps, state, inputRef);

    useEffect(() => {
      const handleWheel = (e: WheelEvent) => {
        // prevent wheel event from changing the value since React Aria
        // doesn’t support disabling that behavior (yet?):
        // https://github.com/adobe/react-spectrum/issues/1602
        e.stopPropagation();
      };

      if (inputRef.current) {
        inputRef.current.addEventListener('wheel', handleWheel, {
          capture: true,
        });
      }

      return () => {
        if (inputRef.current) {
          inputRef.current.removeEventListener('wheel', handleWheel, {
            capture: true,
          });
        }
      };
    }, []);

    return (
      <TextField.Input
        {...inputProps}
        tabIndex={disabled || readOnly ? -1 : undefined}
        // we have to pass placeholder explicitly here as it’s not included in
        // inputProps (possible React Aria bug?)
        placeholder={placeholder}
        ref={mergeRefs([inputRef, forwardedRef])}
        message={message}
        variant={variant}
        clearable={clearable}
        clearLabel={clearLabel}
        onClear={onClear}
        showMessageOnFocus={showMessageOnFocus && inputFocused}
      />
    );
  }
);
