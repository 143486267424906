import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  DownloadDocumentPdFsErrorKind,
  useCreatePdfZipFileForDocumentsMutation,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useComputeQueryFilters } from 'views/Archive/ArchiveDocumentsTable/hooks/useComputeQueryFilters';
import { refetchDocumentDataFileRecords } from 'views/Archive/Documents/queries';

interface UseDownloadPDFFileParams {
  isTableLoading: boolean;
  handleCloseInvoicesDownloadModal: () => void;
}

export const useDownloadInvoiceFiles = ({
  isTableLoading,
  handleCloseInvoicesDownloadModal,
}: UseDownloadPDFFileParams) => {
  const [t] = useTranslation();
  const { success, error, info } = useToastMessage();

  const [
    triggerCreateZipFileForDocuments,
    { loading: createOriginalFileZipFileLoading },
  ] = useCreatePdfZipFileForDocumentsMutation();

  const { filters, dateRangeFilters, query } = useComputeQueryFilters();

  const onPrepareDownloadInvoices = useCallback(async () => {
    if (isTableLoading) {
      info(t('archive:download.waitForLoading'));
    }

    const results = await triggerCreateZipFileForDocuments({
      refetchQueries: refetchDocumentDataFileRecords,
      variables: {
        dateRangeFilters,
        filters,
        query,
      },
    });

    const data = results.data?.createPDFZipFileForDocuments;

    const type = data?.__typename;
    const errorType =
      type === 'DownloadDocumentPDFsError' ? data?.kind : undefined;

    if (type === 'DownloadDocumentPDFsResult') {
      handleCloseInvoicesDownloadModal();
      success(t('archive:download.pdFZipDownloadInitiated'));

      return;
    }

    if (
      type === 'DownloadDocumentPDFsError' &&
      errorType === DownloadDocumentPdFsErrorKind.LimitReached
    ) {
      error(t('archive:download.error.limitReached'));

      return;
    } else {
      error(t('archive:download.error.unknownError'));

      return;
    }
  }, [
    isTableLoading,
    triggerCreateZipFileForDocuments,
    dateRangeFilters,
    filters,
    query,
    info,
    t,
    handleCloseInvoicesDownloadModal,
    success,
    error,
  ]);

  return {
    createOriginalFileZipFileLoading,
    onPrepareDownloadInvoices,
  };
};
