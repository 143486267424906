import { Button, Modal, ModalProps, useModal } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { TransactionList } from './TransactionList';

type AddTransactionsActionProps = {
  documentId: string;
};

export const AddTransactionsAction = ({
  documentId,
}: AddTransactionsActionProps) => {
  const { triggerProps, modalProps, isOpen, close } = useModal();
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <>
      <Button
        {...triggerProps}
        icon="plus"
        variant="tertiary"
        style={{ paddingInline: '1rem' }}
      >
        {t('addTransactionModal.open')}
      </Button>
      <AddTransactionsModal
        modalProps={modalProps}
        isOpen={isOpen}
        documentId={documentId}
        onClose={close}
      />
    </>
  );
};

type AddTransactionsModalProps = {
  documentId: string;
  modalProps?: ModalProps;
  isOpen: boolean;
  onClose: () => void;
};

export const AddTransactionsModal = ({
  documentId,
  modalProps = {},
  isOpen,
  onClose,
}: AddTransactionsModalProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  if (!isOpen) return null;

  return (
    <Modal
      {...modalProps}
      isOpen={isOpen}
      onClose={onClose}
      shouldCloseOnBlur
      background="gray200"
      padding={0}
      overflow="hidden"
      width="80vw"
      height="100%"
      closeLabel={t('addTransactionModal.close')}
      title={t('addTransactionModal.header')}
    >
      <TransactionList documentId={documentId} onSuccess={onClose} />
    </Modal>
  );
};
