import { TruncatedText, HeaderProps } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { ContactsTableData } from '../ContactTableDS';

export const mapColumnIdToTranslationKey: Record<string, string> = {
  contact: 'settings.contacts.columns.name',
  iban: 'settings.contacts.columns.iban',
  taxNumber: 'settings.contacts.columns.tax',
  vatId: 'settings.contacts.columns.vat',
  createTransfer: 'settings.contacts.columns.payment',
  paymentMedium: 'settings.contacts.columns.paymentMedium',
  dueDateOffset: 'settings.contacts.columns.date',
  accountsPayableNumber: 'settings.contacts.columns.accountsPayableNumber',
  accountsReceivableNumber:
    'settings.contacts.columns.accountsReceivableNumber',
  createdAt: 'settings.contacts.columns.createdAt',
  paymentCondition: 'settings.contacts.columns.paymentCondition',
  paymentInfo: 'settings.contacts.columns.paymentInfo',
};

export const Header = ({
  column,
  isFilteredOrSorted,
}: HeaderProps<ContactsTableData>) => {
  const [t] = useTranslation();

  return (
    <TruncatedText
      wordBreak="break-all"
      color={isFilteredOrSorted ? 'gray800' : 'gray500'}
    >
      {t(mapColumnIdToTranslationKey[column.id])}
    </TruncatedText>
  );
};
