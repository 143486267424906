import { Icon, Tooltip, useTooltip } from '@candisio/design-system';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface PermissionIndicatorProps {
  creatorName?: string;
}

export const PermissionIndicator = ({
  creatorName,
}: PermissionIndicatorProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ placement: 'bottom', delay: 300 });

  return (
    <>
      <Icon
        icon="lock"
        size="space16"
        color="gray500"
        style={{ cursor: 'help' }}
        {...triggerProps}
        ref={triggerRef}
      />
      {isOpen && (
        <Tooltip width="space256" {...tooltipProps} ref={tooltipRef}>
          <Trans
            t={t}
            i18nKey="widgets.currencyConversion.lockIconTooltip"
            values={{ creatorName }}
          >
            This report in being shared by <b>{creatorName}</b>. You cannot
            apply any changes.
          </Trans>
        </Tooltip>
      )}
    </>
  );
};
