import { useReimbursementCaseByIdQuery } from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useFormattedReimbursement } from './useFormattedReimbursement';

interface UseGetReimbursementByIdProps {
  reimbursementId?: string;
}

export const useGetReimbursementById = ({
  reimbursementId,
}: UseGetReimbursementByIdProps) => {
  const { canUseReimbursement } = useReimbursement();
  const toReimbursement = useFormattedReimbursement();

  const { data, loading } = useReimbursementCaseByIdQuery({
    variables: { id: reimbursementId ?? '' },
    skip: !reimbursementId || !canUseReimbursement,
    fetchPolicy: 'cache-and-network',
  });

  const fetchedReimbursement = data?.reimbursementCaseById;

  const reimbursement = fetchedReimbursement
    ? toReimbursement(fetchedReimbursement)
    : undefined;

  return { reimbursement, loading };
};
