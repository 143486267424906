import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { Absence } from './Absence';
import { Contracts } from './Contracts';
import { FullTextSearch } from './FullTextSearch';
import { Provisions } from './Provisions';
import { SepaXML } from './SepaXML';
import { Text } from '@candisio/design-system';
import { ComponentType } from 'react';

const featureComponents: Partial<
  Record<FEATURE, ComponentType<{ onClick: () => void }>>
> = {
  [FEATURE.PROVISIONS]: Provisions,
  [FEATURE.ABSENCE_MANAGEMENT]: Absence,
  [FEATURE.CONTRACTS]: Contracts,
  [FEATURE.SEPA_XML]: SepaXML,
};

export const PromotionText = ({
  feature,
  onClick,
}: { feature: FEATURE; onClick: () => void }) => {
  // TODO: move FullTextSearch to the featureComponents map and fix cypress TMI-1248
  if (feature === FEATURE.FULL_TEXT_SEARCH) {
    return <FullTextSearch onClick={onClick} />;
  }

  const Component = featureComponents[feature];
  if (Component) {
    return <Component onClick={onClick} />;
  }

  return (
    <Text fontSize="small" lineHeight="paragraph" onClick={onClick}>
      {feature}
    </Text>
  );
};
