import { getMemberships } from 'components/Comment/gql';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { getOrganizationMembershipsQuery } from 'containers/credit-cards/CreateCreditCard/gql';
import {
  BuiltinRoleName,
  TransferOwnershipErrorKind,
  useTransferOwnershipMutation,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { membershipsQueryFilter } from 'hooks/useUsersFieldOptions';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import {
  OrganizationOwnerFormValues,
  ownerErrorMessages,
  ownerSchema,
} from 'views/Settings/Organization/resolvers/ownerResolver';
import { useGetUsers } from 'views/Settings/TeamMembers/hooks/useGetUsers';

interface useTransferOwnershipProps {
  setDoubleConfirmationOpen: (value: boolean) => void;
  closeDoubleConfirmation: () => void;
}

export const useTransferOwnership = ({
  setDoubleConfirmationOpen,
  closeDoubleConfirmation,
}: useTransferOwnershipProps) => {
  const { success, error } = useToastMessage();
  const [transferOwnership] = useTransferOwnershipMutation();
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { users: admins, isLoadingUsers: isLoadingAdmins } = useGetUsers({
    filters: { roleNames: [BuiltinRoleName.Admin] },
  });

  const { users: owners, isLoadingUsers: isLoadingOwners } = useGetUsers({
    filters: { roleNames: [BuiltinRoleName.Owner] },
  });

  // currently only one owner is supported per organization
  const currentOwnerId = owners.length > 0 ? owners[0].id : null;
  const currentOwnerName = owners.length > 0 ? owners[0].name : null;

  const { isOwner: isCurrentUserOwner, isAdmin: isCurrentUserAdmin } =
    useUserRoles();

  const form = useForm<OrganizationOwnerFormValues>({
    values: { owner: currentOwnerId },
    mode: 'onChange',
    resolver: zodResolver({
      zodSchema: ownerSchema,
      errorMessages: ownerErrorMessages,
    }),
  });

  const selectedOwnerName = useMemo(() => {
    return admins.find(admin => admin.id === form.getValues('owner'))?.name;
  }, [admins, form]);

  const handleTransferOwnershipError = (
    errorKind?: TransferOwnershipErrorKind | null
  ) => {
    form.setValue('owner', currentOwnerId);

    switch (errorKind) {
      case TransferOwnershipErrorKind.NotEnabled:
        return error(
          t('organisation.owner.transferOwnership.error.notEnabled')
        );
      case TransferOwnershipErrorKind.Default:
        return error(t('organisation.owner.transferOwnership.error.default'));
      default:
        return error(t('organisation.owner.transferOwnership.error.default'));
    }
  };

  const handleSubmit = async (data: OrganizationOwnerFormValues) => {
    const result = await transferOwnership({
      variables: { input: { membershipId: data.owner ?? '' } },
      refetchQueries: [
        {
          query: getOrganizationMembershipsQuery,
          variables: membershipsQueryFilter.ActiveAndPending,
        },
        {
          query: getMemberships,
          variables: membershipsQueryFilter.Active,
        },
      ],
    });

    if (
      result.data?.transferOwnership.__typename === 'TransferOwnershipError'
    ) {
      handleTransferOwnershipError(result.data?.transferOwnership?.kind);
    } else if (result.errors) {
      const errorMessage = result.errors[0].message;

      error(t(errorMessage));
    } else if (result.data?.transferOwnership.__typename === 'BuiltinRole') {
      success(
        t('organisation.owner.transferOwnership.success', {
          ownerName: selectedOwnerName,
        })
      );
    }

    closeDoubleConfirmation();
  };

  const isLoading = isLoadingAdmins || isLoadingOwners;

  const isDisabled =
    Boolean(!isCurrentUserOwner && currentOwnerId) ||
    !isCurrentUserAdmin ||
    isLoading;

  const handleCancelSubmit = () => {
    form.setValue('owner', currentOwnerId);
    closeDoubleConfirmation();
  };

  const onConfirm = () => {
    form.handleSubmit(handleSubmit)();
  };

  const handleUserChange = (value: Key | null) => {
    if (value !== null) {
      if (value !== currentOwnerId) {
        setDoubleConfirmationOpen(true);
      } else {
        setDoubleConfirmationOpen(false);
      }
    }
  };

  return {
    form,
    handleUserChange,
    onConfirm,
    handleCancelSubmit,
    selectedOwnerName,
    handleSubmit,
    isDisabled,
    isLoading,
    currentOwnerName,
  };
};
