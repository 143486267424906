import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Link,
  Popover,
  Text,
  usePopover,
  useTheme,
  Image,
} from '@candisio/design-system';
import { CreditCardContainer } from 'containers/credit-cards/CreditCard';
import { useGetCardById } from 'containers/credit-cards/utils';
import {
  CardStatus,
  RecurringPaymentVendorSource,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import creditCards from '../../media/mini-cards.svg';
import sparkles from '../../media/sparkles.svg';
import { CardToLinkDetails } from './CardToLinkDetails';
import { RecurringPaymentsComboBoxField } from './RecurringPaymentsComboBoxField';
import { LinkCardToVendorFormOutput } from './utils/linkCardToVendorFormSchema';
import { useDeleteConfrimationText } from './utils/useDeleteConfirmationText';

const notActionableStates = [CardStatus.Terminated, CardStatus.Expired];

interface CardSectionProps {
  formMode: 'firstLinkage' | 'updateCardLinkage';
  defaultInputValue: string | undefined;
  defaultCreditCardId?: string;
  isLoading?: boolean;
  isUnlinkLoading?: boolean;
  onUnlink: () => void;
  vendor: {
    name?: string;
    source?: RecurringPaymentVendorSource;
  };
}

export const CardSection = ({
  formMode,
  defaultInputValue,
  defaultCreditCardId,
  isLoading,
  isUnlinkLoading,
  vendor: { name: vendorName, source },
  onUnlink,
}: CardSectionProps) => {
  const organizationSlug = useOrganizationId();
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { colors } = useTheme();

  const { close, isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'top' });

  const { getFieldState, watch } = useFormContext<LinkCardToVendorFormOutput>();

  const selectedCardId = watch('creditCard');

  const creditCardFieldState = getFieldState('creditCard');

  const searchParams = new URLSearchParams();

  searchParams.set('cardRefNum', selectedCardId);

  const isCreditCardFieldModified =
    creditCardFieldState.isTouched || creditCardFieldState.isDirty;

  const { card } = useGetCardById({
    cardId: selectedCardId,
    fetchPolicy: 'cache-and-network',
  });

  const cardSettingsLink = `/${organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}/${selectedCardId}?onCreditCardsView=true`;
  const cardTransactionsLink = `/${organizationSlug}${Routes.ARCHIVE}${
    Routes.TRANSACTIONS
  }?${searchParams.toString()}`;

  const buttonText =
    formMode === 'firstLinkage'
      ? t('recurringPaymentsModal.linkCard.linkCardCTA')
      : t('recurringPaymentsModal.linkCard.updateCardCta');

  const linkedVendors: string[] =
    card?.linkedVendors?.records.map(rec => rec.vendor.name) ?? [];

  const confirmationText = useDeleteConfrimationText({ source, linkedVendors });

  return (
    <>
      <Grid gap="space24" templateRows="auto 1fr" height="100%">
        <Card background="gray100">
          <Grid gap="space12">
            <Heading as="h3">
              {formMode === 'firstLinkage'
                ? t('recurringPaymentsModal.linkCard.title')
                : t('recurringPaymentsModal.linkCard.updateCardTitle')}
            </Heading>
            {card && notActionableStates.includes(card?.status) && (
              <Card background="bluebg">
                {card?.status === CardStatus.Expired
                  ? t('recurringPaymentsModal.linkCard.expiredCardInfo')
                  : t('recurringPaymentsModal.linkCard.terminatedCardInfo')}
              </Card>
            )}
            <Grid templateColumns="auto 1fr" gap="space16">
              {selectedCardId ? (
                <CreditCardContainer cardId={selectedCardId} />
              ) : (
                <Image
                  src={creditCards}
                  alt="cards"
                  height="280px"
                  width="180px"
                />
              )}
              <Grid alignContent="start" gap="space10">
                <RecurringPaymentsComboBoxField
                  defaultInputValue={defaultInputValue}
                  defaultCreditCardId={defaultCreditCardId}
                  showConfidence={formMode === 'firstLinkage' ? true : false}
                />
                {!isNil(selectedCardId) && (
                  <Grid gap="space8">
                    <CardToLinkDetails cardId={selectedCardId} />
                    <Grid autoFlow="column" justifyContent="end" gap="space16">
                      <Button
                        as="a"
                        target="_blank"
                        variant="tertiary"
                        rel="noopener noreferrer"
                        href={cardSettingsLink}
                      >
                        {t('recurringPaymentsModal.linkCard.cardSettingsCTA')}
                      </Button>
                      <Button
                        as="a"
                        target="_blank"
                        variant="tertiary"
                        rel="noopener noreferrer"
                        href={cardTransactionsLink}
                      >
                        {t('recurringPaymentsModal.linkCard.transactionsCTA')}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {defaultInputValue &&
              formMode === 'firstLinkage' &&
              !isCreditCardFieldModified && (
                <Card background="bluebg">
                  <Grid templateColumns="auto 1fr" gap="space16">
                    <Image alt="sparkles" src={sparkles} />
                    <Flex direction="column">
                      <Text fontSize="basic">
                        {t('recurringPaymentsModal.linkCard.suggestionBox', {
                          vendorName,
                        })}
                      </Text>
                      <Link
                        external
                        fontSize="space16"
                        href={t(
                          'recurringPaymentsModal.linkCard.suggestionBoxLink.link'
                        )}
                      >
                        {t(
                          'recurringPaymentsModal.linkCard.suggestionBoxLink.text'
                        )}
                      </Link>
                    </Flex>
                  </Grid>
                </Card>
              )}
          </Grid>
        </Card>
        <Grid autoFlow="column" alignSelf="end">
          {formMode === 'updateCardLinkage' && (
            <Button
              justifySelf="start"
              variant="tertiary"
              color="red"
              disabled={isLoading || isUnlinkLoading}
              {...triggerProps}
              ref={triggerRef}
            >
              {t('recurringPaymentsModal.unlinkCard.button')}
            </Button>
          )}
          <Button
            justifySelf="end"
            type="submit"
            loading={isLoading}
            disabled={isLoading || isUnlinkLoading}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
      {isOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          maxWidth="422px"
          padding="space16"
        >
          <Grid gap="space12">
            <Text fontWeight="semibold">
              {t('recurringPaymentsModal.unlinkCard.popover.title')}
            </Text>
            <Grid gap="space18">
              <Text fontSize="basic">{confirmationText}</Text>
              <Flex gap="space4">
                <Button
                  variant="secondary"
                  onClick={close}
                  disabled={isUnlinkLoading}
                >
                  {t('recurringPaymentsModal.unlinkCard.popover.abort')}
                </Button>
                <Button
                  color="red"
                  onClick={onUnlink}
                  disabled={isUnlinkLoading}
                  loading={isUnlinkLoading}
                >
                  {t('recurringPaymentsModal.unlinkCard.popover.cta')}
                </Button>
              </Flex>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
};
