import {
  Avatar,
  AvatarProps,
  Box,
  Flex,
  useTheme,
} from '@candisio/design-system';
import { Clock } from 'components/Icons/Clock';
import { useTranslation } from 'react-i18next';

export type AvatarStatus = 'AWAY' | 'AWAY_SOON';

export interface AvatarWithStatusProps extends AvatarProps {
  status?: AvatarStatus;
}

export const AvatarWithStatus = ({
  status,
  ...restProps
}: AvatarWithStatusProps) => {
  const [t] = useTranslation();

  const { space } = useTheme();
  const sizesMap: Record<string, string> = {
    small: space.space12,
    medium: space.space16,
    large: space.space32,
  };

  return (
    <Box>
      <Avatar {...restProps} />
      {status && (
        <Flex
          position="absolute"
          bottom="-1px"
          left="-1px"
          aria-label={
            status === 'AWAY_SOON'
              ? t('absence.tooltip.status.AWAY_SOON')
              : t('absence.tooltip.status.AWAY')
          }
          style={{ pointerEvents: 'none' }}
        >
          {status === 'AWAY' && (
            <Clock size={sizesMap[restProps.size ?? 'medium']} />
          )}
          {status === 'AWAY_SOON' && (
            <Clock
              size={sizesMap[restProps.size ?? 'medium']}
              backgroundColor="yellow600"
            />
          )}
        </Flex>
      )}
    </Box>
  );
};
