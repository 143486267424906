import { Button } from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../../../../providers/LocaleProvider';

export type RequestNewCardProps = {
  onClick: () => void;
};

export const RequestNewCard = ({ onClick }: RequestNewCardProps) => {
  const [tTransactions] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isCardManager } = useUserRoles();

  return (
    <Button size="small" variant="secondary" icon="plus" onClick={onClick}>
      {isCardManager
        ? t('dashboard.drawer.issueNewCard')
        : tTransactions('carousel.requestNewCard')}
    </Button>
  );
};
