import {
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';
import { Query } from 'generated-types/graphql.types';
import { DocumentNode } from 'graphql';
import { debounce } from 'lodash';
import { useMemo, useRef } from 'react';
import { DeepPartial } from 'utility-types';

export const DEFAULT_DEBOUNCE_TIME = 500;

export interface UseDebouncedSearchOptions<
  TData extends DeepPartial<Query>,
  TVars extends OperationVariables,
  MemoizedFn extends (searchInput: string) => TVars = (args: string) => TVars,
> {
  /**
   * For better type-safety, try to avoid using `Pick<Query, 'myQueryName'>`,
   * Better to use the generated types for the query and its variables.
   */
  gqlQuery: DocumentNode;
  /**
   * This function must be memoized (useCallback), otherwise the `handleSearch`
   * will be re-created on every key-press.
   *
   * This will result in the Graphql query NOT being debounced
   */
  computeVariables: MemoizedFn;
  queryOptions?: LazyQueryHookOptions<TData, TVars>;
  debounceTime?: number;
}

export interface UseDebouncedSearchResult<
  TData extends DeepPartial<Query>,
  TVars extends OperationVariables,
> {
  searchResult: LazyQueryResult<TData, TVars>;
  handleSearch: (inputStr: string) => void;
}

export const useDebouncedSearch = <
  TData extends DeepPartial<Query>,
  TVars extends OperationVariables,
  MemoizedFn extends (searchInput: string) => TVars = (args: string) => TVars,
>({
  gqlQuery,
  computeVariables,
  queryOptions = {},
  debounceTime = DEFAULT_DEBOUNCE_TIME,
}: UseDebouncedSearchOptions<
  TData,
  TVars,
  MemoizedFn
>): UseDebouncedSearchResult<TData, TVars> => {
  // Prevents having `undefined` data while the next search results are loading
  const previousData = useRef<TData>();

  const [searchQuery, result] = useLazyQuery<TData, TVars>(gqlQuery, {
    onCompleted: data => {
      previousData.current = data;
    },
    fetchPolicy: 'no-cache',
    ...queryOptions,
  });

  const handleSearch = useMemo(() => {
    const searchFn = (searchVal: string) =>
      searchQuery({
        variables: computeVariables(searchVal),
      });

    return debounce(searchFn, debounceTime, { leading: true });
  }, [computeVariables, debounceTime, searchQuery]);

  const resultData: TData | undefined = result.loading
    ? previousData.current
    : result.data;

  const searchResult = {
    ...result,
    data: resultData,
  } as unknown as LazyQueryResult<TData, TVars>;

  return {
    handleSearch,
    searchResult,
  };
};
