import { Trans } from 'providers/LocaleProvider';

export interface ImportStatusProps {
  failedToImportDocumentsCount?: number | null;
  successfullyCreatedDocumentsCount?: number | null;
  successfullyUpdatedDocumentsCount?: number | null;
}

export const ImportStatus = ({ ...props }: ImportStatusProps) => {
  const failedCount = props?.failedToImportDocumentsCount ?? 0;
  const successCount = props?.successfullyCreatedDocumentsCount ?? 0;
  const totalImportCount = failedCount + successCount;
  const hasFailedImport = failedCount > 0 && successCount === 0;
  const hasSuccessfullImport = failedCount === 0;

  if (hasSuccessfullImport) {
    return (
      <Trans
        i18nKey="purchase-orders:history.status.successCount"
        count={successCount}
      >
        {{ successCount } as any} purchase orders
      </Trans>
    );
  }

  if (hasFailedImport) {
    return (
      <Trans
        i18nKey="purchase-orders:history.status.failedCount"
        count={failedCount}
      >
        {{ failedCount } as any} purchase order failed
      </Trans>
    );
  }

  return (
    <Trans
      i18nKey="purchase-orders:history.status.totalCount"
      count={totalImportCount}
    >
      {{ failedCount } as any} of {{ totalImportCount }} purchase orders failed
    </Trans>
  );
};
