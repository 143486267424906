import { Button, Flex, Popover, usePopover } from '@candisio/design-system';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateRangePanel } from './DateRangePanel';
import { extractInvoiceDateFromUrl, parseDateRangeOption } from './util';

export const DateRangePicker = ({
  onApplyDateRange,
}: {
  onApplyDateRange: (dateRange: string) => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const currentUser = useCurrentUser();

  const invoiceDate = extractInvoiceDateFromUrl({
    search: window.location.search,
    userId: currentUser?.id,
    forceDefault: false,
  });

  const readableDateRange = invoiceDate.values.readable;
  const { next, prev } = invoiceDate.values;

  const nextReadable = parseDateRangeOption(next);
  const prevReadable = parseDateRangeOption(prev);

  const isInFuture = new Date(nextReadable.values.dateFrom) > new Date();
  const nextIsDisabled = !next || isInFuture;

  const nextLabel = isInFuture
    ? t('dateRangePicker.nextNotAvailableFuture')
    : !next
      ? t('dateRangePicker.nextPrevNotAvailableCustom')
      : nextReadable.values.readable;

  const { close, isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'top right' });

  return (
    <Flex gap="space8" alignItems="center">
      <Button
        variant="secondary"
        icon="caretDown"
        iconPosition="right"
        size="small"
        {...triggerProps}
        ref={triggerRef}
      >
        {t('dateRangePicker.options.description', { readableDateRange })}
      </Button>
      {isOpen && (
        <Popover {...popoverProps} ref={popoverRef} paddingX="space16">
          <DateRangePanel
            onApplyDateRange={val => {
              onApplyDateRange(val);
              close();
            }}
          />
        </Popover>
      )}
      <Button
        variant="secondary"
        size="small"
        label={
          prev
            ? prevReadable.values.readable
            : t('dateRangePicker.nextPrevNotAvailableCustom')
        }
        icon="arrowLeft"
        disabled={!prev}
        onClick={() => {
          if (prev) {
            onApplyDateRange(prev);
          }
        }}
      />
      <Button
        variant="secondary"
        size="small"
        label={nextLabel}
        icon="arrowRight"
        disabled={nextIsDisabled}
        onClick={() => {
          if (next && !nextIsDisabled) {
            onApplyDateRange(next);
          }
        }}
      />
    </Flex>
  );
};
