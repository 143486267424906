import gql from 'graphql-tag';
import { tagFragment } from 'views/Settings/DocumentTags/toolkit/gql';

export const getEcmContractsQuery = gql`
  query getEcmContracts(
    $next: String
    $limit: Int
    $search: String
    $sort: EcmSortInput
    $filter: EcmFilterInput
    $searchInput: SearchInput
  ) {
    getEcmDocuments(
      next: $next
      limit: $limit
      search: $search
      sort: $sort
      filter: $filter
      searchInput: $searchInput
    ) {
      edges {
        cursor
        record {
          amount {
            amount
            currency
            precision
          }
          contactId
          contactName
          costCenterId
          costCenterName
          documentName
          documentNumber
          documentType
          invoiceId
          endDate
          id
          globalDocumentId
          isSensitive
          notes
          notifyPerson {
            primary {
              id
              name
              avatarUrl
            }
          }
          responsiblePerson {
            primary {
              id
              name
              avatarUrl
            }
          }
          startDate
          documentSubCategory {
            id
            name
          }
          terminationDate
          terminationReminderDate
          tags {
            ...Tag
          }
        }
      }
      pageInfo {
        hasNextPage
        nextPageCursor
        totalCount
      }
    }
  }
  ${tagFragment}
`;
