import {
  Button,
  Card,
  Flex,
  Grid,
  InfoPanel,
  Text,
} from '@candisio/design-system';
import { useNew_DatevSettingsQuery } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CheckList, CheckProps } from '../../CheckList';
import { getChecks } from '../../checksState';
import { DatevProvisionsAccountsForm } from './DatevProvisionsAccountsForm/DatevProvisionsAccountsForm';

export const DatevProvisionSettings = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { data, loading } = useNew_DatevSettingsQuery();
  const datevSettings = data?.new_datevSettings;
  const { bdsConnected, bdsSetupCompleted } = useDatev(); // BDS-checked

  const orgId = useOrganizationId();
  const navigate = useNavigate();
  const initialChecks = [getChecks(t).loading];
  const [checks, setChecks] = useState<CheckProps>(initialChecks);
  const [displayEditAccountMode, setDisplayEditAccountMode] =
    useState<boolean>(false);

  const isDatevBDSConnected =
    datevSettings?.hasAuthTokenForExportDocuments && bdsConnected;

  const areAccountNumbersPresent =
    datevSettings?.provisionsLedger?.provisionAccountNumber &&
    datevSettings?.provisionsLedger?.otherAssetsAccountNumber;

  const isProvisionsSetupComplete =
    isDatevBDSConnected && bdsSetupCompleted && areAccountNumbersPresent;

  const displayDatevProvisionsAccountsForm =
    isDatevBDSConnected && bdsSetupCompleted;

  useEffect(() => {
    if (loading) {
      return;
    }

    if (isProvisionsSetupComplete) {
      setChecks([getChecks(t).functionActive]);
    } else {
      setChecks([
        getChecks(t).datev[isDatevBDSConnected ? 'success' : 'error'],
        getChecks(t).bds[bdsSetupCompleted ? 'success' : 'error'],
        getChecks(t).accountNumbers[
          areAccountNumbersPresent ? 'success' : 'error'
        ],
      ]);
    }
  }, [
    t,
    loading,
    isProvisionsSetupComplete,
    isDatevBDSConnected,
    bdsSetupCompleted,
    areAccountNumbersPresent,
  ]);

  const GoToDatevSettingsButton = () => (
    <Button
      width="max-content"
      variant="primary"
      onClick={() => navigate(`/${orgId}${Routes.SETTINGS}${Routes.DATEV}`)}
    >
      {t('provisions.setupCard.goToDatevSettings')}
    </Button>
  );

  const EditAccountsButton = () => (
    <Flex>
      <Grid>
        <Button
          loading={loading}
          width="max-content"
          size="small"
          variant="primary"
          onClick={() => {
            setDisplayEditAccountMode(true);
          }}
        >
          {t('provisions.setupCard.editAccountsButton')}
        </Button>
      </Grid>
    </Flex>
  );

  return (
    <Grid gap="space16" height="fit-content">
      <Grid rowGap="space24">
        <Grid autoFlow="column">
          <Card boxShadow="noShadow" padding="space24">
            <Grid gap="space14">
              {!isProvisionsSetupComplete && !loading && (
                <InfoPanel
                  variant="information"
                  message={t('provisions.setupCard.infoMessage')}
                ></InfoPanel>
              )}
              <Text fontWeight="400" fontSize="xlarge" lineHeight="heading">
                {t('provisions.setupCard.statusLabel')}
              </Text>
              <CheckList checks={checks} animate />

              {!displayDatevProvisionsAccountsForm && (
                <GoToDatevSettingsButton />
              )}
              {displayDatevProvisionsAccountsForm &&
                !areAccountNumbersPresent &&
                !displayEditAccountMode && <EditAccountsButton />}
            </Grid>
          </Card>
        </Grid>
      </Grid>
      {(areAccountNumbersPresent || displayEditAccountMode) &&
        displayDatevProvisionsAccountsForm && (
          <DatevProvisionsAccountsForm
            datevSettings={datevSettings}
            setDisplayEditAccountMode={setDisplayEditAccountMode}
          />
        )}
    </Grid>
  );
};
