import { Button, Flex, Text } from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { BOOKING_ACCOUNT_CREATE_ROUTE } from 'views/Settings/BookingAccounts/consts';
import { BOOKING_ACCOUNT_ROUTE_HASH } from 'views/Settings/BookingAccounts/consts';
import {
  HeadingDivider,
  TypeSelectionEmptyButtonGroup,
  TypeSelectionEmptyDetails,
  TypeSelectionTitle,
  WidgetTypeSelectionBox,
  Wrapper,
} from './styles';
import { SelectionProps, WIDGET_TYPE } from './types';

export const GeneralLedgerSelection = ({
  image,
  setSelectedType,
}: SelectionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  return (
    <WidgetTypeSelectionBox
      title={t('widgets.typeSelection.generalLedgerAccount.title')}
      cta={
        <Trans t={t} i18nKey="widgets.typeSelection.generalLedgerAccount.cta">
          Create a report for one or more
          <Text fontWeight="semibold">General ledger accounts</Text>.
        </Trans>
      }
      image={image}
    >
      <Button
        variant="secondary"
        data-cy="createForGeneralLegerAccounts"
        onClick={() => setSelectedType(WIDGET_TYPE.GENERAL_LEDGER_ACCOUNT)}
      >
        {t('widgets.typeSelection.generalLedgerAccount.buttonTitle')}
      </Button>
    </WidgetTypeSelectionBox>
  );
};

export const GeneralLedgerEmptySelection = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const orgId = useOrganizationId();
  const history = useHistory();
  const { isAccountant, isAdmin } = useUserRoles();

  const canCreateGeneralLedgerAccounts = isAdmin || isAccountant;

  const links = {
    create: `/${orgId}${Routes.SETTINGS}${Routes.BOOKING_ACCOUNT}/${BOOKING_ACCOUNT_CREATE_ROUTE}`,
    import: `/${orgId}${Routes.SETTINGS}${Routes.BOOKING_ACCOUNT}${BOOKING_ACCOUNT_ROUTE_HASH.import}`,
  };

  const texts = {
    askAdmin: t(
      'widgets.typeSelection.generalLedgerAccount.noGeneralLedgerAccountAvailable.ctaApprover'
    ),
    createCTA: t(
      'widgets.typeSelection.generalLedgerAccount.noGeneralLedgerAccountAvailable.ctaAdmin.create'
    ),
    importCTA: t(
      'widgets.typeSelection.generalLedgerAccount.noGeneralLedgerAccountAvailable.ctaAdmin.import'
    ),
  };

  const title = t(
    'widgets.typeSelection.generalLedgerAccount.noGeneralLedgerAccountAvailable.title'
  );

  const details = t(
    'widgets.typeSelection.generalLedgerAccount.noGeneralLedgerAccountAvailable.details'
  );

  return (
    <Wrapper>
      <Flex gap="space16" direction="column">
        <TypeSelectionTitle>{title}</TypeSelectionTitle>
        <HeadingDivider />
        <TypeSelectionEmptyDetails>{details}</TypeSelectionEmptyDetails>
      </Flex>

      <TypeSelectionEmptyButtonGroup
        onCreate={() => history.push(links.create)}
        onImport={() => history.push(links.import)}
        canCreate={canCreateGeneralLedgerAccounts}
        text={texts}
      />
    </Wrapper>
  );
};
