import { Grid, Link, TruncatedText } from '@candisio/design-system';
import { Collapse } from 'components/History/items/Collapse';
import {
  DocumentRelationshipCreatedEventDataFragment,
  RelatedDocumentActivityFragment,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useEcmDocumentTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmDocumentTypeItems';

export const getDisplayName = (
  relatedDocument: RelatedDocumentActivityFragment
) => {
  let displayName;

  if (relatedDocument?.document?.__typename === 'Document') {
    // For invoices, we try to get display name in order: invoice Number => contact name => documentFile name
    const { invoiceNumber, contact, documentFile } = relatedDocument.document;
    if (invoiceNumber) {
      displayName = invoiceNumber.value;
    } else if (contact?.value?.name?.value) {
      displayName = contact.value.name.value;
    } else if (documentFile?.name) {
      displayName = documentFile.name;
    }
  } else if (
    relatedDocument?.document?.__typename === 'AggregatedEcmDocument'
  ) {
    displayName = relatedDocument.document.documentName;
  } else {
    displayName = relatedDocument.id;
  }

  return displayName ?? '';
};

export const getDocumentUrl = (
  organizationId: string,
  relatedDocument: RelatedDocumentActivityFragment
) => {
  if (relatedDocument.document?.__typename === 'Document') {
    return `/${organizationId}${Routes.ARCHIVE}/${relatedDocument.id}`;
  } else if (relatedDocument.document?.__typename === 'AggregatedEcmDocument') {
    return `/${organizationId}${Routes.ECM_DOCUMENTS}/${relatedDocument.id}`;
  } else return '';
};

export const DocumentRelationshipCreatedComponent = ({
  linkedDocuments,
}: DocumentRelationshipCreatedEventDataFragment) => {
  const [t] = useTranslation();
  const organizationId = useOrganizationId();

  const onlyProperlyLinkedDocuments = linkedDocuments?.filter(
    (doc): doc is RelatedDocumentActivityFragment =>
      doc?.__typename === 'RelatedDocument'
  );

  return (
    <Grid gap="space4">
      {t('ecm:documentHistoryEvents.documentsLinked.title', {
        count: onlyProperlyLinkedDocuments?.length,
      })}
      <Collapse>
        <Grid gap="space4">
          {onlyProperlyLinkedDocuments?.map(linkedDocument => {
            return (
              <DocumentRelationshipActivityItem
                key={linkedDocument.id}
                organizationId={organizationId ?? ''}
                relatedDocument={linkedDocument}
              />
            );
          })}
        </Grid>
      </Collapse>
    </Grid>
  );
};

interface DocumentRelationshipActivityItemProps {
  organizationId: string;
  relatedDocument: RelatedDocumentActivityFragment;
  additionalText?: ReactNode;
}
export const DocumentRelationshipActivityItem = ({
  organizationId,
  relatedDocument,
  additionalText,
}: DocumentRelationshipActivityItemProps) => {
  const [t] = useTranslation();
  const { ecmDocumentTypeTranslationMap } = useEcmDocumentTypeOptions();

  return (
    <Grid>
      <Link
        external
        href={getDocumentUrl(organizationId, relatedDocument)}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/** @ts-ignore fix in DS types for TruncatedText to allow for array of strings or JSX.Element[] */}
        <TruncatedText>
          {t('ecm:documentHistoryEvents.relationLinkText', {
            documentType: ecmDocumentTypeTranslationMap[relatedDocument.type],
            documentName: getDisplayName(relatedDocument),
          })}
        </TruncatedText>
      </Link>
      {additionalText}
    </Grid>
  );
};
