import { FilterOptionsAccessor } from '@candisio/design-system';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ReimbursementTableRow } from 'views/Reimbursement/toolkit/types';

export const useInboxReimbursementsFilterOptions = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const filterOptions: FilterOptionsAccessor<ReimbursementTableRow> = {
    createdAt: true,
    firstSubmittedForReviewAt: true,
    status: {
      data: [
        {
          id: ReimbursementCaseStatus.Rejected,
          label: t('status.rejected'),
        },
        {
          id: ReimbursementCaseStatus.Check,
          label: t('status.check'),
        },
        {
          id: ReimbursementCaseStatus.Review,
          label: t('status.review'),
        },
      ],
    },
  };

  return filterOptions;
};
