import { Grid, Text, Theme } from '@candisio/design-system';
import { InfoIcon } from 'components/Icons/InfoIcon';
import { UploadFileErrorIcon } from 'components/Icons/notifications/UploadFileErrorIcon';
import { UploadFileProcessingIcon } from 'components/Icons/notifications/UploadFileProcessingIcon';
import { UploadFileSuccessIcon } from 'components/Icons/notifications/UploadFileSuccessIcon';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { FileState, UploadFileItem } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useTranslation } from 'react-i18next';
import { RoutePath } from 'utils/path_generator';
import { NotificationItem } from './styles';

const mapStatusToColor = (status: FileState): keyof Theme['colors'] => {
  switch (status) {
    case FileState.Error:
      return 'red500';
    case FileState.Uploading:
      return 'yellow500';
    case FileState.Uploaded:
      return 'green500';
    default:
      return 'gray800';
  }
};

const iconMap: Record<FileState, JSX.Element> = {
  [FileState.Uploading]: <UploadFileProcessingIcon />,
  [FileState.Error]: <UploadFileErrorIcon />,
  [FileState.Uploaded]: <UploadFileSuccessIcon />,
};

export interface UploadFileNotificationItemProps {
  data: UploadFileItem;
  onClose: () => void;
  organizationSlug: string;
}
const inboxPathPattern = new RoutePath(`/:organizationSlug${Routes.INBOX}/:id`);

export const UploadFileNotificationItem = ({
  data,
  onClose,
  organizationSlug,
}: UploadFileNotificationItemProps) => {
  const { fileName, state, errorDetails, documentId } = data;
  const [t] = useTranslation();

  const label = documentId ? (
    <RouterLink
      onClick={onClose}
      to={inboxPathPattern.stringify({
        id: documentId,
        organizationSlug,
      })}
    >
      {fileName}
    </RouterLink>
  ) : (
    fileName
  );

  const notificationData = (
    <Grid
      alignItems="center"
      templateColumns="auto 1fr auto"
      gap="space16"
      paddingX="space12"
      paddingY="space8"
    >
      {iconMap[state]}
      <Text fontWeight="bold">{label}</Text>
      <Grid alignItems="center" autoFlow="column" gap="space4">
        <Text color={mapStatusToColor(state)}>
          {t(`uploads.status.${state.toLowerCase()}`)}
        </Text>
        {errorDetails && (
          <InfoIcon
            message={<Text whiteSpace="pre-line">{t(errorDetails)}</Text>}
          />
        )}
      </Grid>
    </Grid>
  );

  return (
    <NotificationItem data-cy={fileName}>
      {state !== FileState.Uploading ? (
        notificationData
      ) : (
        <ProgressBar childStyles={{ padding: '0' }}>
          {notificationData}
        </ProgressBar>
      )}
    </NotificationItem>
  );
};
