import {
  TableBody,
  TableElement,
  TableHead,
  TableRow,
} from '@candisio/design-system';
import { Column, useFlexLayout, useTable } from 'react-table';
import { TableData } from './index';

interface TableProps {
  columns: Array<Column<TableData>>;
  data: TableData[];
}

export const DataTable = ({ columns, data }: TableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useFlexLayout);

  return (
    <TableElement {...getTableProps}>
      <TableHead>
        {headerGroups.map(headerGroup => (
          // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
          <TableRow
            {...headerGroup.getHeaderGroupProps()}
            style={{
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              ...headerGroup.getHeaderGroupProps().style,
            }}
          >
            {headerGroup.headers.map((column, index) => (
              // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
              <th
                {...column.getHeaderProps()}
                style={
                  index === 0
                    ? {
                        borderTopLeftRadius: '8px',
                        ...column.getHeaderProps().style,
                      }
                    : index === headerGroup.headers.length - 1
                      ? {
                          borderTopRightRadius: '8px',
                          ...column.getHeaderProps().style,
                        }
                      : column.getHeaderProps().style
                }
              >
                {column.render('Header')}
              </th>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          const { key, style, ...rowProps } = row.getRowProps();

          return (
            <TableRow
              key={key}
              height="space64"
              style={{
                ...style,
                alignItems: 'center',
                ...(index === rows.length - 1
                  ? {
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                    }
                  : {}),
              }}
              {...rowProps}
            >
              {row.cells.map(cell => (
                // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </TableElement>
  );
};
