import { Button, Grid, Heading, useTheme } from '@candisio/design-system';
import { Loader } from 'components/Loader';
import { useGetCardById } from 'containers/credit-cards/utils';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LinkCardToVendorFormValues } from '../utils/linkCardToVendorFormSchema';
import { useCardTransactionsLink } from '../utils/useCardTransactionsLink';
import { useLatestTransactionsByCardId } from '../utils/useLatestTransactionsByCardId';
import { TransactonEmptyState } from './TransactionEmptyState';
import { TransactionsContent } from './TransactionsContent';

export const TransactionSection = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { watch } = useFormContext<LinkCardToVendorFormValues>();
  const { colors } = useTheme();

  const creditCardIdFieldValue = watch('creditCard');

  const { card } = useGetCardById({
    cardId: creditCardIdFieldValue,
    fetchPolicy: 'cache-and-network',
  });

  const replacedCardId = card?.replacedCardId ?? undefined;

  const {
    loading,
    hasTransactions,
    linkedCardTransactions,
    replacedCardTransactions,
  } = useLatestTransactionsByCardId({
    replacedCardId,
    linkedCardId: creditCardIdFieldValue,
  });

  const { link } = useCardTransactionsLink({
    replacedCardId,
    linkedCardId: creditCardIdFieldValue,
  });

  if (loading) {
    return (
      <Grid gap="space32">
        <Heading as="h2">{t('drawer.latestTransactions')}</Heading>
        <Loader />
      </Grid>
    );
  }

  return (
    <Grid gap="space32">
      <Grid autoFlow="column" placeItems="center" placeContent="space-between">
        <Heading as="h2">{t('drawer.latestTransactions')}</Heading>
        {hasTransactions ? (
          <Button
            as="a"
            target="_blank"
            variant="tertiary"
            rel="noopener noreferrer"
            href={link}
            style={{ color: colors.gray800 }}
          >
            {t('recurringPaymentsModal.linkCard.allTransactions')}
          </Button>
        ) : null}
      </Grid>
      {hasTransactions ? (
        <TransactionsContent
          linkedCardTransactions={linkedCardTransactions}
          replacedCardTransactions={replacedCardTransactions}
        />
      ) : (
        <TransactonEmptyState hasCard={Boolean(creditCardIdFieldValue)} />
      )}
    </Grid>
  );
};
