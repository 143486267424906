import { Box, Button, Grid, Text, Link } from '@candisio/design-system';
import ConnectBDSThumbnail from 'assets/bds/setupWizard/connect-bds.png';
import OrderBDSThumbnail from 'assets/bds/setupWizard/order-bds.png';
import SetupProvisionsThumbnail from 'assets/bds/setupWizard/setup-provisions.png';
import UploadContactsThumbnail from 'assets/bds/setupWizard/upload-contacts.png';
import UploadPaymentsThumbnail from 'assets/bds/setupWizard/upload-payment-conditions.png';
import { AccordionWizard } from 'components/AccordionWizard';
import {
  DatevConnectionType,
  useDisconnectDatevClientMutation,
} from 'generated-types/graphql.types';
import { BdsPreRequisiteStep } from 'generated-types/resolvers-types';
import { Routes } from 'models';
import { useDatevBdsSetupSteps } from 'orgConfig/datev/datevBdsSetupSteps';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CONTACT_ROUTE_HASH } from 'views/Contacts/types';
import { PAYMENTS_IMPORT_HASH } from 'views/Settings/PaymentConditions';
import { SETTINGS_ROUTES } from 'views/Settings/types';
import { useDatevAuthorizeForClients } from '../datevAuthHooks';
import { refetchDatevDataWithNoToken } from '../gql';
import { urlParams } from '../utils';
import { VideoPlayerWithThumbnail } from './VideoPlayerWithThumbnail';

interface SetupInstructionsProps {
  order: number;
  wistiaId?: string;
  navatticId?: string;
  videoThumbnail: string;
  details: string;
  action?: () => void;
  actionButton?: string;
  helpLinkText: string;
  helpLinkUrl: string;
}

const SetupInstructions = ({
  step,
  completed,
  handleComplete,
  instructions,
}: {
  step: BdsPreRequisiteStep;
  completed: boolean;
  handleComplete: (step: BdsPreRequisiteStep, completed: boolean) => void;
  instructions?: SetupInstructionsProps;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  if (!instructions) return null;

  const {
    wistiaId,
    navatticId,
    videoThumbnail,
    action,
    actionButton,
    helpLinkText,
    helpLinkUrl,
  } = instructions;

  return (
    <Grid
      gap="space10"
      paddingX="space24"
      paddingTop={0}
      paddingBottom="space32"
      templateColumns="24px 1fr"
    >
      <Grid gap="space48" templateColumns="1fr auto" gridColumn={2}>
        <Grid gap="space32">
          <Text fontSize="basic" lineHeight="140%" whiteSpace="pre-wrap">
            {instructions.details}
          </Text>
          <Box>
            <Link external icon="newTab" href={helpLinkUrl}>
              {helpLinkText}
            </Link>
          </Box>
        </Grid>
        <Box>
          <VideoPlayerWithThumbnail
            key={wistiaId ?? navatticId}
            wistiaId={wistiaId}
            navatticId={navatticId}
            thumbnail={videoThumbnail}
          />
        </Box>
      </Grid>
      <Grid
        autoFlow="column"
        width="max-content"
        gap="space20"
        paddingTop="space20"
        gridColumn={2}
      >
        {actionButton && action && (
          <Button onClick={action}>{actionButton}</Button>
        )}
        <Button
          variant="secondary"
          onClick={() => handleComplete(step, !completed)}
        >
          {completed
            ? t('datev.setupWizard.step.markAsNotFinished')
            : t('datev.setupWizard.step.markAsFinished')}
        </Button>
      </Grid>
    </Grid>
  );
};

export const BdsSetupWizard = ({ loading }: { loading: boolean }) => {
  const orgId = useOrganizationId();
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const navigate = useNavigate();
  const {
    datevBDSPreRequisites,
    completeStep,
    completedStepsCount,
    wizardDismissed,
    setWizardDismissed,
  } = useDatevBdsSetupSteps();

  const [disconnectDatevClient] = useDisconnectDatevClientMutation({
    refetchQueries: [
      ...refetchDatevDataWithNoToken,
      { query: getFullOrganizationQuery },
    ],
  });

  const [authorizeForClients] = useDatevAuthorizeForClients();

  const onReconnectDatevClient = async () => {
    await disconnectDatevClient();

    navigate(
      `/${orgId}${Routes.SETTINGS}${Routes.DATEV}?${urlParams.datevConnectionType}=${DatevConnectionType.DatevRewe}`
    );

    await authorizeForClients(DatevConnectionType.DatevRewe);
  };

  const setupInstructions = [
    {
      order: 1,
      step: BdsPreRequisiteStep.ImportPaymentConditions,
      title: t('datev.setupWizard.steps.import_payments.title'),
      wistiaId: t('datev.setupWizard.steps.import_payments.wistiaId'),
      videoThumbnail: UploadPaymentsThumbnail,
      details: t('datev.setupWizard.steps.import_payments.instructions'),
      action: () =>
        window.open(
          `/${orgId}${Routes.SETTINGS}/${SETTINGS_ROUTES.PAYMENT_CONDITIONS}${PAYMENTS_IMPORT_HASH}`,
          '_blank'
        ),
      actionButton: t('datev.setupWizard.steps.import_payments.actionButton'),
      helpLinkText: t('datev.setupWizard.steps.import_payments.helpLinkText'),
      helpLinkUrl: t('datev.setupWizard.steps.import_payments.helpLinkUrl'),
    },
    {
      order: 2,
      step: BdsPreRequisiteStep.ImportContacts,
      title: t('datev.setupWizard.steps.import_contacts.title'),
      wistiaId: t('datev.setupWizard.steps.import_contacts.wistiaId'),
      videoThumbnail: UploadContactsThumbnail,
      details: t('datev.setupWizard.steps.import_contacts.instructions'),
      action: () =>
        window.open(
          `/${orgId}${Routes.CONTACTS}${CONTACT_ROUTE_HASH.import}`,
          '_blank'
        ),
      actionButton: t('datev.setupWizard.steps.import_contacts.actionButton'),
      helpLinkText: t('datev.setupWizard.steps.import_contacts.helpLinkText'),
      helpLinkUrl: t('datev.setupWizard.steps.import_contacts.helpLinkUrl'),
    },
    {
      order: 3,
      step: BdsPreRequisiteStep.OrderBds,
      title: t('datev.setupWizard.steps.order_bds.title'),
      navatticId: t('datev.setupWizard.steps.order_bds.navatticId'),
      videoThumbnail: OrderBDSThumbnail,
      details: t('datev.setupWizard.steps.order_bds.instructions'),
      action: undefined,
      actionButton: t('datev.setupWizard.steps.order_bds.actionButton'),
      helpLinkText: t('datev.setupWizard.steps.order_bds.helpLinkText'),
      helpLinkUrl: t('datev.setupWizard.steps.order_bds.helpLinkUrl'),
    },
    {
      order: 4,
      step: BdsPreRequisiteStep.ConnectBds,
      title: t('datev.setupWizard.steps.connect_bds.title'),
      wistiaId: t('datev.setupWizard.steps.connect_bds.wistiaId'),
      videoThumbnail: ConnectBDSThumbnail,
      details: t('datev.setupWizard.steps.connect_bds.instructions'),
      action: () => onReconnectDatevClient(),
      actionButton: t('datev.setupWizard.steps.connect_bds.actionButton'),
      helpLinkText: t('datev.setupWizard.steps.connect_bds.helpLinkText'),
      helpLinkUrl: t('datev.setupWizard.steps.connect_bds.helpLinkUrl'),
    },
    {
      order: 5,
      step: BdsPreRequisiteStep.SetupProvisions,
      title: t('datev.setupWizard.steps.setup_provisions.title'),
      wistiaId: t('datev.setupWizard.steps.setup_provisions.wistiaId'),
      videoThumbnail: SetupProvisionsThumbnail,
      details: t('datev.setupWizard.steps.setup_provisions.instructions'),
      action: () =>
        window.open(
          `/${orgId}${Routes.SETTINGS}${Routes.PROVISIONS}`,
          '_blank'
        ),
      actionButton: t('datev.setupWizard.steps.setup_provisions.actionButton'),
      helpLinkText: t('datev.setupWizard.steps.setup_provisions.helpLinkText'),
      helpLinkUrl: t('datev.setupWizard.steps.setup_provisions.helpLinkUrl'),
    },
  ];

  const wizardSteps = datevBDSPreRequisites
    ?.map(preRequisite => {
      const instructions = setupInstructions.find(
        s => s.step === preRequisite.step
      );

      return {
        ...preRequisite,
        order: instructions?.order || -1,
        title: instructions?.title || '',
        children: (
          <SetupInstructions
            step={preRequisite.step}
            completed={preRequisite.completed}
            handleComplete={completeStep}
            instructions={instructions}
          />
        ),
      };
    })
    .sort((a, b) => a.order - b.order);

  return (
    <AccordionWizard<BdsPreRequisiteStep>
      wizardSteps={wizardSteps}
      loading={loading}
      completedStepsCount={completedStepsCount}
      wizardDismissed={wizardDismissed}
      setWizardDismissed={setWizardDismissed}
    />
  );
};
