import { Button, Flex, Card } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CarouselStates, SensitiveDataProps } from '../../types';
import { UnlockButton } from '../UnlockButton/UnlockButton';
import { CreditCardSensitiveDataContainer } from './CreditCardSensitiveDataContainer';

interface CardColumnProps {
  cardId: string;
  cardState: CarouselStates;
  sensitiveData: SensitiveDataProps;
  token: string | null | undefined;
  uniqueToken: string | null | undefined;
  totalCards: number;
}

export const CardColumn = ({
  cardId,
  sensitiveData,
  token,
  uniqueToken,
  cardState,
  totalCards,
}: CardColumnProps) => {
  const {
    isFetchingSensitiveData,
    onShowCardNumbers,
    resetTokens,
    sensitiveDataIsShown,
  } = sensitiveData;

  const handleShowCardNumbers = () => {
    onShowCardNumbers(cardId);
  };

  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const showDetailsCardButton =
    cardState === CarouselStates.DEFAULT ? (
      <Button
        size="small"
        justifySelf="start"
        variant="secondary"
        icon={sensitiveDataIsShown ? 'viewOutline' : 'view'}
        loading={isFetchingSensitiveData}
        onClick={
          sensitiveDataIsShown ? resetTokens : () => handleShowCardNumbers()
        }
      >
        {sensitiveDataIsShown
          ? t('carousel.hideNumbers')
          : t('carousel.showNumbers')}
      </Button>
    ) : null;

  const showUnlockButton =
    cardState === CarouselStates.LOCKED ||
    cardState === CarouselStates.LOCKEDPIN ? (
      <UnlockButton cardId={cardId} cardState={cardState} />
    ) : null;

  //Do not remove, useEffect hook because we need it to reset tokens and hide sensitive data anytime the component unmounts.
  useEffect(() => (sensitiveDataIsShown ? resetTokens : undefined));

  return (
    <Flex
      direction="column"
      right="24px"
      top="12px"
      zIndex={1}
      position="relative"
      style={{ scale: '1.0' }}
      gap="space12"
      alignItems="center"
    >
      <CreditCardSensitiveDataContainer
        cardId={cardId}
        keys={{ token, uniqueToken }}
      />
      {showDetailsCardButton ?? showUnlockButton}
      {totalCards > 1 ? (
        <Card
          boxShadow="noShadow"
          position="absolute"
          borderRadius="medium"
          width="180px"
          opacity="0.2"
          background="#2B1A2E"
          top={-5}
          right={5}
          zIndex={-1}
          height="280px"
        />
      ) : null}
    </Flex>
  );
};
