import { Button, Grid } from '@candisio/design-system';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface FooterProps {
  isSaveButtonVisible: boolean;
  archiveButton?: ReactNode;
  submitting: boolean;
  formId?: string;
  isActive?: boolean;
}

export const ContactDrawerFooter = ({
  isSaveButtonVisible,
  archiveButton,
  submitting,
  formId,
  isActive = true,
}: FooterProps) => {
  const [t] = useTranslation();

  const saveContactButton = isActive && isSaveButtonVisible && (
    <Button
      type="submit"
      form={formId}
      loading={submitting}
      data-cy="contacts-draw-save-action"
    >
      {t('settings.contacts.details.edit.submit')}
    </Button>
  );

  if (archiveButton) {
    return (
      <Grid placeContent="space-between" autoFlow="column">
        <div>{saveContactButton}</div>

        <div>{archiveButton}</div>
      </Grid>
    );
  }

  return saveContactButton;
};
