import { Maybe } from '@graphql-tools/utils';
import { extractInvoiceDateFromUrl } from 'components/Insights/Widgets/DateRangePicker/util';
import {
  InsightsWidgetBudgetsInput,
  InsightBudgetType,
} from 'generated-types/graphql.types';
import moment from 'moment';
import { DateRangeDefinition, WidgetFormData } from '../../types';
import {
  assignBudgetsByQuarter,
  getQuarterBudgetsFromYear,
  prepareBudgetFormForMutation,
} from './addBudgets';
import { getYearsFields } from '../AnnualOption/AnnualOption.helper';
import { getQuarterFieldsKeys } from '../QuarterOption/QuarterOption.helper';
import { isNil } from 'lodash';

export const isAnyYearBudgetApplied = (formData: WidgetFormData) => {
  const yearFieldsKeys = getYearsFields();
  return yearFieldsKeys.some(year => formData[year]);
};

export const isAnyQuarteBudgetApplied = (formData: WidgetFormData) => {
  const quarterFieldsKeys = getQuarterFieldsKeys();

  return quarterFieldsKeys.some(quarter => !isNil(formData[quarter]));
};

export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const toMonthlyBudgets = (budgets: InsightsWidgetBudgetsInput[]) => {
  const budgetWithDates: Array<{ date: Date; value?: number }> = [];

  budgets.forEach(({ year, monthlyBudgets }) => {
    months.forEach((month, i) => {
      budgetWithDates.push({
        date: new Date(year, i, 15),
        // @ts-ignore
        value: monthlyBudgets[month],
      });
    });
  });

  return budgetWithDates;
};

export const checkIfBudgetsAreApplied = (formData: WidgetFormData) => {
  let budgetsbyMonth: InsightsWidgetBudgetsInput[] = [];
  const isYearBudgetApplied = isAnyYearBudgetApplied(formData);
  const isQuarterBudgetApplied = isAnyQuarteBudgetApplied(formData);

  if (isQuarterBudgetApplied) {
    const budgetsbyQuarter = assignBudgetsByQuarter(
      formData,
      InsightBudgetType.Quarterly
    );

    budgetsbyMonth = budgetsbyQuarter.map(prepareBudgetFormForMutation);
  } else if (isYearBudgetApplied) {
    const budgetsbyQuarter = getQuarterBudgetsFromYear(
      formData,
      InsightBudgetType.Yearly
    );

    budgetsbyMonth = budgetsbyQuarter.map(prepareBudgetFormForMutation);
  }

  return budgetsbyMonth;
};

export const determineBudgetForDateRange = (
  invoiceDate: DateRangeDefinition,
  budgets: Maybe<InsightsWidgetBudgetsInput[]> | undefined
) => {
  if (!budgets) {
    return null;
  }

  let hasMissingBudget = false;
  let hasAnyBudget = false;
  let budget = 0;

  const monthlyBudgets = toMonthlyBudgets(budgets);
  const {
    values: { dateTo, dateFrom },
  } = invoiceDate;

  const asJavaScriptDate = {
    dateTo: moment(dateTo, 'DD.MM.YYYY').toDate(),
    dateFrom: moment(dateFrom, 'DD.MM.YYYY').toDate(),
  };

  monthlyBudgets.forEach(({ date, value }) => {
    if (asJavaScriptDate.dateFrom < date && date < asJavaScriptDate.dateTo) {
      if (!value) {
        hasMissingBudget = true;

        return;
      }

      hasAnyBudget = true;
      budget += value;
    }
  });

  if (hasMissingBudget || !hasAnyBudget) {
    return null;
  }

  return budget;
};

export const getBudgetAmount = (
  formData: WidgetFormData,
  currentUserId: string | undefined
) => {
  const budgetsbyMonth = checkIfBudgetsAreApplied(formData);

  const invoiceDate = extractInvoiceDateFromUrl({
    search: window.location.search,
    userId: currentUserId,
    forceDefault: false,
  });

  const budgetAmount = determineBudgetForDateRange(invoiceDate, budgetsbyMonth);

  return budgetAmount;
};
