import {
  Card,
  Grid,
  ScrollBox,
  Tag,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import {
  DocumentCurrency,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useReimbursementTags } from 'views/Reimbursement/hooks/useReimbursementTags';
import { ReimbursementItemsCard } from './ReimbursementItemsCard';
import { ReimbursementItemListLeftSection } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/Reimbursement';
import { EmptyReimbursementDetailsCard } from './EmptyReimbursementDetailsCard';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';

interface ReimbursementDetailsProps {
  title?: string;
  totalAmount?: string;
  status?: ReimbursementCaseStatus;
  creatingReimbursementItem: boolean;
  items: ReimbursementItemListLeftSection[];
  onHospitalityExpenseClick: () => void;
  onGeneralExpenseClick: () => void;
  reimbursementItemsCount: number;
}

export const ReimbursementDetails = ({
  title,
  totalAmount,
  status,
  creatingReimbursementItem,
  items,
  reimbursementItemsCount,
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
}: ReimbursementDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const tags = useReimbursementTags();
  const { documentMoneyFormatter } = useMoneyFormatter();

  const { searchParams } = useMutateSearchParams();

  const isItemsViewActive = !!searchParams.get(REIMBURSEMENT_URL_PARAM.VIEW);

  if (!status || !reimbursementItemsCount) {
    return <EmptyReimbursementDetailsCard />;
  }

  const fallbackAmount = documentMoneyFormatter({
    precision: 2,
    amount: 0,
    currency: DocumentCurrency.Eur,
  });

  return (
    <ScrollBox scrollDirection="none" scrollbarGutter="stable">
      <Card padding="space16">
        <Grid gap="space12">
          <Grid gap="space12">
            <Grid
              paddingRight="space8"
              autoFlow="column"
              justifyContent="space-between"
              gap="space10"
            >
              <TruncatedText
                fontSize="large"
                fontWeight="semibold"
                height="fit-content"
              >
                {title ?? t('dashboard.list.reimbursementItemPlaceholder')}
              </TruncatedText>
              <Tag variant="secondary" color={tags[status].color}>
                {tags[status].text}
              </Tag>
            </Grid>
            {Boolean(items.length) && isItemsViewActive && (
              <ReimbursementItemsCard
                items={items}
                creatingReimbursementItem={creatingReimbursementItem}
                onGeneralExpenseClick={onGeneralExpenseClick}
                onHospitalityExpenseClick={onHospitalityExpenseClick}
              />
            )}

            <Grid
              paddingRight="space8"
              autoFlow="column"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Text fontSize="large" fontWeight="semibold">
                {t('reimbursementView.leftSection.filledDetails.description')}
              </Text>
              <Text fontSize="large" fontWeight="semibold">
                {totalAmount ?? fallbackAmount}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </ScrollBox>
  );
};
