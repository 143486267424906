import React, { ReactNode, useRef, useState } from 'react';
import { mergeProps, useLabel } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { useTheme } from '../../Theme';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { Box, BoxProps } from '../Box';
import { Flex } from '../Flex';
import { Grid, GridProps } from '../Grid';
import { Icon, IconProps } from '../Icon';
import { MessageBox } from '../MessageBox';
import { Tooltip, useTooltip } from '../Tooltip';
import { Text } from '../Typography/Text';
import { ContentBox } from './ContentBox';
import { FieldContext } from './FieldContext';
import { Label } from './Label';
import { LoadingSkeleton } from './LoadingSkeleton';

export type Variant = 'default' | 'error' | 'warning' | 'success';
type Layout = 'default' | 'compact';
type Color = 'default' | 'white';
type Radii = 'default' | 'rounded';

export type Description = {
  text: string;
  addOn?: React.ReactNode;
  color: 'error' | 'warning';
};

export interface FieldContainerProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLDivElement> {
  label?: React.ReactNode;
  children?: React.ReactNode;
  variant?: Variant;
  color?: Color;
  radii?: Radii;
  message?: React.ReactNode;
  description?: Description;
  readOnly?: boolean;
  disabled?: boolean;
  optionalHint?: string;
  layout?: Layout;
  transparent?: boolean;
  wrapperGridProps?: GridProps;
  wrapperBoxProps?: BoxProps;
  isLoading?: boolean;
  infoTooltip?: {
    message: ReactNode;
    iconProps?: IconProps;
  };
}

/**
 * FieldContainer wrapper for given input.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-forms-fieldcontainer}
 *
 * @param {React.ReactNode} [label] Input field label
 * @param {React.ReactNode} [children] Content
 * @param {Variant} [variant='default'] Visual variants of the FieldContainer
 * @param {Color} [color='default'] Background color of the FieldContainer
 * @param {Radii} [radii='default'] Border radius of the FieldContainer
 * @param {React.ReactNode} [message] Tooltip message
 * @param {Description} [description] Field description
 * @param {boolean} [readOnly] Is Input field read-only
 * @param {boolean} [disabled] Is Input field disabled
 * @param {string} [optionalHint] Hint displayed next to the label about the field being optional
 * @param {Layout} [layout='default'] Display field container with default or compact layout.
 * @param {boolean} [transparent] Display field container with transparent background.
 * @param {boolean} [isLoading] Form data loading state to display the input and label skeleton
 */
export const FieldContainer = React.forwardRef<
  HTMLDivElement,
  FieldContainerProps
>(
  (
    {
      'aria-describedby': ariaDescribedBy,
      'aria-details': ariaDetails,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledby,
      wrapperGridProps,
      wrapperBoxProps,
      children,
      disabled,
      id,
      label,
      layout = 'default',
      transparent,
      message,
      description,
      optionalHint,
      infoTooltip,
      readOnly,
      variant = 'default',
      color = 'default',
      radii = 'default',
      isLoading,
      ...restProps
    },
    forwardedRef
  ) => {
    const disabledOrReadOnlyState = disabled
      ? 'disabled'
      : readOnly
        ? 'readOnly'
        : null;

    const isDisabledHasTooltip = disabled && message;

    const { colors, fieldContainer } = useTheme();

    const { open, close, triggerProps, triggerRef } = useTooltip({ delay: 0 });

    const {
      isOpen: isIconTooltipOpen,
      tooltipProps: iconTooltipProps,
      tooltipRef: iconTooltipRef,
      triggerProps: iconTriggerProps,
      triggerRef: iconTriggerRef,
    } = useTooltip({ delay: 0 });

    const fieldContainerRef = useRef<HTMLDivElement>(null);
    const labelRef = useRef<HTMLDivElement>(null);
    const hintRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLElement>(null);
    const descriptionRef = useRef<HTMLDivElement>(null);

    const [fieldContainerElement, setFieldContainerElement] =
      useState<HTMLDivElement | null>(null);

    const { fieldProps, labelProps } = useLabel({
      'aria-describedby': ariaDescribedBy,
      'aria-details': ariaDetails,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledby,
      id,
      label,
    });

    const fieldContextValue: FieldContext = {
      label,
      inputRef,
      inputProps: {
        'aria-describedby': triggerProps['aria-describedby'],
        'aria-invalid': variant === 'error',
        onInput: () => {
          close();
        },
        onClick: e => {
          e.stopPropagation();
        },
        readOnly,
        disabled,
        ...fieldProps,
      },
      inputCss: disabledOrReadOnlyState
        ? fieldContainer[disabledOrReadOnlyState].input
        : undefined,
      fieldContainerElement,
    };

    const defaultProps = {
      onFocus: () => {
        (disabled || variant === 'error') && message && open();
      },
      onBlur: () => {
        message && close();
      },
      onClick: () => {
        if (inputRef?.current) {
          // programmatic click() does not actually focus an input field as it does not trigger the necessary `onPointerDown` event
          // so we also programmatically focus() to make sure we cover all scenarios
          inputRef.current.focus();
          inputRef.current.click();
        }
      },
      // otherwise clicking a div resets the focus and therefore closes the list box, as we pass `shouldCloseOnBlur` to ListBoxPopup
      onPointerDown: (e: React.SyntheticEvent) => {
        if (e.target === e.currentTarget) {
          e.preventDefault();
        }
      },
    };

    const combinedProps = mergeProps(defaultProps, restProps);

    const getBackgroundStyle = (state: 'default' | 'hovered' | 'focused') => {
      if (variant === 'warning') {
        return fieldContainer.background?.warning[state];
      }

      if (variant === 'error') {
        return fieldContainer.background?.error[state];
      }

      if (!readOnly && color !== 'white') {
        return fieldContainer.background?.default[state];
      }

      if (color === 'white') return fieldContainer.background?.white[state];

      return undefined;
    };

    const fieldContainerBorderRadius = fieldContainer.radii[radii];

    const fieldWrapperStyle = {
      transparent: {
        background: 'none',
        hover: undefined,
      },
      readOnly: {
        background: colors.gray200,
        borderRadius: fieldContainerBorderRadius,
        border: `${colors.gray300} 1px solid`,
        paddingX: radii === 'rounded' ? 'space16' : 'space10',
        paddingY: 'space8',
        minHeight: 'space40',
        hover: undefined,
        cursor: 'default',
        paddingTop: 0,
        paddingBottom: 0,
        tabIndex: -1,
      },
      disabled: {
        cursor: 'not-allowed',
        // so disabled/readonly fields can still be focused by screen readers
        tabIndex: -1,
        hover: undefined,
      },
      default: {
        transition: 'all 0.2s ease-in-out',
        templateColumns: '1fr auto',
        cursor: 'pointer',
        alignItems: 'center',
        ...{ ...getBackgroundStyle('default') },
        borderRadius: fieldContainerBorderRadius,
        border: `${fieldContainer.color[color].borderColor} 1px solid`,
        paddingX: radii === 'rounded' ? 'space16' : 'space10',
        paddingY: 'space8',
        minHeight: 'space40',
        hover: { ...{ ...getBackgroundStyle('hovered') } },
      },
    };

    const focusStyle = {
      default: {
        ':focus-within': {
          ...{ ...getBackgroundStyle('focused') },
        },
      },
      readOnly: {
        ':focus-within': undefined,
      },
      transparent: {
        ':focus-within': undefined,
        border: 'none',
      },
      disabled: {
        ':focus-within': {
          background: undefined,
          outline: `1px solid ${colors.blue500}`,
        },
      },
    };

    const defaultInfoIconProps = {
      color: 'gray450',
      icon: 'infoOutline',
      size: 'space16',
    };

    return (
      <Flex
        gap="space4"
        direction="column"
        justifyContent="center"
        {...mergeProps(combinedProps, isDisabledHasTooltip ? triggerProps : {})}
        // manually unsetting onClicks that may have been passed
        onClick={disabled ? undefined : combinedProps.onClick}
        ref={
          // when the field is disabled, the tooltip should appear in a centered
          // position when hovering over the field so we need to conditionally
          // set triggerRef and triggerProps where it belongs
          isDisabledHasTooltip
            ? mergeRefs([forwardedRef, setFieldContainerElement, triggerRef])
            : mergeRefs([forwardedRef, setFieldContainerElement])
        }
      >
        {label && (
          <Flex gap="space8" alignItems="center">
            <Label
              {...labelProps}
              css={{
                cursor: 'inherit',
                // Prevent label click from triggering a click on associated
                // input. We handle that programmatically above.
                pointerEvents: 'none',
              }}
              readOnly={readOnly}
              disabled={disabled}
              isLoading={isLoading}
            >
              <Flex gap="space4" alignItems="center">
                <Box>
                  <Box css={{ display: 'inline-block' }} ref={labelRef}>
                    <ContentBox element={labelRef} isLoading={isLoading}>
                      {label}
                    </ContentBox>
                  </Box>
                  <LoadingSkeleton isLoading={isLoading} element={labelRef} />
                </Box>
                {optionalHint && (
                  <Text color={isLoading ? 'transparent' : 'gray500'}>
                    <Box css={{ display: 'inline-block' }} ref={hintRef}>
                      <ContentBox element={hintRef} isLoading={isLoading}>
                        {`(${optionalHint})`}
                      </ContentBox>
                    </Box>
                    <LoadingSkeleton isLoading={isLoading} element={hintRef} />
                  </Text>
                )}
              </Flex>
            </Label>
            {infoTooltip?.message && (
              <>
                <Icon
                  {...mergeProps(
                    iconTriggerProps,
                    defaultInfoIconProps,
                    infoTooltip.iconProps
                  )}
                  ref={iconTriggerRef}
                />
                {isIconTooltipOpen && (
                  <Tooltip {...iconTooltipProps} ref={iconTooltipRef}>
                    {infoTooltip?.message}
                  </Tooltip>
                )}
              </>
            )}
          </Flex>
        )}
        <Box flex={1} ref={fieldContainerRef}>
          <FieldContext.Provider value={fieldContextValue}>
            <ContentBox
              element={fieldContainerRef}
              isLoading={isLoading}
              {...wrapperBoxProps}
            >
              <Grid
                css={{
                  outline: '1px solid transparent',
                  ...focusStyle.default,
                  ...(disabled ? focusStyle.disabled : undefined),
                  ...(readOnly ? focusStyle.readOnly : undefined),
                  ...(transparent ? focusStyle.transparent : undefined),
                }}
                {...fieldWrapperStyle.default}
                {...(disabled ? fieldWrapperStyle.disabled : undefined)}
                {...(readOnly ? fieldWrapperStyle.readOnly : undefined)}
                {...(transparent || isLoading
                  ? fieldWrapperStyle.transparent
                  : undefined)}
                {...wrapperGridProps}
              >
                {children}
              </Grid>
            </ContentBox>
            <LoadingSkeleton
              isLoading={isLoading}
              element={fieldContainerRef}
              borderRadius={fieldContainerBorderRadius}
            />
          </FieldContext.Provider>
        </Box>
        {description &&
          (description.color === 'warning' ? (
            <MessageBox
              variant="warning"
              message={description.text}
              isLoading={isLoading}
              additionalContent={description.addOn}
              ref={descriptionRef}
            />
          ) : (
            <Box>
              <Text
                color={
                  isLoading
                    ? 'transparent'
                    : fieldContainer.description[description.color].color
                }
                css={{
                  display: 'inline-block',
                  ...(description.addOn && { paddingRight: '0.25rem' }),
                }}
                ref={descriptionRef}
              >
                <ContentBox element={descriptionRef} isLoading={isLoading}>
                  {description.text}
                </ContentBox>
              </Text>
              {description.addOn}
              <LoadingSkeleton isLoading={isLoading} element={descriptionRef} />
            </Box>
          ))}
      </Flex>
    );
  }
);
