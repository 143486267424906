import {
  ReimbursementCaseItemAddedEventDataFragment,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Text } from '@candisio/design-system';

export const ReimbursementCaseItemAdded = ({
  properties,
}: ReimbursementCaseItemAddedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const itemType =
    properties.expenseItemType === ReimbursementItemType.GeneralExpense
      ? t(
          'reimbursementView.leftSection.reimbursementItems.placeholder.general'
        )
      : t(
          'reimbursementView.leftSection.reimbursementItems.placeholder.hospitality'
        );
  return <Text>{t('timeline.itemCreated', { expenseType: itemType })}</Text>;
};
