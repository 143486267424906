import { OverlayProvider } from '@candisio/design-system';
import { Helmet } from 'components/Helmet/Helmet';
import { History } from 'history';
import { AppRouteParams, Routes } from 'models';
import 'moment/dist/locale/de';
import 'moment/dist/locale/en-gb';
import { ExportNotificationsProvider } from 'providers/ExportNotificationsProvider';
import { GraphQLProvider } from 'providers/GraphQLProvider';
import {
  FullOrganizationProvider,
  OrganizationProvider,
} from 'providers/OrganizationProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { Route, Router, Switch } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { ProtectedHOC } from 'router/Protected';
import { CompatRoute } from 'router/SentryRouting';
import { ApiAuthCodePage } from 'views/ApiAuthCodePage/ApiAuthCodePage';
import { SlackRedirectComponent } from 'views/AppContainer/components/Header/components/UserProfile/NotificationIntegration/SlackRedirectComponent';
import { IamBaseHandler } from 'views/IamHandlers/IamBaseHandler';
import { CurrentUserProvider } from './providers/CurrentUserProvider';
import { AppContainer } from './views/AppContainer';
import { PendingAuthDatev } from './views/AuthDatev';
import { IntegrationsExportProvider } from './views/Integrations/Export/Context';
const ProtectedRoute = ProtectedHOC(CompatRoute);

export const App = ({ history }: { history: History }) => {
  return (
    <OrganizationProvider>
      <GraphQLProvider>
        <FullOrganizationProvider>
          <CurrentUserProvider>
            <ExportNotificationsProvider>
              <Helmet />
              <OverlayProvider>
                <Router history={history}>
                  <CompatRouter>
                    <IntegrationsExportProvider>
                      <Switch>
                        <CompatRoute
                          exact
                          path={Routes.AUTHENTICATION_CODE}
                          component={ApiAuthCodePage}
                        />
                        <Route
                          exact
                          path={Routes.SLACK_REDIRECT}
                          component={SlackRedirectComponent}
                        />
                        <CompatRoute
                          path={Routes.IAM_BASE}
                          component={IamBaseHandler}
                        />
                        <CompatRoute
                          exact
                          path={Routes.AUTH_DATEV}
                          component={PendingAuthDatev}
                        />
                        <ProtectedRoute
                          path={`/:${AppRouteParams.organizationSlug}`}
                          component={AppContainer}
                        />
                        {/* All routes above this line. This is a catch all */}
                        <ProtectedRoute path="" component={AppContainer} />
                      </Switch>
                    </IntegrationsExportProvider>
                  </CompatRouter>
                </Router>
              </OverlayProvider>
            </ExportNotificationsProvider>
          </CurrentUserProvider>
        </FullOrganizationProvider>
      </GraphQLProvider>
    </OrganizationProvider>
  );
};
