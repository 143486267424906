import { Box } from '@candisio/design-system';

export interface MixedProgressBarProps {
  creditLimitPercentage: number;
  prefundPercentage: number;
}

export const MixedProgressBar = ({
  creditLimitPercentage,
  prefundPercentage,
}: MixedProgressBarProps) => {
  return (
    <Box
      height="space8"
      width="100%"
      background="gray300"
      position="relative"
      borderRadius={12}
    >
      <Box
        borderRadius="inherit"
        borderTopRightRadius="0px"
        borderBottomRightRadius="0px"
        maxWidth="100%"
        height="100%"
        minWidth="0%"
        background="gray600"
        // We want to have some tiny space between the 2 progress bars hence the - 0.1
        width={`${creditLimitPercentage - 0.1}%`}
      />
      <Box
        borderRadius="inherit"
        position="absolute"
        top={0}
        right={0}
        borderTopLeftRadius="0px"
        borderBottomLeftRadius="0px"
        maxWidth="100%"
        height="100%"
        minWidth="0%"
        background="blue400"
        width={`${prefundPercentage}%`}
      />
    </Box>
  );
};
