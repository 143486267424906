import { DocumentTableColumnKeys } from 'components/DocumentsTable/types';
import { ExportableEntityType } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { Routes, AppRouteParams } from 'models';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import {
  useFullOrganization,
  useOrganizationId,
} from 'providers/OrganizationProvider';
import { useContext } from 'react';
import { useNavigate, generatePath } from 'react-router-dom-v5-compat';
import { ExportContext } from 'views/Integrations/Export/Context';
import { ExportEntityRow } from 'views/Integrations/Export/types';

export const addTypeAndPaymentPeriod =
  (organization: ReturnType<typeof useFullOrganization>) =>
  (settlement: ExportEntityRow) =>
    ({
      ...settlement,
      type: ExportableEntityType.CardSettlement,
      period: organization?.creditCardsDetails?.paymentFrequency ?? 'UNKNOWN',
    }) satisfies ExportEntityRow;

export const toDocumentTableData =
  (failedDocuments: { id: string; invoiceNumber: string }[]) =>
  (row: ExportEntityRow) => {
    const { amount, currency, precision = 0 } = row.amount;
    const normalizedAmount = amount / 10 ** precision;

    const exportErrorStatus = {
      exportError: {
        ...row.exportStatus?.exportError,
        failedDocuments,
      },
    };

    return {
      id: row.id,
      period: row.period,
      invoiceDate: row.invoiceDate,
      invoiceNumber: row.invoiceNumber,
      grossAmount: { amount: normalizedAmount, currency },
      settlementPaymentStatus: row.settlementPaymentStatus,
      isDisabled: row.isDisabled,
      canBeSelectedTooltipText: row.disableReason,
      hasDocument: row.hasDocument,
      statement: row.statementId,
      exportStatus: row.exportStatus
        ? {
            ...row.exportStatus,
            ...(row.exportStatus?.exportError && exportErrorStatus),
          }
        : undefined,
    };
  };

const defaultColumns: Array<DocumentTableColumnKeys> = [
  'settlementPaymentStatus',
  'invoiceNumber',
  'period',
  'invoiceDate',
  'grossAmount',
] as const;

export const useColumns = () => {
  const { exportId, isReExport } = useContext(ExportContext);
  const creditCardsStatementsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsStatements
  );

  const hasExportStatus = !!exportId && !isReExport;

  const columns = Array<DocumentTableColumnKeys | false>()
    .concat(
      hasExportStatus && 'exportStatus',
      defaultColumns,
      creditCardsStatementsFF && 'statement'
    )
    .filter(Boolean) as DocumentTableColumnKeys[];

  return columns;
};

export const useSettlementRowClick = () => {
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();

  const creditCardsStatementsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsStatements
  );

  return ({
    statement,
    isDisabled,
  }: {
    statement?: string | undefined;
    isDisabled?: boolean;
  }) => {
    if (!creditCardsStatementsFF || isDisabled || !statement) {
      return;
    }

    const statementLink = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.CREDIT_CARDS_DASHBOARD}${Routes.STATEMENTS}/:statementId?`,
      { organizationSlug, statementId: statement }
    );

    navigate(statementLink);
  };
};
