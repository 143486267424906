import { Radio, RadioGroup, Text } from '@candisio/design-system';
import {
  useCreateCreditCardModalSetState,
  useCreateCreditCardModalState,
} from 'containers/credit-cards/CreateCreditCard/CreateCreditCardModalDataProvider';
import { CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const radioButtons = [
  { id: CardType.Virtual, label: 'ccRequestForm.categoryPage.VIRTUAL' },
  { id: CardType.Physical, label: 'ccRequestForm.categoryPage.PHYSICAL' },
];

export const CardTypeField = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { cardType } = useCreateCreditCardModalState();
  const setModalState = useCreateCreditCardModalSetState();

  return (
    <RadioGroup
      orientation="horizontal"
      defaultValue={cardType}
      aria-label={t('ccRequestForm.limitsForm.cardType')}
      onChange={value => {
        setModalState(prevState => ({
          ...prevState,
          cardType: value as Exclude<CardType, CardType.Black>,
          cardDetails: undefined,
        }));
      }}
    >
      {radioButtons.map(itm => (
        <Radio key={itm.id} value={itm.id}>
          <Text fontSize="basic">{t(itm.label)}</Text>
        </Radio>
      ))}
    </RadioGroup>
  );
};
