import { Grid, Paragraph, Text } from '@candisio/design-system';
import { MetaDataWrapper } from 'components/DocumentHistory/MetaDataWrapper';
import {
  ExpenseItemTitleUpdatedEventDataFragment,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const ExpenseItemTitleUpdated = ({
  properties,
  reimbursementItemMetadata,
}: ExpenseItemTitleUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const expenseTitle = properties.oldTitle ? properties.oldTitle : undefined;

  const headline = expenseTitle
    ? t('timeline.expenseChanged', { title: expenseTitle })
    : t('timeline.expenseChangedNoTitle');

  return (
    <Grid>
      <Text>{headline}</Text>
      <MetaDataWrapper>
        <div>
          <Text fontWeight="regular" color="gray500">
            {reimbursementItemMetadata?.type ===
            ReimbursementItemType.HospitalityExpense
              ? t(
                  'reimbursementView.middleSection.form.hospitality.reason.label'
                )
              : t('reimbursementView.middleSection.form.general.reason.label')}
          </Text>
          <Paragraph>
            {properties.newTitle ? properties.newTitle : t('timeline.empty')}
          </Paragraph>
        </div>
      </MetaDataWrapper>
    </Grid>
  );
};
