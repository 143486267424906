import { useEcm } from 'orgConfig/ecm/useEcm';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DataFunc,
  Mention,
  OnChangeHandlerFunc,
  SuggestionFunc,
} from 'react-mentions';
import { CommentMentionsSuggestion } from './CommentMentionsSuggestion';
import { CommentMentionsSuggestionContainer } from './CommentMentionsSuggestionContainer';
import { COMMENT_LENGTH_MAX, COMMENT_TEST_ID } from './constants';
import { MentionsInput } from './styles';
import { CommentFormValues, CommentProps } from './types';
import { useMentionsInputSyles } from './useMentionsInputSyles';

export type CommentMentionsInputProps = {
  allowMentions?: boolean;
  isDisabled: boolean;
  memberships: CommentProps['memberships'];
};

export const CommentMentionsInput = ({
  allowMentions,
  isDisabled,
  memberships,
}: CommentMentionsInputProps) => {
  const hostRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const form = useFormContext<CommentFormValues>();
  const { isDocumentRelationsAvailable } = useEcm();
  const inlineStyles = useMentionsInputSyles(form.watch('mention'));

  const placeholder = t(
    allowMentions
      ? 'comment.placeholder'
      : 'comment.mentioningRestrictedPlaceholder'
  );

  const dataFn: DataFunc = query => {
    if (!allowMentions) {
      return [];
    }

    return memberships
      .filter(member => member.name.toLowerCase().includes(query.toLowerCase()))
      .map(member => ({
        id: member.id,
        display: member.name,
      }));
  };

  const handleChange: OnChangeHandlerFunc = (
    event,
    nextValue,
    nextPlainValue,
    mentions
  ) => {
    form.setValue('mention', nextValue);
    form.setValue('mentionPlain', nextPlainValue);
    form.setValue(
      'mentionedUserIds',
      mentions.map(mention => mention.id)
    );
  };

  const renderSuggestion: SuggestionFunc = (
    suggestion,
    search,
    highlightedDisplay,
    index,
    focused
  ) => {
    const member = memberships.find(member => member.id === suggestion.id);

    return member ? (
      <CommentMentionsSuggestion isFocused={focused} {...member} />
    ) : null;
  };

  return (
    <div ref={hostRef}>
      <Controller
        control={form.control}
        name="mention"
        render={({ field }) => (
          <MentionsInput
            allowSpaceInQuery
            allowSuggestionsAboveCursor
            customSuggestionsContainer={children => (
              <CommentMentionsSuggestionContainer>
                {children}
              </CommentMentionsSuggestionContainer>
            )}
            data-cy={COMMENT_TEST_ID}
            data-show-document-relations={isDocumentRelationsAvailable}
            disabled={isDisabled}
            maxLength={COMMENT_LENGTH_MAX}
            onChange={handleChange}
            placeholder={placeholder}
            style={inlineStyles}
            suggestionsPortalHost={hostRef.current as Element}
            value={field.value}
          >
            <Mention
              appendSpaceOnAdd
              data={dataFn}
              displayTransform={(_, display) => `@${display}`}
              renderSuggestion={renderSuggestion}
              trigger="@"
            />
          </MentionsInput>
        )}
      />
    </div>
  );
};
