import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

type NameInputFieldProps = {
  readOnly: boolean;
};

export const NameInputField = ({ readOnly }: NameInputFieldProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <HookFormTextField
      autoFocus={!readOnly}
      name="projectName"
      label={t('projectCodes.form.name.label')}
      readOnly={readOnly}
    />
  );
};
