import { Item, Link } from '@candisio/design-system';
import { useProcessingFormTypeFieldWarning } from 'components/Form/ProcessingForm/useProcessingFormTypeFieldWarning';

import { useEcm } from 'orgConfig/ecm/useEcm';
import { useTranslation } from 'react-i18next';
import { useDocumentTypeContext } from 'views/DocumentDetails/components/DocumentTypeContext';
import {
  DocumentTypesFieldItem,
  UseDocumentTypeFieldOptionsResult,
} from '../hooks/useDocumentTypeFieldOptions';
import { ProcessingFormComboBoxField } from './ProcessingFormComboBoxField';
import { ProcessingFormSelectFieldProps } from './ProcessingFormSelectField';
import { useEffect } from 'react';
import { ProcessingFormValues } from './processingFormSchema';
import { useWatch } from 'react-hook-form';
import { DocumentConversionWarningContainer } from 'containers/conversion/DocumentConversionWarningContainer';

export const ProcessingFormGlobalTypeField = ({
  name,
  items: _items,
  newItems,
  groupedItems,
  globalDocumentId,

  ...restProps
}: ProcessingFormSelectFieldProps &
  UseDocumentTypeFieldOptionsResult & {
    globalDocumentId: string;
  }) => {
  const [t] = useTranslation();
  const { showEcm } = useEcm();
  const { documentType, setDocumentType, showConversionNeededWarning } =
    useDocumentTypeContext();

  // we need to move all this logic into this layer as we need to access the form values
  const shouldShowWarning = useProcessingFormTypeFieldWarning();

  const type = useWatch<ProcessingFormValues, 'type'>({
    name: 'type',
  });
  const newDocumentType = newItems?.find(item => item.key === type);
  const areDocumentTypesDifferent =
    documentType?.documentType !== newDocumentType?.key;

  useEffect(() => {
    if (areDocumentTypesDifferent) setDocumentType(newDocumentType?.value);
  }, [areDocumentTypesDifferent, newDocumentType?.value, setDocumentType]);

  return (
    <>
      <ProcessingFormComboBoxField
        label=""
        description={
          shouldShowWarning
            ? {
                color: 'warning',
                text: t(
                  'document.requestApproval.inputs.document.rds1-0-warning.text'
                ),
                // XXX as the InlineSkeleton would wrap the Link with a div the UI would break
                // so best we can do is not show this at all while loading
                addOn: restProps.isLoading ? null : (
                  <Link
                    external
                    href={t(
                      'document.requestApproval.inputs.document.rds1-0-warning.link.url'
                    )}
                  >
                    {t(
                      'document.requestApproval.inputs.document.rds1-0-warning.link.text'
                    )}
                  </Link>
                ),
              }
            : undefined
        }
        name={name}
        items={newItems}
        groupedLists={groupedItems}
        groupHeaders={[
          t('ecm:formSwitcher.approval'),
          ...(showEcm ? [t('ecm:formSwitcher.storage.title')] : []),
        ]}
        isGroupedListMode
        onChange={newValue => {
          const newDocumentType = newItems?.find(item => item.key === newValue);
          setDocumentType(newDocumentType?.value);
        }}
        {...restProps}
      >
        {(item: DocumentTypesFieldItem) => (
          <Item key={item.key} textValue={item.label}>
            {item.label}
          </Item>
        )}
      </ProcessingFormComboBoxField>
      {showConversionNeededWarning && (
        <DocumentConversionWarningContainer
          globalDocumentId={globalDocumentId}
          documentType={newDocumentType?.value.documentType ?? ''}
          documentTypeLabel={newDocumentType?.label ?? ''}
        />
      )}
    </>
  );
};
