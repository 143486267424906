import {
  Button,
  Grid,
  Popover,
  PopoverProps,
  Text,
  Flex,
  TextField,
  Box,
  Spinner,
} from '@candisio/design-system';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import {
  paginationFiltersHooks as defaultPaginationFiltersHooks,
  PaginationFiltersHooksType,
} from './hooks/paginationFiltersHook';
import { ItemOptionMemoized } from './ItemOptionMemoized';
import { toUniqueOptions } from './utils';

const ITEM_HEIGHT = 34;

const MotionGrid = motion(Grid);

const spinnerVariants: Variants = {
  hidden: {
    opacity: 0,
    transition: {
      delay: 0.5,
    },
  },
  show: {
    opacity: 1,
    transition: { duration: 0.3, type: 'tween' },
  },
};

export interface FilterPopoverProps extends PopoverProps {
  filterValue: string[];
  onApply: (filters: string[]) => void;
  onReset: () => void;
  columnName: string;
  paginationFiltersHooks?: PaginationFiltersHooksType;
}

export const FilterPopover = forwardRef<HTMLDivElement, FilterPopoverProps>(
  (
    {
      filterValue = [],
      onApply,
      onReset,
      columnName,
      paginationFiltersHooks = defaultPaginationFiltersHooks,
      ...restProps
    },
    ref
  ) => {
    const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
    const [searchStr, setSearchStr] = useState('');

    const [currentFilters, setCurrentFilters] = useState<string[]>(filterValue);

    const usePaginationWithSearchFilter = paginationFiltersHooks[columnName];

    const { filterOptions, handleDebounceSearch, loadMore, loading } =
      usePaginationWithSearchFilter({ filteredValues: filterValue, searchStr });

    const uniqueOptions = toUniqueOptions(filterOptions);

    const sortedOptions = [
      ...uniqueOptions.filter(f => filterValue.includes(f.id)),
      ...uniqueOptions.filter(f => !filterValue.includes(f.id)),
    ];

    const searchInputId = `column-filter-input-${columnName}`;

    const resetSearch = () => {
      handleDebounceSearch?.('');
    };

    const submitSearch = (e: { preventDefault: () => void }) => {
      e.preventDefault();
      if (sortedOptions.length !== 1) {
        return;
      }

      onApply([sortedOptions[0].id]);
    };

    return (
      <Popover {...restProps} ref={ref} minWidth="300px">
        <Grid height="100%" padding="space16" maxHeight="inherit" gap="space8">
          <Box as="form" onSubmit={submitSearch}>
            <TextField
              flex="none"
              // @TODO design system should support a “compact” variant
              // without padding
              id={searchInputId}
              style={{ paddingLeft: 0 }}
              input={{
                value: searchStr,
                placeholder: t('filterSearchPlaceholder'),
                onChange: e => {
                  handleDebounceSearch?.(e.target.value);
                  setSearchStr(e.target.value);
                },
              }}
            />
          </Box>

          {sortedOptions.length === 0 && !loading ? (
            <Text justifySelf="center">
              {t('filterWithPagination.nothingFound')}
            </Text>
          ) : (
            <Grid
              id={`column-filter-options-${columnName}`}
              role="menu"
              paddingY="space16"
              // TODO: figure out a better way to scale the height
              height={`${
                ITEM_HEIGHT * sortedOptions.length > 256
                  ? 256
                  : ITEM_HEIGHT * sortedOptions.length
              }px`}
              minHeight="200px"
              maxHeight="space256"
            >
              <Virtuoso
                endReached={loadMore}
                overscan={200}
                style={{ height: '100%', overflowX: 'hidden' }}
                data={sortedOptions}
                totalCount={sortedOptions.length}
                itemContent={(_index, filter) => (
                  <ItemOptionMemoized
                    currentFilters={currentFilters}
                    setCurrentFilters={setCurrentFilters}
                    id={filter.id}
                    label={filter.label}
                  />
                )}
              />
            </Grid>
          )}
          <Flex justifyContent="space-between">
            <Button
              variant="tertiary"
              onClick={() => {
                resetSearch();
                onReset();
              }}
            >
              {t('filterReset')}
            </Button>

            <AnimatePresence initial={false}>
              {loading && (
                <MotionGrid
                  variants={spinnerVariants}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  key="loading-indicator"
                  placeContent="center"
                >
                  <Spinner size="space24" />
                </MotionGrid>
              )}
            </AnimatePresence>
            <Button
              variant="secondary"
              onClick={() => {
                resetSearch();
                onApply(currentFilters);
              }}
            >
              {t('filterApply')}
            </Button>
          </Flex>
        </Grid>
      </Popover>
    );
  }
);
