import {
  Box,
  Grid,
  InlineSkeleton,
  Link,
  mergeProps,
} from '@candisio/design-system';
import { HookFormApproversFieldArray } from 'components/HookFormFields/HookFormApproversFieldArray/HookFormApproversFieldArray';
import { isNil } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProcessingFormFieldOptions } from './ProcessingForm';
import { ProcessingFormApproversField } from './ProcessingFormApproversField';
import { ProcessingFormValues } from './processingFormSchema';
import { ProcessingFormWorkFlowField } from './ProcessingFormWorkflowField';

export interface ProcessingFormApprovalFieldsProps {
  /** Approvers field options */
  approversFieldOptions?: ProcessingFormFieldOptions['approvers'];
  /** Called when workflow editor should be opened */
  onCreateWorkflow?: () => void;
  /** Workflow field options */
  workflowFieldOptions?: ProcessingFormFieldOptions['workflow'];
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean | undefined;
}

/** Switches between workflow field and manual approvers field array */
export const ProcessingFormApprovalFields = ({
  approversFieldOptions,
  onCreateWorkflow,
  workflowFieldOptions,
  isLoading,
  ...restProps
}: ProcessingFormApprovalFieldsProps) => {
  const [t] = useTranslation();
  const { setValue } = useFormContext<ProcessingFormValues>();
  const approvalMode = useWatch<ProcessingFormValues>({ name: 'approvalMode' });
  const workflow = useWatch<ProcessingFormValues>({ name: 'workflow' });

  const hasWorkflows = !workflowFieldOptions?.hidden;

  return (
    <Grid gap="space4">
      {approvalMode === 'workflow' ? (
        <ProcessingFormWorkFlowField
          name="workflow"
          label={t('document.requestApproval.inputs.workflow.label')}
          placeholder={t(
            'document.requestApproval.inputs.workflow.placeholder'
          )}
          {...mergeProps(workflowFieldOptions?.props, restProps)}
          forceInputFieldRef
        />
      ) : (
        <HookFormApproversFieldArray<ProcessingFormValues>
          name="approvers"
          approversFieldOptions={approversFieldOptions}
          renderApprovalStep={props => (
            <ProcessingFormApproversField
              forceInputFieldRef
              isLoading={isLoading}
              {...mergeProps(props, restProps)}
            />
          )}
          isLoading={isLoading}
        />
      )}
      <Box>
        <InlineSkeleton isLoading={isLoading}>
          <Link
            as="button"
            style={{ textAlign: 'left' }}
            external={!hasWorkflows}
            onClick={() => {
              if (hasWorkflows) {
                setValue(
                  'approvalMode',
                  approvalMode === 'workflow' ? 'approvers' : 'workflow'
                );
              } else {
                onCreateWorkflow?.();
              }
            }}
          >
            {hasWorkflows
              ? approvalMode === 'workflow'
                ? isNil(workflow)
                  ? t(
                      'document.requestApproval.inputs.workflow.selectApproversManually'
                    )
                  : t(
                      'document.requestApproval.inputs.workflow.editWorkflowLocally'
                    )
                : t(
                    'document.requestApproval.inputs.approvalStep.selectExistingWorkflow'
                  )
              : t(
                  'document.requestApproval.inputs.approvalStep.createWorkflow'
                )}
          </Link>
        </InlineSkeleton>
      </Box>
    </Grid>
  );
};
