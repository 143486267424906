export enum ReimbursementErrorType {
  DateTooFarInFuture = 'DATE_TOO_FAR_IN_FUTURE',
  DateTooFarInPast = 'DATE_TOO_FAR_IN_PAST',
  InvalidBookings = 'INVALID_BOOKINGS',
  InvalidNumber = 'INVALID_NUMBER',
  Missing = 'MISSING',
  NotFound = 'NOT_FOUND',
  NumberLessThan1 = 'NUMBER_LESS_THAN_1',
  InvalidArrayContent = 'INVALID_ARRAY_CONTENT',
}
