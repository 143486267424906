import {
  Button,
  Flex,
  Grid,
  Link,
  Spinner,
  Text,
  useId,
} from '@candisio/design-system';
import { HookFormComboBoxField } from 'components/HookFormFields/HookFormComboBoxField';
import { ErrorIcon } from 'components/Icons/DefaultIcons';
import {
  DatevConnectionType,
  New_DatevSettingsQuery,
  useConnectDatevClientMutation,
  useDisconnectDatevClientMutation,
  useNew_DatevClientsQuery,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { zodResolver } from 'utils/zodFormValidation';
import { SendEmailModal } from '../../../components/SendEmailModal';
import { useDatevAuthorizeForExportDocuments } from '../../datevAuthHooks';
import { refetchDatevData, refetchDatevDataWithNoToken } from '../../gql';
import { DatevClient } from '../../types';
import { DcsSettingsContainer } from '../DcsContainer/DcsSettingsContainer';
import {
  SelectClientFormOutput,
  selectClientFormSchema,
  SelectClientFormErrorMessages,
} from './SelectClientFormSchema';

const OPEN_EMAIL_MODAL = 'emailModal';

type SelectClientContainerProps = {
  settingsData?: New_DatevSettingsQuery;
  onReconnect: (connectionType?: DatevConnectionType) => Promise<void>;
  reconnecting?: boolean;
};

export const SelectClientContainer = ({
  settingsData,
  onReconnect,
  reconnecting,
}: SelectClientContainerProps) => {
  const [t] = useTranslation();
  const organization = useFullOrganization();
  const { data: clientsData, loading, error } = useNew_DatevClientsQuery();

  const navigate = useNavigate();

  const [authorizeForExportDocuments] = useDatevAuthorizeForExportDocuments();
  const [connectDatevClient] = useConnectDatevClientMutation({
    refetchQueries: [...refetchDatevData, { query: getFullOrganizationQuery }],
  });

  const selectDatevClient = useCallback(
    async ({ client }: { client?: DatevClient }) => {
      if (!client) return;
      await connectDatevClient({
        variables: {
          input: {
            id: client.id,
            consultantNumber: client.consultantNumber,
            clientNumber: client.clientNumber,
            name: client.name,
            isDxsoJobsAvailable: client.isDxsoJobsAvailable,
          },
        },
      });
      void authorizeForExportDocuments();
    },
    [connectDatevClient, authorizeForExportDocuments]
  );

  const [disconnectDatevClient] = useDisconnectDatevClientMutation({
    refetchQueries: [
      ...refetchDatevDataWithNoToken,
      { query: getFullOrganizationQuery },
    ],
  });

  const resetConnection = useCallback(async () => {
    // 1. Disconnect the client
    await disconnectDatevClient();
    // 2. Redirect to datev setting page
    navigate(`/${organization?.id}${Routes.SETTINGS}${Routes.DATEV}`);
  }, [navigate, organization?.id, disconnectDatevClient]);

  const openModal = () => {
    navigate({ hash: OPEN_EMAIL_MODAL });
  };

  const formId = useId();
  const SelectClientFormErrorMessages: SelectClientFormErrorMessages = {
    client: {
      label: 'settings.datev.connect.selectDatevClient.fieldLabel',
    },
  };

  const form = useForm<SelectClientFormOutput>({
    mode: 'onTouched',
    defaultValues: { client: undefined },
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.COMMON,
      zodSchema: selectClientFormSchema,
      errorMessages: SelectClientFormErrorMessages,
    }),
  });

  const items = clientsData?.new_datevClients.map(client => ({
    key: client.id,
    children: `${client.name} (${client.clientNumber}/${client.consultantNumber})`,
  }));

  return (
    <>
      <DcsSettingsContainer
        title={t('settings.datev.connect.selectDatevClient.cardTitle')}
        details={
          loading ? (
            <Flex
              gap="space8"
              alignItems="center"
              paddingY="space20"
              width="20rem"
            >
              <Spinner color="blue600" size="space12" />
              {t('settings.datev.connect.selectDatevClient.loadingPlaceholder')}
            </Flex>
          ) : clientsData?.new_datevClients &&
            clientsData?.new_datevClients.length > 0 ? (
            <FormProvider {...form}>
              <Grid
                gap="space24"
                width={280}
                as="form"
                id={formId}
                onSubmit={form.handleSubmit(async values => {
                  const selectedClient = clientsData?.new_datevClients.find(
                    ({ id }) => id === values.client
                  );

                  await selectDatevClient({ client: selectedClient });
                })}
              >
                <Grid gap="space12">
                  <HookFormComboBoxField
                    label={t('settings.datev.connect.selectDatevClient.label')}
                    name="client"
                    defaultItems={items}
                    placeholder={t(
                      'settings.datev.connect.selectDatevClient.selectPlaceholder'
                    )}
                    emptyListPlaceholder={t(
                      'settings.datev.connect.selectDatevClient.emptyListPlaceholder'
                    )}
                  />
                  <Link
                    external
                    href={t('settings.datev.connect.selectDatevClient.linkUrl')}
                  >
                    {t('settings.datev.connect.selectDatevClient.linkText')}
                  </Link>
                </Grid>
                <Flex gap="space12" alignItems="center">
                  <Button variant="secondary" onClick={resetConnection}>
                    {t(
                      'settings.datev.connect.selectDatevClient.actionButtons.cancel'
                    )}
                  </Button>
                  <Button loading={loading} type="submit" form={formId}>
                    {t(
                      'settings.datev.connect.selectDatevClient.actionButtons.next'
                    )}
                  </Button>
                </Flex>
              </Grid>
            </FormProvider>
          ) : (
            <Grid templateColumns="auto 1fr" gap="space8" alignItems="center">
              <ErrorIcon size="space12" />
              {error ? (
                <Text color="red500">
                  {t(
                    'settings.datev.connect.selectDatevClient.clientsFetchError'
                  )}
                </Text>
              ) : (
                <Flex gap="space4">
                  <Text color="red500">
                    {t(
                      'settings.datev.connect.selectDatevClient.noClientsError.errorText'
                    )}
                  </Text>
                  <Link
                    external
                    href={t(
                      'settings.datev.connect.selectDatevClient.noClientsError.linkUrl'
                    )}
                  >
                    {t(
                      'settings.datev.connect.selectDatevClient.noClientsError.linkText'
                    )}
                  </Link>
                </Flex>
              )}
            </Grid>
          )
        }
        actions={
          !loading &&
          (!clientsData?.new_datevClients ||
            !clientsData?.new_datevClients.length) && (
            <Button
              size="medium"
              loading={reconnecting}
              onClick={() =>
                onReconnect(
                  settingsData?.new_datevSettings?.connectionType ||
                    DatevConnectionType.DatevDuo
                )
              }
            >
              {t('settings.datev.connect.actions.retry')}
            </Button>
          )
        }
        secondaryActions={
          !loading &&
          (!clientsData?.new_datevClients ||
            !clientsData?.new_datevClients.length) && (
            <Button
              variant="secondary"
              onClick={() => {
                openModal();
              }}
            >
              {t('settings.datev.connect.selectDatevClient.sendEmail')}
            </Button>
          )
        }
      />
      <EmailModalContainer />
    </>
  );
};

const EmailModalContainer = () => {
  const organization = useFullOrganization();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const [t] = useTranslation();

  const visible = window.location.hash === '#' + OPEN_EMAIL_MODAL;

  const closeModal = () => {
    navigate({ hash: '' });
  };

  const subjectText = t(
    'settings.datev.connect.selectDatevClient.taxAdvisorEmail.subject',
    { organizationName: organization?.name }
  );

  const sendEmailModalTitle = t(
    'settings.datev.connect.exportTypes.dxsoJobs.taxAdvisorEmail.modalTitle'
  );

  const emailText = [
    t('settings.datev.connect.selectDatevClient.taxAdvisorEmail.greetings'),
    t('settings.datev.connect.selectDatevClient.taxAdvisorEmail.content'),
    t('settings.datev.connect.selectDatevClient.taxAdvisorEmail.details', {
      onBehalfOf: currentUser?.email,
      organizationName: organization?.name,
    }),
    t('settings.datev.connect.selectDatevClient.taxAdvisorEmail.closing', {
      fullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
    }),
  ].join('<br/><br/>');

  return (
    <SendEmailModal
      modalTitle={sendEmailModalTitle}
      isOpen={visible}
      subjectText={subjectText}
      body={emailText}
      senderEmail={currentUser?.email}
      onCancel={closeModal}
    />
  );
};
