import { CustomEmptyStateProps, Flex } from '@candisio/design-system';
import { InboxViewContainer } from '../components/InboxViewContainer';
import { TabView } from '../models';
import { InboxContractsHeader } from './InboxContractsHeader';
import { EcmDocumentsTable } from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { useGetEcmContractsTableConfigs } from 'views/Archive/EcmContracts/hooks/useGetEcmContractsTableConfigs';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { availableEcmDocumentFilters } from 'components/EcmDocumentsTable/constants';
import { useParams } from 'react-router-dom-v5-compat';
import { AppRouteParams, Routes } from 'models';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { queryParameter } from 'components/Table/consts';
import { UNTAGGED_ID } from 'components/DocumentsTable/constants';
import { EcmFilterInput } from 'generated-types/graphql.types';
import { useCallback, useMemo } from 'react';
import { EcmDocumentsPaginationParams } from 'providers/GraphQLProvider/Pagination/useEcmPagination';
import { useInboxContractsData } from './useInboxContractsData';
import { InboxSensitiveDocumentsEcmEmptyState } from '../EcmSensitiveDocuments/EcmInboxSensitiveDocumentsEmptyState';
import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import { generateInboxTableRowPath } from 'views/utils/generateInboxTableRowPath';

export const InboxContracts = () => {
  const { updateSearchParam, searchParams } = useMutateSearchParams();
  const { organizationSlug } = useParams<AppRouteParams>();
  const { filters, onFilter, sortBy, onSort } =
    useUrlBasedSortAndFilter<EcmDocumentsTableData>({
      availableFilters: availableEcmDocumentFilters,
    });
  const queryStringFilter = searchParams.get(queryParameter) ?? '';

  const {
    availableDocumentColumnIds,
    configurationsTable,
    isResetPending,
    handleResetTableConfigurations,
    handleUpdateConfigurations,
  } = useGetEcmContractsTableConfigs({ filters, sortBy });

  const isTableFiltered = filters.length > 0;

  const formattedFilters: EcmFilterInput | undefined = isTableFiltered
    ? filters.reduce<EcmFilterInput>((acc, filter) => {
        if (filter.id === 'tags') {
          // TODO: fix type definition
          // `filter.value` is type `unknown`, so we typecast here
          if ((filter.value as string).includes(UNTAGGED_ID)) {
            acc.tags = [];
          } else {
            acc.tags = filter.value as Array<string>;
          }
        }

        return acc;
      }, {})
    : undefined;

  const paginationParams: EcmDocumentsPaginationParams = useMemo(
    () => ({
      filter: formattedFilters,
    }),
    [formattedFilters]
  );

  const ecmContractsDataOptions = useMemo(
    () => ({
      params: paginationParams,
    }),
    [paginationParams]
  );

  const {
    data,
    documentsCount,
    handleDebounceSearch,
    isEmpty,
    isLoading,
    onLoadMore,
  } = useInboxContractsData(ecmContractsDataOptions);

  const customEmptyState = ({ resetFilters }: CustomEmptyStateProps) => {
    return (
      <InboxSensitiveDocumentsEcmEmptyState
        isTableEmpty={isEmpty}
        isTableFiltered={isTableFiltered}
        resetFilters={resetFilters}
      />
    );
  };

  const handleSearch = (search: string) => {
    handleDebounceSearch(search);
    updateSearchParam(queryParameter, search);
  };

  const getPath = useCallback(
    ({ id, cursor }: EcmDocumentsTableData) => {
      if (!organizationSlug) {
        return '';
      }

      const { pathname, search } = generateInboxTableRowPath({
        cursor,
        documentId: id,
        organizationSlug,
        route: Routes.INBOX_CONTRACTS,
        searchParams,
      });

      return { pathname, search };
    },
    [organizationSlug, searchParams]
  );

  const cellWrapper = useMemo(() => getCellWrapperLink(getPath), [getPath]);

  const cellStyle = () => ({
    padding: 'unset',
  });

  return (
    <>
      <InboxContractsHeader />
      <InboxViewContainer activeTab={TabView.INBOX_CONTRACTS}>
        <Flex
          direction="column"
          height="100%"
          paddingX="space32"
          paddingBottom="space24"
        >
          <EcmDocumentsTable
            context="contracts"
            columns={availableDocumentColumnIds}
            data={data}
            isLoading={isLoading}
            isLoadingConfigurations={isResetPending}
            defaultFilters={filters}
            isTableFiltered={isTableFiltered}
            configurationsTable={configurationsTable}
            searchQuery={queryStringFilter}
            selectedDocumentsCount={documentsCount}
            defaultSortBy={sortBy}
            onSearchChange={handleSearch}
            onUpdateConfigurations={handleUpdateConfigurations}
            onFilter={onFilter}
            onEndReached={onLoadMore}
            onSort={onSort}
            customEmptyState={customEmptyState}
            onResetTableConfigurations={handleResetTableConfigurations}
            cellWrapper={cellWrapper}
            getCellStyles={cellStyle}
          />
        </Flex>
      </InboxViewContainer>
    </>
  );
};
