import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  useId,
} from '@candisio/design-system';

import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';
import { useOrganizationData } from '../hooks/useOrganizationData';
import { useOrganizationSSO } from '../hooks/useOrganizationSSO';

const ssoConfigurationSchema = z.object({
  ssoConfigurationId: z.string().nullish(),
});

type ConfigSSOValues = z.infer<typeof ssoConfigurationSchema>;

const organizationSSOErrorMessages: ErrorMessages<
  typeof ssoConfigurationSchema
> = {
  ssoConfigurationId: { label: 'settings.sso.select.error' },
};

export const OrganizationSSOConfiguration = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { error } = useToastMessage();
  const formId = useId();
  const { organization, isLoading } = useOrganizationData();

  const {
    ssoConfigurationList,
    updateOrganizationSSO,
    clearOrganizationSSOConfiguration,
    navigateToSSOConfiguration,
  } = useOrganizationSSO();

  const UNSET_IDP_SELECTION_KEY = 'unset-idp-for-organization';

  const form = useForm<ConfigSSOValues>({
    values: { ssoConfigurationId: organization?.ssoConfigurationId },
    resolver: zodResolver({
      zodSchema: ssoConfigurationSchema,
      errorMessages: {
        ...organizationSSOErrorMessages,
      },
    }),
  });

  const handleOrganizationSSOUpdate = (ssoConfigurationId: string) => {
    if (!organization) {
      showOrganizationLoadingError();

      return;
    }

    void updateOrganizationSSO(organization.id, ssoConfigurationId);
  };

  const handlerCreateSSOConfiguration = () => {
    if (!organization) {
      showOrganizationLoadingError();

      return;
    }

    navigateToSSOConfiguration(organization.id);
  };

  const handlerConfigureIdentityProvider = () => {
    if (!organization) {
      showOrganizationLoadingError();

      return;
    }

    const selectedSSOConfigurationid = form.getValues('ssoConfigurationId');
    if (
      selectedSSOConfigurationid &&
      selectedSSOConfigurationid !== UNSET_IDP_SELECTION_KEY
    ) {
      navigateToSSOConfiguration(organization.id, selectedSSOConfigurationid);
    }
  };

  const handleClearSSOConfiguration = () => {
    if (!organization) {
      showOrganizationLoadingError();

      return;
    }

    void clearOrganizationSSOConfiguration(organization.id);
  };

  const handleSubmit = () => {
    const selectedKey = form.getValues('ssoConfigurationId');
    if (selectedKey === UNSET_IDP_SELECTION_KEY) {
      void handleClearSSOConfiguration();

      return;
    }

    if (!selectedKey) {
      return;
    }

    void handleOrganizationSSOUpdate(selectedKey);
  };

  const showOrganizationLoadingError = () => {
    error(t('sso.toastMessage.organizationLoadingError'));
  };

  return (
    <Card height="13rem">
      {!isLoading && (
        <Flex direction="column" height="100%">
          <Grid templateColumns="1fr auto" gap="space10" alignItems="start">
            <Grid gap="space16">
              <Heading as="h3">{t('sso.title')}</Heading>
              <Flex gap="space4" alignItems="center">
                {t('sso.paragraph')}
              </Flex>
            </Grid>
            <Button
              variant="secondary"
              icon="plus"
              onClick={handlerCreateSSOConfiguration}
            >
              {t('sso.buttons.addNewConfiguration')}
            </Button>
          </Grid>
          <FormProvider {...form}>
            <Grid
              onSubmit={form.handleSubmit(handleSubmit)}
              id={formId}
              as="form"
              height="40%"
              alignContent="end"
            >
              <Flex gap="space16" alignItems="center">
                <HookFormSelectField
                  name="ssoConfigurationId"
                  items={[
                    ...ssoConfigurationList.map(item => ({
                      key: item.id,
                      children: t(item.idpAlias),
                    })),
                    {
                      key: UNSET_IDP_SELECTION_KEY,
                      children: t('sso.select.unsetIDP'),
                    },
                  ]}
                  placeholder={t('sso.select.placeholder')}
                  onChange={handleSubmit}
                  width="space256"
                />
                <Button
                  onClick={handlerConfigureIdentityProvider}
                  variant="secondary"
                >
                  {t('sso.buttons.configure')}
                </Button>
              </Flex>
            </Grid>
          </FormProvider>
        </Flex>
      )}
    </Card>
  );
};
