import { Button } from '@candisio/design-system';
import { ConfirmationPopoverDeprecated } from 'components/ConfirmationPopover/ConfirmationPopoverDeprecated';
import { useTranslation } from 'react-i18next';

export interface ArchiveButtonProps {
  archiving?: boolean;
  disabled?: boolean;
  onArchive?: () => void;
}

export const ArchiveButton = ({
  archiving = false,
  disabled = false,
  onArchive,
}: ArchiveButtonProps) => {
  const [t] = useTranslation('settings');

  return (
    <ConfirmationPopoverDeprecated
      closeText={t('paymentConditions.drawer.no')}
      confirmText={t('paymentConditions.drawer.yes')}
      onConfirm={onArchive}
      title={t('paymentConditions.drawer.areYouSure')}
      trigger={({ triggerProps, triggerRef }) => (
        <Button
          color="red"
          loading={archiving}
          disabled={disabled}
          variant="tertiary"
          {...triggerProps}
          ref={triggerRef}
        >
          {t('paymentConditions.form.buttons.archive')}
        </Button>
      )}
    />
  );
};
