import {
  Text,
  InfoPanel,
  Link,
  InfoPanelProps,
  Grid,
  Icon,
  Tooltip,
  useTooltip,
  Box,
} from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export const ExportConfigurationHint = ({
  variant,
  hint,
  linkText,
  onLinkClick,
  dismissable,
  setDismissed,
}: {
  variant: InfoPanelProps['variant'];
  hint: string;
  linkText?: string;
  onLinkClick?: () => void;
  dismissable?: boolean;
  setDismissed?: (value: boolean) => void;
}) => {
  const [t] = useTranslation();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  return (
    <InfoPanel variant={variant}>
      {dismissable && (
        <Box
          position="absolute"
          right={-4}
          top={-4}
          cursor="pointer"
          zIndex={1}
          {...triggerProps}
          ref={triggerRef}
          onClick={() => setDismissed?.(true)}
        >
          <Icon icon="close" />
        </Box>
      )}
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {t('common.close')}
        </Tooltip>
      )}
      <Grid gap="space4">
        <Text fontSize="basic" gridColumn="1">
          {hint}
        </Text>
        {linkText && (
          <Link
            fontSize="basic"
            gridColumn="1"
            icon="newTab"
            iconPosition="right"
            onClick={onLinkClick}
          >
            {linkText}
          </Link>
        )}
      </Grid>
    </InfoPanel>
  );
};
