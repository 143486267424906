import { EntitlementsModal } from 'containers/Entitlements/components/Modal/EntitlementsModal';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { Children, ReactElement, cloneElement, useState } from 'react';
import { PromotionText } from './components/PromotionText';

interface UpsellPromoProps {
  feature: FEATURE;
  children?: ReactElement;
  utmSource?: string;
}

export const UpsellPromo = ({
  feature,
  children,
  utmSource,
}: UpsellPromoProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => {
    setIsOpen(true);
  };

  const callToAction = children ? (
    Children.only(children)
  ) : (
    <PromotionText feature={feature} onClick={open} />
  );

  return (
    <>
      {cloneElement(callToAction, { onClick: open })}
      <EntitlementsModal
        isOpen={isOpen}
        onClose={close}
        feature={feature}
        utmSource={utmSource}
      />
    </>
  );
};
