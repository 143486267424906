import { useQuery } from '@apollo/client';
import {
  Button,
  Flex,
  Icon,
  Popover,
  ScrollBox,
  Skeleton,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import { motion } from 'framer-motion';
import { Query } from 'generated-types/graphql.types';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { getOrganizationsQuery } from 'views/AppContainer/components/Header/components/MultiOrganizationOverview/getOrganizationsQuery';
import { OrganizationsListView } from './OrganizationsListView';

const MotionIcon = motion(Icon);

export const MultiOrganizationOverview = ({
  initialisingAuth,
}: {
  initialisingAuth?: boolean;
}) => {
  const organizationsQuery = useQuery<Pick<Query, 'getOrganizations'>>(
    getOrganizationsQuery,
    { skip: initialisingAuth }
  );

  const selectedOrganization = useOrganizationId();
  const organizations = organizationsQuery.data?.getOrganizations || [];
  const currentOrganization = organizations.find(
    item => item.slug === selectedOrganization
  );

  const hasMoreThanOneOrganization = organizations.length > 1;
  const currentOrganizationName = currentOrganization?.name;

  const {
    isOpen,
    open,
    close,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({ placement: 'bottom' });

  if (initialisingAuth || organizationsQuery.loading) {
    return <Skeleton height="1rem" width="20%" />;
  }

  const showToolbar = organizations ? organizations.length > 2 : false;

  return (
    <>
      <Flex alignItems="center" gap="space4">
        {hasMoreThanOneOrganization ? (
          <Button
            {...triggerProps}
            size="small"
            variant="secondary"
            onPointerDown={isOpen ? close : open}
            ref={triggerRef}
          >
            <Flex gap="space4">
              {currentOrganizationName}
              <MotionIcon
                icon="caretDown"
                size="space16"
                animate={isOpen ? 'open' : 'closed'}
                variants={{
                  open: { rotate: 180 },
                  closed: { rotate: 0 },
                }}
                transition={{
                  rotate: { duration: 0.25 },
                  default: { ease: 'easeInOut' },
                }}
              />
            </Flex>
          </Button>
        ) : (
          <TruncatedText maxWidth="space256">
            {currentOrganizationName ?? ''}
          </TruncatedText>
        )}
      </Flex>

      {isOpen && (
        <Popover
          padding="0"
          {...popoverProps}
          ref={popoverRef}
          width="440px"
          // this background needs to be set dynamically so that the chevron that attaches
          // the popover to the button is the correct color when the search field is hidden
          background={showToolbar ? 'gray200' : 'gray0'}
        >
          <ScrollBox maxHeight="inherit">
            <OrganizationsListView handleCurrentOrganization={close} />
          </ScrollBox>
        </Popover>
      )}
    </>
  );
};
