import { useQuery } from '@apollo/client';
import * as SentryBrowser from '@sentry/browser';
import { Dispatch, ReactNode, useCallback, useState } from 'react';
import { OrganizationHistoryService } from 'services/OrganizationHistoryService';
import { UserRealm } from 'utils/authorization-helper';
import { IntegrationName, Query } from '../../generated-types/graphql.types';
import { getLocalStorageIntegration } from '../../views/AppContainer/components/Header/components/QaUtils/OrgConfig/containers/Overview';
import { shouldUseQaUtils } from '../../views/AppContainer/components/Header/components/QaUtils/utils';
import { FullOrganizationContext } from './FullOrganizationContext';
import { OrganizationContext } from './OrganizationContext';
import { getFullOrganizationQuery } from './queries';
import { useOrganizationId } from './useOrganizationId';

interface OrganizationProviderProps {
  children: ReactNode;
}

export const OrganizationProvider = ({
  children,
}: OrganizationProviderProps) => {
  const [realm, setSelectedOrganization] = useState<UserRealm | null>(null);

  const wrappedSetSelectedOrganization = useCallback<
    Dispatch<UserRealm | null>
  >(newRealm => {
    if (newRealm) {
      OrganizationHistoryService.updateStoredOrganizations(newRealm.realm);
      SentryBrowser.setTag('organization', newRealm.realm);
    }

    setSelectedOrganization(newRealm);
  }, []);

  return (
    <OrganizationContext.Provider
      value={{
        selectedOrganization: realm?.realm ?? null,
        selectedUser: realm?.userId ?? null,
        setSelectedOrganization: wrappedSetSelectedOrganization,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

interface FullOrganizationProviderProps {
  children: ReactNode;
}

export const FullOrganizationProvider = ({
  children,
}: FullOrganizationProviderProps) => {
  const selectedOrganization = useOrganizationId();

  const { data: queryData, loading } = useQuery<Pick<Query, 'organization'>>(
    getFullOrganizationQuery,
    {
      skip: !selectedOrganization,
    }
  );

  const organization = queryData?.organization;

  let currentOrganization =
    !loading && selectedOrganization === organization?.id
      ? organization
      : undefined;

  const overwrittenIntegration = getLocalStorageIntegration();
  const qaUtils = shouldUseQaUtils();

  if (overwrittenIntegration && qaUtils && currentOrganization) {
    currentOrganization = { ...currentOrganization };
    currentOrganization.integrationName =
      overwrittenIntegration as IntegrationName;
  }

  return (
    <FullOrganizationContext.Provider
      value={{
        currentOrganization,
      }}
    >
      {children}
    </FullOrganizationContext.Provider>
  );
};
