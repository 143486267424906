export enum FEATURE_STATE {
  HIDDEN = 'HIDDEN',
  PROMO = 'PROMO',
  VISIBLE = 'VISIBLE',
}

export enum FEATURE {
  ABSENCE_MANAGEMENT = 'ABSENCE_MANAGEMENT',
  BDS = 'BDS',
  CONTRACTS = 'CONTRACTS',
  DOCUMENT_RELATIONS = 'DOCUMENT_RELATIONS',
  FULL_TEXT_SEARCH = 'FULL_TEXT_SEARCH',
  INTEGRATION_SAP = 'INTEGRATION_SAP',
  POSTING_TEXT = 'POSTING_TEXT',
  PROVISIONS = 'PROVISIONS',
  PURCHASE_ORDERS = 'PURCHASE_ORDERS',
  SEPA_XML = 'SEPA_XML',
  ECM = 'ECM',
  REIMBURSEMENT = 'REIMBURSEMENT',
  DOCUMENT_PREVIEW = 'DOCUMENT_PREVIEW',
  TAGS = 'TAGS',
  SEARCH_OVERLAY = 'SEARCH_OVERLAY',
}

export type FeatureToggle = Record<FEATURE, FEATURE_STATE>;

export type FeatureToggleContextResult = {
  ft: FeatureToggle;
  show: (feature: FEATURE_STATE) => boolean;
  promo: (feature: FEATURE_STATE) => boolean;
  hide: (feature: FEATURE_STATE) => boolean;
  overrideFeature: (featureToggle: FeatureToggle) => void;
};
