import { Button, Drawer, Grid, Heading } from '@candisio/design-system';
import { ReActivateRow } from 'components/ArchiveWrapper/ArchiveWrapper';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { AccountingArea } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import {
  AccountingAreasForm,
  AccountingAreasFormProps,
} from './Form/AccountingAreasForm';

const ACCOUNTING_AREA_FORM_ID = 'accounting-area-form';

export interface AccountingAreasDrawerProps {
  id?: 'create' | string;
  onCloseDrawer: () => void;
  onSubmit: AccountingAreasFormProps['onSubmit'];
  isOpen?: boolean;
  loading?: boolean;
  submitting: boolean;
  updatingStatus: boolean;
  onArchive: () => Promise<void>;
  onActivate: () => Promise<void>;
  isActive?: boolean;
  accountArea?: Pick<AccountingArea, 'name' | 'description'>;
}

export const AccountingAreasDrawer = ({
  id,
  onCloseDrawer,
  onSubmit,
  isOpen,
  loading,
  submitting,
  updatingStatus,
  onArchive,
  onActivate,
  isActive,
  accountArea,
}: AccountingAreasDrawerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const titleName =
    id === 'create'
      ? t('accountingAreas.drawer.title.create')
      : t('accountingAreas.drawer.title.edit');

  return (
    <Drawer isOpen={isOpen} onClose={onCloseDrawer}>
      <DrawerLayout
        header={<Heading as="h3">{titleName}</Heading>}
        footer={
          <Grid placeContent="space-between" autoFlow="column">
            {isActive && (
              <Button
                type="submit"
                form={ACCOUNTING_AREA_FORM_ID}
                loading={submitting || updatingStatus}
                disabled={submitting || updatingStatus}
              >
                {t('accountingAreas.drawer.form.actions.submit.label')}
              </Button>
            )}
            {id && id !== 'create' && isActive && (
              <ConfirmationButton
                onConfirm={onArchive}
                confirmationTitle={t(
                  'accountingAreas.drawer.form.actions.archive.confirm'
                )}
                variant="tertiary"
                color="red"
                loading={updatingStatus}
                disabled={updatingStatus}
              >
                {t('accountingAreas.drawer.form.actions.archive.label')}
              </ConfirmationButton>
            )}
          </Grid>
        }
        onClose={onCloseDrawer}
      >
        {!loading && (
          <Grid gap="space32">
            {id && !isActive && (
              <ReActivateRow
                onRestore={onActivate}
                isSubmitting={updatingStatus}
                disabled={updatingStatus}
              />
            )}
            <AccountingAreasForm
              formId={ACCOUNTING_AREA_FORM_ID}
              onSubmit={onSubmit}
              readOnly={submitting || !isActive || updatingStatus || loading}
              defaultAccountingArea={accountArea}
              loading={loading}
            />
          </Grid>
        )}
      </DrawerLayout>
    </Drawer>
  );
};
