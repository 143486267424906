import { CSSObject } from '@emotion/react';
import React from 'react';
import { mergeProps, useFocusRing, useRadio, VisuallyHidden } from 'react-aria';
import { RadioGroupState } from 'react-stately';
import { RadioGroupContext } from '../../Molecules/RadioGroup';
import { useTheme } from '../../Theme';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { Grid } from '../Grid';

export interface RadioProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLLabelElement> {
  isDisabled?: boolean;
  isReadOnly?: boolean;
  value: string;
  autoFocus?: boolean;
  children?: React.ReactNode;
}

/**
 * Use multiple Radios to form a Radio Group.
 *
 * @param {boolean} [isReadOnly] Puts the item in read-only state.
 * @param {boolean} [isDisabled] Puts the item in disabled state.
 * @param {string} value Puts the item in disabled state.
 * @param {boolean} [autoFocus] Whether the element should receive focus on render.
 * @param {ReactNode} [children] The label for the Radio. Accepts any renderable node.
 */
export const Radio = React.forwardRef<HTMLLabelElement, RadioProps>(
  (
    { children, isDisabled, isReadOnly, value, autoFocus, ...restProps },
    forwardedRef
  ) => {
    const radioGroupState = React.useContext(
      RadioGroupContext
    ) as RadioGroupState;

    const radioRef = React.useRef(null);

    const { inputProps } = useRadio(
      { children, isDisabled, value, autoFocus },
      radioGroupState,
      radioRef
    );

    const { isFocusVisible, focusProps } = useFocusRing({});
    const { colors, radio, space } = useTheme();

    const {
      selectedValue: radioGroupStateSelectedValue,
      isDisabled: radioGroupStateIsDisabled,
      isReadOnly: radioGroupStateIsReadOnly,
    } = radioGroupState;

    const selectedState = radioGroupStateSelectedValue === value;
    const readOnlyState = radioGroupStateIsReadOnly || isReadOnly;
    const disabledState = radioGroupStateIsDisabled || isDisabled;

    const baseStyle: CSSObject = {
      label: {
        alignItems: 'start',
        gridTemplateColumns: 'max-content auto',
        gridTemplateRows: 'auto',
        userSelect: 'none',
        lineHeight: space.space20,
        gap: space.space12,
      },
      radioButton: {
        // because children passed to Radio as a label can have larger line-height than would be needed for perfect alignment
        // with the radio button, we need to force it to be positioned a bit lower. This is a best guess according to currently used font-sizes and line-heights
        // and will not work if we move away from them.
        marginTop: '2px',
        transition: 'all 200ms ease',
        boxShadow: isFocusVisible ? `0 0 0 2px ${colors.blue400}` : 'unset',
        borderRadius: '50%',
        strokeWidth: selectedState ? 9 : 1,
      },
    };

    const getRadioStateStyle = () => {
      if (readOnlyState) {
        return radio.readOnlyState;
      }

      if (disabledState) {
        return radio.disabledState;
      }

      return radio.default;
    };

    const radioStateStyle = getRadioStateStyle();

    const mergedProps = mergeProps(inputProps, focusProps);

    return (
      <Grid
        as="label"
        {...restProps}
        css={[baseStyle.label, radioStateStyle.label]}
        ref={forwardedRef}
      >
        <VisuallyHidden>
          <input
            {...mergedProps}
            // to allow screenreaders to focus all Radio Buttons despite being disabled, we only use aria-disabled
            disabled={false}
            aria-disabled={disabledState}
            // however, to make sure it comes as close to the html disabled state as possible we set the readonly attribute in that case
            readOnly={readOnlyState || disabledState}
            ref={radioRef}
            // because we make readonly and disabled Radio Buttons
            // focusable, we need to disable their selection manually
            onChange={
              readOnlyState || disabledState ? undefined : mergedProps.onChange
            }
            // to prevent the onKeyDown handler of a parent to set the focused Radio's value as
            // selectedValue even if the focused Radio is readonly/disabled, we must stop propagation
            onKeyDown={e => {
              e.stopPropagation();
            }}
            tabIndex={disabledState || readOnlyState ? -1 : undefined}
          />
        </VisuallyHidden>
        <svg
          css={[
            baseStyle.radioButton,
            radioStateStyle.radioButton,
            selectedState && radioStateStyle.radioButton.selected,
          ]}
          width={16}
          height={16}
          aria-hidden="true"
        >
          <circle
            className="radio-circle"
            clipPath="circle(8 at center)"
            cx={8}
            cy={8}
            r={7.5}
          />
        </svg>
        {children}
      </Grid>
    );
  }
);
