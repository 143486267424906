import { Popover, usePopover } from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { MobileAppPromotionModal } from 'views/AppContainer/components/Header/components/MobileAppPromotionModal/MobileAppPromotionModal';
// TODO: Move the files to components/NavigationSidebar/UserProfile after removing new navigation FF
import userProfileMenuQRCode from 'views/AppContainer/components/Header/components/MobileAppPromotionModal/media/user-profile-menu-QR-code.jpg';
import { scrollToTargetParam } from 'views/AppContainer/components/Header/components/UserProfile/AccountSecurity/PhoneNumberMfa/PhoneNumberCard';
import { LegalDocumentsModal } from 'views/AppContainer/components/Header/components/UserProfile/LegalDocuments/LegalDocumentsModal';
import { UserProfileUpdateContainer } from 'views/AppContainer/components/Header/components/UserProfile/UserProfileUpdateContainer';
import { IconLink } from '../../IconLink/IconLink';
import { UserProfileMenuPopover } from './UserProfileMenuPopover/UserProfileMenuPopover';

export const UserProfile = ({ expanded }: { expanded: boolean }) => {
  const currentUser = useCurrentUser();
  const { hash: currentHash, search } = useLocation();
  const [legalModalVisible, setLegalModalVisible] = useState(false);
  const [mobileAppPromotionModalVisible, setMobileAppPromotionModalVisible] =
    useState(false);

  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const userLabel = `${currentUser?.firstName} ${currentUser?.lastName}`;

  const {
    close: closeUserProfileMenu,
    isOpen: isUserProfileMenuOpen,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({
    placement: 'right',
    onOpenChange: isOpen => {
      setIsActive(isOpen);
    },
  });

  const onProfileClose = useCallback(() => {
    const updatedSearchParams = new URLSearchParams(search);

    updatedSearchParams.delete(scrollToTargetParam);

    navigate(
      { hash: undefined, search: updatedSearchParams.toString() },
      { replace: true }
    );
  }, [navigate, search]);

  const isProfileOpen = currentHash === '#userProfile';

  const openMobilAppPromotionModal = () =>
    setMobileAppPromotionModalVisible(true);

  const openLegalModal = () => setLegalModalVisible(true);

  return (
    <>
      <IconLink
        as="button"
        avatar={
          <AvatarWithStatusContainer
            hasTooltip={!isUserProfileMenuOpen}
            flexShrink={0}
            img={currentUser?.avatarUrl ?? undefined}
            name={currentUser?.name}
            size="medium"
            userId={currentUser?.id}
            tooltipPlacement="right"
            showAbsenceTooltip={!isUserProfileMenuOpen}
          />
        }
        data-cy="nav-item-userMenu"
        expanded={expanded}
        ref={triggerRef}
        label={userLabel}
        showLabelInTooltip={false}
        isActive={isActive}
        {...triggerProps}
      />
      {isUserProfileMenuOpen && (
        <Popover
          padding="0"
          autoFocus={false}
          {...popoverProps}
          ref={popoverRef}
          dismissWithOutsideClick
          restoreFocus={false}
        >
          <UserProfileMenuPopover
            closeUserProfileMenu={closeUserProfileMenu}
            openMobilAppPromotionModal={openMobilAppPromotionModal}
            openLegalModal={openLegalModal}
          />
        </Popover>
      )}

      <UserProfileUpdateContainer
        isOpen={isProfileOpen}
        onClose={onProfileClose}
      />
      <LegalDocumentsModal
        onCancel={() => setLegalModalVisible(false)}
        visible={legalModalVisible}
      />
      <MobileAppPromotionModal
        qrCode={userProfileMenuQRCode}
        visible={mobileAppPromotionModalVisible}
        onCancel={() => setMobileAppPromotionModalVisible(false)}
      />
    </>
  );
};
