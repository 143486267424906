import { Grid, Text } from '@candisio/design-system';
import {
  GoodsLineItem,
  Maybe,
  Money,
  ServiceLineItem,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { DateFormatters } from 'utils/date_formatter';
import { LineItemsCell } from './Table/LineItemsCell';

export interface PurchaseOrderFieldItem {
  key: string;
  amount?: Money;
  orderNumber?: string;
  postingDate?: string;
  goodsLineItems?: Maybe<Array<GoodsLineItem>>;
  serviceLineItems?: Maybe<Array<ServiceLineItem>>;
}

/** Individual items displayed by PurchaseOrderNumberField */
export const PurchaseOrderItem = ({
  amount,
  orderNumber,
  postingDate,
  goodsLineItems,
  serviceLineItems,
}: PurchaseOrderFieldItem) => {
  const { documentMoneyFormatter } = useMoneyFormatter();

  return (
    <Grid>
      <Text>{orderNumber}</Text>
      <Grid
        autoFlow="column"
        gap="space8"
        templateColumns="auto 1fr auto"
        alignItems="center"
        justifyContent="space-between"
        fontSize="small"
        color="gray500"
        fontWeight="semibold"
      >
        {postingDate && (
          <Text>{DateFormatters.compact(new Date(postingDate))}</Text>
        )}
        <LineItemsCell value={{ goodsLineItems, serviceLineItems }} />
        {amount && <Text>{documentMoneyFormatter(amount)}</Text>}
      </Grid>
    </Grid>
  );
};
