import {
  Button,
  FieldContainer,
  Flex,
  Grid,
  Popover,
  Textarea,
  Text,
  usePopover,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

type RequestType = 'limitRequest' | 'cardRequest';

const requestTranslations: Record<
  RequestType,
  { title: string; placeholderField: string }
> = {
  cardRequest: {
    placeholderField: 'drawer.rejectRequestPopover.fieldPlaceholderCardRequest',
    title: 'drawer.rejectRequestPopover.titleCardRequest',
  },
  limitRequest: {
    placeholderField:
      'drawer.rejectRequestPopover.fieldPlaceholderLimitRequest',
    title: 'drawer.rejectRequestPopover.titleLimitRequest',
  },
};

interface Cardholder {
  firstName: string | undefined;
  lastName: string | undefined;
}

interface HandleRequestProps {
  approving: boolean;
  declining: boolean;
  cardholder: Cardholder;
  requestType: RequestType;
  onApprove: () => Promise<void>;
  onDecline: (declineReason: string | undefined) => Promise<void>;
}

export const HandleRequest = ({
  approving,
  cardholder,
  declining,
  onApprove,
  onDecline,
  requestType,
}: HandleRequestProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [declineReason, setDeclineReason] = useState<undefined | string>(
    undefined
  );

  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ isOpen: isPopoverOpen, placement: 'left' });

  const translations = requestTranslations[requestType];

  const readableCardholderName =
    (cardholder.firstName ?? '') + (cardholder.lastName ?? '');

  return (
    <Flex
      paddingY="space16"
      justifyContent="space-between"
      background="white"
      zIndex={2}
      position="sticky"
      bottom={0}
    >
      <Button
        alignSelf="start"
        onClick={onApprove}
        loading={approving}
        disabled={approving || declining}
      >
        {t('drawer.approveRequest')}
      </Button>
      <Button
        {...triggerProps}
        ref={triggerRef}
        alignSelf="end"
        onClick={() => setIsPopoverOpen(!isOpen)}
        variant="tertiary"
        disabled={approving || declining}
        color="red"
      >
        {t('drawer.rejectRequest')}
      </Button>
      {isOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          width="340px"
          dismissWithOutsideClick={true}
        >
          <Grid autoFlow="rows" gap="space16">
            <Trans t={t} i18nKey={translations.title} parent="span">
              <Text fontWeight="semibold">Why is the request rejected</Text>
              <Text color="gray600" fontSize="small">
                optional
              </Text>
            </Trans>
            <FieldContainer>
              <Textarea
                autoFocus
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  const newValue =
                    e.target.value !== '' ? e.target.value : undefined;

                  setDeclineReason(newValue);
                }}
                value={declineReason}
                placeholder={t(translations.placeholderField)}
                maxLength={100}
              />
            </FieldContainer>

            <Text color="gray600">
              {t('drawer.rejectRequestPopover.cardholderReceivesEmail', {
                cardholder: readableCardholderName,
              })}
            </Text>

            <Flex gap="space8">
              <Button
                variant="secondary"
                onClick={() => setIsPopoverOpen(false)}
              >
                {t('drawer.rejectRequestPopover.cancel')}
              </Button>
              <Button
                color="red"
                loading={declining}
                disabled={declining}
                onClick={async () => {
                  await onDecline(declineReason);
                  setIsPopoverOpen(false);
                }}
              >
                {t('drawer.rejectRequest')}
              </Button>
            </Flex>
          </Grid>
        </Popover>
      )}
    </Flex>
  );
};
