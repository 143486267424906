import { Button, Card, Grid, Text, useTheme } from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail } from './TopupDetails';

interface SingleDetailsProps {
  translation: string;
  value: string | undefined;
  id: Detail;
}

export const SingleDetail = ({
  translation,
  value,
  id,
}: SingleDetailsProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success } = useToastMessage();
  const { colors } = useTheme();

  if (!value) {
    return null;
  }

  const copyDetail = () => {
    const text = document.getElementById(`${id}-value`)?.textContent ?? '';
    void navigator.clipboard.writeText(text);
    setIsCopied(true);
    success(t('organizationLimit.topupDrawer.successToastMessage'));
  };

  return (
    <Grid
      templateColumns="80% 20%"
      alignContent="center"
      gap="space16"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Card
        boxShadow="none"
        padding="space8"
        background={isHovered ? colors.gray200 : 'white'}
        hover={{ background: colors.gray200 }}
        onClick={copyDetail}
        onMouseLeave={() => setIsCopied(false)}
        style={{ cursor: 'pointer' }}
      >
        <Grid templateColumns="1fr 2fr" id={id} alignItems="center">
          <Text fontWeight="500" fontSize="14px">
            {t(translation)}
          </Text>
          <Text fontSize="14px" id={`${id}-value`}>
            {value}
          </Text>
        </Grid>
      </Card>
      {isHovered && (
        <Button
          alignSelf="center"
          onClick={copyDetail}
          variant="secondary"
          iconPosition="right"
          justifySelf="start"
          icon={isCopied ? 'check' : undefined}
          size="small"
        >
          {isCopied
            ? t('organizationLimit.topupDrawer.copied')
            : t('organizationLimit.topupDrawer.singleCopy')}
        </Button>
      )}
    </Grid>
  );
};
