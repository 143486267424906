import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Image,
  Modal,
  Tag,
  Text,
  useModal,
  ModalProps,
} from '@candisio/design-system';
import ContactsLogo from 'assets/promotions/icon-contacts.svg';
import ChecklistLogo from 'assets/promotions/icon-easy-implementation.svg';
import SafetyLogo from 'assets/promotions/icon-safe-data-transfer.svg';
import SapB1Logo from 'assets/promotions/icon-sap-b1.svg';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';

const SAP_PROMOTION_KEY = 'sap_promotion_dismissed';

export const SapPromotionModal = (modalProps: ModalProps) => {
  const [t] = useTranslation('exports');
  const [tCommon] = useTranslation();

  const user = useCurrentUser();
  const organization = useFullOrganization();

  return (
    <Modal
      titleInfo={
        <Grid>
          <Flex alignItems="center" gap="space16">
            <Heading as="h3">{t('sapPromotion.modal.title')}</Heading>
            <Tag color="blue" variant="secondary">
              {t('sapPromotion.betaTag')}
            </Tag>
          </Flex>
        </Grid>
      }
      width="60rem"
      background="gray200"
      isOpen={modalProps.isOpen}
      closeLabel={tCommon('common.close')}
      {...modalProps}
    >
      <Grid gap="space32" paddingBottom="space32" alignItems="start">
        <Grid gap="space32" templateColumns="1fr 1fr">
          <Grid templateColumns="auto 1fr" gap="space24">
            <Image width={78} src={SapB1Logo} alt="sap" />
            <Grid templateRows="auto 1fr" gap="space8">
              <Text fontSize="large">{t('sapPromotion.modal.api.title')}</Text>
              <Text fontSize="basic" color="gray700">
                {t('sapPromotion.modal.api.description')}
              </Text>
            </Grid>
          </Grid>
          <Grid templateColumns="auto 1fr" gap="space24">
            <Image width={78} src={SafetyLogo} alt="sap" />
            <Grid templateRows="auto 1fr" gap="space8">
              <Text fontSize="large">
                {t('sapPromotion.modal.transfer.title')}
              </Text>
              <Text fontSize="basic" color="gray700">
                {t('sapPromotion.modal.transfer.description')}
              </Text>
            </Grid>
          </Grid>
        </Grid>

        <Grid gap="space32" templateColumns="1fr 1fr">
          <Grid templateColumns="auto 1fr" gap="space24">
            <Image width={78} src={ChecklistLogo} alt="sap" />
            <Grid templateRows="auto 1fr" gap="space8">
              <Text fontSize="large">
                {t('sapPromotion.modal.implementation.title')}
              </Text>
              <Text fontSize="basic" color="gray700">
                {t('sapPromotion.modal.implementation.description')}
              </Text>
            </Grid>
          </Grid>
          <Grid templateColumns="auto 1fr" gap="space24">
            <Image width={78} src={ContactsLogo} alt="sap" />
            <Grid templateRows="auto 1fr" gap="space8">
              <Text fontSize="large">
                {t('sapPromotion.modal.dataImport.title')}
              </Text>
              <Text fontSize="basic" color="gray700">
                {t('sapPromotion.modal.dataImport.description')}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Card background="bluebg">
        <Grid templateColumns="1fr auto" gap="space8" alignItems="center">
          <Text fontSize="basic">
            {t('sapPromotion.modal.card.description')}
          </Text>
          {user && organization && (
            <Button
              color="blue"
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href={t('sapPromotion.modal.card.linkTo', {
                email: user.email,
                name: user.firstName,
                company_name: organization.name,
                company_id: organization.id,
              })}
            >
              {t('sapPromotion.modal.card.button')}
            </Button>
          )}
        </Grid>
      </Card>
    </Modal>
  );
};

export const SapPromotion = () => {
  const { triggerProps, modalProps, isOpen } = useModal();

  const [t] = useTranslation('exports');
  const [dismissed, setDismissed] = useLocalStorage(SAP_PROMOTION_KEY, false);

  const onClick = () => {
    setDismissed(true);
  };

  if (dismissed) {
    return null;
  }

  return (
    <Card boxShadow="noShadow" background="bluebg">
      <Grid gap="space8">
        <Grid templateColumns="1fr auto" gap="space8" alignItems="center">
          <Tag color="blue" variant="secondary">
            {t('sapPromotion.betaTag')}
          </Tag>
          <Button
            icon="close"
            variant="tertiary"
            size="small"
            onClick={onClick}
          />
        </Grid>
        <Grid gap="space16">
          <Grid>
            <Text fontSize="large" fontWeight="semibold">
              {t('sapPromotion.card.title')}
            </Text>
            <Text fontSize="basic">{t('sapPromotion.card.subtitle')}</Text>
          </Grid>
          <Box>
            <Button variant="primary" color="blue" {...triggerProps}>
              {t('sapPromotion.card.button')}
            </Button>
          </Box>
        </Grid>

        {isOpen ? <SapPromotionModal {...modalProps} /> : null}
      </Grid>
    </Card>
  );
};
