import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import {
  ShouldShowField,
  SplitBookingFields,
} from 'components/Form/SplitBookingsForm/types';
import {
  GetDocumentForDraftQuery,
  GetDocumentQuery,
  IntegrationName,
} from 'generated-types/graphql.types';
import {
  Entitlements,
  useCandisFeatureFlags,
  useEntitlementsFlag,
} from 'hooks/useCandisFeatureFlags';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { isNil } from 'lodash';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useDatev } from 'orgConfig/datev';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { DOCUMENT_TYPES } from 'views/Inbox/DocumentProcessing/consts';
import { useAccountingAreasData } from 'views/Settings/AccountingAreas/toolkit/hooks/useAccountingAreasData';

export const useShouldShowField = (
  document: Pick<
    NonNullable<
      GetDocumentForDraftQuery['getDocument'] | GetDocumentQuery['getDocument']
    >,
    'bookings' | 'transactions'
  >
) => {
  const shouldShowPostingTextField = useShowPostingTextField();

  const shouldShowCostCenterField = useShowCostCenterField();

  const shouldShowCostObjectField = useShowCostObjectField();

  const shouldShowExtraCostInfoField = useShowExtraCostInfoField();

  const shouldShowGeneralLedgerAccountField =
    useShowGeneralLedgerAccountField();

  const shouldShowArtistSocialInsuranceCodeField =
    useShowArtistSocialInsuranceCodeField();

  const shouldShowBookingKeyField = useShowTaxCodeField();

  const hasTransactions = (document.transactions?.length ?? 0) > 0;

  const showAccountingDataField: ShouldShowField = useCallback(
    (field, index) => {
      switch (field) {
        case SplitBookingFields.costCenterId:
          return (
            shouldShowCostCenterField ||
            !isNil(document.bookings?.[index]?.costCenter?.value.id)
          );
        case SplitBookingFields.costObjectId:
          return (
            shouldShowCostObjectField ||
            !isNil(document.bookings?.[index]?.costObject?.value.id)
          );
        case SplitBookingFields.extraCostInfoId:
          return (
            shouldShowExtraCostInfoField ||
            !isNil(document.bookings?.[index]?.extraCostInfo?.value.id)
          );
        case SplitBookingFields.bookingKeyId:
          return (
            shouldShowBookingKeyField ||
            !isNil(document.bookings?.[index]?.bookingKey?.value.id)
          );
        case SplitBookingFields.generalLedgerAccountId:
          return (
            shouldShowGeneralLedgerAccountField ||
            !isNil(document.bookings?.[index]?.generalLedgerAccount?.value.id)
          );
        case SplitBookingFields.artistSocialInsuranceCode:
          return (
            shouldShowArtistSocialInsuranceCodeField ||
            !isNil(document.bookings?.[index]?.artistSocialInsuranceCode)
          );
        case SplitBookingFields.postingText:
          return (
            shouldShowPostingTextField ||
            !isNil(document.bookings?.[index]?.postingText)
          );
        default:
          return true;
      }
    },
    [
      document.bookings,
      shouldShowCostCenterField,
      shouldShowCostObjectField,
      shouldShowExtraCostInfoField,
      shouldShowGeneralLedgerAccountField,
      shouldShowArtistSocialInsuranceCodeField,
      shouldShowBookingKeyField,
      shouldShowPostingTextField,
    ]
  );

  const showAccountsPayableNumber = ({
    isIncomingInvoice,
  }: {
    isIncomingInvoice: boolean;
  }) => {
    return isIncomingInvoice && !hasTransactions;
  };

  const showAccountsReceivableNumber = ({
    isOutgoingInvoice,
    isSapActive,
  }: {
    isOutgoingInvoice: boolean;
    isSapActive: boolean;
  }) => {
    return isOutgoingInvoice && !hasTransactions && !isSapActive;
  };

  return {
    showAccountingDataField,
    showAccountsPayableNumber,
    showAccountsReceivableNumber,
  };
};

export const useShowInvoiceCorrectionField = () => {
  const sap = useSap();
  const otherIntegration = useOtherIntegration();

  const showInvoiceCorrectionField = !(
    sap.isActive || otherIntegration.showDocumentTypesForOtherIntegrations
  );

  return showInvoiceCorrectionField;
};

export const useShowPostingTextField = () => {
  const { bdsConnected } = useDatev();
  const sap = useSap();
  const otherIntegration = useOtherIntegration();
  const { ft, show } = useFeatureToggle();
  const [newFieldsForIntegrationOtherFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.newFieldsForIntegrationOther,
  ]);

  const showFieldsForOtherIntegration =
    otherIntegration.isActive && newFieldsForIntegrationOtherFF;

  return (
    !sap.isActive &&
    show(ft.POSTING_TEXT) &&
    (bdsConnected || showFieldsForOtherIntegration)
  );
};

export const useShowCostCenterField = () => {
  const fullOrganization = useFullOrganization();
  const hasOrganizationActiveCostCenters = fullOrganization?.hasCostCenters;

  return Boolean(hasOrganizationActiveCostCenters);
};

export const useShowCostObjectField = () => {
  const fullOrganization = useFullOrganization();
  const hasOrganizationActiveCostObjects = fullOrganization?.hasCostObjects;

  return Boolean(hasOrganizationActiveCostObjects);
};

export const useShowExtraCostInfoField = () => {
  const sap = useSap();
  const [artistSocialInsuranceAndExtraCostInfoFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.artistSocialInsuranceAndExtraCostInfo,
  ]);

  const fullOrganization = useFullOrganization();

  const hasOrganizationActiveExtraCostInfos =
    fullOrganization?.hasExtraCostInfos;

  return Boolean(
    !sap.isActive &&
      artistSocialInsuranceAndExtraCostInfoFF &&
      hasOrganizationActiveExtraCostInfos
  );
};

export const useShowArtistSocialInsuranceCodeField = () => {
  const sap = useSap();
  const [artistSocialInsuranceAndExtraCostInfoFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.artistSocialInsuranceAndExtraCostInfo,
  ]);

  return !sap.isActive && artistSocialInsuranceAndExtraCostInfoFF;
};

export const useShowGeneralLedgerAccountField = () => {
  const fullOrganization = useFullOrganization();
  const hasOrganizationActiveGeneralLedgerAccounts =
    fullOrganization?.hasActiveBookingAccounts;

  return Boolean(hasOrganizationActiveGeneralLedgerAccounts);
};
export const useShowProjectCodeField = () => {
  const { shouldUseSapProjectCodes } = useSap();
  return shouldUseSapProjectCodes;
};

interface ShowAccountingAreaFieldProps {
  isAccountingAreaOnDocument?: boolean;
}

export const useShowAccountingAreaField = ({
  isAccountingAreaOnDocument,
}: ShowAccountingAreaFieldProps) => {
  const { shouldUseAccountingAreas } = useOtherIntegration();

  const { activeAccountingAreas } = useAccountingAreasData();

  const hasActiveAccountingAreas =
    (activeAccountingAreas?.accountingAreas.length ?? 0) > 0;

  return Boolean(
    (shouldUseAccountingAreas && hasActiveAccountingAreas) ||
      isAccountingAreaOnDocument
  );
};

export const useShowTaxCodeField = () => {
  const fullOrganization = useFullOrganization();
  const hasOrganizationActiveBookingKeys =
    fullOrganization?.hasActiveBookingKeys;

  const hasOrganizationActiveGeneralLedgerAccounts =
    fullOrganization?.hasActiveBookingAccounts;

  return Boolean(
    hasOrganizationActiveBookingKeys &&
      hasOrganizationActiveGeneralLedgerAccounts
  );
};

export const useShowDeliveryDateField = () => {
  const sap = useSap();

  return !sap.isActive;
};

export const useShowPurchaseOrderNumberField = () => {
  // this PO number is not related with SAP PO number
  const sap = useSap();
  const entitlements = useEntitlementsFlag();

  const [purchaseOrderNumberFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.purchaseOrderNumber,
  ]);

  return (
    !sap.isActive &&
    purchaseOrderNumberFF &&
    entitlements !== Entitlements.LEGACY
  );
};

export const useShowCreditCardPaymentField = () => {
  const creditCardsSetup = useCreditCardsSetup();

  return creditCardsSetup.isInUse;
};

export const useShowPostingDateField = () => {
  const sap = useSap();

  return sap.isActiveIntegration;
};

export const useShowCreditMemoFieldsProcessingForm = () => {
  const sap = useSap();
  const [type] = useWatch<ProcessingFormValues, ['type']>({ name: ['type'] });

  const isCreditMemo =
    sap.isActiveIntegration && type === DOCUMENT_TYPES.EINGANGSGUTSCHRIFT;

  return !isCreditMemo;
};

export const useIncludePurchaseOrderData = () => {
  const sap = useSap();
  const { shouldUseSapPurchaseOrder } = sap;
  const integrationSetting = useIntegrationSettings();
  const showPurchaseOrderNumberField = useShowPurchaseOrderNumberField();

  return (
    (integrationSetting === IntegrationName.Sap && shouldUseSapPurchaseOrder) ||
    showPurchaseOrderNumberField
  );
};

export const useShowAccountsReceivableNumberColumn = () => {
  const { shouldUseCoreDataApi } = useOtherIntegration();

  return !shouldUseCoreDataApi;
};

interface ShowDueDateFieldProps {
  allDueDatesAreSame: boolean;
  isSplitBookingForm?: boolean;
}

export const useShowDueDateField = ({
  allDueDatesAreSame,
  isSplitBookingForm,
}: ShowDueDateFieldProps) => {
  const { isActive: isSapOrg, shouldUseSapPurchaseOrder } = useSap();
  if (isSplitBookingForm) {
    return !isSapOrg;
  }

  return allDueDatesAreSame || !shouldUseSapPurchaseOrder;
};

interface ShowCashDiscountFieldsProps {
  isInvoiceCorrection: boolean;
  isIncomingInvoice: boolean;
  hasTransaction: boolean;
  isNonePaymentCondition?: boolean;
}

export const useShowCashDiscountFields = ({
  isInvoiceCorrection,
  isIncomingInvoice,
  hasTransaction,
  isNonePaymentCondition,
}: ShowCashDiscountFieldsProps) => {
  const { isActive: isSapOrg } = useSap();

  const shouldShowCashDiscount =
    isIncomingInvoice && !isInvoiceCorrection && !hasTransaction;

  return isSapOrg
    ? shouldShowCashDiscount && !isNonePaymentCondition
    : shouldShowCashDiscount;
};

interface ShowPaymentConditionFieldProps {
  isInvoiceCorrection: boolean;
  isIncomingInvoice: boolean;
}

export const useShowPaymentConditionField = ({
  isInvoiceCorrection,
  isIncomingInvoice,
}: ShowPaymentConditionFieldProps) => {
  return isIncomingInvoice && !isInvoiceCorrection;
};

export const useShowSwiftCodeField = () => {
  const { shouldUseCoreDataApi } = useOtherIntegration();

  return !shouldUseCoreDataApi;
};

export const useShowPaymentStatusField = () => {
  const { shouldUseCoreDataApi } = useOtherIntegration();

  return !shouldUseCoreDataApi;
};

export const useShowCreateTransferField = () => {
  const { shouldUseCoreDataApi } = useOtherIntegration();

  return !shouldUseCoreDataApi;
};

export const useMakeBankingDetailsReadOnly = () => {
  const { shouldUseCoreDataApi } = useOtherIntegration();

  return shouldUseCoreDataApi;
};

export const useShowPaymentMediumColumn = () => {
  const { shouldUseCoreDataApi } = useOtherIntegration();

  const { bdsConnected } = useDatev();

  return bdsConnected && !shouldUseCoreDataApi;
};

export const useIsPaymentDueDateReadOnly = ({
  isFormReadOnly,
  isNonePaymentCondition,
}: {
  isNonePaymentCondition: boolean;
  isFormReadOnly?: boolean;
}) => {
  const { isActive: isSapOrg } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();

  return (
    shouldUseCoreDataApi ||
    isFormReadOnly ||
    (isSapOrg ? !isNonePaymentCondition : false)
  );
};
