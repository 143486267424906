import {
  FieldContainer,
  Grid,
  IconProps,
  Text,
  Textarea,
} from '@candisio/design-system';
import { isNil } from 'lodash';
import { ChangeEvent, FocusEventHandler, FocusEvent, ReactNode } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface HookFormTextareaFieldProps<TFormValues extends FieldValues> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Is field disabled? */
  disabled?: boolean;
  /** Should field receive focus on mount? */
  autoFocus?: boolean;
  /** Unique identifier for the text area */
  id?: string;
  /** Field label */
  label?: string;
  /** Maximum number of text rows */
  maxRows?: number;
  /** Maximum number of characters that user can input */
  maxLength?: number;
  /** Message to display in tooltip */
  message?: ReactNode;
  /** Minimum number of text rows */
  minRows?: number;
  /** Called when field value changes */
  onChange?: (newValue: string | null) => void;
  /** Placeholder text shown when no value is set */
  placeholder?: string;
  /** Is field read only? */
  readOnly?: boolean;
  /** Warning to display inside field */
  warning?: string;
  /** Field variant */
  variant?: 'default' | 'error' | 'warning' | 'success';
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean | undefined;
  infoTooltip?: {
    message: ReactNode;
    iconProps?: IconProps;
  };
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
}

/**
 * Controlled textarea field for React Hook Form
 *
 * To connect to your form you must either:
 * - ensure the field is inside a `FormProvider`, or
 * - explicitly pass the `control` prop returned by `useForm`
 */
export const HookFormTextareaField = <TFormValues extends FieldValues>({
  autoFocus,
  control,
  disabled,
  id,
  label,
  maxLength,
  maxRows,
  message,
  minRows,
  name,
  onChange: onChangeProp,
  placeholder,
  readOnly: readOnlyProp,
  variant,
  warning,
  isLoading,
  infoTooltip,
  onBlur: onBlurProp,
}: HookFormTextareaFieldProps<TFormValues>) => {
  const [t] = useTranslation();
  const { field, fieldState, formState } = useController({ control, name });
  const { onChange, value, onBlur, ...fieldProps } = field;
  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;
  const readOnly = readOnlyProp || formState.isSubmitting;

  const handleFocus: FocusEventHandler<HTMLTextAreaElement> = event => {
    event.target.select();
  };

  const fieldVariant = hasError ? 'error' : variant;

  return (
    <>
      <FieldContainer
        disabled={disabled}
        label={label}
        readOnly={readOnly}
        variant={fieldVariant}
        isLoading={isLoading}
        infoTooltip={infoTooltip}
        description={
          warning
            ? {
                text: warning,
                color: 'warning',
              }
            : undefined
        }
      >
        <Grid gap="space2">
          <Textarea
            autoFocus={autoFocus}
            disabled={disabled}
            id={id}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              const newValue = e.target.value !== '' ? e.target.value : null;
              onChange(newValue);
              onChangeProp?.(newValue);
            }}
            maxRows={maxRows}
            minRows={minRows}
            placeholder={readOnly ? '–' : placeholder}
            readOnly={readOnly}
            value={value ?? ''}
            showMessageOnFocus={fieldVariant === 'error'}
            onFocus={handleFocus}
            message={errorMessage ?? message}
            variant={fieldVariant}
            onBlur={(e: FocusEvent<HTMLTextAreaElement, Element>) => {
              onBlurProp?.(e);
            }}
            {...fieldProps}
          />
          {!isNil(maxLength) && maxLength !== Infinity && value?.length > 0 && (
            <Grid alignItems="center" justifyContent="end" fontSize="xsmall">
              <Text>
                {t('common.textarea.characterCount', {
                  currentLength: value?.length ?? 0,
                  maxLength,
                })}
              </Text>
            </Grid>
          )}
        </Grid>
      </FieldContainer>
    </>
  );
};
