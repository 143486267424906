import { Box, Grid } from '@candisio/design-system';
import { ReactNode } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

const ACTIONS_HEIGHT_BUFFER_PX = 20;
const ACTIONS_HEIGHT_FALLBACK_VALUE = 140;

const calculatePadding = (scrollPadding?: number) =>
  (scrollPadding ?? ACTIONS_HEIGHT_FALLBACK_VALUE) + ACTIONS_HEIGHT_BUFFER_PX;

/**
 * Fix focused controls hidden behind the sticky content
 * @link: https://tetralogical.com/blog/2023/06/08/focus-in-view/
 */
export const BoxWrapper = styled(Box)<{ ['data-scroll-padding']?: number }>`
  overflow: hidden;
  height: 100%;

  & * {
    scroll-padding: ${({ 'data-scroll-padding': scrollPadding }) =>
      calculatePadding(scrollPadding) + 'px'};
  }
`;

export const LeftSectionOuterWrapper = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Grid alignContent="start" gap="space8" paddingTop="space32" height="100%">
    {children}
  </Grid>
);

export const LeftSectionContentWrapper = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Grid gap="space8" overflowY="auto">
    {children}
  </Grid>
);
