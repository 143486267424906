import { Button, Flex, Tag, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export function SearchOverlayUpsell({ openModal }: { openModal: () => void }) {
  const [t] = useTranslation();
  return (
    <Flex direction="column" gap="space8">
      <Flex
        direction="column"
        gap="space8"
        background="purplebg"
        padding="space12"
        borderRadius="small"
      >
        <Flex gap="space8">
          <Tag color="purple" variant="secondary">
            {t('tag.addOn')}
          </Tag>
          <Text
            fontWeight="semibold"
            fontSize="basic"
            lineHeight="paragraph"
            color="gray800"
          >
            {t('ecm:searchFilter.popover.upsell.title')}
          </Text>
        </Flex>
        <Text color="gray700" fontWeight="basic">
          {t('ecm:searchFilter.popover.upsell.description')}
        </Text>
      </Flex>
      <Button color="purple" alignSelf="end" size="small" onClick={openModal}>
        {t('ecm:searchFilter.popover.upsell.cta.text')}
      </Button>
    </Flex>
  );
}
