import { Button } from '@candisio/design-system';
import { colors } from '@candisio/design-system/src/Theme/themeValues';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  * > svg {
    color: ${colors.gray500};
  }
`;
