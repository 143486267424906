import { Button, Drawer, Grid, Heading, useId } from '@candisio/design-system';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { useSap } from 'orgConfig/sap';
import { useTranslation } from 'react-i18next';
import { ArchiveButton } from './ArchiveButton';
import { ArchivedActions } from './ArchivedActions';
import {
  PaymentConditionsForm,
  PaymentConditionsFormProps,
  PaymentConditionsFormValues,
} from './PaymentConditionsForm';

export interface PaymentConditionsDrawerProps {
  /** Drawer action */
  action: 'create' | 'edit';
  /** Open state of the drawer */
  isOpen?: boolean;
  /** Called when the drawer is closed */
  onClose?: () => void;
  /** Called when the action of the drawer is triggered */
  onAction: PaymentConditionsFormProps['onSubmit'];
  /** Called when condition is archived */
  onArchive?: () => void;
  /** Called when condition is restored */
  onRestore?: () => void;
  /** Initial values when the form is first rendered  */
  defaultValues?: PaymentConditionsFormValues;
  /** Is loading payment condition */
  loading?: boolean;
  /** Is creating/saving payment condition */
  updating?: boolean;
  /** Is archiving payment condition */
  archiving?: boolean;
  /** Is payment condition archived */
  isArchived?: boolean;
  /** Is restoring payment condition */
  restoring?: boolean;
}

export const PaymentConditionsDrawer = ({
  action,
  defaultValues,
  isOpen = false,
  loading = false,
  updating = false,
  archiving = false,
  onAction,
  onArchive,
  onClose,
  isArchived,
  onRestore,
  restoring = false,
}: PaymentConditionsDrawerProps) => {
  const [t] = useTranslation('settings');
  const formId = useId();
  const { isActiveIntegration: shouldUseSapPaymentConditions } = useSap();

  const submitLabel =
    action === 'create'
      ? t('paymentConditions.form.buttons.submit.create')
      : t('paymentConditions.form.buttons.submit.edit');

  const titleName = shouldUseSapPaymentConditions
    ? t('paymentConditions.drawer.title.view')
    : action === 'create'
      ? t('paymentConditions.drawer.title.create')
      : t('paymentConditions.drawer.title.edit');

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerLayout
        footer={
          !shouldUseSapPaymentConditions && (
            <Grid
              autoFlow="column"
              gap="space16"
              justifyContent="space-between"
            >
              <Button
                type="submit"
                form={formId}
                loading={updating}
                disabled={archiving || isArchived}
              >
                {submitLabel}
              </Button>
              {action === 'edit' && !isArchived && (
                <ArchiveButton
                  onArchive={onArchive}
                  archiving={archiving}
                  disabled={updating}
                />
              )}
            </Grid>
          )
        }
        header={<Heading as="h3">{titleName}</Heading>}
        onClose={onClose}
      >
        {!loading && (
          <Grid gap="space32">
            {isArchived && !shouldUseSapPaymentConditions && (
              <ArchivedActions onRestore={onRestore} restoring={restoring} />
            )}
            <PaymentConditionsForm
              action={action}
              formId={formId}
              onSubmit={onAction}
              defaultValues={defaultValues}
              readOnly={isArchived || shouldUseSapPaymentConditions}
            />
          </Grid>
        )}
      </DrawerLayout>
    </Drawer>
  );
};
