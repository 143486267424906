import {
  Button,
  Flex,
  Grid,
  ListView,
  ListViewProps,
  MenuButton,
  MenuItem,
} from '@candisio/design-system';
import {
  SearchField,
  SearchFieldProps,
} from 'components/SearchField/SearchField';
import { AnimatePresence, motion } from 'framer-motion';
import { noop } from 'lodash';
import { Children, ComponentProps, Key, ReactNode } from 'react';
import { useLocale } from 'utils/useLocale';
import { FilterableListSkeleton } from './FilterableListSkeleton';

type MenuButtonProps = {
  /** List of items to show in the menuButton  */
  menuButtonItems: Iterable<MenuItem>;
  /** Function to select which sort or filter to use */
  onClick: (value: Key[]) => void;
  /** Selected item */
  actionValue: Key[];
  /** Button text that changes depending the selected menuItem */
  text: string;
  loading?: boolean;
};

export interface FilterableListBasicProps {
  /** List Item to be rendered inside the component  */
  children: ListViewProps['children'];
  /** Custom element in toolbar */
  customCreate?: ReactNode;
  emptyDataState?: ReactNode;
  hasMore?: boolean;
  isLoading?: boolean;
  kebabMenu?: ReactNode;
  onCreate?: {
    /** Function to open creation modal or drawer */
    value: () => void;
    text: string;
  };
  /** Callback to fetch more data with infinite scroll */
  onEndReached?: (index: number) => void;
  /** List of extra menuButtons to display to users */
  menuButtons?: MenuButtonProps[];
  width?: string;
  listActions?: ReactNode;
  onAction?: ComponentProps<typeof ListView>['onAction'];
  selectionMode?: ComponentProps<typeof ListView>['selectionMode'];
  selectedKeys?: ComponentProps<typeof ListView>['selectedKeys'];
  onSelectionChange?: ComponentProps<typeof ListView>['onSelectionChange'];
}

type FilterableListUnion =
  | {
      searchFieldProps?: Partial<SearchFieldProps>;
      searchField?: {
        /** Function to update the string to use for filter */
        onSearchFilter: (filter: string) => void;
        placeholder: string;
        /** String used to filter */
        searchQuery: string;
      };
      emptySearchState?: ReactNode;
      heading?: never;
    }
  | {
      heading: ReactNode;
      searchFieldProps?: never;
      searchField?: never;
      emptySearchState?: never;
    };

export type FilterableListProps = FilterableListBasicProps &
  FilterableListUnion;

const rowStyles = {
  gap: 'space32',
  alignItems: 'center',
  padding: 'space8 space16',
  borderBottom: '1px solid gray200',
  // cursed: force height for row with single-lined description
  height: '65px',
};

const MotionGrid = motion(Grid);

const gridVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const listActionVariants = {
  collapsed: { height: '0' },
  expanded: { height: '100%' },
};

export const FilterableList = ({
  children,
  customCreate,
  emptyDataState,
  emptySearchState,
  isLoading,
  kebabMenu,
  heading,
  listActions,
  menuButtons,
  onAction,
  onCreate,
  onEndReached,
  searchField,
  searchFieldProps,
  width,
  selectionMode = 'none',
  selectedKeys = [],
  onSelectionChange = noop,
}: FilterableListProps) => {
  const locale = useLocale();
  const hasToolbar = Boolean(searchField || menuButtons || heading);

  const hasData = Children.count(children) > 0;

  const emptyState =
    searchField && searchField.searchQuery.length > 0
      ? emptySearchState
      : emptyDataState;

  const showLoadingState = isLoading && !hasData;
  const showDataList = hasData;
  const showEmptyState = !isLoading && !hasData;

  return (
    <Grid
      background="gray0"
      borderRadius="medium"
      height="100%"
      overflow="hidden"
      template={`
        "toolbar"     auto
        "content"     1fr
        "listActions" auto
        / auto
      `}
      width={width}
    >
      {hasToolbar && (
        <Grid background="gray100" padding="space16" gridArea="toolbar">
          <Flex alignItems="center" justifyContent="space-between">
            {heading}
            {searchField && (
              <SearchField
                placeholder={searchField?.placeholder}
                value={searchField?.searchQuery}
                onChange={searchField?.onSearchFilter}
                inputSize={locale.startsWith('en') ? 28 : 30}
                {...searchFieldProps}
              />
            )}
            <Flex alignItems="center" gap="space8">
              {menuButtons?.map((button, index) => (
                <MenuButton
                  key={button.actionValue + ''}
                  variant="tertiary"
                  size="small"
                  items={button.menuButtonItems}
                  onChange={value => {
                    if (!value.length) return;

                    button.onClick(value);
                  }}
                  loading={button.loading}
                  value={button.actionValue as (string | number)[]}
                  selectionMode="single"
                >
                  {button.text}
                </MenuButton>
              ))}
              {onCreate && (
                <Button icon="plus" onClick={() => onCreate?.value()}>
                  {onCreate?.text}
                </Button>
              )}
              {customCreate}
              {kebabMenu}
            </Flex>
          </Flex>
        </Grid>
      )}
      <MotionGrid
        gridArea="content"
        alignItems="center"
        background="gray0"
        variants={gridVariants}
        initial="hidden"
        animate="visible"
        transition={{ ease: 'easeOut', duration: 0.8, delay: 0.25 }}
      >
        {showLoadingState && <FilterableListSkeleton />}
        {showDataList && (
          <ListView
            {...(selectionMode !== 'none' && {
              selectionMode,
              selectedKeys,
              onSelectionChange,
              rowStyles,
            })}
            onAction={onAction}
            isVirtualized
            onEndReached={onEndReached}
            height="100%"
          >
            {children}
          </ListView>
        )}
        {showEmptyState && emptyState}
      </MotionGrid>
      <AnimatePresence>
        {listActions && (
          <MotionGrid
            gridArea="listActions"
            variants={listActionVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{ ease: 'easeOut', duration: 0.3, delay: 0.25 }}
          >
            {listActions}
          </MotionGrid>
        )}
      </AnimatePresence>
    </Grid>
  );
};
