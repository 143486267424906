import { useResetReimbursementCaseApprovalMutation } from 'generated-types/graphql.types';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
} from '../toolkit/queries';
import { ReimbursementRouteParams } from './useReimbursementNavigation';

export const useResetReimbursementApproval = () => {
  const { reimbursementId } = useParams<ReimbursementRouteParams>();

  const [
    resetReimbursementCaseApproval,
    { loading: resetReimbursementApprovalPending },
  ] = useResetReimbursementCaseApprovalMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'reimbursementCasesCount',
      {
        query: reimbursementHistoryQuery,
        variables: { id: reimbursementId },
      },
      {
        query: reimbursementCaseByIdQuery,
        variables: {
          id: reimbursementId,
        },
      },
    ],
  });

  const handleResetReimbursementApproval = async () => {
    const { data } = await resetReimbursementCaseApproval({
      variables: {
        id: reimbursementId,
      },
    });

    const isResetSuccessful = data?.resetReimbursementCaseApproval.success;

    if (isResetSuccessful) {
      return { status: 'success' };
    }

    return { status: 'error' };
  };

  return {
    resetReimbursementApprovalPending,
    resetReimbursementApproval: handleResetReimbursementApproval,
  };
};
