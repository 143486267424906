import {
  Grid,
  ComboBox,
  FieldContainer,
  NumberInput,
  Item,
  MessageBox,
} from '@candisio/design-system';
import { Maybe } from '@graphql-tools/utils';
import {
  IsFieldEditable,
  SplitBookingFields,
} from 'components/Form/SplitBookingsForm/types';
import { ErrorIcon } from 'components/Icons/DefaultIcons';
import { useSplitBookingsContext } from 'containers/SplitBookings/SplitBookingsContext';
import { AnimatePresence, motion } from 'framer-motion';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from 'utils/useLocale';
import {
  sortCurrencies,
  toCurrencyFieldItem,
} from 'views/Inbox/DocumentProcessing/toCurrencyFieldItem';
import { RoundingAmount } from './RoundingAmount';

export interface HeaderProps {
  readOnly: boolean;
  isFieldEditable: IsFieldEditable;
  roundingDifference?: Maybe<number>;
  headerData: {
    amount?: Maybe<number>;
    currency?: Maybe<string>;
    remainingAmount?: Maybe<number>;
  };
  onChange: ({
    amount,
    currency,
  }: {
    amount?: Maybe<number>;
    currency?: Maybe<string>;
  }) => void;
}

const MotionGrid = motion(Grid);
const RemainingAmountError = ({ errorMessage }: { errorMessage: String }) => {
  const transition = { duration: 0.3, ease: 'easeInOut' };

  return (
    <AnimatePresence>
      <MotionGrid
        gap="space4"
        templateColumns="auto 1fr"
        alignItems="center"
        justifyContent="start"
        color="red500"
        fontSize="small"
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: 'auto', transition }}
        exit={{ opacity: 0, height: 0, transition }}
      >
        <ErrorIcon size="space16" />
        {errorMessage}
      </MotionGrid>
    </AnimatePresence>
  );
};

export const Header = ({
  roundingDifference,
  isFieldEditable,
  readOnly,
  headerData: { currency, amount, remainingAmount },
  onChange,
}: HeaderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);
  const locale = useLocale();
  const { shouldUseSapNetAmount } = useSap();

  const { showSubmitErrors, roundingAmount } = useSplitBookingsContext();

  const [currencySearch, setCurrencySearch] = useState(currency ?? '');

  const formatOptions = useMemo(
    (): Intl.NumberFormatOptions => ({
      currency: currency ?? undefined,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: currency ? 'currency' : 'decimal',
    }),
    [currency]
  );

  const remainingAmountErrorMessage =
    !shouldUseSapNetAmount &&
    remainingAmount &&
    showSubmitErrors &&
    // TODO: Verify and refactor: should not show submit errors for read only?
    !readOnly &&
    Math.abs(remainingAmount) > Math.abs(roundingDifference ?? 0)
      ? t('errors.totalAmount')
      : null;

  const isCurrencySelected = currencySearch === currency;
  const isCurrencyMatched = (currencyItem: string) =>
    currencyItem.toLowerCase().includes(currencySearch.toLowerCase());

  const currencyItems = isCurrencySelected
    ? sortCurrencies(Object.values(DocumentCurrency)).map(toCurrencyFieldItem)
    : sortCurrencies(Object.values(DocumentCurrency))
        .filter(isCurrencyMatched)
        .map(toCurrencyFieldItem);

  const getAmountError = () => {
    if (amount === null) {
      return t('errors.fieldRequired', {
        label: shouldUseSapNetAmount
          ? t('inputs.grossAmount.label')
          : t('inputs.totalAmount.label'),
      });
    }

    if (amount === 0) {
      return t('inputs.grossAmount.nonZeroError');
    }

    return null;
  };

  const amountError = getAmountError();
  const hasAmountError = !!amountError;

  const isAmountReadyOnly =
    readOnly || !isFieldEditable(SplitBookingFields.grossAmount, 0);

  const showRoundingAmountWarning =
    !readOnly &&
    shouldUseSapNetAmount &&
    !!roundingAmount &&
    Math.abs(roundingAmount) > 1;

  return (
    <Grid gap="space8">
      <Grid
        whiteSpace="nowrap"
        templateColumns={
          readOnly && !shouldUseSapNetAmount
            ? '1fr minmax(min-content, 25%)'
            : 'minmax(80px, 1fr) minmax(59px, 80px) minmax(79px, 109px)'
        }
        gap="space8"
      >
        <FieldContainer
          readOnly={isAmountReadyOnly}
          wrapperBoxProps={isAmountReadyOnly ? { paddingTop: '0' } : {}}
          label={
            shouldUseSapNetAmount
              ? t('inputs.grossAmount.label')
              : t('inputs.totalAmount.label')
          }
          variant={hasAmountError ? 'error' : 'default'}
        >
          <NumberInput
            placeholder={t('inputs.grossAmount.placeholder.enabled')}
            formatOptions={formatOptions}
            value={amount}
            locale={locale}
            message={amountError}
            variant={hasAmountError ? 'error' : 'default'}
            readOnly={isAmountReadyOnly}
            onChange={newValue => {
              onChange({
                amount: newValue,
                currency,
              });
            }}
            showMessageOnFocus={hasAmountError}
            data-cy="grossAmount"
          />
        </FieldContainer>
        <FieldContainer
          label={t('inputs.currency.label')}
          readOnly={isAmountReadyOnly}
          wrapperBoxProps={isAmountReadyOnly ? { paddingTop: '0' } : {}}
        >
          <ComboBox
            placeholder={t('inputs.currency.placeholder.enabled')}
            readOnly={
              readOnly || !isFieldEditable(SplitBookingFields.currency, 0)
            }
            label={t('inputs.currency.label')}
            defaultItems={currencyItems}
            items={currencyItems}
            isVirtualized
            inputValue={currencySearch}
            clearable={false}
            isRequired
            selectedKey={currency || 'EUR'}
            onInputChange={async (newInputValue: string) => {
              setCurrencySearch(newInputValue);
            }}
            onSelectionChange={newValue => {
              setCurrencySearch(newValue as string);
              onChange({
                amount,
                currency: newValue as string,
              });
            }}
          >
            {currencyItems.map(({ key, children }) => {
              return (
                <Item key={key} textValue={children}>
                  {children}
                </Item>
              );
            })}
          </ComboBox>
        </FieldContainer>
        {!readOnly && !shouldUseSapNetAmount && (
          <FieldContainer
            label={t('inputs.remainingAmount.label')}
            variant={
              showSubmitErrors && remainingAmountErrorMessage
                ? 'error'
                : 'default'
            }
            readOnly
            wrapperBoxProps={{ paddingTop: '0' }}
          >
            <NumberInput
              readOnly
              label={t('inputs.remainingAmount.label')}
              formatOptions={formatOptions}
              value={remainingAmount}
              locale={locale}
              message={showSubmitErrors && remainingAmountErrorMessage}
              variant={
                showSubmitErrors && remainingAmountErrorMessage
                  ? 'error'
                  : 'default'
              }
            />
          </FieldContainer>
        )}
        {shouldUseSapNetAmount && (
          <RoundingAmount
            label={t('inputs.roundingAmount.label')}
            locale={locale}
            formatOptions={formatOptions}
          />
        )}
      </Grid>
      {showRoundingAmountWarning ? (
        <MessageBox variant="warning" message={t('warnings.roundingAmount')} />
      ) : null}
      {remainingAmountErrorMessage ? (
        <RemainingAmountError errorMessage={remainingAmountErrorMessage} />
      ) : null}
    </Grid>
  );
};
