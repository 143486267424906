import { Button, Card, Grid, Text, useTheme } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom-v5-compat';
import { generateExportUrlPath } from '../utils/generateExportUrlPath';

export const PartiallyExportedFooterActionBox = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const organizationSlug = useOrganizationId() ?? '';
  const { search } = useLocation();

  const { colors } = useTheme();

  return (
    <Card corners="top" boxShadow="elevatedShadow3" padding="space20">
      <Grid gap="space8">
        <Text color="gray500" fontWeight="semibold" textTransform="uppercase">
          {t('reimbursementView.rightSection.footerActions.title')}
        </Text>
        <Button
          as="a"
          variant="tertiary"
          width="100%"
          icon="newTab"
          iconPosition="right"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: colors.gray800 }}
          href={generateExportUrlPath(search, organizationSlug)}
        >
          {t(
            'reimbursementView.rightSection.partiallyExportedFooterAction.cta'
          )}
        </Button>
      </Grid>
    </Card>
  );
};
