import { Flex, Box } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { QuickSaveButtons } from 'views/Settings/components/QuickSaveButtons/QuickSaveButtons';
import { useUpdateSapB1Settings } from '../hooks/useUpdateSapB1Settings';
import { creditCardGLASchema, errorMessages } from './schema';

type CreditCardGLAData = {
  creditCardGLA: string;
};

export const CreditCardGLAForm = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isLoading, onUpdateSapB1Settings, sapB1Settings } =
    useUpdateSapB1Settings();

  const form = useForm({
    defaultValues: {
      creditCardGLA:
        sapB1Settings.sapB1Settings?.creditCardGeneralLedgerAccount ?? '',
    },
    resolver: zodResolver({
      zodSchema: creditCardGLASchema,
      errorMessages: errorMessages,
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
    }),
    mode: 'onSubmit',
  });

  const handleSubmit = async ({ creditCardGLA: value }: CreditCardGLAData) => {
    const creditCardGLA = !value ? '' : value;

    const result = await onUpdateSapB1Settings({
      creditCardGLA,
    });

    if (result?.data) {
      form.resetField('creditCardGLA', {
        defaultValue: creditCardGLA,
      });
    }
  };

  const isFormDirty = form.formState.isDirty;

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        onSubmit={form.handleSubmit(handleSubmit)}
        gap="space16"
        alignItems="end"
      >
        <HookFormTextField
          label={
            <Box width="90%">
              {t(
                'creditCardExportingSap.creditCardGeneralLedgerAccount.textInput.ariaLabel'
              )}
            </Box>
          }
          name="creditCardGLA"
          placeholder={t(
            'creditCardExportingSap.creditCardGeneralLedgerAccount.textInput.placeholder'
          )}
          clearable={false}
        />
        <Box paddingTop="space16">
          <QuickSaveButtons
            isFormDirty={isFormDirty}
            isLoading={isLoading}
            resetField={() => form.resetField('creditCardGLA')}
          />
        </Box>
      </Flex>
    </FormProvider>
  );
};
