import { Flex, Text } from '@candisio/design-system';
import { HookFormNumberField } from 'components/HookFormFields/HookFormNumberField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useWatchFormValues } from '../hooks/useWatchFormValues';
import { WarningBox } from '../WarningBox';
import { getQuarters } from './QuarterOption.helper';

export const QuarterOption = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const { hasCompleteWidgetDefinition } = useWatchFormValues();

  if (!hasCompleteWidgetDefinition) {
    return <WarningBox />;
  }

  const quarters = getQuarters();

  return (
    <>
      {quarters.map((quarter, index) => (
        <Flex direction="column" gap="space8" key={index}>
          <Text fontWeight={600}>{quarter.year}</Text>
          <Flex direction="row" gap="space4">
            {quarter.fields.map(itm => (
              <HookFormNumberField
                key={itm.key}
                label={t(itm.label)}
                placeholder={t(itm.placeholder)}
                name={itm.key}
                formatOptions={{
                  currency: 'EUR',
                  style: 'currency',
                  useGrouping: false,
                }}
              />
            ))}
          </Flex>
        </Flex>
      ))}
    </>
  );
};
