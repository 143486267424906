import { CSSObject } from '@emotion/react';
import React, { useMemo } from 'react';

import { VirtuosoContext } from '../../Molecules/Table';
import { useTheme } from '../../Theme';
import { StandardHTMLAttributes } from '../../types';
import { Box } from '../Box';

type TextAlign = 'left' | 'center' | 'right';

export interface TableHeadProps
  extends StandardHTMLAttributes<HTMLTableSectionElement>,
    VirtuosoContext {
  textAlign?: TextAlign;
}

/**
 * Wraps all components rendered into head of Table.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-data-table}
 *
 * @param {TextAlign} textAlign Set the horizontal alignment of text
 */
export const TableHead = React.forwardRef<
  HTMLTableSectionElement,
  TableHeadProps
>(({ children, textAlign = 'left', context, ...restProps }, ref) => {
  const isResizable = context?.isResizable;
  const { colors, space, table } = useTheme();

  const baseStyle: CSSObject = useMemo(() => {
    return {
      display: 'contents',
      th: {
        textAlign,
        fontWeight: table.tableHead.th.fontWeight,
        color: table.tableHead.th.color,
        padding: `${space.space4} ${space.space10}`,
        userSelect: 'none',
        background: table.tableHead.th.background,
        position: 'relative',
        ':after': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '1px',
          backgroundColor: colors.gray200,
          bottom: '0',
          left: '0',
        },
      },

      tr: {
        position: 'sticky',
        top: 0,
        zIndex: 999,
        background: table.tableHead.tr.background,
      },
    };
  }, [
    colors.gray200,
    space.space10,
    space.space4,
    table.tableHead.th.background,
    table.tableHead.th.color,
    table.tableHead.th.fontWeight,
    table.tableHead.tr.background,
    textAlign,
  ]);

  const resizableStyle: CSSObject = useMemo(() => {
    return {
      display: 'contents',
      th: {
        textAlign,
        fontWeight: table.tableHead.th.fontWeight,
        color: table.tableHead.th.color,
        padding: `${space.space4} ${space.space10}`,
        userSelect: 'none',
        background: table.tableHead.th.background,
        display: isResizable ? 'inline-block' : undefined,
        boxSizing: isResizable ? 'border-box' : undefined,
        position: 'relative',
      },

      tr: {
        position: 'sticky',
        minWidth: '100%',
        top: 0,
        zIndex: 999,
        background: table.tableHead.tr.background,
        ':after': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '1px',
          backgroundColor: colors.gray200,
          bottom: '0',
          left: '0',
        },
      },
    };
  }, [
    colors.gray200,
    isResizable,
    space.space10,
    space.space4,
    table.tableHead.th.background,
    table.tableHead.th.color,
    table.tableHead.th.fontWeight,
    table.tableHead.tr.background,
    textAlign,
  ]);

  return (
    <Box
      as="thead"
      css={isResizable ? resizableStyle : baseStyle}
      ref={ref}
      {...restProps}
    >
      {children}
    </Box>
  );
});
