import { Grid, Text } from '@candisio/design-system';
import { ExpenseItemBooking } from 'generated-types/graphql.types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { getTranslationContext } from 'orgConfig';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { amountFormatNonNull } from 'utils/format';

const amountFields = new Set(['amount', 'netAmount', 'taxAmount']);

type ExpenseItemBookingKeyNames = keyof Omit<ExpenseItemBooking, '__typename'>;

export type BookingValue =
  | string
  | number
  | null
  | { readableName: string }
  | undefined;

const artistSocialInsuranceCodeTranslationMapping: Record<string, string> = {
  BK: 'timeline.artistSocialInsuranceCode.codes.BK',
  DK: 'timeline.artistSocialInsuranceCode.codes.DK',
  M: 'timeline.artistSocialInsuranceCode.codes.M',
  W: 'timeline.artistSocialInsuranceCode.codes.W',
};
const translationsMapping: Record<ExpenseItemBookingKeyNames, string> = {
  amount: 'timeline.splitItems.amount',
  artistSocialInsuranceCode: 'timeline.splitItems.artistSocialInsuranceCode',
  bookingKey: 'timeline.splitItems.bookingKey',
  costCenter: 'timeline.splitItems.costCenter',
  costObject: 'timeline.splitItems.costObject',
  extraCostInfo: 'timeline.splitItems.extraCostInfo',
  generalLedgerAccount: 'timeline.splitItems.generalLedgerAccount',
  netAmount: 'timeline.splitItems.netAmount',
  note: 'timeline.splitItems.note',
  postingText: 'timeline.splitItems.postingText',
  taxAmount: 'timeline.splitItems.taxAmount',
  vatRate: 'timeline.splitItems.vatRate',
};

interface BookingFieldProps {
  fieldKey: string;
  value: BookingValue;
  currency: string | undefined;
}

export const BookingField = ({
  fieldKey,
  value,
  currency,
}: BookingFieldProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { friendlyFormatGLA, normalizeGLA } = useAccountingNumberFormatters();

  if (value && typeof value === 'object' && 'readableName' in value) {
    const readableValue =
      fieldKey === 'generalLedgerAccount'
        ? normalizeGLA(value.readableName)
        : value.readableName;

    const valueToDisplay = Array.isArray(readableValue)
      ? `${friendlyFormatGLA(readableValue[0])} - ${readableValue[1]} `
      : readableValue;

    return (
      <Grid>
        <Text
          color="gray500"
          lineHeight="paragraph"
          fontWeight="regular"
          fontSize="small"
        >
          {t(translationsMapping[fieldKey as ExpenseItemBookingKeyNames], {
            context: getTranslationContext(),
          })}
        </Text>
        <Text color="gray800" fontSize="small" lineHeight="paragraph">
          {valueToDisplay}
        </Text>
      </Grid>
    );
  }

  let displayValue = value;

  if (!value) {
    displayValue = t('timeline.empty');
  } else if (amountFields.has(fieldKey)) {
    displayValue = amountFormatNonNull(Number(value), currency ?? 'EUR', {
      convertToBasicMonetaryUnit: true,
    });
  } else if (fieldKey === 'vatRate') {
    displayValue = `${value}%`;
  } else if (fieldKey === 'artistSocialInsuranceCode') {
    displayValue = value
      ? t(artistSocialInsuranceCodeTranslationMapping[value])
      : '';
  }

  return (
    <Grid>
      <Text
        color="gray500"
        lineHeight="paragraph"
        fontWeight="regular"
        fontSize="small"
      >
        {t(translationsMapping[fieldKey as ExpenseItemBookingKeyNames])}
      </Text>
      <Text color="gray800" fontSize="small" lineHeight="paragraph">
        {displayValue}
      </Text>
    </Grid>
  );
};
