import { Box, Button, Flex, Grid, ScrollBox } from '@candisio/design-system';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface DrawerLayoutProps {
  /** Optional sticky footer */
  footer?: ReactNode;
  /** Optional fixed header */
  header?: ReactNode;
  /** Called when the drawer should close */
  onClose?: () => void;
  children: ReactNode;
  /** Whether the layout should have minimal paddings (useful when the drawer width is limited) */
  compact?: boolean;
  /** Whether the layout should have tabs */
  hasTabs?: boolean;
  /** An id that should be applied to the wrapping Grid */
  id?: string;
}

/**
 * Common layout for drawers
 *
 * Includes fixed header, close button and sticky footer. Drawer content passed
 *  as `children` will be scrollable.
 */
export const DrawerLayout = ({
  children,
  footer,
  header,
  onClose,
  compact = false,
  hasTabs = false,
  id,
}: DrawerLayoutProps) => {
  const [t] = useTranslation();

  const footerBackgroundGradient =
    'linear-gradient(rgba(255, 255, 255, 0.01) 0%, rgb(255, 255, 255) 20%)';

  return (
    <Grid
      templateRows="auto 1fr"
      height="100%"
      id={id}
      borderTop="1px solid gray250"
    >
      <Flex
        gap="space16"
        padding={compact ? 'space16' : 'space16 space40 space8'}
        paddingBottom={hasTabs ? '0' : 'space16'}
        alignItems="center"
        justifyContent="end"
        borderBottom="1px solid gray300"
      >
        {header !== undefined && <Box flex="1">{header}</Box>}
        {onClose ? (
          <Button
            flex="none"
            icon="close"
            label={t('common.close')}
            onClick={onClose}
            variant="tertiary"
          />
        ) : null}
      </Flex>
      <ScrollBox>
        <Grid alignContent="space-between" height="100%">
          <Box padding={compact ? 'space16' : 'space16 space32'}>
            {children}
          </Box>
          {footer !== undefined && (
            <Box
              padding={compact ? 'space16' : 'space32 space32 space16 space32'}
              style={{
                background: footerBackgroundGradient,
              }}
              position="sticky"
              bottom={0}
              zIndex={1}
            >
              {footer}
            </Box>
          )}
        </Grid>
      </ScrollBox>
    </Grid>
  );
};
