import gql from 'graphql-tag';
import {
  approvedEventData,
  contactSupplierEventData,
  approverExtractedEventData,
  attachmentConvertedToDocumentEventData,
  attachmentDeletedFromDocumentEventData,
  attachmentDetachedFromDocumentEventData,
  contactExtractionEventData,
  costCenterExtractionEventData,
  documentCommentEventData,
  documentConvertedToAttachmentEventData,
  documentFileReplacedEventData,
  documentPaymentInfoUpdatedEventData,
  documentResetByCandisEventData,
  documentResetManualEventData,
  documentResetUserDeactivationEventData,
  documentResetUserRoleChangedEventData,
  documentTransactionCommentCreatedEventData,
  documentTransactionLinkedEventData,
  documentTransactionStatusUpdatedEventData,
  documentTransactionReconciledEventData,
  documentTransactionUnlinkedEventData,
  exportedEventData,
  fileAttachedToDocumentEventData,
  fileUploadedByEmailEventData,
  metaDataExtractionEventData,
  provisionExportedEventData,
  reversalExportedEventData,
  rejectedEventData,
  requestApprovalEventData,
  sepaTransferGeneratedEventData,
  singleBookingUpdatedEventData,
  splitBookingUpdatedEventData,
  userApprovalDocumentWorkflowStepData,
  workflowStepSkippedEventData,
  workflowTemplateAppliedToDocumentEventData,
  documentResetTransactionAmountUpdatedEventData,
  documentResetTransactionLinkedEventData,
  documentResetTransactionUnLinkedEventData,
  documentImportedByMigrationEventData,
  DocumentRelationshipCreatedEventData,
  DocumentRelationshipRemovedEventData,
  documentTagUpdatedEventData,
  DocumentUpdatedAsEcmDocumentEventData,
  DocumentStoredAsEcmDocumentEventData,
  DocumentConvertedEventData,
} from 'views/queries';
import { tagFragment } from 'views/Settings/DocumentTags/toolkit/gql';

export const fileUploadedEventData = gql`
  fragment FileUploadedEventData on FileUploadedEvent {
    user {
      id
      avatarUrl
      name
    }
    file {
      name
      url
    }
    clientInfo {
      name
    }
    # outdated
    uploaderEmail
  }
`;

export const EcmDocumentFileFragment = gql`
  fragment EcmDocumentFile on AggregatedEcmDocument {
    fileSource {
      name
      size
      url
    }
  }
`;

export const ecmDocumentQuery = gql`
  query ecmDocument($id: String!) {
    getAggregatedEcmDocument(id: $id) {
      id
      documentType
      createdAt
      createdBy {
        id
        name
        avatarUrl
      }
      updatedAt
      updatedBy {
        id
        name
        avatarUrl
      }
      contactId
      contactName
      invoiceId
      notes
      documentNumber
      documentDate
      documentStatus
      isSensitive
      tags {
        ...Tag
      }
    }
  }
  ${tagFragment}
`;

export const ecmDocumentFileQuery = gql`
  query ecmDocumentFile($id: String!) {
    getAggregatedEcmDocument(id: $id) {
      ...EcmDocumentFile
    }
  }
`;

/**
 * The fragments are defined separately to generate separate types for each one
 */
export const ecmDocumentHistoryQuery = gql`
  query ecmDocumentHistory($id: String!) {
    getAggregatedEcmDocument(id: $id) {
      id
      timeline {
        __typename
        id
        createdAt
        ...ApprovedEventData
        ...ApproverExtractedEventData
        ...AttachmentConvertedToDocumentEventData
        ...AttachmentDeletedFromDocumentEventData
        ...AttachmentDetachedFromDocumentEventData
        ...ContactExtractionEventData
        ...CostCenterExtractionEventData
        ...DocumentCommentEventData
        ...DocumentConvertedToAttachmentEventData
        ...DocumentFileReplacedEventData
        ...DocumentPaymentInfoUpdatedEventData
        ...DocumentResetByCandisEventData
        ...DocumentResetManualEventData
        ...DocumentResetTransactionAmountUpdatedEventData
        ...DocumentResetTransactionLinkedEventData
        ...DocumentResetTransactionUnLinkedEventData
        ...DocumentResetUserDeactivationEventData
        ...DocumentResetUserRoleChangedEventData
        ...DocumentTransactionCommentCreatedEventData
        ...DocumentTransactionLinkedEventData
        ...DocumentTransactionStatusUpdatedEventData
        ...DocumentTransactionReconciledEventData
        ...DocumentTransactionUnlinkedEventData
        ...ExportedEventData
        ...FileAttachedToDocumentEventData
        ...FileUploadedByEmailEventData
        ...FileUploadedEventData
        ...MetaDataExtractionEventData
        ...ProvisionExportedEventData
        ...ReversalExportedEventData
        ...RejectedEventData
        ...RequestApprovalEventData
        ...SepaTransferGeneratedEventData
        ...SingleBookingUpdatedEventData
        ...SplitBookingUpdatedEventData
        ...UserApprovalDocumentWorkflowStepData
        ...WorkflowStepSkippedEventData
        ...WorkflowTemplateAppliedToDocumentEventData
        ...ContactSupplierSendEmailEventData
        ...DocumentStoredAsEcmDocumentEventData
        ...DocumentUpdatedAsEcmDocumentEventData
        ...DocumentImportedByMigrationEventData
        ...DocumentRelationshipCreatedEventData
        ...DocumentRelationshipRemovedEventData
        ...DocumentTagUpdatedEventData
        ...DocumentConvertedEventData
      }
    }
  }
  ${approvedEventData}
  ${contactSupplierEventData}
  ${approverExtractedEventData}
  ${attachmentConvertedToDocumentEventData}
  ${attachmentDeletedFromDocumentEventData}
  ${attachmentDetachedFromDocumentEventData}
  ${contactExtractionEventData}
  ${costCenterExtractionEventData}
  ${documentCommentEventData}
  ${documentConvertedToAttachmentEventData}
  ${documentFileReplacedEventData}
  ${documentPaymentInfoUpdatedEventData}
  ${documentResetByCandisEventData}
  ${documentResetManualEventData}
  ${documentResetTransactionAmountUpdatedEventData}
  ${documentResetTransactionLinkedEventData}
  ${documentResetTransactionUnLinkedEventData}
  ${documentResetUserDeactivationEventData}
  ${documentResetUserRoleChangedEventData}
  ${documentTransactionCommentCreatedEventData}
  ${documentTransactionLinkedEventData}
  ${documentTransactionStatusUpdatedEventData}
  ${documentTransactionReconciledEventData}
  ${documentTransactionUnlinkedEventData}
  ${exportedEventData}
  ${fileAttachedToDocumentEventData}
  ${fileUploadedByEmailEventData}
  ${fileUploadedEventData}
  ${metaDataExtractionEventData}
  ${provisionExportedEventData}
  ${reversalExportedEventData}
  ${rejectedEventData}
  ${requestApprovalEventData}
  ${sepaTransferGeneratedEventData}
  ${singleBookingUpdatedEventData}
  ${splitBookingUpdatedEventData}
  ${userApprovalDocumentWorkflowStepData}
  ${workflowStepSkippedEventData}
  ${workflowTemplateAppliedToDocumentEventData}
  ${DocumentStoredAsEcmDocumentEventData}
  ${DocumentUpdatedAsEcmDocumentEventData}
  ${documentImportedByMigrationEventData}
  ${DocumentRelationshipCreatedEventData}
  ${DocumentRelationshipRemovedEventData}
  ${documentTagUpdatedEventData}
  ${DocumentConvertedEventData}
`;
