import { CSSObject } from '@emotion/react';
import React, { useRef } from 'react';
import {
  DismissButton,
  FocusScope,
  mergeProps,
  OverlayContainer,
  PopoverAria,
  useDialog,
  useOverlay,
} from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { useTheme } from '../../Theme';
import {
  ColorProps,
  LayoutProps,
  PaddingProps,
  StandardHTMLAttributes,
} from '../../types';
import { useArrowCss } from '../../utils/useArrowCss';
import { Box } from '../Box';

export interface PopoverProps
  extends LayoutProps,
    ColorProps,
    PaddingProps,
    StandardHTMLAttributes<HTMLDivElement> {
  /** Custom CSS for arrow */
  arrowStyle?: React.CSSProperties;
  /** (Controlled) open state */
  isOpen?: boolean;
  /** Called when popover should close */
  onClose?: () => void;
  /** Position */
  placementAxis?: PopoverAria['placement'];
  /**
   * Set to false if popover should not disappear after clicking outside of it
   */
  dismissWithOutsideClick?: boolean;
  /**
   * Whether the overlay should close when focus is lost or moves outside it.
   */
  shouldCloseOnBlur?: boolean;
  /**
   *  Whether to auto focus the first focusable element in the popover on open.
   */
  autoFocus?: boolean;
  /**
   * Whether to restore focus back to the element that was focused when the
   * popover opened after the popover closes.
   */
  restoreFocus?: boolean;
}

/**
 * Popover is a non-modal dialog that floats around a trigger element.
 *
 * Use together with `usePopover` hook.
 *
 * @see [Storybook](https://candisio.github.io/design-system/?path=/docs/atoms-overlay-popover)
 */
export const Popover = React.forwardRef<HTMLDivElement, PopoverProps>(
  (
    {
      arrowStyle,
      autoFocus = true,
      background = 'gray0',
      children,
      color,
      dismissWithOutsideClick = true,
      isOpen = false,
      onClose,
      padding,
      paddingX = 'space16',
      paddingY = 'space12',
      placementAxis,
      restoreFocus = true,
      shouldCloseOnBlur = false,
      ...restProps
    },
    forwardedRef
  ) => {
    const ref = useRef(null);

    const { shadows } = useTheme();

    // Popover is a type of dialog
    const { dialogProps } = useDialog({}, ref) as {
      dialogProps: StandardHTMLAttributes<HTMLElement>;
    };

    // Handle interacting outside the dialog and pressing the Escape key to close
    // the popover
    const { overlayProps } = useOverlay(
      {
        onClose,
        isOpen,
        isDismissable: dismissWithOutsideClick,
        shouldCloseOnBlur,
      },
      ref
    ) as {
      overlayProps: StandardHTMLAttributes<HTMLElement>;
    };

    const baseCss: CSSObject = {
      filter: `drop-shadow(${shadows.elevatedShadow5})`,
    };

    const arrowCss = useArrowCss({
      background,
      placementAxis,
      arrowStyle,
    });

    return (
      <OverlayContainer>
        <FocusScope autoFocus={autoFocus} restoreFocus={restoreFocus}>
          <Box
            css={[baseCss, arrowCss]}
            background={background}
            borderRadius="medium"
            color={color}
            fontSize="basic"
            // Overwrite default paddingX and Y when padding was set
            paddingX={padding ?? paddingX}
            paddingY={padding ?? paddingY}
            {...mergeProps(overlayProps, dialogProps, restProps)}
            ref={mergeRefs([ref, forwardedRef])}
          >
            {children}
            <DismissButton onDismiss={onClose} />
          </Box>
        </FocusScope>
      </OverlayContainer>
    );
  }
);
