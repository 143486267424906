import { UnionToIntersection } from 'utility-types';
import {
  ZodInvalidTypeIssue,
  ZodIssueOptionalMessage,
  ZodTooBigIssue,
  ZodTooSmallIssue,
} from 'zod';

/**
 * The helpers here can be used when you need to perform a custom validation for a
 * field (with `.superRefine`), but do not need a custom translation for the error,
 * and just need to use one of the default error message types.
 *
 * ```
 * const schema = z.object({
 *   bookings: z.array(
 *     z
 *       .object({
 *         taxPresentation: z.enum(['Net', 'Gross']),
 *         // vatRate is optional
 *         vatRate: z.number().min(0).max(99.99).nullable(),
 *       })
 *       .superRefine(({ taxPresentation, vatRate }, ctx) => {
 *         if (taxPresentation === 'Net' && vatRate === null) {
 *           // In the custom validation vatRate is required
 *           ctx.addIssue(requiredNumberIssue({ path: ['vatRate'] }));
 *         }
 *       })
 *   ),
 * });
 * ```
 */

export const requiredNumberIssue: CreateIssueDetails<
  ZodInvalidTypeIssue,
  'expected' | 'received',
  true
> = ({ path = [] } = {}) => ({
  code: 'invalid_type',
  expected: 'number',
  received: 'null',
  path,
});

export const requiredStringIssue: CreateIssueDetails<
  ZodInvalidTypeIssue,
  'expected' | 'received',
  true
> = ({ path = [] } = {}) => ({
  code: 'invalid_type',
  expected: 'string',
  received: 'null',
  path,
});

export const tooBigIssue: CreateIssueDetails<ZodTooBigIssue> = ({
  inclusive,
  maximum,
  path = [],
  type,
}) => ({
  code: 'too_big',
  inclusive,
  // @ts-expect-error We allow strings as well so we can format things. 50.5 -> 50.50 EUR
  maximum,
  path,
  type,
});

export const tooSmallIssue: CreateIssueDetails<ZodTooSmallIssue> = ({
  inclusive,
  minimum,
  path = [],
  type,
}) => ({
  code: 'too_small',
  inclusive,
  // @ts-expect-error We allow strings as well so we can format things. 50.5 -> 50.50 EUR
  minimum,
  path,
  type,
});

type CreateIssueDetails<
  TIssue extends ZodIssueOptionalMessage,
  TKeys extends keyof TIssue | undefined = undefined,
  TOptionalInput extends boolean = false,
> = TOptionalInput extends true
  ? (input?: IssueDetailsInput<TIssue, TKeys>) => TIssue
  : (input: IssueDetailsInput<TIssue, TKeys>) => TIssue;

type IssueDetailsInput<
  TIssue extends ZodIssueOptionalMessage,
  /**
   * `TOmitKeys` are properties on the issue that cannot be modified by the caller
   */
  TOmitKeys extends keyof TIssue | undefined = undefined,
> = UnionToIntersection<
  | {
      /**
       * Path is accumulative, the path supplied here is added to the `path` already
       * on the issue context.
       *
       * ```
       * const schema = z.object({
       *   bookings: z.array(
       *     z
       *       .object({
       *         taxPresentation: z.enum(['Net', 'Gross']),
       *         vatRate: z.number().min(0).max(99.99).nullable(),
       *       })
       *       .superRefine(({ taxPresentation, vatRate }, ctx) => {
       *         // ctx.path is already ['bookings', 0]
       *         if (taxPresentation === 'Net' && vatRate === null) {
       *           // path of the created issue is now
       *           // ['bookings', 0, 'vatRate']
       *           ctx.addIssue(requiredNumberIssue({ path: ['vatRate'] }));
       *         }
       *       })
       *   ),
       * });
       * ```
       */
      path?: (string | number)[];
    }
  | (TIssue extends ZodTooSmallIssue
      ? ExcludePropsFromIssue<TIssue, TOmitKeys | 'minimum'> & {
          minimum?: string | number;
        }
      : TIssue extends ZodTooBigIssue
        ? ExcludePropsFromIssue<TIssue, TOmitKeys | 'maximum'> & {
            maximum?: string | number;
          }
        : ExcludePropsFromIssue<TIssue, TOmitKeys> extends {}
          ? never
          : ExcludePropsFromIssue<TIssue, TOmitKeys>)
>;

type ExcludePropsFromIssue<
  TIssue extends ZodIssueOptionalMessage,
  TOmitKeys extends keyof TIssue | undefined = undefined,
> = Omit<
  TIssue,
  'path' | 'code' | 'message' | (TOmitKeys extends undefined ? '' : TOmitKeys)
>;
