import { Box, Flex, Image, Text } from '@candisio/design-system';
import { CardType } from 'generated-types/graphql.types';
import i18next from 'i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import pliantGray from '../../media/logo-pliant-black.svg';
import pliantWhite from '../../media/logo-pliant-white.svg';
import wireless from '../../media/wireless.svg';

const variant = (t: i18next.TFunction) => ({
  BLACK: (
    <Flex justifyContent="space-between" alignItems="start" height="56px">
      <Image alt="pliant logo" src={pliantWhite} />
      <Flex gap="space16">
        <Box
          height="space40"
          width="space32"
          background="gray400"
          borderRadius="medium"
        />

        <Image alt="{alt}" src={wireless} />
      </Flex>
    </Flex>
  ),
  PHYSICAL: (
    <Flex justifyContent="space-between" alignItems="start" height="56px">
      <Image alt="pliant logo" src={pliantWhite} />
      <Flex gap="space16">
        <Box
          height="space40"
          width="space32"
          background="gray400"
          borderRadius="medium"
        />

        <Image alt="{alt}" src={wireless} />
      </Flex>
    </Flex>
  ),
  SINGLE_USE: (
    <Flex justifyContent="space-between" alignItems="start">
      <Image alt="pliant logo" src={pliantGray} />
      <Flex direction="column" fontWeight="semibold" alignItems="center">
        <Text color="gray800" fontSize="space10" lineHeight="14px">
          {t('dashboard.drawer.card.virtual').toUpperCase()}
        </Text>
        <Text fontSize="33px" color="gray800">
          X1
        </Text>
      </Flex>
    </Flex>
  ),
  VIRTUAL: (
    <Flex justifyContent="space-between" alignItems="start">
      <Image alt="pliant logo" src={pliantGray} />
      <Text
        fontSize="space10"
        fontWeight="semibold"
        color="gray800"
        lineHeight="14px"
      >
        {t('dashboard.drawer.card.virtual').toUpperCase()}
      </Text>
    </Flex>
  ),
});

export const Header = ({ cardType }: { cardType: CardType }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const headerVariant = variant(t);

  return headerVariant[cardType];
};
