import { Card, Flex, Skeleton } from '@candisio/design-system';

export const CreditCardLoading = () => {
  return (
    <Card
      position="relative"
      background="gray800"
      borderRadius="medium"
      width="180px"
      height="280px"
      padding="space16"
      boxShadow="null"
      color="white"
    >
      <Flex justifySelf="end" paddingTop="space16">
        <Skeleton width="4rem" height="3rem" justifySelf="end" />
      </Flex>
      <Flex direction="column" style={{ marginTop: '3rem' }} gap="0.5rem">
        <Skeleton width="5rem" height="1rem" />
        <Skeleton width="10rem" height="1rem" />
        <Flex justifyContent="space-between">
          <Skeleton width="4rem" height="1rem" />
          <Skeleton width="4rem" height="1rem" />
        </Flex>
      </Flex>
      <Skeleton
        width="4rem"
        height="2rem"
        position="absolute"
        bottom="space8"
        right="space8"
      />
    </Card>
  );
};
