import { useEffect } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps } from 'react-router-dom';
import { AuthService } from 'services/AuthService';

type ChangeEmailRequestProps = {} & RouteComponentProps<{ requestId: string }>;

export const ChangeEmailRequest = ({
  match: {
    params: { requestId },
  },
}: ChangeEmailRequestProps) => {
  useEffect(() => {
    const handleError = (e: any) => {
      console.error(e);
      window.location.assign('/');
    };

    try {
      const apiBaseUrl = import.meta.env.REACT_APP_GRAPHQL_ENDPOINT?.replace(
        '/graphql',
        ''
      );

      fetch(`${apiBaseUrl}/iam/change-email-request/${requestId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      })
        .then(response => response.json())
        .then(AuthService.forceLogout)
        .catch(handleError);
    } catch (e) {
      handleError(e);
    }
  }, [requestId]);

  return null;
};
