import { CreationModalMultiStep } from 'components/CreationModalMultiStep/CreationModalMultiStep';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { Team } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';
import { creditCardSetupFormErrorMessages } from 'views/Settings/Teams/toolkit/utils/creditCardSetupFormErrorMessages';
import {
  CreditCardSetupFormOutput,
  CreditCardSetupFormValues,
  creditCardSetupFormSchema,
} from 'views/Settings/Teams/toolkit/utils/creditCardSetupFormSchema';
import { CreditCardSetupDetailsCard } from '../../components/CreditCardSetupDetailsCard';
import { AssignCardsContainer } from './AssignCardsContainer';
import { CreditCardRules } from './CreditCardRules';

export enum Step {
  CardRules,
  CardAssignment,
}

interface TeamCreditCardSetupModalProps {
  isOpen: boolean;
  team: Team;
  isSubmitting: boolean;
  onClose: () => void;
  onSubmit: (data: CreditCardSetupFormOutput) => Promise<void>;
}

const defaultValues: CreditCardSetupFormValues = {
  limit: undefined,
  oneTimeCardLimit: undefined,
  cards: undefined,
  isACardAssignedToTeam: false,
  isTeamAdminAllowedToManageCards: true,
};

export const TeamCreditCardSetupModal = ({
  isOpen,
  team,
  onClose,
  isSubmitting,
  onSubmit,
}: TeamCreditCardSetupModalProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const [step, setStep] = useState<Step>(Step.CardRules);

  const form = useForm<CreditCardSetupFormOutput>({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver({
      zodSchema: creditCardSetupFormSchema,
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
      errorMessages: creditCardSetupFormErrorMessages,
    }),
  });

  const steps: string[] = t('teams.createTeam.creditCardSetup.steps', {
    returnObjects: true,
  });

  return (
    <FormProvider {...form}>
      <form>
        <CreationModalMultiStep
          onClose={onClose}
          isOpen={isOpen}
          currentStep={step}
          steps={steps}
          leftSide={<CreditCardSetupDetailsCard team={team} />}
        >
          {step === Step.CardRules && (
            <CreditCardRules
              teamAdmin={team.teamAdmin?.fullName}
              onClick={() => setStep(Step.CardAssignment)}
            />
          )}
          {step === Step.CardAssignment && (
            <AssignCardsContainer
              team={team}
              isSubmitting={isSubmitting}
              onSubmit={form.handleSubmit(onSubmit)}
              onGoBack={() => setStep(Step.CardRules)}
            />
          )}
        </CreationModalMultiStep>
      </form>
    </FormProvider>
  );
};
