import gql from 'graphql-tag';
import {
  basicApprovalDocumentConnectionFragment,
  basicDocumentConnectionFragment,
  basicInboxInvoiceDocumentConnectionFragment,
} from 'views/queries/fragments';
import { tagFragment } from 'views/Settings/DocumentTags/toolkit/gql';

export const getInboxDocumentNavigationQueries = gql`
  query inboxDocumentNavigationData($cursor: String, $query: String) {
    prevDocument: listInboxDocuments(limit: 1, before: $cursor, query: $query) {
      ...BasicDocumentConnectionData
    }

    nextDocument: listInboxDocuments(limit: 1, after: $cursor, query: $query) {
      ...BasicDocumentConnectionData
    }
  }
  ${basicDocumentConnectionFragment}
`;

export const getInboxInvoiceDocumentsNavigationQueries = gql`
  query inboxInvoiceDocumentsNavigationData($cursor: String, $query: String) {
    prevDocument: inboxInvoiceDocuments(
      limit: 1
      before: $cursor
      query: $query
    ) {
      ...BasicInboxInvoiceDocumentConnectionData
    }

    nextDocument: inboxInvoiceDocuments(
      limit: 1
      after: $cursor
      query: $query
    ) {
      ...BasicInboxInvoiceDocumentConnectionData
    }
  }
  ${basicInboxInvoiceDocumentConnectionFragment}
`;

export const getApprovalDocumentsNavigationQueries = gql`
  query approvalsDocumentNavigationData($cursor: String, $query: String) {
    prevDocument: listApprovalsDocuments(
      limit: 1
      before: $cursor
      query: $query
    ) {
      ...BasicDocumentConnectionData
    }

    nextDocument: listApprovalsDocuments(
      limit: 1
      after: $cursor
      query: $query
    ) {
      ...BasicDocumentConnectionData
    }
  }
  ${basicDocumentConnectionFragment}
`;

export const getApprovalInvoiceDocumentsNavigationQueries = gql`
  query approvalsDocumentNewEsNavigationData($cursor: String, $query: String) {
    prevDocument: approvalInvoiceDocuments(
      limit: 1
      before: $cursor
      query: $query
    ) {
      ...BasicApprovalDocumentConnectionData
    }

    nextDocument: approvalInvoiceDocuments(
      limit: 1
      after: $cursor
      query: $query
    ) {
      ...BasicApprovalDocumentConnectionData
    }
  }
  ${basicApprovalDocumentConnectionFragment}
`;

export const getArchiveDocumentsNavigationQueries = gql`
  query archiveDocumentNavigationData($cursor: String) {
    prevDocument: archivedDocuments(limit: 1, before: $cursor) {
      ...BasicDocumentConnectionData
    }

    nextDocument: archivedDocuments(limit: 1, after: $cursor) {
      ...BasicDocumentConnectionData
    }
  }
  ${basicDocumentConnectionFragment}
`;

export const getArchiveDocumentsNavigationWithSearchQueries = gql`
  query archiveDocumentsNavigationWithSearchNavigationData(
    $cursor: String
    $query: String!
  ) {
    prevDocument: searchDocuments(limit: 1, before: $cursor, query: $query) {
      ...BasicDocumentConnectionData
    }

    nextDocument: searchDocuments(limit: 1, after: $cursor, query: $query) {
      ...BasicDocumentConnectionData
    }
  }
  ${basicDocumentConnectionFragment}
`;

const ecmDocumentConnectionFragment = gql`
  fragment EcmDocumentConnectionData on GetEcmDocumentsResponse {
    edges {
      cursor
      node: record {
        id
        invoiceId
        documentType
        tags {
          ...Tag
        }
      }
    }
    pageInfo {
      totalCount
    }
  }
  ${tagFragment}
`;

export const getEcmDocumentsNavigationQueries = gql`
  query ecmDocumentNavigationData($cursor: String, $query: String, $filter: EcmFilterInput) {
    prevDocument: getEcmDocuments(limit: 1, previous: $cursor, search: $query, filter: $filter) {
      ...EcmDocumentConnectionData
    }

    nextDocument: getEcmDocuments(limit: 1, next: $cursor, search: $query, filter: $filter) {
      ...EcmDocumentConnectionData
    }
  }
  ${ecmDocumentConnectionFragment}
`;
