import { useToastMessage } from 'components/Toast/useToastMessage';
import { useUpdateHospitalityExpenseItemMutation } from 'generated-types/graphql.types';
import { debounce } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { ExpenseSchemaOutput } from '../toolkit/expensesFormSchema';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
  reimbursementItemsListQuery,
  reimbursementItemsSplitBookingsQuery,
} from '../toolkit/queries';
import { sanitizeHospitalityFormInput } from '../utils/sanitizeHospitalityFormInput';

const UPDATE_EXPENSE_DELAY = 1000;

export const useUpdateHospitalityExpense = ({
  shouldDebounce = true,
}: {
  shouldDebounce?: boolean;
} = {}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();

  const { error } = useToastMessage();

  const [
    updateHospitalityExpense,
    { loading: isUpdateHospitalityExpensePending },
  ] = useUpdateHospitalityExpenseItemMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: reimbursementCaseByIdQuery,
        variables: {
          id: reimbursementId,
        },
      },
      {
        query: reimbursementItemsListQuery,
        variables: { filters: { reimbursementCaseId: reimbursementId } },
      },
      {
        query: reimbursementItemsSplitBookingsQuery,
        variables: { filters: { reimbursementCaseId: reimbursementId } },
      },
      {
        query: reimbursementHistoryQuery,
        variables: { id: reimbursementId },
      },
    ],
  });

  const handleUpdateHospitalityExpense = useCallback(
    async (values: ExpenseSchemaOutput) => {
      if (!values.expenseId) return;

      const { errors } = await updateHospitalityExpense({
        variables: {
          id: values.expenseId,
          input: {
            reimbursementCaseId: reimbursementId,
            ...sanitizeHospitalityFormInput(values),
          },
        },
      });

      if (errors?.length) {
        error(t('reimbursementView.createErr'));

        return;
      }
    },
    [error, reimbursementId, t, updateHospitalityExpense]
  );

  const debouncedUpdate = useMemo(() => {
    return debounce(handleUpdateHospitalityExpense, UPDATE_EXPENSE_DELAY);
  }, [handleUpdateHospitalityExpense]);

  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  return {
    isUpdateHospitalityExpensePending,
    updateHospitalityExpense: shouldDebounce
      ? debouncedUpdate
      : handleUpdateHospitalityExpense,
  };
};
