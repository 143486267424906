import { useWatch } from 'react-hook-form';
import { WidgetFormData } from '../../types';

export const useWatchFormValues = () => {
  const [title, costCenters, costObjects, generalLedgerAccounts, contacts] =
    useWatch<
      WidgetFormData,
      [
        'title',
        'costCenters',
        'costObjects',
        'generalLedgerAccounts',
        'contacts',
      ]
    >({
      name: [
        'title',
        'costCenters',
        'costObjects',
        'generalLedgerAccounts',
        'contacts',
      ],
    });

  const hasBookingValue =
    !!costCenters?.length ||
    !!costObjects?.length ||
    !!generalLedgerAccounts?.length ||
    !!contacts?.length;

  const hasCompleteWidgetDefinition = hasBookingValue && title;

  return { hasCompleteWidgetDefinition };
};
