import { Box, Flex, Icon, TruncatedText, Grid } from '@candisio/design-system';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const MotionBox = motion(Box);

const transition = { duration: 0.4, ease: 'easeInOut' };

interface StepperItemProps {
  itemPosition: number;
  isItemPositionVisible: boolean;
  description: string;
  isCurrentStepActive: boolean;
  isStepCompleted: boolean;
  isLastStep: boolean;
}

export const StepperItem = ({
  itemPosition,
  isCurrentStepActive,
  description,
  isItemPositionVisible,
  isStepCompleted,
  isLastStep,
}: StepperItemProps) => {
  const [t] = useTranslation();

  return (
    <Flex
      as="li"
      key={itemPosition}
      gap="space8"
      paddingRight="space4"
      direction="column"
    >
      <Grid alignItems="center" gap="space4" templateColumns="auto 1fr">
        <Flex
          justifyContent="center"
          alignItems="center"
          height="space16"
          width="space16"
          background={
            isCurrentStepActive || isStepCompleted ? 'blue500' : 'gray300'
          }
          borderRadius="full"
        >
          {isStepCompleted && (
            <Icon icon="check" size="space10" color="white" />
          )}
        </Flex>

        {!isLastStep && (
          <Box
            height="space4"
            width="100%"
            background="gray300"
            borderRadius="medium"
          >
            <MotionBox
              transition={transition}
              initial={false}
              background={isStepCompleted ? 'blue500' : 'gray'}
              minWidth="0%"
              maxWidth="100%"
              height="100%"
              borderRadius="inherit"
              opacity="1"
              width={isStepCompleted ? '100%' : '0%'}
              animate={{
                width: isStepCompleted ? '100%' : '0%',
                opacity: 1,
              }}
            />
          </Box>
        )}
      </Grid>
      <Flex direction="column" justifyContent="start">
        {isItemPositionVisible && (
          <TruncatedText maxWidth="140px" fontSize="small" color="gray500">
            {t('stepper.stepperNumber', { count: itemPosition + 1 })}
          </TruncatedText>
        )}
        <TruncatedText maxWidth="140px">{description}</TruncatedText>
      </Flex>
    </Flex>
  );
};
