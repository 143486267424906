import { FieldPolicy, TypePolicies } from '@apollo/client';
import { ProjectCodePaginationResult } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const projectCodesPadinationFieldPolicy: FieldPolicy<ProjectCodePaginationResult> =
  {
    merge: simplePaginationMergeWithoutDuplicates(
      'ProjectCodePaginationResult'
    ),
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      projectCodePagination: projectCodesPadinationFieldPolicy,
    },
  },
};
