import { Button, Grid, Text, TruncatedText } from '@candisio/design-system';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNumberFormat } from 'utils/useNumberFormat';
import {
  useShowCostCenterField,
  useShowCostObjectField,
} from 'views/utils/useShouldShowField';
import { PurchaseOrderSplitItem } from '../../PurchaseOrders/PurchaseOrderSplitItem';
import { ProcessingFormValues } from './processingFormSchema';

const customStyle = 'auto 1fr 1fr 1.5fr';

export interface ProcessingFormSplitItemProps {
  /** Booking number */
  number: number;
  /** Field name */
  name: `bookings.${number}`;
  /** Called when split bookings form should be shown for this booking */
  onEdit?: () => void;
  /** Display item as read only */
  readOnly?: boolean;
  hasRemainingAmount: boolean;
  isGoodsPurchaseOrderLinked?: boolean;
}

/**
 * Displays value of the amount, VAT rate and cost center fields for a given
 * booking
 */
export const ProcessingFormSplitItem = ({
  number,
  name,
  onEdit,
  readOnly,
  hasRemainingAmount,
  isGoodsPurchaseOrderLinked,
}: ProcessingFormSplitItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);
  const { shouldUseSapNetAmount } = useSap();

  const {
    amount,
    vatRate,
    taxCode,
    costCenter,
    costObject,
    quantity,
    note,
    sapExpenseType,
  } = useWatch<ProcessingFormValues, typeof name>({ name });

  const showCostCenterField = useShowCostCenterField();
  const showCostObjectField = useShowCostObjectField();

  const isOrgUsingOnlyCostObjects = !showCostCenterField && showCostObjectField;

  const showVatRate =
    typeof vatRate === 'number' && (shouldUseSapNetAmount ? !!taxCode : true);

  const hasCostCenterInput = costCenter && costCenter.inputValue;
  const hasCostObjectInput =
    isOrgUsingOnlyCostObjects && costObject && costObject.inputValue;

  const fieldIndex = Number(name.split('.')[1]);

  const currency = useWatch<ProcessingFormValues, 'currency'>({
    name: 'currency',
  });

  const { getFieldState } = useFormContext();
  const { invalid } = getFieldState(name);

  const amountFormatter = useNumberFormat({
    style: currency ? 'currency' : 'decimal',
    currency: currency ?? undefined,
  });

  const percentFormatter = useNumberFormat({
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'percent',
  });

  const hasError = invalid || (!shouldUseSapNetAmount && hasRemainingAmount);

  return (
    <Grid
      gap="space8"
      templateColumns="1fr space24"
      alignItems="center"
      minHeight="space24"
      padding="space8 space12"
      background={hasError ? 'redbg' : undefined}
      cursor="pointer"
    >
      <Grid
        gap="space8"
        templateColumns={
          isGoodsPurchaseOrderLinked ? customStyle : '1fr 2fr 2fr 3fr'
        }
      >
        <TruncatedText color="gray400">{String(number)}</TruncatedText>

        {typeof amount === 'number' ? (
          <TruncatedText>{amountFormatter.format(amount)}</TruncatedText>
        ) : (
          <Text>–</Text>
        )}

        {isGoodsPurchaseOrderLinked ? (
          <PurchaseOrderSplitItem
            note={note}
            fieldIndex={fieldIndex}
            quantity={sapExpenseType ? undefined : quantity}
          />
        ) : (
          <>
            {showVatRate ? (
              <TruncatedText>
                {percentFormatter.format(vatRate / 100)}
              </TruncatedText>
            ) : (
              <Text>–</Text>
            )}

            {/* DocumentForm passes costCenterID */}
            {hasCostCenterInput && (
              // @ts-ignore costCenter.inputValue is available hasCostCenterInput is true
              <TruncatedText>{costCenter.inputValue}</TruncatedText>
            )}
            {!hasCostCenterInput && hasCostObjectInput && (
              // @ts-ignore costObject.inputValue is available hasCostObjectInput is true
              <TruncatedText>{costObject.inputValue}</TruncatedText>
            )}
            {!hasCostCenterInput && !hasCostObjectInput && <Text>–</Text>}
          </>
        )}
      </Grid>
      {onEdit !== undefined && (
        <Button
          color="blue"
          icon={readOnly ? 'view' : 'edit'}
          label={readOnly ? t('viewSplitIcon') : t('editSplitIcon')}
          onClick={onEdit}
          size="xsmall"
          variant="tertiary"
        />
      )}
    </Grid>
  );
};
