import { Card, Flex, Grid, Heading, Image } from '@candisio/design-system';
import { ReactNode } from 'react';
import { SETTINGS_CARDS_MAX_WIDTH } from 'views/Settings/Integrations/DATEV/consts';
import { DcsSettingsSkeleton } from './DcsCardSkeleton';

export interface DcoContainerProps {
  icon?: string;
  iconAlt?: string;
  title: ReactNode;
  tag?: ReactNode;
  details: ReactNode;
  actions?: ReactNode;
  secondaryActions?: ReactNode;
  bottomActions?: ReactNode;
  cardLoading?: boolean;
}

export const DcsSettingsContainer = ({
  title,
  details,
  icon,
  iconAlt,
  actions,
  tag,
  secondaryActions,
  bottomActions,
  cardLoading = false,
}: DcoContainerProps) => {
  const hasIcon = icon && iconAlt;

  return !cardLoading ? (
    <Grid
      gap={hasIcon ? 16 : 0}
      templateColumns="auto 1fr"
      maxWidth={SETTINGS_CARDS_MAX_WIDTH}
    >
      <Grid
        alignItems="center"
        justifyItems="center"
        minWidth={hasIcon ? 'space128' : '0'}
      >
        {hasIcon ? <Image src={icon} alt={iconAlt} /> : null}
      </Grid>
      <Card padding="space24">
        <Grid rowGap="space24">
          <Flex gap="space36" placeContent="space-between">
            <Flex direction="column" gap="space16">
              <Flex gap="space8" alignItems="center">
                <Heading as="h3">{title}</Heading>
                {tag}
              </Flex>
              {details}
            </Flex>
            <Flex
              direction="column"
              placeContent="space-between"
              minHeight="space128"
              alignItems="end"
            >
              {actions}
              {secondaryActions}
            </Flex>
          </Flex>
          {bottomActions}
        </Grid>
      </Card>
    </Grid>
  ) : (
    <DcsSettingsSkeleton />
  );
};
