import { Flex, Paragraph } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { LottieAnimation, nohistoryData } from '../../Lottie/Lottie';

interface NoHistoryDataProps {
  message?: string;
}

export const NoHistoryData = ({
  message = 'noHistory',
}: NoHistoryDataProps) => {
  const [t] = useTranslation();

  return (
    <Flex
      gap="space8"
      direction="column"
      width="100%"
      height="auto"
      alignItems="center"
      justifyContent="center"
    >
      <LottieAnimation options={{ animationData: nohistoryData }} />
      <Paragraph whiteSpace="pre-wrap" textAlign="center">
        {t(message)}
      </Paragraph>
    </Flex>
  );
};
