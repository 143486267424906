// Create Context
import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ReimbursementSplitBookingsFormValues } from '../toolkit/reimbursementSplitBookingsFormSchema';
import {
  ActiveBooking,
  DrawerView,
  Workflow,
} from './ReimbursementSplitBookingsProvider';
import {
  getActionRules,
  getFieldRules,
  ReimbursementStatusProps,
} from '../components/RightSection/ReimbursementSplitBookings/hooks/useReimbursementBookingsFieldRules';
import { BookingsErrorsSummary } from '../utils/getBookingsErrorsSummary';

export const ReimbursementSplitBookingsContext = createContext<
  ReimbursementSplitBookingsContextType | undefined
>(undefined);

type ReimbursementSplitBookingsContextType = {
  formMethods: UseFormReturn<ReimbursementSplitBookingsFormValues>;
  drawerView?: DrawerView;
  setDrawerView: Dispatch<SetStateAction<DrawerView | undefined>>;
  activeBooking: ActiveBooking;
  updateActiveBooking: (updates: Partial<ActiveBooking>) => void;
  resetDrawerAndForm: () => void;
  workflow?: Workflow;
  setWorkflow: Dispatch<SetStateAction<Workflow | undefined>>;
  triggerBookingsValidation: () => void;
  getSplitBookingsFormRules: (props: ReimbursementStatusProps) => {
    fieldRules: ReturnType<typeof getFieldRules>;
    actionRules: ReturnType<typeof getActionRules>;
  };
  bookingsErrorsSummary: BookingsErrorsSummary;
  hasBookingsErrors: boolean;
};

export const useReimbursementSplitBookingHelpers = () => {
  const context = useContext(ReimbursementSplitBookingsContext);
  if (!context) {
    throw new Error(
      'useReimbursementSplitBookingHelpers must be used within a ReimbursementSplitBookingsProvider'
    );
  }

  return context;
};
