import { Flex, Text, Skeleton, Box } from '@candisio/design-system';
import { PercentageProgressBar } from 'components/PercentageProgressBar/PercentageProgressBar';
import { AmountLoader } from 'containers/credit-cards/components/CreditCardOverviewCard/LimitText';
import { useGetCardById } from 'containers/credit-cards/utils';
import { CardStatus } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { amountFormat } from 'utils/format';
import { extractCardLimits } from '../../utils';
import { Amount } from '../Limits/Limits';
import { determineColorBar } from './utils';

interface AvailableBalanceprops {
  limit: Amount;
  availableBalance: Amount;
  isCreditCardActive: boolean;
  renewLimitDate: Date | undefined;
}

export const AvailableBalance = ({
  availableBalance,
  limit,
  isCreditCardActive,
  renewLimitDate,
}: AvailableBalanceprops) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  if (!limit.value || isNil(availableBalance.value)) {
    return null;
  }

  const formattedAvailableBalance = amountFormat(
    availableBalance.value,
    availableBalance.currency,
    {
      minimumFractionDigits: 0,
      convertToBasicMonetaryUnit: true,
    }
  );

  const percentage = (availableBalance.value / limit.value) * 100;

  const emptyProgressBar = (
    <Box height="space8" width="100%" background="gray300" borderRadius={12} />
  );

  return (
    <Flex direction="column">
      <Flex alignItems="end" gap="space10">
        <Text
          fontSize="basic"
          fontWeight="regular"
          lineHeight="1"
          color={isCreditCardActive ? 'gray800' : 'gray600'}
        >
          {t('ccOverviewCard.available')}
        </Text>
        {renewLimitDate ? (
          <Text
            fontSize="small"
            fontWeight="regular"
            lineHeight="1"
            color="gray600"
          >
            {t('ccOverviewCard.renewLimitDate', {
              renewLimitDate: DateFormatters.compact(renewLimitDate),
            })}
          </Text>
        ) : null}
      </Flex>
      <Text
        fontSize="xxlarge"
        color={isCreditCardActive ? 'gray800' : 'gray500'}
      >
        {formattedAvailableBalance}
      </Text>
      <Flex maxWidth="space128">
        {isCreditCardActive ? (
          <PercentageProgressBar
            percentage={percentage}
            color={determineColorBar(percentage)}
          />
        ) : (
          emptyProgressBar
        )}
      </Flex>
    </Flex>
  );
};

export const AvailableBalanceContainer = ({ cardId }: { cardId: string }) => {
  const { card, loading } = useGetCardById({ cardId });

  if (loading) {
    return (
      <Flex direction="column" gap="space4">
        {AmountLoader}
        <Skeleton height="space10" width="100%" borderRadius={12}></Skeleton>
      </Flex>
    );
  }

  if (!card) {
    // TODO error state
    return null;
  }

  const { limit, availableBalance } = extractCardLimits(card);

  const isCreditCardActive = card.status === CardStatus.Active;

  const isCardInUse = [
    CardStatus.Active,
    CardStatus.Locked,
    CardStatus.LockedPin,
  ].includes(card.status);

  const balance = isCardInUse
    ? { limit, availableBalance }
    : { limit, availableBalance: limit };

  const renewLimitDate =
    card.limitRenewDate && isCardInUse
      ? new Date(card.limitRenewDate)
      : undefined;

  return (
    <AvailableBalance
      availableBalance={balance.availableBalance}
      limit={balance.limit}
      isCreditCardActive={isCreditCardActive}
      renewLimitDate={renewLimitDate}
    />
  );
};
