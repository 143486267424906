import { getYearsToConsider } from '../utils/getYearsToConsider';

interface MappedFields {
  label: string;
  key: `q${number}_${number}`;
  placeholder: string;
}

interface MappedQuarter {
  year: string;
  fields: Array<MappedFields>;
}

export const quarters = ['q1', 'q2', 'q3', 'q4'] as const;

const mappedTranslationsToQuarters: Record<(typeof quarters)[number], string> =
  {
    q1: 'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q1Label',
    q2: 'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q2Label',
    q3: 'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q3Label',
    q4: 'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q4Label',
  };

export const getQuarters = () => {
  const { years } = getYearsToConsider({ currentDate: new Date() });

  const mappedQuarters = years.reduce<MappedQuarter[]>((acc, value) => {
    const mappedFields: MappedFields[] = quarters.map(quarter => ({
      label: mappedTranslationsToQuarters[quarter],
      key: `${quarter}_${value}`,
      placeholder:
        'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.placeHolder',
    }));

    return [...acc, { year: value.toString(), fields: mappedFields }];
  }, []);

  return mappedQuarters;
};

export const getQuarterFieldsKeys = () =>
  getQuarters().flatMap(quarter => quarter.fields.map(field => field.key));

export type Quarter = MappedFields['key'];

export interface FormQuarterBudget {
  [key: Quarter]: number;
}
