import { Button, Grid, Heading } from '@candisio/design-system';
import { RecurringPaymentVendorSource } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { CardSection } from './CardSection';
import { TransactionSection } from './TransactionsSection/TransactionsSection';
import { linkCardToVendorFormErrorMessages } from './utils/linkCardToVendorFormErrorMessages';
import {
  LinkCardToVendorFormOutput,
  LinkCardToVendorFormSchemaOptions,
  linkCardToVendorFormSchema,
} from './utils/linkCardToVendorFormSchema';
import { VendorSection } from './VendorSection';

export interface LinkExisitingCardToVendorFormProps {
  formMode: 'firstLinkage' | 'updateCardLinkage';
  defaultValues: Partial<LinkCardToVendorFormOutput>;
  defaultInputValue: string | undefined;
  isLoading?: boolean;
  isUnlinkLoading?: boolean;
  loadingVendor: boolean;
  vendor: {
    logoUrl: string | undefined;
    source: RecurringPaymentVendorSource | undefined;
    name?: string;
  };
  onAddRecurringPayment: () => void;
  onUnlink: () => void;
  onSubmit: (values: LinkCardToVendorFormOutput) => void;
  onClose: () => void;
}

export const LinkExisitingCardToVendorForm = ({
  defaultInputValue,
  defaultValues,
  formMode,
  isLoading,
  isUnlinkLoading,
  loadingVendor,
  vendor,
  onAddRecurringPayment,
  onSubmit,
  onUnlink,
  onClose,
}: LinkExisitingCardToVendorFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const isVendorEditable =
    formMode === 'updateCardLinkage' &&
    vendor.source === RecurringPaymentVendorSource.ManuallyAdded;

  const context: LinkCardToVendorFormSchemaOptions = {
    isVendorEditable,
  };

  const form = useForm<LinkCardToVendorFormOutput>({
    mode: 'onTouched',
    defaultValues,
    context,
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
      zodSchema: linkCardToVendorFormSchema,
      errorMessages: linkCardToVendorFormErrorMessages,
    }),
  });

  return (
    <FormProvider {...form}>
      <Grid templateColumns="1fr 1fr" height="100%">
        <Grid
          gap="space24"
          padding="space24"
          height="100%"
          alignItems="start"
          as="form"
          templateRows="auto auto 1fr"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Heading as="h3">
            {t('recurringPaymentsModal.linkCard.modalTitle')}
          </Heading>
          <VendorSection
            formMode={formMode}
            loading={loadingVendor}
            isEditable={isVendorEditable}
            logoUrl={vendor.logoUrl}
            vendorName={vendor.name}
            onAddRecurringPayment={onAddRecurringPayment}
          />
          <CardSection
            defaultInputValue={defaultInputValue}
            defaultCreditCardId={defaultValues.creditCard}
            formMode={formMode}
            onUnlink={onUnlink}
            isLoading={isLoading}
            isUnlinkLoading={isUnlinkLoading}
            vendor={vendor}
          />
        </Grid>
        <Grid
          background="gray50"
          padding="space24"
          height="100%"
          gap="space16"
          alignContent="start"
        >
          <Button
            onClick={onClose}
            icon="close"
            variant="tertiary"
            alignSelf="start"
            justifySelf="end"
          />
          <TransactionSection />
        </Grid>
      </Grid>
    </FormProvider>
  );
};
