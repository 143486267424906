import {
  Button,
  Flex,
  Item,
  Link,
  TabPanel,
  Tabs,
  useModal,
} from '@candisio/design-system';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { FilterableListItem } from 'components/FilterableList/components/FilterableListItem';
import { useTabs } from 'components/Tabs/useTabs';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';
import { TagsInfoPanel } from './TagsInfoPanel';
import { DocumentTagDetailsRoute } from './elements/Drawer/DocumentTagDetailsRoute';
import { DocumentTagsEmptyDataState } from './elements/List/DocumentTagsEmptyDataState';
import { DocumentTagsListItem } from './elements/List/DocumentTagsListItem';
import { MergeModal } from './elements/Modal/MergeModal';
import { useDocumentTagsListLogic } from './toolkit/hooks/useDocumentTagsListLogic';
import { TagsSortBy } from './toolkit/utils';

export const DocumentTags = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const [documentTagsMergeFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.documentTagsMerge,
  ]);

  const sortMenuItems = [
    {
      id: TagsSortBy.alphabetically,
      label: t('documentTags.sort.alphabetically'),
    },
    {
      id: TagsSortBy.newestFirst,
      label: t('documentTags.sort.newestFirst'),
    },
    {
      id: TagsSortBy.mostUsed,
      label: t('documentTags.sort.mostUsed'),
    },
  ] as const;

  const {
    activeCount,
    archivedCount,
    countLoading,
    handleCreateTag,
    onSearchFilter,
    onSelect,
    queryString,
    tags,
    selectedKey,
    onTabChanged,
    sortBy,
    onSortBy,
    selectedTagIds,
    setSelectedTagIds,
    clearSelection,
    showListActions,
    selectedItems,
    isMergeDisabled,
  } = useDocumentTagsListLogic();

  const { modalProps, triggerProps } = useModal();

  const isActive = selectedKey === 'active';
  const canSelect = documentTagsMergeFF && isActive;

  const filterableList = (
    <FilterableList
      selectionMode={canSelect ? 'multiple' : 'none'}
      selectedKeys={selectedTagIds}
      onSelectionChange={setSelectedTagIds}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
      emptyDataState={<DocumentTagsEmptyDataState />}
      onAction={onSelect}
      isLoading={countLoading}
      onCreate={{
        value: handleCreateTag,
        text: t('documentTags.actions.new'),
      }}
      menuButtons={[
        {
          actionValue: [sortBy],
          onClick: value => {
            if (value?.length) {
              onSortBy(value[0]);
            }
          },
          text: sortMenuItems.find(s => s.id === sortBy)?.label ?? '',
          menuButtonItems: sortMenuItems,
        },
      ]}
      searchField={{
        onSearchFilter,
        placeholder: t('documentTags.searchPlaceholder'),
        searchQuery: queryString,
      }}
      listActions={
        showListActions && (
          <Flex
            background="bluebg"
            padding="space16 space16"
            justifyContent="space-between"
          >
            <Flex alignItems="center" gap="space8" fontSize="basic">
              {t('documentTags.mergeModal.subheader.selection', {
                count: selectedItems.length,
              })}
              <Link as="button" onClick={clearSelection}>
                {t('documentTags.mergeModal.subheader.unselectAction')}
              </Link>
            </Flex>
            <Button
              variant="secondary"
              {...(isMergeDisabled ? { disabled: true } : triggerProps)}
            >
              {t('documentTags.mergeModal.subheader.mergeAction')}
            </Button>
          </Flex>
        )
      }
    >
      {tags.map(tag => (
        <Item key={tag.id} textValue={tag.name}>
          <FilterableListItem
            itemId={tag.id}
            showDefaultStyling={!documentTagsMergeFF}
            hasAction
          >
            <DocumentTagsListItem
              tag={tag}
              queryString={queryString}
              isActive={isActive}
            />
          </FilterableListItem>
        </Item>
      ))}
    </FilterableList>
  );

  const { tabPanelProps, tabsProps } = useTabs({
    items: [
      {
        key: 'active',
        title: t('documentTags.actions.activeTab'),
        badge: !countLoading ? String(activeCount) : undefined,
        children: filterableList,
      },
      {
        key: 'archived',
        title: t('documentTags.actions.archiveTab'),
        badge: !countLoading ? String(archivedCount) : undefined,
        children: filterableList,
      },
    ],
    onSelectionChange: onTabChanged,
    selectedKey,
  });

  const handleMergeSuccess = () => {
    clearSelection();
    modalProps.onClose?.();
  };

  return (
    <SettingsLayout
      title={t('documentTags.title')}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
      actions={<Tabs {...tabsProps} />}
    >
      <TabPanel {...tabPanelProps} />

      <TagsInfoPanel />

      <MergeModal
        modalProps={modalProps}
        tags={selectedItems}
        handleMergeSuccess={handleMergeSuccess}
      />

      <DocumentTagDetailsRoute />
    </SettingsLayout>
  );
};
