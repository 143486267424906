import React, { useRef } from 'react';
import {
  FocusScope,
  mergeProps,
  useDialog,
  useModal,
  usePreventScroll,
} from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { Box } from '../../Atoms/Box';
import { ColorProps, LayoutProps, StandardHTMLAttributes } from '../../types';
import { DrawerWidth } from './Drawer';

export interface DrawerDialogProps
  extends StandardHTMLAttributes<HTMLDivElement> {
  isModal?: boolean;
  width?: DrawerWidth;
}

export const DrawerDialog = React.forwardRef<HTMLDivElement, DrawerDialogProps>(
  (
    { children, isModal = false, width = '34.625rem', ...restProps },
    forwardedRef
  ) => {
    const layoutProps: LayoutProps = {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 100,
      width,
    };

    const colorProps: ColorProps = {
      background: 'gray0',
    };

    // Get props for the dialog
    const ref = useRef(null);
    const { dialogProps } = useDialog({}, ref);

    // Prevent scrolling while modal drawer is open, and hide content
    // outside modal drawer from screen readers
    usePreventScroll({ isDisabled: !isModal });
    const { modalProps } = useModal({ isDisabled: !isModal });

    return (
      <FocusScope
        autoFocus
        contain={isModal} // trap focus if drawer is modal
        restoreFocus
      >
        <Box
          boxShadow="elevatedShadow3"
          className="drawer"
          {...layoutProps}
          {...colorProps}
          {...mergeProps(dialogProps, modalProps, restProps)}
          ref={mergeRefs([ref, forwardedRef])}
        >
          {children}
        </Box>
      </FocusScope>
    );
  }
);
