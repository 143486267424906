import { Flex, Text, Card, Grid, Image, Icon } from '@candisio/design-system';
import iconCard from 'assets/credit-cards/icon-card.svg';
import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ReplacementCardItemProps {
  onClick: (mode: DrawerMode) => void;
}

export const ReplacementCardItem = ({
  onClick: onClickProp,
}: ReplacementCardItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Card
      onClick={() => onClickProp('replaceCard')}
      hover={{
        background: 'gray200',
      }}
      background="gray100"
      style={{
        cursor: 'pointer',
      }}
    >
      <Grid templateColumns="1fr auto" alignItems="center">
        <Flex alignItems="center" gap="space16">
          <Image
            src={iconCard}
            alt={t('creditCardFormDataPreview.replaceCard.altIcon')}
          />
          <Flex alignItems="baseline" gap="space8">
            <Text fontSize="large">
              {t('creditCardFormDataPreview.replaceCard.title')}
            </Text>
            <Text>{t('creditCardFormDataPreview.replaceCard.subtitle')}</Text>
          </Flex>
        </Flex>
        <Icon icon="arrowRight" size="space16" />
      </Grid>
    </Card>
  );
};
