import { RecurringPaymentVendorSource } from 'generated-types/graphql.types';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { LinkCardToVendorFormValues } from '../CreditCardsInsights/RecurringPayments/RecurringPaymentsModal/utils/linkCardToVendorFormSchema';
import { useGetVendorCardSuggestionRecurringPayments } from './useGetVendorCardSuggestionRecurringPayments';
import { useGetVendorForRecurringPaymentsById } from './useGetVendorForRecurringPaymentsById';

export const useLinkExisitingCardToFormVendorValues = () => {
  const { vendorId } = useParams<{ vendorId: string }>();
  const { suggestedCard, loading: loadingSuggestedCard } =
    useGetVendorCardSuggestionRecurringPayments();

  const { vendorDetails, loading: loadingVendorDetails } =
    useGetVendorForRecurringPaymentsById({ vendorId });

  const hasVendorCardLinked =
    vendorDetails.linkedCards && vendorDetails.linkedCards.length;

  const linkedCardToVendor = hasVendorCardLinked
    ? vendorDetails.linkedCards[0]
    : undefined;

  const suggestedCardIdForVendor = suggestedCard ? suggestedCard.id : undefined;

  // We always want to make sure the cardLinked has priority over the suggested card

  const formMode: 'firstLinkage' | 'updateCardLinkage' = linkedCardToVendor
    ? 'updateCardLinkage'
    : 'firstLinkage';

  const isVendorEditable =
    formMode === 'updateCardLinkage' &&
    vendorDetails.source === RecurringPaymentVendorSource.ManuallyAdded;

  const defaultValues: LinkCardToVendorFormValues = {
    creditCard: hasVendorCardLinked
      ? linkedCardToVendor?.id
      : suggestedCardIdForVendor,
    vendorName: isVendorEditable ? vendorDetails.name : undefined,
  };

  const defaultInputValue = hasVendorCardLinked
    ? `${linkedCardToVendor?.cardNumber}`
    : suggestedCard
      ? `${suggestedCard.cardNumber}`
      : undefined;

  return {
    defaultValues,
    defaultInputValue,
    formMode,
    loading: loadingSuggestedCard || loadingVendorDetails,
  };
};
