import {
  Button,
  Card,
  Grid,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useDeleteReimbursement } from 'views/Reimbursement/hooks/useDeleteReimbursement';
import { useUpdateGeneralExpense } from 'views/Reimbursement/hooks/useUpdateGeneralExpense';
import { useUpdateHospitalityExpense } from 'views/Reimbursement/hooks/useUpdateHospitalityExpense';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import {
  ErrorStateFooter,
  ReimbursementError,
} from 'views/Reimbursement/toolkit/types';
import { DeleteAction } from './DeleteAction';
import { ErrorSection } from './ErrorSection';
import { useSuccessToastWithDeepLink } from './ReimbursementSuccessMessageWithDeepLink';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';

export interface FooterActionsProps {
  isSubmittingForReview: boolean;
  reimbursement?: Reimbursement;
  onCycleReimbursments: () => void;
  getExpenses: UseFormGetValues<ExpensesFormOutput>;
  onSubmitForReview: (reimbursementId: string) => Promise<{
    status: string;
    reimbursementItemsErrors: ReimbursementError[];
    hasNoReimbursementItems?: boolean;
    hasReimbursementCaseErrors: boolean;
    hasNoIncludedReimbursementItems?: boolean;
  }>;
}

export const FooterActions = ({
  isSubmittingForReview,
  getExpenses,
  onCycleReimbursments,
  onSubmitForReview,
  reimbursement,
}: FooterActionsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { error, success } = useToastMessage();

  const successMessageWithDeepLink = useSuccessToastWithDeepLink(reimbursement);

  const [errors, setErrors] = useState<ErrorStateFooter>({
    itemErrors: [],
    showHasNoExpenseError: false,
    showHasNoIncludedExpensesError: false,
    hasReimbursementCaseErrors: false,
  });

  const { updateGeneralExpense, isUpdateGeneralExpensePending } =
    useUpdateGeneralExpense({ shouldDebounce: false });

  const { isUpdateHospitalityExpensePending, updateHospitalityExpense } =
    useUpdateHospitalityExpense({ shouldDebounce: false });
  const { deletePending, deleteReimbursement } = useDeleteReimbursement();

  const { triggerAllValidations, lastModifiedExpense } =
    useReimbursementFormsContext();

  const errMessage = t('reimbursementView.createErr');
  const successMessage = t(
    'reimbursementView.rightSection.footerActions.doubleConfirm.deleteSuccessToast'
  );

  const handleDelete = async () => {
    const status = await deleteReimbursement(reimbursementId);
    if (status === 'error') {
      error(errMessage);

      return;
    }

    success(successMessage);
    onCycleReimbursments();
  };

  const handleClick = async () => {
    const lastModifiedExpenseData =
      lastModifiedExpense !== null
        ? getExpenses('expenses')[lastModifiedExpense]
        : undefined;

    if (
      lastModifiedExpenseData &&
      lastModifiedExpenseData.expenseType === 'general'
    ) {
      await updateGeneralExpense(lastModifiedExpenseData);
    }

    if (
      lastModifiedExpenseData &&
      lastModifiedExpenseData.expenseType === 'hospitality'
    ) {
      await updateHospitalityExpense(lastModifiedExpenseData);
    }

    const response = await onSubmitForReview(reimbursementId);
    if (response.status === 'error') {
      triggerAllValidations();
      setErrors({
        itemErrors: response.reimbursementItemsErrors,
        showHasNoExpenseError: response.hasNoReimbursementItems ?? false,
        hasReimbursementCaseErrors: response.hasReimbursementCaseErrors,
        showHasNoIncludedExpensesError:
          response.hasNoIncludedReimbursementItems ?? false,
      });

      return;
    }

    successMessageWithDeepLink({
      key: 'reimbursementView.rightSection.footerActions.submitSuccess',
    });
    setErrors({
      itemErrors: [],
      showHasNoExpenseError: false,
      hasReimbursementCaseErrors: false,
      showHasNoIncludedExpensesError: false,
    });
    onCycleReimbursments();
  };

  const isSubmitPending =
    isSubmittingForReview ||
    isUpdateHospitalityExpensePending ||
    isUpdateGeneralExpensePending;

  const isErrorSectionVisible =
    errors.itemErrors.length > 0 ||
    errors.hasReimbursementCaseErrors ||
    errors.showHasNoIncludedExpensesError ||
    errors.showHasNoExpenseError;

  return (
    <Card corners="top" boxShadow="elevatedShadow4" padding="space20">
      <Grid gap="space8">
        <Text color="gray500" fontWeight="semibold" textTransform="uppercase">
          {t('reimbursementView.rightSection.footerActions.title')}
        </Text>
        {isErrorSectionVisible && (
          <ErrorSection
            errors={errors.itemErrors}
            hasReimbursementCaseErrors={errors.hasReimbursementCaseErrors}
            hasNoExpenseError={errors.showHasNoExpenseError}
            hasNoIncludedExpensesError={errors.showHasNoIncludedExpensesError}
          />
        )}

        <Grid gap="space8">
          <Button
            color="blue"
            onClick={handleClick}
            disabled={isSubmitPending || deletePending}
            loading={isSubmitPending}
          >
            <TruncatedText>
              {t('reimbursementView.rightSection.footerActions.submitCta')}
            </TruncatedText>
          </Button>
          <DeleteAction
            loading={deletePending}
            onDelete={handleDelete}
            disabled={isSubmitPending || deletePending}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
