import { History } from 'components/History/History';
import { ActivityType } from 'components/History/types';
import { EmailInboxMessage } from 'generated-types/graphql.types';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { useMemo } from 'react';
import { MailHistoryLayout } from './styles';
type Props = {
  mail: EmailInboxMessage;
};

export const MailHistory = ({ mail }: Props) => {
  const history = useMemo(
    () =>
      mail.history ? sortBy(mail.history, ({ createdAt }) => createdAt) : [],
    [mail.history]
  );

  return (
    <MailHistoryLayout>
      {!!history.length && (
        <History>
          {history.map((item, key, items) => {
            const createdAt = moment(item.createdAt);

            return (
              <History.Render
                absoluteTime={createdAt.format('LLL')}
                header={createdAt.fromNow()}
                key={item.id}
                initial={key === items.length - 1}
                type={ActivityType.Neutral}
              >
                {item.events?.map(event => event.data)}
              </History.Render>
            );
          })}
        </History>
      )}
    </MailHistoryLayout>
  );
};
