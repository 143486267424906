import {
  Button,
  Grid,
  Popover,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface DeleteActionProps {
  disabled?: boolean;
  loading: boolean;
  onDelete: () => Promise<void>;
}

export const DeleteAction = ({
  disabled,
  loading,
  onDelete,
}: DeleteActionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef, close } =
    usePopover();

  return (
    <>
      <Button
        variant="secondary"
        color="red"
        icon="trash"
        iconPosition="left"
        loading={loading}
        disabled={disabled}
        {...triggerProps}
        ref={triggerRef}
      >
        <TruncatedText>
          {t('reimbursementView.rightSection.footerActions.deleteCTa')}
        </TruncatedText>
      </Button>
      {!disabled && isOpen && (
        <Popover
          width="320px"
          padding="space18"
          {...popoverProps}
          ref={popoverRef}
        >
          <Grid gap="space20">
            {t(
              'reimbursementView.rightSection.footerActions.doubleConfirm.desc'
            )}
            <Grid autoFlow="column" gap="space8">
              <Button onClick={close} variant="secondary">
                {t(
                  'reimbursementView.rightSection.footerActions.doubleConfirm.no'
                )}
              </Button>
              <Button
                color="red"
                onClick={() => {
                  close();
                  void onDelete();
                }}
              >
                {t(
                  'reimbursementView.rightSection.footerActions.doubleConfirm.yes'
                )}
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
};
