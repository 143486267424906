import { Flex, Grid, Heading, Icon } from '@candisio/design-system';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const MotionGrid = motion(Grid);

export const ReauthenicationSuccess = ({
  onReauthenticated,
}: {
  onReauthenticated: () => void;
}) => {
  const [t] = useTranslation();

  useEffect(() => {
    const timer = setTimeout(onReauthenticated, 1500);

    return () => clearTimeout(timer);
  }, [onReauthenticated]);

  return (
    <MotionGrid
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeIn' }}
      placeContent="center"
      placeItems="center"
      height="100%"
    >
      <Icon color="green500" icon="checkCircle" size="space128" />
      <Flex alignItems="center" direction="column" maxWidth="400px">
        <Heading as="h2">
          {t('reauthentication.modal.successMessage.title')}
        </Heading>
      </Flex>
    </MotionGrid>
  );
};
