import {
  InsightBudgetType,
  InsightsWidgetBudgetsInput,
} from 'generated-types/graphql.types';
import { WidgetFormData } from '../../types';
import { QUARTER, QUARTERS_IN_A_MONTH, YearDividedByQuarter } from '../types';
import { FormAnnualBudget } from '../AnnualOption/AnnualOption.helper';
import {
  FormQuarterBudget,
  getQuarterFieldsKeys,
} from '../QuarterOption/QuarterOption.helper';
import { getYearsToConsider } from './getYearsToConsider';

//Takes yearly Budgets from the form and translate it into quarterly budgets
export const getQuarterBudgetsFromYear = (
  formData: WidgetFormData,
  type: InsightBudgetType
) => {
  const { years } = getYearsToConsider({ currentDate: new Date() });

  const annualBudgets = years.reduce<FormAnnualBudget>((acc, value) => {
    return {
      ...acc,
      [`year${value}`]: formData[`year${value}`],
    };
  }, {});

  const yearsToInclude = years.map(year => year.toString());

  return yearsToInclude.map(
    translateYearBudgetsInQuarterBudgets(annualBudgets, type)
  );
};

const splitNumber = (value: number, splitCount: number) => {
  const splitFlatValue = !value ? undefined : Math.trunc(value / splitCount);
  const splitRemainderValue = !value ? undefined : value % splitCount;

  return {
    splitFlatValue,
    splitRemainderValue,
  };
};

// https://www.delftstack.com/howto/javascript/javascript-round-to-2-decimal-places/#use-the-math-round-function-to-round-a-number-to2-decimal-places-in-javascript
export const roundToTwoDigits = (number: number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const translateYearBudgetsInQuarterBudgets =
  (annualBudgets: FormAnnualBudget, type: InsightBudgetType) =>
  (year: string) => {
    const index = `year${year}`;
    const budget = annualBudgets[index as keyof FormAnnualBudget];

    const quarterBudget = budget
      ? Math.trunc(budget / QUARTERS_IN_A_MONTH)
      : undefined;

    const quarterBudgetReminder = budget
      ? budget % QUARTERS_IN_A_MONTH
      : undefined;

    return {
      year: Number(year),
      quarters: {
        q1: quarterBudget,
        q2: quarterBudget,
        q3: quarterBudget,
        q4: quarterBudget
          ? // biome-ignore lint/style/noNonNullAssertion: <explanation>
            roundToTwoDigits(quarterBudget + quarterBudgetReminder!)
          : undefined,
      },
      type,
    };
  };

//Takes the quarterly budgets from the form and maps it into quarters
export const assignBudgetsByQuarter = (
  formData: WidgetFormData,
  type: InsightBudgetType
) => {
  const { years } = getYearsToConsider({ currentDate: new Date() });

  const quarterFieldsKeys = getQuarterFieldsKeys();

  const quarterBudgets = quarterFieldsKeys.reduce<FormQuarterBudget>(
    (acc, value) => {
      return {
        ...acc,
        [value]: formData[value],
      };
    },
    {}
  );

  const yearsToInclude = years.map(year => year.toString());

  return yearsToInclude.map(mapQuarterBudgegtsFields(quarterBudgets, type));
};

export const mapQuarterBudgegtsFields =
  (quarterBudgets: FormQuarterBudget, type: InsightBudgetType) =>
  (year: string) => {
    const indexQ1 = `q1_${year}`;
    const indexQ2 = `q2_${year}`;
    const indexQ3 = `q3_${year}`;
    const indexQ4 = `q4_${year}`;

    return {
      year: Number(year),
      quarters: {
        q1: quarterBudgets[indexQ1 as keyof FormQuarterBudget],
        q2: quarterBudgets[indexQ2 as keyof FormQuarterBudget],
        q3: quarterBudgets[indexQ3 as keyof FormQuarterBudget],
        q4: quarterBudgets[indexQ4 as keyof FormQuarterBudget],
      },
      type,
    };
  };

export const prepareBudgetFormForMutation: (
  yearDividedByQuarter: YearDividedByQuarter
) => InsightsWidgetBudgetsInput = (
  yearDividedByQuarter: YearDividedByQuarter
) => {
  const manifest = [
    { key: QUARTER.Q1, months: ['january', 'february', 'march'] },
    { key: QUARTER.Q2, months: ['april', 'may', 'june'] },
    { key: QUARTER.Q3, months: ['july', 'august', 'september'] },
    { key: QUARTER.Q4, months: ['october', 'november', 'december'] },
  ];

  const monthlyBudgets = manifest.reduce((acc, { key, months }) => {
    const currentBudget = yearDividedByQuarter.quarters[key];

    if (!currentBudget) {
      return acc;
    }

    const { splitRemainderValue, splitFlatValue } = splitNumber(
      currentBudget,
      months.length
    );

    const remainderMonth = months.pop();
    const [first, second] = months;

    return {
      ...acc,
      [first]: splitFlatValue,
      [second]: splitFlatValue,
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      [remainderMonth!]: splitFlatValue
        ? // biome-ignore lint/style/noNonNullAssertion: <explanation>
          roundToTwoDigits(splitFlatValue + splitRemainderValue!)
        : undefined,
    };
  }, {});

  return {
    year: yearDividedByQuarter.year,
    monthlyBudgets,
    type: yearDividedByQuarter.type,
  };
};
