import { Button, Grid, Skeleton, Text } from '@candisio/design-system';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { ActionBar } from 'components/Sidebar/ActionBar';
import { PROVISIONS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import {
  ExportableEntityType,
  useGetAvailableMonthsQuery,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useDatev } from 'orgConfig/datev';
import { useDatevExportConfig } from 'orgConfig/datev/datevExportConfig';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Use v5 version of useParams because we’re inside a v5 route
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory, useParams } from 'react-router-dom';
import { ExportContext } from 'views/Integrations/Export/Context';
import { ConfigureButton } from 'views/Integrations/Export/toolkit/components/ConfigureButton';
import { DatevPanel } from 'views/Integrations/Export/toolkit/components/DatevPanel';
import { ProvisionsPromotionExportInfoCard } from 'components/ProductPromotions/Provisions/ProvisionsPromotionExportInfoCard';
import { useDatevSettings } from 'views/Integrations/Export/toolkit/hooks/useDatevSettings';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { useIsExporting } from 'views/Integrations/Export/toolkit/hooks/useIsExporting';
import { useShowEntitlementPromo } from 'views/Integrations/Export/toolkit/hooks/useShowEntitlementPromo';
import { hasTransaction } from 'views/Integrations/Export/toolkit/utils';
import {
  ExportEntity,
  Table,
  TimeFrameOption,
} from 'views/Integrations/Export/types';

export const BdsExportButton = ({
  onExport,
  hasExportableEntity,
  entities,
  loading,
}: {
  onExport: ((timeFrame?: TimeFrameOption) => Promise<void>) | (() => void);
  hasExportableEntity: boolean;
  entities: ExportEntity[];
  loading?: boolean;
}) => {
  const state = useContext(ExportContext);
  const { exportType, table } = state;
  const [t] = useTranslation();
  const history = useHistory();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();
  const [isExportTriggered, setIsExportTriggered] = useState<boolean>(false);
  const { onlyUnsupportedDatevDocumentsAvailable } =
    useExportDataGigaFactory(state);

  const { datevSettings, loading: loadingDatevSettings } = useDatevSettings();

  const showEntitlementPromo = useShowEntitlementPromo();

  const { hasInvalidGrant, loading: loadingDatevExportConfig } =
    useDatevExportConfig();

  const {
    isLoadingDatev: loadingDatev,
    bdsBought,
    exportProvisionsFF,
    rdsConnected,
    bdsConnected,
    bdsSetupCompleted,
  } = useDatev(); // BDS-checked

  const { isExporting } = useIsExporting();

  const { loading: loadingAvailableMonthsData } = useGetAvailableMonthsQuery({
    variables: {
      originalExportId: state.exportId,
    },
    fetchPolicy: 'no-cache',
  });

  const onGoToDatevSettings = () => {
    history.push(`/${organizationSlug}${Routes.SETTINGS}${Routes.DATEV}`);
  };

  const onGoToCreditCardSettings = () => {
    history.push(
      `/${organizationSlug}${Routes.SETTINGS}${Routes.CREDIT_CARDS}`
    );
  };

  const creditCardTransactionExists = entities.some(hasTransaction);
  const cardSettlementsExists = entities.some(
    entity => entity.type === ExportableEntityType.CardSettlement
  );

  const creditCardBookingAccount =
    datevSettings?.creditCardsLedger?.bookingAccount ?? '';

  const creditCardTransitAccount =
    datevSettings?.creditCardsLedger?.transitAccount ?? '';

  const hasError =
    (!hasExportableEntity && isExportTriggered) ||
    (exportType && onlyUnsupportedDatevDocumentsAvailable);

  const provisionAccount =
    datevSettings?.provisionsLedger?.provisionAccountNumber;

  const provisionOtherAssetsAccount =
    datevSettings?.provisionsLedger?.otherAssetsAccountNumber;

  const provisionsExists = entities.some(
    entity =>
      entity.type === ExportableEntityType.Provision ||
      entity.type === ExportableEntityType.ProvisionReversal
  );

  const provisionsOpened =
    !exportProvisionsFF &&
    (table === Table.PROVISIONS || table === Table.REVERSALS);

  const isChartOfAccountsSettingsCompleted =
    datevSettings?.chartOfAccount?.code &&
    datevSettings.chartOfAccount.accountLength;

  const renderExportButton = () => {
    if (hasInvalidGrant) {
      return (
        <ConfigureButton
          message={t('exports:exportButton.invalidGrant.message')}
          buttonLabel={t('exports:exportButton.invalidGrant.button')}
          loading={isExporting}
          onClick={onGoToDatevSettings}
        />
      );
    }

    if (bdsConnected && !bdsSetupCompleted) {
      return (
        <ConfigureButton
          variant="information"
          loading={isExporting}
          message={t('export.exportForm.exportDatevBds.bdsNotEnabled')}
          buttonLabel={t('export.exportForm.exportDatevBds.enableDatevReWe')}
          onClick={onGoToDatevSettings}
        />
      );
    }

    if (bdsConnected && !isChartOfAccountsSettingsCompleted) {
      return (
        <ConfigureButton
          variant="information"
          message={t(
            'export.exportForm.exportDatevBds.chartOfAccountsNotSetup'
          )}
          buttonLabel={t('export.exportForm.exportDatevBds.switchToDatevReWe')}
          loading={isExporting}
          onClick={onGoToDatevSettings}
        />
      );
    }

    if (!bdsBought && rdsConnected) {
      return (
        <>
          <Grid paddingBottom="space8">
            <ProvisionsPromotionExportInfoCard />
          </Grid>
          <Button disabled>{t('export.exportForm.actionBar.export')}</Button>
        </>
      );
    }

    if (bdsBought && rdsConnected) {
      return (
        <ConfigureButton
          variant="information"
          message={t('export.exportForm.exportDatevBds.bdsNotEnabledForDUO')}
          buttonLabel={t('export.exportForm.exportDatevBds.switchToDatevReWe')}
          loading={isExporting}
          onClick={onGoToDatevSettings}
        />
      );
    }

    if (
      (creditCardTransactionExists && !creditCardBookingAccount) ||
      (cardSettlementsExists && !creditCardTransitAccount)
    ) {
      return (
        <ConfigureButton
          message={t('export.exportForm.exportDatev.creditCardAccountMissing')}
          buttonLabel={t('export.exportForm.exportDatev.enterAccountNumbers')}
          loading={isExporting}
          onClick={onGoToCreditCardSettings}
        />
      );
    }

    if (
      bdsConnected &&
      exportProvisionsFF &&
      provisionsExists &&
      (!provisionAccount || !provisionOtherAssetsAccount)
    ) {
      return (
        <ConfigureButton
          variant="information"
          message={t('export.exportForm.exportDatevBds.missingAccountNumber')}
          buttonLabel={t(
            'export.exportForm.exportDatevBds.missingAccountNumberButton'
          )}
          loading={isExporting}
          onClick={() =>
            history.push(
              `/${organizationSlug}${Routes.SETTINGS}${Routes.PROVISIONS}`
            )
          }
        />
      );
    }

    return (
      <>
        <ProvisionsPromotionExportInfoCard />
        <Button
          onClick={() => {
            setIsExportTriggered(true);
            if (hasExportableEntity) {
              void onExport();
            }
          }}
          loading={loading}
          disabled={hasError || provisionsOpened}
          type="submit"
          data-cy="submit"
        >
          {t('export.exportForm.actionBar.export')}
        </Button>
      </>
    );
  };

  const isLoadingConfig =
    loadingDatevSettings || loadingDatevExportConfig || loadingDatev;

  if (isLoadingConfig) {
    return (
      <ActionBar>
        <Skeleton width="100%" height="3rem" />
      </ActionBar>
    );
  }

  if (loadingAvailableMonthsData) {
    return (
      <ActionBar>
        <ProgressBar completion={0}>
          {t('export.exportForm.actionBar.loading')}
        </ProgressBar>
      </ActionBar>
    );
  }

  if (isExporting) {
    return (
      <ActionBar>
        <ProgressBar completion={0}>
          {t('export.exportForm.actionBar.exporting')}
        </ProgressBar>
      </ActionBar>
    );
  }

  if (showEntitlementPromo) {
    return (
      <ActionBar>
        <UpsellPromo
          feature={FEATURE.PROVISIONS}
          utmSource={PROVISIONS_UTM_SOURCE.EXPORT_SIDEBAR}
        />
        <Button disabled>{t('export.exportForm.actionBar.export')}</Button>
      </ActionBar>
    );
  }

  if (!datevSettings?.client) {
    return (
      <ActionBar>
        <Button
          onClick={onGoToDatevSettings}
          data-cy="submit"
          loading={loading}
        >
          {t('export.exportForm.exportDatev.notConnected.cta')}
        </Button>
      </ActionBar>
    );
  }

  return (
    <DatevPanel
      loading={isExporting}
      onExport={onExport}
      datevSettings={datevSettings}
      nonIncidentElement={
        <ActionBar>
          {hasError && (
            <Text color="red500" fontSize="xsmall">
              {t('export.exportForm.actionBar.noExportableDocuments')}
            </Text>
          )}
          {renderExportButton()}
        </ActionBar>
      }
    />
  );
};
