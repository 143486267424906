import { useApolloClient, useQuery } from '@apollo/client';
import {
  CardIssuerCard,
  CardIssuerCardsForCardholderQuery,
  CardStatus,
  GetCardIssuerCardByIdQuery,
  GetCardIssuerCardsForCardholderQuery,
} from 'generated-types/graphql.types';
import { CardType } from 'generated-types/resolvers-types';
import { Routes } from 'models';
import { useEffect } from 'react';
import { getCardIssuerCardByIdQuery } from '../components/CreditCardsSection/gql';
import { cardIssuerCardsForCardholderQuery } from './gql';
import { CarouselStates } from './types';

export const extractCardLimits = (
  card: Pick<
    GetCardIssuerCardByIdQuery['getCardIssuerCardById'],
    'availableBalance' | 'transactionLimit' | 'pendingRequest' | 'limit'
  >
) => {
  const fallback: { [key: string]: number | null } = {
    limit: null,
    transactionLimit: null,
  };

  if (card?.pendingRequest?.__typename === 'PhysicalCardRequest') {
    fallback.limit = card.pendingRequest.requestedLimit?.value || 0;
    fallback.transactionLimit =
      card.pendingRequest.requestedTransactionLimit.value || 0;
  }

  if (card?.pendingRequest?.__typename === 'SingleUseVirtualCardRequest') {
    fallback.transactionLimit =
      card.pendingRequest.requestedTransactionLimit.value || 0;
  }

  if (card?.pendingRequest?.__typename === 'VirtualCardRequest') {
    fallback.limit = card.pendingRequest.requestedLimit?.value || 0;
    fallback.transactionLimit =
      card.pendingRequest.requestedTransactionLimit.value || 0;
  }

  return {
    limit: {
      value: card?.limit?.value || fallback.limit || 0,
      currency: 'EUR',
    },
    availableBalance: {
      value: card?.availableBalance?.value || 0,
      currency: 'EUR',
    },
    transactionLimit: {
      value: card?.transactionLimit?.value || fallback.transactionLimit || 0,
      currency: 'EUR',
    },
  };
};

export const extractCreditCardState = (
  card: Pick<CardIssuerCard, 'status' | 'type'>
) => {
  return card.status === CardStatus.Processing
    ? CarouselStates.PROCESSING
    : card.status === CardStatus.Locked
      ? CarouselStates.LOCKED
      : card.status === CardStatus.LockedPin
        ? CarouselStates.LOCKEDPIN
        : card.status === CardStatus.Expired
          ? CarouselStates.EXPIRED
          : card.status === CardStatus.Requested
            ? CarouselStates.REQUESTED
            : card.status === CardStatus.Pending &&
                [CardType.Physical, CardType.Black].includes(card.type)
              ? CarouselStates.ACTIVATE
              : card.status === CardStatus.Active
                ? CarouselStates.DEFAULT
                : CarouselStates.DEFAULT;
};

interface CreditCardLinkProps {
  cardId: string;
  organizationSlug: string;
}

type NavigationResult = {
  prevCardLink?: string;
  prevCardId?: string;
  nextCardLink?: string;
  nextCardId?: string;
  isLoadingNavigationData: boolean;
  currentCardIndex: number;
  /*** @TODO: should improve this with proper types ***/
  currentCard?: Omit<
    GetCardIssuerCardByIdQuery['getCardIssuerCardById'],
    'allRequests' | 'hasAccountingData' | 'isInvoiceNeeded'
  >;
};
interface CreditCardCarouselNavigationProps {
  cardId?: string;
  organizationSlug: string;
  cards: GetCardIssuerCardsForCardholderQuery['getCardIssuerCardsForCardholder']['edges'];
}

const query = getCardIssuerCardByIdQuery;

export const generateCreditCardLink = ({
  cardId,
  organizationSlug,
}: CreditCardLinkProps) => {
  return `/${organizationSlug}${Routes.DASHBOARD}?cardId=${cardId}`;
};

export const useCreditCardDetails = ({
  cursor,
}: {
  cursor: string | undefined | null;
}) => {
  const { data, loading } = useQuery<CardIssuerCardsForCardholderQuery>(
    cardIssuerCardsForCardholderQuery,
    {
      skip: !cursor,
      variables: { cursor },
      fetchPolicy: 'no-cache',
    }
  );

  const { edges: prevEdges } = data?.prevCard || {};

  const { edges: nextEdges } = data?.nextCard || {};

  return {
    nextCardEdge: nextEdges?.[0],
    prevCardEdge: prevEdges?.[0],
    loading,
  };
};

interface DetailsProps {
  cardIndex: number;
  cursor: string;
  /*** @TODO: should improve this with proper types ***/
  cardDetails: Omit<
    GetCardIssuerCardByIdQuery['getCardIssuerCardById'],
    'allRequests' | 'hasAccountingData' | 'isInvoiceNeeded'
  >;
}

export const useCreditCardCarouselNavigation = ({
  cardId,
  cards,
  organizationSlug,
}: CreditCardCarouselNavigationProps): NavigationResult => {
  const firstCard = cards?.[0];

  const cardHolderCards = cards?.reduce<DetailsProps[]>(
    (acc, currentValue, cardIndex) => {
      return [
        ...acc,
        {
          cardIndex,
          cursor: currentValue?.cursor ?? firstCard?.cursor,
          cardDetails: currentValue.node ?? firstCard?.node,
        },
      ];
    },
    []
  );

  const card = cardHolderCards?.find(itm => itm.cardDetails.id === cardId);

  const currentCardIndex = card?.cardIndex ?? 0;

  const {
    nextCardEdge,
    prevCardEdge,
    loading: isLoadingNavigationData,
  } = useCreditCardDetails({
    cursor: card?.cursor,
  });

  const nextCardId = nextCardEdge?.node?.id ?? '';
  const nextCardLink = nextCardEdge
    ? generateCreditCardLink({
        cardId: nextCardId,
        organizationSlug,
      })
    : undefined;

  const prevCardId = prevCardEdge?.node?.id ?? '';
  const prevCardLink = prevCardEdge
    ? generateCreditCardLink({
        cardId: prevCardId,
        organizationSlug,
      })
    : undefined;

  return {
    nextCardLink,
    prevCardLink,
    nextCardId,
    prevCardId,
    currentCardIndex,
    isLoadingNavigationData,
    currentCard: card?.cardDetails,
  };
};

export const usePrefetchQueries = (
  prevCardId: string | undefined,
  nextCardId: string | undefined
) => {
  const client = useApolloClient();

  useEffect(() => {
    const idleCallback = requestIdleCallback(() => {
      if (prevCardId) {
        void client.query({
          query,
          variables: {
            id: prevCardId,
          },
        });
      }

      if (nextCardId) {
        void client.query({
          query,
          variables: {
            id: nextCardId,
          },
        });
      }
    });

    return () => cancelIdleCallback(idleCallback);
  }, [client, prevCardId, nextCardId]);
};
