import { Box, Grid, ScrollBox } from '@candisio/design-system';
import { Add } from 'components/Insights/Widgets/Add/Add';
import { SumContainer } from 'components/Insights/Widgets/Sum/SumContainer';
import { InsightsWidget } from 'generated-types/graphql.types';
import { forwardRef } from 'react';
import { VirtuosoGrid, GridComponents } from 'react-virtuoso';
import { HelpSection } from './components/HelpSection';

// Ensure that this stays out of the component,
// Otherwise the grid will remount with each render due to new component instances.
const gridComponents: GridComponents = {
  /** @ts-expect-error TODO: React upgrade props types mismatch */
  Scroller: forwardRef(({ ...props }, ref) => (
    <ScrollBox ref={ref} {...props} />
  )),
  Footer: () => (
    <Box paddingTop="space8">
      <HelpSection />
    </Box>
  ),
  /** @ts-expect-error TODO: React upgrade props types mismatch */
  List: forwardRef(({ style, children, ...props }, ref) => (
    <Grid
      paddingBottom="space8"
      style={{
        ...style,
      }}
      ref={ref}
      gap="space16"
      templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
      autoRows="auto-fill 1fr"
      {...props}
    >
      {children}
    </Grid>
  )),
  /** @ts-expect-error TODO: React upgrade props types mismatch */
  Item: ({ children, ...props }) => <Box {...props}>{children}</Box>,
};

interface VirtualizedGridListProps {
  secondarySectionWidgets: InsightsWidget[];
  onAddWidget: () => void;
  hasFreeSpace: boolean;
}

export const VirtualizedGridList = ({
  secondarySectionWidgets,
  onAddWidget,
  hasFreeSpace,
}: VirtualizedGridListProps) => {
  const dataWithAddMore = ['add', ...secondarySectionWidgets];

  return (
    <VirtuosoGrid
      data={hasFreeSpace ? dataWithAddMore : secondarySectionWidgets}
      components={gridComponents}
      itemContent={(_, content) => {
        if (typeof content === 'string') {
          return <Add onAddWidget={onAddWidget} />;
        }

        return <SumContainer {...content} />;
      }}
    />
  );
};
