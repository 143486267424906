import {
  CustomEmptyStateProps,
  EmptyStateLayout,
  Flex,
  Link,
  Text,
} from '@candisio/design-system';
import { LottieAnimation, noresultsData } from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const TableCustomEmptyState = () => {
  const [t] = useTranslation();

  return (
    <Flex
      paddingY="space64"
      justifyContent="center"
      alignItems="center"
      position="sticky"
      left={0}
    >
      <Flex width="250px" direction="column">
        <LottieAnimation options={{ animationData: noresultsData }} />
        <Text color="gray500" textAlign="center">
          {t('document.table.noResults')}
        </Text>
      </Flex>
    </Flex>
  );
};

export const ExportTableCustomEmptyState = ({
  isTableFiltered,
  resetFilters,
}: CustomEmptyStateProps & { isTableFiltered: boolean }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  return (
    <EmptyStateLayout isFiltered={isTableFiltered} resetFilters={resetFilters}>
      <Text textAlign="center" whiteSpace="pre-wrap">
        {isTableFiltered
          ? t('emptyState.content.filtered')
          : t('emptyState.content.empty')}
      </Text>
      {isTableFiltered && (
        <Link as="button" onClick={resetFilters}>
          {t('emptyState.resetFilters')}
        </Link>
      )}
    </EmptyStateLayout>
  );
};
