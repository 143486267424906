import { Card, Flex, Paragraph, Link } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import MoveWidgetIcon from '../../images/MoveWidgetIcon.svg';

export const HelpSection = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  return (
    <Card
      fontSize="small"
      borderRadius="small"
      background="bluebg"
      gridColumn="1/-1"
      boxShadow="noShadow"
    >
      <Flex direction="row" gap="space4">
        <img src={MoveWidgetIcon} alt="niente" height="80px"></img>
        <Flex direction="column" justifyContent="start">
          <Paragraph>
            {t('allWidgets.secondarySection.helpSection.paragraph')}
          </Paragraph>
          <Link
            external
            href={t(
              'allWidgets.secondarySection.helpSection.helpCenterArticle.url'
            )}
          >
            {t(
              'allWidgets.secondarySection.helpSection.helpCenterArticle.text'
            )}
          </Link>
        </Flex>
      </Flex>
    </Card>
  );
};
