import { Card, Flex, Grid, Heading, Skeleton } from '@candisio/design-system';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { noop } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const TeamLoading = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <DrawerLayout
      onClose={noop}
      header={<Heading as="h3">{t('teams.editTeam.title')}</Heading>}
      children={
        <Grid height="100%" gap="space24">
          <Card padding="0px" border="1px solid gray250">
            <Grid templateRows="1fr space80">
              <Flex
                direction="column"
                padding="space16"
                alignItems="start"
                gap="space8"
              >
                <Grid gap="space16">
                  <Skeleton height="space20" width="space128" />
                  <Skeleton height="space20" width="space128" />
                  <Skeleton height="space20" width="space128" />
                </Grid>
              </Flex>

              <Flex background="gray100" padding="space16" gap="space16">
                <Skeleton height="space20" width="space40" />
                <Skeleton height="space20" width="space40" />
              </Flex>
            </Grid>
          </Card>
        </Grid>
      }
    />
  );
};
