import { Flex, InfoPanel as InfoPanelDS, Link } from '@candisio/design-system';
import { ReactElement } from 'react';

export const InfoPanelPromo = ({
  message,
  linkText,
  onClick,
  ...restProps
}: {
  message?: ReactElement;
  linkText: string;
  onClick?: () => void;
}) => {
  return (
    <InfoPanelDS variant="promo" {...restProps}>
      <Flex
        direction="column"
        alignItems="start"
        gap={message ? 'space4' : undefined}
      >
        {message}
        <Link
          as="button"
          color="purple"
          onClick={onClick}
          data-cy="promoInfoPanelCtaButton"
        >
          {linkText}
        </Link>
      </Flex>
    </InfoPanelDS>
  );
};
