import { PromotionModal } from 'components/NewPromotions/PromotionCard/PromotionModal/PromotionModal';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { PromotionDetailsModalContent } from 'components/NewPromotions/PromotionCard/PromotionDetailsModalContent/PromotionDetailsModalContent';
import { PromotionLabel } from 'components/NewPromotions/PromotionCard/PromotionHeader/PromotionHeader';
import { useGetCustomPromotionDetails } from 'components/ProductPromotions/hooks/useGetCustomPromotionDetails';

interface BDSPromotionModalProps {
  utmSource?: string;
  isOpen: boolean;
  close: () => void;
}

export const BDSPromotionModal = ({
  utmSource,
  isOpen,
  close,
}: BDSPromotionModalProps) => {
  const {
    provisionsPromotion: { label, id },
  } = usePromotionContent();

  const customPromotionDetails = useGetCustomPromotionDetails(
    'datevSelfBooker',
    utmSource
  );

  return (
    <PromotionModal
      isModalOpen={isOpen}
      closeModal={close}
      titleInfo={<PromotionLabel label={label} />}
      data-cy={`promotionModal-${id}`}
    >
      <PromotionDetailsModalContent promotionDetails={customPromotionDetails} />
    </PromotionModal>
  );
};
