import {
  Grid,
  Text,
  TruncatedText,
  Icon,
  useTheme,
} from '@candisio/design-system';
import {
  DocumentCurrency,
  ReimbursementItemStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ReimbursementItemListLeftSection } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { useHover } from 'react-aria';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';

interface ReimbursementItemProps {
  reimbursementItem: ReimbursementItemListLeftSection;
  onClick: () => void;
}

export const mappedTypesToItems: Record<
  ReimbursementItemType,
  {
    placeholder: string;
    icon: ({ backgroundColor }: { backgroundColor?: string }) => ReactNode;
  }
> = {
  GENERAL_EXPENSE: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.general',
    icon: ({ backgroundColor = 'green100' }) => (
      <Grid
        background={backgroundColor}
        placeContent="center"
        borderRadius="small"
        padding="space4"
      >
        <Icon icon="receipt" size="space18" />
      </Grid>
    ),
  },
  HOSPITALITY_EXPENSE: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.hospitality',
    icon: ({ backgroundColor = 'pink100' }) => (
      <Grid
        background={backgroundColor}
        placeContent="center"
        borderRadius="small"
        paddingLeft="6px"
        padding="space4"
      >
        <Icon icon="hospitality" size="space18" />
      </Grid>
    ),
  },
  MILEAGE: {
    placeholder: '',
    icon: () => <></>,
  },
  PER_DIEM: {
    placeholder: '',
    icon: () => <></>,
  },
};

export const ReimbursementItem = ({
  reimbursementItem,
  onClick,
}: ReimbursementItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { visibleItemId } = useReimbursementFormsContext();
  const { documentMoneyFormatter } = useMoneyFormatter();
  const { colors } = useTheme();
  const { isHovered, hoverProps } = useHover({});

  const { id, type, reason, totalAmount, status } = reimbursementItem;

  const item = mappedTypesToItems[type];

  const text = reason ? reason : t(item.placeholder);

  const amount = totalAmount
    ? totalAmount
    : documentMoneyFormatter({
        precision: 2,
        amount: 0,
        currency: DocumentCurrency.Eur,
      });

  const isItemVisible = visibleItemId === id;

  const Icon = item.icon;

  return (
    <Grid
      {...hoverProps}
      padding="space8 space16"
      templateColumns="auto 1fr"
      cursor="pointer"
      alignItems="center"
      borderRadius="small"
      onClick={onClick}
      color={isItemVisible || isHovered ? colors.blue600 : 'gray800'}
      background={isItemVisible ? colors.bluebg : 'gray50'}
      hover={{ background: colors.bluebg }}
    >
      <Grid templateColumns="auto 1fr" alignItems="center" gap="space8">
        <Icon
          backgroundColor={
            isItemVisible || isHovered ? colors.bluebg : undefined
          }
        />
        <TruncatedText fontSize="basic" fontWeight="medium">
          {text}
        </TruncatedText>
      </Grid>
      <Text
        justifySelf="right"
        fontSize="basic"
        textDecoration={
          status === ReimbursementItemStatus.Excluded
            ? 'line-through'
            : undefined
        }
      >
        {amount}
      </Text>
    </Grid>
  );
};
