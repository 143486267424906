import { Tag, TagProps } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { AvatarStatus } from './AvatarWithStatus';

const absenceToTagPropsMap: Record<AvatarStatus, TagProps> = {
  AWAY: {
    color: 'red',
    variant: 'secondary',
  },
  AWAY_SOON: {
    color: 'yellow',
    variant: 'secondary',
  },
};

export interface AbsenceTagProps {
  status: AvatarStatus;
}

export const AbsenceTag = ({ status }: AbsenceTagProps) => {
  const [t] = useTranslation();
  const tagProps = absenceToTagPropsMap[status];

  return <Tag {...tagProps}>{t(`absence.tooltip.status.${status}`)}</Tag>;
};
