import { getYearsToConsider } from '../utils/getYearsToConsider';

interface MappedYear {
  placeholder: string;
  key: `year${number}`;
  labelTag: string;
}

export const getYears = () => {
  const { years } = getYearsToConsider({ currentDate: new Date() });

  const mappedYears: MappedYear[] = years.map(year => ({
    placeholder:
      'dashboard.createNewWidget.tabs.yearBudgetForm.budgetField.placeHolder',
    key: `year${year}`,
    labelTag: year.toString(),
  }));

  return mappedYears;
};

export const getYearsFields = () => {
  return getYears().map(year => year.key);
};
type Year = MappedYear['key'];

export interface FormAnnualBudget {
  [key: Year]: number;
}
