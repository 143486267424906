import {
  Flex,
  Tooltip,
  TruncatedText,
  useTooltip,
  Text,
  Checkbox,
  Tag,
} from '@candisio/design-system';
import { BuiltinRoleName } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export interface HandleChangeParams {
  isChecked: boolean;
  switchValue: string;
  switchName: string;
}

interface RoleSwitchProps {
  label: string;
  value: string;
  name: string;
  description: string | undefined;
  isToggled: boolean;
  isReadOnly: boolean;
  readOnlyReason?: string;
  onChange: (params: HandleChangeParams) => void;
}

export const RoleCheckbox = ({
  label,
  value,
  isToggled,
  name,
  isReadOnly,
  readOnlyReason,
  onChange,
  description,
}: RoleSwitchProps) => {
  const readOnlyTooltip = useTooltip({ placement: 'left' });
  const [t] = useTranslation();

  return (
    <Flex padding="space16" justifyContent="space-between" alignItems="center">
      <Flex gap="space8" alignItems="center">
        {/** There is a type mistmatchment between the props coming from the tooltip and the prop from the switch thus the next line for expecting an error*/}
        {/**@ts-expect-error */}
        <Checkbox
          {...readOnlyTooltip.triggerProps}
          ref={readOnlyTooltip.triggerRef}
          isDisabled={isReadOnly}
          isReadOnly={isReadOnly}
          value={value}
          name={name}
          aria-label={name}
          isSelected={isToggled}
          onChange={isChecked =>
            onChange({ isChecked, switchValue: value, switchName: name })
          }
        >
          <Flex gap="space8">
            <TruncatedText fontSize="basic">{label}</TruncatedText>
            {name === BuiltinRoleName.Accountant && (
              <Tag color="blue" callout={true}>
                {t('tag.new')}
              </Tag>
            )}
          </Flex>
          <Text fontSize="basic" color="gray500">
            {description}
          </Text>
        </Checkbox>
      </Flex>
      {readOnlyTooltip.isOpen && isReadOnly && readOnlyReason && (
        <Tooltip
          {...readOnlyTooltip.tooltipProps}
          ref={readOnlyTooltip.tooltipRef}
        >
          {readOnlyReason}
        </Tooltip>
      )}
    </Flex>
  );
};
