import { Card, Flex, Grid, Link, Text } from '@candisio/design-system';
import {
  New_DatevSettings,
  useUpdateDatevProvisionsLedgerMutation,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { refetchDatevData } from '../../../../Integrations/DATEV/gql';
import { OtherAssetsAccountNumber } from './OtherAssetsAccountNumber';
import { ProvisionAccountNumber } from './ProvisionsAccountNumber';

export const DatevProvisionsAccountsForm = ({
  datevSettings,
  setDisplayEditAccountMode,
}: {
  datevSettings?: Omit<New_DatevSettings, 'isFiscalYearConfigured'> | null;
  setDisplayEditAccountMode: (value: boolean) => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const {
    provisionAccountNumber: initialProvisionAccountNumber,
    otherAssetsAccountNumber: initialOtherAssetsAccountNumber,
  } = datevSettings?.provisionsLedger || {};

  const [updateDatevProvisionsLedger] =
    useUpdateDatevProvisionsLedgerMutation();

  const onUpdateDatevProvisionsLedger = (
    provisionAccountNumber?: string,
    otherAssetsAccountNumber?: string
  ) => {
    void updateDatevProvisionsLedger({
      variables: { provisionAccountNumber, otherAssetsAccountNumber },
      refetchQueries: refetchDatevData,
    });
  };

  const onSubmit = ({
    provisionAccountNumber,
  }: {
    provisionAccountNumber: string | undefined;
  }) => {
    setDisplayEditAccountMode(true);

    return onUpdateDatevProvisionsLedger(provisionAccountNumber, undefined);
  };

  const onSubmitOtherAccountNumber = ({
    otherAssetsAccountNumber,
  }: {
    otherAssetsAccountNumber: string | undefined;
  }) => {
    setDisplayEditAccountMode(true);

    return onUpdateDatevProvisionsLedger(undefined, otherAssetsAccountNumber);
  };

  return (
    <Grid
      rowGap="space24"
      aria-label={t('provisions.setupCard.accountsFormTitle')}
    >
      <Grid autoFlow="column" height="184px">
        <Card boxShadow="noShadow" padding="space24">
          <Grid gap="space14">
            <Grid gap="space2">
              <Text fontWeight="400" fontSize="xlarge" lineHeight="heading">
                {t('provisions.setupCard.accountsFormTitle')}
              </Text>
              <Link external href={t('provisions.setupCard.linkUrl')}>
                <Text fontWeight="semibold" fontSize="14px">
                  {t('provisions.setupCard.linkText')}
                </Text>
              </Link>
            </Grid>
            <Flex gap="space16" direction="row">
              <ProvisionAccountNumber
                onSubmit={onSubmit}
                provisionAccountNumber={initialProvisionAccountNumber ?? ''}
              />
              <OtherAssetsAccountNumber
                onSubmit={onSubmitOtherAccountNumber}
                otherAssetsAccountNumber={initialOtherAssetsAccountNumber ?? ''}
              />
            </Flex>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
