import { ImportPreviewTable } from 'components/ImportPreviewTable/ImportPreviewTable';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { WizardModal } from 'components/WizardModal/WizardModal';
import {
  useImportPaymentConditionsDryRunQuery,
  useImportPaymentConditionsMutation,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentConditionCellValue } from '../types';
import { importPaymentConditions } from './importPaymentConditions';
import { PaymentConditionsImportFooter } from './PaymentConditionsImportFooter';
import { PaymentConditionsImportForm } from './PaymentConditionsImportForm';
import { PaymentConditionsData, PaymentConditionsQueryParams } from './types';
import { usePaymentConditionsImportFileUpload } from './usePaymentConditionsImportFileUpload';

export interface PaymentConditionsImportTableData {
  id: string;
  number?: number;
  condition: PaymentConditionCellValue;
  status: 'new' | 'updated' | 'invalid';
}

const WIZARD_STEPS = { select_file: 0, preview: 1 };
type WizardStep = keyof typeof WIZARD_STEPS;

export interface PaymentConditionsImportProps {
  /** Called when import modal should close */
  onClose: () => void;
}

export const PaymentConditionsImportContainer = ({
  onClose,
}: PaymentConditionsImportProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { success, error } = useToastMessage();
  const [wizardStep, setWizardStep] = useState<WizardStep>('select_file');
  const [importing, setImporting] = useState(false);
  const [data, setData] = useState<PaymentConditionsData>({
    parsedPaymentConditions: [],
    file: null,
  });

  const paymentConditionsQueryParams: PaymentConditionsQueryParams =
    data.parsedPaymentConditions
      .filter(({ dueType }) => dueType === 1)
      .map(paymentCondition => ({
        discountOffset: paymentCondition.discountOffset,
        dueDateOffset: paymentCondition.dueDateOffset,
        discountPercentage: paymentCondition.discountPercentage,
        paymentConditionNumber: paymentCondition.paymentConditionNumber,
      }));

  const { data: dryRunData, loading } = useImportPaymentConditionsDryRunQuery({
    variables: { input: paymentConditionsQueryParams },
  });

  const { upload } = usePaymentConditionsImportFileUpload({ file: data.file });
  const [importPaymentConditionsMutation] =
    useImportPaymentConditionsMutation();

  const handleImport = async () => {
    setImporting(true);

    try {
      const { successCount } = await importPaymentConditions({
        importPaymentConditionsMutation,
        paymentConditionsQueryParams,
        upload,
      });

      onClose();
      success(
        t('paymentConditions.import.importSuccess', {
          count: successCount,
        })
      );
    } catch (err) {
      error(t('paymentConditions.import.importFailed'));
    } finally {
      setImporting(false);
    }
  };

  const importPreviewData = dryRunData
    ? dryRunData?.importPaymentConditionsWithUpdate.map<PaymentConditionsImportTableData>(
        (importPaymentCondition, index) => {
          // Because the backend gives us two different responses for 'PaymentCondition' and 'PaymentConditionError' we need to alias the properties (GraphQL will complain otherwise) and we need map the output a bit differently.
          if (
            importPaymentCondition.newValue.__typename === 'PaymentCondition'
          ) {
            const {
              paymentConditionNumber,
              discountOffset,
              discountPercentage,
              dueDateOffset,
            } = importPaymentCondition.newValue;

            const isUpdatable = importPaymentCondition.oldValue !== null;

            return {
              id: String(index),
              number: paymentConditionNumber,
              condition: {
                dueDateOffset: dueDateOffset,
                discountPercentage: discountPercentage ?? undefined,
                discountOffset: discountOffset ?? undefined,
              },
              status: !isUpdatable ? 'new' : 'updated',
            };
          } else {
            const {
              errorPaymentConditionNumber,
              errorDiscountOffset,
              errorDiscountPercentage,
              errorDueDateOffset,
            } = importPaymentCondition.newValue;

            return {
              id: String(index),
              number: errorPaymentConditionNumber ?? undefined,
              condition: {
                dueDateOffset: errorDueDateOffset ?? 0,
                discountPercentage: errorDiscountPercentage ?? undefined,
                discountOffset: errorDiscountOffset ?? undefined,
              },
              status: 'invalid',
            };
          }
        }
      )
    : [];

  return (
    <WizardModal
      isOpen
      currentIndex={WIZARD_STEPS[wizardStep]}
      onClose={onClose}
      steps={[
        t('paymentConditions.import.steps.selectFile'),
        t('paymentConditions.import.steps.preview'),
      ]}
      title={t('paymentConditions.import.title')}
      footer={
        wizardStep === 'preview' && (
          <PaymentConditionsImportFooter
            importing={importing}
            onGoBack={() => {
              setData({ ...data, file: null, parsedPaymentConditions: [] });
              setWizardStep('select_file');
            }}
            onImport={handleImport}
            previewTableData={importPreviewData}
          />
        )
      }
    >
      {wizardStep === 'select_file' ? (
        <PaymentConditionsImportForm
          onSubmit={({ file, parsedPaymentConditions }) => {
            setData({ ...data, file, parsedPaymentConditions });
            setWizardStep('preview');
          }}
        />
      ) : wizardStep === 'preview' ? (
        <ImportPreviewTable
          columns={['status', 'number', 'condition']}
          data={importPreviewData}
          isLoading={loading}
        />
      ) : null}
    </WizardModal>
  );
};
