import {
  Flex,
  Text,
  Skeleton,
  Grid,
  TruncatedText,
  useTooltip,
  Tooltip,
} from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { InvalidTransactionInfoBox } from 'components/Transactions/TransactionDetailsCard/InvalidTransactionInfoBox';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import {
  InvoiceAssociationStatus,
  TransactionStatus,
  useGetCardIssuerTransactionByIdQuery,
  useUnlinkTransactionFromDocumentMutation,
} from 'generated-types/graphql.types';
import { DocumentStatus } from 'generated-types/resolvers-types';
import { GQLError } from 'gql';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useUserRoles } from 'hooks/useUserRoles';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useShowError } from 'utils/error_message';
import { validationErrorsExtractor } from 'utils/forms';
import { TransactionRelatedItem } from 'views/Inbox/DocumentProcessing/components/DocumentRelations';
import { documentHistoryQuery } from 'views/queries';
import { getCardIssuerTransactionById } from 'views/TransactionAssociation/gql';
import { AutomatchedInfoBox } from './AutomatchedInfoBox';
import { CreditCardImage } from './CreditCardImage';
import { CreditCardLinksTooltip } from './CreditCardLinksTooltip/CreditCardLinksTooltip';
import { TransactionDetails } from './TransactionDetails';
import { TransactionDetailsCardContextMenu } from './TransactionDetailsCardContextMenu';
import { TransactionDetailsCardProps, dataToTransactionDetails } from './utils';

const hiddenCardRefNum = '••••';

const LOADING_TEXT_HEIGHT: number = 20;
const LOADING_TEXT_WIDTH: number = 80;

const LoadingTransactionDetailsCard = () => {
  return (
    <>
      <Skeleton height="93.7px" width="100%" />
      <Flex direction="column" gap="space4">
        <Flex justifyContent="space-between">
          {[1, 2].map(i => (
            <Skeleton
              key={i}
              height={`${LOADING_TEXT_HEIGHT}px`}
              width={`${
                i === 2 ? LOADING_TEXT_WIDTH + 10 : LOADING_TEXT_WIDTH
              }px`}
            />
          ))}
        </Flex>
        <Flex justifyContent="space-between">
          <Skeleton
            height={`${LOADING_TEXT_HEIGHT + 2}px`}
            width={`${LOADING_TEXT_WIDTH + 30}px`}
          />
          <Skeleton
            height={`${LOADING_TEXT_HEIGHT + 2}px`}
            width={`${LOADING_TEXT_WIDTH - 10}px`}
          />
        </Flex>
      </Flex>
    </>
  );
};

export const TransactionDetailsCard = ({
  loading,
  transaction,
  contextMenu,
}: TransactionDetailsCardProps) => {
  const { isOnlyApprover } = useUserRoles();
  const user = useCurrentUser();
  const creditCardsAutomatedMatchingFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsAutomatedMatching
  );

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });

  const {
    status,
    member,
    cardName,
    cardRefNum,
    localizedDeclineReason,
    invoiceAssociationStatus,
    cardId,
    cardBrand,
    cardType,
  } = transaction ?? {};

  const membershipId = member?.membershipId ?? undefined;
  const isTransactionOwner = membershipId === user?.id;

  const isTooltipHidden = isOnlyApprover && !isTransactionOwner;

  const isReversed = status === TransactionStatus.Reversed;
  const isDeclined = status === TransactionStatus.Declined;
  const isInvalidStatus = isReversed || isDeclined;

  const isTransactionAutomatched =
    invoiceAssociationStatus === InvoiceAssociationStatus.AutoMatched;

  const isAutomatchedInfoVisible =
    isTransactionAutomatched && creditCardsAutomatedMatchingFF;

  const memberFullName = `${member?.firstName} ${member?.lastName}`;

  if (loading) {
    return (
      <Flex
        gap="space14"
        padding="space16"
        borderRadius="medium"
        direction="column"
        background="gray0"
        boxShadow="noShadow"
      >
        <LoadingTransactionDetailsCard />
      </Flex>
    );
  }

  return (
    <Flex
      gap="space14"
      padding="space16"
      borderRadius="medium"
      direction="column"
      background="gray0"
      boxShadow="noShadow"
    >
      <Grid gap="space8">
        <Flex
          gap="space2"
          borderRadius="medium"
          background="gray200"
          direction="column"
          alignItems="center"
          justifyContent="center"
          paddingY="space8"
        >
          <Flex
            alignItems="center"
            paddingY="space5"
            justifyContent="center"
            width="100%"
          >
            <Flex gap="space8" alignItems="center">
              <AvatarWithStatusContainer
                flex="none"
                img={member?.avatarUrl ?? undefined}
                name={memberFullName}
                userId={member?.membershipId ?? undefined}
                size="small"
                hasTooltip={false}
              />
              <TruncatedText maxWidth="20ch">{memberFullName}</TruncatedText>
            </Flex>
            {contextMenu}
          </Flex>
          {cardName && (
            <TruncatedText maxWidth="20ch">{cardName}</TruncatedText>
          )}
          <Grid
            gap="space8"
            templateColumns="auto 1fr"
            alignItems="center"
            paddingY="space4"
          >
            <CreditCardImage cardType={cardType} cardBrand={cardBrand} />
            <Grid
              autoFlow="column"
              placeItems="center"
              gap="space8"
              {...triggerProps}
              ref={triggerRef}
            >
              <Text color="gray700" fontSize="small" fontWeight="regular">
                {cardRefNum && `${hiddenCardRefNum} ${cardRefNum}`}
              </Text>
            </Grid>
          </Grid>
        </Flex>
        <Flex direction="column" gap="space12">
          <TransactionDetails transaction={transaction} />
          {isAutomatchedInfoVisible && <AutomatchedInfoBox />}
          {isInvalidStatus && (
            <InvalidTransactionInfoBox
              status={status}
              localizedDeclineReason={localizedDeclineReason}
            />
          )}
        </Flex>
      </Grid>
      {isOpen && !isTooltipHidden && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <CreditCardLinksTooltip cardId={cardId} membershipId={membershipId} />
        </Tooltip>
      )}
    </Flex>
  );
};

export const TransactionsDetailsCardContainer = ({
  transactionId,
  linkedDocumentStatus,
  documentId,
}: {
  transactionId: string;
  documentId: string | undefined;
  linkedDocumentStatus: DocumentStatus | undefined;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();
  const showGqlError = useShowError();
  const { showDocumentRelations } = useEcm();

  const { data, loading } = useGetCardIssuerTransactionByIdQuery({
    variables: { id: transactionId },
  });

  const { isOnlyApprover } = useUserRoles();

  const transaction = data?.getCardIssuerTransactionById;

  const [
    unlinkTransactionFromDocumentMutation,
    { loading: isUnlinkingLoading },
  ] = useUnlinkTransactionFromDocumentMutation();

  const counterQueries = useCounterQueries();

  const unlinkTransaction = async () => {
    try {
      if (!documentId) {
        return;
      }

      const { data, errors } = await unlinkTransactionFromDocumentMutation({
        variables: { transactionId, documentId },
        refetchQueries: [
          ...counterQueries,
          {
            query: getCardIssuerTransactionById,
            variables: { id: transactionId },
          },
          { query: documentHistoryQuery, variables: { id: documentId } },
        ],
      });

      if (data?.unlinkTransactionFromDocument) {
        success(t('transactionDetailsCardContextMenu.successMessage'));
      } else if (errors?.length) {
        error(t('transactionDetailsCardContextMenu.errorMessage'));
      }
    } catch (err) {
      showGqlError(err as GQLError);

      return validationErrorsExtractor(err);
    }
  };

  const isDocumentUnlinkable = linkedDocumentStatus
    ? [
        DocumentStatus.New,
        DocumentStatus.Open,
        DocumentStatus.Approved,
        DocumentStatus.Rejected,
      ].includes(linkedDocumentStatus)
    : false;

  const isTransactionAutomatched =
    transaction?.invoiceAssociationStatus ===
    InvoiceAssociationStatus.AutoMatched;

  const isDocumentUnlikableForApprover =
    linkedDocumentStatus === DocumentStatus.New ||
    linkedDocumentStatus === DocumentStatus.Rejected;

  const canApproverUnlink =
    isOnlyApprover &&
    isDocumentUnlikableForApprover &&
    isTransactionAutomatched;

  const isTransactionUnlinkable = !isOnlyApprover
    ? isDocumentUnlinkable
    : canApproverUnlink;

  const hasContextMenu =
    isTransactionUnlinkable && documentId && linkedDocumentStatus;

  const transactionDetails = dataToTransactionDetails(transaction);

  if (showDocumentRelations && transactionDetails) {
    return (
      <Grid borderRadius="medium" overflow="hidden">
        <TransactionRelatedItem
          documentId={documentId}
          transaction={transactionDetails}
          documentStatus={linkedDocumentStatus}
        />
      </Grid>
    );
  }

  return (
    <TransactionDetailsCard
      loading={loading}
      transaction={transactionDetails}
      contextMenu={
        hasContextMenu && (
          <TransactionDetailsCardContextMenu
            documentStatus={linkedDocumentStatus}
            onUnlinkTransaction={unlinkTransaction}
            isUnlinkPending={isUnlinkingLoading}
          />
        )
      }
    />
  );
};
