import { ProjectCodeSortField } from 'generated-types/graphql.types';

export enum PROJECT_CODE_SEARCH_PARAM {
  code = 'code',
  archived = 'archived',
  name = 'name',
  sort = 'sort',
}

export enum SortBy {
  alphabetically = 'alphabetically',
  id = 'id',
  lastAdded = 'lastAdded',
}
export const sortByMap = {
  [SortBy.id]: ProjectCodeSortField.Code,
  [SortBy.alphabetically]: ProjectCodeSortField.Name,
  [SortBy.lastAdded]: ProjectCodeSortField.CreatedAt,
};
