import { Box } from '@candisio/design-system';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

interface ActionsContainerProps {
  hidden?: { y: string };
  duration?: number;
  children: ReactNode;
}

export const ActionsContainer = ({
  hidden = { y: '100%' },
  duration,
  children,
}: ActionsContainerProps) => {
  return (
    <motion.div
      initial={hidden}
      animate={{ y: 0 }}
      exit={hidden}
      transition={{ duration: duration ?? 0.3 }}
    >
      <Box
        textAlign="center"
        zIndex={2}
        padding="space8"
        background="white"
        boxShadow="elevatedShadow1"
        borderTopLeftRadius="medium"
        borderTopRightRadius="medium"
      >
        {children}
      </Box>
    </motion.div>
  );
};
