import { Button, Flex, Grid, Image, Link, Text } from '@candisio/design-system';
import bannerImageCreditCardSetup from 'components/MobileAppPromotionBanner/media/mobile-app-promotion-banner-credit-cards.png';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useAnalytics } from 'providers/AnalyticsProvider';
import {
  AdoptionFeatures,
  TrackingEvents,
} from 'providers/AnalyticsProvider/events';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MobileAppPromotionModal } from 'views/AppContainer/components/Header/components/MobileAppPromotionModal/MobileAppPromotionModal';

interface MobileAppPromotionBannerProps {
  qrCode: string;
  bannerHeight?: string;
  imageWidth?: string;
  imageHeight?: string;
  localStorageKey: string;
  trackingContext: string;
  fontSize?: 'small' | 'basic';
}

export const MobileAppPromotionBanner = ({
  qrCode,
  bannerHeight,
  imageWidth,
  imageHeight,
  localStorageKey,
  trackingContext,
  fontSize = 'small',
}: MobileAppPromotionBannerProps) => {
  const [t] = useTranslation();

  const [wasClosed, setWasClosed] = useLocalStorage(
    `${localStorageKey}-closed`,
    false
  );

  const { track } = useAnalytics();

  const [mobileAppPromotionModalVisible, setMobileAppPromotionModalVisible] =
    useState(false);

  if (wasClosed) return null;

  return (
    <>
      <Flex
        borderRadius="8px"
        background="gray50"
        padding="0"
        height={bannerHeight}
      >
        <Flex justifyContent="center" paddingLeft="space16">
          <Image
            height={imageHeight}
            width={imageWidth}
            alignSelf="flex-end"
            alt={t('mobileAppPromotion.dashboard.banner.backgroundImageAlt')}
            src={bannerImageCreditCardSetup}
          />
          <Flex
            direction="column"
            gap="space4"
            fontSize="basic"
            paddingRight={fontSize === 'small' ? 'space16' : 'space32'}
            padding="space16"
            justifyContent="center"
          >
            <Text
              color="gray800"
              fontSize={fontSize}
              style={{
                paddingRight: '20px',
              }}
            >
              {t('transactions:mobileAppPromotion.banner.description')}
            </Text>
            <Link
              icon="arrowDownCircle"
              iconPosition="right"
              fontSize={fontSize}
              onClick={() => {
                setMobileAppPromotionModalVisible(true);
                track(TrackingEvents.ADOPTION_MODAL_OPENED, {
                  feature: AdoptionFeatures.MOBILE_APP,
                });
              }}
            >
              {t('transactions:mobileAppPromotion.banner.linkLabel')}
            </Link>
          </Flex>
        </Flex>
        <Grid position="absolute" top="space8" right="space8" gap="space4">
          <Button
            label={t('common.close')}
            icon="close"
            size="medium"
            variant="tertiary"
            height="max-content"
            onClick={() => {
              setWasClosed(true);
            }}
          />
        </Grid>
      </Flex>
      <MobileAppPromotionModal
        qrCode={qrCode}
        visible={mobileAppPromotionModalVisible}
        onCancel={() => setMobileAppPromotionModalVisible(false)}
      />
    </>
  );
};
