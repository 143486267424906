import { Grid } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { nonEmptyString } from 'utils/zodFormValidation/Schemas/nonEmptyString';
import { z } from 'zod';

export interface OtpFormProps {
  formId: string;
  onSubmit: (formValues: OtpFormValues) => Promise<{ isVerified: boolean }>;
  deviceName: string;
}

const otpSchema = z.object({
  deviceName: nonEmptyString,
  otpCode: nonEmptyString,
});

type OtpFormValues = z.infer<typeof otpSchema>;

export const OtpForm = ({ formId, deviceName, onSubmit }: OtpFormProps) => {
  const [t] = useTranslation();

  const form = useForm<OtpFormValues>({
    defaultValues: {
      deviceName,
      otpCode: undefined,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: zodResolver({
      zodSchema: otpSchema,
      errorMessages: {
        deviceName: {
          label: 'header.profile.tabs.update.form.deviceName.label',
        },
        otpCode: {
          label: 'header.profile.tabs.update.form.otpCode.label',
        },
      },
    }),
  });

  const handleSubmit = async (formValues: OtpFormValues) => {
    const { isVerified } = await onSubmit(formValues);

    if (!isVerified) {
      form.setError('otpCode', {
        message: t('header.profile.tabs.update.form.otpCode.errorInvalidCode'),
        type: 'server',
      });
    }
  };

  return (
    <FormProvider<OtpFormValues> {...form}>
      <Grid
        as="form"
        id={formId}
        gap="space8"
        width="max-content"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <HookFormTextField<OtpFormValues>
          name="deviceName"
          label={t('header.profile.tabs.update.form.deviceName.label')}
        />
        <HookFormTextField<OtpFormValues>
          name="otpCode"
          label={t('header.profile.tabs.update.form.otpCode.label')}
        />
      </Grid>
    </FormProvider>
  );
};
