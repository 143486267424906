import { Button, Popover, usePopover } from '@candisio/design-system';
import { LayoutProps } from '@candisio/design-system/src/types';
import { useGetPinForCardQuery } from 'generated-types/graphql.types';
import {
  usePhysicalCreditCardDispatch,
  usePhysicalCreditCardState,
} from 'providers/CreditCardsProvider/PhysicalCard/PhysicalCreditCardProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PinPopover } from './PinPopover';

export interface ShowPinButtonProps {
  isLoading: boolean;
  pinCode?: string;
  width?: LayoutProps['width'];
  variant?: 'primary' | 'secondary' | 'tertiary';
}

export const ShowPinButton = ({
  pinCode,
  isLoading,
  width,
  variant,
}: ShowPinButtonProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const { isPinVisible } = usePhysicalCreditCardState();
  const dispatch = usePhysicalCreditCardDispatch();

  const { isOpen, open, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'top' });

  useEffect(() => {
    if (isPinVisible) {
      open();
    }

    return () => {
      if (isPinVisible) {
        dispatch({ isPinVisible: false });
      }
    };
  }, [isPinVisible, open, dispatch]);

  return (
    <>
      <Button
        size="small"
        variant={variant ?? 'secondary'}
        icon={isOpen ? 'viewOutline' : 'view'}
        onClick={open}
        {...triggerProps}
        ref={triggerRef}
        width={width ?? undefined}
      >
        {isOpen
          ? t('carousel.showPinPopover.hidePinCTA')
          : t('carousel.showPin')}
      </Button>
      {isOpen && (
        <Popover {...popoverProps} ref={popoverRef}>
          <PinPopover isLoading={isLoading} pinCode={pinCode} />
        </Popover>
      )}
    </>
  );
};

export const ShowPinButtonContainer = ({
  cardId,
  width,
  variant,
}: {
  cardId: string;
  width?: LayoutProps['width'];
  variant?: 'primary' | 'secondary' | 'tertiary';
}) => {
  const { data, loading: isLoadingPin } = useGetPinForCardQuery({
    variables: { cardId },
  });

  const pinCode = data?.getPINForCard;

  return (
    <ShowPinButton
      pinCode={pinCode}
      isLoading={isLoadingPin}
      width={width}
      variant={variant}
    />
  );
};
