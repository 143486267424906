import { Button, Flex, Grid, useTheme } from '@candisio/design-system';
import { ImportHistoryItem } from 'components/HistoryList/HistoryList';
import { motion } from 'framer-motion';
import { isNil } from 'lodash';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileData } from '../FileData';
import { ImportStatus } from '../ImportStatus';
import { StatusIcon } from '../StatusIcon';

interface ErrorImportHistorySapProps {
  item: ImportHistoryItem;
  queryString: string;
}

const MotionButton = motion(Button);
const MotionGrid = motion(Grid);

export const ErrorImportHistorySap = ({
  item,
  queryString,
}: ErrorImportHistorySapProps): ReactElement => {
  const [t] = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const { colors, space } = useTheme();
  const hasErrorFile = !isNil(item.errorFileUrl);

  return (
    <MotionGrid
      gap="space16"
      height="57px"
      padding="space8 space20"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      templateColumns={`${space.space18} auto auto`}
    >
      <StatusIcon hasErrorFile={hasErrorFile} />

      <Grid
        color="gray800"
        fontSize="basic"
        fontWeight="semibold"
        justifySelf="start"
        lineHeight="paragraph"
        gap="space4"
      >
        <ImportStatus item={item} />
        <FileData item={item} isImportHistory />
      </Grid>

      {isHovered && (
        <Flex gap="space16" justifyContent="end">
          {hasErrorFile && (
            <MotionButton
              as="a"
              icon="arrowDown"
              iconPosition="left"
              variant="secondary"
              // TODO: fix this hack that ensures that the button text stays white on hover
              whileHover={{ color: colors.gray800 }}
              // TODO: removed error file name from query if not needed in hook
              href={item.errorFileUrl}
            >
              {t('importHistoryList.downloadErrorCta.error')}
            </MotionButton>
          )}
        </Flex>
      )}
    </MotionGrid>
  );
};
