import { Button, Flex, Grid } from '@candisio/design-system';
import {
  ArchiveButton,
  ReActivateRow,
} from 'components/ArchiveWrapper/ArchiveWrapper';
import { CostCenter, Maybe } from 'generated-types/graphql.types';
import { CostCenterTypes } from 'generated-types/resolvers-types';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { ApproversField } from 'views/Settings/CostCenters/components/CostCenterForm/toolkit/fields/ApproversField';
import { CodeField } from 'views/Settings/CostCenters/components/CostCenterForm/toolkit/fields/CodeField';
import { NameField } from 'views/Settings/CostCenters/components/CostCenterForm/toolkit/fields/NameField';
import { TypeField } from 'views/Settings/CostCenters/components/CostCenterForm/toolkit/fields/TypeField';
import { useCostCenterSchema } from 'views/Settings/CostCenters/components/CostCenterForm/toolkit/hooks/useCostCenterSchema';

export interface CostCenterFormProps {
  updateStatusLoading: boolean;
  onStatusChange: (isArchived: boolean) => void;
  onSubmit: <T extends CostCenterFormData>(formData: T) => Promise<void>;
  costCenter?: CostCenter;
}

type CostCenterFormData = {
  type: CostCenterTypes;
  code?: string;
  name: Maybe<string>;
  approvers: {
    value: string;
    avatarUrl: Maybe<string>;
    text: string;
    key: string;
    label: JSX.Element;
  }[];
};

export const CostCenterForm = ({
  onSubmit,
  updateStatusLoading,
  onStatusChange,
  costCenter,
}: CostCenterFormProps) => {
  const { isActiveIntegration: shouldUseSapCostCenters } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();
  const [t] = useTranslation();
  const { schema: zodSchema, errorMessages } = useCostCenterSchema(
    costCenter?.code
  );

  const {
    code,
    type = CostCenterTypes.CostCenter,
    name,
    id,
    isArchived,
    approverIds,
  } = costCenter ?? {};

  const form = useForm<any>({
    defaultValues: {
      code,
      type,
      name,
      approvers: approverIds || [],
    },
    mode: 'onBlur',
    resolver: zodResolver({
      zodSchema,
      errorMessages,
      translationNamespace: LOCALE_NAME_SPACE.COMMON,
    }),
    shouldFocusError: true,
  });

  const handleSubmit = form.handleSubmit(async values => {
    await onSubmit({
      ...values,
      approvers: values.approvers.map((approverId: string) => ({
        key: approverId,
      })),
    });
  });

  const areFieldsDisabled = Boolean(
    shouldUseSapCostCenters || shouldUseCoreDataApi || isArchived
  );

  const isTypeDisabled = areFieldsDisabled || Boolean(id);
  const isArchiveButtonDisplayed =
    Boolean(id) && !shouldUseSapCostCenters && !shouldUseCoreDataApi;

  const isReactiveRowDisplayed =
    id && isArchived && !shouldUseSapCostCenters && !shouldUseCoreDataApi;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          void handleSubmit(e);
        }}
      >
        <Flex
          justifyContent="space-between"
          direction="column"
          height="calc(100vh - 120px)"
        >
          {isReactiveRowDisplayed && (
            <Grid paddingBottom="space16">
              <ReActivateRow
                onRestore={() => onStatusChange(false)}
                isSubmitting={updateStatusLoading}
                disabled={updateStatusLoading}
              />
            </Grid>
          )}
          <Grid flexGrow={1}>
            <Flex direction="column" gap="space16">
              <TypeField disabled={isTypeDisabled} />
              <CodeField disabled={areFieldsDisabled} />
              <NameField disabled={areFieldsDisabled} />
              <ApproversField disabled={Boolean(isArchived)} />
            </Flex>
          </Grid>
          {!isArchived && (
            <Flex alignItems="center" justifyContent="space-between">
              <Button type="submit" data-cy="cost-center-save-button">
                {t('settings.costCenter.details.form.actions.save')}
              </Button>
              {isArchiveButtonDisplayed && (
                <ArchiveButton
                  onStatusChange={onStatusChange}
                  updateStatusLoading={updateStatusLoading}
                />
              )}
            </Flex>
          )}
        </Flex>
      </form>
    </FormProvider>
  );
};
