import { Flex, Grid, useTheme } from '@candisio/design-system';
import { Add } from 'components/Insights/Widgets/Add/Add';
import { InitialState } from 'components/Insights/Widgets/InitialState/InitialState';
import { NewInitialState } from 'components/Insights/Widgets/NewInitialState/NewInitialState';
import { Sum } from 'components/Insights/Widgets/Sum/Sum';
import { SumContainer } from 'components/Insights/Widgets/Sum/SumContainer';
import { WidgetsHeader } from 'components/Insights/Widgets/WidgetsHeader';
import { useInsightsWidgets } from 'components/Insights/hooks/useInsightsWidgets';
import { useNewDashboardFF } from 'components/NewPromotions/hooks/useNewDashboardFF';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { Routes } from 'models';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  InsightsWidgetsFiltersFromUrl,
  MAX_WIDGETS_ON_DASHBOARD,
} from './utils';

type InsightsWidgetsProps = {
  onOpenWidgetModal: () => void;
};

export const InsightsWidgets = ({
  onOpenWidgetModal,
}: InsightsWidgetsProps) => {
  const { colors } = useTheme();
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  const currentUser = useCurrentUser()!;
  const { organizationSlug } = useParams<{ organizationSlug: string }>();
  const [tCommon] = useTranslation();
  const [tDashboard] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  const { showNewDashboardPromotions } = useNewDashboardFF();

  const { dashboard, secondary, loading } = useInsightsWidgets();

  const isSecondarySectionEmpty = secondary.count === 0;
  const isDashboardSectionEmpty = dashboard.count === 0;

  const hasSpaceInDashboardSection =
    dashboard.count < MAX_WIDGETS_ON_DASHBOARD && dashboard.count > 0;

  const goToAllInsightsWidgetButtonText = isSecondarySectionEmpty
    ? tDashboard('insights.showMoreWidgetsButton')
    : tDashboard('insights.showMoreWidgetsButtonWithCounter', {
        count: secondary.count,
      });

  const filtersFromUrl = qs.parse(
    window.location.search
  ) as InsightsWidgetsFiltersFromUrl;

  if (loading) {
    return (
      <Grid gap="space16">
        <WidgetsHeader currentUser={currentUser} />
        <DashboardWidgetsSection>
          <Sum source={tCommon('loading')} name={tCommon('loading')} loading />
        </DashboardWidgetsSection>
      </Grid>
    );
  }

  const onAddWidget = () => {
    onOpenWidgetModal();
  };

  if (isDashboardSectionEmpty && !isSecondarySectionEmpty) {
    return (
      <Grid gap="space20">
        <WidgetsHeader currentUser={currentUser} />

        <DashboardWidgetsSection>
          <Add onAddWidget={onAddWidget} />
        </DashboardWidgetsSection>
        <Flex justifyContent="center" paddingBottom="space24">
          <RouterLink
            fontSize="basic"
            to={{
              pathname: `/${organizationSlug}${Routes.INSIGHTS_WIDGETS}`,
              search: qs.stringify(filtersFromUrl),
            }}
          >
            {tDashboard('insights.showMoreWidgetsButtonWithCounter', {
              count: secondary.count,
            })}
          </RouterLink>
        </Flex>
      </Grid>
    );
  }

  if (isDashboardSectionEmpty && isSecondarySectionEmpty) {
    return showNewDashboardPromotions ? (
      <NewInitialState onAddWidget={onAddWidget} />
    ) : (
      <InitialState onAddWidget={onAddWidget} />
    );
  }

  const emptyCards = showNewDashboardPromotions ? (
    <>
      {[...Array(MAX_WIDGETS_ON_DASHBOARD)]
        .splice(dashboard.count)
        .map((_, index) => (
          <Add onAddWidget={onAddWidget} key={index} />
        ))}
    </>
  ) : (
    <Add onAddWidget={onAddWidget} />
  );

  return (
    <Grid gap="space16" paddingBottom="space24">
      <WidgetsHeader currentUser={currentUser} />

      <DashboardWidgetsSection>
        {dashboard.widgets.map(props => (
          <SumContainer {...props} key={props.id} />
        ))}
        {hasSpaceInDashboardSection ? emptyCards : null}
      </DashboardWidgetsSection>
      <Flex justifyContent="center" gap="space24">
        <RouterLink
          fontSize="basic"
          style={{ color: colors.gray800 }}
          to={{
            pathname: `/${organizationSlug}${Routes.INSIGHTS_WIDGETS}`,
            search: qs.stringify(filtersFromUrl),
          }}
        >
          {goToAllInsightsWidgetButtonText}
        </RouterLink>
        {!hasSpaceInDashboardSection && isSecondarySectionEmpty && (
          <RouterLink
            fontSize="basic"
            icon="plus"
            to={{
              pathname: `/${organizationSlug}${Routes.INSIGHTS_WIDGETS}/create`,
              search: qs.stringify({
                ...filtersFromUrl,
                onDashboard: 'false',
              }),
            }}
          >
            {tDashboard('insights.createWidgetCTA')}
          </RouterLink>
        )}
      </Flex>
    </Grid>
  );
};

const DashboardWidgetsSection = ({ children }: { children: ReactNode }) => {
  const { showNewDashboardPromotions } = useNewDashboardFF();

  return showNewDashboardPromotions ? (
    <Grid columns={3} gap="space20">
      {children}
    </Grid>
  ) : (
    <Grid
      columns={2}
      gap="space20"
      padding="0 space14 space16"
      borderRadius="basic"
      background="linear-gradient(180deg, rgba(43, 26, 46, 0) -7.97%, rgba(43, 26, 46, 0.04) 100%)"
    >
      {children}
    </Grid>
  );
};
