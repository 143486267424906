import { Modal, ModalProps } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentTags } from '../../toolkit/hooks/useDocumentTags';
import { MergeTagsForm } from './Form/MergeTagsForm';

export const MergeModal = ({
  handleMergeSuccess,
  modalProps,
  tags,
}: {
  handleMergeSuccess: () => void;
  modalProps: ModalProps;
  tags: DocumentTags;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <Modal
      {...modalProps}
      width="42rem"
      title={t('documentTags.mergeModal.title')}
      closeLabel={t('common:common.close')}
    >
      <MergeTagsForm
        tags={tags}
        onCancel={modalProps?.onClose}
        onMergeSuccess={handleMergeSuccess}
      />
    </Modal>
  );
};
