import {
  Button,
  Card,
  Grid,
  Image,
  Link,
  Paragraph,
  Text,
} from '@candisio/design-system';
import invoiceCrying from 'assets/invoice-crying.svg';
import logo from 'assets/logo.svg';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const GlobalErrorFallbackPage = () => {
  const [t] = useTranslation();

  return (
    <Grid
      placeContent="center"
      placeItems="center"
      minHeight="100vh"
      gap="space40"
      padding="space40"
      background="gray200"
      translate="no"
    >
      <Link href="/">
        <img src={logo} width="65px" height="75px" alt="Candis" />
      </Link>
      <Card
        boxShadow="none"
        background="white"
        padding="space64"
        maxWidth="517px"
      >
        <Grid placeItems="center" gap="space40">
          <Image src={invoiceCrying} alt="" />
          <Grid gap="space24" justifyItems="start">
            <Text as="h1" fontSize="xxxlarge">
              {t('fallbackErrorPage.title')}
            </Text>
            <Paragraph fontSize="basic">
              <Trans i18nKey="fallbackErrorPage.body">
                We are already informed about this issue. However if it happens
                frequently, please
                <Link external href={t('fallbackErrorPage.helpcenterLink')}>
                  contact support
                </Link>
                .
              </Trans>
            </Paragraph>
            <Button as="a" href="/">
              {t('fallbackErrorPage.button')}
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
