import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useGetReimbursementById } from 'views/Reimbursement/hooks/useGetReimbursementById';

export interface FieldsOptions {
  isExcluded: {
    isVisible: boolean;
    isReadOnly: boolean;
    showInfoIsExcluded: boolean;
  };
}

export const useFieldsOptions = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();

  const { reimbursement } = useGetReimbursementById({
    reimbursementId,
  });

  const fieldOptions = {
    isExcluded: {
      isVisible: reimbursement?.status === ReimbursementCaseStatus.Review,
      isReadOnly: reimbursement?.status !== ReimbursementCaseStatus.Review,
      showInfoIsExcluded:
        reimbursement?.status !== ReimbursementCaseStatus.Review &&
        reimbursement?.status !== ReimbursementCaseStatus.Draft,
    },
  } satisfies FieldsOptions;

  return fieldOptions;
};
