import {
  DocumentCurrency,
  ReimbursementItemStatusInput,
} from 'generated-types/graphql.types';
import { getDateConverted } from 'hooks/useDateConverter';
import { formatDecimalToIntegerAmount } from 'hooks/useMoneyFormatter';
import { isNil } from 'lodash';
import { ExpenseSchemaOutput } from '../toolkit/expensesFormSchema';

export const sanitizeHospitalityFormInput = (values: ExpenseSchemaOutput) => {
  if (values.expenseType === 'hospitality') {
    return {
      fileIds: Array.isArray(values.files)
        ? values.files.map(file => file.id)
        : [],
      expenseDate: values.dayOfExpense?.length
        ? getDateConverted().dateStringToDateTimeString(values.dayOfExpense)
        : null,
      location: values.location?.length ? values.location : null,
      title: values.reason?.length ? values.reason : null,
      invoiceNumber: values.invoiceNumber?.length ? values.invoiceNumber : null,
      status: values.isExpenseExcluded
        ? ReimbursementItemStatusInput.Excluded
        : ReimbursementItemStatusInput.Included,
      externalGuests: values.externalGuests ? [values.externalGuests] : null,
      internalGuests: values.internalGuests ? [values.internalGuests] : null,
      receiptAmount: !isNil(values.receiptAmount)
        ? {
            amount: formatDecimalToIntegerAmount(values.receiptAmount),
            currency: DocumentCurrency.Eur,
            precision: 2,
          }
        : null,
      tipAmount: !isNil(values.tipAmount)
        ? {
            amount: formatDecimalToIntegerAmount(values.tipAmount),
            currency: DocumentCurrency.Eur,
            precision: 2,
          }
        : null,
    };
  }
};
