import { Grid, Icon, InlineSkeleton, Text } from '@candisio/design-system';
import {
  HookFormComboBoxField,
  HookFormComboBoxFieldProps,
} from 'components/HookFormFields/HookFormComboBoxField';
import { Key, ReactNode } from 'react';
import { FieldValues, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface HookFormWorkflowFieldItem {
  key: Key;
  children: ReactNode;
  /** @deprecated use `hasAbsenceAndNoSubstitute` instead */
  hasAbsence?: boolean;
  hasAbsenceAndNoSubstitute?: boolean;
  approvers?: string[][];
}

export interface HookFormWorkflowFieldProps<TFormValues extends FieldValues>
  extends HookFormComboBoxFieldProps<TFormValues> {
  /** List of initial combo box items */
  defaultItems?: HookFormWorkflowFieldItem[];
}

/**
 * A HookFormComboBoxField for selecting a workflow.
 *
 * Shows absence information for the currently-selected workflow, if present.
 */
export const HookFormWorkflowField = <TFormValues extends FieldValues>({
  defaultItems,
  name,
  isLoading,
  ...restProps
}: HookFormWorkflowFieldProps<TFormValues>) => {
  const [t] = useTranslation();

  const value = useWatch<TFormValues, typeof name>({ name });
  const item = defaultItems?.find(item => item.key === value);

  return (
    <Grid>
      <HookFormComboBoxField
        name={name}
        defaultItems={defaultItems}
        {...restProps}
      />
      {item?.hasAbsenceAndNoSubstitute && (
        <Grid
          gap="space4"
          templateColumns="auto 1fr"
          padding="space4 space8"
          background="bluebg"
        >
          <InlineSkeleton isLoading={isLoading}>
            <Icon color="blue700" icon="infoCircle" size="space20" />
          </InlineSkeleton>
          <InlineSkeleton isLoading={isLoading}>
            <Text color="gray600" fontSize="small" lineHeight="space20">
              {t('header.profile.tabs.update.absence.workflowAbsenceWarning')}
            </Text>
          </InlineSkeleton>
        </Grid>
      )}
    </Grid>
  );
};
