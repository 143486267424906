import { FieldErrors } from 'react-hook-form';
import {
  BookingValues,
  ReimbursementSplitBookingsFormValues,
} from '../toolkit/reimbursementSplitBookingsFormSchema';

export type BookingsErrorsSummary = {
  invalidFormCount: number;
  invalidFieldCount: number;
};

export const getBookingsErrorsSummary = (
  formErrors?: FieldErrors<ReimbursementSplitBookingsFormValues>
): BookingsErrorsSummary => {
  const defaultSummary = { invalidFormCount: 0, invalidFieldCount: 0 };
  const bookingsErrors = formErrors?.reimbursementItemBookings;

  if (!Array.isArray(bookingsErrors)) {
    return defaultSummary;
  }

  return bookingsErrors.reduce((acc, entry) => {
    // Count individual field errors in bookings
    const bookingErrorsCount =
      entry?.bookings?.reduce(
        (sum: number, booking: BookingValues) =>
          sum + (booking ? Object.keys(booking).length : 0),
        0
      ) ?? 0;

    // Add remainingAmount error if present
    const totalFieldErrors =
      bookingErrorsCount + (entry?.remainingAmount ? 1 : 0);

    return {
      invalidFormCount: acc.invalidFormCount + (totalFieldErrors > 0 ? 1 : 0),
      invalidFieldCount: acc.invalidFieldCount + totalFieldErrors,
    };
  }, defaultSummary);
};
