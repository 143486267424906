import {
  FieldContainer,
  Grid,
  Heading,
  TextField,
  Textarea,
} from '@candisio/design-system';
import {
  CardIssuerTransactionInvoiceNotNeededReason,
  GetCardIssuerTransactionByIdQuery,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { getTranslationContext } from 'orgConfig';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';

export interface ReadOnlyFormDeprecatedProps {
  isTxExported?: boolean | null;
  transaction?: GetCardIssuerTransactionByIdQuery['getCardIssuerTransactionById'];
}

export const ReadOnlyFormFromDeprecated = ({
  isTxExported,
  transaction,
}: ReadOnlyFormDeprecatedProps) => {
  const [t] = useTranslation(['common', 'transactions', 'documents-table']);

  const { transactionAmountFormatter } = useMoneyFormatter();

  const merchantName = transaction?.merchantName ?? '';

  const reason = transaction?.invoiceNotNeeded?.reason;

  const comment =
    reason === CardIssuerTransactionInvoiceNotNeededReason.Other
      ? transaction?.invoiceNotNeeded?.comment
      : undefined;

  const note = transaction?.note ?? '-';

  const reasonText = t(
    `transactions:transactionAssociation.noInvoiceNeeded.popover.options.${reason?.toLocaleLowerCase()}`
  );

  const commentText = comment ? `${reasonText} - ${comment}` : reasonText;

  const transactionDate = transaction?.transactionCreatedAt
    ? DateFormatters.compact(new Date(transaction?.transactionCreatedAt))
    : '';

  const title = isTxExported
    ? t('common:document.details.exportedAndPaid.title')
    : t('transactions:transactionAssociation.noInvoiceNeeded.title');

  return (
    <Grid gap="space5" paddingTop="space32" paddingRight="space16">
      <Heading as="h2" color="gray800">
        {title}
      </Heading>
      <Grid gap="space24">
        <Grid gap="space16">
          <TextField
            readOnly
            label={t('transactions:columns.merchantName')}
            input={{
              value: merchantName,
            }}
          />

          <TextField
            readOnly
            label={t('transactions:transactionWithoutDoc.txId')}
            input={{
              value: transaction?.id ?? '',
            }}
          />
        </Grid>

        <Grid gap="space16" columns={2}>
          <TextField
            readOnly
            label={t('transactions:transactionWithoutDoc.txDate')}
            input={{
              value: transactionDate,
            }}
          />
          <TextField
            readOnly
            label={t('transactions:transactionWithoutDoc.txAmount')}
            input={{
              value: transaction?.billingAmountForAccounting
                ? transactionAmountFormatter(
                    transaction?.billingAmountForAccounting
                  )
                : '',
            }}
          />
        </Grid>
        <FieldContainer
          readOnly
          label={t('documents-table:columns.note', {
            context: getTranslationContext(),
          })}
        >
          <Textarea value={note} />
        </FieldContainer>
        <FieldContainer
          readOnly
          label={t('transactions:transactionWithoutDoc.comment')}
        >
          <Textarea value={commentText} />
        </FieldContainer>
      </Grid>
    </Grid>
  );
};
