import React, { ReactNode } from 'react';
import { mergeProps, AriaPositionProps } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { initialsGenerator } from '../../utils/initialsGenerator';
import { nameToColorGenerator } from '../../utils/nameToColorGenerator';
import { Icon } from '../Icon';
import { Tooltip, useTooltip } from '../Tooltip';
import { AvatarCircle, AvatarCircleProps } from './AvatarCircle';

<svg
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
></svg>;

export interface AvatarProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLDivElement> {
  size?: AvatarCircleProps['size'];
  name?: string;
  img?: string;
  hasTooltip?: boolean;
  tooltipContent?: ReactNode;
  tooltipPlacement?: AriaPositionProps['placement'];
  tooltipDelay?: number;
}

/**
 * The Avatar component is used to represent a user. It displays the profile picture or alternatively their initials along with a fixed color.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-media-avatar}
 *
 * @param {Sizes} size Select from list of sizes
 * @param {string} name Name of the user
 * @param {string} img Path to profile image
 * @param {boolean} hasTooltip Selection whether tooltip is rendered (default: true)
 * @param {ReactNode} tooltipContent Content to display in tooltip (default: name)
 * @param {Placement} tooltipPlacement Set placement of Tooltip (default: undefined)
 * @param {number} tooltipDelay Specify delay for Tooltip (default: 0)
 */

export const AVATAR_CIRCLE_CLASSNAME = 'avatar-circle';

export const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(
  (
    {
      size = 'medium',
      name,
      img,
      hasTooltip: hasTooltipProp = true,
      tooltipDelay = 0,
      tooltipContent = name,
      tooltipPlacement,
      ...restProps
    },
    forwardedRef
  ) => {
    const { isOpen, triggerProps, triggerRef, tooltipProps, tooltipRef } =
      useTooltip({
        delay: tooltipDelay,
        passiveTrigger: true,
        placement: tooltipPlacement,
      });

    const hasTooltip = hasTooltipProp && name !== undefined;

    const children = img ? null : name ? (
      initialsGenerator(name)
    ) : (
      <Icon icon="user" color="gray0" size="space48" />
    );

    const backgroundColor =
      name !== undefined && img === undefined
        ? nameToColorGenerator(name)
        : undefined;

    return (
      <>
        <AvatarCircle
          className={AVATAR_CIRCLE_CLASSNAME}
          size={size}
          img={img}
          background={backgroundColor}
          {...(hasTooltip ? mergeProps(triggerProps, restProps) : restProps)}
          tabIndex={hasTooltip ? 0 : undefined}
          ref={mergeRefs([triggerRef, forwardedRef])}
        >
          {children}
        </AvatarCircle>
        {hasTooltip && isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {tooltipContent}
          </Tooltip>
        )}
      </>
    );
  }
);
