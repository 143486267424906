import {
  ReimbursementCaseSortField,
  ReimbursementCaseSortInput,
  SortDirection,
} from 'generated-types/graphql.types';
import { SortingRule } from 'react-table';
import {
  ReimbursementTableColumnKeys,
  ReimbursementTableRow,
} from '../toolkit/types';

type SortableColumns = Extract<
  ReimbursementTableColumnKeys,
  | 'status'
  | 'createdAt'
  | 'paidAt'
  | 'grossAmount'
  | 'requesterOfTheReimbursement'
  | 'title'
  | 'approver'
  | 'firstSubmittedForReviewAt'
>;

const mappedColumnAccessorsToSortInput: Record<
  SortableColumns,
  ReimbursementCaseSortField
> = {
  status: ReimbursementCaseSortField.Status,
  createdAt: ReimbursementCaseSortField.CreatedAt,
  grossAmount: ReimbursementCaseSortField.TotalAmount,
  paidAt: ReimbursementCaseSortField.PaidAt,
  requesterOfTheReimbursement:
    ReimbursementCaseSortField.TargetMembershipFullName,
  approver: ReimbursementCaseSortField.ApproverMembershipFullName,
  title: ReimbursementCaseSortField.Title,
  firstSubmittedForReviewAt: ReimbursementCaseSortField.SubmittedForReviewAt,
};

export const mapToReimbursementSortInput = (
  sortBy: SortingRule<ReimbursementTableRow>[]
): ReimbursementCaseSortInput | undefined => {
  if (sortBy.length < 1) {
    return undefined;
  }

  const [firstSort] = sortBy;

  const { id, desc } = firstSort;

  return {
    field: mappedColumnAccessorsToSortInput[id as SortableColumns],
    direction: desc ? SortDirection.Desc : SortDirection.Asc,
  };
};
