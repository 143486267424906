import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Paragraph,
  Skeleton,
} from '@candisio/design-system';

import { IbanListView } from 'components/IbanListView/IbanListView';
import { IbanListViewDrawer } from 'components/IbanListView/IbanListViewDrawer';
import { PulseIndicator } from 'components/PulseIndicator/PulseIndicator';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  IbanErrorKind,
  useCreateIbanMutation,
  useDeleteIbanMutation,
  useSetIbanAsOrgDefaultMutation,
  useUpdateIbanMutation,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { useOrganizationOwnIbansData } from 'views/Settings/PaymentsSettings/useOrganizationOwnIbansData';
import { listIbansQuery } from './queries';
import { useBankAccountURLState } from './useBankAccountURLState';

interface BankAccountsProps {
  cardDescription?: string;
  showPulseIndicator?: boolean;
}

/** Displays list of bank accounts associated with current organization */
export const BankAccounts = ({
  cardDescription = 'payments:bankAccounts.description',
  showPulseIndicator,
}: BankAccountsProps) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();

  const { ibanItems, isIbansListDataLoading } = useOrganizationOwnIbansData();

  const [createIbanMutation, { loading: creating }] = useCreateIbanMutation({
    refetchQueries: [{ query: listIbansQuery }],
  });

  const [updateIbanMutation, { loading: updating }] = useUpdateIbanMutation();
  const [deleteIbanMutation, { loading: deleting }] = useDeleteIbanMutation({
    refetchQueries: [{ query: listIbansQuery }],
  });

  const [setIbanAsOrgDefaultMutation] = useSetIbanAsOrgDefaultMutation();

  const { isOpen, openId, setOpenId } = useBankAccountURLState();

  const selectedItem =
    openId !== 'new' ? ibanItems?.find(item => item.key === openId) : undefined;

  return (
    <>
      <Card>
        <Grid gap="space16">
          <Grid gap="space8" templateColumns="1fr auto" alignItems="center">
            <Flex alignItems="center" gap="space8">
              <Heading as="h3">{t('payments:bankAccounts.title')} </Heading>

              {showPulseIndicator && <PulseIndicator />}
            </Flex>
            <Button
              icon="plus"
              variant="secondary"
              onClick={() => {
                setOpenId('new');
              }}
            >
              {t('payments:bankAccounts.createNew')}
            </Button>
          </Grid>

          <Paragraph>{t(cardDescription)}</Paragraph>

          {!isIbansListDataLoading ? (
            <IbanListView
              onAction={setOpenId}
              items={ibanItems}
              selectedItem={ibanItems?.find(item => item.isDefault)?.key}
              onSelectionChange={async key => {
                await setIbanAsOrgDefaultMutation({
                  variables: { id: key },
                  refetchQueries: [{ query: listIbansQuery }],
                });
              }}
            />
          ) : (
            <Skeleton width="100%" height="space128" />
          )}
        </Grid>
      </Card>

      <IbanListViewDrawer
        submitting={creating || updating}
        deleting={deleting}
        action={selectedItem ? 'edit' : 'create'}
        isOpen={isOpen}
        onDelete={async () => {
          if (!openId) {
            return;
          }

          await deleteIbanMutation({ variables: { id: openId } });

          setOpenId(null);
        }}
        onClose={() => {
          setOpenId(null);
        }}
        defaultValues={
          selectedItem
            ? { name: selectedItem.name, iban: selectedItem.iban }
            : undefined
        }
        onSubmit={async values => {
          if (selectedItem) {
            await updateIbanMutation({
              variables: {
                id: selectedItem.key,
                iban: values.iban,
                name: values.name,
              },
            });
          } else {
            const result = await createIbanMutation({
              variables: { name: values.name, iban: values.iban },
            });

            if (result.data?.createIban.__typename === 'Iban') {
              success(t('payments:bankAccounts.successfullyAddedIban'));
              setOpenId(null);
            } else if (result.data?.createIban.__typename === 'IbanError') {
              if (result.data?.createIban.kind === IbanErrorKind.Duplicate) {
                error(t('payments:bankAccounts.ibanAlreadyExists'));
              } else {
                error(t('payments:bankAccounts.failedToAddIban'));
              }
            }
          }
        }}
      />
    </>
  );
};
