import { MenuItem as MenuItemDS } from '@candisio/design-system';
import { KebabMenu } from 'components/KebabMenu/KebabMenu';
import { useExportCostCentersGenericMutation } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useOtherIntegration } from 'orgConfig/other';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { COST_CENTER_ROUTE_HASH } from 'views/Settings/CostCenters/consts';
import { useSap } from '../../../orgConfig/sap';

export interface CostCenterKebabMenuProps {
  organizationSlug: string;
}

export interface MenuItem extends MenuItemDS {
  showMenuItem?: boolean;
}

export const CostCenterKebabMenu = ({
  organizationSlug,
}: CostCenterKebabMenuProps) => {
  const [tCommon] = useTranslation();
  const [tSettings] = useTranslation('settings');
  const { isActiveIntegration: shouldUseSapCostCenters } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();

  const [exportCostCenters] = useExportCostCentersGenericMutation();

  const history = useHistory();
  const showImportAction = !shouldUseSapCostCenters && !shouldUseCoreDataApi;
  const showExportAction = !shouldUseSapCostCenters;

  const items: MenuItem[] = [
    {
      id: 'import',
      label: tSettings('costCenters.contextMenu.import'),
      showMenuItem: showImportAction,
      onAction: () => {
        history.push({ hash: COST_CENTER_ROUTE_HASH.import });
      },
    },
    {
      id: 'history',
      label: tSettings('costCenters.contextMenu.history'),
      showSeparator: true,
      showMenuItem: !shouldUseSapCostCenters,
      onAction: () => {
        history.push(
          `/${organizationSlug}${Routes.SETTINGS}${Routes.COST_CENTER_IMPORT_HISTORY}`
        );
      },
    },
    {
      id: 'export',
      label: tSettings('costCenters.contextMenu.exportGeneric'),
      showMenuItem: showExportAction,
      onAction: async () => {
        const response = await exportCostCenters();
        if (response.data?.exportCostCentersGeneric.url)
          window.open(response.data.exportCostCentersGeneric.url);
      },
    },
  ];

  const visibleItems = items.filter(item => item.showMenuItem !== false);

  if (visibleItems?.length === 0) {
    return null;
  }

  return (
    <KebabMenu
      items={visibleItems}
      label={tCommon('settings.costCenter.openMenu')}
    />
  );
};
