import { CustomEmptyStateProps, Flex } from '@candisio/design-system';
import { queryParameter } from 'components/Table/consts';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { useMemo } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Filters, SortingRule } from 'react-table';
import { CreditCardsTable } from '../CardManagerView/components/CreditCardsTable';
import {
  ToolbarFilter,
  toolBarFilterParam,
} from '../CardManagerView/components/CreditCardsTable/CreditCardsTableToolbar';
import { EmptyState } from '../CardManagerView/components/CreditCardsTable/EmptyState';
import { useGetCreditCardsTableConfigs } from '../CardManagerView/components/CreditCardsTable/hooks/useGetCreditCardsTableConfigs';
import { useCardIssuerCardsFilters } from '../hooks/useCardIssuerCardsFilters';
import { useCardIssuerCardsForCarholderData } from '../hooks/useCardIssuerCardsForCarholderData';
import { CreditCardsTableData } from '../types';
import { availableFilters } from '../utils/utils';

export const CreditCardsTableContainer = () => {
  const { updateSearchParam, searchParams } = useMutateSearchParams();
  const { sortBy, filters, onFilter, onSort } =
    useUrlBasedSortAndFilter<CreditCardsTableData>({
      availableFilters,
    });

  const toolbarFilter: ToolbarFilter = (searchParams.get(toolBarFilterParam) ??
    'InUse') as ToolbarFilter;

  const queryStringFilter = searchParams.get(queryParameter) ?? '';

  const { organizationSlug } = useParams<{
    organizationSlug: string;
  }>();

  const navigate = useNavigate();

  const {
    creditCardsTableData,
    loading,
    onLoadMore,
    isTableEmpty,
    isTableFiltered,
    handleDebounceSearch,
  } = useCardIssuerCardsForCarholderData({ filters, sortBy, toolbarFilter });

  const {
    isLoadingConfigs,
    isResetPending,
    availableCreditCardsColumnIds,
    configurationsTable,
    handleResetTableConfigurations,
    handleUpdateConfigurations,
  } = useGetCreditCardsTableConfigs({ filters, sortBy });

  const { filterOptions, isFilterLoading } = useCardIssuerCardsFilters();

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <EmptyState
        isTableFiltered={isTableFiltered}
        isTableEmpty={isTableEmpty}
        resetFilters={resetFilters}
      />
    ) : null;

  const handleFilter = useMemo(() => {
    return (filterVal: Filters<CreditCardsTableData>) => {
      onFilter(filterVal);
    };
  }, [onFilter]);

  const handleSort = useMemo(() => {
    return (sortVal: SortingRule<CreditCardsTableData>[]) => {
      onSort(sortVal);
    };
  }, [onSort]);

  const openDrawer = (cardId: string) => {
    const pathname = `/${organizationSlug}${Routes.CREDIT_CARDS_CARDHOLDER_OVERVIEW}/${cardId}`;
    searchParams.append('onCreditCardsView', 'true');

    navigate({
      pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <Flex overflow="hidden" direction="column" height="100%">
      <CreditCardsTable
        isLoadingConfigs={isLoadingConfigs || isResetPending}
        data={creditCardsTableData}
        key={`credit-card_table-${isFilterLoading}-${toolbarFilter}-${availableCreditCardsColumnIds.join(
          '-'
        )}`}
        loading={loading}
        defaultFilters={filters}
        filterOptions={filterOptions}
        defaultSortBy={sortBy}
        onFilter={handleFilter}
        customEmptyState={emptyState}
        onEndReached={onLoadMore}
        onSort={handleSort}
        search={queryStringFilter}
        onSearch={search => {
          handleDebounceSearch(search);
          updateSearchParam(queryParameter, search);
        }}
        minWidth="100%"
        width="max-content"
        configurationsTable={configurationsTable}
        columns={availableCreditCardsColumnIds}
        onUpdateColumnsConfigurations={handleUpdateConfigurations}
        onResetConfigurations={handleResetTableConfigurations}
        onRowClick={val => {
          openDrawer(val.id);
        }}
      />
    </Flex>
  );
};
