import { ExportableEntityType } from 'generated-types/graphql.types';
import { hasTransaction } from 'views/Integrations/Export/toolkit/utils';
import { ExportEntity } from 'views/Integrations/Export/types';

export enum DocumentDirection {
  invoices_received = 'invoices_received',
  outgoing_invoices = 'outgoing_invoices',
  others_document = 'others_document',
}

export const inOutDocumentDirections = [
  DocumentDirection.invoices_received,
  DocumentDirection.outgoing_invoices,
];

export const isSupportedInOutDocument = (document: ExportEntity) => {
  if (
    !hasTransaction(document) &&
    document.type === ExportableEntityType.Document
  ) {
    return inOutDocumentDirections.includes(
      document?.direction as DocumentDirection
    );
  }

  return true;
};

export const isSupportedOtherDocument = (document: ExportEntity) => {
  return document.documentType !== 'Kasse';
};
