import { useQuery } from '@apollo/client';
import { Maybe, Query, User } from 'generated-types/graphql.types';
import { useUserId } from 'providers/OrganizationProvider';
import { createContext, ReactNode } from 'react';
import { currentUserQuery } from './queries';

export const currentUserContext = createContext<{
  currentUser?: Maybe<User>;
}>({
  currentUser: null,
});

interface CurrentUserProviderProps {
  children: ReactNode;
}

export const CurrentUserProvider = ({ children }: CurrentUserProviderProps) => {
  const selectedUser = useUserId();

  const { data: currentUserData } = useQuery<Pick<Query, 'currentUser'>>(
    currentUserQuery,
    {
      variables: {
        id: selectedUser,
      },
      skip: !selectedUser,
    }
  );

  return (
    <currentUserContext.Provider
      value={{ currentUser: currentUserData?.currentUser }}
    >
      {children}
    </currentUserContext.Provider>
  );
};
