import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';

export const SlackRedirectComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const code = searchParams.get('code');
  const state = searchParams.get('state') ?? '';
  const organization = searchParams.get('organization');

  useEffect(() => {
    navigate(
      `/${organization}/dashboard?code=${code}&state=${state}#userProfile`
    );
  }, [code, navigate, organization, state]);

  return null;
};
