import { useApolloClient } from '@apollo/client';
import { Grid } from '@candisio/design-system';

import { collectErrorMessages } from 'components/Form/utils';
import { ImportPreviewTable } from 'components/ImportPreviewTable/ImportPreviewTable';
import { ImportPreviewTableData } from 'components/ImportPreviewTable/types';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { WizardModal } from 'components/WizardModal/WizardModal';
import { WizardModalGenerationFooter } from 'components/WizardModal/WizardModalGenerationFooter';
import { WizardModalPreviewFooter } from 'components/WizardModal/WizardModalPreviewFooter';
import { AnimateSharedLayout } from 'framer-motion';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { zodResolver } from 'utils/zodFormValidation';
import { COST_CENTER_ROUTE_HASH } from '../../consts';
import {
  getFilterOptions,
  handleResponseErrors,
  initialState,
  reducer,
} from './CostCenterImport.helper';
import {
  SelectFileFormValues,
  errorMessages,
  schema,
} from './CostCenterImport.schema';
import { InfoList } from './InfoList';
import { SelectFileForm } from './SelectFileForm';
import { costCentersGetForDuplicationCheck } from './gql';
import { ParsedCostCenter, parseCostCenterCSV } from './parseCostCenterCSV';
import { CostCenterImportProps, ImportSteps } from './types';
import { useImportCostCenters } from './useImportCostCenters';
import { findDuplicates } from './utils/countDuplicateCostCenters';
import { normalizeCostCenters } from './utils/normalizeCostCenters';

export const CostCenterImport = ({
  previewDelay = 4800,
  errorPreviewDelay = 1200,
}: CostCenterImportProps) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const [importCostCenters, { loading: importing }] = useImportCostCenters();
  const [state, updateState] = useReducer(reducer, initialState);

  const [defaultFilters, setDefaultFilters] =
    useState<{ id: string; value: string[] }[]>();

  const history = useHistory();
  const visible = history.location.hash === COST_CENTER_ROUTE_HASH.import;

  const { query } = useApolloClient();

  const {
    handleSubmit,
    reset,
    clearErrors,
    control,
    watch,
    setError,
    formState,
  } = useForm<SelectFileFormValues>({
    defaultValues: {},
    resolver: zodResolver({
      zodSchema: schema,
      errorMessages: errorMessages(t),
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
    }),
    mode: 'onSubmit',
  });

  const resetForm = () => {
    reset();
    clearErrors();
    updateState({ action: 'RESET' });
  };

  const closeModal = () => {
    resetForm();
    history.push({ hash: '' });
  };

  const { isSubmitting, isValid, errors } = formState;
  const collectedErrors = collectErrorMessages(errors);

  const onSubmit = async ({ file, costCenterType }: SelectFileFormValues) => {
    if (!file || !costCenterType || !isValid) {
      return;
    }

    const fileData = watch('file') as File;

    let parsedCostCenters: ParsedCostCenter[];
    let isDatevFormat: boolean;

    try {
      ({ parsedCostCenters, isDatevFormat } =
        await parseCostCenterCSV(fileData));
    } catch (error) {
      handleResponseErrors(error, setError, t);

      return;
    }

    // fetch duplicates from server
    const { data: duplicatesData } = await query({
      query: costCentersGetForDuplicationCheck,
      // Edge-case, but if someone tries to import cost centers from
      // the same csv file twice in a row, the second import won't show that there
      // are duplicates since the duplicatesData is cached
      fetchPolicy: 'network-only',
      variables: {
        costCenterCodes: parsedCostCenters.map(({ code }) => code),
        costCenterType,
      },
    });

    const normalizedCostCenters = normalizeCostCenters(
      parsedCostCenters,
      duplicatesData?.costCentersGetForDuplicationCheck
    );

    updateState({
      action: 'SET_COST_CENTERS',
      payload: {
        parsedCostCenters: normalizedCostCenters,
        costCenterType,
        isDatevFormat,
        selectedFile: fileData,
      },
    });

    // fake a delay
    // this should not be here. we should trigger the SHOW_PREVIEW action
    // when the animation completes using onAnimationComplete
    await new Promise(resolve => setTimeout(resolve, previewDelay));
    updateState({ action: 'SHOW_PREVIEW' });
  };

  const handleImport = async () => {
    if (state.parsedCostCenters && state.costCenterType) {
      const { successCount, errorCount } = await importCostCenters(
        state.parsedCostCenters
          .filter(cs => cs.includeInImport)
          .map(({ includeInImport, isDuplicate, key, ...cs }) => ({
            ...cs,
            type: state.costCenterType,
          })),
        state.selectedFile
      );

      closeModal();

      if (errorCount) {
        error(
          t('settings.costCenter.actions.importedFailure', {
            count: errorCount,
          })
        );
      } else {
        success(
          t('settings.costCenter.actions.importedSuccess', {
            count: successCount,
          })
        );
      }
    }
  };

  const duplicates = findDuplicates(state.parsedCostCenters);

  const showDuplicates = () => {
    updateState({
      action: 'UPDATE_FILTERS',
      payload: {
        filters: {
          code: duplicates.map(duplicate => duplicate.code),
          name: null,
        },
      },
    });

    const duplicateArray = duplicates.map(item => {
      return item.code;
    });

    setDefaultFilters([
      {
        id: 'code',
        value: duplicateArray,
      },
    ]);
  };

  const duplicateCount = duplicates.length;
  const costCenterCount = state.parsedCostCenters.length;
  const canImport = state.parsedCostCenters.some(
    ({ includeInImport }) => includeInImport
  );

  const costCentersImportData: ImportPreviewTableData[] = (
    state.parsedCostCenters ?? []
  ).map(item => {
    return {
      isDuplicate: item.isDuplicate,
      code: item.code,
      id: item.key,
      name: item.name,
    };
  });

  return (
    <WizardModal
      onClose={closeModal}
      isOpen={visible}
      steps={[
        t('settings.costCenter.wizardModal.steps.selectFile'),
        t('settings.costCenter.wizardModal.steps.preview'),
      ]}
      currentIndex={state.importStep}
      title={t('settings.costCenter.wizardModal.title')}
      footer={
        (isSubmitting ||
          collectedErrors.length > 0 ||
          state.importStep > 0) && (
          <AnimateSharedLayout>
            {state.importStep === ImportSteps.SelectFile ? (
              <WizardModalGenerationFooter
                canGoBack={!isSubmitting}
                onGoBack={resetForm}
              />
            ) : (
              <WizardModalPreviewFooter
                canImport={canImport}
                importing={importing}
                onGoBack={() => {
                  updateState({ action: 'RESET' });
                }}
                onImport={handleImport}
                infoList={
                  <InfoList
                    costCentersCount={costCenterCount}
                    costCenterType={state.costCenterType}
                    duplicateCount={duplicateCount}
                    errors={collectedErrors}
                    isDatevFormat={state.isDatevFormat}
                    onShowDuplicates={showDuplicates}
                  />
                }
              />
            )}
          </AnimateSharedLayout>
        )
      }
    >
      {state.importStep === ImportSteps.SelectFile ? (
        <Grid as="form" onSubmit={handleSubmit(onSubmit)} gap="space16">
          <SelectFileForm
            costCentersCount={costCenterCount}
            costCenterType={state.costCenterType}
            duplicateCount={duplicateCount}
            isDatevFormat={state.isDatevFormat}
            didCheckDuplicates={state.didCheckDuplicates}
            errorPreviewDelay={errorPreviewDelay}
            errors={collectedErrors}
            isSubmitting={isSubmitting}
            disabledButton={!isValid}
            control={control}
          />
        </Grid>
      ) : (
        state.importStep === ImportSteps.Preview && (
          <ImportPreviewTable
            data={costCentersImportData}
            columns={['code', 'name']}
            defaultFilters={defaultFilters}
            filterOptions={getFilterOptions(costCentersImportData)}
          />
        )
      )}
    </WizardModal>
  );
};
