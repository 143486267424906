import { Radio, RadioGroup, Text } from '@candisio/design-system';
import { CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import {
  useCreateCreditCardModalSetState,
  useCreateCreditCardModalState,
} from './CreateCreditCardModalDataProvider';
import { cardTypeList } from './utils/utils';

export const CardTypeField = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { cardType } = useCreateCreditCardModalState();
  const setModalState = useCreateCreditCardModalSetState();

  return (
    <RadioGroup
      orientation="horizontal"
      defaultValue={cardType}
      aria-label={t('ccRequestForm.limitsForm.cardType')}
      onChange={value => {
        setModalState(prevState => ({
          ...prevState,
          cardType: value as Exclude<CardType, CardType.Black>,
          cardDetails: undefined,
        }));
      }}
    >
      {cardTypeList.map(itm => (
        <Radio key={itm.id} value={itm.id}>
          <Text fontSize="basic">{t(itm.label)}</Text>
        </Radio>
      ))}
    </RadioGroup>
  );
};
