import { countClassifyingDocumentsQuery } from 'containers/conversion/gql';
import { _ListArchiveDocumentsQueryVariables } from 'generated-types/graphql.types';
import gql from 'graphql-tag';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useComputeQueryFilters } from 'views/Archive/ArchiveDocumentsTable/hooks/useComputeQueryFilters';
import {
  getGqlDateFilterFromUrl,
  getGqlFilterFromUrl,
  getGqlSearchFromUrl,
  getGqlSortFromUrl,
} from '../../components/DocumentsTable/Filters/filters';
import { getApprovalDocumentsBasicsQuery } from '../Approvals/queries';
import { getApproximateNumberOfRowForTable } from '../utils/pagination-helper';
import {
  documentListDataFragment,
  inboxInvoiceDocumentDataFragment,
  pageInfoDataFragment,
} from './fragments';

// we fetch this amount of records maximum to enable cycling through document lists
export const LIST_CYCLE_LIMIT = 300;

export const documentCommentEventData = gql`
  fragment DocumentCommentEventData on DocumentCommentEvent {
    __typename
    user {
      id
      name
    }
    clientInfo {
      name
    }
    comment {
      id
      text
      author {
        avatarUrl
      }
    }
  }
`;

export const requestApprovalEventData = gql`
  fragment RequestApprovalEventData on RequestApprovalEvent {
    user {
      id
      avatarUrl
      name
    }
    approvers {
      id
      avatarUrl
      name
      isSubstituting {
        id
        name
        avatarUrl
      }
    }
  }
`;

export const substituteAddedEventData = gql`
  fragment SubstituteAddedEventData on SubstituteAddedEvent {
    substitute {
      id
      name
      email
      avatarUrl
      isSubstituting {
        id
        name
        email
        avatarUrl
      }
    }
  }
`;

export const substituteRemovedEventData = gql`
  fragment SubstituteRemovedEventData on SubstituteRemovedEvent {
    substitute {
      id
      name
      email
      avatarUrl
      isSubstituting {
        id
        name
        email
        avatarUrl
      }
    }
  }
`;

export const fileUploadedEventData = gql`
  fragment FileUploadedEventData on FileUploadedEvent {
    user {
      id
      avatarUrl
      name
    }
    file {
      name
      url
    }
    clientInfo {
      name
    }
    # outdated
    uploaderEmail
  }
`;

export const documentResetManualEventData = gql`
  fragment DocumentResetManualEventData on DocumentResetManualEvent {
    user {
      id
      avatarUrl
      name
    }
  }
`;

export const documentResetUserDeactivationEventData = gql`
  fragment DocumentResetUserDeactivationEventData on DocumentResetUserDeactivationEvent {
    user {
      id
      avatarUrl
      name
    }
    targetUser {
      id
      name
      email
    }
  }
`;

export const documentResetUserRoleChangedEventData = gql`
  fragment DocumentResetUserRoleChangedEventData on DocumentResetUserRoleChangedEvent {
    user {
      id
      avatarUrl
      name
    }
    targetUser {
      id
      email
    }
  }
`;

export const fileUploadedByEmailEventData = gql`
  fragment FileUploadedByEmailEventData on FileUploadedByEmailEvent {
    user {
      id
      avatarUrl
      name
    }
    file {
      name
      url
    }
    uploaderEmail
  }
`;

export const approvedEventData = gql`
  fragment ApprovedEventData on ApprovedEvent {
    user {
      id
      avatarUrl
      name
    }
    clientInfo {
      name
    }
    users {
      id
      avatarUrl
      name
    }
  }
`;

export const contactSupplierEventData = gql`
  fragment ContactSupplierSendEmailEventData on ContactSupplierSendEmailEvent {
    user {
      id
      avatarUrl
      name
      __typename
    }
    emailPayload {
      subject
      textBody
      htmlBody
      recipients
      replyTo
      cc
    }
  }
`;

export const invalidEInvoiceDetectedEventData = gql`
  fragment InvalidEInvoiceDetectedEventData on InvalidEInvoiceDetectedEvent {
    eInvoiceFormat
    eInvoiceError
  }
`;

export const metaDataExtractionEventData = gql`
  fragment MetaDataExtractionEventData on MetaDataExtractionEvent {
    extractedFields {
      value
      key
    }
  }
`;

export const contactExtractionEventData = gql`
  fragment ContactExtractionEventData on ContactExtractionEvent {
    __typename
    contact {
      id
      name {
        value
      }
    }
  }
`;

export const costCenterExtractionEventData = gql`
  fragment CostCenterExtractionEventData on CostCenterExtractionEvent {
    costCenter1 {
      id
      readableName
    }
    costCenter2 {
      id
      readableName
    }
  }
`;

export const workflowTemplateAppliedToDocumentEventData = gql`
  fragment WorkflowTemplateAppliedToDocumentEventData on WorkflowTemplateAppliedToDocumentEvent {
    workflow {
      name
      description
    }
  }
`;

export const approverExtractedEventData = gql`
  fragment ApproverExtractedEventData on ApproverExtractedEvent {
    approvers {
      id
      avatarUrl
      name
    }
  }
`;

export const workflowStepSkippedEventData = gql`
  fragment WorkflowStepSkippedEventData on WorkflowStepSkippedEvent {
    users {
      id
      avatarUrl
      name
    }
    workflowStep {
      resolution {
        comment
      }
      conditions {
        field
        operator
        values
      }
    }
  }
`;

export const userApprovalDocumentWorkflowStepData = gql`
  fragment UserApprovalDocumentWorkflowStepData on UserApprovalDocumentWorkflowStep {
    user {
      id
      avatarUrl
      name
    }
  }
`;

export const rejectedEventData = gql`
  fragment RejectedEventData on RejectedEvent {
    user {
      id
      avatarUrl
      name
    }
    clientInfo {
      name
    }
    comment {
      text
    }
  }
`;

export const exportedEventData = gql`
  fragment ExportedEventData on ExportedEvent {
    exportId
    user {
      id
      avatarUrl
      name
    }
    exportType
    datevLocationParams {
      clientNumber
      consultantNumber
      documentExportGuid
    }
  }
`;

export const provisionExportedEventData = gql`
  fragment ProvisionExportedEventData on ProvisionExportedEvent {
    exportId
    user {
      id
      avatarUrl
      name
    }
    exportType
  }
`;

export const reversalExportedEventData = gql`
  fragment ReversalExportedEventData on ReversalExportedEvent {
    exportId
    user {
      id
      avatarUrl
      name
    }
    exportType
  }
`;

export const sepaTransferGeneratedEventData = gql`
  fragment SepaTransferGeneratedEventData on DocumentSepaTransferGeneratedEvent {
    paymentId
    generatedBy {
      id
      name
    }
    executionDate
  }
`;

export const documentPaymentInfoUpdatedEventData = gql`
  fragment DocumentPaymentInfoUpdatedEventData on DocumentPaymentInfoUpdatedEvent {
    updatedBy {
      id
      name
      avatarUrl
    }
    updatedPaymentFields {
      ... on UpdatedIBANField {
        iban
      }
      ... on UpdatedSwiftCodeField {
        swiftCode
      }
      ... on UpdatedCreateTransferField {
        createTransfer
      }
      ... on UpdatedPaymentStateField {
        paymentState {
          isPaid
          paidAt
        }
      }
    }
  }
`;

export const documentResetByCandisEventData = gql`
  fragment DocumentResetByCandisEventData on DocumentResetByCandis {
    reason
  }
`;

export const fileAttachedToDocumentEventData = gql`
  fragment FileAttachedToDocumentEventData on FileAttachedToDocumentEvent {
    id
    createdAt
    sinceDetachedOrDeleted
    originalDocumentId
    fromEInvoice
    file {
      name
      url
      id
    }
    user {
      id
      avatarUrl
      name
    }
  }
`;

export const splitBookingUpdatedEventData = gql`
  fragment SplitBookingUpdatedEventData on SplitBookingUpdatedEvent {
    id
    createdAt
    user {
      id
      name
      email
      avatarUrl
    }
    bookings {
      number
      action
      fields {
        key
        value
      }
    }
  }
`;

export const singleBookingUpdatedEventData = gql`
  fragment SingleBookingUpdatedEventData on SingleBookingUpdatedEvent {
    id
    createdAt
    user {
      id
      name
      email
      avatarUrl
    }
    fields {
      key
      value
    }
  }
`;

export const attachmentDeletedFromDocumentEventData = gql`
  fragment AttachmentDeletedFromDocumentEventData on AttachmentDeletedFromDocumentEvent {
    id
    createdAt
    fileName
    user {
      id
      avatarUrl
      name
    }
  }
`;

export const attachmentDetachedFromDocumentEventData = gql`
  fragment AttachmentDetachedFromDocumentEventData on AttachmentDetachedFromDocumentEvent {
    id
    createdAt
    fileName
    user {
      id
      avatarUrl
      name
    }
  }
`;

export const documentConvertedToAttachmentEventData = gql`
  fragment DocumentConvertedToAttachmentEventData on DocumentConvertedToAttachmentEvent {
    id
    createdAt
    target {
      fileName
    }
    user {
      id
      avatarUrl
      name
    }
  }
`;

export const attachmentConvertedToDocumentEventData = gql`
  fragment AttachmentConvertedToDocumentEventData on AttachmentConvertedToDocumentEvent {
    id
    createdAt
    source {
      fileName
    }
    user {
      id
      avatarUrl
      name
    }
  }
`;

export const documentFileReplacedEventData = gql`
  fragment DocumentFileReplacedEventData on DocumentFileReplacedEvent {
    id
    createdAt
    oldFile {
      name
      url
    }
  }
`;

export const documentTransactionLinkedEventData = gql`
  fragment DocumentTransactionLinkedEventData on DocumentTransactionLinkedEvent {
    id
    createdAt
    merchantName
    amount {
      amount
      currency
    }
    transactionDate
    user {
      id
      name
      email
      avatarUrl
    }
    receiptAutomatchingTaskId
  }
`;

export const documentTransactionCommentCreatedEventData = gql`
  fragment DocumentTransactionCommentCreatedEventData on DocumentTransactionCommentCreatedEvent {
    id
    createdAt
    comment {
      id
      text
      author {
        avatarUrl
      }
    }
    user {
      id
      name
      email
      avatarUrl
    }
  }
`;

export const documentTransactionUnlinkedEventData = gql`
  fragment DocumentTransactionUnlinkedEventData on DocumentTransactionUnlinkedEvent {
    id
    createdAt
    merchantName
    amount {
      amount
      currency
    }
    transactionDate
    clientInfo {
      name
    }
    user {
      id
      name
      email
      avatarUrl
    }
  }
`;

export const documentTransactionReconciledEventData = gql`
  fragment DocumentTransactionReconciledEventData on DocumentTransactionReconciledEvent {
    id
    createdAt
    properties {
      amount {
        currency
        value
        precision
      }
      merchantName
      transactionCreatedAt
    }
    user {
      name
      email
      avatarUrl
    }
  }
`;

export const documentTransactionStatusUpdatedEventData = gql`
  fragment DocumentTransactionStatusUpdatedEventData on DocumentTransactionStatusUpdatedEvent {
    id
    createdAt
    user {
      id
      name
      email
      avatarUrl
    }
    oldStatus
    newStatus
  }
`;

export const documentResetTransactionUnLinkedEventData = gql`
  fragment DocumentResetTransactionUnLinkedEventData on DocumentResetTransactionUnLinkedEvent {
    id
    user {
      id
      name
      avatarUrl
    }
  }
`;

export const documentResetTransactionLinkedEventData = gql`
  fragment DocumentResetTransactionLinkedEventData on DocumentResetTransactionLinkedEvent {
    id
    user {
      id
      name
      avatarUrl
    }
  }
`;

export const documentResetTransactionAmountUpdatedEventData = gql`
  fragment DocumentResetTransactionAmountUpdatedEventData on DocumentResetTransactionAmountUpdatedEvent {
    id
    user {
      id
      name
      avatarUrl
    }
  }
`;

export const documentImportedByMigrationEventData = gql`
  fragment DocumentImportedByMigrationEventData on DocumentImportedByMigrationEvent {
    user {
      id
      avatarUrl
      name
    }
    file {
      name
      url
    }
    importedFromSystem
  }
`;

const purchaseLinkData = gql`
  fragment PurchaseLinkData on PurchaseOrderLinkedEvent {
    id
    orderNumber
    purchaseOrderId
    createdAt
    user {
      id
      name
      avatarUrl
    }
  }
`;

const purchaseUnlinkData = gql`
  fragment PurchaseUnlinkData on PurchaseOrderUnlinkedEvent {
    id
    orderNumber
    purchaseOrderId
    createdAt
    user {
      id
      name
      avatarUrl
    }
  }
`;

export const RelatedDocumentActivityFragment = gql`
  fragment RelatedDocumentActivity on RelatedDocument {
    id
    type
    document {
      ... on Document {
        invoiceNumber {
          value
        }
        contact {
          value {
            id
            name {
              value
            }
          }
        }
        documentFile {
          name
        }
      }
      ... on AggregatedEcmDocument {
        documentName
      }
    }
  }
`;

export const DocumentRelationshipCreatedEventData = gql`
  fragment DocumentRelationshipCreatedEventData on DocumentRelationshipCreatedEvent {
    id
    createdAt
    user {
      id
      name
      avatarUrl
    }
    linkedDocuments {
      ...RelatedDocumentActivity
    }
  }
  ${RelatedDocumentActivityFragment}
`;

export const DocumentRelationshipRemovedEventData = gql`
  fragment DocumentRelationshipRemovedEventData on DocumentRelationshipRemovedEvent {
    id
    createdAt
    user {
      id
      name
      avatarUrl
    }
    unlinkedDocument {
      ...RelatedDocumentActivity
    }
  }
  ${RelatedDocumentActivityFragment}
`;

export const documentTagUpdatedEventData = gql`
  fragment DocumentTagUpdatedEventData on DocumentTagUpdatedEvent {
    id
    createdAt
    user {
      id
      name
      avatarUrl
    }
    tags {
      id
      name
      description
    }
  }
`;

export const documentGrossNetTaxRoundingAmountChangedEventData = gql`
  fragment DocumentGrossNetTaxRoundingAmountChangedEventData on DocumentGrossNetTaxRoundingAmountChangedEvent {
    id
    createdAt
    user {
      id
      name
      avatarUrl
    }
    documentGrossAmount
    documentNetAmount
    documentTaxAmount
    documentRoundingAmount
  }
`;
export const documentAssociationsCreatedEventData = gql`
  fragment DocumentAssociationsCreatedEventData on DocumentAssociationsCreatedEvent {
    id
    createdAt
    user {
      id
      avatarUrl
      name
    }
    associatedPurchaseOrders {
      purchaseOrderId
      orderNumber
      associatedGoodsReceipts {
        goodsReceiptId
        receiptNumber
      }
    }
  }
`;
export const documentAssociationsRemovedEventData = gql`
  fragment DocumentAssociationsRemovedEventData on DocumentAssociationsRemovedEvent {
    id
    createdAt
    user {
      id
      avatarUrl
      name
    }
    disconnectedPurchaseOrders {
      purchaseOrderId
      orderNumber
      associatedGoodsReceipts {
        goodsReceiptId
        receiptNumber
      }
    }
  }
`;

export const accountingAreaUpdatedEventData = gql`
  fragment AccountingAreaUpdatedEventData on AccountingAreaUpdatedEvent {
    id
    createdAt
    clientInfo {
      name
    }
    user {
      id
      name
      avatarUrl
    }
    accountingAreaName
    accountingAreaId
  }
`;

export const DocumentStoredAsEcmDocumentEventData = gql`
  fragment DocumentStoredAsEcmDocumentEventData on DocumentStoredAsEcmDocumentEvent {
    id
    createdAt
    user {
      id
      name
      email
      avatarUrl
    }
    amount {
      amount
      currency
      precision
    }
    contact {
      id
      name {
        value
      }
    }
    costCenter {
      readableName
    }
    documentDate
    documentName
    documentNumber
    documentType
    endDate
    notes
    notifyPerson {
      primary {
        name
      }
    }
    responsiblePerson {
      primary {
        name
      }
    }
    startDate
    documentSubCategory {
      id
      name
    }
    terminationDate
    terminationReminderDate
  }
`;

export const DocumentUpdatedAsEcmDocumentEventData = gql`
  fragment DocumentUpdatedAsEcmDocumentEventData on DocumentUpdatedAsEcmDocumentEvent {
    id
    createdAt
    user {
      id
      name
      email
      avatarUrl
    }
    amount {
      amount
      currency
      precision
    }
    contact {
      id
      name {
        value
      }
    }
    costCenter {
      readableName
    }
    documentDate
    documentName
    documentNumber
    documentType
    endDate
    notes
    notifyPerson {
      primary {
        name
      }
    }
    responsiblePerson {
      primary {
        name
      }
    }
    startDate
    documentSubCategory {
      id
      name
    }
    terminationDate
    terminationReminderDate
    updatedFields
  }
`;

export const DocumentConvertedEventData = gql`
  fragment DocumentConvertedEventData on DocumentConvertedEvent {
    id
    createdAt
    clientInfo {
      name
    }
    user {
      id
      name
      avatarUrl
    }
    documentType
    convertedFrom
  }
`;

/**
 * The fragments are defined separately to generate separate types for each one
 *
 * NOTE: Please add any new document events to getEcmDocumentHistoryQuery as well.
 *       Thank you!
 */
export const documentHistoryQuery = gql`
  query getDocumentHistory($id: ID!) {
    getDocument(id: $id) {
      id
      status
      timeline {
        __typename
        id
        createdAt
        ...ApprovedEventData
        ...ApproverExtractedEventData
        ...AttachmentConvertedToDocumentEventData
        ...AttachmentDeletedFromDocumentEventData
        ...AttachmentDetachedFromDocumentEventData
        ...ContactExtractionEventData
        ...CostCenterExtractionEventData
        ...DocumentCommentEventData
        ...DocumentConvertedToAttachmentEventData
        ...DocumentFileReplacedEventData
        ...DocumentPaymentInfoUpdatedEventData
        ...DocumentResetByCandisEventData
        ...DocumentResetManualEventData
        ...DocumentResetTransactionAmountUpdatedEventData
        ...DocumentResetTransactionLinkedEventData
        ...DocumentResetTransactionUnLinkedEventData
        ...DocumentResetUserDeactivationEventData
        ...DocumentResetUserRoleChangedEventData
        ...DocumentTransactionCommentCreatedEventData
        ...DocumentTransactionLinkedEventData
        ...DocumentTransactionStatusUpdatedEventData
        ...DocumentTransactionReconciledEventData
        ...DocumentTransactionUnlinkedEventData
        ...ExportedEventData
        ...FileAttachedToDocumentEventData
        ...FileUploadedByEmailEventData
        ...FileUploadedEventData
        ...InvalidEInvoiceDetectedEventData
        ...MetaDataExtractionEventData
        ...ProvisionExportedEventData
        ...ReversalExportedEventData
        ...RejectedEventData
        ...RequestApprovalEventData
        ...SepaTransferGeneratedEventData
        ...SingleBookingUpdatedEventData
        ...SplitBookingUpdatedEventData
        ...UserApprovalDocumentWorkflowStepData
        ...WorkflowStepSkippedEventData
        ...WorkflowTemplateAppliedToDocumentEventData
        ...ContactSupplierSendEmailEventData
        ...DocumentImportedByMigrationEventData
        ...SubstituteAddedEventData
        ...SubstituteRemovedEventData
        ...PurchaseLinkData
        ...PurchaseUnlinkData
        ...DocumentRelationshipCreatedEventData
        ...DocumentRelationshipRemovedEventData
        ...DocumentTagUpdatedEventData
        ...DocumentGrossNetTaxRoundingAmountChangedEventData
        ...AccountingAreaUpdatedEventData
        ...DocumentAssociationsCreatedEventData
        ...DocumentAssociationsRemovedEventData
        ...DocumentStoredAsEcmDocumentEventData
        ...DocumentUpdatedAsEcmDocumentEventData
        ...DocumentConvertedEventData
      }
    }
  }
  ${approvedEventData}
  ${contactSupplierEventData}
  ${approverExtractedEventData}
  ${attachmentConvertedToDocumentEventData}
  ${attachmentDeletedFromDocumentEventData}
  ${attachmentDetachedFromDocumentEventData}
  ${contactExtractionEventData}
  ${costCenterExtractionEventData}
  ${documentCommentEventData}
  ${documentConvertedToAttachmentEventData}
  ${documentFileReplacedEventData}
  ${documentPaymentInfoUpdatedEventData}
  ${documentResetByCandisEventData}
  ${documentResetManualEventData}
  ${documentResetTransactionAmountUpdatedEventData}
  ${documentResetTransactionLinkedEventData}
  ${documentResetTransactionUnLinkedEventData}
  ${documentResetUserDeactivationEventData}
  ${documentResetUserRoleChangedEventData}
  ${documentTransactionCommentCreatedEventData}
  ${documentTransactionLinkedEventData}
  ${documentTransactionStatusUpdatedEventData}
  ${documentTransactionReconciledEventData}
  ${documentTransactionUnlinkedEventData}
  ${exportedEventData}
  ${fileAttachedToDocumentEventData}
  ${fileUploadedByEmailEventData}
  ${fileUploadedEventData}
  ${invalidEInvoiceDetectedEventData}
  ${metaDataExtractionEventData}
  ${provisionExportedEventData}
  ${reversalExportedEventData}
  ${rejectedEventData}
  ${requestApprovalEventData}
  ${sepaTransferGeneratedEventData}
  ${singleBookingUpdatedEventData}
  ${splitBookingUpdatedEventData}
  ${userApprovalDocumentWorkflowStepData}
  ${workflowStepSkippedEventData}
  ${workflowTemplateAppliedToDocumentEventData}
  ${documentImportedByMigrationEventData}
  ${substituteAddedEventData}
  ${substituteRemovedEventData}
  ${purchaseLinkData}
  ${purchaseUnlinkData}
  ${DocumentRelationshipCreatedEventData}
  ${DocumentRelationshipRemovedEventData}
  ${documentTagUpdatedEventData}
  ${documentGrossNetTaxRoundingAmountChangedEventData}
  ${accountingAreaUpdatedEventData}
  ${documentAssociationsCreatedEventData}
  ${documentAssociationsRemovedEventData}
  ${DocumentStoredAsEcmDocumentEventData}
  ${DocumentUpdatedAsEcmDocumentEventData}
  ${DocumentConvertedEventData}
`;

export const getInboxDocumentsQuery = gql`
  query listInboxDocuments(
    $limit: Int
    $after: String
    $filters: DocumentFilterInput
    $sort: SortInput
    $withPurchaseOrderData: Boolean = false
    $query: String
  ) {
    listInboxDocuments(
      limit: $limit
      after: $after
      filters: $filters
      sort: $sort
      query: $query
    )
      @connection(
        key: "inboxDocumentsPagination"
        filter: ["filters", "sort", "query"]
      ) {
      edges {
        cursor
        node {
          isDuplicate
          ...DocumentListData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${documentListDataFragment}
`;

export const getInboxInvoiceDocumentsQuery = gql`
  query getInboxInvoiceDocuments(
    $limit: Int
    $after: String
    $filters: InboxInvoiceDocumentsFilterInput
    $sort: SortInput
    $withPurchaseOrderData: Boolean = false
    $query: String
    $searchInput: SearchInput
  ) {
    inboxInvoiceDocuments(
      limit: $limit
      after: $after
      filters: $filters
      sort: $sort
      query: $query
      searchInput: $searchInput
    )
      @connection(
        key: "inboxDocumentsPaginationV2"
        filter: ["filters", "sort", "query", "searchInput"]
      ) {
      edges {
        cursor
        node {
          ...InboxInvoiceDocumentData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${inboxInvoiceDocumentDataFragment}
`;

export const getExportDocumentsCountQuery = gql`
  query listExportDocumentsCount {
    listExportDocuments {
      count
    }
  }
`;

export enum List {
  INBOX_TABLE = 'INBOX_TABLE',
  INBOX = 'INBOX',
  APPROVAL = 'APPROVAL',
  ARCHIVE = 'ARCHIVE',
  EXPORT = 'EXPORT',
}

export const computePaginationVariables = () => {
  const pageSize = getApproximateNumberOfRowForTable();
  const gqlFilters = getGqlFilterFromUrl(window.location.search);
  const gqlSort = getGqlSortFromUrl(window.location.search);
  const gqlDateFilters = getGqlDateFilterFromUrl(window.location.search);
  const gqlQuery = getGqlSearchFromUrl(window.location.search);

  return {
    limit: pageSize,
    filters: gqlFilters,
    dateRangeFilters: gqlDateFilters,
    ...(gqlSort ? { sort: gqlSort } : {}),
    ...(gqlQuery || {}),
  } satisfies _ListArchiveDocumentsQueryVariables;
};

// Our AntD table and our React table map the filters in the URL differenly so we need to use the respective helper functions for computing the filters.
// When the AntD table is removed from the Inbox and Approval view we can remove this utility and use only the custom hook below it.

export const getRefetchListQueries = (...lists: List[]) => {
  const queries: any[] = [];

  if (lists.includes(List.APPROVAL)) {
    queries.push({
      query: getApprovalDocumentsBasicsQuery,
      variables: { ...computePaginationVariables(), limit: LIST_CYCLE_LIMIT },
    });
  }

  if (lists.includes(List.INBOX_TABLE)) {
    queries.push({
      query: getInboxDocumentsQuery,
      variables: computePaginationVariables(),
    });
  }

  return queries;
};

export const useGetRefetchListQueries = (...lists: List[]) => {
  const [llmClassificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.llmClassification,
  ]);
  const queryOptions = useComputeQueryFilters();

  const queries = [];
  if (lists.includes(List.APPROVAL)) {
    queries.push({
      query: getApprovalDocumentsBasicsQuery,
      variables: { ...computePaginationVariables(), limit: LIST_CYCLE_LIMIT },
    });
  }

  if (lists.includes(List.INBOX_TABLE)) {
    queries.push({
      query: getInboxDocumentsQuery,
      variables: queryOptions,
    });
  }

  if (llmClassificationFF)
    queries.push({
      query: countClassifyingDocumentsQuery,
    });

  return queries;
};
