import {
  Card,
  Grid,
  Image,
  Box,
  Button,
  Spinner,
} from '@candisio/design-system';
import { HookFormComboBoxField } from 'components/HookFormFields/HookFormComboBoxField';
import { HookFormNumberField } from 'components/HookFormFields/HookFormNumberField';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { CreditCardContainer } from 'containers/credit-cards/CreditCard';
import { useGetCardById } from 'containers/credit-cards/utils';
import {
  CardStatus,
  TransactionStatus,
  GenerateTestTransactionsType,
  useGenerateTestTransactionsMutation,
  GenerateTestTransactionsCurrency,
} from 'generated-types/graphql.types';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessages, zodResolver } from 'utils/zodFormValidation';
import creditCards from 'views/CreditCards/CreditCardsInsights/media/mini-cards.svg';
import { useAvailableCardsForLinkingRecurringPayments } from 'views/CreditCards/hooks/useAvailableCardsForLinkingRecurringPayments';
import { z } from 'zod';
import { TransactionsActivationHint } from './containers/TransactionsActivationHint';
import {
  currencies,
  transactionStatuses,
  transactionTypes,
} from './formOptions';

export const testTransactionSchema = () =>
  z.object({
    cardId: z.string().nonempty(),
    numberOfTransactions: z.number().min(0).max(100),
    merchantName: z.string().optional(),
    status: z.nativeEnum(TransactionStatus).optional(),
    type: z.nativeEnum(GenerateTestTransactionsType).optional(),
    amountFrom: z.number().optional(),
    amountTo: z.number().optional(),
    currency: z.nativeEnum(GenerateTestTransactionsCurrency).optional(),
  });

export type TestTransactionOutput = z.infer<
  ReturnType<typeof testTransactionSchema>
>;

export type TestTransactionValues = Partial<TestTransactionOutput>;

export type TestTransactionFormErrorMessages = ErrorMessages<
  ReturnType<typeof testTransactionSchema>
>;

export const BatchMockTransaction = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const notActionableStates = [CardStatus.Terminated, CardStatus.Expired];

  const { isOnboardingNeeded, isOnboardingStatusLoading } =
    useCreditCardsSetup();

  //TO-DO: use generic hook to get all cards available
  const {
    comboBoxOptions,
    handleDebounceSearch,
    loadMore,
    loading: loadingAvailableCards,
  } = useAvailableCardsForLinkingRecurringPayments();

  const form = useForm<TestTransactionOutput>({
    mode: 'onTouched',
    defaultValues: {
      numberOfTransactions: 1,
    },
    resolver: zodResolver({
      zodSchema: testTransactionSchema,
      errorMessages: {
        cardId: { label: 'Card' },
        numberOfTransactions: { label: 'Number of trasnactions' },
        merchantName: { label: 'Merchant name' },
        status: { label: 'Transaction status' },
        type: { label: 'Transaction type' },
        amountFrom: { label: 'From amount' },
        amountTo: { label: 'To amount' },
        currency: { label: 'Currency' },
      },
    }),
  });

  const [generateTestTransactions, { loading: loadingMutation }] =
    useGenerateTestTransactionsMutation();

  const selectedCardId = form.getValues('cardId');
  const { card } = useGetCardById({ cardId: selectedCardId });

  const onSubmit = async (values: TestTransactionValues) => {
    const { cardId } = values;
    if (!cardId) return;

    await generateTestTransactions({
      variables: {
        input: { cardId, ...values },
      },
    });
  };

  if (isOnboardingStatusLoading) {
    return (
      <Grid height={400} placeContent="center">
        <Spinner size="space16" color="gray500" />
      </Grid>
    );
  }

  if (isOnboardingNeeded) {
    return <TransactionsActivationHint />;
  }

  const isSubmitDisabled =
    !selectedCardId ||
    Boolean(card && notActionableStates.includes(card?.status));

  return (
    <FormProvider {...form}>
      <Grid
        placeContent="start"
        gap="space40"
        paddingY="space20"
        as="form"
        onSubmit={form.handleSubmit(values => onSubmit(values))}
      >
        <Card background="gray100">
          <Grid gap="space20" templateColumns="240px 1fr">
            <Grid gap="space12">
              <Grid templateColumns="auto 1fr" gap="space16">
                {selectedCardId ? (
                  <CreditCardContainer cardId={selectedCardId} />
                ) : (
                  <Image src={creditCards} alt="cards" height="280px" />
                )}
              </Grid>
            </Grid>
            <Grid rowGap="space12">
              <HookFormComboBoxField
                name="cardId"
                isVirtualized
                label="Select card"
                placeholder="No card selected"
                onEndReached={loadMore}
                loading={loadingAvailableCards}
                allowsCustomValue
                onSearch={handleDebounceSearch}
                items={comboBoxOptions}
                itemHeight="space64"
                emptyListPlaceholder={t(
                  'recurringPaymentsModal.linkCard.field.emptyList'
                )}
              />
              <Grid gap="space12">
                <HookFormNumberField
                  width="50%"
                  name="numberOfTransactions"
                  label="Number of transactions"
                  disabled={isSubmitDisabled}
                />
                <HookFormTextField
                  name="merchantName"
                  label="Merchant name"
                  disabled={isSubmitDisabled}
                />
                <HookFormSelectField
                  name="status"
                  label="Transaction status"
                  items={transactionStatuses.map(option => ({
                    ...option,
                    children: option.children,
                  }))}
                  disabled={isSubmitDisabled}
                />
                <HookFormSelectField
                  name="type"
                  label="Transaction type"
                  items={transactionTypes.map(option => ({
                    ...option,
                    children: option.children,
                  }))}
                  disabled={isSubmitDisabled}
                />
                <Grid templateColumns="1fr 1fr" gap="space12">
                  <HookFormNumberField
                    name="amountFrom"
                    label="From amount"
                    disabled={isSubmitDisabled}
                  ></HookFormNumberField>
                  <HookFormNumberField
                    name="amountTo"
                    label="To amount"
                    disabled={isSubmitDisabled}
                  ></HookFormNumberField>
                </Grid>
                <HookFormSelectField
                  name="currency"
                  label="Currency"
                  items={currencies}
                  disabled={isSubmitDisabled}
                />
                <Box>
                  <Button
                    disabled={isSubmitDisabled}
                    type="submit"
                    loading={loadingMutation}
                  >
                    Create
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </FormProvider>
  );
};
