import { Box, Paragraph, Text } from '@candisio/design-system';
import { ExportStatus } from 'generated-types/graphql.types';
import { ReactNode } from 'react';
enum Status {
  APPROVED = 'APPROVED',
  REJECTED = 'FAILED',
  EXPORTING = 'EXPORTING',
  EXPORTED = 'EXPORTED',
}
type StatusTitleProps = {
  active: boolean;
  color: string;
  children: ReactNode;
};

type StatusDescProps = {
  color: string;
  children: ReactNode;
};

export const StatusTitle = ({ color, active, children }: StatusTitleProps) => {
  return (
    <Paragraph
      color={color}
      fontSize="small"
      fontWeight={active ? 'bold' : 'normal'}
    >
      {children}
    </Paragraph>
  );
};

export const StatusDesc = ({ color, children }: StatusDescProps) => {
  return (
    <Text color={color} fontSize="xsmall">
      {children}
    </Text>
  );
};

export const ErrorDescription = ({ text }: { text: string }) => {
  return (
    <Box justifySelf="center" paddingX="space32" paddingBottom="space24">
      <Paragraph color="yellow500" fontSize="xsmall">
        {text}
      </Paragraph>
    </Box>
  );
};

export const ColorStatus: {
  [P in keyof typeof Status]: string;
} = {
  APPROVED: 'gray800',
  REJECTED: 'red500',
  EXPORTING: 'blue500',
  EXPORTED: 'yellow500',
};

export function getColors(active: boolean, status: string) {
  const titleColor =
    status === ExportStatus.Failed && active
      ? ColorStatus.REJECTED
      : active
        ? ColorStatus.EXPORTING
        : ColorStatus.APPROVED;

  const statusColor =
    status === ExportStatus.Exporting
      ? ColorStatus.EXPORTING
      : status === ExportStatus.PartialyExported
        ? ColorStatus.EXPORTED
        : status === ExportStatus.Failed
          ? ColorStatus.REJECTED
          : ColorStatus.APPROVED;

  return {
    titleColor,
    statusColor,
  };
}
