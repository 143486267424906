import { Grid } from '@candisio/design-system';
import { HookFormIbanField } from 'components/HookFormFields/HookFormIbanField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { IbanFormValues, ibanFormSchema } from './ibanFormSchema';

export interface IbanFormProps {
  /** Unique identifier for form */
  id?: string;
  /** Initial values */
  defaultValues?: DefaultValues<IbanFormValues>;
  /** Called when submitted */
  onSubmit: (values: IbanFormValues) => void;
}

/** Form for creating/editing IBANs */
export const IbanForm = ({ id, defaultValues, onSubmit }: IbanFormProps) => {
  const [t] = useTranslation('payments');

  const form = useForm({
    defaultValues,
    resolver: zodResolver({
      zodSchema: ibanFormSchema,
      translationNamespace: 'payments',
      errorMessages: {
        name: { label: 'bankAccounts.fields.name.label' },
        iban: { label: 'bankAccounts.fields.iban.label' },
      },
    }),
    mode: 'onTouched',
  });

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        id={id}
        onSubmit={form.handleSubmit(values => {
          onSubmit(values as IbanFormValues);
        })}
        gap="space16"
      >
        <HookFormTextField
          name="name"
          label={t('bankAccounts.fields.name.label')}
        />
        <HookFormIbanField
          name="iban"
          label={t('bankAccounts.fields.iban.label')}
        />
      </Grid>
    </FormProvider>
  );
};
