import { Grid, Link, Paragraph, Text } from '@candisio/design-system';

import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ImportCardProps {
  emails: string[];
  loading: boolean;
}

export const ImportCard = ({ emails, loading }: ImportCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { success } = useToastMessage();

  const [allowProcessingOfXrechnungEinvoicesFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.allowProcessingOfXrechnungEinvoices,
  ]);

  const importWarning = allowProcessingOfXrechnungEinvoicesFF
    ? t('emailInbox.infoPanel.import.importLinks.warning')
    : t('emailInbox.infoPanel.import.importLinks.warningDeprecated');

  return (
    <InfoPanel
      title={t('emailInbox.infoPanel.import.title')}
      emailInbox={
        <Grid gap="space16">
          <Text as="h4" fontWeight="semibold">
            {t('emailInbox.infoPanel.import.importLinks.subtitle')}
          </Text>
          <Paragraph>
            {t('emailInbox.infoPanel.import.importLinks.paragraph', {
              count: emails.length,
            })}
          </Paragraph>
          <Grid gap="space4">
            {emails.map((email, index: number) => (
              <Link
                as="button"
                icon="copy"
                iconPosition="right"
                textAlign="left"
                key={index}
                onClick={async () => {
                  await navigator.clipboard.writeText(email);
                  success(
                    t(
                      'emailInbox.infoPanel.import.importLinks.successMessage',
                      {
                        email: email,
                      }
                    )
                  );
                }}
              >
                {email}
              </Link>
            ))}
          </Grid>
          <Paragraph>{importWarning}</Paragraph>
        </Grid>
      }
      sections={t('emailInbox.infoPanel.import.sections', {
        returnObjects: true,
      })}
      externalLink={t('emailInbox.infoPanel.import.externalLink', {
        returnObjects: true,
      })}
      loading={loading}
    />
  );
};
