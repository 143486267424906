import {
  Box,
  Button,
  Drawer,
  Flex,
  Heading,
  Link,
} from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  useActivateBookingAccountMutation,
  useArchiveBookingAccountMutation,
  useBookingAccountQuery,
  useCreateBookingAccountMutation,
  useUpdateBookingAccountMutation,
} from 'generated-types/graphql.types';
import { useOtherIntegration } from 'orgConfig/other';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { usePath } from 'utils/hooks';
import { useSap } from '../../../../../orgConfig/sap';
import { BOOKING_ACCOUNTS_SEARCH_PARAM } from '../../consts';
import { useBookingAccountRefetchQueries } from '../../hooks/useBookingAccountRefetchQueries';
import {
  isCreated,
  isDuplicate,
  isUpdated,
  sanitizeBookingAccountFormData,
} from '../../utils/utils';
import {
  BookingAccountChange,
  BookingAccountFormData,
} from './BookingAccountChange';

type BookingAccountRouteParams = {
  generalLedgerAccountId?: string;
  organizationSlug: string;
};

export const BookingAccountDetails = () => {
  const { organizationSlug, generalLedgerAccountId } =
    useParams<BookingAccountRouteParams>();

  const history = useHistory();
  const { search } = useLocation();
  const [t] = useTranslation(LOCALE_NAME_SPACE.BOOKING_ACCOUNTS);
  const [tCommon] = useTranslation();
  const { success, error, dismiss } = useToastMessage();
  const { isActiveIntegration: shouldUseSapGL } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();

  const isCreateAction =
    generalLedgerAccountId === 'create' || !generalLedgerAccountId;

  const { url } = usePath<BookingAccountRouteParams>({
    generalLedgerAccountId: undefined,
    organizationSlug,
  });

  const { url: bookingAccountIdUrl } = usePath<BookingAccountRouteParams>({
    generalLedgerAccountId,
    organizationSlug,
  });

  const { refetchQueries, resetPaginationResults } =
    useBookingAccountRefetchQueries();

  const [createBookingAccount] = useCreateBookingAccountMutation({
    onCompleted: resetPaginationResults,
    refetchQueries,
  });

  const [updateBookingAccount] = useUpdateBookingAccountMutation({
    onCompleted: resetPaginationResults,
    refetchQueries,
  });

  const [archiveBookingAccount, { loading: archiveLoading }] =
    useArchiveBookingAccountMutation({
      onCompleted: resetPaginationResults,
      refetchQueries,
    });

  const [activateBookingAccount, { loading: activateLoading }] =
    useActivateBookingAccountMutation({
      onCompleted: resetPaginationResults,
      refetchQueries,
    });

  const bookingAccountQuery = useBookingAccountQuery({
    variables: {
      id: generalLedgerAccountId || '',
    },
    skip: generalLedgerAccountId === 'create' || !generalLedgerAccountId,
  });

  const bookingAccount =
    bookingAccountQuery.data?.bookingAccount.__typename === 'BookingAccount'
      ? bookingAccountQuery.data?.bookingAccount
      : undefined;

  const onClose = useCallback(() => {
    history.replace({
      pathname: url,
      search,
    });
  }, [history, search, url]);

  const handleUpdate = useCallback(
    async (formData: BookingAccountFormData) => {
      const input = sanitizeBookingAccountFormData(formData);
      const result = await updateBookingAccount({
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        variables: { input: { ...input, id: generalLedgerAccountId! } },
      });

      if (isUpdated(result?.data?.updateBookingAccount)) {
        success(
          t('form.actions.toasts.updated', {
            accountNumber: input.accountCode,
          })
        );
        onClose();
      } else if (isDuplicate(result?.data?.updateBookingAccount)) {
        error(
          t('form.actions.toasts.duplicate', {
            accountNumber: input.accountCode,
          })
        );
      }
    },
    [error, generalLedgerAccountId, onClose, success, t, updateBookingAccount]
  );

  const handleCreate = useCallback(
    async (formData: BookingAccountFormData) => {
      const input = sanitizeBookingAccountFormData(formData);
      const result = await createBookingAccount({
        variables: { input },
      });

      if (isCreated(result?.data?.createBookingAccount)) {
        success(
          t('form.actions.toasts.created', {
            accountNumber: input.accountCode,
          })
        );
        onClose();
      } else if (isDuplicate(result?.data?.createBookingAccount)) {
        error(
          t('form.actions.toasts.duplicate', {
            accountNumber: input.accountCode,
          })
        );
      }
    },
    [createBookingAccount, error, onClose, success, t]
  );

  const handleStatus = useCallback(
    async (isArchived: boolean) => {
      if (bookingAccount?.id) {
        if (!isArchived) {
          await activateBookingAccount({
            variables: {
              id: bookingAccount?.id,
            },
          });
        } else {
          await archiveBookingAccount({
            variables: {
              id: bookingAccount?.id,
            },
          });
        }

        const { accountCode } = bookingAccount;

        const key = isArchived
          ? 'form.actions.toasts.archivedSuccess'
          : 'form.actions.toasts.activatedSuccess';

        const content = (
          <Trans t={t} i18nKey={key} values={{ accountNumber: accountCode }}>
            General Ledger Account
            <Link
              href={bookingAccountIdUrl}
              onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                dismiss();
                history.replace({
                  pathname: bookingAccountIdUrl,
                  search: qs.stringify({
                    [BOOKING_ACCOUNTS_SEARCH_PARAM.archive]: isArchived,
                  }),
                });
              }}
            >
              {{ accountNumber: accountCode } as any}
            </Link>
            status was changed.
          </Trans>
        );

        success(content, 7);
        onClose();
      }
    },
    [
      activateBookingAccount,
      archiveBookingAccount,
      bookingAccount,
      bookingAccountIdUrl,
      dismiss,
      history,
      onClose,
      success,
      t,
    ]
  );

  const { id, isArchived } = bookingAccount || {};

  const isArchivedBookingAccount = Boolean(id && isArchived);
  const isFormReadonly =
    isArchivedBookingAccount || shouldUseSapGL || shouldUseCoreDataApi;

  const readonlyTitle = 'details.sap';
  const createModeTitle = 'details.add';
  const editModeTitle = 'details.edit';

  const titleForEditableViews = isCreateAction
    ? createModeTitle
    : editModeTitle;

  const title = isFormReadonly ? readonlyTitle : titleForEditableViews;

  return (
    <Drawer isOpen={!!generalLedgerAccountId} onClose={onClose}>
      <Flex
        borderBottom="1px solid gray300"
        alignItems="center"
        justifyContent="space-between"
        padding="space16 space40 space8"
      >
        <Heading as="h3">{t(title)}</Heading>
        <Button
          icon="close"
          label={tCommon('common.close')}
          onClick={onClose}
          zIndex={10}
          variant="tertiary"
        />
      </Flex>
      <Box paddingX="space40" paddingY="space24" height="100%">
        <BookingAccountChange
          loading={false}
          onChange={isCreateAction ? handleCreate : handleUpdate}
          onStatusChange={handleStatus}
          updateStatusLoading={archiveLoading || activateLoading}
          bookingAccount={bookingAccount}
        />
      </Box>
    </Drawer>
  );
};
