import gql from 'graphql-tag';

export const expenseItemBookingFragment = gql`
  fragment ExpenseItemBookingData on ExpenseItemBooking {
    costCenter
    {
      readableName
    }
    costObject
    {
      readableName
    }
    extraCostInfo
    {
      readableName
    }
    artistSocialInsuranceCode
    generalLedgerAccount
    {
      readableName
    }
    bookingKey
    {
      readableName
    }
    amount
    vatRate
    note
    postingText
    taxAmount
    netAmount
  }
`;

export const reimbursementCaseTitleUpdatedEventFragment = gql`
  fragment ReimbursementCaseTitleUpdatedEventData on ReimbursementCaseTitleUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldTitle
      newTitle
    }
  }
`;

export const expenseItemTitleUpdatedEventFragment = gql`
  fragment ExpenseItemTitleUpdatedEventData on ExpenseItemTitleUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldTitle
      newTitle
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseAmendmentRequestedEventFragment = gql`
  fragment ReimbursementCaseAmendmentRequestedEventData on ReimbursementCaseAmendmentRequestedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      requestedAt
      comment {
        author {
          name
        }
        id
        text
      }
    }
  }
`;

export const reimbursementCaseApprovedEventFragment = gql`
  fragment ReimbursementCaseApprovedEventData on ReimbursementCaseApprovedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      approvedAt
    }
  }
`;

export const reimbursementCaseCreatedEventFragment = gql`
  fragment ReimbursementCaseCreatedEventData on ReimbursementCaseCreatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      createdAt
    }
  }
`;

export const reimbursementCaseSubmittedForReviewEventFragment = gql`
  fragment ReimbursementCaseSubmittedForReviewEventData on ReimbursementCaseSubmittedForReviewEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      submittedAt
    }
  }
`;

export const reimbursementCaseTargetMembershipUpdatedEventFragment = gql`
  fragment ReimbursementCaseTargetMembershipUpdatedEventData on ReimbursementCaseTargetMembershipUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldTargetMembership {
        name
      }
      newTargetMembership {
        name
      }
    }
  }
`;

export const reimbursementCaseNoteUpdatedEventFragment = gql`
  fragment ReimbursementCaseNoteUpdatedEventData on ReimbursementCaseNoteUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldNote
      newNote
    }
  }
`;

export const expenseItemTotalAmountUpdatedEventFragment = gql`
  fragment ExpenseItemTotalAmountUpdatedEventData on ExpenseItemTotalAmountUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldTotalAmount
      newTotalAmount
      oldCurrency
      newCurrency
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemFileAddedEventFragment = gql`
  fragment ExpenseItemFileAddedEventData on ExpenseItemFileAddedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      fileId
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemFileRemovedEventFragment = gql`
  fragment ExpenseItemFileRemovedEventData on ExpenseItemFileRemovedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      fileId
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemExpenseDateUpdatedEventFragment = gql`
  fragment ExpenseItemExpenseDateUpdatedEventData on ExpenseItemExpenseDateUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldExpenseDate
      newExpenseDate
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseCommentAddedEventFragment = gql`
  fragment ReimbursementCaseCommentAddedEventData on ReimbursementCaseCommentAddedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      comment {
        text
      }
    }
  }
`;

export const expenseItemArchivedEventFragment = gql`
  fragment ExpenseItemArchivedEventData on ExpenseItemArchivedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      archivedAt
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemHospitalityExternalGuestsUpdatedEventFragment = gql`
  fragment ExpenseItemHospitalityExternalGuestsUpdatedEventData on ExpenseItemHospitalityExternalGuestsUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldExternalGuests
      newExternalGuests
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemHospitalityInternalGuestsUpdatedEventFragment = gql`
  fragment ExpenseItemHospitalityInternalGuestsUpdatedEventData on ExpenseItemHospitalityInternalGuestsUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldInternalGuests
      newInternalGuests
    }
    reimbursementItemMetadata {
      title
    }
  }
`;

export const expenseItemHospitalityLocationUpdatedEventFragment = gql`
  fragment ExpenseItemHospitalityLocationUpdatedEventData on ExpenseItemHospitalityLocationUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldLocation
      newLocation
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemHospitalityReceiptAmountUpdatedEventFragment = gql`
  fragment ExpenseItemHospitalityReceiptAmountUpdatedEventData on ExpenseItemHospitalityReceiptAmountUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldReceiptAmount
      newReceiptAmount
      oldCurrency
      newCurrency
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemHospitalityTipAmountUpdatedEventFragment = gql`
  fragment ExpenseItemHospitalityTipAmountUpdatedEventData on ExpenseItemHospitalityTipAmountUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldTipAmount
      newTipAmount
      oldCurrency
      newCurrency
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemBookingAddedEventFragment = gql`
  fragment ExpenseItemBookingAddedEventData on ExpenseItemBookingAddedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      currency
      booking{
        ...ExpenseItemBookingData
      }
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
  ${expenseItemBookingFragment}
`;

export const expenseItemBookingRemovedEventFragment = gql`
  fragment ExpenseItemBookingRemovedEventData on ExpenseItemBookingRemovedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      booking{
        ...ExpenseItemBookingData
      }
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
  ${expenseItemBookingFragment}
`;

export const expenseItemBookingUpdatedEventFragment = gql`
  fragment ExpenseItemBookingUpdatedEventData on ExpenseItemBookingUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      currency
      newBooking{
        ...ExpenseItemBookingData
      }
      oldBooking {
        ...ExpenseItemBookingData
      }
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
  ${expenseItemBookingFragment}
`;

export const reimbursementCaseResetApprovalEventFragment = gql`
  fragment ReimbursementCaseResetApprovalEventData on ReimbursementCaseResetApprovalEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      resetAt
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseItemAddedEventFragment = gql`
  fragment ReimbursementCaseItemAddedEventData on ReimbursementCaseItemAddedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      expenseItemType
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseItemRemovedEventFragment = gql`
  fragment ReimbursementCaseItemRemovedEventData on ReimbursementCaseItemRemovedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      expenseItemType
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseItemIncludedEventFragment = gql`
  fragment ReimbursementCaseItemIncludedEventData on ReimbursementCaseItemIncludedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      expenseItemType
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseItemExcludedEventFragment = gql`
  fragment ReimbursementCaseItemExcludedEventData on ReimbursementCaseItemExcludedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      expenseItemType
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemInvoiceNumberUpdatedEventFragment = gql`
  fragment ExpenseItemInvoiceNumberUpdatedEventData on ExpenseItemInvoiceNumberUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      newInvoiceNumber
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseItemExportStatusUpdatedEventFragment = gql`
  fragment ReimbursementCaseItemExportStatusUpdatedEventData on ReimbursementCaseItemExportStatusUpdatedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    exportGuid
    exportType
    failedItems {
        reimbursementItemId
        reimbursementItemTitle
        reimbursementItemType
    }
    successfulItems {
        datevLocationParams {
            datevClientNumber
            datevConsultantNumber
            entityExportGuid
        }
        reimbursementItemId
        reimbursementItemTitle
        reimbursementItemType
    }
  }
  reimbursementItemMetadata {
    title
    type
  }
}
`;
