import { Button, ButtonProps, usePopover } from '@candisio/design-system';
import { ConfirmationPopover } from 'components/ConfirmationPopover/ConfirmationPopover';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useUpdateGeneralExpense } from 'views/Reimbursement/hooks/useUpdateGeneralExpense';
import { useUpdateHospitalityExpense } from 'views/Reimbursement/hooks/useUpdateHospitalityExpense';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { ErrorStateFooter } from 'views/Reimbursement/toolkit/types';
import { FastApproveReturn } from './FooterActionBox';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';

interface ApproveNowActionProps {
  isFastApprovePending: boolean;
  onFastApprove: () => Promise<FastApproveReturn | undefined>;
  onSetErrors: (errors: ErrorStateFooter) => void;
  getExpenses: UseFormGetValues<ExpensesFormOutput>;
}

export const ApproveNowAction = ({
  isFastApprovePending,
  onFastApprove,
  onSetErrors,
  getExpenses,
}: ApproveNowActionProps) => {
  const { triggerBookingsValidation } = useReimbursementSplitBookingHelpers();
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef, close } =
    usePopover();

  const { triggerAllValidations, lastModifiedExpense } =
    useReimbursementFormsContext();

  const { setWorkflow } = useReimbursementSplitBookingHelpers();

  const { updateGeneralExpense, isUpdateGeneralExpensePending } =
    useUpdateGeneralExpense({ shouldDebounce: false });

  const { isUpdateHospitalityExpensePending, updateHospitalityExpense } =
    useUpdateHospitalityExpense({ shouldDebounce: false });

  const handleClick = async () => {
    setWorkflow('approve');

    const lastModifiedExpenseData =
      lastModifiedExpense !== null
        ? getExpenses('expenses')[lastModifiedExpense]
        : undefined;

    if (lastModifiedExpenseData?.expenseType === 'general') {
      await updateGeneralExpense(lastModifiedExpenseData);
    }

    if (lastModifiedExpenseData?.expenseType === 'hospitality') {
      await updateHospitalityExpense(lastModifiedExpenseData);
    }

    const responseFastApprove = await onFastApprove();

    if (responseFastApprove?.status === 'error') {
      triggerAllValidations();
      triggerBookingsValidation();

      onSetErrors({
        itemErrors: responseFastApprove.reimbursementItemsErrors,
        hasReimbursementCaseErrors:
          responseFastApprove.hasReimbursementCaseErrors,
        showHasNoExpenseError:
          responseFastApprove.hasNoReimbursementItems ?? false,
        showHasNoIncludedExpensesError:
          responseFastApprove.hasNoIncludedReimbursementItems ?? false,
      });

      return;
    }

    onSetErrors({
      itemErrors: [],
      hasReimbursementCaseErrors: false,
      showHasNoIncludedExpensesError: false,
    });

    close();
  };

  const isLoading =
    isUpdateHospitalityExpensePending ||
    isFastApprovePending ||
    isUpdateGeneralExpensePending;

  const confirmButtonProps: ButtonProps = {
    color: 'blue',
    loading: isLoading,
    disabled: isLoading,
  };

  return (
    <>
      <Button
        {...triggerProps}
        ref={triggerRef}
        color="blue"
        loading={isLoading}
        disabled={isLoading}
      >
        {t('reimbursementView.rightSection.reviewfooterAction.approve')}
      </Button>
      {isOpen && (
        <ConfirmationPopover
          title={t(
            'reimbursementView.rightSection.reviewfooterAction.fastApprovePopover.text'
          )}
          confirmText={t(
            'reimbursementView.rightSection.reviewfooterAction.fastApprovePopover.confirmText'
          )}
          confirmButtonProps={confirmButtonProps}
          closeText={t(
            'reimbursementView.rightSection.reviewfooterAction.fastApprovePopover.closeText'
          )}
          onConfirm={handleClick}
          onClose={close}
          {...popoverProps}
          ref={popoverRef}
        />
      )}
    </>
  );
};
