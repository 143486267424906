import {
  CustomEmptyStateProps,
  Flex,
  Grid,
  useTheme,
} from '@candisio/design-system';
import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import {
  defaultSortByFields,
  DocumentsTable,
} from 'components/DocumentsTable/DocumentsTable';
import {
  ColumnWidths,
  DocumentsTableData,
  DocumentTableColumnKeys,
} from 'components/DocumentsTable/types';
import { SearchField } from 'components/SearchField/SearchField';
import { ConfigurationsMenu } from 'components/Table/Configurations/ConfigurationsMenu';
import { queryParameter } from 'components/Table/consts';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps } from 'react-router-dom';
import { appendParamsToQueryString } from 'utils/url-helper';
import { EcmSearchHelpCenterButton } from 'views/Archive/shared/EcmSearchFeedbackButton';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';
import { DocumentsHeader } from '../components/DocumentsHeader';
import { InboxViewContainer } from '../components/InboxViewContainer';
import { TabView } from '../models';
import { InboxDocumentsEmptyState } from './InboxDocumentsEmptyState/InboxDocumentsEmptyState';
import { useInboxDocumentsColumnsConfigurations } from './hooks/useInboxDocumentsColumnsConfigurations';
import { useInboxDocumentsData } from './hooks/useInboxDocumentsData';
import { useInboxDocumentsDataDeprecated } from './hooks/useInboxDocumentsDataDeprecated';
import { useInboxDocumentsFilters } from './hooks/useInboxDocumentsFilters';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { EcmSearchField } from 'components/EcmSearchField/EcmSearchField';

type Props = RouteComponentProps<{
  organizationSlug: string;
}>;

export const InboxDocumentsContainer = (props: Props) => {
  const enableNewIndexInInboxViewFF = useCandisFeatureFlags(
    FEATURE_FLAGS.enableNewIndexInInboxView
  );

  return enableNewIndexInInboxViewFF ? (
    <InboxDocuments {...props} />
  ) : (
    <InboxDocumentsDeprecated {...props} />
  );
};

const InboxDocuments = (props: Props) => {
  const inboxDocumentDataProps = useInboxDocumentsData();
  const { showImprovedSearch } = useEcm();
  return (
    <InboxDocumentsComponent
      {...props}
      inboxDocumentDataProps={inboxDocumentDataProps}
      shouldEnableSortByWithSearchQuery={true}
      showImprovedSearch={showImprovedSearch}
    />
  );
};

const InboxDocumentsDeprecated = (props: Props) => {
  const inboxDocumentDataProps = useInboxDocumentsDataDeprecated();

  return (
    <InboxDocumentsComponent
      {...props}
      inboxDocumentDataProps={inboxDocumentDataProps}
      shouldEnableSortByWithSearchQuery={false}
    />
  );
};

type InboxDocumentsComponentProps = Props & {
  showImprovedSearch?: boolean;
  inboxDocumentDataProps:
    | ReturnType<typeof useInboxDocumentsDataDeprecated>
    | ReturnType<typeof useInboxDocumentsData>;
  shouldEnableSortByWithSearchQuery: boolean;
};

const InboxDocumentsComponent = ({
  history,
  location: { search },
  match: {
    params: { organizationSlug },
  },
  inboxDocumentDataProps,
  shouldEnableSortByWithSearchQuery,
  showImprovedSearch = false,
}: InboxDocumentsComponentProps) => {
  const [t] = useTranslation();
  const { space } = useTheme();
  const { searchParams, updateSearchParam } = useMutateSearchParams();
  const [tableRowLinkFF, consistentSortAndFilteringFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.tableRowLink,
    FEATURE_FLAGS.consistentSortAndFiltering,
  ]);
  const {
    showDocumentTags: shouldUseNewEs,
    showSearchOverlay,
    showSearchOverlayPromo,
  } = useEcm();

  const searchQuery = searchParams.get(queryParameter) ?? '';
  const {
    documentEdges,
    loading,
    onLoadMore,
    documentsTableData,
    filters,
    hasMoreData,
    onFilter,
    isTableEmpty,
    isTableFiltered,
    onSort,
    sortBy,
    handleDebounceSearch,
  } = inboxDocumentDataProps;

  const {
    availableDocumentColumnIds,
    configurationsTable,
    isLoadingConfigs,
    isResetPending,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useInboxDocumentsColumnsConfigurations({ sortBy, filters });

  const firstDocumentEdge = documentEdges[0];
  const searchWithCursor = appendParamsToQueryString(search, {
    cursor: firstDocumentEdge?.cursor,
  });

  const columnWidths: ColumnWidths = {
    contact: space.space256,
    discountDateWPercentage: space.space256,
    iban: space.space200,
    invoiceNumber: space.space200,
    purchaseOrderNumber: space.space200,
    goodsReceiptsCount: space.space200,
    sapPurchaseOrderNumber: space.space200,
  };

  const filterOptions = useInboxDocumentsFilters();
  const startProcessingLink = `/${organizationSlug}${Routes.INBOX}/${firstDocumentEdge?.node.id}?${searchWithCursor}`;

  const customEmptyState = memo(({ resetFilters }: CustomEmptyStateProps) => (
    <InboxDocumentsEmptyState
      isTableEmpty={isTableEmpty}
      isTableFiltered={isTableFiltered}
      resetFilters={resetFilters}
    />
  ));

  const getPath = useCallback(
    ({ id, cursor }: DocumentsTableData) => {
      const searchWithCursor = appendParamsToQueryString(search, {
        cursor: cursor ?? null,
      });

      return `/${organizationSlug}${Routes.INBOX}/${id}?${searchWithCursor}`;
    },
    [search, organizationSlug]
  );

  const onClick = useCallback(
    ({ id, cursor }: DocumentsTableData) => {
      if (tableRowLinkFF) {
        return;
      }

      const path = getPath({ id, cursor });
      history.push(path);
    },
    [getPath, history, tableRowLinkFF]
  );

  const cellWrapper = useMemo(
    () => (tableRowLinkFF ? getCellWrapperLink(getPath) : undefined),
    [tableRowLinkFF, getPath]
  );

  const cellStyle = tableRowLinkFF
    ? () => ({
        padding: 'unset',
      })
    : undefined;

  const showLoadingMoreSpinner = documentsTableData.length > 1;

  const infiniteScrollOptions = {
    dataLength: documentsTableData?.length,
    hasMore: hasMoreData,
    showLoadingMoreSpinner: !loading && showLoadingMoreSpinner,
    next: onLoadMore,
  };

  const columnsHash = availableDocumentColumnIds.join('-');
  const tableKey = `documents-inbox-table-filters-${columnsHash}`;

  const sortByFields: DocumentTableColumnKeys[] =
    consistentSortAndFilteringFF && shouldUseNewEs
      ? [
          ...defaultSortByFields,
          'invoiceNumber',
          'purchaseOrderNumber',
          'artistSocialInsuranceCode',
          'costObject',
          'costCenter',
          'extraCostInfo',
          'deliveryDate',
        ]
      : defaultSortByFields;

  return (
    <>
      <DocumentsHeader
        documentsLength={documentEdges.length}
        organizationSlug={organizationSlug}
        startProcessingLink={startProcessingLink}
      />

      <InboxViewContainer activeTab={TabView.INBOX_DOCUMENTS}>
        <Flex
          key={tableKey}
          height="100%"
          padding="0 space32 space24"
          overflow="hidden"
          direction="column"
        >
          <Grid
            templateColumns={
              showImprovedSearch
                ? 'minmax(12rem, 24rem) auto 1fr'
                : 'minmax(12rem, 24rem) 1fr'
            }
            gap="space8"
            borderTopRadius="basic"
            overflow="hidden"
            borderBottom="1px solid gray200"
            background="white"
            padding="space12"
          >
            {showSearchOverlay || showSearchOverlayPromo ? (
              <EcmSearchField
                clearTooltip={t('archive:search.clearTooltip')}
                placeholder={t('archive:search.placeholder')}
                value={searchQuery}
                onQueryChange={query => {
                  handleDebounceSearch(query);
                  updateSearchParam(queryParameter, query);
                }}
                inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
              />
            ) : (
              <SearchField
                clearTooltip={t('archive:search.clearTooltip')}
                placeholder={t('archive:search.placeholder')}
                value={searchQuery}
                onChange={query => {
                  handleDebounceSearch(query);
                  updateSearchParam(queryParameter, query);
                }}
                inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
              />
            )}
            {showImprovedSearch && <EcmSearchHelpCenterButton />}
            <Grid justifySelf="end">
              <Flex gap="space8" alignItems="center">
                <ConfigurationsMenu
                  isLoading={isLoadingConfigs || isResetPending}
                  configurationItems={configurationsTable}
                  onUpdateConfigurations={handleUpdateConfigurations}
                  onResetConfigurations={handleResetTableConfigurations}
                />
              </Flex>
            </Grid>
          </Grid>

          <DocumentsTable
            columns={availableDocumentColumnIds}
            columnWidths={columnWidths}
            customEmptyState={customEmptyState}
            cellWrapper={cellWrapper}
            getCellStyles={cellStyle}
            data={documentsTableData}
            defaultFilters={filters}
            defaultSortBy={sortBy}
            filterOptions={filterOptions}
            infiniteScrollOptions={infiniteScrollOptions}
            isUsingSearchQuery={!!searchQuery}
            shouldEnableSortByWithSearchQuery={
              shouldEnableSortByWithSearchQuery
            }
            isLoading={loading}
            minWidth="100%"
            borderTopRadius="none"
            onEndReached={onLoadMore}
            onFilter={onFilter}
            onSort={onSort}
            onRowClick={onClick}
            width="max-content"
            sortByFields={sortByFields}
          />
        </Flex>
      </InboxViewContainer>
    </>
  );
};
