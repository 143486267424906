import { Grid, Card, Heading, Text, Link } from '@candisio/design-system';
import { AppRouteParams, Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom-v5-compat';

export const CreditCardSettlementsBanner = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const organizationSlug = useOrganizationId();

  const link = generatePath(
    `/:${AppRouteParams.organizationSlug}${Routes.CREDIT_CARDS_DASHBOARD}${Routes.STATEMENTS}`,
    {
      organizationSlug,
    }
  );

  return (
    <Card
      padding="space16"
      background="bluebg"
      borderRadius="medium"
      alignSelf="start"
    >
      <Grid gap="space16">
        <Heading as="h3">{t('settlementsBanner.title')}</Heading>
        <Text fontSize="basic">{t('settlementsBanner.desc')}</Text>
        <Link href={link} icon="arrowRight" fontSize="basic">
          {t('settlementsBanner.linkText')}
        </Link>
      </Grid>
    </Card>
  );
};
