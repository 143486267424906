import { useEffect } from 'react';
import { useExportContext } from 'views/Integrations/Export/Context';
import { useExportPaymentConditionsFactory } from 'views/Integrations/Export/toolkit/hooks/useExportPaymentConditionsFactory';
import { ExportPaymentConditionsTable } from './PaymentConditionsTable';
import { PaymentConditionsTableData } from './types';

type PaymentConditionsTableProps = {
  data: PaymentConditionsTableData;
  hasWarning: boolean;
  isMasked: boolean;
  showAll: boolean;
};

export const PaymentConditions = ({
  data,
  hasWarning,
  isMasked,
  showAll,
}: PaymentConditionsTableProps) => {
  return (
    <ExportPaymentConditionsTable
      data={data}
      hasWarning={hasWarning}
      isMasked={isMasked}
      showAll={showAll}
    />
  );
};

const WARNING_TRESHOLD = 100;

export const PaymentConditionsContainer = () => {
  const { view, includeAllPaymentConditions } = useExportContext();
  const {
    isMasked,
    isAllShown,
    paymentConditions: { tableData, refetch },
    count: { all, newAndUpdated },
  } = useExportPaymentConditionsFactory();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void refetch();
  }, [refetch, view]);

  const hasWarning = includeAllPaymentConditions
    ? all > WARNING_TRESHOLD
    : newAndUpdated > WARNING_TRESHOLD;

  return (
    <PaymentConditions
      data={tableData}
      hasWarning={hasWarning}
      isMasked={isMasked}
      showAll={isAllShown}
    />
  );
};
