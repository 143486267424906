import React from 'react';
import { Icon } from '../../Atoms/Icon';
import { IconKey } from '../../Particles/Spritemap';
import { useTheme } from '../../Theme';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { Flex } from '../Flex';
import { Spinner } from '../Spinner';

export type Color =
  | 'gray'
  | 'green'
  | 'red'
  | 'yellow'
  | 'blue'
  | 'purple'
  | 'pink';

type SizeVariant = 'xsmall' | 'small' | 'large';

export interface TagProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLDivElement> {
  color: Color;
  loading?: boolean;
  inactive?: boolean;
  variant?: Variant;
  size?: SizeVariant;
  icon?: IconKey;
  callout?: boolean;
}

type Variant = 'primary' | 'secondary';

/**
 * Tag component displays a given status.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-feedback-tag}
 *
 * @param {Color} color Color type
 * @param {boolean} loading Loading state
 * @param {boolean} inactive Inactive state
 * @param {boolean} callout Callout state
 */
export const Tag = React.forwardRef<HTMLDivElement, TagProps>(
  (
    {
      children,
      color,
      inactive,
      loading,
      callout = false,
      icon,
      variant = 'primary',
      size = 'small',
      ...restProps
    },
    ref
  ) => {
    const { tag } = useTheme();

    const baseStyle = tag[size];

    const isInactive = inactive && tag.inactive;
    const isCallout = callout && tag.callout;

    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        gap="space4"
        width="max-content"
        css={[baseStyle, tag[variant][color], isInactive || isCallout]}
        ref={ref}
        {...restProps}
      >
        {loading && <Spinner />}

        {icon && <Icon icon={icon} />}
        {children}
      </Flex>
    );
  }
);
