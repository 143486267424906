import React, { CSSProperties, useRef } from 'react';
import { AriaTabListProps, mergeProps, useTabList } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { TabListState } from 'react-stately';
import {
  LayoutProps,
  StandardHTMLAttributes,
  TypographyProps,
} from '../../types';
import { Grid } from '../Grid';
import { Tab, TabItem, TabSize } from './Tab';

export interface TabsProps
  extends LayoutProps,
    TypographyProps,
    Omit<StandardHTMLAttributes<HTMLDivElement>, 'children'>,
    AriaTabListProps<TabItem> {
  state: TabListState<TabItem>;
  size?: TabSize;
  'aria-label'?: string;
  alignment?: CSSProperties['justifyContent'];
  variant?: 'default' | 'opaque';
}

/**
 * `Tabs` component renders each `Tab` component, given some state and `Item`
 *
 * [Storybook]{@link (https://candisio.github.io/design-system/?path=/docs/atoms-navigation-tabs)}
 *
 * @param {TabListState<TabItem>} state initial state of tabs derived from `useTabListState` hook
 * @param {string} aria-label
 * @param {Array<string>} disabledKeys array of item keys to be rendered as disabled `Tab` components within `Tabs`
 * @param {Key} selectedKey The currently selected key in the collection (controlled)
 * @param {Key} defaultSelectedKey The initial selected key in the collection (uncontrolled)
 * @param {(key: Key) => any} onSelectionChange Handler that is called when the selection changes
 */
export const Tabs = React.forwardRef<HTMLDivElement, TabsProps>(
  (
    {
      state,
      size = 'medium',
      items,
      children,
      disabledKeys,
      selectedKey,
      defaultSelectedKey,
      onSelectionChange,
      alignment = 'left',
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      'aria-describedby': ariaDescribedBy,
      'aria-details': ariaDetails,
      variant = 'default',
      ...restProps
    },
    forwardedRef
  ) => {
    const ariaUseTabListProps = {
      items,
      children,
      disabledKeys,
      selectedKey,
      defaultSelectedKey,
      onSelectionChange,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      'aria-describedby': ariaDescribedBy,
      'aria-details': ariaDetails,
    };

    const tabListRef = useRef(null);
    const { tabListProps } = useTabList(ariaUseTabListProps, state, tabListRef);

    // we use `Array.from(state.collection)` rather than [...state.collection]`
    // to avoid problems downstream in frontend-web
    const tabsItems = Array.from(state.collection);

    return (
      <Grid
        autoFlow="column"
        justifyContent={alignment}
        ref={mergeRefs([tabListRef, forwardedRef])}
        gap="space12"
        {...mergeProps(tabListProps, restProps)}
      >
        {tabsItems.map(item => (
          <Tab
            key={item.key}
            size={size}
            item={item}
            state={state}
            variant={variant}
          />
        ))}
      </Grid>
    );
  }
);
