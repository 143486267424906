import { getCardIssuerCardByIdQuery } from 'containers/credit-cards/components/CreditCardsSection/gql';
import { useDeleteCardForRecurringPaymentVendorMutation } from 'generated-types/graphql.types';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { getVendorSuggestionsForRecurringPaymentsQuery } from '../CreditCardsInsights/gql';
import { useGetVendorForRecurringPaymentsById } from './useGetVendorForRecurringPaymentsById';
import { vendorSuggestionsQueryVariables } from './useVendorSuggestionsData';

export const useUnlinkCardFromVendor = () => {
  const { vendorId } = useParams<{ vendorId: string }>();
  const [unlinkCard, { loading, client }] =
    useDeleteCardForRecurringPaymentVendorMutation();

  const { vendorDetails, loading: loadingVendor } =
    useGetVendorForRecurringPaymentsById({ vendorId });

  return {
    unlinkCard: async () => {
      if (!vendorDetails || vendorDetails.linkedCards.length === 0) {
        return { status: 'error' };
      }

      const linkedcardId = vendorDetails.linkedCards?.[0].id;

      const { errors } = await unlinkCard({
        variables: {
          input: {
            cardLinkId: vendorDetails.linkedCards?.[0].linkageId,
            vendorId,
          },
        },
        awaitRefetchQueries: true,
        onCompleted: () => {
          // It is necessary to evict the cache since we do not know at exactly which point we are on the list and we do not know what to refetch
          client.cache.evict({ fieldName: 'getVendorsForRecurringPayments' });
        },
        refetchQueries: [
          {
            query: getVendorSuggestionsForRecurringPaymentsQuery,
            variables: vendorSuggestionsQueryVariables,
          },
          {
            query: getCardIssuerCardByIdQuery,
            variables: { id: linkedcardId },
          },
        ],
      });

      if (errors?.length) {
        return { status: 'error' };
      }

      return { status: 'success' };
    },
    loading: loading || loadingVendor,
  };
};
