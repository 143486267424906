import { Box, Flex, TruncatedText } from '@candisio/design-system';
import { CollapsibleCard } from 'components/CollapsibleCard/CollapsibleCard';
import { CommentForm } from 'components/Comment/CommentForm';
import { ReimbursementHistory } from './History/ReimbursementHistory';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { CommentValuesType } from 'views/Reimbursement/hooks/useCreateCommentOnReimbursement';
import { SupportedReimbursementTimeLineEvents } from 'views/Reimbursement/hooks/useReimbursementHistory';
import { User } from 'generated-types/graphql.types';

interface HistoryAndCommentSectionProps {
  memberships: Array<Pick<User, 'id' | 'roles' | 'name' | 'avatarUrl'>>;
  onCreateComment: (values: CommentValuesType) => Promise<null | undefined>;
  timeline: Array<SupportedReimbursementTimeLineEvents>;
  isLoadingMemberships?: boolean;
  isCreateCommentPending?: boolean;
  isTimelineLoading?: boolean;
}

export const HistoryAndCommentSection = ({
  memberships,
  onCreateComment,
  timeline,
  isCreateCommentPending = false,
  isLoadingMemberships = false,
  isTimelineLoading = false,
}: HistoryAndCommentSectionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const baseTitle = t('reimbursementView.leftSection.protocol.title');
  const showCount = !isTimelineLoading && timeline.length > 0;
  const countSuffix = showCount ? ` (${timeline.length})` : '';
  const headerTitle = baseTitle + countSuffix;

  const charsAfterEllipsis = countSuffix.length && countSuffix.length - 1;
  return (
    <CollapsibleCard
      borderRadius="medium"
      paddingBottom="space10"
      paddingRight="space8"
      maxHeight="85cqh"
      id="history-open"
      headerProps={{
        background: 'gray0',
        borderRadius: 'medium',
      }}
      header={
        <TruncatedText charsAfterEllipsis={charsAfterEllipsis}>
          {headerTitle}
        </TruncatedText>
      }
      defaultOpen
    >
      <Flex
        background="gray0"
        direction="column"
        overflow="hidden"
        borderRadius="none none medium medium"
      >
        <Box padding="space16" paddingLeft="space18">
          <CommentForm
            memberships={memberships}
            loadingMemberships={isLoadingMemberships}
            onCreateComment={onCreateComment}
            isSubmitting={isCreateCommentPending}
          />
        </Box>
        <Box padding="0 space16 space4 space2">
          <ReimbursementHistory
            isLoading={isTimelineLoading}
            timeline={timeline}
          />
        </Box>
      </Flex>
    </CollapsibleCard>
  );
};
