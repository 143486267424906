import { CSSObject } from '@emotion/react';
import React from 'react';
import { AVATAR_CIRCLE_CLASSNAME, AvatarProps } from '../../Atoms/Avatar';
import { Flex } from '../../Atoms/Flex';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { MorePlaceholder } from './MorePlaceholder';
const TRANSITION_DELAY = 200;

type AvatarChildren =
  | React.ReactElement<AvatarProps>
  | React.ReactElement<AvatarProps>[];

export interface AvatarStackProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLDivElement> {
  children: AvatarChildren;
  limit?: number;
  size?: AvatarProps['size'];
  tooltipMaxHeight?: LayoutProps['height'];
}

/**
 * The AvatarStack is used when multiple Avatar components are displayed in a group / as a stack.
 *
 * @param {AvatarChildren} children Set of Avatars
 */
export const AvatarStack = React.forwardRef<HTMLDivElement, AvatarStackProps>(
  (
    {
      children,
      limit: limitProp = 4,
      size = 'medium',
      tooltipMaxHeight,
      ...restProps
    },
    ref
  ) => {
    const childrenArray = React.Children.toArray(
      children
    ) as React.ReactElement<AvatarProps>[];

    const limit = Math.max(limitProp, 2);

    let beforePlaceholder: React.ReactElement<AvatarProps>[] = childrenArray;
    let afterPlaceholder: React.ReactElement<AvatarProps>[] = [];

    if (childrenArray.length > limit) {
      beforePlaceholder = childrenArray.slice(0, limit - 1);
      afterPlaceholder = childrenArray.slice(limit - 1);
    }

    const hasMultipleAvatars = childrenArray.length > 1;
    // only delay the tooltip if we have more than one avatar
    const tooltipDelay = hasMultipleAvatars ? TRANSITION_DELAY : 0;
    const avatarCircleBorderStyles = hasMultipleAvatars
      ? '1px solid white'
      : 'none';

    const numItems = Math.min(childrenArray.length, limit);

    const baseStyle: CSSObject = {
      width: 'max-content',
      display: 'flex',
      '& > div': {
        marginLeft: numItems > 1 ? 'calc(-6px)' : 0,
        '&:first-of-type': {
          marginLeft: 0,
        },
      },
      '& > div:hover': {
        zIndex: 2,
      },
      [`.${AVATAR_CIRCLE_CLASSNAME}`]: {
        border: avatarCircleBorderStyles,
      },
    };

    return (
      <Flex css={baseStyle} {...restProps} ref={ref}>
        {beforePlaceholder.map(child => {
          // This clones each child passed in and adds the tooltipDelay property to it.
          return React.cloneElement(child, {
            tooltipDelay: tooltipDelay,
            size,
          });
        })}
        {afterPlaceholder.length > 0 && (
          <MorePlaceholder
            size={size}
            tooltipDelay={tooltipDelay}
            tooltipMaxHeight={tooltipMaxHeight}
          >
            {afterPlaceholder}
          </MorePlaceholder>
        )}
      </Flex>
    );
  }
);
