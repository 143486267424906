import { Spinner } from '@candisio/design-system';
import { CheckmarkIcon, ErrorIcon } from 'components/Icons/DefaultIcons';
import i18next from 'i18next';
import { CheckProp } from './CheckList';

type GetCheckProps = {
  loading: CheckProp;
  datev: {
    success: CheckProp;
    error: CheckProp;
  };
  bds: {
    success: CheckProp;
    error: CheckProp;
  };
  accountNumbers: {
    success: CheckProp;
    error: CheckProp;
  };
  functionActive: CheckProp;
};

const Loading = <Spinner />;
const Success = <CheckmarkIcon flex="none" size="space16" />;
const Error = <ErrorIcon flex="none" size="space16" />;

export const isSuccessStateCashAccountAdded = (check: CheckProp) =>
  check.icon === Success || check.key === 'cashAccountAdded';

const lokaliseKey = 'provisions.setupCard.checks';

export const getChecks = (t: i18next.TFunction): GetCheckProps => {
  return {
    loading: {
      key: 'checkingFunction',
      icon: Loading,
      text: t(`${lokaliseKey}.checkingFunction`),
      state: 'loading',
    },
    datev: {
      success: {
        key: 'isDatevConnected',
        icon: Success,
        text: t(`${lokaliseKey}.isDatevConnected.success`),
        state: 'loaded',
      },
      error: {
        key: 'isDatevConnected',
        icon: Error,
        text: t(`${lokaliseKey}.isDatevConnected.error`),
        state: 'loaded',
      },
    },
    bds: {
      success: {
        key: 'isBDSAvailable',
        icon: Success,
        text: t(`${lokaliseKey}.isBDSAvailable.success`),
        state: 'loaded',
      },
      error: {
        key: 'isBDSAvailable',
        icon: Error,
        text: t(`${lokaliseKey}.isBDSAvailable.error`),
        state: 'loaded',
      },
    },
    accountNumbers: {
      success: {
        key: 'accountNumbers',
        icon: Success,
        text: t(`${lokaliseKey}.accountNumbers.success`),
        state: 'loaded',
      },
      error: {
        key: 'accountNumbers',
        icon: Error,
        text: t(`${lokaliseKey}.accountNumbers.error`),
        state: 'loaded',
      },
    },
    functionActive: {
      key: 'functionActive',
      icon: Success,
      text: t(`${lokaliseKey}.functionActive`),
      state: 'loaded',
    },
  };
};
