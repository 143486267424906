import { queryDebouncedParameter } from 'components/Table/consts';
import {
  ArchiveAllDocumentsQueryVariables,
  Query,
} from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { archiveAllDocumentsQuery } from 'views/Archive/EcmDocuments/queries';
import { EcmDocumentsTableData } from '../types';
import { useSearchInput } from 'components/EcmSearchFieldFilterOverlay/useSearchInput';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { QueryHookOptions } from '@apollo/client';

export interface UseEcmDocumentsWithExpensesReturn {
  data: EcmDocumentsTableData[];
  handleDebounceSearch: (searchQuery: string) => void;
  hasNoData: boolean;
  isLoading: boolean;
  onLoadMore: () => void;
  selectedDocumentsCount: number;
}

export interface UseEcmDocumentsWithExpensesParams {
  params?: Omit<ArchiveAllDocumentsQueryVariables, 'search'>;
  initialSearchQuery?: string;
  filterParamsSource?: 'local' | 'url';
  previewDocumentId?: string | null;
  queryOptions?: QueryHookOptions;
}

/**
 * Fetches ECM documents and maps to EcmDocumentsTableData
 */
export const useEcmDocumentsWithExpenses = ({
  params,
  initialSearchQuery = '',
  filterParamsSource,
  previewDocumentId,
  queryOptions,
}: UseEcmDocumentsWithExpensesParams): UseEcmDocumentsWithExpensesReturn => {
  const [search, setSearch] = useSearchParams();
  const [debounceSearchValue, setDebounceSearchValue] =
    useState(initialSearchQuery);

  const { searchInputVariable } = useSearchInput();

  const computeVariables = (endCursor: string) => ({
    next: endCursor,
    limit: 20,
    search: debounceSearchValue,
    searchInput: searchInputVariable,

    ...params,
  });

  const {
    data,
    loading: isLoading,
    onLoadMore,
  } = usePagination<Pick<Query, 'archiveAllDocuments'>>(
    archiveAllDocumentsQuery,
    'archiveAllDocuments',
    {
      variables: {
        limit: 20,
        search: debounceSearchValue,
        searchInput: searchInputVariable,
        ...params,
      },
      ...queryOptions,
    },
    { computeVariables }
  );

  useEffect(() => {
    if (
      filterParamsSource === 'url' &&
      search.get(queryDebouncedParameter) !== debounceSearchValue
    ) {
      search.set(queryDebouncedParameter, debounceSearchValue);
      setSearch(search);
    }
  }, [debounceSearchValue, search, filterParamsSource, setSearch]);

  const handleDebounceSearch = useMemo(() => {
    return debounce(setDebounceSearchValue, DEFAULT_DEBOUNCE_TIME, {
      leading: true,
    });
  }, []);

  const ecmDocumentsList = data?.archiveAllDocuments;

  const hasNoData =
    !isLoading && data?.archiveAllDocuments?.edges?.length === 0;

  const documentList = useMemo(
    () =>
      (ecmDocumentsList?.edges ?? []).map(
        ({ node, cursor }): EcmDocumentsTableData => {
          return {
            cursor,
            id: node?.id ?? '',
            globalDocumentId: node?.globalDocumentId ?? '',
            documentType: node?.documentType ?? '',
            contact: node?.contactName ?? '',
            invoiceId: node?.invoiceId ?? '',
            notes: node?.notes ?? '',
            documentName: node?.documentName ?? '',
            documentNumber: node?.documentNumber ?? '',
            documentDate: node?.documentDate
              ? new Date(node?.documentDate)
              : undefined,
            documentStatus: {
              status: node?.documentStatus ?? undefined,
              isSensitive: node?.isSensitive ?? false,
              isEInvoice: node?.isEInvoice ?? false,
            },
            tags: node?.tags ?? [],
            selected:
              node?.id === previewDocumentId ||
              node?.invoiceId === previewDocumentId,
          };
        }
      ),
    [ecmDocumentsList?.edges, previewDocumentId]
  );

  const selectedDocumentsCount = ecmDocumentsList?.pageInfo?.totalCount ?? 0;

  return {
    data: documentList,
    handleDebounceSearch,
    hasNoData,
    isLoading,
    onLoadMore,
    selectedDocumentsCount,
  };
};
