import { TabPanel, Tabs } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { useTabs } from 'components/Tabs/useTabs';
import { Routes } from 'models';
import { useOtherIntegration } from 'orgConfig/other';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { TaxCodeList } from 'views/Settings/TaxCodes/components/TaxCodeList';
import { useTaxCodes } from 'views/Settings/TaxCodes/useTaxCodes';
import { useSap } from '../../../orgConfig/sap';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';
import { TaxCodesDetails } from './components/TaxCodeDetails';
import { DefaultImport } from './components/TaxCodeModals/DefaultImport';
import { TAX_CODES_ROUTE_HASH, TAX_CODES_SEARCH_PARAM } from './consts';

enum TaxCodeTab {
  archived = 'archived',
  active = 'active',
}

export const TaxCodes = () => {
  const navigate = useNavigate();
  const [t] = useTranslation(LOCALE_NAME_SPACE.TAX_CODES);
  const {
    archivedBookingKeys,
    activeBookingKeys,
    bookingKeysLoading,
    bookingKeys,
    importLoading,
    handleImport,
  } = useTaxCodes();

  const organization = useFullOrganization();
  const { isActiveIntegration: shouldUseSapTaxCodes } = useSap();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const { shouldUseCoreDataApiTaxCodes } = useOtherIntegration();

  const hasActiveBookingAccounts = organization?.hasActiveBookingAccounts;
  const isArchived =
    searchParams.get(TAX_CODES_SEARCH_PARAM.archive) === 'true';

  const closeModal = () => {
    navigate({
      pathname,
      hash: '',
    });
  };

  const onGoToBookingAccounts = () => {
    window.open(
      `/${organization?.slug}${Routes.SETTINGS}${Routes.BOOKING_ACCOUNT}`,
      '_blank'
    );
  };

  const { tabPanelProps, tabsProps } = useTabs({
    items: [
      {
        key: TaxCodeTab.active,
        title: t('tabs.active'),
        badge: !bookingKeysLoading
          ? String(activeBookingKeys.length)
          : undefined,
        children: <TaxCodeList />,
      },
      {
        key: TaxCodeTab.archived,
        title: t('tabs.archived'),
        badge: !bookingKeysLoading
          ? String(archivedBookingKeys.length)
          : undefined,
        children: <TaxCodeList />,
      },
    ],
    onSelectionChange: key => {
      const params = new URLSearchParams(searchParams);
      params.set(
        TAX_CODES_SEARCH_PARAM.archive,
        (key === TaxCodeTab.archived).toString()
      );
      setSearchParams(params);
    },
    selectedKey: isArchived ? TaxCodeTab.archived : TaxCodeTab.active,
  });

  const externalLink = shouldUseCoreDataApiTaxCodes
    ? 'infoPanel.coreDataApiExternalLink'
    : 'infoPanel.externalLink';

  const sections = shouldUseCoreDataApiTaxCodes
    ? 'infoPanel.coreDataApiSections'
    : 'infoPanel.sections';

  const title = shouldUseCoreDataApiTaxCodes
    ? 'infoPanel.coreDataApiTitle'
    : 'infoPanel.title';

  return (
    <SettingsLayout
      title={t('title')}
      actions={<Tabs {...tabsProps} />}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      <TabPanel {...tabPanelProps} />
      <InfoPanel
        externalLink={
          !shouldUseSapTaxCodes
            ? t(externalLink, {
                returnObjects: true,
              })
            : undefined
        }
        sections={t(sections, { returnObjects: true })}
        title={t(title)}
      />
      <TaxCodesDetails
        hasActiveBookingAccounts={hasActiveBookingAccounts || false}
        goToBookingAccounts={onGoToBookingAccounts}
      />
      <DefaultImport
        isVisible={
          bookingKeys.length === 0 &&
          window.location.hash === TAX_CODES_ROUTE_HASH.defaultImport &&
          !shouldUseCoreDataApiTaxCodes
        }
        handleImport={handleImport}
        isImporting={importLoading}
        closeModal={closeModal}
        hasActiveBookingAccounts={hasActiveBookingAccounts || false}
        goToBookingAccounts={onGoToBookingAccounts}
      />
    </SettingsLayout>
  );
};
