import { Button, Grid, Popover, usePopover } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface DeleteReimbursementItemActionProps {
  loading: boolean;
  onDelete: () => Promise<void>;
  isPromotion?: boolean;
}

export const DeleteReimbursementItemAction = ({
  loading,
  onDelete,
  isPromotion = false,
}: DeleteReimbursementItemActionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef, close } =
    usePopover();

  const handleDelete = async () => {
    close();
    await onDelete();
  };

  return (
    <>
      <Button
        size="small"
        variant="tertiary"
        justifySelf="end"
        color="error"
        loading={loading}
        disabled={loading}
        {...triggerProps}
        ref={triggerRef}
        tabIndex={isPromotion ? -1 : 0}
      >
        {t('reimbursementView.middleSection.form.hospitality.deleteCta')}
      </Button>
      {isOpen && (
        <Popover
          width="320px"
          padding="space18"
          {...popoverProps}
          ref={popoverRef}
        >
          <Grid gap="space20">
            {t(
              'reimbursementView.middleSection.form.hospitality.doubleConfirm.desc'
            )}
            <Grid autoFlow="column" gap="space8">
              <Button onClick={close} variant="secondary">
                {t(
                  'reimbursementView.middleSection.form.hospitality.doubleConfirm.no'
                )}
              </Button>
              <Button color="red" onClick={handleDelete}>
                {t(
                  'reimbursementView.middleSection.form.hospitality.doubleConfirm.yes'
                )}
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
};
