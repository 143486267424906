import { ContactDataFragment } from 'generated-types/graphql.types';
import i18next from 'i18next';
import { paymentMediumOptions } from 'views/Contacts/ContactDetails/ContactForm/PaymentMediumSelectField';
import { ContactsTableData } from '../ContactTableDS';

export const mapToContactsTableData = (
  contactRecords: ContactDataFragment[],
  t: i18next.TFunction,
  mergedContactId?: string
) => {
  const mappedContacts: ContactsTableData[] = contactRecords.map(
    contactRecord => ({
      contact: contactRecord.name.value,
      id: contactRecord.id,
      iban: contactRecord.iban?.value,
      paymentInfo: {
        bankAccountNumber: contactRecord.bankAccountNumber ?? undefined,
        iban: contactRecord.iban?.value,
        swiftCode: contactRecord.swiftCode ?? undefined,
      },
      taxNumber: contactRecord.taxNumber?.value,
      vatId: contactRecord.vatId?.value,
      createTransfer: t(
        contactRecord.createTransfer?.value
          ? 'settings.contacts.columns.paymentValue.true'
          : 'settings.contacts.columns.paymentValue.false'
      ),
      dueDateOffset: contactRecord.dueDateOffset?.value
        ? `${contactRecord.dueDateOffset?.value}`
        : undefined,
      accountsPayableNumber: contactRecord.accountsPayableNumber ?? undefined,
      accountsReceivableNumber:
        contactRecord.accountsReceivableNumber ?? undefined,
      createdAt: contactRecord.createdAt
        ? new Date(contactRecord.createdAt)
        : undefined,
      paymentMedium: t(
        paymentMediumOptions.find(
          option =>
            option.key === contactRecord.additionalContactInfo?.paymentMedium
        )?.children ?? ''
      ),
      paymentCondition: {
        discountPercentage:
          contactRecord.paymentCondition?.discountPercentage ?? undefined,
        discountOffset:
          contactRecord.paymentCondition?.discountOffset ?? undefined,
        dueDateOffset:
          contactRecord.paymentCondition?.dueDateOffset ?? undefined,
        conditionName:
          contactRecord.paymentCondition?.paymentConditionName ?? undefined,
      },
      highlighted: contactRecord.id === mergedContactId,
      relationshipType: contactRecord.relationshipType ?? undefined,
    })
  );

  return mappedContacts;
};
