import { Box, Flex } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { QuickSaveButtons } from 'views/Settings/components/QuickSaveButtons/QuickSaveButtons';
import { getPlaceholderBookingAccount } from 'views/Settings/CreditCards/CreditCardExportingCard/CreditCardExportingForm/BookingAccountForm/BookingAccountForm.helper';
import { useDatevProvisionsAccountValidation } from '../../hooks/useDatevProvisionsAccountValidation';

interface ProvisionAccountNumberProps {
  onSubmit: ({
    provisionAccountNumber,
  }: {
    provisionAccountNumber: string | undefined;
  }) => void;
  provisionAccountNumber: string | undefined;
}

export const ProvisionAccountNumber = ({
  onSubmit,
  provisionAccountNumber,
}: ProvisionAccountNumberProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const {
    schema,
    errorMessages,
    maxLength: accountLength,
  } = useDatevProvisionsAccountValidation('provisionAccountNumber');

  const form = useForm({
    defaultValues: { provisionAccountNumber },
    context: { t },
    resolver: zodResolver({
      zodSchema: schema({ t }),
      errorMessages: errorMessages({
        t,
        label: 'provisions.setupCard.provisionsAccountLabel',
      }),
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
    }),
    mode: 'onSubmit',
  });

  const handleSubmit = ({
    provisionAccountNumber,
  }: {
    provisionAccountNumber: string | undefined;
  }) => {
    if (!provisionAccountNumber) {
      onSubmit({ provisionAccountNumber: '' });
    } else {
      onSubmit({ provisionAccountNumber });
    }

    form.resetField('provisionAccountNumber', {
      defaultValue: provisionAccountNumber,
    });
  };

  const isFormDirty = form.formState.isDirty;

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        onSubmit={form.handleSubmit(handleSubmit)}
        gap="space16"
        alignItems="end"
      >
        <HookFormTextField
          autoFocus={true}
          label={
            <Flex width="95%">
              {t('provisions.setupCard.provisionsAccountLabel')}
            </Flex>
          }
          name="provisionAccountNumber"
          placeholder={t('provisions.setupCard.accountPlaceholder', {
            accountNumber: getPlaceholderBookingAccount(accountLength),
          })}
          clearable={false}
        />
        <Box paddingTop="space16">
          <QuickSaveButtons
            isFormDirty={isFormDirty}
            resetField={() => form.resetField('provisionAccountNumber')}
          />
        </Box>
      </Flex>
    </FormProvider>
  );
};
