import { PDFDetails } from 'components/DocumentViewer/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';
import { isNumberInRange } from 'utils/isNumberInRange';
import { PdfViewerProps } from '../PdfViewer';
import {
  UseReactPdfControlsProps,
  useReactPdfControls,
} from './useReactPdfControls';
import { getHighestNumberVisiblePage } from './utils';

export interface UseDocumentPagesProps
  extends Pick<
      ReturnType<typeof useReactPdfControls>,
      'setCurrentPage' | 'currentPage'
    >,
    Pick<PdfViewerProps, 'onSelectDocument' | 'selectedFile'>,
    Pick<UseReactPdfControlsProps, 'smoothScroll'> {
  documentFile: PDFDetails;
  attachments?: PDFDetails[];
}

const SMOOTH = 'smooth' as const;

export const useDocumentPages = ({
  documentFile,
  attachments,
  setCurrentPage,
  onSelectDocument,
  currentPage,
  smoothScroll,
  selectedFile,
}: UseDocumentPagesProps) => {
  // ordered map of main doc + attachments as they appear in pdf viewer
  const [documentPages, setDocumentPages] = useState<Map<string, number>>(
    new Map(
      [documentFile, ...(attachments ?? [])].map(file => {
        return [file?.id ?? '', 0];
      })
    )
  );

  // map containing visibility of each page, e.g. [1, 0.2], [2, 0.5], [3, 0.3], used to determine which page is current one based on how much of it is visible
  const [, setVisiblePages] = useState<Map<number, number>>(new Map([[1, 1]]));

  // ordered map with more info about documents pages, its first and last page in total
  // e.g. ['id-1', {firstPage: 1, lastPage: 2}], ['attachment-2', {firstPage: 3, lastPage: 3}]
  // because all documents load asynchronously, we need to iterate over `documentPages` to update their info as they load
  const documentsWithFirstAndLastPage = useMemo(() => {
    const docsWithPagesMap = new Map<
      string,
      {
        firstPage: number;
        lastPage: number;
      }
    >();

    let pageCount = 0;
    for (const [docId, docPages] of documentPages.entries()) {
      docsWithPagesMap.set(docId, {
        firstPage: pageCount + 1,
        lastPage: pageCount + docPages,
      });
      pageCount += docPages;
    }

    return docsWithPagesMap;
  }, [documentPages]);

  // total pages (main document + all attachments)
  // because `documentsWithFirstAndLastPage` is ordered, we know that last page is last's document `lastPage`
  const totalPages = useMemo(
    () => Array.from(documentsWithFirstAndLastPage.values()).pop()?.lastPage,
    [documentsWithFirstAndLastPage]
  );

  // called whenever visibility of pages change (e.g. by scrolling)
  // takes care of:
  // - setting the visibility
  // - setting current page
  // - notifying via `onSelectDocument` if currently viewed document changed
  const handleChangePageVisibility = useCallback(
    (pageNumberInTotal: number, visibilityRatio: number) => {
      // TODO refactor
      flushSync(() => {
        setVisiblePages(prev => {
          const newVisiblePages = new Map(prev);
          newVisiblePages.set(pageNumberInTotal, visibilityRatio);

          const newCurrentPage = getHighestNumberVisiblePage(newVisiblePages);
          setCurrentPage(newCurrentPage);

          let allPagesCount = 0;
          for (const [docId, docPages] of documentPages) {
            allPagesCount += docPages;
            if (newCurrentPage <= allPagesCount) {
              const docToSelect = [documentFile, ...(attachments ?? [])].find(
                doc => doc.id === docId
              );

              if (docToSelect && docToSelect.id !== selectedFile?.id)
                onSelectDocument?.(docToSelect);
              break;
            }
          }

          return newVisiblePages;
        });
      });
    },
    [
      attachments,
      documentFile,
      documentPages,
      onSelectDocument,
      selectedFile?.id,
      setCurrentPage,
    ]
  );

  // calculate page offset based on rendered documents
  // again because documents render asynchronously we have to rely on `documentPages`
  const getPageNumberOffset = useCallback(
    (documentId: string) => {
      let pageCount = 0;
      for (const [id, totalPages] of documentPages.entries()) {
        if (id === documentId) {
          return pageCount;
        } else {
          pageCount += totalPages;
        }
      }
    },
    [documentPages]
  );

  const onDocumentPagesLoaded = useCallback(
    (documentId: string, pagesCount: number) => {
      setDocumentPages(prev => {
        const newDocumentPages = new Map(prev);
        newDocumentPages.set(documentId, pagesCount);

        return newDocumentPages;
      });
    },
    []
  );

  // called when `selectedFile` changes (e.g. by selecting attachment in Attachments tab)
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!selectedFile?.id) return;

    const selectedDoc = documentsWithFirstAndLastPage.get(selectedFile.id);
    if (!selectedDoc) return;

    const isDocumentInViewport = isNumberInRange(
      currentPage,
      selectedDoc.firstPage,
      selectedDoc.lastPage
    );

    if (isDocumentInViewport) return;

    const scrollBehavior = { behavior: smoothScroll ? SMOOTH : undefined };
    document.getElementById(selectedFile.id)?.scrollIntoView(scrollBehavior);
  }, [
    documentsWithFirstAndLastPage,
    selectedFile?.id /** `currentPage` is ommited here because it would trigger endless loop (setCurrentPage => onSelectDocument => this useEffect => setCurrentPage...) */,
  ]);

  return {
    setDocumentPages,
    getPageNumberOffset,
    handleChangePageVisibility,
    totalPages,
    onDocumentPagesLoaded,
  };
};
