import { Card, Flex, Grid, Image, Text } from '@candisio/design-system';
import { LottieAnimation, noresultsData } from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { MonthSpentOverview } from 'views/CreditCards/hooks/useGetSpendOverviewInsights';
import relaxedAccountat from './media/relaxed-accountant.svg';
import { VerticalBarContainer } from './VerticalBarContainer';

export interface SpentOverviewprops {
  overviewMonths: MonthSpentOverview[];
  maxNumberReference: number;
  loading?: boolean;
  monthsWithCreditCards: number;
  isOverviewEmpty: boolean;
  hasFiltersApplied: boolean;
}

export const SpentOverview = ({
  overviewMonths,
  loading,
  maxNumberReference,
  monthsWithCreditCards,
  isOverviewEmpty,
  hasFiltersApplied,
}: SpentOverviewprops) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Card background="gray50">
      <Grid
        height="320px"
        columns={12}
        gap="space8"
        alignItems="end"
        justifyContent="center"
      >
        {(overviewMonths || []).map((month, index) => (
          <VerticalBarContainer
            hasFiltersApplied={hasFiltersApplied}
            loading={loading}
            monthSpentOverview={month}
            maxNumberReference={maxNumberReference}
            key={index}
          />
        ))}
        {!loading && (
          <>
            {isOverviewEmpty && hasFiltersApplied ? (
              <Grid
                position="absolute"
                top="50%"
                left="50%"
                padding="2rem"
                borderRadius="full"
                background="gray50"
                style={{ transform: 'translate(-50%,-50%)' }}
              >
                <Flex direction="column" alignItems="center">
                  <LottieAnimation
                    options={{
                      animationData: noresultsData,
                    }}
                  />
                  <Text>{t('insights.spentWidget.emptyState')}</Text>
                </Flex>
              </Grid>
            ) : (
              monthsWithCreditCards <= 4 && (
                <Grid
                  position="absolute"
                  top="50%"
                  left="50%"
                  padding="2rem"
                  borderRadius="full"
                  background="gray50"
                  style={{ transform: 'translate(-50%,-50%)' }}
                >
                  <Image
                    alt="relaxedAccountat"
                    src={relaxedAccountat}
                    data-test-id="empty-state-accountant"
                  />
                </Grid>
              )
            )}
          </>
        )}
      </Grid>
    </Card>
  );
};
