import { Button, Card, Flex, Grid, Tag, Text } from '@candisio/design-system';
import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { usePermissionsForCreditCards } from 'containers/credit-cards/hooks/usePermissionsForCreditCards';
import {
  CardLimitRenewFrequency,
  CardType,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { intervalTranslations } from '../PendingLimitRequest/PendingLimitRequest';

export interface CardRequestProps {
  requestedAt: string;
  comment?: string;
  purpose?: string;
  limit: string | null;
  transactionLimit: string | null;
  cardType: CardType;
  interval: CardLimitRenewFrequency | undefined;
  onSetMode: (mode: DrawerMode) => void;
}

export const CardRequest = ({
  requestedAt,
  comment,
  purpose,
  cardType,
  interval,
  limit,
  transactionLimit,
  onSetMode,
}: CardRequestProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { canManageCards } = usePermissionsForCreditCards();

  const isIntervalVisible =
    limit && cardType !== CardType.SingleUse && interval;

  return (
    <Grid gap="space2">
      <Card background="yellowbg" borderBottomRadius="none">
        <Grid gap="space8">
          <Tag variant="primary" color="yellow" size="small">
            {t('dashboard.drawer.cardRequest')}
          </Tag>
          <Text fontSize="basic">
            {t('drawer.requested.infoText', {
              readableDate: requestedAt,
            })}
          </Text>
          {comment && (
            <Text fontSize="basic">
              {t('drawer.limitChange.comment')}: {comment}
            </Text>
          )}
          {purpose && (
            <Text fontSize="basic">
              {t('drawer.limitChange.purpose')}: {purpose}
            </Text>
          )}
        </Grid>
      </Card>
      <Card background="yellowbg" borderTopRadius="none">
        <Grid templateColumns="1fr auto">
          <Grid gap="space8">
            <Text fontSize="basic">
              {t('drawer.limitChange.requestedLimits')}
            </Text>
            <Flex gap="space16" alignItems="center">
              {isIntervalVisible && (
                <Text color="yellow700" fontSize="large">
                  {t(intervalTranslations[interval], { amount: limit })}
                </Text>
              )}
              {transactionLimit && (
                <Text color="yellow700" fontSize="large">
                  {t('drawer.limitChange.requestedTxLimit', {
                    formattedAmount: transactionLimit,
                  })}
                </Text>
              )}
            </Flex>
          </Grid>
          {canManageCards && (
            <Button
              onClick={() => onSetMode('cardLimits')}
              variant="secondary"
              size="small"
              alignSelf="center"
              justifySelf="end"
            >
              {t('drawer.requested.changeLimits')}
            </Button>
          )}
        </Grid>
      </Card>
    </Grid>
  );
};
