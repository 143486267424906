import {
  Grid,
  Icon,
  Text,
  Tooltip,
  useTooltip,
  Flex,
  TruncatedText,
} from '@candisio/design-system';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { useLocale } from 'utils/useLocale';
import { NotificationProps } from '../getNotificationInfo';

export const TransactionDeclinedInAppNotification = (
  props: NotificationProps<'TransactionDeclinedInAppNotification'>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);
  const { transactionAmountFormatter } = useMoneyFormatter();
  const userLocale = useLocale();
  const {
    merchantName,
    declineReason,
    transactionAmount,
    cardRefNum,
    transactionCreatedAt,
  } = props;

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });

  const declineReasonTranslated =
    userLocale === 'de' ? declineReason.title?.de : declineReason.title?.en;

  const formattedAmount = transactionAmountFormatter(transactionAmount);

  return (
    <Grid gap="space6" height="100%">
      <Trans
        i18nKey="notifications:events.transactionDeclined.header"
        values={{
          timeAgo: DateFormatters.fromNow(new Date(transactionCreatedAt)),
        }}
        parent="span"
      >
        <Text fontWeight="regular" fontSize="small" color="gray500">
          Transaction Declined at
        </Text>
        <Text
          {...triggerProps}
          ref={triggerRef}
          fontWeight="regular"
          fontSize="small"
          color="gray450"
        >
          3 mins ago
        </Text>
      </Trans>

      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {DateFormatters.compactDatetime(new Date(transactionCreatedAt))}
        </Tooltip>
      )}

      {declineReasonTranslated && (
        <TruncatedText color="red500" fontSize="basic">
          {declineReasonTranslated}
        </TruncatedText>
      )}

      <Flex gap="space4">
        <Text fontSize="small" color="gray450">
          {merchantName}
        </Text>
        <Text fontSize="small" color="gray450">
          •
        </Text>
        <Text fontSize="small" color="gray450">
          {formattedAmount}
        </Text>
        {cardRefNum ? (
          <Text fontSize="small" color="gray450">
            {t('events.transactionDeclined.creditCard', { cardRefNum })}
          </Text>
        ) : null}
      </Flex>
    </Grid>
  );
};

export const TransactionDeclinedInAppNotificationIcon = () => {
  return (
    <Flex
      width="space32"
      height="space32"
      alignItems="center"
      justifyContent="center"
      background="redbg"
      borderRadius="full"
    >
      <Icon icon="creditCardOutline" color="red700" size="space16" />
    </Flex>
  );
};

export const TransactionDeclinedInAppNotificationToast = (
  props: NotificationProps<'TransactionDeclinedInAppNotification'>
) => {
  const userLocale = useLocale();

  const { declineReason, transactionCreatedAt } = props;

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });

  const declineReasonTranslated =
    userLocale === 'de' ? declineReason.title?.de : declineReason.title?.en;

  return (
    <Grid gap="space6" height="100%">
      <Trans
        i18nKey="notifications:events.transactionDeclined.header"
        values={{
          timeAgo: DateFormatters.fromNow(new Date(transactionCreatedAt)),
        }}
        parent="span"
      >
        <Text fontWeight="regular" fontSize="small" color="gray450">
          Transaction Declined at
        </Text>
        <Text
          {...triggerProps}
          ref={triggerRef}
          fontWeight="regular"
          fontSize="small"
          color="gray450"
        >
          3 mins ago
        </Text>
      </Trans>

      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {DateFormatters.compactDatetime(new Date(transactionCreatedAt))}
        </Tooltip>
      )}

      {declineReasonTranslated ? (
        <TruncatedText color="red500" fontSize="basic">
          {declineReasonTranslated}
        </TruncatedText>
      ) : null}
    </Grid>
  );
};
