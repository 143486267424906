import {
  Grid,
  Icon,
  IconProps,
  Image,
  MenuItem,
} from '@candisio/design-system';
import PdfIcon from 'assets/file-icons/icon-pdf.svg';
import XmlIcon from 'assets/file-icons/icon-xml.svg';
import { Filename } from 'components/Filename/Filename';

interface DownloadMenuItemProps {
  icon: IconProps['icon'] | 'pdf' | 'xml';
  label: string;
}

export const DownloadMenuItem = ({ label, icon }: DownloadMenuItemProps) => {
  const renderIcon = (icon: DownloadMenuItemProps['icon']) => {
    if (icon === 'pdf' || icon === 'xml') {
      return (
        <Image
          height="space16"
          width="space16"
          src={icon === 'pdf' ? PdfIcon : XmlIcon}
          alt=""
        />
      );
    }

    return <Icon icon={icon} size="space16" minWidth="space16" />;
  };

  return (
    <Grid
      role="gridcell"
      gap="space4"
      autoFlow="column"
      alignItems="center"
      templateColumns="auto 1fr"
    >
      {renderIcon(icon)}
      <Filename>{label}</Filename>
    </Grid>
  );
};
