import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';
import { DocumentTypesInfoPanel } from './components/DocumentTypesInfoPanel';
import { DocumentTypesList } from './components/DocumentTypesList';

export const DocumentTypes = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <SettingsLayout
      title={t('documentTypes.title')}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      <DocumentTypesList />
      <DocumentTypesInfoPanel />
    </SettingsLayout>
  );
};
