import {
  Box,
  Button,
  Flex,
  Grid,
  Spinner,
  Text,
  useTheme,
} from '@candisio/design-system';
import { PdfViewer } from 'components/DocumentViewer/PdfViewer/PdfViewer';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { useTranslation } from 'react-i18next';

export interface DocumentPreviewProps {
  onOpen: () => void;
  onClose: () => void;
  showPdf: boolean;
  documentFile: PDFDetails;
  attachments?: PDFDetails[];
  loading?: boolean;
}

export const DocumentPreview = ({
  onClose,
  onOpen,
  showPdf,
  documentFile,
  attachments,
  loading,
}: DocumentPreviewProps) => {
  const [t] = useTranslation();
  const { radii, shadows } = useTheme();

  if (loading) {
    return (
      <Grid
        background="white"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      >
        <Spinner size="space40" />
      </Grid>
    );
  }

  return (
    <Grid
      background="white"
      templateRows="auto minmax(0, 1fr)"
      height="100%"
      borderTop="1px solid gray250"
    >
      <Grid
        justifyContent="space-between"
        alignItems="center"
        autoFlow="column"
        padding="space16 space16 space16 space24"
        borderBottom="1px solid gray250"
      >
        <Grid>
          <Text fontSize="xlarge" fontWeight="semibold" lineHeight="paragraph">
            {t('documentPreview.title')}
          </Text>
        </Grid>
        <Grid autoFlow="column" justifyContent="center" alignItems="center">
          <Button
            color="blue"
            variant="tertiary"
            id="document-preview-feedback"
          >
            {t('common.feedback')}
          </Button>
          <Button variant="tertiary" icon="close" onClick={onClose} />
        </Grid>
      </Grid>
      <Grid gap="space24" height="100%">
        <Box background="gray50" overflow="auto" paddingTop="space8">
          {showPdf && (
            <PdfViewer
              documentFile={documentFile}
              attachments={attachments}
              disabledControls={[
                'currentPage',
                'downloadFiles',
                'nextPage',
                'previousPage',
              ]}
              pageCss={{
                boxShadow: shadows.elevatedShadow2,
                borderRadius: radii.basic,
              }}
              initialScale={0.9}
              pdfControlsWrapper={({ children }) => (
                <Flex justifyContent="end" gap="space4" paddingRight="space18">
                  {children}
                </Flex>
              )}
            />
          )}
        </Box>
      </Grid>
      <Grid
        position="sticky"
        justifyContent="start"
        bottom="0"
        padding="space16"
        borderTop="1px solid gray250"
      >
        <Button
          variant="tertiary"
          onClick={onOpen}
          icon="newTab"
          iconPosition="right"
        >
          {t('documentPreview.openDocument')}
        </Button>
      </Grid>
    </Grid>
  );
};
