import { Avatar, Grid, Tag, Text } from '@candisio/design-system';
import { Absence, User } from 'generated-types/graphql.types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isOnlyAccountant, isOnlyRequester } from 'utils/authorization';
import { DateFormatters } from 'utils/date_formatter';
import { isAbsenceDateOverlap } from './isAbsenceDateOverlap';

export type SubstituteItemProps = {
  getAbsence: (userId?: string | undefined) => Absence | undefined;
  fromDate?: string | null;
  toDate?: string | null;
} & Pick<User, 'name' | 'avatarUrl' | 'id' | 'roles'>;

export const SubstituteItem = ({
  name,
  avatarUrl,
  id,
  roles,
  fromDate,
  toDate,
  getAbsence,
}: SubstituteItemProps) => {
  const [t] = useTranslation();
  const userAbsence = getAbsence(id);
  const isDisabled = isOnlyRequester({ roles }) || isOnlyAccountant({ roles });

  const substituteStartDate = moment(userAbsence?.fromDate);
  const substituteEndDate = moment(userAbsence?.toDate);

  const overlap = isAbsenceDateOverlap({
    currentUserFrom: fromDate,
    currentUserTo: toDate,
    substituteFrom: userAbsence?.fromDate,
    substituteTo: userAbsence?.toDate,
  });

  const isOverlappingAbsence = !isDisabled && overlap;

  return (
    <Grid
      autoFlow="column"
      justifyContent="start"
      gap="space8"
      alignItems="center"
    >
      <Avatar
        flex="none"
        img={avatarUrl ?? undefined}
        hasTooltip={false}
        name={name}
        size="small"
      />
      <Grid>
        <Grid autoFlow="column" gap="space4" justifyContent="start">
          <Text color={isDisabled ? 'gray500' : undefined}>{name}</Text>
          {isOverlappingAbsence && (
            <>
              <Text>
                {t(
                  'header.profile.tabs.update.absence.form.substitute.separatorSign'
                )}
              </Text>
              <Tag color="yellow" variant="secondary">
                {t(
                  'header.profile.tabs.update.absence.form.substitute.overlappingAbsence'
                )}
              </Tag>
            </>
          )}
          {isDisabled && (
            <>
              <Text>
                {t(
                  'header.profile.tabs.update.absence.form.substitute.separatorSign'
                )}
              </Text>
              <Tag color="gray" variant="secondary">
                {t(
                  'header.profile.tabs.update.absence.form.substitute.insufficientRights'
                )}
              </Tag>
            </>
          )}
        </Grid>
        {isOverlappingAbsence && (
          <Grid>
            <Text color="gray500" fontSize="small">
              {t(
                'header.profile.tabs.update.absence.form.substitute.absenceDates',
                {
                  fromDate: DateFormatters.compact(
                    substituteStartDate.toDate()
                  ),
                  toDate: DateFormatters.compact(substituteEndDate.toDate()),
                }
              )}
            </Text>
          </Grid>
        )}
        {isDisabled && (
          <Grid>
            <Text color="gray500" fontSize="small">
              {t(
                'header.profile.tabs.update.absence.form.substitute.insufficientRightsExplanation'
              )}
            </Text>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
