import { Box, Grid } from '@candisio/design-system';
import { HookFormIbanField } from 'components/HookFormFields/HookFormIbanField';
import { HookFormRadioGroupField } from 'components/HookFormFields/HookFormRadioGroupField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { stripNonNumericChars } from 'utils/stripNonNumericChars';
import { useContactFormIbanWarning } from 'views/Contacts/ContactDetails/ContactForm/useContactFormIbanWarning';
import { useDatev } from '../../../../orgConfig/datev';
import { ContactFormValues, NON_SEPA, SEPA } from './contactFormSchema';

interface ContactFormBankInfoFieldsProps {
  disabled?: boolean;
}

export const ContactFormBankInfoFields = ({
  disabled,
}: ContactFormBankInfoFieldsProps) => {
  const [t] = useTranslation();

  const { bdsBought } = useDatev();

  const ibanWarning = useContactFormIbanWarning();

  const { trigger, watch } = useFormContext<ContactFormValues>();
  const [bankInfoType, swiftCode, bankAccountNumber] = watch([
    'bankInfoType',
    'swiftCode',
    'bankAccountNumber',
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // trigger validations whenever bank info type changes
    void trigger('iban');
    void trigger('swiftCode');
    void trigger('bankAccountNumber');
  }, [bankInfoType, trigger]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // trigger validations for iban/bank account number when swift code changes, e.g. when its removed so that IBAN/Bank account number becomes optional
    void trigger('iban');
    void trigger('bankAccountNumber');
  }, [swiftCode, trigger]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // when bank account number changes, trigger swiftCode validations
    void trigger('swiftCode');
  }, [bankAccountNumber, trigger]);

  return (
    <Grid gap="space16">
      {bdsBought && (
        <Box fontSize="basic" paddingX="space8">
          <HookFormRadioGroupField<ContactFormValues>
            name="bankInfoType"
            label={t('settings.contacts.details.edit.bankInfo.type')}
            options={[
              {
                value: SEPA,
                children: t('settings.contacts.details.edit.bankInfo.sepa'),
                isDisabled: false,
              },

              {
                value: NON_SEPA,
                children: t('settings.contacts.details.edit.bankInfo.nonSepa'),
                isDisabled: false,
              },
            ]}
            orientation="horizontal"
            disabled={disabled}
          />
        </Box>
      )}

      <Grid gap="space16">
        {bankInfoType === 'SEPA' && (
          <HookFormIbanField<ContactFormValues>
            name="iban"
            description={ibanWarning}
            variant={ibanWarning ? 'warning' : undefined}
            label={t('settings.contacts.details.edit.iban.label')}
            disabled={disabled}
          />
        )}
        {bankInfoType === 'NON_SEPA' && (
          <HookFormTextField<ContactFormValues>
            name="bankAccountNumber"
            inputProps={{
              autoComplete: 'off',
            }}
            transform={{
              input: stripNonNumericChars,
              output: stripNonNumericChars,
            }}
            label={t(
              'settings.contacts.details.edit.bankInfo.bankAccountNumber'
            )}
            disabled={disabled}
          />
        )}
        <HookFormTextField<ContactFormValues>
          name="swiftCode"
          inputProps={{
            autoComplete: 'off',
          }}
          label={t('settings.contacts.details.edit.bankInfo.swiftCode')}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
