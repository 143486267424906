import { Box, Flex, TruncatedText, useTheme } from '@candisio/design-system';
import { Dispatch, SetStateAction, memo, useCallback, useRef } from 'react';
import { useOption } from 'react-aria';
import { ListState, SingleSelectListState } from 'react-stately';
import { CCFilterOption } from '../../utils/paginationFilterHooks';

interface ListItemIconMemoizedProps {
  state: ListState<CCFilterOption>;
  item: SingleSelectListState<CCFilterOption>['selectedItem'];
  currentFilters: string[];
  onSetCurrentFilters: Dispatch<SetStateAction<string[]>>;
}

export const ListItemIconMemoized = memo(
  ({
    item,
    state,
    currentFilters,
    onSetCurrentFilters,
  }: ListItemIconMemoizedProps) => {
    const ref = useRef(null);
    const { space } = useTheme();
    const isSelected = currentFilters.includes(item.key.toString());

    const { optionProps } = useOption(
      {
        key: item.key,
        shouldFocusOnHover: true,
        isSelected,
      },
      state,
      ref
    );

    const { onClick, ...restProps } = optionProps;

    const onSelect = useCallback(() => {
      if (isSelected) {
        onSetCurrentFilters(
          currentFilters.filter(
            idSelected => idSelected !== item.key.toString()
          )
        );
      } else {
        onSetCurrentFilters([...currentFilters, item.key.toString()]);
      }
    }, [currentFilters, isSelected, item.key, onSetCurrentFilters]);

    return (
      <Box
        as="li"
        listStyle="none"
        paddingY="space8"
        paddingLeft="space4"
        borderRadius="small"
        focus={{ background: isSelected ? 'gray200' : 'bluebg' }}
        background={isSelected ? 'gray200' : 'inherit'}
        hover={{ background: isSelected ? 'gray200' : 'bluebg' }}
        ref={ref}
        style={{
          cursor: 'pointer',
          marginTop: space.space4,
          marginBottom: space.space4,
          outline: 'none',
        }}
        onClick={onSelect}
        {...restProps}
      >
        <Flex alignItems="center" gap="space8" paddingLeft="space8">
          {item?.value?.icon ? <>{item.value.icon}</> : null}
          {typeof item?.value?.label === 'string' ? (
            <TruncatedText>{item.value.label}</TruncatedText>
          ) : (
            <>{item?.value?.label}</>
          )}
        </Flex>
      </Box>
    );
  }
);
