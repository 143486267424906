import {
  ExportableEntityType,
  ExportableReimbursementItem,
  User,
  PaymentMethod,
} from 'generated-types/graphql.types';
import { ExportEntity } from '../../types';

export type ExportReimbursementItemUser = Pick<
  User,
  'id' | 'lastName' | 'firstName' | 'avatarUrl'
>;

type ReimbursementCase = Pick<
  ExportableReimbursementItem['reimbursementCase'],
  'id' | 'title' | 'submittedForReviewAt'
> & { targetMembership: ExportReimbursementItemUser };

export type ExportReimbursementItem = Pick<
  ExportableReimbursementItem,
  'id' | 'totalAmount' | 'title' | 'type' | 'reimbursementItemDate'
> & { reimbursementCase: ReimbursementCase };

export const mapReimbursementItemToExportEntity = (
  reimbursementItem: ExportReimbursementItem
): ExportEntity => {
  return {
    amount: reimbursementItem.totalAmount,
    id: reimbursementItem.id,
    reason: reimbursementItem.title,
    type: ExportableEntityType.ReimbursementItem,
    reimbusementItemType: reimbursementItem.type,
    reimbursementCollectionTitle: reimbursementItem.reimbursementCase.title,
    reimbursementColletionId: reimbursementItem.reimbursementCase.id,
    contact: '',
    targetMembership: {
      id: reimbursementItem.reimbursementCase.targetMembership.id,
      lastName: reimbursementItem.reimbursementCase.targetMembership.lastName,
      firstName: reimbursementItem.reimbursementCase.targetMembership.firstName,
      avatarUrl: reimbursementItem.reimbursementCase.targetMembership.avatarUrl,
    },
    hasDocument: true,
    invoiceDate: new Date(reimbursementItem.reimbursementItemDate),
    invoiceNumber: '',
    paymentState: {
      isPaid: false,
      method: PaymentMethod.Manual,
      paidAt: '',
    },
  };
};
