import { Button, Grid, Text } from '@candisio/design-system';
import { CardIssuerCardReplacementReason } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ReplaceCardConfirmationDialogProps {
  onClose: () => void;
  replaceReason?: CardIssuerCardReplacementReason;
  loading: boolean;
  onSubmit: () => Promise<void>;
}

export const ReplaceCardConfirmationDialog = ({
  onClose,
  onSubmit,
  loading,
  replaceReason,
}: ReplaceCardConfirmationDialogProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const isReasonStolenOrLost = replaceReason
    ? [
        CardIssuerCardReplacementReason.Stolen,
        CardIssuerCardReplacementReason.Lost,
      ].includes(replaceReason)
    : false;

  const dialogDescription = isReasonStolenOrLost
    ? 'creditCardFormDataPreview.replaceCard.replaceCardDialog.stolenOrLost'
    : 'creditCardFormDataPreview.replaceCard.replaceCardDialog.other';

  return (
    <Grid gap="space16">
      <Text>{t(dialogDescription)}</Text>
      <Grid autoFlow="column" gap="space16" placeContent="end">
        <Button
          size="small"
          variant="secondary"
          onClick={onClose}
          disabled={loading}
        >
          {t('creditCardFormDataPreview.replaceCard.replaceCardDialog.secCta')}
        </Button>
        <Button
          type="submit"
          size="small"
          disabled={loading}
          loading={loading}
          color="red"
          onClick={async () => {
            await onSubmit();
          }}
        >
          {t('creditCardFormDataPreview.replaceCard.replaceCardDialog.pryCta')}
        </Button>
      </Grid>
    </Grid>
  );
};
