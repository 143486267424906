import { Grid, Paragraph, Text } from '@candisio/design-system';
import { MetaDataWrapper } from 'components/DocumentHistory/MetaDataWrapper';
import { ExpenseItemHospitalityReceiptAmountUpdatedEventDataFragment } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { amountFormat } from 'utils/format';

export const ExpenseItemHospitalityReceiptAmountUpdated = ({
  properties,
  reimbursementItemMetadata,
}: ExpenseItemHospitalityReceiptAmountUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const headline = expenseTitle
    ? t('timeline.expenseChanged', { title: expenseTitle })
    : t('timeline.expenseChangedNoTitle');

  return (
    <Grid>
      <Text>{headline}</Text>
      <MetaDataWrapper>
        <div>
          <Text fontWeight="regular" color="gray500">
            {t(
              'reimbursementView.middleSection.form.hospitality.receiptAmount.label'
            )}
          </Text>
          <Paragraph>
            {!isNil(properties.newReceiptAmount)
              ? amountFormat(
                  properties.newReceiptAmount,
                  properties.newCurrency,
                  { convertToBasicMonetaryUnit: true }
                )
              : t('timeline.empty')}
          </Paragraph>
        </div>
      </MetaDataWrapper>
    </Grid>
  );
};
