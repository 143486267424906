import { Modal } from '@candisio/design-system';
import { PulseIndicator } from 'components/PulseIndicator/PulseIndicator';
import { useSegmentDestinations } from 'providers/AnalyticsProvider/useSegmentDestinations';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useIntercomContext } from 'providers/IntercomProvider/IntercomProvider';
import { useTrackingPreferences } from 'providers/PrivacyConsentProvider/hooks/useTrackingPreferences';
import { usePrivacyContext } from 'providers/PrivacyConsentProvider/PrivacyConsentProvider';
import { useState, useCallback } from 'react';
import { NavigationIcon } from '../../NavigationIcon';
import { IntercomPrivacyModalContent } from './IntercomPrivacyModalContent/IntercomPrivacyModalContent';

export const MenuIntercom = ({
  intercomIcon,
  isNavigationOpen,
}: {
  intercomIcon: any;
  isNavigationOpen: boolean;
}) => {
  const destinations = useSegmentDestinations();
  const currentUser = useCurrentUser();

  const { showConsentManager, handleSavePreferences } = usePrivacyContext();
  const { intercomIsEnabled } = useIntercomContext();
  const { trackingEnabled } = useTrackingPreferences();
  const { unreadCount } = useIntercomContext();

  const [isIntercomPrivacyModalOpen, setIsIntercomPrivacyModalOpen] =
    useState(false);

  const handleIntercomToggle = () =>
    handleSavePreferences(trackingEnabled, true);

  const handleModalToggle = useCallback(
    () => setIsIntercomPrivacyModalOpen(isOpen => !isOpen),
    []
  );

  if (!currentUser || destinations.loading) return null;

  const hasUnreadMessages = unreadCount > 0;

  return (
    <>
      <NavigationIcon
        {...intercomIcon}
        key={intercomIcon.label}
        expanded={isNavigationOpen}
        onClick={intercomIsEnabled ? undefined : handleModalToggle}
        data-intercom={intercomIsEnabled ? 'intercom-chat' : undefined}
        pulseIndicator={
          hasUnreadMessages ? (
            <PulseIndicator status="urgent" source="customIntercom" />
          ) : undefined
        }
        count={hasUnreadMessages ? unreadCount : undefined}
        showLabelInTooltip={!isIntercomPrivacyModalOpen}
      />
      <Modal
        isOpen={isIntercomPrivacyModalOpen}
        onClose={handleModalToggle}
        padding={0}
        borderRadius="medium"
        data-cy="intercom-modal"
        maxWidth="378px"
        position="absolute"
        left={isNavigationOpen ? '300px' : '80px'}
        bottom="40px"
        overflow="hidden"
        background="gray800"
        overlayColor="transparent"
        style={{ filter: 'drop-shadow(0 8px 24px rgba(0, 0, 0, 0.2))' }}
      >
        <IntercomPrivacyModalContent
          handlePrivacyModalOpen={() => {
            handleModalToggle();
            showConsentManager();
          }}
          handleIntercomToggle={handleIntercomToggle}
        />
      </Modal>
    </>
  );
};
