import { FilterOption } from '@candisio/design-system';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { DocumentStatus, Maybe, User } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const userIsMonitoring = (currentUser?: Maybe<User>) => () => {
  const { isAccountant, isAdmin, isRequester } = useUserRoles();

  const canProcessDocuments = isAccountant || isAdmin || isRequester;

  return !canProcessDocuments;
};

export const availableFilters: Array<keyof DocumentsTableData> = [
  'status',
  'contact',
  'costCenter',
  'costObject',
  'extraCostInfo',
  'artistSocialInsuranceCode',
  'creditCardPayment',
  'tags',
  'invoiceDate',
  'deliveryDate',
  'dueDate',
  'discountDateWPercentage',
  'createdAt',
] as const;

export const useStatusFilters = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  const [visualiseWaitingForClarificationFF, enableNewIndexInInboxViewFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.visualiseWaitingForClarification,
      FEATURE_FLAGS.enableNewIndexInInboxView,
    ]);

  const statusFilters = useMemo(
    () => ({
      data: Array<FilterOption | false>(
        // TODO: Remove this once we support isDuplicate filter in new ES index
        !enableNewIndexInInboxViewFF && {
          id: 'DUPLICATE',
          label: t('statuses.duplicate'),
        },
        {
          id: DocumentStatus.New,
          label: t('statuses.new'),
        },
        {
          id: 'OVERDUE',
          label: t('statuses.overdue'),
        },
        {
          id: DocumentStatus.Rejected,
          label: t('statuses.rejected'),
        },
        !enableNewIndexInInboxViewFF &&
          visualiseWaitingForClarificationFF && {
            id: 'WAITING_FOR_CLARIFICATION',
            label: t('statuses.waitingForClarification'),
          }
      ).filter(Boolean),
    }),
    [t, visualiseWaitingForClarificationFF, enableNewIndexInInboxViewFF]
  );

  return statusFilters;
};
