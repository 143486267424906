import { Paragraph, Text } from '@candisio/design-system';
import { Collapse } from 'components/History/items/Collapse';
import {
  ExtractedField,
  MetaDataExtractionEventDataFragment,
} from 'generated-types/graphql.types';
import { friendlyFormatIBAN } from 'ibantools';
import { getTranslationContext } from 'orgConfig';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { amountFormatWithoutCurrency } from 'utils/format';
import { MetaDataWrapper } from '../MetaDataWrapper';
import { eInvoiceFormatTranslationKey } from './InvalidEInvoiceDetectedComponent';

const dateFields = new Set([
  'invoiceDate',
  'invoiceDueDate',
  'invoiceDeliveryDate',
  'discountDate',
]);

const amountFields = new Set(['amount', 'discountAmount']);

const FormattedField = ({ field }: { field: ExtractedField }) => {
  const [t] = useTranslation();

  if (dateFields.has(field?.key as string)) {
    return field?.value ? (
      <Paragraph>{DateFormatters.compact(new Date(field.value))}</Paragraph>
    ) : null;
  }

  if (field?.value === 'true' || field?.value === 'false') {
    return (
      <Paragraph>
        <Trans
          i18nKey={`document.compact.history.metaExtracted.${field.value}`}
        />
      </Paragraph>
    );
  }

  if (amountFields.has(field?.key as string)) {
    const formattedAmount = amountFormatWithoutCurrency(Number(field?.value));

    return <Paragraph>{formattedAmount}</Paragraph>;
  }

  if (field.key === 'iban' && field.value) {
    const formattedIban = friendlyFormatIBAN(field.value);

    return <Paragraph>{formattedIban}</Paragraph>;
  }

  if (field.key === 'eInvoiceFormat') {
    return (
      <Paragraph>
        {t(eInvoiceFormatTranslationKey(field.value, field.value))}
      </Paragraph>
    );
  }

  return <Paragraph>{field.value}</Paragraph>;
};

export const MetaExtractedComponent = ({
  extractedFields,
}: MetaDataExtractionEventDataFragment) => {
  const fieldCount = extractedFields ? extractedFields.length : 0;
  const [t] = useTranslation();
  const title = t('document.compact.history.metaExtracted.title', {
    count: fieldCount,
  });

  return (
    <>
      <Text fontSize="small" lineHeight="paragraph">
        {title}
      </Text>
      <Collapse>
        <MetaDataWrapper>
          {extractedFields &&
            extractedFields.map(field => {
              if (!field || !field.key) {
                return null;
              }

              return (
                <div key={field.key}>
                  <Text fontWeight="regular" color="gray500">
                    {t(
                      `document.compact.history.metaExtracted.labels.${field.key}`,
                      {
                        context: getTranslationContext(),
                      }
                    )}
                  </Text>
                  <FormattedField field={field} />
                </div>
              );
            })}
        </MetaDataWrapper>
      </Collapse>
    </>
  );
};
