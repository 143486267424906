import {
  Box,
  Button,
  Grid,
  MenuButton,
  mergeProps,
  mergeRefs,
  Modal,
  Text,
} from '@candisio/design-system';
import { AttachmentsTableContainer } from 'components/DocumentViewer/AttachmentsTable/AttachmentsTable';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { CanAddAttachments } from 'hooks/useAttachments/useCanAddAttachments';
import { AppRouteParams, Routes } from 'models';
import { forwardRef, HTMLAttributes, useRef, useState } from 'react';
import { useListBox, useListBoxSection, AriaMenuProps } from 'react-aria';
import { useTranslation } from 'react-i18next';
import {
  Item,
  ListState,
  Section,
  SingleSelectListState,
  useListState,
} from 'react-stately';
import { useDisabledAttachmentButtonLabelText } from 'views/Inbox/DocumentProcessing/util/useDisabledAttachmentButtonLabelText';
import { Attachment, AttachmentItem } from './AttachmentItem';
import { useFilesSelected } from './useFilesSelected';

export interface RouteParams {
  [AppRouteParams.organizationSlug]: string;
  documentId: string;
  type: Routes;
}
/** @ts-expect-error TODO: React upgrade props types mismatch */
export interface MenuProps extends HTMLAttributes<HTMLUListElement> {
  /** Where the focus should be set */
  autoFocus?: AriaMenuProps<Attachment>['autoFocus'];
  /** List of menu items */
  items?: AriaMenuProps<Attachment>['items'];
  /** MenuItems components or item render function */
  children?: AriaMenuProps<Attachment>['children'];
  /** Menu element's unique identifier */
  id?: AriaMenuProps<Attachment>['id'];
  /** Identifies the element (or elements) that labels the menu */
  'aria-labelledby'?: AriaMenuProps<Attachment>['aria-labelledby'];
  /** Type of selection allowed among the menu items*/
  selectionMode?: AriaMenuProps<Attachment>['selectionMode'];
  /** Keys of the currently selected items in the menu */
  selectedKeys?: AriaMenuProps<Attachment>['selectedKeys'];
  /** Called when the selection changes */
  onSelectionChange?: AriaMenuProps<Attachment>['onSelectionChange'];
  documentId: string;
  canAttachFiles?: CanAddAttachments;
  selectedFile: PDFDetails;
  // @TODO define handle upload and use that implementation here and in DocumentViewer,
  // then remove `attachments` and `documentFile` from here
  attachments?: PDFDetails[];
  documentFile: PDFDetails;
}

interface AttachmentMenuSectionProps {
  section: SingleSelectListState<Attachment>['selectedItem'];
  state: ListState<Attachment>;
  selectedFile: PDFDetails;
}

const AttachmentMenuSection = ({
  section,
  state,
  selectedFile,
}: AttachmentMenuSectionProps) => {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  const childNodes = [...section.childNodes];

  return (
    <Box as="li" {...itemProps} width="100%">
      {section?.rendered && (
        <Text
          {...headingProps}
          fontSize="small"
          color="gray500"
          lineHeight="body"
          textTransform="uppercase"
        >
          {section.rendered}
        </Text>
      )}
      {childNodes.length > 0 && (
        <Box
          as="ul"
          {...groupProps}
          padding="0"
          paddingTop="space8"
          style={{ listStyle: 'none' }}
        >
          {childNodes.map(node => (
            <AttachmentItem
              key={node.key}
              item={node}
              state={state}
              isDetachable={
                node.parentKey !== 'mainDocument' && node?.value?.isDetachable
              }
              isRemovable={
                node.parentKey !== 'mainDocument' && node?.value?.isRemovable
              }
              onAction={node.value?.onAction}
              selectedFile={selectedFile}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

const defaultRenderChildren = (secOrItem: Attachment) => {
  if (secOrItem.items) {
    return (
      <Section
        key={secOrItem.id}
        title={secOrItem.label}
        items={secOrItem.items}
      >
        {item => <Item key={item.id}>{item.label}</Item>}
      </Section>
    );
  }

  return <Item key={secOrItem.id}>{secOrItem.label}</Item>;
};

const commonAttachmentButtonProps = {
  size: 'small',
  icon: 'plus',
  iconPosition: 'left',
  variant: 'tertiary',
} as const;

export const Attachments = forwardRef<HTMLUListElement, MenuProps>(
  (
    {
      'aria-labelledby': ariaLabelledBy,
      id,
      autoFocus,
      children = defaultRenderChildren,
      items,
      selectedFile,
      selectionMode = 'single',
      selectedKeys,
      onSelectionChange,
      canAttachFiles = { fromCandis: false, fromDisk: false },
      documentId,
      documentFile,
      attachments,
      ...restProps
    },
    forwardRef
  ) => {
    const [t] = useTranslation();

    const state = useListState({
      children,
      items,
      selectionMode,
      selectedKeys,
    });

    const canAttach = canAttachFiles.fromCandis || canAttachFiles.fromDisk;

    const canAttachFromAnywhere =
      canAttachFiles.fromCandis && canAttachFiles.fromDisk;

    const canOnlyAttachFromDisk =
      canAttachFiles.fromDisk && !canAttachFiles.fromCandis;

    const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);
    const openModal = () => setAttachmentModalIsOpen(true);
    const closeModal = () => setAttachmentModalIsOpen(false);

    const ref = useRef(null);
    const { listBoxProps } = useListBox(
      {
        id,
        autoFocus,
        items,
        'aria-labelledby': ariaLabelledBy,
        onAction(key) {
          state.collection.getItem(key)?.value?.onAction?.(key);
        },
      },
      state,
      ref
    );

    const { onAttachFiles, isAttachingFiles } = useFilesSelected(
      attachments,
      documentFile,
      documentId
    );

    const fileUploadRef = useRef<HTMLInputElement>(null);

    const onUploadFileClick = () => {
      fileUploadRef.current?.click();
    };

    const handleFileUpload = () => {
      closeModal();
      onUploadFileClick();
    };

    const onFilesChanged = () => {
      if (!fileUploadRef.current?.files) return;

      onAttachFiles(Array.from(fileUploadRef.current.files));
      fileUploadRef.current.value = '';
    };

    const disabledLabel = useDisabledAttachmentButtonLabelText(canAttach);

    return (
      <Grid
        as="ul"
        gap="space16"
        placeItems="start"
        {...mergeProps(listBoxProps, restProps)}
        ref={mergeRefs([ref, forwardRef])}
        style={{ padding: 0, margin: 0, listStyle: 'none' }}
      >
        {Array.from(state.collection).map(item =>
          item.hasChildNodes ? (
            <AttachmentMenuSection
              key={item.key}
              section={item}
              state={state}
              selectedFile={selectedFile}
            />
          ) : (
            <AttachmentItem
              key={item.key}
              item={item}
              state={state}
              isDetachable={item?.value?.isDetachable}
              onAction={item?.value?.onAction}
              selectedFile={selectedFile}
            />
          )
        )}

        {(canOnlyAttachFromDisk || !canAttach) && (
          <Button
            size="small"
            icon="plus"
            iconPosition="left"
            variant="tertiary"
            loading={isAttachingFiles}
            label={disabledLabel}
            disabled={!canAttach || isAttachingFiles}
            onClick={onUploadFileClick}
          >
            {t('document.tabs.oldAttachments.addAttachment')}
          </Button>
        )}
        {canAttachFromAnywhere && (
          <MenuButton
            items={[
              {
                id: 'fromCandis',
                label: t('document.attachments.attachPdfFromDisk'),
                onAction: onUploadFileClick,
              },
              {
                id: 'fromDisk',
                label: t('document.attachments.attachPdfFromCandis'),
                onAction: openModal,
              },
            ]}
            {...commonAttachmentButtonProps}
            selectionMode="single"
            loading={isAttachingFiles}
            label={disabledLabel}
            disabled={!canAttach || isAttachingFiles}
          >
            {t('document.tabs.oldAttachments.addAttachment')}
          </MenuButton>
        )}

        <input
          id="attachment-upload-input"
          type="file"
          accept="application/pdf"
          ref={fileUploadRef}
          onChange={onFilesChanged}
          disabled={!canAttach}
          multiple
          hidden
        />

        <Modal
          background="gray200"
          closeLabel={t('common.close')}
          width="80vw"
          isOpen={attachmentModalIsOpen}
          onClose={closeModal}
          padding="0"
          scrollDirection="none"
          title={t('document.attachments.attachPdfFromCandis')}
        >
          <AttachmentsTableContainer
            documentId={documentId}
            onDocumentsAttached={closeModal}
            onUploadFileClick={handleFileUpload}
          />
        </Modal>
      </Grid>
    );
  }
);
