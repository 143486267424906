import {
  DocumentConvertedEventDataFragment,
  DocumentType,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { useEcmDocumentTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmDocumentTypeItems';

export const DocumentConvertedComponent = ({
  convertedFrom,
  documentType,
}: DocumentConvertedEventDataFragment) => {
  const [t] = useTranslation();
  const { ecmDocumentTypeTranslationMap } = useEcmDocumentTypeOptions();
  if (!documentType || !convertedFrom) return null;

  const documentTypeTranslated =
    ecmDocumentTypeTranslationMap[documentType as DocumentType];
  const convertedFromTranslated =
    ecmDocumentTypeTranslationMap[convertedFrom as DocumentType];

  const i18nKey = 'document.compact.history.documentConverted';

  return t(i18nKey, {
    documentType: documentTypeTranslated,
    convertedFrom: convertedFromTranslated,
  });
};
