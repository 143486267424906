// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

type Props = {
  inverted?: boolean;
};

// Expand wrapped content on item click
// @param {boolean} inverted - content should be accessible by default but collapse on click
export const Collapse = styled.div.attrs<Props>(({ inverted }) => ({
  className: inverted ? 'inverted' : '',
}))<Props>``;
