import {
  Grid,
  Icon,
  TextField,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { motion } from 'framer-motion';

import { useTranslation } from 'react-i18next';

const MotionGrid = motion(Grid);

export interface SearchFieldProps {
  autoFocus?: boolean;
  clearTooltip?: string;
  inputMaxLength?: number;
  inputSize?: number;
  placeholder: string;
  value: string;
  readOnly?: boolean;
  onChange: (filter: string) => void;
}

export const SearchField = ({
  autoFocus,
  clearTooltip,
  inputMaxLength,
  inputSize,
  placeholder,
  value,
  readOnly = false,
  onChange,
}: SearchFieldProps) => {
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  const transition = {
    default: { duration: 0.25, ease: 'easeInOut' },
  };

  const variants = {
    visible: { opacity: 1, scale: 1, visibility: 'visible' },
    hidden: { opacity: 0, scale: 0.6, visibility: 'hidden' },
    hover: { cursor: 'pointer', color: 'rgba(43, 26, 46, 0.6)' },
  };

  const [t] = useTranslation();

  return (
    <Grid alignItems="center" alignContent="center">
      <TextField
        aria-label={t('search.label')}
        readOnly={readOnly}
        input={{
          autoFocus,
          style: { maxWidth: inputSize ? 'auto' : '85%' },
          size: inputSize,
          placeholder: placeholder,
          readOnly: readOnly,
          value: value,
          onChange: e => onChange(e.target.value),
          maxLength: inputMaxLength,
        }}
      />
      <MotionGrid
        alignContent="center"
        justifySelf="end"
        padding="space12 space16"
        position="absolute"
        animate={value.length === 0 ? 'visible' : 'hidden'}
        transition={transition}
        variants={variants}
      >
        <Icon icon="search" size="space16" />
      </MotionGrid>
      <MotionGrid
        as="button"
        type="button"
        aria-label={t('search.clear')}
        alignContent="center"
        justifySelf="end"
        background="transparent"
        border="none"
        opacity="0"
        padding="space12 space16"
        position="absolute"
        animate={value.length > 0 ? 'visible' : 'hidden'}
        whileHover="hover"
        transition={transition}
        variants={variants}
        {...triggerProps}
        onClick={() => onChange('')}
        ref={triggerRef}
      >
        <Icon icon="close" size="space16" />
      </MotionGrid>
      {clearTooltip && isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {clearTooltip}
        </Tooltip>
      )}
    </Grid>
  );
};
