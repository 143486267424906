import { Grid, TextField } from '@candisio/design-system';
import {
  New_DatevSettingsQuery,
  useDisconnectDatevClientMutation,
} from 'generated-types/graphql.types';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from 'views/Settings/utils';
import { useDatev } from '../../../../../../orgConfig/datev';
import { refetchDatevDataWithNoToken } from '../../gql';
import { DcsContainer } from '../DcsContainer/DcsContainer';
import { AccountingCoreDataContainer } from './AccountingCoreDataContainer';
import { ConnectionDetails } from './components/ConnectionDetails';
import { DisconnectButton } from './components/DisconnectButton';

export type ConnectedClientDetailsContainerProps = {
  settingsData?: New_DatevSettingsQuery;
};

export const ConnectedClientDetailsContainer = ({
  settingsData,
}: ConnectedClientDetailsContainerProps) => {
  const [t] = useTranslation();
  const hasAuthTokenForReadClients =
    settingsData?.new_datevSettings?.hasAuthTokenForReadClients;

  const hasAuthTokenForExport =
    settingsData?.new_datevSettings?.hasAuthTokenForExportDocuments;

  const [disconnectDatevClient, { loading: disconnectLoading }] =
    useDisconnectDatevClientMutation();

  const { bdsConnected, bdsBought } = useDatev(); // BDS-checked

  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  const client = settingsData?.new_datevSettings?.client!;
  const onDisconnectDatevClient = useCallback(() => {
    void disconnectDatevClient({
      refetchQueries: [
        ...refetchDatevDataWithNoToken,
        { query: getFullOrganizationQuery },
      ],
    });
  }, [disconnectDatevClient]);

  const software =
    bdsConnected && bdsBought
      ? t('settings.datev.connect.connectToDatev.datevRewe')
      : t('settings.datev.connect.connectToDatev.datevDUO');

  const { connectedAt, connectedBy } =
    settingsData?.new_datevSettings?.client ?? {};

  return (
    <Grid
      id="connected-datev-client-container"
      rowGap="space12"
      templateColumns={`${SETTINGS_VIEW_DEFAULT_WIDTH} 1fr`}
    >
      <DcsContainer
        title={t('settings.datev.connect.title')}
        details={
          <Grid gap="space24" width="100%" paddingTop="space4">
            <Grid gap="space12">
              <Grid gap="space24" templateColumns="1fr 1fr">
                <Grid gap="space8">
                  <ClientDetail
                    title={t(
                      'settings.datev.connect.connectedClient.clientName'
                    )}
                    description={client.name}
                  />
                  <ClientDetail
                    title={t(
                      'settings.datev.connect.connectedClient.consultantNumber'
                    )}
                    description={client.consultantNumber}
                  />
                </Grid>
                <Grid gap="space8">
                  <ClientDetail
                    title={t(
                      'settings.datev.connect.connectedClient.clientNumber'
                    )}
                    description={client.clientNumber}
                  />
                  <ClientDetail
                    title={t('settings.datev.connect.connectedClient.software')}
                    description={software}
                  />
                </Grid>
              </Grid>

              <ConnectionDetails
                connectedAt={connectedAt}
                connectedBy={connectedBy}
              />
            </Grid>

            <AccountingCoreDataContainer />
          </Grid>
        }
        actions={
          (hasAuthTokenForExport || hasAuthTokenForReadClients) && (
            <DisconnectButton
              loading={disconnectLoading}
              disconnect={onDisconnectDatevClient}
            />
          )
        }
      />
    </Grid>
  );
};

interface ClientDetailProps {
  title: string;
  description: string;
}

const ClientDetail = ({ title, description }: ClientDetailProps) => (
  <Grid gap="space4">
    <TextField
      input={{
        value: description,
      }}
      label={title}
      readOnly
    />
  </Grid>
);
