import { Button, usePopover } from '@candisio/design-system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionConfirm } from './ActionConfirm';
import { Attachment, attachmentItemTransition } from './AttachmentItem';
import { useDeleteAction } from './hooks/useDeleteAction';

export const DeleteActionButton = ({
  attachment,
  isRemovable = false,
  isFocused,
  isSelected,
}: {
  attachment: Attachment;
  isRemovable?: boolean;
  isFocused: boolean;
  isSelected: boolean;
}) => {
  const [t] = useTranslation();

  const [isDeletePending, setIsDeletePending] = useState(false);
  const handleDelete = useDeleteAction(attachment, setIsDeletePending);

  const {
    close: closePopover,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover();

  const isVisible = isFocused || isSelected;

  const warningText = t(
    'document.compact.history.documentAttachment.remove.warning'
  );

  return (
    <>
      <Button
        {...triggerProps}
        ref={triggerRef}
        data-test-id="delete-attachments-button"
        color="red"
        size="small"
        variant="tertiary"
        disabled={!isRemovable || isDeletePending}
        opacity={isVisible ? '1' : '0'}
        visibility={isRemovable && isVisible ? 'visible' : 'hidden'}
        style={{ transition: attachmentItemTransition }}
      >
        {t('document.tabs.oldAttachments.removeBtn')}
      </Button>
      <ActionConfirm
        ref={popoverRef}
        informationText={warningText}
        popoverProps={popoverProps}
        isActionPending={isDeletePending}
        closePopover={closePopover}
        handleConfirm={handleDelete}
      />
    </>
  );
};
