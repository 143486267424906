import { Box, Card, Text, Button, Grid } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface SelectAllPaymentsProps {
  selectedCount: number;
  totalCount: number;
  payableCount: number;
  onSelectAll: (value: boolean) => void;
  exportAll: boolean;
}

export const SelectAllPayments = ({
  selectedCount,
  totalCount,
  payableCount,
  onSelectAll,
  exportAll,
}: SelectAllPaymentsProps) => {
  const [tPayments] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  return (
    <Card background="bluebg" boxShadow="none" padding="space8">
      <Grid alignItems="center" templateColumns="1fr auto" gap="space16">
        <Box paddingLeft="space8" fontSize="small">
          <Text fontWeight="semibold">
            {exportAll ? payableCount : selectedCount}
          </Text>{' '}
          {tPayments('selectAll.selectedMessage', {
            count: totalCount,
          })}{' '}
          {totalCount - payableCount > 0 && (
            <span style={{ display: 'inline-block' }}>
              {tPayments('selectAll.nonPayableMessage', {
                count: totalCount - payableCount,
              })}
            </span>
          )}
        </Box>
        <Button
          variant="primary"
          color="blue"
          size="medium"
          onClick={() =>
            onSelectAll(!(exportAll || selectedCount === payableCount))
          }
        >
          {exportAll || selectedCount === payableCount
            ? tPayments('selectAll.clearButton')
            : tPayments('selectAll.button', {
                count: payableCount,
              })}
        </Button>
      </Grid>
    </Card>
  );
};
