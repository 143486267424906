import { MenuItem } from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PDFDetails } from '../utils';
import { DownloadButton } from './DownloadButton';
import { DownloadMenuItem } from './DownloadMenuItem';
import { DownloadItem, useDownload } from './useDownload';

interface DownloadSectionProps {
  documentFile: PDFDetails;
  attachments?: PDFDetails[];
  selectedFile?: PDFDetails;
}

export const DownloadSection = ({
  documentFile,
  selectedFile,
  attachments,
}: DownloadSectionProps) => {
  const [t] = useTranslation();
  const xRechnungFF = useCandisFeatureFlags(
    FEATURE_FLAGS.allowProcessingOfXrechnungEinvoices
  );

  const { downloadSingle, downloadZip, loading } = useDownload();

  const downloadMenuItems = useMemo<MenuItem[]>(() => {
    if (!xRechnungFF) {
      const fileToDownload = selectedFile ?? documentFile;
      return [
        {
          id: fileToDownload.url,
          label: fileToDownload.name,
          onAction() {
            window.open(fileToDownload.url, '_blank', 'noopener noreferrer');
          },
        },
      ];
    }

    const allFiles: DownloadItem[] = [
      {
        name: documentFile.name,
        url: documentFile.url,
      },
    ];
    const items: MenuItem[] = [
      {
        id: documentFile.url,
        label: documentFile.name,
        renderItem: itemProps => (
          <DownloadMenuItem key={itemProps.id} {...itemProps} icon="pdf" />
        ),
        async onAction() {
          await downloadSingle(documentFile.url, documentFile.name);
        },
      },
    ];

    if (documentFile?.eInvoice) {
      const { eInvoice } = documentFile;
      allFiles.push({
        name: eInvoice.name,
        url: eInvoice.url,
      });

      items.push({
        id: eInvoice.url,
        label: eInvoice.name,
        renderItem: itemProps => (
          <DownloadMenuItem key={itemProps.id} {...itemProps} icon="xml" />
        ),
        async onAction() {
          await downloadSingle(eInvoice.url, eInvoice.name);
        },
      });
    }

    if ((attachments ?? []).length > 0) {
      items[items.length - 1].showSeparator = true;
    }

    for (const attachment of attachments ?? []) {
      allFiles.push({
        name: attachment.name,
        url: attachment.url,
        isAttachment: true,
      });

      items.push({
        id: attachment.url,
        label: attachment.name,
        renderItem: itemProps => (
          <DownloadMenuItem
            key={itemProps.id}
            {...itemProps}
            icon="attachment"
          />
        ),
        async onAction() {
          await downloadSingle(attachment.url, attachment.name);
        },
      });
    }

    if (items.length > 1) {
      items[items.length - 1].showSeparator = true;

      items.push({
        id: 'download-all',
        label: t('document.downloadAll'),
        renderItem: itemProps => (
          <DownloadMenuItem
            key={itemProps.id}
            {...itemProps}
            icon="arrowDownCircle"
          />
        ),
        async onAction() {
          await downloadZip(allFiles);
        },
      });
    }

    return items;
  }, [
    attachments,
    documentFile,
    selectedFile,
    xRechnungFF,
    downloadSingle,
    downloadZip,
    t,
  ]);

  return (
    <DownloadButton loading={loading} menuItems={downloadMenuItems}>
      {t('document.downloadPdf')}
    </DownloadButton>
  );
};
