import { AccountingInfoFieldOptions } from 'containers/credit-cards/CreateCreditCard/AccountingInfoStep/utils/types';
import { CostCenterTypes } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  useApproversFieldOptions,
  useArtistInsuranceCodeFieldOptions,
  useTaxCodesFieldOptions,
  useWorkFlowsFieldOptions,
} from './AccountingInfoFormFieldData';
import { useCostCenterFieldOptions } from './AccountingInfoFormFieldData/useCostCenterFieldOptions';
import { useAccountPayableFieldOptions } from './useAccountPaybleFieldOptions';
import { useContactFieldOptions } from './useContactFieldOptions';

interface UrlParams {
  creditCardId: string;
}

export const useAccountingInfoFormFieldOptions = () => {
  const sap = useSap();

  const { creditCardId } = useParams<UrlParams>();

  const { isOnlyApprover } = useUserRoles();

  const organization = useFullOrganization();

  const isCreateCardMode = creditCardId === 'request';

  const artistSocialInsuranceAndExtraCostInfoFF = useCandisFeatureFlags(
    FEATURE_FLAGS.artistSocialInsuranceAndExtraCostInfo
  );

  const generalLedgerAccount = useAccountPayableFieldOptions();

  const contact = useContactFieldOptions();
  const taxCode = useTaxCodesFieldOptions();
  const workflow = useWorkFlowsFieldOptions();
  const approvers = useApproversFieldOptions();
  const artistSocialInsuranceCode = useArtistInsuranceCodeFieldOptions();

  const hasActiveAccountPayableNumbers =
    organization?.hasActiveBookingAccounts ?? false;

  const hasExtraCostInfos = organization?.hasExtraCostInfos ?? false;

  const hasTaxCodes = organization?.hasActiveBookingKeys ?? false;

  const hasWorkflows = organization?.hasActiveWorkflowTemplates ?? false;

  const hasActiveCostCenters = organization?.hasCostCenters ?? false;

  const hasActiveCostObjects = organization?.hasCostObjects ?? false;

  const hasArtistSocialInsuranceCodes = artistSocialInsuranceAndExtraCostInfoFF;

  const isGeneralLedgerEditable =
    (isOnlyApprover && !isCreateCardMode) || !isOnlyApprover;

  const costCenter = useCostCenterFieldOptions({
    type: CostCenterTypes.CostCenter,
  });

  const costObject = useCostCenterFieldOptions({
    type: CostCenterTypes.CostObject,
  });

  const extraCostInfo = useCostCenterFieldOptions({
    type: CostCenterTypes.ExtraCostInfo,
    skip: sap.isActive || !organization?.hasExtraCostInfos,
  });

  const fieldOptions: AccountingInfoFieldOptions = {
    approvers: {
      options: approvers,
      isVisible: true,
    },
    contact: {
      options: contact,
      isVisible: true,
    },
    generalLedgerAccount: {
      options: generalLedgerAccount,
      isVisible: hasActiveAccountPayableNumbers && isGeneralLedgerEditable,
    },
    costCenter: { options: costCenter, isVisible: hasActiveCostCenters },
    costObject: { options: costObject, isVisible: hasActiveCostObjects },
    taxCode: {
      options: taxCode,
      isVisible: hasTaxCodes,
    },
    artistSocialInsuranceCode: {
      options: artistSocialInsuranceCode,
      isVisible: hasArtistSocialInsuranceCodes && !sap.isActive,
    },
    extraCostInfo: {
      options: extraCostInfo,
      isVisible:
        artistSocialInsuranceAndExtraCostInfoFF &&
        hasExtraCostInfos &&
        !sap.isActive,
    },
    workflow: { options: workflow, isVisible: hasWorkflows },
  };

  return fieldOptions;
};
