import { ElementType, forwardRef } from 'react';
import { mergeProps } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { Box } from '../../../Atoms/Box';
import { Flex } from '../../../Atoms/Flex';
import { Tooltip, useTooltip } from '../../../Atoms/Tooltip';
import { TextProps } from '../../../Atoms/Typography';
import { useOverflow } from '../../../Molecules/Typography/TruncatedText/useOverflow';
import { isChromium } from '../../../utils/browserHelpers';
import { HighlightedText } from './HighlightedText';

const DEFAULT_ELEMENT = 'span';

export interface HighlightResult {
  text: string;
  matches: {
    offset: number;
    length: number;
  }[];
}

export type HighlightedTruncatedTextProps<
  TElement extends ElementType = typeof DEFAULT_ELEMENT,
> = Omit<TextProps<TElement>, 'overflow'> & {
  charsAfterEllipsis?: number;
  lineClamp?: number;
  highlight: HighlightResult;
};

type HighlightedTruncatedTextType = <
  TElement extends ElementType = typeof DEFAULT_ELEMENT,
>(
  props: HighlightedTruncatedTextProps<TElement>
) => React.ReactElement | null;

/**
 * Text that cuts off after a given number of lines. An ellipsis (…) is
 * displayed at the cut-off point. A tooltip displays the full text on hover.
 *
 * Highlights search results with `highlight` prop.
 *
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/molecules-typography-truncatedtext}
 *
 * @param {number} [lineClamp = 1] Number of lines after which to cut off the text
 * @param {normal | break-all} [wordBreak = 'normal'] Break lines only between words or anywhere, forced to 'break-all' when setting charsAfterEllipsis
 * @param {number} [charsAfterEllipsis = 0] Number of characters you want to display after truncation
 */
export const HighlightedTruncatedText = forwardRef(
  <TElement extends ElementType>(
    {
      lineClamp = 1,
      charsAfterEllipsis = 0,
      wordBreak = 'break-all',
      highlight,
      ...restProps
    }: HighlightedTruncatedTextProps<TElement>,
    forwardedRef: typeof restProps.ref
  ) => {
    const { overflowing, overflowRef } = useOverflow();

    const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
      useTooltip({ passiveTrigger: true, delay: 1000 });

    const searchQuery = highlight.text;

    // only truncate in the middle if there are at least 3 more chars in the full string than should be displayed
    // after ellipsis and if browser is Chromium based (current implementation caused flickering in other browsers)
    if ((searchQuery.length ?? 0) - 2 <= charsAfterEllipsis || !isChromium) {
      charsAfterEllipsis = 0;
    }

    let searchResults: string[] = [];

    for (let i = 0; i < highlight.matches.length; i++) {
      searchResults.push(
        searchQuery.slice(
          highlight.matches[i].offset,
          highlight.matches[i].offset + highlight.matches[i].length
        )
      );
    }

    const searchResultsRegEx = new RegExp(
      `(${searchResults.join('|') ?? ''})`,
      'gi'
    );

    const textPreTruncation = searchQuery.slice(
      0,
      searchQuery.length - charsAfterEllipsis
    );

    const textPostTruncation = charsAfterEllipsis
      ? searchQuery.slice(-charsAfterEllipsis)
      : '';

    const querySlices = searchQuery.split(searchResultsRegEx);
    const querySlicesPreTrunc = textPreTruncation.split(searchResultsRegEx);
    const querySlicesPostTrunc = textPostTruncation.split(searchResultsRegEx);

    return (
      <>
        <Box
          as={DEFAULT_ELEMENT}
          {...(overflowing
            ? (mergeProps(restProps, triggerProps) as Record<string, unknown>)
            : restProps)}
          // See https://css-tricks.com/almanac/properties/l/line-clamp/
          css={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: lineClamp,
            // not allowing to break after any character can cause layout issues when truncating in the middle, therefore we force break-all in that case
            wordBreak: charsAfterEllipsis
              ? 'break-all'
              : lineClamp > 1
                ? 'break-word'
                : wordBreak,
            // prevent post truncation string to push the last line of text down when resizing smaller
            minWidth:
              textPostTruncation.length > 0
                ? `${textPostTruncation.length + 3}ch`
                : undefined,
            textAlign: 'start',
          }}
          ref={mergeRefs([forwardedRef, triggerRef, overflowRef])}
        >
          {overflowing ||
          querySlicesPreTrunc.length ||
          querySlicesPostTrunc.length ? (
            <>
              {/* After hours of research this seems like the cleanest non-JS solution
                (since `line-clamp` works really well, I was hesitant to get rid of it):
                This adds as many lines as necessary to push the post truncation string (which is only displayed on overflow)
                into the last line of the entire TruncatedText block */}
              {[...Array(lineClamp - 1)].map((_, index) => (
                <span
                  key={index}
                  css={{
                    float: 'right',
                    clear: 'both',
                  }}
                >
                  &nbsp;
                </span>
              ))}
              {querySlicesPostTrunc.map((slice, index) => {
                return (
                  <HighlightedText
                    key={index}
                    isHighlighted={searchResults.includes(slice)}
                    style={{
                      whiteSpace: 'nowrap',
                      float: 'right',
                      clear: 'both',
                    }}
                  >
                    {slice}
                  </HighlightedText>
                );
              })}

              {querySlicesPreTrunc.map((slice, index) => {
                return (
                  <HighlightedText
                    key={index}
                    isHighlighted={searchResults.includes(slice)}
                  >
                    {slice}
                  </HighlightedText>
                );
              })}
            </>
          ) : (
            <Flex>
              {querySlices.map((slice, index) => {
                return (
                  <HighlightedText
                    key={index}
                    isHighlighted={searchResults.includes(slice)}
                  >
                    {slice}
                  </HighlightedText>
                );
              })}
            </Flex>
          )}
        </Box>
        {overflowing && isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {querySlices.map((slice, index) => {
              return (
                <HighlightedText
                  key={index}
                  isHighlighted={searchResults.includes(slice)}
                >
                  {slice}
                </HighlightedText>
              );
            })}
          </Tooltip>
        )}
      </>
    );
  }
) as HighlightedTruncatedTextType;
