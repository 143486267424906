import { Button, Card, Grid, Text } from '@candisio/design-system';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ArchiveAction } from './ArchiveAction';

interface RejectedStatusFooterActionBoxProps {
  createdById?: string;
}

export const RejectedStatusFooterActionBox = ({
  createdById,
}: RejectedStatusFooterActionBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const currentUserId = useCurrentUser()?.id || '';

  const isReimbursementOwner = createdById === currentUserId;

  if (!isReimbursementOwner) return null;

  return (
    <Card corners="top" boxShadow="elevatedShadow3" padding="space20">
      <Grid gap="space8">
        <Text color="gray500" fontWeight="semibold" textTransform="uppercase">
          {t('reimbursementView.rightSection.footerActions.title')}
        </Text>
        <Grid gap="space8">
          <Button color="blue">
            {t('reimbursementView.rightSection.rejectFooterAction.submit')}
          </Button>
          <ArchiveAction
            loading={false}
            disabled={false}
            onArchive={() => Promise.resolve()}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
