import { Spinner } from '@candisio/design-system';
import { CheckmarkIcon, ErrorIcon } from 'components/Icons/DefaultIcons';
import i18next from 'i18next';
import { CheckProp } from './CheckList';

type GetCheckProps = {
  loading: CheckProp;
  client: {
    success: CheckProp;
    error: CheckProp;
  };
  rds: {
    success: CheckProp;
    error: CheckProp;
  };
  bds: {
    success: CheckProp;
    error: CheckProp;
  };
  cashLedger: {
    success: CheckProp;
    error: CheckProp;
  };
  creditCard: {
    success: CheckProp;
    error: CheckProp;
  };
  creditCardFF: {
    success: CheckProp;
    error: CheckProp;
  };
  serviceConnected: {
    success: CheckProp;
  };
  network: {
    failure: CheckProp;
  };
};

const Loading = <Spinner />;
const Success = <CheckmarkIcon flex="none" size="space16" />;
const Error = <ErrorIcon flex="none" size="space16" />;

export const isSuccessStateCashAccountAdded = (check: CheckProp) =>
  check.icon === Success || check.key === 'cashAccountAdded';

export const getChecks = (t: i18next.TFunction): GetCheckProps => {
  return {
    loading: {
      key: 'checkingService',
      icon: Loading,
      text: t('creditCardExporting.checks.checkingService'),
      state: 'loading',
    },
    client: {
      success: {
        key: 'isClientConnected',
        icon: Success,
        text: t('creditCardExporting.checks.isClientConnected.success'),
        state: 'loaded',
      },
      error: {
        key: 'isClientConnected',
        icon: Error,
        text: t('creditCardExporting.checks.isClientConnected.error'),
        state: 'loaded',
      },
    },
    rds: {
      success: {
        key: 'isRDSConnected',
        icon: Success,
        text: t('creditCardExporting.checks.isRDSConnected.success'),
        state: 'loaded',
      },
      error: {
        key: 'isRDSConnected',
        icon: Error,
        text: t('creditCardExporting.checks.isRDSConnected.error'),
        state: 'loaded',
      },
    },
    bds: {
      success: {
        key: 'isBDSConnected',
        icon: Success,
        text: t('creditCardExporting.checks.isBDSConnected.success'),
        state: 'loaded',
      },
      error: {
        key: 'isBDSConnected',
        icon: Error,
        text: t('creditCardExporting.checks.isBDSConnected.error'),
        state: 'loaded',
      },
    },
    cashLedger: {
      success: {
        key: 'isCashLedgerConnected',
        icon: Success,
        text: t('creditCardExporting.checks.isCashLedgerConnected.success'),
        state: 'loaded',
      },
      error: {
        key: 'isCashLedgerConnected',
        icon: Error,
        text: t('creditCardExporting.checks.isCashLedgerConnected.error'),
        state: 'loaded',
      },
    },
    creditCard: {
      success: {
        key: 'isCreditCardAccountNumberSet',
        icon: Success,
        text: t(
          'creditCardExporting.checks.isCreditCardAccountNumberSet.success'
        ),
        state: 'loaded',
      },
      error: {
        key: 'isCreditCardAccountNumberSet',
        icon: Error,
        text: t(
          'creditCardExporting.checks.isCreditCardAccountNumberSet.error'
        ),
        state: 'loaded',
      },
    },
    creditCardFF: {
      success: {
        key: 'isCreditCardAccountNumberSetFF',
        icon: Success,
        text: t(
          'creditCardExporting.checks.isCreditCardAccountNumberSetFF.success'
        ),
        state: 'loaded',
      },
      error: {
        key: 'isCreditCardAccountNumberSet',
        icon: Error,
        text: t(
          'creditCardExporting.checks.isCreditCardAccountNumberSetFF.error'
        ),
        state: 'loaded',
      },
    },
    serviceConnected: {
      success: {
        key: 'serviceConnected',
        icon: Success,
        text: t('creditCardExporting.checks.serviceConnected'),
        state: 'loaded',
      },
    },
    network: {
      failure: {
        key: 'networkFailure',
        icon: Error,
        text: t('settings.datev.connect.exportTypes.dxsoJobs.networkFailure'),
        state: 'loaded',
      },
    },
  };
};
