import {
  Text,
  useTooltip,
  Tooltip,
  Flex,
  mergeRefs,
  Icon,
  Skeleton,
  mergeProps,
  Box,
  TruncatedText,
} from '@candisio/design-system';
import { colors } from '@candisio/design-system/src/Theme/themeValues';
import { motion } from 'framer-motion';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { IconLinkProps } from './types';

const MAX_DISPLAY_COUNT = 999;
const MotionFlex = motion(Flex);

const motionTextVariants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

const motionFlexVariants = {
  initial: {
    background: 'transparent',
    outline: 'none',
    color: colors.gray600,
    transition: { color: { duration: 0 } },
  },
  hover: {
    outline: 'none',
    background: colors.bluebg,
    color: colors.blue600,
    transition: { color: { duration: 0 } },
  },
};

export const IconLink = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  IconLinkProps
>(
  (
    {
      icon,
      avatar,
      as: Component,
      size = 'space20',
      count,
      countLoading = false,
      label = '',
      showLabelInTooltip = true,
      expanded = false,
      to,
      description,
      isActive,
      pulseIndicator,
      'aria-label': ariaLabel,
      children,
      ...restProps
    }: IconLinkProps,
    ref
  ) => {
    const { isOpen, triggerProps, triggerRef, tooltipProps, tooltipRef } =
      useTooltip({
        placement: 'right',
        passiveTrigger: true,
        delay: 0,
      });

    const LinkOrButton = Component === 'button' ? 'button' : Link;

    const showLabelTooltip = !expanded && showLabelInTooltip;

    const getGapValue = () => {
      if (expanded && icon) return 'space12';
      if (expanded && avatar) return 'space8';

      return undefined;
    };

    return (
      <>
        <Flex alignItems="center">
          <MotionFlex
            as={LinkOrButton}
            alignItems="center"
            justifyContent="space-between"
            flexShrink={0}
            textDecoration="none"
            background="transparent"
            borderRadius="medium"
            cursor="pointer"
            color="gray600"
            padding={icon ? 'space10 space12' : 'space4'}
            border="none"
            outline="none"
            gap={count || pulseIndicator || children ? 'space8' : undefined}
            width={expanded ? '100%' : 'fit-content'}
            to={to}
            tabIndex={0}
            type={Component === 'button' ? 'button' : undefined}
            variants={motionFlexVariants}
            initial={isActive ? 'hover' : 'initial'}
            animate={isActive ? 'hover' : 'initial'}
            whileHover="hover"
            whileFocus="hover"
            ref={mergeRefs([triggerRef, ref])}
            aria-label={ariaLabel ?? label}
            {...mergeProps(triggerProps, restProps)}
          >
            <Flex alignItems="center" gap={getGapValue()}>
              {icon && <Icon icon={icon} size={size} flexShrink={0} />}
              {avatar}
              {expanded && (
                <MotionFlex
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  initial="closed"
                  animate={expanded ? 'open' : 'closed'}
                  variants={motionTextVariants}
                  transition={{ duration: 0.2 }}
                  style={{ overflow: 'hidden' }}
                >
                  <TruncatedText
                    aria-label={ariaLabel ?? label}
                    fontSize="basic"
                    fontWeight="500"
                    lineHeight="space20"
                  >
                    {label}
                  </TruncatedText>
                  {description && (
                    <TruncatedText
                      aria-label={description}
                      fontSize="small"
                      fontWeight="regular"
                      lineHeight="space16"
                    >
                      {description}
                    </TruncatedText>
                  )}
                </MotionFlex>
              )}
            </Flex>

            <Flex gap="space8" alignItems="center">
              {children}
              {countLoading || !!count || (pulseIndicator && expanded) ? (
                <>
                  {countLoading && (
                    <Skeleton width="space16" height="space16" />
                  )}

                  {!!count && !countLoading && (
                    <Text
                      fontSize="x-small"
                      fontWeight="bold"
                      lineHeight="paragraph"
                      data-cy={`count-${icon}`}
                    >
                      {`${Math.min(count, MAX_DISPLAY_COUNT)}${
                        count > MAX_DISPLAY_COUNT ? '+' : ''
                      }`}
                    </Text>
                  )}

                  {pulseIndicator && expanded && <Box>{pulseIndicator}</Box>}
                </>
              ) : null}
            </Flex>
          </MotionFlex>
          {pulseIndicator && !expanded && <Box>{pulseIndicator}</Box>}
        </Flex>
        {isOpen && label && showLabelTooltip && (
          <Tooltip ref={tooltipRef} {...tooltipProps}>
            {label}
          </Tooltip>
        )}
      </>
    );
  }
);
