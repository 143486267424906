import { Grid, useTheme } from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { useTranslation } from 'react-i18next';
import { useCreditCardRequestCountData } from 'views/CreditCards/hooks/useCreditCardRequestCountData';
import creditCardsIcon from '../../images/credit-cards-icon.svg';
import { MenuItemCount, MenuItemIcon } from '../../style';

export const CreditCards = () => {
  const [t] = useTranslation();
  const { fontSizes } = useTheme();

  const { loading, totalRequests } = useCreditCardRequestCountData();

  const isCountHidden = loading || !Boolean(totalRequests);

  const { isCardManager } = useUserRoles();

  return (
    <Grid alignItems="center" justifyItems="center">
      {isCardManager ? (
        <MenuItemCount
          aria-label="Inbox-menu-item-count"
          style={{
            fontSize: isCountHidden ? 0 : fontSizes.xsmall,
            padding: isCountHidden ? 0 : '0.05rem 0.35rem',
          }}
        >
          {totalRequests}
        </MenuItemCount>
      ) : null}

      <MenuItemIcon>
        <img
          src={creditCardsIcon}
          alt={t('navigation.archive')}
          width="60px"
          height="26px"
        />
      </MenuItemIcon>
    </Grid>
  );
};
