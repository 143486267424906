import { Grid, Text } from '@candisio/design-system';
import { ExpenseItemArchivedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const ExpenseItemArchived = ({
  reimbursementItemMetadata,
}: ExpenseItemArchivedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const heading = expenseTitle
    ? t('timeline.expenseRemoved')
    : t('timeline.expenseRemovedNoTitle');

  return (
    <Grid>
      <Text>{heading}</Text>
    </Grid>
  );
};
