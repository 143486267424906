import { Grid, Text, useTheme } from '@candisio/design-system';
import { ColorProps } from '@candisio/design-system/src/types';
import {
  CardIssuerSettlementMethod,
  CardIssuerSettlementStatus,
  CardIssuerSettlementType,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import {
  settlementMethodTranslationKey,
  settlementTypeTranslationKey,
  settlementStatusTranslationKey,
} from '../utils';

interface SettlementCardProps {
  background?: ColorProps['background'];
  isLastItem: boolean;
  publicPaymentId: string;
  paymentDate: Date | undefined;
  cutOffDate?: Date;
  amount: string;
  type: CardIssuerSettlementType;
  method: CardIssuerSettlementMethod;
  status: CardIssuerSettlementStatus;
}

export const SettlementCard = ({
  background,
  isLastItem,
  cutOffDate,
  ...restProps
}: SettlementCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { colors } = useTheme();

  const { publicPaymentId, paymentDate, amount, type, method, status } =
    restProps;

  const formattedCutOffDate = cutOffDate
    ? DateFormatters.compact(cutOffDate)
    : '-';

  const formattedDate = paymentDate ? DateFormatters.compact(paymentDate) : '-';

  return (
    <Grid
      gap="space16"
      padding="space10 space16"
      background={background ?? 'gray100'}
      borderBottomRadius={isLastItem ? 'medium' : 'none'}
      borderBottom={isLastItem ? 'none' : `1px solid ${colors.gray300}`}
    >
      <Grid columns={2} gap="space8" alignItems="center">
        <Text fontSize="basic" fontWeight="semibold">
          {formattedDate}
        </Text>
        <Text fontSize="basic" fontWeight="semibold" justifySelf="end">
          {amount}
        </Text>
        <Text color="gray600">
          {t('statements.settlementWidget.cutOffDate')} {formattedCutOffDate}
        </Text>
        <Text color="gray600" justifySelf="end">
          {t(settlementMethodTranslationKey[method]).toUpperCase()}
        </Text>
        <Text color="gray600">
          {t(settlementTypeTranslationKey[type], { id: publicPaymentId })}
        </Text>
        <Text color="gray600" justifySelf="end">
          {t(settlementStatusTranslationKey[status]).toUpperCase()}
        </Text>
      </Grid>
    </Grid>
  );
};
