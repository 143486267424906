import {
  Box,
  Grid,
  Heading,
  Flex,
  Text,
  Card,
  TruncatedText,
  Skeleton,
  Link,
  Button,
  Avatar,
  Item,
} from '@candisio/design-system';
import { TokenOrCSSValue } from '@candisio/design-system/src/types';
import { HookFormUsersFieldOption } from 'components/HookFormFields/HookFormUsersField/HookFormUsersFieldOption';
import { useUserRoles } from 'hooks/useUserRoles';
import { UserFieldItem } from 'hooks/useUsersFieldOptions';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { Key } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';

import { CreateCreditCardComboBoxField } from './CreateCreditCardComboBoxField';
import {
  useCreateCreditCardModalSetState,
  useCreateCreditCardModalState,
} from './CreateCreditCardModalDataProvider';
import { MostActiveCardholder } from './hooks/useMostActiveCardholders';
import { selectMemberStepErrorMessages } from './utils/selectMemberStepErrorMessages';
import {
  SelectMemberStepOutput,
  SelectMemberStepValues,
  selectMemberStepSchema,
} from './utils/selectMemberStepSchema';

export interface ComboBoxFieldOptions {
  items: UserFieldItem[];
  loading: boolean;
}
interface SelectMemberStepProps {
  fieldOptions: ComboBoxFieldOptions;
  mostActiveCardholders: MostActiveCardholder[];
  isLoading: boolean;
  isLoadingSuggestion: boolean;
  isCardForRecurringPayment: boolean;
  defaultValues: SelectMemberStepValues;
  suggestedCardholder:
    | {
        membershipId: string | undefined;
        name: string;
      }
    | undefined;
  onSubmit: (values: SelectMemberStepOutput) => void;
}

export const SelectMemberStep = ({
  fieldOptions,
  isLoading,
  isLoadingSuggestion,
  mostActiveCardholders,
  suggestedCardholder,
  defaultValues,
  isCardForRecurringPayment,
  onSubmit,
}: SelectMemberStepProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const user = useCurrentUser();
  const organization = useFullOrganization();
  const { teamMemberId } = useCreateCreditCardModalState();
  const setModalState = useCreateCreditCardModalSetState();
  const { isCardManager } = useUserRoles();

  const shouldShowMostActiveCardholders =
    !isLoading && mostActiveCardholders.length > 0;

  const form = useForm<SelectMemberStepOutput>({
    mode: 'onTouched',
    defaultValues,
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
      zodSchema: selectMemberStepSchema,
      errorMessages: selectMemberStepErrorMessages,
    }),
  });

  const teamMemberFieldValue = form.getValues('teamMember');

  const handleClick = (id: string | undefined) => {
    if (!id) {
      return;
    }

    form.setValue('teamMember', id, { shouldDirty: true });
    form.clearErrors('teamMember');
    setModalState(prevState => ({
      ...prevState,
      teamMemberId: id,
    }));
  };

  const handleOnComboBoxChange = (value: Key | null) => {
    if (!value) {
      return;
    }

    const teamMemberId = value as string;

    setModalState(prevState => ({
      ...prevState,
      teamMemberId,
    }));
  };

  const getActiveBackgroundColour = (
    id: string | undefined
  ): TokenOrCSSValue<'colors', 'background'> => {
    if (!id) {
      return 'gray300';
    }

    if (teamMemberId === id) {
      return 'gray400';
    }

    if (form.getValues('teamMember') === id) {
      return 'gray400';
    }

    return 'gray300';
  };

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        height="100%"
        padding="space24"
        paddingBottom="0px"
        gap="3rem"
        onSubmit={form.handleSubmit(onSubmit)}
        templateRows="auto auto 1fr"
      >
        <Flex direction="column" gap="space16">
          <Heading as="h2">{t('ccRequestForm.teamMemberStep.title')}</Heading>
          <Box width="300px">
            {isLoadingSuggestion ? (
              <Skeleton height="space24" width="100%" />
            ) : (
              <CreateCreditCardComboBoxField
                suggestion={suggestedCardholder}
                name="teamMember"
                placeholder={t('ccRequestForm.fields.teamMember.placeholder')}
                label={t('ccRequestForm.fields.teamMember.label')}
                defaultItems={fieldOptions.items}
                onChange={handleOnComboBoxChange}
                children={({ key, children, avatarUrl }) => (
                  <Item key={key} textValue={children}>
                    <HookFormUsersFieldOption
                      children={children}
                      avatarUrl={avatarUrl}
                    />
                  </Item>
                )}
                loading={fieldOptions.loading || isLoading}
              />
            )}
          </Box>
        </Flex>
        <Flex direction="column" gap="space16">
          {shouldShowMostActiveCardholders && (
            <Text fontSize="basic" color="gray600">
              {t('ccRequestForm.teamMemberStep.desc')}
            </Text>
          )}
          <Flex gap="space10">
            {isLoading
              ? Array.from({ length: 4 }, (_, index) => (
                  <Skeleton height="space36" width="space128" key={index} />
                ))
              : mostActiveCardholders.map(cardholder => (
                  <Card
                    borderRadius="full"
                    padding="space4"
                    key={cardholder.id}
                    background={getActiveBackgroundColour(
                      cardholder.memberships?.[0]?.membershipId
                    )}
                    onClick={() =>
                      handleClick(cardholder.memberships?.[0]?.membershipId)
                    }
                    hover={{ background: 'gray400' }}
                    style={{ cursor: 'pointer' }}
                  >
                    <Grid autoFlow="column" alignItems="center" gap="space4">
                      <Avatar
                        name={cardholder.fullName}
                        img={cardholder.avatarUrl}
                        size="small"
                        hasTooltip={false}
                      />
                      <TruncatedText maxWidth="10ch">
                        {cardholder.fullName}
                      </TruncatedText>
                    </Grid>
                  </Card>
                ))}
          </Flex>
        </Flex>

        <Grid gap="space16" alignSelf="end" paddingBottom="space16">
          {(user?.id === teamMemberFieldValue || !isCardManager) &&
            organization?.name && (
              <Box justifySelf="start">
                <Trans
                  t={t}
                  parent="span"
                  i18nKey="ccRequestForm.usageAgreement.text"
                  values={{ orgName: organization?.name }}
                >
                  I accept
                  <Link
                    href={t('ccRequestForm.usageAgreement.url')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    the usage agreement
                  </Link>
                  of Candis
                </Trans>
              </Box>
            )}

          {isCardForRecurringPayment ? (
            <Flex alignSelf="end" justifyContent="space-between">
              <Button
                variant="secondary"
                onClick={() =>
                  setModalState(prevState => ({
                    ...prevState,
                    formStep: 'LinkCard',
                  }))
                }
                icon="arrowLeft"
                iconPosition="left"
              >
                {t('ccRequestForm.goBackCTA')}
              </Button>

              <Button icon="arrowRight" type="submit" iconPosition="right">
                {t('ccRequestForm.teamMemberStep.cta')}
              </Button>
            </Flex>
          ) : (
            <Grid alignSelf="end">
              <Button
                icon="arrowRight"
                iconPosition="right"
                type="submit"
                justifySelf="end"
              >
                {t('ccRequestForm.teamMemberStep.cta')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  );
};
