import {
  Flex,
  Grid,
  Icon,
  Popover,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { usePrivacyContext } from 'providers/PrivacyConsentProvider/PrivacyConsentProvider';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom-v5-compat';
import { AuthService } from 'services/AuthService';
import userProfileMenuQRCode from '../MobileAppPromotionModal/media/user-profile-menu-QR-code.jpg';
import { MobileAppPromotionModal } from '../MobileAppPromotionModal/MobileAppPromotionModal';
import { scrollToTargetParam } from './AccountSecurity/PhoneNumberMfa/PhoneNumberCard';
import { LegalDocumentsModal } from './LegalDocuments/LegalDocumentsModal';
import { profileMenuLinks } from './profileMenuLinks';
import { UserMenuItem } from './UserMenuItem';
import { UserProfileUpdateContainer } from './UserProfileUpdateContainer';
import {
  AdoptionFeatures,
  TrackingEvents,
} from 'providers/AnalyticsProvider/events';

export const UserProfileMenu = () => {
  const [t] = useTranslation();

  const currentUser = useCurrentUser();
  const creditCardsSetup = useCreditCardsSetup();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();
  const { hash: currentHash, search } = useLocation();
  const navigate = useNavigate();
  const { showConsentManager } = usePrivacyContext();

  const {
    close: closeUserProfileMenu,
    isOpen: isUserProfileMenuOpen,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({ placement: 'bottom right' });

  const { track } = useAnalytics();

  const logout = useCallback(async () => {
    void AuthService.logout();
  }, []);

  const [legalModalVisible, setLegalModalVisible] = useState(false);
  const [mobileAppPromotionModalVisible, setMobileAppPromotionModalVisible] =
    useState(false);

  const faqUrl = t('header.profile.menu.items.faq.url');

  const profileMenu = (
    <Grid gap="space12" width="345px">
      <Grid gap="space4" paddingX="space12">
        {profileMenuLinks.map(
          ({
            description,
            title,
            icon,
            url,
            hash,
            saveQuery,
            dataTestId,
            allowedRoles,
          }) => (
            <UserRoleAccessWrapper key={title} allowedRoles={allowedRoles}>
              <UserMenuItem
                onClick={() => {
                  closeUserProfileMenu();
                }}
                icon={icon}
                dataTestId={dataTestId}
                description={description ? t(description) : undefined}
                routerLink={{
                  pathname: url && `/${organizationSlug}${url}`,
                  search: saveQuery ? search : undefined,
                  hash,
                }}
                title={t(title)}
              />
            </UserRoleAccessWrapper>
          )
        )}
        <UserMenuItem
          icon="mobile"
          onClick={() => {
            closeUserProfileMenu();
            track(TrackingEvents.ADOPTION_MODAL_OPENED, {
              feature: AdoptionFeatures.MOBILE_APP,
            });
            setMobileAppPromotionModalVisible(true);
          }}
          title={t('header.profile.menu.items.mobile.title')}
          description={t('header.profile.menu.items.mobile.description')}
        />
        <UserMenuItem
          icon="gift"
          backgroundColor="bluebg"
          description={t('header.profile.menu.items.referral.description')}
          onClick={() => {
            closeUserProfileMenu();
            window.open(
              t('header.profile.menu.items.referral.link', {
                email: currentUser?.email,
              }),
              '_blank'
            );
          }}
          title={t('header.profile.menu.items.referral.title')}
        />
      </Grid>
      <Grid
        borderTop="1px solid gray200"
        borderBottom="1px solid gray200"
        padding="space12"
        gap="space4"
      >
        <UserMenuItem
          onClick={() => {
            closeUserProfileMenu();
            showConsentManager();
          }}
          title={t('header.profile.menu.items.privacy.title')}
        />
        {creditCardsSetup.isInUse && (
          <UserMenuItem
            onClick={() => {
              setLegalModalVisible(true);
              closeUserProfileMenu();
            }}
            title={t('header.profile.menu.items.legalDocuments.title')}
          />
        )}
        <UserMenuItem
          onClick={() => {
            closeUserProfileMenu();
            window.open(t('header.profile.menu.items.support.url'), '_blank');
          }}
          title={t('header.profile.menu.items.support.title')}
        />
        {creditCardsSetup.isInUse && (
          <UserMenuItem
            onClick={() => {
              closeUserProfileMenu();
              window.open(faqUrl, '_blank');
            }}
            title={t('header.profile.menu.items.faq.title')}
          />
        )}
      </Grid>
      <Grid paddingX="space12">
        <UserMenuItem
          onClick={logout}
          title={t('header.profile.menu.logout')}
          dataTestId="profile-nav-logout"
        />
      </Grid>
    </Grid>
  );

  const onProfileClose = useCallback(() => {
    const updatedSearchParams = new URLSearchParams(search);
    if (updatedSearchParams.has(scrollToTargetParam)) {
      updatedSearchParams.delete(scrollToTargetParam);
    }

    navigate(
      { hash: undefined, search: updatedSearchParams.toString() },
      { replace: true }
    );
  }, [navigate, search]);

  const isProfileOpen = currentHash === '#userProfile';

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        data-cy="profile-nav"
        {...triggerProps}
        ref={triggerRef}
      >
        <Flex gap="space8" alignItems="center">
          <AvatarWithStatusContainer
            hasTooltip={false}
            img={currentUser?.avatarUrl ?? undefined}
            name={currentUser?.name}
            size="small"
            userId={currentUser?.id}
          />
          <Flex paddingRight="space8">
            {currentUser?.name && (
              <TruncatedText maxWidth="11.25rem">
                {currentUser.name}
              </TruncatedText>
            )}
          </Flex>
        </Flex>
        <Icon
          size="space16"
          icon={isUserProfileMenuOpen ? 'caretUp' : 'caretDown'}
        />
      </Flex>
      {isUserProfileMenuOpen && (
        <Popover
          paddingX="0"
          {...popoverProps}
          ref={popoverRef}
          dismissWithOutsideClick
        >
          {profileMenu}
        </Popover>
      )}

      <UserProfileUpdateContainer
        isOpen={isProfileOpen}
        onClose={onProfileClose}
      />
      <LegalDocumentsModal
        onCancel={() => setLegalModalVisible(false)}
        visible={legalModalVisible}
      />
      <MobileAppPromotionModal
        qrCode={userProfileMenuQRCode}
        visible={mobileAppPromotionModalVisible}
        onCancel={() => setMobileAppPromotionModalVisible(false)}
      />
    </>
  );
};
