import {
  Button,
  Grid,
  Heading,
  ScrollBox,
  Skeleton,
  useTheme,
} from '@candisio/design-system';
import { ReactNode } from 'react';

export interface SidebarProps {
  onClose?: () => void;
  closeTitle?: string;
  heading?: string;
  isLoading?: boolean;
  children: ReactNode;
}

export const Sidebar = ({
  heading,
  children,
  onClose,
  closeTitle,
  isLoading,
}: SidebarProps) => {
  const { lineHeights } = useTheme();

  return (
    <Grid
      templateRows="auto 1fr"
      background="white"
      overflow="hidden"
      borderTop="1px solid gray250"
    >
      <Grid padding="space24" gap="space8" templateColumns="1fr auto">
        {isLoading ? (
          <Skeleton width="80%" height="space32" />
        ) : (
          <Heading as="h2" style={{ lineHeight: lineHeights.paragraph }}>
            {heading}
          </Heading>
        )}
        {onClose && (
          <Button
            icon="close"
            variant="tertiary"
            label={closeTitle}
            onClick={onClose}
            alignSelf="start"
          />
        )}
      </Grid>
      <ScrollBox padding="space24" paddingBottom={0}>
        {children}
      </ScrollBox>
    </Grid>
  );
};
