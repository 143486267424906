import { useGetVendorCardsSuggestionsForRecurringPaymentsQuery } from 'generated-types/graphql.types';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';

export const useGetVendorCardSuggestionRecurringPayments = () => {
  const { vendorId } = useParams<{ vendorId: string }>();
  const { data, loading } =
    useGetVendorCardsSuggestionsForRecurringPaymentsQuery({
      variables: { id: vendorId },
    });

  const mappedSuggestions = (
    data?.getVendorCardsSuggestionsForRecurringPayments.suggestions ?? []
  ).map(cc => ({
    id: cc.card.id,
    label: cc.card.label ?? '',
    cardNumber: cc.card.refNum ?? '',
    brand: cc.card.brand ?? undefined,
    type: cc.card.type,
  }));

  return { suggestedCard: mappedSuggestions[0], loading };
};
