import { InfoPanel } from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { Routes } from 'models';
import { Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { AttachmentsValidationsError } from './useAttachmentsValidations';

interface AttachmentTableErrorPanelProps extends AttachmentsValidationsError {}

export const AttachmentTableErrorPanel = ({
  type,
  maxValue,
}: AttachmentTableErrorPanelProps) => {
  const orgId = useOrganizationId();

  const translationKey =
    type === 'maxFileLength' ? 'maxFileLength' : 'maxFileSize';

  const [t] = useTranslation();
  const title = t(`document.attachments.errors.${translationKey}.title`, {
    value: maxValue,
  });

  return (
    <InfoPanel variant="error" title={title}>
      <Trans i18nKey={`document.attachments.errors.${translationKey}.subtitle`}>
        Further files would not be attached because the document can then no
        longer be exported to DATEV. Activate the option accounting data service
        1.0 on the
        <RouterLink to={`/${orgId}${Routes.SETTINGS}${Routes.DATEV}`}>
          DATEV settings
        </RouterLink>
        page to attach more documents.
      </Trans>
    </InfoPanel>
  );
};
