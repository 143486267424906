import React from 'react';
import {
  AriaPositionProps,
  mergeProps,
  useMenuTrigger,
  usePress,
} from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { useMenuTriggerState } from 'react-stately';
import { Button, ButtonProps } from '../../Atoms/Button';
import { Menu, MenuItem, MenuProps } from '../../Atoms/Menu';
import { Popover, usePopover } from '../../Atoms/Popover';

export interface MenuButtonProps
  extends Omit<ButtonProps, 'onChange' | 'value' | 'defaultValue'> {
  /** List of menu items */
  items: MenuProps['items'];
  /** Type of selection allowed among the menu items*/
  selectionMode?: MenuProps['selectionMode'];
  /** Ids of the currently selected items in the menu */
  value?: MenuProps['value'];
  /** Ids of the currently selected items in the menu */
  defaultValue?: MenuProps['defaultValue'];
  /** Called when the menu selection changes */
  onChange?: MenuProps['onChange'];
  /** Menu popover placement relative to trigger element */
  menuPlacement?: AriaPositionProps['placement'];
  /** Custom render function for menu items */
  renderItem?: MenuProps['renderItem'];
}

/**
 * A button that opens a dropdown menu
 * [Storybook]{@link (https://candisio.github.io/design-system/?path=/story/molecules-menubutton)}
 */
export const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(
  (
    {
      children,
      disabled = false,
      items,
      loading = false,
      menuPlacement = 'bottom', // Menu “drops down” by default
      onChange,
      selectionMode = 'none',
      value,
      defaultValue,
      ...restProps
    },
    forwardedRef
  ) => {
    const menuState = useMenuTriggerState({});
    const {
      close,
      isOpen,
      open,
      setOpen,
      toggle,
      popoverProps,
      popoverRef,
      triggerRef,
    } = usePopover({
      placement: menuPlacement,
      onOpenChange: () => menuState.toggle(),
    });

    const { menuTriggerProps, menuProps } = useMenuTrigger<MenuItem>(
      { isDisabled: disabled || loading },
      { close, isOpen, open, setOpen, toggle, focusStrategy: 'first' },
      triggerRef
    );

    const { pressProps } = usePress({
      ...menuTriggerProps,
      isDisabled: disabled || loading,
    });

    return (
      <>
        <Button
          icon={isOpen ? 'caretUp' : 'caretDown'}
          iconPosition="right"
          isPressed={isOpen}
          disabled={disabled}
          loading={loading}
          {...mergeProps(pressProps, restProps)}
          ref={mergeRefs([triggerRef, forwardedRef])}
        >
          {children}
        </Button>
        {isOpen && (
          <Popover
            {...popoverProps}
            padding={0}
            arrowStyle={{ display: 'none' }}
            maxWidth="space256"
            ref={popoverRef}
          >
            <Menu
              defaultValue={defaultValue}
              items={items}
              // Popover has a maxHeight but not height. In order for the menu
              // list to be scrollable it needs to inherit the maxHeight value
              // See https://stackoverflow.com/a/14264093
              maxHeight="inherit"
              onChange={onChange}
              onClose={close}
              selectionMode={selectionMode}
              value={value}
              {...menuProps}
            />
          </Popover>
        )}
      </>
    );
  }
);
