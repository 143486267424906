import { Grid, Text, useTheme } from '@candisio/design-system';
import moment from 'moment';
import { useReauthenticationState } from 'providers/ReauthenticationProvider/ReauthenticationProvider';
import { useTranslation } from 'react-i18next';

export const DefaultModalContent = () => {
  const [t] = useTranslation();
  const theme = useTheme();
  const { validForSeconds } = useReauthenticationState();

  const validUntilTime = moment(Date.now() + validForSeconds * 1000).format(
    'LT'
  );

  return (
    <Grid
      as="div"
      gap="space16"
      fontSize="basic"
      lineHeight={theme.lineHeights.heading}
    >
      <Grid>
        <Text fontWeight="bold">
          {t('reauthentication.modal.default.content.info.title')}
        </Text>
        <Text>{t('reauthentication.modal.default.content.info.text')}</Text>
      </Grid>
      <Grid>
        <Text fontWeight="bold">
          {t('reauthentication.modal.default.content.validFor.title')}
        </Text>
        <Text>
          {t('reauthentication.modal.default.content.validFor.text', {
            validUntilTime,
          })}
        </Text>
      </Grid>
    </Grid>
  );
};
