import { Grid, Item, Text } from '@candisio/design-system';
import { EmptyDataState } from 'components/FilterableList/components/EmptyDataState';
import { EmptySearchState } from 'components/FilterableList/components/EmptySearchState';
import { FilterableListItem } from 'components/FilterableList/components/FilterableListItem';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useAccountingAreasList } from 'views/Settings/AccountingAreas/toolkit/hooks/useAccountingAreasList';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';

export const AccountingAreasList = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const {
    sortedAccountingAreas,
    queryString,
    onSearchFilter,
    handleCreateAccountingArea,
    handleSearchReset,
    onShowItem,
    listDataLoading,
    sortBy,
    handleSortBy,
    sortItems,
    sortButtonText,
  } = useAccountingAreasList();

  return (
    <FilterableList
      children={sortedAccountingAreas?.map(accountingArea => (
        <Item key={accountingArea.id} textValue={accountingArea.name}>
          <FilterableListItem
            itemId={accountingArea.id}
            onSelect={() => {
              onShowItem(accountingArea.id);
            }}
          >
            <Grid>
              <Text fontWeight="semibold" fontSize="basic">
                {renderQueryHighlight({
                  value: accountingArea.name,
                  queryString: queryString,
                })}
              </Text>
              <Text color="gray500">
                {renderQueryHighlight({
                  value: accountingArea.description,
                  queryString: queryString,
                })}
              </Text>
            </Grid>
          </FilterableListItem>
        </Item>
      ))}
      searchField={{
        onSearchFilter,
        placeholder: t('accountingAreas.searchPlaceholder'),
        searchQuery: queryString,
      }}
      emptyDataState={<EmptyDataState translation="" />}
      emptySearchState={<EmptySearchState searchReset={handleSearchReset} />}
      onCreate={{
        value: handleCreateAccountingArea,
        text: t('accountingAreas.actions.create'),
      }}
      menuButtons={[
        {
          actionValue: [sortBy],
          onClick: handleSortBy,
          text: sortButtonText ?? '',
          menuButtonItems: sortItems,
        },
      ]}
      isLoading={listDataLoading}
    />
  );
};
