import { defaultTheme } from '@candisio/design-system';
import { HEADER_HEIGHT, SIDEBAR_WIDTH } from 'styles/GlobalStyles';
import { hoverScrollbar } from 'styles/scrollbar';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

export const AppLayoutDeprecated = styled.main`
  display: grid;
  overflow: hidden;
  height: 100%;
  grid-template-columns: ${SIDEBAR_WIDTH} 1fr;
  grid-template-rows: ${HEADER_HEIGHT} 1fr;
  grid-template-areas: 'header header' 'sidebar content';
  background-color: ${defaultTheme.colors.gray200};

  *:hover:not(table):not(tr):not(td):not(th) {
    transition: background-color 0.2s ease-in-out;
  }
`;

export const HeaderLayout = styled.aside`
  min-width: 100%;
  grid-area: header;
`;

export const ContentLayout = styled.section`
  grid-area: content;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
`;
