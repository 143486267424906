import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { useTranslation } from 'react-i18next';
import { PaymentConditionItemCoreDataApi } from 'views/Contacts/ContactDetails/ContactForm/CoreDataApi/PaymentConditionItemCoreDataApi';
import { PaymentConditionItemProps } from 'views/Contacts/ContactDetails/ContactForm/PaymentConditionItem';

interface PaymentConditionFieldProps {
  defaultValue?: string;
  paymentConditionItems?: PaymentConditionItemProps[];
}

export const PaymentConditionReadOnlyField = ({
  defaultValue,
  paymentConditionItems = [],
}: PaymentConditionFieldProps) => {
  const [t] = useTranslation();

  const defaultItem = paymentConditionItems.find(
    ({ id }) => id === defaultValue
  );

  const items = [
    {
      key: 'none',
      children: t(
        'settings.contacts.details.edit.paymentCondition.options.none'
      ),
    },
    ...(defaultItem
      ? [
          {
            key: defaultValue,
            children: (
              <PaymentConditionItemCoreDataApi
                discountOffset={defaultItem.discountOffset}
                discountPercentage={defaultItem.discountPercentage}
                dueDateOffset={defaultItem.dueDateOffset}
                conditionName={defaultItem.conditionName}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <HookFormSelectField
      readOnly
      name="paymentCondition"
      label={t('settings.contacts.details.edit.paymentCondition.label')}
      items={items}
    />
  );
};
