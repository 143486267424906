import {
  CustomEmptyStateProps,
  Flex,
  Grid,
  Link,
  LottieAnimation,
  Paragraph,
  Text,
} from '@candisio/design-system';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';

interface InboxTransactionsEmptyStateProps extends CustomEmptyStateProps {
  isTableFiltered: boolean;
}

const FilteredAndEmpty = ({ resetFilters }: { resetFilters: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <>
      <Text textAlign="center" whiteSpace="pre-wrap">
        {t('addTransactionModal.emptyState.isFilteredAndEmpty')}
      </Text>
      <Link as="button" onClick={() => resetFilters()}>
        {t('addTransactionModal.emptyState.resetFilter')}
      </Link>
    </>
  );
};

const Empty = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const orgId = useOrganizationId();
  const navigate = useNavigate();

  const archiveLink = `/${orgId}${Routes.ARCHIVE}${Routes.TRANSACTIONS}`;

  return (
    <Flex direction="column" alignItems="center" gap="space16">
      <Paragraph
        textAlign="center"
        width="12rem"
        minWidth="10rem"
        fontSize="basic"
        fontWeight="semibold"
        wordBreak="break-word"
      >
        {t('emptyTransactionTable.emptyInbox')}
      </Paragraph>

      <Paragraph textAlign="center">
        <Trans i18nKey="emptyTransactionTable.emptyInboxDescription" t={t}>
          You can find all your credit card transactions in the
          <Link as="button" onClick={() => navigate(archiveLink)}>
            Archive
          </Link>
        </Trans>
      </Paragraph>
    </Flex>
  );
};

export const InboxTransactionsEmptyState = ({
  isTableFiltered,
  resetFilters,
}: InboxTransactionsEmptyStateProps) => {
  return (
    <Grid
      color="gray800"
      paddingTop="6.5rem"
      paddingBottom="4.5rem"
      minHeight="100%"
      justifyItems="center"
      gap="space16"
      alignItems="center"
    >
      <LottieAnimation
        animationData={isTableFiltered ? 'noresultsData' : 'successData'}
      />
      <Flex maxWidth="space256" alignItems="center" direction="column">
        {isTableFiltered ? (
          <FilteredAndEmpty resetFilters={resetFilters} />
        ) : (
          <Empty />
        )}
      </Flex>
    </Grid>
  );
};
