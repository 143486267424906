import { QueryHookOptions } from '@apollo/client';
import {
  useCountDocumentsByStatusNewQuery,
  useCountDocumentsByStatusRejectedForSidebarQuery,
  useCountDocumentsIRejectedQuery,
} from 'generated-types/graphql.types';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useEcmSensitiveDocumentsCount } from 'views/Inbox/EcmSensitiveDocuments/useGetEcmSensitiveDocumentsCount';
import { useInboxTransactionCount } from './useCounterQueries';
import { useUserRoles } from './useUserRoles';

interface InboxProps {
  processingDocuments: number | null;
  loadingProcessing: boolean;
  featureFlag: boolean;
}

/**
 * If the user is only an Approver, this will only compute the documents the user has rejected otherwise
 * it'll return a sum of New and all Rejected documents.
 *
 * @param options - query options
 * @param userRoles - roles of the user
 */
export const useCountDocumentsProcessing = (
  options: QueryHookOptions<any, any>
) => {
  // XXX: Consider introducing one endpoint to return this count

  const { isOnlyApprover } = useUserRoles();

  const {
    data: {
      countDocumentsByStatus: { count: newDocuments = null } = {},
    } = {},
    loading: loadingNewDocuments,
  } = useCountDocumentsByStatusNewQuery({
    ...options,
    skip: isOnlyApprover,
  });

  const {
    data: {
      countDocumentsByStatus: { count: rejectedDocuments = null } = {},
    } = {},
    loading: loadingRejectedDocuments,
  } = useCountDocumentsByStatusRejectedForSidebarQuery({
    ...options,
    skip: isOnlyApprover,
  });

  const {
    data: {
      countDocumentsIRejected: { count: documentsIRejected = null } = {},
    } = {},
    loading: loadingDocumentsIRejected,
  } = useCountDocumentsIRejectedQuery({
    ...options,
    skip: !isOnlyApprover,
  });

  if (isOnlyApprover) {
    return {
      count: documentsIRejected,
      loading: loadingDocumentsIRejected,
    };
  } else {
    const sumOfProcessingDocuments =
      newDocuments === null || rejectedDocuments === null
        ? null
        : newDocuments + rejectedDocuments;

    return {
      count: sumOfProcessingDocuments,
      loading: loadingNewDocuments && loadingRejectedDocuments,
    };
  }
};

export const useCountTransactionsAndDocumentsInbox = ({
  processingDocuments,
  loadingProcessing,
  featureFlag,
}: InboxProps) => {
  const { inboxTransactionCount, loading: loadingInboxTransactions } =
    useInboxTransactionCount({
      skip: !featureFlag,
    });

  const { showEcmSensitiveDocuments } = useEcm();
  const { sensitiveDocumentsCount, isLoading: loadingSensitiveDocuments } =
    useEcmSensitiveDocumentsCount();

  let transactionPlusDocuments =
    inboxTransactionCount + (processingDocuments ?? 0);

  if (showEcmSensitiveDocuments) {
    transactionPlusDocuments += sensitiveDocumentsCount;
  }

  const loadTransactionsAndDocuments =
    loadingProcessing || loadingInboxTransactions || loadingSensitiveDocuments;

  return {
    totalCount: transactionPlusDocuments,
    loading: loadTransactionsAndDocuments,
    transactionsCount: inboxTransactionCount,
    transactionsLoading: loadingInboxTransactions,
  };
};
