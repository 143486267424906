import { CellProps as ReactTableCellProps } from 'react-table';
import { TruncatedText } from '../..';
import { Text } from '../../../Atoms/Typography';

export type CellProps<
  TTableData extends object,
  TCellValue = any,
> = ReactTableCellProps<TTableData, TCellValue | undefined>;

/** Default cell */
export const Cell = <TTableData extends object>({
  value,
}: CellProps<TTableData>) => {
  if (value === undefined) {
    return <Text>-</Text>;
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return <TruncatedText>{String(value)}</TruncatedText>;
  }

  return <>{value}</>;
};
