import { useGetCardById } from 'containers/credit-cards/utils';
import { DateFormatters } from 'utils/date_formatter';
import { CardIsReplacedByInfo } from './CardIsReplacedByInfo';

interface CardIsReplacedByInfoContainerProps {
  cardId: string;
}

export const CardIsReplacedByInfoContainer = ({
  cardId,
}: CardIsReplacedByInfoContainerProps) => {
  const { card, loading } = useGetCardById({ cardId });

  const replacedByCardId = card?.replacedByCardId ?? undefined;

  const { card: replacementCard, loading: replacementCardLoading } =
    useGetCardById({
      cardId: replacedByCardId,
    });

  if (!replacementCard || replacementCardLoading || loading) {
    return null;
  }

  const replacementCardDetails = {
    issuingDate: replacementCard.issuingDate
      ? DateFormatters.compact(new Date(replacementCard.issuingDate))
      : undefined,
    refNumber: replacementCard.refNum
      ? `•••• ${replacementCard.refNum}`
      : undefined,
    id: replacementCard.id,
    replacedBy: card?.replacedBy
      ? `${card.replacedBy.firstName} ${card.replacedBy.lastName}`
      : undefined,
  };

  return replacementCardDetails.issuingDate &&
    replacementCardDetails.replacedBy ? (
    <CardIsReplacedByInfo {...replacementCardDetails} />
  ) : null;
};
