import { SplitBookingsFormValues } from 'components/Form/SplitBookingsForm/schema';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormNumberFieldProps } from 'components/HookFormFields/HookFormNumberField';
import { maximumPrecisionForSapItemInvoices } from 'constants/sap';
import { useSplitBookingsContext } from 'containers/SplitBookings/SplitBookingsContext';

export const UnitPrice = ({
  readOnly,
  name = 'unitPrice',
  placeholder,
  ...props
}: Partial<HookFormNumberFieldProps<SplitBookingsFormValues>> & {
  readOnly?: boolean;
  onChange: (val: number | null) => void;
  autoFocus?: boolean;
}) => {
  const { currency } = useSplitBookingsContext();

  return (
    <HookFormAmountField
      name={name}
      currency={currency ?? undefined}
      readOnly={readOnly}
      placeholder={readOnly ? '-' : placeholder}
      maximumFractionDigits={maximumPrecisionForSapItemInvoices}
      {...props}
    />
  );
};
