import { AvatarProps } from '@candisio/design-system';
import { castArray } from 'lodash';
import { Row } from 'react-table';
import { DocumentsTableData } from '../types';

export const includesArray = (
  rows: Row<DocumentsTableData>[],
  columnIds: string[],
  filterArray: string[]
) => {
  return rows.filter(row => {
    return filterArray.some(filter => {
      const headerId: string = columnIds[0];
      if (Array.isArray(row.values[headerId])) {
        return row.values[headerId].some(
          (value: AvatarProps) => value.name?.includes(filter) ?? false
        );
      }

      // the properties on row.values are typed 'any' by react table
      // so it's safer to go with 'unknown' here
      const rowValues: unknown = row.values[headerId].status
        ? row.values[headerId].status
        : row.values[headerId].name
          ? row.values[headerId].name
          : row.values[headerId];

      return castArray(rowValues).includes(filter);
    });
  });
};
