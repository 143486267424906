import React from 'react';
import {
  AriaOverlayProps,
  mergeProps,
  OverlayContainer,
  useOverlay,
} from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { OverlayBackground } from '../../Atoms/Overlay';
import { useTheme } from '../../Theme';
import { StandardHTMLAttributes, TokenOrCSSValue } from '../../types';
import {
  AnimationBox,
  AnimationCSSObject,
} from '../../Utilities/AnimationBox/AnimationBox';
import { DrawerDialog } from './DrawerDialog';

export type DrawerWidth = TokenOrCSSValue<'space', 'width'>;

export interface DrawerProps
  extends AriaOverlayProps,
    StandardHTMLAttributes<HTMLDivElement> {
  width?: DrawerWidth;
  hasOverlay?: boolean;
  containFocus?: boolean;
}

/**
 * Drawer is a panel that slides out from the edge of the screen. It is used to show additional information and UI elements for the current page.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/story/molecules-overlay-drawer}
 *
 * @param {(DrawerWidth)} width Sets the width of the Drawer (if not, the default width is set)
 * @param {(boolean)} hasOverlay Selection whether Overlay should be rendered or not
 * @param {(boolean)} containFocus Selection whether focus should be contained within the Drawer
 */
export const Drawer = React.forwardRef<HTMLDivElement, DrawerProps>(
  (
    {
      onClose,
      isOpen,
      hasOverlay = true,
      width,
      children,
      containFocus = true,
      ...restProps
    },
    forwardedRef
  ) => {
    const drawerRef = React.useRef(null);
    const { overlayProps } = useOverlay({ onClose, isOpen }, drawerRef);

    const { timingFunctions } = useTheme();
    const animationDuration = 250;

    const animationStyle: AnimationCSSObject = {
      enter: {
        '.overlay': {
          opacity: 0,
        },
        '.drawer': {
          transform: 'translateX(100%)',
        },
      },
      enterActive: {
        '.overlay': {
          opacity: 1,
          transition: `opacity ${animationDuration}ms ${timingFunctions.ease}`,
        },
        '.drawer': {
          transform: 'translateX(0%)',
          transition: `transform ${animationDuration}ms ${timingFunctions.ease}`,
        },
      },
      exit: {
        '.overlay': {
          opacity: 1,
        },
        '.drawer': {
          transform: 'translateX(0%)',
        },
      },
      exitActive: {
        '.overlay': {
          opacity: 0,
          transition: `opacity ${animationDuration}ms ${timingFunctions.ease}`,
        },
        '.drawer': {
          transform: 'translateX(100%)',
          transition: `transform ${animationDuration}ms ${timingFunctions.ease}`,
        },
      },
    };

    return (
      <AnimationBox
        in={isOpen}
        timeout={animationDuration}
        animation={animationStyle}
        unmountOnExit
        mountOnEnter
      >
        <OverlayContainer css={{ position: 'fixed', inset: 0, zIndex: 100 }}>
          <DrawerDialog
            isModal={containFocus}
            width={width}
            {...mergeProps(overlayProps, restProps)}
            ref={mergeRefs([drawerRef, forwardedRef])}
          >
            {children}
          </DrawerDialog>
          {hasOverlay && (
            <OverlayBackground
              className="overlay"
              onClick={onClose}
              zIndex={0}
            />
          )}
        </OverlayContainer>
      </AnimationBox>
    );
  }
);
