import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import { NavigationTableParams } from 'components/DocumentsTable/types';
import { queryParameter } from 'components/Table/consts';
import { DocumentPreviewDrawer } from 'containers/DocumentPreview/DocumentPreviewDrawer';
import { DocumentTableRowActionsOverlay } from 'containers/DocumentPreview/DocumentTableRowActionsOverlay';
import { ACTIVATION_LOCAL_STORAGE_KEY } from 'containers/DocumentPreview/useDocumentPreviewActivationPopover';
import { DocumentPreviewUpsellDrawer } from 'containers/DocumentPreviewUpsell/DocumentPreviewUpsellDrawer';
import { usePreviewUpsellSeen } from 'containers/DocumentPreviewUpsell/usePreviewUpsellSeen';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider/feature-flag-names';
import { useOrganizationId } from 'providers/OrganizationProvider/useOrganizationId';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';
import { appendParamsToQueryString } from 'utils/url-helper';
import { TabView } from 'views/Inbox/models';
import {
  ArchiveDocumentsTable,
  ArchiveDocumentsTableDSProps,
} from '../ArchiveDocumentsTable/ArchiveDocumentsTable';
import { useArchiveDocumentsDataDeprecated } from '../ArchiveDocumentsTable/hooks/useArchiveDocumentsDataDeprecated';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { useArchiveDocumentsData } from '../ArchiveDocumentsTable/hooks/useArchiveDocumentsData';
import { DocumentPreviewEventLocations } from 'providers/AnalyticsProvider/events';

type Props = RouteComponentProps<{ organizationSlug: string }>;

type PreviewDocumentProps = {
  previewDocumentId: string | null;
  setPreviewDocumentId: (documentId: string | null) => void;
  isUsingSearchQuery: boolean;
  searchQuery: string;
  updateSearchParam: (key: string, value: string) => void;
};

export const MAX_DOCUMENTS_FOR_ORIGINAL_FILES_ZIP_DOWNLOAD = 1000;
export const MIN_DOCUMENTS_FOR_ORIGINAL_FILES_ZIP_DOWNLOAD = 1;

type ArchiveDocumentsComponentProps = Props &
  PreviewDocumentProps & {
    showImprovedSearch?: boolean;
    archiveDocumentsDataProps:
      | ReturnType<typeof useArchiveDocumentsData>
      | ReturnType<typeof useArchiveDocumentsDataDeprecated>;
    shouldEnableSortByWithSearchQuery: boolean;
  };

export const ArchiveDocumentsContainer = (props: Props) => {
  const [enableNewIndexInArchiveViewFF, consistentSortAndFilteringFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.enableNewIndexInArchiveView,
      FEATURE_FLAGS.consistentSortAndFiltering,
    ]);

  const [previewDocumentId, setPreviewDocumentId] = useState<string | null>(
    null
  );
  const { searchParams, updateSearchParam } = useMutateSearchParams();
  const searchQuery = searchParams.get(queryParameter) ?? '';
  const isUsingSearchQuery = Boolean(searchQuery);

  return enableNewIndexInArchiveViewFF && consistentSortAndFilteringFF ? (
    <ArchiveDocuments
      {...props}
      previewDocumentId={previewDocumentId}
      setPreviewDocumentId={setPreviewDocumentId}
      isUsingSearchQuery={isUsingSearchQuery}
      searchQuery={searchQuery}
      updateSearchParam={updateSearchParam}
    />
  ) : (
    <ArchiveDocumentsDeprecated
      {...props}
      previewDocumentId={previewDocumentId}
      setPreviewDocumentId={setPreviewDocumentId}
      isUsingSearchQuery={isUsingSearchQuery}
      searchQuery={searchQuery}
      updateSearchParam={updateSearchParam}
    />
  );
};

const ArchiveDocuments = (props: Props & PreviewDocumentProps) => {
  const archiveDocumentsDataProps = useArchiveDocumentsData({
    previewDocumentId: props.previewDocumentId,
  });
  const { showImprovedSearch } = useEcm();
  return (
    <ArchiveDocumentsComponent
      {...props}
      archiveDocumentsDataProps={archiveDocumentsDataProps}
      shouldEnableSortByWithSearchQuery={true}
      showImprovedSearch={showImprovedSearch}
      previewDocumentId={props.previewDocumentId}
      setPreviewDocumentId={props.setPreviewDocumentId}
    />
  );
};

/**
 * @deprecated
 */
const ArchiveDocumentsDeprecated = (props: Props & PreviewDocumentProps) => {
  const archiveDocumentsDataProps = useArchiveDocumentsDataDeprecated({
    previewDocumentId: props.previewDocumentId,
    isUsingSearchQuery: props.isUsingSearchQuery,
  });

  return (
    <ArchiveDocumentsComponent
      {...props}
      archiveDocumentsDataProps={archiveDocumentsDataProps}
      shouldEnableSortByWithSearchQuery={false}
      previewDocumentId={props.previewDocumentId}
      setPreviewDocumentId={props.setPreviewDocumentId}
    />
  );
};

export const ArchiveDocumentsComponent = ({
  archiveDocumentsDataProps,
  previewDocumentId,
  setPreviewDocumentId,
  isUsingSearchQuery,
  searchQuery,
  shouldEnableSortByWithSearchQuery,
  match: {
    params: { organizationSlug },
  },
  updateSearchParam,
}: ArchiveDocumentsComponentProps) => {
  const [tableRowLinkFF] = useCandisFeatureFlags([FEATURE_FLAGS.tableRowLink]);

  const { showDocumentPreview, showDocumentPreviewPromo } = useEcm();
  const showPreview = showDocumentPreview || showDocumentPreviewPromo;

  const { previewUpsellSeen, markPreviewUpsellSeen } = usePreviewUpsellSeen();
  const [wasActivationSeen] = useLocalStorage(
    ACTIVATION_LOCAL_STORAGE_KEY,
    false
  );

  const isAutoOpened = useRef(wasActivationSeen);

  const { search } = useLocation();
  const history = useHistory();
  const { identify } = useAnalytics();

  const orgId = useOrganizationId();

  const {
    documentsCount,
    documentsTableData,
    handleDebounceSearch,
    hasMoreData,
    loading,
    onLoadMore,
    selectedDocumentsCount,
  } = archiveDocumentsDataProps;

  useEffect(() => {
    if (loading) return;

    identify({
      archive_documents_count: documentsCount ?? 0,
    });
  }, [documentsCount, identify, loading]);

  const isDocumentPreviewReady =
    !loading && previewDocumentId === null && !!documentsTableData[0]?.id;

  useEffect(() => {
    if (!isDocumentPreviewReady) return;

    const shouldAutoOpenPreview =
      (showDocumentPreviewPromo && !previewUpsellSeen) ||
      (!showDocumentPreviewPromo && !isAutoOpened.current);

    if (!shouldAutoOpenPreview) return;

    setPreviewDocumentId(documentsTableData[0].id);
    if (!showDocumentPreviewPromo) isAutoOpened.current = true;
  }, [
    documentsTableData,
    isDocumentPreviewReady,
    previewUpsellSeen,
    showDocumentPreviewPromo,
    setPreviewDocumentId,
  ]);

  const documentPreviewRowOverlay: ArchiveDocumentsTableDSProps['rowOverlay'] =
    useMemo(
      () =>
        showPreview
          ? ({ data }) => {
              if (data.selected) return <></>; // TODO: Fix DS type to allow to return null

              return (
                <DocumentTableRowActionsOverlay
                  data={data}
                  onQuickViewClick={data => {
                    setPreviewDocumentId(data.id);
                  }}
                  isSelected={data.id === previewDocumentId}
                />
              );
            }
          : undefined,
      [showPreview, previewDocumentId, setPreviewDocumentId]
    );

  const cursor =
    documentsTableData.find(d => d.id === previewDocumentId)?.cursor ?? null;

  const openDocument = useCallback(
    ({
      documentId,
      cursor,
    }: {
      documentId: string | null;
      cursor: string | null;
    }): void => {
      if (!documentId) return;

      const params = new URLSearchParams();
      if (cursor) params.set('cursor', cursor);

      const path = `/${organizationSlug}${Routes.ARCHIVE}/${documentId}?${params}`;

      window.open(path, '_blank');
    },
    [organizationSlug]
  );

  const closePreview = useCallback(() => {
    setPreviewDocumentId(null);
    if (!previewUpsellSeen) {
      markPreviewUpsellSeen();
    }
  }, [markPreviewUpsellSeen, previewUpsellSeen, setPreviewDocumentId]);

  const documentPreviewDrawer = useMemo(() => {
    if (showDocumentPreviewPromo) {
      return (
        <DocumentPreviewUpsellDrawer
          isOpen={!!previewDocumentId}
          closePreviewDrawer={closePreview}
        />
      );
    }

    if (showDocumentPreview) {
      return (
        <DocumentPreviewDrawer
          documentPreviewEventLocation={DocumentPreviewEventLocations.ARCHIVE}
          documentId={previewDocumentId}
          closePreviewDrawer={closePreview}
          openDocument={() =>
            openDocument({ documentId: previewDocumentId, cursor })
          }
        />
      );
    }

    return null;
  }, [
    showDocumentPreviewPromo,
    showDocumentPreview,
    previewDocumentId,
    closePreview,
    openDocument,
    cursor,
  ]);

  const getPath = useCallback(
    ({ id, cursor }: NavigationTableParams) => {
      const searchWithCursor = appendParamsToQueryString(search, {
        cursor: cursor ?? '',
      });

      return `/${orgId}${Routes.ARCHIVE}/${id}?${searchWithCursor}`;
    },
    [orgId, search]
  );

  const onClick = useCallback(
    ({ id, cursor }: NavigationTableParams) => {
      if (tableRowLinkFF) return;

      const path = getPath({ id, cursor });
      history.push(path);
    },
    [getPath, history, tableRowLinkFF]
  );

  const cellWrapper = useMemo(
    () => (tableRowLinkFF ? getCellWrapperLink(getPath) : undefined),
    [tableRowLinkFF, getPath]
  );

  const cellStyle = tableRowLinkFF
    ? () => ({
        padding: 'unset',
      })
    : undefined;

  return (
    <ArchiveViewLayout
      activeTab={TabView.ARCHIVE_DOCUMENTS}
      rightSide={documentPreviewDrawer}
    >
      <ArchiveDocumentsTable
        data={documentsTableData}
        isLoading={loading}
        hasMoreData={hasMoreData}
        selectedDocumentsCount={selectedDocumentsCount}
        organizationSlug={organizationSlug}
        isUsingSearchQuery={isUsingSearchQuery}
        shouldEnableSortByWithSearchQuery={shouldEnableSortByWithSearchQuery}
        searchFieldValue={searchQuery}
        searchFieldOnChange={query => {
          handleDebounceSearch(query);
          updateSearchParam(queryParameter, query);
        }}
        onEndReached={onLoadMore}
        onClick={onClick}
        rowOverlay={documentPreviewRowOverlay}
        cellWrapper={cellWrapper}
        getCellStyles={cellStyle}
      />
    </ArchiveViewLayout>
  );
};
