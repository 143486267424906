import { defaultTheme } from '@candisio/design-system';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

export const EmptyDataWrapper = styled.div`
  margin: 6.25rem 0;
`;

export const EmptyDataText = styled.div`
  font-size: ${defaultTheme.fontSizes.basic};
  color: ${defaultTheme.colors.gray500};
  margin-bottom: 0.125rem;
  white-space: pre-wrap;
  text-align: center;
  word-break: break-word;
`;

export const EmptyDataS = styled.span`
  cursor: pointer;
  color: ${defaultTheme.colors.blue500};
`;
