import { useToastMessage } from 'components/Toast/useToastMessage';
import { useCreateHospitalityExpenseItemMutation } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
  reimbursementItemsListQuery,
} from '../toolkit/queries';

export const useCreateHospitalityExpense = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { error } = useToastMessage();
  const [createHospitalityExpense, { loading: createHospitalityPending }] =
    useCreateHospitalityExpenseItemMutation({
      onError: () => error(t('reimbursementView.createErr')),
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: reimbursementCaseByIdQuery,
          variables: {
            id: reimbursementId,
          },
        },
        {
          query: reimbursementItemsListQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
        {
          query: reimbursementHistoryQuery,
          variables: { id: reimbursementId },
        },
      ],
    });

  const handleCreateHospitalityExpense = async () => {
    const { data, errors } = await createHospitalityExpense({
      variables: {
        input: {
          reimbursementCaseId: reimbursementId,
        },
      },
    });

    if (errors?.length) {
      error(t('reimbursementView.createErr'));

      return;
    }

    if (data?.createHospitalityExpenseItem.id) {
      return data.createHospitalityExpenseItem.id;
    }
  };

  return {
    createHospitalityPending,
    createHospitality: handleCreateHospitalityExpense,
  };
};
