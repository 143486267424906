import { useCallback } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { Filters, SortingRule } from 'react-table';
import { prepareTableFiltersForUrl } from 'utils/table/prepareTableFiltersForUrl';
import { prepareTableSortForUrl } from 'utils/table/prepareTableSortForUrl';
import { useTranslateFiltersFromUrl } from './useTranslateFiltersFromUrl';

export const useUrlBasedSortAndFilter = <TableDataType extends object>({
  availableFilters,
}: {
  availableFilters: string[];
}) => {
  const { sortBy, filters } = useTranslateFiltersFromUrl({
    availableFilters,
  });

  const history = useHistory();

  const onSort = useCallback(
    (sortBy: SortingRule<TableDataType>[]): void => {
      const sortForUrl = prepareTableSortForUrl(sortBy);
      const urlSearchParams = new URLSearchParams(window.location.search);

      if (sortForUrl) {
        urlSearchParams.set('sort', sortForUrl.sort);
      } else {
        urlSearchParams.delete('sort');
      }

      history.replace({
        pathname: history.location.pathname,
        search: urlSearchParams.toString(),
      });
    },
    [history]
  );

  const onFilter = useCallback(
    (filters: Filters<TableDataType>) => {
      const filtersForUrl = prepareTableFiltersForUrl(filters);

      const urlSearchParams = new URLSearchParams(window.location.search);

      availableFilters.forEach(filter => urlSearchParams.delete(filter));

      if (filtersForUrl) {
        filters.forEach(({ id, value }: { id: string; value: string[] }) =>
          value.forEach(value => urlSearchParams.append(id, value))
        );
      }

      history.replace({
        pathname: history.location.pathname,
        search: urlSearchParams.toString(),
      });
    },
    [availableFilters, history]
  );

  return {
    onSort,
    onFilter,
    sortBy,
    filters,
  };
};
