import { Flex, Grid, Icon, Image, Text } from '@candisio/design-system';
import cardIcon from 'assets/credit-cards/icon-card.svg';
import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const CardLimitsItem = ({
  cardType,
  onSetMode,
}: {
  cardType?: CardType;
  onSetMode: (mode: DrawerMode) => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const handleClick = () => {
    onSetMode('cardLimits');
  };

  return (
    <Grid
      onClick={handleClick}
      templateColumns="1fr auto"
      padding="space16"
      alignItems="center"
      style={{ cursor: 'pointer' }}
      hover={{
        background: 'gray200',
        borderTopLeftRadius: 'medium',
        borderTopRightRadius: 'medium',
      }}
    >
      <Flex gap="space16" alignItems="center">
        <Image src={cardIcon} alt="card icon" />
        <Flex alignItems="baseline" gap="space8">
          <Text fontSize="large">
            {t('creditCardFormDataPreview.cardLimits.title')}
          </Text>
          <Text>
            {t(
              cardType === CardType.SingleUse
                ? 'creditCardFormDataPreview.cardLimits.singleUseSubtitle'
                : 'creditCardFormDataPreview.cardLimits.subtitle'
            )}
          </Text>
        </Flex>
      </Flex>
      <Icon icon="arrowRight" size="space16" />
    </Grid>
  );
};
