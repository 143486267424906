import { Button, Grid } from '@candisio/design-system';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface DocumentTableRowActionsOverlayProps<T> {
  data: T;
  onQuickViewClick: (data: T) => void;
  isSelected?: boolean;
  disabledText?: string;
}

export const DocumentTableRowActionsOverlay = <T extends object>({
  data,
  onQuickViewClick,
  isSelected,
  disabledText,
}: DocumentTableRowActionsOverlayProps<T>) => {
  const [t] = useTranslation();

  return (
    <Grid
      width="100%"
      height="100%"
      justifyContent="end"
      alignItems="center"
      zIndex="100"
    >
      <Grid
        height="100%"
        background={
          isSelected
            ? 'linear-gradient(90deg, #EBEDF700 0%, #EBEDF7FF 10%)'
            : 'linear-gradient(90deg, #F8F6F500 0%, #F8F6F5FF 10%)'
        }
        paddingLeft="space32"
        paddingRight="space16"
        alignItems="center"
        style={{ pointerEvents: 'all' }}
      >
        <Button
          size="small"
          variant="secondary"
          color="gray"
          disabled={Boolean(disabledText)}
          label={disabledText}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onQuickViewClick(data);
          }}
        >
          {t('documentPreview.quickView')}
        </Button>
      </Grid>
    </Grid>
  );
};
