import { Grid, Skeleton, Text } from '@candisio/design-system';
import { ColorProps } from '@candisio/design-system/src/types';

interface SettlementLoadingStateProps {
  title?: string;
  background?: ColorProps['background'];
}

export const SettlementLoadingState = ({
  title,
  background,
}: SettlementLoadingStateProps) => {
  return (
    <Grid
      height="fit-content"
      background={background ?? 'gray100'}
      padding="space16"
      gap="space16"
      borderRadius="medium"
    >
      {title && (
        <Text fontSize="large" fontWeight="semibold">
          {title}
        </Text>
      )}
      <Skeleton width="100%" height="120px" />
    </Grid>
  );
};
