import { Box, Grid } from '@candisio/design-system';
import { PdfViewer } from 'components/DocumentViewer/PdfViewer/PdfViewer';
import { memo } from 'react';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { ReplaceInvoice } from './ReplaceInvoice';
import { useState, useCallback } from 'react';
import { PdfInvoiceSkeleton } from 'components/DocumentViewer/PdfViewer/PdfInvoiceSkeleton';
import { Expense } from './ExpenseFormWrapper';

interface ExpenseInvoicePreviewContainerProps {
  id: string;
  name: string;
  invoice?: ExpenseInvoice | null;
  createInvoicePending?: boolean;
  isReadOnly?: boolean;
  isExcluded?: boolean;
  onChange: (file: File) => Promise<void>;
  onInvoiceUpdate?: () => Promise<void>;
  expenseType: Expense;
}

export const ExpenseInvoicePreviewContainer = memo(
  ({
    id,
    name,
    invoice,
    createInvoicePending,
    isReadOnly,
    isExcluded,
    onChange,
    onInvoiceUpdate,
    expenseType,
  }: ExpenseInvoicePreviewContainerProps) => {
    const [isFileChanging, setIsFileChanging] = useState(false);

    const isLoading = createInvoicePending || isFileChanging;
    const initialScale = expenseType === 'general' ? 0.6 : 0.5;

    const handleIsFileChanging = useCallback((val: boolean) => {
      setIsFileChanging(val);
    }, []);

    if (isLoading) {
      return (
        <Grid height="600px">
          <PdfInvoiceSkeleton />
        </Grid>
      );
    }

    if (!invoice) {
      return null;
    }

    return (
      <Grid
        gap="space24"
        height="100%"
        background="gray0"
        overflow="auto"
        padding="space8 space16"
        borderRadius="medium"
      >
        {/** Overlay to blur the whole pdf viewer */}
        {isExcluded && (
          <Box
            height="100%"
            width="100%"
            position="absolute"
            opacity="0.4"
            background="white"
            zIndex={10}
          />
        )}
        <PdfViewer
          pdfWrapperId={`exp-invoice-${invoice.id}`}
          documentFile={invoice}
          maxHeight="550px"
          initialScale={initialScale}
          disabledControls={['downloadFiles']}
          attachmentSection={
            <ReplaceInvoice
              id={id}
              name={name}
              disabled={isReadOnly || isLoading || isExcluded}
              onChange={onChange}
              onInvoiceUpdate={onInvoiceUpdate}
              isFileChanging={isFileChanging}
              onIsFileChanging={handleIsFileChanging}
            />
          }
        />
      </Grid>
    );
  },
  (prevProps, nextProps) =>
    prevProps.invoice?.id === nextProps.invoice?.id &&
    prevProps.isExcluded === nextProps.isExcluded
);
