import {
  Box,
  CustomEmptyStateProps,
  Flex,
  SelectionOptions,
} from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { AssociateCard } from 'components/Transactions/Table/AssociateCard/AssociateCard';
import { TransactionsTable } from 'components/Transactions/Table/TransactionsTable';
import {
  TransactionsTableData,
  ViewUsingTransactions,
} from 'components/Transactions/Table/types';
import { useCardIssuerTransactionsData } from 'components/Transactions/Table/useCardIssuerTransactionsData';
import { transactionToTableData } from 'components/Transactions/Table/util';
import { AnimatePresence } from 'framer-motion';
import { useLinkDocumentAndTransactionsMutation } from 'generated-types/graphql.types';
import { GQLError } from 'gql';
import { useLocalSortAndFilters } from 'hooks/table/useLocalSortAndFilters';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';
import { useShowError } from 'utils/error_message';
import { validationErrorsExtractor } from 'utils/forms';
import { documentQueries } from 'views/Inbox/DocumentProcessing/queries';
import {
  TRANSACTION_FILTER,
  useTransactionListFilters,
} from 'views/Inbox/Transactions/useTransactionListFilters';
import { documentHistoryQuery } from 'views/queries';
import { TransactionsTableEmptyState } from '../Table/components/TransactionsTableStates/components/TransactionsTableEmptyState';

interface TransactionListProps {
  documentId: string;
  onSuccess: () => void;
}

const transactionFilters: TRANSACTION_FILTER[] = [
  TRANSACTION_FILTER.invoiceAssociationStatus_inbox,
  TRANSACTION_FILTER.status_inbox,
  TRANSACTION_FILTER.cardholderName,
  TRANSACTION_FILTER.type,
  TRANSACTION_FILTER.category,
] as const;

export const TransactionList = ({
  documentId,
  onSuccess,
}: TransactionListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();
  const showError = useShowError();

  const { filters, sortBy, onSort, onFilter } =
    useLocalSortAndFilters<TransactionsTableData>();

  const { filterOptions, isFilterLoading } = useTransactionListFilters({
    filters: transactionFilters,
    fetchCardholdersWithMissingInvoices: true,
  });

  const {
    transactionList,
    isLoadingTransactionList,
    onLoadMore,
    isTableEmpty,
    isTableFiltered,
  } = useCardIssuerTransactionsData({
    routeType: ViewUsingTransactions.PROCESSING_DOCUMENT,
    filters,
    sortBy,
  });

  const [selectedTransaction, setSelectedTransaction] =
    useState<TransactionsTableData | null>(null);

  const counterQueries = useCounterQueries();
  const docHistoryQuery = {
    query: documentHistoryQuery,
    variables: { id: documentId },
  };

  const [linkTransactionsWithDocument] = useLinkDocumentAndTransactionsMutation(
    {
      refetchQueries: [
        { query: documentQueries.forDraftForm, variables: { id: documentId } },
        ...counterQueries,
        docHistoryQuery,
      ],
    }
  );

  const handleLinkTransactionsWithDocument = async () => {
    try {
      const result = await linkTransactionsWithDocument({
        variables: {
          documentId,
          transactionIds: [selectedTransaction?.id ?? ''],
        },
      });

      if (result.data?.linkDocumentAndTransactions) {
        success(t('addTransactionModal.toastMessageSucces'));
      } else {
        error('There was an error');
      }
    } catch (error) {
      showError(error as GQLError);

      return validationErrorsExtractor(error);
    }

    onSuccess();
  };

  const selectionOptions: SelectionOptions<TransactionsTableData> =
    useMemo(() => {
      return {
        onSelectionRowChanged: transactions => {
          setSelectedTransaction(transactions[0]);
        },
        selectedRowsIds: selectedTransaction ? [selectedTransaction.id] : [],
      };
    }, [selectedTransaction]);

  const areTransactionsSelected = Boolean(selectedTransaction);

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <TransactionsTableEmptyState
        isTableFiltered={isTableFiltered}
        areTransactionsSelected={areTransactionsSelected}
        isTableEmpty={isTableEmpty}
        resetFilters={resetFilters}
      />
    ) : null;

  const handleFilter = useMemo(() => {
    return (filterVal: Filters<TransactionsTableData>) => {
      onFilter(filterVal);
    };
  }, [onFilter]);

  const handleSort = useMemo(() => {
    return (sortVal: SortingRule<TransactionsTableData>[]) => {
      onSort(sortVal);
    };
  }, [onSort]);

  return (
    <Box height="100%" overflow="hidden">
      <Flex
        direction="column"
        height="100%"
        overflow="hidden"
        paddingTop="space16"
        paddingBottom="space32"
        paddingX="space32"
      >
        <TransactionsTable
          key={`tx-table-${isFilterLoading}`}
          isLoading={isLoadingTransactionList}
          onEndReached={onLoadMore}
          columns={[
            'type',
            'status',
            'merchantName',
            'grossAmount',
            'category',
            'transactionCreatedAt',
            'cardRefNum',
            'cardholderName',
          ]}
          onFilter={handleFilter}
          customEmptyState={emptyState}
          onSort={handleSort}
          selectionOptions={selectionOptions}
          data={transactionToTableData(transactionList ?? [])}
          filterOptions={filterOptions}
          defaultFilters={filters}
          defaultSortBy={sortBy}
        />
      </Flex>
      <AnimatePresence>
        {selectedTransaction && (
          <AssociateCard
            onAssociate={handleLinkTransactionsWithDocument}
            footerText={t('addTransactionModal.footer.info')}
            ctaText={t('addTransactionModal.footer.buttonCTA')}
            flex="none"
            width="100%"
            position="absolute"
            bottom={0}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: '50%', opacity: 0 },
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
          />
        )}
      </AnimatePresence>
    </Box>
  );
};
