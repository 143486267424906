// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { keyframes } from 'styled-components';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

const opacityAnimation = keyframes`
  0%{
    opacity: 0;
  }
  80%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;

export const StyledWrapper = styled.div`
  animation: ${opacityAnimation} linear 0.4s;
`;
