import { Item, ListView, ListViewProps } from '@candisio/design-system';
import { IbanListViewItem } from './IbanListViewItem';

export interface IbanItem {
  key: string;
  iban: string;
  name: string;
  isDefault?: boolean;
  logoUrl?: string;
}

export interface IbanListViewProps
  extends Omit<
    ListViewProps,
    'onAction' | 'selectedItem' | 'onSelectionChange'
  > {
  /** IBAN list items */
  items?: IbanItem[];
  /** Called when IBAN item action invoked */
  onAction?: (selectedItem: string) => void;
  /** Called when currently-selected IBAN item changes */
  onSelectionChange?: (id: string) => Promise<void>;
  /** Currently-selected IBAN item */
  selectedItem?: string;
}

export const IbanListView = ({
  items = [],
  onAction,
  onSelectionChange,
  selectedItem,
  ...restProps
}: IbanListViewProps) => {
  return (
    <ListView
      onAction={key => {
        onAction?.(key as string);
      }}
      {...restProps}
    >
      {items.map(item => (
        <Item key={item.key} textValue={item.name}>
          <IbanListViewItem
            iban={item.iban}
            logoUrl={item.logoUrl}
            isDefault={selectedItem === item.key}
            name={item.name}
            onSetAsDefault={async () => {
              await onSelectionChange?.(item.key);
            }}
          />
        </Item>
      ))}
    </ListView>
  );
};
