import { Button, Flex } from '@candisio/design-system';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

interface GenerationFooterProps {
  canGoBack: boolean;
  onGoBack?: () => void;
}

const MotionFlex = motion(Flex);

export const WizardModalGenerationFooter = ({
  canGoBack,
  onGoBack,
}: GenerationFooterProps) => {
  const [t] = useTranslation();

  return (
    <MotionFlex justifyContent="end" layoutId="backButton">
      <Button
        disabled={!canGoBack}
        onClick={onGoBack}
        size="small"
        variant="secondary"
        icon="arrowLeft"
      >
        {t('common.import.goBackToUpload')}
      </Button>
    </MotionFlex>
  );
};
