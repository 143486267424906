import { ProcessedDocument } from 'components/Form/DocumentForm/toolkit/types';
import { ProcessingFormContactFieldItem } from 'components/Form/ProcessingForm/ProcessingFormContactField';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { DocumentStatusFrontend } from 'models';
import { MonitoringActionsContainer } from './MonitoringActionsContainer';
import { MonitoringFormContainer } from './MonitoringFormContainer';

export interface MonitoringProps {
  document: ProcessedDocument;
  purchaseOrderId?: string;
  contactItem?: ProcessingFormContactFieldItem;
  toggleEditMode?: () => void;
  onGetContactItem?: (value?: ProcessingFormContactFieldItem) => void;
}

export const Monitoring = ({
  document,
  toggleEditMode,
  purchaseOrderId,
  contactItem,
  onGetContactItem,
}: MonitoringProps) => {
  return (
    <ProcessSidebar
      key={purchaseOrderId}
      documentId={document.id}
      globalDocumentId={document.globalDocumentId ?? undefined}
      documentStatus={DocumentStatusFrontend.AwaitingApproval}
      actions={
        <MonitoringActionsContainer
          documentId={document.id}
          onEdit={toggleEditMode}
        />
      }
    >
      <MonitoringFormContainer
        documentId={document.id}
        contactItem={contactItem}
        onGetContactItem={onGetContactItem}
      />
    </ProcessSidebar>
  );
};
