import { Button, Flex } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface CancelCardRequestProps {
  canceling: boolean;
  onCancel: () => Promise<void>;
}

export const CancelCardRequest = ({
  canceling,
  onCancel,
}: CancelCardRequestProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Flex
      position="sticky"
      bottom={0}
      paddingY="space16"
      justifyContent="end"
      background="white"
    >
      <Button
        variant="tertiary"
        color="red"
        onClick={onCancel}
        loading={canceling}
        disabled={canceling}
      >
        {t('drawer.cancelRequest')}
      </Button>
    </Flex>
  );
};
