import {
  Button,
  Grid,
  useTheme,
  Text,
  Flex,
  Box,
} from '@candisio/design-system';
import { ButtonWrapper } from 'components/HistoryList/components/ButtonWrapper';
import { motion } from 'framer-motion';
import { useHover } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { FilterNameTags } from './FilterNameTags';
import { TransactionDownloadItem } from './hooks/useTransactionDownloadHistoryData';

const MotionGrid = motion(Grid);

interface TransactionDownloadHistoryItemProps {
  item: TransactionDownloadItem;
  queryString: string;
}

export const TransactionDownloadHistoryItem = ({
  item,
  queryString,
}: TransactionDownloadHistoryItemProps) => {
  const [t] = useTranslation();
  const { colors } = useTheme();
  const { hoverProps, isHovered } = useHover({});
  const dateFormatter = DateFormatters.compactDatetime;

  const hasFilters = Object.values(item.filters).some(Boolean);

  const hasDownloadableFile = item.file?.url;

  return (
    <MotionGrid
      gap="space32"
      padding="space16"
      alignItems="center"
      templateColumns="minmax(50ch, 1fr) auto auto"
      borderBottom="1px solid gray200"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeOut', duration: 0.5 }}
      {...hoverProps}
    >
      <Box>
        <Text
          overflow="hidden"
          whiteSpace="nowrap"
          fontSize="basic"
          style={{ textOverflow: 'ellipsis' }}
        >
          {renderQueryHighlight({
            value: item.file?.name,
            queryString: queryString,
          })}
        </Text>
        <Flex as="p" color="gray500" fontSize="small" gap="space4">
          <Text>{dateFormatter(new Date(item.createdAt))}</Text>
          <Text>{`${t('importHistoryList.bulletPoint')}`}</Text>
          {item.username && <Text>{item.username}</Text>}
        </Flex>
      </Box>
      {hasFilters && <FilterNameTags filters={item.filters} />}

      {isHovered && hasDownloadableFile && (
        <ButtonWrapper>
          <Button
            as="a"
            position="absolute"
            alignSelf="center"
            right={8}
            icon="arrowDown"
            size="small"
            href={item.file?.url}
            style={{ color: colors.gray0 }}
          />
        </ButtonWrapper>
      )}
    </MotionGrid>
  );
};
