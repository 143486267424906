import {
  Button,
  Flex,
  Grid,
  Popover,
  PopoverProps,
  Text,
} from '@candisio/design-system';
import { motion } from 'framer-motion';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';

const MotionPopover = motion(Popover);

interface EcmSearchFieldFilterActivationPopoverProps {
  popoverProps: PopoverProps;
  popoverRef: RefObject<HTMLDivElement>;
  onClose: () => void;
}

export const EcmSearchFieldFilterActivationPopover = ({
  popoverProps,
  popoverRef,
  onClose,
}: EcmSearchFieldFilterActivationPopoverProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <MotionPopover
      {...(popoverProps as any)}
      ref={popoverRef}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -10, opacity: 0 }}
      transition={{ type: 'spring', duration: 2, bounce: 0.5 }}
      padding="space16"
      dismissWithOutsideClick={false}
      background="blue700"
      maxWidth="16.25rem"
    >
      <Grid gap="space12">
        <Text
          color="white"
          fontSize="basic"
          fontWeight="semibold"
          lineHeight="paragraph"
        >
          {t('searchFilter.activationPopover.title')}
        </Text>
        <Text color="white" fontSize="basic" lineHeight="paragraph">
          {t('searchFilter.activationPopover.description')}
        </Text>

        <Button
          color="whiteBlue"
          size="small"
          variant="primary"
          onClick={onClose}
        >
          {t('searchFilter.activationPopover.closePopoverButton')}
        </Button>
      </Grid>
    </MotionPopover>
  );
};
