import { Button, Flex, Grid } from '@candisio/design-system';
import {
  ArchiveButton,
  ReActivateRow,
} from 'components/ArchiveWrapper/ArchiveWrapper';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { AccountCodeField } from 'views/Settings/BookingAccounts/components/BookingAccountForm/toolkit/fields/AccountCodeField';
import { NameField } from 'views/Settings/BookingAccounts/components/BookingAccountForm/toolkit/fields/NameField';
import { useBookingAccountSchema } from 'views/Settings/BookingAccounts/components/BookingAccountForm/toolkit/hooks/useBookingAccountSchema';
import {
  BookingAccountFormData,
  BookingAccountFormProps,
} from 'views/Settings/BookingAccounts/components/BookingAccountForm/types';

export const BookingAccountForm = ({
  updateStatusLoading,
  onStatusChange,
  onChange,
  bookingAccount,
}: BookingAccountFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.BOOKING_ACCOUNTS);
  const { isActiveIntegration: shouldUseSapGL } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();
  const { id, accountCode, name, isArchived } = bookingAccount || {};
  const { schema: zodSchema, errorMessages } =
    useBookingAccountSchema(accountCode);

  const isArchivedBookingAccount = Boolean(id && isArchived);
  const isFormReadonly =
    isArchivedBookingAccount || shouldUseSapGL || shouldUseCoreDataApi;

  const form = useForm<BookingAccountFormData>({
    defaultValues: {
      accountCode,
      name,
    },
    mode: 'onBlur',
    resolver: zodResolver({
      zodSchema,
      errorMessages,
      translationNamespace: LOCALE_NAME_SPACE.BOOKING_ACCOUNTS,
    }),
    shouldFocusError: true,
  });

  const handleSubmit = form.handleSubmit(async values => {
    await onChange(values);
  });

  const canDisplayFooterActions = !shouldUseSapGL && !shouldUseCoreDataApi;
  const canRestoreBookingAccount =
    isArchivedBookingAccount && !shouldUseSapGL && !shouldUseCoreDataApi;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          void handleSubmit(e);
        }}
      >
        <Flex
          justifyContent="space-between"
          direction="column"
          height="calc(100vh - 120px)"
        >
          {canRestoreBookingAccount && (
            <Grid paddingBottom="space32">
              <ReActivateRow
                onRestore={() => onStatusChange(false)}
                isSubmitting={updateStatusLoading}
                disabled={updateStatusLoading}
              />
            </Grid>
          )}
          <Grid flexGrow={1}>
            <Flex direction="column" gap="space16">
              <AccountCodeField readOnly={isFormReadonly} />
              <NameField readOnly={isFormReadonly} />
            </Flex>
          </Grid>

          {canDisplayFooterActions && (
            <Flex alignItems="center" justifyContent="space-between">
              <Button
                type="submit"
                disabled={isFormReadonly}
                data-cy="booking-account-save-button"
              >
                {t('form.actions.save')}
              </Button>
              {id && !isArchived && (
                <ArchiveButton
                  onStatusChange={onStatusChange}
                  updateStatusLoading={updateStatusLoading}
                />
              )}
            </Flex>
          )}
        </Flex>
      </form>
    </FormProvider>
  );
};
