import { Grid } from '@candisio/design-system';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { noop } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { MAX_DAYS_AGO } from '../constants';
import { getAllowedMinDate as defaultGetAllowedMinDate } from '../utils/getMinDate';
import { errorMessages, schema } from './DatePickerForm.schema';

interface DatePickerFormProps {
  initialValues: { startDate: string };
  autoFocus: boolean;
  readOnly?: boolean;
  updatePaymentStartDate: ({
    variables,
  }: {
    variables: { startDate: string };
  }) => void;
  isActive: boolean;
  activatePayment: ({
    variables,
  }: {
    variables: { startDate: string };
  }) => void;
  getAllowedMinDate?: (daysAgo: number) => string;
}

export const DatePickerForm = ({
  initialValues,
  autoFocus,
  readOnly,
  updatePaymentStartDate,
  isActive,
  activatePayment,
  getAllowedMinDate = defaultGetAllowedMinDate,
}: DatePickerFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { success } = useToastMessage();

  const form = useForm({
    defaultValues: initialValues,
    mode: 'all',
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
      zodSchema: schema(t),
      errorMessages,
    }),
    shouldFocusError: true,
  });

  // Destructuring formState to read the formState before render to subscribe the form state through the Proxy
  // See: https://react-hook-form.com/docs/useform/formstate
  const { isValid } = form.formState;

  const submitActivatePayment = async (date: string) => {
    if (isActive) {
      await updatePaymentStartDate({
        variables: { startDate: date },
      });

      success(
        t('payments.activation.toastMessage.updateStartDate', {
          date: date,
        })
      );
    } else {
      await activatePayment({
        variables: { startDate: date },
      });
      success(
        t('payments.activation.toastMessage.activate', {
          date: date,
        })
      );
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(noop)}>
        <Grid templateColumns="5fr 7fr" gap="space32" alignItems="end">
          <HookFormDatePickerField
            name="startDate"
            readOnly={readOnly}
            disabled={readOnly}
            aria-label="payments-start-date"
            label={t('payments.activation.startDate.label')}
            /** @ts-expect-error TODO: React upgrade missing placeholder type */
            placeholder={t('payments.activation.startDate.placeholder')}
            autoFocus={autoFocus}
            minValue={getAllowedMinDate(MAX_DAYS_AGO)}
            onChange={date => {
              if (!date || !isValid) {
                return;
              }

              void submitActivatePayment(date);
            }}
          />
        </Grid>
      </form>
    </FormProvider>
  );
};
