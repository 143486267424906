import React from 'react';
import {
  FocusScope,
  mergeProps,
  useDialog,
  useModal,
  usePreventScroll,
} from 'react-aria';
import mergeRefs from 'react-merge-refs';
import {
  BorderProps,
  ColorProps,
  LayoutProps,
  PaddingProps,
  StandardHTMLAttributes,
} from '../../types';
import { Box } from '../Box';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { ScrollBox, ScrollBoxProps } from '../ScrollBox';
import { Heading } from '../Typography/Heading';

export interface DialogProps
  extends LayoutProps,
    BorderProps,
    ColorProps,
    PaddingProps,
    Omit<StandardHTMLAttributes<HTMLDivElement>, 'title'> {
  autoFocus?: boolean;
  closeLabel?: string;
  isModal?: boolean;
  onClose?: () => void;
  role?: 'dialog' | 'alertdialog';
  scrollDirection?: ScrollBoxProps['scrollDirection'];
  title?: React.ReactNode;
  titleInfo?: React.ReactNode;
}

/**
 * Dialog atom provides dialog container for various modal molecules.
 *
 * @param {string} title Title of Heading to appear above Dialog content
 * @param {string} closeLabel Label for close button Tooltip
 * @param {boolean} [autoFocus = true] Whether to automatically focus the first element.
 */
export const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      'aria-labelledby': ariaLabelledBy,
      autoFocus = true,
      background = 'gray0',
      borderRadius = 'medium',
      children,
      closeLabel,
      color = 'gray800',
      id,
      isModal = false,
      onClose,
      padding = 'space32',
      role = 'dialog',
      scrollDirection = 'both',
      title,
      titleInfo,
      ...restProps
    },
    forwardedRef
  ) => {
    // Handle interacting outside the dialog and pressing
    // the Escape key to close the modal.
    const dialogRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

    // Prevent scrolling while the modal is open, and hide content
    // outside the modal from screen readers.
    usePreventScroll({ isDisabled: !isModal });
    const { modalProps } = useModal({ isDisabled: !isModal });

    // Get props for the dialog and its title
    const { dialogProps, titleProps } = useDialog(
      { 'aria-labelledby': ariaLabelledBy, id, role },
      dialogRef
    );

    return (
      <FocusScope contain={isModal} restoreFocus autoFocus={autoFocus}>
        <Flex
          direction="column"
          background={background}
          borderRadius={borderRadius}
          color={color}
          {...mergeProps(modalProps, dialogProps, restProps)}
          ref={mergeRefs([dialogRef, forwardedRef])}
        >
          {(title !== undefined ||
            titleInfo !== undefined ||
            closeLabel !== undefined) && (
            <Flex
              alignItems="center"
              flex="none"
              justifyContent="end"
              padding="space32"
              paddingBottom={0}
            >
              {title !== undefined && (
                <Heading as="h3" flex="1" {...titleProps}>
                  {title}
                </Heading>
              )}
              {titleInfo !== undefined && <Box flex="1">{titleInfo}</Box>}
              {closeLabel !== undefined && (
                <Button
                  flex="none"
                  icon="close"
                  variant="tertiary"
                  onClick={onClose}
                  label={closeLabel}
                />
              )}
            </Flex>
          )}
          {children !== undefined && (
            <ScrollBox
              flex="1"
              padding={padding}
              scrollDirection={scrollDirection}
            >
              {children}
            </ScrollBox>
          )}
        </Flex>
      </FocusScope>
    );
  }
);
