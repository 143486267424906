import { Card, Grid, Icon, Link, Text } from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';

export interface InfoBoxProps {
  isTransactionExported: boolean;
  cardRefNumber?: string;
  creditCardId?: string;
}

export const InfoBox = ({
  isTransactionExported,
  creditCardId,
  cardRefNumber,
}: InfoBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { isCardManager, isOnlyApprover } = useUserRoles();
  const orgId = useOrganizationId();

  if (!creditCardId || !cardRefNumber) {
    return null;
  }

  if (isTransactionExported) {
    return (
      <Card background="bluebg">
        <Grid templateColumns="auto 1fr" gap="space4">
          <Icon size="space20" icon="infoCircle" color="blue500" />
          <Text>{t('accountingDataInfoBox.transactionExported')}</Text>
        </Grid>
      </Card>
    );
  }

  const linkToCreditCard = isCardManager
    ? `/${orgId}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}/${creditCardId}?onCreditCardsView=true`
    : isOnlyApprover
      ? `/${orgId}${Routes.DASHBOARD_CREDIT_CARD}/${creditCardId}?cardId=${creditCardId}`
      : undefined;

  const translation = isCardManager ? (
    <Trans
      t={t}
      parent="span"
      i18nKey="accountingDataInfoBox.cardManager.textWithLink"
      values={{ cardRefNumber: `•••• ${cardRefNumber}` }}
    >
      Accounting data for transactions can be edited once a document is linked
      to the transaction. Source of accounting data is the
      <Link external href={linkToCreditCard}>
        {{ cardRefNumber } as any}
      </Link>
    </Trans>
  ) : isOnlyApprover ? (
    <Trans
      t={t}
      parent="span"
      i18nKey="accountingDataInfoBox.approver.textWithLink"
      values={{ cardRefNumber: `•••• ${cardRefNumber}` }}
    >
      Accounting data for transactions can be edited once a document is linked
      to the transaction. Source of accounting data is the
      <Link external href={linkToCreditCard}>
        {{ cardRefNumber } as any}
      </Link>
    </Trans>
  ) : (
    t('accountingDataInfoBox.cardManager.text', { cardRefNumber })
  );

  return (
    <Card background="bluebg">
      <Grid templateColumns="auto 1fr" gap="space4">
        <Icon size="space20" icon="infoCircle" color="blue500" />
        <Text>{translation}</Text>
      </Grid>
    </Card>
  );
};
