import { defaultTheme } from '@candisio/design-system';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

export const DocumentRequestErrors = styled.p`
  text-align: left;
  color: ${defaultTheme.colors.red500}!important;
  margin-top: -4px;
  margin-bottom: 12px;
  text-transform: none !important;
  font-size: ${defaultTheme.fontSizes.small};
`;
