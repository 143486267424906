import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { LayoutProps } from '../../types';
import { Box } from '../Box';

const MotionBox = motion(Box);

const boxVariants = {
  visible: {
    opacity: 1,
    transition: { duration: 0.3, ease: 'easeInOut' },
  },
  hidden: {
    opacity: 0,
  },
};

interface ContentBoxProps extends LayoutProps {
  children: string | ReactNode;
  element: React.Ref<HTMLDivElement | HTMLLabelElement> | undefined;
  isLoading?: boolean;
}

export const ContentBox = ({
  children,
  element,
  isLoading,
  ...restProps
}: ContentBoxProps) => {
  return (
    <MotionBox
      variants={boxVariants}
      initial="hidden"
      animate={isLoading ? 'hidden' : 'visible'}
      ref={element}
      {...restProps}
    >
      {children}
    </MotionBox>
  );
};
