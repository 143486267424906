import { Button, Flex, Grid, Icon, Link, Text } from '@candisio/design-system';
import {
  BdsPermissionError,
  BdsPermissionStatus,
  useCreateTestBdsExtfImportJobMutation,
  useDatevBdsPermissionQuery,
  useDisconnectDatevClientMutation,
  useNew_DatevSettingsQuery,
} from 'generated-types/graphql.types';
import { usePolling } from 'hooks/usePolling';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from 'views/Settings/utils';
import { refetchDatevDataWithNoToken } from '../../../gql';
import { DcsContainer } from '../../DcsContainer/DcsContainer';

export interface BDSPermissionCheckProps {}

const ConnectionTest = ({
  loading,
  handleCreateTest,
  resetConnection,
}: {
  loading: boolean;
  handleCreateTest: () => void;
  resetConnection: () => void;
}) => {
  const [t] = useTranslation();

  return (
    <Grid gap="space24">
      <Text fontSize="basic">
        {t('settings.datev.bdsPermissionCheck.initialState.description')}
      </Text>
      <Flex gap="space12" alignItems="center">
        <Button variant="secondary" onClick={resetConnection}>
          {t('settings.datev.bdsPermissionCheck.initialState.cancelButton')}
        </Button>
        <Button loading={loading} onClick={handleCreateTest}>
          {t('settings.datev.bdsPermissionCheck.initialState.button')}
        </Button>{' '}
        {loading && (
          <Text fontSize="basic">
            {t('settings.datev.bdsPermissionCheck.initialState.loading')}
          </Text>
        )}
      </Flex>
    </Grid>
  );
};

const ConnectionTestSucceeded = () => {
  const [t] = useTranslation();
  const { refetch: refetchDatevSettings } = useNew_DatevSettingsQuery();
  const completeConnectionTest = async () => {
    await refetchDatevSettings();
  };

  return (
    <Grid gap="space24">
      <Text fontSize="basic">
        {t('settings.datev.bdsPermissionCheck.successState.description')}
      </Text>
      <Flex>
        <Button onClick={completeConnectionTest}>
          {t('settings.datev.bdsPermissionCheck.successState.button')}
        </Button>
      </Flex>
    </Grid>
  );
};

const ConnectionTestFailed = ({
  error,
  helpLink,
  resetConnection,
}: {
  error?: string;
  helpLink?: string;
  resetConnection: () => void;
}) => {
  const [t] = useTranslation();

  return (
    <Grid gap="space24">
      <Grid gap="space12">
        <Text fontSize="basic">{error}</Text>
        {helpLink && (
          <Link external href={helpLink}>
            {t('settings.datev.bdsPermissionCheck.failedState.helpLinkText')}
          </Link>
        )}
      </Grid>
      <Flex>
        <Button variant="secondary" onClick={resetConnection}>
          {t('settings.datev.bdsPermissionCheck.failedState.button')}
        </Button>
      </Flex>
    </Grid>
  );
};

export const BDSPermissionCheck = () => {
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const organizationSlug = useOrganizationId();
  const [t] = useTranslation();

  const [disconnectDatevClient] = useDisconnectDatevClientMutation({
    refetchQueries: [
      ...refetchDatevDataWithNoToken,
      { query: getFullOrganizationQuery },
    ],
  });

  const resetConnection = useCallback(async () => {
    // 1. Disconnect the client
    await disconnectDatevClient();
    // 2. Redirect to datev setting page
    navigate(`/${organizationSlug}${Routes.SETTINGS}${Routes.DATEV}`);
  }, [navigate, organizationSlug, disconnectDatevClient]);

  const [createTestBDSExtfImportJob] = useCreateTestBdsExtfImportJobMutation();
  const handleCreateTest = async () => {
    await createTestBDSExtfImportJob();
    setloading(true);
  };

  const { data, startPolling, stopPolling } = useDatevBdsPermissionQuery();

  usePolling({
    pollInterval: 5000,
    skip: data?.datevBdsPermission.status === BdsPermissionStatus.Succeeded,
    startPolling,
    stopPolling,
  });

  useEffect(() => {
    if (data?.datevBdsPermission.status === BdsPermissionStatus.Succeeded) {
      setloading(false);
    }
  }, [data?.datevBdsPermission.status]);

  const getMappedErrorTranslation = ({
    error,
  }: {
    error: BdsPermissionError;
  }) => {
    switch (error) {
      case BdsPermissionError.OnlineRightOrLicenseMissing:
        return {
          title: t(
            'settings.datev.bdsPermissionCheck.errors.onlineRightOrLicenseMissing.title'
          ),
          description: t(
            'settings.datev.bdsPermissionCheck.errors.onlineRightOrLicenseMissing.description'
          ),
          link: t(
            'settings.datev.bdsPermissionCheck.errors.onlineRightOrLicenseMissing.helpLink'
          ),
        };
      case BdsPermissionError.ClientPermissionMissing:
        return {
          title: t(
            'settings.datev.bdsPermissionCheck.errors.clientPermissionMissing.title'
          ),
          description: t(
            'settings.datev.bdsPermissionCheck.errors.clientPermissionMissing.description'
          ),
          link: t(
            'settings.datev.bdsPermissionCheck.errors.clientPermissionMissing.helpLink'
          ),
        };
      default:
        return {
          title: t(
            'settings.datev.bdsPermissionCheck.errors.unknownError.title'
          ),
          description: t(
            'settings.datev.bdsPermissionCheck.errors.unknownError.description'
          ),
        };
    }
  };

  const renderConnectionTestCard = () => {
    const status = data?.datevBdsPermission.status;
    const error =
      data?.datevBdsPermission.__typename === 'BdsPermissionErrorResult'
        ? data?.datevBdsPermission.error
        : undefined;

    if (status === BdsPermissionStatus.Succeeded)
      return (
        <DcsContainer
          title={
            <Flex gap="space4" alignItems="center">
              <Icon icon="checkCircle" color="green600" size="space20" />
              <Text>
                {t('settings.datev.bdsPermissionCheck.successState.title')}
              </Text>
            </Flex>
          }
          details={<ConnectionTestSucceeded />}
        />
      );

    if (status === BdsPermissionStatus.Failed) {
      if (error === BdsPermissionError.NoTestExtfJobFound) {
        return (
          <DcsContainer
            title={t('settings.datev.bdsPermissionCheck.initialState.title')}
            details={
              <ConnectionTest
                loading={loading}
                handleCreateTest={handleCreateTest}
                resetConnection={resetConnection}
              />
            }
          />
        );
      } else {
        if (!error) return null;
        const mappedErrorTranslation = getMappedErrorTranslation({ error });

        return (
          <DcsContainer
            title={
              <Flex gap="space4" alignItems="center">
                <Icon icon="failCircle" color="red600" size="space20" />
                <Text>{mappedErrorTranslation.title}</Text>
              </Flex>
            }
            details={
              <ConnectionTestFailed
                error={mappedErrorTranslation.description}
                helpLink={mappedErrorTranslation.link}
                resetConnection={resetConnection}
              />
            }
          />
        );
      }
    }
  };

  return (
    <Grid
      id="bds-connection-test-container"
      rowGap="space12"
      maxWidth={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      {renderConnectionTestCard()}
    </Grid>
  );
};
