import { CSSObject } from '@emotion/react';
import React from 'react';
import { AriaMenuItemProps, useMenuItem } from 'react-aria';
import { SingleSelectListState, TreeState } from 'react-stately';
import { useTheme } from '../../Theme';
import { Box } from '../Box';
import { MenuItem } from './Menu';

export interface MenuListItemProps {
  /** Node describing state of the item */
  item: SingleSelectListState<MenuItem>['selectedItem'];
  /** Action invoked by the item */
  onAction: AriaMenuItemProps['onAction'];
  /** Action invoked on closing the menu containing the item */
  onClose: AriaMenuItemProps['onClose'];
  /** State of the menu containing the item */
  state: TreeState<MenuItem>;
  /** Whether or not to close the menu once an item is clicked */
  closeOnSelect?: AriaMenuItemProps['closeOnSelect'];
  showSeparator?: boolean;
}

export const MenuListItem: React.FC<MenuListItemProps> = ({
  item,
  onAction,
  onClose,
  state,
  closeOnSelect = true,
  showSeparator = false,
}) => {
  const ref = React.useRef(null);
  const { colors, menuListItem, space } = useTheme();
  const isSelected = state.selectionManager.isSelected(item.key);
  const isDisabled = state.disabledKeys.has(item.key);
  const { menuItemProps } = useMenuItem(
    {
      isDisabled,
      isSelected,
      key: item.key,
      onAction,
      onClose,
      closeOnSelect,
    },
    state,
    ref
  );

  const isFocused = state.selectionManager.focusedKey === item.key;

  const separatorStyle: CSSObject = {
    position: 'relative',
    '::after': {
      content: '""',
      position: 'absolute',
      left: space.space8,
      right: space.space8,
      bottom: 0,
      borderBottom: `1px solid ${colors.gray250}`,
    },
  };

  return (
    <Box
      as="li"
      {...menuItemProps}
      ref={ref}
      paddingY="space8"
      paddingX="space16"
      fontSize="basic"
      css={{
        outline: 'none',
        ...menuListItem.default,
        ...(isSelected && menuListItem.selected),
        ...(isFocused && menuListItem.focused),
        ...(isDisabled && menuListItem.disabled),
        ...(showSeparator && separatorStyle),
      }}
    >
      {item.rendered}
    </Box>
  );
};
