import {
  Button,
  defaultTheme,
  Flex,
  Grid,
  Icon,
  Popover,
  TextField,
  Tooltip,
  usePopover,
  useTooltip,
} from '@candisio/design-system';
import { EcmSearchFieldFilterOverlay } from 'components/EcmSearchFieldFilterOverlay/EcmSearchFieldFilterOverlay';
import { SearchFilterUpsellModal } from 'components/EcmSearchFieldFilterOverlay/SearchFilterUpsellModal';
import {
  searchScopeParameter,
  searchTypeParameter,
} from 'components/Table/consts';
import { motion } from 'framer-motion';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { EcmSearchFieldFilterActivationPopover } from './EcmSearchFieldFilterActivationPopover';
import { useWhatsNewPopover } from 'components/WhatsNewPopover/useWhatsNewPopover';

const MotionGrid = motion(Grid);

export interface EcmSearchFieldProps {
  autoFocus?: boolean;
  clearTooltip?: string;
  inputMaxLength?: number;
  width?: number;
  placeholder: string;
  value: string;
  readOnly?: boolean;
  onQueryChange: (filter: string) => void;
}

interface ClearButtonProps {
  value: string;
  onClear: () => void;
  clearTooltip?: string;
}

const transition = {
  default: { duration: 0.25, ease: 'easeInOut' },
};

const variants = {
  visible: { opacity: 1, scale: 1, visibility: 'visible' },
  hidden: { opacity: 0, scale: 0.6, visibility: 'hidden' },
  hover: { cursor: 'pointer', color: '#2b1a2e99' /* gray800 + 60% opacity */ },
};

const searchInputWidth = '2.125rem' /* 34px */;

const ACTIVATION_LOCAL_STORAGE_KEY = 'search-filter-overlay-activation-seen';

export const EcmSearchField = ({
  autoFocus,
  clearTooltip,
  inputMaxLength,
  width,
  placeholder,
  value,
  readOnly = false,
  onQueryChange,
}: EcmSearchFieldProps) => {
  const [inputHasFocus, setInputHasFocus] = useState(false);

  const [t] = useTranslation();

  const {
    close,
    setOpen,
    isOpen,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({ placement: 'bottom' });

  const [isPromoModalOpen, setIsPromoModalOpen] = useState(false);
  const openPromoModal = () => {
    close();
    setIsPromoModalOpen(true);
  };
  const closePromoModal = () => {
    setIsPromoModalOpen(false);
    setOpen(true);
  };

  const { showSearchOverlay, showSearchOverlayPromo } = useEcm();

  const {
    shouldOpenActivationPopover,
    targetProps: activationTargetProps,
    targetRef: activationTargetRef,
    isOpen: activationIsOpen,
    popoverProps: activationPopoverProps,
    popoverRef: activationPopoverRef,
    close: activationClose,
  } = useWhatsNewPopover({
    activationLocalStorageKey: ACTIVATION_LOCAL_STORAGE_KEY,
    placement: 'top',
  });

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showSearchOverlay && shouldOpenActivationPopover) {
      inputRef.current?.focus();
    }
  }, [showSearchOverlay, shouldOpenActivationPopover]);

  const showFilter =
    inputHasFocus || value.length > 0 || isOpen || activationIsOpen;

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (value === '') {
      searchParams.delete(searchTypeParameter);
      searchParams.delete(searchScopeParameter);
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, value]);

  return (
    <Flex alignItems="center" alignContent="center" position="relative">
      <TextField
        flex={1}
        aria-label={t('search.label')}
        readOnly={readOnly}
        inputRef={inputRef}
        input={{
          autoFocus,
          size: width,
          placeholder,
          readOnly,
          value,
          maxLength: inputMaxLength,
          style: { maxWidth: width ? 'auto' : '85%' },
          onChange: e => onQueryChange(e.target.value),
          onFocus: () => setInputHasFocus(true),
          onBlur: () => setInputHasFocus(false),
        }}
      />
      <MotionGrid
        position="absolute"
        right={searchInputWidth}
        initial="hidden"
        animate={showFilter ? 'visible' : 'hidden'}
        transition={transition}
        variants={variants}
        {...activationTargetProps}
        ref={activationTargetRef}
      >
        <Button
          aria-label={t('search.filter')}
          style={{ padding: defaultTheme.space.space4 }}
          color="blue"
          icon="filterOutlined"
          variant="tertiary"
          isPressed={isOpen}
          {...triggerProps}
          ref={triggerRef}
        />
      </MotionGrid>

      {value.length === 0 && (
        <MotionGrid
          position="absolute"
          right="0"
          alignContent="center"
          justifySelf="end"
          padding="space10"
          animate="visible"
          transition={transition}
          variants={variants}
        >
          <Icon icon="search" size="space14" />
        </MotionGrid>
      )}
      <ClearButton
        value={value}
        clearTooltip={clearTooltip}
        onClear={() => onQueryChange('')}
      />

      {isOpen && (
        <Popover {...popoverProps} ref={popoverRef} autoFocus restoreFocus>
          <EcmSearchFieldFilterOverlay
            onApply={close}
            onShowPromoModal={openPromoModal}
          />
        </Popover>
      )}
      {showSearchOverlayPromo && (
        <SearchFilterUpsellModal
          isOpen={isPromoModalOpen}
          onClose={closePromoModal}
        />
      )}

      {showSearchOverlay && activationIsOpen && (
        <EcmSearchFieldFilterActivationPopover
          onClose={activationClose}
          popoverProps={activationPopoverProps}
          popoverRef={activationPopoverRef}
        />
      )}
    </Flex>
  );
};

function ClearButton({ value, onClear, clearTooltip }: ClearButtonProps) {
  const [t] = useTranslation();

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();
  return (
    <>
      {value.length > 0 && (
        <MotionGrid
          position="absolute"
          right="0"
          as="button"
          type="button"
          borderRadius="full"
          aria-label={t('search.clear')}
          alignContent="center"
          justifySelf="end"
          background="transparent"
          border="none"
          opacity="0"
          padding="space10"
          animate="visible"
          whileHover="hover"
          transition={transition}
          variants={variants}
          {...triggerProps}
          onClick={onClear}
          ref={triggerRef}
        >
          <Icon icon="close" size="space14" />
        </MotionGrid>
      )}
      {clearTooltip && isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {clearTooltip}
        </Tooltip>
      )}
    </>
  );
}
