import {
  ExportableEntityType,
  useGetAvailableMonthsQuery,
} from 'generated-types/graphql.types';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { useMemo } from 'react';
import {
  hasTransaction,
  isExportTypeWithTimeframe,
} from 'views/Integrations/Export/toolkit/utils';
import {
  ExportEntity,
  ExportType,
  TimeFrame,
  TimeFrameOption,
} from 'views/Integrations/Export/types';
import {
  isSupportedInOutDocument,
  isSupportedOtherDocument,
} from 'views/utils/DocumentDirection';
import { csvExportTypesDatev, csvExportTypesNonDatev } from '../../consts';
import { getSelectedTimeFrameIndexFromQuery } from '../utils';

type SelectedTimeFrameProps = {
  entities: ExportEntity[];
  exportAll: boolean;
  exportType: ExportType | null;
  exportId: string | null;
};

export const organizeTimeFrames = (
  organizedTimeFrames: Record<string, TimeFrameOption>,
  timeFrame: TimeFrame
) => {
  const dateObject = new Date(timeFrame.invoiceDate);
  const monthYear = dateObject.toLocaleString('en-us', {
    month: 'long',
    year: 'numeric',
  });

  if (!organizedTimeFrames[monthYear]) {
    organizedTimeFrames[monthYear] = {
      reimbursementItemsIds: timeFrame.reimbursementItemId
        ? [timeFrame.reimbursementItemId]
        : [],
      documentIds: timeFrame.documentId ? [timeFrame.documentId] : [],
      transactionIds: timeFrame.transactionId ? [timeFrame.transactionId] : [],
      cardSettlementIds: timeFrame.cardSettlementId
        ? [timeFrame.cardSettlementId]
        : [],
      provisionIds: timeFrame.provisionId ? [timeFrame.provisionId] : [],
      reversalIds: timeFrame.reversalId ? [timeFrame.reversalId] : [],
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      numberOfExportableEntities: 1,
      numberOfDocuments: timeFrame.numberOfDocuments || 0,
      numberOfTxnBasedDocuments: timeFrame.numberOfTxnBasedDocuments || 0,
      numberOfTxnWithoutDocuments: timeFrame.numberOfTxnWithoutDocuments || 0,
      numberOfSettlements: timeFrame.numberOfSettlements || 0,
      numberOfDocumentProvisions: timeFrame.numberOfDocumentProvisions || 0,
      numberOfTxnProvisions: timeFrame.numberOfTxnProvisions || 0,
      numberOfDocumentReversals: timeFrame.numberOfDocumentReversals || 0,
      numberOfTxnReversals: timeFrame.numberOfTxnReversals || 0,
      numberOfReimbursementItems: timeFrame.numberOfReimbursementItems || 0,
    };
  } else {
    timeFrame.documentId &&
      organizedTimeFrames[monthYear].documentIds.push(timeFrame.documentId);
    timeFrame.transactionId &&
      organizedTimeFrames[monthYear].transactionIds.push(
        timeFrame.transactionId
      );
    timeFrame.reimbursementItemId &&
      organizedTimeFrames[monthYear].reimbursementItemsIds.push(
        timeFrame.reimbursementItemId
      );
    timeFrame.cardSettlementId &&
      organizedTimeFrames[monthYear].cardSettlementIds.push(
        timeFrame.cardSettlementId
      );
    timeFrame.provisionId &&
      organizedTimeFrames[monthYear].provisionIds.push(timeFrame.provisionId);
    timeFrame.reversalId &&
      organizedTimeFrames[monthYear].reversalIds.push(timeFrame.reversalId);

    organizedTimeFrames[monthYear].numberOfExportableEntities++;

    if (timeFrame.numberOfDocuments) {
      organizedTimeFrames[monthYear].numberOfDocuments++;
    }

    if (timeFrame.numberOfTxnBasedDocuments) {
      organizedTimeFrames[monthYear].numberOfTxnBasedDocuments++;
    }

    if (timeFrame.numberOfTxnWithoutDocuments) {
      organizedTimeFrames[monthYear].numberOfTxnWithoutDocuments++;
    }

    if (timeFrame.numberOfSettlements) {
      organizedTimeFrames[monthYear].numberOfSettlements++;
    }

    if (timeFrame.numberOfDocumentProvisions) {
      organizedTimeFrames[monthYear].numberOfDocumentProvisions++;
    }

    if (timeFrame.numberOfTxnProvisions) {
      organizedTimeFrames[monthYear].numberOfTxnProvisions++;
    }

    if (timeFrame.numberOfDocumentReversals) {
      organizedTimeFrames[monthYear].numberOfDocumentReversals++;
    }

    if (timeFrame.numberOfTxnReversals) {
      organizedTimeFrames[monthYear].numberOfTxnReversals++;
    }

    if (timeFrame.numberOfReimbursementItems) {
      organizedTimeFrames[monthYear].numberOfReimbursementItems++;
    }
  }

  return organizedTimeFrames;
};

const generateTimeFramesFromEntities = (entities: ExportEntity[]) => {
  const sortByRecentInvoiceDate = (
    a: Record<'invoiceDate', Date | string>,
    b: Record<'invoiceDate', Date | string>
  ): number =>
    new Date(a.invoiceDate).getTime() - new Date(b.invoiceDate).getTime();

  const mappedDocs = entities.map(entity => {
    const hasTx = hasTransaction(entity);

    return {
      invoiceDate: entity.invoiceDate,
      documentId:
        entity.type === ExportableEntityType.Document ? entity.id : undefined,
      transactionId:
        entity.type === ExportableEntityType.CardTransaction
          ? entity.id
          : undefined,
      cardSettlementId:
        entity.type === ExportableEntityType.CardSettlement
          ? entity.id
          : undefined,
      provisionId:
        entity.type === ExportableEntityType.Provision ? entity.id : undefined,
      reversalId:
        entity.type === ExportableEntityType.ProvisionReversal
          ? entity.id
          : undefined,
      reimbursementItemId:
        entity.type === ExportableEntityType.ReimbursementItem
          ? entity.id
          : undefined,
      numberOfExportableEntities: 1,
      numberOfDocuments:
        !hasTx && entity.type === ExportableEntityType.Document ? 1 : 0,
      numberOfTxnBasedDocuments:
        hasTx && entity.type === ExportableEntityType.Document ? 1 : 0,
      numberOfTxnWithoutDocuments:
        entity.type === ExportableEntityType.CardTransaction ? 1 : 0,
      numberOfSettlements:
        entity.type === ExportableEntityType.CardSettlement ? 1 : 0,
      numberOfDocumentProvisions:
        !hasTx && entity.type === ExportableEntityType.Provision ? 1 : 0,
      numberOfTxnProvisions:
        hasTx && entity.type === ExportableEntityType.Provision ? 1 : 0,
      numberOfDocumentReversals:
        !hasTx && entity.type === ExportableEntityType.ProvisionReversal
          ? 1
          : 0,
      numberOfTxnReversals:
        hasTx && entity.type === ExportableEntityType.ProvisionReversal ? 1 : 0,
      numberOfReimbursementItems:
        entity.type === ExportableEntityType.ReimbursementItem ? 1 : 0,
    };
  });

  return Object.values(
    mappedDocs.sort(sortByRecentInvoiceDate).reduce(organizeTimeFrames, {})
  );
};

export const useTimeFrameOptions = (
  entities: ExportEntity[],
  exportType: ExportType | null,
  originalExportId?: string | null,
  exportAll?: boolean
): TimeFrameOption[] => {
  const timeFramesFromEntities = generateTimeFramesFromEntities(entities);

  const skipServerAvailableMonths = Boolean(
    originalExportId || (exportType && !isExportTypeWithTimeframe(exportType))
  );

  const { data: availableMonths } = useGetAvailableMonthsQuery({
    variables: {
      originalExportId: originalExportId,
    },
    fetchPolicy: 'no-cache',
    skip: skipServerAvailableMonths,
  });

  const timeFrameOptions = useMemo<TimeFrameOption[]>(() => {
    return skipServerAvailableMonths || !exportAll
      ? timeFramesFromEntities?.map(timeFrame => ({
          ...timeFrame,
        }))
      : (availableMonths?.getAvailableMonths.map<TimeFrameOption>(
          timeFrame => ({
            documentIds: [],
            cardSettlementIds: [],
            reimbursementItemsIds: [],
            transactionIds: [],
            provisionIds:
              timeFramesFromEntities.filter(
                entityTimeFrame => entityTimeFrame.month === timeFrame.month
              )[0]?.provisionIds ?? [],
            reversalIds:
              timeFramesFromEntities.filter(
                entityTimeFrame => entityTimeFrame.month === timeFrame.month
              )[0]?.reversalIds ?? [],
            numberOfExportableEntities:
              timeFrame.numberOfDocuments +
              timeFrame.numberOfTxnBasedDocuments +
              timeFrame.numberOfTxnWithoutDocuments +
              timeFrame.numberOfSettlements +
              timeFrame.numberOfDocumentProvisions +
              timeFrame.numberOfTxnProvisions +
              timeFrame.numberOfDocumentReversals +
              timeFrame.numberOfReimbursementItems +
              timeFrame.numberOfTxnReversals,
            ...timeFrame,
          })
        ) ?? []);
  }, [
    availableMonths?.getAvailableMonths,
    exportAll,
    skipServerAvailableMonths,
    timeFramesFromEntities,
  ]);

  return timeFrameOptions || [];
};

const supportedCsvExportTypes = [
  ...csvExportTypesDatev,
  ...csvExportTypesNonDatev,
];

export const useSelectedTimeFrame = ({
  entities,
  exportAll,
  exportType,
  exportId,
}: SelectedTimeFrameProps) => {
  const isCsvExportType =
    exportType &&
    supportedCsvExportTypes.includes(
      exportType as (typeof supportedCsvExportTypes)[number]
    );

  const isBdsExport = exportType === ExportType.DATEV_BDS;

  const supportedEntities = !isCsvExportType
    ? entities
    : entities.filter(entity =>
        isBdsExport
          ? isSupportedOtherDocument(entity)
          : isSupportedInOutDocument(entity)
      );

  const timeFrames = useTimeFrameOptions(
    supportedEntities,
    exportType,
    exportId,
    exportAll
  );

  const timeFrameIndexFromQuery = getSelectedTimeFrameIndexFromQuery(
    timeFrames,
    qs.parse(window.location.search)
  );

  const selectedTimeIndex =
    timeFrameIndexFromQuery === -1 ? 0 : timeFrameIndexFromQuery;

  const selectedTimeFrame = timeFrames[selectedTimeIndex];

  return {
    timeFrames,
    selectedTimeFrame,
  };
};
