import { Flex, Grid, Text, Spinner } from '@candisio/design-system';
import { motion } from 'framer-motion';
import { useState, useEffect, ReactElement } from 'react';

export type CheckProp = {
  key: string;
  text: string | ReactElement;
  icon: ReactElement;
  emailDescription?: string;
  state: string;
};
export type CheckProps = CheckProp[];

const MotionFlex = motion(Flex);
const MotionGrid = motion(Grid);

export const CheckList = ({
  checks,
  animate = false,
  retries,
}: {
  checks: CheckProps;
  animate?: boolean;
  retries?: number;
}) => {
  const allChecksAreLoaded =
    checks.filter(check => check.state === 'loaded').length === checks.length;

  const animationState = () => {
    if (retries && retries > 0) {
      return allChecksAreLoaded ? 'loaded' : 'loading';
    }

    return 'loaded';
  };

  return (
    <MotionGrid
      key={retries}
      as="ul"
      listStyle="none"
      gap="space4"
      padding="0"
      justifyContent="left"
      initial={animate ? 'hidden' : undefined}
      variants={{
        hidden: { height: retries && retries > 1 ? 'auto' : 0 },
        loading: {
          height: 'auto',
          transition: { staggerChildren: 1.2 },
        },
        loaded: {
          height: 'auto',
          transition: { staggerChildren: 1.2 },
        },
      }}
      animate={animationState()}
    >
      {checks.map(({ icon, text, key, state }) => (
        <CheckItem key={key} icon={icon} text={text} state={state} />
      ))}
    </MotionGrid>
  );
};

interface CheckItemProps {
  icon: ReactElement;
  text: string | ReactElement;
  state: string;
}

const CheckItem = ({ icon, text, state }: CheckItemProps) => {
  // we persist the loadingText so that it remains visible
  // when text changes to loaded so the animation can switch it out one by one
  const [loadingText, setLoadingText] = useState(state === 'loading' && text);

  useEffect(() => {
    if (state === 'loading') {
      setLoadingText(text);
    }
  }, [state, text]);

  return (
    <MotionFlex
      as="li"
      variants={{
        hidden: { opacity: 0 },
        loading: { opacity: 1 },
        loaded: { opacity: 1 },
      }}
      alignItems="center"
    >
      <MotionFlex
        gap="space4"
        width="100%"
        alignItems="center"
        variants={{
          loading: { opacity: 1, display: 'flex' },
          loaded: { display: 'none' },
        }}
      >
        <Spinner size="space16" />
        <Text>{loadingText}</Text>
      </MotionFlex>
      <MotionFlex
        gap="space4"
        variants={{
          hidden: { display: 'none', opacity: 0 },
          loading: { display: 'none', opacity: 0 },
          loaded: { display: 'flex', opacity: 1 },
        }}
        alignItems="center"
      >
        {icon}
        <Text>{text}</Text>
      </MotionFlex>
    </MotionFlex>
  );
};
