import {
  Box,
  Flex,
  Icon,
  Modal,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { CommentContainer } from 'components/Comment/CommentContainer';
import { DocumentHistoryContainer } from 'components/DocumentHistory/DocumentHistoryContainer';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { PdfInvoiceSkeleton } from 'components/DocumentViewer/PdfViewer/PdfInvoiceSkeleton';
import { DetailsLayout } from 'components/Layouts/DetailsLayout';
import {
  LeftSectionOuterWrapper,
  LeftSectionContentWrapper,
} from 'components/Layouts/styles';
import { ListNavigator } from 'components/ListNavigator/ListNavigator';
import { Loader } from 'components/Loader';
import { TransactionsDetailsCardContainer } from 'components/Transactions/TransactionDetailsCard/TransactionDetailsCard';
import {
  Document,
  InvoiceAssociationStatus,
} from 'generated-types/graphql.types';

import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentHistoryTabsContainer } from 'views/Inbox/DocumentProcessing/components/DocumentHistoryTabs/DocumentHistoryTabsContainer';
import { Attachments } from 'views/Inbox/DocumentProcessing/components/OldAttachments';
import { DocumentTransactionDetails } from 'views/Inbox/Transactions/DocumentTransactionDetails';
import { DocumentTransactionProcessing } from 'views/Inbox/Transactions/DocumentTransactionProcessing';
import {
  DocumentDetailsPanel,
  TransactionViewEmptyState,
} from '../DocumentDetails/components/DocumentDetailsPanel';
import {
  TransactionAssociationProvider,
  useTransactionAssociationContext,
} from './Context';
import { DocumentsWithoutTransactionContainer } from './DocumentsWithoutTransaction/DocumentsWithoutTransaction';
import { InvoiceUploadDisabledState } from './InvoiceState/InvoiceUploadDisabledState';
import { RightInformationContent } from './RightInformationContent';
import { ReadOnlyFormFromDeprecated } from './RightInformationContent/ReadOnlyAccountingDataForm/ReadOnlyFormDeprecated';
import { ReadOnlyTransactionFormContainer } from './RightInformationContent/ReadOnlyAccountingDataForm/ReadOnlyTransactionFormContainer';
import { TransactionReadOnlySideBar } from './RightInformationContent/TransactionReadOnlySidebar';
import { CommentTransactionContainer } from './component/CommentTransactionContainer';
import { InvoiceLayout } from './component/InvoiceUploadContainer';
import { InvoiceUploaderContainer } from './component/InvoiceUploadContainer/InvoiceUploaderContainer';
import { TransactionHistoryContainer } from './component/TransactionHistoryContainer';

const TitleWithInfo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ placement: 'bottom' });

  return (
    <Flex alignItems="center" gap="space8">
      <Text>{t('transactionAssociation.documentsModal.title')}</Text>
      <Flex {...triggerProps} ref={triggerRef}>
        <Icon icon="infoCircle" color="gray700" size="space12" />

        {isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            <Text fontWeight="regular" color="gray800" fontSize="basic">
              {t('transactionAssociation.documentsModal.titleInfo')}
            </Text>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};

export const TransactionAssociationAdminRequesterComponent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const {
    transactionId,
    handleBackToList,
    prevTransactionLink,
    prevTransactionPage,
    nextTransactionLink,
    nextTransactionPage,
    transactionListCount: count,
    isLoadingNavigationData,
    isLoadingCurrentCardTransaction,
    document,
    documentId,
    linkedDocumentStatus,
    attachmentListItems,
    selectedPdf,
    mainDocumentFile,
    attachments,
    attachPermissions,
    ownerTxMembershipId,
    isSectionLoading,
    setSelectedPdf,
    canUserLinkInvoice,
    handleUploaderChange,
    cycleTransactions,
    transactionAmount,
    isNoInvoiceNeededToggleActive,
    setDocumentsNoTxModalIsOpen,
    routeType,
    organizationSlug,
    isArchivedTx,
    onToggleEditDocumentMode,
    showAllExtraFeatures,
    transaction,
    isTransactionExported,
    closeModal,
    documentsNoTxModalIsOpen,
  } = useTransactionAssociationContext();

  const listNavigatorTitle: string = isLoadingNavigationData
    ? t('transactionAssociation.listNavigator.loading')
    : t('transactionAssociation.listNavigator.title', { count });

  const attachmentsTabTitle = t('common:document.tabs.oldAttachments.title');
  const protocolTabTitle = t('common:document.tabs.history');

  return (
    <DetailsLayout
      key={transactionId}
      leftSection={
        <LeftSectionOuterWrapper>
          <ListNavigator
            backToListText={t('common:document.backToDocumentList')}
            arrowLeftTooltip={t(
              'transactionAssociation.listNavigator.prevTransaction'
            )}
            arrowRightTooltip={t(
              'transactionAssociation.listNavigator.nextTransaction'
            )}
            onBackToList={handleBackToList}
            onPrev={prevTransactionLink ? prevTransactionPage : undefined}
            onNext={nextTransactionLink ? nextTransactionPage : undefined}
            listNavigatorCounter={listNavigatorTitle}
            loading={isLoadingNavigationData}
          />
          <LeftSectionContentWrapper>
            <DocumentDetailsPanel
              loadingDoc={isLoadingCurrentCardTransaction}
              document={document as Document}
              emptyState={<TransactionViewEmptyState />}
            />
            <TransactionsDetailsCardContainer
              transactionId={transactionId}
              documentId={documentId}
              linkedDocumentStatus={linkedDocumentStatus}
            />
            {documentId && (
              <DocumentHistoryTabsContainer
                key="tabs"
                defaultSelectedKey={protocolTabTitle}
                chatBox={<CommentContainer documentId={documentId} />}
                tabItems={[
                  {
                    title: protocolTabTitle,
                    children: (
                      <DocumentHistoryContainer documentId={documentId} />
                    ),
                  },
                  {
                    title: attachmentsTabTitle,
                    children: (
                      <Attachments
                        aria-labelledby="attachments"
                        documentId={documentId}
                        items={attachmentListItems}
                        selectedFile={selectedPdf}
                        documentFile={mainDocumentFile}
                        attachments={attachments}
                        canAttachFiles={attachPermissions}
                      />
                    ),
                  },
                ]}
              />
            )}
            {!documentId && (
              <DocumentHistoryTabsContainer
                key="tabs"
                defaultSelectedKey={protocolTabTitle}
                disabledTitles={[attachmentsTabTitle]}
                chatBox={
                  <CommentTransactionContainer
                    transactionId={transactionId}
                    ownerTxMembershipId={ownerTxMembershipId}
                  />
                }
                tabItems={[
                  {
                    title: protocolTabTitle,
                    children: (
                      <TransactionHistoryContainer
                        transactionId={transactionId}
                        isTransactionLoading={isSectionLoading}
                      />
                    ),
                  },
                  {
                    title: attachmentsTabTitle,
                    tabTooltip: t('transactionAssociation.tabs.disabled'),
                  },
                ]}
              />
            )}
          </LeftSectionContentWrapper>
        </LeftSectionOuterWrapper>
      }
      middleSection={
        isSectionLoading ? (
          <Box paddingTop="72px" paddingX="space24" height="100%">
            <PdfInvoiceSkeleton />
          </Box>
        ) : (
          <>
            {documentId ? (
              <Box
                height="100%"
                width="100%"
                paddingX="space16"
                paddingTop="space32"
                overflowY="auto"
              >
                <DocumentViewer
                  documentId={documentId ?? ''}
                  attachments={attachments}
                  canAttachFiles={attachPermissions}
                  documentFile={mainDocumentFile}
                  selectedFile={selectedPdf}
                  onSelectDocument={setSelectedPdf}
                />
              </Box>
            ) : (
              <InvoiceLayout canUserLinkInvoice={canUserLinkInvoice}>
                {canUserLinkInvoice ? (
                  <InvoiceUploaderContainer
                    txId={transactionId}
                    onChange={handleUploaderChange}
                    cycleTransaction={cycleTransactions}
                    transactionAmount={transactionAmount}
                    isNoInvoiceNeededToggleActive={
                      isNoInvoiceNeededToggleActive
                    }
                    setDocumentsNoTxModalIsOpen={setDocumentsNoTxModalIsOpen}
                  />
                ) : (
                  <InvoiceUploadDisabledState />
                )}
              </InvoiceLayout>
            )}
          </>
        )
      }
      rightSection={
        isSectionLoading ? (
          <Loader />
        ) : documentId ? (
          routeType === Routes.INBOX ? (
            <DocumentTransactionProcessing
              documentId={documentId}
              transactionId={transactionId}
              organizationSlug={organizationSlug}
              cycleTransaction={cycleTransactions}
              isArchivedTx={isArchivedTx}
            />
          ) : (
            <DocumentTransactionDetails
              documentId={documentId}
              organizationSlug={organizationSlug}
              cycleTransactions={cycleTransactions}
              onToggleEditDocumentMode={onToggleEditDocumentMode}
            />
          )
        ) : showAllExtraFeatures ? (
          // We need to force the component to re-render when the transactionId changes
          <TransactionReadOnlySideBar
            key={transactionId}
            isInvoicedNotNeeded={
              transaction?.invoiceAssociationStatus ===
              InvoiceAssociationStatus.NotNeeded
            }
            isTxExported={isTransactionExported ?? false}
          >
            <ReadOnlyTransactionFormContainer
              isTransactionExported={isTransactionExported ?? false}
            />
          </TransactionReadOnlySideBar>
        ) : isNoInvoiceNeededToggleActive ? (
          <ReadOnlyFormFromDeprecated
            transaction={transaction}
            isTxExported={isTransactionExported}
          />
        ) : (
          <RightInformationContent
            isLoadingCurrentCardTransaction={isSectionLoading}
          />
        )
      }
    >
      <Modal
        closeLabel={t('transactionAssociation.documentsModal.close')}
        background="gray200"
        width="80vw"
        title={<TitleWithInfo />}
        minHeight="60vh"
        padding="0"
        overflow="hidden"
        shouldCloseOnBlur
        onClose={closeModal}
        isOpen={documentsNoTxModalIsOpen}
      >
        <DocumentsWithoutTransactionContainer
          transactionId={transactionId}
          closeModal={closeModal}
        />
      </Modal>
    </DetailsLayout>
  );
};

export const TransactionAssociationAdminRequester = () => (
  <TransactionAssociationProvider>
    <TransactionAssociationAdminRequesterComponent />
  </TransactionAssociationProvider>
);
