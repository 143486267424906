import { Flex, Tag, Text, useTheme } from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { amountFormat } from 'utils/format';
import { ProgressBarWidget } from '../ProgressBarWidget/ProgressBarWidget';
import { determineTagColor } from '../ProgressBarWidget/utils';

type Url = {
  pathname: string;
  search: string;
};

const NoBudgetAvailable = ({
  openBudgetTab,
  isOwnedByCurrentUser,
}: {
  openBudgetTab: Url | undefined;
  isOwnedByCurrentUser: boolean | undefined;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const { colors } = useTheme();
  if (openBudgetTab && isOwnedByCurrentUser) {
    return (
      <RouterLink
        fontSize="basic"
        to={openBudgetTab}
        // @TODO custom style override should not be needed.
        // Design system should cover this use case
        style={{ color: colors.gray600 }}
      >
        {t('dashboard.createNewWidget.progressBar.noBudget')}
      </RouterLink>
    );
  } else {
    return (
      <Text fontSize="small" color="gray600">
        {t('dashboard.createNewWidget.progressBar.noBudget')}
      </Text>
    );
  }
};

export const BudgetInsightsWithProgressBar = ({
  budget,
  aggregateSum,
  currency,
  openBudgetTab,
  isOwnedByCurrentUser,
}: {
  currency: DocumentCurrency | undefined;
  budget: number | null;
  aggregateSum: number;
  openBudgetTab?: Url;
  isOwnedByCurrentUser?: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  if (!budget) {
    return (
      <NoBudgetAvailable
        openBudgetTab={openBudgetTab}
        isOwnedByCurrentUser={isOwnedByCurrentUser}
      />
    );
  }

  const formattedBudget = amountFormat(budget, currency);
  const percentage = (aggregateSum / budget) * 100;
  const roundedPercentage = Math.floor(percentage);

  return (
    <>
      <Flex paddingBottom="space4" gap="space10" alignItems="center">
        <Flex gap="space2" fontSize="basic" color="gray600">
          <Text>{t('dashboard.createNewWidget.budget')}</Text>
          <Text>{formattedBudget}</Text>
        </Flex>
        <Tag color={determineTagColor(roundedPercentage)} variant="secondary">
          {roundedPercentage}%
        </Tag>
      </Flex>
      <ProgressBarWidget percentage={roundedPercentage} />
    </>
  );
};
