import { FilterOption } from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useArtistSocialInsuranceCodeFilters } from 'views/utils/useArtistSocialInsuranceCodeFilters';
import { useStatusFilters } from '../util';

const useCreditCardPaymentFilters = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  const creditCardPaymentFilters = useMemo(
    () => ({
      data: Array<FilterOption>(
        {
          id: 'true',
          label: t('creditCardPaymentFilter.creditCardPayment'),
        },
        {
          id: 'false',
          label: t('creditCardPaymentFilter.noCreditCardPayment'),
        }
      ),
    }),
    [t]
  );

  return creditCardPaymentFilters;
};

export const useInboxDocumentsFilters = () => {
  const statusFilters = useStatusFilters();
  const creditCardPaymentFilters = useCreditCardPaymentFilters();
  const artistSocialInsuranceCodeFilters =
    useArtistSocialInsuranceCodeFilters();

  const [enableNewIndexInInboxViewFF, consistentSortAndFilteringFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.enableNewIndexInInboxView,
      FEATURE_FLAGS.consistentSortAndFiltering,
    ]);

  const isNewIndexWithConsistentSortEnabled =
    enableNewIndexInInboxViewFF && consistentSortAndFilteringFF;
  const filterOptions = useMemo(
    () => ({
      invoiceDate: isNewIndexWithConsistentSortEnabled,
      dueDate: isNewIndexWithConsistentSortEnabled,
      deliveryDate: isNewIndexWithConsistentSortEnabled,
      createdAt: isNewIndexWithConsistentSortEnabled,
      discountDateWPercentage: isNewIndexWithConsistentSortEnabled,
      tags: enableNewIndexInInboxViewFF,
      status: statusFilters,
      creditCardPayment: creditCardPaymentFilters,
      artistSocialInsuranceCode: artistSocialInsuranceCodeFilters,
    }),
    [
      statusFilters,
      creditCardPaymentFilters,
      artistSocialInsuranceCodeFilters,
      enableNewIndexInInboxViewFF,
      isNewIndexWithConsistentSortEnabled,
    ]
  );

  return filterOptions;
};
