import gql from 'graphql-tag';
import { pageInfoDataFragment } from 'views/queries/fragments';

export const reimbursementListDataFragment = gql`
  fragment ReimbursementListData on ReimbursementCase {
    id
    createdAt
    title
    status
    targetMembership {
      id
      firstName
      lastName
      avatarUrl
    }
    totalAmount {
      amount
      currency
      precision
    }
  }
`;

export const inboxReimbursementsQuery = gql`
  query reimbursementCasesCursorBased(
    $input: ReimbursementCasePaginationInput!
    $filters: ReimbursementCaseFiltersInput
    $sortBy: ReimbursementCaseSortInput
    $queries: ReimbursementCaseQueryInput
  ) {
    reimbursementCasesCursorBased(
      input: $input
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    ) {
      pageInfo {
        ...PageInfoData
      }
      edges {
        cursor
        node {
          id
          createdAt
          firstSubmittedForReviewAt
          title
          status
          targetMembership {
            id
            firstName
            lastName
            avatarUrl
          }
          totalAmount {
            amount
            currency
            precision
          }
        }
      }
    }
  }
  ${pageInfoDataFragment}
`;
