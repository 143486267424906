import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from 'utils/zodFormValidation';
import {
  useShouldRequireGeneralLedgerAccounts,
  useShouldRequireTaxCodes,
} from 'views/utils/useShouldRequireField';
import { useReimbursementSplitBookingsFormInitialValues } from '../hooks/useReimbursementSplitBookingsFormInitialValues';
import { reimbursementSplitBookingsFormErrorMessages } from '../toolkit/reimbursementSplitBookingsFormErrorMessages';
import {
  reimbursementSplitBookingsFormSchema,
  ReimbursementSplitBookingsFormValues,
} from '../toolkit/reimbursementSplitBookingsFormSchema';
import { ReimbursementSplitBookingsContext } from './ReimbursementSplitBookingsContext';
import {
  useShowCostCenterField,
  useShowCostObjectField,
} from 'views/utils/useShouldShowField';
import {
  getFieldRules,
  getActionRules,
  ReimbursementStatusProps,
} from '../components/RightSection/ReimbursementSplitBookings/hooks/useReimbursementBookingsFieldRules';
import { getBookingsErrorsSummary } from '../utils/getBookingsErrorsSummary';

export type DrawerView = 'split-bookings';
export type Workflow = 'approve';

export interface ActiveBooking {
  entryIndex: number;
  bookingId?: string;
  reimbursementItemId?: string;
}
interface ReimbursementSplitBookingsProviderProps {
  children: ReactNode;
}

export const ReimbursementSplitBookingsProvider = ({
  children,
}: ReimbursementSplitBookingsProviderProps) => {
  const [drawerView, setDrawerView] = useState<DrawerView>();
  const [activeBooking, setActiveBooking] = useState<ActiveBooking>({
    entryIndex: -1,
    bookingId: undefined,
    reimbursementItemId: undefined,
  });

  const [workflow, setWorkflow] = useState<Workflow>();
  const hasCostCenters = useShowCostCenterField();
  const hasCostObjects = useShowCostObjectField();
  const shouldRequireGeneralLedgerAccount =
    useShouldRequireGeneralLedgerAccounts();

  const shouldRequireTaxCode = useShouldRequireTaxCodes();
  const integration = useIntegrationSettings();

  const { reimbursementItemSplitBookings } =
    useReimbursementSplitBookingsFormInitialValues();

  const initialBookings = useMemo(
    () => reimbursementItemSplitBookings,
    [reimbursementItemSplitBookings]
  );

  const form = useForm<ReimbursementSplitBookingsFormValues>({
    context: {
      integration,
      workflow,
      hasCostCenters,
      hasCostObjects,
      shouldRequireTaxCode,
      shouldRequireGeneralLedgerAccount,
    },
    defaultValues: {
      reimbursementItemBookings: initialBookings,
    },
    values: {
      reimbursementItemBookings: initialBookings,
    },
    mode: 'all',
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resetOptions: {
      keepErrors: true,
    },
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.REIMBURSEMENT,
      zodSchema: reimbursementSplitBookingsFormSchema,
      errorMessages: reimbursementSplitBookingsFormErrorMessages,
    }),
  });

  const updateActiveBooking = useCallback((updates: Partial<ActiveBooking>) => {
    setActiveBooking(prev => ({
      ...prev,
      ...updates,
    }));
  }, []);

  const triggerBookingsValidation = useCallback(() => {
    void form.trigger();
  }, [form]);

  const bookingsErrorsSummary = getBookingsErrorsSummary(form.formState.errors);
  const hasBookingsErrors =
    workflow === 'approve' && !!bookingsErrorsSummary.invalidFormCount;

  //we force reset when split-bookings form is closed
  const resetDrawerAndForm = useCallback(() => {
    setDrawerView(undefined);
    form.reset({
      reimbursementItemBookings: initialBookings,
    });

    // re-trigger validation to remove errors
    // or persist errors when form is closed
    if (workflow === 'approve') {
      triggerBookingsValidation();
    }
  }, [form, initialBookings, triggerBookingsValidation, workflow]);

  const getSplitBookingsFormRules = useCallback(
    (props: ReimbursementStatusProps) => {
      const { status, itemStatus } = props;
      const bookings = form
        ?.getValues('reimbursementItemBookings')
        .flatMap(item => item?.bookings ?? []);

      const fieldRules = getFieldRules({ status, itemStatus, bookings });

      const actionRules = getActionRules(fieldRules);
      return {
        fieldRules,
        actionRules,
      };
    },
    [form]
  );

  const contextValue = useMemo(
    () => ({
      formMethods: form,
      drawerView,
      setDrawerView,
      activeBooking,
      updateActiveBooking,
      resetDrawerAndForm,
      workflow,
      setWorkflow,
      triggerBookingsValidation,
      getSplitBookingsFormRules,
      bookingsErrorsSummary,
      hasBookingsErrors,
    }),
    [
      form,
      drawerView,
      activeBooking,
      updateActiveBooking,
      resetDrawerAndForm,
      workflow,
      triggerBookingsValidation,
      getSplitBookingsFormRules,
      bookingsErrorsSummary,
      hasBookingsErrors,
    ]
  );

  return (
    <ReimbursementSplitBookingsContext.Provider value={contextValue}>
      {children}
    </ReimbursementSplitBookingsContext.Provider>
  );
};
