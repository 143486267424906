import {
  CardIssuerTransactionInvoiceNotNeededReason,
  InvoiceAssociationStatus,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useGetTransactionById } from 'views/TransactionAssociation/hooks/useGetTransactionById';
import { ReadOnlyTransactionFormValues } from '../utils/readOnlyTransactionFormSchema';
import { useGetCostCenterById } from './useGetCostCenterById';
import { useGetBookingAccountById } from './useGetGeneralLedgerAccountById';

const reasonTranslations: Record<
  CardIssuerTransactionInvoiceNotNeededReason,
  string
> = {
  INVOICE_ALREADY_EXPORTED:
    'transactionAssociation.noInvoiceNeeded.popover.options.invoice_already_exported',
  NEEDS_NO_INVOICE:
    'transactionAssociation.noInvoiceNeeded.popover.options.needs_no_invoice',
  OTHER: 'transactionAssociation.noInvoiceNeeded.popover.options.other',
  CARD_NEEDS_NO_INVOICE:
    'transactionAssociation.noInvoiceNeeded.popover.options.card_needs_no_invoice',
};

export const useGetReadOnlyAccountingDataFormValues = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { transactionId } = useParams<{ transactionId: string }>();
  const fullOrg = useFullOrganization();
  const artistSocialInsuranceAndExtraCostInfoFF = useCandisFeatureFlags(
    FEATURE_FLAGS.artistSocialInsuranceAndExtraCostInfo
  );

  const { transactionAmountFormatter } = useMoneyFormatter();

  const { transaction, isTransactionLoading } = useGetTransactionById({
    id: transactionId,
  });

  const { accountingData } = transaction;

  const { costCenter, costCenterLoading } = useGetCostCenterById({
    id: accountingData.costCenterId,
  });

  const { costCenter: extraCostInfo, costCenterLoading: extraCostInfoLoading } =
    useGetCostCenterById({
      id: accountingData.extraCostInfoId,
      skip:
        !artistSocialInsuranceAndExtraCostInfoFF || !fullOrg?.hasExtraCostInfos,
    });

  const { costCenter: costObject, costCenterLoading: costObjectLoading } =
    useGetCostCenterById({
      id: accountingData.costObjectId,
    });

  const { bookingAccount, bookingAccountLoading } = useGetBookingAccountById({
    id: accountingData.generalLedgerAccountId,
  });

  const reason = transaction?.invoiceNotNeeded?.reason;

  const reasonTranslation = reason ? t(reasonTranslations[reason]) : undefined;

  const transactionAssociationStatus = transaction.invoiceAssociationStatus;

  const comment = transaction?.invoiceNotNeeded?.comment;

  const isNameComingFromCreditCard =
    transactionAssociationStatus !== InvoiceAssociationStatus.NotNeeded ||
    (Boolean(transaction.merchantLegalName) &&
      Boolean(transaction.accountingData.contactId) &&
      transactionAssociationStatus === InvoiceAssociationStatus.NotNeeded);

  const defaultValues: ReadOnlyTransactionFormValues = {
    approvalMode: accountingData.approvalWorkflowTemplateId
      ? 'workflow'
      : 'approvers',
    contact: isNameComingFromCreditCard
      ? {
          inputValue: accountingData.contact.name,
          value: accountingData.contact.id,
        }
      : transaction.merchantLegalName,
    costCenter: {
      inputValue: costCenter.readableName,
      value: accountingData.costCenterId,
    },
    costObject: {
      inputValue: costObject.readableName,
      value: accountingData.costObjectId,
    },
    generalLedgerAccount: {
      inputValue: bookingAccount.readableName,
      value: accountingData.generalLedgerAccountId,
    },
    extraCostInfo: {
      inputValue: extraCostInfo.readableName,
      value: accountingData.extraCostInfoId,
    },
    taxCode: accountingData.bookingKeyId,
    transactionId: transaction.id,
    comment:
      comment && reasonTranslation
        ? `${reasonTranslation} - ${comment}`
        : reasonTranslation,
    transactionDate: transaction.transactionCreatedAt,
    transactionAmount: transactionAmountFormatter({
      currency: transaction.billingAmountForAccounting?.currency ?? 'EUR',
      value: transaction.billingAmountForAccounting?.value ?? 0,
      precision: transaction.billingAmountForAccounting?.precision ?? 2,
    }),
    workflow: accountingData.approvalWorkflowTemplateId,
    artistSocialInsuranceCode: accountingData.artistSocialInsuranceCode,
    approvers: accountingData.approvers,
    note: transaction.note,
  };

  return {
    loading:
      isTransactionLoading ||
      costCenterLoading ||
      costObjectLoading ||
      bookingAccountLoading ||
      extraCostInfoLoading,
    defaultValues,
    creditCardId: transaction.cardId,
    cardRefNumber: transaction.cardRefNumber,
    isNameComingFromCreditCard,
  };
};
