import { Flex, Grid, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { AddisonXmlGreyscale } from '../../../images/export-formats/AddisonXmlGreyscale';
import { DatevApiGreyscale } from '../../../images/export-formats/DatevApiGreyscale';
import { DatevCsvGreyscale } from '../../../images/export-formats/DatevCsvGreyscale';
import { DatevXmlGreyscale } from '../../../images/export-formats/DatevXmlGreyscale';
import { ZipGreyscale } from '../../../images/export-formats/ZipGreyscale';

export const ExportIcons = () => {
  const [tExport] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);

  return (
    <>
      <Grid
        borderTop="1px solid gray500"
        justifyItems="center"
        paddingY="space16"
        width="8.75rem"
      >
        <Text color="gray800" fontSize="xsmall">
          {tExport('emptyState.availableFormats')}
        </Text>
      </Grid>
      <Flex paddingTop="space16" width="26rem" justifyContent="space-between">
        <DatevApiGreyscale
          data-key="DatevApiGreyscale"
          width="66"
          height="66"
          viewBox="0 0 66 66"
          fill="none"
        />
        <DatevXmlGreyscale
          data-key="DatevXmlGreyscale"
          width="67"
          height="66"
          viewBox="0 0 67 66"
          fill="none"
        />
        <DatevCsvGreyscale
          data-key="DatevCsvGreyscale"
          width="67"
          height="66"
          viewBox="0 0 67 66"
          fill="none"
        />
        <AddisonXmlGreyscale
          data-key="AddisonXmlGreyscale"
          width="67"
          height="66"
          viewBox="0 0 67 66"
          fill="none"
        />
        <ZipGreyscale width="67" height="66" viewBox="0 0 67 66" fill="none" />
      </Flex>
    </>
  );
};
