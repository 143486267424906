import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  useQuery,
} from '@apollo/client';
import { Maybe } from 'generated-types/graphql.types';
import { Query, SimplePageInfoDetails } from 'generated-types/graphql.types';
import { DocumentNode } from 'graphql';
import { useEffect } from 'react';
import { DeepPartial } from 'utility-types';

export type OnLoadMore = () => void;

export type UsePagePaginationResponse<T> = Omit<QueryResult<T>, 'fetchMore'> & {
  onLoadMore: OnLoadMore;
  refetchCurrentPage: () => void;
};

export type PaginationResponse = {
  pageInfo?: Maybe<SimplePageInfoDetails>; // # Information to aid in pagination.
};

export type PagePaginationVariables = OperationVariables & {
  input?: Maybe<{
    page?: Maybe<number>; // We expect the paginated query to contain page property
  }>;
};

export const usePagePagination = <
  QueryType extends DeepPartial<Query>,
  QueryVariablesType extends PagePaginationVariables,
  QueryKeyType extends keyof Omit<QueryType, '__typename'> = keyof Omit<
    QueryType,
    '__typename'
  >,
>(
  query: DocumentNode,
  queryRootKey: QueryKeyType & string,
  options?: QueryHookOptions<QueryType, QueryVariablesType>
): UsePagePaginationResponse<QueryType> => {
  const { data, loading, fetchMore, ...rest } = useQuery<QueryType, any>(
    query,
    {
      variables: {
        ...options?.variables,
        input: {
          ...options?.variables?.input,
          page: options?.variables?.input?.page ?? 1,
        },
      },
      ...options,
    }
  );

  const response = data?.[queryRootKey] as PaginationResponse | undefined;

  const onLoadMore = () => {
    const currentPage = response?.pageInfo?.currentPage ?? 0;
    const lastPage = response?.pageInfo?.pageCount ?? 0;
    const isLastPage = currentPage >= lastPage;
    if (isLastPage) {
      return;
    }

    const nextPage = currentPage + 1;
    void fetchMore({
      query,
      //@ts-expect-error https://github.com/apollographql/apollo-client/issues/7059
      notifyOnNetworkStatusChange: true,
      variables: {
        ...options?.variables,
        input: {
          ...options?.variables?.input,
          page: nextPage,
        },
      },
    });
  };

  const refetchCurrentPage = () => {
    if (!response?.pageInfo?.currentPage) return;

    void fetchMore({
      query,
      //@ts-expect-error https://github.com/apollographql/apollo-client/issues/7059
      notifyOnNetworkStatusChange: true,
      variables: {
        ...options?.variables,
        input: {
          ...options?.variables?.input,
          page: response?.pageInfo?.currentPage,
        },
      },
    });
  };

  /**
   * Evict all the cached pagination results, so when we navigate back to the
   * view, we don't try render potentially hundreds of results from the cache.
   */
  useEffect(() => {
    return () => {
      rest.client.cache.evict({ fieldName: queryRootKey });
    };
  }, [queryRootKey, rest.client.cache]);

  return {
    data,
    loading,
    onLoadMore,
    refetchCurrentPage,
    ...rest,
  };
};
