import { Text, TextProps } from '../../../Atoms/Typography';

export interface HighlightedTextProps extends TextProps {
  isHighlighted: boolean;
}

export const HighlightedText = ({
  isHighlighted,
  children,
  ...restProps
}: HighlightedTextProps) => {
  return (
    <Text
      {...restProps}
      wordBreak="break-word"
      as="span"
      background={isHighlighted ? 'yellow400' : undefined}
    >
      {children}
    </Text>
  );
};
