import {
  useCountDocumentsProcessing,
  useCountTransactionsAndDocumentsInbox,
} from 'hooks/useCounters';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { NavigationIcon } from '../../NavigationIcon';
import { useInboxReimbursementCount } from 'hooks/useCounterQueries';
import { Tag } from '@candisio/design-system';
import { useClassificationCountPolling } from 'containers/conversion/useClassificationCountPolling';
import { NavIconProps } from '../../../hooks/useNavigationIcons';
import { useTranslation } from 'react-i18next';

export const MenuInbox = ({ ...iconProps }: NavIconProps) => {
  const [t] = useTranslation();
  const creditCardsSetup = useCreditCardsSetup();
  const { count: documentsProcessingCount, loading: loadingProcessing } =
    useCountDocumentsProcessing({
      fetchPolicy: 'cache-first',
    });

  const { totalCount: inboxTotalCount, loading: loadingInbox } =
    useCountTransactionsAndDocumentsInbox({
      processingDocuments: documentsProcessingCount,
      loadingProcessing,
      featureFlag: creditCardsSetup.isAddonBought,
    });

  const { inboxReimbursementCount, loadingCount } =
    useInboxReimbursementCount();

  const { count } = useClassificationCountPolling();

  const countLoading = loadingProcessing || loadingInbox || loadingCount;

  const countTotal = inboxTotalCount + inboxReimbursementCount;

  const expandedAndUploading = iconProps.expanded && count > 0;
  const collapsedAndUploading = !iconProps.expanded && count > 0;

  return (
    <NavigationIcon
      count={collapsedAndUploading ? undefined : countTotal}
      countLoading={countLoading}
      {...iconProps}
      icon="menuInbox"
    >
      {expandedAndUploading && (
        <Tag color="blue" variant="secondary" loading>
          {t('uploads.status.uploadingWithCount', { count })}
        </Tag>
      )}
      {collapsedAndUploading && (
        <Tag color="blue" variant="secondary" loading>
          {count}
        </Tag>
      )}
    </NavigationIcon>
  );
};
