import { Grid, useModal } from '@candisio/design-system';
import { useGetSapB1Credentials } from 'hooks/useGetSAPB1Credentials';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { ExportContext } from '../../Context';
import { ExportType } from '../../types';
import { useDatevSettings } from '../hooks/useDatevSettings';
import addisonIcon from '../images/icon-addison.svg';
import iconCandisAPI from '../images/icon-candis-api.svg';
import iconCandisCsv from '../images/icon-candis-csv.svg';
import iconDatev from '../images/icon-datev-api.svg';
import iconDatevCsvZip from '../images/icon-datev-csv.svg';
import iconDatevDxsoZip from '../images/icon-datev-xml.svg';
import iconSapB1 from '../images/icon-sap-b1.svg';
import iconSapCsv from '../images/icon-sap-csv.svg';
import iconZip from '../images/icon-zip.svg';
import { isDatevExportType } from '../utils';
import { ExportCard } from './ExportCard';
import { SapPromotionModal } from './SapPromotion';

const exportTypeDetailsMap: Record<
  ExportType,
  { label: string; icon: string }
> = {
  ADDISON_FORMAT: {
    label: 'export.exportForm.cards.addison.title',
    icon: addisonIcon,
  },
  CANDIS_CSV: {
    label: 'export.exportForm.cards.candis_csv.title',
    icon: iconCandisCsv,
  },
  DATEV: { label: 'export.exportForm.cards.datev.title', icon: iconDatev },
  DATEV_BDS: {
    label: 'export.exportForm.cards.datev_bds.title',
    icon: iconDatev,
  },
  DATEV_CSV: {
    label: 'export.exportForm.cards.datev_csv.title',
    icon: iconDatevCsvZip,
  },
  DATEV_DXSO_ZIP: {
    label: 'export.exportForm.cards.datev_dxso_zip.title',
    icon: iconDatevDxsoZip,
  },
  SAP_B1: { label: 'export.exportForm.cards.sap_b1.title', icon: iconSapB1 },
  SAP_CSV: { label: 'export.exportForm.cards.sap_csv.title', icon: iconSapCsv },
  ZIP: { label: 'export.exportForm.cards.zip.title', icon: iconZip },
  API: {
    label: 'export.exportForm.cards.candis_api.title',
    icon: iconCandisAPI,
  },
};

export const SelectExportTypeCardList = () => {
  const state = useContext(ExportContext);
  const orgId = useOrganizationId();
  const [t] = useTranslation();
  const [tExport] = useTranslation('exports');
  const { data } = useGetSapB1Credentials();
  const isConfigured = Boolean(data?.sapB1Credentials?.companyDbId);

  const { setExportType } = state;

  const history = useHistory();

  const onGoToDatevSettings = useCallback(
    (exportType: ExportType) => {
      const search =
        exportType === ExportType.DATEV_BDS
          ? '?datevConnectionType=DATEV_REWE'
          : '?datevConnectionType=DATEV_DUO';

      history.push(`/${orgId}${Routes.SETTINGS}${Routes.DATEV}${search}`);
    },
    [history, orgId]
  );

  const datevSettings = useDatevSettings();
  const isDatevConnected =
    !!datevSettings?.datevSettings?.client &&
    !!datevSettings?.datevSettings?.hasAuthTokenForExportDocuments;

  const { open, modalProps, isOpen } = useModal();

  const exportTypeOptions = state.availableExportTypes;

  const choose = (exportType: ExportType) => {
    if (exportType === ExportType.SAP_B1 && !isConfigured) {
      open();
    } else if (isDatevExportType(exportType) && !isDatevConnected) {
      onGoToDatevSettings(exportType);
    } else {
      state.setExportType(exportType);
    }
  };

  const exportMethods = exportTypeOptions.map(type => ({
    type,
    label: exportTypeDetailsMap[type].label,
    icon: exportTypeDetailsMap[type].icon,
    tag:
      type === ExportType.SAP_B1 || type === ExportType.SAP_CSV
        ? tExport('sapPromotion.betaTag')
        : undefined,
    customElement:
      type === ExportType.SAP_B1 && !isConfigured && isOpen ? (
        <SapPromotionModal {...modalProps} />
      ) : null,
  }));

  const isOnlyOneOptionAvailable = exportTypeOptions.length === 1;

  // auto-select the only available export type
  if (isOnlyOneOptionAvailable) {
    setExportType(exportTypeOptions[0]);
  }

  return (
    <Grid gap="space16" autoRows="max-content">
      {exportMethods.map(({ type, label, icon, tag, customElement }, index) => {
        return (
          <>
            {customElement && customElement}
            <ExportCard
              key={index}
              title={t(label)}
              icon={icon}
              tag={tag}
              onClick={() => choose(type)}
            />
          </>
        );
      })}
    </Grid>
  );
};
