import { Link } from '@candisio/design-system';
import { useProcessingFormTypeFieldWarning } from 'components/Form/ProcessingForm/useProcessingFormTypeFieldWarning';

import { ReactNode, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DocumentDirection } from 'views/utils/DocumentDirection';
import { ProcessingFormValues } from './processingFormSchema';
import {
  ProcessingFormSelectField,
  ProcessingFormSelectFieldProps,
} from './ProcessingFormSelectField';
import { useDocumentTypeContext } from 'views/DocumentDetails/components/DocumentTypeContext';

export interface ProcessingFormTypeFieldItem {
  key: string;
  children: ReactNode;
  direction: DocumentDirection;
  warning?: string;
}

/**
 * (Document) type field for use in processing form.
 *
 * Displays a warning in the field if one is specified for the selected type.
 */
export const ProcessingFormTypeField = ({
  name,
  items,
  ...restProps
}: ProcessingFormSelectFieldProps) => {
  const [t] = useTranslation();
  const type = useWatch<ProcessingFormValues>({ name });

  // we need to move all this logic into this layer as we need to access the form values
  const shouldShowWarning = useProcessingFormTypeFieldWarning();

  const selectedType = (items as ProcessingFormTypeFieldItem[])?.find(
    ({ key }) => key === type
  );
  const { setDocumentTypeOld } = useDocumentTypeContext();

  useEffect(() => {
    setDocumentTypeOld(selectedType?.key);
  }, [selectedType, setDocumentTypeOld]);

  return (
    <ProcessingFormSelectField
      description={
        shouldShowWarning
          ? {
              color: 'warning',
              text: t(
                'document.requestApproval.inputs.document.rds1-0-warning.text'
              ),
              // XXX as the InlineSkeleton would wrap the Link with a div the UI would break
              // so best we can do is not show this at all while loading
              addOn: restProps.isLoading ? null : (
                <Link
                  external
                  href={t(
                    'document.requestApproval.inputs.document.rds1-0-warning.link.url'
                  )}
                >
                  {t(
                    'document.requestApproval.inputs.document.rds1-0-warning.link.text'
                  )}
                </Link>
              ),
            }
          : undefined
      }
      name={name}
      items={items}
      warning={selectedType?.warning}
      {...restProps}
    />
  );
};
