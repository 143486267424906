import {
  Box,
  BoxProps,
  Button,
  Flex,
  Grid,
  Icon,
  IconProps,
  Paragraph,
  Radio,
  RadioGroup,
  Text,
  TextField,
} from '@candisio/design-system';
import { ContactDataFragment } from 'generated-types/graphql.types';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useCheckContactName } from 'views/Contacts/ContactDetails/useCheckContactName';

interface MergeContactState {
  targetContact: typeof CUSTOM_NAME | string;
  targetContactName: string;
  failedSubmit: boolean;
  errors: FormErrors;
}

type UpdateTargetContactId = {
  action: 'UPDATE_TARGET_CONTACT_ID';
  payload: {
    id: string;
  };
};

type UpdateTargetContactName = {
  action: 'UPDATE_TARGET_CONTACT_NAME';
  payload: {
    name: string;
  };
};

type UpdateSubmitErrors = {
  action: 'UPDATE_SUBMIT_ERRORS';
  payload: Record<keyof FormErrors, boolean>;
};

type UpdateNewNameErrors = {
  action: 'UPDATE_NEW_NAME_ERRORS';
  payload: Record<keyof FormErrors, boolean>;
};

const reducer = (
  currentState: MergeContactState,
  update:
    | UpdateTargetContactId
    | UpdateTargetContactName
    | UpdateSubmitErrors
    | UpdateNewNameErrors
): MergeContactState => {
  switch (update.action) {
    case 'UPDATE_TARGET_CONTACT_ID':
      return {
        ...currentState,
        targetContact: update.payload.id,
        errors: {
          CUSTOM_NAME: false,
          DUPLICATE_NAME: false,
        },
      };

    case 'UPDATE_TARGET_CONTACT_NAME':
      return {
        ...currentState,
        targetContactName: update.payload.name,
        errors: {
          CUSTOM_NAME: currentState.failedSubmit && !update.payload.name,
          DUPLICATE_NAME: false,
        },
      };

    case 'UPDATE_NEW_NAME_ERRORS':
      return {
        ...currentState,
        errors: {
          ...currentState.errors,
          ...update.payload,
        },
      };

    case 'UPDATE_SUBMIT_ERRORS':
      return {
        ...currentState,
        failedSubmit: true,
        errors: {
          ...currentState.errors,
          ...update.payload,
        },
      };

    default:
      return currentState;
  }
};

interface FormErrors {
  CUSTOM_NAME: boolean;
  DUPLICATE_NAME: boolean;
}

// Used as the value of the radio button of the text input field
// So it can be included in the radio group and easily identified
const CUSTOM_NAME = 'CUSTOM_NAME';

export interface FormSubmitData {
  targetContactId?: string | null;
  targetContactName?: string | null;
}

interface Props {
  hasMergeConflicts: boolean;
  onClose: () => void;
  onSubmit: (formData: FormSubmitData) => void;
  submitting?: boolean;
  contacts: ContactDataFragment[];
}

interface MergeInfoProps {
  backgroundColor: BoxProps['background'];
  icon: IconProps['icon'];
  iconColor: IconProps['color'];
  textTranslationKey: string;
}

const MergeContactsInfoBox = ({
  backgroundColor,
  icon,
  iconColor,
  textTranslationKey,
}: MergeInfoProps) => {
  const [t] = useTranslation();

  return (
    <Box background={backgroundColor} borderRadius="small" padding="space16">
      <Grid gap="space4" templateColumns="auto 1fr">
        <Icon icon={icon} size="space16" color={iconColor} />
        <Paragraph whiteSpace="pre-line">{t(textTranslationKey)}</Paragraph>
      </Grid>
    </Box>
  );
};

const MODAL_WIDTH = '37.5rem';

export const MergeContactsForm = ({
  contacts,
  hasMergeConflicts,
  onClose,
  onSubmit,
  submitting,
}: Props) => {
  const [t] = useTranslation();
  const initialState: MergeContactState = {
    targetContact: contacts[0]?.id,
    targetContactName: '',
    errors: {
      CUSTOM_NAME: false,
      DUPLICATE_NAME: false,
    },
    failedSubmit: false,
  };

  const [formState, updateFormState] = useReducer(reducer, initialState);

  const checkContactName = useCheckContactName();

  const hasErrors = Object.values(formState.errors).some(error => error);

  const handleSubmit = async () => {
    if (
      formState.targetContact === CUSTOM_NAME &&
      !formState.targetContactName
    ) {
      updateFormState({
        action: 'UPDATE_SUBMIT_ERRORS',
        payload: { CUSTOM_NAME: true, DUPLICATE_NAME: false },
      });

      return;
    }

    const { isAvailable } = await checkContactName(formState.targetContactName);

    if (
      formState.targetContact === CUSTOM_NAME &&
      formState.targetContactName &&
      !isAvailable
    ) {
      updateFormState({
        action: 'UPDATE_SUBMIT_ERRORS',
        payload: { DUPLICATE_NAME: true, CUSTOM_NAME: false },
      });

      return;
    }

    const { targetContact, targetContactName } = formState;

    return onSubmit({
      targetContactId: targetContact !== CUSTOM_NAME ? targetContact : null,
      targetContactName:
        targetContact === CUSTOM_NAME ? targetContactName : null,
    });
  };

  return (
    <Grid gap="space16" width={MODAL_WIDTH}>
      {hasMergeConflicts ? (
        <MergeContactsInfoBox
          backgroundColor="redbg"
          icon="failCircle"
          iconColor="red700"
          textTranslationKey="settings.contacts.merge.conflict"
        />
      ) : (
        <>
          <Text fontSize="basic">
            {t('settings.contacts.merge.selection.heading')}
          </Text>
          <RadioGroup
            value={formState.targetContact}
            onChange={value =>
              updateFormState({
                action: 'UPDATE_TARGET_CONTACT_ID',
                payload: { id: value },
              })
            }
          >
            <Grid gap="space8" fontSize="basic">
              {contacts.map(contact => (
                <Radio key={contact.id} value={contact.id}>
                  {contact.name.value}
                </Radio>
              ))}
              <Radio value={CUSTOM_NAME} style={{ alignItems: 'center' }}>
                <TextField
                  aria-label={t(
                    'settings.contacts.merge.inputs.customName.placeholder'
                  )}
                  style={{
                    padding: 0,
                    minHeight: 'auto',
                  }}
                  width={`calc(${MODAL_WIDTH} / 2)`}
                  variant={hasErrors ? 'error' : 'default'}
                  message={
                    formState.errors.CUSTOM_NAME
                      ? t(
                          'settings.contacts.merge.inputs.customName.errorLabel'
                        )
                      : formState.errors.DUPLICATE_NAME
                        ? t('settings.contacts.details.edit.errors.duplicate')
                        : undefined
                  }
                  input={{
                    value: formState.targetContactName,
                    placeholder: t(
                      'settings.contacts.merge.inputs.customName.placeholder'
                    ),
                    onFocus: () => {
                      updateFormState({
                        action: 'UPDATE_TARGET_CONTACT_ID',
                        payload: { id: CUSTOM_NAME },
                      });
                    },
                    onChange: async ({ target }) => {
                      updateFormState({
                        action: 'UPDATE_TARGET_CONTACT_NAME',
                        payload: { name: target.value },
                      });
                      const { isAvailable } = await checkContactName(
                        target.value
                      );

                      if (!isAvailable) {
                        updateFormState({
                          action: 'UPDATE_NEW_NAME_ERRORS',
                          payload: { DUPLICATE_NAME: true, CUSTOM_NAME: false },
                        });
                      }
                    },
                  }}
                />
              </Radio>
            </Grid>
          </RadioGroup>
          <MergeContactsInfoBox
            backgroundColor="bluebg"
            icon="infoCircle"
            iconColor="blue700"
            textTranslationKey="settings.contacts.merge.hint"
          />
        </>
      )}
      <Flex justifyContent="end" gap="space8">
        <Button disabled={submitting} variant="secondary" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button
          disabled={hasMergeConflicts || submitting || hasErrors}
          loading={submitting}
          onClick={handleSubmit}
        >
          {t('settings.contacts.actions.merge')}
        </Button>
      </Flex>
    </Grid>
  );
};
