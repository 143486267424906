import { Grid, Text } from '@candisio/design-system';
import { Collapse } from 'components/History/items/Collapse';
import {
  ExpenseItemBookingAddedEventDataFragment,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { BookingField } from './BookingField';

export const ExpenseItemBookingAdded = ({
  properties,
  reimbursementItemMetadata,
}: ExpenseItemBookingAddedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  if (!properties.booking) {
    return null;
  }

  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const currency = properties.currency ?? undefined;

  const createdAccountingData =
    Object.entries(properties.booking).filter(
      ([key, value]) => !isNil(value) && key !== '__typename'
    ) ?? [];

  const headline = expenseTitle
    ? t('timeline.bookingAdded', { title: expenseTitle })
    : t('timeline.bookingAddedNoTitle');

  return (
    <Grid gap="space8">
      <Text>{headline}</Text>
      <Collapse>
        <Grid gap="space4">
          {createdAccountingData.map(([key, value], index) => (
            <BookingField
              key={index}
              value={value}
              fieldKey={key}
              currency={currency}
            />
          ))}
        </Grid>
      </Collapse>
    </Grid>
  );
};
