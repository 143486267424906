import {
  Avatar,
  Flex,
  Grid,
  Icon,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { amountFormat } from 'utils/format';
import { NotificationProps } from '../getNotificationInfo';
import { Comment } from './Comment/Comment';

export const DocumentRejectedInAppNotification = (
  props: NotificationProps<'DocumentRejectedInAppNotification'>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);

  const {
    rejectedBy,
    rejectedAt,
    commentText,
    invoiceContact,
    invoiceAmount,
    documentNumber,
  } = props;

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });

  const authorName = `${rejectedBy.firstName} ${rejectedBy.lastName}`;

  const formattedInvoiceAmount = amountFormat(
    invoiceAmount?.value,
    invoiceAmount?.currencyCode
  );

  return (
    <Grid templateRows="auto 1fr auto" height="100%">
      <Flex wrap="wrap" fontSize="small" gap="space4">
        <Trans
          i18nKey="notifications:events.documentRejected.header"
          values={{
            authorName,
            timeAgo: DateFormatters.fromNow(new Date(rejectedAt)),
          }}
        >
          {/* @ts-expect-error we have to surpress typescript here because TruncatedText expects a string */}
          <TruncatedText color="gray800" maxWidth="15ch">
            {{ authorName }}
          </TruncatedText>
          <Text fontSize="small" color="gray500">
            rejected your document
          </Text>
          <Text
            fontSize="small"
            color="gray500"
            {...triggerProps}
            ref={triggerRef}
          >
            3 minutes ago
          </Text>
        </Trans>
      </Flex>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {DateFormatters.compactDatetime(new Date(rejectedAt))}
        </Tooltip>
      )}

      <Comment>
        <Flex direction="column">
          <Flex gap="space4" direction="row" color="red500" alignItems="center">
            <Icon icon="close" color="red500" />
            {t('common:document.compact.history.documentRejected')}
          </Flex>
          <Text color="gray800" fontSize="basic">
            {t('events.commentText', { commentText })}
          </Text>
        </Flex>
      </Comment>
      <Grid
        autoFlow="column"
        gap="space4"
        justifyContent="start"
        fontSize="small"
        color="gray500"
      >
        {invoiceContact && <TruncatedText>{invoiceContact}</TruncatedText>}
        <Text>•</Text>
        <Text>{formattedInvoiceAmount}</Text>
        <Text>•</Text>
        <Text>{documentNumber}</Text>
      </Grid>
    </Grid>
  );
};

export const DocumentRejectedInAppNotificationIcon = ({
  rejectedBy,
}: NotificationProps<'DocumentRejectedInAppNotification'>) => (
  <Avatar
    name={`${rejectedBy.firstName} ${rejectedBy.lastName}`}
    img={rejectedBy.avatarUrl ?? undefined}
    hasTooltip={false}
    size="medium"
  />
);

export const DocumentRejectedInAppNotificationToast = (
  props: NotificationProps<'DocumentRejectedInAppNotification'>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);

  const { rejectedBy, commentText } = props;

  const authorName = `${rejectedBy.firstName} ${rejectedBy.lastName}`;

  return (
    <Grid templateRows="auto 1fr auto" height="100%">
      <Grid autoFlow="column" alignItems="start" justifyContent="start">
        <Flex wrap="wrap" fontSize="small" gap="space4">
          <Trans
            i18nKey="notifications:events.documentRejected.toast.header"
            values={{
              authorName,
            }}
          >
            {/* @ts-expect-error we have to surpress typescript here because TruncatedText expects a string */}
            <TruncatedText color="gray800" maxWidth="15ch">
              {{ authorName }}
            </TruncatedText>
            <Text color="gray500">mentioned you in a comment</Text>
          </Trans>
        </Flex>
      </Grid>
      <Grid autoFlow="column" gap="space8" justifyContent="start">
        <TruncatedText color="gray800" fontSize="basic" width="25ch">
          {t('events.commentText', { commentText })}
        </TruncatedText>
      </Grid>
    </Grid>
  );
};
