import { Box } from '@candisio/design-system';
import { Trans } from 'providers/LocaleProvider';
import { ImportHistoryItem } from '../HistoryList';

interface ImportStatusProps {
  item: ImportHistoryItem;
}

export const ImportStatus = ({ item }: ImportStatusProps) => {
  const importWasSuccessful = item.importStatus?.countFailedToCreate === 0;
  const failedCount = item?.importStatus?.countFailedToCreate ?? 0;
  const successCount = item?.importStatus?.countSuccessfullyCreated ?? 0;
  const importTotal = failedCount + successCount;

  return (
    <Box>
      {importWasSuccessful ? (
        <Trans
          i18nKey="contacts:importHistory.status.successCount"
          count={successCount}
        >
          {{ successCount } as any} contact
        </Trans>
      ) : (
        <Trans
          i18nKey="contacts:importHistory.status.totalCount"
          count={importTotal}
        >
          {{ failedCount } as any} of {{ importTotal }} contact failed
        </Trans>
      )}
    </Box>
  );
};
