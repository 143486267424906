import { History } from 'components/History/History';
import { BaseItemProps } from 'components/History/items/BaseItem';
import { ReimbursementItemType } from 'generated-types/graphql.types';
import { FunctionComponent } from 'react';
import { ExpenseItemArchived } from '../components/LeftSection/History/Events/ExpenseItemArchived';
import { ExpenseItemFieldUpdatedDateUpdated } from '../components/LeftSection/History/Events/ExpenseItemFieldUpdatedDateUpdated';
import { ExpenseItemFileAdded } from '../components/LeftSection/History/Events/ExpenseItemFileAdded';
import { ExpenseItemFileRemoved } from '../components/LeftSection/History/Events/ExpenseItemFileRemoved';
import { ExpenseItemHospitalityExternalGuestsUpdated } from '../components/LeftSection/History/Events/ExpenseItemHospitalityExternalGuestsUpdated';
import { ExpenseItemHospitalityInternalGuestsUpdated } from '../components/LeftSection/History/Events/ExpenseItemHospitalityInternalGuestsUpdated';
import { ExpenseItemHospitalityLocationUpdated } from '../components/LeftSection/History/Events/ExpenseItemHospitalityLocationUpdated';
import { ExpenseItemHospitalityReceiptAmountUpdated } from '../components/LeftSection/History/Events/ExpenseItemHospitalityReceiptAmountUpdated';
import { ExpenseItemHospitalityTipAmountUpdated } from '../components/LeftSection/History/Events/ExpenseItemHospitalityTipAmountUpdated';
import { ExpenseItemTitleUpdated } from '../components/LeftSection/History/Events/ExpenseItemTitleUpdated';
import { ExpenseItemTotalAmountUpdated } from '../components/LeftSection/History/Events/ExpenseItemTotalAmountUpdated';
import { ReimbursementCaseAmendmentRequested } from '../components/LeftSection/History/Events/ReimbursementCaseAmendmentRequested';
import { ReimbursementCaseApproved } from '../components/LeftSection/History/Events/ReimbursementCaseApproved';
import { ReimbursementCaseCommentAdded } from '../components/LeftSection/History/Events/ReimbursementCaseCommentAdded';
import { ReimbursementCaseCreated } from '../components/LeftSection/History/Events/ReimbursementCaseCreated';
import { ReimbursementCaseNoteUpdatedEvent } from '../components/LeftSection/History/Events/ReimbursementCaseNoteUpdated';
import { ReimbursementCaseSubmittedForReviewEvent } from '../components/LeftSection/History/Events/ReimbursementCaseSubmittedForReview';
import { ReimbursementCaseTargetMembershipUpdatedEvent } from '../components/LeftSection/History/Events/ReimbursementCaseTargetedMembershipUpdated';
import { ReimbursementCaseTitleUpdatedEvent } from '../components/LeftSection/History/Events/ReimbursementCaseTitleUpdated';
import {
  SupportedReimbursementTimeLineEvents,
  SupportedFragmentEvents,
} from '../hooks/useReimbursementHistory';
import { ExpenseItemBookingAdded } from '../components/LeftSection/History/Events/SplitEvents/ExpenseItemBookingAdded';
import { ExpenseItemBookingUpdated } from '../components/LeftSection/History/Events/SplitEvents/ExpenseItemBookingUpdated';
import { ExpenseItemBookingRemoved } from '../components/LeftSection/History/Events/SplitEvents/ExpeseItemBookingRemoved';
import { ExpenseItemInvoiceNumberUpdated } from '../components/LeftSection/History/Events/ExpenseItemInvoiceNumberUpdated';
import { ReimbursementCaseItemAdded } from '../components/LeftSection/History/Events/ReimbursementCaseItemAdded';
import { ReimbursementCaseItemExcluded } from '../components/LeftSection/History/Events/ReimbursementCaseItemExcluded';
import { ReimbursementCaseItemIncluded } from '../components/LeftSection/History/Events/ReimbursementCaseItemIncluded';
import { ReimbursementCaseItemRemoved } from '../components/LeftSection/History/Events/ReimbursementCaseItemRemoved';
import { ReimbursementCaseResetApproval } from '../components/LeftSection/History/Events/ReimbursementCaseResetApproval';
import { ReimbursementCaseItemExportStatusUpdated } from '../components/LeftSection/History/Events/ReimbursementCaseItemExportStatusUpdated';

export const mappedExpenseTypeToGeneralTitle: Record<
  ReimbursementItemType,
  string
> = {
  GENERAL_EXPENSE: 'timeline.generalExpense',
  HOSPITALITY_EXPENSE: 'timeline.hospitalityExpense',
  MILEAGE: '',
  PER_DIEM: '',
};

export type SupportedReimbursemementEventKeys =
  Required<SupportedReimbursementTimeLineEvents>['__typename'];

export type Content = SupportedFragmentEvents['properties'];

export const mapWithSupportedReimbursementEventKeys = (
  obj: {
    [k in SupportedReimbursemementEventKeys]: {
      // biome-ignore lint/nursery/noRestrictedTypes: <explanation>
      item: FunctionComponent<BaseItemProps>;
      // biome-ignore lint/nursery/noRestrictedTypes: <explanation>
      content: FunctionComponent<any>;
      collapsible?: boolean;
    };
  }
) => obj;

export const getActivityTypeToReimbursementHistoryComponent =
  mapWithSupportedReimbursementEventKeys({
    ExpenseItemExpenseDateUpdatedEvent: {
      item: History.User,
      content: ExpenseItemFieldUpdatedDateUpdated,
    },
    ExpenseItemFileAddedEvent: {
      item: History.User,
      content: ExpenseItemFileAdded,
    },
    ExpenseItemFileRemovedEvent: {
      item: History.User,
      content: ExpenseItemFileRemoved,
    },
    ExpenseItemTitleUpdatedEvent: {
      item: History.User,
      content: ExpenseItemTitleUpdated,
    },
    ExpenseItemTotalAmountUpdatedEvent: {
      item: History.User,
      content: ExpenseItemTotalAmountUpdated,
    },
    ReimbursementCaseAmendmentRequestedEvent: {
      item: History.User,
      content: ReimbursementCaseAmendmentRequested,
    },
    ReimbursementCaseApprovedEvent: {
      item: History.User,
      content: ReimbursementCaseApproved,
    },
    ReimbursementCaseCreatedEvent: {
      item: History.User,
      content: ReimbursementCaseCreated,
    },
    ReimbursementCaseNoteUpdatedEvent: {
      item: History.User,
      content: ReimbursementCaseNoteUpdatedEvent,
    },
    ReimbursementCaseSubmittedForReviewEvent: {
      item: History.User,
      content: ReimbursementCaseSubmittedForReviewEvent,
    },
    ReimbursementCaseTargetMembershipUpdatedEvent: {
      item: History.User,
      content: ReimbursementCaseTargetMembershipUpdatedEvent,
    },
    ReimbursementCaseTitleUpdatedEvent: {
      item: History.User,
      content: ReimbursementCaseTitleUpdatedEvent,
    },
    ExpenseItemArchivedEvent: {
      item: History.User,
      content: ExpenseItemArchived,
    },
    ExpenseItemHospitalityExternalGuestsUpdatedEvent: {
      item: History.User,
      content: ExpenseItemHospitalityExternalGuestsUpdated,
    },
    ExpenseItemHospitalityInternalGuestsUpdatedEvent: {
      item: History.User,
      content: ExpenseItemHospitalityInternalGuestsUpdated,
    },
    ExpenseItemHospitalityLocationUpdatedEvent: {
      item: History.User,
      content: ExpenseItemHospitalityLocationUpdated,
    },
    ExpenseItemHospitalityReceiptAmountUpdatedEvent: {
      item: History.User,
      content: ExpenseItemHospitalityReceiptAmountUpdated,
    },
    ExpenseItemHospitalityTipAmountUpdatedEvent: {
      item: History.User,
      content: ExpenseItemHospitalityTipAmountUpdated,
    },
    ReimbursementCaseCommentAddedEvent: {
      item: History.User,
      content: ReimbursementCaseCommentAdded,
    },
    ExpenseItemBookingAddedEvent: {
      item: History.User,
      content: ExpenseItemBookingAdded,
      collapsible: true,
    },
    ExpenseItemBookingRemovedEvent: {
      item: History.User,
      content: ExpenseItemBookingRemoved,
    },
    ExpenseItemBookingUpdatedEvent: {
      item: History.User,
      content: ExpenseItemBookingUpdated,
      collapsible: true,
    },
    ExpenseItemInvoiceNumberUpdatedEvent: {
      item: History.User,
      content: ExpenseItemInvoiceNumberUpdated,
    },
    ReimbursementCaseItemAddedEvent: {
      item: History.User,
      content: ReimbursementCaseItemAdded,
    },
    ReimbursementCaseItemExcludedEvent: {
      item: History.User,
      content: ReimbursementCaseItemExcluded,
    },
    ReimbursementCaseItemIncludedEvent: {
      item: History.User,
      content: ReimbursementCaseItemIncluded,
    },
    ReimbursementCaseItemRemovedEvent: {
      item: History.User,
      content: ReimbursementCaseItemRemoved,
    },
    ReimbursementCaseResetApprovalEvent: {
      item: History.User,
      content: ReimbursementCaseResetApproval,
    },
    ReimbursementCaseItemExportStatusUpdatedEvent: {
      item: History.User,
      content: ReimbursementCaseItemExportStatusUpdated,
      collapsible: true,
    },
  });
