import { getRandomDate, getRandomId } from 'utils/generateRandomData';
import { ExportEntityRow } from 'views/Integrations/Export/types';

export const dummyProvisionData = Array.from({ length: 14 }, () => ({
  type: 'PROVISION',
  id: getRandomId(),
  contact: 'Amazon EU',
  amount: {
    amount: 0,
    currency: 'EUR',
    precision: 0,
  },
  netAmount: {
    __typename: 'Money',
    currency: 'EUR',
    amount: 77350,
    precision: 2,
  },
  invoiceDate: getRandomDate(),
  deliveryDate: getRandomDate(),
  invoiceNumber: '',
  paymentState: {
    isPaid: false,
  },
  hasDocument: false,
  generalLedgerAccount: ['6600'],
  account: ['1354'],
  provisionEntityType: 'DOCUMENT',
  provisionEntityId: getRandomId(),
  provisionStatus: 'OPEN',
  isDisabled: true,
  exportStatus: null,
  disableReason: 'Not part of the selected posting period',
})) as ExportEntityRow[];

export const dummyReversalData = Array.from({ length: 14 }, () => ({
  type: 'PROVISION_REVERSAL',
  id: getRandomId(),
  contact: 'Amazon EU',
  amount: {
    amount: 0,
    currency: 'EUR',
    precision: 0,
  },
  netAmount: {
    __typename: 'Money',
    currency: 'EUR',
    amount: 909,
    precision: 2,
  },
  invoiceDate: getRandomDate(),
  deliveryDate: getRandomDate(),
  invoiceNumber: '',
  paymentState: {
    isPaid: false,
  },
  hasDocument: false,
  generalLedgerAccount: ['7888', '7888', '7888'],
  provisionEntityType: 'DOCUMENT',
  provisionEntityId: getRandomId(),
  provisionStatus: 'EXPORTING',
  isDisabled: true,
  exportStatus: null,
  disableReason: 'This export type does not support provisions or reversals',
})) as ExportEntityRow[];
