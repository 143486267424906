import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';

export const useDrawerNavigation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const organization = useOrganizationId();

  const navigateBackToList = () => {
    navigate({
      pathname: `/${organization}${Routes.SETTINGS}${Routes.PROJECT_CODES_ROUTE}`,
      search: searchParams.toString(),
    });
  };

  const navigateToDetailsDrawer = (id: string) => {
    navigate({
      pathname: `/${organization}${Routes.SETTINGS}${Routes.PROJECT_CODES_ROUTE}/${id}`,
      search: searchParams.toString(),
    });
  };

  return {
    navigateBackToList,
    navigateToDetailsDrawer,
  };
};
