import { Grid, Modal } from '@candisio/design-system';
import { getTranslationContext } from 'orgConfig';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { WidgetModalComponentProps } from '../../types';
import { WIDGET_TYPE } from './WidgetTypeSelection/TypeSelection/types';

export const WidgetModalComponent = ({
  visible,
  selectedWidgetType,
  resetModalValues,
  onCloseWidgetModal,
  children,
}: WidgetModalComponentProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const [tCommon] = useTranslation();

  const renderTitleBasedOnWidgetType = (selectedWidget: WIDGET_TYPE | null) => {
    if (!selectedWidget) {
      return t('dashboard.createNewWidget.header.title.baseTitle');
    }

    const mapWidgetTypesToTranslations = {
      [WIDGET_TYPE.CONTACT]: t(
        'dashboard.createNewWidget.header.title.contact'
      ),
      [WIDGET_TYPE.COST_CENTER]: t(
        'dashboard.createNewWidget.header.title.costCenter',
        {
          context: getTranslationContext(),
        }
      ),

      [WIDGET_TYPE.GENERAL_LEDGER_ACCOUNT]: t(
        'dashboard.createNewWidget.header.title.generalLedgerAccount'
      ),
    };

    return mapWidgetTypesToTranslations[selectedWidget as WIDGET_TYPE];
  };

  const handleModalClose = () => {
    resetModalValues({ isCloseModal: true });
    onCloseWidgetModal();
  };

  return (
    <Modal
      onClose={handleModalClose}
      isOpen={visible}
      title={renderTitleBasedOnWidgetType(selectedWidgetType)}
      closeLabel={tCommon('common.close')}
      width="52rem"
      height="610px"
      background="gray200"
    >
      <Grid gap="space24">{children}</Grid>
    </Modal>
  );
};
