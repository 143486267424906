import {
  Card,
  Flex,
  Grid,
  Heading,
  Link,
  TruncatedText,
} from '@candisio/design-system';
import { AppRouteParams, Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom-v5-compat';
import { Team } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';
import { SETTINGS_ROUTES } from 'views/Settings/types';

export interface UserTeamsWithLinksProps {
  teams: Team[];
}

export const UserTeamsWithLinks = ({ teams }: UserTeamsWithLinksProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const organizationSlug = useOrganizationId();

  if (!teams.length) {
    return null;
  }

  const sortedTeams = teams.sort((a, b) => a.name.localeCompare(b.name));

  const createTeamUrl = (id?: string) => {
    if (!id) {
      return;
    }

    return generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.TEAMS}/:id?`,
      { organizationSlug, id }
    );
  };

  return (
    <Grid gap="space8">
      <Heading as="h3">{t('teams.title')}</Heading>
      <Card background="gray100">
        <Flex wrap="wrap" gap="space8">
          {sortedTeams.map(team => (
            <Grid key={team.id} templateColumns="auto 1fr" alignItems="center">
              <Link
                href={createTeamUrl(team.id)}
                style={{ display: 'contents' }}
                external
              >
                <TruncatedText
                  fontSize="basic"
                  maxWidth="20ch"
                  lineHeight="paragraph"
                >
                  {team.name}
                </TruncatedText>
              </Link>
            </Grid>
          ))}
        </Flex>
      </Card>
    </Grid>
  );
};
