import { Grid, Skeleton } from '@candisio/design-system';

export const VerticalBarSkeleton = () => {
  const randomHeight = Math.floor(Math.random() * (100 - 0 + 1)) + 0;

  return (
    <Grid
      height="100%"
      alignItems="end"
      templateRows="1fr auto"
      gap="space8"
      justifyContent="center"
    >
      <Skeleton
        width="space12"
        height={`${randomHeight}%`}
        justifySelf="center"
      />
      <Skeleton height="space12" width="space24" />
    </Grid>
  );
};
