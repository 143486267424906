import { i18n } from 'providers/LocaleProvider';
import {
  z,
  ZodCustomIssue,
  ZodErrorMap,
  ZodIssueCode,
  ZodIssueOptionalMessage,
  ZodType,
  ZodTypeAny,
} from 'zod';
import {
  DefinedZodIssueDetails,
  ErrorMessageDetails,
  ErrorMessages,
  Paths,
} from './types';

type ErrorMapCtx = Parameters<ZodErrorMap>[1];
type AnyRecord = Record<string, any>;

// Join the path so we can use it to access our flat map of error messages.
// Array paths are all give a "0" index, same as they are defined.
const joinPath = (path: Array<string | number>): string => {
  return path.map(p => (typeof p === 'number' ? '0' : p)).join('.');
};

const getErrorMessageDefinition = <
  TErrorMessages extends ErrorMessages<ZodType<AnyRecord>>,
>(
  error: ZodIssueOptionalMessage,
  errorMessages: TErrorMessages
): ErrorMessageDetails | undefined => {
  const joinedPath = joinPath(error.path) as keyof TErrorMessages;

  return errorMessages[joinedPath];
};

const hasTranslationKey = <TErrorDetails>(
  errorDetails: TErrorDetails
): errorDetails is TErrorDetails & { translationKey: string } => {
  return Boolean(
    errorDetails &&
      typeof errorDetails === 'object' &&
      errorDetails !== null &&
      'translationKey' in errorDetails
  );
};

const hasZodIssueKeyInMessages = <
  TIssueCode extends ZodIssueCode,
  TErrorMessage,
>(
  code: TIssueCode,
  errorMessage: TErrorMessage
): errorMessage is {
  [K in TIssueCode]: DefinedZodIssueDetails<TIssueCode>;
} & TErrorMessage => {
  return Boolean(
    errorMessage &&
      typeof errorMessage === 'object' &&
      errorMessage !== null &&
      code in errorMessage
  );
};

const hasDefinedZodIssue = <TIssueCode extends ZodIssueCode, TErrorMessage>(
  code: TIssueCode,
  errorMessage: TErrorMessage
): errorMessage is {
  [K in TIssueCode]: DefinedZodIssueDetails<TIssueCode>;
} & TErrorMessage => {
  return (
    hasZodIssueKeyInMessages(code, errorMessage) &&
    hasTranslationKey(errorMessage[code])
  );
};

/**
 * Translation key added with
 * ```
 * ctx.addIssue({
 *  code: 'custom',
 *  params: { translationKey: 'key' }
 * });
 * ```
 * takes precedence over the translation key added to the errorMessages
 */
const getTranslationKeyFromCustomError = (
  error: ZodCustomIssue,
  definedError: ErrorMessageDetails
): string | undefined => {
  if (typeof definedError === 'string') {
    return definedError;
  }

  if (customZodIssueHasTranslationKey(error)) {
    return error.params.translationKey;
  }

  if (customErrorMapHasTranslationKey(definedError.custom)) {
    return definedError.custom.translationKey;
  }
};

/**
 * Returns a zod error map to that translates zod errors using our translation keys
 */
export const getZodErrorMap =
  <
    TExludeKeys extends Paths<z.infer<TSchema>> | undefined = undefined,
    TSchema extends ZodTypeAny = ZodTypeAny,
  >(
    _schema: TSchema,
    errorMessages: ErrorMessages<TSchema, TExludeKeys>,
    /**
     * Defaults to `common`
     */
    translationNamespace?: string
  ): ZodErrorMap =>
  (error, ctx) => {
    const definedError = getErrorMessageDefinition(error, errorMessages);

    if (!definedError) {
      return { message: error.message ?? ctx.defaultError };
    }

    const label = i18n.t(definedError.label, { ns: translationNamespace });

    switch (error.code) {
      case 'invalid_string': {
        const translationKey = definedError[error.code]?.translationKey;

        if (translationKey) {
          return {
            message: i18n.t(translationKey, {
              ns: translationNamespace,
              label,
            }),
          };
        }

        break;
      }

      case 'custom':
        const translationKey = getTranslationKeyFromCustomError(
          error,
          definedError
        );

        if (
          hasZodIssueKeyInMessages('custom', definedError) &&
          translationKey
        ) {
          return {
            message: i18n.t(translationKey, {
              ...error.params,
              ...definedError.custom.params,
              ...definedError.custom.additionalCtx,
              ns: translationNamespace,
              label,
            }),
          };
        }

        /**
         * The translationKey can be added when creating the zod issue without
         * including it in the ErrorMessages.
         *
         * ```
         * const schema = z.object({ myFormField: z.string().superRefine((s) => {
         *   if (s === 'notAllowedToBeThisWord') {
         *     z.addIssue({
         *       code: 'custom',
         *       params: {
         *         translationKey: 'my.custom.translation.key'
         *       }
         *     })
         *   }
         * })});
         *
         * const errorMessages = { myFormField: { label: 'my.label.key' } }
         * ```
         */
        if (translationKey) {
          return {
            message: i18n.t(translationKey, {
              ...error.params,
              ns: translationNamespace,
              label,
            }),
          };
        }

        break;

      case 'invalid_enum_value':
        if (hasDefinedZodIssue('invalid_enum_value', definedError)) {
          const {
            options: optionsFromMessages,
            additionalCtx,
            ...errorCtx
          } = definedError.invalid_enum_value;

          const options = optionsFromMessages?.length
            ? optionsFromMessages
            : error.options;

          return {
            message: i18n.t(definedError.invalid_enum_value.translationKey, {
              ...error,
              ...errorCtx,
              options: options.join(', '),
              ...additionalCtx,
              ns: translationNamespace,
              label,
            }),
          };
        }

        if (shouldUseDefaultMessage(definedError)) {
          return {
            message: i18n.t('formValidation.fields.oneOf', {
              ...error,
              options: error.options.join(', '),
              label,
            }),
          };
        }

        break;

      case 'too_big':
        if (hasDefinedZodIssue('too_big', definedError)) {
          const {
            translationKey: tKey,
            additionalCtx,
            ...errorCtx
          } = definedError.too_big;

          const translationKey =
            typeof tKey === 'string' ? tKey : tKey(error.inclusive);

          return {
            message: i18n.t(translationKey, {
              ...error,
              maximum: error.maximum,
              ...errorCtx,
              ...additionalCtx,
              ns: translationNamespace,
              label,
            }),
          };
        }

        if (shouldUseDefaultMessage(definedError)) {
          const keyType = error.type;

          return {
            message: i18n.t(
              `formValidation.fields.${keyType}.max.${
                error.inclusive ? 'inclusive' : 'exclusive'
              }`,
              {
                label,
                maximum: error.maximum,
              }
            ),
          };
        }

        break;

      case 'too_small':
        /**
         * Handle an error for a string with minimum length of 1 as a `isRequiredError`
         * error instead of a too small error.
         */
        if (isEmptyStringError(error)) {
          break;
        }

        if (hasDefinedZodIssue('too_small', definedError)) {
          const {
            translationKey: tKey,
            additionalCtx,
            ...errorCtx
          } = definedError.too_small;

          const translationKey =
            typeof tKey === 'string' ? tKey : tKey(error.inclusive);

          return {
            message: i18n.t(translationKey, {
              ...error,
              minimum: error.minimum,
              ...errorCtx,
              ...additionalCtx,
              ns: translationNamespace,
              label,
            }),
          };
        }

        if (shouldUseDefaultMessage(definedError)) {
          const keyType = error.type;

          return {
            message: i18n.t(
              `formValidation.fields.${keyType}.min.${
                error.inclusive ? 'inclusive' : 'exclusive'
              }`,
              {
                label,
                minimum: error.minimum,
              }
            ),
          };
        }

        break;
    }

    if (hasTranslationKey(definedError)) {
      return {
        message: i18n.t(definedError.translationKey, {
          ...error,
          ...definedError.additionalCtx,
          ns: translationNamespace,
          label,
        }),
      };
    }

    if (isRequiredError(error, ctx)) {
      return {
        message: i18n.t('formValidation.fields.required', {
          label,
        }),
      };
    }

    return { message: i18n.t('formValidation.fields.invalid', { label }) };
  };

const isEmptyStringError = (error: ZodIssueOptionalMessage): boolean => {
  return Boolean(
    error.code === 'too_small' && error.type === 'string' && error.minimum === 1
  );
};

const hasNoValue = (error: ZodIssueOptionalMessage): boolean => {
  return (
    error.code === 'invalid_type' &&
    ['null', 'undefined'].includes(error.received)
  );
};

const nonStringFieldHasEmptyStringValue = (
  error: ZodIssueOptionalMessage,
  ctx: ErrorMapCtx
): boolean => {
  return (
    error.code === 'invalid_type' &&
    error.received === 'string' &&
    error.expected !== 'string' &&
    ctx.data === ''
  );
};

const shouldUseDefaultMessage = <TErrorDetails extends ErrorMessageDetails>(
  definedErrorMessage: TErrorDetails
): boolean => {
  return !hasTranslationKey(definedErrorMessage);
};

const isRequiredError = (
  error: ZodIssueOptionalMessage,
  ctx: ErrorMapCtx
): boolean => {
  return (
    hasNoValue(error) ||
    isEmptyStringError(error) ||
    nonStringFieldHasEmptyStringValue(error, ctx)
  );
};

const customErrorMapHasTranslationKey = <
  TIssueCode extends DefinedZodIssueDetails<'custom'>,
>(
  error?: TIssueCode
): error is { custom: { translationKey: string } } & TIssueCode => {
  return Boolean(
    typeof error?.translationKey === 'string' && error.translationKey.length
  );
};

const customZodIssueHasTranslationKey = <TIssueCode extends ZodCustomIssue>(
  error: TIssueCode
): error is { params: { translationKey: string } } & TIssueCode => {
  return Boolean(error.params && 'translationKey' in error.params);
};
