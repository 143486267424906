import { Button, usePopover } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Filters } from 'react-table';
import { Filter } from '../Filter/Filter';
import { WidgetFilter, PopoverFilters } from '../utils';

interface CreditCardFilterProps {
  onFilter: (filters: Filters<PopoverFilters>) => void;
  appliedFilters: string[];
  allFilters: Filters<PopoverFilters>;
}

export const CreditCardFilter = ({
  onFilter,
  appliedFilters,
  allFilters,
}: CreditCardFilterProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isOpen, close, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'bottom' });

  const filtersWithouthCreditCard = allFilters.filter(
    filter => filter.id !== WidgetFilter.cardRefNum
  );

  const buttonText = appliedFilters?.length
    ? t('credit-cards:insights.filters.creditCard.withNumber', {
        filters: appliedFilters?.length,
      })
    : t('credit-cards:insights.filters.creditCard.noNumber');

  const handleApplyFilters = (filters: string[]) => {
    if (!filters) {
      return [];
    }

    const filtersForUrl = [
      ...filtersWithouthCreditCard,
      { id: WidgetFilter.cardRefNum, value: filters },
    ];

    onFilter(filtersForUrl);
    close();
  };

  const handleResetFilters = () => {
    onFilter(filtersWithouthCreditCard);
    close();
  };

  return (
    <>
      <Button
        {...triggerProps}
        ref={triggerRef}
        icon={isOpen ? 'caretUp' : 'caretDown'}
        iconPosition="right"
        alignSelf="start"
        variant="secondary"
      >
        {buttonText}
      </Button>

      {isOpen && (
        <Filter
          {...popoverProps}
          filterName={WidgetFilter.cardRefNum}
          filterValue={appliedFilters}
          ref={popoverRef}
          onApply={handleApplyFilters}
          onReset={handleResetFilters}
        />
      )}
    </>
  );
};
