import { useUpdateAccountsPayableNumberForEmployeeContactMutation } from 'generated-types/graphql.types';
import { useCallback } from 'react';
import { isNilOrEmpty } from 'utils/isNilOrEmpty';
import { getAccountPayableNumberByMembershipId } from '../queries';

export const useUpdateAccountsPayableNumber = () => {
  const [updateAccountPayableNumber, { loading }] =
    useUpdateAccountsPayableNumberForEmployeeContactMutation();

  const handleUpdateAccountPayableNumber = useCallback(
    async (
      accountPayableNumber: string | null | undefined,
      membershipId: string
    ) => {
      const response = await updateAccountPayableNumber({
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getAccountPayableNumberByMembershipId,
            variables: { membershipId },
          },
        ],
        variables: {
          membershipId,
          accountsPayableNumber: !isNilOrEmpty(accountPayableNumber)
            ? accountPayableNumber?.replaceAll(' ', '')
            : null,
        },
      });

      if ((response.errors ?? []).length > 0) {
        return { status: 'error' };
      }

      return { status: 'success' };
    },
    [updateAccountPayableNumber]
  );

  return {
    updateAccountPayableNumber: handleUpdateAccountPayableNumber,
    isUpdateAccountPayableNumberPending: loading,
  };
};
