import {
  ExportablePaymentCondition,
  ExportablePaymentConditionStatus,
  useCountExportablePaymentConditionsQuery,
  usePaymentConditionsForExportQuery,
} from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { UseTranslationResponse, useTranslation } from 'react-i18next';
import { percentageFormat } from 'utils/format';
import { useExportContext } from '../../Context';
import { View } from '../../types';
import { useExportDataGigaFactory } from './useExportDataGigaFactory';

type TranslationFunc = UseTranslationResponse<
  LOCALE_NAME_SPACE.EXPORTS,
  undefined
>[0];

const getPaymentConditionValue = (
  t: TranslationFunc,
  condition: ExportablePaymentCondition
) => {
  const { discountOffset, discountPercentage, dueDateOffset } = condition;
  const noDiscount =
    discountOffset === undefined || discountPercentage === undefined;

  const value = noDiscount
    ? t('paymentConditions.table.values.conditionWithoutDiscount', {
        dueDateOffset,
      })
    : t('paymentConditions.table.values.conditionWithDiscount', {
        discountOffset,
        discountPercentage: percentageFormat(discountPercentage ?? 0),
        dueDateOffset,
      });

  return value;
};

// HACK: those numbers are only used for generating stable pseudo-random data
const nameLengths = [4, 23, 8, 42, 15, 16] as const;

const toMaskedTableData = (t: TranslationFunc) => (_: unknown, i: number) => {
  const dataLength = nameLengths[i % nameLengths.length];
  const discountOffset = nameLengths[(i * 3) % nameLengths.length];
  const discountPercentage = nameLengths[(i * 5) % nameLengths.length];
  const dueDateOffset = nameLengths[(i * 7) % nameLengths.length];

  return {
    id: i + '',
    value: getPaymentConditionValue(t, {
      discountOffset,
      discountPercentage,
      dueDateOffset,
    } as ExportablePaymentCondition),
    status: ExportablePaymentConditionStatus.Updated,
    paymentConditionNumber: i * dataLength,
  };
};

const maxEntriesCap = 50;
const getMaskedData = (historyConditionsCount = 0, t: TranslationFunc) => {
  const entitiesCount =
    historyConditionsCount && Math.min(historyConditionsCount, maxEntriesCap);

  return Array(entitiesCount).fill(0).map(toMaskedTableData(t));
};

const toPaymentConditionsTableData =
  (t: TranslationFunc) => (condition: ExportablePaymentCondition) => {
    const { id, paymentConditionNumber, status } = condition;

    const value = getPaymentConditionValue(t, condition);

    return {
      id,
      value,
      status,
      paymentConditionNumber,
    };
  };

const usePaymentConditions = ({
  isHistoryView,
  historyConditionsCount,
  skip,
  isAllShown,
}: {
  isHistoryView: boolean;
  historyConditionsCount: number | undefined;
  skip: boolean;
  isAllShown: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);

  const paymentConditionsQuery = usePaymentConditionsForExportQuery({
    variables: { fetchAllRecords: isAllShown },
    // HACK: We receive all records with status "New"
    // value form cache overrides other statuses if not fetched from `network-only`
    fetchPolicy: 'network-only',
    skip,
  });

  const { paymentConditionsForExport } = paymentConditionsQuery.data ?? {};

  const paymentConditions = useMemo(
    () =>
      isHistoryView
        ? getMaskedData(historyConditionsCount, t)
        : (paymentConditionsForExport?.map(toPaymentConditionsTableData(t)) ??
          []),
    [historyConditionsCount, isHistoryView, paymentConditionsForExport, t]
  );

  return { paymentConditionsQuery, paymentConditions };
};

const usePaymentConditionsCount = ({
  isHistoryView,
  historyConditionsCount,
  skip,
}: {
  isHistoryView: boolean;
  historyConditionsCount: number | undefined;
  skip: boolean;
}) => {
  const countQuery = useCountExportablePaymentConditionsQuery({ skip });

  const {
    exportablePaymentConditionCount,
    newAndUpdatedExportablePaymentConditionCount,
  } = countQuery.data?.evaluateCoreDataExport?.exportableCoreDataCount ?? {};

  const allConditionsCount = exportablePaymentConditionCount?.count;

  const newAndUpdatedConditionsCount = isHistoryView
    ? historyConditionsCount
    : newAndUpdatedExportablePaymentConditionCount?.count;

  return {
    countQuery,
    allConditionsCount: allConditionsCount ?? 0,
    newAndUpdatedConditionsCount: newAndUpdatedConditionsCount ?? 0,
  };
};

export const useExportPaymentConditionsFactory = () => {
  const { bdsBought, exportDatevBdsViaIntegrationSvcFF } = useDatev();
  const state = useExportContext();
  const { exportDetails } = useExportDataGigaFactory(state);

  const isAllShown = state.includeAllPaymentConditions;
  const isHistoryView = state.view === View.HISTORY;

  const historyConditionsCount =
    exportDetails?.exportedPaymentConditionsInfo?.count;

  const skip =
    !bdsBought || !exportDatevBdsViaIntegrationSvcFF || isHistoryView;

  const { paymentConditionsQuery, paymentConditions } = usePaymentConditions({
    isHistoryView,
    historyConditionsCount,
    skip,
    isAllShown,
  });

  const { countQuery, allConditionsCount, newAndUpdatedConditionsCount } =
    usePaymentConditionsCount({ isHistoryView, historyConditionsCount, skip });

  return {
    isAllShown,
    isMasked: isHistoryView,
    paymentConditions: {
      refetch: paymentConditionsQuery.refetch,
      isLoading: paymentConditionsQuery.loading,
      data: paymentConditionsQuery.data,
      tableData: paymentConditions,
    },
    count: {
      refetch: countQuery.refetch,
      isLoading: countQuery.loading,
      all: allConditionsCount,
      newAndUpdated: newAndUpdatedConditionsCount,
    },
  };
};
