import { castArray } from 'lodash';
import React from 'react';
import { useTheme } from '../../Theme';
import { ThemeValues } from '../../types';

/**
 * Map of props to token scales
 */
const SCALE_MAP: Record<string, keyof ThemeValues | Array<keyof ThemeValues>> =
  {
    // standard CSS properties
    background: 'colors',
    border: ['space', 'colors'],
    borderBottom: ['space', 'colors'],
    borderBottomLeftRadius: 'radii',
    borderBottomRightRadius: 'radii',
    borderLeft: ['space', 'colors'],
    borderRadius: 'radii',
    borderRight: ['space', 'colors'],
    borderTop: ['space', 'colors'],
    borderTopLeftRadius: 'radii',
    borderTopRightRadius: 'radii',
    bottom: 'space',
    boxShadow: 'shadows',
    color: 'colors',
    columnGap: 'space',
    fontFamily: 'fontFamilies',
    fontSize: 'fontSizes',
    fontWeight: 'fontWeights',
    gap: 'space',
    height: 'space',
    inset: 'space',
    left: 'space',
    lineHeight: ['lineHeights', 'space'],
    maxHeight: 'space',
    maxWidth: 'space',
    minHeight: 'space',
    minWidth: 'space',
    padding: 'space',
    paddingBottom: 'space',
    paddingLeft: 'space',
    paddingRight: 'space',
    paddingTop: 'space',
    right: 'space',
    rowGap: 'space',
    templateColumns: 'space',
    templateRows: 'space',
    top: 'space',
    width: 'space',
    // custom extensions
    borderBottomRadius: 'radii',
    borderTopRadius: 'radii',
    paddingX: 'space',
    paddingY: 'space',
  };

interface CustomProps {
  borderBottomRadius: React.CSSProperties['borderRadius'];
  borderTopRadius: React.CSSProperties['borderRadius'];
  paddingX: React.CSSProperties['padding'];
  paddingY: React.CSSProperties['padding'];
}

/**
 * Return type of useMapTokensToCSS:
 * - Contains only the specific props that were passed in
 * - Mapped props have appropriate React.CSSProperties types
 * - Any other props left untouched
 */
type MapTokensToCSSResult<Props> = {
  [Prop in keyof Props]: Prop extends keyof React.CSSProperties
    ? React.CSSProperties[Prop]
    : Prop extends keyof CustomProps
      ? CustomProps[Prop]
      : Props[Prop];
};

/**
 * Maps the values of a set of props from design tokens to CSS
 */
const mapTokensToCSS = <Props extends Record<string, unknown>>(
  props: Props,
  theme: ThemeValues
): MapTokensToCSSResult<Props> => {
  const mappedProps: Record<string, unknown> = {};

  Object.entries(props).forEach(([propName, propValue]) => {
    // Bail out if no mapping exists
    if (typeof propValue !== 'string' || !(propName in SCALE_MAP)) {
      return;
    }

    let mappedValue = propValue;

    const scaleNames = castArray(SCALE_MAP[propName]);
    for (const scaleName of scaleNames) {
      const scale = theme[scaleName];

      // simple case: prop value is a token name in the scale
      // e.g: color="blue500"
      if (propValue in scale) {
        mappedValue = scale[propValue as keyof typeof scale];
        break;
      }

      // complex case: prop value is a string *containing* one or more token
      // names in the scale
      // e.g: border="1px solid blue500"
      Object.entries(scale).forEach(([tokenName, tokenValue]) => {
        mappedValue = mappedValue.replace(
          new RegExp(`\\b${tokenName}\\b`, 'g'),
          tokenValue
        );
      });
    }

    mappedProps[propName] = mappedValue;
  });

  return { ...props, ...mappedProps } as MapTokensToCSSResult<Props>;
};

export const useMapTokensToCSS = <Props extends Record<string, unknown>>(
  props: Props
): MapTokensToCSSResult<Props> => {
  const theme = useTheme();

  return mapTokensToCSS(props, theme);
};
