import { Button, Flex, Icon, Paragraph, Text } from '@candisio/design-system';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DatevStatusCardState } from './datevStatusCardState';

export interface DatevStatusCardProps {
  loading?: boolean;
  primaryAction: () => void;
  secondaryAction: () => void;
  additionalActions?: ReactElement[];
  state?: DatevStatusCardState;
}

export const DatevStatusCard = ({
  loading,
  primaryAction,
  secondaryAction,
  additionalActions,
  state = DatevStatusCardState.UNAVAILABLE,
}: DatevStatusCardProps) => {
  const [t] = useTranslation('exports');

  return (
    <Flex
      direction="column"
      gap="space16"
      borderRadius="medium"
      background="bluebg"
      padding="space16"
    >
      <Flex alignItems="center" gap="space8">
        <Icon size="space24" icon="infoCircle" />
        <Text as="h2" fontSize="xlarge" fontWeight="semibold">
          {t(`${state}.title`)}
        </Text>
      </Flex>

      <Paragraph>{t(`${state}.contextInformation`)}</Paragraph>

      <Flex direction="column" gap="space8">
        <Button loading={loading} variant="primary" onClick={primaryAction}>
          <Text>{t(`${state}.primaryCta`)}</Text>
        </Button>
        <Button variant="tertiary" onClick={secondaryAction}>
          {t(`${state}.secondaryCta`)}
        </Button>
        {additionalActions ? additionalActions : null}
      </Flex>
    </Flex>
  );
};
