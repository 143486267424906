import {
  DocumentCurrency,
  ExportableEntityType,
  InvoiceAssociationStatus,
  PaymentMethod,
  TransactionCategory,
  TransactionStatus,
  TransactionType,
} from 'generated-types/graphql.types';
import { createCardIssuerPaymentSettlement } from 'tests/valueObjectFactories/cardSettlementsReadyForExport';
import {
  getRandomAmount,
  getRandomDate,
  getRandomId,
} from 'utils/generateRandomData';

export const transactionData = Array.from({ length: 15 }, (_, index) => ({
  cursor: `eyJpdiI6Ii1wRGJPblBaWUFYbFdnaXVMbEcyWEEiLCJkYXRhIjoiVTh3QnFiaTlkLVljX2NLanlRbktDai11cjBJQkEwNFZPMXd0TWlvMW1BRkJHOTB${index}`,
  status:
    index % 2 === 0 ? TransactionStatus.Confirmed : TransactionStatus.Declined,
  id: `f703b614-4821-4398-88ad-d9e2a51d65${index}`,
  merchantName: `Merchant ${index}`,
  transactionAmount: {
    value: getRandomAmount(),
    precision: 2,
    currency: 'EUR',
  },
  grossAmount: {
    value: getRandomAmount(),
    currency: 'EUR',
  },
  transactionCreatedAt: getRandomDate(),
  cardRefNum: `•••• ${Math.floor(1000 + Math.random() * 9000)}`,
  cardholderName: {
    firstName: `FirstName${index}`,
    lastName: `LastName${index}`,
    avatarUrl: null,
    membershipId: `20fc0d65-c465-4b01-a6e2-8ee75a51d0${index}`,
  },
  invoiceAssociationStatus:
    index % 3 === 0
      ? InvoiceAssociationStatus.Uploaded
      : InvoiceAssociationStatus.NotNeeded,
  type: index % 2 === 0 ? TransactionType.Purchase : TransactionType.Refund,
  category:
    index % 3 === 0
      ? TransactionCategory.AdvertisingAndMarketing
      : TransactionCategory.FoodAndDrinks,
}));

const contacts = [
  'Candis',
  'Google Ads',
  'Indeed',
  'AWS',
  'Meta',
  'LinkedIn',
  'Flowify Systems',
  'SkySync Labs',
  'Apple',
  'Figma',
  'SAP',
  'Datev',
  'Personio',
  'OmniCore Enterprises',
];

export const approvalsData = contacts.map((contact, index) => ({
  id: getRandomId(),
  amount: {
    amount: getRandomAmount(),
    currency: DocumentCurrency.Eur,
    precision: 2,
  },
  invoiceDate: getRandomDate(),
  invoiceNumber: `DE12345678${index}`,
  contact: contact,
  exportGuid: getRandomId(),
  paymentState: {
    isPaid: true,
    paidAt: getRandomDate(),
    method: PaymentMethod.CardTransaction,
  },
  transactionStatus: TransactionStatus.Confirmed,
  hasDocument: true,
  type: ExportableEntityType.Document,
  direction: null,
  documentType: null,
}));

export const cardSettlementsData = Array.from({ length: 14 }, () => ({
  node: createCardIssuerPaymentSettlement(),
}));
