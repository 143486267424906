import {
  ReimbursementErrorType,
  useFastApproveReimbursementCaseMutation,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
} from '../toolkit/queries';
import { filterReimbursementItemErrors } from '../utils/filterReimbursementItemErrors';
import { isNotRelevantErrorForCase } from '../utils/isNotRelevantErrorForCase';

const EXCLUDED_ERROR_TYPE_FIELD = 'totalAmount.amount';

export const useFastApproveReimbursementCase = () => {
  const [fastApproveMutation, { loading: isFastApprovePending }] =
    useFastApproveReimbursementCaseMutation();

  const fastApprove = useCallback(
    async (reimbursementId: string) => {
      const response = await fastApproveMutation({
        variables: { id: reimbursementId },
        refetchQueries: [
          'reimbursementCasesCount',
          {
            query: reimbursementHistoryQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementCaseByIdQuery,
            variables: { id: reimbursementId },
          },
        ],
      });

      const isReponseInvalid =
        !response.data?.fastApproveReimbursementCase.success;

      const reimbursementItemErrors =
        response.data?.fastApproveReimbursementCase.errors
          .reimbursementItemsErrors ?? [];

      const caseErrors =
        response.data?.fastApproveReimbursementCase.errors
          .reimbursementCaseErrors?.errors ?? [];

      // XXX: Backend should remove this error on their side
      const reimbursementCaseErrors = caseErrors.filter(
        isNotRelevantErrorForCase
      );

      const itemsErrors =
        filterReimbursementItemErrors(reimbursementItemErrors)
          .itemErrorsWithoutBookings ?? [];

      const reimbursementItemsErrors = itemsErrors.map(reimbursementItem => ({
        id: reimbursementItem.id,
        errors: reimbursementItem.errors.length ?? 0,
        title: reimbursementItem.title ?? undefined,
        type: reimbursementItem.type,
      }));

      const noReimbursementItemsError = caseErrors.filter(errorField =>
        errorField.errors.includes(ReimbursementErrorType.NotFound)
      );

      const notIncludedReimbursementItemsError = caseErrors.filter(errorField =>
        errorField.errors.includes(ReimbursementErrorType.InvalidArrayContent)
      );

      if (isReponseInvalid) {
        return {
          status: 'error',
          reimbursementItemsErrors: reimbursementItemsErrors,
          hasNoReimbursementItems: !!noReimbursementItemsError.length,
          hasReimbursementCaseErrors: !!reimbursementCaseErrors.length,
          hasNoIncludedReimbursementItems:
            !!notIncludedReimbursementItemsError.length,
        };
      }

      return {
        status: 'success',
        reimbursementItemsErrors: [],
        hasReimbursementCaseErrors: false,
      };
    },
    [fastApproveMutation]
  );

  return { fastApprove, isFastApprovePending };
};
