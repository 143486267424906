import {
  EcmDocumentStatus,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface FilterOption {
  id: EcmDocumentStatus;
  label: string;
}

interface EcmDocumentStatusReturn {
  /** A map of the EcmDocumentStatus enum to the translated string */
  ecmDocumentStatusTranslationMap: Record<EcmDocumentStatus | string, string>;
  /** A list of filter options used in the table */
  ecmDocumentStatusFilterOptions: Array<FilterOption>;
}

const EXCLUDED_ECM_DOCUMENTS_STATUSES = [
  EcmDocumentStatus.Exporting,
  EcmDocumentStatus.Processing,
];

const EXCLUDED_REIMBURSEMENT_STATUSES = [
  ReimbursementCaseStatus.Check,
  ReimbursementCaseStatus.Draft,
  ReimbursementCaseStatus.Exporting,
  ReimbursementCaseStatus.Review,
  ReimbursementCaseStatus.PartiallyExported,
  ReimbursementCaseStatus.Approving,
];
const isSupportedDocumentStatusFilter = ([id]: [id: string, value: string]) =>
  !EXCLUDED_ECM_DOCUMENTS_STATUSES.includes(id as EcmDocumentStatus);

const isSupportedReimbursementCaseStatusFilter = ([id]: [
  id: string,
  value: string,
]) => !EXCLUDED_REIMBURSEMENT_STATUSES.includes(id as ReimbursementCaseStatus);

/**
 * A custom hook to get the translation map and filter options for the ECM document status
 * based on the EcmDocumentStatus enum
 */

export const useEcmDocumentStatusOptions = (): EcmDocumentStatusReturn => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { canUseReimbursement } = useReimbursement();

  const ecmDocumentStatusTranslationMap: Record<
    EcmDocumentStatus | string,
    string
  > = {
    [EcmDocumentStatus.Processing]: t('status.processing'),
    [EcmDocumentStatus.New]: t('status.new'),
    [EcmDocumentStatus.Open]: t('status.open'),
    [EcmDocumentStatus.Rejected]: t('status.rejected'),
    [EcmDocumentStatus.Approved]: t('status.approved'),
    [EcmDocumentStatus.Exported]: t('status.exported'),
    [EcmDocumentStatus.Exporting]: t('status.exporting'),
    [EcmDocumentStatus.Stored]: t('status.stored'),
    [EcmDocumentStatus.Archived]: t('status.archived'),
    [ReimbursementCaseStatus.Check]: t('reimbursement:status.check'),
    [ReimbursementCaseStatus.Draft]: t('reimbursement:status.draft'),
    [ReimbursementCaseStatus.Review]: t('reimbursement:status.review'),
    [ReimbursementCaseStatus.PartiallyExported]: t(
      'reimbursement:status.partiallyExported'
    ),
    [ReimbursementCaseStatus.Approving]: t('reimbursement:status.approving'),
  };

  const ecmDocumentStatusFilterOptions = Object.entries(
    ecmDocumentStatusTranslationMap
  )
    .filter(isSupportedDocumentStatusFilter)
    .filter(filterValue =>
      canUseReimbursement
        ? filterValue
        : isSupportedReimbursementCaseStatusFilter(filterValue)
    )
    .map(([id, label]) => {
      return {
        id,
        label,
      } as FilterOption;
    });

  return {
    ecmDocumentStatusTranslationMap,
    ecmDocumentStatusFilterOptions,
  };
};
