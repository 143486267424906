import { Grid, Text } from '@candisio/design-system';
import { Collapse } from 'components/History/items/Collapse';
import {
  ExpenseItemBooking,
  ExpenseItemBookingUpdatedEventDataFragment,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { BookingField, BookingValue } from './BookingField';
import { isNil } from 'lodash';

const isNotANestedField = (value: BookingValue) =>
  typeof value === 'string' ||
  typeof value === 'number' ||
  isNil(value) ||
  typeof value !== 'object';

const getNestedValue = (value: BookingValue) =>
  value && typeof value === 'object' && 'readableName' in value
    ? value.readableName
    : undefined;

export const ExpenseItemBookingUpdated = ({
  reimbursementItemMetadata,
  properties,
}: ExpenseItemBookingUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  if (!properties.newBooking || !properties.oldBooking) {
    return null;
  }

  const newBooking = properties.newBooking;
  const oldBooking = properties.oldBooking;
  const currency = properties.currency ?? undefined;

  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const updatedAccountingData = Object.entries(properties.newBooking);

  const filteredAccountingData = updatedAccountingData
    .filter(([key, value]) => {
      const objectKey = key as keyof ExpenseItemBooking;

      const oldValue = getNestedValue(oldBooking[objectKey]);

      const newValue = getNestedValue(newBooking[objectKey]);

      return (
        (isNotANestedField(value) &&
          oldBooking[objectKey] !== newBooking[objectKey]) ||
        newValue !== oldValue
      );
    })
    .filter(([key]) => key !== '__typename');

  const headlineWithTitle = t('timeline.bookingUpdated', {
    title: expenseTitle,
    count: filteredAccountingData.length,
  });

  const headlineWithNoTitle = t('timeline.bookingUpdatedNoTitle', {
    count: filteredAccountingData.length,
  });

  return (
    <Grid gap="space8">
      <Text>{expenseTitle ? headlineWithTitle : headlineWithNoTitle}</Text>
      <Collapse>
        <Grid gap="space4">
          {(filteredAccountingData ?? []).map(([key, value], index) => (
            <BookingField
              key={index}
              value={value}
              fieldKey={key}
              currency={currency}
            />
          ))}
        </Grid>
      </Collapse>
    </Grid>
  );
};
