import { Grid, Link, Text, useTheme } from '@candisio/design-system';
import { formatPhoneNumber } from 'components/HookFormFields/HookFormPhoneNumberField/formatPhoneNumber';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { nonEmptyString } from 'utils/zodFormValidation/Schemas/nonEmptyString';
import { z } from 'zod';

export interface OtpFormProps {
  formId: string;
  onSubmit: (formValues: OtpFormValues) => Promise<{ isVerified: boolean }>;
  newPhoneNumber: string;
  onResendOtpCode: () => void;
}

const otpSchema = z.object({
  otpCode: nonEmptyString,
});

type OtpFormValues = z.infer<typeof otpSchema>;

export const OtpForm = ({
  formId,
  newPhoneNumber,
  onResendOtpCode,
  onSubmit,
}: OtpFormProps) => {
  const [t] = useTranslation();
  const { success } = useToastMessage();
  const { space } = useTheme();

  const form = useForm<OtpFormValues>({
    defaultValues: {
      otpCode: undefined,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: zodResolver({
      zodSchema: otpSchema,
      errorMessages: {
        otpCode: {
          label: 'header.profile.tabs.update.form.otpCode.label',
        },
      },
    }),
  });

  const handleSubmit = async (formValues: OtpFormValues) => {
    const { isVerified } = await onSubmit(formValues);

    if (!isVerified) {
      form.setError('otpCode', {
        message: t('header.profile.tabs.update.form.otpCode.errorInvalidCode'),
        type: 'server',
      });
    }
  };

  return (
    <FormProvider<OtpFormValues> {...form}>
      <Grid
        as="form"
        id={formId}
        gap="space8"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <Text fontSize="small">
          {t('header.profile.tabs.update.pendingPhoneNumber.description', {
            newPhoneNumber: formatPhoneNumber(newPhoneNumber),
          })}
          <Link
            as="button"
            style={{ marginLeft: space.space16 }}
            onClick={async () => {
              onResendOtpCode();
              success(
                t(
                  'header.profile.tabs.update.pendingPhoneNumber.resendConfirmMessage'
                )
              );
            }}
          >
            {t('header.profile.tabs.update.pendingPhoneNumber.resend')}
          </Link>
        </Text>
        <HookFormTextField<OtpFormValues>
          autoFocus
          name="otpCode"
          label={t('header.profile.tabs.update.form.otpCode.label')}
        />
      </Grid>
    </FormProvider>
  );
};
