import { KebabMenu } from 'components/KebabMenu/KebabMenu';
import { MenuItem } from 'components/Menu/CostCenterKebabMenu/CostCenterKebabMenu';
import { useExportBookingKeysGenericMutation } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useOtherIntegration } from 'orgConfig/other';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { useSap } from '../../../orgConfig/sap';

export const TaxCodesKebabMenu = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TAX_CODES);

  const organizationSlug = useOrganizationId();

  const history = useHistory();

  const { isActiveIntegration: shouldUseSapTaxCodes } = useSap();
  const [exportBookingKeys] = useExportBookingKeysGenericMutation();

  const { shouldUseCoreDataApiTaxCodes } = useOtherIntegration();

  const items: MenuItem[] = [
    {
      id: 'history',
      label: t('contextMenu.history'),
      showSeparator: true,
      showMenuItem: shouldUseCoreDataApiTaxCodes,
      onAction: () => {
        history.push(
          `/${organizationSlug}${Routes.SETTINGS}${Routes.TAX_CODE_IMPORT_HISTORY}`
        );
      },
    },
    {
      id: 'export',
      label: t('contextMenu.exportGeneric'),
      showMenuItem: !shouldUseSapTaxCodes,
      onAction: async () => {
        const response = await exportBookingKeys();
        if (response.data?.exportBookingKeysGeneric.url)
          window.open(response.data.exportBookingKeysGeneric.url);
      },
    },
  ];

  return <KebabMenu items={items} label={t('contextMenu.tooltip')} />;
};
