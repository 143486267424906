import {
  searchScopeParameter,
  searchTypeParameter,
} from 'components/Table/consts';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { DEFAULT_SEARCH_TYPE } from './SearchTypeSelect';
import { DEFAULT_SEARCH_SCOPE } from './SearchScopeSelect';
import { useEcm } from 'orgConfig/ecm/useEcm';

export function useSearchInput() {
  const [searchParams] = useSearchParams();
  const { showSearchOverlay } = useEcm();

  if (!showSearchOverlay) {
    return {
      searchType: DEFAULT_SEARCH_TYPE,
      searchScope: DEFAULT_SEARCH_SCOPE,
      searchInputVariable: undefined,
    };
  }

  const searchType =
    searchParams.get(searchTypeParameter) || DEFAULT_SEARCH_TYPE;

  const searchScope =
    searchParams.get(searchScopeParameter) || DEFAULT_SEARCH_SCOPE;

  return {
    searchType,
    searchScope,
    searchInputVariable: {
      searchType,
      searchScope,
    },
  };
}
