import React from 'react';
import { AriaRadioGroupProps, useRadioGroup } from 'react-aria';
import { RadioGroupState, useRadioGroupState } from 'react-stately';
import { Box } from '../../Atoms/Box';
import { Flex } from '../../Atoms/Flex';
import { useTheme } from '../../Theme';
import { LayoutProps, StandardHTMLAttributes } from '../../types';

export const RadioGroupContext = React.createContext<RadioGroupState | null>(
  null
);

export interface RadioGroupProps
  extends LayoutProps,
    Omit<
      StandardHTMLAttributes<HTMLDivElement>,
      'onChange' | 'defaultValue' | 'onBlur' | 'onFocus'
    >,
    AriaRadioGroupProps {}

/**
 * The Radio Group component allow users to select a single item from a list of mutually exclusive options.
 *
 * @param {'horizontal' | 'vertical'} [orientation=vertical] The axis the Radio Button(s) should align with
 * @param {string} [value] The current value (controlled)
 * @param {string} [defaultValue] The default value (uncontrolled)
 * @param {(value: T) => void} [onChange] Handler that is called when the value changes
 * @param {string} [isReadOnly] Puts all items of the group in read only state
 * @param {string} [isDisabled] Puts all items of the group in disabled state
 *
 * @see [Storybook](https://candisio.github.io/design-system/?path=/docs/molecules-forms-radiogroup)
 */

export const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  (props, ref) => {
    const { children, label, orientation = 'vertical' } = props;
    const { colors } = useTheme();
    const state = useRadioGroupState(props);
    const { radioGroupProps, labelProps } = useRadioGroup(props, state);

    return (
      <Flex
        {...radioGroupProps}
        alignItems="flex-start"
        direction="column"
        gap="space16"
        color={colors.gray800}
        ref={ref}
      >
        {label && (
          <Box as="label" {...labelProps}>
            {label}
          </Box>
        )}
        <RadioGroupContext.Provider value={state}>
          <Flex
            alignItems="flex-start"
            wrap="wrap"
            gap="space16"
            direction={orientation === 'horizontal' ? 'row' : 'column'}
          >
            {children}
          </Flex>
        </RadioGroupContext.Provider>
      </Flex>
    );
  }
);
