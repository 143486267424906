import { Text, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const AmountCell = ({
  formattedAmount,
  color = 'gray800',
}: {
  formattedAmount: string | null;
  color?: string;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);

  if (!formattedAmount) {
    return (
      <Text color={color} style={{ float: 'right' }}>
        {t('emptyCellPlaceholder')}
      </Text>
    );
  }

  return (
    <TruncatedText
      wordBreak="break-all"
      color={color}
      style={{ textAlign: 'end', marginLeft: 'auto' }}
    >
      {formattedAmount}
    </TruncatedText>
  );
};

// https://candis.atlassian.net/browse/REB-182
// https://candis.atlassian.net/browse/TG-3482
// this is a workaround to style the amount column properly in the index.html
// if you change this property make sure that index.html is also updated
export const amountCellProps = {
  width: '9.99rem',
};
