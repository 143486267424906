import { Color as TagColor } from '@candisio/design-system';
import { UpdateUserInput } from 'generated-types/resolvers-types';
import {
  CreateUserInput,
  UserRole,
} from '../../../generated-types/graphql.types';
import { RoutePath } from '../../../utils/path_generator';
import { TeamMemberFormOutput } from './TeamMemberDetails/containers/teamMemberFormSchema';
import { RouteParams } from './types';

type PathProps = {
  organizationSlug: string;
  path: string;
  url: string;
  userId?: string;
};

export const generateTeamMembersPath = ({
  organizationSlug,
  path,
  url,
  userId,
}: PathProps) => {
  const pathPattern = new RoutePath<RouteParams>(path);

  return pathPattern.stringify(
    {
      organizationSlug,
      userId,
    },
    url
  );
};

export const getUserInfo = ({
  firstName,
  lastName,
  email,
}: Pick<TeamMemberFormOutput, 'firstName' | 'lastName' | 'email'>) => {
  return `${firstName} ${lastName} (${email})`;
};

export const getAvailableRoles = ({
  isCreditCardsInUse,
}: {
  isCreditCardsInUse: boolean;
}) => {
  const roles = [UserRole.Admin, UserRole.Approver, UserRole.Requester];

  if (isCreditCardsInUse) {
    roles.push(UserRole.CardManager);
  }

  return roles;
};

export const sanitizeInviteFormData = (
  formValues: TeamMemberFormOutput
): CreateUserInput => {
  const roleIds = formValues.roles.map(role => role.id);

  const teamIds = formValues.teams ?? [];

  return {
    email: formValues.email,
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    locale: formValues.locale,
    roles: [],
    roleIds,
    ...(teamIds.length > 0 ? { teamIds } : {}),
  };
};

export const sanitizeUpdateFormData = (
  formValues: Omit<TeamMemberFormOutput, 'email'>
): UpdateUserInput => {
  const roleIds = formValues.roles.map(role => role.id);

  return {
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    locale: formValues.locale,
    roles: [],
    roleIds,
  };
};
