import { Text, Link, CustomEmptyStateProps } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentsWithoutTransactionEmptyStateLayout } from './DocumentsWithoutTransactionEmptyStateLayout';

interface DocumentsWithoutTransactionEmptyStateProps
  extends CustomEmptyStateProps {
  isDocumentSelected: boolean;
  isTableEmpty: boolean;
  isTableFiltered: boolean;
}

const TableIsEmpty = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Text textAlign="center">
      {t('transactionAssociation.documentsModal.emptyState.isEmpty')}
    </Text>
  );
};

const TableIsFilteredAndEmpty = ({
  resetFilters,
}: {
  resetFilters: () => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <>
      <Text textAlign="center" whiteSpace="pre-wrap">
        {t(
          'transactionAssociation.documentsModal.emptyState.isFilteredAndEmpty'
        )}
      </Text>
      <Link as="button" onClick={() => resetFilters()}>
        {t('transactionAssociation.documentsModal.emptyState.resetFilter')}
      </Link>
    </>
  );
};

const DocumentIsSelectedTableIsEmpty = ({
  resetFilters,
}: {
  resetFilters: () => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <>
      <Text textAlign="center" whiteSpace="pre-wrap">
        {t(
          'transactionAssociation.documentsModal.emptyState.isFilteredAndEmptyAndDocumentsAreSelected'
        )}
      </Text>
      <Link as="button" onClick={() => resetFilters()}>
        {t('transactionAssociation.documentsModal.emptyState.resetFilter')}
      </Link>
    </>
  );
};

export const DocumentsWithoutTransactionEmptyState = ({
  isDocumentSelected,
  isTableEmpty,
  isTableFiltered,
  resetFilters,
}: DocumentsWithoutTransactionEmptyStateProps) => {
  const hasNoDocuments = isTableEmpty && !isTableFiltered;
  const isFilteredAndEmpty =
    isTableEmpty && isTableFiltered && !isDocumentSelected;

  return (
    <DocumentsWithoutTransactionEmptyStateLayout
      isTableFiltered={isTableFiltered}
    >
      {hasNoDocuments ? (
        <TableIsEmpty />
      ) : isFilteredAndEmpty ? (
        <TableIsFilteredAndEmpty resetFilters={resetFilters} />
      ) : (
        <DocumentIsSelectedTableIsEmpty resetFilters={resetFilters} />
      )}
    </DocumentsWithoutTransactionEmptyStateLayout>
  );
};
