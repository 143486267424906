import { Box, Button, Card, Grid } from '@candisio/design-system';
import { AccountingInfoFormFields } from 'containers/credit-cards/CreateCreditCard/AccountingInfoStep/AccountingInfoFormFields';
import {
  AccountingInfoFormSchemaFormValues,
  accountingInfoFormSchema,
} from 'containers/credit-cards/CreateCreditCard/AccountingInfoStep/utils/accountingInfoFormSchema';
import { getAccountingInfoFormErrorMessages } from 'containers/credit-cards/CreateCreditCard/AccountingInfoStep/utils/getAccountingInfoFormErrorMessages';
import { AccountingInfoFieldOptions } from 'containers/credit-cards/CreateCreditCard/AccountingInfoStep/utils/types';
import { CardStatus } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { AccountingFormHeader } from './AccountingFormHeader';
import { NoInvoiceNeededOnCardCheckBox } from './NoInvoiceNeededOnCardCheckBox';

interface UpdateAccountingInfoFormProps {
  isSubmitting: boolean;
  cardStatus: CardStatus;
  hasAccountingData: boolean;
  hasPendingLimitRequest: boolean;
  isNewCardRequest: boolean;
  fieldOptions: AccountingInfoFieldOptions;
  defaultValues: AccountingInfoFormSchemaFormValues;
  onClose: () => void;
  onSubmit: (values: AccountingInfoFormSchemaFormValues) => void;
}

export const UpdateAccountingInfoForm = ({
  isSubmitting,
  cardStatus,
  hasAccountingData,
  hasPendingLimitRequest,
  isNewCardRequest,
  defaultValues,
  fieldOptions,
  onClose,
  onSubmit,
}: UpdateAccountingInfoFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isCardManager } = useUserRoles();

  const [creditCardsInvoiceNotNeededOnCard] = useCandisFeatureFlags([
    FEATURE_FLAGS.creditCardsInvoiceNotNeededOnCard,
  ]);

  const form = useForm<AccountingInfoFormSchemaFormValues>({
    defaultValues,
    values: defaultValues,
    resolver: zodResolver({
      errorMessages: getAccountingInfoFormErrorMessages(),
      zodSchema: accountingInfoFormSchema,
    }),
    resetOptions: {
      keepDirty: false,
    },
  });

  const canUserEditFieldValues =
    isCardManager &&
    [
      CardStatus.Active,
      CardStatus.Locked,
      CardStatus.Pending,
      CardStatus.LockedPin,
    ].includes(cardStatus);

  const isReadOnly = !canUserEditFieldValues || hasPendingLimitRequest;

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        paddingY="space16"
        paddingX="space24"
        overflow="hidden"
        onSubmit={form.handleSubmit(onSubmit)}
        templateRows="auto 1fr auto"
      >
        <AccountingFormHeader
          onClick={onClose}
          hasAccountingData={hasAccountingData}
          hasPendingLimitRequest={hasPendingLimitRequest}
          isNewCardRequest={isNewCardRequest}
        />
        <Box overflowY="auto">
          <Card background="gray100" borderTopRadius="none">
            <Grid gap="space16">
              {creditCardsInvoiceNotNeededOnCard && (
                <NoInvoiceNeededOnCardCheckBox
                  name="noInvoiceNeeded"
                  readOnly={isReadOnly}
                />
              )}
              <AccountingInfoFormFields
                readOnly={isReadOnly}
                fieldOptions={fieldOptions}
              />
            </Grid>
          </Card>
        </Box>
        {!isReadOnly && (
          <Box paddingTop="space16">
            <Button
              type="submit"
              justifySelf="start"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {t('creditCardFormDataPreview.save')}
            </Button>
          </Box>
        )}
      </Grid>
    </FormProvider>
  );
};
