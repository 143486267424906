import { useTheme } from '@candisio/design-system';
import { useCountOpenApprovalsQuery } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import approvalsIcon from '../../images/approval-icon.svg';
import { MenuItemCount, MenuItemIcon } from '../../style';

export const Approvals = () => {
  const [t] = useTranslation();
  const { fontSizes } = useTheme();
  const {
    data: {
      countOpenApprovals: { count = null } = {},
    } = {},
    loading,
  } = useCountOpenApprovalsQuery({ fetchPolicy: 'cache-first' });

  const isCountHidden = loading || !Boolean(count);

  return (
    <>
      <MenuItemCount
        aria-label="Approvals-menu-item-count"
        style={{
          fontSize: isCountHidden ? 0 : fontSizes.xsmall,
          padding: isCountHidden ? 0 : '0.05rem 0.35rem',
        }}
      >
        {count}
      </MenuItemCount>
      <MenuItemIcon>
        <img src={approvalsIcon} alt={t('navigation.approvals')} />
      </MenuItemIcon>
    </>
  );
};
