import { Card, Grid, Text, Flex, Checkbox } from '@candisio/design-system';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportContext } from '../../../../Context';
import { ExportType, DocumentType } from '../../../../types';
import { formatCountByType } from '../utils';

export const ApprovalsSummary = ({
  readyToExportEntitiesCount,
  excludeTransactionsEnabled,
}: {
  readyToExportEntitiesCount: Record<DocumentType, number>;
  excludeTransactionsEnabled?: boolean;
}) => {
  const {
    exportType,
    includeEntitiesWithoutDoc,
    setIncludeEntitiesWithoutDoc,
  } = useContext(ExportContext);

  const isBdsExport = exportType === ExportType.DATEV_BDS;
  const [t] = useTranslation();

  const totalExportEntitiesCount =
    (readyToExportEntitiesCount.DOCUMENT ?? 0) +
    (readyToExportEntitiesCount.CARD_TRANSACTION ?? 0) +
    (readyToExportEntitiesCount.CARD_SETTLEMENT ?? 0);

  return totalExportEntitiesCount ? (
    <Card
      boxShadow="noShadow"
      width="100%"
      paddingX="space16"
      paddingY="space12"
      background="gray100"
    >
      <Grid gap="space4">
        <Text color="gray700" fontSize="xsmall">
          {isBdsExport
            ? t('export.provisionsSummary.bookingsBDSTitle')
            : t('export.provisionsSummary.bookingsTitle')}
        </Text>
        <Text fontSize="basic">
          {isBdsExport
            ? t('export.provisionsSummary.bookingsBDSCount', {
                count: totalExportEntitiesCount,
              })
            : t('export.provisionsSummary.bookingsCount', {
                count: totalExportEntitiesCount,
              })}
        </Text>
        {readyToExportEntitiesCount && (
          <Text fontSize="xsmall" color="gray700">
            {formatCountByType({
              documentsCount: readyToExportEntitiesCount.DOCUMENT,
              transactionsCount: readyToExportEntitiesCount.CARD_TRANSACTION,
              settlementsCount: readyToExportEntitiesCount.CARD_SETTLEMENT,
              t,
            })}
          </Text>
        )}
        {excludeTransactionsEnabled && (
          <Flex alignItems="center" paddingTop="space8">
            <Checkbox
              isSelected={includeEntitiesWithoutDoc}
              onChange={selected => setIncludeEntitiesWithoutDoc(selected)}
            >
              <Text>
                {t(
                  'export.exportDetails.summaryContent.includeAllEntitiesWithoutDocs'
                )}
              </Text>
            </Checkbox>
          </Flex>
        )}
      </Grid>
    </Card>
  ) : null;
};

export const ApprovalInvoicesSummary = ({ count }: { count: number }) => {
  const [t] = useTranslation();

  return (
    <Card
      boxShadow="noShadow"
      width="100%"
      paddingX="space16"
      paddingY="space12"
      background="gray100"
    >
      <Grid gap="space4">
        <Text color="gray700" fontSize="xsmall">
          {t('export.provisionsSummary.bookingsTitle')}
        </Text>
        <Text fontSize="basic">
          {t('export.provisionsSummary.bookingsCount', {
            count,
          })}
        </Text>
        <Text fontSize="xsmall" color="gray700">
          {formatCountByType({
            documentsCount: count,
            transactionsCount: 0,
            settlementsCount: 0,
            t,
          })}
        </Text>
      </Grid>
    </Card>
  );
};
