import { Box, Button, Drawer, Flex, Heading } from '@candisio/design-system';
import { CreateCreditCardModal } from 'containers/credit-cards/CreateCreditCard/CreateCreditCardModal';
import { CreditCardDrawer } from 'containers/credit-cards/CreditCardDrawer';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { isNil } from 'lodash';
import { Routes } from 'models';
import { CreditCardsProvider } from 'providers/CreditCardsProvider/CreditCardsProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { orgSettingsParam } from '../CreditCardsInsights/OrganizationSettingsDrawer/OrganizationSettingsDrawer';
import { CreditCardsTableContainer } from './CreditCardsTableContainer';

export const creditCardSearchParamKey: 'onCreditCardsView' =
  'onCreditCardsView';

export const CardholderOverview = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { searchParams } = useMutateSearchParams();

  const navigate = useNavigate();

  const { creditCardId, organizationSlug } = useParams<{
    creditCardId: string;
    organizationSlug: string;
  }>();

  const closeDrawer = () => {
    navigate(
      { pathname: '..', search: searchParams.toString() },
      { relative: 'path' }
    );
  };

  const openIssueNewCardDrawer = () => {
    const existingSearchParams = new URLSearchParams(location.search);
    existingSearchParams.append(creditCardSearchParamKey, 'true');
    navigate({
      pathname: `/${organizationSlug}${Routes.CREDIT_CARDS_CARDHOLDER_OVERVIEW}/request`,
      search: existingSearchParams.toString(),
    });
  };

  const isDrawerOpen =
    !isNil(creditCardId) &&
    creditCardId !== 'request' &&
    creditCardId !== orgSettingsParam;

  return (
    <CreditCardsProvider>
      <Box height="100%" overflow="hidden">
        <Flex
          overflow="hidden"
          height="100%"
          gap="space24"
          direction="column"
          paddingX="space32"
          paddingTop="space20"
          paddingBottom="space8"
        >
          <Flex direction="column" gap="space16">
            <Flex justifyContent="space-between">
              <Heading as="h1">{t('creditCardPage.title')}</Heading>
              <Flex gap="space24" alignItems="center">
                <Button alignSelf="center" onClick={openIssueNewCardDrawer}>
                  {t('transactions:carousel.requestNewCard')}
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <CreditCardsTableContainer />
        </Flex>
      </Box>
      <>
        {creditCardId === 'request' ? (
          <CreateCreditCardModal
            isOpen={creditCardId === 'request'}
            onClose={closeDrawer}
          />
        ) : undefined}
        <Drawer width="40.5rem" isOpen={isDrawerOpen} onClose={closeDrawer}>
          <CreditCardDrawer cardId={creditCardId} onClose={closeDrawer} />
        </Drawer>
      </>
    </CreditCardsProvider>
  );
};
