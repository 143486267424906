import { FilterOption } from '@candisio/design-system';
import { ArtistSocialInsuranceCode } from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { useArtistSocialInsuranceCodeTranslationsMap } from './useArtistSocialInsuranceCodeTranslationsMap';

const ArtistSocialInsuranceCodes = Object.values(ArtistSocialInsuranceCode);

export const useArtistSocialInsuranceCodeFilters = () => {
  const translationMap = useArtistSocialInsuranceCodeTranslationsMap();

  const artistSocialInsuranceCodeFilters = useMemo(
    () => ({
      data: Array<FilterOption>().concat(
        ArtistSocialInsuranceCodes.map(code => ({
          id: code,
          label: translationMap[code],
        }))
      ),
    }),
    [translationMap]
  );

  return artistSocialInsuranceCodeFilters;
};
