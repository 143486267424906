import { Box, Button, Flex, Grid } from '@candisio/design-system';
import { usePromotionsContext } from 'components/NewPromotions/context/PromotionsProvider';
import {
  animationVariants,
  transition,
} from 'components/NewPromotions/toolkit/animation';
import { PromotionCardWrapper } from 'components/NewPromotions/toolkit/PromotionCardWrapper';
import { Promotion, PromotionDetail } from 'components/NewPromotions/types';
import { motion } from 'framer-motion';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { PromotionContent } from './PromotionContent/PromotionContent';
import { PromotionCTA } from './PromotionCTA/PromotionCTA';
import { PromotionHeader } from './PromotionHeader/PromotionHeader';
import { PromotionImages } from './PromotionImages/PromotionImages';
import { ReactNode } from 'react';

export type PromotionCardProps = {
  promotion: Promotion;
  closable?: boolean;
  customModalContent?: ReactNode;
  customPromotionDetails?: PromotionDetail;
};

const MotionGrid = motion(Grid);
export const PromotionCard = ({
  promotion,
  closable = true,
  customModalContent,
  customPromotionDetails,
}: PromotionCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);
  const { hidePromotionCard } = usePromotionsContext();

  const onClickCloseIcon = () => {
    hidePromotionCard?.(promotion?.id);
  };

  return (
    <PromotionCardWrapper dataCy={`promotion-${promotion.id}`}>
      <MotionGrid
        transition={transition}
        variants={animationVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        alignContent="space-between"
        color="gray800"
        lineHeight="space32"
        paddingY="space20"
        background="gray0"
      >
        <Flex direction="column" gap="space24">
          <PromotionHeader promotion={promotion} />
          <PromotionContent promotion={promotion} />
        </Flex>

        <Flex direction="column" paddingX="space20">
          <PromotionCTA
            promotion={promotion}
            customModalContent={customModalContent}
            customPromotionDetails={customPromotionDetails}
          />
        </Flex>
      </MotionGrid>
      <Grid>
        <PromotionImages promotion={promotion} />
      </Grid>
      {closable && (
        <Box
          position="absolute"
          top="0"
          right="0"
          padding="10px"
          opacity="0.5"
          zIndex="3"
        >
          <Button
            icon="close"
            variant="tertiary"
            color="gray"
            onClick={onClickCloseIcon}
            label={t('closeButton.tooltip')}
          />
        </Box>
      )}
    </PromotionCardWrapper>
  );
};
