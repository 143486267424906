import { Drawer, Flex, Paragraph } from '@candisio/design-system';
import { CardStatus } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { isNil } from 'lodash';
import { Routes } from 'models';
import { CreditCardsProvider } from 'providers/CreditCardsProvider/CreditCardsProvider';
import { useCreditCards } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { Match } from 'router/Match';
import { usePath } from 'utils/hooks';
import { CreateCreditCardModal } from '../CreateCreditCard/CreateCreditCardModal';
import { CreditCardCarouselContainer } from '../CreditCardCarousel/CreditCardCarousel';
import { CreditCardDrawer } from '../CreditCardDrawer';
import { CreditCardRequestViewLoader } from '../components/CreditCardsSection';
import { getVariantMap } from '../components/CreditCardsSection/utils';

interface WalletProps {
  userName: string | undefined;
}

export const cardStatusesForWallet = [
  CardStatus.Active,
  CardStatus.Locked,
  CardStatus.LockedPin,
  CardStatus.Pending,
  CardStatus.Requested,
  CardStatus.Processing,
];

export const Wallet = ({ userName }: WalletProps) => {
  const { pathGenerator } = usePath<{ creditCardId?: string }>();
  const { creditCardId } = useParams<{ creditCardId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin } = useUserRoles();
  const organizationSlug = useOrganizationId();
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { currentCardHolderCards: cards, loading } = useCreditCards();

  const activeStateWithNoCards = getVariantMap({
    userName: userName || '',
    isWalletEnabled: true,
    isAdmin,
  }).ACTIVE;

  if (loading) {
    return <CreditCardRequestViewLoader />;
  }

  const closeDrawer = () => {
    navigate({
      pathname: `/${organizationSlug}${Routes.DASHBOARD}`,
      search: location.search,
    });
  };

  const handleCardTermination = () =>
    navigate({
      pathname: `/${organizationSlug}${Routes.DASHBOARD}`,
    });

  return (
    <CreditCardsProvider>
      {cards.length === 0 ? (
        <Flex direction="column" gap="space8">
          {activeStateWithNoCards}
          <Paragraph color="gray600">
            {t('dashboard.drawer.form.footerText')}
          </Paragraph>
        </Flex>
      ) : (
        <CreditCardCarouselContainer />
      )}
      <Match<{ creditCardId?: string }>
        paths={[
          pathGenerator.stringify({ creditCardId: 'request' }),
          pathGenerator.stringify({ creditCardId: ':creditCardId' }),
        ]}
      >
        {() => (
          <>
            {creditCardId === 'request' ? (
              <CreateCreditCardModal
                isOpen={creditCardId === 'request'}
                onClose={closeDrawer}
              />
            ) : undefined}
            <Drawer
              width="40.5rem"
              onClose={closeDrawer}
              isOpen={!isNil(creditCardId) && creditCardId !== 'request'}
            >
              <CreditCardDrawer
                cardId={creditCardId}
                onClose={closeDrawer}
                onCardTermination={handleCardTermination}
              />
            </Drawer>
          </>
        )}
      </Match>
    </CreditCardsProvider>
  );
};
