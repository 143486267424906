import { Flex, Grid, Link, Text } from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useNumberFormat } from 'utils/useNumberFormat';
import { ProcessingFormFieldMetadata } from './ProcessingFormMetadataContext';

const CONFIDENCE_LEVELS = {
  SAFE: { threshold: 1, color: 'green500' },
  RELIABLE: { threshold: 0.9, color: 'green500' },
  UNRELIABLE: { threshold: 0, color: 'yellow500' },
} as const;

const getConfidenceLevel = (confidence: number) => {
  if (confidence >= CONFIDENCE_LEVELS.SAFE.threshold) return 'SAFE';
  if (confidence >= CONFIDENCE_LEVELS.RELIABLE.threshold) return 'RELIABLE';

  return 'UNRELIABLE';
};

/** Tooltip message describing the metadata associated with the field */
export const ProcessingFormFieldMetadataMessage = ({
  confidence,
  source,
}: ProcessingFormFieldMetadata) => {
  const [
    allowProcessingOfZugferdEinvoicesFF,
    allowProcessingOfXRechnungEinvoicesFF,
  ] = useCandisFeatureFlags([
    FEATURE_FLAGS.allowProcessingOfZugferdEinvoices,
    FEATURE_FLAGS.allowProcessingOfXrechnungEinvoices,
  ]);

  return allowProcessingOfZugferdEinvoicesFF ||
    allowProcessingOfXRechnungEinvoicesFF ? (
    <ProcessingFormFieldMetadataMessageWithCertainty
      confidence={confidence}
      source={source}
    />
  ) : (
    // TODO: Remove this block once allowProcessingOfZugferdEinvoicesFF and
    // allowProcessingOfXRechnungEinvoicesFF feature flags are removed
    <ProcessingFormFieldMetadataMessageWithScores
      confidence={confidence}
      source={source}
    />
  );
};

/** Tooltip message describing the metadata associated with the field */
export const ProcessingFormFieldMetadataMessageWithCertainty = ({
  confidence,
  source,
}: ProcessingFormFieldMetadata) => {
  const [t] = useTranslation();
  const confidenceLevel = getConfidenceLevel(confidence);

  const extractionType = t(
    `document.requestApproval.fieldRecognition.${source}.source.type`
  );

  return (
    <Grid fontSize="small">
      <Text fontWeight="bold">
        {t(`document.requestApproval.fieldRecognition.${source}.title`)}
      </Text>
      <Flex gap="space2">
        <Trans
          i18nKey={`document.requestApproval.confidenceScore.info.${confidenceLevel.toLowerCase()}`}
          values={{ source: extractionType }}
        >
          <Text color={CONFIDENCE_LEVELS[confidenceLevel].color}>
            {confidenceLevel.toLowerCase()}ly recognized
          </Text>
          <Link
            external
            href={t(
              `document.requestApproval.fieldRecognition.${source}.source.infoUrl`
            )}
          >
            {extractionType}
          </Link>
        </Trans>
      </Flex>
    </Grid>
  );
};

// TODO: Remove ProcessingFormFieldMetadataMessageWithScores once allowProcessingOfZugferdEinvoicesFF and
// allowProcessingOfXRechnungEinvoicesFF feature flags are removed
/** Tooltip message describing the metadata associated with the field */
export const ProcessingFormFieldMetadataMessageWithScores = ({
  confidence,
  source,
}: ProcessingFormFieldMetadata) => {
  const [t] = useTranslation();
  const percentFormatter = useNumberFormat({ style: 'percent' });

  return (
    <Grid fontSize="small">
      <Text fontWeight="bold">
        {t(`document.requestApproval.fieldRecognition.${source}.title`)}
      </Text>
      <Text>
        {t(`document.requestApproval.fieldRecognition.${source}.confidence`, {
          confidence: percentFormatter.format(confidence),
        })}
      </Text>
      <Text>
        {t(
          `document.requestApproval.fieldRecognition.${source}.source.message`
        )}{' '}
        <Link
          external
          href={t(
            `document.requestApproval.fieldRecognition.${source}.source.infoUrl`
          )}
        >
          {t(`document.requestApproval.fieldRecognition.${source}.source.type`)}
        </Link>
      </Text>
    </Grid>
  );
};
