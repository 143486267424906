import { Box, Grid, GridProps, Link, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';

export interface PaymentTableFooterProps extends GridProps {}

export const PaymentTableFooter = (props: PaymentTableFooterProps) => {
  const [tPayments] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  const organization = useFullOrganization();
  const startDate = organization?.payment.startDate ?? null;

  return (
    <Grid
      gap="space8"
      justifyContent="center"
      padding="space16"
      textAlign="center"
      {...props}
    >
      <Box background="white" borderRadius="full" color="gray500">
        <Trans t={tPayments} i18nKey="table.invoiceNotFoundWarning.warning">
          Findest du deine zu zahlende Rechnung hier nicht?
          <Link
            href={tPayments('table.invoiceNotFoundWarning.helpUrl')}
            external
          >
            Erfahre warum
          </Link>
        </Trans>
      </Box>
      {startDate !== null && (
        <Text fontSize="xsmall" color="gray500">
          {tPayments('table.footer.activationDetails', {
            date: DateFormatters.compact(new Date(startDate)),
          })}
        </Text>
      )}
    </Grid>
  );
};
