import { CSSObject } from '@emotion/react';
import React, { ReactNode, useState } from 'react';
import { mergeProps } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import {
  FieldContainer,
  FieldContainerProps,
  Variant,
  useFieldContext,
} from '../../Atoms/FieldContainer';
import { ClearButton } from '../../Atoms/FieldContainer/ClearButton';
import { StatusIndicator } from '../../Atoms/FieldContainer/StatusIndicator';
import { Flex } from '../../Atoms/Flex';
import { Grid } from '../../Atoms/Grid';
import { useTheme } from '../../Theme';
import { StandardInputHTMLAttributes } from '../../types';

export interface TextFieldProps extends FieldContainerProps {
  input?: StandardInputHTMLAttributes<HTMLInputElement> & InputProps;
  inputRef?: React.Ref<HTMLInputElement>;
  message?: ReactNode;
  variant?: Variant;
  isLoading?: boolean | undefined;
  /** Show message on focus */
  showMessageOnFocus?: boolean;
}

/**
 * An input field for entering a single line of unformatted text.
 *
 * @param {StandardInputHTMLAttributes<HTMLInputElement>} input To pass props such as value and onChange
 * @param {React.Ref<HTMLInputElement>} inputRef Ref for TextField.Input
 *
 * @see [Storybook](https://candisio.github.io/design-system/?path=/docs/molecules-forms-textfield)
 */
export const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      children,
      input: inputProps = {},
      inputRef,
      isLoading,
      showMessageOnFocus = false,
      ...restProps
    },
    ref
  ) => {
    return (
      <FieldContainer ref={ref} isLoading={isLoading} {...restProps}>
        <Input
          {...inputProps}
          ref={inputRef}
          showMessageOnFocus={showMessageOnFocus}
        />
        {children}
      </FieldContainer>
    );
  }
);

const inputStyle: CSSObject = {
  display: 'block',
  boxSizing: 'border-box',
  width: '100%',
  height: 'auto',
  border: 'none',
  padding: 0,
  background: 'transparent',
  '&:focus': {
    outline: 'none',
  },
};

const ellipsisStyle: CSSObject = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

const ignorePasswordManagerProps = {
  autoComplete: 'off',
  // 1Password
  'data-1p-ignore': true,
  // Bitwarden
  'data-bwignore': true,
  // Lastpass
  'data-lpignore': true,
  // Dashlane
  'data-form-type': 'other',
};

interface InputProps extends StandardInputHTMLAttributes<HTMLInputElement> {
  message?: ReactNode;
  variant?: Variant;
  clearable?: boolean;
  clearLabel?: string;
  onClear?: () => void;
  /** Show message on focus */
  showMessageOnFocus?: boolean;
  ignorePasswordManager?: boolean;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      disabled,
      readOnly,
      message,
      variant,
      clearable,
      clearLabel,
      onClear,
      showMessageOnFocus,
      ignorePasswordManager = true,
      ...restProps
    },
    forwardedRef
  ) => {
    const { fontFamilies, fontSizes, textField } = useTheme();

    const { inputRef, inputProps } = useFieldContext();
    const isDisabled = inputProps.disabled || disabled;
    const isReadOnly = inputProps.readOnly || readOnly;
    const [inputFocused, setInputFocused] = useState(false);

    const customInputProps = {
      ...inputProps,
      onFocus: () => setInputFocused(true),
      onBlur: () => setInputFocused(false),
    };

    const showClearButton = clearable && !isDisabled && !isReadOnly;
    const showStatus = !isDisabled && (message || variant);

    const showFooter = showClearButton || showStatus;

    return (
      <Grid gap={showFooter ? 'space8' : 'space0'} templateColumns="1fr auto">
        <input
          css={{
            ...inputStyle,
            ...ellipsisStyle,
            fontFamily: fontFamilies.body,
            fontSize: fontSizes.basic,
            color: isDisabled ? textField.disabled.color : textField.color,
            fontStyle: isDisabled ? 'italic' : 'initial',
            lineHeight: textField.lineHeight,
            '&::placeholder': {
              color: textField.placeholderColor,
              fontSize: fontSizes.basic,
            },
            cursor: isDisabled || isReadOnly ? 'not-allowed' : undefined,
          }}
          type={type}
          tabIndex={isDisabled || isReadOnly ? -1 : undefined}
          {...(ignorePasswordManager && { ...ignorePasswordManagerProps })}
          ref={mergeRefs([inputRef, forwardedRef])}
          {...mergeProps(customInputProps, restProps)}
        />

        {showFooter && (
          <Flex
            gap={showClearButton && showStatus ? 'space8' : 'space0'}
            alignItems="center"
          >
            {!isDisabled && (
              <StatusIndicator
                message={message}
                // @ts-expect-error BE returns default variant, which we have no use for here
                variant={variant}
                showMessage={showMessageOnFocus && inputFocused}
              />
            )}
            {clearable && !isDisabled && !isReadOnly && (
              <ClearButton onClear={onClear} clearLabel={clearLabel} />
            )}
          </Flex>
        )}
      </Grid>
    );
  }
);

export interface TextareaProps extends TextareaAutosizeProps {
  resize?: 'horizontal' | 'vertical' | 'both' | 'none';
  message?: ReactNode;
  variant?: Variant;
  clearable?: boolean;
  clearLabel?: string;
  onClear?: () => void;
  showMessageOnFocus?: boolean;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      resize = 'none',
      message,
      variant,
      clearable,
      clearLabel,
      onClear,
      showMessageOnFocus = false,
      disabled,
      readOnly,
      ...restProps
    },
    forwardedRef
  ) => {
    const { colors, fontFamilies, fontSizes, textField } = useTheme();
    const { inputRef, inputProps } = useFieldContext();
    const [inputFocused, setInputFocused] = useState(false);

    const isDisabled = inputProps.disabled || disabled;
    const isReadOnly = inputProps.readOnly || readOnly;

    const baseStyle: CSSObject = {
      ...inputStyle,
      fontFamily: fontFamilies.body,
      fontSize: fontSizes.basic,
      color: colors.gray800,
      lineHeight: fontSizes.xlarge,
      '&::placeholder': {
        color: textField.placeholderColor,
        fontSize: fontSizes.basic,
      },
      /*
      Hide scrollbars but keep scrolling functionality in the case of resizing
      https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp
      */
      '::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      'scrollbar-width': 'none' /* Firefox */,
    };

    const customInputProps = {
      ...inputProps,
      onFocus: () => setInputFocused(true),
      onBlur: () => setInputFocused(false),
    };

    const showClearButton =
      clearable && !inputProps.disabled && !inputProps.readOnly;

    const showStatus = !inputProps.disabled && (message || variant);

    const showFooter = showClearButton || showStatus;

    return (
      <Grid gap={showFooter ? 'space8' : 'space0'} templateColumns="1fr auto">
        <TextareaAutosize
          tabIndex={isDisabled || isReadOnly ? -1 : undefined}
          css={[baseStyle, { resize }]}
          ref={mergeRefs([inputRef, forwardedRef])}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          {...mergeProps(restProps, customInputProps)}
        />
        {showFooter && (
          <Flex
            alignItems="center"
            gap={showClearButton && showStatus ? 'space10' : 'space0'}
          >
            {!inputProps.disabled && (
              <StatusIndicator
                message={message}
                // @ts-expect-error BE returns default variant, which we have no use for here
                variant={variant}
                showMessage={showMessageOnFocus && inputFocused}
              />
            )}
            {clearable && !inputProps.disabled && (
              <ClearButton onClear={onClear} clearLabel={clearLabel} />
            )}
          </Flex>
        )}
      </Grid>
    );
  }
);
