import {
  Button,
  ButtonProps,
  MenuButton,
  MenuItem,
} from '@candisio/design-system';
import { ReactNode } from 'react';

interface DownloadButtonProps {
  menuItems: MenuItem[];
  loading?: boolean;
  children?: ReactNode;
}

const commonButtonProps = {
  color: 'gray',
  variant: 'tertiary',
  size: 'small',
} satisfies ButtonProps;

export const DownloadButton = ({
  menuItems,
  loading,
  children,
}: DownloadButtonProps) => {
  const hasSingleAction = menuItems.length === 1;

  if (hasSingleAction) {
    return (
      <Button
        loading={loading}
        onClick={menuItems[0].onAction}
        {...commonButtonProps}
      >
        {children}
      </Button>
    );
  }

  return (
    <MenuButton
      loading={loading}
      {...commonButtonProps}
      items={menuItems}
      icon={undefined}
      selectionMode="single"
    >
      {children}
    </MenuButton>
  );
};
