import { Flex } from '@candisio/design-system';
import { InitialLoadingIndicator } from './InitialLoadingIndicator/InitialLoadingIndicator';

export const Loading = () => {
  return (
    <Flex
      alignItems="center"
      placeContent="center"
      padding="space256"
      overflow="hidden"
    >
      <InitialLoadingIndicator />
    </Flex>
  );
};
