import { Grid, InlineSkeleton, Item, ListView } from '@candisio/design-system';
import { useSumOfBookingsAmounts } from 'components/Form/ProcessingForm/hooks/useSumOfBookingsAmounts';
import { ProcessingFormSplitDocumentActionButton } from 'components/Form/ProcessingForm/ProcessingFormSplitDocumentActionButton';
import { useSap } from 'orgConfig/sap';
import { useWatch } from 'react-hook-form';
import { useDocumentFormContext } from '../../DocumentFormContext';
import { DocumentFormValues } from '../../toolkit/schema';
import { DocumentFormSplitItem } from '../components/DocumentFormSplitItem';

export interface SplitBookingsListProps {
  onSplitDocument?: (index?: number) => void;
  readOnly?: boolean;
  isLoading?: boolean | undefined;
  isGoodsPurchaseOrderLinked?: boolean;
}

export const SplitBookingsList = ({
  onSplitDocument,
  readOnly,
  isLoading,
  isGoodsPurchaseOrderLinked = false,
}: SplitBookingsListProps) => {
  const { shouldUseSapNetAmount } = useSap();
  const [grossAmount, currency, bookings] =
    useWatch<DocumentFormValues, ['grossAmount', 'currency', 'bookings']>({
      name: ['grossAmount', 'currency', 'bookings'],
    }) ?? [];

  const bookingsFieldArray = useDocumentFormContext();

  const splits = bookingsFieldArray?.fields ?? [];
  const showSplit =
    (isGoodsPurchaseOrderLinked && splits.length > 0) || splits.length > 1;

  /** make sure to use bookings from the watch hook instead of the context
   * to avoid data inconsistency issues */
  const sumOfBookingsAmounts = useSumOfBookingsAmounts({ bookings });
  const remainingAmount = grossAmount - sumOfBookingsAmounts;
  const hasItems = splits.length > 1;

  return (
    <InlineSkeleton isLoading={isLoading} isBlockLevel>
      <Grid
        borderRadius="medium"
        overflow="hidden"
        data-cy="document-split-list"
      >
        {showSplit && (
          <ListView
            onAction={key => {
              onSplitDocument?.(Number(key));
            }}
          >
            {splits.map(({ id }, index) => (
              <Item
                key={String(index)} // passed to onAction
                textValue={id}
              >
                <DocumentFormSplitItem
                  key={id}
                  name={`bookings.${index}`}
                  number={index + 1}
                  onView={() => {
                    onSplitDocument?.(index);
                  }}
                  hasRemainingAmount={Boolean(remainingAmount)}
                  isGoodsPurchaseOrderLinked={isGoodsPurchaseOrderLinked}
                />
              </Item>
            ))}
          </ListView>
        )}
        <ProcessingFormSplitDocumentActionButton
          currency={currency}
          hasItems={hasItems}
          readOnly={readOnly}
          remainingAmount={remainingAmount}
          onSplitDocument={onSplitDocument}
          isSapNetAmount={shouldUseSapNetAmount}
        />
      </Grid>
    </InlineSkeleton>
  );
};
