import { forwardRef } from 'react';
import { mergeProps } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { ScrollerProps } from 'react-virtuoso';
import { ScrollBox } from '../../Atoms/ScrollBox';
import { VirtuosoContext } from './types';

export const VirtuosoScroller = forwardRef<
  HTMLDivElement,
  ScrollerProps & VirtuosoContext
>(
  (
    {
      context,
      style: _, // ignore Virtuoso’s own inline styles
      ...restProps
    },
    forwardedRef
  ) => {
    const { ref, ...scrollerProps } = context.scrollerProps;

    return (
      <ScrollBox
        background="gray0"
        {...mergeProps(scrollerProps, restProps)}
        ref={ref ? mergeRefs([ref, forwardedRef]) : forwardedRef}
      />
    );
  }
);
