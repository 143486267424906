import {
  Flex,
  Grid,
  Icon,
  Text,
  Skeleton,
  Image,
} from '@candisio/design-system';
import accountingIcon from 'assets/credit-cards/icon-accouting.svg';
import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { CardStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface CreditCardFormDataPreviewProps {
  loading: boolean;
  isCardReplaceable: boolean;
  hasAccountingData?: boolean;
  cardStatus?: CardStatus;
  onClose?: () => void;
  onSetMode: (mode: DrawerMode) => void;
}

export const AccountingSettingsItem = ({
  isCardReplaceable,
  loading,
  cardStatus,
  hasAccountingData,
  onSetMode,
}: CreditCardFormDataPreviewProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const handleClick = () => {
    onSetMode('accountingSettings');
  };

  const isCardRequested = cardStatus === CardStatus.Requested;

  return (
    <Grid
      onClick={handleClick}
      templateColumns="1fr auto"
      padding="space16"
      alignItems="center"
      style={{ cursor: 'pointer' }}
      hover={{
        background: 'gray200',
        borderBottomLeftRadius: isCardReplaceable ? undefined : 'medium',
        borderBottomRightRadius: isCardReplaceable ? undefined : 'medium',
      }}
    >
      <Flex gap="space16" alignItems="center">
        <Image src={accountingIcon} alt="accounting icon" />
        <Flex alignItems="baseline" gap="space8">
          <Text fontSize="large">
            {t('creditCardFormDataPreview.accounting.title')}
          </Text>
          {loading ? (
            <Skeleton width="space128" height="space20" alignSelf="center" />
          ) : hasAccountingData ? (
            <Grid templateColumns="auto 1fr" gap="space4" alignSelf="center">
              <Icon
                icon={isCardRequested ? 'warning' : 'checkCircle'}
                color={isCardRequested ? 'yellow500' : 'green500'}
                size="space18"
              />
              <Text color={isCardRequested ? 'yellow500' : 'green500'}>
                {t(
                  isCardRequested
                    ? 'creditCardFormDataPreview.accounting.checkSettings'
                    : 'cardManagerView.status.active'
                )}
              </Text>
            </Grid>
          ) : (
            <Text>{t('creditCardFormDataPreview.accounting.subtitle')}</Text>
          )}
        </Flex>
      </Flex>
      <Icon icon="arrowRight" size="space16" />
    </Grid>
  );
};
