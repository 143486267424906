import { Flex, Grid, Card, Tag, Text, Button } from '@candisio/design-system';
import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { usePermissionsForCreditCards } from 'containers/credit-cards/hooks/usePermissionsForCreditCards';
import {
  CardLimitRenewFrequency,
  CardType,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface PendingLimitRequestProps {
  limit: string | null;
  transactionLimit: string | null;
  requestedDate: string;
  comment: string;
  cardType: CardType;
  interval: CardLimitRenewFrequency | undefined;
  onSetMode: (mode: DrawerMode) => void;
}

export const intervalTranslations: Record<CardLimitRenewFrequency, string> = {
  ANNUAL: 'drawer.limitChange.interval.perYear',
  MONTHLY: 'drawer.limitChange.interval.perMonth',
  QUARTERLY: 'drawer.limitChange.interval.perQuarter',
  TOTAL: 'drawer.limitChange.interval.total',
};

export const PendingLimitRequest = ({
  cardType,
  comment,
  interval,
  limit,
  requestedDate,
  transactionLimit,
  onSetMode,
}: PendingLimitRequestProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { canManageCards } = usePermissionsForCreditCards();

  return (
    <Grid gap="space2">
      <Card background="yellowbg" borderBottomRadius="none">
        <Grid gap="space8">
          <Tag variant="primary" color="yellow" size="small">
            {t('cardManagerView.pendingRequest.limitRequest')}
          </Tag>
          <Text fontSize="basic">
            {t('drawer.limitChange.requestedInfoPanel', {
              requestedAt: requestedDate,
            })}
          </Text>
          <Text fontSize="basic">
            {t('drawer.limitChange.comment')}: {comment}
          </Text>
        </Grid>
      </Card>
      <Card background="yellowbg" borderTopRadius="none">
        <Grid templateColumns="1fr auto">
          <Grid gap="space8">
            <Text fontSize="basic">
              {t('drawer.limitChange.requestedLimits')}
            </Text>
            <Flex gap="space16">
              {limit && cardType !== CardType.SingleUse && interval ? (
                <Text color="yellow700" fontSize="large">
                  {t(intervalTranslations[interval], { amount: limit })}
                </Text>
              ) : null}
              {transactionLimit ? (
                <Text color="yellow700" fontSize="large">
                  {t('drawer.limitChange.requestedTxLimit', {
                    formattedAmount: transactionLimit,
                  })}
                </Text>
              ) : null}
            </Flex>
          </Grid>
          {canManageCards && (
            <Button
              variant="secondary"
              size="small"
              alignSelf="center"
              justifySelf="end"
              onClick={() => onSetMode('cardLimits')}
            >
              {t('drawer.requested.changeLimits')}
            </Button>
          )}
        </Grid>
      </Card>
    </Grid>
  );
};
