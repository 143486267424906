import { useSetConsentForTrackingConfigurationMutation } from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { noop } from 'lodash';
import { useSegmentDestinations } from 'providers/AnalyticsProvider/useSegmentDestinations';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { currentUserQuery } from 'providers/CurrentUserProvider/queries';
import {
  useState,
  useEffect,
  ReactNode,
  createContext,
  useContext,
} from 'react';
import { CookieBanner } from 'views/AppContainer/components/ConsentManager/CookieBanner';
import { PreferencesModal } from 'views/AppContainer/components/ConsentManager/PreferencesModal';
import { ConsentManagerContainer } from 'views/AppContainer/components/ConsentManager/styles';
import { PRIVACY_LOCAL_STORAGE_KEY } from './constants';
import { useTrackingPreferences } from './hooks/useTrackingPreferences';

const PrivacyConsentContext = createContext<{
  handleSavePreferences: (
    isTrackingEnabled: boolean,
    isIntercomCookieEnabled: boolean
  ) => Promise<void>;
  showConsentManager: () => void;
}>({
  handleSavePreferences: async () => {},
  showConsentManager: () => noop,
});

export const usePrivacyContext = () => useContext(PrivacyConsentContext);

export const PrivacyConsentProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const destinations = useSegmentDestinations();
  const currentUser = useCurrentUser();
  const [consent, setConsent] = useLocalStorage(
    `${PRIVACY_LOCAL_STORAGE_KEY}.${currentUser?.analyticsId ?? ''}`,
    false
  );

  const {
    trackingEnabled,
    intercomCookiesEnabled,
    setTrackingEnabled,
    setIntercomCookiesEnabled,
  } = useTrackingPreferences();

  const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  const [setConsentForTrackingConfiguration] =
    useSetConsentForTrackingConfigurationMutation();

  useEffect(() => {
    if (!currentUser?.analyticsId) return;

    setShowBanner(!consent);
  }, [currentUser?.analyticsId, consent]);

  const hideBanner = () => {
    setConsent(true);
    setShowBanner(false);
  };

  const showConsentManager = () => {
    setIsPreferencesModalOpen(true);
  };

  const setTrackingConsent = async (
    isTrackingEnabled: boolean,
    isIntercomCookieEnabled: boolean
  ) => {
    if (!currentUser) return;

    await setConsentForTrackingConfiguration({
      variables: {
        intercom: isIntercomCookieEnabled,
        trackingEnabled: isTrackingEnabled,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: currentUserQuery, variables: { id: currentUser.id } },
      ],
    });
  };

  const handleSavePreferences = async (
    isTrackingEnabled: boolean,
    isIntercomCookieEnabled: boolean
  ) => {
    hideBanner();
    setIsPreferencesModalOpen(false);

    const hasTrackingPreferencesChanged =
      currentUser?.trackingConfiguration?.trackingEnabled !==
        isTrackingEnabled ||
      currentUser?.trackingConfiguration?.intercom !== isIntercomCookieEnabled;

    if (hasTrackingPreferencesChanged) {
      await setTrackingConsent(isTrackingEnabled, isIntercomCookieEnabled);
      window.location.reload();
    }
  };

  if (!currentUser || destinations.loading) return null;

  return (
    <PrivacyConsentContext.Provider
      value={{ handleSavePreferences, showConsentManager }}
    >
      {showBanner && (
        <ConsentManagerContainer>
          <CookieBanner
            onAcceptAll={() => handleSavePreferences(true, true)}
            onOpenPreferences={showConsentManager}
          />
        </ConsentManagerContainer>
      )}
      <PreferencesModal
        onCancel={() => setIsPreferencesModalOpen(false)}
        onSaveSelected={() =>
          handleSavePreferences(trackingEnabled, intercomCookiesEnabled)
        }
        setTrackingEnabled={setTrackingEnabled}
        visible={isPreferencesModalOpen}
        destinations={destinations.data}
        trackingEnabled={trackingEnabled}
        intercom={intercomCookiesEnabled}
        setIntercomEnabled={setIntercomCookiesEnabled}
      />
      {children}
    </PrivacyConsentContext.Provider>
  );
};
