import { HeaderProps, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ReimbursementTableRow } from 'views/Reimbursement/toolkit/types';

const mappedColumnHeaders: Record<string, string> = {
  approver: 'table.columns.approver',
  createdAt: 'table.columns.createdAt',
  grossAmount: 'table.columns.grossAmount',
  iban: 'table.columns.iban',
  isPaid: 'table.columns.isPaid',
  paidAt: 'table.columns.paidAt',
  requesterOfTheReimbursement: 'table.columns.requesterOfTheReimbursement',
  status: 'table.columns.status',
  title: 'table.columns.title',
  firstSubmittedForReviewAt: 'table.columns.firstSubmittedForReviewAt',
  lastApprovedByMembership: 'table.columns.lastApprovedByMembership',
};

export const Header = ({
  column,
  isFilteredOrSorted,
}: HeaderProps<ReimbursementTableRow>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const header = mappedColumnHeaders[column.id];

  return (
    <TruncatedText
      wordBreak="break-word"
      color={isFilteredOrSorted ? 'gray800' : 'gray500'}
    >
      {t(header)}
    </TruncatedText>
  );
};
