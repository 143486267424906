// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import Joi from 'joi';

export const EMAIL_SEPARATOR_REGEX = /[,;]/;

export const getEmailAddresses = (emailTo: string) =>
  emailTo
    .split(EMAIL_SEPARATOR_REGEX)
    .map(email => email.trim())
    .filter(email => email);

const schema = Joi.string().email({
  minDomainSegments: 1,
  tlds: { allow: false },
});

export const isValidEmailAddresses = (emailTo: string) => {
  const addresses = getEmailAddresses(emailTo);

  return (
    addresses.length > 0 &&
    addresses.every(email => !schema.validate(email).error)
  );
};

export const checkEmailErrors = (t: any, emailTo: string | null) => {
  const emailLabel = t('emailEditor.email.label');
  if (emailTo === null) {
    return '';
  }

  return emailTo === ''
    ? t('validation.fields.required', {
        label: emailLabel,
      })
    : isValidEmailAddresses(emailTo)
      ? ''
      : t('emailEditor.email.validationError');
};
