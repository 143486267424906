import { Card, Flex, Grid, Heading, Image } from '@candisio/design-system';
import { ReactNode } from 'react';
import { DcsSettingsSkeleton } from './DcsCardSkeleton';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from 'views/Settings/utils';

export interface DcoContainerProps {
  icon?: string;
  iconAlt?: string;
  iconHeight?: string;
  iconWidth?: string;
  title: ReactNode;
  tag?: ReactNode;
  details: ReactNode;
  actions?: ReactNode;
  secondaryActions?: ReactNode;
  bottomActions?: ReactNode;
  cardLoading?: boolean;
}

export const DcsContainer = ({
  title,
  details,
  icon,
  iconAlt,
  iconHeight,
  iconWidth,
  actions,
  secondaryActions,
  tag,
  bottomActions,
  cardLoading = false,
}: DcoContainerProps) => {
  const hasIcon = icon && iconAlt;

  return !cardLoading ? (
    <Card padding={0} overflow="hidden">
      <Grid autoFlow="column" maxWidth={SETTINGS_VIEW_DEFAULT_WIDTH}>
        {hasIcon && (
          <Grid
            alignItems="center"
            justifyItems="center"
            width="138px"
            background="blue100"
          >
            <Image
              src={icon}
              alt={iconAlt}
              width={iconWidth}
              height={iconHeight}
              style={{ borderRadius: '3.2px' }}
            />
          </Grid>
        )}
        <Grid rowGap="space24" paddingX="space16" paddingY="space24">
          <Flex gap="space36" placeContent="space-between">
            <Flex direction="column" gap="space16" width="100%">
              <Grid columns={2} gap="space24">
                <Grid
                  justifyItems="left"
                  columns={2}
                  gap="space8"
                  alignItems="center"
                  width="max-content"
                >
                  <Heading as="h3">{title}</Heading>
                  {tag}
                </Grid>
                <Grid
                  autoFlow="column"
                  justifyItems="right"
                  justifyContent="right"
                >
                  {actions}
                  {secondaryActions}
                </Grid>
              </Grid>
              {details}
            </Flex>
          </Flex>
          {bottomActions}
        </Grid>
      </Grid>
    </Card>
  ) : (
    <DcsSettingsSkeleton />
  );
};
