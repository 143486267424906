import { Card, Flex, Grid, Text } from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { Team } from '../../toolkit/hooks/useGetTeams';
import { getTemplateRows } from '../../toolkit/utils/getStyles';
import { Members } from './Members';

interface TeamDetailsProps {
  team: Team;
  search?: string;
  additionalDetails?: ReactNode;
  onShowTeamDetails?: (id: string) => void;
  isCreateMode?: boolean;
}

export const TeamDetails = ({
  team,
  search,
  additionalDetails,
  onShowTeamDetails,
  isCreateMode,
}: TeamDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { id: userId, fullName, avatarUrl } = team.teamAdmin ?? {};

  const members = team.members ?? [];
  const hasMembers = Boolean(members.length);
  const hasAdditionalDetails = Boolean(additionalDetails);

  const handleClick = (id: string | undefined) => {
    if (!id) return;

    onShowTeamDetails?.(id);
  };

  return (
    <Grid
      borderRadius="medium"
      background="gray0"
      boxShadow={isCreateMode ? 'elevatedShadow4' : 'noShadow'}
      cursor={Boolean(onShowTeamDetails) ? 'pointer' : 'default'}
      templateRows={
        getTemplateRows({
          hasMembers,
          hasAdditionalDetails,
        }) ?? '1fr'
      }
      onClick={() => handleClick(team.id)}
    >
      <Flex
        direction="column"
        padding="space10"
        alignItems="start"
        gap="space8"
      >
        <Grid gap="space8">
          <Card
            background="gray200"
            justifySelf="start"
            paddingX="space8"
            paddingY="space4"
            borderRadius="small"
          >
            <Text fontSize="basic" data-testid={team.name}>
              {renderQueryHighlight({
                value: team.name,
                queryString: search ?? '',
              })}
            </Text>
          </Card>
          {userId && (
            <Flex gap="space8" alignItems="center">
              <AvatarWithStatusContainer
                name={fullName}
                img={avatarUrl}
                userId={userId}
              />
              <Flex direction="column">
                <Text color="gray600" fontSize="small">
                  {t('teams.list.teamAdmin')}
                </Text>
                <Text fontSize="basic">{fullName}</Text>
              </Flex>
            </Flex>
          )}
        </Grid>
      </Flex>
      {hasMembers && (
        <Flex
          background="gray100"
          padding="space10"
          direction="column"
          justifyContent="center"
          borderBottomRadius="medium"
          gap={hasAdditionalDetails ? 'space16' : 'unset'}
        >
          <Members members={members} />
          {additionalDetails}
        </Flex>
      )}
    </Grid>
  );
};
