import { useTranslation } from 'react-i18next';

export const useArtistSocialInsuranceCodeTranslationsMap = () => {
  const [t] = useTranslation();
  const translationMap = {
    BK: t('document.requestApproval.inputs.artistSocialInsuranceCode.codes.BK'),
    DK: t('document.requestApproval.inputs.artistSocialInsuranceCode.codes.DK'),
    M: t('document.requestApproval.inputs.artistSocialInsuranceCode.codes.M'),
    W: t('document.requestApproval.inputs.artistSocialInsuranceCode.codes.W'),
  };

  return translationMap;
};
