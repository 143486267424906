import { Button, Grid, Icon, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DrawerMode } from '../../../../CreditCardDrawer/CreditCardDrawer';

interface ReplaceCardTipProps {
  onSetMode: (mode: DrawerMode) => void;
}
export const ReplaceCardTip = ({ onSetMode }: ReplaceCardTipProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const tipInfo = [
    {
      description: t('dashboard.drawer.terminateCardPopover.info.tip1'),
    },
    {
      description: t('dashboard.drawer.terminateCardPopover.info.tip2'),
    },
    {
      description: t('dashboard.drawer.terminateCardPopover.info.tip3'),
    },
  ];

  const handleClick = () => {
    onSetMode('replaceCard');
  };

  return (
    <Grid
      background="bluebg"
      gap="space10"
      padding="space16"
      borderBottomRadius="medium"
    >
      <Grid gap="space4">
        <Text fontWeight="semibold">
          {t('dashboard.drawer.terminateCardPopover.info.title')}
        </Text>
        <Grid>
          {tipInfo.map(({ description }, i) => (
            <Grid
              key={i}
              alignItems="center"
              autoFlow="column"
              gap="space8"
              templateColumns="auto 1fr"
            >
              <Icon icon="arrowRight" />
              <Text>{description}</Text>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Button variant="secondary" justifySelf="end" onClick={handleClick}>
        {t('creditCardFormDataPreview.replaceCard.title')}
      </Button>
    </Grid>
  );
};
