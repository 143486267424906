import { xor } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { Box } from '../../../Atoms/Box';
import { Checkbox } from '../../../Atoms/Checkbox';
import { Separator } from '../../../Atoms/Separator';

export const PinnedOption = ({
  label,
  id,
  setCurrentFilters,
  currentFilters,
}: {
  label: string;
  id: string;
  currentFilters: string[];
  setCurrentFilters: Dispatch<SetStateAction<string[]>>;
}) => {
  return (
    <>
      <Box paddingLeft="space3">
        <Checkbox
          key={id}
          isSelected={currentFilters?.includes(id ?? '') ?? false}
          onChange={() => {
            setCurrentFilters(xor(currentFilters, [id ?? '']));
          }}
        >
          {label}
        </Checkbox>
      </Box>
      <Separator color="gray300" />
    </>
  );
};
