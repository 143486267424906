import { Grid, Item, Skeleton, TabItem } from '@candisio/design-system';
import {
  useInboxReimbursementCount,
  useInboxTransactionCount,
} from 'hooks/useCounterQueries';
import { useCountDocumentsProcessing } from 'hooks/useCounters';
import { useUserRoles } from 'hooks/useUserRoles';
import { AppRouteParams } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Key, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { TabsContainer } from 'views/Archive/components/ArchiveTabs';
import { tabViewToUrlMap } from 'views/Archive/components/utils';
import { useEcmSensitiveDocumentsCount } from '../EcmSensitiveDocuments/useGetEcmSensitiveDocumentsCount';
import { TabView } from '../models';
import { options } from './utils';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useInboxContractsCount } from '../InboxContracts/useInboxContractsCount';
import { useInboxOtherDocumentsCount } from '../InboxOtherDocuments/useInboxOtherDocumentsCount';

type TabConfigItem = TabItem & {
  isAvailable: boolean;
};

export const InboxTabs = ({ activeTab }: { activeTab: TabView }) => {
  const [t] = useTranslation();
  const [llmClassificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.llmClassification,
  ]);
  const navigate = useNavigate();
  const organizationSlug = useOrganizationId();
  const creditCards = useCreditCardsSetup();
  const { canUseReimbursement } = useReimbursement();
  const { isAdmin } = useUserRoles();

  const { variant } = useReimbursementsPromotions();

  const { showEcmSensitiveDocuments, canAccessEcm, showEcmContractManagement } =
    useEcm();

  const { count: inboxDocumentCount } = useCountDocumentsProcessing(options);

  const { inboxReimbursementCount } = useInboxReimbursementCount();

  const { count: inboxContractsCount } = useInboxContractsCount({
    skip: !llmClassificationFF,
  });

  const { count: inboxOtherDocumentsCount } = useInboxOtherDocumentsCount({
    skip: !llmClassificationFF,
  });

  const documentCount = Boolean(inboxDocumentCount)
    ? inboxDocumentCount?.toLocaleString()
    : undefined;

  const { inboxTransactionCount } = useInboxTransactionCount();
  const transactionCount = Boolean(inboxTransactionCount)
    ? inboxTransactionCount.toLocaleString()
    : undefined;

  const { sensitiveDocumentsCount } = useEcmSensitiveDocumentsCount();
  const sensitiveDocumentsCountString = Boolean(sensitiveDocumentsCount)
    ? sensitiveDocumentsCount.toLocaleString()
    : undefined;

  const reimbursementCount = Boolean(inboxReimbursementCount)
    ? String(inboxReimbursementCount)
    : undefined;

  const isConfigLoading = creditCards.isOnboardingStatusLoading;

  const tabs: TabItem[] = useMemo(() => {
    let reimbursementsTabIcon: TabItem = {
      iconColor: 'purple500',
      iconTooltip: t('tag.addOn'),
      icon: 'arrowUpCircleFilled',
    };

    if (variant === 'noPromotion') {
      reimbursementsTabIcon = {
        badge: reimbursementCount,
        badgeColor: 'darkGray',
      };
    }

    if (variant === 'promotionForEntitlements') {
      reimbursementsTabIcon = { tag: t('tag.soon') };
    }

    const tabConfigs: TabConfigItem[] = [
      {
        key: TabView.INBOX_DOCUMENTS,
        title: t('archive.tabs.invoices'),
        badge: documentCount,
        badgeColor: 'darkGray',
        isAvailable: true,
      },
      {
        key: TabView.INBOX_TRANSACTIONS,
        title: t('archive.tabs.transactions'),
        isAvailable: !isConfigLoading,
        ...(creditCards.isAddonBought
          ? { badge: transactionCount, badgeColor: 'darkGray' }
          : {
              icon: 'arrowUpCircleFilled',
              iconColor: 'purple500',
              iconTooltip: t('tag.addOn'),
            }),
      },
      {
        key: TabView.INBOX_CONTRACTS,
        title: t('archive.tabs.contracts'),
        isAvailable: canAccessEcm,
        ...(showEcmContractManagement
          ? {
              badge: inboxContractsCount
                ? inboxContractsCount.toLocaleString()
                : undefined,
              badgeColor: 'darkGray',
            }
          : {
              icon: 'arrowUpCircleFilled',
              iconColor: 'purple500',
              iconTooltip: t('tag.addOn'),
            }),
      },
      {
        key: TabView.INBOX_REIMBURSEMENTS,
        title: t('archive.tabs.reimbursements'),
        isAvailable: canUseReimbursement || isAdmin,
        ...reimbursementsTabIcon,
      },
      {
        key: TabView.INBOX_OTHER_DOCUMENTS,
        title: t('archive.tabs.otherDocuments'),
        isAvailable: canAccessEcm,
        ...(canAccessEcm
          ? {
              badge: inboxOtherDocumentsCount
                ? inboxOtherDocumentsCount.toLocaleString()
                : undefined,
              badgeColor: 'darkGray',
            }
          : {
              icon: 'arrowUpCircleFilled',
              iconColor: 'purple500',
              iconTooltip: t('tag.addOn'),
            }),
      },
    ];

    const tabConfigsDeprecated: TabConfigItem[] = [
      {
        key: TabView.INBOX_DOCUMENTS,
        title: t('archive.tabs.documents'),
        badge: documentCount,
        badgeColor: 'darkGray',
        isAvailable: true,
      },
      {
        key: TabView.INBOX_TRANSACTIONS,
        title: t('archive.tabs.transactions'),
        isAvailable: !isConfigLoading,
        ...(creditCards.isAddonBought
          ? { badge: transactionCount, badgeColor: 'darkGray' }
          : {
              icon: 'arrowUpCircleFilled',
              iconColor: 'purple500',
              iconTooltip: t('tag.addOn'),
            }),
      },
      {
        key: TabView.INBOX_ECM_SENSITIVE_DOCUMENTS,
        title: t('archive.tabs.ecmSensitiveDocuments'),
        isAvailable: canAccessEcm,
        ...(showEcmSensitiveDocuments
          ? {
              badge: sensitiveDocumentsCountString,
              badgeColor: 'darkGray',
            }
          : {
              icon: 'arrowUpCircleFilled',
              iconColor: 'purple500',
              iconTooltip: t('tag.addOn'),
            }),
      },
      {
        key: TabView.INBOX_REIMBURSEMENTS,
        title: t('archive.tabs.reimbursements'),
        isAvailable: canUseReimbursement || isAdmin,
        ...reimbursementsTabIcon,
      },
    ];

    if (!llmClassificationFF)
      return tabConfigsDeprecated.filter(item => item.isAvailable);

    return tabConfigs.filter(item => item.isAvailable);
  }, [
    llmClassificationFF,
    t,
    documentCount,
    transactionCount,
    isConfigLoading,
    creditCards,
    canAccessEcm,
    showEcmContractManagement,
    canUseReimbursement,
    isAdmin,
    sensitiveDocumentsCountString,
    showEcmSensitiveDocuments,
    reimbursementCount,
    variant,
    inboxContractsCount,
    inboxOtherDocumentsCount,
  ]);

  if (isConfigLoading) {
    return (
      <Grid paddingLeft="space32">
        <Skeleton height="46px" width="10rem" />
      </Grid>
    );
  }

  const handleSelectionChange = (value: Key) => {
    if (typeof value === 'bigint' || typeof value === 'number') return;

    const tab = value as TabView;

    const url = tabViewToUrlMap[tab];

    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${url}`,
      { organizationSlug }
    );

    navigate(pathname);
  };

  return (
    <TabsContainer
      items={tabs}
      onSelectionChange={handleSelectionChange}
      defaultSelectedKey={activeTab}
    >
      {item => (
        <Item key={item.key} title={item.title} textValue={item.title}>
          {item.children}
        </Item>
      )}
    </TabsContainer>
  );
};
