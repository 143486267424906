import {
  Grid,
  Image,
  Icon,
  Card,
  Flex,
  Heading,
  Paragraph,
  Tag,
  Button,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import teaser from './media/teaser.png';

export const TeaserWidget = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid>
      <Image src={teaser} alt="blurry widget" height="696px" />
      <Icon
        icon="logo"
        size="360px"
        color="blue400"
        position="absolute"
        right="45%"
        top="3%"
      />
      <Card
        position="absolute"
        background="gray50"
        top="12%"
        left="20%"
        width="500px"
        padding="space32"
      >
        <Grid gap="space16">
          <Flex direction="column" gap="space4">
            <Tag size="large" variant="secondary" color="blue">
              {t('insights.teaser.tag')}
            </Tag>
            <Heading as="h1">{t('insights.teaser.title')}</Heading>
            <Paragraph fontSize="large" whiteSpace="pre-wrap">
              {t('insights.teaser.text')}
            </Paragraph>
          </Flex>
          <Button justifySelf="start" data-intercom="intercom-chat">
            {t('insights.teaser.cta')}
          </Button>
        </Grid>
      </Card>
    </Grid>
  );
};
