import { FilterOptionsAccessor } from '@candisio/design-system';
import { mapDocumentTypeTranslations } from 'components/DocumentsTable/Cells/mapDocumentTypeTranslations';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { useAllDocumentTypesQuery } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountingAreasList } from 'views/Settings/AccountingAreas/toolkit/hooks/useAccountingAreasList';
import { useArtistSocialInsuranceCodeFilters } from 'views/utils/useArtistSocialInsuranceCodeFilters';
import { useStatusFilters } from '../consts';

export const useArchiveDocumentsFilters = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  const { data: allDocumentTypesData, loading: loadingDocumentTypes } =
    useAllDocumentTypesQuery();

  const {
    allAccountingAreasListData,
    listDataLoading: accountingAreasLoading,
  } = useAccountingAreasList();

  const accountingAreas = useMemo(() => {
    return allAccountingAreasListData?.map(area => ({
      id: area.id,
      label: area.name,
    }));
  }, [allAccountingAreasListData]);

  const documentTypeFilters = useMemo(() => {
    return allDocumentTypesData?.availableDocumentCategories.map(c => ({
      id: c.documentType,
      label: c.isDefault
        ? t(mapDocumentTypeTranslations(c.documentType))
        : c.documentType,
    }));
  }, [allDocumentTypesData, t]);

  const artistSocialInsuranceCodeFilters =
    useArtistSocialInsuranceCodeFilters();

  const statusFilters = useStatusFilters();

  const [enableNewIndexInArchiveViewFF, consistentSortAndFilteringFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.enableNewIndexInArchiveView,
      FEATURE_FLAGS.consistentSortAndFiltering,
    ]);

  const isNewIndexWithConsistentSortEnabled =
    enableNewIndexInArchiveViewFF && consistentSortAndFilteringFF;

  const filterOptions: FilterOptionsAccessor<DocumentsTableData> =
    useMemo(() => {
      return {
        invoiceDate: true,
        dueDate: true,
        deliveryDate: true,
        createdAt: true,
        paidAt: true,
        discountDateWPercentage: isNewIndexWithConsistentSortEnabled,
        accountingArea: {
          data: accountingAreas,
          isLoading: accountingAreasLoading,
        },
        isInvoiceCorrection: {
          data: [
            {
              id: 'true',
              label: t('isInvoiceCorrection.yes'),
            },
            {
              id: 'false',
              label: t('isInvoiceCorrection.no'),
            },
          ],
        },
        isPayable: {
          data: [
            { id: 'false', label: t('isPayable.notPayable') },
            { id: 'true', label: t('isPayable.payable') },
          ],
        },
        paymentStatus: {
          data: [
            { id: 'false', label: t('isPaid.notPaid') },
            { id: 'true', label: t('isPaid.paid') },
          ],
        },
        status: {
          data: statusFilters.map(status => ({
            id: status.id,
            label: t(status.label),
          })),
        },
        documentType: {
          data: documentTypeFilters || [],
          isLoading: loadingDocumentTypes,
        },
        creditCardPayment: {
          data: [
            {
              id: 'true',
              label: t('creditCardPaymentFilter.creditCardPayment'),
            },
            {
              id: 'false',
              label: t('creditCardPaymentFilter.noCreditCardPayment'),
            },
          ],
        },
        artistSocialInsuranceCode: artistSocialInsuranceCodeFilters,
        tags: enableNewIndexInArchiveViewFF,
      };
    }, [
      accountingAreas,
      accountingAreasLoading,
      t,
      statusFilters,
      documentTypeFilters,
      loadingDocumentTypes,
      artistSocialInsuranceCodeFilters,
      enableNewIndexInArchiveViewFF,
      isNewIndexWithConsistentSortEnabled,
    ]);

  return {
    filterOptions,
    filtersLoading: loadingDocumentTypes || accountingAreasLoading,
  };
};
