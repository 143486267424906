import { Flex } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Title } from '../../styles';
import { RequestNewCard, RequestNewCardProps } from './RequestNewCard';

export type HeaderProps = {
  newCard: RequestNewCardProps;
};

export const Header = (props: HeaderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      paddingY="space5"
      flexGrow={1}
    >
      <Title>{t('carousel.title')}</Title>
      <RequestNewCard {...props.newCard} />
    </Flex>
  );
};
