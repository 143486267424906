import {
  EcmDocumentStatus,
  DocumentType,
  useGetEcmContractsQuery,
} from 'generated-types/graphql.types';

export const useInboxContractsCount = ({ skip }: { skip: boolean }) => {
  const { data } = useGetEcmContractsQuery({
    variables: {
      filter: {
        documentStatus: [EcmDocumentStatus.New],
        documentType: [DocumentType.Contract],
      },
    },
    skip,
  });

  return {
    count: data?.getEcmDocuments.pageInfo.totalCount,
  };
};
