import {
  Avatar,
  Flex,
  Grid,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { CardRequestType } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { NotificationProps } from '../getNotificationInfo';

const translations: { [key in CardRequestType]?: { message: string } } = {
  ChangeCardLimitRequest: {
    message: 'events.cardRequestApproved.approval.limit',
  },
  IssueNewPhysicalCardRequest: {
    message: 'events.cardRequestApproved.approval.physicalCard',
  },
  IssueNewSingleUseVirtualCardRequest: {
    message: 'events.cardRequestApproved.approval.singleUseCard',
  },
  IssueNewVirtualCardRequest: {
    message: 'events.cardRequestApproved.approval.virtualCard',
  },
};

export const CardRequestApprovedInAppNotification = (
  props: NotificationProps<'CardRequestApprovedInAppNotification'>
) => {
  const {
    cardManager,
    cardRequestStatusChangedAt,
    cardRequestType,
    cardRefNum,
    limit,
    transactionLimit,
  } = props;

  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);
  const { transactionAmountFormatter } = useMoneyFormatter();

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });

  const cardManagerName = cardManager.name;

  const formattedLimit = transactionAmountFormatter({
    currency: limit?.currency ?? 'EUR',
    precision: limit?.precision ?? 2,
    value: limit?.value ?? 0,
  });

  const formattedTransactionLimit = transactionAmountFormatter({
    currency: transactionLimit?.currency ?? 'EUR',
    precision: transactionLimit?.precision ?? 2,
    value: transactionLimit?.value ?? 0,
  });

  const translation = translations[cardRequestType];

  return (
    <Grid templateRows="auto 1fr auto" gap="space6" height="100%">
      <Grid autoFlow="column" alignItems="start" justifyContent="start">
        <Flex wrap="wrap" fontSize="small" gap="space4">
          <Trans
            i18nKey="notifications:events.cardRequestApproved.notification"
            values={{
              cardManagerName: cardManagerName,
              timeAgo: DateFormatters.fromNow(
                new Date(cardRequestStatusChangedAt)
              ),
            }}
          >
            {/* @ts-expect-error we have to surpress typescript here because TruncatedText expects a string */}
            <TruncatedText color="gray800" maxWidth="15ch">
              {{ cardManagerName }}
            </TruncatedText>
            <Text color="gray500" {...triggerProps} ref={triggerRef}>
              3 minutes ago
            </Text>
          </Trans>
        </Flex>
        {isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {DateFormatters.compactDatetime(
              new Date(cardRequestStatusChangedAt)
            )}
          </Tooltip>
        )}
      </Grid>

      {translation && (
        <TruncatedText color="gray800" fontSize="basic">
          {t(translation.message, { cardRefNum })}
        </TruncatedText>
      )}

      <Flex gap="space4">
        {cardRequestType !==
          CardRequestType.IssueNewSingleUseVirtualCardRequest && (
          <Flex gap="space4">
            <Text fontSize="small" color="gray500">
              {t('events.cardRequestApproved.limit', { limit: formattedLimit })}
            </Text>
            <Text fontSize="small" color="gray500">
              •
            </Text>
          </Flex>
        )}
        <Text fontSize="small" color="gray500">
          {t('events.cardRequestApproved.transactionLimit', {
            transactionLimit: formattedTransactionLimit,
          })}
        </Text>
      </Flex>
    </Grid>
  );
};

export const CardRequestApprovedInAppNotificationToast = (
  props: NotificationProps<'CardRequestApprovedInAppNotification'>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);
  const { cardManager, cardRequestType, cardRefNum } = props;

  const cardManagerName = cardManager.name;

  const translation = translations[cardRequestType];

  return (
    <Grid templateRows="auto auto" gap="space6" height="100%">
      <TruncatedText color="gray800" maxWidth="15ch">
        {cardManagerName}
      </TruncatedText>
      {translation && (
        <TruncatedText color="gray800" fontSize="basic">
          {t(translation.message, { cardRefNum })}
        </TruncatedText>
      )}
    </Grid>
  );
};

export const CardRequestApprovedInAppNotificationIcon = ({
  cardManager,
}: NotificationProps<'CardRequestApprovedInAppNotification'>) => {
  return (
    <Avatar
      name={cardManager.name}
      img={cardManager.avatarUrl ?? undefined}
      hasTooltip={false}
      size="medium"
    />
  );
};
