import {
  HookFormTextField,
  HookFormTextFieldProps,
} from 'components/HookFormFields/HookFormTextField';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContactFormOutput, ContactFormValues } from './contactFormSchema';

export const ContactTaxNumberField = (
  props: Omit<HookFormTextFieldProps<ContactFormValues>, 'name'>
) => {
  const [t] = useTranslation();
  const { watch, trigger } = useFormContext<ContactFormOutput>();
  const [country] = watch(['country']);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void trigger('taxNumber');
  }, [country, trigger]);

  return (
    <HookFormTextField<ContactFormValues>
      {...props}
      name="taxNumber"
      label={t('settings.contacts.details.edit.taxNumber.label')}
      placeholder={t('settings.contacts.details.edit.taxNumber.placeholder')}
    />
  );
};
