import { SvgIcon, SvgProps } from './SvgIcon';

/**
 * @deprecated please use Icon from design system instead.
 * @see https://candisio.github.io/design-system/
 */
export const Warning = ({
  fill = '#FF9900',
  width = '19',
  height = '19',
  viewBox = '0 0 19 19',
  ...props
}: SvgProps) => {
  return (
    <SvgIcon
      {...props}
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6328 7.4285C10.6328 8.56684 10.6328 9.01758 10.6327 9.75922C10.6327 10.4027 10.1256 10.924 9.5 10.924C8.8744 10.924 8.36726 10.4027 8.36722 9.75922C8.36719 9.01758 8.36719 8.56684 8.36719 7.4285C8.36719 6.29016 8.36719 6.71334 8.36722 5.97166C8.36726 5.32819 8.8744 4.80685 9.5 4.80685C10.1256 4.80685 10.6327 5.32819 10.6327 5.97166C10.6328 6.71334 10.6328 6.29016 10.6328 7.4285ZM9.5 14.711C8.87437 14.711 8.36719 14.1893 8.36719 13.5458C8.36719 12.9023 8.87437 12.3807 9.5 12.3807C10.1256 12.3807 10.6328 12.9023 10.6328 13.5458C10.6328 14.1893 10.1256 14.711 9.5 14.711ZM18.3605 14.1753L10.7842 1.29715C10.5148 0.758288 10.0351 0.4375 9.5 0.4375C8.96488 0.4375 8.48517 0.758288 8.21577 1.29715L0.639548 14.1753C0.370151 14.71 0.370151 15.3515 0.639548 15.8904C0.90891 16.4292 1.38866 16.75 1.92377 16.75H17.0763C17.6113 16.75 18.0911 16.4292 18.3605 15.8904C18.6298 15.3558 18.6298 14.7142 18.3605 14.1753Z"
      />
    </SvgIcon>
  );
};
