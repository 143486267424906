import { Flex, Grid, Heading, Text } from '@candisio/design-system';
import { useCreateCreditCardModalState } from 'containers/credit-cards/CreateCreditCard/CreateCreditCardModalDataProvider';
import { PhysicalCardLimitsFormContainer } from 'containers/credit-cards/CreateCreditCard/PhysicalCardLimitsFormContainer';
import { VirtualCardLimitsFormContainer } from 'containers/credit-cards/CreateCreditCard/VirtualCardLimitsFormContainer';
import { CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CardTypeField } from './CardTypeField';

export interface LimitsAndCardTypeProps {
  hasPhysicalCard: boolean;
  suggestedAmount: number;
}

type CardTypeDetails = Record<
  Exclude<CardType, CardType.Black>,
  { bulletList: string[]; form: JSX.Element } | undefined
>;

export const LimitsAndCardType = ({
  hasPhysicalCard,
  suggestedAmount,
}: LimitsAndCardTypeProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { cardType } = useCreateCreditCardModalState();

  const cardTypeDetails: CardTypeDetails = {
    SINGLE_USE: undefined,
    VIRTUAL: {
      bulletList: [
        'ccRequestForm.bulletList.virtualCard.point1',
        'ccRequestForm.bulletList.virtualCard.point2',
      ],
      form: (
        <VirtualCardLimitsFormContainer suggestedAmount={suggestedAmount} />
      ),
    },
    PHYSICAL: {
      bulletList: [
        'ccRequestForm.bulletList.physicalCard.point1',
        'ccRequestForm.bulletList.physicalCard.point2',
      ],
      form: (
        <PhysicalCardLimitsFormContainer
          hasPhysicalCard={hasPhysicalCard}
          suggestedAmount={suggestedAmount}
        />
      ),
    },
  };

  return (
    <Grid
      gap="space24"
      height="100%"
      templateRows="auto 1fr"
      padding="space24"
      paddingBottom="0px"
    >
      <Grid gap="space16" alignItems="start">
        <Heading as="h2">{t('ccRequestForm.limitsForm.cardType')}</Heading>
        <CardTypeField />
        <Flex direction="column" gap="space2">
          {cardTypeDetails[cardType]?.bulletList.map((point, index) => (
            <Text color="gray600" key={index}>
              {t(point)}
            </Text>
          ))}
        </Flex>
      </Grid>
      {cardTypeDetails[cardType]?.form}
    </Grid>
  );
};
