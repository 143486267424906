import { TabPanel, Tabs } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { useTabs } from 'components/Tabs/useTabs';
import { CreateCreditCardModal } from 'containers/credit-cards/CreateCreditCard/CreateCreditCardModal';
import { Locale } from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useTeams } from 'orgConfig/teams/useTeams';
import { CreditCardsProvider } from 'providers/CreditCardsProvider/CreditCardsProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Match } from 'router/Match';
import { usePath } from 'utils/hooks';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { UserDetails } from './TeamMemberDetails/UserDetails';
import { userIdParamKey } from './TeamMemberDetails/containers/invite/IssueCreditCard';
import { LocalMembershipStatus, ROLE_FILTER_PARAM } from './UsersListView';
import { useGetUsers } from './hooks/useGetUsers';
import { useGetUsersCountersByStatus } from './hooks/useGetUsersCountersByStatus';
import { usePermissionsForTeamMembers } from './hooks/usePermissionsForTeamMembers';
import { RouteParams } from './types';

import { UsersListViewContainer } from './UsersListView/UsersListViewContainer';
import { generateTeamMembersPath } from './utils';

export type TeamMembersProps = RouteComponentProps<RouteParams>;

// Since we are reusing the userId route param to also show the invite form
// when the userId is "invite" we show the invite form else
// we try to load the user to show
const userIdForInvitingAUser = 'invite';
const TEAM_MEMBERS_WIDTH = '50rem';

export const TeamMembers = ({
  location: { search },
  match: {
    params: { organizationSlug, userId },
    path,
    url,
  },
  history,
}: TeamMembersProps) => {
  const {
    pathGenerator,
    queryParams: { status = LocalMembershipStatus.ACTIVE, roleName },
  } = usePath<
    {},
    { status?: LocalMembershipStatus; [ROLE_FILTER_PARAM]?: string }
  >();

  const { canInviteMember } = usePermissionsForTeamMembers();
  const { isTeamsFeatureEnabled } = useTeams();

  const [t] = useTranslation();
  const navigate = useNavigate();

  const { isInUse } = useCreditCardsSetup();
  const { isCardManager } = useUserRoles();
  const { counters, loading: isLoadingCounters } =
    useGetUsersCountersByStatus();

  const { searchParams } = useMutateSearchParams();
  const selectedRoleName = searchParams.get(ROLE_FILTER_PARAM);
  const roleNames = selectedRoleName ? [selectedRoleName] : undefined;

  const { users, isLoadingUsers } = useGetUsers({
    filters: { roleNames },
  });

  const toggleDrawer = useCallback(
    (id: string & { emailVerified: boolean }) => {
      const search = qs.stringify({ status, roleName });
      const pathname = generateTeamMembersPath({
        organizationSlug,
        path,
        url,
        userId: id,
      });

      history.push({ pathname, search });
    },
    [status, roleName, organizationSlug, path, url, history]
  );

  const organization = useFullOrganization();
  const organizationLanguage = organization?.locale || Locale.De;

  const openInviteForm = useCallback(() => {
    const search = qs.stringify({ status });
    const pathname = generateTeamMembersPath({
      organizationSlug,
      path,
      url,
      userId: userIdForInvitingAUser,
    });

    history.push({
      pathname,
      search,
    });
  }, [history, organizationSlug, path, url, status]);

  const closeDrawer = useCallback(() => {
    const search = qs.stringify({ status, roleName });
    const pathname = generateTeamMembersPath({
      organizationSlug,
      path,
      url,
    });

    history.push({
      pathname,
      search,
    });
  }, [status, roleName, organizationSlug, path, url, history]);

  // We are implementing new roles and branching directly on the list will allow us to easily introduce gradual changes
  // When adding changes please do it in both lists and make sure everything works correctly
  const teamMembersList = (
    <UsersListViewContainer
      openInviteForm={openInviteForm}
      toggleDrawer={toggleDrawer}
    />
  );

  const loadingCounters = isLoadingCounters;

  const tabs = canInviteMember
    ? Object.values(LocalMembershipStatus)
    : [LocalMembershipStatus.ACTIVE];

  const { tabPanelProps, tabsProps } = useTabs({
    items: tabs.map(key => ({
      key,
      title: t(`settings.teamMembers.actions.tabs.${key}`),
      badge: loadingCounters ? undefined : String(counters[key] ?? 0),
      children: teamMembersList,
    })),
    onSelectionChange: key => {
      history.push({
        search: qs.stringify({
          ...qs.parse(search),
          roleName: undefined,
          status: LocalMembershipStatus[key as LocalMembershipStatus],
        }),
      });
    },
    selectedKey: status,
  });

  const handleCloseIssueCardModal = () => {
    searchParams.delete(userIdParamKey);
    navigate({
      pathname: `/${organizationSlug}${Routes.SETTINGS}${Routes.TEAM_MEMBERS}`,
      search: searchParams.toString(),
    });
  };

  const handleOpenIssueCardModal = (createdUserId: string) => {
    searchParams.set(userIdParamKey, createdUserId);
    navigate({
      pathname: `/${organizationSlug}${Routes.SETTINGS}${Routes.TEAM_MEMBERS}/requestCard`,
      search: searchParams.toString(),
    });
  };

  const translations = useMemo(() => {
    return isTeamsFeatureEnabled
      ? {
          title: 'settings.users.title',
          externalLink: 'settings.users.infoPanel.externalLink',
          sections: 'settings.users.infoPanel.sections',
        }
      : {
          title: 'settings.teamMembers.title',
          externalLink: 'settings.teamMembers.infoPanel.externalLink',
          sections: 'settings.teamMembers.infoPanel.sections',
        };
  }, [isTeamsFeatureEnabled]);

  return (
    <SettingsLayout
      actions={<Tabs {...tabsProps} />}
      title={t(translations.title)}
      width={TEAM_MEMBERS_WIDTH}
    >
      <TabPanel {...tabPanelProps} />
      <InfoPanel
        externalLink={t(translations.externalLink, {
          returnObjects: true,
        })}
        sections={t(translations.sections, {
          returnObjects: true,
        })}
        title={t('settings.teamMembers.infoPanel.title')}
      />
      <Match<{ userId?: string }>
        paths={[
          pathGenerator.stringify({ userId: userIdForInvitingAUser }),
          pathGenerator.stringify({
            userId: userId !== 'requestCard' ? ':userId' : null,
          }),
        ]}
      >
        {({ matched }) => {
          return (
            <UserDetails
              isOpen={!!matched}
              organizationLanguage={organizationLanguage}
              closeDrawer={closeDrawer}
              selectedUserId={matched?.userId}
              onOpenIssueCardModal={handleOpenIssueCardModal}
              allUsers={users ?? []}
              loading={isLoadingUsers}
            />
          );
        }}
      </Match>
      {userId === 'requestCard' && isInUse && isCardManager && (
        <CreditCardsProvider>
          <CreateCreditCardModal
            isOpen={userId === 'requestCard'}
            onClose={handleCloseIssueCardModal}
          />
        </CreditCardsProvider>
      )}
    </SettingsLayout>
  );
};
