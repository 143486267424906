import { Box, Grid, ScrollBox } from '@candisio/design-system';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { DetailsLayout } from 'components/Layouts/DetailsLayout';
import {
  LeftSectionOuterWrapper,
  LeftSectionContentWrapper,
} from 'components/Layouts/styles';
import { ListNavigator } from 'components/ListNavigator/ListNavigator';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  DocumentHistoryComponent,
  DocumentSummarySection,
} from 'containers/document-summary/DocumentSummarySection';
import {
  DocumentType,
  EcmDocumentStatus,
  useEcmDocumentFileQuery,
} from 'generated-types/graphql.types';
import { useFetchDocNavigationData } from 'hooks/useFetchDocNavigationData/useFetchDocNavigationData';
import { AppRouteParams, Routes } from 'models';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { DocumentTypeContextProvider } from 'views/DocumentDetails/components/DocumentTypeContext';
import { DocumentSummaryCard } from 'views/Inbox/DocumentProcessing/components/DocumentSummaryCard';
import { StorageFormContainer } from 'views/Inbox/DocumentProcessing/components/Ecm/StorageFormContainer';
import { useStorageFormInitialData } from 'views/Inbox/DocumentProcessing/components/Ecm/useStorageFormInitialData';
import { DOCUMENT_PROCESSING_SEARCH_PARAMS } from 'views/Inbox/DocumentProcessing/consts';

/**
 * Error messages are defined and come from BE
 * TODO: either localise errors in BE or introduce status code
 * to reliably show localised error in FE
 */
const GET_DOCUMENT_FILE_INVALID_ID_ERROR = 'Invalid ecm document id';
const GET_DOCUMENT_FILE_ACCESS_DENIED_ERROR =
  'Access denied due to insufficient permissions';

interface RouteParams {
  [AppRouteParams.organizationSlug]: string;
  documentId: string;
}
const possibleRoutes = [
  Routes.ECM_DOCUMENTS,
  Routes.ECM_CONTRACTS,
  Routes.ECM_SENSITIVE_CONTRACTS,
  Routes.INBOX_CONTRACTS,
  Routes.INBOX_OTHER_DOCUMENTS,
] as const;

export const EcmDocument = () => {
  const [t] = useTranslation();
  const { error: errorToast } = useToastMessage();
  const { organizationSlug, documentId } = useParams<RouteParams>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    globalDocumentId,
    defaultValues,
    documentStatus,
    loading: isDocumentDataLoading,
    isDocumentReadOnly,
  } = useStorageFormInitialData({ documentId });

  const {
    data: documentFileData,
    error,
    loading: documentFileLoading,
  } = useEcmDocumentFileQuery({
    variables: { id: documentId as string },
    skip: typeof documentId !== 'string',
    // Document file URL expires after 12 hours but the user’s session can
    // last much longer. If we use the default `cache-first` fetch policy we
    // might try to download the PDF from an expired URL.
    //
    // We use `cache-and-network` instead so that we can still show the PDF
    // viewer immediately but then rerender if the URL has changed.
    fetchPolicy: 'cache-and-network',
  });

  const documentFile = documentFileData?.getAggregatedEcmDocument.fileSource;

  const mainDocumentFile: PDFDetails = useMemo(
    () => ({
      name: documentFile?.name ?? '',
      url: documentFile?.url ?? '',
      size: documentFile?.size ?? 0,
      // @TODO BE: EcmFileSource should have an id. Using URL as id for now.
      id: documentFile?.url ?? '',
    }),
    [documentFile]
  );

  const {
    amount,
    currency,
    contact,
    documentDate,
    documentNumber,
    documentType,
    documentSubCategory,
    documentName,
  } = defaultValues;

  const document = {
    id: documentId,
    amount:
      amount && currency
        ? {
            amount: amount,
            currency: currency,
            // We pass '0' to prevent conversion to basic monetary value
            // that happens in `DocumentSummaryCardList`
            precision: 0,
          }
        : null,
    name: contact?.inputValue ?? null,
    date: documentDate ?? null,
    number:
      (documentType === DocumentType.Contract
        ? documentName
        : documentNumber) ?? null,
    status: documentStatus ?? null,
    type: documentType ?? DocumentType.Other,
    contractTypeId: documentSubCategory,
  };

  const pathname = useLocation().pathname;

  const currentRoute =
    possibleRoutes.find(route => pathname.includes(route)) ??
    Routes.ECM_DOCUMENTS;

  const {
    linkBackToList,
    navigationLoading,
    nextDocumentLink,
    prevDocumentLink,
  } = useFetchDocNavigationData({
    cursor: searchParams.get(DOCUMENT_PROCESSING_SEARCH_PARAMS.CURSOR),
    organizationSlug,
    route: currentRoute,
  });

  useEffect(() => {
    if (documentFileLoading || !error?.message) return;

    // If the document cannot be found, show error message
    // and redirect back to Archive view
    if (error?.message === GET_DOCUMENT_FILE_INVALID_ID_ERROR) {
      errorToast(t('ecm:invalidDocumentIdError'));
    } else if (error?.message === GET_DOCUMENT_FILE_ACCESS_DENIED_ERROR) {
      errorToast(t('ecm:insufficientPermissionsError'));
    }

    navigate(linkBackToList);
  }, [
    documentFileLoading,
    error?.message,
    errorToast,
    linkBackToList,
    navigate,
    t,
  ]);

  // If there's no next document and no previous document, return to list
  const cycleDocument = useCallback(() => {
    if (nextDocumentLink) {
      navigate(nextDocumentLink);
    } else if (prevDocumentLink) {
      navigate(prevDocumentLink);
    } else {
      navigate(linkBackToList);
    }
  }, [linkBackToList, nextDocumentLink, prevDocumentLink, navigate]);

  const goBackToList = useCallback(() => {
    navigate(linkBackToList);
  }, [linkBackToList, navigate]);

  const gotoNextDocument = useCallback(() => {
    if (nextDocumentLink) navigate(nextDocumentLink);
  }, [nextDocumentLink, navigate]);

  const gotoPreviousDocument = useCallback(() => {
    if (prevDocumentLink) navigate(prevDocumentLink);
  }, [prevDocumentLink, navigate]);

  return (
    <DetailsLayout
      key={documentId}
      leftSection={
        <LeftSectionOuterWrapper>
          <ListNavigator
            loading={navigationLoading}
            backToListText={t('document.backToDocumentList')}
            arrowLeftTooltip={t('document.previousDocument')}
            arrowRightTooltip={t('document.nextDocument')}
            onBackToList={goBackToList}
            onNext={nextDocumentLink ? gotoNextDocument : undefined}
            onPrev={prevDocumentLink ? gotoPreviousDocument : undefined}
          />
          <LeftSectionContentWrapper>
            {/*
            // cursed: Windows Chrome renders scrollbars outside component's border
            // we add static ScrollBox to reserve same gutter space as for
            // scrollable DocumentSummarySection 
            */}
            <ScrollBox scrollDirection="none" scrollbarGutter="stable">
              <DocumentSummaryCard
                variant="short"
                as="div"
                borderRadius="medium"
                isLoading={isDocumentDataLoading}
                document={document}
              />
            </ScrollBox>
            <ScrollBox scrollDirection="y" scrollbarGutter="stable">
              <Grid gap="space8">
                <DocumentSummarySection
                  globalDocumentId={globalDocumentId}
                  documentId={documentId}
                  documentName={mainDocumentFile.name}
                  documentStatus={documentStatus}
                  readOnly={isDocumentReadOnly}
                />
                <DocumentHistoryComponent
                  isEcmDocument
                  documentId={documentId}
                  isSensitiveDocument={defaultValues.isSensitive}
                />
              </Grid>
            </ScrollBox>
          </LeftSectionContentWrapper>
        </LeftSectionOuterWrapper>
      }
      middleSection={
        <Box
          height="100%"
          width="100%"
          paddingX="space16"
          paddingTop="space32"
          overflowY="auto"
        >
          <DocumentViewer
            documentId={documentId}
            documentFile={mainDocumentFile}
            isLoadingPdf={documentFileLoading}
          />
        </Box>
      }
      rightSection={
        <DocumentTypeContextProvider initiallyAnInvoice={false}>
          <ProcessSidebar
            documentStatus={documentStatus}
            globalDocumentId={globalDocumentId}
            documentType={defaultValues.documentType}
            isSensitiveDocument={defaultValues.isSensitive}
          >
            <StorageFormContainer
              documentId={documentId}
              onDeleteDocument={cycleDocument}
              onStoreDocument={
                documentStatus === EcmDocumentStatus.New
                  ? cycleDocument
                  : undefined
              }
            />
          </ProcessSidebar>
        </DocumentTypeContextProvider>
      }
    />
  );
};
