import { Grid, Text } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { ExpenseFormWrapper } from '../ExpenseFormWrapper';
import { ExpenseInvoicePreviewContainer } from '../ExpenseInvoicePreviewContainer';
import { generateGeneralExpenseFormFieldNames } from '../utils/generateGeneralExpenseFormFieldNames';
import {
  ReimbursementCaseStatus,
  ReimbursementItemStatus,
} from 'generated-types/graphql.types';
import { FieldsOptions } from '../utils/useFieldsOptions';

interface ReadonlyGeneralExpensesFormProps {
  formIndex: number;
  expenseId: string;
  reason: string | undefined;
  totalAmount?: number | null;
  invoice?: ExpenseInvoice;
  reimbursementStatus?: ReimbursementCaseStatus;
  itemStatus?: ReimbursementItemStatus;
  isExcluded: boolean;
}
export const ReadonlyGeneralExpensesForm = ({
  expenseId,
  formIndex,
  reason,
  invoice,
  totalAmount,
  reimbursementStatus,
  itemStatus,
  isExcluded,
}: ReadonlyGeneralExpensesFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const fields = generateGeneralExpenseFormFieldNames(formIndex);

  return (
    <ExpenseFormWrapper
      expenseId={expenseId}
      totalAmount={totalAmount}
      expenseType="general"
      isExcluded={isExcluded}
      reason={reason}
      reimbursementStatus={reimbursementStatus}
      itemStatus={itemStatus}
    >
      <Grid as="form" gap="space16">
        <Grid columns={2} gap="space8">
          <HookFormTextField
            name={fields.reason}
            readOnly
            label={t(
              'reimbursementView.middleSection.form.general.reason.label'
            )}
            ariaLabel={t(
              'reimbursementView.middleSection.form.general.reason.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.general.reason.placeholder'
            )}
          />
          <HookFormDatePickerField
            name={fields.expenseDate}
            readOnly
            label={t('reimbursementView.middleSection.form.general.date.label')}
          />
        </Grid>
        <Grid columns={2} gap="space8">
          <HookFormTextField
            name={fields.invoiceNumber}
            readOnly
            label={t(
              'reimbursementView.middleSection.form.general.invoiceNumber.label'
            )}
            ariaLabel={t(
              'reimbursementView.middleSection.form.general.invoiceNumber.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.general.invoiceNumber.placeholder'
            )}
          />
          <HookFormAmountField
            name={fields.totalAmount}
            readOnly
            label={t(
              'reimbursementView.middleSection.form.general.totalAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.general.totalAmount.placeholder'
            )}
            currency="EUR"
          />
        </Grid>
        <ExpenseInvoicePreviewContainer
          name={fields.files}
          expenseType="general"
          id={`invoice-picker-${fields.files}`}
          invoice={invoice}
          onChange={Promise.resolve}
          isReadOnly
          isExcluded={isExcluded}
        />
        {isExcluded && (
          <Text fontSize="basic" justifySelf="end">
            {t(
              'reimbursementView.middleSection.form.general.expenseIsExcludedInfo'
            )}
          </Text>
        )}
      </Grid>
    </ExpenseFormWrapper>
  );
};
