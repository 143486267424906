export const CREDIT_CARDS_UTM_SOURCE = {
  DASHBOARD: 'dashboard',
  SETTINGS: 'settings',
  ARCHIVE: 'archive-table',
  INBOX: 'inbox-table',
  EXPORT_CARD_SETTLEMENT: 'export-card-settlement-table',
  EXPORT_APPROVAL: 'export-approval-table',
};

export const PROVISIONS_UTM_SOURCE = {
  SETTINGS: 'settings',
  APPROVALS_BANNER: 'approvals-banner',
  EXPORT_PROVISIONS: 'export-provisions-table',
  EXPORT_REVERSALS: 'export-reversals-table',
  EXPORT_SIDEBAR: 'export-sidebar',
  PAYMENT_TABLE: 'payment-table',
  SETTINGS_DATEV_INTEGRATION: 'settings-datev',
  SETTINGS_DATEV_INTEGRATION_DUO: 'settings-datev-duo',
  PAYMENT_SETTINGS: 'payment-settings',
  CONTACTS: 'contacts',
};

export const CONTRACT_UTM_SOURCE = {
  ARCHIVE: 'archive-contracts',
  STORAGE_FORM: 'storage-form-contracts',
  INBOX: 'inbox-contracts',
};

export const DOCUMENT_MANAGEMENT_UTM_SOURCE = {
  ARCHIVE: 'archive-documents',
  DOCUMENTS_TABLE: 'documents-table',
  DOCUMENTS_RELATIONS: 'document-relations',
  INBOX_SENSITIVE_DOCUMENTS: 'inbox-sensitive-documents',
  INBOX_DOCUMENT_PROCESSING: 'inbox-document-processing',
};

export const UPSELL_PLUS_UTM_SOURCE = {
  ABSENCE_FORM: 'absence-form',
  ARCHIVE: 'archive',
  PAYMENT_SETTINGS_SEPA_XML: 'payment-settings-sepa-xml',
};
