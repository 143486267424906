import {
  Locale,
  TransactionDeclinedReason,
  TransactionStatus,
  TransactionType,
} from 'generated-types/graphql.types';

export type NoDocumentInfoBoxProps = {
  type?: TransactionType | null;
  status?: TransactionStatus | null;
  locale?: Locale | null;
  localizedDeclineReason?: TransactionDeclinedReason | null;
};

export enum InfoBoxVariant {
  DECLINED = 'DECLINED',
  REVERSED = 'REVERSED',
}

type InfoBoxManifest = {
  title?: string;
  description: string;
};

type GetDeclinedReasonProps = {
  locale?: Locale | null;
  localizedDeclineReason?: TransactionDeclinedReason | null;
};

const infoBoxVariant: Record<InfoBoxVariant, InfoBoxManifest> = {
  [InfoBoxVariant.REVERSED]: {
    description: 'transactionDetailsCard.noFileInfoBox.reversed',
  },
  [InfoBoxVariant.DECLINED]: {
    title: 'transactionDetailsCard.noFileInfoBox.declinedTitle',
    description: 'transactionDetailsCard.noFileInfoBox.declinedDescription',
  },
};

export const getInfoBoxVariant = (status?: TransactionStatus | null) => {
  let variant: InfoBoxVariant;

  if (status === TransactionStatus.Reversed) {
    variant = InfoBoxVariant.REVERSED;
  } else variant = InfoBoxVariant.DECLINED;

  return infoBoxVariant[variant];
};

export const getDeclineReason = ({
  locale,
  localizedDeclineReason,
}: GetDeclinedReasonProps) => {
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  let declineTitle: string = localizedDeclineReason?.title?.en!;
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  let declineDescription: string = localizedDeclineReason?.description?.en!;

  if (locale === Locale.De) {
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    declineTitle = localizedDeclineReason?.title?.de!;
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    declineDescription = localizedDeclineReason?.description?.de!;
  }

  return { declineTitle, declineDescription };
};
