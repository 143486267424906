import { ApolloError } from '@apollo/client';
import {
  CreateCostCenterInput,
  useBulkImportCostCentersMutation,
  useCostCenterRequestImportFileUploadUrlMutation,
} from 'generated-types/graphql.types';
import { useCallback, useState } from 'react';
import { uploadFile } from 'utils/upload';
import { useCostCenterRefetchQueries } from '../../hooks/useCostCenterRefetchQueries';

type ImportCostCentersCallback = {
  loading: boolean;
  errors?: ApolloError[];
};

type UseImportCostCentersHook = [
  (
    costCenters: CreateCostCenterInput[],
    file: File | null
  ) => Promise<{ successCount: number; errorCount: number }>,
  ImportCostCentersCallback,
];

export const useImportCostCenters = (): UseImportCostCentersHook => {
  const { refetchQueries, resetPaginationResults } =
    useCostCenterRefetchQueries();

  const [bulkImport] = useBulkImportCostCentersMutation({
    refetchQueries,
    onCompleted: resetPaginationResults,
  });

  const [requestFileUploadUrl] =
    useCostCenterRequestImportFileUploadUrlMutation();

  const [importLoading, setImportLoading] = useState<boolean>(false);
  const [importError, setImportError] = useState<ApolloError | null>(null);

  const doImport = useCallback(
    async (costCenters: CreateCostCenterInput[], file: File | null) => {
      let successCount = 0;
      let errorCount = 0;

      setImportLoading(true);
      setImportError(null);

      try {
        let fileId: string | null = null;

        if (file) {
          const { data: requestFileUploadUrlData } = await requestFileUploadUrl(
            {
              variables: {
                fileName: file.name,
                contentType: 'text/csv',
              },
            }
          );

          const uploadOptions =
            requestFileUploadUrlData?.costCenterRequestImportFileUploadURL;

          if (uploadOptions && uploadOptions.url) {
            await uploadFile(
              file,
              uploadOptions.postOptions || [],
              uploadOptions.url
            );
            fileId = uploadOptions.id;
          }
        }

        const { data, errors } = await bulkImport({
          variables: { input: costCenters, fileId },
        });

        if (data?.bulkImportCostCenters) {
          successCount = data.bulkImportCostCenters.imported.length;
          errorCount = data.bulkImportCostCenters.errors.length;
        } else if (errors?.length) {
          errorCount = errors?.length;
          setImportError(new ApolloError({ graphQLErrors: errors }));
        }
      } catch (e) {
        setImportError(e as ApolloError);
      } finally {
        setImportLoading(false);
      }

      return { successCount, errorCount };
    },
    [bulkImport, requestFileUploadUrl]
  );

  const loading = importLoading;
  const errors = [...(importError ? [importError] : [])];

  return [doImport, { loading, errors }];
};
