import { Avatar, Grid, useTheme } from '@candisio/design-system';
import { AvatarUpload } from 'components/AvatarUpload/AvatarUpload';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { Locale } from 'generated-types/graphql.types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AbsenceFormOutput } from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/absenceFormSchema';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { ReadonlyEmailField } from '../components/ReadonlyEmailField/ReadonlyEmailField';
import {
  TeamMemberFormOutput,
  TeamMemberFormSchemaOptions,
} from '../teamMemberFormSchema';
import { InactiveStatusBar } from './InactiveStatusBar';

export type TeamMemberWithAbsenceFormOutput = TeamMemberFormOutput &
  AbsenceFormOutput;

export interface InactiveTeamMemberFormProps {
  isSubmittingActivateMembership?: boolean;
  onActivateMembership: () => void;
  selectedUser: User;
}

export const InactiveTeamMemberForm = ({
  onActivateMembership,
  selectedUser,
  isSubmittingActivateMembership,
}: InactiveTeamMemberFormProps) => {
  const [t] = useTranslation();
  const { colors } = useTheme();

  const locales = Object.values(Locale).map(value => ({
    children: t(`locales.${value}`),
    key: value,
  }));

  const form = useForm<
    TeamMemberWithAbsenceFormOutput,
    TeamMemberFormSchemaOptions
  >({
    mode: 'all',
    defaultValues: {
      firstName: selectedUser.firstName,
      lastName: selectedUser.lastName,
      locale: selectedUser.locale ?? Locale.De,
      email: selectedUser.email,
      roles: [],
    },
  });

  return (
    <FormProvider {...form}>
      <Grid as="form" gap="space24" templateRows="1fr auto">
        <InactiveStatusBar
          onRestore={onActivateMembership}
          loading={Boolean(isSubmittingActivateMembership)}
        />
        <Grid gap="space24">
          <AvatarUpload readonly>
            <Avatar
              name={selectedUser?.name}
              img={selectedUser?.avatarUrl ?? undefined}
              size="large"
              hasTooltip={false}
            />
          </AvatarUpload>
          <Grid
            background="gray100"
            borderRadius="8px"
            padding="space16"
            rowGap="space12"
            columnGap="space32"
            templateColumns="1fr 1fr 1fr 1fr"
            templateRows="auto auto auto"
          >
            <Grid gridRow="1" gridColumn="1/3">
              <HookFormTextField
                disabled
                autoFocus
                inputProps={{
                  style: {
                    color: colors.gray500, // TODO this should be possible from top lvl prop
                  },
                }}
                label={t('settings.teamMembers.form.firstName.label')}
                name="firstName"
              />
            </Grid>
            <Grid gridRow="1" gridColumn="3/5">
              <HookFormTextField
                name="lastName"
                inputProps={{
                  style: {
                    color: colors.gray500, // TODO this should be possible from top lvl prop
                  },
                }}
                disabled
                label={t('settings.teamMembers.form.lastName.label')}
              />
            </Grid>
            <Grid
              gridRow="2"
              gridColumn="1/4"
              autoFlow="column"
              gap="space20"
              alignItems="center"
            >
              <ReadonlyEmailField fieldName="email" />
            </Grid>
            <Grid gridRow="3" gridColumn="1/3">
              <HookFormSelectField
                name="locale"
                disabled
                label={t('settings.teamMembers.form.locale')}
                items={locales}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
