import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Promotion } from '../types';

const PromotionsContext = createContext<{
  currentCardIndex: number;
  currentBenefitIndex: number;
  visiblePromotions: Promotion[];
  goToSlide?: (index: number) => void;
  goToBenefit?: (index: number) => void;
  pauseAutoPlayCard?: () => void;
  resumeAutoPlayCard?: () => void;
  hidePromotionCard?: (promotionId: string) => void;
}>({
  currentCardIndex: 0,
  currentBenefitIndex: 0,
  visiblePromotions: [],
  goToSlide: undefined,
  goToBenefit: undefined,
  pauseAutoPlayCard: undefined,
  resumeAutoPlayCard: undefined,
  hidePromotionCard: undefined,
});

export const HIDDEN_PROMOTION_CARDS = 'hidden-promotion-cards';

export const usePromotionsContext = () => useContext(PromotionsContext);

type Timeout = ReturnType<typeof setInterval> | string | number;

export const PromotionsProvider = ({
  autoPlayCard = true,
  autoPlayBenefits = true,
  autoPlayBenefitInterval = 30000,
  autoPlayCardInterval = 300000,
  promotions,
  children,
}: {
  promotions: Promotion[];
  children: ReactNode;
  autoPlayBenefits?: boolean;
  autoPlayBenefitInterval?: number;
  autoPlayCard?: boolean;
  autoPlayCardInterval?: number;
}) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [currentBenefitIndex, setCurrentBenefitIndex] = useState(0);
  const [shouldAutoPlayCard, setShouldAutoPlayCard] = useState(autoPlayCard);

  const intervalRefBenefits = useRef<Timeout | null>(null);
  const intervalRefCards = useRef<Timeout | null>(null);

  const [hiddenCards, setHiddenCards] = useLocalStorage<string[]>(
    HIDDEN_PROMOTION_CARDS,
    []
  );

  const visiblePromotions = useMemo(
    () => promotions.filter(promotion => !hiddenCards.includes(promotion.id)),
    [promotions, hiddenCards]
  );

  const hidePromotionCard = useCallback(
    (promotionId: string) => {
      if (!hiddenCards.includes(promotionId)) {
        setHiddenCards([...hiddenCards, promotionId]);

        setCurrentCardIndex(prevIndex => {
          const newLength = visiblePromotions.length - 1;
          if (newLength === 0) {
            return 0;
          }

          return prevIndex >= newLength ? 0 : prevIndex;
        });
      }
    },
    [hiddenCards, setHiddenCards, visiblePromotions.length]
  );

  const promotionsCount = visiblePromotions.length;
  const shownPromotion = visiblePromotions[currentCardIndex];
  const benefitsCount = shownPromotion?.benefits?.length ?? 0;

  const goToBenefit = useCallback(
    (index: number) => {
      setCurrentBenefitIndex(index);

      if (!shouldAutoPlayCard || !autoPlayBenefits || benefitsCount <= 1)
        return;

      intervalRefBenefits.current && clearInterval(intervalRefBenefits.current);
      intervalRefBenefits.current = setInterval(() => {
        setCurrentBenefitIndex(prevIndex => (prevIndex + 1) % benefitsCount);
      }, autoPlayBenefitInterval);
    },
    [
      autoPlayBenefitInterval,
      autoPlayBenefits,
      shouldAutoPlayCard,
      benefitsCount,
    ]
  );

  // Auto-play benefits
  useEffect(() => {
    if (!shouldAutoPlayCard || !autoPlayBenefits || benefitsCount <= 1) return;

    intervalRefBenefits.current = setInterval(() => {
      setCurrentBenefitIndex(prevIndex => (prevIndex + 1) % benefitsCount);
    }, autoPlayBenefitInterval);

    return () => {
      intervalRefBenefits.current && clearInterval(intervalRefBenefits.current);
    };
  }, [
    autoPlayBenefitInterval,
    autoPlayBenefits,
    shouldAutoPlayCard,
    benefitsCount,
  ]);

  const goToCard = useCallback(
    (index: number) => {
      setCurrentCardIndex(index);

      if (!shouldAutoPlayCard || promotionsCount <= 1) return;

      const autoPlayInterval = benefitsCount
        ? autoPlayBenefitInterval * benefitsCount
        : autoPlayCardInterval;

      intervalRefCards.current && clearInterval(intervalRefCards.current);
      intervalRefCards.current = setInterval(() => {
        setCurrentCardIndex(prevIndex => (prevIndex + 1) % promotionsCount);
      }, autoPlayInterval);
    },
    [
      autoPlayBenefitInterval,
      autoPlayCardInterval,
      benefitsCount,
      promotionsCount,
      shouldAutoPlayCard,
    ]
  );

  // Auto-play cards
  useEffect(() => {
    if (!shouldAutoPlayCard || promotionsCount <= 1) return;

    const autoPlayInterval = benefitsCount
      ? autoPlayBenefitInterval * benefitsCount
      : autoPlayCardInterval;

    intervalRefCards.current = setInterval(() => {
      setCurrentCardIndex(prevIndex => (prevIndex + 1) % promotionsCount);
    }, autoPlayInterval);

    return () => {
      intervalRefCards.current && clearInterval(intervalRefCards.current);
    };
  }, [
    autoPlayBenefitInterval,
    shouldAutoPlayCard,
    autoPlayCardInterval,
    benefitsCount,
    promotionsCount,
  ]);

  // Reset card index when initial auto-play is toggled
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setCurrentCardIndex(0);
  }, [autoPlayCard]);

  // Reset benefit index when card changes or auto-play is toggled
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setCurrentBenefitIndex(0);
  }, [shouldAutoPlayCard, currentCardIndex]);

  const pauseAutoPlayCard = useCallback(() => {
    setShouldAutoPlayCard(false);
  }, []);

  const resumeAutoPlayCard = useCallback(() => {
    setShouldAutoPlayCard(true);
  }, []);

  const value = useMemo(
    () => ({
      currentCardIndex,
      currentBenefitIndex,
      goToSlide: goToCard,
      goToBenefit,
      visiblePromotions,
      pauseAutoPlayCard,
      resumeAutoPlayCard,
      hidePromotionCard,
    }),
    [
      currentBenefitIndex,
      currentCardIndex,
      goToBenefit,
      goToCard,
      visiblePromotions,
      pauseAutoPlayCard,
      resumeAutoPlayCard,
      hidePromotionCard,
    ]
  );

  return (
    <PromotionsContext.Provider value={value}>
      {children}
    </PromotionsContext.Provider>
  );
};
