import { Trans } from 'providers/LocaleProvider';

export interface ImportStatusProps {
  failedToImportDocumentsCount?: number | null;
  successfullyCreatedDocumentsCount?: number | null;
}

export const ImportStatus = (props: ImportStatusProps) => {
  const failedCount = props?.failedToImportDocumentsCount ?? 0;
  const successCount = props?.successfullyCreatedDocumentsCount ?? 0;
  const totalImportCount = failedCount + successCount;
  const hasFailedImport = failedCount > 0 && successCount === 0;
  const hasSuccessfullImport = failedCount === 0;

  if (hasSuccessfullImport) {
    return (
      <Trans
        i18nKey="purchase-orders:goodsReceiptHistory.status.successCount"
        count={successCount}
        values={{ successCount }}
      >
        {{ successCount } as any} goods receipts
      </Trans>
    );
  }

  if (hasFailedImport) {
    return (
      <Trans
        i18nKey="purchase-orders:goodsReceiptHistory.status.failedCount"
        count={failedCount}
        values={{ failedCount }}
      >
        {{ failedCount } as any} goods receipt failed
      </Trans>
    );
  }

  return (
    <Trans
      i18nKey="purchase-orders:goodsReceiptHistory.totalCount"
      count={totalImportCount}
      values={{ failedCount, totalImportCount }}
    >
      {{ failedCount } as any} of {{ totalImportCount }} goods receipts failed
    </Trans>
  );
};
