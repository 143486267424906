import { Grid, ScrollBox, Spinner } from '@candisio/design-system';
import { DocumentTagsPromotionSettings } from 'components/ProductPromotions/DocumentTags/DocumentTagsPromotionSettings';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { compiledRoutes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { useTeams } from 'orgConfig/teams/useTeams';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { Suspense, lazy } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import { Routes, Route as V6Route } from 'react-router-dom-v5-compat';
import { Route } from 'router/SentryRouting';
import { AccountingAreas } from 'views/Settings/AccountingAreas/AccountingAreas';
import { ApiSettings } from 'views/Settings/ApiSettings/ApiSettings';
import { MobileApplicationContainer } from 'views/Settings/MobileApplication/MobileApplicationContainer';
import { TaxCodeImportHistory } from 'views/Settings/TaxCodes/TaxCodeImportHistory/TaxCodeImportHistory';
import { TaxCodes } from 'views/Settings/TaxCodes/TaxCodes';
import { ContractSubCategoriesPromotionSettings } from '../../components/ProductPromotions/Contracts/ContractSubCategoriesPromotionSettings';
import { BookingAccounts } from './BookingAccounts/BookingAccounts';
import { BookingAccountImportHistory } from './BookingAccounts/components/BookingAccountImportHistory/BookingAccountImportHistory';
import { ContractSubCategories } from './ContractSubCategories/ContractSubCategories';
import { CostCenters } from './CostCenters/CostCenters';
import { CostCenterImportHistory } from './CostCenters/components/CostCenterImportHistory/CostCenterImportHistory';
import { CreditCards } from './CreditCards';
import { DocumentTags } from './DocumentTags/DocumentTags';
import { DocumentTypes } from './DocumentTypes/DocumentTypes';
import { DatevSettings } from './Integrations/DATEV';
import { SapSettings } from './Integrations/SAP';
import { MailSync } from './MailSync/MailSync';
import { Organization } from './Organization/Organization';
import { PaymentConditionsRoute } from './PaymentConditions';
import { PaymentConditionsImportHistory } from './PaymentConditions/PaymentConditionsImportHistory/PaymentConditionsImportHistory';
import { PaymentsSettings } from './PaymentsSettings';
import { ProjectCodes } from './ProjectCodes/ProjectCodes';
import { ProvisionsSettings } from './Provisions/ProvisionsSettings';
import { SettingsNavigation } from './SettingsNavigation';
import { SingleSignOnRoute } from './SingleSignOn/SingleSignOnRoute';
import { SuggestionsAndExtractions } from './SuggestionsAndExtractions/SuggestionsAndExtractions';
import { TeamMembers } from './TeamMembers';
import { Teams } from './Teams/Teams';
import { WorkflowTemplates } from './WorkflowTemplates/WorkFlowTemplates';
import { SETTINGS_ROUTES } from './types';

// We split GraphQL playground into a separate chunk so normal users don’t
// unnecessarily download its dependencies
const GraphiQLEditor = lazy(async () => {
  const module = await import('./GraphiQL');

  return { default: module.GraphiQLEditor };
});

const SettingsLoadingFallback = () => (
  <Grid height="100%" placeContent="center">
    <Spinner size="space64" color="gray400" />
  </Grid>
);

export const Settings = ({ match: { path } }: RouteComponentProps) => {
  const { isTeamsFeatureEnabled } = useTeams();
  const { shouldUseCoreDataApi, shouldUseCandisApi } = useOtherIntegration();
  const { showEcm, showDocumentTags, showContractCustomSubCategories } =
    useEcm();
  const [ssoConfigFF, documentTypesSettingFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.ssoConfig,
    FEATURE_FLAGS.documentTypesSetting,
  ]);
  const { shouldUseSapProjectCodes } = useSap();

  const showDocumentTypes = documentTypesSettingFF && showEcm;

  return (
    <Grid paddingTop="space8" templateColumns="max-content 1fr" height="100%">
      <SettingsNavigation />
      <ScrollBox padding="space16 space40 space40 space24">
        <Suspense fallback={<SettingsLoadingFallback />}>
          <Switch>
            <Route
              path={`${path}${compiledRoutes.bookingAccountImportHistoryRoute}`}
              component={BookingAccountImportHistory}
            />
            <Route
              path={`${path}${compiledRoutes.costCenterImportHistoryRoute}`}
              component={CostCenterImportHistory}
            />
            <Route
              path={`${path}${compiledRoutes.editCostCenterRoute}`}
              component={CostCenters}
            />
            <Route
              path={`${path}${compiledRoutes.editBookingAccountRoute}`}
              component={BookingAccounts}
            />
            <Route
              path={`${path}${compiledRoutes.editTaxCodeRoute}`}
              component={TaxCodes}
            />
            <Route
              path={`${path}${compiledRoutes.taxCodeImportHistoryRoute}`}
              component={TaxCodeImportHistory}
            />
            <Redirect
              from={`${path}${compiledRoutes.editBookingKeyRoute}`}
              to={`${path}${compiledRoutes.editTaxCodeRoute}`}
            />
            <Route
              path={`${path}${compiledRoutes.editUserRoute}`}
              component={TeamMembers}
            />
            <Route
              path={`${path}${compiledRoutes.provisionsRoute}`}
              component={ProvisionsSettings}
            />
            <Route
              path={`${path}${compiledRoutes.datevRoute}`}
              component={DatevSettings}
            />
            <Route
              path={`${path}${compiledRoutes.sapRoute}`}
              component={SapSettings}
            />
            {!shouldUseCoreDataApi && (
              <Route
                path={`${path}${compiledRoutes.paymentsRoute}`}
                component={PaymentsSettings}
              />
            )}
            <Route
              path={`${path}${compiledRoutes.editWorkflowRoute}`}
              component={WorkflowTemplates}
            />
            <Route
              path={`${path}${compiledRoutes.editOrganizationRoute}`}
              component={Organization}
            />
            <Route
              path={`${path}${compiledRoutes.graphiqlEditorRoute}`}
              component={GraphiQLEditor}
            />
            <Route
              path={`${path}${compiledRoutes.setSmartFieldSuggestionsRoute}`}
              component={SuggestionsAndExtractions}
            />
            <Route
              path={`${path}${compiledRoutes.creditCardsRoute}`}
              component={CreditCards}
            />
          </Switch>

          <Routes>
            <V6Route
              path={`settings/${SETTINGS_ROUTES.MAIL}`}
              Component={MailSync}
            />
            <V6Route
              path={`settings/${SETTINGS_ROUTES.PAYMENT_CONDITIONS_IMPORT_HISTORY}`}
              Component={PaymentConditionsImportHistory}
            />
            <V6Route
              path={`settings/${SETTINGS_ROUTES.PAYMENT_CONDITIONS}/:id?`}
              Component={PaymentConditionsRoute}
            />
            <V6Route
              path={`settings/${SETTINGS_ROUTES.ACCOUNTING_AREAS}/:id?`}
              Component={AccountingAreas}
            />
            <V6Route
              path={`settings/${SETTINGS_ROUTES.MOBILE_APPLICATION}`}
              Component={MobileApplicationContainer}
            />
            {shouldUseCandisApi && (
              <V6Route
                path={`settings/${SETTINGS_ROUTES.CANDIS_API}`}
                Component={ApiSettings}
              />
            )}
            {ssoConfigFF && (
              <V6Route
                path={`settings/${SETTINGS_ROUTES.SINGLE_SIGN_ON}/:id?`}
                Component={SingleSignOnRoute}
              />
            )}
            {showDocumentTags && (
              <V6Route
                path={`settings/${SETTINGS_ROUTES.TAGS}/:id?`}
                Component={DocumentTags}
              />
            )}
            {!showDocumentTags && (
              <V6Route
                path={`settings/${SETTINGS_ROUTES.TAGS}/:id?`}
                Component={DocumentTagsPromotionSettings}
              />
            )}
            {showDocumentTypes && (
              <V6Route
                path={`settings/${SETTINGS_ROUTES.DOCUMENT_TYPES}`}
                Component={DocumentTypes}
              />
            )}
            {showContractCustomSubCategories && (
              <V6Route
                path={`settings/${SETTINGS_ROUTES.CONTRACT_SUBCATEGORIES}/:id?`}
                Component={ContractSubCategories}
              />
            )}
            {!showContractCustomSubCategories && (
              <V6Route
                path={`settings/${SETTINGS_ROUTES.CONTRACT_SUBCATEGORIES}/:id?`}
                Component={ContractSubCategoriesPromotionSettings}
              />
            )}

            {isTeamsFeatureEnabled && (
              <V6Route
                path={`settings/${SETTINGS_ROUTES.TEAMS}/:id?/:additionalSetup?`}
                Component={Teams}
              />
            )}
            {shouldUseSapProjectCodes && (
              <V6Route
                path={`settings/${SETTINGS_ROUTES.PROJECT_CODES}/:id?`}
                Component={ProjectCodes}
              />
            )}
          </Routes>
        </Suspense>
      </ScrollBox>
    </Grid>
  );
};
