// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { Redirect, Switch } from 'react-router-dom';
import { Route } from 'router/SentryRouting';
import { ChangeEmailRequest } from './ChangeEmailRequest';
import { IamRoutes } from './routes';

export const IamBaseHandler = () => (
  <Switch>
    <Route
      exact
      path={`${IamRoutes.CHANGE_EMAIL_REQUEST}/:requestId`}
      component={ChangeEmailRequest}
    />
    <Redirect to="/" />
  </Switch>
);
