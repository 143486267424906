import { Button } from '@candisio/design-system';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from '../../../../../../../../components/ProgressBar/ProgressBar';
import { ActionBar } from '../../../../../../../../components/Sidebar/ActionBar';
import { useGetAvailableMonthsQuery } from '../../../../../../../../generated-types/graphql.types';
import { ExportContext } from '../../../../../Context';
import { useIsExporting } from '../../../../../toolkit/hooks/useIsExporting';
import { Table } from '../../../../../types';

export const SapCsvExportButton = ({
  onExport,
  loading,
}: {
  onExport: () => void;
  loading?: boolean;
}) => {
  const [t] = useTranslation();
  const { exportId, table } = useContext(ExportContext);
  const { isExporting } = useIsExporting();

  const { loading: loadingAvailableMonthsData } = useGetAvailableMonthsQuery({
    variables: {
      originalExportId: exportId,
    },
    fetchPolicy: 'no-cache',
  });

  if (loadingAvailableMonthsData) {
    return (
      <ActionBar>
        <ProgressBar completion={0}>
          {t('export.exportForm.actionBar.loading')}
        </ProgressBar>
      </ActionBar>
    );
  }

  if (isExporting) {
    return (
      <ActionBar>
        <ProgressBar completion={0}>
          {t('export.exportForm.actionBar.exporting')}
        </ProgressBar>
      </ActionBar>
    );
  }

  return (
    <ActionBar>
      <Button
        disabled={table === Table.PROVISIONS || table === Table.REVERSALS}
        onClick={() => onExport()}
        type="submit"
        loading={loading}
        data-cy="submit"
      >
        {t('export.exportForm.actionBar.export')}
      </Button>
    </ActionBar>
  );
};
