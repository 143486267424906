import {
  Button,
  Card,
  Grid,
  Icon,
  Image,
  Spinner,
  Text,
} from '@candisio/design-system';
import slackIcon from 'assets/integrations/slack-icon.svg';
import {
  useCurrentSlackTokenQuery,
  useObtainNewSlackTokenMutation,
} from 'generated-types/graphql.types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { currentSlackTokenQuery } from '../../../queries';

export const SlackIntegrationCard = () => {
  const [t] = useTranslation();
  const { data, loading } = useCurrentSlackTokenQuery();
  const slackIntegrationEnabled = data?.currentSlackToken?.tokenValid ?? false;
  const [obtainNewSlackTokenMutation] = useObtainNewSlackTokenMutation();
  const [searchParams] = useSearchParams();

  const handleConnectSlack = () => {
    if (!slackIntegrationEnabled) {
      window.location.href = data?.currentSlackToken?.redirectUrl ?? '#';
    }
  };

  const code = searchParams.get('code');
  useEffect(() => {
    if (code) {
      const state = searchParams.get('state') ?? '';
      void obtainNewSlackTokenMutation({
        variables: { token: { code, state } },
        refetchQueries: [{ query: currentSlackTokenQuery }],
      });
    }
  }, [code, obtainNewSlackTokenMutation, searchParams]);

  if (loading) {
    return <Spinner color="gray400" size="space64"></Spinner>;
  }

  return (
    <Card background="gray100" padding="space16">
      <Grid gap="space16" justifyContent="start">
        <Grid
          gap="space8"
          templateColumns="repeat(2, max-content)"
          alignItems="center"
        >
          <Image
            src={slackIcon}
            alt="Slack Icon"
            height="space18"
            width="space18"
          />
          <Text fontSize="basic" fontWeight="semibold">
            {t(
              'header.profile.tabs.update.notificationIntegration.slack.title'
            )}
          </Text>
        </Grid>
        {slackIntegrationEnabled ? (
          <Grid autoFlow="column" gap="space4" alignItems="center">
            <Icon icon="checkCircle" color="green700"></Icon>
            <Text fontSize="basic">
              {t(
                'header.profile.tabs.update.notificationIntegration.slack.connected'
              )}
            </Text>
          </Grid>
        ) : (
          <Button variant="secondary" color="blue" onClick={handleConnectSlack}>
            {t(
              'header.profile.tabs.update.notificationIntegration.slack.connectButton'
            )}
          </Button>
        )}
      </Grid>
    </Card>
  );
};
