import { Button, Flex, Image, Tag, Text } from '@candisio/design-system';
import { PromotionLink } from 'components/PromotionLink/PromotionLink';
import { VideoCard } from 'containers/Entitlements/components/Upsell/components/VideoCard';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import { thumbnails } from 'containers/Entitlements/components/Upsell/utils';
import { appointmentLinks } from 'containers/Entitlements/toolkit/utils';
import { Locale } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { InboxViewContainer } from '../components/InboxViewContainer';
import { TabView } from '../models';
import { SensitiveDocumentsHeader } from './EcmSensitiveDocumentsHeader';
import { DOCUMENT_MANAGEMENT_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';

export const EcmInboxSensitiveDocumentsPromo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const thumbnail = thumbnails[FEATURE.CONTRACTS][locale];

  return (
    <>
      <SensitiveDocumentsHeader />
      <InboxViewContainer activeTab={TabView.INBOX_ECM_SENSITIVE_DOCUMENTS}>
        <Flex
          direction="column"
          gap="space16"
          paddingX="44px"
          maxWidth="1300px"
        >
          <Tag color="purple" variant="secondary">
            {t('promo.tag')}
          </Tag>
          <Text as="h2" fontSize="xxlarge">
            <Trans t={t} i18nKey="promo.contractTable.title">
              <Text fontWeight="bold">Contract management</Text>
              Behalte den Überblick über laufende Verträge und Fristen
            </Trans>
          </Text>
          <VideoCard feature={FEATURE.CONTRACTS} withFading>
            <Image src={thumbnail} alt="" width="100%" opacity=".7" />
          </VideoCard>
          <Flex
            direction="column"
            alignItems="center"
            gap="space16"
            alignSelf="center"
            width="fit-content"
          >
            <PromotionLink
              href={t(appointmentLinks[FEATURE.CONTRACTS])}
              variant="calendar"
            >
              {t('promo.modal.bookAppointment')}
            </PromotionLink>
            <UpsellPromo
              feature={FEATURE.CONTRACTS}
              utmSource={
                DOCUMENT_MANAGEMENT_UTM_SOURCE.INBOX_SENSITIVE_DOCUMENTS
              }
            >
              <Button variant="tertiary" color="promo" width="100%">
                {t('promo.contractTable.moreInfoCTA')}
              </Button>
            </UpsellPromo>
          </Flex>
        </Flex>
      </InboxViewContainer>
    </>
  );
};
