import gql from 'graphql-tag';
import { pageInfoDataFragment } from 'views/queries/fragments';

export const archiveReimbursementsQuery = gql`
  query archiveReimbursements(
    $input: ReimbursementCasePaginationInput!
    $filters: ReimbursementCaseFiltersInput
    $sortBy: ReimbursementCaseSortInput
    $queries: ReimbursementCaseQueryInput
  ) {
    reimbursementCasesCursorBased(
      input: $input
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    ) {
      pageInfo {
        ...PageInfoData
      }
      edges {
        cursor
        node {
          id
          createdAt
          isPaid
          contact {
            iban
          }
          paidAt
          firstSubmittedForReviewAt
          title
          status
          lastApprovedByMembership {
            id
            firstName
            lastName
            avatarUrl
          }
          targetMembership {
            id
            firstName
            lastName
            avatarUrl
          }
          totalAmount {
            amount
            currency
            precision
          }
        }
      }
    }
  }
  ${pageInfoDataFragment}
`;
