import { CSSObject } from '@emotion/react';
import React, { ReactElement, ReactNode } from 'react';
import mergeRefs from 'react-merge-refs';
import { TruncatedText } from '../../Molecules/Typography/TruncatedText';
import { useTheme } from '../../Theme';
import { LayoutProps } from '../../types';
import { AnimationCSSObject } from '../../Utilities/AnimationBox';
import { SwitchAnimationBox } from '../../Utilities/SwitchAnimationBox';
import { Box } from '../Box';
import { Flex } from '../Flex';
import { Icon, IconProps } from '../Icon';
import { Skeleton } from '../Skeleton';
import { Tooltip, useTooltip } from '../Tooltip';
import { useChip } from './useChip';

export interface ToggleableTypeProps {
  type: 'toggleable';
  selectLabel: string;
  unselectLabel: string;
  removeLabel?: never;
  onChange?: (isSelected: boolean) => void;
  onClickButton?: never;
  defaultSelected?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
}

export interface RemovableTypeProps {
  type: 'removable';
  selectLabel?: never;
  unselectLabel?: never;
  removeLabel?: string;
  onChange?: never;
  onClickButton?: () => void;
  defaultSelected?: never;
  isSelected?: never;
  isDisabled?: boolean;
}

export interface BasicProps {
  type: 'basic';
  selectLabel?: never;
  unselectLabel?: never;
  removeLabel?: never;
  onChange?: never;
  onClickButton?: never;
  isSelected?: never;
  isDisabled?: never;
  defaultSelected?: never;
}

export type TypeProps = ToggleableTypeProps | RemovableTypeProps | BasicProps;

export interface ChipProps extends LayoutProps {
  children: string | ReactNode;
  size: 'xsmall' | 'small';
  variant?: 'blue' | 'darkGray' | 'lightGray';
  avatar?: ReactElement;
  isLoading?: boolean;
}

type ChipState =
  | 'pressed'
  | 'selected'
  | 'focused'
  | 'hovered'
  | 'selectedHovered'
  | 'default';

/**
 * The Chip component is UI element that represents an input, attribute or action. It is always wrapped with a ChipGroup.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/molecules-chip}
 *
 * @typedef {Object} ToggleableTypeProps
 * @property {string} selectLabel Tooltip label for selecting a toggleable type Chip
 * @property {string} unselectLabel Tooltip label for unselecting a toggleable type Chip
 * @property {boolean} [defaultSelected] Selected state (uncontrolled)
 * @property {boolean} [isSelected] Selected state (controlled)
 * @property {boolean} [isDisabled] Disabled state (uncontrolled)
 * @property {(isSelected) => void} [onChange] Handler which is called when toggling isSelected
 *
 * @typedef {Object} RemovableTypeProps
 * @property {string} removeLabel Tooltip label for removable type Chip
 * @property {() => void} [onClickButton] Handler on button which is called when removing Chip
 * @property {boolean} [isDisabled] Disabled state (uncontrolled)
 *
 * @param {xsmall | small} size Size
 * @param {string | ReactNode} children Text or Custom JSX inside. Text supports truncation.
 * @param {Variant} [variant] Variant
 * @param {ReactElement} [avatar] Optional Avatar element
 * @param {boolean} [isLoading] Optional loading state
 *
 */
export const Chip = React.forwardRef<
  HTMLButtonElement | HTMLDivElement,
  ChipProps & TypeProps
>(
  (
    {
      type,
      size,
      children,
      variant = 'blue',
      removeLabel,
      onChange,
      onClickButton,
      defaultSelected,
      avatar,
      unselectLabel,
      selectLabel,
      isSelected: selected,
      isDisabled: disabled,
      isLoading,
      maxWidth,
      ...restProps
    },
    forwardedRef
  ) => {
    const { chip, radii, space, timingFunctions } = useTheme();

    const { isOpen, tooltipProps, triggerProps, triggerRef, tooltipRef } =
      useTooltip();

    const {
      isDisabled,
      isSelected,
      iconKey,
      buttonLabel,
      containerProps,
      containerRefs,
      iconProps,
      isFocusVisible,
      isHovered,
      isPressed,
    } = useChip({
      typeProps: {
        type,
        selectLabel,
        unselectLabel,
        removeLabel,
        onChange,
        onClickButton,
        defaultSelected,
        isSelected: selected,
        isDisabled: disabled,
      } as TypeProps,
      tooltipTriggerProps: { triggerRef, triggerProps },
    });

    const unsetDefaultStyle: CSSObject = {
      borderWidth: 'unset',
      borderStyle: 'unset',
      borderColor: 'unset',
      background: 'unset',
      color: 'unset',
      padding: 'unset',
      outline: 'unset',
    };

    const animationDuration = 250;

    const baseStyle: CSSObject = {
      width: 'max-content',
      maxWidth: maxWidth
        ? maxWidth
        : type === 'removable'
          ? '10rem'
          : undefined,
      borderRadius: radii.full,
      alignItems: 'center',
      transition: `all ${animationDuration}ms ${timingFunctions.ease}`,
      outline: 'none',
      display: 'grid',
      gridAutoColumns: 'auto',
      gridAutoFlow: 'column',
      columnGap: space.space4,
      fontFamily: 'inherit',
    };

    const paddingStyles = {
      paddingLeft: !avatar
        ? chip.paddings[size].paddingLeft
        : chip.paddings[size].paddingLeftWithAvatar,
      paddingRight: isDisabled
        ? chip.paddings[size].paddingRightWhenDisabled
        : chip.paddings[size].paddingRight,
    };

    const paddingStylesTypeBasic = {
      paddingLeft: !avatar
        ? chip.paddings[size].paddingLeft
        : chip.paddings[size].paddingLeftWithAvatar,
      paddingRight: chip.paddings[size].paddingRightBasic,
    };

    const baseIconStyle: CSSObject = {
      borderRadius: '100%',
      cursor: 'pointer',
      transition: `all ${animationDuration}ms ${timingFunctions.ease}`,
    };

    const getIconStyleByState = (state: ChipState) => {
      return avatar
        ? chip[variant][state]?.icon?.[size].avatar
        : chip[variant][state]?.icon?.[size].default;
    };

    const animationStyle: AnimationCSSObject = {
      enter: {
        svg: {
          opacity: 0,
        },
      },
      enterActive: {
        svg: {
          opacity: 1,
          transition: `opacity ${animationDuration}ms ${timingFunctions.ease}`,
        },
      },
      exit: {
        svg: {
          opacity: 1,
        },
      },
      exitActive: {
        svg: {
          opacity: 0,
          transition: `opacity ${animationDuration}ms ${timingFunctions.ease}`,
        },
      },
    };

    if (isLoading) {
      return (
        <Skeleton
          width={space.space96}
          height={chip[size].height as string}
          borderRadius={radii.full}
        />
      );
    }

    return (
      <>
        <Box
          {...containerProps}
          ref={mergeRefs([forwardedRef, ...(containerRefs || [])])}
          role="option"
          css={[
            unsetDefaultStyle,
            baseStyle,
            type !== 'basic' ? paddingStyles : paddingStylesTypeBasic,
            chip[size],
            chip[variant].default,
            isSelected && chip[variant].selected,
            isHovered && chip[variant].hovered,
            isSelected && isHovered && chip[variant].selectedHovered,
            isDisabled && chip.disabled,
            isFocusVisible && chip[variant].focused,
            isPressed && chip[variant].pressed,
          ]}
          aria-disabled={isDisabled}
          {...restProps}
        >
          {avatar}
          {type === 'removable' && typeof children === 'string' ? (
            <TruncatedText wordBreak="break-all">{children}</TruncatedText>
          ) : (
            children
          )}
          {!isDisabled && type !== 'basic' && (
            <SwitchAnimationBox trigger={isSelected} animation={animationStyle}>
              <Flex
                {...iconProps}
                alignItems="center"
                justifyContent="center"
                css={[
                  unsetDefaultStyle,
                  baseIconStyle,
                  getIconStyleByState('default'),
                  isPressed && getIconStyleByState('pressed'),
                  isSelected && getIconStyleByState('selected'),
                  isHovered && getIconStyleByState('hovered'),
                  isSelected &&
                    isHovered &&
                    getIconStyleByState('selectedHovered'),
                  isFocusVisible && getIconStyleByState('focused'),
                  chip[size].icon,
                ]}
              >
                <Icon icon={iconKey as IconProps['icon']} size="space16" />
              </Flex>
            </SwitchAnimationBox>
          )}
        </Box>
        {!isDisabled && isOpen && buttonLabel && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {buttonLabel}
          </Tooltip>
        )}
      </>
    );
  }
);
