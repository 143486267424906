import {
  CustomEmptyStateProps,
  Flex,
  Grid,
  Link,
  LottieAnimation,
  Paragraph,
  Text,
} from '@candisio/design-system';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';

interface PurchaseOrdersEmptyStateProps extends CustomEmptyStateProps {
  isTableFiltered: boolean;
  isUsingSearchQuery?: boolean;
  arePurchaseOrdersSelected?: boolean;
  resetSearch: () => void;
}

const FilteredAndEmpty = ({
  resetFilters,
  arePurchaseOrdersSelected,
}: {
  resetFilters: () => void;
  arePurchaseOrdersSelected: Boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  return (
    <>
      <Text textAlign="center" whiteSpace="pre-wrap">
        {arePurchaseOrdersSelected
          ? t(
              'addPurchaseOrderModal.emptyState.isFilteredAndPurchaseOrderSelected'
            )
          : t('emptyState.isFilteredAndEmpty')}
      </Text>
      <Link as="button" onClick={() => resetFilters()}>
        {t('emptyState.resetFilter')}
      </Link>
    </>
  );
};

const SearchedAndEmpty = ({
  resetSearch,
  arePurchaseOrdersSelected,
}: {
  resetSearch: () => void;
  arePurchaseOrdersSelected: Boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  return (
    <>
      <Text textAlign="center" whiteSpace="pre-wrap">
        {arePurchaseOrdersSelected
          ? t(
              'addPurchaseOrderModal.emptyState.isSearchedAndPurchaseOrderSelected'
            )
          : t('emptyState.isSearchedAndEmpty')}
      </Text>
      <Link as="button" onClick={() => resetSearch()}>
        {t('emptyState.resetSearch')}
      </Link>
    </>
  );
};

const Empty = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const orgId = useOrganizationId();
  const navigate = useNavigate();

  const archiveLink = `/${orgId}${Routes.ARCHIVE}${Routes.PURCHASE_ORDERS}`;

  return (
    <Flex direction="column" alignItems="center" gap="space16">
      <Paragraph
        textAlign="center"
        width="12rem"
        minWidth="10rem"
        fontSize="basic"
        fontWeight="semibold"
        wordBreak="break-word"
      >
        {t('emptyTable.emptyArchive')}
      </Paragraph>

      <Paragraph textAlign="center">
        <Trans i18nKey="emptyTable.emptyArchiveDescription" t={t}>
          You can find all your purchase orders in the
          <Link as="button" onClick={() => navigate(archiveLink)}>
            Archive
          </Link>
        </Trans>
      </Paragraph>
    </Flex>
  );
};

export const PurchaseOrdersEmptyState = ({
  isTableFiltered,
  isUsingSearchQuery = false,
  arePurchaseOrdersSelected = false,
  resetFilters,
  resetSearch,
}: PurchaseOrdersEmptyStateProps) => {
  return (
    <Grid
      color="gray800"
      paddingTop="6.5rem"
      paddingBottom="4.5rem"
      minHeight="100%"
      justifyItems="center"
      gap="space16"
      alignItems="center"
    >
      <LottieAnimation
        animationData={
          isTableFiltered || isUsingSearchQuery
            ? 'noresultsData'
            : 'successData'
        }
      />
      <Flex maxWidth="space256" alignItems="center" direction="column">
        {isTableFiltered && (
          <FilteredAndEmpty
            arePurchaseOrdersSelected={arePurchaseOrdersSelected}
            resetFilters={resetFilters}
          />
        )}
        {isUsingSearchQuery && (
          <SearchedAndEmpty
            arePurchaseOrdersSelected={arePurchaseOrdersSelected}
            resetSearch={resetSearch}
          />
        )}
        {!isTableFiltered && !isUsingSearchQuery && <Empty />}
      </Flex>
    </Grid>
  );
};
