import { Grid, Paragraph } from '@candisio/design-system';
import { CardIsReplacedByInfoContainer } from 'containers/credit-cards/CreditCardSettings/CardReplacementInfo/CardIsReplacedByInfoContainer';
import { CardReplacedContainer } from 'containers/credit-cards/CreditCardSettings/CardReplacementInfo/CardReplacedContainer';
import { CardRequestContainer } from 'containers/credit-cards/CreditCardSettings/CardRequest/CardRequestContainer';
import { CardStatusInfoBox } from 'containers/credit-cards/CreditCardSettings/CardStatusInfoBox/CardStatusInfoBox';
import { CreditCardDetailsAndActions } from 'containers/credit-cards/CreditCardSettings/CreditCardDetailsAndActions/CreditCardDetailsAndActions';
import { CreditCardFormDataPreviewContainer } from 'containers/credit-cards/CreditCardSettings/CreditCardFormDataPreview/CreditCardFormDataPreview';
import { HandleCardRequestContainer } from 'containers/credit-cards/CreditCardSettings/HandleRequest/HandleCardRequestContainer';
import { HandleLimitRequestContainer } from 'containers/credit-cards/CreditCardSettings/HandleRequest/HandleLimitRequestContainer';
import { InfoCardIsLockedByPin } from 'containers/credit-cards/CreditCardSettings/InfoCardIsLockedByPin';
import { PendingLimitRequestContainer } from 'containers/credit-cards/CreditCardSettings/PendingLimitRequest/PendingLimitRequestContainer';
import { useGetCardById } from 'containers/credit-cards/utils';
import { CardStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ExchangeRatePanel } from '../../components/ExchangeRatePanel';
import { DrawerMode } from '../../CreditCardDrawer';

interface SettingsTabProps {
  cardId: string;
  onClose: () => void;
  onSetMode: (mode: DrawerMode) => void;
}

export const SettingsTab = ({
  cardId,
  onClose,
  onSetMode,
}: SettingsTabProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { card } = useGetCardById({ cardId, fetchPolicy: 'cache-and-network' });

  const isCancelled = card?.status === CardStatus.RequestCancelled;
  const isRejected = card?.status === CardStatus.RequestRejected;

  const isCardIssued = !isCancelled && !isRejected;

  return (
    <Grid
      templateRows="1fr auto"
      gap="space16"
      paddingX="space40"
      paddingTop="space24"
      overflowY="auto"
      height="100%"
    >
      <Grid gap="space16" height="fit-content">
        <CreditCardDetailsAndActions
          cardId={cardId}
          onClose={onClose}
          onSetMode={onSetMode}
        />
        <InfoCardIsLockedByPin cardId={cardId} />
        <CardRequestContainer cardId={cardId} onSetMode={onSetMode} />
        <PendingLimitRequestContainer cardId={cardId} onSetMode={onSetMode} />
        <CardStatusInfoBox cardId={cardId} cardStatus={card?.status} />
        <CreditCardFormDataPreviewContainer
          cardId={cardId}
          onSetMode={onSetMode}
        />
        <CardIsReplacedByInfoContainer cardId={cardId} />
        <CardReplacedContainer cardId={cardId} />
      </Grid>
      <Grid bottom={0}>
        <Paragraph color="gray600">
          {t('dashboard.drawer.form.footerText')}
        </Paragraph>
        {isCardIssued && <ExchangeRatePanel />}
      </Grid>
      <HandleLimitRequestContainer cardId={cardId} />
      <HandleCardRequestContainer cardId={cardId} />
    </Grid>
  );
};
