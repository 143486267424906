import { Flex } from '@candisio/design-system';
import { ReactNode } from 'react';
import { ActionsContainer } from 'views/DocumentDetails/components/ActionsContainer';

const buttonStates = {
  hidden: { y: '3.25rem' },
  visible: { y: 0 },
};

interface ActionsWrapperProps {
  children: ReactNode;
}

export const ActionsWrapper = ({ children }: ActionsWrapperProps) => {
  return (
    <ActionsContainer
      hidden={buttonStates.hidden}
      duration={0.5}
      key="request-association"
    >
      <Flex direction="column" alignItems="start" gap="space8" padding="space5">
        {children}
      </Flex>
    </ActionsContainer>
  );
};
