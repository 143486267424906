import React from 'react';
import { TokenOrCSSValue } from '../../types';
import { Box, BoxProps } from '../Box';
import { useMapTokensToCSS } from '../Box/useMapTokensToCSS';

const DEFAULT_ELEMENT = 'div';

export type FlexProps<
  TElement extends React.ElementType = typeof DEFAULT_ELEMENT,
> = BoxProps<TElement> & {
  alignContent?: React.CSSProperties['alignContent'];
  alignItems?: React.CSSProperties['alignItems'];
  as?: keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
  columnGap?: TokenOrCSSValue<'space', 'columnGap'>;
  direction?: React.CSSProperties['flexDirection'];
  gap?: TokenOrCSSValue<'space', 'gap'>;
  inline?: boolean;
  justifyContent?: React.CSSProperties['justifyContent'];
  placeContent?: React.CSSProperties['placeContent'];
  placeItems?: React.CSSProperties['placeItems'];
  rowGap?: TokenOrCSSValue<'space', 'rowGap'>;
  wrap?: React.CSSProperties['flexWrap'];
};

type FlexType = <TElement extends React.ElementType = typeof DEFAULT_ELEMENT>(
  props: FlexProps<TElement>
) => React.ReactElement | null;

/**
 * Flex is a straightforward wrapper around the
 * [CSS Flexbox API]{@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout}.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-layout-flex}
 */
export const Flex = React.forwardRef<FlexType, FlexProps<FlexType>>(
  (
    {
      alignContent,
      alignItems,
      children,
      columnGap,
      direction = 'row',
      gap,
      inline = false,
      justifyContent,
      placeContent,
      placeItems,
      rowGap,
      wrap,
      ...restProps
    },
    ref
  ) => {
    const mappedCSSProps = useMapTokensToCSS({ columnGap, gap, rowGap });

    return (
      <Box
        as={DEFAULT_ELEMENT}
        css={{
          ...mappedCSSProps,
          alignContent,
          alignItems,
          display: inline ? 'inline-flex' : 'flex',
          flexDirection: direction,
          flexWrap: wrap,
          justifyContent,
          placeContent,
          placeItems,
        }}
        ref={ref}
        {...restProps}
      >
        {children}
      </Box>
    );
  }
) as FlexType;
