import { Card, Grid, Icon, Item, Link, Text } from '@candisio/design-system';
import { HookFormComboBoxField } from 'components/HookFormFields/HookFormComboBoxField';
import { HookFormUsersFieldOption } from 'components/HookFormFields/HookFormUsersField/HookFormUsersFieldOption';
import { UserFieldItem } from 'hooks/useUsersFieldOptions';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { createSearchParams } from 'react-router-dom-v5-compat';
import { AccountsPayableNumberField } from 'views/Settings/TeamMembers/TeamMemberDetails/containers/active/AccountsPayableNumberField';
import { ContactFormValues } from '../contactFormSchema';

interface ContactEmployeeFieldsProps {
  disabled: boolean;
  onGetTeamMemberByContactName?: (
    name: string | undefined
  ) => Promise<string | undefined>;
  fieldOptions?: {
    teamMembers: {
      items: UserFieldItem[];
      loading: boolean;
    };
  };
}

export const ContactEmployeeFields = ({
  disabled,
  fieldOptions,
  onGetTeamMemberByContactName,
}: ContactEmployeeFieldsProps) => {
  const [t] = useTranslation();
  const orgId = useOrganizationId();
  const { watch, setValue } = useFormContext<ContactFormValues>();

  const teamMember = watch('teamMember');
  const individualFirstName = watch('individualFirstName');
  const individualLastName = watch('individualLastName');

  const linkToTeamMember = `/${orgId}${Routes.SETTINGS}${Routes.TEAM_MEMBERS}/${teamMember}?${createSearchParams({ status: 'ACTIVE' }).toString()}`;

  useEffect(() => {
    const updateFoundTeamMember = async () => {
      const fullName = (individualFirstName ?? '') + (individualLastName ?? '');
      const response = await onGetTeamMemberByContactName?.(fullName);

      if (response) {
        setValue('teamMember', response, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }
    };

    updateFoundTeamMember();
  }, [
    setValue,
    individualFirstName,
    individualLastName,
    onGetTeamMemberByContactName,
  ]);

  return (
    <Grid gap="space14">
      <HookFormComboBoxField
        label={t('settings.contacts.details.edit.teamMember.label')}
        defaultItems={fieldOptions?.teamMembers.items}
        placeholder={t('settings.contacts.details.edit.teamMember.placeholder')}
        loading={fieldOptions?.teamMembers.loading}
        name="teamMember"
        children={({ key, children, avatarUrl }) => (
          <Item key={key} textValue={children}>
            <HookFormUsersFieldOption
              children={children}
              avatarUrl={avatarUrl}
            />
          </Item>
        )}
      />
      <AccountsPayableNumberField
        name="accountsPayableNumber"
        label={t('settings.contacts.details.edit.accountsPayableNumber.label')}
        disabled={disabled}
      />

      <Card background="yellowbg" border="1px solid yellow700">
        <Grid templateColumns="auto 1fr" gap="space8">
          <Icon icon="warning" color="yellow700" size="space20" />
          <Grid gap="space4">
            <Text fontSize="basic">
              {t('settings.contacts.details.edit.convertToEmployee.title')}
            </Text>
            {!!teamMember ? (
              <Trans
                parent="span"
                t={t}
                i18nKey="settings.contacts.details.edit.convertToEmployee.text"
              >
                Accounts payable number will be transferred to team member. You
                can manage the employee information in the team member section.
                <Link external href={linkToTeamMember}>
                  {t(
                    'settings.contacts.details.edit.convertToEmployee.openTeamMember'
                  )}
                </Link>
              </Trans>
            ) : (
              <Text>
                {t(
                  'settings.contacts.details.edit.convertToEmployee.textNoLink'
                )}
              </Text>
            )}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
