import { Grid, Image, Text } from '@candisio/design-system';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { IndicatorStatus } from 'views/AppContainer/components/Header/components/ProgressIndicator';
import { Status } from './Status';

export interface ProgressMessageProps {
  errorDetails?: string;
  icon: string;
  label?: string | JSX.Element;
  state: IndicatorStatus;
  statusLabel: string;
}

export const ProgressMessage = ({
  errorDetails,
  icon,
  label,
  state,
  statusLabel,
}: ProgressMessageProps) => {
  const isProcessing = state === IndicatorStatus.PROCESSING;

  const message = (
    <Grid
      alignItems="center"
      templateColumns="1fr auto"
      gap="space8"
      padding={!isProcessing ? 'space4' : undefined}
    >
      <Grid alignItems="center" templateColumns="auto 1fr" gap="space4">
        <Image src={icon} width="space24" height="space24" alt="" />
        <Text textAlign="left">{label}</Text>
      </Grid>
      <Status status={state} label={statusLabel} details={errorDetails} />
    </Grid>
  );

  return !isProcessing ? message : <ProgressBar>{message}</ProgressBar>;
};
