import { DocumentsTableData } from 'components/DocumentsTable/types';
import { queryParameter } from 'components/Table/consts';
import { Query } from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { debounce } from 'lodash';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useComputeQueryFilters } from 'views/Archive/ArchiveDocumentsTable/hooks/useComputeQueryFilters';
import { mapApprovalInvoicesToDocumentsTableData } from 'views/utils/mapApprovalInvoicesToDocumentsTableData';
import { useIncludePurchaseOrderData } from 'views/utils/useShouldShowField';
import { getApprovalInvoiceDocumentsQuery } from '../queries';
import { availableFilters } from './consts';
import { useApprovalDocumentsDataFilters } from './useApprovalDocumentsDataFilters';
import { mapToDocumentSortInput } from 'views/utils/mapToDocumentSortInput';
import { useSearchInput } from 'components/EcmSearchFieldFilterOverlay/useSearchInput';

export const useApprovalDocumentsData = () => {
  const [t] = useTranslation();

  const { searchParams } = useMutateSearchParams();

  const computedFilters = useComputeQueryFilters();

  const includePurchaseOrderData = useIncludePurchaseOrderData();

  const searchQuery = searchParams.get(queryParameter) ?? '';

  const { filters, onFilter, onSort, sortBy } =
    useUrlBasedSortAndFilter<DocumentsTableData>({ availableFilters });

  const gqlSort = mapToDocumentSortInput(sortBy);

  const [debounceSearchValue, setDebounceSearchValue] = useState(searchQuery);

  const { searchInputVariable } = useSearchInput();

  const {
    data,
    loading: paginatedLoadingState,
    onLoadMore,
  } = usePagination<Pick<Query, 'approvalInvoiceDocuments'>>(
    getApprovalInvoiceDocumentsQuery,
    'approvalInvoiceDocuments',
    {
      variables: {
        ...computedFilters,
        filters: {
          ...computedFilters.filters,
          ...computedFilters.dateRangeFilters,
        },
        ...(gqlSort ? { sort: gqlSort } : {}),
        query: debounceSearchValue,
        withPurchaseOrderData: includePurchaseOrderData,
        searchInput: searchInputVariable,
      },
    }
  );

  const listapprovalDocuments = data && data.approvalInvoiceDocuments;

  const documentEdges = listapprovalDocuments?.edges ?? [];
  const documentsCount = listapprovalDocuments?.pageInfo?.totalCount;
  const isTableEmpty = documentEdges.length === 0 && !paginatedLoadingState;
  const hasMoreData = listapprovalDocuments?.pageInfo?.hasNextPage ?? false;
  const approvalsTableData = mapApprovalInvoicesToDocumentsTableData(
    documentEdges,
    t
  );

  const isTableFiltered = filters.length > 0;

  const { filterOptions, filtersLoading } = useApprovalDocumentsDataFilters({
    approvalsTableData,
  });

  const showLoadingMoreSpinner = approvalsTableData.length > 1;

  const handleDebounceSearch = useMemo(() => {
    return debounce(setDebounceSearchValue, DEFAULT_DEBOUNCE_TIME, {
      leading: true,
    });
  }, []);

  return {
    approvalsTableData,
    filtersLoading,
    filters,
    filterOptions,
    hasMoreData,
    isTableEmpty,
    isTableFiltered,
    loading: paginatedLoadingState,
    onFilter,
    onLoadMore,
    onSort,
    result: data,
    totalCount: documentsCount ?? 0,
    showLoadingMoreSpinner,
    sortBy,
    handleDebounceSearch,
  };
};
