import { Button, defaultTheme, Grid } from '@candisio/design-system';
import { ProcessingFormRemainingAmountErrorCard } from 'components/Form/ProcessingForm/ProcessingFormRemainingAmountErrorCard';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

const commonButtonProps: Omit<ComponentProps<typeof Button>, 'as'> = {
  flexGrow: '1',
  style: {
    padding: '12px 1rem',
    borderRadius: '0',
    borderEndStartRadius: defaultTheme.radii.medium,
    borderEndEndRadius: defaultTheme.radii.medium,
  },
} as const;

type ReimbursementSplitActionButtonProps = {
  currency: string;
  remainingAmount: number;
  hasItems: boolean;
  readOnly?: boolean;
  onSplitDocument?: (index?: number) => void;
};

export const ReimbursementSplitActionButton = ({
  currency,
  hasItems,
  remainingAmount,
  readOnly,
  onSplitDocument,
}: ReimbursementSplitActionButtonProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const hasRemainingAmount = Boolean(remainingAmount);

  if (!hasRemainingAmount && !readOnly) {
    return (
      <Grid background={hasItems ? 'gray50' : undefined}>
        <Button
          icon="plus"
          iconPosition="left"
          variant="tertiary"
          color="gray"
          onClick={() => onSplitDocument?.()}
          {...(hasItems && { ...commonButtonProps })}
        >
          {t('splitBookings.splitAction')}
        </Button>
      </Grid>
    );
  }

  if (hasRemainingAmount) {
    return (
      <ProcessingFormRemainingAmountErrorCard
        currency={currency}
        readOnly={readOnly}
        remainingAmount={remainingAmount}
        onSplitDocument={onSplitDocument}
      />
    );
  }

  return null;
};
