import { SplitBookingFields } from 'components/Form/SplitBookingsForm/types';
import {
  sumNetAmounts,
  sumTaxAmounts,
} from 'containers/SplitBookings/toolkit/utils';
import {
  GetDocumentForDraftQuery,
  GetDocumentQuery,
  useGetDocumentForDraftQuery,
} from 'generated-types/graphql.types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useGetBookingAssociation } from 'hooks/useGetBookingAssociation';
import { formatIntegerAmountToDecimal } from 'hooks/useMoneyFormatter';
import { friendlyFormatIBAN } from 'ibantools';
import { isNil } from 'lodash';
import moment from 'moment';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { isNilOrEmpty } from 'utils/isNilOrEmpty';
import { PaymentStatus } from 'views/Payments/types';
import { DocumentDirection } from 'views/utils/DocumentDirection';
import { getInitialCashDiscount } from 'views/utils/getInitialCashDiscount';
import {
  useShouldShowField,
  useShowAccountingAreaField,
  useShowCashDiscountFields,
  useShowDeliveryDateField,
  useShowDueDateField,
  useShowInvoiceCorrectionField,
  useShowPostingDateField,
  useShowPostingTextField,
  useShowPurchaseOrderNumberField,
  useShowSwiftCodeField,
} from 'views/utils/useShouldShowField';
import { DocumentFormValues } from '../schema';
import { DocumentFormInitialData, ProcessedDocument } from '../types';
import { useLinkedPurchaseOrder } from 'views/Inbox/DocumentProcessing/useLinkedPurchaseOrder';

export interface useDocumentFormInitialDataOptions {
  document?: ProcessedDocument;
}

export const useDocumentFormInitialData = ({
  document,
}: useDocumentFormInitialDataOptions = {}): DocumentFormInitialData => {
  const {
    isActive: isSapActive,
    shouldUseSapPurchaseOrder,
    shouldUseSapNetAmount,
  } = useSap();

  const showAccountingArea = useShowAccountingAreaField({
    isAccountingAreaOnDocument: !isNilOrEmpty(
      document?.accountingArea?.value.id
    ),
  });

  const showInvoiceCorrection = useShowInvoiceCorrectionField();
  const showPurchaseOrderNumber = useShowPurchaseOrderNumberField();

  const { friendlyFormatAccountsNumber, friendlyFormatGLA } =
    useAccountingNumberFormatters();

  const { isQuantityRequired, getBookingAssociationData } =
    useGetBookingAssociation(document?.id);

  const { isQuantityRequired: isGoodsPurchaseOrderLinked } =
    useLinkedPurchaseOrder(document?.id);

  const { data: documentData, loading: isLoading } =
    useGetDocumentForDraftQuery({
      variables: { id: document?.id as string },
      skip: typeof document?.id !== 'string',
    });

  const {
    amount,
    bookings = [],
    category,
    contact,
    createTransfer,
    currency,
    hasTransactionLinked,
    iban,
    swiftCode,
    invoiceDate,
    invoiceDeliveryDate,
    invoiceNumber,
    isReadOnly: isDocumentReadOnly,
    paymentState,
    purchaseOrderData,
    invoicePostingDate,
    roundingAmount,
    accountingArea,
  } = documentData?.getDocument ?? {};

  const isInvoiceCorrection =
    !isNil(amount?.value) && Math.abs(amount?.value) !== amount?.value;

  const mappedBookings = (bookings ?? [])?.map(booking => {
    const {
      quantity: associatedBookingQuantity,
      sapExpenseType,
      unitPrice,
    } = getBookingAssociationData(booking.id);

    const bookingUnitPrice = formatIntegerAmountToDecimal(
      unitPrice?.amount ?? 0,
      unitPrice?.precision ?? 0
    );

    return {
      amount: booking.amount?.value ?? undefined,
      artistSocialInsuranceCode: booking.artistSocialInsuranceCode ?? undefined,
      bookingId: booking.id ?? '',
      bookingKey: {
        value: booking.bookingKey?.value.id ?? '',
        inputValue: booking.bookingKey?.value.readableName ?? '',
      },
      costCenter: {
        value: booking.costCenter?.value.id ?? '',
        inputValue: booking.costCenter?.value.readableName ?? '',
      },
      costObject: {
        value: booking.costObject?.value.id ?? '',
        inputValue: booking.costObject?.value.readableName ?? '',
      },
      dueDate: booking.dueDate?.value ? new Date(booking.dueDate?.value) : null,
      extraCostInfo: {
        value: booking.extraCostInfo?.value.id ?? '',
        inputValue: booking.extraCostInfo?.value.readableName ?? '',
      },
      generalLedgerAccount: {
        value: booking?.generalLedgerAccount
          ? `${friendlyFormatGLA(
              booking.generalLedgerAccount?.value.accountCode
            )} - ${booking.generalLedgerAccount?.value.name}`
          : '',
        inputValue: booking.generalLedgerAccount?.value.readableName ?? '',
      },
      note: booking.note?.value ?? '',
      postingText: booking.postingText ?? '',
      ...(isQuantityRequired
        ? {
            quantity: associatedBookingQuantity,
            unitPrice: bookingUnitPrice,
          }
        : {}),
      ...(shouldUseSapPurchaseOrder && sapExpenseType
        ? {
            sapExpenseType,
          }
        : {}),
      vatRate: booking.vatRate?.value ?? undefined,
      netAmount: booking.netAmount,
      taxAmount: booking.taxAmount,
    };
  });

  const initialValues: DocumentFormValues = {
    accountingArea: {
      value: accountingArea?.value.id ?? '',
      inputValue: accountingArea?.value.name ?? '',
    },
    accountsPayableNumber: friendlyFormatAccountsNumber(
      contact?.value.accountsPayableNumber ?? ''
    ),
    accountsReceivableNumber: contact?.value.accountsReceivableNumber ?? '',
    bookings: mappedBookings,
    cashDiscount:
      getInitialCashDiscount(
        (documentData?.getDocument as NonNullable<
          | GetDocumentForDraftQuery['getDocument']
          | GetDocumentQuery['getDocument']
        >) ?? {}
      ) ?? undefined,
    category: {
      type: category?.documentType?.value ?? undefined,
      direction: category?.direction?.value ?? undefined,
    },
    contact: contact?.value.name.value ?? '',
    contactId: contact?.value.id ?? '',
    createTransfer: createTransfer?.value ?? false,
    currency: currency?.value ?? '',
    grossAmount: Number(amount?.value),
    hasTransactions: hasTransactionLinked ?? false,
    iban: friendlyFormatIBAN(iban?.value) ?? null,
    invoiceCorrection: isInvoiceCorrection,
    invoiceDate: invoiceDate?.value ? new Date(invoiceDate?.value) : null,
    invoiceDeliveryDate: invoiceDeliveryDate?.value
      ? new Date(invoiceDeliveryDate?.value)
      : null,
    invoicePostingDate: invoicePostingDate?.value
      ? new Date(invoicePostingDate.value)
      : null,
    invoiceNumber: invoiceNumber?.value ?? '',
    paidAt:
      paymentState?.__typename === 'PaidDocumentState'
        ? moment(paymentState.paidAt).format('YYYY-MM-DD')
        : null,
    paymentStatus:
      paymentState?.__typename === 'PaidDocumentState'
        ? PaymentStatus.PAID
        : PaymentStatus.UNPAID,
    purchaseOrderNumber: purchaseOrderData?.orderNumber,
    swiftCode: swiftCode?.value ?? null,
    vatRate: bookings?.[0]?.vatRate
      ? Number(bookings?.[0]?.vatRate.value)
      : undefined,
    roundingAmount,
    netAmount: sumNetAmounts(bookings ?? []),
    taxAmount: sumTaxAmounts(bookings ?? []),
  };

  const {
    showAccountingDataField,
    showAccountsPayableNumber,
    showAccountsReceivableNumber,
  } = useShouldShowField(document ?? {});

  const isIncomingInvoice =
    initialValues.category?.direction === DocumentDirection.invoices_received;

  const showAccountsPayableNumberField = showAccountsPayableNumber({
    isIncomingInvoice,
  });

  const isOutgoingInvoice =
    initialValues.category?.direction === DocumentDirection.outgoing_invoices;

  const showAccountsReceivableNumberField = showAccountsReceivableNumber({
    isOutgoingInvoice,
    isSapActive,
  });

  const showBookingsWithSapPurchaseOrder =
    isGoodsPurchaseOrderLinked && !isNil(bookings);

  const showFormField =
    (showBookingsWithSapPurchaseOrder || (bookings && bookings.length > 1)) ??
    false;

  const allDueDatesAreSame = Boolean(
    bookings?.every(({ dueDate }) => dueDate === bookings[0].dueDate)
  );

  const showDueDateField = useShowDueDateField({ allDueDatesAreSame });
  const showSwiftCodeField = useShowSwiftCodeField();

  const showCashDiscountFields = useShowCashDiscountFields({
    isInvoiceCorrection,
    isIncomingInvoice,
    hasTransaction: Boolean(hasTransactionLinked),
  });

  const showSplitPostingTextFF = useShowPostingTextField();
  const showSplitPostingText =
    showAccountingDataField(SplitBookingFields.postingText, 0) &&
    showSplitPostingTextFF;

  const showGeneralLedgerAccountId = showAccountingDataField(
    SplitBookingFields.generalLedgerAccountId,
    0
  );

  const showBookingAccount = showAccountingDataField(
    SplitBookingFields.bookingKeyId,
    0
  );

  const showCostCenter = showAccountingDataField(
    SplitBookingFields.costCenterId,
    0
  );

  const showCostObject = showAccountingDataField(
    SplitBookingFields.costObjectId,
    0
  );

  const showArtistSocial = showAccountingDataField(
    SplitBookingFields.artistSocialInsuranceCode,
    0
  );

  const showExtraCostInfo = showAccountingDataField(
    SplitBookingFields.extraCostInfoId,
    0
  );

  const showDeliveryDate = useShowDeliveryDateField();

  const showPostingDate = useShowPostingDateField();

  return {
    documentId: document?.id,
    fieldConditions: {
      showAccountsPayableNumberField,
      showAccountsReceivableNumberField,
      shouldUseSapPurchaseOrder,
      showArtistSocial,
      showBookingAccount,
      showCashDiscountFields,
      showCostCenter,
      showCostObject,
      showDeliveryDate,
      showDueDateField,
      showExtraCostInfo,
      showFormField,
      showGeneralLedgerAccountId,
      showPostingDate,
      showSplitPostingText,
      showInvoiceCorrection,
      showSwiftCodeField,
      showPurchaseOrderNumber,
      shouldUseSapNetAmount,
      showAccountingArea,
    },
    initialValues,
    isLoading,
    isDocumentReadOnly: isDocumentReadOnly ?? true,
  };
};
