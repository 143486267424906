import { FieldPath } from 'react-hook-form';
import { ReadOnlyTransactionFormValues } from '../utils/readOnlyTransactionFormSchema';
import { useGetReadOnlyAccountingDataFormValues } from './useGetReadOnlyAccountingDataFormValues';

const READ_ONLY_TRANSACTION_FIELD_META_DATA_SOURCE = [
  'transaction',
  'creditCard',
] as const;

export type ReadOnlyTransactionFormFieldMetadataSource =
  (typeof READ_ONLY_TRANSACTION_FIELD_META_DATA_SOURCE)[number];

export const useReadOnlyTransactionFormMetaData = (
  name: FieldPath<ReadOnlyTransactionFormValues>
) => {
  const { defaultValues, isNameComingFromCreditCard } =
    useGetReadOnlyAccountingDataFormValues();

  const fieldsMetaData: Partial<
    Record<
      FieldPath<ReadOnlyTransactionFormValues>,
      {
        confidence: number;
        source: ReadOnlyTransactionFormFieldMetadataSource;
      }
    >
  > = {
    approvers: defaultValues.approvers
      ? {
          confidence: 1,
          source: 'creditCard',
        }
      : undefined,
    artistSocialInsuranceCode: defaultValues.artistSocialInsuranceCode
      ? {
          confidence: 1,
          source: 'creditCard',
        }
      : undefined,
    comment: defaultValues.comment
      ? {
          confidence: 1,
          source: 'transaction',
        }
      : undefined,
    contact:
      // defaultValues.contact can be a simple string or an object with two fields, that's why we need to be explicit
      (typeof defaultValues.contact === 'string' && defaultValues.contact) ||
      (typeof defaultValues.contact !== 'string' &&
        defaultValues.contact?.value)
        ? {
            confidence: 1,
            source: isNameComingFromCreditCard ? 'creditCard' : 'transaction',
          }
        : undefined,
    costCenter: defaultValues.costCenter.value
      ? {
          confidence: 1,
          source: 'creditCard',
        }
      : undefined,
    costObject: defaultValues.costObject.value
      ? {
          confidence: 1,
          source: 'creditCard',
        }
      : undefined,
    extraCostInfo: defaultValues.extraCostInfo.value
      ? {
          confidence: 1,
          source: 'creditCard',
        }
      : undefined,
    generalLedgerAccount: defaultValues.generalLedgerAccount.value
      ? {
          confidence: 1,
          source: 'creditCard',
        }
      : undefined,
    note: defaultValues.note
      ? {
          confidence: 1,
          source: 'transaction',
        }
      : undefined,
    taxCode: defaultValues.taxCode
      ? {
          confidence: 1,
          source: 'creditCard',
        }
      : undefined,
    transactionAmount: defaultValues.transactionAmount
      ? {
          confidence: 1,
          source: 'transaction',
        }
      : undefined,
    transactionDate: defaultValues.transactionDate
      ? {
          confidence: 1,
          source: 'transaction',
        }
      : undefined,
    transactionId: defaultValues.transactionId
      ? {
          confidence: 1,
          source: 'transaction',
        }
      : undefined,
    workflow: defaultValues.workflow
      ? {
          confidence: 1,
          source: 'creditCard',
        }
      : undefined,
  };

  return fieldsMetaData[name];
};
