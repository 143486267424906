import {
  Button,
  Grid,
  Item,
  TabPanel,
  Tabs,
  Text,
} from '@candisio/design-system';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { EmptySearchState } from 'components/FilterableList/components/EmptySearchState';
import { FilterableListItem } from 'components/FilterableList/components/FilterableListItem';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { BookingAccountKebabMenu } from 'components/Menu/BookingAccountKebabMenu/BookingAccountKebabMenu';
import { useTabs } from 'components/Tabs/useTabs';
import {
  BookingAccountDataFragment,
  BookingAccountSortField,
} from 'generated-types/graphql.types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { Routes } from 'models';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { Match } from 'router/Match';
import { usePath } from 'utils/hooks';
import { escapeRegex } from 'utils/regex';
import { sortByStringProp } from 'utils/sorting';
import { SettingsLayout } from 'views/Settings/components/SettingsLayout/SettingsLayout';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';
import { BookingAccountDetails } from './components/BookingAccountDetails';
import { BookingAccountImport } from './components/BookingAccountImport/BookingAccountImport';
import { BookingAccountsEmptyState } from './components/BookingAccountsEmptyState';
import { BOOKING_ACCOUNTS_SEARCH_PARAM } from './consts';
import { useBookingAccountsData } from './hooks/useBookingAccountsData';

enum SortBy {
  alphabetically = 'alphabetically',
  id = 'id',
  lastAdded = 'lastAdded',
}

const queryBookingAccounts = (
  bookingAccounts: BookingAccountDataFragment[],
  queryString: string
) => {
  const regExp = new RegExp(escapeRegex(queryString), 'i');

  return bookingAccounts.filter(bookingAccounts => {
    if (
      bookingAccounts.name?.match(regExp) ||
      bookingAccounts.readableName.match(regExp)
    ) {
      return true;
    }

    return null;
  });
};

const sortBookingAccounts = (
  bookingAccounts: BookingAccountDataFragment[],
  sortBy: SortBy
) => {
  switch (sortBy) {
    case SortBy.alphabetically: {
      const collator = new Intl.Collator();

      return (bookingAccounts || []).sort(
        (a: BookingAccountDataFragment, b: BookingAccountDataFragment) =>
          collator.compare(a.name ?? '', b.name ?? '')
      );
    }

    case SortBy.id: {
      return (bookingAccounts || []).sort(sortByStringProp('accountCode'));
    }

    case SortBy.lastAdded: {
      return (bookingAccounts || []).sort(
        (a: BookingAccountDataFragment, b: BookingAccountDataFragment) => {
          return (a.createdAt ?? '') < (b.createdAt ?? '') ? 1 : -1;
        }
      );
    }

    default: {
      return bookingAccounts;
    }
  }
};

export const BookingAccounts = () => {
  const { friendlyFormatGLA } = useAccountingNumberFormatters();
  const [t] = useTranslation(LOCALE_NAME_SPACE.BOOKING_ACCOUNTS);
  const navigate = useNavigate();
  const { isActiveIntegration: shouldUseSapGL } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();
  const organization = useOrganizationId();
  const [queryString, setQueryString] = useState('');
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.id);
  const [searchParams] = useSearchParams();
  const isArchived = searchParams.toString().includes('true');
  const showCreateAction = !shouldUseCoreDataApi && !shouldUseSapGL;

  const { pathGenerator } = usePath<
    { generalLedgerAccountId?: string },
    {
      [BOOKING_ACCOUNTS_SEARCH_PARAM.archive]?: boolean;
    }
  >();

  const sortByMap = {
    [SortBy.id]: BookingAccountSortField.AccountCode,
    [SortBy.alphabetically]: BookingAccountSortField.Name,
    [SortBy.lastAdded]: BookingAccountSortField.CreatedAt,
  };

  const {
    activeCount,
    archivedCount,
    bookingAccountsData,
    handleDebounceSearch,
    isCountLoading,
    isDataLoading: isLoading,
    onEndReached,
  } = useBookingAccountsData({
    archivedTabEnabled: isArchived,
    search: searchParams.toString(),
    sortBy: sortByMap[sortBy],
  });

  const handleCreate = () => {
    navigate({
      pathname: `/${organization}${Routes.SETTINGS}${Routes.BOOKING_ACCOUNT}/create`,
    });
  };

  const onSelect = (id: string) => {
    navigate({
      pathname: `/${organization}${Routes.SETTINGS}${Routes.BOOKING_ACCOUNT}/${id}`,
      search: searchParams.toString(),
    });
  };

  const sortItems = [
    {
      id: SortBy.alphabetically,
      label: t('sortItems.alphabetically'),
    },
    {
      id: SortBy.id,
      label: t('sortItems.id'),
    },
    {
      id: SortBy.lastAdded,
      label: t('sortItems.lastCreatedFirst'),
    },
  ];

  const sortButtonText = sortItems.find(
    item => sortBy.toString() === item.id
  )?.label;

  const handleSortBy = (value: Key[]) => {
    setSortBy(value.length ? (value[0] as SortBy) : sortBy);
  };

  const onSearchFilter = (filter: string) => {
    handleDebounceSearch(filter);
    setQueryString(filter);
  };

  const handleSearchReset = () => {
    handleDebounceSearch('');
    setQueryString('');
  };

  const queriedBookingAccounts = queryBookingAccounts(
    bookingAccountsData,
    queryString
  );

  const sortedBookingAccounts = sortBookingAccounts(
    queriedBookingAccounts,
    sortBy
  );

  const hasArchivedData = !isArchived && archivedCount > 0;

  const filterableList = (
    <FilterableList
      children={sortedBookingAccounts.map(
        (bookingAccount: BookingAccountDataFragment) => (
          <Item key={bookingAccount.id} textValue={bookingAccount.name}>
            <FilterableListItem
              itemId={bookingAccount.id}
              onSelect={onSelect}
              templateColumns="1fr"
            >
              <Grid>
                <Text
                  fontWeight="semibold"
                  fontSize="basic"
                  overflowWrap="anywhere"
                >
                  {renderQueryHighlight({
                    value: bookingAccount.name ?? '',
                    queryString: queryString,
                  })}
                </Text>
                <Text color="gray500">
                  {renderQueryHighlight({
                    value: bookingAccount.accountCode
                      ? friendlyFormatGLA(bookingAccount.accountCode)
                      : '',
                    queryString: queryString,
                  })}
                </Text>
              </Grid>
            </FilterableListItem>
          </Item>
        )
      )}
      customCreate={
        shouldUseSapGL && (
          <>
            <SyncFromSap type={SAP_SYNC.GeneralLedgerAccounts} />
            <Button
              width="max-content"
              size="small"
              variant="secondary"
              onClick={() =>
                navigate(
                  `/${organization ?? ''}${Routes.SETTINGS}${
                    Routes.BOOKING_ACCOUNT_IMPORT_HISTORY
                  }`
                )
              }
            >
              {t('contextMenu.historyLabel')}
            </Button>
          </>
        )
      }
      emptyDataState={
        <BookingAccountsEmptyState
          handleCreate={handleCreate}
          hasArchivedData={hasArchivedData}
        />
      }
      emptySearchState={<EmptySearchState searchReset={handleSearchReset} />}
      kebabMenu={
        <BookingAccountKebabMenu organizationSlug={organization ?? ''} />
      }
      menuButtons={[
        {
          actionValue: [sortBy],
          onClick: handleSortBy,
          text: sortButtonText ?? '',
          menuButtonItems: sortItems,
        },
      ]}
      onCreate={
        showCreateAction
          ? {
              value: handleCreate,
              text: t('create'),
            }
          : undefined
      }
      onEndReached={onEndReached}
      searchField={{
        onSearchFilter,
        placeholder: t('details.searchPlaceholder'),
        searchQuery: queryString,
      }}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
      isLoading={isLoading}
    />
  );

  const { tabPanelProps, tabsProps } = useTabs({
    items: [
      {
        key: 'active',
        title: t('tabs.active'),
        badge: !isCountLoading ? String(activeCount) : undefined,
        children: filterableList,
      },
      {
        key: 'archived',
        title: t('tabs.archived'),
        badge: !isCountLoading ? String(archivedCount) : undefined,
        children: filterableList,
      },
    ],
    onSelectionChange: key => {
      searchParams.set('archived', key === 'archived' ? 'true' : 'false');
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    selectedKey: isArchived ? 'archived' : 'active',
  });

  const sections = shouldUseCoreDataApi
    ? 'infoPanel.coreDataApiSections'
    : 'infoPanel.sections';

  const externalLink = shouldUseCoreDataApi
    ? 'infoPanel.coreDataApiExternalLink'
    : 'infoPanel.externalLink';

  return (
    <SettingsLayout
      title={t('title')}
      actions={<Tabs {...tabsProps} />}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      <TabPanel {...tabPanelProps} />
      <InfoPanel
        title={t('infoPanel.title')}
        externalLink={
          !shouldUseSapGL
            ? t(externalLink, {
                returnObjects: true,
              })
            : undefined
        }
        additionalParagraph={shouldUseSapGL ? t('description_sap') : undefined}
        sections={
          !shouldUseSapGL ? t(sections, { returnObjects: true }) : undefined
        }
      />
      <Match
        paths={[
          pathGenerator.stringify({
            generalLedgerAccountId: 'create',
          }),
          pathGenerator.stringify({
            generalLedgerAccountId: ':generalLedgerAccountId',
          }),
        ]}
        component={BookingAccountDetails}
      />
      <BookingAccountImport />
    </SettingsLayout>
  );
};
