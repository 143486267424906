import {
  Button,
  Grid,
  Text,
  usePopover,
  Popover,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ArchiveButtonProps {
  onArchiveTeam: () => Promise<void>;
  onClose: () => void;
  isTeamAssignedToCCs: boolean;
  isArchivingPending: boolean;
}

export const ArchiveButton = ({
  onArchiveTeam,
  onClose,
  isTeamAssignedToCCs,
  isArchivingPending,
}: ArchiveButtonProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef, close } =
    usePopover();

  const tooltipText = isTeamAssignedToCCs
    ? t('teams.editTeam.archivePopover.textWithCreditCards')
    : t('teams.editTeam.archivePopover.textWithoutCreditCards');

  const handleConfirm = async () => {
    await onArchiveTeam();
    close();
    onClose();
  };

  const handleDismiss = () => {
    close();
  };

  return (
    <>
      <Button
        {...triggerProps}
        ref={triggerRef}
        variant="tertiary"
        color="error"
        justifySelf="right"
        disabled={isArchivingPending}
      >
        {t('teams.editTeam.archiveCta')}
      </Button>
      {isOpen && (
        <Popover {...popoverProps} ref={popoverRef} width="space256">
          <Grid gap="space8">
            <Text fontSize="basic">{tooltipText}</Text>
            <Grid gap="space4" templateColumns="1fr 1fr">
              <Button
                variant="secondary"
                onClick={handleDismiss}
                disabled={isArchivingPending}
              >
                {t('teams.editTeam.archivePopover.dismiss')}
              </Button>
              <Button
                color="red"
                onClick={handleConfirm}
                loading={isArchivingPending}
                disabled={isArchivingPending}
              >
                {t('teams.editTeam.archivePopover.confirm')}
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
};
