import { Grid, Switch, Text } from '@candisio/design-system';
import {
  ControllerRenderProps,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';

interface ReimbursementSwitchFieldProps {
  isReadOnly?: boolean;
  readOnlyReason?: string;
  name: UseControllerProps['name'];
  control?: UseControllerProps['control'];
  label: string;
  onChange?: () => void;
}

export const ReimbursementSwitchField = ({
  name,
  control,
  isReadOnly,
  label,
  onChange: onChangeProp,
}: ReimbursementSwitchFieldProps) => {
  const { field } = useController({ name, control });

  const { value, onChange } = field as unknown as ControllerRenderProps<
    ExpensesFormOutput['expenses'][number],
    'isExpenseExcluded'
  >;
  return (
    <Grid autoFlow="column" justifySelf="end" gap="space8" alignItems="center">
      <Text fontSize="basic">{label}</Text>
      <Switch
        label={label}
        disabled={isReadOnly}
        name={name}
        aria-label={name}
        checked={value}
        onChange={isChecked => {
          onChange(isChecked);
          onChangeProp?.();
        }}
      />
    </Grid>
  );
};
