import {
  Description,
  FieldContainer,
  FieldContainerProps,
  Input as TextInput,
  mergeProps,
  useLabel,
} from '@candisio/design-system';
import { electronicFormatIBAN, friendlyFormatIBAN } from 'ibantools';
import { isEmpty, isNil } from 'lodash';
import { FocusEventHandler, ReactNode, useState } from 'react';
import { FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHookFormField } from './useHookFormField';

export interface HookFormIbanFieldProps<TFormValues extends FieldValues> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Is field disabled? */
  disabled?: boolean;
  /** Should field receive focus on mount? */
  autoFocus?: boolean;
  /** Field label */
  label: ReactNode;
  /** Message to display in tooltip */
  message?: ReactNode;
  /** Called when field value changes */
  onChange?: (newValue: string | null) => void;
  /** Placeholder text shown when no value is set */
  placeholder?: string;
  /** Is field read only? */
  readOnly?: boolean;
  /** Field variant */
  variant?: 'default' | 'error' | 'warning' | 'success';
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean | undefined;
  description?: Description;
}

/**
 * Controlled text (IBAN) field for React Hook Form
 *
 * To connect to your form you must either:
 * - ensure the field is inside a `FormProvider`, or
 * - explicitly pass the `control` prop returned by `useForm`
 *
 * Expects an electronic-format IBAN (e.g. "DE89370400440532013000") or null as
 * field value and outputs an electronic-format IBAN or null as `onChange`
 * value.
 *
 * Displays a friendly-format IBAN (e.g. "DE89 3704 0044 0532 0130 00") in the
 * input field.
 *
 * Formats the displayed friendly value on blur.
 */
export const HookFormIbanField = <TFormValues extends FieldValues>({
  autoFocus,
  control,
  disabled,
  label,
  message,
  name,
  onChange: onChangeProp,
  placeholder,
  readOnly,
  variant,
  isLoading,
  description,
}: HookFormIbanFieldProps<TFormValues>) => {
  const [t] = useTranslation();
  const {
    fieldContainerProps,
    inputProps: { onBlur, onChange, value, ...inputProps },
  } = useHookFormField({
    control,
    disabled,
    message,
    name,
    onChange: onChangeProp,
    readOnly,
    variant,
  });

  const [friendlyValue, setFriendlyValue] = useState(
    () => friendlyFormatIBAN(value) ?? ''
  );

  // Keep the displayed friendly value in sync with field value
  // https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
  if (isNil(value) && friendlyValue.trim() !== '') {
    setFriendlyValue('');
  } else if (
    electronicFormatIBAN(friendlyValue) !== electronicFormatIBAN(value ?? '')
  ) {
    setFriendlyValue(friendlyFormatIBAN(value) ?? '');
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFriendlyValue(friendlyFormatIBAN(e.target.value) ?? '');
    onBlur();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFriendlyValue(e.target.value);

    const electronicValue = electronicFormatIBAN(e.target.value);
    const newValue = electronicValue === '' ? null : electronicValue;

    onChange(newValue);
  };

  const {
    message: fieldMessage,
    variant: fieldVariant,
    ...restFieldContainerProps
  } = fieldContainerProps;

  const labelInput = typeof label === 'string' && label ? label : name;

  const { labelProps, fieldProps: labelFieldProps } = useLabel({
    label: labelInput,
    'aria-label': labelInput,
  });

  const handleFocus: FocusEventHandler<HTMLInputElement> = event => {
    event.target.select();
  };

  return (
    // remove message prop when FF is archived
    // and these props are removed from the DS
    <FieldContainer
      label={label}
      variant={fieldVariant}
      isLoading={isLoading}
      description={description}
      {...(mergeProps(labelProps, restFieldContainerProps) as Omit<
        FieldContainerProps,
        'color'
      >)}
    >
      <TextInput
        autoFocus={autoFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={readOnly ? '–' : placeholder}
        value={friendlyValue}
        autoComplete="off"
        message={fieldMessage}
        variant={fieldVariant}
        clearable={!isEmpty(value)}
        clearLabel={t('common.clear')}
        onClear={() => {
          onChange(null);
          onChangeProp?.(null);
        }}
        onFocus={handleFocus}
        showMessageOnFocus={fieldVariant === 'error'}
        {...mergeProps(labelFieldProps, inputProps)}
      />
    </FieldContainer>
  );
};
