import {
  Box,
  Flex,
  Icon,
  IconProps,
  Tag,
  VisuallyHidden,
  mergeRefs,
} from '@candisio/design-system';
import { defaultTheme } from '@candisio/design-system';
import {
  CSSProperties,
  ChangeEvent,
  DragEvent,
  ReactNode,
  forwardRef,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDragAndDrop } from './useDragAndDrop';

type BooleanAsString = 'true' | 'false';
type Variant = 'default' | 'newFeature';

interface FileDropZoneProps {
  accept?: string[];
  children: ReactNode;
  icon?: IconProps['icon'];
  inputId?: string;
  multiple?: boolean;
  isXmlDocumentUploadAllowed?: boolean;
  onFilesSelected: (
    event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
    files: File[]
  ) => void;
  variant?: Variant;
}

export const FileDropZone = forwardRef<HTMLInputElement, FileDropZoneProps>(
  (
    {
      accept = [],
      children,
      icon = 'upload',
      inputId,
      multiple = true,
      isXmlDocumentUploadAllowed,
      onFilesSelected,
      variant = 'default',
    },
    forwardedRef
  ) => {
    const [t] = useTranslation();
    const {
      fileUploadRef,
      isFileDraggable,
      canUserDropFile,
      onFileDrop,
      onDragLeave,
      onDragOver,
      onDropZoneClick,
      onFilesChanged,
    } = useDragAndDrop({
      onFilesSelected,
      isXmlDocumentUploadAllowed,
    });

    const variantStyleMap: Record<Variant, CSSProperties> = {
      default: { color: 'gray500', background: 'gray200' },
      newFeature: { color: 'purple500', background: 'purplebg' },
    };

    const isDroppableStyleMap: Record<
      BooleanAsString,
      Record<Variant, CSSProperties>
    > = {
      true: {
        default: { color: 'gray600', background: 'gray300' },
        newFeature: { color: 'purple600', background: 'purple100' },
      },
      false: {
        default: { color: 'red500', background: 'redbg' },
        newFeature: { color: 'red500', background: 'redbg' },
      },
    };

    useEffect(() => {
      const handleDragLeave = (ev: DragEvent) => {
        const relatedTarget = ev.relatedTarget as HTMLElement | null;
        if (!relatedTarget || !ev.currentTarget.contains(relatedTarget)) {
          onDragLeave();
        }
      };

      /* @ts-ignore there's some sort of `DragEvent` types mismatch even though all necessary properties are there... */
      document.addEventListener('dragover', onDragOver);
      /* @ts-ignore */
      document.addEventListener('dragleave', handleDragLeave);
      /* @ts-ignore */
      document.addEventListener('drop', onFileDrop);

      return () => {
        /* @ts-ignore */
        document.removeEventListener('dragover', onDragOver);
        /* @ts-ignore */
        document.removeEventListener('dragleave', handleDragLeave);
        /* @ts-ignore */
        document.removeEventListener('drop', onFileDrop);
      };
    }, [onDragLeave, onDragOver, onFileDrop]);

    const canUserDropFileString = String(canUserDropFile) as BooleanAsString;

    const isTagVisible = variant === 'newFeature' && canUserDropFile;

    return (
      <Flex
        height="162px"
        fontSize="small"
        borderRadius="medium"
        justifyContent="center"
        alignItems="center"
        padding="space20"
        textAlign="center"
        onClick={onDropZoneClick}
        transition={`all 0.2s ${defaultTheme.timingFunctions.ease}`}
        color={
          isFileDraggable
            ? isDroppableStyleMap[canUserDropFileString][variant].color
            : variantStyleMap[variant].color
        }
        background={
          isFileDraggable
            ? isDroppableStyleMap[canUserDropFileString][variant].background
            : variantStyleMap[variant].background
        }
        style={{ cursor: 'pointer' }}
      >
        {isTagVisible && (
          <Tag
            variant="primary"
            color="purple"
            position="absolute"
            top="space16"
            right="space16"
          >
            {t('tag.new')}
          </Tag>
        )}
        <Box as="label" style={{ pointerEvents: 'none', cursor: 'inherit' }}>
          <Icon size="3.75rem" icon={canUserDropFile ? icon : 'warning'} />
          {children}
          <VisuallyHidden>
            <input
              id={inputId}
              data-cy="file-upload"
              type="file"
              accept={accept.join(',') || undefined}
              ref={mergeRefs([fileUploadRef, forwardedRef])}
              onChange={onFilesChanged}
              multiple={multiple}
            />
          </VisuallyHidden>
        </Box>
      </Flex>
    );
  }
);
