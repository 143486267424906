import { Item } from '@candisio/design-system';
import { DocumentTypesFieldItem } from 'components/Form/hooks/useDocumentTypeFieldOptions';
import {
  HookFormComboBoxField,
  HookFormComboBoxFieldProps,
} from 'components/HookFormFields/HookFormComboBoxField';
import { useTranslation } from 'react-i18next';
import { useDocumentTypeContext } from 'views/DocumentDetails/components/DocumentTypeContext';
import { StorageFormValues } from './storageFormSchema';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { DocumentConversionWarningContainer } from 'containers/conversion/DocumentConversionWarningContainer';

export interface StorageFormDocumentTypeFieldProps
  extends HookFormComboBoxFieldProps<StorageFormValues> {
  newItems?: DocumentTypesFieldItem[];
  groupedItems?: DocumentTypesFieldItem[][];
  globalDocumentId: string;
}

export const StorageFormDocumentTypeField = ({
  name,
  items: _items,
  newItems,
  groupedItems,
  globalDocumentId,
  ...restProps
}: StorageFormDocumentTypeFieldProps) => {
  const [t] = useTranslation();
  const { documentType, setDocumentType, showConversionNeededWarning } =
    useDocumentTypeContext();

  const type = useWatch<StorageFormValues, 'documentType'>({
    name: 'documentType',
  });
  const newDocumentType = newItems?.find(item => item.key === type);
  const areDocumentTypesDifferent =
    documentType?.documentType !== newDocumentType?.key;

  useEffect(() => {
    if (areDocumentTypesDifferent) setDocumentType(newDocumentType?.value);
  }, [areDocumentTypesDifferent, newDocumentType?.value, setDocumentType]);

  return (
    <>
      <HookFormComboBoxField
        name={name}
        items={newItems}
        groupedLists={groupedItems}
        groupHeaders={[
          t('ecm:formSwitcher.approval'),
          t('ecm:formSwitcher.storage.title'),
        ]}
        isGroupedListMode
        {...restProps}
      >
        {(item: DocumentTypesFieldItem) => (
          <Item key={item.key} textValue={item.label}>
            {item.label}
          </Item>
        )}
      </HookFormComboBoxField>
      {showConversionNeededWarning && (
        <DocumentConversionWarningContainer
          globalDocumentId={globalDocumentId}
          documentTypeLabel={newDocumentType?.label ?? ''}
          documentType={newDocumentType?.value.documentType ?? ''}
        />
      )}
    </>
  );
};
