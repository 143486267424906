import { Box, Flex, Text } from '@candisio/design-system';
import i18next from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface DxsoProtocolProps {
  details?: string[] | null;
  targetClient?: string | null;
}

export const DxsoProtocol = ({ details, targetClient }: DxsoProtocolProps) => {
  const [t] = useTranslation();
  const translatedDetails = useMemo(
    () =>
      (details || []).map(text => {
        // some protocol details values can also be translated as warnings
        const translationkey = `export.table.columns.status.warnings.${text.toLowerCase()}`;

        return i18next.exists(translationkey) ? t(translationkey) : text;
      }),
    [t, details]
  );

  return (
    <Box
      color="gray700"
      background="gray100"
      padding="space16"
      borderRadius="basic"
    >
      <Box paddingBottom="space8">
        <Text color="gray800">
          {t('export.exportDetails.dxsoProtocol.title')}
        </Text>
      </Box>
      <Flex direction="column" rowGap="space8">
        {targetClient && (
          <Text color="gray700">
            {t('export.exportDetails.dxsoProtocol.targetClient', {
              targetClient,
            })}
          </Text>
        )}
        {translatedDetails.map((text, index) => (
          <Text color="gray700" key={index}>
            {text}
          </Text>
        ))}
      </Flex>
    </Box>
  );
};
