import { ScrollBox } from '@candisio/design-system';
import { ReactNode } from 'react';

export const CommentMentionsSuggestionContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <ScrollBox
      borderRadius="medium"
      boxShadow="elevatedShadow5"
      maxHeight="250px"
      overflow="auto"
    >
      {children}
    </ScrollBox>
  );
};
