import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { useSaveTableConfigurations } from 'hooks/useSaveTableConfigurations';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useGetTableConfigurations } from './useGetTableConfigurations';

type Configurations = {
  label: string;
  isVisible: boolean;
  isFixed?: true | undefined;
  isAvailable?: boolean;
};

const mapToObject = (array: Configuration[]) => {
  const configObject = array.reduce<Record<string, Configurations>>(
    (acc, item) => {
      acc[item.id] = {
        label: item.label,
        isVisible: item.isVisible,
        isFixed: item.isFixed,
        isAvailable: item.isAvailable,
      };

      return acc;
    },
    {}
  );

  return configObject;
};

export const mergeConfigs = (
  defaultConfigs: Configuration[],
  savedConfigs: Configuration[]
) => {
  const mergedConfigs: Configuration[] = [];
  const defaultConfigsObject = mapToObject(defaultConfigs);
  const savedConfigsObject = mapToObject(savedConfigs);

  for (const key of Object.keys(defaultConfigsObject)) {
    // Since this function gets called only if we have modified the default array manually we need to take
    // the label and isFixed always from the defaultConfigsObject because they are not field modifiable by the users

    const { label, isFixed } = defaultConfigsObject[key];

    // Other way around, is visible and isAvailable are modifiable by the user and we first need to take the ones
    // saved in BE if they exists.

    const { isVisible, isAvailable } =
      savedConfigsObject[key] ?? defaultConfigsObject[key];

    mergedConfigs.push({
      id: key,
      isAvailable: isAvailable,
      label: label,
      ...(isFixed
        ? { isFixed: true, isVisible: true }
        : { isVisible: isVisible }),
    });
  }

  return mergedConfigs;
};

export const shouldUpdateColumns = (
  defaultConfigs: Configuration[],
  savedConfigs: Configuration[]
) => {
  const basicInfosDefaultConfigs = defaultConfigs
    .map(config => ({
      id: config.id,
      label: config.label,
      isFixed: config.isFixed ?? false,
    }))
    .sort((a, b) => a.id.localeCompare(b.id));

  const basicInfosSavedConfigs = savedConfigs
    .map(config => ({
      id: config.id,
      label: config.label,
      isFixed: config.isFixed ?? false,
    }))
    .sort((a, b) => a.id.localeCompare(b.id));

  return !isEqual(basicInfosDefaultConfigs, basicInfosSavedConfigs);
};

interface UseUpdateColumnsParams {
  tableId: string;
  defaultColumnsConfigurations: Configuration[];
}

/**
 * This hook will target the saved configs in the BE
 * and it will update the configurations to include new columns or modified columns that will be introduced during development.
 * Without this hook, if we introduce a new column in the table or we change the label or we change the fixed columns, it will never be shown to the user because
 * the configurations in the BE would be outdated.
 */
export const useUpdateColumnsBE = ({
  tableId,
  defaultColumnsConfigurations,
}: UseUpdateColumnsParams) => {
  const { configurations } = useGetTableConfigurations({
    tableId,
    defaultColumnsConfigurations,
  });

  const { saveTableConfigurations } = useSaveTableConfigurations();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const handleSaveTableConfigurations = async () => {
      if (shouldUpdateColumns(defaultColumnsConfigurations, configurations)) {
        const mergedConfigs = mergeConfigs(
          defaultColumnsConfigurations,
          configurations
        );

        await saveTableConfigurations({
          tableId,
          configurations: mergedConfigs,
        });
      }
    };

    void handleSaveTableConfigurations();
  }, []);
};
