import { Box, Button, Flex, Grid, Tag, Text } from '@candisio/design-system';
import { useInboxTransactionCount } from 'hooks/useCounterQueries';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../../../models';
import { useCardholderId } from '../../../../../../providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from '../../../../../../providers/LocaleProvider';
import { useOrganizationId } from '../../../../../../providers/OrganizationProvider';
import { TRANSACTION_FILTER } from '../../../../../../views/Inbox/Transactions/useTransactionListFilters';
import { ShowTransactions } from '../../ShowTransactions';

type ExpiredProps = {
  onShowDetails: () => void;
  onShowAll: () => void;
  onShowMissing: () => void;
  transactionsWithoutDocumentCount: number;
};

export const Expired = ({
  onShowDetails,
  onShowAll,
  onShowMissing,
  transactionsWithoutDocumentCount,
}: ExpiredProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Flex direction="column" gap="space8">
      <Box background="gray200" borderRadius="8px" padding="space8">
        <Flex alignItems="center" gap="space8">
          <Tag color="gray" height="18px">
            {t('carousel.expired.tag')}
          </Tag>
          <Text color="gray800">{t('carousel.expired.infoText')}</Text>
        </Flex>
      </Box>
      <ShowTransactions
        onShowAll={onShowAll}
        onShowMissing={onShowMissing}
        transactionsWithoutDocumentCount={transactionsWithoutDocumentCount}
      />

      <Grid autoFlow="column" gap="space8">
        <Button
          size="small"
          color="blue"
          variant="secondary"
          icon="arrowRight"
          onClick={onShowDetails}
        >
          {t('carousel.details')}
        </Button>
      </Grid>
    </Flex>
  );
};

export const ExpiredContainer = ({ cardId }: { cardId: string }) => {
  const history = useHistory();
  const organizationSlug = useOrganizationId();
  const { cardholderId } = useCardholderId();

  const { inboxTransactionCount } = useInboxTransactionCount({
    cardholderIds: cardholderId ? [cardholderId] : undefined,
    cardIds: [cardId],
    skip: !cardholderId,
  });

  const onShowAll = () => {
    history.push({
      pathname: `/${organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}`,
      search: qs.stringify({
        [TRANSACTION_FILTER.cardRefNum]: [cardId],
      }),
    });
  };

  const onShowMissing = () => {
    history.push({
      pathname: `/${organizationSlug}${Routes.INBOX}${Routes.TRANSACTIONS}`,
      search: qs.stringify({
        [TRANSACTION_FILTER.cardRefNum]: [cardId],
      }),
    });
  };

  const onShowDetails = () => {
    history.replace({
      pathname: `/${organizationSlug}${Routes.DASHBOARD_CREDIT_CARD}/${cardId}`,
    });
  };

  return (
    <Expired
      onShowDetails={onShowDetails}
      onShowAll={onShowAll}
      onShowMissing={onShowMissing}
      transactionsWithoutDocumentCount={inboxTransactionCount}
    />
  );
};
