import { Flex, Heading } from '@candisio/design-system';
import { UploadDocumentMenuButton } from 'components/UploadDocumentMenuButton';
import { useTranslation } from 'react-i18next';

export const InboxContractsHeader = () => {
  const [t] = useTranslation();

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      gap="space16"
      paddingRight="space32"
      paddingLeft="space32"
      paddingTop="space20"
      paddingBottom="space16"
    >
      <Flex alignItems="center" gap="space16">
        <Heading as="h1">{t('inbox.title')}</Heading>
      </Flex>
      <UploadDocumentMenuButton />
    </Flex>
  );
};
