import { Money } from 'generated-types/graphql.types';
import { noop } from 'lodash';
import { ReactNode, createContext, useContext, useState } from 'react';

export type BookingsFormPartialData = {
  bookingId: string;
  price: Money;
  quantity?: number;
  description: string;
}[];

type BookingsFormContextData = {
  data: BookingsFormPartialData;
  setData: (bookings: BookingsFormPartialData) => void;
  shouldResetForm?: boolean;
  setShouldResetForm?: (shouldResetForm: boolean) => void;
};

export const BookingsFormContext = createContext<BookingsFormContextData>({
  data: [],
  setData: noop,
});

export const useBookingsFormContext = () => {
  return useContext<BookingsFormContextData>(BookingsFormContext);
};

export const BookingsFormContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [bookingsFormData, setBookingsFormData] =
    useState<BookingsFormPartialData>([]);

  const [shouldResetForm, setShouldResetForm] = useState(false);

  return (
    <BookingsFormContext.Provider
      value={{
        data: bookingsFormData,
        setData: setBookingsFormData,
        shouldResetForm,
        setShouldResetForm,
      }}
    >
      {children}
    </BookingsFormContext.Provider>
  );
};
