import {
  ProjectCodeDataFragment,
  ProjectCodeInfiniteScrollPaginationQuery,
  ProjectCodeInfiniteScrollPaginationQueryVariables,
  ProjectCodeSortField,
  SortDirection,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';
import { projectCodeInfiniteScrollPagination } from '../gql';

// TODO: TPX-1205 add count queries once backend is ready

interface ProjectCodeListData {
  projectCodeData: ProjectCodeDataFragment[];
  handleDebounceSearch: (inputStr: string) => void;
  isLoading: boolean;
  onEndReached: () => void;
}

export const useProjectCodeListData = ({
  showArchived,
  sortBy,
}: {
  showArchived: boolean;
  sortBy: ProjectCodeSortField;
}): ProjectCodeListData => {
  const computeVariables = useCallback(
    (inputStr?: string): ProjectCodeInfiniteScrollPaginationQueryVariables => ({
      filters: {
        isArchived: showArchived,
      },
      sortBy: {
        direction:
          sortBy === ProjectCodeSortField.CreatedAt
            ? SortDirection.Desc
            : SortDirection.Asc,
        field: sortBy,
      },
      // TODO: backend which fields to search
      queries: {
        name: inputStr,
      },
    }),
    [showArchived, sortBy]
  );

  const {
    data: projectCodeData,
    handleDebounceSearch,
    loading: isLoading,
    loadMore: onEndReached,
  } = useDebouncedSearchPagination<
    ProjectCodeInfiniteScrollPaginationQuery,
    ProjectCodeInfiniteScrollPaginationQueryVariables
  >({
    query: projectCodeInfiniteScrollPagination,
    queryRootKey: 'projectCodePagination',
    computeVariables,
  });

  return {
    projectCodeData: projectCodeData?.projectCodePagination?.records ?? [],
    handleDebounceSearch,
    isLoading,
    onEndReached,
  };
};
