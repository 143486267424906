import { Box, Text } from '@candisio/design-system';
import { ReactNode } from 'react';

interface ExportInfoProps {
  children: ReactNode;
}

export const ExportInfo = ({ children }: ExportInfoProps) => {
  return (
    <Box
      background="gray100"
      borderRadius="basic"
      position="relative"
      textAlign="center"
      padding="space32 space16"
    >
      {children}
    </Box>
  );
};

interface ExportInfoLabelProps {
  children: ReactNode;
}

export const ExportInfoLabel = ({ children }: ExportInfoLabelProps) => (
  <Box paddingTop="space12">
    <Text as="h4">{children}</Text>
  </Box>
);
