import { Button, Grid, Radio, RadioGroup, Text } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { usePermissionsByCreditCardId } from 'containers/credit-cards/hooks/usePermissionsByCreditCardId';
import { useGenericErrorMessageHandler } from 'containers/credit-cards/utils';
import {
  CardStatus,
  CardType,
  TerminationReason,
  useTerminateCardMutation,
  useTerminateProcessingCardMutation,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ReplaceCardTip } from './ReplaceCardTip';

interface TerminationReasonsFormProps {
  cardType?: CardType;
  close: () => void;
  afterTerminate: () => void;
  cardId: string;
  cardholder: string;
  cardStatus?: CardStatus;
  onSetMode: (mode: DrawerMode) => void;
}

export const TerminationReasonsForm = ({
  cardType,
  close,
  afterTerminate,
  cardId,
  cardholder,
  cardStatus,
  onSetMode,
}: TerminationReasonsFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const genericErrorMessageHandler = useGenericErrorMessageHandler();
  const { success } = useToastMessage();
  const navigate = useNavigate();
  const { canReplace } = usePermissionsByCreditCardId({ cardId });
  const organizationSlug = useOrganizationId();

  const [selectedValue, setSelectedValue] = useState<TerminationReason>(
    TerminationReason.Other
  );

  const { refetchQueries, evictPaginationResults } =
    useCreditCardsRefetchQueries();

  const [terminateCard, { loading }] = useTerminateCardMutation({
    onError: () => genericErrorMessageHandler(),
    variables: { input: { reason: selectedValue, cardId } },
    onCompleted: () => {
      evictPaginationResults();
      client.cache.evict({ fieldName: 'getCardIssuerCardsForCardholder' });
    },
  });

  const [
    terminateProcessingCard,
    { loading: loadingTerminateProcessingCard, client },
  ] = useTerminateProcessingCardMutation({
    variables: { input: { cardId } },
    onError: () => genericErrorMessageHandler(),
    onCompleted: () => {
      evictPaginationResults();
      client.cache.evict({ fieldName: 'getCardIssuerCardsForCardholder' });
    },
  });

  const handleSubmit = async () => {
    const action =
      cardStatus === CardStatus.Processing
        ? terminateProcessingCard
        : terminateCard;

    const response = await action({
      awaitRefetchQueries: true,
      refetchQueries: refetchQueries.cardIssuerCards(cardId),
    });

    if (response.data) {
      success(t('dashboard.drawer.cardTerminated'));
      navigate(`/${organizationSlug}${Routes.DASHBOARD}`);
    }
  };

  const terminateReasons: Record<
    CardType,
    { value: string; message: string }[]
  > = {
    BLACK: [
      {
        value: TerminationReason.Other,
        message: 'dashboard.drawer.terminateCardPopover.reasons.other',
      },
      {
        value: TerminationReason.Stolen,
        message: 'dashboard.drawer.terminateCardPopover.reasons.stolen',
      },
      {
        value: TerminationReason.Lost,
        message: 'dashboard.drawer.terminateCardPopover.reasons.lost',
      },
    ],
    PHYSICAL: [
      {
        value: TerminationReason.Other,
        message: 'dashboard.drawer.terminateCardPopover.reasons.other',
      },
      {
        value: TerminationReason.Stolen,
        message: 'dashboard.drawer.terminateCardPopover.reasons.stolen',
      },
      {
        value: TerminationReason.Lost,
        message: 'dashboard.drawer.terminateCardPopover.reasons.lost',
      },
    ],
    SINGLE_USE: [
      {
        value: TerminationReason.Other,
        message: 'dashboard.drawer.terminateCardPopover.reasons.other',
      },
      {
        value: TerminationReason.Stolen,
        message: 'dashboard.drawer.terminateCardPopover.reasons.compromised',
      },
    ],
    VIRTUAL: [
      {
        value: TerminationReason.Other,
        message: 'dashboard.drawer.terminateCardPopover.reasons.other',
      },
      {
        value: TerminationReason.Stolen,
        message: 'dashboard.drawer.terminateCardPopover.reasons.compromised',
      },
    ],
  };

  const listReasonToShow = terminateReasons[cardType ?? CardType.Virtual];

  if (cardStatus === CardStatus.Processing) {
    return (
      <Grid gap="space16" padding="space16">
        <Text>
          {t('dashboard.drawer.terminateCardPopover.confirmationMessage')}
        </Text>
        <Grid autoFlow="column" gap="space16" placeContent="end">
          <Button
            size="small"
            variant="secondary"
            onClick={close}
            disabled={loadingTerminateProcessingCard}
          >
            {t('dashboard.drawer.terminateCardPopover.buttonCancel')}
          </Button>
          <Button
            type="submit"
            size="small"
            loading={loadingTerminateProcessingCard}
            color="red"
            onClick={async () => {
              await handleSubmit();
              afterTerminate();
            }}
          >
            {t('dashboard.drawer.terminateCardPopover.buttonCTA')}
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      <form onSubmit={e => e.preventDefault()}>
        <Grid gap="space16" padding="space16">
          <Grid gap="space10">
            <Text fontWeight="semibold" fontSize="large">
              {t('dashboard.drawer.terminateCardPopover.title')}
            </Text>
            <RadioGroup
              defaultValue={TerminationReason.Other}
              onChange={(e: string) => {
                setSelectedValue(e as TerminationReason);
              }}
            >
              {listReasonToShow.map(({ message, value }) => (
                <Radio value={value} key={value}>
                  <Text fontSize="small">{t(message)}</Text>
                </Radio>
              ))}
            </RadioGroup>
          </Grid>
          <Grid autoFlow="column" gap="space16" placeContent="end">
            <Button
              size="small"
              variant="secondary"
              onClick={close}
              disabled={loading || loadingTerminateProcessingCard}
            >
              {t('dashboard.drawer.terminateCardPopover.buttonCancel')}
            </Button>
            <Button
              type="submit"
              size="small"
              loading={loading || loadingTerminateProcessingCard}
              color="red"
              onClick={async () => {
                await handleSubmit();
                afterTerminate();
              }}
            >
              {t('dashboard.drawer.terminateCardPopover.buttonCTA')}
            </Button>
          </Grid>
        </Grid>
      </form>
      {selectedValue !== TerminationReason.Other && canReplace && (
        <ReplaceCardTip onSetMode={onSetMode} />
      )}
    </Grid>
  );
};
