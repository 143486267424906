import { Grid, Item } from '@candisio/design-system';
import { EmptyDataState } from 'components/FilterableList/components/EmptyDataState';
import { EmptySearchState } from 'components/FilterableList/components/EmptySearchState';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { motion } from 'framer-motion';
import {
  DocumentDataFileStatus,
  DocumentDataFileType,
  DocumentDateFilters,
  DownloadFilters,
  EcmDocumentFilters,
  Maybe,
} from 'generated-types/graphql.types';
import { useState } from 'react';
import { escapeRegex } from 'utils/regex';
import { DownloadHistory } from './components/variants/DownloadHistory';
import { ErrorImportHistory } from './components/variants/ErrorImportHistory';
import { ErrorImportHistorySap } from './components/variants/ErrorImportHistorySap';
import { ImportHistory } from './components/variants/ImportHistory';

export type ImportStatus = {
  countFailedToCreate?: Maybe<number>;
  countSuccessfullyCreated?: Maybe<number>;
};

export const HISTORY_LIST_WIDTH = '657px';

export interface ImportHistoryItem {
  createdAt?: Maybe<string>;
  dateRangeFilters?: DocumentDateFilters | null;
  errorFileUrl?: string | undefined;
  file?: {
    name?: string;
    url?: string;
    size?: number;
  };
  fileType?: DocumentDataFileType;
  ecmFilters?: EcmDocumentFilters;
  filters?: DownloadFilters | undefined;
  importStatus?: ImportStatus;
  isExpired?: boolean;
  status?: DocumentDataFileStatus;
  query?: string | null;
  username?: string;
}

type ImportHistoryVariants =
  | 'downloadHistory'
  | 'importHistory'
  | 'errorImportHistory'
  | 'errorImportHistorySap';

export interface HistoryListProps {
  data: ImportHistoryItem[];
  variant: ImportHistoryVariants;
  emptyState?: {
    translation: string;
    url?: string;
  };
  isLoading: boolean;
  isSapVariant?: boolean;
  /** if searchPlaceholder is undefined the toolbar will not be displayed  */
  searchPlaceholder?: string;
  width?: string;
}

const MotionGrid = motion(Grid);

const queriedItems = (items: ImportHistoryItem[], queryString: string) => {
  const regExp = new RegExp(escapeRegex(queryString), 'i');

  if (queryString.length === 0) {
    return items;
  }

  return items.filter(item => item.file?.name?.match(regExp));
};

export const HistoryList = ({
  data,
  variant,
  emptyState,
  isLoading,
  isSapVariant,
  searchPlaceholder,
  width = '657px',
}: HistoryListProps) => {
  const [queryString, setQueryString] = useState('');

  const onSearchFilter = (filter: string) => {
    setQueryString(filter);
  };

  const dataItems = queriedItems(data, queryString);

  const renderHistoryItem = (item: ImportHistoryItem) => {
    switch (variant) {
      case 'downloadHistory':
        return <DownloadHistory item={item} queryString={queryString} />;
      case 'errorImportHistory':
        return (
          <ErrorImportHistory
            item={item}
            queryString={queryString}
            isSapVariant={isSapVariant}
          />
        );
      case 'errorImportHistorySap':
        return <ErrorImportHistorySap item={item} queryString={queryString} />;
      case 'importHistory':
        return <ImportHistory item={item} queryString={queryString} />;
      default:
        return null;
    }
  };

  const searchReset = () => {
    setQueryString('');
  };

  return (
    <FilterableList
      children={dataItems.map((item: ImportHistoryItem, index: number) => (
        <Item key={index} textValue={item.username}>
          <MotionGrid
            borderBottom="1px solid gray200"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: 'easeOut', duration: 0.3 }}
          >
            {renderHistoryItem(item)}
          </MotionGrid>
        </Item>
      ))}
      emptyDataState={
        emptyState ? (
          <EmptyDataState
            translation={emptyState.translation}
            url={emptyState.url}
          />
        ) : null
      }
      emptySearchState={<EmptySearchState searchReset={searchReset} />}
      isLoading={isLoading}
      searchField={
        searchPlaceholder
          ? {
              onSearchFilter,
              placeholder: searchPlaceholder,
              searchQuery: queryString,
            }
          : undefined
      }
      width={width}
    />
  );
};
