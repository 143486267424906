import { usePromotionDetailsModalContent } from 'components/NewPromotions/hooks/usePromotionDetailsModalContent';
import {
  PromotionDetail,
  PromotionModalId,
} from 'components/NewPromotions/types';
import { replaceUTMSource } from 'utils/url-helper';

export const useGetCustomPromotionDetails = (
  promotionModalId: PromotionModalId,
  utmSource?: string
): PromotionDetail => {
  const { promotionDetails } =
    usePromotionDetailsModalContent(promotionModalId);

  const { ctaButton, ...restDetails } = promotionDetails || {};
  const { text = '', url = '' } = ctaButton || {};

  return {
    ...restDetails,
    ctaButton: {
      text,
      url: replaceUTMSource(url, utmSource),
    },
  };
};
