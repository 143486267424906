import { Button, Flex } from '@candisio/design-system';
import { colors } from '@candisio/design-system/src/Theme/themeValues';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportNotifications } from './ExportNotifications/ExportNotifications';
import { ImportNotifications } from './ImportNotifications/ImportNotifications';
import { InAppNotifications } from './InAppNotifications/InAppNotifications';

interface NotificationSummaryContainerProps {
  currentCompanyOnly?: boolean;
  onClose: () => void;
}

type NotificationType = 'general' | 'import' | 'export';

export const NotificationsPopoverContent = ({
  currentCompanyOnly,
  onClose,
}: NotificationSummaryContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const [activeNotification, setActiveNotification] =
    useState<NotificationType>('general');

  const wipImportExportNotificationsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.wipImportExportNotifications
  );

  const handleButtonClick = (type: NotificationType) => {
    setActiveNotification(type);
  };

  const getButtonStyle = (type: NotificationType) => ({
    background: activeNotification === type ? colors.gray400 : colors.gray250,
  });

  return (
    <Flex direction="column" justifyContent="space-between" height="100%">
      {currentCompanyOnly && !wipImportExportNotificationsFF && (
        <Flex gap="space12" padding="space16 0 space16 space16">
          <Button
            variant="secondary"
            onClick={() => handleButtonClick('general')}
            style={getButtonStyle('general')}
          >
            {t('notifications.buttonGroup.general')}
          </Button>
          <Button
            variant="secondary"
            onClick={() => handleButtonClick('import')}
            style={getButtonStyle('import')}
          >
            {t('notifications.buttonGroup.import')}
          </Button>
          <Button
            variant="secondary"
            onClick={() => handleButtonClick('export')}
            style={getButtonStyle('export')}
          >
            {t('notifications.buttonGroup.export')}
          </Button>
        </Flex>
      )}

      {wipImportExportNotificationsFF ? (
        <InAppNotifications
          onClose={onClose}
          currentCompanyOnly={!!currentCompanyOnly}
        />
      ) : (
        <Flex direction="column" justifyContent="space-between" height="100%">
          {(activeNotification === 'general' || !currentCompanyOnly) && (
            <InAppNotifications
              onClose={onClose}
              currentCompanyOnly={!!currentCompanyOnly}
            />
          )}
          {activeNotification === 'import' && (
            <ImportNotifications onClose={onClose} />
          )}
          {activeNotification === 'export' && (
            <ExportNotifications onClose={onClose} />
          )}
        </Flex>
      )}
    </Flex>
  );
};
