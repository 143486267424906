import image from 'assets/icon-email-import.svg';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';
import { BaseItem, BaseItemProps } from './BaseItem';

const StyledEmail = styled(BaseItem)`
  text-transform: none !important;
`;

export const Email = (props: BaseItemProps) => {
  const [t] = useTranslation();

  return (
    <StyledEmail {...props} src={image} author={t('inbox.actions.import')} />
  );
};
