import {
  Export,
  ExportStatus,
  ExportableEntityInfo,
  ExportableEntityType,
} from 'generated-types/graphql.types';
import { DeepPartial } from 'utility-types';
import { AggregatedCoreDataInfo, ExportDetails } from '../../types';
import { gqlDocumentToExportEntity } from './toExportEntity';

export const gqlExportToExportDetails = (
  gqlExport: DeepPartial<Export>
  // @ts-ignore
): ExportDetails => ({
  ...gqlExport,
  // @ts-ignore
  documents: (gqlExport.exportableEntityInfo || [])
    .filter(entityInfo => entityInfo.type === 'DOCUMENT')
    .filter(
      entityInfo =>
        entityInfo.id &&
        gqlExport.documents
          ?.map(doc => doc?.document?.id)
          .includes(entityInfo.id)
    )
    .map(entityInfo => ({
      document: {
        // @ts-ignore
        ...gqlDocumentToExportEntity(
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          gqlExport.documents?.find(doc => doc?.document?.id === entityInfo.id)
            ?.document!
        ),
        exportStatus: {
          status: entityInfo.status || ExportStatus.Pending,
          warnings: entityInfo.warnings,
        },
      },
    })),
  exportedPaymentConditionsInfo: getExportedCoreDataCount(
    ExportableEntityType.AggregatedPaymentConditions,
    gqlExport.exportableEntityInfo
  ),
  exportedContactsInfo: getExportedCoreDataCount(
    ExportableEntityType.AggregatedContacts,
    gqlExport.exportableEntityInfo
  ),
});

const getExportedCoreDataCount = (
  type: ExportableEntityType,
  exportableEntityInfo?: DeepPartial<ExportableEntityInfo[]> | null
): AggregatedCoreDataInfo | undefined => {
  const exportedCoreDataInfo = exportableEntityInfo?.filter(
    info => info.type === type
  );

  if (!exportedCoreDataInfo?.length) return undefined;

  const { details, id, status } = exportedCoreDataInfo[0];

  if (!details?.length) return undefined;

  const [count] = details;

  return {
    count: Number(count),
    id: id ?? '',
    status: status ?? ExportStatus.Unknown,
  };
};
