import { Icon, Link, Spinner, Text } from '@candisio/design-system';
import { CheckmarkIcon, ErrorIcon } from 'components/Icons/DefaultIcons';
import { LedgerState } from 'generated-types/graphql.types';
import i18next from 'i18next';
import { CheckProp } from './types';

const ledgerKey = 'settings.datev.connect.exportTypes.dxsoJobs.ledgerStates';
const bds = 'settings.datev.connect.exportTypes.bds';
const emailKey =
  'settings.datev.connect.exportTypes.dxsoJobs.taxAdvisorEmail.checks';

const ErrorTextWithLink = ({
  t,
  namespace,
}: {
  t: i18next.TFunction;
  namespace: string;
}) => {
  return (
    <Text color="red500">
      {t(`${ledgerKey}.${namespace}`)}{' '}
      <Link external href={t(`${ledgerKey}.${namespace}InfoUrl`)}>
        {t(`${ledgerKey}.moreInfoText`)}
      </Link>
    </Text>
  );
};

const BDSErrorTextWithLink = ({ t }: { t: i18next.TFunction }) => {
  return (
    <Text color="red500">
      {t(`${bds}.error`)}{' '}
      <Link external href={t(`${bds}.errorInfoUrl`)}>
        {t(`${bds}.errorInfoText`)}
      </Link>
    </Text>
  );
};

const TextWithLink = ({
  t,
  namespace,
}: {
  t: i18next.TFunction;
  namespace: string;
}) => {
  return (
    <Text>
      {t(`${ledgerKey}.${namespace}`)}{' '}
      <Link external href={t(`${ledgerKey}.${namespace}Url`)}>
        {t(`${ledgerKey}.moreInfoText`)}
      </Link>
    </Text>
  );
};

export type GetCheckProps = {
  isDocumentManagementAvailable: {
    loading: CheckProp;
    success: CheckProp;
    error: CheckProp;
  };
  isBDSConnected: {
    loading: CheckProp;
    success: CheckProp;
    error: CheckProp;
  };
  cash: {
    loading: CheckProp;
    [LedgerState.Found]: CheckProp;
    [LedgerState.NotFound]: CheckProp;
    [LedgerState.OutdatedFiscalYear]: CheckProp;
  };
  cashAccountAdded: {
    loading: CheckProp;
    success: CheckProp;
    info: CheckProp;
  };
  creditCardAccountAdded: {
    loading: CheckProp;
    success: CheckProp;
    info: CheckProp;
  };
  provisionsAccountAdded: {
    loading: CheckProp;
    success: CheckProp;
    error: CheckProp;
  };
  accountsPayable: {
    loading: CheckProp;
    [LedgerState.Found]: CheckProp;
    [LedgerState.NotFound]: CheckProp;
    [LedgerState.OutdatedFiscalYear]: CheckProp;
  };
  accountsReceivable: {
    loading: CheckProp;
    [LedgerState.Found]: CheckProp;
    [LedgerState.NotFound]: CheckProp;
    [LedgerState.OutdatedFiscalYear]: CheckProp;
  };
  connected: {
    success: CheckProp;
  };
  network: {
    failure: CheckProp;
  };
  customError: CheckProp;
};

const loadingIcon = <Spinner color="blue700" size="space12" />;
const successIcon = <CheckmarkIcon flex="none" size="space16" />;
const errorIcon = <ErrorIcon flex="none" size="space16" />;

const infoIcon = (
  <Icon icon="infoCircle" flex="none" color="blue700" size="space16" />
);

export const isSuccessStateCashAccountAdded = (check: CheckProp) =>
  check.icon === successIcon ||
  check.key === 'cashAccountAdded' ||
  check.key === 'creditCardAccountAdded';

export const getChecks = (t: i18next.TFunction): GetCheckProps => {
  return {
    connected: {
      success: {
        key: 'connected',
        icon: successIcon,
        text: t(
          'settings.datev.connect.exportTypes.accountingDocuments.connected'
        ),
        state: 'loaded',
      },
    },
    isDocumentManagementAvailable: {
      loading: {
        key: 'isDocumentManagementAvailable',
        icon: loadingIcon,

        text: t(`${ledgerKey}.documents.loading`),
        state: 'loading',
      },
      success: {
        key: 'isDocumentManagementAvailable',
        icon: successIcon,

        text: t(`${ledgerKey}.documents.success`),
        state: 'loaded',
      },
      error: {
        key: 'isDocumentManagementAvailable',
        icon: errorIcon,
        text: <ErrorTextWithLink t={t} namespace="documents.error" />,

        emailDescription: t(`${emailKey}.documents.error`),
        state: 'loaded',
      },
    },
    isBDSConnected: {
      loading: {
        key: 'isBDSConnectionSelected',
        icon: loadingIcon,

        text: t(`${bds}.loading`),
        state: 'loading',
      },
      success: {
        key: 'isBDSConnectionSelected',
        icon: successIcon,

        text: t(`${bds}.connected`),
        state: 'loaded',
      },
      error: {
        key: 'isBDSConnectionSelected',
        icon: errorIcon,
        text: <BDSErrorTextWithLink t={t} />,
        state: 'loaded',
      },
    },
    cashAccountAdded: {
      loading: {
        key: 'cashAccountAdded',
        icon: loadingIcon,

        text: t(`${ledgerKey}.cashAccountAdded.loading`),
        state: 'loading',
      },
      success: {
        key: 'cashAccountAdded',
        icon: successIcon,

        text: t(`${ledgerKey}.cashAccountAdded.success`),
        state: 'loaded',
      },
      info: {
        key: 'cashAccountAdded',
        icon: infoIcon,
        text: <TextWithLink t={t} namespace="cashAccountAdded.info" />,

        emailDescription: t(`${emailKey}.cashAccountAdded.info`),
        state: 'loaded',
      },
    },
    creditCardAccountAdded: {
      loading: {
        key: 'creditCardAccountAdded',
        icon: loadingIcon,

        text: t(`${ledgerKey}.creditCardAccountAdded.loading`),
        state: 'loading',
      },
      success: {
        key: 'creditCardAccountAdded',
        icon: successIcon,

        text: t(`${ledgerKey}.creditCardAccountAdded.success`),
        state: 'loaded',
      },
      info: {
        key: 'creditCardAccountAdded',
        icon: errorIcon,
        text: <TextWithLink t={t} namespace="creditCardAccountAdded.info" />,

        emailDescription: t(`${emailKey}.creditCardAccountAdded.info`),
        state: 'loaded',
      },
    },
    provisionsAccountAdded: {
      loading: {
        key: 'provisionsAccountAdded',
        icon: loadingIcon,

        text: t(`${ledgerKey}.provisionsAccountAdded.loading`),
        state: 'loading',
      },
      success: {
        key: 'provisionsAccountAdded',
        icon: successIcon,

        text: t(`${ledgerKey}.provisionsAccountAdded.success`),
        state: 'loaded',
      },
      error: {
        key: 'provisionsAccountAdded',
        icon: errorIcon,
        text: (
          <Text color="red500">
            {t(`${ledgerKey}.provisionsAccountAdded.error`)}
          </Text>
        ),
        state: 'loaded',
      },
    },
    cash: {
      loading: {
        key: 'cash',
        icon: loadingIcon,

        text: t(`${ledgerKey}.cash.loading`),
        state: 'loading',
      },
      [LedgerState.Found]: {
        key: 'cash',
        icon: successIcon,

        text: t(`${ledgerKey}.cash.Found`),
        state: 'loaded',
      },
      [LedgerState.NotFound]: {
        key: 'cash',
        icon: errorIcon,
        text: <ErrorTextWithLink t={t} namespace="cash.NotFound" />,

        emailDescription: t(`${emailKey}.cash.NotFound`),
        state: 'loaded',
      },
      [LedgerState.OutdatedFiscalYear]: {
        key: 'cash',
        icon: errorIcon,
        text: <ErrorTextWithLink t={t} namespace="cash.OutdatedFiscalYear" />,

        emailDescription: t(`${emailKey}.cash.OutdatedFiscalYear`),
        state: 'loaded',
      },
    },
    accountsPayable: {
      loading: {
        key: 'accountsPayable',
        icon: loadingIcon,

        text: t(`${ledgerKey}.accountsPayable.loading`),
        state: 'loading',
      },
      [LedgerState.Found]: {
        key: 'accountsPayable',
        icon: successIcon,

        text: t(`${ledgerKey}.accountsPayable.Found`),
        state: 'loaded',
      },
      [LedgerState.NotFound]: {
        key: 'accountsPayable',
        icon: errorIcon,
        text: <ErrorTextWithLink t={t} namespace="accountsPayable.NotFound" />,

        emailDescription: t(`${emailKey}.accountsPayable.NotFound`),
        state: 'loaded',
      },
      [LedgerState.OutdatedFiscalYear]: {
        key: 'accountsPayable',
        icon: errorIcon,
        text: (
          <ErrorTextWithLink
            t={t}
            namespace="accountsPayable.OutdatedFiscalYear"
          />
        ),

        emailDescription: t(`${emailKey}.accountsPayable.OutdatedFiscalYear`),
        state: 'loaded',
      },
    },
    accountsReceivable: {
      loading: {
        key: 'accountsReceivable',
        icon: loadingIcon,

        text: t(`${ledgerKey}.accountsReceivable.loading`),
        state: 'loading',
      },
      [LedgerState.Found]: {
        key: 'accountsReceivable',
        icon: successIcon,

        text: t(`${ledgerKey}.accountsReceivable.Found`),
        state: 'loaded',
      },
      [LedgerState.NotFound]: {
        key: 'accountsReceivable',
        icon: errorIcon,
        text: (
          <ErrorTextWithLink t={t} namespace="accountsReceivable.NotFound" />
        ),

        emailDescription: t(`${emailKey}.accountsReceivable.NotFound`),
        state: 'loaded',
      },
      [LedgerState.OutdatedFiscalYear]: {
        key: 'accountsReceivable',
        icon: errorIcon,
        text: (
          <ErrorTextWithLink
            t={t}
            namespace="accountsReceivable.OutdatedFiscalYear"
          />
        ),

        emailDescription: t(
          `${emailKey}.accountsReceivable.OutdatedFiscalYear`
        ),
        state: 'loaded',
      },
    },
    network: {
      failure: {
        key: 'networkFailure',
        icon: errorIcon,
        text: t('settings.datev.connect.exportTypes.dxsoJobs.networkFailure'),
        state: 'loaded',
      },
    },
    customError: {
      key: 'customError',
      icon: errorIcon,
      text: '',
      state: 'loaded',
    },
  };
};
