import { Grid, Text } from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import {
  CardStatus,
  useGetCardStatusByIdQuery,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useTransactionsLinks } from './useTransactionsLinks';

const visibleInWalletStatuses: CardStatus[] = [
  CardStatus.Active,
  CardStatus.Locked,
  CardStatus.Pending,
  CardStatus.LockedPin,
  CardStatus.Requested,
  CardStatus.Processing,
] as const;

export const isVisibleInWallet = (cardStatus: CardStatus) =>
  visibleInWalletStatuses.includes(cardStatus);

const statusesTranslations: Record<CardStatus, string> = {
  ACTIVE: 'credit-cards:cardManagerView.status.active',
  EXPIRED: 'credit-cards:cardManagerView.status.expired',
  LOCKED: 'credit-cards:cardManagerView.status.locked',
  PENDING: 'credit-cards:cardManagerView.status.pending',
  PROCESSING: 'credit-cards:cardManagerView.status.processing',
  REQUESTED: 'credit-cards:cardManagerView.status.requested',
  REQUEST_CANCELLED: 'credit-cards:cardManagerView.status.requestCancelled',
  REQUEST_REJECTED: 'credit-cards:cardManagerView.status.requestRejected',
  TERMINATED: 'credit-cards:cardManagerView.status.terminated',
  LOCKED_PIN: 'credit-cards:cardManagerView.status.lockedPin',
} as const;

interface CreditCardLinksTooltipProps {
  membershipId: string | undefined;
  cardId: string | undefined;
}

export const CreditCardLinksTooltip = ({
  membershipId,
  cardId,
}: CreditCardLinksTooltipProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const user = useCurrentUser();
  const { isCardManager } = useUserRoles();
  const { showAllExtraFeatures } = useCreditCardsSetup();

  const { data, loading } = useGetCardStatusByIdQuery({
    variables: { id: cardId as string },
    skip: !cardId,
  });

  const cardStatus = data?.getCardIssuerCardById.status;
  const links = useTransactionsLinks(cardId);

  // If one of those values is not there it does not make sense to render the links

  if (!cardId || !membershipId || !cardStatus || !links || loading) {
    return null;
  }

  const isCardVisibleInWallet = isVisibleInWallet(cardStatus);
  const isCardAndTransactionOwner = membershipId === user?.id;
  const isAllowedToSeeCardSettings =
    showAllExtraFeatures &&
    ((isCardAndTransactionOwner && isCardVisibleInWallet) || isCardManager);

  return (
    <Grid gap="space4" maxWidth="220px">
      <RouterLink target="_blank" to={links.archiveTransactions} external>
        {t('transactionDetailsCard.creditCardLinks.archiveTransactions')}
      </RouterLink>
      {isAllowedToSeeCardSettings && (
        <RouterLink
          target="_blank"
          to={isCardManager ? links.cardManagerView : links.dashboardWallet}
          external
        >
          {t('transactionDetailsCard.creditCardLinks.cardSettings')}
        </RouterLink>
      )}
      {!isAllowedToSeeCardSettings && (
        <Text color="gray500">
          {!isCardAndTransactionOwner
            ? t(
                'transactionDetailsCard.creditCardLinks.notAllowedToSeeCardSettings'
              )
            : t('transactionDetailsCard.creditCardLinks.cardIsNotInWallet', {
                cardStatus: t(statusesTranslations[cardStatus]).toLowerCase(),
              })}
        </Text>
      )}
    </Grid>
  );
};
