import { Card, Grid, Text, Button, Box } from '@candisio/design-system';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const MotionBox = motion(Box);

type SelectionCardProps = {
  count: number;
  totalCount: number;
  onMerge: () => void;
  mode: 'archiving' | 'activating';
  onArchive: () => void;
  isArchiving?: boolean;
  onActivate: () => void;
  isActivating?: boolean;
};

export const SelectionCard = ({
  count,
  totalCount,
  onMerge,
  onArchive,
  isArchiving: archiving,
  mode,
  onActivate,
  isActivating: activating,
}: SelectionCardProps) => {
  const [t] = useTranslation();

  const mergeButtonDisabled = archiving || activating;

  return (
    <MotionBox
      width="100%"
      variants={{
        visible: { height: 'auto', opacity: 1 },
        hidden: { height: '0', opacity: 0 },
      }}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Card
        background="blue100"
        corners="bottom"
        boxShadow="elevatedShadow5"
        paddingX="space36"
        maxHeight="space80"
      >
        <Grid
          alignItems="center"
          autoFlow="column"
          justifyContent="space-between"
        >
          <Grid alignItems="center" autoFlow="column" gap="space8">
            <Text>
              {t('settings.contacts.actions.selectedInfo', {
                count,
                totalCount: totalCount,
              })}
            </Text>
          </Grid>
          <Grid autoFlow="column" gap="space16">
            <Button
              variant="secondary"
              onClick={onMerge}
              disabled={mergeButtonDisabled || count < 2}
            >
              {t('settings.contacts.actions.merge')}
            </Button>
            {mode === 'archiving' ? (
              <ConfirmationButton
                variant="secondary"
                onConfirm={onArchive}
                disabled={archiving}
                loading={archiving}
                confirmationTitle={t(
                  'settings.contacts.details.edit.confirmArchive',
                  {
                    count,
                  }
                )}
              >
                {t('settings.contacts.actions.archive')}
              </ConfirmationButton>
            ) : (
              <Button
                variant="secondary"
                onClick={onActivate}
                disabled={activating}
                loading={activating}
              >
                {t('settings.contacts.actions.activate')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Card>
    </MotionBox>
  );
};
