import { Box, Grid, GridProps, Heading } from '@candisio/design-system';
import { BackButton } from 'components/BackButton/BackButton';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

export interface ImportHistoryLayoutProps extends GridProps {
  title: string;
  backButtonLabel: string;
  backButtonLink?: string;
  aboveTable?: ReactElement;
  titleAs?: 'h1' | 'h2';
}

export const ImportHistoryLayout = ({
  title,
  backButtonLabel,
  backButtonLink,
  children,
  titleAs = 'h2',
  height,
  aboveTable,
  ...restProps
}: ImportHistoryLayoutProps) => {
  const navigate = useNavigate();

  // If a custom link for the back button is provided, navigate to it, otherwise go back to the previous page
  const handleClick = () => {
    if (backButtonLink) {
      navigate(backButtonLink);
    } else {
      navigate(-1);
    }
  };

  return (
    <Grid
      minHeight="100%"
      templateRows={
        aboveTable ? 'min-content min-content auto' : 'min-content auto'
      }
      height={height}
      gap="space16"
      {...restProps}
    >
      <Grid gap="space16">
        <Grid autoFlow="row" gap="space16" justifyContent="space-between">
          <Heading as={titleAs}>{title}</Heading>
          <Box minWidth="60ch" maxWidth="50%">
            <BackButton onClick={handleClick}>{backButtonLabel}</BackButton>
          </Box>
        </Grid>
      </Grid>
      {aboveTable}
      {children}
    </Grid>
  );
};
