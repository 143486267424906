import { Box, ScrollBox } from '@candisio/design-system';
import { useMemo } from 'react';
import { HistoryLoading } from '../History/HistoryLoading';
import { supportedEventKeys } from './common';
import { DocumentHistory } from './DocumentHistory';
import { useDocumentHistory } from './useDocumentHistory';

export interface DocumentHistoryContainerProps {
  documentId: string;
  isEcmDocument?: boolean;
  isSensitiveDocument?: boolean;
}

export const DocumentHistoryContainer = ({
  documentId,
}: DocumentHistoryContainerProps) => {
  const { documentData, loading } = useDocumentHistory(documentId);

  const supportedEvents = useMemo(() => {
    if (!documentData) return [];

    const filteredEvents: any[] = documentData.timeline ?? [];

    // Keep only events supported by DocumentHistory
    return filteredEvents.filter((e: { __typename: string }) =>
      supportedEventKeys.includes(e.__typename)
    );
  }, [documentData]);

  if (!loading && !document) {
    // TODO add error state
    return null;
  }

  if (loading) {
    return (
      <ScrollBox
        key="history-wrapper"
        scrollDirection="y"
        padding="space16"
        paddingTop="space8"
      >
        <HistoryLoading itemCount={4} />
      </ScrollBox>
    );
  }

  return (
    <ScrollBox key="history-wrapper" scrollDirection="y" paddingX="space8">
      <DocumentHistory events={supportedEvents} />
    </ScrollBox>
  );
};
