import { useToastMessage } from 'components/Toast/useToastMessage';
import { GQLError, GQLErrorTypes } from 'gql';
import { useCallback } from 'react';

interface BookingError {
  [key: string]: string;
}

interface BookingsError {
  bookings?: BookingError[];
}

export const parseError = (
  errors: BookingsError | GQLError
): string | undefined => {
  if ('bookings' in errors && Array.isArray(errors.bookings)) {
    return errors.bookings
      .map(booking => Object.values(booking).join('. '))
      .join('. ');
  }

  if ('graphQLErrors' in errors && Array.isArray(errors.graphQLErrors)) {
    const globalErrors = errors.graphQLErrors
      .filter(
        error => error.extensions?.code !== GQLErrorTypes.VALIDATION_ERROR
      )
      .map(error => error.message)
      .join('. ');

    if (errors.graphQLErrors.length && globalErrors) {
      return globalErrors;
    }
  }

  if ('message' in errors) {
    return errors.message;
  }
};

export const useShowError = () => {
  const { error } = useToastMessage();

  const showError = useCallback(
    (errors: GQLError) => {
      const content = parseError(errors);
      if (content) error(content);
    },
    [error]
  );

  return showError;
};
