import { Flex, FlexProps, useTheme } from '@candisio/design-system';
import { ReactNode, forwardRef } from 'react';

export interface DocumentTagProps extends FlexProps<'div'> {
  children: ReactNode;
}

/**
 * A component similar to `Tag` component from DS that has some additional
 * styles applied to it and provides support for `onClick` events
 */
export const DocumentTag = forwardRef<HTMLDivElement, DocumentTagProps>(
  ({ children, onClick, ...restProps }, ref) => {
    const { colors, fontSizes, fontWeights, radii, space } = useTheme();

    const baseStyle = {
      backgroundColor: colors.gray50,
      border: `1px solid ${colors.gray200}`,
      fontWeight: fontWeights.semibold,
      fontSize: fontSizes.xsmall,
      borderRadius: radii.full,
      padding: `${space.space2} ${space.space8}`,
      lineHeight: space.space20,
      cursor: Boolean(onClick) ? 'pointer' : 'default',

      // Prevent `text` cursor when `children` is string
      '& > *': {
        cursor: 'inherit',
      },
    };

    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        onClick={onClick}
        style={baseStyle}
        ref={ref}
        {...restProps}
      >
        {children}
      </Flex>
    );
  }
);
