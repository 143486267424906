import { Trans } from 'react-i18next';

// The Trans component does not work well when any of the values used for
// interpolation have < or > in it. The library will incorrectly try to
// create HTML tags and close them (but still display as text :/),
// or completely omit parts of the string.
//
// const fileName = "My <Invoice from Facebook.pdf"
// <Trans values={{ fileName }}>This is my file: {{fileName}}</Trans>
// Would produce the DOM element
// <div>
//   This is my file: My <Invoice> from Facebook.pdf</Invoice>
// </div>

// Only consistent way to create a nice string is to remove angled brackets
// from dynamic interpolation values.

// Note: It requires that you pass the `values` prop in, no just as children
export const SanitisedTrans: typeof Trans = ({
  values,
  children,
  ...props
}) => {
  const santisedValues = values
    ? Object.assign(
        {},
        ...Object.entries<string>(values || {}).map(([key, value]) => ({
          [key]: String(value).replace(/[<>]+/g, ''),
        }))
      )
    : undefined;

  return (
    <Trans {...props} values={santisedValues}>
      {children}
    </Trans>
  );
};
