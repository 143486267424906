import { Grid, Text, TruncatedText } from '@candisio/design-system';
import { InfoIcon } from 'components/Icons/InfoIcon';
import { EmailImportErrorIcon } from 'components/Icons/notifications/EmailImportErrorIcon';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { EmailImportErrorNotification } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useTranslation } from 'react-i18next';
import { NotificationItem } from './styles';

export interface EmailImportErrorNotificationItemProps {
  data: EmailImportErrorNotification;
  organizationSlug: string;
  onEmailLinkClick: () => void;
}

export const EmailImportErrorNotificationItem = ({
  data,
  organizationSlug,
  onEmailLinkClick,
}: EmailImportErrorNotificationItemProps) => {
  const [t] = useTranslation();
  const { fromAddress } = data;

  const handleEmailLinkClick = () => {
    onEmailLinkClick();
  };

  return (
    <NotificationItem>
      <Grid
        alignItems="center"
        gap="space16"
        templateColumns="auto 1fr auto"
        paddingX="space12"
        paddingY="space8"
      >
        <EmailImportErrorIcon />
        <Text fontWeight="bold">
          <RouterLink
            onClick={handleEmailLinkClick}
            to={`/${organizationSlug}${Routes.SETTINGS}${Routes.MAIL_SYNC}/${data.emailMessageId}`}
          >
            {t('uploads.errors.emailImportError', { fromAddress })}
          </RouterLink>
        </Text>
        <Grid alignItems="center" gap="space4" templateColumns="1fr auto">
          <TruncatedText color="red500">
            {t('uploads.status.error')}
          </TruncatedText>
          <InfoIcon message={t('uploads.errors.emailImportErrorInfo')} />
        </Grid>
      </Grid>
    </NotificationItem>
  );
};
