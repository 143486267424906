import { DocumentTableColumnKeys } from 'components/DocumentsTable/types';
import { SortDirection } from 'generated-types/graphql.types';
import { SortingRule } from 'react-table';

type AvailableSortField =
  | 'invoiceDueDate'
  | 'status'
  | 'amount'
  | 'invoiceDate'
  | 'isPayable'
  | 'paymentState'
  | 'dueDateWithCashDiscount'
  | 'hasTransactionLinked'
  | 'createdAt'
  | 'contact'
  | 'requestedAt'
  | 'invoiceNumber'
  | 'purchaseOrderNumber'
  | 'artistSocialInsurance'
  | 'costObject'
  | 'costCenter'
  | 'extraCost'
  | 'documentType'
  | 'generalLedgerAccount'
  | 'categoryType'
  | 'invoiceDeliveryDate'
  | 'paidAt';

const mapColumnIdsToSortInput: Partial<
  Record<DocumentTableColumnKeys, AvailableSortField>
> = {
  dueDate: 'invoiceDueDate',
  grossAmount: 'amount',
  discountDateWPercentage: 'dueDateWithCashDiscount',
  artistSocialInsuranceCode: 'artistSocialInsurance',
  extraCostInfo: 'extraCost',
  documentType: 'categoryType',
  createdAt: 'createdAt',
  requestedAt: 'requestedAt',
  contact: 'contact',
  deliveryDate: 'invoiceDeliveryDate',
  paidAt: 'paidAt',
  paymentStatus: 'paymentState',
};

export const mapToDocumentSortInput = (
  sort: Array<SortingRule<DocumentTableColumnKeys>>
) => {
  if (sort.length < 1) {
    return undefined;
  }

  const [firstSort] = sort;

  const firstSortId = firstSort.id;

  return {
    field:
      mapColumnIdsToSortInput[firstSortId as DocumentTableColumnKeys] ??
      firstSortId,
    order: firstSort.desc ? SortDirection.Desc : SortDirection.Asc,
  };
};
