import { useArchiveOrDeleteReimbursementCaseMutation } from 'generated-types/graphql.types';

export const useArchiveReimbursement = () => {
  const [archiveReimbursement, { loading, client }] =
    useArchiveOrDeleteReimbursementCaseMutation({
      refetchQueries: ['reimbursementCasesCount'],
      onCompleted: () => {
        client.cache.evict({ fieldName: 'reimbursementCasesPageBased' });
        client.cache.evict({ fieldName: 'reimbursementCasesCursorBased' });
      },
    });

  const handleArchiveReimbursement = async (id: string) => {
    const { data, errors } = await archiveReimbursement({
      variables: { id },
    });

    const { archived } = data?.archiveOrDeleteReimbursementCase ?? {};

    if (errors?.length || !archived) {
      return 'error';
    }

    return 'success';
  };

  return {
    archivePending: loading,
    archiveReimbursement: handleArchiveReimbursement,
  };
};
