import { Flex, Icon, Link, Text } from '@candisio/design-system';
import { StatusIcon } from 'components/DocumentsTable/Cells/StatusCell';
import { TagWithTooltip } from 'components/TagWithTooltip';
import { DocumentEInvoiceField } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface EInvoiceInfoIconProps {
  isEInvoice?: boolean;
  eInvoice?: DocumentEInvoiceField | null;
  iconLabel?: ReactNode;
  showWarningDescription?: boolean;
}

export const EInvoiceInfoIcon = ({
  isEInvoice,
  eInvoice,
  iconLabel,
  showWarningDescription,
}: EInvoiceInfoIconProps) => {
  const [t] = useTranslation();
  const [allowProcessingOfZugferdEinvoicesFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.allowProcessingOfZugferdEinvoices,
  ]);

  const [allowProcessingOfXRechnungEinvoicesFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.allowProcessingOfXrechnungEinvoices,
  ]);

  const eInvoiceWarnningMessage = (
    <Flex direction="column">
      <Text>
        {t('common:document.compact.history.invalidEInvoice.warning.message')}
      </Text>
      <Link
        href={t(
          'common:document.compact.history.invalidEInvoice.warning.helpCenterArticle.url'
        )}
        external
      >
        {t(
          'common:document.compact.history.invalidEInvoice.warning.helpCenterArticle.title'
        )}
      </Link>
    </Flex>
  );

  if (
    allowProcessingOfZugferdEinvoicesFF ||
    allowProcessingOfXRechnungEinvoicesFF
  ) {
    if (eInvoice?.error) {
      if (showWarningDescription) {
        return (
          <TagWithTooltip
            color="yellow"
            variant="secondary"
            label={
              <>
                <Icon icon="warning" color="yellow500" />
                <Text>
                  {t(
                    'common:document.compact.history.invalidEInvoice.warning.title'
                  )}
                </Text>
              </>
            }
            description={eInvoiceWarnningMessage}
          />
        );
      }

      return (
        <StatusIcon
          label={eInvoiceWarnningMessage}
          icon="warning"
          color="yellow500"
        />
      );
    }

    if (isEInvoice) {
      return (
        <StatusIcon
          label={iconLabel ?? t('documents-table:statuses.eInvoice')}
          icon="eInvoice"
          color="gray600"
          hasTooltip
        />
      );
    }
  }
};
