import { Box, Menu, Text, Tooltip, useTooltip } from '@candisio/design-system';
import { useUploadDocumentOptions } from 'components/UploadDocumentMenuButton';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useDocumentUploadDispatch } from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentUploadModal } from 'views/AppContainer/components/Header/components/DocumentUploadModal/DocumentUploadModal';
import { IconLink } from '../../IconLink/IconLink';

export const MenuUploadDocument = ({
  showMenuIcon,
  expanded,
  ...iconProps
}: {
  showMenuIcon?: boolean;
  expanded?: boolean;
}) => {
  const { showEcmSensitiveDocuments } = useEcm();
  const [isActive, setIsActive] = useState(false);
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const showUploadDocumentMenuFF = useCandisFeatureFlags(
    FEATURE_FLAGS.wipShowUploadDocumentMenuIcon
  );

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef, close } =
    useTooltip({
      placement: 'right top',
      passiveTrigger: true,
      closeDelay: 500,
      onOpenChange: isOpen => {
        setIsActive(isOpen);
      },
    });

  const uploadOptions = useUploadDocumentOptions(close);

  const dispatch = useDocumentUploadDispatch();

  const openUploadDocumentModal = () => {
    dispatch('showModal');
  };

  const showUploadIcon = showMenuIcon && showUploadDocumentMenuFF;

  if (!showUploadIcon) return null;

  if (!showEcmSensitiveDocuments) {
    return (
      <>
        <IconLink
          {...iconProps}
          icon="menuUpload"
          as="button"
          isActive={isActive}
          onClick={openUploadDocumentModal}
          showLabelInTooltip={false}
          expanded={expanded}
        />
        <DocumentUploadModal />
      </>
    );
  }

  return (
    <>
      <IconLink
        {...iconProps}
        icon="menuUpload"
        as="button"
        ref={triggerRef}
        isActive={isActive}
        showLabelInTooltip={false}
        expanded={expanded}
        {...triggerProps}
      />

      {isOpen && (
        <Tooltip
          {...tooltipProps}
          ref={tooltipRef}
          paddingX={0}
          paddingY={0}
          arrowStyle={{ display: 'none' }}
        >
          <>
            {!expanded && (
              <Box padding="space16 space16 0 space16">
                <Text color="gray450">{t('menuLabels.upload')}</Text>
              </Box>
            )}
            <Menu
              items={uploadOptions}
              style={expanded ? undefined : { paddingTop: '4px' }}
            />
          </>
        </Tooltip>
      )}
      <DocumentUploadModal />
    </>
  );
};
