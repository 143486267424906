import { Grid, Skeleton } from '@candisio/design-system';
import { motion } from 'framer-motion';

const MotionSkeleton = motion(Skeleton);

const skeletonVariants = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

export const FilterableListSkeleton = () => (
  <Grid
    background="gray0"
    gap="space8"
    width="100%"
    height="100%"
    padding="space8 space16 0"
    position="absolute"
    data-cy="filterable-list-skeleton"
  >
    {Array.from({ length: 20 }, (_, index) => (
      <MotionSkeleton
        variants={skeletonVariants}
        height="space40"
        width="100%"
        key={index}
      />
    ))}
  </Grid>
);
