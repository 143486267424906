import {
  Box,
  Flex,
  Link,
  Skeleton,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import {
  DocumentDetailsCardQuery,
  DocumentStatus,
} from 'generated-types/graphql.types';
import i18next from 'i18next';
import { Routes } from 'models';
import { ReactElement } from 'react';
import { DateFormatters } from 'utils/date_formatter';
import { amountFormat } from 'utils/format';
import { DocumentDetailsCardHeader } from './DocumentDetailsCardHeader';

type DocumentDetailsCardProps = {
  t: i18next.TFunction;
  organizationSlug: string;
  loading?: boolean;
  document?: NonNullable<DocumentDetailsCardQuery['getDocument']>;
  actions?: ReactElement[];
  wrapperStyle?: object;
  header?: {
    title?: string;
    secondaryTitle?: string;
    color?: string;
  };
};

const LoadingDocumentDetailsCard = ({
  wrapperStyle,
}: {
  wrapperStyle?: object;
}) => {
  return (
    <Box
      background="white"
      maxWidth="600px"
      boxShadow="elevatedShadow4"
      borderTopLeftRadius="basic"
      borderTopRightRadius="basic"
      style={wrapperStyle}
    >
      <Skeleton height="space32" width="100%" />
      <Flex
        direction="column"
        justifyContent="space-between"
        gap="space32"
        padding="space8"
      >
        <Flex justifyContent="space-between" gap="space12" width="100%">
          <Flex width="75%" direction="column" gap="space4">
            <Skeleton height="20px" width="80%" />
            <Flex direction="column" gap="space2">
              <Skeleton height="14px" width="40%" />
              <Skeleton height="14px" width="60%" />
            </Flex>
          </Flex>
          <Skeleton height="20px" width="100px" />
        </Flex>
        <Skeleton height="32px" width="100%" />
      </Flex>
    </Box>
  );
};

export const DocumentDetailsCard = ({
  organizationSlug,
  t,
  actions,
  loading,
  document,
  wrapperStyle,
  header,
}: DocumentDetailsCardProps) => {
  if (loading) {
    return <LoadingDocumentDetailsCard wrapperStyle={wrapperStyle} />;
  }

  if (!document) {
    // Error state
    return null;
  }

  const notAvailableText = t('documentDetailsCard.notAvailable');

  const formattedAmount = amountFormat(
    document?.amount?.value,
    document?.currency?.value
  );

  const contactName =
    document.contact?.value.name.value ||
    document.extractedContact?.name?.value;

  const invoiceDate =
    (document.invoiceDate &&
      DateFormatters.compact(new Date(document.invoiceDate.value))) ||
    notAvailableText;

  const invoiceNumber = document?.invoiceNumber?.value || notAvailableText;

  const linkText = t('documentDetailsCard.documentLink');

  const invoiceNumberText = t('documentDetailsCard.invoiceNumber');
  const invoiceDateText = t('documentDetailsCard.invoiceDate');

  return (
    <Box maxWidth="600px" boxShadow="elevatedShadow4" style={wrapperStyle}>
      <Header
        status={document?.status}
        color={header?.color || 'gray500'}
        title={header?.title || null}
        secondaryTitle={
          header?.secondaryTitle && (
            <Link
              style={{ textDecoration: 'underline' }}
              variant="secondary"
              fontWeight="regular"
              href={t(
                'documentDetailsCard.documentFileViewerHeader.duplicateInfoTextLink'
              )}
              external
            >
              {header.secondaryTitle}
            </Link>
          )
        }
      />
      <Flex direction="column" padding="space16" background="gray0">
        <Flex justifyContent="space-between" gap="space16">
          <Flex width="80%" direction="column" gap="space4" whiteSpace="normal">
            <TruncatedText fontWeight="semibold" color="gray800">
              {contactName ?? ''}
            </TruncatedText>
            <Flex direction="row" gap="space16">
              <Flex
                gap="space2"
                fontSize="small"
                whiteSpace="normal"
                color="gray600"
              >
                <Text whiteSpace="nowrap">{invoiceDateText}</Text>
                <TruncatedText color="gray800">{invoiceDate}</TruncatedText>
              </Flex>
              <Flex
                whiteSpace="normal"
                gap="space2"
                fontSize="small"
                color="gray600"
              >
                <Text whiteSpace="nowrap">{invoiceNumberText}</Text>
                <TruncatedText color="gray800">{invoiceNumber}</TruncatedText>
              </Flex>
            </Flex>
            <Flex gap="space4" alignItems="center">
              <Link
                external
                href={`/${organizationSlug}${Routes.ARCHIVE}/${document.id}`}
                height="space24"
              >
                {linkText}
              </Link>
            </Flex>
          </Flex>
          {formattedAmount ? (
            <Flex direction="column" fontWeight="semibold" fontSize="basic">
              <Text>{formattedAmount}</Text>
            </Flex>
          ) : null}
        </Flex>
        {actions?.length ? (
          <Flex flex={1} gap="space16" justifyContent="right">
            {actions}
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
};

const Header = ({
  status,
  title,
  color,
  secondaryTitle,
}: {
  title?: string | null;
  color: string;
  status: DocumentStatus;
  secondaryTitle?: any;
}) => {
  return (
    <DocumentDetailsCardHeader
      status={status}
      title={title ?? ''}
      backgroundColor={color}
      secondaryTitle={secondaryTitle}
    />
  );
};
