import {
  ContactValidationErrorType,
  IntegrationName,
} from 'generated-types/graphql.types';
import { isValidBIC, isValidIBAN } from 'ibantools';
import { isNil } from 'lodash';
import {
  validateIBAN,
  isValidVatId,
  isValidPostalCodeForGermany,
  ibanIncludesBan,
  GERMAN_COUNTRY_ISO_CODE,
  isValidTaxNumberForGermany,
} from 'utils/forms';
import { accountsPayableNumberSchema } from 'utils/zodFormValidation/Schemas/accountsPayableNumber';
import { bankAccountNumberSchema } from 'utils/zodFormValidation/Schemas/bankAccountNumberSchema';
import {
  isSwiftCountryCodeMatch,
  swiftCodeSchema,
} from 'utils/zodFormValidation/Schemas/swiftCodeSchema';
import { PAYMENT_CONDITION_NUMBER } from 'views/consts';
import { SUPPORTED_CONTACT_TYPES_NUMBERS } from 'views/Contacts/consts';
import {
  citySchema,
  contactEmailSchema,
  contactPhoneNumberSchema,
  countrySchema,
  customerNumberSchema,
  postalCodeSchema,
  postOfficeBoxSchema,
  streetSchema,
} from 'views/Contacts/ContactDetails/ContactForm/contactFormSchema';
import { SingleParsedContact } from 'views/Contacts/ContactImport/types';
import { taxNumberSchema } from '../ContactDetails/ContactForm/taxNumberSchema';
export enum ContactCSVFileType {
  CORE_DATA_DTVF = 'CORE_DATA_DTVF',
  CORE_DATA_EXTF = 'CORE_DATA_EXTF',
  CANDIS_ACCOUNTS_PAYABLE_CUSTOM_TEMPLATE = 'CANDIS_ACCOUNTS_PAYABLE_CUSTOM_TEMPLATE',
  UNKNOWN_FILE_TYPE = 'UNKNOWN_FILE_TYPE',
}

const taxNumberSchemaWithCountryCheck = (
  country: string | null | undefined
) => {
  if (country === GERMAN_COUNTRY_ISO_CODE || isNil(country)) {
    return taxNumberSchema
      .nullish()
      .refine(value => isValidTaxNumberForGermany(country, value));
  } else {
    return taxNumberSchema.nullish();
  }
};

export const getFileTypeFromHeaderRow = (csvHeaderRow: string[]) => {
  const firstHeader = csvHeaderRow[0].toUpperCase();

  if (firstHeader.startsWith('DTVF')) {
    return ContactCSVFileType.CORE_DATA_DTVF;
  } else if (firstHeader.startsWith('EXTF')) {
    return ContactCSVFileType.CORE_DATA_EXTF;
  } else if (firstHeader.startsWith('CANDIS')) {
    return ContactCSVFileType.CANDIS_ACCOUNTS_PAYABLE_CUSTOM_TEMPLATE;
  } else {
    return ContactCSVFileType.UNKNOWN_FILE_TYPE;
  }
};

// in DATEV CSV files, payment condition number is 0 if it's not specified
const DATEV_PAYMENT_CONDITION_NUMBER_EMPTY_VALUE = '0';

export const getPaymentConditionNumber = (
  paymentConditionNumber?: string
): number | undefined => {
  return !paymentConditionNumber ||
    paymentConditionNumber === DATEV_PAYMENT_CONDITION_NUMBER_EMPTY_VALUE
    ? undefined
    : Number(paymentConditionNumber);
};

export const getCreateTransferValue = (
  paymentMediumNumber?: string,
  isBDSEnabled?: boolean
): boolean | undefined => {
  if (!isBDSEnabled) {
    return paymentMediumNumber === '7' || paymentMediumNumber === '8'
      ? true
      : !paymentMediumNumber || paymentMediumNumber === '9'
        ? false
        : undefined;
  }

  return undefined;
};

export function getContactTypeNumberFromCSV(
  rawContactTypeNumber: string,
  SUPPORTED_CONTACT_TYPES_NUMBERS: number[],
  DEFAULT_CONTACT_TYPE_NUMBER: number
): number {
  if (
    rawContactTypeNumber &&
    rawContactTypeNumber !== '' &&
    SUPPORTED_CONTACT_TYPES_NUMBERS.includes(Number(rawContactTypeNumber))
  ) {
    return Number(rawContactTypeNumber);
  } else {
    return DEFAULT_CONTACT_TYPE_NUMBER;
  }
}

export interface ValidateContactDataProps {
  singleParsedContact: SingleParsedContact;
  integration: IntegrationName;
  glaLength?: number;
}

export const validateContactData = ({
  integration,
  singleParsedContact,
  glaLength,
}: ValidateContactDataProps) => {
  const contact = singleParsedContact.contactInputData;

  const isNotSpecified =
    contact.contactTypeNumber === SUPPORTED_CONTACT_TYPES_NUMBERS[0];

  const isIndividual =
    contact.contactTypeNumber === SUPPORTED_CONTACT_TYPES_NUMBERS[1];

  const isCompany =
    contact.contactTypeNumber === SUPPORTED_CONTACT_TYPES_NUMBERS[2];

  const noCompanyNames =
    isCompany && !contact.companyName && !contact.shortName;

  const noIndividualNames =
    isIndividual &&
    !contact.individualFirstName &&
    !contact.individualLastName &&
    !contact.shortName;

  const noNotSpecifiedName =
    isNotSpecified && !contact.notSpecifiedName && !contact.shortName;

  if (noCompanyNames) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.InvalidCompanyName
    );
  } else if (noIndividualNames) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.AllNameFieldsAreEmptyForIndividual
    );
  } else if (noNotSpecifiedName) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.InvalidNotSpecifiedName
    );
  }

  const validationSchema = accountsPayableNumberSchema(integration, glaLength);

  if (
    contact.accountsPayableNumber &&
    !validationSchema.safeParse(contact.accountsPayableNumber).success
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.InvalidAccountsPayableNumber
    );
  }

  if (
    contact.customerNumber &&
    !customerNumberSchema.safeParse(contact.customerNumber).success
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.InvalidCustomerNumber
    );
  }

  if (
    !isNil(contact.contactTypeNumber) &&
    !Boolean(
      SUPPORTED_CONTACT_TYPES_NUMBERS.includes(contact.contactTypeNumber)
    )
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.InvalidContactType
    );
  }

  if (
    contact.taxNumber &&
    !taxNumberSchemaWithCountryCheck(contact.countryISOCode).safeParse(
      contact.taxNumber
    ).success
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.InvalidTaxNumber
    );
  }

  if (contact.vatId && !isValidVatId(contact.vatId)) {
    singleParsedContact.errors.push(ContactValidationErrorType.InvalidVatId);
  }

  if (contact.iban && !validateIBAN(contact.iban)) {
    singleParsedContact.errors.push(ContactValidationErrorType.InvalidIban);
  }

  if (
    contact.phoneNumber &&
    !contactPhoneNumberSchema.safeParse(contact.phoneNumber).success
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.InvalidPhoneNumber
    );
  }

  if (contact.email && !contactEmailSchema.safeParse(contact.email).success) {
    singleParsedContact.errors.push(ContactValidationErrorType.InvalidEmail);
  }

  if (
    contact.swiftCode &&
    (!swiftCodeSchema.safeParse(contact.swiftCode).success ||
      (contact.iban &&
        isValidIBAN(contact.iban) &&
        isValidBIC(contact.swiftCode) &&
        !isSwiftCountryCodeMatch({
          iban: contact.iban,
          swiftCode: contact.swiftCode,
        })))
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.InvalidSwiftCode
    );
  }

  if (
    contact.bankAccountNumber &&
    !bankAccountNumberSchema.safeParse(contact.bankAccountNumber).success
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.InvalidBankAccountNumber
    );
  }

  if (!contact.iban && contact.bankAccountNumber && !contact.swiftCode) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.BankAccountNumberMissingSwiftCode
    );
  }

  if (
    contact.swiftCode &&
    isNil(contact.bankAccountNumber) &&
    isNil(contact.iban)
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.SwiftCodeMissingIbanOrBankAccountNumber
    );
  }

  if (
    contact.iban &&
    contact.bankAccountNumber &&
    !ibanIncludesBan(contact.iban, contact.bankAccountNumber)
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.BankAccountNumberAndIbanMismatch
    );
  }

  if (contact.street && !streetSchema.safeParse(contact.street).success) {
    singleParsedContact.errors.push(ContactValidationErrorType.InvalidStreet);
  }

  if (
    contact.postOfficeBox &&
    !postOfficeBoxSchema.safeParse(contact.postOfficeBox).success
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.InvalidPostOfficeBox
    );
  }

  if (
    (contact.postalCode &&
      !postalCodeSchema.safeParse(contact.postalCode).success) ||
    !isValidPostalCodeForGermany(contact.countryISOCode, contact.postalCode)
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.InvalidPostalCode
    );
  }

  if (contact.city && !citySchema.safeParse(contact.city).success) {
    singleParsedContact.errors.push(ContactValidationErrorType.InvalidCity);
  }

  if (
    contact.countryISOCode &&
    !countrySchema.safeParse(contact.countryISOCode).success
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.InvalidCountryCode
    );
  }

  return singleParsedContact;
};

export const validatePaymentConditionNumber = (
  singleParsedContact: SingleParsedContact,
  existingPaymentConditionNumbers?: {
    isArchived: boolean;
    paymentConditionNumber: number;
  }[]
): SingleParsedContact => {
  const extractedFromCSV = singleParsedContact.contactInputData;
  const isEmptyEntry = isNil(extractedFromCSV.paymentConditionNumber);
  const noPaymentConditionsFoundInOrg =
    existingPaymentConditionNumbers === undefined ||
    existingPaymentConditionNumbers?.length === 0;

  if (noPaymentConditionsFoundInOrg) {
    return singleParsedContact;
  }

  if (isEmptyEntry) {
    return singleParsedContact;
  }

  if (
    existingPaymentConditionNumbers?.find(
      pc =>
        pc.paymentConditionNumber === extractedFromCSV.paymentConditionNumber
    )?.isArchived
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.PaymentConditionArchived
    );
  }

  if (
    (!isNil(extractedFromCSV.paymentConditionNumber) &&
      (extractedFromCSV.paymentConditionNumber < PAYMENT_CONDITION_NUMBER.MIN ||
        extractedFromCSV.paymentConditionNumber >
          PAYMENT_CONDITION_NUMBER.MAX)) ||
    Number.isNaN(extractedFromCSV.paymentConditionNumber)
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.InvalidPaymentCondition
    );
  } else if (
    existingPaymentConditionNumbers?.find(
      pc =>
        pc.paymentConditionNumber === extractedFromCSV.paymentConditionNumber
    ) === undefined
  ) {
    singleParsedContact.errors.push(
      ContactValidationErrorType.PaymentConditionNotFound
    );
  }

  return singleParsedContact;
};
