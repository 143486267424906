import { Grid, Item, TruncatedText } from '@candisio/design-system';
import {
  HookFormComboBoxField,
  HookFormComboBoxFieldProps,
} from 'components/HookFormFields/HookFormComboBoxField';
import { IntegrationName } from 'generated-types/graphql.types';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AccountingFieldItem,
  ComboBoxFieldOptions,
} from 'views/Reimbursement/hooks/types';
import { taxCodeExportValidationSchema } from 'views/utils/taxCodeExportValidationSchema';

interface TaxCodeFieldProps<TFormValues extends FieldValues>
  extends HookFormComboBoxFieldProps<TFormValues> {
  name: Path<TFormValues>;
}

/**
 * IMPORTANT: Reimbursement documents are always treated as `invoices_received`, never as `outgoing_invoices`.
 * Therefore, taxCodes for `outgoing_invoices` are disabled.
 */
export const TaxCodeField = <TFormValues extends FieldValues>({
  name,
  defaultItems,
  ...props
}: TaxCodeFieldProps<TFormValues> &
  ComboBoxFieldOptions<AccountingFieldItem>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const integration = useIntegrationSettings();

  const { enrichedTaxCodeItems, disabledKeys } = useMemo(() => {
    const disabledKeys: string[] = [];

    const enrichedTaxCodeItems = (defaultItems || []).map(item => {
      const isTaxCodeDisabled =
        item.code &&
        !taxCodeExportValidationSchema[item.code].invoices_received;

      if (integration === IntegrationName.Datev && isTaxCodeDisabled) {
        disabledKeys.push(item.key);

        return {
          ...item,
          description: t('splitBookings.taxCode.description.outgoingInvoices'),
        };
      }

      return item;
    });

    return { enrichedTaxCodeItems, disabledKeys };
  }, [defaultItems, integration, t]);

  return (
    <HookFormComboBoxField
      name={name}
      isVirtualized={false}
      placeholder={t('splitBookings.taxCode.placeholder.enabled')}
      disabledKeys={disabledKeys}
      defaultItems={enrichedTaxCodeItems}
      {...props}
    >
      {item => (
        <Item key={item.key} textValue={item.children as string}>
          <Grid>
            <TruncatedText>{item.children}</TruncatedText>
            {item.description && (
              <TruncatedText fontSize="small" fontStyle="italic">
                {item.description}
              </TruncatedText>
            )}
          </Grid>
        </Item>
      )}
    </HookFormComboBoxField>
  );
};
