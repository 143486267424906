import {
  Card,
  Flex,
  Grid,
  Heading,
  TruncatedText,
} from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { Team } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';

export interface UserTeamsProps {
  teams: Team[];
}

export const UserTeams = ({ teams }: UserTeamsProps) => {
  const [t] = useTranslation();

  if (!teams.length) {
    return null;
  }

  const sortedTeams = teams.sort((a, b) => a.name.localeCompare(b.name));

  const isNotLastTeam = (index: number) => index !== sortedTeams.length - 1;

  return (
    <Grid gap="space8">
      <Heading as="h3">{t('header.profile.teamHeader')}</Heading>
      <Card background="gray100">
        <Flex gap="space8" wrap="wrap">
          {sortedTeams.map((team, i) => (
            <Flex
              key={team.id}
              gap="space8"
              fontSize="basic"
              alignItems="center"
            >
              <TruncatedText maxWidth="20ch">{team.name}</TruncatedText>
              {isNotLastTeam(i) && <div>•</div>}
            </Flex>
          ))}
        </Flex>
      </Card>
    </Grid>
  );
};
