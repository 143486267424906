import { Card, Grid } from '@candisio/design-system';
import { EInvoicesFeedbackInfoBox } from 'components/Form/ProcessingForm/EInvoicesFeedbackInfoBox';
import { ProcessingFormAmountFieldsWithFF } from 'components/Form/ProcessingForm/ProcessingFormAmountFieldsWithFF';
import { ProcessingFormPaginatedComboBoxField } from 'components/Form/ProcessingForm/ProcessingFormPaginatedComboBoxField';
import { ProcessingFormPaymentConditionFieldItem } from 'components/Form/ProcessingForm/ProcessingFormPaymentConditionFieldDefault';
import { ProcessingFormPurchaseOrderTypeField } from 'components/Form/ProcessingForm/ProcessingFormPurchaseOrder/ProcessingFormPurchaseOrderTypeField';
import { HookFormUserFieldItem } from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { HookFormWorkflowFieldItem } from 'components/HookFormFields/HookFormWorkFlowField';
import { SectionSeparator } from 'components/SectionSeparator/SectionSeparator';
import { motion } from 'framer-motion';
import {
  DocumentCategory,
  DocumentCurrency,
  DocumentStatus,
  GetDocumentForDraftQuery,
  IntegrationName,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { merge } from 'lodash';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useStickyCardRefContext } from 'providers/StickyCardRefProvider';
import React, {
  FormEvent,
  Key,
  ReactNode,
  isValidElement,
  KeyboardEvent,
  useEffect,
} from 'react';
import {
  DefaultValues,
  FieldPath,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { useBookingsFormContext } from 'views/DocumentDetails/BookingsFormContext';
import { ProcessingFormOverlay } from 'views/Inbox/DocumentProcessing/components/AddContact/ProcessingFormOverlay';
import { useLinkedPurchaseOrder } from 'views/Inbox/DocumentProcessing/useLinkedPurchaseOrder';
import {
  useShouldRequireAccountsPayableNumber,
  useShouldRequireGeneralLedgerAccounts,
  useShouldRequireTaxCodes,
} from 'views/utils/useShouldRequireField';
import { useShowPurchaseOrderNumberField } from 'views/utils/useShouldShowField';
import { UseDocumentTypeFieldOptionsResult } from '../hooks/useDocumentTypeFieldOptions';
import { ProcessingFormAccountingDataFieldItem } from './ProcessingFormAccountingFields';
import { ProcessingFormAmountFields } from './ProcessingFormAmountFields';
import { ProcessingFormApprovalFields } from './ProcessingFormApprovalFields';
import { ProcessingFormBookingFields } from './ProcessingFormBookingFields';
import { ProcessingFormContactDetails } from './ProcessingFormContactDetails';
import {
  ProcessingFormContactField,
  ProcessingFormContactFieldItem,
} from './ProcessingFormContactField';
import { ProcessingFormDateFields } from './ProcessingFormDateFields';
import { ProcessingFormGlobalTypeField } from './ProcessingFormGlobalTypeField';
import { ProcessingFormMetadata } from './ProcessingFormMetadataContext';
import { ProcessingFormMetadataProvider } from './ProcessingFormMetadataProvider';
import { ProcessingFormPaymentFields } from './ProcessingFormPaymentFields';
import { ProcessingFormProvider } from './ProcessingFormProvider';
import { ProcessingFormSplitList } from './ProcessingFormSplitList';
import { ProcessingFormTextField } from './ProcessingFormTextField';
import { ProcessingFormTypeField } from './ProcessingFormTypeField';
import { getProcessingFormErrorMessages } from './processingFormErrorMessages';
import {
  ProcessingFormSchemaOptions,
  ProcessingFormValues,
  processingFormSchema,
} from './processingFormSchema';
import { useSyncProcessingFormDefaultValues } from './useSyncProcessingFormDefaultValues';
import { useWatchProcessingForm } from './useWatchProcessingForm';

const MotionGrid = motion(Grid);

interface CommonFieldOptions {
  readOnly?: boolean;
}

interface SelectFieldOptions<
  TItem extends { key: Key } = { key: Key; children: ReactNode },
> extends CommonFieldOptions {
  items?: TItem[];
  isLoading?: boolean;
  autoFocus?: boolean;
}

export interface ComboBoxFieldOptions<
  TItem extends { key: Key } = { key: Key; children: ReactNode },
> extends CommonFieldOptions {
  defaultItems?: TItem[];
  isLoading?: boolean;
}

export interface PaginatedComboBoxFieldOptions<
  TItem extends { key: Key } = { key: Key; children: ReactNode },
> extends CommonFieldOptions {
  getItem?: (value: string) => Promise<TItem | undefined>;
  items?: TItem[];
  onEndReached?: () => void;
  onSearch?: (inputValue: string) => Promise<void>;
  persistFilter?: boolean;
  isLoading?: boolean;
  autoFocus?: boolean;
  delayForShowOnFocusInMS?: number;
  disabledKeys?: string[];
}

export type ProcessingFormSubmitErrors = Partial<
  Record<FieldPath<ProcessingFormValues> | 'root', string>
>;

export interface ProcessingFormFieldOptions {
  /** Options for approvers field */
  approvers?: ComboBoxFieldOptions<HookFormUserFieldItem>;
  /** Options for artist social insurance code field */
  artistSocialInsuranceCode?: {
    hidden?: boolean;
    props?: ComboBoxFieldOptions;
  };
  /** Options for contact field */
  contact?: PaginatedComboBoxFieldOptions<ProcessingFormContactFieldItem>;
  /** Options for accounting area field */
  accountingArea?: {
    hidden?: boolean;
    props?: PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
  /** Options for cost center field */
  costCenter?: {
    hidden?: boolean;
    props?: PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
  /** Options for cost object field */
  costObject?: {
    hidden?: boolean;
    props?: PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
  /** Options for extra cost info field */
  extraCostInfo?: {
    hidden?: boolean;
    props?: PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
  /** Options for currency field */
  currency?: ComboBoxFieldOptions;
  /** Option that ensures cash discount fields and payment condition
   *  are removed when contact is changed and there's transaction
   *  linked to the invoice
   */
  hasLinkedTransaction?: boolean;
  /** Options for discount amount field */
  discountAmount?: CommonFieldOptions;
  /** Options for discount amount field */
  discountDate?: CommonFieldOptions;
  /** Options for discount amount field */
  discountPercentage?: CommonFieldOptions;
  /** Options for general ledger account field */
  generalLedgerAccount?: {
    hidden?: boolean;
    props?: PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
  /** Options for payment condition field */
  paymentCondition?: SelectFieldOptions<ProcessingFormPaymentConditionFieldItem>;
  /** Options for posting text field */
  postingText?: {
    hidden?: boolean;
    props?: CommonFieldOptions;
  };
  /** Options for purchase order number field */
  purchaseOrderNumber?: {
    hidden?: boolean;
    props?: CommonFieldOptions;
  };
  /** Options for tax code field */
  taxCode?: {
    hidden?: boolean;
    props?: ComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
  /** Options for document type field */
  type?: UseDocumentTypeFieldOptionsResult;
  /** Options for workflow field */
  workflow?: {
    hidden?: boolean;
    props?: ComboBoxFieldOptions<HookFormWorkflowFieldItem>;
  };
  /** Option to hide createTransfer field */
  createTransfer?: { hidden: boolean };
  /** Option to hide deliveryDate field */
  deliveryDate?: { hidden: boolean };
  /** Option to hide postingDate field */
  postingDate?: { hidden: boolean };
  /** invoiceDate props */
  invoiceDate?: { autoFocus?: boolean };
  isLoading?: boolean;
  /** Options for project code field */
  projectCode?: {
    hidden?: boolean;
    props?: PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
}

export interface ProcessingFormProps {
  /** documentId for currently open document */
  documentId?: string;
  /** E-Invoice info object */
  isEInvoice?: NonNullable<
    GetDocumentForDraftQuery['getDocument']
  >['isEInvoice'];
  /** Actions card content */
  actions?: ReactNode;
  /** Initial field metadata */
  defaultMetadata?: ProcessingFormMetadata;
  /** Initial field values */
  defaultValues?: DefaultValues<ProcessingFormValues>;
  /** Individual field options */
  fieldOptions?: ProcessingFormFieldOptions;
  /**
   * Integration that the organization is using
   * Changes how some parts of the form will behave
   * */
  integration: `${IntegrationName}`;
  /** List of available document categories */
  availableDocumentCategories?: DocumentCategory[];
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean | undefined;
  /** Called when add contact form should be shown. */
  onAddContact?: () => void;
  /** Called when contact field value changes */
  onContactChange?: (value: string | null) => void;
  /** Called when workflow editor should be opened */
  onCreateWorkflow?: () => void;
  /** Called when edit contact form should be shown */
  onEditContact?: () => void;
  /** Called when split bookings form should be shown */
  onSplitDocument?: (index?: number) => void;
  /** Called when sub form overlay should close */
  onSubFormClose?: () => void;
  /** Called when form is submitted */
  onSubmit: (
    values: ProcessingFormValues
  ) => Promise<ProcessingFormSubmitErrors | void>;
  /** Display form as read only */
  readOnly?: boolean;
  /** Sub form to show in an overlay on top of the form  */
  subForm?: ReactNode;
  /** Do not wrap the subform  */
  isCustomSubform?: boolean;
  /** Contact item */
  contactItem?: ProcessingFormContactFieldItem;
  /** Called when contact item was fetched */
  onGetContactItem?: (contactItem?: ProcessingFormContactFieldItem) => void;
  /** Tags field to handle assignment of tags to document */
  tagsField?: ReactNode;
  /** Global document id. TODO: Make it required once we stop relying on documentId and ecmDocumentId. */
  globalDocumentId?: string;
  /** Status of the document */
  status?: DocumentStatus;
}

export const fallbackDefaultValues = {
  mode: 'requestApproval',
  approvalMode: 'approvers',
  deliveryDate: null,
  bookings: [
    {
      vatRate: null,
      dueDate: null,
      postingText: null,
      note: null,
      generalLedgerAccount: { value: null, inputValue: '' },
      taxCode: null,
      costCenter: { value: null, inputValue: '' },
      costObject: { value: null, inputValue: '' },
      artistSocialInsuranceCode: null,
      extraCostInfo: { value: null, inputValue: '' },
    },
  ],
  iban: null,
  swiftCode: null,
  createTransfer: false,
  paymentCondition: null,
  accountingArea: {
    value: null,
    inputValue: '',
  },
  discountDate: null,
  discountPercentage: null,
  discountAmount: null,
  approvers: [],
} satisfies DefaultValues<ProcessingFormValues>;

export const Form = ({
  documentId,
  actions,
  defaultMetadata,
  defaultValues: defaultValuesProp,
  fieldOptions,
  integration,
  availableDocumentCategories,
  isLoading = false,
  onAddContact,
  onContactChange,
  onCreateWorkflow,
  onEditContact,
  onSplitDocument,
  onSubFormClose,
  onSubmit,
  readOnly,
  subForm,
  isCustomSubform,
  contactItem,
  onGetContactItem,
  tagsField,
  globalDocumentId,
  isEInvoice,
  status,
}: ProcessingFormProps) => {
  const [t] = useTranslation();
  const cardRef = useStickyCardRefContext();

  const [
    llmClassificationFF,
    allowProcessingOfZugferdEinvoicesFF,
    allowProcessingOfXrechnungEinvoicesFF,
  ] = useCandisFeatureFlags([
    FEATURE_FLAGS.llmClassification,
    FEATURE_FLAGS.allowProcessingOfZugferdEinvoices,
    FEATURE_FLAGS.allowProcessingOfXrechnungEinvoices,
  ]);

  const { shouldUseCoreDataApi } = useOtherIntegration();

  const { shouldUseSapPurchaseOrder, shouldUseSapNetAmount } = useSap();

  const { isQuantityRequired: isGoodsPurchaseOrderLinked } =
    useLinkedPurchaseOrder(documentId);

  const shouldRequireGeneralLedgerAccount =
    useShouldRequireGeneralLedgerAccounts();

  const shouldRequireTaxCode = useShouldRequireTaxCodes();

  const shouldRequireAccountsPayableNumber =
    useShouldRequireAccountsPayableNumber();

  const showPurchaseOrderNumberField = useShowPurchaseOrderNumberField();
  const showAccountingAreaField = !fieldOptions?.accountingArea?.hidden;

  const { setData: setBookingsFormData } = useBookingsFormContext();

  // make sure we have fallbacks for all default values
  const defaultValues = merge({}, fallbackDefaultValues, defaultValuesProp);

  const form = useForm<ProcessingFormValues, ProcessingFormSchemaOptions>({
    context: {
      integration,
      availableDocumentCategories,
      shouldRequireGeneralLedgerAccount,
      shouldRequireTaxCode,
      shouldRequireAccountsPayableNumber,
      shouldUseAccountingArea: showAccountingAreaField,
      taxCodeItems: fieldOptions?.taxCode?.props
        ? fieldOptions?.taxCode.props.defaultItems
        : undefined,
      typeItems: fieldOptions?.type?.items,
      hasLinkedTransaction: fieldOptions?.hasLinkedTransaction,
      hasCostCenterField: !fieldOptions?.costCenter?.hidden,
      hasCostObjectField: !fieldOptions?.costObject?.hidden,
      shouldUseSapNetAmount,
      isFormReadOnly: readOnly,
    },
    defaultValues,
    mode: 'onTouched',
    resolver: !readOnly
      ? zodResolver({
          zodSchema: processingFormSchema,
          errorMessages: getProcessingFormErrorMessages(),
        })
      : undefined,
    shouldFocusError: true,
  });

  const bookingsFieldArray = useFieldArray<ProcessingFormValues, 'bookings'>({
    control: form.control,
    name: 'bookings',
  });

  const splits = bookingsFieldArray.fields;
  const showSplitList =
    (isGoodsPurchaseOrderLinked && splits.length > 0) || splits.length > 1;

  // Keep form in sync with the latest default values and metadata
  useSyncProcessingFormDefaultValues({
    defaultMetadata,
    defaultValues,
    form,
    readOnly,
  });
  // Watch for changes and update fields and metadata accordingly
  useWatchProcessingForm({
    fieldOptions,
    form,
    showSplitList,
  });

  const handleSubmit = form.handleSubmit(async values => {
    const submitErrors = await onSubmit(values);

    if (submitErrors) {
      Object.entries(submitErrors).forEach(([path, message], index) => {
        form.setError(
          path as FieldPath<ProcessingFormValues>,
          { message },
          { shouldFocus: index === 0 } // focus first field with error
        );
      });
    }
  });

  const hasSubForm = isValidElement(subForm);
  const watchedBookings = useWatch({ control: form.control, name: 'bookings' });
  const currency = form.getValues('currency');
  useEffect(() => {
    if (shouldUseSapPurchaseOrder) {
      setBookingsFormData(
        watchedBookings.map(split => ({
          bookingId: split.bookingId ?? split.sapExpenseType ?? '',
          price: {
            amount: split.unitPrice ?? split.netAmount ?? split.amount,
            currency: currency as DocumentCurrency,
            precision: 0,
          },
          quantity: split.quantity || 0,
          description: split.note || '',
        }))
      );
    }
  }, [
    shouldUseSapPurchaseOrder,
    watchedBookings,
    currency,
    setBookingsFormData,
  ]);

  const showProcessingFormBookingField =
    !isGoodsPurchaseOrderLinked && splits.length === 1;

  return (
    <ProcessingFormProvider form={form} bookingsFieldArray={bookingsFieldArray}>
      <MotionGrid
        as="form"
        data-testid="new-processing-form"
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          form.setValue('mode', 'requestApproval');
          void handleSubmit(e);
        }}
        onKeyDownCapture={(e: KeyboardEvent<HTMLFormElement>) => {
          if (
            e.key === 'Enter' &&
            e.target instanceof HTMLElement &&
            e.target.nodeName !== 'TEXTAREA' &&
            e.target.nodeName !== 'BUTTON'
          ) {
            e.preventDefault();
          }
        }}
        gap="space32"
        alignContent="space-between"
        height="100%"
        animate={
          hasSubForm && isCustomSubform
            ? 'custom'
            : hasSubForm
              ? 'hidden'
              : 'visible'
        }
        variants={{
          custom: {
            display: 'grid',
            opacity: 0.5,
            pointerEvents: 'none',
          },
          visible: { display: 'grid', opacity: 1 },
          hidden: { opacity: 0, transitionEnd: { display: 'none' } },
        }}
      >
        <Grid gap="space20" padding="space8">
          <Grid gap="space16">
            {llmClassificationFF &&
            status === DocumentStatus.New &&
            !Boolean(isEInvoice) ? (
              <ProcessingFormGlobalTypeField
                globalDocumentId={globalDocumentId ?? ''}
                name="type"
                label={t('document.requestApproval.inputs.document.label')}
                placeholder={t(
                  'document.requestApproval.inputs.document.placeholder'
                )}
                {...fieldOptions?.type}
                readOnly={readOnly || isGoodsPurchaseOrderLinked}
                isLoading={isLoading}
              />
            ) : (
              <ProcessingFormTypeField
                name="type"
                label={t('document.requestApproval.inputs.document.label')}
                placeholder={t(
                  'document.requestApproval.inputs.document.placeholder'
                )}
                {...fieldOptions?.type}
                readOnly={readOnly || isGoodsPurchaseOrderLinked}
                isLoading={isLoading}
              />
            )}
            {shouldUseSapPurchaseOrder && (
              <ProcessingFormPurchaseOrderTypeField
                documentId={documentId}
                hasBorder
                hasTooltip
              />
            )}
            <ProcessingFormContactField
              name="contact"
              label={t('document.requestApproval.inputs.contact.label')}
              onAddContact={onAddContact}
              onEditContact={onEditContact}
              onChange={onContactChange}
              placeholder={t(
                'document.requestApproval.inputs.contact.placeholder'
              )}
              {...fieldOptions?.contact}
              readOnly={readOnly || fieldOptions?.contact?.readOnly}
              isLoading={isLoading}
            />
            <ProcessingFormContactDetails
              getContactFieldItem={fieldOptions?.contact?.getItem}
              typeFieldItems={fieldOptions?.type?.items}
              contactItem={contactItem}
              onGetContactItem={onGetContactItem}
              isLoading={isLoading}
            />

            {tagsField}

            <ProcessingFormDateFields
              fieldOptions={fieldOptions}
              readOnly={readOnly}
              isLoading={isLoading}
            />
            <ProcessingFormTextField
              name="invoiceNumber"
              label={t('document.requestApproval.inputs.invoiceNumber.label')}
              placeholder={t(
                'document.requestApproval.inputs.invoiceNumber.placeholder'
              )}
              readOnly={readOnly}
              isLoading={isLoading}
            />
            {showPurchaseOrderNumberField && (
              <ProcessingFormTextField
                name="purchaseOrderNumber"
                label={t(
                  'document.requestApproval.inputs.purchaseOrderNumber.label'
                )}
                placeholder={t(
                  'document.requestApproval.inputs.purchaseOrderNumber.placeholder'
                )}
                readOnly={readOnly}
                isLoading={isLoading}
              />
            )}

            {shouldUseSapNetAmount ? (
              <ProcessingFormAmountFieldsWithFF
                fieldOptions={fieldOptions}
                readOnly={readOnly}
                isLoading={isLoading}
                watchedBookings={watchedBookings}
                showSplitList={showSplitList}
              />
            ) : (
              <ProcessingFormAmountFields
                fieldOptions={fieldOptions}
                readOnly={readOnly}
                isLoading={isLoading}
              />
            )}

            <ProcessingFormSplitList
              onSplitDocument={onSplitDocument}
              readOnly={readOnly}
              isLoading={isLoading}
              showSplitList={showSplitList}
              isGoodsPurchaseOrderLinked={isGoodsPurchaseOrderLinked}
            />
          </Grid>

          {(showProcessingFormBookingField || showAccountingAreaField) && (
            <SectionSeparator
              header={t('document.requestApproval.section.preAccounting')}
            />
          )}
          {showAccountingAreaField && (
            <ProcessingFormPaginatedComboBoxField
              name="accountingArea"
              label={t('document.requestApproval.inputs.accountingArea.label')}
              placeholder={t(
                'document.requestApproval.inputs.accountingArea.placeholder'
              )}
              readOnly={readOnly}
              {...fieldOptions?.accountingArea?.props}
            />
          )}
          {showProcessingFormBookingField && (
            <ProcessingFormBookingFields
              key={splits[0].id} // used by React Hook Form to trigger rerenders
              fieldOptions={fieldOptions}
              integration={integration}
              readOnly={readOnly}
              isLoading={isLoading}
            />
          )}

          <SectionSeparator
            header={t('document.requestApproval.section.payment')}
          />

          <ProcessingFormPaymentFields
            fieldOptions={fieldOptions}
            readOnly={readOnly || shouldUseCoreDataApi}
            isLoading={isLoading}
          />

          {!readOnly && (
            <SectionSeparator
              header={t('document.requestApproval.section.approval')}
            />
          )}
          {!readOnly && (
            <ProcessingFormApprovalFields
              approversFieldOptions={fieldOptions?.approvers}
              onCreateWorkflow={onCreateWorkflow}
              workflowFieldOptions={fieldOptions?.workflow}
              isLoading={isLoading}
            />
          )}
          {(allowProcessingOfZugferdEinvoicesFF ||
            allowProcessingOfXrechnungEinvoicesFF) && (
            <EInvoicesFeedbackInfoBox
              isEInvoice={Boolean(isEInvoice)}
              isLoading={isLoading}
            />
          )}
        </Grid>

        {actions && (
          <Card
            corners="top"
            boxShadow="elevatedShadow3"
            position="sticky"
            padding="space20"
            bottom="0"
            zIndex="1"
            ref={cardRef}
          >
            {actions}
          </Card>
        )}
      </MotionGrid>

      {isCustomSubform && hasSubForm ? (
        subForm
      ) : (
        <ProcessingFormOverlay isOpen={hasSubForm} onClose={onSubFormClose}>
          {subForm}
        </ProcessingFormOverlay>
      )}
    </ProcessingFormProvider>
  );
};

// We need this separate component so we can update processing form metadata
// context value at the top level of the form without rerendering the whole form
export const ProcessingForm = ({
  defaultMetadata,
  ...restProps
}: ProcessingFormProps) => {
  return (
    <ProcessingFormMetadataProvider defaultMetadata={defaultMetadata}>
      <Form defaultMetadata={defaultMetadata} {...restProps} />
    </ProcessingFormMetadataProvider>
  );
};
