import gql from 'graphql-tag';
import { tagFragment } from 'views/Settings/DocumentTags/toolkit/gql';
import {
  inboxInvoiceDocumentDataFragment,
  pageInfoDataFragment,
} from 'views/queries/fragments';

export const listInboxDocumentsForAttachmentsQuery = gql`
  query listInboxDocumentsForAttachments(
    $limit: Int
    $after: String
    $filters: DocumentFilterInput
    $sort: SortInput
  ) {
    listInboxDocuments(
      limit: $limit
      after: $after
      filters: $filters
      sort: $sort
    )
      @connection(
        key: "inboxDocumentsForAttachments"
        filter: ["filters", "sort"]
      ) {
      edges {
        cursor
        node {
          isDuplicate
          isOverdue
          isWaitingForClarification
          id
          status
          contact {
            value {
              id
              name {
                value
              }
            }
          }
          extractedContact {
            cachingKey
            name {
              value
            }
          }
          createdAt
          documentFile {
            id
            name
            size
          }
          isEInvoice
          eInvoice {
            format
          }
          hasAttachments
          tags {
            ...Tag
          }
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${tagFragment}
`;

export const getInboxInvoiceDocumentsForAttachmentsQuery = gql`
  query getInboxInvoiceDocumentsForAttachments(
    $limit: Int
    $after: String
    $filters: InboxInvoiceDocumentsFilterInput
    $sort: SortInput
    $withPurchaseOrderData: Boolean = false
    $query: String
  ) {
    inboxInvoiceDocuments(
      limit: $limit
      after: $after
      filters: $filters
      sort: $sort
      query: $query
    )
      @connection(
        key: "inboxDocumentsPaginationV2"
        filter: ["filters", "sort", "query"]
      ) {
      edges {
        cursor
        node {
          ...InboxInvoiceDocumentData
          hasAttachments
          documentFile {
            name
            size
          }
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${inboxInvoiceDocumentDataFragment}
`;
