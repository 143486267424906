import {
  CustomEmptyStateProps,
  Flex,
  Grid,
  Link,
  Paragraph,
} from '@candisio/design-system';
import {
  LottieAnimation,
  noDocumentsInTable,
  noresultsData,
} from 'components/Lottie/Lottie';
import { UPSELL_PLUS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface EcmDocumentsEmptyStateProps extends CustomEmptyStateProps {
  isTableEmpty: boolean;
  isTableFiltered: boolean;
}

export const EcmDocumentsEmptyState = ({
  isTableEmpty,
  isTableFiltered,
  resetFilters,
}: EcmDocumentsEmptyStateProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { showImprovedSearch } = useEcm();

  const hasNoFilterResults = isTableFiltered && isTableEmpty;

  return (
    <Grid gap="space16">
      <LottieAnimation
        options={{
          animationData: hasNoFilterResults
            ? noresultsData
            : noDocumentsInTable,
        }}
      />
      <Flex maxWidth="space256" alignItems="center" direction="column">
        {hasNoFilterResults ? (
          <>
            <Paragraph textAlign="center" whiteSpace="pre-wrap">
              {t('emptyState.noFilterResults')}
            </Paragraph>
            <Link as="button" onClick={() => resetFilters()}>
              {t('emptyState.resetFilters')}
            </Link>
          </>
        ) : (
          <Paragraph textAlign="center" whiteSpace="pre-wrap">
            {t('emptyState.noDocumentsFound')}
          </Paragraph>
        )}
        {showImprovedSearch && (
          <UpsellPromo
            feature={FEATURE.FULL_TEXT_SEARCH}
            utmSource={UPSELL_PLUS_UTM_SOURCE.ARCHIVE}
          />
        )}
      </Flex>
    </Grid>
  );
};
