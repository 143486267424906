import { IconKey, Link } from '@candisio/design-system';
import { ReactNode } from 'react';

interface HelpCenterArticleLinkProps {
  articleUrl: string;
  onClick?: () => void;
  children: ReactNode;
  icon?: IconKey;
}

export const HelpCenterArticleLink = ({
  articleUrl,
  onClick,
  children,
  icon = 'infoOutline',
}: HelpCenterArticleLinkProps) => {
  return (
    <Link
      external
      href={articleUrl}
      icon={icon}
      iconPosition="left"
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
