import { Card, Flex, Grid, ScrollBox, Skeleton } from '@candisio/design-system';

export const VendorListLoader = () => {
  return (
    <Grid>
      <Card background="gray200">
        <Flex alignItems="center" justifyContent="space-between">
          <Skeleton height="space20" width="245px" />
          <Grid autoFlow="column" placeItems="center" gap="space8">
            <Skeleton height="space20" width="138px" />
            <Skeleton height="space10" width="100px" />
            <Skeleton height="space18" width="160px" />
          </Grid>
        </Flex>
      </Card>
      <ScrollBox height="100%">
        {Array.from({ length: 4 }, (_, index) => (
          <Grid
            key={index}
            height="60px"
            background="gray100"
            autoFlow="column"
            alignItems="center"
            padding="space8 space16"
            borderBottom="1px solid gray200"
            justifyContent="space-between"
          >
            <Grid autoFlow="column" alignItems="center" gap="space10">
              <Skeleton height="space36" width="space36" borderRadius="full" />
              <Skeleton height="22px" width="150px" />
            </Grid>
            <Grid autoFlow="column" alignItems="center" gap="space64">
              <Skeleton height="space32" width="space80" />
              <Skeleton height="22px" width="100px" />
            </Grid>
          </Grid>
        ))}
      </ScrollBox>
    </Grid>
  );
};
