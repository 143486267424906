import { Grid, Paragraph } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteTypeProps } from './utils';

export interface Props {
  children: ReactNode;
  routeType: RouteTypeProps;
}

export const CreditCardRequestStateLayout = ({
  children,
  routeType,
}: Props) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid
      gap="space8"
      maxWidth="50%"
      paddingLeft={routeType === 'Inbox' ? 'space32' : 0}
    >
      {children}
      <Paragraph color="gray600">
        {t('dashboard.drawer.form.footerText')}
      </Paragraph>
    </Grid>
  );
};
