import {
  FieldContainer,
  Flex,
  Grid,
  Item,
  Paragraph,
  Select,
  SelectField,
} from '@candisio/design-system';
import { useSelectOptionMonths } from 'components/utils/select-option-months';
import {
  ChartOfAccountCode,
  New_DatevSettingsQuery,
  Organization,
  useUpdateDatevChartOfAccountMutation,
  useUpdateExportConfigurationNewMutation,
} from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';
import { Dispatch, Key, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { getBookingAccountValidation } from '../../../gql';

export interface ExportConfiguration {
  chartOfAccountCode: string | null;
  accountLength: number | null;
  fiscalYearStartDay: number | null;
  fiscalYearStartMonth: number | null;
}

const supportedChartOfAccounts = [
  ChartOfAccountCode.Skr_03,
  ChartOfAccountCode.Skr_04,
];

function toSelectOption(obj: { [key: string]: string }) {
  return Object.entries(obj).map(entry => ({
    id: !isNaN(Number(entry[0])) ? Number(entry[0]) : entry[0],
    name: entry[1],
  }));
}

const getDays = (selectedMonth: number | null) => {
  let days = 31;
  if (selectedMonth === 2) {
    days = 28;
  } else if ([4, 6, 9, 11].includes(selectedMonth ?? 0)) {
    days = 30;
  }

  return [...Array(days).keys()];
};

const shouldDisplayMonth = (selectedDay: number | null) => {
  return ({ id }: { id: string; name: string }) => {
    if (selectedDay === null) {
      return true; // display all months if no day is selected
    } else if (selectedDay === 31) {
      // Do not show April, June, September, November if selected day is 31
      return ![2, 4, 6, 9, 11].includes(Number(id));
    } else if (selectedDay > 28) {
      // Do not show February if selected day is greater than 28
      return Number(id) !== 2;
    } else {
      // Display all months for selected days less than or equal to 28
      return true;
    }
  };
};

export type ExportConfigurationFieldsProps = {
  settingsData?: New_DatevSettingsQuery;
  organization?: Pick<Organization, 'exportConfiguration'> | null;
  exportConfiguration: ExportConfiguration;
  setExportConfiguration: Dispatch<SetStateAction<ExportConfiguration>>;
};

export const ExportConfigurationFields = ({
  settingsData,
  organization,
  exportConfiguration,
  setExportConfiguration,
}: ExportConfigurationFieldsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const optionMonths = useSelectOptionMonths();

  const [updateChartOfAccountMutation] = useUpdateDatevChartOfAccountMutation();
  const [updateExportConfigurationMutation] =
    useUpdateExportConfigurationNewMutation({
      refetchQueries: [{ query: getFullOrganizationQuery }],
      awaitRefetchQueries: true,
    });

  const disabledAccountLengthFieldUpdate =
    settingsData?.new_datevSettings?.datevCoreDataSource
      ?.isAccountLengthSourceDatev;

  const disabledChartOfAccountFieldUpdate =
    settingsData?.new_datevSettings?.datevCoreDataSource
      ?.isChartOfAccountCodeSourceDatev;

  const disableFiscalStartDateFieldUpdate =
    settingsData?.new_datevSettings?.datevCoreDataSource
      ?.isFiscalStartDateSourceDatev;

  const accountLengthOptions = t('accountLength.values', {
    returnObjects: true,
  }) as { [key: string]: string };

  const chartOfAccountsOptions = t('chartOfAccounts.values', {
    returnObjects: true,
  }) as { [key: string]: string };

  const onAccountLengthChange = async (key: Key) => {
    setExportConfiguration({
      ...exportConfiguration,
      accountLength: key as number,
    });
    await updateChartOfAccountMutation({
      variables: {
        accountLength: key as number,
      },
      refetchQueries: [{ query: getBookingAccountValidation }],
    });
  };

  const onChartOfAccountChange = async (key: Key) => {
    setExportConfiguration({
      ...exportConfiguration,
      chartOfAccountCode: key as string,
    });
    await updateChartOfAccountMutation({
      variables: {
        code: key as ChartOfAccountCode,
      },
    });
  };

  const onFiscalYearStartDayChange = async (key: Key) => {
    setExportConfiguration({
      ...exportConfiguration,
      fiscalYearStartDay: key as number,
    });

    await updateExportConfigurationMutation({
      variables: {
        fiscalYearStartDay: key as number,
      },
    });
  };

  const onFiscalYearStartMonthChange = async (key: Key) => {
    setExportConfiguration({
      ...exportConfiguration,
      fiscalYearStartMonth: Number(key),
    });

    await updateExportConfigurationMutation({
      variables: {
        fiscalYearStartMonth: Number(key),
      },
    });
  };

  const { bdsConnected } = useDatev(); // BDS-checked

  const chartOfAccountMessage = () => {
    if (disabledChartOfAccountFieldUpdate) {
      return t('chartOfAccounts.disabledMessage');
    }

    if (!exportConfiguration.chartOfAccountCode && !bdsConnected) {
      return t('chartOfAccounts.pleaseSyncFromDatev');
    }

    if (!exportConfiguration.chartOfAccountCode) {
      return t('chartOfAccounts.emptyState');
    }

    if (
      !supportedChartOfAccounts.includes(
        exportConfiguration.chartOfAccountCode as ChartOfAccountCode
      )
    ) {
      return t('chartOfAccounts.message');
    }
  };

  const chartOfAccountVariant = (): 'default' | 'warning' | 'error' => {
    if (exportConfiguration.chartOfAccountCode) {
      return 'default';
    }

    if (!exportConfiguration.chartOfAccountCode && !bdsConnected) {
      return 'warning';
    }

    return 'error';
  };

  let fiscalYearStartMonth = null;
  if (exportConfiguration.fiscalYearStartMonth) {
    fiscalYearStartMonth =
      exportConfiguration.fiscalYearStartMonth >= 10
        ? `${exportConfiguration.fiscalYearStartMonth}`
        : `0${exportConfiguration.fiscalYearStartMonth}`;
  }

  const fiscalYearConfigMissing =
    !exportConfiguration.fiscalYearStartDay ||
    !exportConfiguration.fiscalYearStartMonth;

  const isAccountLengthFieldDisabled =
    disabledAccountLengthFieldUpdate &&
    !!settingsData?.new_datevSettings?.chartOfAccount?.accountLength;

  const isChartOfAccountFieldDisabled =
    disabledChartOfAccountFieldUpdate &&
    !!settingsData?.new_datevSettings?.chartOfAccount?.code;

  return (
    <>
      <Grid paddingY="space12" gap="space24" templateColumns="auto auto auto">
        <SelectField
          label={t('accountLength.title')}
          message={
            disabledAccountLengthFieldUpdate
              ? t('accountLength.disabledMessage')
              : !exportConfiguration.accountLength
                ? t('accountLength.emptyState')
                : undefined
          }
          select={{
            selectedKey: exportConfiguration.accountLength,
            placeholder: t('accountLength.emptyState'),
            onSelectionChange: onAccountLengthChange,
            name: 'accountLength',
            items: toSelectOption(accountLengthOptions),
            children: item => <Item>{item.name}</Item>,
          }}
          variant={exportConfiguration.accountLength ? 'default' : 'error'}
          disabled={isAccountLengthFieldDisabled}
          readOnly={!bdsConnected}
        />
        <Grid>
          <SelectField
            label={t('chartOfAccounts.title')}
            message={chartOfAccountMessage()}
            select={{
              selectedKey: exportConfiguration.chartOfAccountCode,
              placeholder:
                !exportConfiguration.chartOfAccountCode && !bdsConnected
                  ? t('chartOfAccounts.pleaseSyncFromDatevPlaceholder')
                  : t('accountLength.emptyState'),
              onSelectionChange: onChartOfAccountChange,
              name: 'chartOfAccounts',
              items: toSelectOption(chartOfAccountsOptions),
              children: item => <Item>{item.name}</Item>,
            }}
            disabled={isChartOfAccountFieldDisabled}
            variant={chartOfAccountVariant()}
            readOnly={!bdsConnected}
          />
        </Grid>
        <FieldContainer
          label={t('fiscalYearStart.title')}
          message={
            disableFiscalStartDateFieldUpdate ? (
              t('fiscalYearStart.disabledMessage')
            ) : fiscalYearConfigMissing ? (
              <Flex direction="column">
                <Paragraph>{t('fiscalYearStart.warningMessage')}</Paragraph>
                <br />
                <Paragraph>{t('fiscalYearStart.warningMessage2')}</Paragraph>
              </Flex>
            ) : null
          }
          readOnly={
            disableFiscalStartDateFieldUpdate &&
            !!organization?.exportConfiguration?.fiscalYearStartDay &&
            !!organization?.exportConfiguration?.fiscalYearStartMonth
          }
          variant={fiscalYearConfigMissing ? 'warning' : 'default'}
        >
          <Grid columns={2} templateColumns="auto auto" gap="space8">
            <Select
              selectedKey={exportConfiguration.fiscalYearStartDay}
              placeholder={t('fiscalYearStart.day')}
              onSelectionChange={onFiscalYearStartDayChange}
              name="fiscalYearStartDay"
              items={getDays(exportConfiguration.fiscalYearStartMonth).map(
                i => ({
                  id: i + 1,
                  name: `${i + 1 < 10 ? '0' : ''}${i + 1}`,
                })
              )}
              children={item => <Item>{item.name}</Item>}
              readOnly={!bdsConnected}
            />
            <Select
              selectedKey={fiscalYearStartMonth}
              placeholder={t('fiscalYearStart.month')}
              onSelectionChange={onFiscalYearStartMonthChange}
              name="fiscalYearStartMonth"
              items={optionMonths.filter(
                shouldDisplayMonth(exportConfiguration.fiscalYearStartDay)
              )}
              children={item => <Item>{item.name}</Item>}
              readOnly={!bdsConnected}
            />
          </Grid>
        </FieldContainer>
      </Grid>
    </>
  );
};
