import React from 'react';
import { AvatarProps } from '../../Atoms/Avatar';
import {
  AvatarCircle,
  AvatarCircleProps,
} from '../../Atoms/Avatar/AvatarCircle';
import { Flex } from '../../Atoms/Flex';
import { ScrollBox } from '../../Atoms/ScrollBox';
import { Tooltip, useTooltip } from '../../Atoms/Tooltip';
import { LayoutProps } from '../../types';

interface MorePlaceholderProps {
  children: React.ReactElement<AvatarProps>[];
  size?: AvatarCircleProps['size'];
  tooltipDelay?: number;
  tooltipMaxHeight?: LayoutProps['height'];
}

export const MorePlaceholder = ({
  children,
  size = 'medium',
  tooltipDelay = 200,
  tooltipMaxHeight,
}: MorePlaceholderProps) => {
  const { isOpen, triggerProps, triggerRef, tooltipProps, tooltipRef } =
    useTooltip({ delay: tooltipDelay });

  const maxHeightScroller = tooltipProps.style?.maxHeight;
  const heightScroller = tooltipProps.style?.height;

  return (
    <>
      <AvatarCircle
        size={size}
        color="gray700"
        background="gray200"
        {...triggerProps}
        tabIndex={0}
        ref={triggerRef}
      >
        +{children.length}
      </AvatarCircle>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <ScrollBox
            overflow="scroll"
            maxHeight={maxHeightScroller}
            height={heightScroller}
          >
            <Flex direction="column" gap="space4" maxHeight={tooltipMaxHeight}>
              {children.map((child, index) => {
                return (
                  <Flex key={index} alignItems="center" gap="space8">
                    {React.cloneElement(child, {
                      hasTooltip: false,
                      size: 'small',
                    })}
                    {child.props.name}
                  </Flex>
                );
              })}
            </Flex>
          </ScrollBox>
        </Tooltip>
      )}
    </>
  );
};
