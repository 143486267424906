import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Drawer,
  Flex,
  Heading,
  Link,
} from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  CostCenter,
  Query,
  UpdateCostCenterInput,
} from 'generated-types/graphql.types';
import { GQLError } from 'gql';
import { useOtherIntegration } from 'orgConfig/other';
import { Trans } from 'providers/LocaleProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { FC, FunctionComponent, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// Use v5 version of useParams because we’re inside a v5 route
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MatchParams } from 'router/Match';
import { useShowError } from 'utils/error_message';
import { validationErrorsExtractor } from 'utils/forms';
import { usePath } from 'utils/hooks';
import { COST_CENTER_SEARCH_PARAM } from '../../consts';
import { useCostCenterRefetchQueries } from '../../hooks/useCostCenterRefetchQueries';
import { costCenterQuery, updateCostCenterStatusMutation } from '../../queries';
import { CostCenterChange } from './CostCenterChange';
import {
  isAlreadyExists,
  isCreated,
  isUpdated,
  useSaveCostCenter,
} from './useSaveCostCenter';

type CostCenterRouteParams = {
  costCenterId?: string;
  organizationSlug: string;
};

type Props = MatchParams<CostCenterRouteParams> &
  RouteComponentProps<CostCenterRouteParams>;

// biome-ignore lint/nursery/noRestrictedTypes: <explanation>
export const CostCenterDetails = withRouter<Props, FunctionComponent<Props>>(
  ({
    matched,
    history,
    location: { search },
    match: {
      params: { organizationSlug, costCenterId },
    },
  }) => {
    const [t] = useTranslation();
    const showError = useShowError();
    const { success, error, dismiss } = useToastMessage();

    const { url } = usePath<CostCenterRouteParams>({
      costCenterId: undefined,
      organizationSlug,
    });

    const { url: costCenterIdUrl } = usePath<CostCenterRouteParams>({
      costCenterId,
      organizationSlug,
    });

    const { shouldUseCoreDataApi } = useOtherIntegration();

    const { refetchQueries, resetPaginationResults } =
      useCostCenterRefetchQueries();

    const [saveCostCenter] = useSaveCostCenter();
    const [updateCostCenterStatus, { loading: updateStatusLoading }] =
      useMutation<UpdateCostCenterInput>(updateCostCenterStatusMutation, {
        onCompleted: resetPaginationResults,
        refetchQueries: [
          ...refetchQueries,
          {
            query: costCenterQuery,
            variables: {
              input: { id: matched?.costCenterId },
            },
          },
        ],
        awaitRefetchQueries: true,
      });

    const { data, loading: costCenterLoading } = useQuery<
      Pick<Query, 'costCenter'>
    >(costCenterQuery, {
      skip: !matched?.costCenterId,
      variables: {
        input: { id: matched?.costCenterId },
      },
    });

    const costCenter = data && (data.costCenter as CostCenter);
    const onClose = useCallback(() => {
      history.replace({
        pathname: url,
        search,
      });
    }, [history, search, url]);

    const handleChange = useCallback(
      async ({ type, ...input }: any) => {
        const costCenterIdInput = matched?.costCenterId || null;

        const sanitizedInput = {
          ...input,
          ...(!costCenterIdInput ? { type } : null),
          approvers: (input.approvers || []).map((a: any) => ({
            value: a.key,
            label: a.key,
            text: a.key,
          })),
        };

        try {
          const saveResult = await saveCostCenter(
            costCenterIdInput,
            sanitizedInput
          );

          if (isCreated(saveResult)) {
            success(t('settings.costCenter.created', { code: input.code }));
            onClose();
          } else if (isUpdated(saveResult)) {
            success(t('settings.costCenter.updated', { code: input.code }));
            onClose();
          } else if (isAlreadyExists(saveResult)) {
            error(
              t('settings.costCenter.update.alreadyExists', {
                code: input.code,
              })
            );
          }
        } catch (e) {
          showError(e as GQLError);

          return validationErrorsExtractor(e);
        }
      },
      [
        matched?.costCenterId,
        saveCostCenter,
        success,
        t,
        onClose,
        error,
        showError,
      ]
    );

    const handleStatus = useCallback(
      async (isArchived: boolean) => {
        if (costCenter?.id) {
          await updateCostCenterStatus({
            variables: {
              id: costCenter.id,
              input: {
                isArchived,
              },
            },
          });

          const { readableName } = costCenter;

          const key = isArchived
            ? 'settings.costCenter.actions.archivedSuccess'
            : 'settings.costCenter.actions.activatedSuccess';

          const content = (
            <Trans i18nKey={key} values={{ readableName }}>
              Cost center
              <Link
                href={costCenterIdUrl}
                onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault();
                  dismiss();
                  history.replace({
                    pathname: costCenterIdUrl,
                    search: qs.stringify({
                      [COST_CENTER_SEARCH_PARAM.archive]: isArchived,
                    }),
                  });
                }}
              >
                {{ readableName } as any}
              </Link>
              status was changed.
            </Trans>
          );

          success(content, 7);
          onClose();
        }
      },
      [
        costCenter,
        updateCostCenterStatus,
        costCenterIdUrl,
        success,
        onClose,
        dismiss,
        history,
      ]
    );

    const isArchivedCostCenter = Boolean(
      costCenter?.id && costCenter?.isArchived
    );

    const isFormReadonly = isArchivedCostCenter || shouldUseCoreDataApi;

    const createModeTitle = 'settings.costCenter.details.tabs.add';
    const editModeTitle = 'settings.costCenter.details.tabs.edit';
    const readonlyTitle = 'settings.costCenter.details.tabs.readonly';
    const titleForEditableViews = data?.costCenter
      ? editModeTitle
      : createModeTitle;

    const title = isFormReadonly ? readonlyTitle : titleForEditableViews;

    return (
      <Drawer isOpen={!!matched} onClose={onClose}>
        <Flex
          borderBottom="1px solid gray300"
          alignItems="center"
          justifyContent="space-between"
          padding="space16 space40 space8"
        >
          <Heading as="h3">{t(title)}</Heading>
          <Button
            icon="close"
            label={t('common.close')}
            onClick={onClose}
            zIndex={10}
            variant="tertiary"
          />
        </Flex>
        <Box paddingX="space40" paddingY="space24" height="100%">
          <CostCenterChange
            loading={costCenterLoading}
            onChange={handleChange}
            onStatusChange={handleStatus}
            updateStatusLoading={updateStatusLoading}
            costCenter={costCenter}
          />
        </Box>
      </Drawer>
    );
  }
);
