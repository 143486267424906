import { Grid, Text } from '@candisio/design-system';
import { ExpenseItemBookingRemovedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const ExpenseItemBookingRemoved = ({
  reimbursementItemMetadata,
}: ExpenseItemBookingRemovedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const headline = expenseTitle
    ? t('timeline.bookingRemoved', { title: expenseTitle })
    : t('timeline.bookingRemovedNoTitle');

  return (
    <Grid gap="space8">
      <Text>{headline}</Text>
    </Grid>
  );
};
