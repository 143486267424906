import {
  Grid,
  Icon,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { amountFormatNonNull } from 'utils/format';
import { Amount } from '../Table/types';

const precision = 100;

interface PurchaseOrderAmountCellProps {
  value?: Amount | null;
  documentAmount?: number | null;
}

export const PurchaseOrderAmountCell = ({
  value,
  documentAmount,
}: PurchaseOrderAmountCellProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ delay: 500 });

  if (isNil(value)) {
    return (
      <Text color="gray800" style={{ float: 'right' }}>
        {t('addPurchaseOrderModal.emptyCellPlaceholder')}
      </Text>
    );
  }

  const formattedAmount = amountFormatNonNull(value.value, value.currency, {
    convertToBasicMonetaryUnit: true,
  });

  /** Because documentAmount is a floating point number with a precision of 2,
   * we need to covert purchaseOrderAmount into the same format before comparing them. */
  const purchaseOrderAmount = value.value / precision;

  const shouldShowTooltip = documentAmount === purchaseOrderAmount;

  return (
    <Grid
      templateColumns="1fr auto"
      gap="space4"
      alignItems="center"
      ref={triggerRef}
      {...triggerProps}
    >
      <TruncatedText
        wordBreak="break-all"
        style={{ textAlign: 'end' }}
        color={shouldShowTooltip ? 'green700' : 'gray800'}
      >
        {formattedAmount}
      </TruncatedText>
      {shouldShowTooltip && (
        <>
          <Icon icon="checkCircle" color="green700" size="space16" />
          {isOpen && (
            <Tooltip {...tooltipProps} ref={tooltipRef}>
              {t('addPurchaseOrderModal.documentAmountMatchTooltip')}
            </Tooltip>
          )}
        </>
      )}
    </Grid>
  );
};
