import { KebabMenu } from 'components/KebabMenu/KebabMenu';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';

export interface ArchiveKebabMenuProps {
  organizationSlug?: string;
}

export const ArchiveKebabMenu = ({
  organizationSlug,
}: ArchiveKebabMenuProps) => {
  const [tArchive] = useTranslation(LOCALE_NAME_SPACE.ARCHIVE);
  const [tInsights] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  const history = useHistory();

  return (
    <KebabMenu
      items={[
        {
          id: 'history',
          label: tArchive('contextMenu.history'),
          onAction: () => {
            history.push(
              `/${organizationSlug}${Routes.DOCUMENT_DATA_DOWNLOAD_HISTORY}${window.location.search}`
            );
          },
        },
      ]}
      label={tInsights('download.contextMenu.tooltip')}
    />
  );
};
