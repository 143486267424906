import { useCreditCardsAccountingInfoFieldItemsQuery } from 'generated-types/graphql.types';
import { isNil } from 'lodash';

export const useTaxCodesFieldOptions = () => {
  const { data } = useCreditCardsAccountingInfoFieldItemsQuery();

  const { taxCodes = [] } = data ?? {};

  const defaultItems = taxCodes.map(tax => ({
    key: tax.id,
    children: tax.readableName,
    textValue: tax.readableName,
    code: !isNil(tax.taxCode) ? String(tax.taxCode) : undefined,
  }));

  return { defaultItems };
};
