import { Avatar, Grid } from '@candisio/design-system';
import {
  FilterOption,
  PaginationWithSearchFilterHook,
} from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import {
  BuiltinRoleName,
  useMembersByRoleQuery,
} from 'generated-types/graphql.types';
import { escapeRegex } from 'utils/regex';

export const useRequesterFilter: PaginationWithSearchFilterHook = ({
  searchStr,
}) => {
  const { data, loading } = useMembersByRoleQuery({
    variables: {
      filters: {
        roleNames: [BuiltinRoleName.Requester, BuiltinRoleName.Admin],
      },
    },
  });

  const requesters = data?.organizationMemberships ?? [];

  const searchedRequesters: FilterOption[] = requesters
    .filter(({ name }) => name.match(new RegExp(escapeRegex(searchStr), 'i')))
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name, avatarUrl }) => ({
      id: id,
      label: (
        <Grid
          templateColumns="max-content auto"
          gap="space8"
          alignItems="center"
        >
          <Avatar
            size="small"
            name={name}
            img={avatarUrl ?? undefined}
            hasTooltip={false}
          />
          {name}
        </Grid>
      ),
    }));

  return {
    filterOptions: searchedRequesters,
    loading: loading,
  };
};
