import { Avatar, Image } from '@candisio/design-system';
import candisImage from 'assets/icon-history-candis.svg';
import { useTranslation } from 'react-i18next';
import { BaseItem, BaseItemProps } from './BaseItem';

export const User = ({
  src,
  avatarUrl,
  author,
  wasImpersonated,
  ...rest
}: BaseItemProps) => {
  const [t] = useTranslation();
  const isSystemUser = author?.toLowerCase().localeCompare('candis') === 0; // TODO TG-1561: This 'CANDIS' string comes from backend api, it would be good to have it in generated types

  const props = src
    ? { ...rest, author, src, wasImpersonated }
    : {
        ...rest,
        author,
        wasImpersonated,
        customDot: isSystemUser ? (
          <Image
            alt={t('document.compact.history.shared.systemUser')}
            src={candisImage}
            width="18px"
            height="18px"
          />
        ) : (
          <Avatar name={author} img={avatarUrl} hasTooltip={false} />
        ),
      };

  return <BaseItem {...props} />;
};
