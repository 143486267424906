import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  DownloadDocumentPdFsErrorKind,
  DocumentType,
  EcmFilterInput,
  useCreatePdfZipFileForEcmDocumentsMutation,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { refetchDocumentDataFileRecords } from 'views/Archive/Documents/queries';
import { usePrepareEcmFiltersSortingFromUrl } from './usePrepareEcmFiltersSortingFromUrl';

type DefaultFilters = EcmFilterInput & {
  documentType: Array<DocumentType> | undefined;
};

interface UseDownloadDocumentFileEcmParams {
  defaultFilters?: DefaultFilters;
  isTableLoading: boolean;
  handleClosePdfDownloadModal: () => void;
}

export const useDownloadDocumentFilesEcm = ({
  defaultFilters,
  isTableLoading,
  handleClosePdfDownloadModal,
}: UseDownloadDocumentFileEcmParams) => {
  const [t] = useTranslation();
  const { success, error, info } = useToastMessage();

  const [
    triggerCreateDocumentZipFileForDocuments,
    { loading: createDocumentZipFileLoading },
  ] = useCreatePdfZipFileForEcmDocumentsMutation();

  const { filters, sort, search } = usePrepareEcmFiltersSortingFromUrl();

  const onPrepareDownloadDocument = useCallback(async () => {
    if (isTableLoading) {
      info(t('archive:download.waitForLoading'));
    }

    const results = await triggerCreateDocumentZipFileForDocuments({
      refetchQueries: refetchDocumentDataFileRecords,
      variables: {
        filters: defaultFilters ? { ...filters, ...defaultFilters } : filters,
        sort,
        search,
      },
    });

    const data = results.data?.createPDFZipFileForEcmDocuments;
    const type = data?.__typename;
    const errorType =
      type === 'DownloadDocumentPDFsError' ? data?.kind : undefined;

    if (type === 'DownloadDocumentPDFsResult') {
      handleClosePdfDownloadModal();
      success(t('archive:download.pdFZipDownloadInitiated'));

      return;
    }

    if (
      type === 'DownloadDocumentPDFsError' &&
      errorType === DownloadDocumentPdFsErrorKind.LimitReached
    ) {
      error(t('archive:download.error.limitReached'));

      return;
    } else {
      error(t('archive:download.error.unknownError'));

      return;
    }
  }, [
    isTableLoading,
    triggerCreateDocumentZipFileForDocuments,
    defaultFilters,
    filters,
    sort,
    search,
    info,
    t,
    handleClosePdfDownloadModal,
    success,
    error,
  ]);

  return {
    createDocumentZipFileLoading,
    onPrepareDownloadDocument,
  };
};
