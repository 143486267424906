import gql from 'graphql-tag';

export const contactFragment = gql`
  fragment ContactData on Contact {
    id
    name {
      value
    }
    organizationId
    email
    phoneNumber
    iban {
      value
    }
    taxNumber {
      value
    }
    vatId {
      value
    }
    dueDateOffset {
      value
    }
    paymentCondition {
      id
      dueDateOffset
      discountOffset
      discountPercentage
      paymentConditionName
    }
    createTransfer {
      value
    }
    accountsPayableNumber
    accountsReceivableNumber
    createdAt
    updatedAt
    additionalContactInfo {
      contactType
      shortName
      companyName
      individualFirstName
      individualLastName
      notSpecifiedName
      paymentMedium
    }
    relationshipType
    swiftCode
    bankAccountNumber
    street
    postOfficeBox
    postalCode
    city
    countryISOCode
    customerNumber
    isArchived
  }
`;

export const mergeContactsMutation = gql`
  mutation mergeContacts($input: MergeContactsInput!) {
    mergeContacts(input: $input) {
      ...ContactData
    }
  }
  ${contactFragment}
`;

export const createContactMutation = gql`
  mutation createContact($input: CreateContactInput!) {
    createContact(input: $input) {
      __typename
      ... on ContactCreated {
        id
      }
      ... on ContactAlreadyExists {
        name
      }
      ... on AccountsPayableNumberAlreadyAssigned {
        id
        name
        accountsPayableNumber
      }
    }
  }
`;

export const updateContactMutation = gql`
  mutation updateContact($id: ID!, $input: UpdateContactInput!) {
    updateContact(id: $id, input: $input) {
      ...ContactData
    }
  }
  ${contactFragment}
`;

export const getContactQuery = gql`
  query contact($input: GetContactInput!) {
    contact(input: $input) {
      ...ContactData
    }
  }
  ${contactFragment}
`;

export const contactsPaginationQuery = gql`
  query contactsPagination(
    $input: ContactsPaginationInput
    $filters: ContactsFilterInput
    $queries: ContactsQueryInput
    $sortBy: ContactsSortInput
  ) {
    contactsPagination(
      input: $input
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    ) {
      records {
        ...ContactData
      }
      pageInfo {
        totalCount
      }
    }
  }
  ${contactFragment}
`;

export const contactsInfiniteScrollPaginationQuery = gql`
  query contactsInfiniteScrollPagination(
    $input: ContactsPaginationInput
    $filters: ContactsFilterInput
    $queries: ContactsQueryInput
    $sortBy: ContactsSortInput
  ) {
    contactsPagination(
      input: $input
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    )
      @connection(
        key: "contactsInfiniteScrollPagination"
        filter: ["filters", "queries", "sortBy"]
      ) {
      records {
        ...ContactData
      }
      pageInfo {
        totalCount
        pageSize
        currentPage
        pageCount
      }
    }
  }
  ${contactFragment}
`;

export const setAccountsPayableNumberForContactMutation = gql`
  mutation setAccountsPayableNumberForContact(
    $id: ID!
    $accountsPayableNumber: AccountsPayableNumber!
  ) {
    setAccountsPayableNumberForContact(
      id: $id
      accountsPayableNumber: $accountsPayableNumber
    ) {
      ...ContactData
    }
  }
  ${contactFragment}
`;

export const unsetAccountsPayableNumberForContactMutation = gql`
  mutation unsetAccountsPayableNumberForContact($id: ID!) {
    unsetAccountsPayableNumberForContact(id: $id) {
      ...ContactData
    }
  }
  ${contactFragment}
`;

export const contactsImportHistoryQuery = gql`
  query contactsImportHistory {
    contactsImportHistory {
      id
      organizationId
      file {
        id
        name
        url
      }
      errorFile {
        id
        name
        url
      }
      createdBy {
        name
        avatarUrl
      }
      createdAt
      countFailedToCreate
      countSuccessfullyCreated
    }
  }
`;

export const contactsImportHistoryErrorQuery = gql`
  query contactsImportHistoryError {
    contactsImportHistoryError {
      id
      errorFile {
        id
        name
        url
      }
      importedBy {
        name
        avatarUrl
      }
      importedAt
    }
  }
`;

export const contactsImportHistoriesWithUnacknowledgedErrorsQuery = gql`
  query contactsImportHistoriesWithUnacknowledgedErrors {
    contactsImportHistoriesWithUnacknowledgedErrors {
      id
      organizationId
      hasErrorsToAcknowledge
    }
  }
`;

export const acknowledgeContactsImportHistoryErrorsMutation = gql`
  mutation acknowledgeContactsImportHistoryErrors($ids: [ID!]!) {
    acknowledgeContactsImportHistoryErrors(ids: $ids)
  }
`;

export const exportContactsMutation = gql`
  mutation exportContacts {
    exportContacts {
      url
    }
  }
`;

export const exportContactsGenericMutation = gql`
  mutation exportContactsGeneric {
    exportContactsGeneric {
      url
    }
  }
`;

export const getNextFreeAccountsPayableNumber = gql`
  query getNextFreeAccountsPayableNumber($input: String) {
    getNextFreeAccountsPayableNumber(input: $input)
  }
`;

export const archiveContactsMutation = gql`
  mutation archiveContacts($ids: [ID!]!) {
    archiveContacts(ids: $ids) {
      ... on ContactArchived {
        archivedContactIds
      }

      ... on ContactArchivedError {
        message
        code
      }
    }
  }
`;

export const activateContactsMutation = gql`
  mutation activateContacts($ids: [ID!]!) {
    activateContacts(ids: $ids) {
      ... on ContactActivated {
        activatedContactIds
      }

      ... on ContactActivatedError {
        message
        code
      }
    }
  }
`;
