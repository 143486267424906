import {
  Box,
  Button,
  Grid,
  SelectProps,
  Separator,
} from '@candisio/design-system';
import {
  HookFormSelectField,
  HookFormSelectFieldProps,
} from 'components/HookFormFields/HookFormSelectField';
import { MouseEvent, ReactElement, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PaymentConditionsFormOutput } from 'views/Settings/PaymentConditions/PaymentConditionsDrawer/PaymentConditionsForm';
import { CreatePaymentCondition } from './CreatePaymentCondition';
import {
  PaymentConditionItem,
  PaymentConditionItemProps,
} from './PaymentConditionItem';

export interface PaymentConditionSelectFieldProps<
  TFormValues extends FieldValues,
> extends HookFormSelectFieldProps<TFormValues> {
  /** Called when new payment condition should be created */
  onCreatePaymentCondition?: (
    values: PaymentConditionsFormOutput
  ) => Promise<string | undefined>;
  /** Payment condition field items */
  paymentConditionItems?: PaymentConditionItemProps[];
}

export const PaymentConditionSelectField = <TFormValues extends FieldValues>({
  onCreatePaymentCondition,
  paymentConditionItems = [],
  name,
  ...restProps
}: PaymentConditionSelectFieldProps<TFormValues>) => {
  const [t] = useTranslation();
  const { setValue } = useFormContext<TFormValues>();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownState, setDropdownState] = useState<'default' | 'create'>(
    'default'
  );

  let renderCustomDropdown: SelectProps['renderCustomDropdown'];
  if (onCreatePaymentCondition !== undefined) {
    renderCustomDropdown = (listbox: ReactElement) => (
      <Box
        onClick={
          // @TODO explicit MouseEvent<HTMLDivElement> type should not be
          // required.
          // See: https://github.com/microsoft/TypeScript/issues/44596
          (e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation(); // prevent dropdown from closing
          }
        }
        maxHeight="inherit"
      >
        {dropdownState === 'default' ? (
          <Grid
            templateRows="1fr auto auto"
            overflow="hidden"
            maxHeight="inherit"
          >
            {listbox}

            <Separator />

            <Grid padding="space16">
              <Button
                icon="plus"
                color="blue"
                onClick={() => {
                  setDropdownState('create');
                }}
                variant="tertiary"
              >
                {t('settings.contacts.details.edit.paymentCondition.createNew')}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <CreatePaymentCondition
            onCreate={async values => {
              const newConditionId = await onCreatePaymentCondition?.(values);

              if (newConditionId) {
                setValue(
                  name,
                  // @ts-expect-error React Hook Form types don’t work so
                  // well with generics
                  newConditionId
                );
                setIsOpen(false);
                setDropdownState('default');
              }
            }}
            onDiscard={() => {
              setDropdownState('default');
            }}
          />
        )}
      </Box>
    );
  }

  return (
    <HookFormSelectField<TFormValues>
      name={name}
      onOpenChange={open => {
        if (!open) {
          // reset to default state when dropdown closes
          setDropdownState('default');
        }

        setIsOpen(open);
      }}
      isOpen={isOpen}
      items={[
        {
          key: 'none',
          children: t(
            'settings.contacts.details.edit.paymentCondition.options.none'
          ),
        },
        ...paymentConditionItems.map(item => ({
          key: item.id,
          children: <PaymentConditionItem {...item} />,
          textValue: String(item.conditionNumber),
        })),
      ]}
      renderCustomDropdown={renderCustomDropdown}
      {...restProps}
    />
  );
};
