import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { CreateReimbursementCard } from '../components/CreateReimbursementCard';
import { useCreateReimbursement } from '../hooks/useCreateReimbursement';
import { REIMBURSEMENT_VIEW_ROUTES } from '../hooks/useReimbursementNavigation';
import { useGetUsers } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import {
  BuiltinRoleName,
  MembershipStatus,
} from 'generated-types/graphql.types';

export const CreateReimbursementCardContainer = () => {
  const organizationSlug = useOrganizationId();
  const { users: administrators } = useGetUsers({
    filters: {
      roleNames: [BuiltinRoleName.Admin],
      status: MembershipStatus.Active,
      emailVerified: true,
    },
  });
  const navigate = useNavigate();
  const {
    createReimbursement,
    createReimbursementPending,
    isDuplicateErrorVisible,
  } = useCreateReimbursement();

  const handleCreateReimbursement = async () => {
    const { createdReimbursementId } = await createReimbursement();

    if (!createdReimbursementId) {
      return;
    }

    const pathname = generatePath(
      REIMBURSEMENT_VIEW_ROUTES[Routes.REIMBURSEMENTS_DASHBOARD],
      { organizationSlug, reimbursementId: createdReimbursementId }
    );

    navigate(pathname);
  };

  return (
    <CreateReimbursementCard
      isLoading={createReimbursementPending}
      isDuplicateErrorVisible={isDuplicateErrorVisible}
      onCreateReimbursement={handleCreateReimbursement}
      administrators={administrators}
    />
  );
};
