import { useEffect } from 'react';
import { useReimbursementFormsContext } from '../context/ReimbursementFormsContext';

export const useFormValidationTrigger = (
  formKey: string,
  trigger: () => void
) => {
  const { registerForm } = useReimbursementFormsContext();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    registerForm(formKey, { trigger });

    return () => {
      registerForm(formKey, { trigger: undefined });
    };
  }, []);
};
