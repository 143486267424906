import { Card, Grid, Text, Button, useModal } from '@candisio/design-system';
import { PROVISIONS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { BDSPromotionModal } from './BDSPromotionModal';

export const ProvisionsPromotionApprovalsBanner = ({
  onCloseBanner,
}: { onCloseBanner: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.APPROVALS);

  const { triggerProps, isOpen, close } = useModal();

  return (
    <Card background="purple100" paddingY="space12">
      <Grid templateColumns="1fr auto auto" alignItems="center" gap="space4">
        <Grid>
          <Text fontWeight="semibold" fontSize="large">
            {t('add-on.title')}
          </Text>
          <Text fontSize="basic">{t('add-on.description')} </Text>
        </Grid>
        <Button color="purple" {...triggerProps}>
          {t('add-on.button')}
        </Button>
        <Button
          icon="close"
          variant="tertiary"
          label={t('add-on.buttonTooltip')}
          size="small"
          onClick={onCloseBanner}
        ></Button>
      </Grid>
      {isOpen && (
        <BDSPromotionModal
          utmSource={PROVISIONS_UTM_SOURCE.APPROVALS_BANNER}
          isOpen={isOpen}
          close={close}
        />
      )}
    </Card>
  );
};
