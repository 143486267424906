import { defaultTheme } from '@candisio/design-system';
import { mapExportStatusIcon } from 'components/utils/status-mapping';
import { ExportStatus } from 'generated-types/graphql.types';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';
import { BaseItem, BaseItemProps } from './BaseItem';

export type Props = BaseItemProps & {
  active?: string | null;
  status?: ExportStatus | 'default' | 'not-ready';
  size?: 'big' | 'default';
};

export const Export = styled(BaseItem).attrs<Props>(({ status }) => ({
  src: mapExportStatusIcon(status),
}))<Props>`
  transition: background-color 0.3s;
  cursor: pointer;

  &[active='true'] {
    background-color: ${defaultTheme.colors.gray0} !important;
  }

  &[active='false']:hover {
    background-color: ${defaultTheme.colors.gray50} !important;
  }

  // main item
  ${p =>
    p.size === 'big' &&
    `
    height: 4.3125rem;
    margin-bottom: 10px !important;
  `}
`;
