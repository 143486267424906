import { Card, Grid, Text } from '@candisio/design-system';
import { ErrorIcon, WarningIcon } from 'components/Icons/DefaultIcons';
import { TransactionStatus } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import {
  getDeclineReason,
  getInfoBoxVariant,
  NoDocumentInfoBoxProps,
} from './utils';

export const InvalidTransactionInfoBox = ({
  status,
  localizedDeclineReason,
}: NoDocumentInfoBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const currentUser = useCurrentUser();

  const locale = currentUser?.locale;
  const { title, description } = getInfoBoxVariant(status);

  const { declineTitle, declineDescription } = getDeclineReason({
    locale,
    localizedDeclineReason,
  });

  const isDeclined =
    status === TransactionStatus.Declined && localizedDeclineReason;

  return (
    <Card
      background={isDeclined ? 'redbg' : 'yellowbg'}
      padding="space8"
      boxShadow="noShadow"
    >
      <Grid
        justifyContent="start"
        alignItems="start"
        gap="space8"
        templateColumns="auto 1fr"
      >
        {isDeclined ? (
          <ErrorIcon size="space18" />
        ) : (
          <WarningIcon size="space18" />
        )}
        <Grid gap="space4">
          {title && (
            <Text fontWeight="semibold">{t(declineTitle ?? title)}</Text>
          )}
          {description && <Text>{t(declineDescription ?? description)}</Text>}
        </Grid>
      </Grid>
    </Card>
  );
};
