import { Card, Grid, Heading } from '@candisio/design-system';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { TeamDetails as TeamDetailsCard } from 'views/Settings/Teams/elements/List/TeamDetails';
import { Team as TeamType } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';
import { Step } from 'views/Settings/Teams/toolkit/utils/types';
import { ArchiveButton } from '../../components/ArchiveButton';
import { ActionCards } from './ActionCards';

interface TeamProps {
  team: TeamType;
  isArchivingPending: boolean;
  isTeamAssignedToCCs: boolean;
  onClose: () => void;
  onSetStep: (step: Step) => void;
  onArchiveTeam: () => Promise<void>;
}

export const CARD_TEST_ID = 'card-test-id';

export const Team = ({
  team,
  isArchivingPending,
  isTeamAssignedToCCs,
  onClose,
  onSetStep,
  onArchiveTeam,
}: TeamProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <DrawerLayout
      onClose={onClose}
      header={<Heading as="h3">{t('teams.editTeam.title')}</Heading>}
      children={
        <Grid height="100%" gap="space24">
          <Card
            padding="0px"
            border="1px solid gray250"
            data-testid={CARD_TEST_ID}
          >
            <TeamDetailsCard team={team} />
          </Card>
          <ActionCards onSetStep={onSetStep} />
        </Grid>
      }
      footer={
        <Grid>
          <ArchiveButton
            isArchivingPending={isArchivingPending}
            isTeamAssignedToCCs={isTeamAssignedToCCs}
            onArchiveTeam={onArchiveTeam}
            onClose={onClose}
          />
        </Grid>
      }
    />
  );
};
