import { FieldPolicy, TypePolicies } from '@apollo/client';
import { ArchiveInvoiceDocumentsResponse } from 'generated-types/resolvers-types';
import { paginationWithRecordConnectionsMergeNoNodes } from '../pagination-results-merge';

const archiveInvoiceDocumentsPagination: FieldPolicy<ArchiveInvoiceDocumentsResponse> =
  {
    merge: paginationWithRecordConnectionsMergeNoNodes(
      'ArchiveInvoiceDocumentsResponse'
    ),
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      archiveInvoiceDocuments: archiveInvoiceDocumentsPagination,
    },
  },
};
