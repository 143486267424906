import { SvgIcon, SvgProps } from './SvgIcon';

/**
 * @deprecated please use Icon from design system instead.
 * @see https://candisio.github.io/design-system/
 */
export const Cross = ({
  fill = '#333',
  height = '10',
  viewBox = '0 0 10 10',
  width = '10',
  ...props
}: SvgProps) => {
  return (
    <SvgIcon
      {...props}
      height={height}
      viewBox={viewBox}
      width={width}
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0.950962L5.38796 4.54492L9 8.13888L8.09699 9.04492L4.48495 5.45096L0.90301 9.04492L0 8.13888L3.58194 4.54492L0 0.950962L0.90301 0.0449219L4.48495 3.63888L8.09699 0.0449219L9 0.950962Z"
      />
    </SvgIcon>
  );
};
