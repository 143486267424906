import { History } from 'components/History/History';
import {
  ExportableEntityInfo,
  ExportStatus,
} from 'generated-types/graphql.types';
import { DateFormatters } from 'utils/date_formatter';
import { ExportInfoSection } from './ExportInfoSection';

export const Exported = ({
  createdAt,
  exportId,
  exportableEntityInfo,
  exportedByName,
  index,
  isActive,
  onGoToExport,
  status,
  isLast,
}: {
  createdAt: Date;
  exportId: string;
  exportableEntityInfo?: ExportableEntityInfo[];
  exportedByName: string;
  index: number;
  isActive: boolean;
  onGoToExport: (exportId: string) => void;
  status: ExportStatus;
  isLast?: boolean;
}) => {
  const isStillExporting =
    status !== ExportStatus.Failed &&
    status !== ExportStatus.Exported &&
    exportableEntityInfo?.some(
      d =>
        d.status &&
        [ExportStatus.Exporting, ExportStatus.Pending].includes(d.status)
    );

  return (
    <History.Export
      hideTail={isLast}
      absoluteTime={DateFormatters.regularDatetime(createdAt)}
      header={DateFormatters.compact(createdAt)}
      author={exportedByName}
      data-cy={`export-history-item-${index}`}
      status={
        isStillExporting
          ? ExportStatus.Exporting
          : (status ?? ExportStatus.Failed)
      }
      active={isActive.toString()}
      onClick={() => onGoToExport(exportId)}
    >
      {status && (
        <ExportInfoSection
          mainStatus={status}
          exportableEntities={exportableEntityInfo}
          active={isActive}
        />
      )}
    </History.Export>
  );
};
