import { IconProps } from '@candisio/design-system';
import { Routes } from 'models';
import { forwardRef, MouseEventHandler, ReactNode } from 'react';
import { useLocation, useParams } from 'react-router-dom-v5-compat';
import { IconLink } from './IconLink/IconLink';

export type NavigationIconType = {
  icon: IconProps['icon'];
  route?: string;
  label?: string;
  expanded?: boolean;
  showMenuIcon?: boolean;
  pulseIndicator?: ReactNode;
  count?: number;
  countLoading?: boolean;
  isActive?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  children?: ReactNode;
};

const extractPathWithoutParams = (path: string) => path.split('?')[0];

export const NavigationIcon = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  NavigationIconType
>(
  (
    {
      icon,
      route,
      label,
      expanded,
      showMenuIcon = true,
      pulseIndicator,
      count,
      countLoading,
      isActive: isActiveProp,
      onClick,
      ...restProps
    }: NavigationIconType,
    ref
  ) => {
    const { organizationSlug } = useParams();
    const location = useLocation();

    const isActiveRoute = () => {
      if (!route) return false;

      const routeWithPrefix = route.includes(Routes.SETTINGS)
        ? `/${organizationSlug}${Routes.SETTINGS}`
        : `/${organizationSlug}${route}`;

      const cleanRoute = extractPathWithoutParams(routeWithPrefix);
      const cleanPathname = extractPathWithoutParams(location.pathname);

      const isMatchingRoute = cleanPathname === cleanRoute;
      const isMatchingSubPath = cleanPathname.startsWith(cleanRoute + '/');

      return isMatchingRoute || isMatchingSubPath;
    };

    if (!showMenuIcon) return null;

    if (route) {
      return (
        <IconLink
          icon={icon}
          label={label}
          expanded={expanded}
          to={`/${organizationSlug}${route}`}
          isActive={isActiveProp ?? isActiveRoute()}
          pulseIndicator={pulseIndicator}
          count={count}
          countLoading={countLoading}
          ref={ref}
          onClick={onClick}
          {...restProps}
        />
      );
    }

    return (
      <IconLink
        icon={icon}
        label={label}
        expanded={expanded}
        pulseIndicator={pulseIndicator}
        count={count}
        countLoading={countLoading}
        isActive={isActiveProp ?? isActiveRoute()}
        ref={ref}
        as="button"
        onClick={onClick}
        {...restProps}
      />
    );
  }
);
