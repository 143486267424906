import { Flex } from '@candisio/design-system';
import { IntercomLink } from 'components/InfoPanel/components/IntercomLink';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export function DocumentTypesInfoPanel() {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  return (
    <Flex direction="column" gap="space16">
      <InfoPanel
        sections={t('documentTypes.infoPanel.faq.sections', {
          returnObjects: true,
        })}
        title={t('documentTypes.infoPanel.faq.title')}
        externalLink={t('documentTypes.infoPanel.faq.externalLink', {
          returnObjects: true,
        })}
      />
      <InfoPanel
        sections={t('documentTypes.infoPanel.request.sections', {
          returnObjects: true,
        })}
        title={t('documentTypes.infoPanel.request.title')}
        intercomLink={
          <IntercomLink id="settings-document-types-intercom-link">
            {t('documentTypes.infoPanel.request.requestLink')}
          </IntercomLink>
        }
      />
    </Flex>
  );
}
