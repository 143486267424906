import React, { useRef } from 'react';
import {
  AriaOverlayProps,
  mergeProps,
  OverlayContainer,
  useOverlay,
} from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { Dialog, DialogProps } from '../../Atoms/Dialog';
import {
  OverlayBackground,
  OverlayProps,
} from '../../Atoms/Overlay/OverlayBackground';

export interface ModalProps
  extends AriaOverlayProps,
    Omit<DialogProps, 'isModal'> {
  children?: DialogProps['children'];
  closeLabel?: DialogProps['closeLabel'];
  isDismissable?: boolean;
  isOpen?: boolean;
  onClose?: DialogProps['onClose'];
  overlayColor?: string;
  overlayPadding?: OverlayProps['padding'];
  title?: DialogProps['title'];
  titleInfo?: DialogProps['titleInfo'];
  autoFocus?: DialogProps['autoFocus'];
}

/**
 * Modal molecule wraps content provided in Dialog and Overlay atoms.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/story/molecules-overlay-modal}
 *
 * @param {string} title Heading to appear above content
 * @param {React.ReactNode} titleInfo Heading to appear above content
 * @param {string} closeLabel Label for close Button Tooltip
 * @param {boolean} isOpen Open state
 * @param {function} onClose Close handler
 * @param {string} overlayColor other than the default
 * @param {React.ReactNode} children Content
 * @param {boolean} [isDismissable = true] Whether to close the Modal when the user interacts outside it.
 * @param {boolean} [autoFocus = true] Whether to automatically focus the first element.
 */
export const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      children,
      isDismissable = true,
      isOpen = false,
      maxHeight = '100%',
      maxWidth = '100%',
      onClose,
      overlayColor,
      width = '60ch',
      overlayPadding,
      ...restProps
    },
    forwardedRef
  ) => {
    const ref = useRef<HTMLDivElement>(null);
    const backgroundRef = useRef<HTMLDivElement>(null);

    const { overlayProps, underlayProps } = useOverlay(
      {
        onClose,
        isOpen,
        isDismissable: isDismissable,
        shouldCloseOnInteractOutside: element =>
          element === backgroundRef.current,
      },
      ref
    );

    return isOpen ? (
      <OverlayContainer>
        <OverlayBackground
          background={overlayColor}
          padding={overlayPadding ?? 'space32'}
          {...underlayProps}
          ref={backgroundRef}
        >
          <Dialog
            isModal={true}
            onClose={onClose}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            width={width}
            {...mergeProps(overlayProps, restProps)}
            ref={mergeRefs([ref, forwardedRef])}
          >
            {children}
          </Dialog>
        </OverlayBackground>
      </OverlayContainer>
    ) : null;
  }
);
