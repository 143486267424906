import {
  Box,
  Flex,
  Grid,
  Skeleton,
  defaultTheme,
} from '@candisio/design-system';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

const SECTION_SPACE = '0.1rem';
const HEIGHT_HEADER = defaultTheme.space.space14;
const HEIGHT_CONTENT = defaultTheme.space.space12;
const HEIGHT_SMALL = defaultTheme.space.space10;

const Spacer = styled.div`
  height: 2rem;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const OrderDetails = styled.div`
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: ${SECTION_SPACE};
  }
`;

const ContactLogo = styled.div`
  width: 60%;
  height: 2rem;
`;

const CompanyInfo = styled.div`
  width: 50%;
  & > :not(:last-child) {
    margin-bottom: ${SECTION_SPACE};
  }
`;

const ContactInfo = styled(CompanyInfo)`
  justify-content: end;
`;

const Name = styled.div`
  height: ${HEIGHT_HEADER};
`;

const Street = styled.div`
  height: ${HEIGHT_CONTENT};
`;

const OrderDetailsHeader = styled.div`
  width: 20%;
`;

const OrderDetailsTextLines = styled.div`
  width: 100%;
  & > :not(:last-child) {
    margin-bottom: ${SECTION_SPACE};
  }
`;

const City = styled(Street)``;
const Country = styled(Street)``;
const VatId = styled(Street)``;

const Orders = styled.div`
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: ${SECTION_SPACE};
  }
`;

const OrderRow = styled.div`
  display: flex;
  flex-direction: row;
  & > :not(:last-child) {
    margin-right: 1rem;
  }
`;

const OrderDescription = styled.div`
  flex-grow: 4;
`;

const OrderCount = styled.div`
  flex-grow: 1;
`;

const OrderVat = styled(OrderCount)``;
const OrderPrice = styled(OrderCount)``;
const OrderTotal = styled(OrderCount)``;

const OrderSummary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const OrderSummaryTotal = styled.div`
  flex: 1;
`;

const OrderSummaryTotalPrice = styled.div`
  flex: 1;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  flex-grow: 1;
  & > :not(:last-child) {
    margin-bottom: ${SECTION_SPACE};
  }
`;

export const PdfInvoiceSkeleton = () => (
  <Grid
    background="white"
    borderRadius="medium"
    height="100%"
    padding="space16"
    data-testid="pdf-loading-skeleton"
    width="100%"
  >
    <ContactLogo>
      <Skeleton height="2rem" width="100%" />
    </ContactLogo>
    <Spacer />
    <Header>
      <CompanyInfo>
        <Name>
          <Skeleton height={HEIGHT_HEADER} width="60%" />
        </Name>
        <Street>
          <Skeleton height={HEIGHT_CONTENT} width="40%" />
        </Street>
        <City>
          <Skeleton height={HEIGHT_CONTENT} width="30%" />
        </City>
        <Country>
          <Skeleton height={HEIGHT_CONTENT} width="10%" />
        </Country>
      </CompanyInfo>
      <ContactInfo>
        <Name>
          <Skeleton
            height={HEIGHT_HEADER}
            width="60%"
            style={{ float: 'right' }}
          />
        </Name>
        <Street>
          <Skeleton
            height={HEIGHT_CONTENT}
            width="40%"
            style={{ float: 'right' }}
          />
        </Street>
        <City>
          <Skeleton
            height={HEIGHT_CONTENT}
            width="30%"
            style={{ float: 'right' }}
          />
        </City>
        <Country>
          <Skeleton
            height={HEIGHT_CONTENT}
            width="10%"
            style={{ float: 'right' }}
          />
        </Country>
        <VatId>
          <Skeleton
            height={HEIGHT_CONTENT}
            width="50%"
            style={{ float: 'right' }}
          />
        </VatId>
      </ContactInfo>
    </Header>
    <Spacer />
    <OrderDetails>
      <OrderDetailsHeader>
        <Skeleton height={HEIGHT_HEADER} width="100%" />
      </OrderDetailsHeader>
      <OrderDetailsTextLines>
        <Skeleton height={HEIGHT_CONTENT} width="100%" />
        <Skeleton height={HEIGHT_CONTENT} width="90%" />
        <Skeleton height={HEIGHT_CONTENT} width="40%" />
      </OrderDetailsTextLines>
    </OrderDetails>
    <Spacer />
    <Orders>
      <OrderRow>
        <OrderDescription>
          <Skeleton height={HEIGHT_CONTENT} width="100%" />
        </OrderDescription>
        <OrderCount>
          <Skeleton height={HEIGHT_CONTENT} width="100%" />
        </OrderCount>
        <OrderVat>
          <Skeleton height={HEIGHT_CONTENT} width="100%" />
        </OrderVat>
        <OrderPrice>
          <Skeleton height={HEIGHT_CONTENT} width="100%" />
        </OrderPrice>
        <OrderTotal>
          <Skeleton height={HEIGHT_CONTENT} width="100%" />
        </OrderTotal>
      </OrderRow>
      <OrderRow>
        <OrderDescription>
          <Skeleton height={HEIGHT_CONTENT} width="70%" />
        </OrderDescription>
        <OrderCount>
          <Skeleton
            height={HEIGHT_CONTENT}
            width="20%"
            style={{ float: 'right' }}
          />
        </OrderCount>
        <OrderVat>
          <Skeleton
            height={HEIGHT_CONTENT}
            width="20%"
            style={{ float: 'right' }}
          />
        </OrderVat>
        <OrderPrice>
          <Skeleton
            height={HEIGHT_CONTENT}
            width="30%"
            style={{ float: 'right' }}
          />
        </OrderPrice>
        <OrderTotal>
          <Skeleton
            height={HEIGHT_CONTENT}
            width="30%"
            style={{ float: 'right' }}
          />
        </OrderTotal>
      </OrderRow>
      <OrderRow>
        <OrderDescription>
          <Skeleton height={HEIGHT_CONTENT} width="50%" />
        </OrderDescription>
        <OrderCount>
          <Skeleton
            height={HEIGHT_CONTENT}
            width="40%"
            style={{ float: 'right' }}
          />
        </OrderCount>
        <OrderVat>
          <Skeleton
            height={HEIGHT_CONTENT}
            width="20%"
            style={{ float: 'right' }}
          />
        </OrderVat>
        <OrderPrice>
          <Skeleton
            height={HEIGHT_CONTENT}
            width="40%"
            style={{ float: 'right' }}
          />
        </OrderPrice>
        <OrderTotal>
          <Skeleton
            height={HEIGHT_CONTENT}
            width="40%"
            style={{ float: 'right' }}
          />
        </OrderTotal>
      </OrderRow>
    </Orders>
    <Spacer />
    <OrderSummary>
      <Box flex="1" />
      <Flex flex="1">
        <OrderSummaryTotal>
          <Skeleton
            height={HEIGHT_CONTENT}
            width="50%"
            style={{ float: 'right' }}
          />
        </OrderSummaryTotal>
        <OrderSummaryTotalPrice>
          <Skeleton
            height={HEIGHT_CONTENT}
            width="20%"
            style={{ float: 'right' }}
          />
        </OrderSummaryTotalPrice>
      </Flex>
    </OrderSummary>
    <Footer>
      <Skeleton
        height={HEIGHT_SMALL}
        width="80%"
        style={{ marginRight: 'auto', marginLeft: 'auto' }}
      />
      <Skeleton
        height={HEIGHT_SMALL}
        width="60%"
        style={{ marginRight: 'auto', marginLeft: 'auto' }}
      />
      <Skeleton
        height={HEIGHT_SMALL}
        width="50%"
        style={{ marginRight: 'auto', marginLeft: 'auto' }}
      />
      <Skeleton
        height={HEIGHT_SMALL}
        width="90%"
        style={{ marginRight: 'auto', marginLeft: 'auto' }}
      />
    </Footer>
  </Grid>
);
