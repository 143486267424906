import { useQuery } from '@apollo/client';
import {
  Grid,
  Icon,
  Popover,
  ScrollBox,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import { Query } from 'generated-types/graphql.types';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { getOrganizationsQuery } from '../../Header/components/MultiOrganizationOverview/getOrganizationsQuery';
import { OrganizationsListView } from '../../Header/components/MultiOrganizationOverview/OrganizationsListView';
import {
  MotionOrganizationDetails,
  MotionOrganizationSwitcher,
  organizationDetailsVariants,
  organizationSwitcherVariants,
} from '../animations';
import { OrganizationAvatar } from './OrganizationAvatar/OrganizationAvatar';

interface OrganizationSwitcherProps {
  isNavigationOpen: boolean;
}

export const OrganizationSwitcher = ({
  isNavigationOpen,
}: OrganizationSwitcherProps) => {
  const organization = useFullOrganization();
  const organizationName = organization?.name;
  const truncateAt = organizationName && organizationName.length / 2;
  const organizationsQuery = useQuery<Pick<Query, 'getOrganizations'>>(
    getOrganizationsQuery
  );

  const organizations = organizationsQuery.data?.getOrganizations || [];
  const hasOnlyOneOrganization = organizations.length === 1;

  const { close, isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'right' });

  if (hasOnlyOneOrganization)
    return (
      <Grid
        alignItems="center"
        background={isNavigationOpen ? 'transparent' : 'gray200'}
        borderRadius="medium"
        gap="space8"
        overflow="hidden"
        padding="space12"
        templateColumns="auto 1fr"
      >
        <OrganizationAvatar
          organizationName={organizationName}
          isNavigationOpen={isNavigationOpen}
          isPopoverOpen={isOpen}
          hasOnlyOneOrganization={hasOnlyOneOrganization}
        />
        <MotionOrganizationDetails
          templateColumns="1fr auto"
          gap="space8"
          alignItems="center"
          opacity={0}
          variants={organizationDetailsVariants}
          animate={isNavigationOpen ? 'open' : 'closed'}
        >
          <Grid>
            <TruncatedText
              fontSize="basic"
              fontWeight="500"
              color="gray800"
              charsAfterEllipsis={truncateAt ? truncateAt : undefined}
            >
              {organizationName ?? ''}
            </TruncatedText>
          </Grid>
        </MotionOrganizationDetails>
      </Grid>
    );

  return (
    <MotionOrganizationSwitcher
      {...triggerProps}
      ref={triggerRef}
      initial={false}
      variants={organizationSwitcherVariants}
      animate={isNavigationOpen ? 'open' : 'closed'}
      transition={{ duration: 0.3 }}
      alignItems="center"
      background={
        isOpen ? 'bluebg' : isNavigationOpen ? 'transparent' : 'gray200'
      }
      borderRadius="medium"
      cursor="pointer"
      gap="space8"
      overflow="hidden"
      padding="space12"
      templateColumns="auto 1fr"
    >
      <OrganizationAvatar
        organizationName={organizationName}
        isNavigationOpen={isNavigationOpen}
        isPopoverOpen={isOpen}
      />
      <MotionOrganizationDetails
        templateColumns="1fr auto"
        gap="space8"
        alignItems="center"
        opacity={0}
        variants={organizationDetailsVariants}
        animate={isNavigationOpen ? 'open' : 'closed'}
      >
        <Grid>
          <TruncatedText
            fontSize="basic"
            fontWeight="500"
            color="gray800"
            charsAfterEllipsis={truncateAt ? truncateAt : undefined}
          >
            {organizationName ?? ''}
          </TruncatedText>
        </Grid>
        <Icon icon="caretDown" size="space20" color="gray600" />
      </MotionOrganizationDetails>
      {isOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          paddingX="0"
          paddingY="0"
          width="440px"
        >
          <ScrollBox maxHeight="inherit" padding="0">
            <OrganizationsListView handleCurrentOrganization={close} />
          </ScrollBox>
        </Popover>
      )}
    </MotionOrganizationSwitcher>
  );
};
