import { Grid } from '@candisio/design-system';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DocumentTagDetailsFormOutput } from './documentTagDetailsFormSchema';

export interface DocumentTagDetailsFormProps {
  formId: string;
  onSubmit: (values: DocumentTagDetailsFormOutput) => Promise<void>;
  form: ReturnType<typeof useForm<DocumentTagDetailsFormOutput>>;
}

export const DocumentTagDetailsForm = ({
  formId,
  onSubmit,
  form,
}: DocumentTagDetailsFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        gap="space24"
        id={formId}
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.stopPropagation();
          void form.handleSubmit(onSubmit)(e);
        }}
        paddingTop="space36"
      >
        <HookFormTextField
          autoFocus
          label={t('documentTags.drawer.fields.name.label')}
          name="name"
          placeholder={t('documentTags.drawer.fields.name.placeholder')}
        />
        <HookFormTextareaField
          label={t('documentTags.drawer.fields.description.label')}
          name="description"
          placeholder={t('documentTags.drawer.fields.description.placeholder')}
          minRows={4}
        />
      </Grid>
    </FormProvider>
  );
};
