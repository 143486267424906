import {
  Button,
  Flex,
  Grid,
  Tag,
  Text,
  TruncatedText,
  useTheme,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface StatementSectionProps {
  month: string | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  isClosed: boolean;
  startingBalance?: string;
  closingBalance?: string;
  hasPdfFile: boolean;
  pdfFileUrl: string | undefined;
  totalTransactionAmount: string | undefined;
  totalPaymentAmount: string | undefined;
  isDownloadingCSV?: boolean;
  onDownloadCSV: () => void;
}

export const StatementSection = ({
  closingBalance,
  startingBalance,
  endDate,
  isClosed,
  month,
  startDate,
  totalPaymentAmount,
  totalTransactionAmount,
  hasPdfFile,
  pdfFileUrl,
  isDownloadingCSV,
  onDownloadCSV,
}: StatementSectionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { colors } = useTheme();

  const datePeriod = isClosed ? `${startDate} - ${endDate}` : `${startDate}`;

  return (
    <Grid gap="space16">
      <Grid>
        <Grid gap="space16" templateColumns="auto 1fr">
          <Flex gap="space4" alignItems="center">
            <Text fontSize="xxlarge">{month}</Text>
            {!isClosed && (
              <Tag variant="secondary" color="blue">
                {t('statements.list.onGoingTag')}
              </Tag>
            )}
          </Flex>

          <Flex gap="space4" justifySelf="end" alignItems="center">
            {hasPdfFile && (
              <Button
                as="a"
                iconPosition="right"
                icon="arrowDown"
                href={pdfFileUrl}
                target="_blank"
                variant="secondary"
                color="gray"
                rel="noopener noreferrer"
                style={{ color: colors.gray800 }}
              >
                {t('statements.detail.pdf')}
              </Button>
            )}

            <Button
              iconPosition="right"
              icon="arrowDown"
              variant="secondary"
              color="gray"
              onClick={onDownloadCSV}
              disabled={isDownloadingCSV}
              loading={isDownloadingCSV}
            >
              {t('statements.detail.csv')}
            </Button>
          </Flex>
        </Grid>
        {!hasPdfFile && (
          <TruncatedText color="gray600" alignSelf="end" lineHeight="space32">
            {t('statements.detail.noFileText')}
          </TruncatedText>
        )}
        <Text color="gray600">{datePeriod}</Text>
      </Grid>
      <Grid
        autoFlow="column"
        padding="space16"
        borderRadius="medium"
        placeItems="center"
        gap="space8"
        border="1px solid gray300"
      >
        <Flex direction="column" alignItems="end">
          <Text fontSize="basic">{startingBalance}</Text>
          <Text color="gray600">{t('statements.detail.startingBalance')}</Text>
        </Flex>
        <Flex direction="column" alignItems="end">
          <Text fontSize="basic">{totalTransactionAmount}</Text>
          <Text color="gray600">{t('statements.detail.totalTx')}</Text>
        </Flex>
        <Flex direction="column" alignItems="end">
          <Text fontSize="basic">{totalPaymentAmount}</Text>
          <Text color="gray600">{t('statements.detail.totalPayment')}</Text>
        </Flex>
        <Flex direction="column" alignItems="end">
          <Text fontSize="basic">{closingBalance}</Text>
          <Text color={isClosed ? 'gray600' : 'gray800'}>
            {isClosed
              ? t('statements.list.closingBalance')
              : t('statements.list.openingBalance')}
          </Text>
        </Flex>
      </Grid>
    </Grid>
  );
};
