import { IntegrationName } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useGetSapB1Credentials } from 'hooks/useGetSAPB1Credentials';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useDatev } from 'orgConfig/datev';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import {
  useFullOrganization,
  useOrganizationId,
} from 'providers/OrganizationProvider';
import { useContext, useEffect, useMemo } from 'react';
import {
  AvailableEntity,
  ExportContext,
  TableType,
  entities,
  exportTypes,
  tables,
  views,
} from './Context';
import { useDatevSettings } from './toolkit/hooks/useDatevSettings';
import { isExportTypeWithTimeframe } from './toolkit/utils';
import { Entity, ExportType, Sidebar, Table, View } from './types';

type ExportViewUrlProps = {
  view?: View;
  exportId?: string | null;
  entity?: AvailableEntity;
  table?: TableType;
  exportType?: ExportType | null;
  sidebar?: Sidebar;
  shouldTriggerDownload?: boolean;
  timeframe?: string | null;
};

export const useDefineExportView = (def: ExportViewUrlProps) => {
  const orgId = useOrganizationId();

  return defineExportView(def, { orgId });
};

type DefineViewContext = {
  orgId?: string | null;
};

export const defineExportView = (
  def: ExportViewUrlProps,
  { orgId = '' }: DefineViewContext
) => {
  const viewDef = {
    view: def.view ?? View.READY,
    entity: def.entity ?? Entity.DOCUMENT,
    table: def.table ?? Table.APPROVALS,
    sidebar: def.sidebar ?? Sidebar.INIT_NEW_EXPORT,
    shouldTriggerDownload: def.shouldTriggerDownload
      ? def.shouldTriggerDownload + ''
      : 'false',
    ...(def.exportType && { exportType: def.exportType }),
    ...(def.exportId && { exportId: def.exportId }),
    ...(def.timeframe && { timeframe: def.timeframe }),
  };

  if (viewDef.view !== View.HISTORY) {
    delete viewDef.exportId;
  }

  if (!isExportTypeWithTimeframe(viewDef.exportType)) {
    delete viewDef.timeframe;
  }

  const search = new URLSearchParams(viewDef).toString();

  return {
    props: viewDef,
    navigate: {
      pathname: `/${orgId}${Routes.EXPORTS}`,
      search,
    },
    url: `${Routes.EXPORTS}?${search}`,
  };
};

export const ExportManifest = () => {
  const state = useContext(ExportContext);
  const { bdsConnected } = useDatev();
  const { datevSettings } = useDatevSettings();
  const sap = useSap();
  const organization = useFullOrganization();
  const { data } = useGetSapB1Credentials();
  const creditCardsSetup = useCreditCardsSetup();
  const hideCreditCards = creditCardsSetup.hide;
  const isSapConfigured = Boolean(data?.sapB1Credentials?.companyDbId);
  const integrationName = organization?.integrationName;

  const isDatevConnected =
    !!datevSettings?.client && !!datevSettings?.hasAuthTokenForExportDocuments;

  const [
    experimentalSapCsvFF,
    experimentalCandisCsvFF,
    exportProvisionsWithDATEVFormatFF,
  ] = useCandisFeatureFlags([
    FEATURE_FLAGS.sapCsvExport,
    FEATURE_FLAGS.experimentalCandisCsvExport,
    FEATURE_FLAGS.exportProvisionsDatevFormatInternal,
  ]);

  const { shouldUseCandisApi } = useOtherIntegration();

  const integrationExportTypes = useMemo(() => {
    const integrationExportTypes: ExportType[] = [];

    if (integrationName === IntegrationName.Other) {
      if (shouldUseCandisApi) {
        return exportTypes.candisApi;
      } else {
        integrationExportTypes.push(...exportTypes.other);
      }
    }

    // Export types based on integration types
    if (integrationName === IntegrationName.Datev) {
      if (bdsConnected) integrationExportTypes.push(ExportType.DATEV_BDS);

      if (!bdsConnected && isDatevConnected) {
        integrationExportTypes.push(ExportType.DATEV, ExportType.DATEV_BDS);
      }

      integrationExportTypes.push(...exportTypes.datev);
    }

    if (integrationName === IntegrationName.DatevAdjacent) {
      integrationExportTypes.push(...exportTypes.datevAdjacent);
    }

    if (integrationName === IntegrationName.Sap && sap.isActive) {
      integrationExportTypes.push(
        ...(isSapConfigured ? exportTypes.sapWithB1 : exportTypes.sap)
      );
    }

    // Experimental export types
    if (experimentalSapCsvFF) {
      integrationExportTypes.push(ExportType.SAP_CSV);
    }

    if (experimentalCandisCsvFF) {
      integrationExportTypes.push(ExportType.CANDIS_CSV);
    }

    if (exportProvisionsWithDATEVFormatFF) {
      integrationExportTypes.push(ExportType.DATEV_CSV);
    }

    return [...new Set(integrationExportTypes)];
  }, [
    shouldUseCandisApi,
    integrationName,
    sap,
    isSapConfigured,
    bdsConnected,
    isDatevConnected,
    experimentalSapCsvFF,
    experimentalCandisCsvFF,
    exportProvisionsWithDATEVFormatFF,
  ]);

  const {
    setAvailableExportTypes,
    availableExportTypes,
    setAvailableTables,
    setAvailableViews,
    setAvailableEntities,
  } = state;

  useEffect(() => {
    if (availableExportTypes.toString() !== integrationExportTypes.toString()) {
      setAvailableExportTypes(integrationExportTypes);
    }
  }, [integrationExportTypes, availableExportTypes, setAvailableExportTypes]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (sap.isActive) {
      setAvailableTables(tables.sap);
      setAvailableViews(views.sap);
      setAvailableEntities(hideCreditCards ? entities.sap : entities.default);
    }
  }, [
    hideCreditCards,
    sap,
    setAvailableEntities,
    setAvailableExportTypes,
    setAvailableTables,
    setAvailableViews,
  ]);

  useEffect(() => {
    if (shouldUseCandisApi) {
      setAvailableTables(tables.candisApi);
      setAvailableViews(views.candisApi);
      setAvailableEntities(entities.candisApi);
    }
  }, [
    shouldUseCandisApi,
    setAvailableEntities,
    setAvailableTables,
    setAvailableViews,
  ]);

  return null;
};

export const useInitialExportUrl = () => {
  const { view, ...state } = useContext(ExportContext);
  const { bdsConnected, rdsConnected } = useDatev();
  const sap = useSap();
  const { data } = useGetSapB1Credentials();

  const isConfigured = Boolean(data?.sapB1Credentials?.companyDbId);

  let datevExportType = null;

  if (bdsConnected) datevExportType = ExportType.DATEV_BDS;
  if (rdsConnected) datevExportType = ExportType.DATEV;

  const viewDefDatev = useDefineExportView({
    ...state,
    sidebar: Sidebar.INIT_NEW_EXPORT,
    exportType: datevExportType,
  });

  const viewDefSap = useDefineExportView({
    ...state,
    sidebar: Sidebar.INIT_NEW_EXPORT,
    exportType: isConfigured ? ExportType.SAP_B1 : ExportType.SAP_CSV,
  });

  return sap.isActive ? viewDefSap : viewDefDatev;
};
