import {
  DocumentDateFilterInput,
  DocumentStatus,
  DownloadFilters,
  Maybe,
} from 'generated-types/graphql.types';
import i18next from 'i18next';
import moment from 'moment-timezone';
import { DateFormatters } from 'utils/date_formatter';

const DUPLICATE = 'common.duplicate';
const OVERDUE = 'common.overdue';
const ISPAYABLE = 'list.filters.isPayable.payable';
const IS_NOT_PAYABLE = 'list.filters.isPayable.notPayable';
const ISPAID = 'list.filters.isPaid.paid';
const IS_NOT_PAID = 'list.filters.isPaid.notPaid';
const HAS_TRANSACTION_LINKED =
  'list.filters.hasTransactionLinked.creditCardPayment';

const HAS_TRANSACTION_NOT_LINKED =
  'list.filters.hasTransactionLinked.noCreditCardPayment';

const mappedDateFilterOptionsToTranslations: {
  [index in keyof DocumentDateFilterInput]-?: string;
} = {
  invoiceDate: 'list.columns.invoiceDate',
  invoiceDueDate: 'list.columns.invoiceDueDate',
  invoiceDeliveryDate: 'list.columns.invoiceDeliveryDate',
  paidAt: 'list.columns.paidAt',
  createdAt: 'list.columns.createdAt',
  dueDateWithCashDiscount: 'list.columns.dueDateWithCashDiscount',
};

const mappedStatusesToTranslations = {
  [DocumentStatus.Processing]: 'statuses.processing',
  [DocumentStatus.New]: 'statuses.new',
  [DocumentStatus.Open]: 'statuses.open',
  [DocumentStatus.Rejected]: 'statuses.rejected',
  [DocumentStatus.Approved]: 'statuses.approved',
  [DocumentStatus.Exporting]: 'statuses.exporting',
  [DocumentStatus.Exported]: 'statuses.exported',
  [DocumentStatus.Archived]: 'statuses.archived',
};

const toReadableStatus = (t: i18next.TFunction) => (s: DocumentStatus) =>
  t(mappedStatusesToTranslations[s]);

export const getReadableFilterNames = (
  t: i18next.TFunction,
  filters?: DownloadFilters,
  query?: Maybe<string>
) => {
  const allFiltersApplied = [];

  if (filters?.statuses?.length) {
    allFiltersApplied.push(...filters.statuses.map(toReadableStatus(t)));
  }

  if (filters?.approvers?.length) {
    filters.approvers.map(approver => allFiltersApplied.push(approver.name));
  }

  if (filters?.contacts?.length) {
    filters.contacts.map(contact => allFiltersApplied.push(contact.name.value));
  }

  if (filters?.requesters?.length) {
    filters?.requesters.map(requester =>
      allFiltersApplied.push(requester.name)
    );
  }

  if (filters?.costCenters?.length) {
    filters.costCenters.map(costCenter =>
      allFiltersApplied.push(costCenter.readableName)
    );
  }

  if (filters?.artistSocialInsuranceCodes?.length) {
    filters.artistSocialInsuranceCodes.map(code =>
      allFiltersApplied.push(code)
    );
  }

  if (filters?.costObjects?.length) {
    filters.costObjects.map(costObject =>
      allFiltersApplied.push(costObject.readableName)
    );
  }

  if (filters?.extraCostInfos?.length) {
    filters.extraCostInfos.map(extraCostInfo =>
      allFiltersApplied.push(extraCostInfo.readableName)
    );
  }

  if (filters?.generalLedgerAccounts?.length) {
    filters.generalLedgerAccounts.map(generalLedgerAccount =>
      allFiltersApplied.push(generalLedgerAccount.readableName)
    );
  }

  if (filters?.isDuplicate) {
    allFiltersApplied.push(t(DUPLICATE));
  }

  if (filters?.isOverdue) {
    allFiltersApplied.push(t(OVERDUE));
  }

  if (filters?.isPayable === true) {
    allFiltersApplied.push(t(ISPAYABLE));
  } else if (filters?.isPayable === false) {
    allFiltersApplied.push(t(IS_NOT_PAYABLE));
  }

  if (filters?.isPaid) {
    allFiltersApplied.push(t(ISPAID));
  } else if (filters?.isPaid === false) {
    allFiltersApplied.push(t(IS_NOT_PAID));
  }

  if (filters?.hasTransactionLinked) {
    allFiltersApplied.push(t(HAS_TRANSACTION_LINKED));
  } else if (filters?.hasTransactionLinked === false) {
    allFiltersApplied.push(t(HAS_TRANSACTION_NOT_LINKED));
  }

  if (query) {
    allFiltersApplied.push(query);
  }

  return allFiltersApplied;
};

export const toReadableFilter = (
  dateFilterOption: string,
  dateFrom?: string,
  dateTo?: string
) => {
  if (!dateFrom || !dateTo) {
    return null;
  }

  return `${dateFilterOption}: ${dateFrom} - ${dateTo}`;
};

type DocumentDateFilterInputKeys = keyof DocumentDateFilterInput;

export const getReadableFiltersDateRanges = (
  t: i18next.TFunction,
  dateRangeFilters?: DocumentDateFilterInput | null
) => {
  if (!dateRangeFilters) {
    return [];
  }

  const allReadableFiltersDateRanges: string[] = [];
  const dateKeys: DocumentDateFilterInputKeys[] = [
    'invoiceDate',
    'invoiceDueDate',
    'invoiceDeliveryDate',
    'paidAt',
    'createdAt',
    'dueDateWithCashDiscount',
  ];

  dateKeys.forEach(dateKey => {
    const readableFilter = toReadableFilter(
      t(mappedDateFilterOptionsToTranslations[dateKey]),
      dateRangeFilters[dateKey]?.dateFrom,
      dateRangeFilters[dateKey]?.dateTo
    );

    if (readableFilter) {
      allReadableFiltersDateRanges.push(readableFilter);
    }
  });

  return allReadableFiltersDateRanges;
};

export const getFileAvailableUntilDate = (
  timezone: string,
  createdAt?: Maybe<string>
): string => {
  const fileAvailableUntil = moment(createdAt).add(1, 'days').toDate();

  return DateFormatters.compact(fileAvailableUntil, timezone);
};

const BYTES_IN_KILOBYTE = 1024;
const KILOBYTES_IN_MEGABYTE = 1024;

export const bytesToMB = (bytes: number): number => {
  const fileSizeInMB = bytes / (BYTES_IN_KILOBYTE * KILOBYTES_IN_MEGABYTE);

  return Math.round((fileSizeInMB + Number.EPSILON) * 100) / 100;
};
