import { ComponentType, ReactElement } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import useReactRouter from 'use-react-router';
import { RoutePath } from 'utils/path_generator';

type Props<T> = {
  paths: string[] | string;
  component?: ComponentType<any>;
  children?: (props: MatchParams<Partial<T>>) => ReactElement;
};

/** @deprecated Please use React Router’s built-in components/hooks instead */
export function Match<T extends object = any>({
  paths,
  component: WrappedComponent,
  children,
}: Props<T>) {
  const {
    location: { pathname },
  } = useReactRouter();

  const render = (matched: Partial<T> | null | undefined) =>
    WrappedComponent ? (
      <WrappedComponent matched={matched} />
    ) : children ? (
      children({ matched })
    ) : null;

  if (!Array.isArray(paths)) {
    const params = new RoutePath<T>(paths).match(pathname);

    return render(params);
  }

  const patterns = paths.map(path => new RoutePath<T>(path));

  const pattern = patterns.find(path => !!path.match(pathname));

  const match = pattern && pattern.match(pathname);

  return render(match);
}

export type MatchParams<T> = {
  matched: T | null | undefined;
};
