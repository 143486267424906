import { Text } from '@candisio/design-system';
import { KebabMenu } from 'components/KebabMenu/KebabMenu';
import { useExportBookingAccountsGenericMutation } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useOtherIntegration } from 'orgConfig/other';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { BOOKING_ACCOUNT_ROUTE_HASH } from 'views/Settings/BookingAccounts/consts';
import { useShowExtendAccountNumbersEntry } from 'views/utils/useShowExtendAccountNumbersEntry';
import { useSap } from '../../../orgConfig/sap';
import { MenuItem } from '../CostCenterKebabMenu/CostCenterKebabMenu';

export interface BookingAccountKebabMenuProps {
  organizationSlug: string;
}

export const BookingAccountKebabMenu = ({
  organizationSlug,
}: BookingAccountKebabMenuProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.BOOKING_ACCOUNTS);

  const showExtendAccountNumbersEntry = useShowExtendAccountNumbersEntry();
  const history = useHistory();
  const { isActiveIntegration: shouldUseSapGL } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();
  const [exportBookingAccounts] = useExportBookingAccountsGenericMutation();
  const showImportAction = !shouldUseSapGL && !shouldUseCoreDataApi;
  const showExportAction = !shouldUseSapGL;

  const items: MenuItem[] = [
    {
      id: 'import',
      label: t('contextMenu.import'),
      showMenuItem: showImportAction,
      onAction: () => {
        history.push({ hash: BOOKING_ACCOUNT_ROUTE_HASH.import });
      },
    },
    {
      id: 'history',
      label: t('contextMenu.history'),
      showSeparator: true,
      showMenuItem: !shouldUseSapGL,
      onAction: () => {
        history.push(
          `/${organizationSlug}${Routes.SETTINGS}${Routes.BOOKING_ACCOUNT_IMPORT_HISTORY}`
        );
      },
    },
    {
      id: 'export',
      label: t('contextMenu.exportGeneric'),
      showMenuItem: showExportAction,
      onAction: async () => {
        const response = await exportBookingAccounts();
        if (response.data?.exportBookingAccountsGeneric.url)
          window.open(response.data.exportBookingAccountsGeneric.url);
      },
    },
    {
      id: 'extend-account-numbers',
      label: t('booking-accounts:contextMenu.extendAccountNumbers.label'),
      renderItem: (item: MenuItem) => <Text>{item.label}</Text>,
      closeOnSelect: false,
      showMenuItem: showExtendAccountNumbersEntry,
    },
  ];

  const visibleItems = items.filter(item => item.showMenuItem !== false);

  if (visibleItems?.length === 0) {
    return null;
  }

  return <KebabMenu items={visibleItems} label={t('contextMenu.tooltip')} />;
};
