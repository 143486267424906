import { mergeRefs, usePopover } from '@candisio/design-system';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useIsElementVisible } from 'hooks/useIsElementVisible';
import { useEffect, useState } from 'react';
import { AriaPositionProps } from 'react-aria';

export const useWhatsNewPopover = ({
  activationLocalStorageKey,
  placement,
}: {
  activationLocalStorageKey: string;
  placement?: AriaPositionProps['placement'];
}) => {
  const [activationSeen, setActivationSeen] = useLocalStorage(
    activationLocalStorageKey,
    false
  );

  const [delayPassed, setDelayPassed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setDelayPassed(true), 1000);

    return () => clearTimeout(timer);
  }, []);

  const { ref, isIntersecting: isTriggerVisible } = useIsElementVisible();

  const shouldOpenActivationPopover =
    !activationSeen && isTriggerVisible && delayPassed;

  const {
    isOpen,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
    close,
    updatePosition,
  } = usePopover({
    placement: placement,
    updatePlacementOnTriggerMovement: true,
    isOpen: shouldOpenActivationPopover,
  });

  // onPointerDown and onClick from trigger interfere with current props
  const { onPointerDown, onClick, ...restProps } = triggerProps;

  const handleClose = () => {
    close();
    setActivationSeen(true);
  };

  return {
    shouldOpenActivationPopover,
    targetProps: restProps,
    targetRef: mergeRefs([triggerRef, ref]),
    isOpen,
    popoverProps,
    popoverRef,
    close: handleClose,
    updatePosition,
  };
};
