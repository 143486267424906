import gql from 'graphql-tag';

export const convertDocumentMutation = gql`
  mutation convertDocument($input: ConvertDocumentInput!) {
    convertDocument(input: $input) {
      globalDocumentId
      invoiceId
    }
  }
`;

export const countClassifyingDocumentsQuery = gql`
  query countClassifyingDocuments {
    countClassifyingDocuments {
      count
    }
  }
`;
