import {
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import moment from 'moment';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { DateFormatters } from 'utils/date_formatter';
import { getRemainingDays } from 'utils/getRemainingDays';
import { remainingDaysToColor } from 'utils/remainingDaysToColor';
import { EcmDocumentsTableData } from '../types';

type Value = Date | undefined;

/**
 * Moment takes care of calculating time relative to today and
 * uses i18n to show correct translation when user switches languages.
 *
 * https://momentjs.com/docs/#/displaying/from/
 */
const getRemainingTime = (dueDate: Date): string => {
  const today = moment().startOf('day');

  return moment(dueDate).from(today);
};

export const RelativeDateCell = ({
  value,
}: CellProps<EcmDocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { tooltipProps, tooltipRef, triggerProps, triggerRef, isOpen } =
    useTooltip({ placement: 'top' });

  if (!value) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  const remainingDays = getRemainingDays(value);
  // if remaining time is less than a day, show "Today"
  const remainingTime =
    remainingDays === 0 ? t('relativeDateCell.today') : getRemainingTime(value);

  return (
    <>
      <TruncatedText
        ref={triggerRef}
        {...triggerProps}
        color={remainingDaysToColor(remainingDays)}
        wordBreak="break-all"
      >
        {remainingTime}
      </TruncatedText>

      {isOpen && (
        <Tooltip ref={tooltipRef} {...tooltipProps}>
          {DateFormatters.compact(value)}
        </Tooltip>
      )}
    </>
  );
};
