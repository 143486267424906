import { MAX_FRACTION_DIGITS_FOR_CURRENCY } from 'utils/currency-fraction-digits';
import { roundNumber } from 'utils/roundNumber';

export function roundToCurrencyPrecision(
  amount: number,
  roundingPrecision?: number
): number {
  return roundNumber(
    amount,
    roundingPrecision ?? MAX_FRACTION_DIGITS_FOR_CURRENCY
  );
}
