import { Button, TruncatedText } from '@candisio/design-system';

export interface HeaderProps {
  backText: string;
  onBackClick: () => void;
}

export const Header = ({ backText, onBackClick }: HeaderProps) => {
  return (
    <Button
      justifySelf="start"
      icon="arrowLeft"
      iconPosition="left"
      size="small"
      variant="tertiary"
      onClick={onBackClick}
    >
      <TruncatedText>{backText}</TruncatedText>
    </Button>
  );
};
