import { Button, Flex, Grid, Link, Text } from '@candisio/design-system';
import reportsBackgroundDE from 'assets/insights/reports-background-DE.png';
import reportsBackgroundEN from 'assets/insights/reports-background-EN.png';
import { LottieAnimation, reportDE, reportEN } from 'components/Lottie/Lottie';
import { Locale } from 'generated-types/graphql.types';
import i18next from 'i18next';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { InitialStateProps } from '../types';

const dashboardEmptyStateMultiImageMap: Record<string, string> = {
  de: reportsBackgroundDE,
  en: reportsBackgroundEN,
};

const lottieAnimationMap: Record<string, any> = {
  de: reportDE,
  en: reportEN,
};

export const InitialState = ({ onAddWidget }: InitialStateProps) => {
  const [tDashboard] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  const user = useCurrentUser();
  const userLanguageSetting = user?.locale;
  const isEnglishLocale = userLanguageSetting === Locale.En;

  return (
    <Grid justifyContent="center" gap="space12">
      <Text as="h2" textAlign="center" fontSize="xlarge" fontWeight="regular">
        {tDashboard('insights.heading')}
      </Text>
      <Grid
        height="480px"
        width="420px"
        alignItems="start"
        background={`url(${
          dashboardEmptyStateMultiImageMap[i18next.language]
        }) center top / contain no-repeat`}
      >
        <Grid
          boxShadow="elevatedShadow4"
          borderRadius="medium"
          position="absolute"
          top={30}
          left={72}
          background="gray50"
        >
          <LottieAnimation
            options={{ animationData: lottieAnimationMap[i18next.language] }}
            height="100%"
            width="100%"
          />
        </Grid>

        <Flex
          gap={isEnglishLocale ? 'space40' : '3.5rem'}
          justifyContent="center"
          paddingTop="275px"
          direction="column"
          alignItems="center"
        >
          <Link
            href={tDashboard(
              'insights.widgets.initialState.helpCenterLink.url'
            )}
            external
          >
            {tDashboard('insights.widgets.initialState.helpCenterLink.text')}
          </Link>
          <Button
            icon="plus"
            onClick={onAddWidget}
            variant="secondary"
            size="small"
          >
            {tDashboard('insights.widgets.initialState.addCtaButton')}
          </Button>
        </Flex>
      </Grid>
    </Grid>
  );
};
