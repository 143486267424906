import { Grid, Heading } from '@candisio/design-system';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface ArchiveHeaderProps {
  children?: ReactNode;
}

export const ArchiveHeader = ({ children }: ArchiveHeaderProps) => {
  const [t] = useTranslation();

  return (
    <Grid
      autoFlow="column"
      alignItems="center"
      padding="space20 space32 space16 space32"
      gap="space24"
      templateColumns="auto 1fr"
    >
      <Heading as="h1">{t('archive.title')}</Heading>
      {children}
    </Grid>
  );
};
