import {
  ContactValidationErrorType,
  IntegrationName,
} from 'generated-types/graphql.types';
import { v4 as uuidV4 } from 'uuid';
import {
  detectCSVEncoding,
  parseCSVFile,
} from 'views/Contacts/ContactImport/parseCSVfile';
import {
  ContactCSVFileType,
  getContactTypeNumberFromCSV,
  getCreateTransferValue,
  getFileTypeFromHeaderRow,
  getPaymentConditionNumber,
  validateContactData,
  validatePaymentConditionNumber,
} from 'views/Contacts/ContactImport/parseUtils';
import {
  DEFAULT_CONTACT_TYPE_NUMBER,
  SPECIAL_CHARACTERS_TAX_NUMBER,
  SPECIAL_CHARACTERS_VAT_ID,
  SUPPORTED_CONTACT_TYPES_NUMBERS,
} from 'views/Contacts/consts';
import { stripSpecialCharactersFromString } from '../utils';
import { getPreviousContacts, toContactAccountsPayableNumber } from './consts';
import { SingleParsedContact } from './types';

export interface ParseContactCSVProps {
  file: File;
  integration: IntegrationName;
  bdsConnected: boolean;
  bdsBought: boolean;
  existingPaymentConditionNumbers?: {
    isArchived: boolean;
    paymentConditionNumber: number;
  }[];
  glaLength?: number;
}

export const parseContactCSV = async ({
  file,
  bdsConnected,
  bdsBought,
  integration,
  glaLength,
  existingPaymentConditionNumbers,
}: ParseContactCSVProps): Promise<SingleParsedContact[]> => {
  const encoding = await detectCSVEncoding(file);
  const { data } = await parseCSVFile(file, encoding);

  const parsedContacts = await parseContacts({
    data,
    integration,
    bdsConnected,
    bdsBought,
    existingPaymentConditionNumbers,
    glaLength,
  });

  return parsedContacts;
};

export const checkForDuplicate = (
  parsedContact: SingleParsedContact,
  index: number,
  allContacts: SingleParsedContact[]
): SingleParsedContact => {
  //TODO: Figure out if there's a need to check for duplicates of CompanyName, IndividualName etc.

  if (parsedContact.errors.length > 0) {
    return parsedContact;
  }

  const { accountsPayableNumber } = parsedContact.contactInputData;

  const previousContacts = getPreviousContacts(allContacts, index);

  const accountsPayableNumberExistsInFile = previousContacts
    .filter(c => c.errors.length === 0)
    .map(toContactAccountsPayableNumber)
    .includes(accountsPayableNumber);

  if (accountsPayableNumber && accountsPayableNumberExistsInFile) {
    parsedContact.errors.push(
      ContactValidationErrorType.DuplicateCreditorAccountNumber
    );
  }

  return parsedContact;
};

export interface SingleParseContactCoreProps {
  data: string[];
  bdsConnected: boolean;
  bdsBought: boolean;
}

export const singleParseContactDatev = ({
  data,
  bdsConnected,
  bdsBought,
}: SingleParseContactCoreProps): SingleParsedContact => {
  const companyName = data[1] || undefined; // todo store index accessors in constants
  const lastName = data[3] || undefined;
  const firstName = data[4] || undefined;
  const notSpecifiedLastName = data[5] || undefined;

  const contactTypeNumber = getContactTypeNumberFromCSV(
    data[6],
    SUPPORTED_CONTACT_TYPES_NUMBERS,
    DEFAULT_CONTACT_TYPE_NUMBER
  );

  const paymentConditionNumber = getPaymentConditionNumber(data[108]);

  const validPaymentMediumNumbers = ['0', '7', '8', '9'];
  // 0 = keine Angaben, es gilt die MPD-Schlüsselung
  // 7 = SEPA-/Auslandsüberweisung mit einer Rechnung
  // 8 = SEPA-/Auslandsüberweisung mit mehreren Rechnungen
  // 9 = keine Überweisungen, Scheck

  const paymentMediumNumber = validPaymentMediumNumbers.includes(data[135])
    ? Number(data[135])
    : undefined;

  const createTransfer = validPaymentMediumNumbers.includes(data[135])
    ? getCreateTransferValue(data[135], bdsConnected)
    : undefined;

  const shortName = data[7] || undefined;
  const vatIdMerged = `${data[8]}${data[9]}`;
  const vatId = vatIdMerged
    ? stripSpecialCharactersFromString(vatIdMerged, SPECIAL_CHARACTERS_VAT_ID)
    : undefined;

  const customerNumber = data[98] || undefined;

  const taxNumber = data[99]
    ? stripSpecialCharactersFromString(data[99], SPECIAL_CHARACTERS_TAX_NUMBER)
    : undefined;

  const phoneNumber = data[28] || undefined;
  const email = data[32] || undefined;

  const swiftCode = data[46] || undefined;
  const bankAccountNumber = data[42] || undefined;

  const street = data[15] || undefined;
  const postOfficeBox = data[16] || undefined;
  const postalCode = data[17] || undefined;
  const city = data[18] || undefined;
  const country = data[19] || undefined;

  return {
    errors: [],
    id: uuidV4(),
    contactInputData: {
      companyName: companyName,
      individualFirstName: firstName,
      individualLastName: lastName,
      notSpecifiedName: notSpecifiedLastName,
      shortName,
      contactTypeNumber: contactTypeNumber,
      iban: data[44] || undefined,
      taxNumber: taxNumber,
      accountsPayableNumber: data[0] ? data[0] : undefined,
      customerNumber: customerNumber,
      vatId: vatId,
      paymentConditionNumber: paymentConditionNumber,
      createTransfer: createTransfer,
      paymentMediumNumber: paymentMediumNumber,
      phoneNumber: phoneNumber,
      email: email,
      swiftCode: swiftCode,
      bankAccountNumber: bdsBought ? bankAccountNumber : undefined,
      street: street,
      postOfficeBox: postOfficeBox,
      postalCode: postalCode,
      city: city,
      countryISOCode: country,
    },
  };
};

export const singleParseContactCandisCustomTemplate = (
  data: string[],
  bdsBought: boolean
): SingleParsedContact => {
  const companyName = data[1] || undefined;

  const accountsPayableNumber = data[0] || undefined;

  const individualLastName = data[2] || undefined;
  const individualFirstName = data[3] || undefined;
  const notSpecifiedName = data[4] || undefined;
  const shortName = data[6] || undefined;

  const iban = data[7] || undefined;
  const taxNumber = data[8]
    ? stripSpecialCharactersFromString(data[8], SPECIAL_CHARACTERS_TAX_NUMBER)
    : undefined;

  const vatId = data[9]
    ? stripSpecialCharactersFromString(data[9], SPECIAL_CHARACTERS_VAT_ID)
    : undefined;

  const contactTypeNumber = getContactTypeNumberFromCSV(
    data[5],
    SUPPORTED_CONTACT_TYPES_NUMBERS,
    DEFAULT_CONTACT_TYPE_NUMBER
  );

  const paymentConditionNumber = getPaymentConditionNumber(data[10]);

  const phoneNumber = data[12] || undefined;
  const email = data[13] || undefined;
  const street = data[14] || undefined;
  const postOfficeBox = data[15] || undefined;
  const postalCode = data[16] || undefined;
  const city = data[17] || undefined;
  const country = data[18] || undefined;

  const swiftCode = data[19] || undefined;
  const bankAccountNumber = data[20] || undefined;

  const customerNumber = data[21] || undefined;

  return {
    errors: [],
    id: uuidV4(),
    contactInputData: {
      accountsPayableNumber,
      customerNumber,
      companyName,
      individualLastName,
      individualFirstName,
      notSpecifiedName,
      iban,
      taxNumber,
      vatId,
      contactTypeNumber,
      shortName,
      paymentConditionNumber,
      phoneNumber: phoneNumber,
      email: email,
      street: street,
      postOfficeBox: postOfficeBox,
      postalCode: postalCode,
      city: city,
      countryISOCode: country,
      swiftCode: swiftCode,
      bankAccountNumber: bdsBought ? bankAccountNumber : undefined,
    },
  };
};

const getFileType = (data: string[][]) => getFileTypeFromHeaderRow(data[0]);

export interface ParseContactsProps {
  data: string[][];
  integration: IntegrationName;
  bdsConnected: boolean;
  bdsBought: boolean;
  existingPaymentConditionNumbers?: {
    isArchived: boolean;
    paymentConditionNumber: number;
  }[];
  glaLength?: number;
}

export function parseContacts({
  data,
  bdsConnected,
  bdsBought,
  integration,
  glaLength,
  existingPaymentConditionNumbers,
}: ParseContactsProps): SingleParsedContact[] {
  const contactFileType = getFileType(data);

  switch (contactFileType) {
    case ContactCSVFileType.CORE_DATA_DTVF:
    case ContactCSVFileType.CORE_DATA_EXTF: {
      const [_greyRow, _descriptionRow, ...rawData] = data;

      return rawData
        .map(value => {
          return singleParseContactDatev({
            data: value,
            bdsConnected,
            bdsBought,
          });
        })
        .map(contactData =>
          validateContactData({
            singleParsedContact: contactData,
            integration,
            glaLength,
          })
        )
        .map(value => {
          return validatePaymentConditionNumber(
            value,
            existingPaymentConditionNumbers
          );
        })
        .map(checkForDuplicate);
    }

    case ContactCSVFileType.CANDIS_ACCOUNTS_PAYABLE_CUSTOM_TEMPLATE: {
      const [_greyRow, _descriptionRow, ...rawData] = data;

      return rawData
        .map(value => singleParseContactCandisCustomTemplate(value, bdsBought))
        .map(contactData =>
          validateContactData({
            singleParsedContact: contactData,
            integration,
            glaLength,
          })
        )
        .map(value => {
          return validatePaymentConditionNumber(
            value,
            existingPaymentConditionNumbers
          );
        })
        .map(checkForDuplicate);
    }

    // TODO educate user about what exactly is wrong
    case ContactCSVFileType.UNKNOWN_FILE_TYPE:
      return [];
    default:
      return [];
  }
}
