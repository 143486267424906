import { Link } from '@candisio/design-system';
import { TimelineEventWithMobileIndication } from 'components/DocumentHistory/history-components/TimelineEventWithMobileIndication';
import { isMobileClient } from 'components/DocumentHistory/history-components/utils';
import { FileUploadedEventDataFragment } from 'generated-types/graphql.types';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const FileImported = ({ file }: FileUploadedEventDataFragment) => {
  const [t] = useTranslation();

  return (
    <>
      {file && (
        <>
          {t('document.compact.history.fileUpload.imported')}
          &nbsp;
          <Link external href={file.url ?? ''} download={file.name}>
            {file.name}
          </Link>
        </>
      )}
    </>
  );
};

const FileUploaded = ({ file, clientInfo }: FileUploadedEventDataFragment) => {
  const fileName = file?.name ?? '';
  const fileUrl = file?.url ?? '';

  const isMobileUpload = isMobileClient(clientInfo?.name);

  if (isMobileUpload) {
    return (
      <TimelineEventWithMobileIndication
        iconName="mobileUpload"
        clientName={clientInfo?.name}
        eventComponent={
          <Link external href={fileUrl} download={fileName}>
            {fileName}
          </Link>
        }
        mobilePromoTranslation="common:document.compact.history.fileUpload.uploadedOn.mobile"
      />
    );
  } else {
    return (
      <Trans
        i18nKey="document.compact.history.fileUpload.uploadedOn.web"
        values={{ fileName }}
      >
        <Link external href={fileUrl} download={fileName}>
          {{ fileName } as any}
        </Link>{' '}
        uploaded
      </Trans>
    );
  }
};

export const FileUploadComponent = ({
  file,
  uploaderEmail,
  clientInfo,
}: FileUploadedEventDataFragment) => {
  return (
    <>
      {/* uploaderEmail is outdated here, kept for compatibility */}
      {uploaderEmail ? (
        <FileImported file={file} />
      ) : (
        <FileUploaded file={file} clientInfo={clientInfo} />
      )}
    </>
  );
};
