import {
  Box,
  Flex,
  Grid,
  Icon,
  IconKey,
  Link,
  Text,
  BoxProps,
  Tag,
} from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { motion } from 'framer-motion';
import { memo } from 'react';
import { useHover } from 'react-aria';
import { useTranslation } from 'react-i18next';

type UserMenuItemProps = {
  onClick: () => void;
  title: string;
  description?: string;
  routerLink?: { pathname?: string; search?: string; hash?: string };
  icon?: IconKey;
  backgroundColor?: BoxProps['background'];
  dataTestId?: string;
  newBadge?: boolean;
};

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
export const UserMenuItem = memo(
  ({
    onClick,
    title,
    description,
    routerLink,
    icon,
    backgroundColor,
    newBadge,
    dataTestId,
  }: UserMenuItemProps) => {
    const { hoverProps, isHovered } = useHover({});

    const [t] = useTranslation();

    const renderLink = () => (
      <Flex
        alignItems="center"
        gap="space12"
        justifyContent={newBadge ? 'space-between' : undefined}
        borderRadius="0.5rem"
        data-testid={dataTestId}
        padding={icon || description ? 'space12' : 'space8'}
        width="100%"
        background={
          isHovered ? (backgroundColor ? 'blue200' : 'bluebg') : backgroundColor
        }
        {...hoverProps}
      >
        {icon && (
          <MotionFlex
            animate={isHovered ? 'onMouseEnter' : 'onMouseLeave'}
            background="white"
            color={backgroundColor ? 'blue700' : 'gray800'}
            padding="space10"
            borderRadius="0.5rem"
            border="space2 solid gray100"
            justifyContent="center"
            alignItems="center"
          >
            <Icon icon={icon} size="space20" />
          </MotionFlex>
        )}
        <Flex direction="column" fontSize="small" textAlign="left" flexGrow={1}>
          <MotionBox
            animate={isHovered ? 'onMouseEnter' : 'onMouseLeave'}
            fontSize="basic"
            fontWeight="semibold"
            color={backgroundColor ? 'blue700' : 'gray800'}
          >
            {title}
          </MotionBox>
          {description && <Text color="gray500">{description}</Text>}
        </Flex>
        {newBadge && (
          <Flex alignItems="center">
            <Tag color="blue">{t('tag.new')}</Tag>
          </Flex>
        )}
      </Flex>
    );

    return (
      <Grid as="li" {...hoverProps}>
        {routerLink ? (
          <RouterLink onClick={onClick} to={routerLink}>
            {renderLink()}
          </RouterLink>
        ) : (
          <Link onClick={onClick}>{renderLink()}</Link>
        )}
      </Grid>
    );
  }
);
