import { Button, Modal, Tag } from '@candisio/design-system';
import { usePromotionDetailsModalContent } from 'components/NewPromotions/hooks/usePromotionDetailsModalContent';
import { PromotionDetailsModalContent } from 'components/NewPromotions/PromotionCard/PromotionDetailsModalContent/PromotionDetailsModalContent';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function DocumentPreviewCTA() {
  const [t] = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { showEcm } = useEcm();
  const promotionId = showEcm ? 'contractManagement' : 'documentManagement';
  const { promotionDetails } = usePromotionDetailsModalContent(promotionId);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Button
        width="fit-content"
        color="purple"
        size="medium"
        style={{ color: 'white' }}
        onClick={openModal}
      >
        {t('documentPreview.upSell.cta.text')}
      </Button>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        width="1000px"
        background="gray100"
        padding="0"
        titleInfo={
          <Tag color="purple" variant="secondary">
            {t('tag.addOn')}
          </Tag>
        }
        closeLabel={t('common.close')}
        style={{
          background: 'linear-gradient(90deg, #ffffff 60%, #f8f6f5 40%)',
        }}
      >
        <PromotionDetailsModalContent promotionDetails={promotionDetails} />
      </Modal>
    </>
  );
}
