import { Flex, Grid } from '@candisio/design-system';
import { useExportContext } from '../../Context';
import { useGetColumnIds } from '../../hooks/useGetColumnIds';
import { View } from '../../types';
import { ConfigurationsMenuContainer } from './ConfigurationsMenuContainer';
import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';

export const EmptyTableTabs = () => {
  const state = useExportContext();
  const { view, tableId } = state;
  const promotions = useReimbursementsPromotions();

  const isConfigurationMenuVisible =
    view !== View.HISTORY && promotions.variant === 'noPromotion';

  const columnIds = useGetColumnIds({
    tableId,
  });

  if (!isConfigurationMenuVisible) {
    return null;
  }

  return (
    <Flex paddingBottom="space8">
      <Grid
        borderBottom="1px solid gray300"
        width="100%"
        autoFlow="column"
        paddingY="3px"
        alignItems="center"
        justifyContent="right"
      >
        {/** The state inside this component needs to be reset each time we change a table
         *  hence we force the component to remount by adding a key
         */}
        <ConfigurationsMenuContainer
          key={`${tableId}-${columnIds.join('-')}`}
        />
      </Grid>
    </Flex>
  );
};
