import { Box, Card, Grid, ScrollBox, Separator } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { scrollToTarget } from 'views/CreditCards/utils/utils';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ReimbursementItemListLeftSection } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/Reimbursement';
import { AddReimbursementItemActionMenuButton } from './AddReimbursementItemActionMenuButton';
import { ReimbursementItem } from './ReimbursementItem';

interface ReimbursementItemsCardProps {
  creatingReimbursementItem: boolean;
  items: ReimbursementItemListLeftSection[];
  onHospitalityExpenseClick: () => void;
  onGeneralExpenseClick: () => void;
}

export const ReimbursementItemsCard = ({
  items,
  creatingReimbursementItem,
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
}: ReimbursementItemsCardProps) => {
  const { updateSearchParam } = useMutateSearchParams();
  const { reimbursementUIConfig } = useReimbursementFormsContext();

  const { canManageReimbursementItems } = reimbursementUIConfig;

  const handleClick = (id: string) => () => {
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
    scrollToTarget(id);
  };

  return (
    <Grid overflowY="auto" gap="space8">
      <ScrollBox scrollDirection="y" scrollbarGutter="stable" maxHeight="250px">
        <Grid as="ul" listStyle="none" padding={0} gap="space8">
          {items.map(item => {
            return (
              <Box as="li" listStyle="none" key={item.id} borderRadius="medium">
                <ReimbursementItem
                  reimbursementItem={item}
                  onClick={handleClick(item.id)}
                />
              </Box>
            );
          })}
        </Grid>
      </ScrollBox>
      {canManageReimbursementItems && (
        <Card
          background="gray50"
          padding={0}
          border="1px solid gray200"
          style={{ marginRight: '8px' }}
        >
          <AddReimbursementItemActionMenuButton
            creatingReimbursementItem={creatingReimbursementItem}
            onGeneralExpenseClick={onGeneralExpenseClick}
            onHospitalityExpenseClick={onHospitalityExpenseClick}
          />
        </Card>
      )}
    </Grid>
  );
};
