import {
  Card,
  Flex,
  Grid,
  Heading,
  Link,
  Paragraph,
  Switch,
} from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { useDocumentDataExtractionSettings } from 'views/Settings/DocumentDataExtractions/useDocumentDataExtractionSettings';

export const DocumentDataExtractionsSettingsCard = () => {
  const [t] = useTranslation();

  const {
    isOutgoingInvoiceExtractionEnabled,
    switchOutgoingInvoiceExtraction,
    loading,
  } = useDocumentDataExtractionSettings();

  return (
    <Grid gap="space16">
      <Heading as="h3">
        {t('settings.documentDataExtractions.field.documentType.title')}
      </Heading>
      <Grid>
        <Card paddingX="space24" paddingY="space16">
          <Flex placeContent="space-between">
            <Flex direction="column" gap="space16">
              <Flex gap="space8" alignItems="center">
                <Heading as="h3">
                  {t(
                    'settings.documentDataExtractions.field.documentType.description'
                  )}
                </Heading>
              </Flex>
              <Grid gap="space8">
                <Paragraph>
                  {t(
                    'settings.documentDataExtractions.field.documentType.detailedDescription'
                  )}{' '}
                  <Link
                    external
                    href={t(
                      'settings.documentDataExtractions.field.documentType.url'
                    )}
                  >
                    {t(
                      'settings.documentDataExtractions.field.documentType.helpCenterArticleLabel'
                    )}
                  </Link>
                </Paragraph>
              </Grid>
            </Flex>
            <Flex
              direction="column"
              placeContent="space-between"
              minHeight="space64"
              alignItems="end"
            >
              <Switch
                data-testid="document-direction-extractions-toggle"
                name="document-type-extractions"
                label="switch document type suggestions on/off"
                checked={isOutgoingInvoiceExtractionEnabled ?? false}
                disabled={loading}
                onChange={async isSelected => {
                  await switchOutgoingInvoiceExtraction(isSelected);
                }}
              />
            </Flex>
          </Flex>
        </Card>
      </Grid>
    </Grid>
  );
};
