import { FilterOptionsAccessor } from '@candisio/design-system';
import { mapToDocumentsTableData } from 'components/DocumentViewer/AttachmentsTable/utils';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { mapToFilterInput } from 'components/DocumentsTable/utils/mapToFilterInput';
import { mapToSortInput } from 'components/DocumentsTable/utils/mapToSortInput';
import { DocumentStatus, Query } from 'generated-types/graphql.types';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';
import { getApproximateNumberOfRowForTable } from 'views/utils/pagination-helper';
import { getInboxInvoiceDocumentsForAttachmentsQuery } from './gql';

export const useAttachmentsTableData = ({
  documentId,
  sort,
  filters,
}: {
  documentId: string;
  sort: SortingRule<DocumentsTableData>[];
  filters: Filters<DocumentsTableData>;
}) => {
  const [t] = useTranslation();
  const pageSize = getApproximateNumberOfRowForTable();
  const [tableData, setTableData] = useState<DocumentsTableData[]>([]);
  const [hasMore, setHasMore] = useState(false);

  const { loading: loadingDocuments, onLoadMore } = usePagination<
    Pick<Query, 'inboxInvoiceDocuments'>
  >(getInboxInvoiceDocumentsForAttachmentsQuery, 'inboxInvoiceDocuments', {
    variables: {
      limit: pageSize,
      sort: mapToSortInput(sort),
      filters: mapToFilterInput(filters),
    },

    onCompleted: ({ inboxInvoiceDocuments }) => {
      const documentEdges = inboxInvoiceDocuments.edges ?? [];
      setTableData(
        documentEdges
          .filter(edge => edge.node.id !== documentId) // display all documents except the original one
          .map(mapToDocumentsTableData)
      );

      const hasNextPage = inboxInvoiceDocuments.pageInfo?.hasNextPage ?? false;
      setHasMore(hasNextPage);
    },
  });

  const isTableEmpty = tableData.length === 0 && !loadingDocuments;

  const isTableFiltered = filters.length > 0;

  const filterOptions: FilterOptionsAccessor<DocumentsTableData> = useMemo(
    () => ({
      status: {
        data: [
          {
            id: DocumentStatus.New,
            label: t(`document.statuses.${DocumentStatus.New.toLowerCase()}`),
          },
          {
            id: DocumentStatus.Rejected,
            label: t(
              `document.statuses.${DocumentStatus.Rejected.toLowerCase()}`
            ),
          },
        ],
      },
      tags: true,
      createdAt: true,
    }),
    [t]
  );

  return {
    tableData,
    loadingDocuments,
    onLoadMore,
    setTableData,
    filterOptions,
    hasMore,
    isTableEmpty,
    isTableFiltered,
  };
};
