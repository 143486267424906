import { defaultTheme } from '@candisio/design-system';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { css, keyframes } from 'styled-components';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

type StripedProps = {
  completion?: number;
  opacity: string;
  static?: boolean;
  color: string;
};

const barBackgroundWidth = '1.875rem';
const progressBarAnimation = keyframes`
  0%{
    background-position: 0;
  }
  100%{
    background-position: -${barBackgroundWidth};
  }
`;

export const StripedBackground = styled.div<StripedProps>`
  border-radius: 0.5rem;
  grid-area: progress-content;
  background: repeating-linear-gradient(
    -45deg,
    ${p => p.color},
    ${p => p.color} 25%,
    transparent 25%,
    transparent 50%,
    ${p => p.color} 50%
  );
  opacity: ${p => p.opacity};
  background-size: ${barBackgroundWidth} ${barBackgroundWidth};
  grid-area: progress-content;
  ${p => p.completion && `width:${p.completion * 100}%`};
  transition: width 1s;
  ${({ static: statical }) =>
    !statical &&
    css`
      animation: ${progressBarAnimation} linear 1s infinite;
    `}
`;

export const ProgressBarContainer = styled.div`
  display: grid;
  grid-template:
    'progress-content' auto /
    auto;
  background: ${defaultTheme.colors.gray0};
`;

export const ProgressChildrenContainer = styled.div<{ padding?: string }>`
  grid-area: progress-content;
  padding: ${p => p.padding || '0.25rem'};
  text-align: center;
`;
