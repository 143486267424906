import {
  ContactRelationshipType,
  ExportableContact,
  ExportableContactStatus,
  useContactsForExportQuery,
  useCountExportableContactQuery,
} from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { useExportContext } from '../../Context';
import { View } from '../../types';
import { useExportDataGigaFactory } from './useExportDataGigaFactory';

export type ContactsTableData = {
  id: string;
  name: string;
  status: ExportableContactStatus;
};

// HACK: those variables are only used for generating stable pseudo-random data
const nameLengths = [4, 23, 8, 42, 15, 16] as const;
const namePattern = 'Lorem ipsum dolor sit amet, consetetur sad';

const toMaskedTableData = (_: unknown, i: number) => {
  const nameLength = nameLengths[i % nameLengths.length];

  return {
    id: i + '',
    name: namePattern.slice(0, nameLength),
    status: ExportableContactStatus.Updated,
    membershipId: i + '',
    relationshipType: ContactRelationshipType.Customer,
  };
};

const maxEntriesCap = 50;
const getMaskedData = (historyContactsCount = 0) => {
  const entitiesCount =
    historyContactsCount && Math.min(historyContactsCount, maxEntriesCap);

  return Array(entitiesCount).fill(0).map(toMaskedTableData);
};

const toContactsTableData = ({
  id,
  name,
  status,
  membershipId,
  relationshipType,
}: ExportableContact) => ({
  id,
  name,
  status,
  membershipId,
  relationshipType,
});

const useContacts = ({
  isHistoryView,
  historyContactsCount,
  skip,
  isAllShown,
}: {
  isHistoryView: boolean;
  historyContactsCount: number | undefined;
  skip: boolean;
  isAllShown: boolean;
}) => {
  const contactsQuery = useContactsForExportQuery({
    variables: { fetchAllRecords: isAllShown },
    // HACK: We receive all records with status "New"
    // value form cache overrides other statuses if not fetched from `network-only`
    fetchPolicy: 'network-only',
    skip,
  });

  const { contactsForExport } = contactsQuery.data ?? {};

  const contacts = isHistoryView
    ? getMaskedData(historyContactsCount)
    : (contactsForExport?.map(toContactsTableData) ?? []);

  return { contactsQuery, contacts };
};

const useContactsCount = ({
  isHistoryView,
  historyContactsCount,
  skip,
}: {
  isHistoryView: boolean;
  historyContactsCount: number | undefined;
  skip: boolean;
}) => {
  const countQuery = useCountExportableContactQuery({ skip });

  const { exportableContactCount, newAndUpdatedExportableContactCount } =
    countQuery.data?.evaluateCoreDataExport?.exportableCoreDataCount ?? {};

  const allContactsCount = exportableContactCount?.count;

  const newAndUpdatedContactsCount = isHistoryView
    ? historyContactsCount
    : newAndUpdatedExportableContactCount?.count;

  return {
    countQuery,
    allContactsCount: allContactsCount ?? 0,
    newAndUpdatedContactsCount: newAndUpdatedContactsCount ?? 0,
  };
};

export const useExportContactsFactory = () => {
  const { bdsBought, exportDatevBdsViaIntegrationSvcFF } = useDatev();
  const state = useExportContext();
  const { exportDetails } = useExportDataGigaFactory(state);

  const isAllShown = state.includeAllContacts;
  const isHistoryView = state.view === View.HISTORY;
  const historyContactsCount = exportDetails?.exportedContactsInfo?.count;
  const skip =
    !bdsBought || !exportDatevBdsViaIntegrationSvcFF || isHistoryView;

  const { contactsQuery, contacts } = useContacts({
    isHistoryView,
    historyContactsCount,
    skip,
    isAllShown,
  });

  const { countQuery, allContactsCount, newAndUpdatedContactsCount } =
    useContactsCount({ isHistoryView, historyContactsCount, skip });

  return {
    isAllShown,
    isMasked: isHistoryView,
    contacts: {
      refetch: contactsQuery.refetch,
      isLoading: contactsQuery.loading,
      data: contactsQuery.data,
      tableData: contacts,
    },
    count: {
      refetch: countQuery.refetch,
      isLoading: countQuery.loading,
      data: countQuery.data,
      all: allContactsCount,
      newAndUpdated: newAndUpdatedContactsCount,
    },
  };
};
