import moment from 'moment';

const YEARS_DIFFERENCE = 2;

interface GetYearsToConsiderReturn {
  startYear: number;
  endYear: number;
  years: Array<number>;
}

export const getYearsToConsider = ({
  currentDate,
}: { currentDate: Date }): GetYearsToConsiderReturn => {
  const comparisonDateMoment = moment(currentDate);

  const beginningOfNovember = moment(
    new Date(currentDate.getFullYear(), 10, 1)
  );

  const isNextYearVisible =
    comparisonDateMoment.isSameOrAfter(beginningOfNovember);

  const initialYear = isNextYearVisible
    ? beginningOfNovember.year() - 1
    : beginningOfNovember.year() - YEARS_DIFFERENCE;

  const finalYear = initialYear + YEARS_DIFFERENCE;

  const yearsToConsider = {
    startYear: initialYear,
    endYear: finalYear,
    years: [initialYear, initialYear + 1, initialYear + YEARS_DIFFERENCE],
  };

  return yearsToConsider;
};
