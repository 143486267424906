import {
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Link,
  Modal,
  Paragraph,
  Tag,
  Text,
  useTheme,
} from '@candisio/design-system';
import physicalCard from 'assets/credit-cards/candis-physical.svg';
import virtualCard from 'assets/credit-cards/candis-virtual.svg';
import virtual1xCard from 'assets/credit-cards/candis-virtual1x.svg';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface ForeignCurrencyFeesModalProps {
  isOpen: boolean;
  onClose: () => void;
  totalAmount: string;
  estimatedFeesSaving: string;
}

export const ForeignCurrencyFeesPromoModal = ({
  isOpen,
  onClose,
  totalAmount,
  estimatedFeesSaving,
}: ForeignCurrencyFeesModalProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Modal
      title={t('foreignCurrencyFees.modal.title')}
      isOpen={isOpen}
      onClose={onClose}
      closeLabel={t('modal.close')}
      isDismissable
      background="gray200"
      width="890px"
    >
      <Grid gap="space24" fontSize="basic">
        <Paragraph>
          <Trans
            i18nKey="credit-cards:foreignCurrencyFees.modal.potentialSavings"
            values={{
              fees: totalAmount,
              savings: estimatedFeesSaving,
            }}
          >
            Based on your invoice data, you have paid approximately{' '}
            <Text fontSize="xlarge" fontWeight="500">
              {{ fees: totalAmount } as any}
            </Text>{' '}
            in foreign currencies in the past 12 months. On average, you could
            save up to{' '}
            <Text fontSize="xlarge" fontWeight="500">
              {{ savings: estimatedFeesSaving } as any}
            </Text>{' '}
            * in foreign currency and remittance fees.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans i18nKey="credit-cards:foreignCurrencyFees.modal.didYouKnow.text">
            Did you know that you can pay with{' '}
            <Link href={t('foreignCurrencyFees.modal.didYouKnow.url')} external>
              Candis Visa Credit Cards
            </Link>{' '}
            anywhere in the world without foreign currency fees?
          </Trans>
        </Paragraph>

        <Grid gap="space24" columns={2}>
          <Flex
            background="gray0"
            borderRadius="medium"
            direction="column"
            fontWeight="500"
            gap="space16"
            padding="space16"
          >
            <Text fontSize="medium">
              {t('foreignCurrencyFees.modal.benefits.title')}
            </Text>

            <Grid
              gap="space8"
              alignItems="center"
              justifyContent="center"
              templateColumns="auto 1fr"
            >
              <Icon icon="check" color="blue500" size="space16" />
              <Text fontSize="basic" color="gray800">
                {t('foreignCurrencyFees.modal.benefits.noFees')}
              </Text>
            </Grid>

            <Grid
              gap="space8"
              alignItems="center"
              justifyContent="center"
              templateColumns="auto 1fr"
            >
              <Icon icon="check" color="blue500" size="space16" />
              <Text fontSize="basic" color="gray800">
                {t('foreignCurrencyFees.modal.benefits.rates')}
              </Text>
            </Grid>

            <Grid
              gap="space8"
              alignItems="center"
              justifyContent="center"
              templateColumns="auto 1fr"
            >
              <Icon icon="check" color="blue500" size="space16" />
              <Text fontSize="basic" color="gray800">
                {t('foreignCurrencyFees.modal.benefits.overview')}
              </Text>
            </Grid>
          </Flex>

          <Flex
            background="gray0"
            borderRadius="medium"
            direction="column"
            gap="space8"
            padding="space16"
          >
            <Text fontSize="medium" fontWeight="500">
              {t('foreignCurrencyFees.modal.whyCC.title')}
            </Text>

            <Flex>
              <Image alt="visa logo" src={virtualCard} />
              <Image alt="visa logo" src={virtual1xCard} />
              <Image alt="visa logo" src={physicalCard} />
            </Flex>

            <Link
              href={t('foreignCurrencyFees.modal.whyCC.blogLinkUrl')}
              external
            >
              {t('foreignCurrencyFees.modal.whyCC.blogLinkText')}
            </Link>
          </Flex>
        </Grid>

        <Grid
          background="purplebg"
          borderRadius="medium"
          gap="space8"
          padding="space16"
          templateColumns="1fr auto"
          alignItems="center"
        >
          <Flex direction="column" gap="space8">
            <Tag color="purple">{t('foreignCurrencyFees.promoTag')}</Tag>
            <Text>{t('foreignCurrencyFees.modal.bookDemo.text')}</Text>
          </Flex>

          <Button
            as="a"
            href={t('foreignCurrencyFees.modal.bookDemo.url')}
            target="_blank"
            rel="noopener noreferrer"
            color="purple"
          >
            {t('foreignCurrencyFees.modal.bookDemo.button')}
          </Button>
        </Grid>

        <Text color="gray500">
          {`* ${t('foreignCurrencyFees.modal.footnote')}`}
        </Text>
      </Grid>
    </Modal>
  );
};

export const ForeignCurrencyFeesModal = ({
  isOpen,
  onClose,
  totalAmount,
  estimatedFeesSaving,
}: ForeignCurrencyFeesModalProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { colors } = useTheme();

  return (
    <Modal
      title={t('foreignCurrencyFees.modal.title')}
      isOpen={isOpen}
      onClose={onClose}
      closeLabel={t('modal.close')}
      isDismissable
      background="gray200"
      width="890px"
    >
      <Grid gap="space24" fontSize="basic">
        <Paragraph>
          <Trans
            i18nKey="credit-cards:foreignCurrencyFees.modal.potentialSavings"
            values={{
              fees: totalAmount,
              savings: estimatedFeesSaving,
            }}
          >
            Based on your invoice data, you have paid approximately{' '}
            <Text fontSize="xlarge" fontWeight="500">
              {{ fees: totalAmount } as any}
            </Text>{' '}
            in foreign currencies in the past 12 months. On average, you could
            save up to{' '}
            <Text fontSize="xlarge" fontWeight="500">
              {{ savings: estimatedFeesSaving } as any}
            </Text>{' '}
            * in foreign currency and remittance fees.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans i18nKey="credit-cards:foreignCurrencyFees.modal.didYouKnow.text">
            Did you know that you can pay with{' '}
            <Link href={t('foreignCurrencyFees.modal.didYouKnow.url')} external>
              Candis Visa Credit Cards
            </Link>{' '}
            anywhere in the world without foreign currency fees?
          </Trans>
        </Paragraph>

        <Grid gap="space24" templateColumns="50%">
          <Flex
            background="gray0"
            borderRadius="medium"
            direction="column"
            gap="space8"
            padding="space16"
          >
            <Text fontSize="medium" fontWeight="500">
              {t('foreignCurrencyFees.modal.whyCC.title')}
            </Text>

            <Flex>
              <Image alt="visa logo" src={virtualCard} />
              <Image alt="visa logo" src={virtual1xCard} />
              <Image alt="visa logo" src={physicalCard} />
            </Flex>

            <Link
              href={t('foreignCurrencyFees.modal.whyCC.blogLinkUrl')}
              external
            >
              {t('foreignCurrencyFees.modal.whyCC.blogLinkText')}
            </Link>
          </Flex>
        </Grid>

        <Grid
          background="bluebg"
          borderRadius="medium"
          gap="space8"
          padding="space16"
          templateColumns="1fr auto"
          alignItems="center"
        >
          <Text>{t('foreignCurrencyFees.modal.bookDemo.text')}</Text>

          <Button
            as="a"
            href={t('foreignCurrencyFees.modal.bookDemo.url')}
            target="_blank"
            rel="noopener noreferrer"
            color="blue"
            style={{ color: colors.gray0 }}
          >
            {t('foreignCurrencyFees.modal.bookDemo.button')}
          </Button>
        </Grid>

        <Text color="gray500">
          {`* ${t('foreignCurrencyFees.modal.footnote')}`}
        </Text>
      </Grid>
    </Modal>
  );
};
