import React from 'react';
import { TokenOrCSSValue } from '../../types';
import { Box, BoxProps } from '../Box';
import { useMapTokensToCSS } from '../Box/useMapTokensToCSS';

const DEFAULT_ELEMENT = 'div';

export type GridProps<
  TElement extends React.ElementType = typeof DEFAULT_ELEMENT,
> = BoxProps<TElement> & {
  alignContent?: React.CSSProperties['alignContent'];
  alignItems?: React.CSSProperties['alignItems'];
  as?: keyof JSX.IntrinsicElements;
  autoColumns?: React.CSSProperties['gridAutoColumns'];
  autoFlow?: React.CSSProperties['gridAutoFlow'];
  autoRows?: React.CSSProperties['gridAutoRows'];
  children?: React.ReactNode;
  columnGap?: TokenOrCSSValue<'space', 'columnGap'>;
  columns?: number;
  gap?: TokenOrCSSValue<'space', 'gap'>;
  inline?: boolean;
  justifyContent?: React.CSSProperties['justifyContent'];
  justifyItems?: React.CSSProperties['justifyItems'];
  placeContent?: React.CSSProperties['placeContent'];
  placeItems?: React.CSSProperties['placeItems'];
  rowGap?: TokenOrCSSValue<'space', 'rowGap'>;
  rows?: number;
  template?: React.CSSProperties['gridTemplate'];
  templateAreas?: React.CSSProperties['gridTemplateAreas'];
  templateColumns?: TokenOrCSSValue<'space', 'gridTemplateColumns'>;
  templateRows?: TokenOrCSSValue<'space', 'gridTemplateRows'>;
};

type GridType = <TElement extends React.ElementType = typeof DEFAULT_ELEMENT>(
  props: GridProps<TElement>
) => React.ReactElement | null;

/**
 * Grid is a straightforward wrapper around the
 * [CSS Grid API]{@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout}.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-layout-grid}
 */
export const Grid = React.forwardRef(
  <TElement extends React.ElementType>(
    {
      alignContent,
      alignItems,
      autoColumns,
      autoFlow,
      autoRows,
      children,
      columnGap,
      columns,
      gap,
      inline = false,
      justifyContent,
      justifyItems,
      placeContent,
      placeItems,
      rowGap,
      rows,
      template,
      templateAreas,
      templateColumns,
      templateRows,
      ...restProps
    }: GridProps<TElement>,
    ref: typeof restProps.ref
  ) => {
    const mappedCSSProps = useMapTokensToCSS({
      columnGap,
      gap,
      rowGap,
      templateColumns,
      templateRows,
    });

    return (
      /** @ts-ignore `as` type mismatch after react upgrade */
      <Box
        as={DEFAULT_ELEMENT}
        {...restProps}
        css={{
          ...mappedCSSProps,
          alignContent,
          alignItems,
          display: inline ? 'inline-grid' : 'grid',
          gridAutoColumns: autoColumns,
          gridAutoFlow: autoFlow,
          gridAutoRows: autoRows,
          gridTemplate: template,
          gridTemplateAreas: templateAreas,
          gridTemplateColumns:
            mappedCSSProps.templateColumns ||
            (columns ? `repeat( ${columns}, 1fr )` : undefined),
          gridTemplateRows:
            mappedCSSProps.templateRows ||
            (rows ? `repeat( ${rows}, 1fr )` : undefined),
          justifyContent,
          justifyItems,
          placeContent,
          placeItems,
        }}
        ref={ref}
      >
        {children}
      </Box>
    );
  }
) as GridType;
