import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import { useSfsForApprovers } from 'components/Form/DocumentApprovalsForm/toolkit/hooks/use-sfs-for-approvers';
import { useCanFirstApproverSplitBookings } from 'components/Form/DocumentApprovalsForm/toolkit/hooks/useCanFirstApproverSplitBookings';
import { getBookingFieldInitialValue } from 'components/Form/DocumentApprovalsForm/toolkit/utils';
import {
  AcceptedValuesDS,
  IsFieldEditable,
  SplitBookingFields,
  TaxPresentation,
} from 'components/Form/SplitBookingsForm/types';
import { netToGross } from 'containers/SplitBookings/toolkit/utils';
import {
  GetDocumentForDraftQuery,
  GetDocumentQuery,
  PurchaseOrderType,
} from 'generated-types/graphql.types';
import { useGetBookingAssociation } from 'hooks/useGetBookingAssociation';
import { isNil } from 'lodash';
import { isDocWithMultipleBookings } from 'utils/document-with-bookings';
import { useSapPurchaseOrders } from 'views/DocumentDetails/components/ThreeWayMatch/hooks/useSapPurchaseOrders';

type UseSplitBookingsPropsProps = {
  document: NonNullable<
    GetDocumentForDraftQuery['getDocument'] | GetDocumentQuery['getDocument']
  >;
};
export const useSplitBookingsProps = ({
  document,
}: UseSplitBookingsPropsProps) => {
  const { smartFieldsValues } = useSfsForApprovers(document);
  const { purchaseOrders } = useSapPurchaseOrders(document.id);
  const canSplitBookings = useCanFirstApproverSplitBookings(document.id);

  const { isQuantityRequired, getBookingAssociationData } =
    useGetBookingAssociation(document.id);

  const hasSapPurchaseOrder = purchaseOrders?.length > 0;

  const isPrevBookingsMultipleSplits = isDocWithMultipleBookings(document);
  const isPrevBookingsWithSapPurchaseOrder =
    hasSapPurchaseOrder && !isNil(document.bookings);

  const hasPrevBookingsSplits =
    isPrevBookingsWithSapPurchaseOrder || isPrevBookingsMultipleSplits;

  const isFieldEditable: IsFieldEditable = (field, index) => {
    // We can only edit cost center/object fields from this form
    // if the requester did not already add one.
    // Note field can always be edited except shouldUsePurchaseOrder FF is enabled.
    // Amount, currency, due date and vat rate cannot be edited here.
    if (hasSapPurchaseOrder) {
      if (
        field === SplitBookingFields.generalLedgerAccountId ||
        field === SplitBookingFields.note
      ) {
        return false;
      }
    }

    switch (field) {
      case SplitBookingFields.amount:
        return canSplitBookings;
      case SplitBookingFields.vatRate:
        return canSplitBookings;
      case SplitBookingFields.dueDate:
        return canSplitBookings && isNil(document.bookings?.[0].dueDate?.value);
      case SplitBookingFields.costCenterId:
        return hasPrevBookingsSplits
          ? isNil(document.bookings?.[index]?.costCenter?.value.id)
          : isNil(document.bookings?.[0].costCenter?.value.id);
      case SplitBookingFields.costObjectId:
        return hasPrevBookingsSplits
          ? isNil(document.bookings?.[index]?.costObject?.value.id)
          : isNil(document.bookings?.[0].costObject?.value.id);
      case SplitBookingFields.artistSocialInsuranceCode:
        return hasPrevBookingsSplits
          ? isNil(document.bookings?.[index]?.artistSocialInsuranceCode)
          : isNil(document.bookings?.[0].artistSocialInsuranceCode);
      case SplitBookingFields.extraCostInfoId:
        return hasPrevBookingsSplits
          ? isNil(document.bookings?.[index]?.extraCostInfo?.value.id)
          : isNil(document.bookings?.[0].extraCostInfo?.value.id);
      case SplitBookingFields.bookingKeyId:
        const isBookingKeyAtIndexEmpty = isNil(
          document.bookings?.[index]?.bookingKey?.value.id
        );

        const isFirstBookingKeyEmpty = isNil(
          document.bookings?.[0]?.bookingKey?.value.id
        );

        return hasPrevBookingsSplits
          ? isBookingKeyAtIndexEmpty
          : isFirstBookingKeyEmpty;
      case SplitBookingFields.generalLedgerAccountId:
        return hasPrevBookingsSplits
          ? isNil(document.bookings?.[index]?.generalLedgerAccount?.value.id)
          : isNil(document.bookings?.[0].generalLedgerAccount?.value.id);
      case SplitBookingFields.quantity:
        return hasPrevBookingsSplits
          ? isNil(
              getBookingAssociationData(document.bookings?.[index]?.id).quantity
            )
          : isNil(
              getBookingAssociationData(document.bookings?.[0].id).quantity
            );
      case SplitBookingFields.note:
        return true;
      case SplitBookingFields.postingText:
        return true;
      case SplitBookingFields.taxAmount:
        return canSplitBookings;
      case SplitBookingFields.netAmount:
        return canSplitBookings;
      default:
        return false;
    }
  };

  const onAcceptBookings = (
    acceptedValues: AcceptedValuesDS
  ): DocumentApprovalFormValues['bookings'] => {
    return acceptedValues.bookings.map(b => ({
      ...b,
      bookingId: b.id,
      taxPresentation: b.taxPresentation || TaxPresentation.Gross,
      amount:
        (b.taxPresentation || TaxPresentation.Gross) === TaxPresentation.Gross
          ? // XXX fix types in SplitDS
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            b.amount!
          : netToGross(b.amount ?? 0, b.vatRate ?? 0),
      dueDate: b.dueDate ? new Date(b.dueDate) : null,
      quantity: b.quantity ?? undefined,
      generalLedgerAccount:
        b.generalLedgerAccount?.value && b.generalLedgerAccount?.inputValue
          ? {
              value: b.generalLedgerAccount?.value,
              inputValue: b.generalLedgerAccount?.inputValue,
            }
          : {
              value: null,
              inputValue: '',
            },
      costCenter:
        b.costCenter?.value && b.costCenter?.inputValue
          ? {
              value: b.costCenter?.value,
              inputValue: b.costCenter?.inputValue,
            }
          : {
              value: null,
              inputValue: '',
            },
      costObject:
        b.costObject?.value && b.costObject?.inputValue
          ? {
              value: b.costObject?.value,
              inputValue: b.costObject?.inputValue,
            }
          : {
              value: null,
              inputValue: '',
            },
      extraCostInfo:
        b.extraCostInfo?.value && b.extraCostInfo?.inputValue
          ? {
              value: b.extraCostInfo?.value,
              inputValue: b.extraCostInfo?.inputValue,
            }
          : {
              value: null,
              inputValue: '',
            },
    }));
  };

  const onDiscardBookings = (): DocumentApprovalFormValues['bookings'] => {
    if (!document.bookings || document.bookings?.length === 0) {
      return [];
    }

    const firstBooking = document.bookings[0];

    return [
      {
        amount: Number(firstBooking.amount?.value),
        bookingId: firstBooking.id,
        taxCode:
          firstBooking.bookingKey?.value.id ||
          smartFieldsValues?.bookingKey?.id,
        costCenter: getBookingFieldInitialValue(
          firstBooking,
          smartFieldsValues,
          'costCenter'
        ),
        costObject: getBookingFieldInitialValue(
          firstBooking,
          smartFieldsValues,
          'costObject'
        ),
        extraCostInfo: firstBooking.extraCostInfo
          ? {
              value: String(firstBooking.extraCostInfo.value.id),
              inputValue: firstBooking.extraCostInfo.value.readableName,
            }
          : {
              value: null,
              inputValue: '',
            },
        generalLedgerAccount: getBookingFieldInitialValue(
          firstBooking,
          smartFieldsValues,
          'generalLedgerAccount'
        ),
        note: firstBooking.note?.value,
        postingText: firstBooking.postingText ?? undefined,
        dueDate: firstBooking.dueDate
          ? new Date(firstBooking.dueDate.value)
          : undefined,
        vatRate: firstBooking.vatRate?.value,
        netAmount: firstBooking.netAmount,
        taxAmount: firstBooking.taxAmount,
        artistSocialInsuranceCode: firstBooking.artistSocialInsuranceCode,
        taxPresentation: TaxPresentation.Gross,
      },
    ];
  };

  return {
    isFieldEditable,
    onAcceptBookings,
    onDiscardBookings,
    purchaseOrderType: isQuantityRequired
      ? PurchaseOrderType.Goods
      : PurchaseOrderType.Service,
  };
};
