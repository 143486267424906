import {
  CustomEmptyStateProps,
  Flex,
  Grid,
  Link,
  LottieAnimation,
  Paragraph,
  Text,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface EmptyStateProps extends CustomEmptyStateProps {
  isTableFiltered: boolean;
  isUsingSearchQuery?: boolean;
  resetSearch: () => void;
}

const FilteredAndEmpty = ({ resetFilters }: { resetFilters: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  return (
    <>
      <Text textAlign="center" whiteSpace="pre-wrap">
        {t('goodsReceipts.emptyTable.isFilteredAndEmpty')}
      </Text>
      <Link as="button" onClick={() => resetFilters()}>
        {t('emptyState.resetFilter')}
      </Link>
    </>
  );
};

const SearchedAndEmpty = ({ resetSearch }: { resetSearch: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  return (
    <>
      <Text textAlign="center" whiteSpace="pre-wrap">
        {t('goodsReceipts.emptyTable.isSearchedAndEmpty')}
      </Text>
      <Link as="button" onClick={() => resetSearch()}>
        {t('emptyState.resetSearch')}
      </Link>
    </>
  );
};

const Empty = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  return (
    <Flex direction="column" alignItems="center" gap="space16">
      <Paragraph
        textAlign="center"
        width="14rem"
        minWidth="10rem"
        fontSize="basic"
        fontWeight="semibold"
        wordBreak="break-word"
      >
        {t('goodsReceipts.emptyTable.emptyArchive')}
      </Paragraph>

      <Paragraph textAlign="center" wordBreak="break-word" width="12rem">
        {t('goodsReceipts.emptyTable.emptyArchiveDescription')}
      </Paragraph>
    </Flex>
  );
};

export const GoodsReceiptsEmptyState = ({
  isTableFiltered,
  isUsingSearchQuery = false,
  resetFilters,
  resetSearch,
}: EmptyStateProps) => {
  return (
    <Grid
      color="gray800"
      paddingTop="6.5rem"
      paddingBottom="4.5rem"
      minHeight="100%"
      justifyItems="center"
      gap="space16"
      alignItems="center"
    >
      <LottieAnimation
        animationData={
          isTableFiltered || isUsingSearchQuery
            ? 'noresultsData'
            : 'successData'
        }
      />
      <Flex maxWidth="space256" alignItems="center" direction="column">
        {isTableFiltered && <FilteredAndEmpty resetFilters={resetFilters} />}
        {isUsingSearchQuery && <SearchedAndEmpty resetSearch={resetSearch} />}
        {!isTableFiltered && !isUsingSearchQuery && <Empty />}
      </Flex>
    </Grid>
  );
};
