import { Button, Flex, Text } from '@candisio/design-system';
import { Maybe } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import {
  COST_CENTER_CREATE_ROUTE,
  COST_CENTER_ROUTE_HASH,
} from 'views/Settings/CostCenters/consts';
import {
  HeadingDivider,
  TypeSelectionEmptyButtonGroup,
  TypeSelectionEmptyDetails,
  TypeSelectionTitle,
  WidgetTypeSelectionBox,
  Wrapper,
} from './styles';
import { SelectionProps, WIDGET_TYPE } from './types';

export const CostCenterSelection = ({
  hasCostCenters,
  hasCostObjects,
  image,
  setSelectedType,
}: SelectionProps & {
  hasCostCenters?: Maybe<boolean>;
  hasCostObjects?: Maybe<boolean>;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  const sap = useSap();
  const ctaKey = sap.isActive
    ? 'widgets.typeSelection.costCenter.cta_sap'
    : 'widgets.typeSelection.costCenter.cta';

  return (
    <WidgetTypeSelectionBox
      title={t('widgets.typeSelection.costCenter.title')}
      cta={
        <Trans t={t} i18nKey={ctaKey}>
          Create a report for one or more
          <Text fontWeight="semibold">Cost Centers</Text> or
          <Text fontWeight="semibold">Cost Objects</Text>.
        </Trans>
      }
      image={image}
    >
      {hasCostCenters || hasCostObjects ? (
        <Button
          variant="secondary"
          data-cy="createForCostCenters"
          onClick={() => setSelectedType(WIDGET_TYPE.COST_CENTER)}
        >
          {t('widgets.typeSelection.costCenter.costCenterButtonTitle')}
        </Button>
      ) : null}
    </WidgetTypeSelectionBox>
  );
};

export const CostCenterEmptySelection = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const orgId = useOrganizationId();
  const history = useHistory();
  const { isAdmin, isAccountant } = useUserRoles();
  const canCreateCostCenters = isAdmin || isAccountant;

  const links = {
    create: `/${orgId}${Routes.SETTINGS}${Routes.COST_CENTER}/${COST_CENTER_CREATE_ROUTE}`,
    import: `/${orgId}${Routes.SETTINGS}${Routes.COST_CENTER}${COST_CENTER_ROUTE_HASH.import}`,
  };

  const texts = {
    askAdmin: t(
      'widgets.typeSelection.costCenter.noCostCenterAvailable.ctaApprover'
    ),
    createCTA: t(
      'widgets.typeSelection.costCenter.noCostCenterAvailable.ctaAdmin.create'
    ),
    importCTA: t(
      'widgets.typeSelection.costCenter.noCostCenterAvailable.ctaAdmin.import'
    ),
  };

  const title = t(
    'widgets.typeSelection.costCenter.noCostCenterAvailable.title'
  );

  const details = t(
    'widgets.typeSelection.costCenter.noCostCenterAvailable.details'
  );

  return (
    <Wrapper>
      <Flex gap="space16" direction="column">
        <TypeSelectionTitle>{title}</TypeSelectionTitle>
        <HeadingDivider />
        <TypeSelectionEmptyDetails>{details}</TypeSelectionEmptyDetails>
      </Flex>

      <TypeSelectionEmptyButtonGroup
        onCreate={() => history.push(links.create)}
        onImport={() => history.push(links.import)}
        canCreate={canCreateCostCenters}
        text={texts}
      />
    </Wrapper>
  );
};
