import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Icon,
  Link,
  Paragraph,
  Text,
} from '@candisio/design-system';
import { useSapB1SettingsQuery } from 'generated-types/graphql.types';
import { useGetSapB1Credentials } from 'hooks/useGetSAPB1Credentials';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { exportCardId, scrollToTarget } from 'views/CreditCards/utils/utils';
import { CheckList } from './CheckList/CheckList';
import { CreditCardExportingFormSap } from './CreditCardExportingFormSap/CreditCardExportingFormSap';
import { StateKey } from './states';

type AvailablityObject = {
  isAvailable: boolean;
  stateKeySuccess: string;
  stateKeyError: string;
};

export const CreditCardExportingCardSap = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { data, loading } = useGetSapB1Credentials();
  const { data: sapB1Settings, loading: settingsLoading } =
    useSapB1SettingsQuery();

  const organizationSlug = useOrganizationId();
  const history = useHistory();

  const { hash } = history?.location;

  const [checks, setChecks] = useState<string[] | undefined>([
    StateKey.checkingService,
  ]);

  const [showForm, toggleShowForm] = useState(false);

  const isCCIdAvailable = !!sapB1Settings?.sapB1Settings?.creditCardId;
  const isCCGLAAvailable =
    !!sapB1Settings?.sapB1Settings?.creditCardGeneralLedgerAccount;

  const isMoneyTransitAvailable =
    !!sapB1Settings?.sapB1Settings?.moneyInTransitGeneralLedgerAccount;

  const areCreditCardAccountsPresent =
    isCCIdAvailable && isCCGLAAvailable && isMoneyTransitAvailable;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (loading || settingsLoading) {
      return;
    }

    if (data?.sapB1Credentials) {
      // client is connected
      toggleShowForm(true);

      if (areCreditCardAccountsPresent) {
        // all fields are present
        setChecks([StateKey.serviceConnected]);
      } else {
        const availability: AvailablityObject[] = [
          {
            isAvailable: isCCIdAvailable,
            stateKeySuccess: StateKey.isCreditCardIdSetSuccess,
            stateKeyError: StateKey.isCreditCardIdSetError,
          },
          {
            isAvailable: isCCGLAAvailable,
            stateKeySuccess: StateKey.isGeneralLedgerAccountConnectedSuccess,
            stateKeyError: StateKey.isGeneralLedgerAccountConnectedError,
          },
          {
            isAvailable: isMoneyTransitAvailable,
            stateKeySuccess: StateKey.isMoneyTransitSetSuccess,
            stateKeyError: StateKey.isMoneyTransitSetError,
          },
        ];

        if (!availability.find(a => a.isAvailable)) {
          // all fields are missing
          setChecks([StateKey.isCreditCardSetError]);
        } else {
          // Some fields are missing
          const addChecks = availability.map(a =>
            a.isAvailable ? a.stateKeySuccess : a.stateKeyError
          );

          setChecks(addChecks);
        }
      }
    } else {
      // client is not connected
      setChecks([StateKey.isClientConnectedError]);
    }
  }, [data, loading, sapB1Settings, settingsLoading]);

  useEffect(() => {
    if (hash !== '') {
      scrollToTarget(hash);
    }
  }, [hash]);

  let formElement = null;
  let creditCardHelperElement = null;
  if (!loading && !settingsLoading) {
    if (showForm) {
      formElement = <CreditCardExportingFormSap />;
    } else {
      formElement = (
        <Button
          width="max-content"
          size="small"
          variant="secondary"
          onClick={() =>
            history.push(`/${organizationSlug}${Routes.SETTINGS}${Routes.SAP}`)
          }
        >
          {t('creditCardExportingSap.accountNumbers.crossLink')}
        </Button>
      );
    }

    if (!areCreditCardAccountsPresent) {
      creditCardHelperElement = (
        <Grid
          autoFlow="column"
          background="redbg"
          gap="space4"
          paddingY="space12"
          paddingX="space16"
          borderRadius="medium"
        >
          <Icon icon="infoCircle" color="red500" size="space16" />
          <Text color="red500" fontSize="small">
            {t('creditCardExportingSap.missingAccountNumber')}
          </Text>
        </Grid>
      );
    }
  }

  return (
    <Card
      id={exportCardId}
      padding="space16"
      borderRadius="medium"
      alignSelf="start"
    >
      <Grid gap="space14" paddingBottom="space12">
        {creditCardHelperElement}
        <Heading as="h3">{t('creditCardExportingSap.title')}</Heading>
        <Paragraph>{t('creditCardExportingSap.description')}</Paragraph>
        <Flex>
          <Link external href={t('creditCardExportingSap.linkUrl')}>
            {t('creditCardExportingSap.linkText')}
          </Link>
        </Flex>
        {checks && <CheckList checks={checks} />}
        {formElement}
      </Grid>
    </Card>
  );
};
