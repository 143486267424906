import { Flex, Tag as TagDS, defaultTheme } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ComponentProps, ReactNode, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

export const FallbackStatusTag = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <TagDS color="gray" variant="secondary">
      {t('documentRelationship.fallback.status')}
    </TagDS>
  );
};

type DocumentInfoTagProps = ComponentProps<typeof Flex> & {
  children: ReactNode;
  color?: Extract<keyof typeof defaultTheme.colors, `${string}bg` | 'gray200'>;
  textColor?: keyof typeof defaultTheme.colors;
};

export const DocumentInfoTag = forwardRef<HTMLElement, DocumentInfoTagProps>(
  ({ children, color = 'gray200', textColor = 'gray800', ...rest }, ref) => (
    <Flex
      ref={ref}
      height="max-content"
      width="fit-content"
      fontWeight="semibold"
      paddingX="space12"
      paddingY="space4"
      borderRadius="small"
      textTransform="capitalize"
      color={textColor}
      background={color}
      {...rest}
    >
      {children}
    </Flex>
  )
);
