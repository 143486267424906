import { Button, Grid } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { ActionBar } from '../../../../../components/Sidebar/ActionBar';
import { Sidebar } from '../../../../../components/Sidebar/Sidebar';
import { ExportStatus } from '../../../../../generated-types/graphql.types';
import { DateFormatters } from '../../../../../utils/date_formatter';
import { ExportDetails, ExportType } from '../../types';
import { ExportHistorySummary } from '../containers/ExportableEntitiesSummary/History';
import {
  ZipIconImage,
  DatevDxsoZipImage,
  AddisonImage,
  DatevCsvImage,
  SapCsvImage,
  CandisCsvImage,
} from './common';
import { ExportInfo, ExportInfoLabel } from './ExportInfo';
import { ExportProtocol } from './ExportProtocol';

export interface FileExportDetailsProps {
  onExportAgain: () => void;
  onDownloadArchive: () => void;
  exportDetails: ExportDetails;
}

export const FileExportDetails = ({
  exportDetails,
  onExportAgain,
  onDownloadArchive,
}: FileExportDetailsProps) => {
  const [t] = useTranslation();

  const exportTypeInfo = () => {
    switch (exportDetails?.type) {
      case ExportType.ZIP:
        return (
          <>
            <ZipIconImage />
            <ExportInfoLabel>
              {t('export.exportForm.exportZip.info')}
            </ExportInfoLabel>
          </>
        );
      case ExportType.DATEV_DXSO_ZIP:
        return (
          <>
            <DatevDxsoZipImage />
            <ExportInfoLabel>
              {t('export.exportForm.exportDatevDxsoZip.info')}
            </ExportInfoLabel>
          </>
        );
      case ExportType.ADDISON_FORMAT:
        return (
          <>
            <AddisonImage />
            <ExportInfoLabel>
              {t('export.exportForm.exportAddisonZip.info')}
            </ExportInfoLabel>
          </>
        );
      case ExportType.DATEV_CSV:
        return (
          <>
            <DatevCsvImage />
            <ExportInfoLabel>
              {t('export.exportForm.exportDatevFormat.info')}
            </ExportInfoLabel>
          </>
        );
      case ExportType.SAP_CSV:
        return (
          <>
            <SapCsvImage />
            <ExportInfoLabel>
              {t('export.exportForm.exportSapCsv.info')}
            </ExportInfoLabel>
          </>
        );
      case ExportType.CANDIS_CSV:
        return (
          <>
            <CandisCsvImage />
            <ExportInfoLabel>
              {t('export.exportForm.exportCandisCsv.info')}
            </ExportInfoLabel>
          </>
        );
    }

    return null;
  };

  const hasAnythingToReExport =
    exportDetails.documents.length > 0 ||
    exportDetails.transactions.length > 0 ||
    exportDetails.cardSettlements.length > 0 ||
    exportDetails.provisions.length > 0 ||
    exportDetails.reversals.length > 0 ||
    exportDetails.reimbursementItems.length > 0;

  return (
    <Sidebar
      heading={t('export.exportForm.exportZip.title', {
        date: DateFormatters.compact(exportDetails.createdAt),
      })}
    >
      <Grid
        alignContent="baseline"
        templateRows="auto auto auto 1fr"
        height="100%"
      >
        <ExportInfo>{exportTypeInfo()}</ExportInfo>
        <ExportHistorySummary />
        <ExportProtocol exportDetails={exportDetails} />

        <ActionBar>
          <Button
            onClick={onDownloadArchive}
            disabled={exportDetails.status === ExportStatus.Failed}
            loading={exportDetails.status === ExportStatus.Exporting}
            data-cy="submit"
          >
            {t('export.exportForm.exportZip.download')}
          </Button>
          {hasAnythingToReExport && (
            <Button variant="secondary" onClick={onExportAgain}>
              {t('export.exportForm.exportZip.exportAgain')}
            </Button>
          )}
        </ActionBar>
      </Grid>
    </Sidebar>
  );
};
