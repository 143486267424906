import { Box } from '@candisio/design-system';
import { HistoryLayout } from 'components/History/styles';
import { ActivityUser } from 'generated-types/graphql.types';
import moment from 'moment';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { History } from '../History/History';
import { activityTypeToHistoryComponent } from './common';
import {
  isDocumentCommentEvent,
  isDocumentPaymentInfoUpdatedEvent,
  isDocumentSepaTransferGeneratedEvent,
  isFileUploadedEvent,
  isUserEvent,
  SupportedEvent,
} from './types';

/**
 *  Depending on the document event type determines the corresponding author
 */
const useGetAuthor = () => {
  const [t] = useTranslation();

  const getAuthor = (
    event: SupportedEvent,
    user?: ActivityUser | null
  ): string => {
    if (isFileUploadedEvent(event) && event.uploaderEmail?.[0]) {
      return event.uploaderEmail[0];
    }

    if (user?.name) {
      return user.name;
    }

    if (isDocumentSepaTransferGeneratedEvent(event)) {
      return event.generatedBy.name;
    }

    if (isDocumentPaymentInfoUpdatedEvent(event)) {
      return event.updatedBy.name;
    }

    return t('document.compact.history.shared.systemUser');
  };

  return { getAuthor };
};

/**
 * Depending on the document event type gets the avatar
 */
const getAvatar = (
  event: SupportedEvent,
  user?: ActivityUser | null
): string | undefined => {
  if (user?.avatarUrl) {
    return user.avatarUrl;
  }

  if (isDocumentCommentEvent(event) && event.comment?.author?.avatarUrl) {
    return event.comment.author.avatarUrl;
  }

  if (isDocumentPaymentInfoUpdatedEvent(event) && event.updatedBy.avatarUrl) {
    return event.updatedBy.avatarUrl;
  }

  return undefined;
};

type DocumentHistoryProps = {
  events: SupportedEvent[];
  style?: CSSProperties;
};

export const DocumentHistory = ({ events, ...rest }: DocumentHistoryProps) => {
  const { getAuthor } = useGetAuthor();
  if (events.length === 0) {
    return null;
  }

  return (
    <HistoryLayout {...rest}>
      <Box paddingX={4}>
        <History>
          {events.map((event, idx) => {
            const typename = event.__typename;
            if (!typename) {
              return null;
            }

            const user = isUserEvent(event) ? event.user : undefined;
            const author = getAuthor(event, user);
            const avatarUrl = getAvatar(event, user);

            const {
              item: HistoryItem,
              content: HistoryContent,
              source,
              collapsible,
              skip,
            } = activityTypeToHistoryComponent[typename];

            const isCollapsible =
              typeof collapsible === 'function'
                ? collapsible(event)
                : collapsible;

            const createdAt = moment(event.createdAt);

            const shouldSkipEvent = skip && skip(event);
            if (shouldSkipEvent) return null;

            return (
              <HistoryItem
                size="big"
                source={source}
                type={typename}
                key={event.id || idx}
                author={author}
                avatarUrl={avatarUrl}
                absoluteTime={createdAt.format('LLL')}
                header={createdAt.fromNow()}
                isCollapsible={isCollapsible}
                defaultCollapse={false}
                hideTail={idx === events.length - 1}
              >
                <HistoryContent {...(event as any)} />
              </HistoryItem>
            );
          })}
        </History>
      </Box>
    </HistoryLayout>
  );
};
