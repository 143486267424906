import { FieldContainer, Grid, Input, Text } from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { useUserRoles } from 'hooks/useUserRoles';
import { DebouncedFunc, isNil } from 'lodash';
import { Routes } from 'models';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useFocusWithin } from 'react-aria';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useSetEmployeesAccountsPayableNumber } from 'views/Settings/SuggestionsAndExtractions/components/EmployeesAPNSettings/useSetEmployeesAccountsPayableNumber';
import { AccountsPayableNumberActions } from './AccountsPayableNumberActions';

export interface AccountsPayableNumberFieldProps<
  TFormValues extends FieldValues,
> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Is field disabled? */
  disabled?: boolean;
  /** Field label */
  label?: string;
  /** Placeholder text shown when no value is set */
  placeholder?: string;
  /** Is field read only? */
  readOnly?: boolean;
  /** If parent form is creating new contact */
  isCreatingNewContact?: boolean;
  /** Invoked when leaving the field or after clicking the suggest next account number button */
  onSaveAccountPayableNumber?: DebouncedFunc<
    (accountsPayableNumber: string | null | undefined) => void
  >;
  layout?: 'compact' | 'regular';
}

/**
 * Accounts payable number field for React Hook Form. Displays a suggested value
 * that the user can accept or discard.
 */
export const AccountsPayableNumberField = <TFormValues extends FieldValues>({
  control,
  disabled,
  label,
  name,
  placeholder,
  readOnly: readOnlyProp,
  onSaveAccountPayableNumber,
  layout = 'compact',
}: AccountsPayableNumberFieldProps<TFormValues>) => {
  const { organizationSlug } = useParams<{ organizationSlug?: string }>();
  const [t] = useTranslation(LOCALE_NAME_SPACE.CONTACTS);
  const { canUseEmployeeAPNSettings } = useReimbursement();
  const { isAccountant, isAdmin } = useUserRoles();

  const { field, fieldState, formState } = useController({ control, name });
  const { friendlyFormatAccountsNumber, removeSpacesConditionally } =
    useAccountingNumberFormatters();

  const { onChange, value, ref, onBlur, ...fieldProps } = field;

  const [suggestedNumber, setSuggestedNumber] = useState<string | null>(null);

  const [friendlyValue, setFriendlyValue] = useState(
    value ? friendlyFormatAccountsNumber(value) : ''
  );

  const { enabled } = useSetEmployeesAccountsPayableNumber();

  const [isFocused, setIsFocused] = useState(false);
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: setIsFocused,
  });

  if (isNil(value) && friendlyValue.trim() !== '') {
    setFriendlyValue('');
  } else if (
    friendlyValue !== undefined &&
    value !== undefined &&
    typeof value === 'string' &&
    friendlyValue.replaceAll(' ', '') !== (value as string).replaceAll(' ', '')
  ) {
    setFriendlyValue(friendlyFormatAccountsNumber(value ?? ''));
  }

  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;

  const readOnly = readOnlyProp || formState.isSubmitting;

  const usingSuggestedNumber =
    !isNil(suggestedNumber) && !isNil(value) && suggestedNumber === value;

  const suggestNextFreeNumberToExistingMember =
    isNil(value) && !fieldState.isDirty && !fieldState.isTouched && isFocused;

  const successMessage = (
    <Grid fontSize="small">
      <Text fontWeight="bold">
        {t('form.accountsPayableNumber.findNextAccountNumber.fieldInfo.title')}
      </Text>
      <Text>
        {t(
          'form.accountsPayableNumber.findNextAccountNumber.fieldInfo.source'
        )}{' '}
      </Text>
    </Grid>
  );

  const canSeeConfigureButton =
    canUseEmployeeAPNSettings && (isAccountant || isAdmin);

  const fieldVariant = () => {
    if (hasError) {
      return 'error';
    }

    if (usingSuggestedNumber) {
      return 'success';
    }

    return undefined;
  };

  const fieldMessage = () => {
    if (hasError) {
      return errorMessage;
    }

    if (usingSuggestedNumber) {
      return successMessage;
    }

    return undefined;
  };

  return (
    <Grid
      autoFlow="column"
      templateColumns={
        layout === 'compact'
          ? '2fr 1fr'
          : enabled && !readOnly
            ? '1fr auto'
            : '1fr'
      }
      gap="space16"
      justifyContent="start"
      alignItems="center"
    >
      <FieldContainer
        disabled={disabled}
        aria-label={label}
        label={
          <Grid autoFlow="column" justifyContent="space-between" gap="space4">
            <Text as="span">{label}</Text>
            {canSeeConfigureButton && !readOnly && (
              <RouterLink
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.SMART_FIELD_SUGGESTIONS}`}
                style={{
                  pointerEvents: 'all',
                }}
              >
                {t(
                  'form.accountsPayableNumber.findNextAccountNumber.configureButton'
                )}
              </RouterLink>
            )}
          </Grid>
        }
        message={fieldMessage()}
        readOnly={readOnly}
        variant={fieldVariant()}
        {...focusWithinProps}
      >
        <Input
          message={fieldMessage()}
          variant={fieldVariant()}
          disabled={disabled}
          value={friendlyValue}
          onChange={e => {
            const result = e.target.value !== '' ? e.target.value : null;

            setFriendlyValue(result ?? '');
            onChange(result && removeSpacesConditionally(result));
          }}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref}
          onBlur={e => {
            onSaveAccountPayableNumber?.(e.target.value ?? null);
            onBlur();
            setFriendlyValue(
              friendlyFormatAccountsNumber(e.target.value) ?? null
            );
          }}
          {...fieldProps}
          showMessageOnFocus={hasError}
        />
      </FieldContainer>
      {enabled && !readOnly && (
        <AccountsPayableNumberActions
          setCurrentNumber={input => {
            onSaveAccountPayableNumber?.(input);
            setSuggestedNumber(input);
            setFriendlyValue(input);
            onChange(input);
          }}
          currentNumber={friendlyValue}
          disabled={disabled}
          findNextNumberOnRender={suggestNextFreeNumberToExistingMember}
        />
      )}
    </Grid>
  );
};
