import { Button, Flex, Grid, Skeleton, useId } from '@candisio/design-system';
import {
  ChartOfAccountCode,
  useDisconnectDatevClientMutation,
  useNew_DatevSettingsQuery,
  useOrganizationQuery,
  useUpdateDatevChartOfAccountMutation,
  useUpdateExportConfigurationNewMutation,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useDatev } from 'orgConfig/datev';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import {
  getBookingAccountValidation,
  refetchDatevDataWithNoToken,
} from '../../../gql';
import { DcsContainer } from '../../DcsContainer/DcsContainer';
import { AccountingCoreDataContainer } from './AccountingCoreDataContainer';
import { AccountingCoreDataFormOutput } from './AccountingCoreDataSchema';

export const AccountingCoreDataCheck = ({
  onSubmit,
}: {
  onSubmit: (value: boolean) => void;
}) => {
  const [t] = useTranslation();
  const fullOrganization = useFullOrganization();

  const { data: organizationData, loading: loadingOrganizationData } =
    useOrganizationQuery({
      fetchPolicy: 'network-only',
    });

  const { data: settingsData, loading: loadingSettingsData } =
    useNew_DatevSettingsQuery({
      fetchPolicy: 'network-only',
    });

  const getInitialValues = () => {
    return {
      chartOfAccountCode:
        settingsData?.new_datevSettings?.chartOfAccount?.code?.toString() ??
        null,
      accountLength:
        settingsData?.new_datevSettings?.chartOfAccount?.accountLength?.toString() ??
        null,
      fiscalYearStart: {
        day:
          fullOrganization?.exportConfiguration?.fiscalYearStartDay?.toString() ??
          organizationData?.organization?.exportConfiguration?.fiscalYearStartDay?.toString() ??
          null,

        month:
          fullOrganization?.exportConfiguration?.fiscalYearStartMonth?.toString() ??
          organizationData?.organization?.exportConfiguration?.fiscalYearStartMonth?.toString() ??
          null,
      },
    };
  };

  const loading = loadingOrganizationData || loadingSettingsData;

  return (
    <Grid id="connected-datev-client-container" rowGap="space12">
      <DcsContainer
        title={t('settings.datev.connect.accountingCoreDataCheck.title')}
        details={
          loading ? (
            <Grid gap="space12">
              <Grid gap="space24" columns={4} width="100%">
                <Skeleton minWidth="space200" height="space40" width="100%" />
                <Skeleton height="space40" width="100%" />
                <Skeleton height="space40" width="100%" />
                <Skeleton height="space40" width="100%" />
              </Grid>
              <Skeleton height="space16" width="100%" />
              <Skeleton height="space16" width="100%" />
            </Grid>
          ) : (
            <AccountingCoreDataForm
              onSubmit={onSubmit}
              initialValues={getInitialValues()}
            />
          )
        }
      />
    </Grid>
  );
};

const AccountingCoreDataForm = ({
  onSubmit,
  initialValues,
}: {
  onSubmit: (value: boolean) => void;
  initialValues: AccountingCoreDataFormOutput;
}) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const organization = useFullOrganization();
  const { bdsConnected } = useDatev();

  const [disconnectDatevClient] = useDisconnectDatevClientMutation({
    refetchQueries: [
      ...refetchDatevDataWithNoToken,
      { query: getFullOrganizationQuery },
    ],
  });

  const resetConnection = useCallback(async () => {
    // 1. Disconnect the client
    await disconnectDatevClient();
    // 2. Redirect to datev setting page
    navigate(`/${organization?.id}${Routes.SETTINGS}${Routes.DATEV}`);
  }, [navigate, organization?.id, disconnectDatevClient]);

  const [updateChartOfAccountMutation] = useUpdateDatevChartOfAccountMutation();
  const [updateExportConfigurationMutation] =
    useUpdateExportConfigurationNewMutation({
      refetchQueries: [{ query: getFullOrganizationQuery }],
      awaitRefetchQueries: true,
    });

  const onSubmitCoreData = async ({
    accountLength,
    chartOfAccountCode,
    fiscalYearStart,
  }: AccountingCoreDataFormOutput) => {
    await updateChartOfAccountMutation({
      variables: {
        accountLength: Number(accountLength),
      },
      refetchQueries: [{ query: getBookingAccountValidation }],
    });
    await updateChartOfAccountMutation({
      variables: {
        code: chartOfAccountCode as ChartOfAccountCode,
      },
    });
    await updateExportConfigurationMutation({
      variables: {
        fiscalYearStartDay: Number(fiscalYearStart.day),
      },
    });
    await updateExportConfigurationMutation({
      variables: {
        fiscalYearStartMonth: Number(fiscalYearStart.month),
      },
    });
  };

  const formId = useId();

  const form = useForm<AccountingCoreDataFormOutput>({
    mode: 'onTouched',
    defaultValues: {
      ...initialValues,
    },
  });

  const hasNoCoreAccountingData =
    !form.getValues().accountLength &&
    !form.getValues().chartOfAccountCode &&
    !form.getValues().fiscalYearStart.day &&
    !form.getValues().fiscalYearStart.month;

  const hideConfigurationFields = !bdsConnected && hasNoCoreAccountingData;

  return (
    <FormProvider {...form}>
      <Grid
        width="100%"
        as="form"
        gap="space24"
        id={formId}
        onSubmit={form.handleSubmit(async values => {
          if (
            !form.getValues().accountLength ||
            !form.getValues().chartOfAccountCode ||
            !form.getValues().fiscalYearStart.day ||
            !form.getValues().fiscalYearStart.month
          )
            return;
          await onSubmitCoreData(values);
          onSubmit(true);
        })}
      >
        <AccountingCoreDataContainer
          hideConfigurationFields={hideConfigurationFields}
        />
        <Flex gap="space12" alignItems="center">
          <Button variant="secondary" onClick={resetConnection}>
            {t(
              'settings.datev.connect.accountingCoreDataCheck.actionButtons.cancel'
            )}
          </Button>
          <Button type="submit" form={formId}>
            {t(
              'settings.datev.connect.accountingCoreDataCheck.actionButtons.next'
            )}
          </Button>
        </Flex>
      </Grid>
    </FormProvider>
  );
};
