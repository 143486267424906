import { useToastMessage } from 'components/Toast/useToastMessage';
import { useTranslation } from 'react-i18next';
import {
  PaymentConditionsDrawer,
  PaymentConditionsDrawerProps,
} from './PaymentConditionsDrawer';
import { PaymentConditionsFormOutput } from './PaymentConditionsForm';
import { useArchivePaymentCondition } from './useArchivePaymentCondition';
import { useCreatePaymentCondition } from './useCreatePaymentCondition';
import { usePaymentConditionData } from './usePaymentConditionData';
import { useRestorePaymentCondition } from './useRestorePaymentCondition';
import { useUpdatePaymentCondition } from './useUpdatePaymentCondition';

export interface PaymentConditionsDrawerContainerProps {
  id?: 'create' | string;
  onClose?: PaymentConditionsDrawerProps['onClose'];
}

/** Handles data fetching for PaymentConditionsDrawer component */
export const PaymentConditionsDrawerContainerDefault = ({
  id,
  onClose,
}: PaymentConditionsDrawerContainerProps) => {
  const [t] = useTranslation('settings');
  const { success, error } = useToastMessage();

  const { data, isArchived, loading } = usePaymentConditionData({
    id: id !== 'create' ? id : undefined,
  });

  const { create, creating } = useCreatePaymentCondition();
  const { update, updating } = useUpdatePaymentCondition({ id });
  const { archive, archiving } = useArchivePaymentCondition({ id });
  const { restore, restoring } = useRestorePaymentCondition({ id });

  const isOpen = id !== undefined;

  const handleAction = async (formData: PaymentConditionsFormOutput) => {
    const handler = id === 'create' ? create : update;

    const { status } = await handler(formData);

    if (status === 'success') {
      success(
        t(
          `paymentConditions.messages.${
            id === 'create' ? 'created' : 'updated'
          }`
        )
      );
      onClose?.();
    } else {
      error(
        t(
          `paymentConditions.messages.${
            id === 'create' ? 'createFailed' : 'updateFailed'
          }`
        )
      );

      onClose?.();
    }
  };

  const handleArchive = async () => {
    const { status } = await archive();
    if (status === 'success') {
      success(t('paymentConditions.messages.archived'));
    } else {
      error(t('paymentConditions.messages.archiveFailed'));
    }

    onClose?.();
  };

  const handleRestore = async () => {
    const { status } = await restore();
    if (status === 'success') {
      success(t('paymentConditions.messages.restored'));
    } else {
      error(t('paymentConditions.messages.restoreFailed'));
    }

    onClose?.();
  };

  return (
    <PaymentConditionsDrawer
      action={id === 'create' ? 'create' : 'edit'}
      isOpen={isOpen}
      onClose={onClose}
      defaultValues={data}
      loading={loading}
      updating={creating || updating}
      onAction={handleAction}
      onArchive={handleArchive}
      archiving={archiving}
      isArchived={isArchived}
      onRestore={handleRestore}
      restoring={restoring}
    />
  );
};
