import { ContactExtractionEventDataFragment } from 'generated-types/graphql.types';
import { Trans } from 'providers/LocaleProvider';

export const ContactExtractedComponent = ({
  contact,
}: ContactExtractionEventDataFragment) => {
  return contact ? (
    <Trans
      i18nKey="document.compact.history.contactExtracted.title"
      values={{ name: contact.name.value }}
    >
      Contact {{ name: contact.name.value } as any} automatically extracted
    </Trans>
  ) : null;
};
