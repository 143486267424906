import { Grid, Tooltip, useTooltip } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useNavigationSidebarContext } from 'providers/NavigationSidebarProvider/NavigationSidebarProvider';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './NavigationSidebar.module.css';
import { StyledButton } from './NavigationSidebar.styled';
import { OrganizationSwitcher } from './OrganizationSwitcher/OrganizationSwitcher';
import { MenuIntercom } from './components/MenuItems/MenuIntercom/MenuIntercom';
import { MenuNotifications } from './components/MenuItems/MenuNotifications/MenuNotifications';
import { MenuSettings } from './components/MenuItems/MenuSettings/MenuSettings';
import { UserProfile } from './components/MenuItems/UserProfile/UserProfile';
import { NavigationIcons } from './components/NavigationIcons';
import { useIconOverflow } from './hooks/useIconOverflow';
import { useNavigationIcons } from './hooks/useNavigationIcons';

export const NavigationSidebar = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const { isNavigationOpen, toggleSidebar } = useNavigationSidebarContext();

  const {
    isOpen: isTooltipOpen,
    tooltipProps,
    tooltipRef,
    triggerProps,
    triggerRef,
  } = useTooltip({
    placement: 'right',
    passiveTrigger: true,
    delay: 2000,
  });

  const navigationSidebarRef = useRef<HTMLDivElement>(null);
  const bottomNavigationItemsRef = useRef<HTMLDivElement>(null);

  const {
    icons: initialIcons,
    intercomIcon,
    notificationsIcon,
  } = useNavigationIcons();

  const { visibleIcons, hiddenIcons, sidebarRefCallback } = useIconOverflow({
    initialIcons,
    navigationSidebarRef,
    bottomNavigationItemsRef,
  });

  return (
    <Grid
      className={styles.sidebar}
      ref={sidebarRefCallback}
      id="menu"
      data-open={isNavigationOpen}
      role="menu"
      alignContent="space-between"
      gridArea="sidebar"
      as="aside"
      padding="space16 space16 space24 space8"
      position="relative"
      width={isNavigationOpen ? '292px' : '96px'}
      gap="auto"
      height="100vh"
    >
      <StyledButton
        id="menuButton"
        aria-controls="menu"
        aria-haspopup="true"
        aria-label={
          isNavigationOpen ? t('toggleLabelClosed') : t('toggleLabelOpen')
        }
        aria-pressed={isNavigationOpen}
        color="gray"
        size="small"
        icon={isNavigationOpen ? 'menuExpanded' : 'menuCollapsed'}
        variant="tertiary"
        onClick={toggleSidebar}
        position="absolute"
        right={isNavigationOpen ? '8px' : '-20px'}
        top="32px"
        zIndex={1}
        ref={triggerRef}
        {...triggerProps}
      />
      {isTooltipOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {isNavigationOpen ? t('toggleLabelClosed') : t('toggleLabelOpen')}
        </Tooltip>
      )}
      <Grid as="nav" gap="space20">
        <OrganizationSwitcher isNavigationOpen={isNavigationOpen} />
        <NavigationIcons
          icons={visibleIcons}
          isNavigationOpen={isNavigationOpen}
          hiddenIcons={hiddenIcons}
        />
      </Grid>
      <Grid paddingLeft="space8" gap="space8" ref={bottomNavigationItemsRef}>
        <MenuIntercom
          intercomIcon={intercomIcon}
          isNavigationOpen={isNavigationOpen}
        />
        <MenuNotifications {...notificationsIcon} expanded={isNavigationOpen} />
        <MenuSettings expanded={isNavigationOpen} />
        <UserProfile expanded={isNavigationOpen} />
      </Grid>
    </Grid>
  );
};
