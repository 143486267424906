import {
  Card,
  Flex,
  Grid,
  Heading,
  Paragraph,
  Skeleton,
} from '@candisio/design-system';
import { PulseIndicator } from 'components/PulseIndicator/PulseIndicator';
import { motion } from 'framer-motion';
import type { ReactNode } from 'react';

const MotionGrid = motion(Grid);

export const AnimationGrid = ({ children }: { children: ReactNode }) => (
  <MotionGrid
    gap="space16"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1, transition: { duration: 0.3 } }}
  >
    {children}
  </MotionGrid>
);

interface OrganizationCardProps {
  children: ReactNode;
  title: string;
  paragraph: ReactNode;
  isLoading: boolean;
  showIndicator?: boolean;
}

export const OrganizationCard = ({
  children,
  title,
  paragraph,
  isLoading,
  showIndicator,
}: OrganizationCardProps) => (
  <Card>
    {isLoading ? (
      <AnimationGrid>
        <Skeleton height="space32" width="100%" />
        <Grid gap="space24">
          <Grid gap="space4">
            <Skeleton height="space16" width="100%" />
            <Skeleton height="space16" width="100%" />
          </Grid>
          {children}
        </Grid>
      </AnimationGrid>
    ) : (
      <AnimationGrid>
        <Flex alignItems="center" gap="space8">
          <Heading as="h3">{title}</Heading>
          {showIndicator && <PulseIndicator />}
        </Flex>
        <Grid gap="space24">
          <Paragraph whiteSpace="pre-line">{paragraph}</Paragraph>
          {children}
        </Grid>
      </AnimationGrid>
    )}
  </Card>
);
