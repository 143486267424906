import { Grid, Button } from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { AnimatePresence } from 'framer-motion';
import { UserRole } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { ActionsContainer } from 'views/DocumentDetails/components/ActionsContainer';

interface DocumentFormActionsProps {
  allowInlineEditing: boolean;
  isEditing?: boolean;
  isUpdatingDocument: boolean;
  reRequestApproval?: () => void;
}

const buttonStates = {
  hidden: { y: '3.25rem' },
};

export const DocumentFormActions = ({
  allowInlineEditing,
  isEditing,
  isUpdatingDocument,
  reRequestApproval,
}: DocumentFormActionsProps) => {
  const [t] = useTranslation();

  return (
    <Grid position="absolute" bottom="0" width="100%">
      <AnimatePresence exitBeforeEnter>
        {isEditing ? (
          <ActionsContainer
            hidden={buttonStates.hidden}
            key="editing-payment-data"
          >
            <UserRoleAccessWrapper
              allowedRoles={[UserRole.Accountant, UserRole.Admin]}
            >
              <Button
                loading={isUpdatingDocument}
                variant="primary"
                width="100%"
                type="submit"
              >
                {t('document.requestApproval.actions.save')}
              </Button>
            </UserRoleAccessWrapper>
          </ActionsContainer>
        ) : (
          reRequestApproval && (
            <ActionsContainer
              hidden={buttonStates.hidden}
              duration={0.5}
              key="re-request-approval"
            >
              <UserRoleAccessWrapper
                allowedRoles={[
                  UserRole.Accountant,
                  UserRole.Admin,
                  UserRole.Requester,
                ]}
              >
                <Button
                  width="100%"
                  variant={allowInlineEditing ? 'tertiary' : 'primary'}
                  onClick={reRequestApproval}
                >
                  {allowInlineEditing
                    ? t('document.requestApproval.actions.requestApprovalAgain')
                    : t('document.requestApproval.actions.edit')}
                </Button>
              </UserRoleAccessWrapper>
            </ActionsContainer>
          )
        )}
      </AnimatePresence>
    </Grid>
  );
};
