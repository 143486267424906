import { Button, Card, Flex, Grid, Link, Text } from '@candisio/design-system';
import { CreditCardsProvider } from 'providers/CreditCardsProvider/CreditCardsProvider';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useVendorSuggestionsData } from 'views/CreditCards/hooks/useVendorSuggestionsData';
import { RecurringPaymentsListContainer } from './RecurringPaymentsList/RecurringPaymentsListContainer';
import { RecurringPaymentsModal } from './RecurringPaymentsModal/RecurringPaymentsModal';
import { VendorListSuggestionsContainer } from './VendorListSuggestions/VendorListSuggestionsContainer';

export const recurringPaymentsId = 'recurring-payments';

export const RecurringPaymentsWidget = forwardRef<HTMLDivElement>((_, ref) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const [expandibleCards, setExpandibleCards] = useState({
    vendorSuggestions: true,
    recurringPayments: true,
  });

  const [suggestedAmount, setSuggestedAmount] = useState<number>(0);

  const { vendorId } = useParams<{ vendorId: string }>();

  const { loading, vendorDetails } = useVendorSuggestionsData();

  const handleSetExpandibleCards = (
    toggle: 'vendorSuggestions' | 'recurringPayments'
  ) => {
    if (toggle === 'vendorSuggestions') {
      setExpandibleCards(prevState => ({
        vendorSuggestions: !prevState.vendorSuggestions,
        recurringPayments: prevState.recurringPayments,
      }));
    }

    if (toggle === 'recurringPayments') {
      setExpandibleCards(prevState => ({
        recurringPayments: !prevState.recurringPayments,
        vendorSuggestions: prevState.vendorSuggestions,
      }));
    }
  };

  return (
    <Card background="gray50">
      <Grid gap="space16">
        <Flex direction="column" gap="space16">
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="xxlarge">
              {t('insights.recurringPaymentsWidget.title')}
            </Text>
            <Button size="small" variant="secondary">
              {t('insights.recurringPaymentsWidget.feedbackCTA')}
            </Button>
          </Flex>
          <Text fontSize="basic">
            <Trans t={t} i18nKey="insights.recurringPaymentsWidget.subTitle">
              Behalte den Überblick über regelmäßige Zahlungen in deinem
              Unternehmen.
              <Link
                href={t('insights.recurringPaymentsWidget.url')}
                fontWeight="semibold"
                external
              >
                Wie funktioniert das
              </Link>
            </Trans>
          </Text>
        </Flex>
        <Grid ref={ref} id={recurringPaymentsId}>
          <VendorListSuggestionsContainer
            loading={loading}
            vendorDetails={vendorDetails}
            onSetExpandibleCards={handleSetExpandibleCards}
            showVendors={expandibleCards.vendorSuggestions}
            onSetSuggestedAmount={setSuggestedAmount}
          />
        </Grid>
        <RecurringPaymentsListContainer
          numOfVendorSuggestions={vendorDetails.vendorsToConvert}
          onSetExpandibleCards={handleSetExpandibleCards}
          showRecurringPayments={expandibleCards.recurringPayments}
        />
      </Grid>

      {vendorId ? (
        <CreditCardsProvider>
          <RecurringPaymentsModal
            suggestedAmount={suggestedAmount}
            onSetSuggestedAmount={setSuggestedAmount}
          />
        </CreditCardsProvider>
      ) : null}
    </Card>
  );
});
