import {
  CardIssuerPaymentSettlement,
  CardIssuerTransaction,
  DocumentPaymentState,
  DocumentStatus,
  ExportStatus,
  ExportableEntity,
  ExportableEntityInfo,
  ExportableEntityType,
  ExportableReimbursementItem,
  Money,
  PaymentFrequencyType,
  Provision,
  ProvisionEntityType,
  ReimbursementItemType,
  Reversal,
  TransactionStatus,
} from 'generated-types/graphql.types';
import { UsePaginationResponse } from 'providers/GraphQLProvider/Pagination/usePagination';
import { ExportQuery } from './consts';
import { ExportReimbursementItemUser } from './toolkit/utils/mapReimbursementItemToExportEntity';
export interface AggregatedCoreDataInfo {
  count: number;
  id: string;
  status: ExportStatus;
}

export enum ModalSource {
  EXPORT_PROVISION_REVERSAL_TAB = 'export-provision_reversal_tab',
  EXPORT_SIDEBAR_INITIALIZED = 'export-sidebar_initialized',
  SETTINGS_CONNECT_TO_DATEV = 'settings-connect_to_datev',
  SETTINGS_CONNECTED_TO_DATEV = 'settings-connected_to_datev',
  PROVISIONS_SETTINGS = 'settings-provisions',
  PAYMENTS_PAGE = 'payments',
  APPROVALS_PAGE = 'approvals',
  PAYMENTS_SETTINGS = 'settings-payments',
  PAYMENT_CONDITIONS_SETTINGS = 'settings-payment_conditions',
  CONTACT_PAGE = 'contact',
  DASHBOARD = 'dashboard',
}
export interface ExportCreator {
  avatarUrl?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}
export interface ExportDocumentDetails {
  document: ExportEntity;
}
export interface ExportDocumentDetails {
  document: ExportEntity;
}
export type FailedDocument = {
  id: string;
  invoiceNumber: string;
};

export enum DocumentType {
  CardTransaction = 'CARD_TRANSACTION',
  Document = 'DOCUMENT',
  CardSettlement = 'CARD_SETTLEMENT',
  DocumentProvision = 'DOCUMENT_PROVISION',
  TransactionProvision = 'TRANSACTION_PROVISION',
  DocumentReversal = 'DOCUMENT_REVERSAL',
  TransactionReversal = 'TRANSACTION_REVERSAL',
  Contacts = 'CONTACTS',
  PaymentConditions = 'PAYMENT_CONDITIONS',
  ReimbursementItems = 'REIMBURSEMENT_ITEMS',
}

export const ExportedEntityType = {
  CARD_TRANSACTION: 'CARD_TRANSACTION',
  DOCUMENT: 'DOCUMENT',
  CARD_SETTLEMENT: 'CARD_SETTLEMENT',
  PROVISION: 'PROVISION',
  REVERSAL: 'REVERSAL',
  CONTACTS: 'CONTACTS',
  PAYMENT_CONDITIONS: 'PAYMENT_CONDITIONS',
} as const;

export interface ExportDetails {
  id: string;
  clientNumber: string;
  consultantNumber: string;
  createdAt: Date;
  documents: ExportDocumentDetails[];
  reversals: Reversal[];
  provisions: Provision[];
  transactions: CardIssuerTransaction[];
  reimbursementItems: ExportableReimbursementItem[];
  cardSettlements: CardIssuerPaymentSettlement[];
  exportableEntities?: ExportableEntity[];
  exportableEntityInfo?: ExportableEntityInfo[];
  exportedPaymentConditionsInfo?: AggregatedCoreDataInfo;
  exportedContactsInfo?: AggregatedCoreDataInfo;
  creator?: ExportCreator | null;
  type: ExportType;
  status: ExportStatus;
  client?: string | null;
  integration?: {
    details?: string[] | null;
  } | null;
  timeFrame?: {
    year: number;
    month: number;
  } | null;
  originExportId?: string | null;
}
export interface ExportError {
  message?: string | null;
  errorCode?: string | null;
  errorDescription?: string | null;
  errorUri?: string | null;
  failedDocuments?: FailedDocument[];
}

export interface DocumentExportStatus {
  status?: ExportStatus | null;
  details?: string[] | null;
  warnings?: string[] | null;
  exportMethod?: string | null;
  exportError?: ExportError | null;
  entityType?: ExportableEntityType | null;
  isDocumentDirectionOthers?: boolean;
}
export interface ExportEntity {
  account?: string[];
  amount: Money;
  clientNumber?: string;
  consultantNumber?: string;
  contact: string;
  deliveryDate?: Date;
  direction?: string | null;
  documentType?: string | null;
  exportGuid?: string | null;
  exportStatus?: DocumentExportStatus | null;
  generalLedgerAccount?: string[];
  hasDocument: boolean;
  id: string;
  invoiceDate: Date;
  invoiceNumber: string;
  netAmount?: Money;
  paymentState: DocumentPaymentState;
  period?: PaymentFrequencyType | 'UNKNOWN' | undefined;
  provisionEntityId?: string;
  provisionEntityType?: ProvisionEntityType;
  provisionStatus?: TransactionStatus | DocumentStatus;
  settlementPaymentStatus?: string | undefined;
  transactionStatus?: TransactionStatus | null;
  statementId?: string;
  type: ExportableEntityType;
  reason?: string;
  targetMembership?: ExportReimbursementItemUser;
  reimbursementCollectionTitle?: string;
  reimbusementItemType?: ReimbursementItemType;
  reimbursementColletionId?: string;
}

export interface TimeFrameOption {
  documentIds: string[];
  transactionIds: string[];
  cardSettlementIds: string[];
  provisionIds: string[];
  reimbursementItemsIds: string[];
  reversalIds: string[];
  year: number;
  month: number;
  numberOfExportableEntities: number;
  numberOfDocuments: number;
  numberOfTxnBasedDocuments: number;
  numberOfTxnWithoutDocuments: number;
  numberOfSettlements: number;
  numberOfDocumentProvisions: number;
  numberOfTxnProvisions: number;
  numberOfDocumentReversals: number;
  numberOfTxnReversals: number;
  numberOfReimbursementItems: number;
}
export type ExportPaginationResponse = UsePaginationResponse<ExportQuery>;
export interface ExportEntityRow extends ExportEntity {
  isDisabled?: boolean;
  disableReason?: string;
}

export enum ExportType {
  ZIP = 'ZIP',
  DATEV = 'DATEV',
  DATEV_BDS = 'DATEV_BDS',
  DATEV_DXSO_ZIP = 'DATEV_DXSO_ZIP',
  ADDISON_FORMAT = 'ADDISON_FORMAT',
  DATEV_CSV = 'DATEV_CSV',
  CANDIS_CSV = 'CANDIS_CSV',
  SAP_CSV = 'SAP_CSV',
  SAP_B1 = 'SAP_B1',
  API = 'API',
}

export enum View {
  HISTORY = 'HISTORY',
  NOT_READY = 'NOT_READY',
  READY = 'READY',
}

export const Entity = {
  DOCUMENT: 'DOCUMENT',
  REIMBURSEMENT_ITEMS: 'REIMBURSEMENT_ITEMS',
  TRANSACTION: 'TRANSACTION',
  SETTLEMENT: 'SETTLEMENT',
  CORE_DATA: 'CORE_DATA',
} as const;

export const Table = {
  APPROVALS: 'APPROVALS',
  PROVISIONS: 'PROVISIONS',
  REVERSALS: 'REVERSALS',
  SETTLEMENTS: 'SETTLEMENTS',
  CONTACTS: 'CONTACTS',
  PAYMENT_CONDITIONS: 'PAYMENT_CONDITIONS',
} as const;

export enum Sidebar {
  NOT_READY = 'NOT_READY',
  HISTORY = 'HISTORY',
  INIT_NEW_EXPORT = 'INIT_NEW_EXPORT',
}

export enum SEARCH_PARAM {
  VIEW = 'view',
  EXPORT_ID = 'exportId',
  ENTITY = 'entity',
  TABLE = 'table',
  EXPORT_TYPE = 'exportType',
  TIME_FRAME = 'timeframe',
  IS_RE_EXPORT = 'isReExport',
  SIDEBAR = 'sidebar',
  TRIGGER_DOWNLOAD = 'shouldTriggerDownload',
}
export interface TimeFrame {
  invoiceDate: Date;
  documentId?: string;
  transactionId?: string;
  cardSettlementId?: string;
  provisionId?: string;
  reversalId?: string;
  reimbursementItemId?: string;
  numberOfExportableEntities: number;
  numberOfDocuments?: number;
  numberOfReimbursementItems?: number;
  numberOfTxnBasedDocuments?: number;
  numberOfTxnWithoutDocuments?: number;
  numberOfSettlements?: number;
  numberOfDocumentProvisions?: number;
  numberOfTxnProvisions?: number;
  numberOfDocumentReversals?: number;
  numberOfTxnReversals?: number;
}
