import { Box, Button, Grid, ScrollBox } from '@candisio/design-system';
import { Routes } from 'models';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import {
  EmptyDocumentSummaryCardList,
  ErrorDocumentSummaryCardList,
  LoadingDocumentSummaryCard,
} from './DocumentSummaryCard';
import { useDocumentRelations } from './hooks/useDocumentRelations';
import { DocumentRelationItem } from './DocumentRelations';

type DocumentRelationsDrawerProps = {
  globalDocumentId: string;
  readOnly?: boolean;
};

export const DocumentRelationsDrawer = ({
  globalDocumentId,
  readOnly = false,
}: DocumentRelationsDrawerProps) => {
  const [t] = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const goToManageRelationships = useCallback(() => {
    navigate(location.pathname + Routes.RELATIONSHIPS);
  }, [location.pathname, navigate]);

  const {
    isLoading: isLoadingRelations,
    isEmpty: hasNoRelations,
    hasError,
    relatedDocuments,
    isLinkingDocumentsPending,
    isUnlinkingDocumentsPending,
    handleUnlinkDocuments,
  } = useDocumentRelations(globalDocumentId);

  const isEmpty = hasNoRelations;
  const isLoading = isLoadingRelations;

  const showError = !isLoading && hasError;
  const showEmptyList = !isLoading && !hasError && isEmpty;
  const showLoadingItem = isLoadingRelations || isLinkingDocumentsPending;

  return (
    <ScrollBox scrollDirection="y" scrollbarGutter="stable">
      <Grid
        as="ul"
        listStyle="none"
        paddingLeft="0"
        role="grid"
        rowGap="space8"
        autoRows="min-content"
      >
        {showError && <ErrorDocumentSummaryCardList key="error-item" />}
        {showEmptyList && <EmptyDocumentSummaryCardList key="empty-item" />}
        {relatedDocuments.map(relatedDocument => (
          <DocumentRelationItem
            readOnly={readOnly}
            key={relatedDocument.relationId}
            documentName={relatedDocument.name ?? ''}
            handleUnlinkDocuments={handleUnlinkDocuments}
            isUnlinkingDocumentsPending={isUnlinkingDocumentsPending}
            {...relatedDocument}
          />
        ))}
        {showLoadingItem && <LoadingDocumentSummaryCard key="loading-item" />}
        <Grid
          position="sticky"
          bottom="0"
          paddingTop="space8"
          justifyContent="center"
        >
          <Box>
            <Button
              variant="secondary"
              color="blue"
              disabled={readOnly}
              onClick={goToManageRelationships}
              icon="plus"
            >
              {t('document.tabs.relationships.manageRelationships')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </ScrollBox>
  );
};
