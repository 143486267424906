import { Grid } from '@candisio/design-system';
import { TruncatedTitle } from 'components/HistoryList/components/TruncatedTitle';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { ContactType } from 'generated-types/graphql.types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContactFormValues } from 'views/Contacts/ContactDetails/ContactForm/contactFormSchema';

export interface ContactNameFieldProps {
  /** Is the field disabled? */
  disabled?: boolean;
}

/** Displays a “name” field appropriate for the current contact type */
export const ContactNameField = ({ disabled }: ContactNameFieldProps) => {
  const [t] = useTranslation();

  const contactType = useWatch<ContactFormValues, 'contactType'>({
    name: 'contactType',
  });

  switch (contactType) {
    case ContactType.Company:
      return (
        <HookFormTextField<ContactFormValues>
          key="companyName" // unmount field when `contactType` changes
          disabled={disabled}
          inputProps={{
            autoComplete: 'off',
          }}
          name="companyName"
          label={t('settings.contacts.details.edit.companyName.label')}
        />
      );
    case ContactType.Individual:
      return (
        <Grid autoFlow="column" columnGap="space14">
          <HookFormTextField<ContactFormValues>
            disabled={disabled}
            inputProps={{
              autoComplete: 'off',
            }}
            name="individualFirstName"
            label={t(
              'settings.contacts.details.edit.individualFirstName.label'
            )}
          />
          <HookFormTextField<ContactFormValues>
            disabled={disabled}
            inputProps={{
              autoComplete: 'off',
            }}
            name="individualLastName"
            label={t('settings.contacts.details.edit.individualLastName.label')}
          />
        </Grid>
      );
    case 'EMPLOYEE':
      return (
        <Grid autoFlow="column" columnGap="space14">
          <HookFormTextField<ContactFormValues>
            inputProps={{
              autoComplete: 'off',
            }}
            readOnly
            name="individualFirstName"
            label={t(
              'settings.contacts.details.edit.individualFirstName.label'
            )}
          />
          <HookFormTextField<ContactFormValues>
            inputProps={{
              autoComplete: 'off',
            }}
            readOnly
            name="individualLastName"
            label={t('settings.contacts.details.edit.individualLastName.label')}
          />
        </Grid>
      );
    case ContactType.NotSpecified:
      return (
        <HookFormTextField<ContactFormValues>
          key="notSpecifiedName" // unmount field when `contactType` changes
          disabled={disabled}
          inputProps={{
            autoComplete: 'off',
          }}
          name="notSpecifiedName"
          label={t('settings.contacts.details.edit.notSpecifiedName.label')}
        />
      );
    default:
      return null;
  }
};
