import { DocumentCurrency } from 'generated-types/graphql.types';
import {
  PaymentSettlementStatus,
  CardIssuerPaymentSettlement,
} from 'generated-types/resolvers-types';
import {
  getRandomAmount,
  getRandomDate,
  getRandomId,
} from 'utils/generateRandomData';

type CreateCardSettlementsReadyForExport = {
  cardSettlementsReadyForExport?: Partial<CardIssuerPaymentSettlement>;
};
export const createCardIssuerPaymentSettlement = ({
  cardSettlementsReadyForExport,
}: CreateCardSettlementsReadyForExport = {}): CardIssuerPaymentSettlement => {
  return {
    __typename: 'CardIssuerPaymentSettlement',
    id: getRandomId(),
    status: PaymentSettlementStatus.Paid,
    amount: {
      __typename: 'PaymentSettlementAmount',
      value: getRandomAmount(),
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
    actualPaymentDate: getRandomDate().toISOString(),
    paymentCreatedAt: getRandomDate().toISOString(),
    plannedPaymentDate: getRandomDate().toISOString(),
    publicPaymentId: getRandomId(),
    ...cardSettlementsReadyForExport,
    statement: cardSettlementsReadyForExport?.statement ?? null,
  };
};
