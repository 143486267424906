import { Flex, Grid } from '@candisio/design-system';
import { TabViewLayout } from 'components/TabViewLayout';
import { ReactNode } from 'react';
import { ArchiveTabs } from 'views/Archive/components/ArchiveTabs';
import { TabView } from 'views/Inbox/models';
import { ArchiveHeader } from './ArchiveHeader';

export const ArchiveViewLayout = ({
  activeTab,
  children,
  header,
  rightSide,
}: {
  activeTab: TabView;
  children: ReactNode;
  header?: ReactNode;
  rightSide?: ReactNode;
}) => {
  return (
    <Grid
      autoFlow="column"
      width="100%"
      height="100%"
      templateColumns="1fr auto"
      overflow="hidden"
    >
      <Flex direction="column" height="100%">
        <ArchiveHeader>{header}</ArchiveHeader>
        <TabViewLayout tabs={<ArchiveTabs activeTab={activeTab} />}>
          <Flex
            height="100%"
            paddingX="space32"
            paddingBottom="space24"
            direction="column"
          >
            {children}
          </Flex>
        </TabViewLayout>
      </Flex>
      {rightSide}
    </Grid>
  );
};
