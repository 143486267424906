import {
  Box,
  Grid,
  Icon,
  Paragraph,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { CheckmarkIcon } from 'components/Icons/DefaultIcons';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { OrganizationHistoryService } from 'services/OrganizationHistoryService';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { APPROVALS, EXPORT, FilterBy, INBOX } from './OrganizationsListView';
import { SortedOrganization } from './useOrganizationSummary';

interface OrganizationItemProps {
  organization: SortedOrganization;
  queryString: string;
  filterBy: FilterBy;
}

export const OrganizationItem = ({
  organization,
  queryString,
  filterBy,
}: OrganizationItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const history = useHistory();
  const { pathname: currentSlug } = history.location;

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  const {
    title,
    overdueCount,
    slug,
    taskCount,
    toProcessTotal,
    toProcessOverdue,
    toApproveTotal,
    toApproveOverdue,
    toExportTotal,
    toExportOverdue,
  } = organization;

  const isComplete = !taskCount && !overdueCount;
  const isCurrentOrganization = currentSlug.split('/')[1] === slug;

  const renderTaskCount = () => {
    switch (filterBy) {
      case INBOX:
        return toProcessTotal;
      case APPROVALS:
        return toApproveTotal;
      case EXPORT:
        return toExportTotal;
      default:
        return taskCount;
    }
  };

  const renderOverdueCount = () => {
    switch (filterBy) {
      case INBOX:
        return toProcessOverdue;
      case APPROVALS:
        return toApproveOverdue;
      case EXPORT:
        return toExportOverdue;
      default:
        return overdueCount;
    }
  };

  return (
    <RouterLink
      to={`/${slug}`}
      onClick={() =>
        OrganizationHistoryService.updateStoredOrganizations(organization.id)
      }
    >
      <Grid alignItems="center" padding="space8 space16">
        <Grid autoFlow="column" alignItems="center" gap="space8">
          <Box
            as="h3"
            fontSize="basic"
            fontWeight={isCurrentOrganization ? 'bold' : 'regular'}
            lineHeight="paragraph"
            overflow="hidden"
            whiteSpace="nowrap"
            style={{ textOverflow: 'ellipsis' }}
          >
            {renderQueryHighlight({
              value: title,
              queryString: queryString,
            })}
          </Box>
          <Grid
            autoFlow="column"
            alignItems="center"
            justifyContent="end"
            gap="space8"
          >
            <Grid
              autoFlow="column"
              alignItems="center"
              color="red700"
              width="space16"
            >
              {overdueCount ? (
                <>
                  <Icon
                    size="space16"
                    icon="bell"
                    {...triggerProps}
                    ref={triggerRef}
                  />
                  {isOpen && (
                    <Tooltip {...tooltipProps} ref={tooltipRef}>
                      {`${overdueCount} ${t(
                        'multiOrganization.status.overdueLabel',
                        { count: overdueCount }
                      )}`}
                    </Tooltip>
                  )}
                </>
              ) : null}
              {isComplete ? <CheckmarkIcon size="space16" /> : null}
              {isOpen && (
                <Tooltip {...tooltipProps} ref={tooltipRef}>
                  {`${renderOverdueCount()} ${t(
                    'multiOrganization.status.overdueLabel',
                    { count: renderOverdueCount() }
                  )}`}
                </Tooltip>
              )}
            </Grid>
            {!isComplete && (
              <Paragraph color="gray800">
                {`${renderTaskCount()} ${t(
                  'multiOrganization.status.taskLabel',
                  {
                    count: renderTaskCount(),
                  }
                )}`}
              </Paragraph>
            )}
          </Grid>
        </Grid>
      </Grid>
    </RouterLink>
  );
};
