import { Button, Flex, Grid, InfoPanel, Text } from '@candisio/design-system';
import { HookFormRadioGroupField } from 'components/HookFormFields/HookFormRadioGroupField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DocumentTags } from 'views/Settings/DocumentTags/toolkit/hooks/useDocumentTags';
import { preventInputPropagation } from 'views/Settings/DocumentTags/toolkit/utils';
import { useMergeTagsForm } from './useMergeTagsForm';

export const CUSTOM_NAME = 'CUSTOM_NAME';

type MergeTagsFormProps = {
  tags: DocumentTags;
  onCancel?: () => void;
  onMergeSuccess: () => void;
};

export const MergeTagsForm = ({
  tags,
  onCancel,
  onMergeSuccess,
}: MergeTagsFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { form, mergeTags, isMergeActionPending } = useMergeTagsForm({
    tags,
    onMergeSuccess,
  });

  return (
    <FormProvider {...form}>
      <Grid as="form" gap="space16" onSubmit={mergeTags}>
        <TagsRadioGroup
          tags={tags}
          isMergeActionPending={isMergeActionPending}
        />
        <InfoPanel variant="information">
          {t('documentTags.mergeModal.hint')}
        </InfoPanel>
        <Flex justifySelf="end" alignItems="end" gap="space8">
          <Button
            variant="secondary"
            onClick={onCancel}
            disabled={isMergeActionPending}
          >
            {t('documentTags.mergeModal.cancelAction')}
          </Button>
          <Button
            type="submit"
            loading={isMergeActionPending}
            disabled={!form.formState.isValid}
          >
            {t('documentTags.mergeModal.confirmAction')}
          </Button>
        </Flex>
      </Grid>
    </FormProvider>
  );
};

const TagsRadioGroup = ({
  tags,
  isMergeActionPending,
}: {
  tags: DocumentTags;
  isMergeActionPending: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const placeholder = t('documentTags.mergeModal.customNamePlaceholder');
  const { setValue } = useFormContext();

  return (
    <>
      <Text fontSize="basic">{t('documentTags.mergeModal.label')}</Text>
      <HookFormRadioGroupField
        gap="space8"
        name="targetTagId"
        label={t('documentTags.mergeModal.label')}
        disabled={isMergeActionPending}
        options={[
          ...tags.map(tag => ({ value: tag.id, children: tag.name })),
          {
            value: CUSTOM_NAME,
            style: { alignItems: 'center' },
            children: (
              <HookFormTextField
                width="19rem" // cursed: to fit maxLength + error indicator
                name="customName"
                ariaLabel={placeholder}
                readOnly={isMergeActionPending}
                clearable={false}
                placeholder={placeholder}
                inputProps={{ onKeyDown: preventInputPropagation }}
                onFocus={() => {
                  setValue('targetTagId', CUSTOM_NAME);
                }}
              />
            ),
          },
        ]}
      />
    </>
  );
};
