import { Box, Card, Grid, Skeleton, Text } from '@candisio/design-system';
import { PROVISIONS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { PromotionWrapper, Wrapper } from '../Wrapper';
import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { Reversals } from 'views/Integrations/Export/elements/table/reversals/Reversals';
import { Provisions } from 'views/Integrations/Export/elements/table/provisions/Provisions';
import { ExportEntityRow } from 'views/Integrations/Export/types';
import { useGetCustomPromotionDetails } from '../hooks/useGetCustomPromotionDetails';

const translationKeys = {
  reversal: {
    titleWithCount: 'reversalsPromotionTableCard.titleWithCount',
    title: 'reversalsPromotionTableCard.title',
    description: 'reversalsPromotionTableCard.description',
    button: 'reversalsPromotionTableCard.button',
  },
  provision: {
    titleWithCount: 'provisionsPromotionTableCard.titleWithCount',
    title: 'provisionsPromotionTableCard.title',
    description: 'provisionsPromotionTableCard.description',
    button: 'provisionsPromotionTableCard.button',
  },
} as const;

type ProvisionsPromotionExportProps = {
  amount: number;
  type: 'provision' | 'reversal';
  loading?: boolean;
  data: ExportEntityRow[];
};

const provisionUtmSourceMap = {
  provision: PROVISIONS_UTM_SOURCE.EXPORT_PROVISIONS,
  reversal: PROVISIONS_UTM_SOURCE.EXPORT_REVERSALS,
};

export const ProvisionsPromotionExport = ({
  amount,
  type,
  loading = false,
  data,
}: ProvisionsPromotionExportProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const { documentMoneyFormatter } = useMoneyFormatter();

  const { provisionsPromotion } = usePromotionContent();

  const customPromotionDetails = useGetCustomPromotionDetails(
    'datevSelfBooker',
    provisionUtmSourceMap[type]
  );

  const title =
    amount === 0
      ? t(translationKeys[type].title)
      : t(translationKeys[type].titleWithCount, {
          amount: documentMoneyFormatter({
            amount,
            currency: DocumentCurrency.Eur,
            precision: 2,
          }),
        });

  if (loading) {
    return <Skeleton width="100%" height="space64"></Skeleton>;
  }

  return (
    <Wrapper>
      <Box height="100%" width="100%" position="relative">
        <Card background="purplebg" paddingY="space12" width="100%">
          <Grid templateColumns="1fr auto" alignItems="center">
            <Grid gap="space4">
              <Text fontWeight="semibold" fontSize="large">
                {title}
              </Text>
              <Text fontSize="basic">
                {t(translationKeys[type].description)}
              </Text>
            </Grid>
          </Grid>
        </Card>

        <Box
          opacity="0.7"
          style={{
            maskImage: 'linear-gradient(to top, transparent 5%, #000000 15%)',
            pointerEvents: 'none',
            userSelect: 'none',
          }}
        >
          {type === 'provision' && <Provisions data={data} />}
          {type === 'reversal' && <Reversals data={data} />}
        </Box>
      </Box>

      <PromotionWrapper width="34rem" height="24rem">
        <PromotionsProvider promotions={[provisionsPromotion]}>
          <PromotionCard
            promotion={provisionsPromotion}
            closable={false}
            customPromotionDetails={customPromotionDetails}
          />
        </PromotionsProvider>
      </PromotionWrapper>
    </Wrapper>
  );
};
