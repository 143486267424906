import { Box, Grid, Text } from '@candisio/design-system';
import { TransactionStatus } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { MonthSpentOverview } from 'views/CreditCards/hooks/useGetSpendOverviewInsights';
import { VerticalBar } from './VerticalBar';
import { VerticalBarSkeleton } from './VerticalBarSkeleton';
import { UrlSearchParams, getDateRange } from './utils';

interface VerticalBarContainerProps {
  monthSpentOverview: MonthSpentOverview;
  maxNumberReference: number;
  loading?: boolean;
  hasFiltersApplied: boolean;
}

export const VerticalBarContainer = ({
  maxNumberReference,
  monthSpentOverview,
  loading,
  hasFiltersApplied,
}: VerticalBarContainerProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const orgSlug = useOrganizationId();
  const { dateRange } = getDateRange({ monthSpentOverview });

  const searchParams: UrlSearchParams = qs.parse(search);
  const pathname = `/${orgSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}`;

  if (loading) {
    return <VerticalBarSkeleton />;
  }

  const handleClick = () => {
    if (hasFiltersApplied) {
      navigate({
        pathname,
        search: qs.stringify({
          transactionCreatedAt: dateRange,
          status: [TransactionStatus.Confirmed, TransactionStatus.Pending],
          ...searchParams,
        }),
      });
    } else {
      navigate({
        pathname,
        search: qs.stringify({
          transactionCreatedAt: dateRange,
          status: [TransactionStatus.Confirmed, TransactionStatus.Pending],
        }),
      });
    }
  };

  if (isNil(monthSpentOverview.spentAmount.value)) {
    return (
      <Grid
        height="100%"
        alignItems="end"
        gap="space16"
        templateRows="1fr auto"
        justifyContent="center"
      >
        <Box
          borderRadius={12}
          width="space12"
          justifySelf="center"
          height="40%"
          background="linear-gradient(180deg, rgba(243,239,238,0) 0%, rgba(234,229,230,1) 80%)"
        />

        <Text textAlign="center" fontSize="basic" color="gray600">
          {monthSpentOverview.monthLabel}
        </Text>
      </Grid>
    );
  }

  const chartBarPercentage =
    (monthSpentOverview.spentAmount.value / maxNumberReference) * 100;

  return (
    <Grid
      height="100%"
      alignItems="end"
      gap="space16"
      templateRows="1fr auto"
      justifyContent="center"
    >
      <VerticalBar
        onClick={handleClick}
        percentage={chartBarPercentage}
        amount={monthSpentOverview.spentAmount.value}
        currency={monthSpentOverview.spentAmount.currency}
      />

      <Text textAlign="center" fontSize="basic">
        {monthSpentOverview.monthLabel}
      </Text>
    </Grid>
  );
};
