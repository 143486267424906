import { UseFormReturn, FieldValues } from 'react-hook-form';
import { WidgetFormAnnualBudget, WidgetFormQuarterBudget } from './types';
import { getQuarters, Quarter } from './QuarterOption/QuarterOption.helper';
import { getYears } from './AnnualOption/AnnualOption.helper';

export const assignBudgetsToFormFields = (
  budgets: WidgetFormAnnualBudget | WidgetFormQuarterBudget,
  form: UseFormReturn<FieldValues, any, undefined>
) => {
  Object.entries(budgets).forEach(([formField, value]) =>
    form.setValue(formField, value)
  );
};

export const cleanQuarterBudgetsForm = (
  form: UseFormReturn<FieldValues, any, undefined>
) => {
  const quarterFieldsKeys = getQuarters().reduce<Quarter[]>((acc, value) => {
    const fields = value['fields'].map(value => value.key);
    return acc.concat([...fields]);
  }, []);
  quarterFieldsKeys.forEach(quarter => form.setValue(quarter, undefined));
};

export const cleanYearBudgetsForm = (
  form: UseFormReturn<FieldValues, any, undefined>
) => {
  const yearFieldsKeys = getYears().map(option => option.key);
  yearFieldsKeys.forEach(year => form.setValue(year, undefined));
};
