import { Flex, Grid, Paragraph, Text } from '@candisio/design-system';
import { LottieAnimation, noemailData } from 'components/Lottie/Lottie';

export const EmptyNotificationList = ({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) => {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      height="100%"
    >
      <LottieAnimation
        width={78}
        height={55}
        options={{ animationData: noemailData }}
      />
      <Grid gap="space10">
        <Text as="h3" fontSize="xlarge" fontWeight="bold">
          {title}
        </Text>
      </Grid>
      <Paragraph color="gray600" fontSize="small" width="14rem">
        {description}
      </Paragraph>
    </Flex>
  );
};
