import { defaultTheme } from '@candisio/design-system';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { NavLink, NavLinkProps } from 'react-router-dom';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

const iconWidth = '1.875rem';

export const MenuItemCount = styled.div`
  margin: 0;
  position: absolute;
  transform: translate(30px, -20px);
  background-color: ${defaultTheme.colors.gray300};
  color: ${defaultTheme.colors.gray800};
  border-radius: 24px;
  transition: all 0.25s ease-out;
`;

export const MenuItemIcon = styled.div`
  overflow: hidden;
  width: ${iconWidth};
  margin-bottom: 0.25rem;
`;

export const MenuLink = styled((props: NavLinkProps) => (
  <NavLink tabIndex={0} {...props} />
))`
  position: relative;
  color: ${defaultTheme.colors.gray500};
  text-align: center;
  display: flex;
  align-items: center;
  width: 60px;
  flex-direction: column;
  justify-content: center;

  &:focus {
    ${MenuItemIcon} img {
      margin-left: -${iconWidth};
    }

    ${MenuItemCount} {
      color: ${defaultTheme.colors.blue500};
    }
  }

  &:hover {
    svg {
      transform: scale(1.1);
    }
  }

  &:hover,
  &.nav-item-active {
    color: ${defaultTheme.colors.gray800};

    svg {
      transition: ${defaultTheme.timingFunctions};
    }

    ${MenuItemIcon} {
      img {
        margin-left: -${iconWidth};
      }
    }

    ${MenuItemCount} {
      color: #fff;
      background-color: ${defaultTheme.colors.gray800};
    }
  }
`;

export const MenuItem = styled.li`
  list-style: none;

  transition: ${defaultTheme.timingFunctions};

  color: ${defaultTheme.colors.gray800};
  font-size: ${defaultTheme.fontSizes.small};
  cursor: pointer;
`;
