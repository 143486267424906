import {
  CardIssuerTransaction,
  ExportableEntityInfo,
  ExportableEntityType,
  Maybe,
} from 'generated-types/graphql.types';
import { ExportEntity } from '../../types';
import { txnWithoutDocumentToExportEntity } from './toExportEntity';

export type ExportTransaction = Pick<
  CardIssuerTransaction,
  | 'id'
  | 'billingAmount'
  | 'billingAmountForAccounting'
  | 'transactionCreatedAt'
  | 'merchantLegalName'
  | 'status'
  | 'invoiceAssociationStatus'
> & {
  accountingData?: Maybe<{
    contact?: Maybe<{
      id: string;
      name: {
        value: string;
      };
    }>;
  }>;
};

export const toTransactionExportEntity =
  ({
    exportableEntitiesInfo,
  }: {
    exportableEntitiesInfo: ExportableEntityInfo[];
  }) =>
  (transaction: ExportTransaction): ExportEntity => {
    const exportableEntityInfo = exportableEntitiesInfo.find(
      entity => entity.id === transaction.id
    );

    return {
      ...txnWithoutDocumentToExportEntity(transaction),
      exportStatus: {
        status: exportableEntityInfo?.status,
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        details: (exportableEntityInfo?.details || []).map(s => s!),
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        warnings: (exportableEntityInfo?.warnings || []).map(s => s!),
        entityType: ExportableEntityType.CardTransaction,
        exportMethod: exportableEntityInfo?.exportMethod,
        ...(exportableEntityInfo?.exportError
          ? {
              exportError: exportableEntityInfo?.exportError,
            }
          : null),
      },
    };
  };
