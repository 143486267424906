import { NetworkStatus } from '@apollo/client';
import { Button } from '@candisio/design-system';
import i18next from 'i18next';
import { MouseEvent } from 'react';
import { ErrorDescription } from '../../elements/navigation/History/styles';
import { ExportPaginationResponse } from '../../types';

export const LoadMoreItemsButton = ({
  t,
  paginationLimit,
  ...paginationResponse
}: ExportPaginationResponse & {
  t: i18next.TFunction;
  paginationLimit: number;
}) => {
  if (paginationResponse.error) {
    return <LoadMoreErrorMessage t={t} paginationLimit={paginationLimit} />;
  }

  const hasMoreData = paginationResponse.data?._exports?.pageInfo?.hasNextPage;
  const endCursor = paginationResponse.data?._exports?.pageInfo?.endCursor;
  const showLoadMore =
    hasMoreData && endCursor && !isLoadingInitialOrMoreData(paginationResponse);

  if (!showLoadMore) {
    return null;
  }

  return (
    <Button
      variant="tertiary"
      size="small"
      // @TODO explicit MouseEvent<HTMLButtonElement> type should not be required
      // See: https://github.com/microsoft/TypeScript/issues/44596
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        paginationResponse.onLoadMore?.();
      }}
    >
      {/** @ts-expect-error TODO: React upgrade props types mismatch */}
      {t('export.exportHistory.loadMore', { count: paginationLimit })}
    </Button>
  );
};

const LoadMoreErrorMessage = ({
  t,
  paginationLimit,
}: {
  t: i18next.TFunction;
  paginationLimit: number;
}) => {
  return (
    <ErrorDescription
      text={t('timeline.dataFetchingErrorDescription', {
        count: paginationLimit,
        ns: 'exports',
      })}
    />
  );
};

export const isLoadingInitialOrMoreData = ({
  loading,
  networkStatus,
}: ExportPaginationResponse): boolean =>
  loading && networkStatus !== NetworkStatus.poll;
