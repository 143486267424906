import { Box, Text, Tooltip, useTooltip } from '@candisio/design-system';
import { Item } from '@react-stately/collections';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TabItem, Tabs } from './Tabs';

interface DocumentHistoryTabsContainerProps {
  chatBox?: ReactNode;
  tabItems: TabItem[];
  disabledTitles?: string[];
  showOnlyProtocol?: boolean;
  defaultSelectedKey?: string;
}

export const DocumentHistoryTabsContainer = ({
  showOnlyProtocol = false,
  tabItems,
  defaultSelectedKey,
  chatBox,
  disabledTitles = [],
}: DocumentHistoryTabsContainerProps) => {
  const [t] = useTranslation();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ placement: 'top' });

  const protocolTabItem = tabItems.filter(
    tab => tab.title === t('document.tabs.history')
  );

  const showTabTooltip = (title?: string) => {
    if (!title) return false;

    return disabledTitles.includes(title);
  };

  return (
    <Tabs
      aria-label="navigation"
      defaultSelectedKey={defaultSelectedKey ?? t('document.tabs.history')}
      chatBox={chatBox}
      disabledKeys={disabledTitles}
      items={showOnlyProtocol ? protocolTabItem : tabItems}
    >
      {tabItems.map(item => {
        const { children, tabTooltip, title } = item;

        return (
          <Item
            key={title}
            textValue={title}
            title={
              <Box
                {...(showTabTooltip(title) && triggerProps)}
                ref={showTabTooltip(title) ? triggerRef : undefined}
              >
                <Text lineHeight="body">{title}</Text>
                {isOpen && (
                  <Tooltip {...tooltipProps} ref={tooltipRef}>
                    {tabTooltip}
                  </Tooltip>
                )}
              </Box>
            }
          >
            {children}
          </Item>
        );
      })}
    </Tabs>
  );
};
