import {
  Box,
  Button,
  Flex,
  Grid,
  SectionHeading,
} from '@candisio/design-system';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FormAction } from 'views/Inbox/DocumentProcessing/components/FormAction';

export interface MonitoringActionsProps {
  /** Called when document should be edited */
  onEdit?: () => void;
  /** Slot for workflow visualization */
  workflowVisualization?: ReactNode;
}

/** Actions for read-only (“monitoring”) processing form */
export const MonitoringActions = ({
  workflowVisualization,
  onEdit,
}: MonitoringActionsProps) => {
  const [t] = useTranslation();

  return (
    <FormAction>
      <Grid gap="space8">
        <Flex gap="space8" alignItems="center" justifyContent="space-between">
          <SectionHeading as="h2" flex="none">
            {t('document.requestApproval.actions.title')}
          </SectionHeading>
          {onEdit && (
            <Button
              onClick={onEdit}
              size="small"
              variant="tertiary"
              flex="none"
            >
              {t('document.requestApproval.actions.edit')}
            </Button>
          )}
        </Flex>

        <Box>{workflowVisualization}</Box>
      </Grid>
    </FormAction>
  );
};
