import {
  Cell,
  Color,
  InfoPanel,
  Flex,
  Table,
  Tag,
  Text,
  defaultTheme,
} from '@candisio/design-system';
import { ExportablePaymentConditionStatus } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Column, Row } from 'react-table';
import { useExportContext } from 'views/Integrations/Export/Context';
import { useGetColumnIds } from 'views/Integrations/Export/hooks/useGetColumnIds';
import { useExportTableMaxHeight } from 'views/Integrations/Export/toolkit/hooks/useExportTableMaxHeight';
import {
  MaskedPaymentConditionsOverlay,
  PaymentConditionsEmptyState,
} from './EmptyState';
import { Header } from './Header';
import {
  PaymentConditionsTableColumnKeys,
  PaymentConditionsTableColumns,
  PaymentConditionsTableData,
  PaymentConditionsTableRow,
} from './types';

export interface ExportPaymentConditionsTableProps {
  data: PaymentConditionsTableData;
  hasWarning: boolean;
  isMasked: boolean;
  showAll: boolean;
}

const translationKeys = {
  [ExportablePaymentConditionStatus.New]: 'paymentConditions.table.status.new',
  [ExportablePaymentConditionStatus.Updated]:
    'paymentConditions.table.status.updated',
} as const;

const StatusCell = ({ value }: { value: ExportablePaymentConditionStatus }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);

  const status = t(translationKeys[value]);

  const isNew = value === ExportablePaymentConditionStatus.New;
  const tagColor: Color = isNew ? 'green' : 'gray';

  return (
    <Tag color={tagColor} variant="secondary">
      {status}
    </Tag>
  );
};

export const ExportPaymentConditionsTable = ({
  data,
  hasWarning,
  isMasked,
  showAll,
}: ExportPaymentConditionsTableProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const navigate = useNavigate();
  const orgId = useOrganizationId();
  const { tableId } = useExportContext();

  const columnIds = useGetColumnIds<PaymentConditionsTableColumnKeys>({
    tableId,
  });

  const defaultColumn = useMemo(
    (): Partial<Column<PaymentConditionsTableRow>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header,
      Cell,
      disableSortBy: isMasked,
    }),
    [isMasked]
  );

  const columns = useMemo(() => {
    const allColumns: PaymentConditionsTableColumns = {
      status: {
        accessor: 'status',
        width: `${defaultTheme.space.space128}`,
        Cell: StatusCell,
        disableFilters: true,
      },
      paymentConditionNumber: {
        accessor: 'paymentConditionNumber',
        width: `${defaultTheme.space.space128}`,
        disableFilters: true,
      },
      value: {
        accessor: 'value',
        width: 'auto',
        disableFilters: true,
      },
    };

    const visibleColumns = columnIds.map(column => allColumns[column]);

    return showAll
      ? visibleColumns.filter(column => column.accessor !== 'status')
      : visibleColumns;
  }, [columnIds, showAll]);

  const handleRowClick = ({
    original: { id },
  }: Row<PaymentConditionsTableRow>) => {
    navigate(`/${orgId}${Routes.SETTINGS}${Routes.PAYMENT_CONDITIONS}/${id}`);
  };

  const height = useExportTableMaxHeight(data.length);
  const showEmptyState = data.length === 0;

  if (showEmptyState) return <PaymentConditionsEmptyState />;

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      overflow="hidden"
      height="100%"
    >
      <Flex direction="column" overflow="hidden" height="100%">
        {hasWarning && (
          <InfoPanel
            variant="warning"
            message={t('paymentConditions.table.warning.bigDataExport')}
          />
        )}
        {isMasked && <MaskedPaymentConditionsOverlay />}
        <Table
          borderTopRadius="0"
          borderBottomRadius="0"
          height={height}
          defaultColumn={defaultColumn}
          columns={columns}
          data={data}
          onRowClick={handleRowClick}
          key={`${tableId}-${columns.join('-')}`}
        />
      </Flex>
      {!isMasked && (
        <Flex
          direction="column"
          width="100%"
          justifyContent="start"
          alignItems="center"
          color="gray500"
          fontSize="basic"
          paddingTop="space16"
        >
          <Text>{t('paymentConditions.table.message.entriesLimit')}</Text>
        </Flex>
      )}
    </Flex>
  );
};
