import { TabPanel, Tabs } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { UseTabsOptions, useTabs } from 'components/Tabs/useTabs';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { PaymentConditionsListData } from 'views/Settings/PaymentConditions/types';
import { SettingsLayout } from 'views/Settings/components/SettingsLayout/SettingsLayout';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from 'views/Settings/utils';
import { PaymentConditionsList } from './PaymentConditionsList';

type Status = 'active' | 'archived';

export interface PaymentConditionsProps {
  data?: PaymentConditionsListData[];
  loading?: boolean;
  numActive?: number;
  numArchived?: number;
  onChangeStatus?: (status: Status) => void;
  onShowImportHistory?: () => void;
  onStartExport?: () => void;
  onShowItem?: (id: string) => void;
  status?: Status;
}

export const PaymentConditions = ({
  data = [],
  loading = false,
  numActive,
  numArchived,
  onChangeStatus,
  onShowImportHistory,
  onStartExport,
  onShowItem,
  status = 'active',
}: PaymentConditionsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { tabPanelProps, tabsProps } = useTabs({
    items: [
      {
        key: 'active',
        title: t('paymentConditions.tabs.active'),
        badge: numActive ? String(numActive) : undefined,
        children: (
          <PaymentConditionsList
            data={data}
            loading={loading}
            onShowItem={onShowItem}
            onStartExport={onStartExport}
            onShowImportHistory={onShowImportHistory}
          />
        ),
      },
      {
        key: 'archived',
        title: t('paymentConditions.tabs.archived'),
        badge: numArchived ? String(numArchived) : undefined,
        children: (
          <PaymentConditionsList
            data={data}
            loading={loading}
            onShowItem={onShowItem}
            onStartExport={onStartExport}
            onShowImportHistory={onShowImportHistory}
          />
        ),
      },
    ],
    onSelectionChange: onChangeStatus as UseTabsOptions['onSelectionChange'],
    selectedKey: status,
  });

  return (
    <SettingsLayout
      actions={<Tabs {...tabsProps} />}
      title={t('paymentConditions.title')}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      <TabPanel {...tabPanelProps} />
      <InfoPanel
        title={t('paymentConditions.infoPanel.title')}
        sections={t('paymentConditions.infoPanel.coreDataApiSections', {
          returnObjects: true,
        })}
      />
    </SettingsLayout>
  );
};
