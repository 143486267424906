import { Button, Grid, MenuButton } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { Organization } from './hooks/useOrganizationsByMembershipList';
import { ALL_ORGANIZATIONS } from './utils';

export interface OrganizationSelectorProps {
  loading: boolean;
  showMarkAllAsReadToggle: boolean;
  hasMoreThanOneOrganization: boolean;
  selectedOrganization: string;
  organizations: Organization[];
  onMarkAllAsRead: () => void;
  onSelectionChange: (key: Key[]) => void;
}

export const OrganizationSelector = ({
  loading,
  organizations,
  onMarkAllAsRead,
  selectedOrganization,
  onSelectionChange,
  showMarkAllAsReadToggle,
  hasMoreThanOneOrganization,
}: OrganizationSelectorProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);

  const menuItems = [
    {
      id: ALL_ORGANIZATIONS,
      label: t('popover.allOrg'),
    },
    ...organizations,
  ];

  const selectedItem = menuItems.find(
    item => item.id === selectedOrganization
  )?.label;

  return (
    <Grid alignItems="center" autoFlow="column">
      {hasMoreThanOneOrganization ? (
        <MenuButton
          variant="tertiary"
          justifySelf="start"
          selectionMode="single"
          autoFocus
          name="organization-selector"
          items={menuItems}
          loading={loading}
          value={[selectedOrganization]}
          onChange={onSelectionChange}
        >
          {selectedItem}
        </MenuButton>
      ) : null}
      {showMarkAllAsReadToggle && (
        <Button
          icon="check"
          variant="tertiary"
          onClick={onMarkAllAsRead}
          justifySelf="end"
        >
          {t('popover.markAllAsRead')}
        </Button>
      )}
    </Grid>
  );
};
