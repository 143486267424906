import { TruncatedText, HeaderProps } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ThreeWayMatchTableRow } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchTable/ThreeWayMatchTable';

const getTranslationKey = (columnId: string): string => {
  const prefix = 'threeWayMatch.table.columns.';

  return prefix.concat(columnId);
};

export const ThreeWayMatchTableHeader = ({
  column,
  isFilteredOrSorted,
}: HeaderProps<ThreeWayMatchTableRow>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  return (
    <TruncatedText
      wordBreak="break-all"
      color={isFilteredOrSorted ? 'gray800' : 'gray500'}
    >
      {t(getTranslationKey(column.id))}
    </TruncatedText>
  );
};
