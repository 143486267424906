import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

type CodeInputFieldProps = {
  readOnly: boolean;
};

export const CodeInputField = ({ readOnly }: CodeInputFieldProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <HookFormTextField
      autoFocus={!readOnly}
      name="projectCode"
      label={t('projectCodes.form.code.label')}
      readOnly={readOnly}
    />
  );
};
