import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Modal,
  Text,
} from '@candisio/design-system';
import { motion } from 'framer-motion';
import { useCookie } from 'hooks/useCookie';
import { Trans } from 'providers/LocaleProvider';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isIOS } from 'utils/osDetection';

const MotionGrid = motion(Grid);

export const AppBanner = () => {
  const modalRef = useRef<HTMLDivElement>(null);
  const BANNER_DISMISSED = 'candis_app_store_banner_dismissed';
  const [cookie, updateCookie] = useCookie(BANNER_DISMISSED, 'false');
  const isDismissed = cookie === 'true';
  const [isVisible, setIsVisible] = useState(!isDismissed && isIOS());
  const [t] = useTranslation();

  const handleBannerClose = () => {
    // set isDismissed cookie to expire after 7 days
    updateCookie('true', 7);
    setIsVisible(false);
  };

  // remove focus from button onload
  // TODO: resolve this issue in the DS
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    modalRef.current?.focus();
  }, [modalRef]);

  return (
    <Modal
      isOpen={isVisible}
      onClose={handleBannerClose}
      background="gray800"
      borderRadius="medium"
      bottom="space7"
      maxWidth="365px"
      overflow="hidden"
      overlayColor="rgba(43, 26, 46, 0.3)"
      padding={0}
      position="absolute"
      ref={modalRef}
      width="100%"
    >
      <MotionGrid
        columnGap="space8"
        padding="space12"
        rowGap="space24"
        templateColumns="auto 1fr"
        templateRows="auto auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
      >
        <Flex gridRow="1" gridColumn="1">
          <Icon color="gray0" icon="logo" size="46px" />
        </Flex>
        <Text
          as="p"
          color="gray0"
          gridRow="1"
          gridColumn="2"
          fontWeight="regular"
          fontSize="small"
          lineHeight="heading"
        >
          <Trans i18nKey="appBanner.message">
            You are currently viewing the desktop version. For the
            <Box as="span" fontStyle="italic">
              full approval experience
            </Box>
            switch to the app.
          </Trans>
        </Text>
        <Flex
          gridRow="2"
          gridColumn="2"
          justifyContent="right"
          columnGap="space12"
        >
          <Button variant="primary" onClick={handleBannerClose}>
            {t('appBanner.closeLabel')}
          </Button>
          <Button
            color="blue"
            onClick={() => window.open(t('appBanner.url'), '_blank')}
            icon="arrowRight"
            iconPosition="right"
            variant="primary"
          >
            {t('appBanner.openLabel')}
          </Button>
        </Flex>
      </MotionGrid>
    </Modal>
  );
};
