import {
  Flex,
  Button,
  Grid,
  InfoPanel,
  Modal,
  Paragraph,
} from '@candisio/design-system';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useTranslation } from 'react-i18next';
import {
  MAX_DOCUMENTS_FOR_ORIGINAL_FILES_ZIP_DOWNLOAD,
  MIN_DOCUMENTS_FOR_ORIGINAL_FILES_ZIP_DOWNLOAD,
} from 'views/Archive/Documents/ArchiveDocuments';

interface DownloadOriginalFilesModalProps {
  onPrepareDownload: () => Promise<void>;
  createOriginalFilesZipFileLoading: boolean;
  selectedDocumentsCount: number;
  isTableLoading: boolean;
}

export const DownloadOriginalFilesModal = ({
  onPrepareDownload,
  createOriginalFilesZipFileLoading,
  selectedDocumentsCount,
  isTableLoading,
  ...restProps
}: DownloadOriginalFilesModalProps) => {
  const [t] = useTranslation();

  const currentUser = useCurrentUser();

  const infoMessage = t('archive:download.downloadOriginalFilesModal.info', {
    userEmailAddress: currentUser?.email,
  });

  let errorMessage = null;

  if (!isTableLoading) {
    if (
      selectedDocumentsCount > MAX_DOCUMENTS_FOR_ORIGINAL_FILES_ZIP_DOWNLOAD
    ) {
      errorMessage = t('archive:download.error.maxAmountReached', {
        maxAmount: MAX_DOCUMENTS_FOR_ORIGINAL_FILES_ZIP_DOWNLOAD,
      });
    } else if (
      selectedDocumentsCount < MIN_DOCUMENTS_FOR_ORIGINAL_FILES_ZIP_DOWNLOAD
    ) {
      errorMessage = t('archive:download.error.noDocumentsSelected');
    }
  }

  const hasError = errorMessage !== null;

  return (
    <Modal
      closeLabel={t('common.close')}
      width="500px"
      title={t('archive:download.downloadOriginalFilesModal.title')}
      {...restProps}
    >
      <Grid
        gap="space16"
        templateRows="auto 1fr auto"
        height="100%"
        overflow="hidden"
      >
        <Paragraph>
          {t('archive:download.downloadOriginalFilesModal.description')}
        </Paragraph>
        <InfoPanel
          variant={hasError ? 'error' : 'information'}
          message={errorMessage || infoMessage}
        />
        <Flex paddingTop="space64" justifySelf="flex-end">
          <Button
            loading={createOriginalFilesZipFileLoading}
            disabled={
              selectedDocumentsCount >
                MAX_DOCUMENTS_FOR_ORIGINAL_FILES_ZIP_DOWNLOAD ||
              selectedDocumentsCount <
                MIN_DOCUMENTS_FOR_ORIGINAL_FILES_ZIP_DOWNLOAD
            }
            size="small"
            onClick={onPrepareDownload}
            justifySelf="end"
          >
            {t('archive:download.downloadOriginalFilesModal.triggerDownload')}
          </Button>
        </Flex>
      </Grid>
    </Modal>
  );
};
