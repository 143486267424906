import { Button, FieldContainer, Flex, Grid } from '@candisio/design-system';
import { TextInput } from 'components/TextInput/TextInput';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WysiwygEditor } from './components/WysiwygEditor';
import { EmailEditorProps } from './types';
import {
  checkEmailErrors,
  getEmailAddresses,
  isValidEmailAddresses,
} from './utils';

export const EmailEditor = (props: EmailEditorProps) => {
  const [t] = useTranslation();
  const [subject, setSubject] = useState(props.subject || null);
  const [emailTo, setEmailTo] = useState(props.emailTo || null);
  const [htmlText, setHtmlText] = useState(props.htmlText);
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const emailLabel = t('emailEditor.email.label');
  const subjectLabel = t('emailEditor.subject.label');
  const bodyLabel = t('emailEditor.body.label');

  const sendEmailButton = (
    <Button
      alignSelf="end"
      width="fit-content"
      loading={props.loading}
      onClick={() => {
        setShowValidationErrors(true);
        if (subject && emailTo && htmlText && isValidEmailAddresses(emailTo)) {
          props.onSubmit({
            subject,
            emailTo: getEmailAddresses(emailTo),
            htmlText,
          });
        } else {
          if (subject === null) {
            setSubject('');
          }

          if (emailTo === null) {
            setEmailTo('');
          }
        }
      }}
    >
      {t('common.send')}
    </Button>
  );

  return (
    <Grid gap="space16" width="37.5rem">
      <TextInput
        required
        autoComplete={props.preventAutocomplete ? 'off' : 'on'}
        alert={showValidationErrors && checkEmailErrors(t, emailTo)}
        label={emailLabel}
        placeholder={props.emailToPlaceholder}
        name="email"
        value={emailTo || ''}
        onChange={e => {
          setShowValidationErrors(false);
          setEmailTo(e.currentTarget.value);
        }}
        onBlur={e => {
          setShowValidationErrors(true);
          setEmailTo(e.currentTarget.value);
        }}
      />
      <TextInput
        required
        placeholder={props.subjectPlaceholder}
        alert={
          subject === ''
            ? t('validation.fields.required', {
                label: subjectLabel,
              })
            : ''
        }
        label={subjectLabel}
        name="subject"
        value={subject || ''}
        onChange={e => {
          setSubject(e.currentTarget.value);
        }}
      />

      <FieldContainer label={bodyLabel}>
        <WysiwygEditor value={htmlText ?? ''} onChange={setHtmlText} />
      </FieldContainer>

      <Flex gap="space16" direction="column">
        {props.aboveSendElement}

        {props.feedbackButton ? (
          <Grid autoFlow="column" justifyContent="space-between">
            {props.feedbackButton}
            {sendEmailButton}
          </Grid>
        ) : (
          sendEmailButton
        )}
      </Flex>
    </Grid>
  );
};
