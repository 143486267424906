import { Button, TruncatedText } from '@candisio/design-system';

export interface BackButtonProps {
  children: string;
  onClick?: () => void;
}

export const BackButton = ({ children, onClick }: BackButtonProps) => {
  return (
    <Button
      icon="arrowLeft"
      iconPosition="left"
      size="small"
      variant="tertiary"
      onClick={onClick}
    >
      <TruncatedText>{children}</TruncatedText>
    </Button>
  );
};
