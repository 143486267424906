import { Button } from '@candisio/design-system';
import { SplitDS } from 'components/Form/SplitBookingsForm/types';
import {
  NewSplitForPurchaseOrders,
  SapFreightPackageBookingFields,
} from 'containers/SplitBookings/components/NewSplit/NewSplitForPurchaseOrders';
import { PurchaseOrderType } from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface AddNewSplitProps {
  type?: PurchaseOrderType;
  onClick: (item?: SapFreightPackageBookingFields) => void;
  isLoading?: boolean;
  currentBookings?: SplitDS[];
}

export const NewSplit = ({
  type,
  onClick,
  isLoading,
  currentBookings,
}: AddNewSplitProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);
  const { shouldUseSapPurchaseOrder } = useSap();

  const isPurchaseOrderConnected =
    shouldUseSapPurchaseOrder && type !== undefined;

  if (isPurchaseOrderConnected) {
    return (
      <NewSplitForPurchaseOrders
        onClick={onClick}
        purchaseOrderType={type}
        currentBookings={currentBookings}
      />
    );
  }

  if (isPurchaseOrderConnected) {
    return null;
  }

  return (
    <Button
      onClick={() => onClick()}
      icon="plus"
      variant="secondary"
      disabled={isLoading}
      loading={isLoading}
    >
      {t('actions.addNewSplitButton')}
    </Button>
  );
};
