import { Flex } from '@candisio/design-system';
import { UPSELL_PLUS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import { useDatev } from 'orgConfig/datev';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { ReWeActivationForm } from 'views/Settings/PaymentsSettings/ReWeActivationForm';

export const PaymentsEntitlementsPromo = () => {
  const organization = useFullOrganization();
  const { bdsBought, bdsConnected, hide: hideDatev } = useDatev();

  return (
    <Flex direction="column" gap="space16">
      <UpsellPromo
        feature={FEATURE.SEPA_XML}
        utmSource={UPSELL_PLUS_UTM_SOURCE.PAYMENT_SETTINGS_SEPA_XML}
      />
      {!hideDatev && !(bdsConnected && bdsBought) && (
        <ReWeActivationForm
          isActive={!!organization?.exportConfiguration?.isReweEnabled}
        />
      )}
    </Flex>
  );
};
