import { Grid, defaultTheme } from '@candisio/design-system';
import { ComponentProps, useCallback, useId, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { PdfInvoiceSkeleton } from '../PdfInvoiceSkeleton';
import { PdfViewerProps } from '../PdfViewer';
import { PdfViewerError } from '../PdfViewerError';
import { DocumentWrapper } from './DocumentWrapper';
import { PdfStatus, options } from './utils';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

const StyledPdf = styled(Grid)`
  .react-pdf__Page__canvas {
    aspect-ratio: 1 / ${Math.SQRT2};
    border-radius: ${defaultTheme.radii.medium};
  }
`;

export interface ReactPdfThumbnailProps extends PdfViewerProps {}

export const ReactPdfThumbnail = ({ documentFile }: ReactPdfThumbnailProps) => {
  const componentId = useId();

  const [status, setStatus] = useState<PdfStatus>('idle');
  const [isPdfProtected, setIsPdfProtected] = useState(false);

  // called when main document is loaded
  const onDocumentLoadSuccess: NonNullable<
    ComponentProps<typeof DocumentWrapper>['onLoadSuccess']
  > = useCallback(() => setStatus('loaded'), []);

  const handleLoadProgress = useCallback(() => setStatus('loading'), []);
  const handleLoadError = useCallback(() => setStatus('error'), []);

  // called when PDF asks for a password
  // we treat document as loaded and set its page to exactly one (the page with password)
  const handleOnPassword = useCallback(() => {
    setStatus('loaded');
    setIsPdfProtected(true);
  }, []);

  const showLoader =
    status === 'idle' || status === 'loading' || isPdfProtected;
  const showError = status === 'error';
  const showDocument = status === 'loaded';

  return (
    <Grid
      id={componentId}
      data-cy="document-thumbnail-body"
      height="inherit"
      width="inherit"
      /** Internal code of react-pdf modifies z-index, so we have to reset it here to 0 */
      zIndex={0}
      justifyContent="center"
      overflow="hidden"
      style={{ aspectRatio: Math.SQRT1_2 + '' }}
    >
      {showLoader && <PdfInvoiceSkeleton />}
      {showError && <PdfViewerError />}
      <StyledPdf visibility={showDocument ? 'visible' : 'hidden'}>
        <Document
          externalLinkTarget="_blank"
          file={documentFile?.url}
          onLoadProgress={handleLoadProgress}
          onLoadError={handleLoadError}
          onLoadSuccess={onDocumentLoadSuccess}
          onPassword={handleOnPassword}
          options={options}
        >
          <Page pageNumber={1} height={64 * Math.SQRT2} />
        </Document>
      </StyledPdf>
    </Grid>
  );
};
