import { ErrorMessages } from 'utils/zodFormValidation';
import { reimbursementFormSchema } from './reimbursementFormSchema';

type ExcludeKeys =
  | 'note'
  | 'targetMembership'
  | 'suggestedCostCenter'
  | 'suggestedCostCenter.value'
  | 'suggestedCostCenter.inputValue'
  | 'suggestedCostObject'
  | 'suggestedCostObject.value'
  | 'suggestedCostObject.inputValue';

/** Gets reimbursement form validation error messages */
export const reimbursementFormErrorMessages: ErrorMessages<
  ReturnType<typeof reimbursementFormSchema>,
  ExcludeKeys
> = {
  title: {
    label: 'reimbursementView.rightSection.form.title.label',
  },
  iban: {
    label: 'reimbursementView.rightSection.form.iban.label',
  },
  accountsPayableNumber: {
    label: 'reimbursementView.rightSection.form.accountsPayableNumber.label',
    custom: {
      translationKey:
        'reimbursementView.rightSection.form.accountsPayableNumber.duplicate',
    },
  },
};
