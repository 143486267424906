import { Text, TruncatedText } from '@candisio/design-system';
import { friendlyFormatIBAN } from 'ibantools';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps as ReactTableCellProps } from 'react-table';

export type PaymentInfoCellValue = {
  iban?: string;
  bankAccountNumber?: string;
  swiftCode?: string;
};

export type PaymentInfoCellProps<
  TTableData extends object,
  TCellValue = PaymentInfoCellValue,
> = ReactTableCellProps<TTableData, TCellValue | undefined>;

/** Payment Info cell */
export const PaymentInfoCell = <TTableData extends object>({
  value,
}: PaymentInfoCellProps<TTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  if (isNil(value)) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  const { iban, bankAccountNumber, swiftCode } = value;
  if (isNil(iban) && isNil(bankAccountNumber)) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  const formattedIban = friendlyFormatIBAN(iban);

  const displayValueWithSwift = `${formattedIban ?? ''}${
    bankAccountNumber ?? ''
  } / ${swiftCode}`;

  const displayValueWithoutSwift = `${formattedIban ?? ''}${
    bankAccountNumber ?? ''
  }`;

  return (
    <TruncatedText charsAfterEllipsis={5}>
      {swiftCode ? displayValueWithSwift : displayValueWithoutSwift}
    </TruncatedText>
  );
};
