import { Grid, Switch } from '@candisio/design-system';
import { ReactNode, forwardRef, useCallback } from 'react';
import { useController } from 'react-hook-form';

interface SwitchFieldWithPanelProps {
  isSwitchToggleable?: boolean;
  text: ReactNode;
  children: ReactNode;
  switchFieldLabel: string;
  switchFieldName: string;
  onSwitchChange?: () => void;
}

export const SwitchFieldWithPanel = forwardRef<
  HTMLLabelElement,
  SwitchFieldWithPanelProps
>(
  (
    {
      text,
      switchFieldName,
      switchFieldLabel,
      children,
      isSwitchToggleable = true,
      onSwitchChange,
      ...restProps
    },
    ref
  ) => {
    const { field } = useController({ name: switchFieldName });
    const { value, onChange } = field;

    const handleChange = useCallback(
      (isChecked: boolean) => {
        if (isSwitchToggleable) {
          onSwitchChange?.();
          onChange(isChecked);
        }
      },
      [isSwitchToggleable, onChange, onSwitchChange]
    );

    return (
      <Grid borderRadius="medium" templateRows="auto 1fr">
        <Grid
          padding="space16"
          gap="space8"
          alignItems="center"
          borderBottomRadius="none"
          borderTopRadius="medium"
          templateColumns="auto 1fr"
          background="gray200"
        >
          <Switch
            label={switchFieldLabel}
            checked={value}
            onChange={handleChange}
            ref={ref}
            {...restProps}
          />
          {text}
        </Grid>
        <Grid maxHeight="450px">{children}</Grid>
      </Grid>
    );
  }
);
