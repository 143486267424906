import { Button, Flex, Text, Image } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { ApiAuthCodeField } from 'views/ApiAuthCodePage/elements/ApiAuthCodeField';
import { LanguageMenuButton } from 'views/ApiAuthCodePage/elements/LanguageMenuButton';
import { useApiAuthCode } from 'views/ApiAuthCodePage/hooks/useApiAuthCode';
import candisLogoSrc from './assets/candis-logo.png';

export const ApiAuthCodePage = () => {
  const { t } = useTranslation();
  const {
    authCodeExpired,
    authCode,
    showQueryStingError,
    counter,
    handleCopy,
  } = useApiAuthCode();

  const shouldDisplayAuthCodeTimer = authCode && !showQueryStingError;

  return (
    <Flex background="gray800" justifyContent="center" height="100%">
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="space24"
        height="100%"
      >
        <Flex
          background="gray800"
          justifyContent="center"
          alignItems="center"
          direction="column"
          width="290px"
          gap="space16"
          height="100%"
        >
          <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
            gap="space48"
            direction="column"
          >
            <Image src={candisLogoSrc} alt="" width="100%" />
            <Text fontSize="basic" color="white">
              {t('apiAuthCodePage.title')}
            </Text>
            <ApiAuthCodeField />
          </Flex>
          <Flex
            paddingTop="space16"
            gap="space16"
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Button width="space256" color="blue" onClick={handleCopy}>
              {t('apiAuthCodePage.copyToClipboard.buttonLabel')}
            </Button>
            {shouldDisplayAuthCodeTimer && (
              <Text color={authCodeExpired ? 'red' : 'white'}>
                {authCodeExpired
                  ? t('apiAuthCodePage.authCodeExpired')
                  : t('apiAuthCodePage.remainingTime', {
                      count: counter,
                    })}
              </Text>
            )}
          </Flex>
        </Flex>
        <LanguageMenuButton />
      </Flex>
    </Flex>
  );
};
