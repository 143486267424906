import {
  Avatar,
  Flex,
  Grid,
  Heading,
  Link,
  Paragraph,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { CandisLayout } from 'containers/credit-cards/CreditCard/Candis/CandisLayout';
import { ExchangeRatePanel } from 'containers/credit-cards/CreditCardDrawer/components/ExchangeRatePanel';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useGetVendorForRecurringPaymentsById } from 'views/CreditCards/hooks/useGetVendorForRecurringPaymentsById';
import { useCreditCardPlaceholderDetails } from '../hooks/useCreditCardPlaceholderDetails';

export const CardSection = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { isCardManager } = useUserRoles();
  const { vendorId } = useParams<{ vendorId?: string }>();

  const { vendorDetails } = useGetVendorForRecurringPaymentsById({ vendorId });

  const { cardDetails } = useCreditCardPlaceholderDetails();

  return (
    <Grid
      background="gray200"
      alignContent="start"
      placeContent="start"
      placeItems="start"
      padding="space24"
      gap="space32"
    >
      <Grid gap="space40">
        <Heading as="h3">
          {isCardManager
            ? t('createCard.issueNewCard')
            : t('createCard.newCardRequest')}
        </Heading>
        <Grid gap="space16">
          {vendorDetails.id && vendorDetails.name && (
            <Flex gap="space4">
              <Avatar
                img={vendorDetails.logoUrl}
                hasTooltip={false}
                name={vendorDetails.name}
                size="medium"
              />
              <TruncatedText
                maxWidth="50ch"
                fontSize="xxlarge"
                lineHeight="paragraph"
              >
                {vendorDetails.name}
              </TruncatedText>
            </Flex>
          )}
          <CandisLayout
            showFooter={true}
            cardType={cardDetails.cardType}
            cardDetails={cardDetails}
          />
        </Grid>
      </Grid>
      <Grid gap="space32">
        <Grid gap="space8">
          <Heading as="h3">{t('createCard.candisCreditCard')}</Heading>
          <Text fontSize="basic" lineHeight="paragraph">
            {t('createCard.cardAndFooter.footNote')}
          </Text>
          <Link href={t('createCard.cardAndFooter.link')} external>
            {t('createCard.cardAndFooter.linkText')}
          </Link>
        </Grid>
        <Grid>
          <Paragraph color="gray500" fontSize="xsmall">
            {t('dashboard.drawer.form.footerText')}
          </Paragraph>
          <ExchangeRatePanel />
        </Grid>
      </Grid>
    </Grid>
  );
};
