import {
  DocumentsTableData,
  StatusType,
} from 'components/DocumentsTable/types';
import {
  DocumentEdge,
  DocumentStatus,
  EInvoiceFormat,
  InboxInvoiceDocumentEdge,
} from 'generated-types/graphql.types';

export const isXRechnung = (
  eInvoiceFormat?: EInvoiceFormat | null
): boolean => {
  switch (eInvoiceFormat) {
    case EInvoiceFormat.XrechnungUbl:
    case EInvoiceFormat.XrechnungCii:
    case EInvoiceFormat.ZugferdXrechnung:
      return true;
    default:
      return eInvoiceFormat?.includes('XRECHNUNG') ?? false;
  }
};

export const mapDocumentStatusToStatus = (
  documentStatus: DocumentStatus
): StatusType => {
  switch (documentStatus) {
    case DocumentStatus.Approved:
      return 'approved';
    case DocumentStatus.Exported:
      return 'exported';
    case DocumentStatus.Exporting:
      return 'exporting';
    case DocumentStatus.New:
      return 'new';
    case DocumentStatus.Open:
      return 'open';
    case DocumentStatus.Processing:
      return 'processing';
    case DocumentStatus.Rejected:
      return 'rejected';
    case DocumentStatus.Archived:
      return 'archived';
  }
};

/**
 * @deprecated
 */
export const mapToDocumentsTableDataDeprecated = (
  edge: DocumentEdge
): DocumentsTableData => {
  const documentsTableData: DocumentsTableData = {
    id: edge.node.id,
    status: {
      status: mapDocumentStatusToStatus(edge.node.status),
      isOverdue: !!edge.node.isOverdue,
      isDuplicate: !!edge.node.isDuplicate,
      isEInvoice: !!edge.node.isEInvoice,
      hasAttachments: edge.node.hasAttachments,
    },
    eInvoice: {
      format: edge.node.eInvoice?.format,
    },
    contact:
      edge.node.contact?.value.name.value ??
      edge.node.extractedContact?.name?.value,
    createdAt: new Date(edge.node.createdAt),
    fileName: edge.node.documentFile?.name,
    fileSize: edge.node.documentFile?.size ?? undefined,
    tags: edge.node.tags,
  };

  return documentsTableData;
};

export const mapToDocumentsTableData = (
  edge: InboxInvoiceDocumentEdge
): DocumentsTableData => {
  const documentsTableData: DocumentsTableData = {
    id: edge.node.id,
    status: {
      status: mapDocumentStatusToStatus(edge.node.status),
      isOverdue: !!edge.node.isOverdue,
      isDuplicate: !!edge.node.isDuplicate,
      isEInvoice: !!edge.node.isEInvoice,
      hasAttachments: !!edge.node.hasAttachments,
    },
    eInvoice: {
      format: edge.node.eInvoice?.format,
    },
    contact: edge.node.contact?.name ?? undefined,
    createdAt: edge.node.createdAt ? new Date(edge.node.createdAt) : undefined,
    fileName: edge.node.documentFile?.name,
    fileSize: edge.node.documentFile?.size ?? undefined,
    tags: edge.node.tags,
  };

  return documentsTableData;
};
