import { Box, Flex, Text } from '@candisio/design-system';
import { ReactNode } from 'react';

interface TitleProps {
  children: ReactNode;
}

export const Title = ({ children }: TitleProps) => (
  <Text as="div" fontSize="xlarge">
    {children}
  </Text>
);

interface TopRowWrapperProps {
  children: ReactNode;
}

export const TopRowWrapper = ({ children }: TopRowWrapperProps) => (
  <Flex alignItems="center" height="3rem" minHeight="space48">
    {children}
  </Flex>
);

interface WrapperProps {
  children: ReactNode;
}

export const Wrapper = ({ children }: WrapperProps) => (
  <Box
    borderRadius="medium"
    padding="space16"
    height="290px"
    width="100%"
    position="relative"
    background="gray50"
  >
    {children}
  </Box>
);
