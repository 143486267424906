import { Flex, Grid, Text } from '@candisio/design-system';
import { ReactElement } from 'react';

export type CheckProp = {
  key: string;
  text: string | ReactElement;
  icon: ReactElement;
  emailDescription?: string;
  state: string;
};
export type CheckProps = CheckProp[];

export const CheckList = ({
  checks,
  retries,
}: {
  checks: CheckProps;
  retries?: number;
}) => {
  return (
    <Grid
      key={retries}
      as="ul"
      listStyle="none"
      gap="space4"
      padding="0"
      justifyContent="left"
    >
      {checks.map(({ icon, text, key }) => (
        <CheckItem key={key} icon={icon} text={text} />
      ))}
    </Grid>
  );
};

interface InfoItemProps {
  icon: ReactElement;
  text: string | ReactElement;
}

const CheckItem = ({ icon, text }: InfoItemProps) => {
  return (
    <Flex as="li" alignItems="center">
      <Flex gap="space4">
        {icon}
        <Text>{text}</Text>
      </Flex>
    </Flex>
  );
};
