import { Button, Flex, Image, Tag, Text } from '@candisio/design-system';
import { EcmDocumentsTable } from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { PromotionLink } from 'components/PromotionLink/PromotionLink';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import { thumbnails } from 'containers/Entitlements/components/Upsell/utils';
import { appointmentLinks } from 'containers/Entitlements/toolkit/utils';
import { Locale } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { noop } from 'lodash';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ArchiveViewLayout } from 'views/Archive/components/ArchiveViewLayout';
import { useGetEcmContractsTableConfigs } from 'views/Archive/EcmContracts/hooks/useGetEcmContractsTableConfigs';
import { TabView } from 'views/Inbox/models';
import { contractsData } from 'containers/Entitlements/components/Upsell/dummyData';
import { VideoCard } from 'containers/Entitlements/components/Upsell/components/VideoCard';
import { PromotionWrapper, TableWrapper, Wrapper } from '../Wrapper';
import { CONTRACT_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';

export const ContractsPromotionArchive = () => {
  const [newSettingsPromotionsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.newSettingsPromotions,
  ]);

  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_ECM_CONTRACTS}>
      {newSettingsPromotionsFF ? <NewPromotions /> : <OldPromotions />}
    </ArchiveViewLayout>
  );
};

const OldPromotions = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const thumbnail = thumbnails[FEATURE.CONTRACTS][locale];

  return (
    <Flex direction="column" gap="space16" paddingX="space12" maxWidth="1300px">
      <Tag color="purple" variant="secondary">
        {t('promo.tag')}
      </Tag>
      <Text as="h2" fontSize="xxlarge">
        <Trans t={t} i18nKey="promo.contractTable.title">
          <Text fontWeight="bold">Contract management</Text>
          Behalte den Überblick über laufende Verträge und Fristen
        </Trans>
      </Text>
      <VideoCard feature={FEATURE.CONTRACTS} withFading>
        <Image src={thumbnail} alt="" width="100%" opacity=".7" />
      </VideoCard>
      <Flex
        position="absolute"
        bottom="-4vmax"
        direction="column"
        alignItems="center"
        gap="space16"
        alignSelf="center"
        width="fit-content"
      >
        <PromotionLink
          href={t(appointmentLinks[FEATURE.CONTRACTS])}
          variant="calendar"
        >
          {t('promo.modal.bookAppointment')}
        </PromotionLink>
        <UpsellPromo
          feature={FEATURE.CONTRACTS}
          utmSource={CONTRACT_UTM_SOURCE.ARCHIVE}
        >
          <Button variant="tertiary" color="promo" width="100%">
            {t('promo.contractTable.moreInfoCTA')}
          </Button>
        </UpsellPromo>
      </Flex>
    </Flex>
  );
};

const tableData = Array(10)
  .fill(0)
  .flatMap((_, i) =>
    contractsData.map((el, j) => ({ ...el, id: i + i + j + '' }))
  ) as EcmDocumentsTableData[];

const NewPromotions = () => {
  const { contractManagementPromotion } = usePromotionContent();
  const { availableDocumentColumnIds, configurationsTable } =
    useGetEcmContractsTableConfigs({ filters: [], sortBy: [] });

  return (
    <Wrapper>
      <TableWrapper>
        <EcmDocumentsTable
          context="contracts"
          columns={availableDocumentColumnIds}
          configurationsTable={configurationsTable}
          data={tableData}
          selectedDocumentsCount={0}
          onSearchChange={noop}
          onRowClick={noop}
          onFilter={noop}
          onEndReached={noop}
          onUpdateConfigurations={noop}
          onSort={noop}
          onResetTableConfigurations={noop}
        />
      </TableWrapper>
      <PromotionWrapper>
        <PromotionsProvider promotions={[contractManagementPromotion]}>
          <PromotionCard
            promotion={contractManagementPromotion}
            closable={false}
          />
        </PromotionsProvider>
      </PromotionWrapper>
    </Wrapper>
  );
};
