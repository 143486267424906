import { BuiltinRoleName } from 'generated-types/graphql.types';
import { Role } from 'views/Settings/TeamMembers/hooks/useGetRolesForUser';
import { TeamMemberFormOutput } from '../../teamMemberFormSchema';

export type FormDataRole = TeamMemberFormOutput['roles'][number];

const roleOrder = [
  BuiltinRoleName.Admin,
  BuiltinRoleName.CardManager,
  BuiltinRoleName.Accountant,
  BuiltinRoleName.Requester,
  BuiltinRoleName.Approver,
];

export const sortRoles = (roles: Role[]): Role[] => {
  return roles.sort((a, b) => {
    const indexA = roleOrder.indexOf(a.name as BuiltinRoleName);
    const indexB = roleOrder.indexOf(b.name as BuiltinRoleName);

    return indexA - indexB;
  });
};

const roleCoveredByMap: Record<string, string[]> = {
  [BuiltinRoleName.Admin]: [BuiltinRoleName.Owner],
  [BuiltinRoleName.CardManager]: [],
  [BuiltinRoleName.Accountant]: [BuiltinRoleName.Admin],
  [BuiltinRoleName.Requester]: [
    BuiltinRoleName.Admin,
    BuiltinRoleName.Accountant,
  ],
  [BuiltinRoleName.Approver]: [BuiltinRoleName.Admin],
  [BuiltinRoleName.Owner]: [],
  // Makes the object indexable by string, but must have known roles as keys
} satisfies Record<BuiltinRoleName, BuiltinRoleName[]>;

const isBuiltinRole = (role: FormDataRole): boolean =>
  role.type === 'BuiltinRole';

export const coveredByExistingRole = (
  inputRole: FormDataRole,
  selectedRoles: FormDataRole[]
): FormDataRole | undefined => {
  if (!isBuiltinRole(inputRole)) {
    return;
  }

  const coveredRoles = roleCoveredByMap[inputRole.name];

  return selectedRoles.find(
    role => isBuiltinRole(role) && coveredRoles.includes(role.name)
  );
};

// Filters out roles that are already covered by a selected role that has
// a higher level of permissions
export const filterAlreadyCoveredRoles = (roles: FormDataRole[]) => {
  return roles.filter(role => !coveredByExistingRole(role, roles));
};
