import React from 'react';
import { Box } from '../../Atoms/Box';
import { Flex } from '../../Atoms/Flex';
import { Grid } from '../../Atoms/Grid';
import { Icon } from '../../Atoms/Icon';
import { Text } from '../../Atoms/Typography/Text';
import { useTheme } from '../../Theme';
import { LayoutProps, StandardHTMLAttributes } from '../../types';

const sizeToPropsMap = {
  small: {
    fontSize: 'xsmall',
    iconSize: 'space12',
  },
  normal: {
    fontSize: 'small',
    iconSize: 'space16',
  },
  large: {
    fontSize: 'basic',
    iconSize: 'space20',
  },
} as const;

type InfoPanelType = keyof ReturnType<typeof useTheme>['infoPanel'];
type InfoPanelSize = keyof typeof sizeToPropsMap;

export interface InfoPanelProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLDivElement> {
  title?: string;
  variant?: InfoPanelType;
  message?: string;
  size?: InfoPanelSize;
}

/**
 * Info panel component to display messages to the user.
 * [Storybook]{@link https://candisio.github.io/design-system/https://candisio.github.io/design-system/?path=/story/molecules-infopanel--error}
 *
 * @param {string} [title] Optional Component title component
 * @param {InfoPanelType} [variant] Component theme variant
 * @param {string} [message] Optional message
 * @param {InfoPanelSize} [size] Optional size of infopanel. Default large.
 */
export const InfoPanel = React.forwardRef<HTMLDivElement, InfoPanelProps>(
  (
    {
      title,
      variant = 'default',
      message,
      size = 'normal',
      children,
      ...restProps
    },
    ref
  ) => {
    const { infoPanel } = useTheme();
    const { fontSize, iconSize } = sizeToPropsMap[size];
    const { icon, backgroundColor, color, border } = infoPanel[variant];

    return (
      <Grid
        {...restProps}
        ref={ref}
        width="100%"
        color={color}
        fontSize={fontSize}
        background={backgroundColor}
        {...(border && { border })}
        borderRadius="medium"
        templateColumns="auto 1fr"
        templateRows="auto auto"
        columnGap="space8"
        rowGap={message ? 'space4' : '0'}
        padding={size === 'small' ? 'space4' : 'space16'}
      >
        {icon && (
          <Flex
            gridRow="1"
            gridColumn="1"
            alignItems={title ? 'center' : 'start'}
          >
            <Icon icon={icon.type} color={icon.color} size={iconSize} />
          </Flex>
        )}

        {title && (
          <Text
            gridRow="1"
            gridColumn="2"
            fontWeight="semibold"
            lineHeight="paragraph"
          >
            {title}
          </Text>
        )}
        <Box gridRow={title ? '2' : '1'} gridColumn="2">
          {message && (
            <Text gridRow="2" gridColumn="2" lineHeight="body">
              {message}
            </Text>
          )}
          {children}
        </Box>
      </Grid>
    );
  }
);
