import { Nullable } from 'utils/extractors';
import { ErrorMessages } from 'utils/zodFormValidation';
import { nonEmptyString } from 'utils/zodFormValidation/Schemas/nonEmptyString';
import { z } from 'zod';

export const ownerSchema = z.object({
  owner: nonEmptyString,
});

export type OrganizationOwnerFormOutput = z.infer<typeof ownerSchema>;

export type OrganizationOwnerFormValues = Nullable<OrganizationOwnerFormOutput>;

export type OrganizationOwnerFormErrors = ErrorMessages<typeof ownerSchema>;

export const ownerErrorMessages: OrganizationOwnerFormErrors = {
  owner: { label: 'settings:organisation.owner.title' },
};
