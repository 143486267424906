import { Grid } from '@candisio/design-system';
import { SearchField } from 'components/SearchField/SearchField';
import {
  Configuration,
  ConfigurationsMenu,
} from 'components/Table/Configurations/ConfigurationsMenu';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface ContactTableToolbarProps {
  search: string;
  configurations: Configuration[];
  isLoadingConfigs?: boolean;
  onUpdateConfigurations: (configurations: Configuration[]) => void;
  onResetConfigurations: () => void;
  onSearch: (querySearch: string) => void;
  additionalContent?: ReactNode;
}

export const ContactTableToolbar = ({
  configurations,
  search,
  isLoadingConfigs,
  onSearch,
  onUpdateConfigurations,
  onResetConfigurations,
  additionalContent,
}: ContactTableToolbarProps) => {
  const [t] = useTranslation();

  return (
    <Grid
      templateColumns="24rem auto"
      borderTopRadius="basic"
      alignItems="center"
      background="white"
      borderBottom="1px solid gray200"
      padding="space12"
    >
      <SearchField
        clearTooltip={t('settings.contacts.search.clearTooltip')}
        placeholder={t('settings.contacts.search.placeholder')}
        value={search}
        onChange={onSearch}
      />
      <Grid
        autoFlow="column"
        justifySelf="right"
        alignContent="center"
        gap="space16"
      >
        {additionalContent}
        <ConfigurationsMenu
          configurationItems={configurations}
          onUpdateConfigurations={onUpdateConfigurations}
          onResetConfigurations={onResetConfigurations}
          isLoading={isLoadingConfigs}
        />
      </Grid>
    </Grid>
  );
};
