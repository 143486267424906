import { Button, usePopover } from '@candisio/design-system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionConfirm } from './ActionConfirm';
import { Attachment } from './AttachmentItem';
import { useDetachAction } from './hooks/useDetachAction';

export const attachmentItemTransition =
  'all 0.25s cubic-bezier(0.87, 0, 0.13, 1) 0.2s';

export const DetachActionButton = ({
  isFocused,
  isSelected,
  attachment,
}: {
  isFocused: boolean;
  isSelected: boolean;
  attachment: Attachment;
}) => {
  const [t] = useTranslation();

  const [isDetachPending, setIsDetachPending] = useState(false);
  const handleDetach = useDetachAction(attachment, setIsDetachPending);

  const {
    close: closePopover,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover();

  const isVisible = isFocused || isSelected;

  const warningText = t(
    'document.compact.history.documentAttachment.detach.warning'
  );

  return (
    <>
      <Button
        {...triggerProps}
        ref={triggerRef}
        data-test-id="detach-attachments-button"
        color="blue"
        size="small"
        variant="tertiary"
        disabled={isDetachPending}
        opacity={isVisible ? '1' : '0'}
        visibility={isVisible ? 'visible' : 'hidden'}
        style={{ transition: attachmentItemTransition }}
      >
        {t('document.compact.history.documentAttachment.detach.label')}
      </Button>
      <ActionConfirm
        ref={popoverRef}
        informationText={warningText}
        popoverProps={popoverProps}
        isActionPending={isDetachPending}
        closePopover={closePopover}
        handleConfirm={handleDetach}
      />
    </>
  );
};
