import { Grid, Text } from '@candisio/design-system';
import { Collapse } from 'components/History/items/Collapse';
import { SingleBookingUpdatedEvent } from 'generated-types/graphql.types';
import { getTranslationContext } from 'orgConfig';
import { useTranslation } from 'react-i18next';
import { MetaDataWrapper } from '../MetaDataWrapper';
import { BookingField } from './SplitBookingUpdatedComponent';

export const SingleBookingUpdatedComponent = ({
  fields,
}: SingleBookingUpdatedEvent) => {
  const [tCommon] = useTranslation();
  const title = tCommon('document.compact.history.bookings.single.title', {
    count: fields.length,
  });

  return (
    <Grid>
      <Text fontSize="small" lineHeight="paragraph">
        {title}
      </Text>
      <Collapse>
        <MetaDataWrapper>
          {fields.map(field => (
            <BookingField
              key={field.key}
              fieldKey={field.key}
              value={field.value}
              label={tCommon(
                `document.compact.history.bookings.single.fields.${field.key}`,
                {
                  context: getTranslationContext(),
                }
              )}
            />
          ))}
        </MetaDataWrapper>
      </Collapse>
    </Grid>
  );
};
