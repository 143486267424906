import {
  Button,
  ButtonProps,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import { ConfirmationPopover } from 'components/ConfirmationPopover/ConfirmationPopover';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface ArchiveActionProps {
  disabled: boolean;
  loading: boolean;
  onArchive: () => Promise<void>;
}

export const ArchiveAction = ({
  disabled,
  loading,
  onArchive,
}: ArchiveActionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef, close } =
    usePopover();

  const confirmButtonProps: ButtonProps = {
    color: 'red',
    loading,
    disabled,
  };

  const handleArchive = () => {
    close();
    void onArchive();
  };

  return (
    <>
      <Button
        variant="secondary"
        color="red"
        loading={loading}
        disabled={disabled}
        {...triggerProps}
        ref={triggerRef}
      >
        <TruncatedText>
          {t('reimbursementView.rightSection.footerActions.archiveCta')}
        </TruncatedText>
      </Button>
      {!disabled && isOpen && (
        <ConfirmationPopover
          ref={popoverRef}
          confirmButtonProps={confirmButtonProps}
          onConfirm={handleArchive}
          onClose={close}
          title={t(
            'reimbursementView.rightSection.footerActions.archiveConfirmation.desc'
          )}
          confirmText={t(
            'reimbursementView.rightSection.footerActions.archiveConfirmation.yes'
          )}
          closeText={t(
            'reimbursementView.rightSection.footerActions.archiveConfirmation.no'
          )}
          {...popoverProps}
        />
      )}
    </>
  );
};
