import {
  Button,
  Grid,
  Item,
  TabPanel,
  Tabs,
  Text,
} from '@candisio/design-system';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { EmptySearchState } from 'components/FilterableList/components/EmptySearchState';
import { FilterableListItem } from 'components/FilterableList/components/FilterableListItem';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { UseTabsOptions, useTabs } from 'components/Tabs/useTabs';
import { useSap } from 'orgConfig/sap';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { PaymentConditionsKebabMenu } from 'views/Settings/PaymentConditions/PaymentConditionsKebabMenu';
import { usePaymentConditionsList } from 'views/Settings/PaymentConditions/usePaymentConditionsList';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';
import { EmptyDataState } from './components/EmptyDataState';
import { PaymentConditionsListData } from './types';

type Status = 'active' | 'archived';

export interface PaymentConditionsProps {
  data?: PaymentConditionsListData[];
  loading?: boolean;
  numActive?: number;
  numArchived?: number;
  onChangeStatus?: (status: Status) => void;
  onShowImport?: () => void;
  onShowImportHistory?: () => void;
  onShowItem?: (id: string | 'new') => void;
  onStartExport?: () => void;
  status?: Status;
}

export const PaymentConditions = ({
  data = [],
  loading = false,
  numActive,
  numArchived,
  onChangeStatus,
  onShowImport,
  onShowImportHistory,
  onShowItem,
  onStartExport,
  status = 'active',
}: PaymentConditionsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { isActiveIntegration: shouldUseSapPaymentConditions } = useSap();
  const navigate = useNavigate();

  const {
    sortedPaymentConditions,
    onSearchFilter,
    handleSearchReset,
    renderPaymentCondition,
    queryString,
    handleSortBy,
    sortButtonText,
    sortBy,
    sortItems,
  } = usePaymentConditionsList(data);

  const handleCreate = () => {
    onShowItem?.('create');
  };

  const paymentConditionsList = (
    <FilterableList
      children={sortedPaymentConditions.map(paymentCondition => (
        <Item key={paymentCondition.id} textValue={paymentCondition.id}>
          <FilterableListItem
            itemId={paymentCondition.id}
            onSelect={() => onShowItem && onShowItem(paymentCondition.id)}
          >
            <Grid>
              <Text fontWeight="semibold" fontSize="basic">
                {renderQueryHighlight({
                  value: renderPaymentCondition(paymentCondition.condition),
                  queryString: queryString,
                })}
              </Text>
              <Text color="gray500">
                {renderQueryHighlight({
                  value: paymentCondition.conditionNumber + '',
                  queryString: queryString,
                })}
              </Text>
            </Grid>
          </FilterableListItem>
        </Item>
      ))}
      customCreate={
        shouldUseSapPaymentConditions && (
          <>
            <SyncFromSap type={SAP_SYNC.PaymentConditions} />
            <Button
              width="max-content"
              size="small"
              variant="secondary"
              onClick={() => navigate('import_history')}
            >
              {t('paymentConditions.contextMenu.historyLabel')}
            </Button>
          </>
        )
      }
      emptyDataState={<EmptyDataState />}
      emptySearchState={<EmptySearchState searchReset={handleSearchReset} />}
      isLoading={loading}
      kebabMenu={
        <PaymentConditionsKebabMenu
          onImportAction={onShowImport}
          onImportHistoryAction={onShowImportHistory}
          onExportAction={onStartExport}
        />
      }
      menuButtons={[
        {
          actionValue: [sortBy],
          onClick: handleSortBy,
          text: sortButtonText ?? '',
          menuButtonItems: sortItems,
        },
      ]}
      onCreate={
        !shouldUseSapPaymentConditions
          ? {
              value: handleCreate,
              text: t('paymentConditions.createLabel'),
            }
          : undefined
      }
      searchField={{
        onSearchFilter,
        placeholder: t('paymentConditions.searchPlaceholder'),
        searchQuery: queryString,
      }}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    />
  );

  const { tabPanelProps, tabsProps } = useTabs({
    items: [
      {
        key: 'active',
        title: t('paymentConditions.tabs.active'),
        badge: numActive ? String(numActive) : undefined,
        children: paymentConditionsList,
      },
      {
        key: 'archived',
        title: t('paymentConditions.tabs.archived'),
        badge: numArchived ? String(numArchived) : undefined,
        children: paymentConditionsList,
      },
    ],
    onSelectionChange: onChangeStatus as UseTabsOptions['onSelectionChange'],
    selectedKey: status,
  });

  return (
    <SettingsLayout
      actions={<Tabs {...tabsProps} />}
      title={t('paymentConditions.title')}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      <TabPanel {...tabPanelProps} />
      <InfoPanel
        externalLink={
          !shouldUseSapPaymentConditions
            ? t('paymentConditions.infoPanel.externalLink', {
                returnObjects: true,
              })
            : undefined
        }
        title={t('paymentConditions.infoPanel.title')}
        additionalParagraph={
          shouldUseSapPaymentConditions
            ? t('paymentConditions.descriptionSap')
            : undefined
        }
        sections={
          !shouldUseSapPaymentConditions
            ? t('paymentConditions.infoPanel.sections', {
                returnObjects: true,
              })
            : undefined
        }
      />
    </SettingsLayout>
  );
};
