import { HeaderProps, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CreditCardsTableData } from 'views/CreditCards/types';

export const Header = ({
  column,
  isFilteredOrSorted,
}: HeaderProps<CreditCardsTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <TruncatedText
      wordBreak="break-word"
      color={isFilteredOrSorted ? 'gray800' : 'gray500'}
    >
      {t(`cardManagerView.table.columns.${column?.id}`)}
    </TruncatedText>
  );
};
