import { Box, Button, Card, Grid, Separator } from '@candisio/design-system';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { SplitDS } from 'components/Form/SplitBookingsForm/types';
import { PurchaseOrderType } from 'generated-types/graphql.types';
import { useStickyCardRefContext } from 'providers/StickyCardRefProvider';
import { useTranslation } from 'react-i18next';
import { NewSplit } from '../NewSplit/NewSplit';
import { SapFreightPackageBookingFields } from '../NewSplit/NewSplitForPurchaseOrders';

export type ActionsProps = {
  readOnly: boolean;
  isLoading?: boolean;
  onAddNewSplit: (additional?: SapFreightPackageBookingFields) => void;
  errorMessage?: string;
  roundingDifference?: string;
  onDiscardBookings?: () => void;
  onAcceptBookings?: () => void;
  purchaseOrderType?: PurchaseOrderType;
  currentBookings?: SplitDS[];
};

export const Actions = ({
  readOnly,
  errorMessage,
  onDiscardBookings,
  onAcceptBookings,
  roundingDifference,
  onAddNewSplit,
  purchaseOrderType,
  isLoading,
  currentBookings,
}: ActionsProps) => {
  const [t] = useTranslation('split-bookings');
  const cardRef = useStickyCardRefContext();

  return (
    <Card
      bottom={0}
      corners="top"
      position="sticky"
      boxShadow="elevatedShadow4"
      zIndex={10}
      ref={cardRef}
    >
      <Grid gap="space8">
        {!readOnly && (
          <>
            <NewSplit
              type={purchaseOrderType}
              onClick={onAddNewSplit}
              isLoading={isLoading}
              currentBookings={currentBookings}
            />
            <Grid padding="space8 space16">
              <Separator />
            </Grid>
          </>
        )}
        {errorMessage && <Box color="red500">{errorMessage}</Box>}
        {onAcceptBookings &&
          (roundingDifference != null ? (
            <ConfirmationButton
              loading={isLoading}
              disabled={isLoading}
              color="default"
              onConfirm={onAcceptBookings}
              confirmationTitle={t('errors.roundingDifference', {
                roundingDifference,
              })}
              variant="primary"
            >
              {t('actions.acceptSplitWithRemaingAmountButton')}
            </ConfirmationButton>
          ) : (
            <Button
              onClick={onAcceptBookings}
              disabled={isLoading}
              loading={isLoading}
            >
              {t('actions.acceptSplitButton')}
            </Button>
          ))}
        {!readOnly && onDiscardBookings && (
          <ConfirmationButton
            disabled={isLoading}
            loading={isLoading}
            color="red"
            onConfirm={onDiscardBookings}
            confirmationTitle={t('actions.title')}
            variant="secondary"
          >
            {t('actions.discardAllSplitsButton')}
          </ConfirmationButton>
        )}
      </Grid>
    </Card>
  );
};
