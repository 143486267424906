import {
  Flex,
  Grid,
  Icon,
  Skeleton,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { UserRole } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useCreditCards } from 'providers/EntityLoader/EntityLoader';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useTranslation } from 'react-i18next';
import { LanguageToggle } from 'views/AppContainer/components/Header/components/QaUtils/LanguageToggle';
import { DocumentUploadModal } from './components/DocumentUploadModal/DocumentUploadModal';
import { EventNotifications } from './components/EventNotifications/EventNotifications';
import { MultiOrganizationOverview } from './components/MultiOrganizationOverview';
import {
  ExportNotifications,
  UploadEmailNotifications,
} from './components/Notifications';
import { QaUtils } from './components/QaUtils/QaUtils';
import { useShowQaUtils } from './components/QaUtils/utils';
import {
  phoneNumberCardId,
  scrollToTargetParam,
} from './components/UserProfile/AccountSecurity/PhoneNumberMfa/PhoneNumberCard';
import { UserProfileMenu } from './components/UserProfile/UserProfileMenu';

export interface HeaderProps {
  initialisingAuth?: boolean;
}

const USER_PROFILE_NAME_MAX_WIDTH = '11.25rem';

export const Header = ({ initialisingAuth }: HeaderProps) => {
  const user = useCurrentUser();
  const [t] = useTranslation();
  const creditCardsSetup = useCreditCardsSetup();
  const { hasCreditCards } = useCreditCards(initialisingAuth);
  const { isCardManager } = useUserRoles();
  const [inAppNotificationsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.inAppNotifications,
  ]);

  const hasPhoneNumber = Boolean(user?.phoneNumber);
  const showReminderIndicator =
    creditCardsSetup.showAllExtraFeatures &&
    !hasPhoneNumber &&
    (hasCreditCards || isCardManager);

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ placement: 'bottom' });

  const showQaUtils = useShowQaUtils();

  return (
    <Grid
      as="header"
      background="gray0"
      height="space48"
      padding="0 space32"
      template="'left-items right-items' auto/1fr 1fr"
    >
      <Flex gridArea="left-items" alignItems="center" gap="space8">
        <MultiOrganizationOverview initialisingAuth={initialisingAuth} />
        {showReminderIndicator && (
          <>
            <Icon
              {...triggerProps}
              ref={triggerRef}
              icon="warning"
              size="space16"
              color="yellow700"
            />

            {isOpen && (
              <Tooltip {...tooltipProps} ref={tooltipRef}>
                <Text>{t('dashboard:phoneNumberMissingIndicator')}</Text>{' '}
                <RouterLink
                  to={{
                    hash: 'userProfile',
                    search: `${scrollToTargetParam}=${phoneNumberCardId}`,
                  }}
                >
                  {t('header.profile.menu.items.user.title')}
                </RouterLink>
              </Tooltip>
            )}
          </>
        )}
      </Flex>

      <Flex
        alignItems="center"
        gridArea="right-items"
        justifyContent="end"
        gap="space24"
      >
        {' '}
        {showQaUtils ? <LanguageToggle /> : null}
        {showQaUtils ? <QaUtils /> : null}
        <UserRoleAccessWrapper
          allowedRoles={[UserRole.Admin, UserRole.Accountant]}
        >
          <ExportNotifications />
        </UserRoleAccessWrapper>
        <UserRoleAccessWrapper
          allowedRoles={[
            UserRole.Admin,
            UserRole.Requester,
            UserRole.Accountant,
          ]}
        >
          <UploadEmailNotifications />
        </UserRoleAccessWrapper>
        {user ? (
          <UserProfileMenu />
        ) : (
          <Skeleton height="1.5rem" width={USER_PROFILE_NAME_MAX_WIDTH} />
        )}
        {inAppNotificationsFF && !initialisingAuth ? (
          <EventNotifications />
        ) : null}
      </Flex>
      <DocumentUploadModal />
    </Grid>
  );
};
