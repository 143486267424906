import { Box, Button, Grid } from '@candisio/design-system';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { PaymentConditionsImportTableData } from './PaymentConditionsImportContainer';
import { PaymentConditionsImportSummary } from './PaymentConditionsImportSummary';

export interface PaymentConditionsImportFooterProps {
  importing?: boolean;
  onGoBack?: () => void;
  onImport?: () => void;
  previewTableData?: PaymentConditionsImportTableData[];
}

export const PaymentConditionsImportFooter = ({
  importing,
  onGoBack,
  onImport,
  previewTableData,
}: PaymentConditionsImportFooterProps) => {
  const [t] = useTranslation();

  let invalidCount = 0;
  let newCount = 0;
  let updatedCount = 0;

  previewTableData?.forEach(({ status }) => {
    if (status === 'new') {
      newCount++;
    } else if (status === 'updated') {
      updatedCount++;
    } else if (status === 'invalid') {
      invalidCount++;
    }
  });

  return (
    <Grid gap="space16" templateColumns="1fr auto">
      <Box>
        {previewTableData && (
          <PaymentConditionsImportSummary
            invalidCount={invalidCount}
            newCount={newCount}
            updatedCount={updatedCount}
          />
        )}
      </Box>
      <Grid autoFlow="column" gap="space8" alignContent="end">
        <motion.div layout>
          <Button
            disabled={importing}
            onClick={onGoBack}
            size="small"
            variant="secondary"
            icon="arrowLeft"
          >
            {t('common.import.goBackToUpload')}
          </Button>
        </motion.div>
        {(newCount > 0 || updatedCount > 0) && (
          <Button
            loading={importing}
            onClick={onImport}
            size="small"
            variant="primary"
            icon="arrowRight"
            iconPosition="right"
          >
            {t('common.import.startImport')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
