import {
  ReimbursementErrorType,
  useSubmitReimbursementCaseForReviewMutation,
} from 'generated-types/graphql.types';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
} from '../toolkit/queries';
import { isNotRelevantErrorForCase } from '../utils/isNotRelevantErrorForCase';

export const useSubmitReimbursementCaseForReview = () => {
  const [submitReimbursmentCaseForReview, { loading }] =
    useSubmitReimbursementCaseForReviewMutation();

  return {
    submitReimbursmentCaseForReview: async (reimbursementId: string) => {
      const response = await submitReimbursmentCaseForReview({
        variables: { id: reimbursementId },
        refetchQueries: [
          'reimbursementCasesCount',
          {
            query: reimbursementCaseByIdQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementHistoryQuery,
            variables: { id: reimbursementId },
          },
        ],
      });

      if (response.data?.submitReimbursementCaseForReview.success === true) {
        return {
          status: 'success',
          reimbursementItemsErrors: [],
          hasReimbursementCaseErrors: false,
        };
      }

      const allCaseErrors =
        response.data?.submitReimbursementCaseForReview.errors
          .reimbursementCaseErrors?.errors ?? [];

      const reimbursementItemsErrors = (
        response.data?.submitReimbursementCaseForReview.errors
          .reimbursementItemsErrors ?? []
      ).map(reimbursementItem => ({
        id: reimbursementItem.id,
        errors: reimbursementItem.errors.length,
        title: reimbursementItem.title ?? undefined,
        type: reimbursementItem.type,
      }));

      // XXX: Backend should remove this error on their side
      const reimbursementCaseErrorsToConsider = allCaseErrors.filter(
        isNotRelevantErrorForCase
      );

      const hasNoReimbursementItemsError = allCaseErrors.filter(errorField =>
        errorField.errors.includes(ReimbursementErrorType.NotFound)
      );

      const notIncludedRembursementItemsError = allCaseErrors.filter(
        errorField =>
          errorField.errors.includes(ReimbursementErrorType.InvalidArrayContent)
      );

      return {
        status: 'error',
        reimbursementItemsErrors: reimbursementItemsErrors,
        hasNoReimbursementItems: !!hasNoReimbursementItemsError.length,
        hasNoIncludedReimbursementItems:
          !!notIncludedRembursementItemsError.length,
        hasReimbursementCaseErrors: !!reimbursementCaseErrorsToConsider.length,
      };
    },
    isSubmitReimbursmentCaseForReviewPending: loading,
  };
};
