import { Card, Flex, Grid, Separator, Text } from '@candisio/design-system';
import { BuiltinRoleName } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import {
  ControllerRenderProps,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { defaultRolesTranslations } from 'views/Settings/Roles/toolkit/utils/defaultRolesTranslations';
import {
  HandleChangeParams,
  RoleCheckbox,
} from 'views/Settings/TeamMembers/TeamMemberDetails/containers/invite/RolesSection/RoleCheckbox';
import { Role } from 'views/Settings/TeamMembers/hooks/useGetRolesForUser';
import { TeamMemberFormOutput } from '../../teamMemberFormSchema';
import {
  FormDataRole,
  coveredByExistingRole,
  filterAlreadyCoveredRoles,
  sortRoles,
} from './utils';

interface RolesFieldProps {
  roles: Role[];
  isReadOnly?: boolean;
  readOnlyReason?: string;
  name: UseControllerProps['name'];
  control?: UseControllerProps['control'];
}

export const RolesField = ({
  name,
  control,
  roles,
  isReadOnly = false,
  readOnlyReason,
}: RolesFieldProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const sortedRoles = sortRoles(roles);

  const { field, fieldState } = useController({ name, control });

  const { value, onChange } = field as unknown as ControllerRenderProps<
    TeamMemberFormOutput,
    'roles'
  >;

  const { error } = fieldState;

  const translateRoleName = (role: FormDataRole) =>
    role.type === 'CustomRole'
      ? role.name
      : t(defaultRolesTranslations[role.name]);

  const handleChange = ({ isChecked, switchValue }: HandleChangeParams) => {
    if (isChecked) {
      const selected = roles.filter(role => role.id === switchValue);
      const filteredRoles = filterAlreadyCoveredRoles([...value, ...selected]);

      onChange(filteredRoles);
    } else {
      onChange(value.filter(currentRole => currentRole.id !== switchValue));
    }
  };

  const selectedRoleIds = (value ?? []).map(role => role.id);

  return (
    <Card padding="0px" background="gray100">
      <Grid as="ul" style={{ listStyle: 'none', paddingLeft: '0px' }}>
        {sortedRoles.map((role, index) => {
          const isLastItem = index === roles.length - 1;

          const coveredByRole = coveredByExistingRole(role, value);
          const roleCoveredByOtherRoleToolip = coveredByRole
            ? t('common:settings.teamMembers.roles.activeReadonlyTooltip', {
                roleName: translateRoleName(coveredByRole),
              })
            : undefined;

          const isToggled =
            selectedRoleIds.includes(role.id) || Boolean(coveredByRole);

          const readOnly = isReadOnly || Boolean(coveredByRole);
          const readOnlyToolip = readOnlyReason || roleCoveredByOtherRoleToolip;

          if (role.name === BuiltinRoleName.Owner) {
            return null;
          }

          return (
            <Grid key={role.name} as="li">
              <RoleCheckbox
                onChange={handleChange}
                label={translateRoleName(role)}
                value={role.id}
                name={role.name}
                description={role.description}
                isToggled={isToggled}
                isReadOnly={readOnly}
                readOnlyReason={readOnlyToolip}
              />
              {!isLastItem && <Separator />}
            </Grid>
          );
        })}

        {Boolean(error?.message) && (
          <Flex padding="space16" color="red500" borderTop="1px solid gray300">
            <Text>{error?.message}</Text>
          </Flex>
        )}
      </Grid>
    </Card>
  );
};
