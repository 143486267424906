import {
  ElementType,
  HTMLAttributes,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AriaToggleButtonProps,
  mergeProps,
  useFocusRing,
  useHover,
  usePress,
  useToggleButton,
} from 'react-aria';
import { useToggleState } from 'react-stately';
import { AvatarProps } from '../Avatar';
import { RemovableTypeProps, ToggleableTypeProps, TypeProps } from './Chip';

interface UseChipReturn {
  iconKey: 'inputClose' | 'plus' | 'check';
  buttonLabel?: string;
  containerProps?: React.HTMLAttributes<HTMLElement> | MutableRefObject<null>;
  containerRefs?: MutableRefObject<null>[];
  iconProps?:
    | {
        as: keyof JSX.IntrinsicElements;
        ref: React.MutableRefObject<null>;
      }
    | React.HTMLAttributes<HTMLElement>;
  isDisabled?: boolean;
  isSelected?: boolean;
  avatar?: Pick<AvatarProps, 'img' | 'name'>;
  isFocusVisible: boolean;
  isHovered: boolean;
  isPressed: boolean;
}

export const useChip = ({
  typeProps,
  tooltipTriggerProps,
}: {
  typeProps: TypeProps;
  tooltipTriggerProps: {
    triggerRef: MutableRefObject<null>;
    triggerProps: HTMLAttributes<HTMLElement>;
  };
}): UseChipReturn => {
  const { isFocusVisible, focusProps } = useFocusRing();
  const { hoverProps, isHovered: isHoveredInitial } = useHover({});
  const { isPressed, pressProps } = usePress({});

  const [isHovered, setIsHovered] = useState<boolean>(isHoveredInitial);

  useEffect(() => {
    setIsHovered(isHoveredInitial);
  }, [isHoveredInitial]);

  const { triggerProps, triggerRef } = tooltipTriggerProps;

  const type = typeProps.type;

  if (type === 'toggleable') {
    const {
      isSelected,
      isDisabled,
      defaultSelected,
      onChange,
      unselectLabel,
      selectLabel,
    } = typeProps as ToggleableTypeProps;

    const toggleProps = { isSelected, isDisabled, defaultSelected, onChange };
    // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
    const toggleRef = useRef(null);
    // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
    const state = useToggleState(toggleProps);
    // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
    const { buttonProps } = useToggleButton(
      toggleProps as AriaToggleButtonProps<ElementType>,
      state,
      toggleRef
    );

    const iconKey = state.isSelected ? 'check' : 'plus';
    const buttonLabel = state.isSelected ? unselectLabel : selectLabel;

    const containerProps = mergeProps(
      triggerProps,
      hoverProps,
      focusProps,
      buttonProps,
      pressProps,
      {
        as: 'button' as ElementType<any>,
        type: 'button',
        // in order to make toggling of Chip visually clearer, we prevent the immediate hover effect on toggle
        onClick: () => setIsHovered(false),
      }
    );

    return {
      isDisabled,
      isSelected: state.isSelected,
      iconKey,
      buttonLabel,
      containerProps,
      containerRefs: [toggleRef, triggerRef],
      isFocusVisible,
      isHovered,
      isPressed,
    };
  }

  // Type = removable
  const {
    isDisabled,
    onClickButton,
    removeLabel: buttonLabel,
  } = typeProps as RemovableTypeProps;

  const iconKey = 'inputClose';

  const iconProps = mergeProps(triggerProps, hoverProps, focusProps, {
    as: 'button',
    type: 'button',
    ref: triggerRef,
    onClick: onClickButton,
  });

  return {
    iconKey,
    buttonLabel,
    iconProps,
    isFocusVisible,
    isHovered,
    isDisabled,
    isPressed,
  };
};
