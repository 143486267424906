import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { useNetworkStatus } from 'providers/GraphQLProvider/networkStatusLink';
import { ReactNode } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

export interface GraphQLLoadingIndicatorProps {
  children: ReactNode;
}

export const GraphQLLoadingIndicator = ({
  children,
}: GraphQLLoadingIndicatorProps) => {
  const { isLoading } = useNetworkStatus();
  const ProgressBarContainer = styled.div`
    position: absolute;
    width: 100vw;
    height: 1rem;
    top: 0;
  `;

  return (
    <>
      {isLoading && (
        <ProgressBarContainer>
          <ProgressBar className="graphql-loading-bar" />
        </ProgressBarContainer>
      )}
      <>{children}</>
    </>
  );
};
