import { Flex, Grid, Text } from '@candisio/design-system';
import { ReactElement } from 'react';
import { useCheckItem } from '../states';

export type CheckProp = {
  text: string | ReactElement;
  icon: ReactElement;
};

export const CheckList = ({
  checks,
  retries,
}: {
  checks: string[];
  retries?: number;
}) => {
  const { getCheckItemProps } = useCheckItem();

  return (
    <Grid
      key={retries}
      as="ul"
      listStyle="none"
      gap="space4"
      padding="0"
      justifyContent="left"
    >
      {checks.map(stateKey => (
        <CheckItem key={stateKey} {...getCheckItemProps(stateKey)} />
      ))}
    </Grid>
  );
};

interface InfoItemProps {
  icon: ReactElement;
  text: string | ReactElement;
}

const CheckItem = ({ icon, text }: InfoItemProps) => {
  return (
    <Flex as="li" alignItems="center">
      <Flex gap="space4">
        {icon}
        <Text>{text}</Text>
      </Flex>
    </Flex>
  );
};
