import { Modal } from '@candisio/design-system';
import {
  useReauthenticationDispatch,
  useReauthenticationState,
} from 'providers/ReauthenticationProvider/ReauthenticationProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReauthenticationModalContent } from './ReauthenticationModalContent';

export const ReauthenticationModal = () => {
  const [t] = useTranslation();
  const dispatch = useReauthenticationDispatch();
  const { modalTitle, modalVisibility, onReauthenticated } =
    useReauthenticationState();

  const defaultModalTitle = t('reauthentication.modal.default.title');

  const handleClose = useCallback(() => {
    dispatch({ action: 'hideModal' });
  }, [dispatch]);

  const handleReauthenticated = useCallback(() => {
    void onReauthenticated?.();
    handleClose();
  }, [handleClose, onReauthenticated]);

  return (
    <Modal
      title={modalTitle ?? defaultModalTitle}
      shouldCloseOnBlur={true}
      onClose={handleClose}
      closeLabel={t('common.close')}
      isOpen={modalVisibility}
      padding="space16 space32 space32 space32"
      background="gray200"
      minHeight="28rem"
      width="28rem"
    >
      <ReauthenticationModalContent onReauthenticated={handleReauthenticated} />
    </Modal>
  );
};
