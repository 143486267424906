import {
  Box,
  Grid,
  Modal,
  Image,
  useModal,
  Flex,
} from '@candisio/design-system';
import PlayIcon from 'assets/bds/play.svg';
import { Loader } from 'components/Loader';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const MotionImage = motion(Image);

export const VideoPlayerWithThumbnail = ({
  wistiaId,
  navatticId,
  thumbnail,
}: {
  wistiaId?: string;
  navatticId?: string;
  thumbnail?: string;
}) => {
  const [t] = useTranslation();
  const { open: openModal, modalProps } = useModal();

  return (
    <>
      <Box
        background={`url(${thumbnail}) center / contain`}
        borderRadius="basic"
        overflow="hidden"
      >
        <Grid
          placeContent="center"
          as="a"
          onClick={openModal}
          background="rgba(0,0,0,0.5)"
          width="10rem"
          height="10rem"
          borderRadius="basic"
        >
          <MotionImage
            height="space32"
            width="space32"
            whileHover={{ filter: 'brightness(75%)' }}
            src={PlayIcon}
            alt=""
          />
        </Grid>
      </Box>
      <Modal closeLabel={t('common.close')} width="max-content" {...modalProps}>
        <Flex position="absolute" direction="column" width="100%" height="100%">
          <Loader />
        </Flex>
        {wistiaId && (
          <Box
            width="40rem"
            height="22.5rem"
            className={`wistia_embed wistia_async_${wistiaId}`}
          />
        )}
        {navatticId && (
          <Box width="70rem" height="40rem">
            <Box
              as="iframe"
              width="100%"
              height="100%"
              title={navatticId}
              src={`https://capture.navattic.com/${navatticId}`}
              allow="fullscreen"
            />
          </Box>
        )}
      </Modal>
    </>
  );
};
