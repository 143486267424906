import {
  Icon,
  IconProps,
  mergeProps,
  mergeRefs,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { forwardRef, ReactNode } from 'react';

export interface InfoIconProps extends Omit<IconProps, 'icon'> {
  /**
   * Prevent the Icon from being considered when tabbing
   */
  disableTabbing?: boolean;
  /**
   * Content of the tooltip
   */
  message: ReactNode;
}

/**
 * An info icon with a tooltip containing additional information.
 *
 * The icon is focusable so that the tooltip content is accesible when
 * navigating with keyboard.
 */
export const InfoOutlineIcon = forwardRef<SVGSVGElement, InfoIconProps>(
  (
    {
      message,
      disableTabbing = false,
      size = 'space16',
      color = 'gray450',
      ...restProps
    },
    forwardedRef
  ) => {
    const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
      useTooltip({ delay: 0 });

    return (
      <>
        <Icon
          {...mergeProps(triggerProps, restProps)}
          ref={mergeRefs([triggerRef, forwardedRef])}
          icon="infoOutline"
          color={color}
          size={size}
          tabIndex={disableTabbing ? -1 : 0}
        />
        {isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {message}
          </Tooltip>
        )}
      </>
    );
  }
);
