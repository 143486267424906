import { MenuItem, Popover, usePopover } from '@candisio/design-system';
import { KebabMenu } from 'components/KebabMenu/KebabMenu';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ConfirmationPopover } from '../Common/ConfirmationPopover';
import { WidgetContextProps } from '../types';

export interface InsightWidgetKebabMenuProps extends WidgetContextProps {
  creatorName: string;
}

export const InsightWidgetKebabMenu = ({
  creatorName,
  isOwnedByCurrentUser,
  isOnDashboard,
  onDeleteWidget,
  onEditWidget,
  onUnsubscribeWidget,
  onMoveWidget,
}: InsightWidgetKebabMenuProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  const [tInsights] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  const {
    close: closeDoubleConfirm,
    isOpen: doubleConfirmIsOpen,
    open: openDoubleConfirm,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({ placement: 'bottom right' });

  const confirmationTranslationPrefix = isOwnedByCurrentUser
    ? 'dashboard.deleteWidget.confirmation'
    : 'dashboard.unsubscribeWidget.confirmation';

  let items: MenuItem[] = [];

  if (isOwnedByCurrentUser) {
    items.push({
      id: 'edit',
      label: tInsights('widgets.contextMenu.edit'),
      onAction: onEditWidget,
    });
  }

  if (!isOnDashboard) {
    items.push({
      id: 'move-from-dashboard',
      label: tInsights('widgets.contextMenu.moveToDashboard'),
      onAction: onMoveWidget,
    });
  } else {
    items.push({
      id: 'move-to-dashboard',
      label: tInsights('widgets.contextMenu.moveFromDashboard'),
      onAction: onMoveWidget,
    });
  }

  // delete always last as per https://candis.atlassian.net/browse/TA-490
  if (isOwnedByCurrentUser) {
    items.push({
      id: 'delete',
      label: tInsights('widgets.contextMenu.delete'),
      onAction: openDoubleConfirm,
      disabled: !isOwnedByCurrentUser,
    });
  } else {
    items.push({
      id: 'unsubscribe',
      label: tInsights('widgets.contextMenu.unsubscribe'),
      onAction: openDoubleConfirm,
    });
  }

  const handleConfirm = async () => {
    if (isOwnedByCurrentUser) {
      await onDeleteWidget();
    } else {
      await onUnsubscribeWidget();
    }

    closeDoubleConfirm();
  };

  return (
    <>
      <KebabMenu
        items={items}
        label={t('insights.widgets.sum.contextMenu.info')}
        size="small"
        // we only spread in the props for accessiblity,
        // not those for triggering the popover
        aria-controls={triggerProps['aria-controls']}
        aria-expanded={triggerProps['aria-expanded']}
        aria-haspopup={triggerProps['aria-haspopup']}
        ref={triggerRef}
      />

      {doubleConfirmIsOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          paddingX="space16"
          paddingY="space12"
        >
          <ConfirmationPopover
            title={tInsights(`${confirmationTranslationPrefix}.title`)}
            infoText={tInsights(`${confirmationTranslationPrefix}.info`, {
              creatorName,
            })}
            cancelLabel={tInsights(
              `${confirmationTranslationPrefix}.cancelButtonCTA`
            )}
            confirmLabel={tInsights(
              `${confirmationTranslationPrefix}.confirmButtonCTA`
            )}
            onConfirm={handleConfirm}
            onClose={closeDoubleConfirm}
          />
        </Popover>
      )}
    </>
  );
};
