import { DocumentFormContainer } from 'components/Form/DocumentForm/DocumentFormContainer';
import { ProcessedDocument } from 'components/Form/DocumentForm/toolkit/types';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { DocumentStatus } from 'generated-types/graphql.types';

interface ArchivedProps {
  document: ProcessedDocument;
}

export const Archived = ({ document }: ArchivedProps) => {
  return (
    <ProcessSidebar
      documentStatus={DocumentStatus.Archived}
      globalDocumentId={document.globalDocumentId ?? undefined}
    >
      <DocumentFormContainer allowInlineEditing={false} document={document} />
    </ProcessSidebar>
  );
};
