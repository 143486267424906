import { MutationResult } from '@apollo/client';
import { getMemberships } from 'components/Comment/gql';
import { useForceDeactivateMembershipMutation } from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { membershipsQueryFilter } from 'hooks/useUsersFieldOptions';
import { useCallback } from 'react';
import { getApproversQuery } from './queries';

type ForceDeactivateMembershipCallback = (
  membershipId: string
) => Promise<void | unknown>;

type ForceDeactivateMembershipHook = [
  ForceDeactivateMembershipCallback,
  MutationResult,
];

export const useForceDeactivateMembership =
  (): ForceDeactivateMembershipHook => {
    const [forceDeactivateMembershipMutation, mutationProps] =
      useForceDeactivateMembershipMutation();

    const counterQueries = useCounterQueries();

    const forceDeactivateMembership =
      useCallback<ForceDeactivateMembershipCallback>(
        async membershipId => {
          const { errors } = await forceDeactivateMembershipMutation({
            variables: { membershipId },
            refetchQueries: [
              ...counterQueries,
              'organizationMemberships',
              {
                query: getApproversQuery,
                variables: { name: '' },
              },
              {
                query: getMemberships,
                variables: membershipsQueryFilter.Active,
              },
            ],
          });

          if (errors?.length) {
            throw new Error('Force deactivating the member was not successful');
          }
        },
        [forceDeactivateMembershipMutation, counterQueries]
      );

    return [forceDeactivateMembership, mutationProps as MutationResult];
  };
