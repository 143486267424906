import {
  DocumentCurrency,
  ReimbursementItemStatus,
  ReimbursementItemType,
  useReimbursementItemsSplitBookingsQuery,
} from 'generated-types/graphql.types';
import { formatIntegerAmountToDecimal } from 'hooks/useMoneyFormatter';
import { isNil } from 'lodash';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';

export interface BookingItem {
  id: string;
  splitAmount: number;
  vatRate?: number;
  taxCode?: string;
  costCenter?: string;
  costObject?: string;
}

interface ReimbursementItemSplitBooking {
  id: string;
  title?: string;
  status: ReimbursementItemStatus;
  type: ReimbursementItemType;
  totalAmount: number;
  currency: DocumentCurrency;
  bookings: BookingItem[];
}

export const useReimbursementSplitBookingsData = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { canUseReimbursement } = useReimbursement();

  const { data, loading: loadingSplits } =
    useReimbursementItemsSplitBookingsQuery({
      variables: { filters: { reimbursementCaseId: reimbursementId } },
      skip: !reimbursementId || !canUseReimbursement,
      fetchPolicy: 'network-only',
    });

  const fetchedItems = data?.reimbursementItemsSplitBookings ?? [];

  const reimbursementItemSplitBookings: ReimbursementItemSplitBooking[] =
    fetchedItems.map(item => ({
      id: item.id,
      type: item.type,
      status: item.status,
      title: item.title ?? undefined,
      currency: item.totalAmount?.currency ?? DocumentCurrency.Eur,
      totalAmount: !isNil(item.totalAmount?.amount)
        ? formatIntegerAmountToDecimal(
            item.totalAmount?.amount,
            item.totalAmount?.precision
          )
        : 0,
      bookings: (item.bookings ?? []).map(booking => ({
        id: booking.id,
        splitAmount: booking.amount?.value ?? 0,
        vatRate: booking.vatRate?.value ?? undefined,
        taxCode: booking.bookingKey?.value.readableName ?? undefined,
        costCenter: booking.costCenter
          ? booking.costCenter.value.readableName
          : undefined,
        costObject: booking.costObject
          ? booking.costObject.value.readableName
          : undefined,
      })),
    }));

  return {
    loadingSplits,
    reimbursementItemSplitBookings,
  };
};
