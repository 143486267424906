import { History } from 'history';
import moment from 'moment';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { prefix } from '../../../../utils/localStorage';
import { DATE_RANGE_OPTION, DateRangeDefinition } from '../types';

export const readableDateFormat = 'DD.MM.YYYY';

const regEx = {
  year: /^\d\d\d\d$/,
  quarter: /Q[1|2|3|4]-\d\d\d\d/,
  month: /\d\d-\d\d\d\d/,
  custom: /\d\d.\d\d.\d\d\d\d-\d\d.\d\d.\d\d\d\d/,
};

const isYear = (readableDateRange: string) =>
  regEx.year.test(readableDateRange);

const isQuarter = (readableDateRange: string) =>
  regEx.quarter.test(readableDateRange);

const isMonth = (readableDateRange: string) =>
  regEx.month.test(readableDateRange);

const isCustom = (readableDateRange: string) =>
  regEx.custom.test(readableDateRange);

const getPrevNextMonth = (year: number, month: number) => {
  const toMonthKey = (month: number) =>
    month >= 10 ? `${month}` : `0${month}`;

  let prev,
    next = '';

  if (month === 1) {
    prev = `12-${year - 1}`;
    next = `02-${year}`;
  } else if (month === 12) {
    prev = `11-${year}`;
    next = `01-${year + 1}`;
  } else {
    prev = `${toMonthKey(month - 1)}-${year}`;
    next = `${toMonthKey(month + 1)}-${year}`;
  }

  return {
    prev,
    next,
  };
};

export const parseDateRangeOption = (
  readableDateRange?: string
): DateRangeDefinition => {
  if (!readableDateRange) {
    const baseDate = new Date();
    const startOfCurrentMonth = moment(baseDate)
      .startOf('month')
      .format(readableDateFormat);

    const endOfCurrentMonth = moment(baseDate)
      .endOf('month')
      .format(readableDateFormat);

    const currentYear = baseDate.getFullYear();
    const currentMonth = baseDate.getMonth() + 1;
    const { prev, next } = getPrevNextMonth(currentYear, currentMonth);

    return {
      type: DATE_RANGE_OPTION.MONTH,
      values: {
        readable: moment(baseDate).format('MMMM YYYY'),
        dateFrom: startOfCurrentMonth,
        dateTo: endOfCurrentMonth,
        prev,
        next,
      },
    };
  } else if (isYear(readableDateRange)) {
    const startDateOfTheYear = moment([readableDateRange]).format(
      readableDateFormat
    );

    const endDateOfTheYear = moment([readableDateRange])
      .endOf('year')
      .format(readableDateFormat);

    return {
      type: DATE_RANGE_OPTION.YEAR,
      values: {
        readable: readableDateRange,
        dateFrom: startDateOfTheYear,
        dateTo: endDateOfTheYear,
        prev: `${Number(readableDateRange) - 1}`,
        next: `${Number(readableDateRange) + 1}`,
      },
    };
  } else if (isQuarter(readableDateRange)) {
    const extractYearAndQuarter = (): {
      year: string;
      quarter: '1' | '2' | '3' | '4';
    } => {
      const [, quarter, year] =
        readableDateRange.match(/Q(\d)-(\d\d\d\d)/) ?? [];

      return {
        year,
        quarter: quarter as '1' | '2' | '3' | '4',
      };
    };

    const monthPerQuarter = {
      '1': { from: '01.01', to: '31.03' },
      '2': { from: '01.04', to: '30.06' },
      '3': { from: '01.07', to: '30.09' },
      '4': { from: '01.10', to: '31.12' },
    };

    const { year, quarter } = extractYearAndQuarter();

    let prev,
      next = '';

    if (quarter === '1') {
      prev = `Q4-${Number(year) - 1}`;
      next = `Q2-${year}`;
    } else if (quarter === '4') {
      prev = `Q3-${year}`;
      next = `Q1-${Number(year) + 1}`;
    } else {
      prev = `Q${Number(quarter) - 1}-${year}`;
      next = `Q${Number(quarter) + 1}-${year}`;
    }

    return {
      type: DATE_RANGE_OPTION.QUARTER,
      values: {
        readable: readableDateRange.replace('-', ' '),
        dateFrom: `${monthPerQuarter[quarter].from}.${year}`,
        dateTo: `${monthPerQuarter[quarter].to}.${year}`,
        prev,
        next,
      },
    };
  } else if (isMonth(readableDateRange)) {
    const extractYearAndMonth = (): {
      year: string;
      month: string;
    } => {
      const [, month, year] =
        readableDateRange.match(/(\d\d)-(\d\d\d\d)/) ?? [];

      return {
        year,
        month,
      };
    };

    const { year, month } = extractYearAndMonth();

    const baseDate = new Date(Number(year), Number(month) - 1);
    const dateFrom = moment(baseDate)
      .startOf('month')
      .format(readableDateFormat);

    const dateTo = moment(baseDate).endOf('month').format(readableDateFormat);

    const { prev, next } = getPrevNextMonth(Number(year), Number(month));

    return {
      type: DATE_RANGE_OPTION.MONTH,
      values: {
        readable: moment(baseDate).format('MMMM YYYY'),
        dateFrom,
        dateTo,
        prev,
        next,
      },
    };
  } else if (isCustom(readableDateRange)) {
    const [, dateFrom, dateTo] =
      readableDateRange.match(/(\d\d.\d\d.\d\d\d\d)-(\d\d.\d\d.\d\d\d\d)/) ??
      [];

    return {
      type: DATE_RANGE_OPTION.CUSTOM,
      values: {
        readable: readableDateRange.replace('-', ' - '),
        dateFrom,
        dateTo,
      },
    };
  }

  const fullYear = new Date().getFullYear();

  return {
    type: DATE_RANGE_OPTION.YEAR,
    values: {
      readable: String(fullYear),
      dateFrom: `01.01.${fullYear}`,
      dateTo: `31.12.${fullYear}`,
      prev: `${fullYear + 1}`,
      next: `${fullYear - 1}`,
    },
  };
};

const getLocalStorageKey = (userId: string) =>
  `${prefix}_insights_widgets_selectedDateRange_${userId}`;

export const persistInvoiceDateToLocalStorage = (
  readableInvoiceDate: string,
  userId: string
) => {
  localStorage.setItem(getLocalStorageKey(userId), readableInvoiceDate);
};

type ExtractInvoiceDateFromUrlProps = {
  search: string;
  userId?: string;
  forceDefault: boolean;
};

export const extractInvoiceDateFromUrl = ({
  search,
  userId,
  forceDefault,
}: ExtractInvoiceDateFromUrlProps) => {
  if (forceDefault) {
    if (userId) {
      localStorage.removeItem(getLocalStorageKey(userId));
    }

    return parseDateRangeOption();
  }

  if (!userId) {
    const searchObj = qs.parse(search);

    return parseDateRangeOption(searchObj.invoiceDate as string);
  }

  const invoiceDates = {
    search: qs.parse(search).invoiceDate,
    localStorage: localStorage.getItem(getLocalStorageKey(userId)),
  };

  return parseDateRangeOption(
    (invoiceDates.search || invoiceDates.localStorage) as string
  );
};

export const applyDateRange =
  (history: History, userId?: string) => (invoiceDate: string) => {
    if (userId) {
      persistInvoiceDateToLocalStorage(invoiceDate, userId);
    }

    const search = qs.parse(window.location.search);
    history.push({
      search: qs.stringify({
        ...search,
        invoiceDate,
      }),
    });
  };
