import {
  Flex,
  Grid,
  Skeleton,
  Text,
  useId,
  usePopover,
} from '@candisio/design-system';
import { ConfirmationPopover } from 'components/ConfirmationPopover/ConfirmationPopover';
import { IntercomLink } from 'components/InfoPanel/components/IntercomLink';
import { BuiltinRoleName } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HookFormUserField } from 'views/Settings/Organization/components/HookFormUserField';
import { useTransferOwnership } from 'views/Settings/Organization/hooks/useTransferOwnership';
import { useGetUsers } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { OrganizationCard } from './OrganizationCard';

export const ORGANIZATION_OWNER_SELECT_DATA_CY = 'organization-owner-select';

export const OrganizationOwner = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { users: admins, isLoadingUsers: isLoadingAdmins } = useGetUsers({
    filters: { roleNames: [BuiltinRoleName.Admin] },
  });

  const {
    isOpen: isDoubleConfirmationOpen,
    popoverProps,
    popoverRef,
    triggerRef,
    setOpen: setDoubleConfirmationOpen,
    close: closeDoubleConfirmation,
  } = usePopover({ placement: 'right' });

  const {
    handleUserChange,
    onConfirm,
    handleCancelSubmit,
    form,
    selectedOwnerName,
    currentOwnerName,
    isLoading,
    isDisabled,
  } = useTransferOwnership({
    setDoubleConfirmationOpen,
    closeDoubleConfirmation,
  });

  const formId = useId();

  const sortedAdmins = admins
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  const items = useMemo(() => {
    return sortedAdmins.map(admin => ({
      key: admin.id,
      children: admin.name,
      avatarUrl: admin.avatarUrl,
    }));
  }, [sortedAdmins]);

  return (
    <FormProvider {...form}>
      <Grid id={formId} as="form">
        <OrganizationCard
          title={t('organisation.owner.title')}
          paragraph={
            <span>
              <Text fontSize="small" color="gray800" lineHeight="paragraph">
                {t('organisation.owner.description', {
                  ownerName:
                    currentOwnerName || t('organisation.owner.defaultName'),
                })}
              </Text>{' '}
              <IntercomLink
                translation={t('organisation.owner.linkText')}
                id="settings-owner-not-available"
              />
            </span>
          }
          isLoading={isLoading}
        >
          {isLoading || isLoadingAdmins ? (
            <Skeleton height="space48" width="100%" />
          ) : (
            <Flex
              gap="space16"
              width="max-content"
              height="space48"
              alignItems="center"
              ref={triggerRef}
            >
              <HookFormUserField
                width="space256"
                label=""
                name="owner"
                defaultItems={items}
                isLoading={isLoadingAdmins}
                readOnly={isLoading || isLoadingAdmins || isDisabled}
                onChange={handleUserChange}
                dataCy={ORGANIZATION_OWNER_SELECT_DATA_CY}
              />
              {!isDisabled && isDoubleConfirmationOpen && (
                <ConfirmationPopover
                  onConfirm={onConfirm}
                  title={t(
                    'organisation.owner.transferOwnership.doubleConfirmation',
                    {
                      ownerName: selectedOwnerName,
                    }
                  )}
                  onClose={handleCancelSubmit}
                  closeButtonProps={{
                    onClick: handleCancelSubmit,
                  }}
                  ref={popoverRef}
                  {...popoverProps}
                />
              )}
            </Flex>
          )}
        </OrganizationCard>
      </Grid>
    </FormProvider>
  );
};
