import { Card, Flex, Grid, Modal } from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import { useApplyForCreditCardsMutation } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormEvent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { refetchCreditCardIssuerOrganizationOnboarding } from '../gql';
import { CompanyInfoStep } from './CreditCardApplicationSteps/CompanyInfoStep';
import { OverviewStep } from './CreditCardApplicationSteps/OverviewStep';
import { RequesterInfoStep } from './CreditCardApplicationSteps/RequesterInfoStep';
import {
  CredCardApplicationFormValues,
  credCardApplicationFormSchema,
  getCredCardApplicationFormErrorMessages,
} from './CreditCardApplicationSteps/toolkit/schema';
import { sanitizeCreditCardApplicationFormData } from './CreditCardApplicationSteps/toolkit/utils';

enum FormStep {
  OVERVIEW,
  COMPANY_INFO,
  REQUESTER_INFO,
}

export const formTitleVariant = [
  {
    title: 'modal.form.overviewForm.title',
  },
  {
    title: 'modal.form.companyInfoForm.title',
  },
  {
    title: 'modal.form.requesterInfoForm.title',
  },
];

export interface CreditCardsApplicationModalProps {
  isOpen: boolean;
  close: () => void;
}

export const CreditCardsApplicationModal = ({
  isOpen,
  close,
}: CreditCardsApplicationModalProps) => {
  const currentUserEmail = useCurrentUser()?.email || '';
  const [step, setStep] = useState<FormStep>(FormStep.OVERVIEW);
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success, error } = useToastMessage();
  const [applyForCreditCards, { loading: isApplyForCreditCardsPending }] =
    useApplyForCreditCardsMutation();

  const handleSubmit = async (values: CredCardApplicationFormValues) => {
    const input = sanitizeCreditCardApplicationFormData({
      ...values,
    });

    const result = await applyForCreditCards({
      variables: { input },
      refetchQueries: refetchCreditCardIssuerOrganizationOnboarding,
    });

    if (result.data?.applyForCreditCards.accepted) {
      success(t('modal.applicationSent'));
    } else if (result.errors?.length) {
      error(t('modal.errorWhileSending'));
    }

    close();
  };

  const defaultValues: CredCardApplicationFormValues = {
    acceptTerms: false,
    companyName: '',
    annualRevenue: null,
    zipCode: '',
    city: '',
    employeeCount: '',
    foundingYear: '',
    address: '',
    country: '',
    authorizedRepresentativeFirstName: '',
    authorizedRepresentativeLastName: '',
    authorizedRepresentativeEmail: '',
    monthlyCardLimit: null,
    paymentFrequency: t(
      'modal.form.companyInfoForm.paymentFrequency.PREFUNDED'
    ),
    contactFirstName: '',
    contactLastName: '',
    contactPhone: '',
    contactEmail: currentUserEmail,
  };

  const form = useForm<CredCardApplicationFormValues>({
    defaultValues,
    mode: 'all',
    resolver: zodResolver({
      zodSchema: credCardApplicationFormSchema,
      errorMessages: getCredCardApplicationFormErrorMessages(),
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
    }),
    shouldFocusError: true,
  });

  return (
    <Modal
      width="max-content"
      background="gray200"
      title={t(formTitleVariant[step].title)}
      isOpen={isOpen}
      onClose={() => {
        close();
        setStep(FormStep.OVERVIEW);
        form.reset();
      }}
      closeLabel={t('modal.close')}
    >
      <FormProvider {...form}>
        <Grid width="900px">
          <Flex direction="column" gap="space24">
            <Card background="gray200" boxShadow="noShadow" padding={0}>
              <Grid
                as="form"
                onSubmit={(event: FormEvent<HTMLFormElement>) => {
                  event.preventDefault();
                }}
              >
                {step === FormStep.OVERVIEW && (
                  <OverviewStep
                    onNextStep={() => setStep(FormStep.COMPANY_INFO)}
                  />
                )}
                {step === FormStep.COMPANY_INFO && (
                  <CompanyInfoStep
                    onNextStep={() => setStep(FormStep.REQUESTER_INFO)}
                  />
                )}
                {step === FormStep.REQUESTER_INFO && (
                  <RequesterInfoStep
                    onPrevStep={() => {
                      setStep(FormStep.COMPANY_INFO);
                    }}
                    handleSubmit={form.handleSubmit(handleSubmit)}
                    isApplyForCreditCardsPending={isApplyForCreditCardsPending}
                  />
                )}
              </Grid>
            </Card>
          </Flex>
        </Grid>
      </FormProvider>
    </Modal>
  );
};
