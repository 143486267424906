import { Grid, Heading, Icon, Link, Paragraph } from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import { useSyncDocumentTypesMutation } from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { Trans } from 'providers/LocaleProvider';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { refetchDatevData } from 'views/Settings/Integrations/DATEV/gql';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from 'views/Settings/utils';
import { DATEV_DOCUMENT_TYPES } from '../consts';

export const DocumentTypesTableDescription = () => {
  const [t] = useTranslation();
  const { success, error, warning } = useToastMessage();
  const [isSyncingDocumentTypes, setIsSyncingDocumentTypes] = useState(false);
  const { bdsBought, bdsConnected } = useDatev(); // BDS-checked

  const [syncDocumentTypes] = useSyncDocumentTypesMutation();
  const onSyncDocumentTypes = useCallback(async () => {
    setIsSyncingDocumentTypes(true);
    const result = await syncDocumentTypes({
      refetchQueries: refetchDatevData,
    });

    const hasErrors = result.errors;
    const wasUpdated = result.data?.syncDocumentTypes;

    if (hasErrors) {
      error(t('settings.datev.connect.documentTypes.syncDocumentTypes.error'));
    } else if (wasUpdated) {
      success(
        t('settings.datev.connect.documentTypes.syncDocumentTypes.updated')
      );
    } else {
      warning(
        t('settings.datev.connect.documentTypes.syncDocumentTypes.notUpdated')
      );
    }

    setTimeout(() => {
      setIsSyncingDocumentTypes(false);
    }, 2000);
  }, [syncDocumentTypes, error, t, success, warning]);

  return (
    <Grid
      id={DATEV_DOCUMENT_TYPES}
      gap="space16"
      maxWidth={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      <Heading as="h3">
        {t('settings.datev.connect.documentTypes.title')}
      </Heading>
      <Paragraph fontSize="small" color="gray600" lineHeight="space20">
        {bdsBought && bdsConnected ? (
          <Trans i18nKey="settings.datev.connect.documentTypes.BdsInfo">
            Below you will find a list of the document types configured online
            in DATEV companies. Only the incoming and outgoing invoice document
            groups are supported by the booking data service. Cash register and
            other document types not yet. If new document types are not yet
            visible, please{' '}
            <Link
              as="button"
              onClick={() => {
                if (!isSyncingDocumentTypes) {
                  void onSyncDocumentTypes();
                }
              }}
            >
              refresh the list
            </Link>
            <Icon icon="rotateLeft" color="blue500" />
          </Trans>
        ) : (
          <Trans i18nKey="settings.datev.connect.documentTypes.info">
            Below you will find a list of the document types configured online
            in DATEV companies. Only the incoming and outgoing invoice document
            groups are supported by the booking data service. Cash register and
            other document types not yet. If new document types are not yet
            visible, please{' '}
            <Link
              as="button"
              onClick={() => {
                if (!isSyncingDocumentTypes) {
                  void onSyncDocumentTypes();
                }
              }}
            >
              refresh the list
            </Link>
            <Icon icon="rotateLeft" color="blue500" />
          </Trans>
        )}
      </Paragraph>
    </Grid>
  );
};
