import { Button, defaultTheme } from '@candisio/design-system';
import { ReactNode, memo } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { css, styled } from 'styles/themes';

// cursed: fix for link hover style
// TODO: remove after fix in DS
const StyledButtonLinkFix = styled.div<{ ['data-calendar']?: boolean }>`
  & > {
    a {
      padding-inline: 1rem;

      :hover {
        color: ${defaultTheme.colors.gray0};
      }
    }

    ${({ 'data-calendar': calendar }) =>
      calendar &&
      css`
        a svg {
          top: -2px;
        }
      `}
  }
`;

type PromotionLinkProps = {
  href: string;
  children: ReactNode;
  variant?: 'arrow' | 'calendar';
  trackClick?: () => void;
};

export const PromotionLink = memo(
  ({ href, children, variant, trackClick }: PromotionLinkProps) => {
    const iconProps = getIcon(variant ?? 'default');

    return (
      <StyledButtonLinkFix data-calendar={variant === 'calendar'}>
        <Button
          as="a"
          href={href}
          target="_blank"
          color="promo"
          {...iconProps}
          onClick={trackClick}
        >
          {children}
        </Button>
      </StyledButtonLinkFix>
    );
  }
);

const getIcon = (variant: 'default' | 'arrow' | 'calendar') => {
  if (variant === 'arrow') {
    return {
      icon: 'arrowRight',
      iconPosition: 'right',
    } as const;
  }

  if (variant === 'calendar') {
    return {
      icon: 'calendar',
      iconPosition: 'left',
    } as const;
  }

  return {} as const;
};
