import { SplitDS } from 'components/Form/SplitBookingsForm/types';
import { Maybe } from 'generated-types/graphql.types';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

export type SplitBookingsContextProps = {
  openedIndex: number;
  setOpenedIndex: (index: number) => void;
  initialBookings: SplitDS[];
  bookings: SplitDS[];
  setBookings: (bookings: SplitDS[]) => void;
  initialGrossAmount: Maybe<number>;
  grossAmount: Maybe<number>;
  setGrossAmount: (index: Maybe<number>) => void;
  currency: Maybe<string>;
  setCurrency: (currency: Maybe<string>) => void;
  invoiceDate: Maybe<string>;
  setInvoiceDate: (invoiceDate: Maybe<string>) => void;
  showSubmitErrors: boolean;
  setShowSubmitErrors: (show: boolean) => void;
  roundingAmount?: Maybe<number>;
  setRoundingAmount: (roundingAmount: Maybe<number>) => void;
  isGoodsPurchaseOrderLinked?: boolean;
};

type SplitBookingsProviderProps = {
  children: ReactNode;
} & Pick<
  SplitBookingsContextProps,
  | 'openedIndex'
  | 'initialBookings'
  | 'bookings'
  | 'grossAmount'
  | 'currency'
  | 'invoiceDate'
  | 'showSubmitErrors'
  | 'roundingAmount'
  | 'isGoodsPurchaseOrderLinked'
>;

const initialState = {
  openedIndex: -1,
  setOpenedIndex: () => null,
  initialBookings: [],
  bookings: [],
  setBookings: () => null,
  initialGrossAmount: null,
  grossAmount: null,
  setGrossAmount: () => null,
  currency: null,
  setCurrency: () => null,
  invoiceDate: null,
  setInvoiceDate: () => null,
  showSubmitErrors: false,
  setShowSubmitErrors: () => null,
  roundingAmount: null,
  setRoundingAmount: () => null,
  isGoodsPurchaseOrderLinked: false,
};

export const SplitBookingsContext =
  createContext<SplitBookingsContextProps>(initialState);

export const SplitBookingsProvider = ({
  children,
  ...props
}: SplitBookingsProviderProps) => {
  const [openedIndex, setOpenedIndex] = useState(props.openedIndex);
  const [bookings, setBookings] = useState(props.bookings);
  const [grossAmount, setGrossAmount] = useState(props.grossAmount);
  const [currency, setCurrency] = useState(props.currency);
  const [invoiceDate, setInvoiceDate] = useState(props.invoiceDate);
  const [roundingAmount, setRoundingAmount] = useState(props.roundingAmount);
  const [showSubmitErrors, setShowSubmitErrors] = useState(
    props.showSubmitErrors
  );

  const maxValidBookingIndex = bookings.length - 1;

  // Enforce that we always have the last booking opened in case the business logic beneath fails
  useEffect(() => {
    if (openedIndex > maxValidBookingIndex || openedIndex === -1) {
      setOpenedIndex(maxValidBookingIndex);
    }
  }, [maxValidBookingIndex, openedIndex]);

  return (
    <SplitBookingsContext.Provider
      value={{
        bookings,
        currency,
        grossAmount,
        initialBookings: props.initialBookings,
        initialGrossAmount: props.grossAmount,
        invoiceDate,
        openedIndex,
        roundingAmount,
        setRoundingAmount,
        setBookings,
        setCurrency,
        setGrossAmount,
        setInvoiceDate,
        setOpenedIndex,
        setShowSubmitErrors,
        showSubmitErrors,
      }}
    >
      {children}
    </SplitBookingsContext.Provider>
  );
};

export const useSplitBookingsContext = () => useContext(SplitBookingsContext);
