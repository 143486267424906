import { noop } from 'lodash';
import { DocumentThumbnail, DocumentThumbnailProps } from './DocumentThumbnail';
import { useDocumentPreviewData } from './useDocumentPreviewData';

export type DocumentThumbnailContainerProps = {
  handleClick?: DocumentThumbnailProps['handleClick'];
  documentId: string;
};

export const DocumentThumbnailContainer = ({
  documentId,
  handleClick = noop,
}: DocumentThumbnailContainerProps) => {
  const { documentFile } = useDocumentPreviewData({ documentId });

  return (
    <DocumentThumbnail documentFile={documentFile} handleClick={handleClick} />
  );
};
