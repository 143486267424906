import { Grid, Icon, Text } from '@candisio/design-system';
import { DocumentFormContainer } from 'components/Form/DocumentForm/DocumentFormContainer';
import { ProcessedDocument } from 'components/Form/DocumentForm/toolkit/types';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { DocumentStatus } from 'generated-types/graphql.types';
import { DocumentStatusFrontend } from 'models';
import { useOtherIntegration } from 'orgConfig/other';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { isPaidDocument } from 'utils/document_payment_data';

interface ExportedProps {
  document: ProcessedDocument;
}

export const ExportedDocumentFormFooter = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { shouldUseCoreDataApi } = useOtherIntegration();
  if (!shouldUseCoreDataApi) return null;

  return (
    <Grid autoFlow="column" padding="space4 space8" gap="space4">
      <Icon icon="infoCircle" color="blue500" size="space24" />
      <Text color="gray700">{children}</Text>
    </Grid>
  );
};

export const Exported = ({ document }: ExportedProps) => {
  const [t] = useTranslation();

  return (
    <ProcessSidebar
      documentId={document.id}
      globalDocumentId={document.globalDocumentId ?? undefined}
      documentStatus={
        isPaidDocument(document)
          ? DocumentStatusFrontend.ExportedAndPaid
          : DocumentStatus.Exported
      }
    >
      <DocumentFormContainer
        document={document}
        allowInlineEditing
        footer={
          <ExportedDocumentFormFooter>
            {t('document.details.exported.info')}
          </ExportedDocumentFormFooter>
        }
      />
    </ProcessSidebar>
  );
};
