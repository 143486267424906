import { Card, Grid, Heading } from '@candisio/design-system';
import { HookFormIbanField } from 'components/HookFormFields/HookFormIbanField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BankInfoSchemaValues } from './bankInfoSchema';

export const BankInfo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { trigger, watch } = useFormContext<BankInfoSchemaValues>();
  const swiftCode = watch('swiftCode');

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // trigger validations for iban when swift code changes, e.g. when its removed so that IBAN becomes optional
    void trigger('iban');
  }, [swiftCode, trigger]);

  return (
    <Grid gap="space8">
      <Heading as="h3">{t('userProfile.bankDetails.header')}</Heading>
      <Card background="gray100">
        <Grid gap="space16" templateColumns="1.5fr 1fr">
          <HookFormIbanField
            name="iban"
            label={t('common:header.profile.tabs.update.form.iban.label')}
          />
          <HookFormTextField
            name="swiftCode"
            inputProps={{
              autoComplete: 'off',
            }}
            label={t('common:header.profile.tabs.update.form.bic.label')}
          />
        </Grid>
      </Card>
    </Grid>
  );
};
