// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { useCallback, useContext } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory, useLocation } from 'react-router-dom';
import { Routes } from '../../../../../models';
import { useOrganizationId } from '../../../../../providers/OrganizationProvider';
import { ExportContext } from '../../Context';

export const useGotoExport = () => {
  const orgId = useOrganizationId();
  const history = useHistory();
  const { entity, table } = useContext(ExportContext);

  const onGoToExport = useCallback(
    (exportId: string) => {
      history.push({
        pathname: `/${orgId}${Routes.EXPORTS}/${exportId}`,
        search: qs.stringify({
          entity,
          table,
        }),
      });
    },
    [history, orgId, entity, table]
  );

  return onGoToExport;
};

export const useGoToDatevSettings = () => {
  const orgId = useOrganizationId();
  const history = useHistory();

  const onGoToDatevSettings = useCallback(() => {
    history.push(`/${orgId}${Routes.SETTINGS}${Routes.DATEV}`);
  }, [history, orgId]);

  return onGoToDatevSettings;
};

export const useGoToDocument = () => {
  const orgId = useOrganizationId();
  const history = useHistory();
  const { search, pathname } = useLocation();

  const onGoToDocument = useCallback(
    (documentId: string) => {
      history.push({
        pathname: `/${orgId}${Routes.ARCHIVE}/${documentId}`,
        state: {
          from: pathname,
          search,
        },
      });
    },
    [history, orgId, pathname, search]
  );

  return onGoToDocument;
};

export const useGoToTransaction = () => {
  const orgId = useOrganizationId();
  const history = useHistory();
  const { search, pathname } = useLocation();

  const onGoToTransaction = useCallback(
    (transactionId: string) => {
      history.push({
        pathname: `/${orgId}${Routes.ARCHIVE}${Routes.TRANSACTIONS}/${transactionId}`,
        state: {
          from: pathname,
          search,
        },
      });
    },
    [history, orgId, pathname, search]
  );

  return onGoToTransaction;
};

export const goTo = {
  useGotoExport,
  useGoToDatevSettings,
  useGoToDocument,
  useGoToTransaction,
};
