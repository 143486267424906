import {
  DocumentPreview,
  DocumentPreviewProps,
} from 'containers/DocumentPreview/DocumentPreview';
import { useGetExpenseForPreview } from './useGetExpenseForPreview';

interface ExpensePreviewContainerProps
  extends Pick<DocumentPreviewProps, 'onClose' | 'onOpen' | 'showPdf'> {
  documentId: string;
}

export const DocumentPreviewForExpenseContainer = ({
  documentId,
  onClose,
  onOpen,
  showPdf,
}: ExpensePreviewContainerProps) => {
  const { file, isExpenseForPreviewLoading } = useGetExpenseForPreview({
    expenseId: documentId,
  });

  return (
    <DocumentPreview
      documentFile={file}
      loading={isExpenseForPreviewLoading}
      onClose={onClose}
      onOpen={onOpen}
      showPdf={showPdf}
    />
  );
};
