import { Box, TableRow, Text, TruncatedText } from '@candisio/design-system';
import { getTranslationContext } from 'orgConfig';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderProps } from 'react-table';
import { CreditCardSettlementsTableData } from './types';

export const TableBodyLayout = ({
  colSpan,
  children,
}: {
  colSpan: number;
  children: ReactNode;
}) => {
  return (
    <TableRow>
      <Box as="td" colSpan={colSpan} style={{ background: '#F6F6F6' }}>
        {children}
      </Box>
    </TableRow>
  );
};

export const Header = ({
  column,
}: HeaderProps<CreditCardSettlementsTableData>) => {
  const [tCreditCards] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <TruncatedText wordBreak="break-all">
      {tCreditCards(`columns.${column.id}`, {
        context: getTranslationContext(),
      })}
    </TruncatedText>
  );
};

export const Cell = ({
  value,
}: HeaderProps<CreditCardSettlementsTableData>) => {
  return <Text wordBreak="break-word">{value}</Text>;
};
