import { FieldContainer, Grid, Input, Text } from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { UserRole } from 'generated-types/graphql.types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { isNil } from 'lodash';
import { Routes } from 'models';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useFocusWithin } from 'react-aria';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom-v5-compat';
import { useApnSuggestionSettings } from 'views/Settings/SuggestionsAndExtractions/components/ContactSettings/useApnSuggestionSettings';
import { AccountsPayableNumberActionsContainer } from './AccountsPayableNumberActionsContainer';

export interface AccountsPayableNumberFieldProps<
  TFormValues extends FieldValues,
> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Is field disabled? */
  disabled?: boolean;
  /** Field label */
  label?: string;
  /** Placeholder text shown when no value is set */
  placeholder?: string;
  /** Is field read only? */
  readOnly?: boolean;
  /** If parent form is creating new contact */
  isCreatingNewContact?: boolean;
}

/**
 * Accounts payable number field for React Hook Form. Displays a suggested value
 * that the user can accept or discard.
 */
export const AccountsPayableNumberField = <TFormValues extends FieldValues>({
  control,
  disabled,
  label,
  name,
  placeholder,
  readOnly: readOnlyProp,
  isCreatingNewContact,
}: AccountsPayableNumberFieldProps<TFormValues>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CONTACTS);
  const currentUser = useCurrentUser();
  const { organizationSlug } = useParams<{ organizationSlug?: string }>();
  const { field, fieldState, formState } = useController({
    control,
    name,
  });

  const { friendlyFormatAccountsNumber, removeSpacesConditionally } =
    useAccountingNumberFormatters();

  const { enabled } = useApnSuggestionSettings();
  const [isFocused, setIsFocused] = useState(false);
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: setIsFocused,
  });

  const { onChange, value, ref, onBlur, ...fieldProps } = field;

  const [suggestedNumber, setSuggestedNumber] = useState<string | null>(null);

  const [friendlyValue, setFriendlyValue] = useState(
    value ? friendlyFormatAccountsNumber(value) : ''
  );

  if (isNil(value) && friendlyValue.trim() !== '') {
    setFriendlyValue('');
  } else if (
    friendlyValue !== undefined &&
    value !== undefined &&
    typeof value === 'string' &&
    friendlyValue.replaceAll(' ', '') !== (value as string).replaceAll(' ', '')
  ) {
    setFriendlyValue(friendlyFormatAccountsNumber(value ?? ''));
  }

  const suggestNextFreeNumberToNewContact =
    isNil(value) &&
    isCreatingNewContact &&
    !fieldState.isDirty &&
    !fieldState.isTouched;

  const suggestNextFreeNumberToExistingContact =
    isNil(value) &&
    !isCreatingNewContact &&
    !fieldState.isDirty &&
    !fieldState.isTouched &&
    isFocused;

  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;

  const readOnly = readOnlyProp || formState.isSubmitting;

  const usingSuggestedNumber =
    !isNil(suggestedNumber) && !isNil(value) && suggestedNumber === value;

  const canSeeConfigureButton = Boolean(
    currentUser?.roles?.includes(UserRole.Admin)
  );

  const fieldVariant = () => {
    if (hasError) {
      return 'error';
    }

    if (usingSuggestedNumber) {
      return 'success';
    }

    return undefined;
  };

  const successMessage = (
    <Grid fontSize="small">
      <Text fontWeight="bold">
        {t('form.accountsPayableNumber.findNextAccountNumber.fieldInfo.title')}
      </Text>
      <Text>
        {t(
          'form.accountsPayableNumber.findNextAccountNumber.fieldInfo.source'
        )}{' '}
      </Text>
    </Grid>
  );

  const fieldMessage = () => {
    if (hasError) {
      return errorMessage;
    }

    if (usingSuggestedNumber) {
      return successMessage;
    }

    return undefined;
  };

  return (
    <Grid
      autoFlow="column"
      templateColumns={enabled ? '1fr auto' : '1fr'}
      gap="space14"
      justifyContent="center"
      alignItems="center"
    >
      <FieldContainer
        disabled={disabled}
        aria-label={label}
        label={
          <Grid autoFlow="column" justifyContent="space-between" gap="space4">
            <Text as="span">{label}</Text>
            {canSeeConfigureButton && (
              <RouterLink
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.SMART_FIELD_SUGGESTIONS}`}
                style={{
                  pointerEvents: 'all',
                }}
              >
                {t(
                  'form.accountsPayableNumber.findNextAccountNumber.configureButton'
                )}
              </RouterLink>
            )}
          </Grid>
        }
        message={fieldMessage()}
        readOnly={readOnly}
        variant={fieldVariant()}
        {...focusWithinProps}
      >
        <Grid gap="space8" templateColumns="1fr auto">
          <Input
            message={fieldMessage()}
            variant={fieldVariant()}
            disabled={disabled}
            value={friendlyValue}
            onChange={e => {
              const result = e.target.value !== '' ? e.target.value : null;

              setFriendlyValue(result ?? '');
              onChange(result && removeSpacesConditionally(result));
            }}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={ref}
            onBlur={e => {
              onBlur();
              setFriendlyValue(
                friendlyFormatAccountsNumber(e.target.value) ?? null
              );
            }}
            {...fieldProps}
            showMessageOnFocus={hasError}
          />
        </Grid>
      </FieldContainer>

      <AccountsPayableNumberActionsContainer
        setCurrentNumber={input => {
          setSuggestedNumber(input);
          setFriendlyValue(input);
          onChange(input);
        }}
        currentNumber={friendlyValue}
        findNextNumberOnRender={
          suggestNextFreeNumberToNewContact ||
          suggestNextFreeNumberToExistingContact
        }
        showSuggestion={true}
        disabled={disabled}
      />
    </Grid>
  );
};
