import gql from 'graphql-tag';
import {
  attachmentDeletedFromDocumentEventData,
  attachmentDetachedFromDocumentEventData,
  fileAttachedToDocumentEventData,
} from 'views/queries';

export const documentFileQuery = gql`
  query getDocumentFile($id: ID!) {
    getDocument(id: $id) {
      id
      status
      documentFile {
        id
        name
        url
        versions
        pages
        size
        eInvoice {
          id
          url
          name
        }
      }
      isEInvoice
      eInvoice  {
        format
        error
      }
      attachments {
        id
        name
        url
        pages
        rank
        size
        isDetachable
        originalDocumentId
        attachedBy {
          id
        }
      }
    }
  }
`;

export const documentAttachmentUploadUrl = gql`
  mutation documentAttachmentUploadUrl($options: UploadOptions!) {
    documentAttachmentUploadUrl(options: $options) {
      id
      url
      postOptions {
        field
        value
      }
    }
  }
`;

export const attachFileMutation = gql`
  mutation attachFiles($input: AttachFilesInput!) {
    attachFiles(input: $input) {
      __typename
      id
      timeline {
        __typename
        id
        createdAt
        ...FileAttachedToDocumentEventData
      }
      attachments {
        id
        name
        url
        pages
        rank
        size
      }
    }
  }
  ${fileAttachedToDocumentEventData}
`;

export const deleteAttachmentsMutation = gql`
  mutation deleteAttachments($input: DeleteAttachmentsInput!) {
    deleteAttachments(input: $input) {
      __typename
      id
      timeline {
        __typename
        id
        createdAt
        ...AttachmentDeletedFromDocumentEventData
      }
      attachments {
        id
        name
        url
        pages
        rank
        size
      }
    }
  }
  ${attachmentDeletedFromDocumentEventData}
`;

export const convertDocumentsToAttachmentsMutation = gql`
  mutation convertDocumentsToAttachments(
    $input: ConvertDocumentsToAttachmentsInput!
  ) {
    convertDocumentsToAttachments(input: $input) {
      id
    }
  }
`;

export const detachAttachmentsMutation = gql`
  mutation detachAttachments($input: DetachAttachmentsInput!) {
    detachAttachments(input: $input) {
      __typename
      id
      timeline {
        __typename
        id
        createdAt
        ...AttachmentDetachedFromDocumentEventData
      }
      attachments {
        id
        name
        url
        pages
        rank
        size
      }
    }
  }
  ${attachmentDetachedFromDocumentEventData}
`;
