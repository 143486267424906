import { z } from 'zod';

export type CheckContactName = (
  value: string
) => Promise<{ isAvailable: boolean; isEmployeeType?: boolean }>;

export interface ContactNameSchemaOptions {
  checkContactName?: CheckContactName;
  originalContactName?: string;
}

/** Schema to validate contact name, including duplicate check */
export const contactNameSchema = ({
  checkContactName,
}: ContactNameSchemaOptions = {}) =>
  z.string().refine(async value => {
    return await checkContactInternalNameAvailableValidation({
      name: value,
      checkContactName,
    });
  });

export const checkContactInternalNameAvailableValidation = async ({
  checkContactName,
  name,
}: {
  name?: string | null;
  checkContactName?: CheckContactName;
}) => {
  if (!checkContactName || !name) {
    return { isAvailable: true };
  }

  const reponseNameAvailability = await checkContactName(name);

  return reponseNameAvailability;
};
