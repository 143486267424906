export enum AppRouteParams {
  organizationSlug = 'organizationSlug',
}

export enum DocumentProcessingRouteParams {
  type = 'type',
  documentId = 'documentId',
}

export enum InboxTransactionAssociationRouteParams {
  type = 'type',
  transactionId = 'transactionId',
}

export enum Routes {
  APPROVALS = '/approvals',
  ARCHIVE = '/archive',
  AUTHORIZE_DATEV = '/auth/datev/authorize',
  AUTH_DATEV = '/auth/datev',
  BOOKING_ACCOUNT = '/booking_account',
  BOOKING_ACCOUNT_IMPORT_HISTORY = '/booking_account/import_history',
  TAX_CODE = '/tax_code',
  TAX_CODE_IMPORT_HISTORY = '/tax_code/import_history',
  COMPARE = '/compare',
  CONTACTS = '/contacts',
  CONTACT_IMPORT_HISTORY = '/contacts/import_history',
  CONTRACT_SUBCATEGORIES = '/contract_subcategories',
  COST_CENTER = '/cost_center',
  COST_CENTER_IMPORT_HISTORY = '/cost_center/import_history',
  CREDIT_CARDS = '/credit_cards',
  CREDIT_CARDS_CARD_MANAGER_OVERVIEW = '/credit_cards/dashboard/card_manager_overview',
  CREDIT_CARDS_DASHBOARD = '/credit_cards/dashboard',
  CREDIT_CARDS_CARDHOLDER_OVERVIEW = '/credit_cards/cardholder_overview',
  DASHBOARD = '/dashboard',
  DASHBOARD_CREDIT_CARD = '/dashboard/credit_card',
  DASHBOARD_WIDGET = '/dashboard/widget',
  DATEV = '/datev',
  DEV_TOOLS = '/dev-tools',
  DOCUMENT = '/doc',
  DOCUMENT_DATA_DOWNLOAD_HISTORY = '/document_data_download_history',
  DOCUMENT_TAGS = '/tags',
  DOCUMENT_TYPES = '/document_types',
  ACCOUNTING_AREAS = '/accounting_areas',
  DUPLICATE = '/duplicate',
  ECM_CONTRACTS = '/archive/contracts',
  ECM_DOCUMENTS = '/archive/stored_documents',
  ECM_SENSITIVE_CONTRACTS = '/inbox/sensitive_contracts',
  EXPORTS = '/exports',
  IAM_BASE = '/iam',
  INBOX = '/inbox',
  INBOX_CONTRACTS = '/inbox/contracts',
  INBOX_OTHER_DOCUMENTS = '/inbox/other_documents',
  INSIGHTS_WIDGETS = '/insights_widgets',
  MAIL_SYNC = '/mail',
  ORGANIZATION = '/organization',
  PAYMENTS = '/payments',
  PAYMENT_CONDITIONS = '/payment_conditions',
  PROVISIONS = '/provisions',
  RECURRING_PAYMENTS = '/recurring_payments',
  RELATIONSHIPS = '/relationships',
  SAP = '/sap',
  CANDIS_API = '/candis_api',
  REIMBURSEMENTS = '/reimbursements',
  REIMBURSEMENTS_DASHBOARD = '/reimbursements_dashboard',
  STATEMENTS = '/statements',
  SETTINGS = '/settings',
  MOBILE_APPLICATION = '/mobile_application',
  SLACK_REDIRECT = '/slack-redirect',
  SMART_FIELD_SUGGESTIONS = '/smart-field-suggestions',
  TAX_CODES = '/tax_codes',
  TEAM_MEMBERS = '/team_members',
  TRANSACTIONS = '/transactions',
  TRANSACTIONS_IMPORT_HISTORY = '/transactions/import_history',
  PURCHASE_ORDERS = '/purchase_orders',
  PURCHASE_ORDERS_IMPORT_HISTORY = '/purchase_orders/import_history',
  WORKFLOWS = '/workflows',
  AUTHENTICATION_CODE = '/authentication_code',
  GOODS_RECEIPTS = '/goods_receipts',
  GOODS_RECEIPTS_IMPORT_HISTORY = '/goods_receipts/import_history',
  PROJECT_CODES_ROUTE = '/project_codes',
}

export const compiledRoutes = {
  allTransactions: `${Routes.ARCHIVE}${Routes.TRANSACTIONS}`,
  allPurchaseOrders: `${Routes.ARCHIVE}${Routes.PURCHASE_ORDERS}`,
  allPurchaseOrdersImportHistoryRoute: `${Routes.ARCHIVE}${Routes.PURCHASE_ORDERS_IMPORT_HISTORY}`,
  allWidgetsRoute: `${Routes.INSIGHTS_WIDGETS}/:widgetId?`,
  approvalsRoute: `${Routes.APPROVALS}`,
  archiveRoute: `${Routes.ARCHIVE}`,
  arhiveGoodsReceiptsTableRoute: `${Routes.ARCHIVE}${Routes.GOODS_RECEIPTS}`,
  archiveEcmContractRoute: `${Routes.ECM_CONTRACTS}/:documentId`,
  archiveEcmContractsRoute: `${Routes.ECM_CONTRACTS}`,
  archiveEcmDocumentRoute: `${Routes.ECM_DOCUMENTS}/:documentId`,
  archiveEcmDocumentsRoute: Routes.ECM_DOCUMENTS,
  archiveTransactionAssociationRoute: `:type(${Routes.ARCHIVE})${Routes.TRANSACTIONS}/:transactionId`,
  // for matchRoutes to work, we need to duplicate the route with same name but without `:type` param
  __archiveTransactionAssociationRoute: `${Routes.ARCHIVE}${Routes.TRANSACTIONS}/:transactionId`,
  archiveReimbursementsRoute: `${Routes.ARCHIVE}${Routes.REIMBURSEMENTS}`,
  archiveReimbursementRoute: `:type(${Routes.ARCHIVE})${Routes.REIMBURSEMENTS}/:reimbursementId`,
  authDatevRoute: `${Routes.AUTH_DATEV}`,
  authorizeDatevRoute: `${Routes.AUTHORIZE_DATEV}`,
  bookingAccountImportHistoryRoute: `${Routes.BOOKING_ACCOUNT_IMPORT_HISTORY}`,
  bookingAccountRoute: `${Routes.BOOKING_ACCOUNT}`,
  taxCodeRoute: `${Routes.TAX_CODE}`,
  taxCodeImportHistoryRoute: `${Routes.TAX_CODE_IMPORT_HISTORY}`,
  contactImportHistoryRoute: `${Routes.CONTACT_IMPORT_HISTORY}`,
  contactsRoute: `${Routes.CONTACTS}`,
  costCenterImportHistoryRoute: `${Routes.COST_CENTER_IMPORT_HISTORY}`,
  costCenterRoute: `${Routes.COST_CENTER}`,
  creditCardsManagerOverviewRoute: `${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}/:creditCardId?`,
  creditCardsCardholderOverviewRoute: `${Routes.CREDIT_CARDS_CARDHOLDER_OVERVIEW}/:creditCardId?`,
  creditCardsStatements: `${Routes.CREDIT_CARDS_DASHBOARD}${Routes.STATEMENTS}/:statementId?`,
  creditCardsRoute: `${Routes.CREDIT_CARDS}/:creditCardId?`,
  dashboardCreditCardRoute: `${Routes.DASHBOARD_CREDIT_CARD}/:creditCardId?`,
  dashboardRoute: `${Routes.DASHBOARD}`,
  dashboardWidgetRoute: `${Routes.DASHBOARD_WIDGET}/:widgetId?`,
  datevRoute: `${Routes.DATEV}`,
  devToolsRoute: `${Routes.DEV_TOOLS}`,
  documentApprovalRoute: `:type(${Routes.APPROVALS})/:documentId?`,
  // for matchRoutes to work, we need to duplicate the route with same name but without `:type` param
  __documentApprovalRoute: `${Routes.APPROVALS}/:documentId?`,
  documentArchiveRoute: `${Routes.ARCHIVE}/:documentId`,
  documentDataDownloadHistoryRoute: `${Routes.DOCUMENT_DATA_DOWNLOAD_HISTORY}`,
  documentDataHistoryRoute: `${Routes.DOCUMENT_DATA_DOWNLOAD_HISTORY}`,
  documentProcessingRoute: `:type(${Routes.INBOX})/:documentId`,
  // for matchRoutes to work, we need to duplicate the route with same name but without `:type` param
  __documentProcessingRoute: `${Routes.INBOX}/:documentId`,
  documentRoute: `${Routes.DOCUMENT}`,
  duplicateRoute: `${Routes.DUPLICATE}`,
  editBookingAccountRoute: `${Routes.BOOKING_ACCOUNT}/:generalLedgerAccountId?`,
  editBookingKeyRoute: `${Routes.TAX_CODE}/:bookingKeyId?`,
  editContactRoute: `${Routes.CONTACTS}/:contactId?`,
  editCostCenterRoute: `${Routes.COST_CENTER}/:costCenterId?`,
  editOrganizationRoute: `${Routes.ORGANIZATION}/:orgSlug?`,
  editTaxCodeRoute: `${Routes.TAX_CODES}/:bookingKeyId?`,
  editUserRoute: `${Routes.TEAM_MEMBERS}/:userId?`,
  editUserSettingsRoute: `${Routes.SETTINGS}${Routes.TEAM_MEMBERS}/:userId?`,
  editWorkflowRoute: `${Routes.WORKFLOWS}/:workflowId?`,
  exportsRoute: `${Routes.EXPORTS}`,
  graphiqlEditorRoute: `${Routes.DEV_TOOLS}/graphiql`,
  goodsReceiptsImportHistoryRoute: `${Routes.ARCHIVE}${Routes.GOODS_RECEIPTS_IMPORT_HISTORY}`,
  iamBaseRoute: `${Routes.IAM_BASE}`,
  inboxRoute: `${Routes.INBOX}`,
  inboxReimbursementsRoute: `${Routes.INBOX}${Routes.REIMBURSEMENTS}`,
  inboxReimbursementRoute: `:type(${Routes.INBOX})${Routes.REIMBURSEMENTS}/:reimbursementId`,
  inboxTransactionAssociationRoute: `:type(${Routes.INBOX})${Routes.TRANSACTIONS}/:transactionId`,
  // for matchRoutes to work, we need to duplicate the route with same name but without `:type` param
  __inboxTransactionAssociationRoute: `:type(${Routes.INBOX})${Routes.TRANSACTIONS}/:transactionId`,
  inboxSensitiveContractECM: `${Routes.ECM_SENSITIVE_CONTRACTS}/:documentId`,
  inboxContractECM: `${Routes.INBOX_CONTRACTS}/:documentId`,
  inboxContractsECM: `${Routes.INBOX_CONTRACTS}`,
  inboxOtherDocumentECM: `${Routes.INBOX_OTHER_DOCUMENTS}/:documentId`,
  inboxOtherDocumentsECM: `${Routes.INBOX_OTHER_DOCUMENTS}`,
  inboxSensitiveContractsECM: `${Routes.ECM_SENSITIVE_CONTRACTS}`,
  inboxTransactions: `${Routes.INBOX}${Routes.TRANSACTIONS}`,
  insightsCreditCardsRoute: `${Routes.CREDIT_CARDS_DASHBOARD}/:creditCardId?`,
  recurringPaymentsModalRoute: `${Routes.CREDIT_CARDS_DASHBOARD}${Routes.RECURRING_PAYMENTS}/:vendorId?`,
  mailSyncRoute: `${Routes.MAIL_SYNC}`,
  manageRelationshipsInbox: `${Routes.INBOX}/:documentId${Routes.RELATIONSHIPS}`,
  manageRelationshipsApprovals: `${Routes.APPROVALS}/:documentId${Routes.RELATIONSHIPS}`,
  manageRelationshipsArchive: `${Routes.ARCHIVE}/:documentId${Routes.RELATIONSHIPS}`,
  manageRelationshipsEcmArchive: `/:type/:ecmType/:documentId${Routes.RELATIONSHIPS}`,
  organizationsRoute: `${Routes.ORGANIZATION}`,
  paymentsRoute: `${Routes.PAYMENTS}`,
  provisionsRoute: `${Routes.PROVISIONS}`,
  resolveDuplicateRoute: `${Routes.DUPLICATE}/:originalDocumentId/:documentId/:transactionId?`,
  compareDocumentsRoute: `${Routes.COMPARE}/:documentId/:relatedDocumentId?`,
  sapRoute: `${Routes.SAP}`,
  setSmartFieldSuggestionsRoute: `${Routes.SMART_FIELD_SUGGESTIONS}`,
  settingsCreditCardsRoute: `${Routes.SETTINGS}${Routes.CREDIT_CARDS}`,
  settingsMobileApplicationRoute: `${Routes.SETTINGS}${Routes.MOBILE_APPLICATION}`,
  settingsContractSubcategoriesRoute: `${Routes.SETTINGS}${Routes.CONTRACT_SUBCATEGORIES}`,
  settingsContractSubcategoryRoute: `${Routes.SETTINGS}${Routes.CONTRACT_SUBCATEGORIES}/:contractSubCategoryId`,
  settingsDocumentTypesRoute: `${Routes.SETTINGS}${Routes.DOCUMENT_TYPES}`,
  settingsRoute: `${Routes.SETTINGS}`,
  teamMembersRoute: `${Routes.TEAM_MEMBERS}`,
  transactionsDownloadHistory: `${Routes.TRANSACTIONS_IMPORT_HISTORY}`,
  reimbursementsDashboardRoute: Routes.REIMBURSEMENTS_DASHBOARD,
  reimbursementDashboardRoute: `:type(${Routes.REIMBURSEMENTS_DASHBOARD})/:reimbursementId`,
  workflowsRoute: `${Routes.WORKFLOWS}`,
  projectCodesRoute: `${Routes.PROJECT_CODES_ROUTE}`,
} as const;

export enum DocumentStatusFrontend {
  ApprovedAndPaid = 'APPROVED_AND_PAID',
  AwaitingApproval = 'AWAITING_APPROVAL',
  Draft = 'DRAFT',
  ExportedAndPaid = 'EXPORTED_AND_PAID',
  Stored = 'STORED',
}

export enum FieldSources {
  contact = 'contact',
  gini = 'gini',
  templateBased = 'templateBased',
  'gini-approver' = 'gini-approver',
  smartFieldSuggestions = 'smartFieldSuggestions',
  costCenter = 'costCenter',
  workflow = 'workflow',
  system = 'system',
  transaction = 'transaction',
  creditCard = 'creditCard',
}
