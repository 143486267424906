import { getUniqueUsersById } from 'components/DocumentAccess/utils';
import {
  useDocumentAccessLevelsQuery,
  ActivityUser,
  AccessLevelName,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

enum AccessLevelRoleName {
  Admins = 'ADMINS',
  Accountants = 'ACCOUNTANTS',
  Requesters = 'REQUESTERS',
  Approvers = 'APPROVERS',
  Viewers = 'VIEWERS',
  ResponsibleAndNotifiedPersons = 'RESPONSIBLE_AND_NOTIFIED_PERSONS',
  ResponsibleNotifiedAndUploader = 'RESPONSIBLE_NOTIFIED_AND_UPLOADER',
}

export type AccessByData =
  | {
      users: ActivityUser[] | null;
      roleName: AccessLevelRoleName;
      roleTranslationKey: string;
      accessTranslationKey: string;
    }
  | undefined;

interface UseDocumentAccessLevelDataProps {
  globalDocumentId?: string;
}

export const useDocumentAccessLevelsData = ({
  globalDocumentId,
}: UseDocumentAccessLevelDataProps) => {
  const [accountantRoleFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.accountantRole,
  ]);

  const { data, loading } = useDocumentAccessLevelsQuery({
    fetchPolicy: 'no-cache',
    skip: !globalDocumentId,
    variables: globalDocumentId ? { globalDocumentId } : undefined,
  });

  const documentAccessLevels =
    data?.documentAccessLevels?.__typename === 'AccessLevels'
      ? data.documentAccessLevels.levels
      : [];

  const accessLevels = documentAccessLevels.reduce(
    (acc, level) => {
      acc[level.name] = level.users;

      return acc;
    },
    {} as Record<AccessLevelName, ActivityUser[]>
  );

  const uniqueResponsibleNotifiedUploaderUsers = getUniqueUsersById([
    ...(accessLevels[AccessLevelName.ResponsiblePersons] ?? []),
    ...(accessLevels[AccessLevelName.NotifiedPersons] ?? []),
    ...(accessLevels[AccessLevelName.Uploader] ?? []),
  ]);

  const uniqueResponsibleNotifiedUsers = getUniqueUsersById([
    ...(accessLevels[AccessLevelName.ResponsiblePersons] ?? []),
    ...(accessLevels[AccessLevelName.NotifiedPersons] ?? []),
  ]);

  // AccessByData
  const accessByRoleData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Admins],
      roleName: AccessLevelRoleName.Admins,
      roleTranslationKey: 'documentAccess.membership.builtinRoles.admins',
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
    accountantRoleFF
      ? {
          users: accessLevels[AccessLevelName.Accountants],
          roleName: AccessLevelRoleName.Accountants,
          roleTranslationKey:
            'documentAccess.membership.builtinRoles.accountants',
          accessTranslationKey: 'documentAccess.membership.access.edit',
        }
      : undefined,
    {
      users: accessLevels[AccessLevelName.Requesters],
      roleName: AccessLevelRoleName.Requesters,
      roleTranslationKey: 'documentAccess.membership.builtinRoles.requesters',
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
  ];

  const accessDataForSensitiveDocuments: AccessByData[] = [
    {
      users: uniqueResponsibleNotifiedUploaderUsers,
      roleName: AccessLevelRoleName.ResponsibleNotifiedAndUploader,
      roleTranslationKey:
        'documentAccess.membership.access.contracts.notifiedResponsibleOrUploader',
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
  ];

  const accessDataForContracts: AccessByData[] = [
    {
      users: uniqueResponsibleNotifiedUsers,
      roleName: AccessLevelRoleName.ResponsibleAndNotifiedPersons,
      roleTranslationKey:
        'documentAccess.membership.access.contracts.notifiedOrResponsiblePersons',
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
  ];

  const accessByApproversData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Approvers],
      roleName: AccessLevelRoleName.Approvers,
      roleTranslationKey: 'documentAccess.membership.builtinRoles.approvers',
      accessTranslationKey: 'documentAccess.membership.access.approve',
    },
  ];

  const accessByViewersData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Viewers],
      roleName: AccessLevelRoleName.Viewers,
      roleTranslationKey: 'documentAccess.membership.access.viewers.title',
      accessTranslationKey: 'documentAccess.membership.access.view',
    },
  ];

  return {
    admins: accessLevels[AccessLevelName.Admins],
    accountants: accessLevels[AccessLevelName.Accountants],
    requesters: accessLevels[AccessLevelName.Requesters],
    viewers: accessLevels[AccessLevelName.Viewers],
    approvers: accessLevels[AccessLevelName.Approvers],
    accessByRoleData,
    accessByApproversData,
    accessByViewersData,
    accessDataForSensitiveDocuments,
    accessDataForContracts,
    loading,
  };
};
