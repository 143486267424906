import React, { forwardRef, useRef } from 'react';
import { AriaGridListOptions, useGridList } from 'react-aria';
import { Item, ListProps as AriaListProps, useListState } from 'react-stately';
import { Components, Virtuoso } from 'react-virtuoso';
import { GridProps } from '../../Atoms/Grid';
import { LIST_PADDING } from '../../Atoms/ListBox/List';
import { ScrollBox } from '../../Atoms/ScrollBox';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { List } from './List';
import { ListItem } from './ListItem';
import { VirtuosoItem } from './VirtuosoItem';
import { VirtuosoList } from './VirtuosoList';
import { VirtuosoScroller } from './VirtuosoScroller';

export interface ListViewProps<TListItem = any>
  extends LayoutProps,
    Omit<StandardHTMLAttributes<HTMLDivElement>, 'children'> {
  children?: AriaListProps<TListItem>['children'];
  selectedKeys?: AriaGridListOptions<TListItem>['selectedKeys'];
  onSelectionChange?: AriaGridListOptions<TListItem>['onSelectionChange'];
  disabledKeys?: AriaGridListOptions<TListItem>['disabledKeys'];
  defaultSelectedKeys?: AriaGridListOptions<TListItem>['defaultSelectedKeys'];
  isVirtualized?: AriaGridListOptions<TListItem>['isVirtualized'];
  items?: AriaListProps<TListItem>['items'];
  onAction?: AriaGridListOptions<TListItem>['onAction'];
  /** Called when user scrolls to the end of the list */
  onEndReached?: (index: number) => void;
  selectionMode?: AriaGridListOptions<TListItem>['selectionMode'];
  rowStyles?: Omit<GridProps<'div'>, 'as' | 'children'>;
}

const defaultChildren: ListViewProps['children'] = ({
  children,
  key,
  textValue,
}) => (
  <Item key={key} textValue={textValue}>
    {children}
  </Item>
);

/**
 * Displays a list of interactive items with support for keyboard navigation and row actions.
 *
 * [Storybook]{@link (https://candisio.github.io/design-system/story/molecules-listview--default-story)}
 */
export const ListView = forwardRef<HTMLDivElement, ListViewProps>(
  (
    {
      'aria-describedby': ariaDescribedBy,
      'aria-details': ariaDetails,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      children = defaultChildren,
      disabledKeys,
      selectedKeys,
      selectionMode = 'none',
      onSelectionChange,
      height,
      id,
      isVirtualized,
      items = [],
      maxHeight,
      onAction,
      onEndReached,
      rowStyles = {
        width: '100%',
        justifyContent: 'start',
        alignItems: 'center',
        gap: 'space8',
        paddingX: 'space16',
        borderTop: '1px solid gray200',
      },
      ...restProps
    },
    ref
  ) => {
    const state = useListState({
      children,
      items,
      onSelectionChange,
      selectionMode,
      selectedKeys,
      selectionBehavior: selectionMode === 'multiple' ? 'toggle' : undefined,
      disabledKeys,
      disabledBehavior: 'selection',
    });

    const listRef = useRef<HTMLUListElement | null>(null);
    const { gridProps } = useGridList(
      {
        'aria-describedby': ariaDescribedBy,
        'aria-details': ariaDetails,
        'aria-label':
          // suppress console warning
          ariaLabel || ariaLabelledBy ? ariaLabel : 'Listbox',
        'aria-labelledby': ariaLabelledBy,
        id,
        isVirtualized,
        items,
        onAction,
      },
      state,
      listRef
    );

    const data = Array.from(state.collection);

    const itemHeight = 'space32';

    if (isVirtualized) {
      return (
        <Virtuoso
          components={{
            Item: VirtuosoItem as Components['Item'],
            List: VirtuosoList as Components['List'],
            Scroller: VirtuosoScroller as Components['Scroller'],
          }}
          context={{
            listProps: {
              ...gridProps,
              ref: listRef,
            },
            scrollerProps: {
              maxHeight: maxHeight ?? '100%',
              height:
                height ??
                `calc((${itemHeight} * ${data.length}) + (${LIST_PADDING} * 2))`,
              ...restProps,
              ref,
            },
            state,
            rowStyles,
          }}
          data={data}
          totalCount={data.length}
          endReached={onEndReached}
        />
      );
    }

    return (
      <ScrollBox background="gray0" {...restProps} ref={ref}>
        <List {...gridProps} ref={listRef}>
          {data.map(item => (
            <ListItem
              key={item.key}
              item={item}
              state={state}
              rowStyles={rowStyles}
            />
          ))}
        </List>
      </ScrollBox>
    );
  }
);
