import { Grid } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreditCardSetupFormValues } from 'views/Settings/Teams/toolkit/utils/creditCardSetupFormSchema';

export const LimitsPanel = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { watch } = useFormContext<CreditCardSetupFormValues>();

  const isAllowedToManageCards =
    watch('isTeamAdminAllowedToManageCards') ?? false;

  return (
    <Grid
      templateColumns="1fr 1fr"
      padding="space16"
      gap="space8"
      background="gray100"
      borderBottomRadius="medium"
    >
      <HookFormAmountField
        minimumFractionDigits={0}
        maximumFractionDigits={0}
        readOnly={!isAllowedToManageCards}
        minValue={0}
        currency="EUR"
        name="limit"
        label={t('teams.editTeam.creditCardsSetup.form.fields.limit.label')}
        placeholder={t(
          'teams.editTeam.creditCardsSetup.form.fields.limit.placeholder'
        )}
      />
      <HookFormAmountField
        name="oneTimeCardLimit"
        minimumFractionDigits={0}
        readOnly={!isAllowedToManageCards}
        maximumFractionDigits={0}
        minValue={0}
        currency="EUR"
        label={t(
          'teams.editTeam.creditCardsSetup.form.fields.oneTimeCardLimit.label'
        )}
        placeholder={t(
          'teams.editTeam.creditCardsSetup.form.fields.oneTimeCardLimit.placeholder'
        )}
      />
    </Grid>
  );
};
