import { Grid, Paragraph, Text } from '@candisio/design-system';
import { MetaDataWrapper } from 'components/DocumentHistory/MetaDataWrapper';
import { ExpenseItemHospitalityExternalGuestsUpdatedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const ExpenseItemHospitalityExternalGuestsUpdated = ({
  properties,
  reimbursementItemMetadata,
}: ExpenseItemHospitalityExternalGuestsUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const headline = expenseTitle
    ? t('timeline.expenseChanged', { title: expenseTitle })
    : t('timeline.expenseChangedNoTitle');
  return (
    <Grid>
      <Text>{headline}</Text>
      <MetaDataWrapper>
        <div>
          <Text fontWeight="regular" color="gray500">
            {t(
              'reimbursementView.middleSection.form.hospitality.externalGuests.label'
            )}
          </Text>
          <Paragraph>
            {properties.newExternalGuests?.length
              ? properties.newExternalGuests.map(guest => guest + ' ')
              : t('timeline.empty')}
          </Paragraph>
        </div>
      </MetaDataWrapper>
    </Grid>
  );
};
