import { Box } from '@candisio/design-system';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { DetailsLayout } from 'components/Layouts/DetailsLayout';
import { DocumentProcessingContextProvider, useInboxContext } from '../Context';
import { LeftSection } from './LeftSection';
import { RightSection } from './RightSection';
import { DocumentTypeContextProvider } from 'views/DocumentDetails/components/DocumentTypeContext';

const DocumentProcessingComponent = () => {
  const {
    documentId,
    mainDocumentFile,
    isDocumentFileLoading,
    useAttachmentsResult: {
      attachments,
      attachPermissions,
      selectedPdf,
      setSelectedPdf,
    },
  } = useInboxContext();

  return (
    <DocumentTypeContextProvider initiallyAnInvoice>
      <DetailsLayout
        key={documentId}
        leftSection={<LeftSection />}
        middleSection={
          <Box height="100%" width="100%" paddingTop="space32" overflowY="auto">
            <DocumentViewer
              documentId={documentId}
              attachments={attachments}
              canAttachFiles={attachPermissions}
              documentFile={mainDocumentFile}
              selectedFile={selectedPdf}
              onSelectDocument={setSelectedPdf}
              isLoadingPdf={isDocumentFileLoading}
            />
          </Box>
        }
        rightSection={<RightSection />}
      />
    </DocumentTypeContextProvider>
  );
};

export const DocumentProcessing = () => (
  <DocumentProcessingContextProvider>
    <DocumentProcessingComponent />
  </DocumentProcessingContextProvider>
);
