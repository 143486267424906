import {
  Button,
  MenuButton,
  MenuItem,
  TruncatedText,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface DownloadMenuButtonProps {
  isDisabled: boolean;
  isLoading: boolean;
  isTableFiltered: boolean;
  isTableSearched: boolean;
  items?: Array<MenuItem>;
  //* onClick can be provided if the menu button should not open a menu but instead trigger an action directly, do not pass `items` in this situation
  onClick?: () => void;
  selectedDocumentsCount: number;
}

export const DownloadMenuButton = ({
  isDisabled,
  isLoading,
  isTableFiltered,
  isTableSearched,
  items,
  onClick,
  selectedDocumentsCount: count,
}: DownloadMenuButtonProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ARCHIVE);

  const buttonText =
    !isTableFiltered && !isTableSearched
      ? t('download.menuButton.label', { count })
      : t('download.menuButton.labelFiltered', { count });

  // number of digits + 2 parenthesis
  const charsAfterEllipsis = String(count).length + 2;

  if (onClick && !items) {
    return (
      <Button
        loading={isLoading}
        disabled={isDisabled}
        variant="tertiary"
        icon="arrowDown"
        iconPosition="left"
        onClick={onClick}
      >
        <TruncatedText charsAfterEllipsis={charsAfterEllipsis}>
          {buttonText}
        </TruncatedText>
      </Button>
    );
  }

  return (
    <MenuButton
      variant="tertiary"
      position="relative"
      loading={isLoading}
      disabled={isDisabled}
      icon="arrowDown"
      iconPosition="left"
      items={items}
      selectionMode="single"
    >
      <TruncatedText charsAfterEllipsis={charsAfterEllipsis}>
        {buttonText}
      </TruncatedText>
    </MenuButton>
  );
};
