import { Grid, Heading, Skeleton } from '@candisio/design-system';
import { ReactNode } from 'react';

export interface TableTitleProps {
  loading: boolean;
  children: ReactNode;
}

export const TableTitle = ({ children, loading }: TableTitleProps) => {
  return (
    <Heading as="h2">
      {loading ? (
        <Grid
          inline
          gap="space16"
          templateColumns="2ch 25ch"
          height="1em"
          verticalAlign="middle"
        >
          <Skeleton height="100%" width="100%" />
          <Skeleton height="100%" width="100%" />
        </Grid>
      ) : (
        children
      )}
    </Heading>
  );
};
