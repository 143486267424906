import { Box, Grid } from '@candisio/design-system';
import { BackButton } from 'components/BackButton/BackButton';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { useGetDocumentGlobalIdQuery } from 'generated-types/graphql.types';
import { useDocumentFile } from 'hooks/useDocumentFile';
import { ComponentProps, memo } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { DocumentRelationsDrawer } from './components/DocumentRelationsDrawer';
import { useRelatedDocumentFiles } from './useRelatedDocumentFiles';

interface RouteParams {
  organizationSlug: string;
  documentId: string;
  relatedDocumentId: string;
}

const DocumentLayout = (props: ComponentProps<typeof DocumentViewer>) => (
  <Box overflowY="auto" height="100%">
    <DocumentViewer hideAttachmentSection {...props} />
  </Box>
);

const RelatedDocumentContainer = ({
  relatedDocumentId,
  mainDocumentId,
}: {
  relatedDocumentId: string;
  mainDocumentId: string;
}) => {
  const { documentFile, attachments } = useRelatedDocumentFiles(mainDocumentId);

  return (
    <DocumentLayout
      documentId={relatedDocumentId}
      documentFile={documentFile}
      attachments={attachments}
    />
  );
};

const MainDocumentContainer = ({
  documentId,
}: {
  documentId: string;
}) => {
  const { documentFile } = useDocumentFile({ documentId });

  return <DocumentLayout documentId={documentId} documentFile={documentFile} />;
};

const DrawerContainer = memo(({ documentId }: { documentId: string }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const { data } = useGetDocumentGlobalIdQuery({
    variables: { id: documentId },
  });

  const documentGlobalId = data?.getDocument?.globalDocumentId ?? '';

  return (
    <Grid
      height="100%"
      overflow="hidden"
      templateRows="auto 1fr"
      rowGap="space8"
      paddingBottom="space16"
    >
      <Grid justifyContent="start">
        <BackButton onClick={() => navigate(-1)}>
          {t('documentDetailsCard.backToList')}
        </BackButton>
      </Grid>
      <DocumentRelationsDrawer globalDocumentId={documentGlobalId} />
    </Grid>
  );
});

export const CompareDocumentsView = () => {
  const { relatedDocumentId, documentId } = useParams<RouteParams>();

  return (
    <Grid
      paddingTop="space32"
      paddingRight="space24"
      templateColumns="1fr 2fr 2fr"
      autoFlow="column"
      columnGap="space12"
      height="100vh"
      overflow="hidden"
    >
      <DrawerContainer documentId={documentId} />
      <RelatedDocumentContainer
        mainDocumentId={documentId}
        relatedDocumentId={relatedDocumentId}
      />
      <MainDocumentContainer documentId={documentId} />
    </Grid>
  );
};
