import { filter, map } from 'lodash';
import { ComponentProps, forwardRef, Fragment, useRef } from 'react';
import { useListBox } from 'react-aria';
import { useListState } from 'react-stately';
import { Components, GroupedVirtuoso } from 'react-virtuoso';
import { useTheme } from '../../../Theme';
import { Box } from '../../Box';
import { Option } from '../../Option';
import { ScrollBox } from '../../ScrollBox';
import { List, LIST_PADDING } from '../List';
import { defaultChildren, ListBoxProps } from '../ListBox';
import { VirtuosoScroller } from '../VirtuosoScroller';
import { GroupedItemContent } from './GroupedItemContent';
import { GroupHeader } from './GroupHeader';
import { VirtuosoGroupedList } from './VirtuosoGroupedList';

export const groupedListPadding = (value: string) => ({
  paddingTop: value,
  paddingBottom: value,
});

export interface GroupedListBoxProps extends ListBoxProps {
  initialTopMostItemIndex?: ComponentProps<
    typeof GroupedVirtuoso
  >['initialTopMostItemIndex'];
  groupedLists?: Array<Array<any>>;
  groupHeaders?: string[];
}

export const GroupedListBox = forwardRef<HTMLDivElement, GroupedListBoxProps>(
  (
    {
      'aria-describedby': ariaDescribedBy,
      'aria-details': ariaDetails,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      autoFocus,
      children = defaultChildren,
      defaultSelectedKeys,
      disabledKeys,
      disallowEmptySelection,
      height = '0',
      id,
      initialTopMostItemIndex,
      isVirtualized,
      items = [],
      keyboardDelegate,
      label,
      maxHeight,
      onAction,
      onBlur,
      onEndReached,
      onFocus,
      onFocusChange,
      onSelectionChange,
      selectedKeys,
      selectionBehavior,
      selectionMode,
      shouldFocusOnHover,
      shouldFocusWrap,
      shouldSelectOnPressUp,
      shouldUseVirtualFocus,
      state: stateProp,
      itemHeight = 'space32',
      showSeparator = false,
      groupHeaders,
      groupedLists,
      ...restProps
    },
    forwardedRef
  ) => {
    const listRef = useRef<HTMLUListElement>(null);
    const { colors } = useTheme();
    const separatorStyle = `1px solid ${colors.gray250}`;

    const localState = useListState({
      defaultSelectedKeys,
      disabledKeys,
      disallowEmptySelection,
      items,
      onSelectionChange,
      selectedKeys,
      selectionBehavior,
      selectionMode,
      children,
    });

    const state = stateProp ?? localState;

    const { listBoxProps } = useListBox(
      {
        'aria-describedby': ariaDescribedBy,
        'aria-details': ariaDetails,
        'aria-label': ariaLabel || ariaLabelledBy ? ariaLabel : 'grouped list',
        'aria-labelledby': ariaLabelledBy,
        autoFocus: stateProp?.focusStrategy ?? autoFocus,
        defaultSelectedKeys,
        disabledKeys,
        disallowEmptySelection: true,
        id,
        isVirtualized,
        items,
        keyboardDelegate,
        label,
        onAction,
        onBlur,
        onFocus,
        onFocusChange,
        onSelectionChange,
        selectedKeys,
        selectionBehavior,
        selectionMode,
        shouldFocusOnHover,
        shouldFocusWrap,
        shouldSelectOnPressUp,
        shouldUseVirtualFocus,
      },
      state,
      listRef
    );

    const options = Array.from(state.collection);

    const itemsPixelHeight =
      parseInt(itemHeight.toString().replace('space', '')) * options.length;

    const heightPixelHeight = parseInt(height);

    const popoverHeight =
      itemsPixelHeight < heightPixelHeight || heightPixelHeight === 0
        ? `calc((${itemHeight} * ${options.length}) + (${LIST_PADDING} * 2))`
        : `${heightPixelHeight}px`;

    // Note: The `groupedLists` array defines the predefined grouping of items and indicates which option belongs to which group.
    // However, it does not directly interact with the state managed by `useListState`, which only accepts a flat list (`items`).
    // Therefore, we need to create an array of grouped items with their corresponding options from the `options` array.
    // This ensures that each group in `groupedLists` aligns with the relevant options from the `options` array.
    const groupedData = map(groupedLists ?? [], group => {
      const groupKeys = map(group, 'key');

      return filter(options, option => groupKeys.includes(option.key));
    });

    const groupCounts = map(groupedData, 'length');

    if (isVirtualized) {
      // Note: We do not use the `data` prop from GroupedVirtuoso because the outermost indexes are always hidden, which breaks the grouping.
      // Known issues: https://github.com/toddburnside/scalajs-react-virtuoso/pull/62 & https://github.com/petyosi/react-virtuoso/issues/608
      // Instead, we use the `itemContent` prop. It retrieves data from the `options` array using the index.
      // This ensures that each item is rendered correctly, maintaining the intended grouping and display of items.

      return (
        <GroupedVirtuoso
          groupCounts={groupCounts}
          components={{
            Scroller: VirtuosoScroller as Components['Scroller'],
            List: VirtuosoGroupedList as Components['List'],
          }}
          context={{
            listProps: {
              ...listBoxProps,
              ref: listRef,
            },
            scrollerProps: {
              height:
                popoverHeight ??
                `calc((${itemHeight} * ${options.length}) + (${LIST_PADDING} * 2))`,
              maxHeight: maxHeight,
              ...restProps,
              ref: forwardedRef,
            },
            state,
          }}
          groupContent={index => {
            const groupCount = groupCounts[index];
            if (groupCount === 0) return null;

            return <GroupHeader header={groupHeaders?.[index]} />;
          }}
          itemContent={(index, groupIdx) => {
            return (
              <GroupedItemContent
                index={index}
                groupIdx={groupIdx}
                state={state}
                groupCounts={groupCounts}
                options={options}
                itemHeight={itemHeight}
                showSeparator={showSeparator}
                separatorStyle={separatorStyle}
              />
            );
          }}
          endReached={onEndReached}
          initialTopMostItemIndex={initialTopMostItemIndex ?? 0}
        />
      );
    }

    return (
      <ScrollBox maxHeight={maxHeight} {...restProps} ref={forwardedRef}>
        <List
          {...listBoxProps}
          ref={listRef}
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          {groupedData.map((group, groupIdx) => {
            const hasGroup = group.length > 0;
            const isLastGroup = groupIdx === groupCounts.length - 1;
            const shouldAddGap = group.length > 0 && !isLastGroup;

            return (
              <Fragment key={groupIdx}>
                {hasGroup && <GroupHeader header={groupHeaders?.[groupIdx]} />}
                {group.map(option => (
                  <Option
                    key={option.key}
                    item={option}
                    state={state}
                    height={itemHeight}
                    showSeparator={showSeparator}
                  />
                ))}
                {shouldAddGap && <Box height="space16" />}
              </Fragment>
            );
          })}
        </List>
      </ScrollBox>
    );
  }
);
