import {
  Button,
  Grid,
  Popover,
  Text,
  usePopover,
} from '@candisio/design-system';
import { ComponentProps, ElementRef, ReactNode, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

type ActionConfirmProps = {
  informationText: ReactNode;
  popoverProps: ReturnType<typeof usePopover>['popoverProps'];
  closePopover: () => void;
  handleConfirm: (() => Promise<void>) | (() => void);
  confirmLabel?: ReactNode;
  cancelLabel?: ReactNode;
  isActionPending?: boolean;
} & ComponentProps<typeof Popover>;

export const ActionConfirm = forwardRef<
  ElementRef<typeof Popover>,
  ActionConfirmProps
>(
  (
    {
      informationText,
      popoverProps,
      isActionPending = false,
      closePopover,
      handleConfirm,
      confirmLabel,
      cancelLabel,
      ...rest
    },
    ref
  ) => {
    const [t] = useTranslation();

    if (!popoverProps.isOpen) return null;

    return (
      <Popover {...popoverProps} ref={ref} maxWidth="space256" {...rest}>
        <Grid gap="space16" padding="space8">
          <Text whiteSpace="pre-wrap">{informationText}</Text>
          <Grid columns={2} gap="space8">
            <Button
              disabled={isActionPending}
              onClick={closePopover}
              size="small"
              variant="secondary"
            >
              {cancelLabel ?? t('no')}
            </Button>
            <Button
              color="red"
              loading={isActionPending}
              onClick={handleConfirm}
              size="small"
            >
              {confirmLabel ?? t('yes')}
            </Button>
          </Grid>
        </Grid>
      </Popover>
    );
  }
);
