import {
  Button,
  Card,
  Grid,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { Locale } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { ErrorStateFooter } from 'views/Reimbursement/toolkit/types';
import { ApproveNowAction } from './ApproveNowAction';
import { ErrorSection } from './ErrorSection';
import { FastApproveReturn } from './FooterActionBox';
import { RequestApprovalAction } from './RequestApprovalAction';
import { SendBackToEmployeeForm } from '../SendBackToEmploy/SendBackToEmployeeForm';
import { CommentFormOutput } from '../SendBackToEmploy/utils';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';

interface ReviewStatusFooterActionBoxProps {
  createdById?: string;
  isFastApprovePending: boolean;
  isRequestAmendmentPending: boolean;
  onFastApprove: () => Promise<FastApproveReturn | undefined>;
  getExpenses: UseFormGetValues<ExpensesFormOutput>;
  onRequestAmendment: ({ comment }: CommentFormOutput) => Promise<void>;
}

export const MIN_COLUMN_WIDTH = 151;
export const MIN_COLUMN_WIDTH_OTHER = 191;
const mainKey = 'reimbursementView.rightSection.reviewfooterAction';

export const ReviewStatusFooterActionBox = ({
  createdById,
  isFastApprovePending,
  isRequestAmendmentPending,
  onRequestAmendment,
  onFastApprove,
  getExpenses,
}: ReviewStatusFooterActionBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const [errors, setErrors] = useState<ErrorStateFooter>({
    itemErrors: [],
    showHasNoExpenseError: false,
    hasReimbursementCaseErrors: false,
    showHasNoIncludedExpensesError: false,
  });
  const [isCommentFormVisible, setIsCommentFormVisible] = useState(false);
  const { hasBookingsErrors } = useReimbursementSplitBookingHelpers();

  const { id: currentUserId, locale } = useCurrentUser() ?? {};
  const { isAccountant, isAdmin, isOnlyRequester, isOnlyApprover } =
    useUserRoles();

  const isReimbursementOwner = createdById === (currentUserId ?? '');
  const canFastApprove = isAdmin || isAccountant;
  const canRequestApproval = canFastApprove || isOnlyRequester;

  if (isOnlyApprover) return null;

  const backTextKey = isReimbursementOwner
    ? `${mainKey}.backMain`
    : `${mainKey}.back`;

  const minColumnWidth =
    isReimbursementOwner || locale === Locale.En
      ? MIN_COLUMN_WIDTH
      : MIN_COLUMN_WIDTH_OTHER;

  const isErrorSectionVisible =
    errors.itemErrors.length > 0 ||
    errors.hasReimbursementCaseErrors ||
    errors.showHasNoIncludedExpensesError ||
    hasBookingsErrors;

  if (isCommentFormVisible) {
    return (
      <SendBackToEmployeeForm
        onSetIsFormVisible={setIsCommentFormVisible}
        onRequestAmendment={onRequestAmendment}
        isRequestAmendmentPending={isRequestAmendmentPending}
      />
    );
  }

  return (
    <Card corners="top" boxShadow="elevatedShadow3" padding="space20">
      <Grid gap="space8">
        <Text color="gray500" fontWeight="semibold" textTransform="uppercase">
          {t('reimbursementView.rightSection.footerActions.title')}
        </Text>
        {isErrorSectionVisible && (
          <ErrorSection
            errors={errors.itemErrors}
            hasReimbursementCaseErrors={errors.hasReimbursementCaseErrors}
            hasNoIncludedExpensesError={errors.showHasNoIncludedExpensesError}
          />
        )}
        <Grid gap="space8">
          {/* {canRequestApproval && <RequestApprovalAction />} */}
          {canFastApprove && (
            <ApproveNowAction
              onFastApprove={onFastApprove}
              getExpenses={getExpenses}
              onSetErrors={setErrors}
              isFastApprovePending={isFastApprovePending}
            />
          )}
          <Grid
            gap="space8"
            templateColumns={`repeat(auto-fit, minmax(${minColumnWidth}px, 1fr))`}
          >
            {/* {canFastApprove && (
              <ApproveNowAction
                onFastApprove={onFastApprove}
                getExpenses={getExpenses}
                onSetErrors={setErrors}
                isFastApprovePending={isFastApprovePending}
              />
            )} */}
            <Button
              color="blue"
              variant="secondary"
              onClick={() => setIsCommentFormVisible(true)}
            >
              <TruncatedText>{t(backTextKey)}</TruncatedText>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
