import { Box, ButtonProps, Grid, Separator } from '@candisio/design-system';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { DOMAttributes, ReactNode } from 'react';

export const commonSapBtnStyles: ButtonProps = {
  width: '100%',
  variant: 'secondary',
  justifySelf: 'center',
};

interface PurchaseOrderDropdownMenuProps {
  listbox: ReactNode;
  hoverProps: DOMAttributes<HTMLDivElement>;
  accountsPayableNumber?: string;
}

export const PurchaseOrderDropdownMenu = ({
  listbox,
  hoverProps,
  accountsPayableNumber,
}: PurchaseOrderDropdownMenuProps) => {
  return (
    <Grid
      aria-label="purchase-order-number-dropdown"
      templateRows="1fr auto"
      maxHeight="inherit"
      overflow="hidden"
    >
      <Box overflow="hidden">{listbox}</Box>
      <Grid {...hoverProps}>
        <Separator />
        <Grid padding="space16">
          <SyncFromSap
            type={SAP_SYNC.PurchaseOrdersWithFilters}
            btnProps={commonSapBtnStyles}
            accountsPayableNumber={accountsPayableNumber}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
