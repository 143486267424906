import { MenuButton, Text, useTheme } from '@candisio/design-system';
import { backgroundColor } from 'containers/credit-cards/components/CreditCardsSection/consts';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface AddReimbursementItemActionMenuButtonProps {
  onHospitalityExpenseClick: () => void;
  onGeneralExpenseClick: () => void;
  creatingReimbursementItem: boolean;
}

export const AddReimbursementItemActionMenuButton = ({
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
  creatingReimbursementItem,
}: AddReimbursementItemActionMenuButtonProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { colors, fontWeights } = useTheme();
  return (
    <MenuButton
      icon="plus"
      iconPosition="left"
      selectionMode="single"
      size="medium"
      loading={creatingReimbursementItem}
      style={{
        padding: '.7rem 1rem',
        borderRadius: '8px',
        backgroundColor: 'white',
        color: colors.gray800,
        justifyContent: 'start',
        width: '100%',
      }}
      items={[
        {
          id: 'hospitality-expense',
          label: t('reimbursementView.middleSection.form.hospitality.addCta'),
          onAction: onHospitalityExpenseClick,
        },
        {
          id: 'general-expense',
          label: t('reimbursementView.middleSection.form.general.addCta'),
          onAction: onGeneralExpenseClick,
        },
      ]}
    >
      <Text fontWeight="regular">
        {t('reimbursementView.leftSection.reimbursementItems.addExpenseCta')}
      </Text>
    </MenuButton>
  );
};
