import { CSSObject } from '@emotion/react';
import { ClassNames, ClassNamesContent } from '@emotion/react';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  CSSTransitionClassNames,
  CSSTransitionProps,
} from 'react-transition-group/CSSTransition';

export type AnimationCSSObject = {
  [key in keyof CSSTransitionClassNames]: CSSObject;
};

type CastCSSObject = (cssObject: ClassNamesContent) => string;

type AnimationBoxProps = {
  animation: AnimationCSSObject;
} & CSSTransitionProps;

/**
 * AnimationsBox wraps the CSSTransition component from [React Transition Group](https://reactcommunity.org/) and makes it usable with [Emotion](https://emotion.sh/).
 *
 * @param {AnimationCSSObject} animation CSS transition styling as object
 */
export const AnimationBox = ({
  animation,
  children,
  ...props
}: AnimationBoxProps) => {
  // Map the classnames created by Emotion to the classnames used by CSSTransition
  const createClassNames = (
    css: CastCSSObject,
    animation: AnimationCSSObject
  ) => {
    return Object.assign(
      {},
      ...Object.entries(animation).map(([animationName, cssObject]) => ({
        [animationName]: css(cssObject as unknown as ClassNamesContent),
      }))
    ) as CSSTransitionClassNames;
  };

  return (
    <ClassNames>
      {({ css }) => (
        <CSSTransition
          classNames={createClassNames(
            css as unknown as CastCSSObject,
            animation
          )}
          {...props}
        >
          {children}
        </CSSTransition>
      )}
    </ClassNames>
  );
};
