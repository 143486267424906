import { Grid } from '@candisio/design-system';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContractSubCategoryDetailsFormOutput } from './contractSubCategoryDetailsFormSchema';

export interface ContractSubCategoryDetailsFormProps {
  formId: string;
  onSubmit: (values: ContractSubCategoryDetailsFormOutput) => Promise<void>;
  form: ReturnType<typeof useForm<ContractSubCategoryDetailsFormOutput>>;
  readOnly?: boolean;
}

export const ContractSubtypeDetailsForm = ({
  formId,
  onSubmit,
  form,
  readOnly,
}: ContractSubCategoryDetailsFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        gap="space24"
        id={formId}
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.stopPropagation();
          void form.handleSubmit(values => {
            return onSubmit(values);
          })(e);
        }}
        paddingTop="space36"
      >
        <HookFormTextField
          autoFocus
          label={t('contractCustomSubCategories.drawer.fields.name.label')}
          name="name"
          placeholder={t(
            'contractCustomSubCategories.drawer.fields.name.placeholder'
          )}
          readOnly={readOnly}
        />
        <HookFormTextareaField
          label={t(
            'contractCustomSubCategories.drawer.fields.description.label'
          )}
          name="description"
          placeholder={t(
            'contractCustomSubCategories.drawer.fields.description.placeholder'
          )}
          minRows={4}
          readOnly={readOnly}
        />
      </Grid>
    </FormProvider>
  );
};
