import { Card, Grid } from '@candisio/design-system';
import { ReactNode } from 'react';

interface ActionBarProps {
  children: ReactNode;
}

export const ActionBar = ({ children, ...props }: ActionBarProps) => (
  <Card
    position="sticky"
    bottom={0}
    height="max-content"
    width="100%"
    alignSelf="end"
    boxShadow="elevatedShadow4"
    corners="top"
    {...props}
  >
    <Grid gap="space8">{children}</Grid>
  </Card>
);
