import { Button, Card, CardProps, Grid, Text } from '@candisio/design-system';
import { motion } from 'framer-motion';
import { forwardRef } from 'react';

export interface AssociateCardProps extends CardProps {
  footerText: string;
  ctaText: string;
  onAssociate?: () => void;
  loading?: boolean;
}

export const AssociateCard = motion(
  forwardRef<HTMLDivElement, AssociateCardProps>(
    ({ onAssociate, footerText, ctaText, loading, ...restProps }, ref) => {
      return (
        <Card
          background="blue100"
          corners="all"
          boxShadow="elevatedShadow5"
          paddingX="space36"
          maxHeight="space80"
          ref={ref}
          {...restProps}
        >
          <Grid
            alignItems="center"
            autoFlow="column"
            justifyContent="space-between"
          >
            <Text>{footerText}</Text>
            <Button onClick={onAssociate} loading={loading}>
              {ctaText}
            </Button>
          </Grid>
        </Card>
      );
    }
  )
);
