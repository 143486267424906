import { Skeleton, Tooltip, useId, useTooltip } from '@candisio/design-system';
import { FieldCorner } from 'components/FieldCorner/FieldCorner';
import { confidenceToColor } from 'components/utils/confidence-to-colors';
import i18next from 'i18next';
import {
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
  ReactText,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Cross } from '../Icons/Cross';
import { CharacterCounter } from './CharacterCounter';
import {
  ClearInput,
  InputInfo,
  StyledIcon,
  StyledInput,
  StyledLabel,
  StyledTextArea,
  TextInputContainer,
} from './style';

type Props = Partial<InputHTMLAttributes<HTMLInputElement>> & {
  controlled?: string;
  label?: string | ReactNode;
  optional?: boolean;
  alert?: string | boolean;
  readonly?: boolean;
  primary?: 'true' | undefined;
  icon?: string;
  info?:
    | ReactText
    | ReactElement
    | JSX.Element
    | ReturnType<i18next.TFunction>
    | null;
  initialValue?: string | number | null;
  confidence?: number | null;
  inputStyle?: object;
  loading?: boolean;
  clearable?: boolean;
  onClear?: () => void;
  fieldSource?: string | null;
  maxLengthWarning?: string;
};

const TextArea = ({
  alert,
  clearable,
  disabled,
  icon,
  id,
  info,
  initialValue,
  label,
  loading,
  maxLength,
  maxLengthWarning,
  onClear,
  optional,
  placeholder,
  readonly,
  required,
  value,
  ...props
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const clearInput = useCallback(() => {
    onClear?.();
    ref?.current?.focus();
  }, [onClear, ref]);

  const inputId = useId(id);

  return (
    <TextInputContainer disabled={disabled} readonly={readonly} alert={!!alert}>
      <StyledTextArea
        id={inputId}
        value={value}
        inputRef={ref}
        readOnly={readonly}
        alert={alert}
        /** Add empty placeholder to implement placeholder-shown css state */
        placeholder={placeholder || ' '}
        required={required}
        disabled={readonly || disabled}
        icon={icon}
        {...(props as any)}
      />
      <StyledLabel
        htmlFor={inputId}
        alert={!!alert}
        readonly={readonly}
        required={required}
      >
        {label}
        {/* Currently just comment, once we finilize that this is
          not required at all just remove all related params
         */}
        {/* {optional && !readonly && !required && (
          <StyledInputOptional>({t`inputs.optional`})</StyledInputOptional>
        )} */}
      </StyledLabel>
      {clearable && onClear ? (
        <ClearInput onClick={clearInput}>
          <Cross width="10" height="10" viewBox="0 0 10 10" />
        </ClearInput>
      ) : null}
      {icon && !readonly && <StyledIcon src={icon} />}
      <FieldCorner alert={alert} />
      <CharacterCounter
        characterLength={value?.toString().length || 0}
        maxCharacters={maxLength}
        maxCharactersWarning={maxLengthWarning}
      />
    </TextInputContainer>
  );
};

type Inputs = {
  TextArea: typeof TextArea;
};

const RefTextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      alert,
      autoFocus,
      className,
      clearable,
      confidence,
      controlled,
      disabled,
      fieldSource,
      icon,
      id,
      info,
      initialValue,
      inputStyle,
      label,
      loading,
      onChange,
      onClear,
      optional,
      placeholder,
      readonly,
      required,
      style,
      value,
      ...props
    },
    outerRef
  ) => {
    // in most cases native autoFocus will not work cause more then one rerenders
    // so we do autoFocus manually
    const ref = useRef<HTMLInputElement>(null);
    const currentRef = ((outerRef || ref) as RefObject<HTMLInputElement>)
      ?.current;

    useEffect(() => {
      if (autoFocus && currentRef) {
        setTimeout(() => currentRef.focus(), 200);
      }
    }, [currentRef, autoFocus]);
    const [isModifiedByUser, setIsModifiedByUser] = useState(false);
    const clearInput = useCallback(() => {
      onClear?.();
      setIsModifiedByUser(true);
      currentRef?.focus();
    }, [onClear, currentRef]);

    const onChangeSetIsModifiedByUser = (event: any) => {
      const initialValueAsString =
        initialValue != null ? initialValue.toString() : null;

      if (event.target.value !== initialValueAsString) {
        setIsModifiedByUser(true);
      } else {
        setIsModifiedByUser(false);
      }

      if (onChange) {
        onChange(event);
      }
    };

    const colors =
      confidence != null
        ? confidenceToColor(confidence, isModifiedByUser, readonly)
        : null;

    const newStyles = { ...style, backgroundColor: colors?.background };

    const inputId = useId(id);
    const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
      useTooltip();

    return (
      <TextInputContainer
        className={className}
        disabled={disabled}
        style={newStyles}
        readonly={readonly}
        alert={!!alert}
      >
        {loading ? (
          <Skeleton
            width={info ? 'calc(100% - 25px)' : '100%'}
            height="1rem"
            style={{ marginBottom: '0.25rem' }}
          />
        ) : (
          <StyledInput
            id={inputId}
            ref={outerRef || ref}
            value={controlled != null ? controlled : value}
            /** Add empty placeholder to implement placeholder-shown css state */
            placeholder={placeholder || ' '}
            readOnly={readonly}
            alert={alert}
            required={required}
            disabled={readonly || disabled}
            icon={icon}
            onChange={onChangeSetIsModifiedByUser}
            style={inputStyle}
            aria-invalid={alert !== undefined}
            {...(props as any)}
          />
        )}
        <StyledLabel
          htmlFor={inputId}
          alert={!!alert}
          value={value}
          readonly={readonly}
          required={required}
        >
          {label}
          {/* Currently just comment, once we finalize that this is
            not required at all just remove all related params
          */}
          {/* {optional && !readonly && !required && (
            <StyledInputOptional>({t`inputs.optional`})</StyledInputOptional>
          )} */}
        </StyledLabel>
        {clearable && onClear ? (
          <ClearInput value={value} onClick={clearInput}>
            <Cross width="10" height="10" viewBox="0 0 10 10" />
          </ClearInput>
        ) : null}
        {icon && !readonly && <StyledIcon src={icon} />}
        <FieldCorner
          alert={alert}
          color={colors?.corner}
          confidence={confidence}
          fieldSource={fieldSource}
          isModifiedByUser={isModifiedByUser}
          initialValue={initialValue}
        />
        {info && (
          <>
            <InputInfo {...triggerProps} ref={triggerRef} />
            {isOpen && (
              <Tooltip {...tooltipProps} ref={tooltipRef}>
                {/** @ts-expect-error TODO: React upgrade props types mismatch */}
                {info}
              </Tooltip>
            )}
          </>
        )}
      </TextInputContainer>
    );
  }
);

/**
 * @deprecated please use TextField from design system instead.
 * @see https://candisio.github.io/design-system/
 */
export const TextInput: typeof RefTextInput & Inputs = RefTextInput as any;

TextInput.TextArea = TextArea;
export { CharacterCounter };
