import { Button, usePopover } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Filters } from 'react-table';
import { Filter } from './Filter/Filter';
import { PopoverFilters, WidgetFilter } from './utils';

interface MerchantNameFilterProps {
  onFilter: (filters: Filters<PopoverFilters>) => void;
  appliedFilters: string[];
  allFilters: Filters<PopoverFilters>;
}

export const MerchantNameFilter = ({
  allFilters,
  appliedFilters,
  onFilter,
}: MerchantNameFilterProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { isOpen, popoverProps, close, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'bottom' });

  const filtersWithoutMerchantName = allFilters.filter(
    filter => filter.id !== WidgetFilter.merchantName
  );

  const applyFilters = (filters: undefined | string[]) => {
    if (!filters) {
      return [];
    }

    // It maps the filters from the checkboxes into an object where the id identifies the general filter we are using
    // onFilter expects the filters to be in this exact shape

    const filtersForUrl = [
      ...filtersWithoutMerchantName,
      { id: WidgetFilter.merchantName, value: filters },
    ];

    onFilter(filtersForUrl);
    close();
  };

  const resetFilters = () => {
    // removes the filters related to merchantName and sends them to the URL

    onFilter(filtersWithoutMerchantName);
    close();
  };

  const buttonText = appliedFilters?.length
    ? t('credit-cards:insights.filters.merchantName.withNumber', {
        filters: appliedFilters?.length,
      })
    : t('credit-cards:insights.filters.merchantName.noNumber');

  return (
    <>
      <Button
        {...triggerProps}
        ref={triggerRef}
        justifySelf="start"
        iconPosition="right"
        icon={isOpen ? 'caretUp' : 'caretDown'}
        variant="secondary"
      >
        {buttonText}
      </Button>
      {isOpen && (
        <Filter
          {...popoverProps}
          filterValue={appliedFilters}
          filterName={WidgetFilter.merchantName}
          ref={popoverRef}
          onApply={applyFilters}
          onReset={resetFilters}
        />
      )}
    </>
  );
};
