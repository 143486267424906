import {
  Button,
  Card,
  Grid,
  Heading,
  usePopover,
} from '@candisio/design-system';
import { AcknowledgePopover } from 'components/AcknowledgePopover/AcknowledgePopover';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { HookFormIbanField } from 'components/HookFormFields/HookFormIbanField';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { Locale } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useTeams } from 'orgConfig/teams/useTeams';
import { useCurrentUser } from 'providers/CurrentUserProvider/useCurrentUser';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useState } from 'react';
import { UseFormReturn, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isOwner } from 'utils/authorization';
import { AbsenceFormWrapper } from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/AbsenceFormWrapper';
import { AbsenceFormOutput } from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/absenceFormSchema';
import { UserTeamsWithLinks } from 'views/AppContainer/components/Header/components/UserProfile/UserTeams/UserTeamsWithLinks';
import { RolesSection } from 'views/Settings/TeamMembers/TeamMemberDetails/containers/invite/RolesSection/RolesSection';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { usePermissionsForTeamMembers } from 'views/Settings/TeamMembers/hooks/usePermissionsForTeamMembers';
import { Team } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';
import { MembershipInvolvementContainer } from '../components/MembershipInvolvement/MembershipInvolvementContainer';
import { ReadonlyEmailField } from '../components/ReadonlyEmailField/ReadonlyEmailField';
import {
  TeamMemberFormOutput,
  TeamMemberFormSchemaOptions,
} from '../teamMemberFormSchema';
import { AccountsPayableNumberField } from './AccountsPayableNumberField';
import { DeactivateMembershipButton } from './DeactivateMembershipButton';
import { UserAvatar } from './UserAvatar';
import { ShowPendingApprovalsAckMsg } from './hooks/useShowAcknowledgeMessage';

export type TeamMemberWithAbsenceFormOutput = TeamMemberFormOutput &
  AbsenceFormOutput;

export type TeamMemberFormDirtyFields = UseFormReturn<
  TeamMemberWithAbsenceFormOutput,
  TeamMemberFormSchemaOptions
>['formState']['dirtyFields'];

export interface ActiveTeamMemberFormProps {
  absenceValuesLoading?: boolean;
  closeDrawer: () => void;
  formId: string;
  involvementErrorMessage?: string;
  isSubmittingDeactivateMembership: boolean;
  isSubmittingUpdateUser: boolean;
  loading: boolean;
  onDeactivateMembership: () => void;
  onForceDeactivateMembership: (membershipId: string) => void;
  onForceUpdate: (data: TeamMemberFormOutput) => void;
  onSubmit: (
    formOutput: TeamMemberWithAbsenceFormOutput,
    modifiedFields?: TeamMemberFormDirtyFields
  ) => void;
  organizationSlug: string;
  shouldUseCoreDataApi: boolean;
  shouldUseSapContacts: boolean;
  showPendingApprovalsAckMsg?: ShowPendingApprovalsAckMsg;
  resetShowPendingApprovalsAckMsg: () => void;
  readOnly?: {
    firstName?: boolean;
    lastName?: boolean;
    roles?: boolean;
    locale?: boolean;
  };

  selectedUser: User;
  teams: Team[];
}

export const ActiveTeamMemberForm = ({
  absenceValuesLoading,
  closeDrawer,
  formId,
  shouldUseCoreDataApi,
  shouldUseSapContacts,
  involvementErrorMessage,
  isSubmittingDeactivateMembership,
  isSubmittingUpdateUser,
  loading,
  onDeactivateMembership,
  onForceDeactivateMembership,
  onForceUpdate,
  onSubmit,
  organizationSlug,
  showPendingApprovalsAckMsg,
  resetShowPendingApprovalsAckMsg,
  readOnly,
  selectedUser,
  teams,
}: ActiveTeamMemberFormProps) => {
  const { isTeamsFeatureEnabled } = useTeams();
  const [employeeContactTypeFF, implementOwnerRoleFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.employeeContactType,
    FEATURE_FLAGS.implementOwnerRole,
  ]);

  const isOrganizationOwner = isOwner(selectedUser) && implementOwnerRoleFF;

  const form = useFormContext<TeamMemberFormOutput>();
  const { canUseReimbursement } = useReimbursement();
  const [t] = useTranslation();
  const { canDeactivateMember } = usePermissionsForTeamMembers();

  const { close, isOpen, triggerProps, triggerRef, popoverProps, popoverRef } =
    usePopover({
      placement: 'top',
      isOpen:
        showPendingApprovalsAckMsg?.show &&
        showPendingApprovalsAckMsg.context === 'update',
      onOpenChange(isOpen) {
        if (!isOpen) {
          // Need to reset this since the popover open state is controlled by it.
          // If the user tries again to re-submit the form, the popover will show up again
          resetShowPendingApprovalsAckMsg();
        }
      },
    });

  const isSubmitting =
    isSubmittingDeactivateMembership || isSubmittingUpdateUser;

  const currentUser = useCurrentUser();

  const disabled = loading || isSubmitting;

  const [involvedCount, setInvolvedCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const locales = Object.values(Locale).map(value => ({
    children: t(`locales.${value}`),
    key: value,
  }));

  const shouldShowDeactivateActionLink =
    !loading && currentUser?.id !== selectedUser.id && canDeactivateMember;

  const dirtyFields = form.formState.dirtyFields;

  const isAccountsPayableNumberVisible =
    canUseReimbursement && employeeContactTypeFF;

  const isAccountsPayableNumberReadOnly =
    shouldUseCoreDataApi || shouldUseSapContacts;

  return (
    <DrawerLayout
      onClose={closeDrawer}
      header={
        <Heading as="h3">
          {isTeamsFeatureEnabled
            ? t('settings.users.details.tabs.edit')
            : t('settings.teamMembers.details.tabs.edit')}
        </Heading>
      }
      footer={
        <Grid
          autoFlow="column"
          justifyContent="space-between"
          data-cy="team-member-invite-action"
        >
          <Button
            {...triggerProps}
            ref={triggerRef}
            loading={isSubmittingUpdateUser}
            disabled={disabled}
            type="submit"
            form={formId}
          >
            {t('settings.teamMembers.form.actions.update')}
          </Button>

          {isOpen && (
            <AcknowledgePopover
              {...popoverProps}
              ref={popoverRef}
              onClose={close}
              acknowledgeText={t(
                'settings.teamMembers.form.involvement.hasPendingApprovalsMessage',
                { numberOfPendingApprovals: pendingCount }
              )}
              confirmButtonProps={{ loading: isSubmittingUpdateUser }}
              onConfirm={() => {
                onForceUpdate(form.getValues());
              }}
            />
          )}
          {shouldShowDeactivateActionLink && (
            <DeactivateMembershipButton
              isOrganizationOwner={isOrganizationOwner}
              involvedCount={involvedCount}
              onDeactivateMembership={onDeactivateMembership}
              disabled={disabled}
              loading={isSubmittingDeactivateMembership}
              pendingCount={pendingCount}
              onForceDeactivateMembership={onForceDeactivateMembership}
              selectedUser={selectedUser}
            />
          )}
        </Grid>
      }
    >
      <Grid
        as="form"
        gap="space24"
        templateRows="1fr auto"
        id={formId}
        onSubmit={form.handleSubmit(values => {
          return onSubmit(values, dirtyFields);
        })}
      >
        <Grid gap="space24">
          <UserAvatar selectedUser={selectedUser} />
          <Grid
            background="gray100"
            borderRadius="8px"
            padding="space16"
            rowGap="space12"
            columnGap={canUseReimbursement ? 'space16' : 'space32'}
            templateColumns="1fr 1fr 1fr 1fr"
            templateRows="auto auto auto"
          >
            <Grid gridRow="1" gridColumn="1/3">
              <HookFormTextField
                readOnly={readOnly?.firstName}
                disabled={readOnly?.firstName}
                label={t('settings.teamMembers.form.firstName.label')}
                name="firstName"
                autoFocus
              />
            </Grid>
            <Grid gridRow="1" gridColumn="3/5">
              <HookFormTextField
                readOnly={readOnly?.lastName}
                disabled={readOnly?.lastName}
                name="lastName"
                label={t('settings.teamMembers.form.lastName.label')}
              />
            </Grid>
            <Grid
              gridRow="2"
              gridColumn="1/5"
              templateColumns="1fr auto"
              autoFlow="column"
              gap="space20"
              alignItems="center"
            >
              <ReadonlyEmailField fieldName="email" />
            </Grid>
            <Grid gridRow="3" gridColumn="1/3">
              <HookFormSelectField
                name="locale"
                readOnly={readOnly?.locale}
                disabled={readOnly?.locale}
                label={t('settings.teamMembers.form.locale')}
                items={locales}
              />
            </Grid>
          </Grid>

          {canUseReimbursement && (
            <Grid gap="space8">
              <Heading as="h3">
                {t('reimbursement:userProfile.bankDetails.header')}
              </Heading>
              <Card background="gray100">
                <Grid gap="space12">
                  <Grid templateColumns="2fr 1fr" gap="space16">
                    <HookFormIbanField
                      name="iban"
                      label={t(
                        'common:header.profile.tabs.update.form.iban.label'
                      )}
                      readOnly
                    />
                    <HookFormTextField
                      name="swiftCode"
                      label={t(
                        'common:header.profile.tabs.update.form.bic.label'
                      )}
                      readOnly
                    />
                  </Grid>
                  {isAccountsPayableNumberVisible && (
                    <AccountsPayableNumberField
                      readOnly={isAccountsPayableNumberReadOnly}
                      name="accountsPayableNumber"
                      label={t(
                        'settings.contacts.details.edit.accountsPayableNumber.label'
                      )}
                      disabled={disabled}
                    />
                  )}
                </Grid>
              </Card>
            </Grid>
          )}
          <AbsenceFormWrapper
            defaultValuesLoading={absenceValuesLoading}
            userId={selectedUser?.id}
            userRoles={selectedUser?.roles}
          />

          <RolesSection readOnly={readOnly?.roles} />

          <UserTeamsWithLinks teams={teams} />

          <Grid gap="space8">
            <Heading as="h3">
              {t('settings.teamMembers.form.involvement.header')}
            </Heading>
            {selectedUser?.id ? (
              <MembershipInvolvementContainer
                selectedUserId={selectedUser.id}
                organizationSlug={organizationSlug}
                setInvolvedCount={setInvolvedCount}
                setPendingCount={setPendingCount}
                errorMessage={involvementErrorMessage}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </DrawerLayout>
  );
};
