import { Card, Grid, Heading, Text } from '@candisio/design-system';
import {
  FetchCardIssuerSettlementsQuery,
  PaymentFrequencyType,
  useFetchCardIssuerSettlementsQuery,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useLocation } from 'react-router-dom';
import { scrollToTarget, statementCardId } from 'views/CreditCards/utils/utils';
import { CreditCardSettlementsTable } from './CreditCardSettlementsTable';
import { paymentFrequencyTranslation, toTableData } from './utils';

export type CreditCardSettlementsComponentProps = {
  loading: boolean;
  settlements: FetchCardIssuerSettlementsQuery['fetchCardIssuerPaymentSettlements']['edges'];
  paymentFrequency?: PaymentFrequencyType | null;
};

export const CreditCardSettlements = () => {
  const settlementsQuery = useFetchCardIssuerSettlementsQuery();
  const organization = useFullOrganization();

  return (
    <CreditCardSettlementsComponent
      loading={settlementsQuery.loading}
      paymentFrequency={organization?.creditCardsDetails?.paymentFrequency}
      settlements={
        settlementsQuery.data?.fetchCardIssuerPaymentSettlements.edges || []
      }
    />
  );
};

export const CreditCardSettlementsComponent = ({
  loading,
  settlements,
  paymentFrequency,
}: CreditCardSettlementsComponentProps) => {
  const [tCreditCards] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { hash } = useLocation();

  const readablePaymentFrequency = tCreditCards(
    paymentFrequencyTranslation[paymentFrequency as PaymentFrequencyType] ??
      'creditCardPayments.paymentFrequency.unknown'
  );

  useEffect(() => {
    if (hash !== '') {
      scrollToTarget(hash);
    }
  }, [hash]);

  return (
    <Card
      id={statementCardId}
      padding="space16"
      borderRadius="medium"
      alignSelf="start"
    >
      <Grid gap="space16">
        <Heading as="h3">{tCreditCards('creditCardPayments.title')}</Heading>
        <Text>
          <Trans
            i18nKey="creditCardPayments.subtitle"
            t={tCreditCards}
            values={{ readablePaymentFrequency }}
          >
            Your settlement period is
            <Text as="b" fontWeight="semibold">
              {{ readablePaymentFrequency } as any}
            </Text>
          </Trans>
        </Text>
        <CreditCardSettlementsTable
          loading={loading}
          data={toTableData(settlements)}
        />
      </Grid>
    </Card>
  );
};
