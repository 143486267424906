import { InfoPanelPromo } from 'containers/Entitlements/components/Upsell/components/InfoPanelPromo';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@candisio/design-system';

export const Provisions = ({ onClick }: { onClick?: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);
  const linkText = t('promo.link');

  return (
    <InfoPanelPromo
      linkText={linkText}
      onClick={onClick}
      message={
        <Text>
          <Trans t={t} i18nKey="promo.provisions">
            To export provisions, an upgrade to the
            <Text fontWeight="semibold">Candis Plus</Text>
            package is required.
          </Trans>
        </Text>
      }
    />
  );
};
