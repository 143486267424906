import { useToastMessage } from 'components/Toast/useToastMessage';
import { useUpdateHospitalityExpenseItemMutation } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
  reimbursementItemsListQuery,
  reimbursementItemsSplitBookingsQuery,
} from '../toolkit/queries';
import { ReimbursementSplitBookingsFormValues } from '../toolkit/reimbursementSplitBookingsFormSchema';
import { sanitizeReimbursementBookingInput } from '../utils/sanitizeReimbursementBookingInput';

export const useUpdateHospitalityExpenseBookings = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { error } = useToastMessage();

  const [
    updateHospitalityExpenseBookings,
    { loading: isUpdatingHospitalityBookings },
  ] = useUpdateHospitalityExpenseItemMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: reimbursementCaseByIdQuery,
        variables: {
          id: reimbursementId,
        },
      },
      {
        query: reimbursementItemsListQuery,
        variables: { filters: { reimbursementCaseId: reimbursementId } },
      },
      {
        query: reimbursementItemsSplitBookingsQuery,
        variables: { filters: { reimbursementCaseId: reimbursementId } },
      },
      {
        query: reimbursementHistoryQuery,
        variables: { id: reimbursementId },
      },
    ],
  });

  const handleUpdateHospitalityExpenseBookings = useCallback(
    async (
      values: ReimbursementSplitBookingsFormValues['reimbursementItemBookings'][number]
    ) => {
      const reimbursementItemId = values?.reimbursementItemId;
      const bookings = values?.bookings;

      if (!reimbursementItemId) return;

      const { errors } = await updateHospitalityExpenseBookings({
        variables: {
          id: reimbursementItemId,
          input: {
            reimbursementCaseId: reimbursementId,
            bookings: bookings?.length
              ? bookings.map(sanitizeReimbursementBookingInput)
              : [],
          },
        },
      });

      if (errors?.length) {
        error(t('splitBookings.splitSaveError'));

        return;
      }

      return { status: 'success' };
    },
    [error, reimbursementId, t, updateHospitalityExpenseBookings]
  );

  return {
    isUpdatingHospitalityBookings,
    updateHospitalityExpenseBookings: handleUpdateHospitalityExpenseBookings,
  };
};
