import { Card, Link, Paragraph, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export type HelpCenterPanelProps = {
  onClickHelpCenter?: () => void;
  onClickIntercomChat?: () => void;
};

export const HelpCenterPanel = ({
  onClickHelpCenter,
  onClickIntercomChat,
}: HelpCenterPanelProps) => {
  const [tCreditCards] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Card
      background="bluebg"
      borderRadius="small"
      boxShadow="noShadow"
      fontSize="basic"
    >
      <Paragraph>
        <Trans t={tCreditCards} i18nKey="informationBox.description">
          <Text fontWeight="semibold">You have questions?</Text>
          You can find information in the{' '}
          <Link
            external
            onClick={onClickHelpCenter}
            href={tCreditCards('informationBox.helpCenter.url')}
          >
            {tCreditCards('informationBox.helpCenter.text')}
          </Link>{' '}
          Our customer service will also be happy to help you in the{' '}
          <Link
            href="containers/credit-cards/components/HelpCenterPanel"
            onClick={onClickIntercomChat}
            data-intercom="intercom-chat"
          >
            Chat
          </Link>
          .
        </Trans>
      </Paragraph>
    </Card>
  );
};
