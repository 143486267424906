import {
  Flex,
  Icon,
  Text,
  Button,
  useModal,
  useTheme,
} from '@candisio/design-system';
import { PurchaseOrderUnlinkContextMenu as UnlinkButton } from 'components/PurchaseOrders/PurchaseOrderDetailsCard/PurchaseOrderUnlinkContextMenu';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { getThreeWayMatchGeneralState } from 'utils/three-way-match';
import { ThreeWayMatchModal } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchModal';
import { useGetMatchStateStyles } from './hooks/useGetMatchStateStyles';
import { useThreeWayMatchContext } from './ThreeWayMatchContext';
import { matchState } from './types';

export function ThreeWayMatchIndicator() {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { open, ...modalProps } = useModal();

  const {
    newData: data,
    isLoading,
    isPurchaseOrderEditable,
  } = useThreeWayMatchContext();

  const variant = getThreeWayMatchGeneralState(data);
  const getMatchingStyles = useGetMatchStateStyles();
  const { iconProps } = getMatchingStyles(variant);
  const { colors } = useTheme();

  return (
    <>
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" gap="space4">
          <Text fontWeight="bold">{t('threeWayMatch.indicator.header')}</Text>
          {variant !== 'default' && <Icon size="space16" {...iconProps} />}
        </Flex>

        <Flex gap="space12" alignItems="center">
          <Button
            onClick={open}
            label={
              variant === matchState.default
                ? t('threeWayMatch.indicator.compareTooltipOnDefault')
                : undefined
            }
            variant="tertiary"
            size="xsmall"
            height="space24"
            disabled={variant === matchState.default || isLoading}
            style={{ backgroundColor: colors.gray250 }}
          >
            {t('threeWayMatch.indicator.compareCTA')}
          </Button>
          <UnlinkButton
            variant={variant}
            disabled={Boolean(
              !isPurchaseOrderEditable || !data.length || isLoading
            )}
          />
        </Flex>
      </Flex>
      <ThreeWayMatchModal {...modalProps} />
    </>
  );
}
