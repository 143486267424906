import { Link } from '@candisio/design-system';
import { DocumentImportedByMigrationEventDataFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export const DocumentImportedbyMigrationComponent = (
  documentImportedByMigrationEvent: DocumentImportedByMigrationEventDataFragment
) => {
  const [t] = useTranslation();
  const fileName = documentImportedByMigrationEvent.file?.name;
  const fileUrl = documentImportedByMigrationEvent.file?.url;
  const importedFromSystem =
    documentImportedByMigrationEvent.importedFromSystem;

  return (
    <>
      <Link
        external
        href={
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          fileUrl!
        }
        download={fileName}
      >
        {fileName}
      </Link>
      <br />
      {t('document.compact.history.migratedFrom')} {importedFromSystem}
    </>
  );
};
