import { Grid } from '@candisio/design-system';
import { MouseEvent, ReactNode } from 'react';

interface MetaDataWrapperProps {
  children: ReactNode;
}

export const MetaDataWrapper = ({ children }: MetaDataWrapperProps) => (
  <Grid
    gap="space8"
    paddingTop="space8"
    style={{ cursor: 'auto' }}
    onClick={
      // @TODO explicit MouseEvent<HTMLSpanElement> type should not be required.
      // See: https://github.com/microsoft/TypeScript/issues/44596
      (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  >
    {children}
  </Grid>
);
