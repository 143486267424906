import {
  useDocumentRelationshipsFilesQuery,
  useGetDocumentGlobalIdQuery,
} from 'generated-types/graphql.types';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { useMemo } from 'react';

export const useRelatedDocumentFiles = (documentId: string) => {
  const { data: documentData } = useGetDocumentGlobalIdQuery({
    variables: { id: documentId },
  });

  const documentGlobalId = documentData?.getDocument?.globalDocumentId ?? '';

  const { data: relationsData } = useDocumentRelationshipsFilesQuery({
    variables: { input: { globalDocumentId: documentGlobalId } },
    skip: !documentGlobalId,
  });

  const relationships = relationsData?.documentRelationships.relationships;

  const result = useMemo(() => {
    const files: PDFDetails[] = [];

    (relationships ?? []).forEach(({ linkedDocument: link }) => {
      if (!link || link.__typename !== 'RelatedDocument' || !link.document)
        return;

      if (
        link.document.__typename === 'AggregatedEcmDocument' &&
        link.document.fileSource
      ) {
        const { url, name, id } = link.document.fileSource;
        return files.push({ url, name, id: id ?? undefined });
      }

      if (
        link.document.__typename === 'Document' &&
        link.document.documentFile
      ) {
        const { url, name, id } = link.document.documentFile;
        files.push({ url, name, id });
      }

      if (
        link.document.__typename === 'Document' &&
        link.document.attachments
      ) {
        link.document.attachments.forEach(({ url, name, id }) =>
          files.push({ url, name, id })
        );
      }
    });

    const [documentFile, ...attachments] = files;
    return { documentFile, attachments };
  }, [relationships]);

  return result;
};
