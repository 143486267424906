import { HeaderProps, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { EcmDocumentsTableData } from './types';

export const Header = ({
  column,
  isFilteredOrSorted,
}: HeaderProps<EcmDocumentsTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <TruncatedText
      wordBreak="break-all"
      color={isFilteredOrSorted ? 'gray800' : 'gray500'}
    >
      {t(`tableColumns.${column.id}`)}
    </TruncatedText>
  );
};
