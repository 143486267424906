import { Card, Flex, Grid } from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useOtherIntegration } from 'orgConfig/other';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { ReactNode } from 'react';
import { useExportContext } from './Context';
import { ExportNavigation } from './elements/navigation/ExportNavigation';
import { ExportSidebar } from './elements/sidebar/ExportSidebar';
import { ExportTable } from './elements/table/ExportTable';
import { EmptyTableTabs } from './elements/tabs/EmptyTableTabs';
import { EntityTabs } from './elements/tabs/EntityTabs';
import { TableTabs } from './elements/tabs/TableTabs';
import { EmptyExportView } from './toolkit/components/EmptyState/ExportEmptyState';
import { IncludeAllCoreDataEntities } from './toolkit/components/IncludeAllCoreDataEntities';
import { IncludeEntitiesWithoutDocument } from './toolkit/components/IncludeEntitiesWithoutDocument';
import { useExportDataGigaFactory } from './toolkit/hooks/useExportDataGigaFactory';
import { useShowExportAllCoreData } from './toolkit/hooks/useShowExportAllCoreData';
import { Entity, View } from './types';

const ExportWrapper = ({ children }: { children?: ReactNode }) => (
  <Grid
    templateColumns="20rem minmax(20rem, 1fr) 21.875rem"
    paddingLeft="space32"
    gap="space24"
    height="100%"
    max-height="100%"
    width="100%"
    max-width="100%"
    overflow="hidden"
  >
    {children}
  </Grid>
);

export const ExportLayout = () => {
  const errorVisibilityImprovementFF = useCandisFeatureFlags(
    FEATURE_FLAGS.errorVisibilityImprovement
  );

  const showExportAllCoreData = useShowExportAllCoreData();
  const state = useExportContext();
  const { entity, exportId, view } = state;
  const {
    counts: {
      readyToExportSettlementsCount,
      readyToExportEntitiesCount,
      provisionsCount,
    },
  } = useExportDataGigaFactory(state);

  const showExportEmptyState =
    !exportId &&
    !provisionsCount &&
    !readyToExportEntitiesCount &&
    !readyToExportSettlementsCount &&
    view !== View.NOT_READY;

  const showIncludeEntitiesWithoutDocument = entity === Entity.TRANSACTION;

  const { shouldUseCandisApi } = useOtherIntegration();

  return (
    <ExportWrapper>
      <ExportNavigation />
      {showExportEmptyState && (
        <EmptyExportView displayExportOptionIcons={!shouldUseCandisApi} />
      )}
      {!showExportEmptyState && (
        <>
          <Flex
            direction="column"
            gap="space32"
            paddingTop="4.2rem"
            paddingBottom="1rem"
            overflow="hidden"
          >
            <EntityTabs />
            {errorVisibilityImprovementFF && (
              <Card height="100%" padding="0" overflow="auto">
                <Flex
                  height="inherit"
                  direction="column"
                  padding="space16"
                  rowGap="space16"
                >
                  {entity !== 'REIMBURSEMENT_ITEMS' ? (
                    <TableTabs />
                  ) : (
                    <EmptyTableTabs />
                  )}
                  <Flex
                    overflow="auto"
                    direction="column"
                    flexGrow="1"
                    height="inherit"
                    paddingRight="space4"
                  >
                    <ExportTable />
                  </Flex>
                  {showIncludeEntitiesWithoutDocument && (
                    <IncludeEntitiesWithoutDocument />
                  )}
                  {showExportAllCoreData && <IncludeAllCoreDataEntities />}
                </Flex>
              </Card>
            )}
            {!errorVisibilityImprovementFF && (
              <Card height="100%" padding={0} overflow="hidden">
                <Flex direction="column" height="100%">
                  <Flex direction="column" height="100%" padding="space16">
                    {entity !== 'REIMBURSEMENT_ITEMS' ? (
                      <TableTabs />
                    ) : (
                      <EmptyTableTabs />
                    )}
                    <ExportTable />
                  </Flex>
                  {showIncludeEntitiesWithoutDocument && (
                    <IncludeEntitiesWithoutDocument />
                  )}
                  {showExportAllCoreData && <IncludeAllCoreDataEntities />}
                </Flex>
              </Card>
            )}
          </Flex>
          <ExportSidebar />
        </>
      )}
    </ExportWrapper>
  );
};
