import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { PromotionDetailsModalContent } from 'components/NewPromotions/PromotionCard/PromotionDetailsModalContent/PromotionDetailsModalContent';
import { PromotionLabel } from 'components/NewPromotions/PromotionCard/PromotionHeader/PromotionHeader';
import { PromotionModal } from 'components/NewPromotions/PromotionCard/PromotionModal/PromotionModal';
import { PromotionModalId } from 'components/NewPromotions/types';
import { useGetCustomPromotionDetails } from 'components/ProductPromotions/hooks/useGetCustomPromotionDetails';
import { FEATURE } from 'providers/FeatureToggleProvider/types';

export type EntitlementsModalProps = {
  feature: FEATURE;
  isOpen: boolean;
  onClose: () => void;
  utmSource?: string;
};

export const EntitlementsModal = ({
  feature,
  isOpen,
  onClose,
  utmSource,
}: EntitlementsModalProps) => {
  const featurePromotionIdMap: Record<FEATURE, PromotionModalId> = {
    // datev self booker -> plus
    [FEATURE.PROVISIONS]: 'datevSelfBooker',
    [FEATURE.BDS]: 'datevSelfBooker',
    [FEATURE.POSTING_TEXT]: 'datevSelfBooker',

    // contract management -> plus
    [FEATURE.CONTRACTS]: 'contractManagement',

    // document management -> plus
    [FEATURE.DOCUMENT_RELATIONS]: 'documentManagement',
    [FEATURE.ECM]: 'documentManagement',
    [FEATURE.TAGS]: 'documentManagement',
    [FEATURE.FULL_TEXT_SEARCH]: 'documentManagement',
    [FEATURE.DOCUMENT_PREVIEW]: 'documentManagement',
    [FEATURE.SEARCH_OVERLAY]: 'documentManagement',

    // others -> plus
    [FEATURE.ABSENCE_MANAGEMENT]: 'upsellPlus',
    [FEATURE.SEPA_XML]: 'upsellPlus',

    // max
    [FEATURE.INTEGRATION_SAP]: 'upsellMax',
    [FEATURE.PURCHASE_ORDERS]: 'upsellMax',
    [FEATURE.REIMBURSEMENT]: 'reimbursementsNonLegacy',
  };

  const { promotionContentById } = usePromotionContent(
    featurePromotionIdMap[feature]
  );

  const customPromotionDetails = useGetCustomPromotionDetails(
    featurePromotionIdMap[feature],
    utmSource
  );

  return (
    <PromotionModal
      isModalOpen={isOpen}
      closeModal={onClose}
      titleInfo={<PromotionLabel label={promotionContentById?.label} />}
      data-cy={promotionContentById?.id}
    >
      <PromotionDetailsModalContent promotionDetails={customPromotionDetails} />
    </PromotionModal>
  );
};
