import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useDatev } from 'orgConfig/datev';
import { useContext } from 'react';
import { ExportContext } from '../../Context';
import { Entity, Table } from '../../types';
import {
  ApprovalsDocumentsContainer,
  ApprovalsReimbursementItemsContainer,
  ApprovalsTransactionsContainer,
} from './approvals/Approvals';
import { ContactsContainer } from './contacts/Contacts';
import { PaymentConditionsContainer } from './paymentConditions/PaymentConditions';
import { ProvisionsContainer } from './provisions/Provisions';
import { ReversalsContainer } from './reversals/Reversals';
import { Settlements } from './settlements/Settlements';
import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';
import { ReimbursementPromotionExport } from 'components/ProductPromotions/Reimbursement/ReimbursementsPromotionExport';
import { CreditCardsPromotionExportApproval } from 'components/ProductPromotions/CreditCards/CreditCardsPromotionExportApproval';
import { CreditCardsPromotionExportCardSettlement } from 'components/ProductPromotions/CreditCards/CreditCardsPromotionExportCardSettlement';

export const ExportTable = () => {
  const state = useContext(ExportContext);
  const { entity, table } = state;
  const { bdsBought } = useDatev(); // BDS-checked
  const creditCards = useCreditCardsSetup();

  const reimbursementsPromotions = useReimbursementsPromotions();

  const isPromotionForReimbursementsVisible =
    reimbursementsPromotions.variant !== 'noPromotion';

  const showBdsPromo = !bdsBought;

  if (entity === Entity.TRANSACTION && table === Table.APPROVALS) {
    if (creditCards.showPromo) return <CreditCardsPromotionExportApproval />;

    return <ApprovalsTransactionsContainer />;
  }

  if (entity === Entity.REIMBURSEMENT_ITEMS) {
    if (isPromotionForReimbursementsVisible) {
      return <ReimbursementPromotionExport />;
    }

    return <ApprovalsReimbursementItemsContainer />;
  }

  if (table === Table.SETTLEMENTS) {
    if (creditCards.showPromo)
      return <CreditCardsPromotionExportCardSettlement />;

    return <Settlements />;
  }

  if (table === Table.REVERSALS) {
    return <ReversalsContainer showBdsPromo={showBdsPromo} />;
  }

  if (table === Table.PROVISIONS) {
    return <ProvisionsContainer showBdsPromo={showBdsPromo} />;
  }

  if (entity === Entity.DOCUMENT && table === Table.APPROVALS) {
    return <ApprovalsDocumentsContainer />;
  }

  if (table === Table.CONTACTS) {
    return <ContactsContainer />;
  }

  if (table === Table.PAYMENT_CONDITIONS) {
    return <PaymentConditionsContainer />;
  }

  return null;
};
