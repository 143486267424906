import { Button, Grid, useId } from '@candisio/design-system';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PaymentConditionsForm,
  PaymentConditionsFormOutput,
} from 'views/Settings/PaymentConditions/PaymentConditionsDrawer/PaymentConditionsForm';

export interface CreatePaymentConditionProps {
  /** Called when new payment condition is discarded */
  onDiscard?: () => void;
  /** Called when new payment condition is submitted */
  onCreate: (values: PaymentConditionsFormOutput) => Promise<void>;
}

/**
 * Renders payment condition form to display inside payment condition dropdown
 */
export const CreatePaymentCondition = ({
  onDiscard,
  onCreate,
}: CreatePaymentConditionProps) => {
  const [t] = useTranslation();
  const [creating, setCreating] = useState(false);
  const formId = useId();

  const handleSubmit = async (values: PaymentConditionsFormOutput) => {
    setCreating(true);
    await onCreate(values);
    setCreating(false);
  };

  return (
    <Grid
      onSubmit={
        // @TODO explicit MouseEvent<HTMLDivElement> type should not be
        // required.
        // See: https://github.com/microsoft/TypeScript/issues/44596
        (e: MouseEvent<HTMLDivElement>) => {
          // stop form submit event from bubbling up through the React Portal
          // and submitting parent form
          e.stopPropagation();
        }
      }
      padding="space16"
      gap="space16"
    >
      <PaymentConditionsForm
        action="create"
        formId={formId}
        layout="compact"
        onSubmit={handleSubmit}
        readOnly={creating}
      />
      <Grid gap="space8">
        <Button
          type="submit"
          color="blue"
          loading={creating}
          form={formId}
          variant="secondary"
        >
          {t('settings.contacts.details.edit.paymentCondition.submit')}
        </Button>
        <Button
          color="blue"
          disabled={creating}
          onClick={onDiscard}
          variant="tertiary"
        >
          {t('settings.contacts.details.edit.paymentCondition.discard')}
        </Button>
      </Grid>
    </Grid>
  );
};
