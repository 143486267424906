import {
  EcmDocumentContractStatus,
  DocumentType,
  GetEcmContractsQuery,
} from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { debounce, isEmpty, merge } from 'lodash';
import moment from 'moment';
import {
  EcmDocumentsPaginationParams,
  useEcmPagination,
} from 'providers/GraphQLProvider/Pagination/useEcmPagination';
import { useMemo, useState } from 'react';
import { getEcmContractsQuery } from 'views/Archive/EcmContracts/queries';
import { ContractStatus, EcmDocumentsTableData } from '../types';
import { SortingRule } from 'react-table';

import { mapToEcmDocumentSortInput } from 'views/utils/mapToEcmDocumentSortInput';
import { useSearchInput } from 'components/EcmSearchFieldFilterOverlay/useSearchInput';
export interface UseEcmDocumentsDataReturn {
  data: EcmDocumentsTableData[];
  handleDebounceSearch: (searchQuery: string) => void;
  hasNoData: boolean;
  isLoading: boolean;
  onLoadMore: () => void;
  selectedContractsCount: number;
}

interface UseEcmDocumentsDataParams {
  params?: Omit<EcmDocumentsPaginationParams, 'search'>;
  initialSearchQuery?: string;
  previewDocumentId?: string | null;
  sortBy?: Array<SortingRule<keyof EcmDocumentsTableData>>;
}

interface GetContractStatusOptions {
  endDate?: Date;
  startDate?: Date;
  terminationReminderDate?: Date;
  isSensitive: boolean;
}

export const getContractStatus = ({
  endDate,
  startDate,
  terminationReminderDate,
  isSensitive,
}: GetContractStatusOptions): ContractStatus => {
  const today = moment().startOf('day');

  let contractStatus: ContractStatus = {
    status: EcmDocumentContractStatus.Active,
    isOverdue: false,
    isSensitive,
  };

  if (today.isAfter(terminationReminderDate)) {
    contractStatus.isOverdue = true;
  }

  if (!startDate && !endDate) {
    return contractStatus;
  }

  if (
    startDate !== undefined &&
    endDate !== undefined &&
    !today.isBetween(startDate, endDate)
  ) {
    contractStatus.status = EcmDocumentContractStatus.Inactive;
  }

  if (endDate === undefined && today.isBefore(startDate)) {
    contractStatus.status = EcmDocumentContractStatus.Inactive;
  }

  if (startDate === undefined && today.isAfter(endDate)) {
    contractStatus.status = EcmDocumentContractStatus.Inactive;
  }

  return contractStatus;
};

/**
 * Fetches ECM documents and maps to EcmDocumentsTableData
 */
export const useEcmContractsData = ({
  params,
  initialSearchQuery = '',
  previewDocumentId,
  sortBy,
}: UseEcmDocumentsDataParams): UseEcmDocumentsDataReturn => {
  const [debounceSearchValue, setDebounceSearchValue] =
    useState(initialSearchQuery);

  const gqlSort = sortBy ? { sort: mapToEcmDocumentSortInput(sortBy) } : {};
  delete params?.sort;

  const { searchInputVariable } = useSearchInput();

  const contractQueryParams = {
    search: debounceSearchValue,
    filter: { documentType: [DocumentType.Contract] },
    searchInput: searchInputVariable,
  };
  const variables = merge(contractQueryParams, params, gqlSort);

  const {
    data,
    loading: isLoading,
    onLoadMore,
  } = useEcmPagination<
    GetEcmContractsQuery,
    {} // TODO: Query variables do not extend from interface properly, this needs to be fixed in `useEcmPagination`
  >(getEcmContractsQuery, 'getEcmDocuments', { variables });

  const handleDebounceSearch = useMemo(() => {
    return debounce(setDebounceSearchValue, DEFAULT_DEBOUNCE_TIME);
  }, []);

  const ecmDocumentsList = data?.getEcmDocuments;

  const hasNoData = !isLoading && data?.getEcmDocuments?.edges?.length === 0;

  const contractList = useMemo(
    () =>
      ecmDocumentsList?.edges
        ? ecmDocumentsList.edges.map(
            ({ record, cursor }): EcmDocumentsTableData => {
              const responsiblePerson = record?.responsiblePerson?.primary;
              const notifyPerson = record?.notifyPerson?.primary;

              const startDate = record?.startDate
                ? new Date(record?.startDate)
                : undefined;

              const endDate = record?.endDate
                ? new Date(record?.endDate)
                : undefined;

              const terminationReminderDate = record?.terminationReminderDate
                ? new Date(record?.terminationReminderDate)
                : undefined;

              const value = record.amount?.amount;
              const currency = record.amount?.currency;
              const amount =
                value && currency
                  ? { amount: value, currency, isBasicMonetaryUnit: true }
                  : undefined;

              return {
                cursor,
                id: record?.id ?? '',
                globalDocumentId: record?.globalDocumentId ?? '',
                contact: record?.contactName ?? '',
                contractStatus: getContractStatus({
                  startDate,
                  endDate,
                  terminationReminderDate,
                  isSensitive: record?.isSensitive ?? false,
                }),
                invoiceId: record?.invoiceId ?? '',
                amount,
                notes: record?.notes ?? '',
                documentName: record?.documentName ?? '',
                documentNumber: record?.documentNumber ?? '',
                costCenter: record?.costCenterName ?? '',
                contractType: record?.documentSubCategory ?? undefined,
                startDate,
                endDate,
                terminationDate: record?.terminationDate
                  ? new Date(record?.terminationDate)
                  : undefined,
                terminationReminderDate,
                responsiblePerson: !isEmpty(responsiblePerson)
                  ? responsiblePerson
                  : undefined,
                notifyPerson: !isEmpty(notifyPerson) ? notifyPerson : undefined,
                tags: record?.tags,
                selected: record?.id === previewDocumentId,
              };
            }
          )
        : [],
    [ecmDocumentsList?.edges, previewDocumentId]
  );

  const selectedContractsCount = ecmDocumentsList?.pageInfo?.totalCount ?? 0;

  return {
    data: contractList,
    handleDebounceSearch,
    hasNoData,
    isLoading,
    onLoadMore,
    selectedContractsCount,
  };
};
