import { ImportHistoryLayout } from 'components/ImportHistoryLayout/ImportHistoryLayout';
import { CoreDataTypes } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { CoreDataImportHistoryList } from 'views/CoreDataImportHistory/elements/CoreDataImportHistoryList';
import { useCoreDataImportHistory } from 'views/CoreDataImportHistory/toolkit/hooks/useCoreDataImportHistory';

interface CoreDataImportHistoryProps {
  coreDataType: CoreDataTypes;
  title?: string;
  backButtonLabel?: string;
  backButtonLink: string;
  localeNamespace?: string;
}

export const CoreDataImportHistory = ({
  coreDataType,
  title = 'importHistoryList.title',
  backButtonLabel = 'importHistoryList.backButtonLabel',
  backButtonLink,
  localeNamespace,
}: CoreDataImportHistoryProps) => {
  const [t] = useTranslation(localeNamespace);
  const { historyItems, isImportHistoryDataLoading } = useCoreDataImportHistory(
    { coreDataType }
  );

  return (
    <ImportHistoryLayout
      title={t(title)}
      backButtonLabel={t(backButtonLabel)}
      backButtonLink={backButtonLink}
    >
      <CoreDataImportHistoryList
        importHistoryData={historyItems}
        coreDataType={coreDataType}
        isLoading={isImportHistoryDataLoading}
      />
    </ImportHistoryLayout>
  );
};
