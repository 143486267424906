import { Card, Grid, Tag, Text, useTheme } from '@candisio/design-system';
import { CardStatus, CardType } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { LOCALE_NAME_SPACE } from '../../../../../providers/LocaleProvider';
import { DateFormatters } from '../../../../../utils/date_formatter';
import { useGetCardById } from '../../../utils';

export const LimitChangeInfo = ({ cardId }: { cardId: string }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { colors } = useTheme();
  const { card } = useGetCardById({ cardId });

  const responsiveInfoBox = useMediaQuery({
    maxWidth: 1500,
  })
    ? '100px'
    : undefined;

  if (!card) {
    return null;
  }

  const { pendingRequest, status, type, availableBalance, transactionLimit } =
    card;

  const hasLimitChangeRequest =
    !isNil(pendingRequest) &&
    pendingRequest.__typename === 'CardLimitChangeRequest';

  const isCardNotUsableAnymore =
    status === CardStatus.Active &&
    type === CardType.SingleUse &&
    (availableBalance?.value ?? 0) < (transactionLimit?.value ?? 0);

  if (!hasLimitChangeRequest || isCardNotUsableAnymore) {
    return null;
  }

  const requestDate = pendingRequest?.createdAt;

  return (
    <Card
      background={colors.gray200}
      borderRadius="8px"
      padding="space12"
      maxWidth="261px"
      width={responsiveInfoBox}
      justifySelf="end"
      boxShadow="none"
      alignSelf="start"
    >
      <Grid gap="space8" templateRows="18px auto">
        <Tag color="yellow">{t('carousel.requested.tag')}</Tag>
        <Text color="gray600">
          {t('carousel.limitChange.info', {
            requestedAt: DateFormatters.compactDatetime(new Date(requestDate)),
          })}
        </Text>
      </Grid>
    </Card>
  );
};
