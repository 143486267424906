import { Card, Grid, Icon, Link } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';

interface CardReplacesInfoProps {
  id: string;
  replacedDate: string;
  refNumber?: string;
  replacedBy?: string;
}

export const CardReplaced = ({
  id,
  replacedDate,
  refNumber,
  replacedBy,
}: CardReplacesInfoProps) => {
  const orgId = useOrganizationId();
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { searchParams } = useMutateSearchParams();

  const { isCardManager, isAdmin, isAccountant } = useUserRoles();

  const isAllowedToSeeOnlyCardsTable =
    !isCardManager && !isAdmin && !isAccountant;

  const getLinkToReplacementCard = () => {
    const newUrlSerchParams = new URLSearchParams(searchParams);
    newUrlSerchParams.set('onCreditCardsView', 'true');
    newUrlSerchParams.set('toolbarFilter', 'Inactive');

    return isAllowedToSeeOnlyCardsTable
      ? `/${orgId}${
          Routes.CREDIT_CARDS_CARDHOLDER_OVERVIEW
        }/${id}?${newUrlSerchParams.toString()}`
      : `/${orgId}${
          Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW
        }/${id}?${newUrlSerchParams.toString()}`;
  };

  return (
    <Card background="bluebg">
      <Grid templateColumns="auto 1fr" gap="space8" alignItems="center">
        <Icon size="space20" icon="infoCircle" color="blue500" />
        <Trans
          t={t}
          parent="span"
          values={{
            cardRefNum: refNumber,
            date: replacedDate,
            userName: replacedBy,
          }}
          i18nKey="creditCardFormDataPreview.replaceCard.infoBox.replaces"
        >
          Replacement card for
          <Link external href={getLinkToReplacementCard()}>
            {{ cardRefNum: refNumber } as any}
          </Link>
          on
          {{ date: replacedDate } as any}
          by
          {{ userName: replacedBy } as any}
        </Trans>
      </Grid>
    </Card>
  );
};
