import { Image, Grid, Link } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import sparkles from 'views/CreditCards/CreditCardsInsights/media/sparkles.svg';

export const AutoMatchBannerWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Grid
      background="bluebg"
      autoFlow="column"
      paddingX="space24"
      paddingY="space16"
      borderTopRadius="medium"
      placeItems="center"
      placeContent="space-between"
    >
      <Grid autoFlow="column" placeItems="center" gap="space8">
        <Image alt="sparkles" src={sparkles} height="space32" width="space32" />
        {children}
      </Grid>
      <Link
        external
        fontSize="basic"
        icon="newTab"
        iconPosition="right"
        href={t('autoMatchBanner.articleLink')}
      >
        {t('autoMatchBanner.article')}
      </Link>
    </Grid>
  );
};
