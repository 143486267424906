import {
  Button,
  Grid,
  Paragraph,
  Popover,
  Text,
  usePopover,
} from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../../../providers/LocaleProvider';
import { OnAssociateDocument, OnDeleteFile } from '../../types';

export const UploadedFile = ({
  onDeleteFile,
  isProcessingLoading,
  isDeletingLoading,
  onAssociateDocument,
}: {
  isProcessingLoading: boolean;
  isDeletingLoading: boolean;
  onDeleteFile: OnDeleteFile;
  onAssociateDocument: OnAssociateDocument;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const { close, isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'top' });

  const deleteTranslations = {
    title: t(
      'transactionAssociation.rightContent.actionBox.deleteUploadedFile.confirm.title'
    ),
    text: t(
      'transactionAssociation.rightContent.actionBox.deleteUploadedFile.confirm.text'
    ),
    cancel: t(
      'transactionAssociation.rightContent.actionBox.deleteUploadedFile.confirm.cancelButton'
    ),
    ok: t(
      'transactionAssociation.rightContent.actionBox.deleteUploadedFile.confirm.okButton'
    ),
  };

  return (
    <>
      <Paragraph color="gray500" fontWeight="semibold">
        {t('transactionAssociation.rightContent.actionBox.title')}
      </Paragraph>
      <Button
        loading={isProcessingLoading}
        disabled={isDeletingLoading}
        width="100%"
        onClick={onAssociateDocument}
      >
        {t('transactionAssociation.rightContent.actionBox.ctaButton')}
      </Button>

      <Button
        loading={isDeletingLoading}
        disabled={isProcessingLoading}
        variant="tertiary"
        color="red"
        width="100%"
        {...triggerProps}
        ref={triggerRef}
      >
        {t(
          'transactionAssociation.rightContent.actionBox.deleteUploadedFile.cta'
        )}
      </Button>
      {isOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          paddingX="space16"
          paddingY="space12"
        >
          <Grid gap="space8" padding="space16" width="350px">
            <Grid gap="space4">
              <Text fontWeight="bold">{deleteTranslations.title}</Text>
              <Paragraph>{deleteTranslations.text}</Paragraph>
            </Grid>
            <Grid autoFlow="column" gap="space16" justifyContent="end">
              <Button variant="secondary" onClick={close}>
                {deleteTranslations.cancel}
              </Button>
              <Button
                color="red"
                onClick={() => {
                  close();
                  void onDeleteFile();
                }}
              >
                {deleteTranslations.ok}
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
};
