import {
  Checkbox,
  Flex,
  Grid,
  Image,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { CardBrand } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { cardTypes } from 'views/CreditCards/CardManagerView/components/CreditCardsTable/Cells/CardNumberCell';
import { CreditCardOption } from '../../toolkit/hooks/useCreditCardOptions';

interface CreditCardGridItemCheckboxProps {
  creditCard: CreditCardOption;
  isChecked: boolean;
  isReadOnly: boolean;
  onChange: (checkBoxValue: string) => void;
}

export const CreditCardGridItemCheckbox = ({
  creditCard,
  isChecked,
  isReadOnly,
  onChange,
}: CreditCardGridItemCheckboxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { brand, cardNumber, firstName, label, lastName, purpose, type, id } =
    creditCard;

  const isDisabled = creditCard.isAssignedToTeam;

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  const handleClick = () => {
    if (isDisabled || isReadOnly) {
      return;
    }

    onChange(id);
  };

  return (
    <Grid
      {...triggerProps}
      ref={triggerRef}
      padding="space8"
      gap="space4"
      borderRadius="medium"
      height="97px"
      background="gray100"
      templateRows="auto 1fr"
      onClick={handleClick}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
    >
      <Grid templateColumns="1fr 1fr">
        <Image
          src={
            brand === CardBrand.Candis
              ? cardTypes[type].candis
              : cardTypes[type].pliant
          }
          height="40px"
          alt={t(
            'teams.createTeam.creditCardDetails.creditCardsField.altCreditCard'
          )}
          justifySelf="start"
        />
        <Flex
          justifySelf="end"
          direction="column"
          alignItems="end"
          gap="space4"
        >
          <Checkbox
            labelHidden
            isSelected={isChecked}
            isDisabled={isDisabled || isReadOnly}
          >
            {cardNumber}
          </Checkbox>
          {cardNumber && <Text>{cardNumber}</Text>}
        </Flex>
      </Grid>
      <Grid>
        {firstName && lastName && (
          <TruncatedText>{`${firstName} ${lastName}`}</TruncatedText>
        )}
        {label && <TruncatedText color="gray500">{label}</TruncatedText>}
        {purpose && <TruncatedText color="gray500">{purpose}</TruncatedText>}
      </Grid>
      {isOpen && isDisabled && (
        <Tooltip {...tooltipProps} ref={tooltipRef} padding="space8">
          <Grid>
            <Text>
              {t(
                'teams.createTeam.creditCardDetails.creditCardsField.disabledTooltip'
              )}
            </Text>
          </Grid>
        </Tooltip>
      )}
    </Grid>
  );
};
