import { Link } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useArchiveContactsMutation } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { Trans } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { useContactRefetchQueries } from 'views/queries/contacts';
import { getContactQuery } from '../queries';

export const useArchiveContacts = () => {
  const [t] = useTranslation();
  const { success, error, dismiss } = useToastMessage();
  const { queries: contactRefetchQueries, evictPaginationResults } =
    useContactRefetchQueries();

  const [archive, { loading: archiving }] = useArchiveContactsMutation();

  const navigate = useNavigate();
  const params = useParams();
  const organizationSlug = params?.organizationSlug;

  const handleArchive = useCallback(
    async (ids: string[], name?: string) => {
      const queriesToRefetch = contactRefetchQueries;
      ids.forEach(id => {
        queriesToRefetch.push({
          query: getContactQuery,
          variables: { input: { id } },
        });
      });
      const result = await archive({
        variables: {
          ids,
        },
        refetchQueries: queriesToRefetch,
        onCompleted: () => {
          evictPaginationResults();
        },
      });

      if (result.data?.archiveContacts?.__typename === 'ContactArchived') {
        if (name && ids.length === 1) {
          const key = 'settings.contacts.archived';
          const archivedContactDetailsUrl = `/${organizationSlug}${Routes.CONTACTS}/${ids[0]}?isArchived=true`;
          const content = (
            <Trans i18nKey={key} values={{ name }}>
              Contact
              <Link
                href={archivedContactDetailsUrl}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault();
                  dismiss();
                  navigate(archivedContactDetailsUrl, { replace: true });
                }}
              >
                {{ name } as any}
              </Link>
              archived
            </Trans>
          );

          success(content, 7);
        } else {
          success(
            t('settings.contacts.archived_plural', { count: ids.length })
          );
        }

        return result.data.archiveContacts;
      } else {
        error(t('settings.contacts.archiveFailed'));

        return null;
      }
    },
    [
      archive,
      contactRefetchQueries,
      dismiss,
      error,
      evictPaginationResults,
      navigate,
      organizationSlug,
      success,
      t,
    ]
  );

  return {
    handleArchive,
    archiving,
  };
};
