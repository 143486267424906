import {
  Button,
  Grid,
  Heading,
  InfoPanel,
  Link,
  Text,
} from '@candisio/design-system';
import { ReActivateRow } from 'components/ArchiveWrapper/ArchiveWrapper';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import {
  ContractSubCategoryDetailsFormProps,
  ContractSubtypeDetailsForm,
} from '../Form/ContractSubCategoryDetailsForm';
import { ContractSubCategoryDetailsFormValues } from '../Form/contractSubCategoryDetailsFormSchema';
import { ContractCustomSubCategoryDetailsDrawerProps } from './ContractCustomSubCategoryDetailsDrawer';
import { useCreateSubCategoryForm } from './useCreateSubCategoryForm';

const CREATE_CONTRACT_SUBCATEGORY_FORM_ID = 'create-contract-subcategory';

export const contractSubCategoryDefaultValues = {
  name: '',
  description: '',
};

export interface ContractCustomSubCategoryDetailsProps
  extends Omit<ContractCustomSubCategoryDetailsDrawerProps, 'isOpen'> {
  defaultValues: ContractSubCategoryDetailsFormValues;
  onSubmit: ContractSubCategoryDetailsFormProps['onSubmit'];
  isSubmitPending: boolean;
  updatingStatus: boolean;
  onArchive: () => Promise<void>;
  onActivate: () => Promise<void>;
  isActive?: boolean;
  isStatic?: boolean;
}

export const ContractCustomSubCategoryDetails = ({
  onCloseDrawer,
  compact,
  contractSubCategoryId,
  onArchive,
  defaultValues,
  onSubmit,
  isSubmitPending,
  updatingStatus,
  isActive,
  isStatic,
  onActivate,
}: ContractCustomSubCategoryDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { showConfirmation, form } = useCreateSubCategoryForm({
    defaultValues,
    contractSubCategoryId,
  });

  const isReadOnly = !isActive || isStatic;
  const showArchiveButton = contractSubCategoryId && !isReadOnly;

  return (
    <DrawerLayout
      compact={compact}
      onClose={onCloseDrawer}
      header={
        <Heading as="h3">
          {contractSubCategoryId
            ? t('contractCustomSubCategories.drawer.titleEdit')
            : t('contractCustomSubCategories.drawer.titleNew')}
        </Heading>
      }
      footer={
        <Grid placeContent="space-between" autoFlow="column">
          {showConfirmation ? (
            <ConfirmationButton
              confirmButtonProps={{
                type: 'submit',
                form: CREATE_CONTRACT_SUBCATEGORY_FORM_ID,
                loading: isSubmitPending,
                disabled: isSubmitPending,
              }}
              confirmationTitle={t(
                'contractCustomSubCategories.drawer.actions.confirmNameChange'
              )}
              onConfirm={form.handleSubmit(onSubmit)}
              loading={isSubmitPending}
              disabled={isSubmitPending || isReadOnly}
            >
              {t('contractCustomSubCategories.drawer.actions.save')}
            </ConfirmationButton>
          ) : (
            <Button
              type="submit"
              form={CREATE_CONTRACT_SUBCATEGORY_FORM_ID}
              loading={isSubmitPending}
              disabled={isSubmitPending || isReadOnly}
            >
              {t('contractCustomSubCategories.drawer.actions.save')}
            </Button>
          )}
          {showArchiveButton && (
            <ConfirmationButton
              onConfirm={onArchive}
              confirmationTitle={t(
                'contractCustomSubCategories.drawer.actions.confirmArchive'
              )}
              variant="tertiary"
              color="red"
              loading={updatingStatus}
              disabled={updatingStatus}
            >
              {t('contractCustomSubCategories.drawer.actions.archive')}
            </ConfirmationButton>
          )}
        </Grid>
      }
    >
      {!isActive && (
        <ReActivateRow
          onRestore={onActivate}
          isSubmitting={updatingStatus}
          disabled={updatingStatus}
        />
      )}
      {isStatic && (
        <InfoPanel
          variant="information"
          title="Predefined Type"
          message="Predefined contract types can currently not be edited."
        />
      )}
      <ContractSubtypeDetailsForm
        form={form}
        formId={CREATE_CONTRACT_SUBCATEGORY_FORM_ID}
        onSubmit={onSubmit}
        readOnly={isReadOnly}
      />
      <Grid
        top="space24"
        gap="space16"
        background="bluebg"
        padding="space16"
        borderRadius="medium"
        overflow="hidden"
      >
        <Grid gap="space8">
          <Text
            as="h3"
            fontWeight="semibold"
            justifySelf="start"
            fontSize="medium"
          >
            {t('contractCustomSubCategories.drawer.links.title')}
          </Text>
          <Text>{t('contractCustomSubCategories.drawer.links.hint')}</Text>
        </Grid>
        <Link
          external
          href={t(
            'contractCustomSubCategories.drawer.links.helpCenterArticle.url'
          )}
        >
          {t(
            'contractCustomSubCategories.drawer.links.helpCenterArticle.label'
          )}
        </Link>
      </Grid>
    </DrawerLayout>
  );
};
