import { Text } from '@candisio/design-system';
import { PromotionPanel } from 'components/Promotions/PromotionPanel';
import { videoTours } from 'containers/Entitlements/components/Upsell/utils';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const SepaXML = ({ onClick }: { onClick?: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  return (
    <PromotionPanel
      thumbnails={videoTours[FEATURE.SEPA_XML]}
      slots={{
        header: t('promo.payments.header'),
        description: (
          <Trans t={t} i18nKey="promo.payments.description">
            is required to be able to use this export.
            <Text fontWeight="semibold">Candis Plus</Text>
          </Trans>
        ),
        button: t('promo.payments.button'),
      }}
      onLearnMoreClick={onClick}
      imageStyle={{ marginTop: '20px' }}
    />
  );
};
