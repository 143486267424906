import {
  AvatarStack,
  Box,
  Button,
  Flex,
  Grid,
  Item,
  TabPanel,
  Tabs,
  Text,
} from '@candisio/design-system';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { EmptySearchState } from 'components/FilterableList/components/EmptySearchState';
import { FilterableListItem } from 'components/FilterableList/components/FilterableListItem';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { CostCenterKebabMenu } from 'components/Menu/CostCenterKebabMenu/CostCenterKebabMenu';
import { useTabs } from 'components/Tabs/useTabs';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import {
  CostCenterDataFragment,
  CostCenterSortField,
  CostCenterTypes,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { Routes } from 'models';
import { getTranslationContext } from 'orgConfig';
import { useOtherIntegration } from 'orgConfig/other';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { Match } from 'router/Match';
import { usePath } from 'utils/hooks';
import { SettingsLayout } from 'views/Settings/components/SettingsLayout/SettingsLayout';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { useSap } from '../../../orgConfig/sap';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';
import { CostCenterDetails } from './components/CostCenterDetails';
import { CostCenterImport } from './components/CostCenterImport/CostCenterImport';
import { EmptyDataState } from './components/EmptyDataState';
import { COST_CENTER_CREATE_ROUTE, COST_CENTER_SEARCH_PARAM } from './consts';
import { useCostCenterData } from './hooks/useCostCenterData';

enum FilterBy {
  All = 'ALL',
  CostCenter = CostCenterTypes.CostCenter,
  CostObject = CostCenterTypes.CostObject,
  ExtraCostInfo = CostCenterTypes.ExtraCostInfo,
}

enum SortBy {
  alphabetically = CostCenterSortField.Name,
  lastAdded = CostCenterSortField.CreatedAt,
  code = CostCenterSortField.Code,
}

export const CostCenters = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const navigate = useNavigate();
  const { isActiveIntegration: shouldUseSapCostCenters } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();
  const organization = useOrganizationId();
  const [queryString, setQueryString] = useState('');
  const [sortBy, setSortBy] = useState<CostCenterSortField>(
    CostCenterSortField.Code
  );

  const [filterBy, setFilterBy] = useState<FilterBy>(FilterBy.All);
  const [searchParams] = useSearchParams();
  const isArchived = searchParams.toString().includes('true');
  const showCreateAction = !shouldUseCoreDataApi && !shouldUseSapCostCenters;

  const { pathGenerator } = usePath<
    { costCenterId?: string },
    {
      [COST_CENTER_SEARCH_PARAM.archive]?: boolean;
    }
  >();

  const filterByMap = {
    [FilterBy.CostCenter]: CostCenterTypes.CostCenter,
    [FilterBy.CostObject]: CostCenterTypes.CostObject,
    [FilterBy.ExtraCostInfo]: CostCenterTypes.ExtraCostInfo,
  };

  const {
    activeCount,
    archivedCount,
    costCentersData,
    countLoading,
    onEndReached,
    handleDebounceSearch,
    isLoading,
  } = useCostCenterData(
    isArchived,
    sortBy,
    filterBy !== FilterBy.All ? filterByMap[filterBy] : null
  );

  const onSelect = (id: string) => {
    navigate({
      pathname: `/${organization}${Routes.SETTINGS}${Routes.COST_CENTER}/${id}`,
      search: searchParams.toString(),
    });
  };

  const handleCreate = () => {
    navigate({
      pathname: `/${organization}${Routes.SETTINGS}${Routes.COST_CENTER}/create`,
    });
  };

  const [artistSocialInsuranceAndExtraCostInfoFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.artistSocialInsuranceAndExtraCostInfo,
  ]);

  const typeItems = artistSocialInsuranceAndExtraCostInfoFF
    ? [
        {
          id: FilterBy.All,
          label: t('costCenters.typeItems.all'),
        },
        {
          id: FilterBy.CostCenter,
          label: t('costCenters.typeItems.costCenter', {
            context: getTranslationContext(),
          }),
        },
        {
          id: FilterBy.CostObject,
          label: t('costCenters.typeItems.costObject', {
            context: getTranslationContext(),
          }),
        },
        {
          id: FilterBy.ExtraCostInfo,
          label: t('costCenters.typeItems.extraCostInfo'),
        },
      ]
    : [
        {
          id: FilterBy.All,
          label: t('costCenters.typeItems.all'),
        },
        {
          id: FilterBy.CostCenter,
          label: t('costCenters.typeItems.costCenter'),
        },
        {
          id: FilterBy.CostObject,
          label: t('costCenters.typeItems.costObject'),
        },
      ];

  const sortItems = [
    {
      id: SortBy.alphabetically,
      label: t('costCenters.sortItems.alphabetically'),
    },
    {
      id: SortBy.code,
      label: t('costCenters.sortItems.code'),
    },
    {
      id: SortBy.lastAdded,
      label: t('costCenters.sortItems.lastCreatedFirst'),
    },
  ];

  const sortButtonText = sortItems.find(
    item => sortBy.toString() === item.id
  )?.label;

  const typeButtonText = typeItems.find(
    item => filterBy.toString() === item.id
  )?.label;

  const renderTypeLabel = (type: Key) => {
    return typeItems.find(item => item.id === type)?.label;
  };

  const handleSortByType = (value: Key[]) => {
    setFilterBy(value.length ? (value[0] as FilterBy) : filterBy);
  };

  const handleSortBy = (value: Key[]) => {
    setSortBy(value.length ? (value[0] as CostCenterSortField) : sortBy);
  };

  const onSearchFilter = (filter: string) => {
    handleDebounceSearch(filter);
    setQueryString(filter);
  };

  const handleSearchReset = () => {
    handleDebounceSearch('');
    setQueryString('');
  };

  const hasArchivedData = !isArchived && archivedCount > 0;

  const filterableList = (
    <FilterableList
      children={costCentersData.map((costCenter: CostCenterDataFragment) => (
        <Item key={costCenter.id} textValue={costCenter.id}>
          <FilterableListItem
            itemId={costCenter.id}
            onSelect={onSelect}
            templateColumns="1fr 90px"
          >
            <Grid>
              <Text
                fontWeight="semibold"
                fontSize="basic"
                overflowWrap="anywhere"
              >
                {renderQueryHighlight({
                  value: costCenter.name ?? '',
                  queryString: queryString,
                })}
              </Text>
              <Flex
                as="p"
                color="gray500"
                fontSize="small"
                fontWeight="regular"
                gap="space4"
              >
                <Box as="span">
                  {renderQueryHighlight({
                    value: costCenter.code ?? '',
                    queryString: queryString,
                  })}
                </Box>
                <Box as="span">{t('costCenters.bulletPoint')}</Box>
                {renderTypeLabel(costCenter.type)}
              </Flex>
            </Grid>
            <Grid justifyContent="start">
              <AvatarStack limit={4} size="small">
                {(costCenter.approvers ?? []).map(approver => (
                  <AvatarWithStatusContainer
                    key={approver.id}
                    name={approver.name}
                    userId={approver.id}
                    img={approver.avatarUrl ?? undefined}
                  />
                ))}
              </AvatarStack>
            </Grid>
          </FilterableListItem>
        </Item>
      ))}
      customCreate={
        shouldUseSapCostCenters && (
          <>
            <SyncFromSap type={SAP_SYNC.CostObjects} />
            <Button
              width="max-content"
              size="small"
              variant="secondary"
              onClick={() =>
                navigate(
                  `/${organization ?? ''}${Routes.SETTINGS}${
                    Routes.COST_CENTER_IMPORT_HISTORY
                  }`
                )
              }
            >
              {t('costCenters.contextMenu.historyLabel')}
            </Button>
          </>
        )
      }
      emptyDataState={
        !isLoading && (
          <EmptyDataState
            handleCreate={handleCreate}
            hasArchivedData={hasArchivedData}
          />
        )
      }
      emptySearchState={
        !isLoading && <EmptySearchState searchReset={handleSearchReset} />
      }
      isLoading={isLoading}
      kebabMenu={<CostCenterKebabMenu organizationSlug={organization ?? ''} />}
      menuButtons={[
        {
          actionValue: [filterBy],
          onClick: handleSortByType,
          text: typeButtonText ?? '',
          menuButtonItems: typeItems,
        },
        {
          actionValue: [sortBy],
          onClick: handleSortBy,
          text: sortButtonText ?? '',
          menuButtonItems: sortItems,
        },
      ]}
      onCreate={
        showCreateAction
          ? {
              value: handleCreate,
              text: t('costCenters.actions.new'),
            }
          : undefined
      }
      onEndReached={onEndReached}
      searchField={{
        onSearchFilter,
        placeholder: t('costCenters.searchPlaceholder'),
        searchQuery: queryString,
      }}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    />
  );

  const { tabPanelProps, tabsProps } = useTabs({
    items: [
      {
        key: 'active',
        title: t('costCenters.actions.activeTab'),
        badge: !countLoading ? String(activeCount) : undefined,
        children: filterableList,
      },
      {
        key: 'archived',
        title: t('costCenters.actions.archiveTab'),
        badge: !countLoading ? String(archivedCount) : undefined,
        children: filterableList,
      },
    ],
    onSelectionChange: key => {
      searchParams.set('archived', key === 'archived' ? 'true' : 'false');
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    selectedKey: isArchived ? 'archived' : 'active',
  });

  const externalLink = shouldUseCoreDataApi
    ? 'costCenters.infoPanel.coreDataApiExternalLink'
    : 'costCenters.infoPanel.externalLink';

  const sections = shouldUseCoreDataApi
    ? 'costCenters.infoPanel.coreDataApiSections'
    : 'costCenters.infoPanel.sections';

  const title = shouldUseCoreDataApi
    ? 'costCenters.infoPanel.coreDataApiTitle'
    : 'costCenters.infoPanel.title';

  return (
    <SettingsLayout
      actions={<Tabs {...tabsProps} />}
      title={t('costCenters.title')}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      <TabPanel {...tabPanelProps} />
      <InfoPanel
        externalLink={
          !shouldUseSapCostCenters
            ? t(externalLink, {
                returnObjects: true,
              })
            : undefined
        }
        sections={t(sections, {
          returnObjects: true,
        })}
        title={t(title)}
      />

      <Match
        paths={[
          pathGenerator.stringify({ costCenterId: COST_CENTER_CREATE_ROUTE }),
          pathGenerator.stringify({ costCenterId: ':costCenterId' }),
        ]}
        component={CostCenterDetails}
      />

      <CostCenterImport />
    </SettingsLayout>
  );
};
