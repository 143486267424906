import { ContactsTableData } from '../ContactTableDS';

export const availableFilters: Array<keyof ContactsTableData> = [
  'contact',
  'accountsPayableNumber',
  'accountsReceivableNumber',
  'iban',
  'paymentInfo',
  'taxNumber',
  'vatId',
  'isArchived',
];
