import { FieldContainerProps } from '@candisio/design-system';
import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import { ProcessingFormFieldMetadataMessage } from 'components/Form/ProcessingForm/ProcessingFormFieldMetadataMessage';
import { ProcessingFormFieldMetadataSource } from 'components/Form/ProcessingForm/ProcessingFormMetadataContext';
import {
  SmartFields,
  SplitDS,
  TaxPresentation,
} from 'components/Form/SplitBookingsForm/types';
import { grossToNet } from 'containers/SplitBookings/toolkit/utils';
import {
  ArtistSocialInsuranceCode,
  GetDocumentForDraftQuery,
  GetDocumentQuery,
} from 'generated-types/graphql.types';
import { getDateConverted } from 'hooks/useDateConverter';

export const getSmartFieldStatus = ({
  source,
  confidence,
  isDirty,
  showSFS,
}: {
  source?: string;
  // strictly speaking this seems to be not required as we already compare values in showSFS
  // but without the subscription to the dirty concept, the values will not re-compute and re-render the form
  // and cause errors.
  // Also, this means we lose the SFS in some cases unnecessarily when the array length changes :sad:
  isDirty: boolean;
  showSFS: boolean;
  confidence?: number;
}) => {
  if (isDirty || !showSFS || !source || confidence == null) {
    return null;
  }

  const fieldMetadata = {
    source: source as ProcessingFormFieldMetadataSource,
    confidence,
  };

  const message = fieldMetadata ? (
    <ProcessingFormFieldMetadataMessage {...fieldMetadata} />
  ) : undefined;

  const variant: FieldContainerProps['variant'] = fieldMetadata
    ? fieldMetadata.confidence < 0.9
      ? 'warning'
      : 'success'
    : 'default';

  return {
    message,
    variant,
  };
};

type BookingFieldsType = Pick<
  NonNullable<
    NonNullable<
      GetDocumentForDraftQuery['getDocument'] | GetDocumentQuery['getDocument']
    >['bookings']
  >[0],
  'bookingKey' | 'costCenter' | 'costObject' | 'generalLedgerAccount'
>;

export const getBookingFieldInitialValue = (
  booking: BookingFieldsType,
  smartFieldsSuggestions: SmartFields,
  fieldName: keyof BookingFieldsType
) => {
  const bookingField = booking[fieldName];
  const smartFieldSuggestion = smartFieldsSuggestions[fieldName];

  return bookingField
    ? {
        value: String(bookingField.value.id),
        inputValue: bookingField.value.readableName,
      }
    : smartFieldSuggestion
      ? {
          value: smartFieldSuggestion.id,
          inputValue: smartFieldSuggestion.readableName,
        }
      : {
          value: null,
          inputValue: '',
        };
};

export const toSplitDS = ({
  bookingId,
  ...b
}: DocumentApprovalFormValues['bookings']['0']): SplitDS => ({
  ...b,
  id: bookingId,
  artistSocialInsuranceCode:
    b.artistSocialInsuranceCode as ArtistSocialInsuranceCode,
  dueDate: b.dueDate ? getDateConverted().dateToDateString(b.dueDate) : null,
  generalLedgerAccount: b.generalLedgerAccount?.value
    ? b.generalLedgerAccount
    : undefined,
  costObject: b.costObject?.value ? b.costObject : undefined,
  costCenter: b.costCenter?.value ? b.costCenter : undefined,
  extraCostInfo: b.extraCostInfo?.value ? b.extraCostInfo : undefined,
  taxCode: b.taxCode,
  taxPresentation: b.taxPresentation || TaxPresentation.Gross,
  amount:
    (b.taxPresentation || TaxPresentation.Gross) === TaxPresentation.Gross
      ? b.amount
      : grossToNet(b.amount ?? 0, b.vatRate ?? 0),
});
