import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';
import { ExportReimbursementItemsTable } from 'views/Integrations/Export/elements/table/reimbursementItems/ExportReimbursementItemsTable';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { PromotionCard } from 'components/NewPromotions';
import { Locale } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import {
  dataDe,
  dataEn,
} from 'views/Integrations/Export/elements/table/reimbursementItems/utils';
import { ExportReimbursementItemsTableColumnKeys } from 'views/Integrations/Export/elements/table/reimbursementItems/types';
import { PromotionWrapper, TableWrapper, Wrapper } from '../Wrapper';

const columnIds: ExportReimbursementItemsTableColumnKeys[] = [
  'reasonExpense',
  'expenseId',
  'reimbursementItemDate',
  'type',
  'titleReimbursement',
  'grossAmount',
] as const;

export const ReimbursementPromotionExport = () => {
  const { reimbursementsPromotionLegacy, reimbursementsPromotionNonLegacy } =
    usePromotionContent();

  const user = useCurrentUser();

  const mockData = user?.locale === Locale.De ? dataDe : dataEn;

  const { variant } = useReimbursementsPromotions();

  const promotionVariant =
    variant === 'promotionForEntitlements'
      ? reimbursementsPromotionNonLegacy
      : reimbursementsPromotionLegacy;

  return (
    <Wrapper>
      <TableWrapper>
        <ExportReimbursementItemsTable data={mockData} columnIds={columnIds} />
      </TableWrapper>
      <PromotionWrapper width="34rem" height="24rem">
        <PromotionsProvider promotions={[promotionVariant]}>
          <PromotionCard promotion={promotionVariant} closable={false} />
        </PromotionsProvider>
      </PromotionWrapper>
    </Wrapper>
  );
};
