import {
  Box,
  Flex,
  useTheme,
  CustomEmptyStateProps,
  SelectionOptions,
} from '@candisio/design-system';
import {
  defaultSortByFields,
  DocumentsTable,
} from 'components/DocumentsTable/DocumentsTable';
import {
  ColumnWidths,
  DocumentsTableData,
  DocumentTableColumnKeys,
} from 'components/DocumentsTable/types';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { AnimatePresence } from 'framer-motion';
import { useLinkDocumentAndTransactionsMutation } from 'generated-types/graphql.types';
import { GQLError } from 'gql';
import { useLocalSortAndFilters } from 'hooks/table/useLocalSortAndFilters';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShowError } from 'utils/error_message';
import { validationErrorsExtractor } from 'utils/forms';
import { documentHistoryQuery } from 'views/queries';
import { getCardIssuerTransactionById } from '../gql';
import { AssociateBanner } from './AssociateBanner';
import { DocumentsWithoutTransactionEmptyState } from './DocumentsWithoutTransactionEmptyState';
import { useDocumentWithoutTransactionDataDeprecated } from './hooks/useDocumentWithoutTransactionDataDeprecated';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useDocumentWithoutTransactionData } from './hooks/useDocumentWithoutTransactionData';
import { SortingRule } from 'react-table';
import { Filters } from 'react-table';

export interface DocumentsWithoutTransactionContainerProps {
  transactionId: string;
  closeModal: () => void;
}

export const DocumentsWithoutTransactionContainer = ({
  transactionId,
  closeModal,
}: DocumentsWithoutTransactionContainerProps) => {
  const [enableNewIndexInArchiveViewFF, consistentSortAndFilteringFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.enableNewIndexInArchiveView,
      FEATURE_FLAGS.consistentSortAndFiltering,
    ]);

  const { filters, sortBy, onSort, onFilter } =
    useLocalSortAndFilters<DocumentsTableData>();

  return enableNewIndexInArchiveViewFF && consistentSortAndFilteringFF ? (
    <DocumentsWithoutTransaction
      transactionId={transactionId}
      closeModal={closeModal}
      onFilter={onFilter}
      onSort={onSort}
      filters={filters}
      sortBy={sortBy}
    />
  ) : (
    <DocumentsWithoutTransactionDeprecated
      transactionId={transactionId}
      closeModal={closeModal}
      onFilter={onFilter}
      onSort={onSort}
      filters={filters}
      sortBy={sortBy}
    />
  );
};

export type DocumentsWithoutTransactionProps =
  DocumentsWithoutTransactionContainerProps & {
    onFilter: (filter: Filters<DocumentsTableData>) => void;
    onSort: (sortBy: SortingRule<DocumentsTableData>[]) => void;
    filters: Filters<DocumentsTableData>;
    sortBy: SortingRule<DocumentsTableData>[];
  };

export const DocumentsWithoutTransaction = ({
  transactionId,
  closeModal,
  onFilter,
  onSort,
  filters,
  sortBy,
}: DocumentsWithoutTransactionProps) => {
  const {
    tableData,
    loadingDocuments,
    onLoadMore,
    hasMore,
    isTableEmpty,
    isTableFiltered,
    filterOptions,
  } = useDocumentWithoutTransactionData({
    filters,
    sortBy,
  });

  return (
    <DocumentsWithoutTransactionComponent
      transactionId={transactionId}
      closeModal={closeModal}
      onFilter={onFilter}
      onSort={onSort}
      documentsWithoutTransactionDataProps={{
        tableData,
        loadingDocuments,
        onLoadMore,
        hasMore,
        isTableEmpty,
        isTableFiltered,
        filterOptions,
      }}
    />
  );
};

export const DocumentsWithoutTransactionDeprecated = ({
  filters,
  sortBy,
  transactionId,
  closeModal,
  onFilter,
  onSort,
}: DocumentsWithoutTransactionProps) => {
  const {
    tableData,
    loadingDocuments,
    onLoadMore,
    hasMore,
    isTableEmpty,
    isTableFiltered,
    filterOptions,
  } = useDocumentWithoutTransactionDataDeprecated({
    filters,
    sortBy,
  });

  return (
    <DocumentsWithoutTransactionComponent
      transactionId={transactionId}
      closeModal={closeModal}
      onFilter={onFilter}
      onSort={onSort}
      documentsWithoutTransactionDataProps={{
        tableData,
        loadingDocuments,
        onLoadMore,
        hasMore,
        isTableEmpty,
        isTableFiltered,
        filterOptions,
      }}
    />
  );
};

export type DocumentsWithoutTransactionComponentProps =
  DocumentsWithoutTransactionContainerProps & {
    onFilter: (filter: Filters<DocumentsTableData>) => void;
    onSort: (sortBy: SortingRule<DocumentsTableData>[]) => void;
    documentsWithoutTransactionDataProps:
      | ReturnType<typeof useDocumentWithoutTransactionData>
      | ReturnType<typeof useDocumentWithoutTransactionDataDeprecated>;
  };

export const DocumentsWithoutTransactionComponent = ({
  transactionId,
  closeModal,
  documentsWithoutTransactionDataProps: archiveDocumentsDataProps,
  onFilter,
  onSort,
}: DocumentsWithoutTransactionComponentProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const showError = useShowError();
  const { success, error } = useToastMessage();
  const { space } = useTheme();
  const counterQueries = useCounterQueries();
  const [selectedDocument, setSelectedDocument] =
    useState<DocumentsTableData | null>(null);
  const { showDocumentTags: shouldUseNewEs } = useEcm();
  const [consistentSortAndFilteringFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.consistentSortAndFiltering,
  ]);

  const [linkTransactionsWithDocument, { loading }] =
    useLinkDocumentAndTransactionsMutation({
      refetchQueries: [
        {
          query: getCardIssuerTransactionById,
          variables: { id: transactionId },
        },
        {
          query: documentHistoryQuery,
          variables: { id: selectedDocument?.id },
        },
        ...counterQueries,
      ],
    });

  const {
    tableData,
    loadingDocuments,
    onLoadMore,
    hasMore,
    isTableEmpty,
    isTableFiltered,
    filterOptions,
  } = archiveDocumentsDataProps;

  const showTableLoadingSpinner = loadingDocuments && tableData.length < 1;

  const handleLinkTransactionWithDocument = async () => {
    try {
      if (!selectedDocument?.id) {
        return;
      }

      const result = await linkTransactionsWithDocument({
        variables: {
          documentId: selectedDocument?.id,
          transactionIds: [transactionId],
        },
      });

      if (result.data?.linkDocumentAndTransactions) {
        success(t('transactionAssociation.documentsModal.toastMessageSucces'));
      } else {
        error('There was an error');
      }
    } catch (error) {
      showError(error as GQLError);

      return validationErrorsExtractor(error);
    }

    closeModal();
  };

  const selectionOption: SelectionOptions<DocumentsTableData> = {
    onSelectionRowChanged: documents => {
      setSelectedDocument(documents[0]);
    },
    selectedRowsIds: selectedDocument ? [selectedDocument.id] : [],
  };

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <DocumentsWithoutTransactionEmptyState
        isTableFiltered={isTableFiltered}
        isDocumentSelected={!!selectedDocument}
        isTableEmpty={isTableEmpty}
        resetFilters={resetFilters}
      />
    ) : null;

  const columnWidths: ColumnWidths = {
    contact: 'auto',
    fileName: space.space256,
  };

  const showLoadingMoreSpinner = tableData.length > 1;

  const infiniteScrollOptions = {
    dataLength: tableData?.length,
    hasMore: hasMore,
    showLoadingMoreSpinner: !showTableLoadingSpinner && showLoadingMoreSpinner,
    next: onLoadMore,
  };

  const selectedDocumentStatus = selectedDocument?.status?.status;

  const sortByFields: DocumentTableColumnKeys[] =
    consistentSortAndFilteringFF && shouldUseNewEs
      ? [...defaultSortByFields, 'contact', 'invoiceNumber']
      : defaultSortByFields;

  return (
    <Box height="100%" overflow="hidden">
      <Flex direction="column" height="100%" padding="space16 space32 space32">
        <DocumentsTable
          columns={[
            'status',
            'contact',
            'grossAmount',
            'invoiceNumber',
            'invoiceDate',
            'fileName',
          ]}
          columnWidths={columnWidths}
          customEmptyState={emptyState}
          data={tableData}
          filterOptions={filterOptions}
          infiniteScrollOptions={infiniteScrollOptions}
          isLoading={showTableLoadingSpinner}
          isSingleSelect
          onEndReached={onLoadMore}
          onFilter={onFilter}
          onSort={onSort}
          selectionOptions={selectionOption}
          sortByFields={sortByFields}
        />
      </Flex>
      <AnimatePresence>
        {selectedDocument && (
          <AssociateBanner
            selectedDocumentStatus={selectedDocumentStatus}
            isAssociating={loading}
            onAssociate={handleLinkTransactionWithDocument}
          />
        )}
      </AnimatePresence>
    </Box>
  );
};
