import { Box, Button, Flex, Grid, Image, Text } from '@candisio/design-system';
import { Locale } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { ReactNode } from 'react';

interface WrapperProps {
  children: ReactNode;
}

export const Wrapper = ({ children }: WrapperProps) => {
  return (
    <Grid
      background="gray100"
      borderRadius="medium"
      gap="space8"
      hover={{ boxShadow: 'elevatedShadow4' }}
      padding="space16"
      transition="box-shadow 300ms ease-in"
      width="15rem"
    >
      {children}
    </Grid>
  );
};

export const HeadingDivider = () => (
  <Box borderRadius="0.25rem" color="blue400" border="0px solid" width="3rem" />
);

interface WidgetTypeSelectionBoxProps {
  title: string;
  cta: string | JSX.Element;
  image: string;
  children: ReactNode;
}

export const WidgetTypeSelectionBox = ({
  title,
  children,
  image,
  cta,
}: WidgetTypeSelectionBoxProps) => {
  const user = useCurrentUser();

  return (
    <Wrapper>
      <Flex gap="space16" direction="column">
        <TypeSelectionTitle>{title}</TypeSelectionTitle>

        <Flex justifyContent="left">
          <Flex borderRadius="small" boxShadow="elevatedShadow1">
            <Image width="12rem" src={image} alt="" />
          </Flex>
        </Flex>
        <Flex justifyContent="left">
          <Text
            color="gray800"
            textAlign="left"
            height={user?.locale === Locale.De ? '66px' : 'auto'}
          >
            {cta}
          </Text>
        </Flex>
        {children}
      </Flex>
    </Wrapper>
  );
};

export const TypeSelectionEmptyButtonGroup = ({
  onCreate,
  onImport,
  text,
  canCreate,
}: {
  onCreate: () => void;
  onImport: () => void;
  canCreate: boolean;
  text: {
    createCTA: string;
    importCTA: string;
    askAdmin: string;
  };
}) => {
  const { importCTA, createCTA, askAdmin } = text;

  return (
    <Flex justifyContent="center" style={{ marginTop: 'auto' }}>
      {canCreate ? (
        <Flex direction="column" gap="space8">
          <Button variant="secondary" onClick={onCreate}>
            {createCTA}
          </Button>
          <Button variant="tertiary" onClick={onImport}>
            {importCTA}
          </Button>
        </Flex>
      ) : (
        <Text textAlign="center" color="gray600">
          {askAdmin}
        </Text>
      )}
    </Flex>
  );
};

interface TypeSelectionTitleProps {
  children: ReactNode;
}

export const TypeSelectionTitle = ({ children }: TypeSelectionTitleProps) => {
  return (
    <Flex justifyContent="left">
      <Text color="gray800" fontSize="large" textAlign="left">
        {children}
      </Text>
    </Flex>
  );
};

interface TypeSelectionEmptyDetailsProps {
  children: ReactNode;
}

export const TypeSelectionEmptyDetails = ({
  children,
}: TypeSelectionEmptyDetailsProps) => {
  return (
    <Flex justifyContent="left">
      <Text textAlign="left" color="gray600">
        {children}
      </Text>
    </Flex>
  );
};
