import { Button, Flex, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export const FeedbackCard = () => {
  const [t] = useTranslation();

  return (
    <>
      <Flex borderRadius="8px" background="white" padding="0px" height="172px">
        <Flex
          direction="column"
          gap="space16"
          justifyContent="center"
          padding="space24"
          fontSize="large"
        >
          <Flex direction="column" gap="space4" maxWidth="721px">
            <Text fontWeight="semibold">
              {t('settings:mobileApplication.feedbackCard.title')}
            </Text>
            <Text color="gray800" fontSize="small">
              {t('settings:mobileApplication.feedbackCard.description')}
            </Text>
          </Flex>
          <Button
            width="max-content"
            id="mobile-app-promo-feedback-button"
            icon="megaphone"
            variant="secondary"
            color="blue"
          >
            {t('common.feedbackButton')}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
