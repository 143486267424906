import { Grid } from '@candisio/design-system';
import { ContactNameField } from 'views/Contacts/ContactDetails/ContactForm/ContactTypeFields/ContactNameField';
import { ContactTypeField } from 'views/Contacts/ContactDetails/ContactForm/ContactTypeFields/ContactTypeField';

export interface ContactTypeFieldsProps {
  disabled?: boolean;
  isEmployeeContactTypeHidden: boolean;
}

/** Set of fields for entering extra contact data based on contact type */
export const ContactTypeFields = ({
  disabled,
  isEmployeeContactTypeHidden,
}: ContactTypeFieldsProps) => {
  return (
    <Grid gap="space16">
      <ContactTypeField
        disabled={disabled}
        isEmployeeContactTypeHidden={isEmployeeContactTypeHidden}
      />
      <ContactNameField disabled={disabled} />
    </Grid>
  );
};
