import { Box, Grid } from '@candisio/design-system';
import { SearchField } from 'components/SearchField/SearchField';
import {
  Configuration,
  ConfigurationsMenu,
} from 'components/Table/Configurations/ConfigurationsMenu';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ReimbursementTableToolbarProps {
  search: string;
  configurations: Configuration[];
  isLoading?: boolean;
  isSearchReadOnly?: boolean;
  onUpdateConfigurations: (configurations: Configuration[]) => void;
  onResetTableConfigurations: () => void;
  onSearch: (querySearch: string) => void;
}

export const ReimbursementTableToolbar = ({
  configurations,
  search,
  isLoading,
  isSearchReadOnly = false,
  onSearch,
  onResetTableConfigurations,
  onUpdateConfigurations,
}: ReimbursementTableToolbarProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Grid
      templateColumns="minmax(12rem, 24rem) 1fr"
      borderTopRadius="basic"
      overflow="hidden"
      alignItems="center"
      background="white"
      borderBottom="1px solid gray200"
      padding="space12"
    >
      <SearchField
        clearTooltip={t('table.search.clearTooltip')}
        placeholder={t('table.search.placeholder')}
        value={search}
        readOnly={isSearchReadOnly}
        onChange={onSearch}
      />
      <Box justifySelf="right">
        <ConfigurationsMenu
          isLoading={isLoading}
          configurationItems={configurations}
          onUpdateConfigurations={onUpdateConfigurations}
          onResetConfigurations={onResetTableConfigurations}
        />
      </Box>
    </Grid>
  );
};
