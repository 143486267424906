import { useContext, useEffect } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { AuthService } from 'services/AuthService';
import { OrganizationHistoryService } from 'services/OrganizationHistoryService';
import { OrganizationContext } from './OrganizationContext';

export const useSelectOrganization = (organizationSlug: string) => {
  const history = useHistory();
  const { selectedOrganization, setSelectedOrganization } =
    useContext(OrganizationContext);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const availableRealms = AuthService.getAvailableRealms();
    OrganizationHistoryService.reconcileOrganizations(availableRealms);
    if (!availableRealms.length) {
      return;
    }

    // do we have a realm matching this slug?
    let realm = availableRealms.find(r => r.realm === organizationSlug);

    if (!realm) {
      // redirect to most recently visited realm’s slug

      const lastSlug = OrganizationHistoryService.getLatestOrganization();
      const lastRealm =
        lastSlug && availableRealms.find(r => r.realm === lastSlug);

      realm = lastRealm || availableRealms[0];
      history.replace(`/${realm.realm}`);
    }

    setSelectedOrganization(realm);
  }, [
    history,
    organizationSlug,
    selectedOrganization,
    setSelectedOrganization,
  ]);
};
