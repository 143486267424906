import { useCallback, useContext, useEffect } from 'react';
import { getPaginatedExportsQuery } from 'views/Integrations/Export/toolkit/queries';
import { ExportStatus } from '../../../../../generated-types/graphql.types';
import { usePagination } from '../../../../../providers/GraphQLProvider/Pagination/usePagination';
import { ExportQuery, paginationQueryOptions } from '../../consts';
import { ExportContext } from '../../Context';
import { useExportDataGigaFactory } from './useExportDataGigaFactory';

export const useIsExporting = () => {
  const state = useContext(ExportContext);
  const paginationResponse = usePagination<ExportQuery>(
    getPaginatedExportsQuery,
    '_exports',
    paginationQueryOptions
  );

  const {
    refetch: { all: refetchAll },
  } = useExportDataGigaFactory(state);

  const { data, refetch } = paginationResponse;

  const exports = data?._exports.node ?? [];
  const exporting =
    exports.find(({ status }) => status === ExportStatus.Exporting) ?? null;

  const refetchAllExportEntities = useCallback(() => {
    refetchAll.forEach(refetch => refetch());
  }, [refetchAll]);

  useEffect(() => {
    if (exporting === null) {
      refetchAllExportEntities();
    }
  }, [exporting, refetchAllExportEntities]);

  return {
    isExporting: !!exporting,
    refetchAllExportEntities,
    refetchExporteds: refetch,
  };
};
