import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  ContactImportStatus,
  useContactsImportHistoryByIdQuery,
} from 'generated-types/graphql.types';
import { useDatevSettings } from 'hooks/useDatevSettings';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { usePolling } from 'hooks/usePolling';
import { useDatev } from 'orgConfig/datev';
import { useCallback, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { zodResolver } from 'utils/zodFormValidation';
import { contactCanBeImported } from 'views/Contacts/ContactImport/consts';
import { contactImportFormErrorMessages } from 'views/Contacts/ContactImport/contactImportFormErrorMessages';
import {
  ContactImportFormValues,
  contactImportFormSchema,
} from 'views/Contacts/ContactImport/contactImportFormSchema';
import {
  CONTACT_IMPORT_ACTION,
  contactImportInitialState,
  contactImportReducer,
} from 'views/Contacts/ContactImport/contactImportReducer';
import { parseContactCSV } from 'views/Contacts/ContactImport/parseContactCSV';
import { FilterState } from 'views/Contacts/ContactImport/types';
import { useImportAndUpdateContacts } from 'views/Contacts/ContactImport/useImportAndUpdateContacts';
import { usePaymentConditions } from 'views/Contacts/ContactImport/usePaymentConditions';
import { CONTACT_ROUTE_HASH } from 'views/Contacts/types';
import { useRefetchContactsHook } from 'views/queries/contacts';

export enum ImportTrackingActions {
  START_IMPORT = 'start_import',
  IMPORT_SUCCESS = 'import_success',
  IMPORT_FAIL = 'import_fail',
}

export const useContactImport = () => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();

  const form = useForm<ContactImportFormValues>({
    mode: 'onSubmit',
    resolver: zodResolver({
      zodSchema: contactImportFormSchema,
      errorMessages: contactImportFormErrorMessages,
    }),
    defaultValues: {},
  });

  const history = useHistory();

  const visible = history.location.hash === CONTACT_ROUTE_HASH.import;

  const [isParsingCSV, setIsParsingCSV] = useState(false);

  const [importContacts_new] = useImportAndUpdateContacts();

  const [contactImportState, dispatchContactsImportAction] = useReducer(
    contactImportReducer,
    contactImportInitialState
  );

  const numberOfContactsToBeImported =
    contactImportState.parsedContacts.filter(contactCanBeImported).length;

  const { refetchContactData, evictPaginationResults } =
    useRefetchContactsHook();

  const { bdsConnected } = useDatev(); // BDS-checked

  const integrationSetting = useIntegrationSettings();
  const { data: datevSettings } = useDatevSettings();

  const { bdsBought } = useDatev();

  const glaLength =
    datevSettings?.new_datevSettings?.chartOfAccount?.accountLength ??
    undefined;

  const { existingPaymentConditionNumbers } = usePaymentConditions();

  const closeModal = useCallback(() => {
    history.push({ hash: '' });
  }, [history]);

  const handleAsyncSubmit = useCallback(async () => {
    const selectedFile = form.getValues('file') as File;

    if (!selectedFile) {
      return;
    }

    setIsParsingCSV(true);

    dispatchContactsImportAction({
      type: CONTACT_IMPORT_ACTION.SHOW_PREVIEW,
    });

    try {
      const parsedContacts = await parseContactCSV({
        file: selectedFile,
        integration: integrationSetting,
        bdsConnected,
        bdsBought,
        existingPaymentConditionNumbers,
        glaLength,
      });

      setIsParsingCSV(false);
      dispatchContactsImportAction({
        type: CONTACT_IMPORT_ACTION.SET_CONTACTS,
        payload: { parsedContacts, selectedFile },
      });
    } catch (e) {
      form.setError('file', {
        message: t(
          'contacts:import.fileSelectionStep.encounteredErrorInFileReading'
        ),
      });
      dispatchContactsImportAction({
        type: CONTACT_IMPORT_ACTION.RESET,
      });
      setIsParsingCSV(false);
      if (e instanceof Error) {
        error(e.toString());
      }
    }
  }, [
    form,
    integrationSetting,
    bdsConnected,
    bdsBought,
    existingPaymentConditionNumbers,
    glaLength,
    t,
    error,
  ]);

  const handleSubmit = form.handleSubmit(handleAsyncSubmit);

  const handleImport = useCallback(async () => {
    // here we call the hook to invoke the mutation for saving the contact in the backend
    if (contactImportState.parsedContacts && contactImportState.selectedFile) {
      dispatchContactsImportAction({
        type: CONTACT_IMPORT_ACTION.START_IMPORT,
        payload: { startTimestamp: Date.now() },
      });

      const { importId } = await importContacts_new(
        contactImportState.parsedContacts.map(({ id, ...c }) => c),
        contactImportState.selectedFile
      );

      if (importId === null) {
        closeModal();

        return;
      }

      dispatchContactsImportAction({
        type: CONTACT_IMPORT_ACTION.START_POLLING,
        payload: { importRecordId: importId },
      });
    }
  }, [
    contactImportState.parsedContacts,
    contactImportState.selectedFile,
    importContacts_new,
    closeModal,
  ]);

  const onUpdateFilter = (filters: FilterState | null) => {
    dispatchContactsImportAction({
      type: CONTACT_IMPORT_ACTION.UPDATE_FILTERS,
      payload: { filters },
    });
  };

  const {
    data: importHistoryData,
    startPolling,
    stopPolling,
  } = useContactsImportHistoryByIdQuery({
    variables: { id: contactImportState.importRecordId ?? '' },
    skip: !contactImportState.importRecordId,
    // we have to set notifyOnNetworkStatusChange to true to fire onCompleted callback after each poll
    // otherwise onCompleted is being fired only once. First time the query is made. https://github.com/apollographql/apollo-client/issues/5531
    notifyOnNetworkStatusChange: true,
    onCompleted: async ({ contactsImportHistoryById }) => {
      if (
        contactImportState.importing &&
        contactImportState.startTimestamp !== null &&
        contactsImportHistoryById?.status === ContactImportStatus.Processed
      ) {
        const errorCount = contactsImportHistoryById.importErrors?.length ?? 0;
        const successCount =
          contactsImportHistoryById.importedContacts?.length ?? 0;

        const TOAST_MESSAGE_DURATION = 8;

        evictPaginationResults();

        await refetchContactData();

        closeModal();

        if (successCount && !errorCount) {
          success(
            t('contacts:import.actions.importedSuccess', {
              count: successCount,
            }),
            TOAST_MESSAGE_DURATION
          );
        } else if (successCount && errorCount) {
          success(
            t('contacts:import.actions.importedSuccessWithFailureFirst', {
              count: successCount,
            }) +
              ' ' +
              t('contacts:import.actions.importedSuccessWithFailureSecond', {
                count: errorCount,
              }),
            TOAST_MESSAGE_DURATION
          );
        } else if (errorCount && !successCount) {
          error(
            t('contacts:import.actions.importedFailure', { count: errorCount }),
            TOAST_MESSAGE_DURATION
          );
        }
      }
    },
  });

  const importStatus =
    importHistoryData?.contactsImportHistoryById?.status ?? null;

  usePolling({
    pollInterval: 5000,
    skip: importStatus !== ContactImportStatus.Processing,
    startPolling,
    stopPolling,
    pollOnlyOnFocus: false, // we want to poll for result even if the window is out of focus because import usually takes longer
  });

  const canImport =
    contactImportState.parsedContacts.some(contactCanBeImported);

  return {
    isParsingCSV,
    form,
    handleSubmit,
    handleImport,
    contactImportState,
    dispatchContactsImportAction,
    closeModal,
    numberOfContactsToBeImported,
    importHistoryData,
    onUpdateFilter,
    visible,
    canImport,
  };
};
