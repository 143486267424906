import {
  Card,
  Icon,
  Flex,
  Grid,
  Button,
  Link,
  Text,
} from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useContactImportErrorsPopup } from './useContactImportErrorsPopup';

interface ErrorImportPopupProps {
  organizationSlug?: string;
}

export const ContactImportErrorsPopup = ({
  organizationSlug,
}: ErrorImportPopupProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CONTACTS);

  const {
    detailsTranslation,
    loading,
    acknowledgeContactsImportHistoryErrors,
    errorFileUrl,
    importedAtText,
    hasError,
  } = useContactImportErrorsPopup();

  if (loading || !hasError) {
    return null;
  }

  const handleCloseButton = async () => {
    await acknowledgeContactsImportHistoryErrors();
  };

  return (
    <Card
      borderRadius="small"
      background="redbg"
      maxWidth="max-content"
      padding="space8"
      height="max-content"
    >
      <Flex justifyContent="space-between" gap="space8">
        <Flex>
          <Icon icon="failCircle" color="red700" size="space40" />
        </Flex>
        <Grid gap="space4">
          <Grid>
            <Text
              fontSize="xsmall"
              lineHeight="paragraph"
              color="gray800"
              width="fit-content"
              title={importedAtText}
            >
              {detailsTranslation}
            </Text>
            <Text
              color="gray800"
              fontSize="basic"
              fontWeight="semibold"
              lineHeight="body"
            >
              {t('importHistory.errorPopup.title')}
            </Text>
          </Grid>
          <Grid gap="space8">
            <Text color="gray800" fontSize="small" lineHeight="160%">
              {t('importHistory.errorPopup.text')}
            </Text>
            <Flex gap="space16">
              <Link
                external
                href={`${errorFileUrl}`}
                icon="arrowDownCircle"
                iconPosition="left"
                fontSize="small"
              >
                {t('importHistory.errorPopup.downloadErrorFileText')}
              </Link>
              <RouterLink
                to={`/${organizationSlug}${Routes.CONTACT_IMPORT_HISTORY}`}
                icon="infoOutline"
                fontSize="small"
              >
                {t('importHistory.errorPopup.goToHistoryText')}
              </RouterLink>
            </Flex>
          </Grid>
        </Grid>
        <Grid gap="space4">
          <Button
            aria-label={t('common.close')}
            icon="close"
            size="medium"
            variant="tertiary"
            height="max-content"
            onClick={handleCloseButton}
          />
        </Grid>
      </Flex>
    </Card>
  );
};
