import { getCardIssuerCardByIdQuery } from 'containers/credit-cards/components/CreditCardsSection/gql';
import { useLinkCardForRecurringPaymentVendorMutation } from 'generated-types/graphql.types';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { LinkCardToVendorFormOutput } from '../CreditCardsInsights/RecurringPayments/RecurringPaymentsModal/utils/linkCardToVendorFormSchema';
import { getVendorSuggestionsForRecurringPaymentsQuery } from '../CreditCardsInsights/gql';
import { vendorSuggestionsQueryVariables } from './useVendorSuggestionsData';

export const useLinkCardForRecurringPaymentVendor = () => {
  const [linkCardForRecurringPaymentVendor, { loading, client }] =
    useLinkCardForRecurringPaymentVendorMutation();

  const { vendorId } = useParams<{ vendorId: string }>();

  return {
    link: async (values: LinkCardToVendorFormOutput) => {
      if (!vendorId) {
        return { status: 'error' };
      }

      const { errors } = await linkCardForRecurringPaymentVendor({
        variables: {
          input: {
            cardId: values.creditCard,
            vendorId,
          },
        },
        awaitRefetchQueries: true,
        onCompleted: () => {
          // It is necessary to evict the cache since we do not know at exactly which point we are on the list and we do not know what to refetch
          client.cache.evict({ fieldName: 'getVendorsForRecurringPayments' });
        },
        refetchQueries: () => {
          return [
            {
              query: getVendorSuggestionsForRecurringPaymentsQuery,
              variables: vendorSuggestionsQueryVariables,
            },
            {
              query: getCardIssuerCardByIdQuery,
              variables: { id: values.creditCard },
            },
          ];
        },
      });

      if (errors?.length) {
        return { status: 'error' };
      }

      return { status: 'success' };
    },
    loading,
  };
};
