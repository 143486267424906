import { Button, Grid } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ReplaceCardFormContainer } from './ReplaceCardFormContainer';

interface ReplaceCardLayoutProps {
  onClose: () => void;
}

export const ReplaceCardLayout = ({ onClose }: ReplaceCardLayoutProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid
      gap="space16"
      alignContent="start"
      paddingY="space16"
      templateRows="auto 1fr"
      paddingX="space24"
    >
      <Button
        justifySelf="start"
        icon="arrowLeft"
        variant="tertiary"
        onClick={onClose}
      >
        {t('ccRequestForm.goBackCTA')}
      </Button>
      <ReplaceCardFormContainer />
    </Grid>
  );
};
