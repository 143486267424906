import { Grid, Heading } from '@candisio/design-system';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface FormBlockProps {
  children: ReactNode;
  headingKey: string;
}

export const FormBlock = ({ children, headingKey }: FormBlockProps) => {
  const [t] = useTranslation();

  return (
    <Grid gap="space8">
      <Heading as="h2">{t(headingKey)}</Heading>

      <Grid gap="space16">
        <Grid
          gap="space16"
          background="gray100"
          padding="space24"
          borderRadius="large"
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
