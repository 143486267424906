import { Grid, Text } from '@candisio/design-system';
import { memo } from 'react';

interface ChangedValueProps {
  propertyName: string;
  propertyValue: string;
}

export const ChangedValue = memo(
  ({ propertyName, propertyValue }: ChangedValueProps) => (
    <Grid key={propertyName} paddingTop="space8">
      <Text
        color="gray500"
        lineHeight="paragraph"
        fontWeight="regular"
        fontSize="small"
      >
        {propertyName}
      </Text>
      <Text color="gray800" fontSize="small" lineHeight="paragraph">
        {propertyValue}
      </Text>
    </Grid>
  )
);
