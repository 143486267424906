// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';

enum PARAMS {
  PAGE = 'page',
}

export const getCurrentPage = () => {
  const params = qs.parse(window.location.search);
  const page = params[PARAMS.PAGE] as string;

  return (page && parseInt(page)) || 1;
};
