import {
  Box,
  Button,
  Card,
  Grid,
  Image,
  Tag,
  Text,
  useTheme,
} from '@candisio/design-system';
import { Locale } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import contractsDE from 'views/Dashboard/components/media/Contracts-DE.svg';
import contractsEN from 'views/Dashboard/components/media/Contracts-EN.svg';
import { ContractsAdvertModal } from './ContractsAdvertModal';

export const thumbnails: Record<Locale, string> = {
  DE: contractsDE,
  EN: contractsEN,
} as const;

export const ContractsAdvertCard = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);
  const { colors } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const thumbnail = thumbnails[locale];

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Card background="gray50">
        <Grid
          right="space30"
          alignItems="center"
          gap="space40"
          templateColumns="auto minmax(310px, 1fr)"
        >
          <Image src={thumbnail} height="240px" alt="advert thumbnail" />
          <Grid gap="space16">
            <Tag color="purple" variant="secondary">
              {t('promo.tag')}
            </Tag>
            <Box fontSize="xxlarge" lineHeight="paragraph">
              <Text fontWeight="semibold">{t('promo.dashboard.header')}</Text>
              <Text>{t('promo.dashboard.description')}</Text>
            </Box>
            <Grid
              autoFlow="column"
              gap="space16"
              width="max-content"
              justifyContent="start"
              alignItems="center"
            >
              <Button color="promo" width="100%" onClick={handleOpen}>
                {t('promo.dashboard.pryCTA')}
              </Button>
              <Button
                as="a"
                variant="secondary"
                target="_blank"
                href={t('promo.dashboard.demoLink')}
              >
                {t('promo.dashboard.secCTA')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <ContractsAdvertModal isOpen={isOpen} onClose={handleClose} />
    </>
  );
};
