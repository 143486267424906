import { Grid, Separator, Text } from '@candisio/design-system';

export const CrossLinedText = ({ text }: { text: string }) => {
  return (
    <Grid
      autoFlow="column"
      alignItems="center"
      gap="space16"
      templateColumns="auto 1fr"
    >
      <Text fontSize="large" color="gray600">
        {text}
      </Text>
      <Separator color="gray300" />
    </Grid>
  );
};
