import type { AvailableEcmDocumentFiltersKeys } from 'components/EcmDocumentsTable/constants';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useDeferredValue, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_SORT_BY,
  DOCUMENT_TYPE_TRANSLATION_MAP,
  DocumentTypesSortBy,
  DocumentTypesSortByValues,
} from './consts';
import { useDocumentTypesData } from './useDocumentTypesData';
import { searchByName, sortByMapper } from './utils';

export function useDocumentTypesList() {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const sortMenuItems = [
    {
      id: DocumentTypesSortBy.active,
      label: t('documentTypes.sort.active'),
    },
    {
      id: DocumentTypesSortBy.name,
      label: t('documentTypes.sort.name'),
    },
    {
      id: DocumentTypesSortBy.documentCount,
      label: t('documentTypes.sort.documentCount'),
    },
  ] as const;

  const sortMenuItemsMap = sortMenuItems.reduce(
    (acc, item) => {
      acc[item.id] = item.label;

      return acc;
    },
    {} as Record<DocumentTypesSortByValues, string>
  );

  const [sortBy, setSortBy] =
    useState<DocumentTypesSortByValues>(DEFAULT_SORT_BY);

  const onSortBy = (value: DocumentTypesSortByValues) => {
    setSortBy(value);
  };

  const [query, setQuery] = useState('');
  const deferredQuery = useDeferredValue(query);
  const onSearchFilter = (filter: string) => {
    setQuery(filter);
  };

  const onSearchReset = () => {
    setQuery('');
  };

  const searchPlaceholder = t('documentTypes.searchPlaceholder');
  const response = useDocumentTypesData();

  const data = useMemo(() => {
    if (!response.data) {
      return [];
    }

    const { ecmDocumentTypeSettings } = response.data;
    const filteredDocumentTypes = ecmDocumentTypeSettings.filter(
      ({ name }) => name in DOCUMENT_TYPE_TRANSLATION_MAP
    );

    const searchResult = filteredDocumentTypes.filter(
      searchByName(deferredQuery)
    );

    const sortResult = searchResult.sort(sortByMapper(sortBy));

    return sortResult;
  }, [deferredQuery, response.data, sortBy]);

  const organizationSlug = useOrganizationId();
  const viewDocumentsLinkPrefix = `/${organizationSlug}${Routes.ECM_DOCUMENTS}?${DOCUMENT_TYPE_FILTER_NAME}=`;

  return {
    data,
    isLoading: response.loading,
    sortMenuItems,
    sortMenuItemsMap,
    sortBy,
    onSortBy,
    query,
    onSearchFilter,
    onSearchReset,
    searchPlaceholder,
    viewDocumentsLinkPrefix,
  };
}

const DOCUMENT_TYPE_FILTER_NAME: AvailableEcmDocumentFiltersKeys =
  'documentType';
