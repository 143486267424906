import { Tooltip, useTooltip } from '@candisio/design-system';
import { HookFormCheckboxField } from 'components/HookFormFields/HookFormCheckboxField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const AcquisitionReverseCheckbox = () => {
  const { t } = useTranslation(LOCALE_NAME_SPACE.TAX_CODES);

  // Should be moved to DS
  const { isOpen, triggerRef, triggerProps, tooltipRef, tooltipProps } =
    useTooltip({
      placement: 'top',
    });

  return (
    <>
      <div ref={triggerRef} {...triggerProps}>
        <HookFormCheckboxField
          name="isAcquisitionReverse"
          label={t('form.acquisitionReverse.label')}
          readOnly
        />
      </div>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {t('form.readOnlySyncHint')}
        </Tooltip>
      )}
    </>
  );
};
