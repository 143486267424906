import {
  Button,
  ButtonProps,
  Grid,
  Link,
  Popover,
  Text,
  usePopover,
} from '@candisio/design-system';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CopyToClipboardButton = ({
  copyText,
  ...buttonProps
}: {
  copyText: string;
} & ButtonProps) => {
  const [t] = useTranslation();
  const [copied, setCopied] = useState(false);
  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef, close } =
    usePopover();

  const handleOnClick = (ev: MouseEvent<HTMLButtonElement>) => {
    setCopied(true);
    triggerProps.onClick?.(ev);
    buttonProps.onClick?.(ev);
    void navigator.clipboard.writeText(copyText);

    setTimeout(() => {
      setCopied(false);
      close();
    }, 1000);
  };

  return (
    <>
      <Button
        {...triggerProps}
        ref={triggerRef}
        label={t('common.copyToClipboard')}
        {...buttonProps}
        icon={copied ? 'check' : 'copy'}
        onClick={handleOnClick}
      />
      {isOpen && (
        <Popover {...popoverProps} ref={popoverRef}>
          {t('common.copiedToClipboard')}
        </Popover>
      )}
    </>
  );
};

export const TotpSecretText = ({
  toggleShow,
  totpSecret,
}: {
  totpSecret: string;
  toggleShow: () => void;
}) => {
  const [t] = useTranslation();

  return (
    <Grid gap="space8">
      <Text>
        {t(
          'header.profile.tabs.update.accountSecurity.authenticatorAppCard.modal.enterSecretManuallyInstruction'
        )}
      </Text>
      <Grid
        borderRadius="small"
        background="gray250"
        width="max-content"
        padding="space4"
        gap="space8"
        alignItems="center"
        templateColumns="auto max-content"
      >
        {totpSecret}
        <CopyToClipboardButton
          copyText={totpSecret}
          variant="secondary"
          size="xsmall"
        />
      </Grid>
      <Link width="max-content" as="button" onClick={toggleShow}>
        {t(
          'header.profile.tabs.update.accountSecurity.authenticatorAppCard.modal.showQrCode.cta'
        )}
      </Link>
    </Grid>
  );
};
