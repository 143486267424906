// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { useMemo } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import useReactRouter from 'use-react-router';
import { RoutePath } from './path_generator';

/** @deprecated Please use React Router’s built-in hooks instead */
export const usePath = <
  T extends object = any,
  Q extends object = ReturnType<typeof qs.parse>,
>(
  pathParams?: T
) => {
  const {
    match,
    location: { search },
  } = useReactRouter();

  const pathGenerator = useMemo(
    () => new RoutePath<T>(match.path),
    [match.path]
  );

  const queryParams: Partial<Q> = qs.parse(search, {
    parseBooleans: true,
    parseNumbers: true,
  }) as any;

  return useMemo(() => {
    const url = pathGenerator.stringify(pathParams, match.url);
    const params = pathGenerator.match(match.url);

    return {
      url,
      params,
      queryParams,
      pathGenerator: {
        stringify: (values?: T) => pathGenerator.stringify(values, match.url),
        match: pathGenerator.match,
      },
    };
  }, [match.url, pathGenerator, pathParams, queryParams]);
};
