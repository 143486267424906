import {
  Box,
  ComboBox,
  FieldContainer,
  FieldContainerProps,
  Flex,
  Grid,
  Item,
  mergeProps,
  useLabel,
} from '@candisio/design-system';
import { AvatarStatus } from 'components/AvatarWithStatus/AvatarWithStatus';
import { useHookFormField } from 'components/HookFormFields/useHookFormField';
import { User } from 'generated-types/graphql.types';
import { Key, KeyboardEvent, ReactNode, useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HookFormUsersFieldOption } from './HookFormUsersFieldOption';
import { UserChip } from './UserChip';

export interface HookFormUserFieldItem {
  key: Key;
  children: ReactNode;
  avatarUrl?: string;
  absence?: {
    fromDate: Date;
    note?: string;
    status: AvatarStatus;
    toDate: Date;
    substitute?: Pick<User, '__typename' | 'id' | 'name' | 'avatarUrl'>;
  };
}

export interface HookFormUsersFieldProps<TFormValues extends FieldValues> {
  /** Field name */
  name: FieldPath<TFormValues>;
  /** List of field items */
  defaultItems?: HookFormUserFieldItem[];
  /** Shown when list box is empty */
  emptyListPlaceholder?: string;
  /** Field label */
  label?: string;
  /** Message to display in tooltip */
  message?: string;
  /** Called when field value changes */
  onChange?: (value: string[]) => void;
  /** Called when field should be removed */
  onRemove?: () => void;
  /** Shown when field is empty */
  placeholder?: string;
  /** Is field read only? */
  readOnly?: boolean;
  /** Is adding more values disabled? */
  disabled?: boolean;
  /** Field variant */
  variant?: 'default' | 'error' | 'warning' | 'success';
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean | undefined;
  /** Register input's ref into the hook form, please note that it can have unintended side effects */
  forceInputFieldRef?: boolean;
  dataCy?: string;
  color?: FieldContainerProps['color'];
}

/** A Hook Form field for selecting multiple users */
export const HookFormUsersField = <TFormValues extends FieldValues>({
  defaultItems = [],
  emptyListPlaceholder,
  label,
  message,
  name,
  onChange: onChangeProp,
  onRemove: onClearAll,
  placeholder,
  readOnly: readOnlyProp,
  variant,
  isLoading,
  disabled: disabledProp,
  forceInputFieldRef,
  dataCy,
}: HookFormUsersFieldProps<TFormValues>) => {
  const [t] = useTranslation();

  const [inputValue, setInputValue] = useState('');

  const {
    fieldContainerProps,
    inputProps: { onChange, value, readOnly, ...inputProps },
  } = useHookFormField({
    message,
    name,
    onChange: onChangeProp,
    readOnly: readOnlyProp,
    disabled: disabledProp,
    variant,
    forceInputFieldRef,
  });

  const labelInput = typeof label === 'string' && label ? label : name;

  const { labelProps, fieldProps: labelFieldProps } = useLabel({
    label: labelInput,
    'aria-label': labelInput,
  });

  const values = (value as string[]) ?? [];

  return (
    <FieldContainer
      label={label}
      data-cy={dataCy}
      {...(mergeProps(labelProps, fieldContainerProps) as Omit<
        FieldContainerProps,
        'color'
      >)}
      isLoading={isLoading}
    >
      <Flex
        role="listbox"
        aria-label={label}
        inline
        gap="space8"
        alignItems="center"
        width="100%"
        wrap="wrap"
        {...(mergeProps(labelProps, fieldContainerProps) as Omit<
          FieldContainerProps,
          'color'
        >)}
      >
        {defaultItems.length > 0 &&
          values.length > 0 &&
          values.map((value, index) => (
            <UserChip
              defaultItems={defaultItems}
              index={index}
              key={value}
              onChange={onChange}
              readOnly={readOnly}
              value={value}
              values={values}
            />
          ))}
        {!readOnly && (
          <Grid
            gap={onClearAll ? 'space8' : undefined}
            templateColumns="1fr auto"
            // @TODO DS: ComboBox should accept LayoutProps
            // @TODO DS: ComboBox should be focusable via ref
            flex="1 1 50%"
            // @TODO DS: Fix ComboBox onKeyDown
            onKeyDownCapture={({
              key,
              target,
            }: KeyboardEvent<HTMLDivElement>) => {
              if (
                key === 'Backspace' &&
                (target as HTMLInputElement).value === ''
              ) {
                const newValue = values.slice(0, values.length - 1);
                onChange(newValue);
              }
            }}
          >
            <Box style={{ display: inputProps.disabled ? 'none' : 'initial' }}>
              <ComboBox
                label={label}
                emptyListPlaceholder={emptyListPlaceholder}
                onSelectionChange={value => {
                  if (value !== null) {
                    const newValue = [...values, value as string];
                    onChange(newValue);
                  }

                  setInputValue('');
                }}
                selectedKey={null}
                key={`${disabledProp}`} // Force close dropdown when disabled changes
                placeholder={value?.length ? '' : placeholder}
                defaultItems={defaultItems.filter(
                  ({ key }) => !values.includes(key as string)
                )}
                inputValue={inputValue}
                onInputChange={value => {
                  if (value) setInputValue(value);
                  else setInputValue('');
                }}
                onClear={onClearAll}
                clearLabel={t(
                  'document.requestApproval.inputs.approvalStep.remove'
                )}
                message={fieldContainerProps.message}
                variant={fieldContainerProps.variant}
                showMessageOnFocus={fieldContainerProps.variant === 'error'}
                {...mergeProps(labelFieldProps, inputProps)}
              >
                {({ key, children, absence, avatarUrl }) => (
                  <Item key={key} textValue={children}>
                    <HookFormUsersFieldOption
                      absence={absence}
                      avatarUrl={avatarUrl}
                    >
                      {children}
                    </HookFormUsersFieldOption>
                  </Item>
                )}
              </ComboBox>
            </Box>
          </Grid>
        )}
      </Flex>
    </FieldContainer>
  );
};
