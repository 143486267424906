import {
  Grid,
  Link,
  Tooltip,
  defaultTheme,
  useTooltip,
} from '@candisio/design-system';
import i18next from 'i18next';
import { FieldSources } from 'models';
import { getTranslationContext } from 'orgConfig';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';
import { useNumberFormat } from 'utils/useNumberFormat';

interface FieldCornerProps {
  color?: string;
  confidence?: number | null;
  isModifiedByUser?: boolean;
  initialValue?: any;
  alert?: string | boolean;
  fieldSource?: string | null;
}

const CornerComponent = styled.div<{ color?: string; size?: string }>`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px ${p => p.size || '10px'} ${p => p.size || '10px'} 0px;
  border-color: transparent ${p => p.color || 'transparent'} transparent
    transparent;
  right: 0px;
  top: 0px;
  position: absolute;
`;

const hasTranslation = (
  key: string,
  t: i18next.TFunction,
  options?: i18next.TOptions
): boolean => {
  const translation = t(key, { defaultValue: null, ...options });

  return Boolean(translation);
};

const getFormattedSource = (translationKey: string, t: i18next.TFunction) => {
  return (
    <Link external href={t(`${translationKey}.source.infoUrl`)}>
      {t(`${translationKey}.source.type`, {
        context: getTranslationContext(),
      })}
    </Link>
  );
};

const useGetCornerProps = (
  t: i18next.TFunction,
  source?: string | null,
  color?: string,
  confidence: number | null = null,
  isModifiedByUser?: boolean,
  initialValue?: any,
  alert?: string | boolean
) => {
  const percentFormatter = useNumberFormat({ style: 'percent' });

  if (typeof alert !== 'boolean' && alert) {
    return {
      content: alert,
      color: defaultTheme.colors.red500,
    };
  }

  if (isModifiedByUser) {
    return { content: null, color: null };
  }

  if (
    ![null, undefined, FieldSources.system].includes(source as FieldSources)
  ) {
    const baseKey = `document.requestApproval.fieldRecognition.${source}`;
    if (hasTranslation(baseKey, t, { returnObjects: true })) {
      return {
        color,
        content: (
          <Grid fontSize="small">
            <b>{t(`${baseKey}.title`) as any}</b>
            <div>
              {t(`${baseKey}.source.message`) as any}{' '}
              {getFormattedSource(baseKey, t)}
            </div>
            {confidence !== null ? (
              <span>
                {
                  t(`${baseKey}.confidence`, {
                    confidence: percentFormatter.format(confidence),
                  }) as any
                }
              </span>
            ) : null}
          </Grid>
        ),
      };
    }
  }

  if (confidence !== null && source !== FieldSources.system) {
    return {
      color,
      content: (
        <Grid fontSize="small">
          <b>{t('document.requestApproval.confidenceScore.title') as any}</b>
          {
            t('document.requestApproval.confidenceScore.message', {
              confidence: Math.ceil(100 * confidence),
            }) as any
          }
        </Grid>
      ),
    };
  }

  return { content: null, color: null };
};

/**
 * @deprecated Please use design system components instead
 */
export const FieldCorner = ({
  color,
  confidence,
  isModifiedByUser,
  fieldSource,
  initialValue,
  alert,
}: FieldCornerProps) => {
  const [t] = useTranslation();
  const { content, color: cornerColor } = useGetCornerProps(
    t,
    fieldSource,
    color,
    confidence,
    isModifiedByUser,
    initialValue,
    alert
  );

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  return !!content && cornerColor ? (
    <>
      <CornerComponent
        color={cornerColor}
        {...triggerProps}
        ref={triggerRef}
        data-cy="form-field-corner"
      />
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {content}
        </Tooltip>
      )}
    </>
  ) : null;
};
