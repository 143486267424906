type FieldKeys =
  | 'expenseDate'
  | 'expenseId'
  | 'files'
  | 'reason'
  | 'totalAmount'
  | 'itemStatus'
  | 'invoiceNumber'
  | 'isExpenseExcluded';

type FieldNames = {
  [K in FieldKeys]: `expenses.${number}.${K}`;
};

export const generateGeneralExpenseFormFieldNames = (
  index: number
): FieldNames => ({
  expenseDate: `expenses.${index}.expenseDate`,
  expenseId: `expenses.${index}.expenseId`,
  files: `expenses.${index}.files`,
  reason: `expenses.${index}.reason`,
  totalAmount: `expenses.${index}.totalAmount`,
  itemStatus: `expenses.${index}.itemStatus`,
  invoiceNumber: `expenses.${index}.invoiceNumber`,
  isExpenseExcluded: `expenses.${index}.isExpenseExcluded`,
});
