import {
  Flex,
  Link,
  Paragraph,
  Text,
  CustomEmptyStateProps,
} from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { useDocumentUploadDispatch } from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { InboxDocumentsEmptyStateLayout } from '../InboxDocuments/InboxDocumentsEmptyState/InboxDocumentsEmptyStateLayout';

interface InboxDocumentsEmptyStateProps extends CustomEmptyStateProps {
  isTableEmpty: boolean;
  isTableFiltered: boolean;
}

const TableIsEmpty = () => {
  const { isOnlyApprover } = useUserRoles();
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const dispatch = useDocumentUploadDispatch();
  const handleShowSensitiveModal = () => dispatch('showSensitiveModal');

  return (
    <Flex direction="column" alignItems="center" gap="space16">
      <Paragraph
        textAlign="center"
        width="12rem"
        minWidth="10rem"
        fontSize="basic"
        fontWeight="semibold"
        wordBreak="break-word"
      >
        {t('inbox.sensitiveDocuments.table.emptyState.allDoneInbox')}
      </Paragraph>
      {!isOnlyApprover && (
        <Paragraph textAlign="center">
          <Trans
            i18nKey="inbox.sensitiveDocuments.table.emptyState.upload"
            t={t}
          >
            You can
            <Link as="button" onClick={handleShowSensitiveModal}>
              upload
            </Link>
            new sensitive documents.
          </Trans>
        </Paragraph>
      )}
    </Flex>
  );
};

const TableIsFilteredAndEmpty = ({
  resetFilters,
}: {
  resetFilters: () => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <>
      <Text textAlign="center" whiteSpace="pre-wrap">
        {t('inbox.sensitiveDocuments.table.emptyState.filtered')}
      </Text>
      <Link as="button" onClick={() => resetFilters()}>
        {t('inbox.sensitiveDocuments.table.emptyState.resetFilters')}
      </Link>
    </>
  );
};

export const InboxSensitiveDocumentsEcmEmptyState = ({
  isTableEmpty,
  isTableFiltered,
  resetFilters,
}: InboxDocumentsEmptyStateProps) => {
  const isFilteredAndEmpty = isTableEmpty && isTableFiltered;

  return (
    <InboxDocumentsEmptyStateLayout isTableFiltered={isTableFiltered}>
      {isFilteredAndEmpty ? (
        <TableIsFilteredAndEmpty resetFilters={resetFilters} />
      ) : (
        <TableIsEmpty />
      )}
    </InboxDocumentsEmptyStateLayout>
  );
};
