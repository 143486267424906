import { CustomEmptyStateProps } from '@candisio/design-system';
import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import { UNTAGGED_ID } from 'components/DocumentsTable/constants';
import { availableEcmDocumentFilters } from 'components/EcmDocumentsTable/constants';
import {
  defaultSortByFields,
  EcmDocumentsTable,
  EcmDocumentsTableProps,
} from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { useEcmContractsData } from 'components/EcmDocumentsTable/hooks/useEcmContractsData';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { queryParameter } from 'components/Table/consts';
import { DEFAULT_DATE_FROM } from 'components/Table/Filters/DateRangeFilter/DateRangeFilter';
import { DocumentPreviewDrawer } from 'containers/DocumentPreview/DocumentPreviewDrawer';
import { DocumentTableRowActionsOverlay } from 'containers/DocumentPreview/DocumentTableRowActionsOverlay';
import { ACTIVATION_LOCAL_STORAGE_KEY } from 'containers/DocumentPreview/useDocumentPreviewActivationPopover';
import { DocumentPreviewUpsellDrawer } from 'containers/DocumentPreviewUpsell/DocumentPreviewUpsellDrawer';
import { usePreviewUpsellSeen } from 'containers/DocumentPreviewUpsell/usePreviewUpsellSeen';
import {
  EcmDateFilter,
  EcmDocumentContractStatus,
  DocumentType,
  EcmFilterInput,
  EcmSortInput,
  EcmSortOrder,
} from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useDateConverter } from 'hooks/useDateConverter';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { AppRouteParams, Routes } from 'models';
import moment from 'moment';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider/feature-flag-names';
import { EcmDocumentsPaginationParams } from 'providers/GraphQLProvider/Pagination/useEcmPagination';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { TabView } from 'views/Inbox/models';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';
import { EcmDocumentsEmptyState } from '../EcmDocuments/EcmDocumentsEmptyState';
import { useGetEcmContractsTableConfigs } from './hooks/useGetEcmContractsTableConfigs';
import { DocumentPreviewEventLocations } from 'providers/AnalyticsProvider/events';

export const EcmContracts = () => {
  const org = useFullOrganization();
  const organizationSlug = org?.slug;

  const [tableRowLinkFF, consistentSortAndFilteringFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.tableRowLink,
    FEATURE_FLAGS.consistentSortAndFiltering,
  ]);

  const {
    showDocumentPreview,
    showDocumentPreviewPromo,
    showDocumentTags: shouldUseNewEs,
  } = useEcm();
  const showPreview = showDocumentPreview || showDocumentPreviewPromo;

  const { previewUpsellSeen, markPreviewUpsellSeen } = usePreviewUpsellSeen();

  const [wasActivationSeen] = useLocalStorage(
    ACTIVATION_LOCAL_STORAGE_KEY,
    false
  );

  const isAutoOpened = useRef(wasActivationSeen);

  const [previewDocumentId, setPreviewDocumentId] = useState<string | null>(
    null
  );

  const navigate = useNavigate();
  const { searchParams, updateSearchParam } = useMutateSearchParams();

  const { dateStringToIsoDateFilterFormat } = useDateConverter();

  const { filters, onFilter, sortBy, onSort } =
    useUrlBasedSortAndFilter<EcmDocumentsTableData>({
      availableFilters: availableEcmDocumentFilters,
    });

  const {
    isSavingConfigurations,
    availableDocumentColumnIds,
    configurationsTable,
    isResetPending,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useGetEcmContractsTableConfigs({ filters, sortBy });

  const searchQuery = searchParams.get(queryParameter) ?? '';

  /** For the date filters, we need to split the value
   *  into 'toDate' and 'fromDate', and transform it to ISO format.
   */
  const extractDates = (dateFilter: string[]): EcmDateFilter => {
    const activeFilterValues = dateFilter?.[0];
    const [from, to] = activeFilterValues?.split('-');
    const fromIso = dateStringToIsoDateFilterFormat(from);
    const toIso = dateStringToIsoDateFilterFormat(to);

    return {
      fromDate: fromIso || '',
      toDate: toIso || '',
    };
  };

  const getPath = useCallback(
    ({ id, documentType, cursor }: EcmDocumentsTableData) => {
      if (!organizationSlug) return '';

      const pathname = generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.ECM_CONTRACTS}/:documentId`,
        { organizationSlug, documentId: id }
      );

      if (cursor) searchParams.set('cursor', cursor);
      if (documentType) searchParams.set('documentType', documentType);
      if (searchQuery) searchParams.set(queryParameter, searchQuery);

      return { pathname, search: searchParams.toString() };
    },
    [organizationSlug, searchParams, searchQuery]
  );

  const handleRowClick = (
    documentId: string,
    documentType?: DocumentType,
    cursor?: string
  ) => {
    if (tableRowLinkFF) return;

    const path = getPath({
      id: documentId,
      globalDocumentId: documentId,
      documentType,
      cursor,
    });

    navigate(path);
  };

  const isTableFiltered = filters.length > 0;

  const formattedFilters: EcmFilterInput | undefined = !isTableFiltered
    ? undefined
    : filters.reduce<EcmFilterInput>(
        toFormattedFilters(dateStringToIsoDateFilterFormat, extractDates),
        {}
      );

  const formattedSort: EcmSortInput | undefined =
    sortBy.length > 0
      ? sortBy.map(field => {
          return {
            [field.id]:
              typeof field.desc !== 'boolean'
                ? undefined
                : field.desc
                  ? EcmSortOrder.Desc
                  : EcmSortOrder.Asc,
          };
        })[0]
      : undefined;

  const paginationParams: EcmDocumentsPaginationParams = {
    filter: formattedFilters,
    sort: formattedSort,
  };

  const {
    data,
    handleDebounceSearch,
    hasNoData,
    isLoading,
    onLoadMore,
    selectedContractsCount,
  } = useEcmContractsData({
    params: paginationParams,
    initialSearchQuery: searchQuery,
    previewDocumentId,
    sortBy,
  });

  const customEmptyState = ({ resetFilters }: CustomEmptyStateProps) => {
    return (
      <EcmDocumentsEmptyState
        isTableEmpty={hasNoData}
        isTableFiltered={isTableFiltered}
        resetFilters={resetFilters}
      />
    );
  };

  const handleSearchChange = (searchQuery: string) => {
    handleDebounceSearch(searchQuery);
    updateSearchParam(queryParameter, searchQuery);
  };

  const isDocumentPreviewReady =
    !isLoading && previewDocumentId === null && !!data[0]?.id;

  useEffect(() => {
    if (!isDocumentPreviewReady) return;

    const shouldAutoOpenPreview =
      (showDocumentPreviewPromo && !previewUpsellSeen) ||
      (!showDocumentPreviewPromo && !isAutoOpened.current);

    if (!shouldAutoOpenPreview) return;

    setPreviewDocumentId(data[0].id);
    if (!showDocumentPreviewPromo) isAutoOpened.current = true;
  }, [
    data,
    isDocumentPreviewReady,
    previewUpsellSeen,
    showDocumentPreviewPromo,
  ]);

  const documentPreviewRowOverlay: EcmDocumentsTableProps['rowOverlay'] =
    useMemo(
      () =>
        showPreview
          ? ({ data }) => {
              if (data.selected) return <></>; // TODO: Fix DS type to allow to return null

              return (
                <DocumentTableRowActionsOverlay
                  data={data}
                  onQuickViewClick={data => {
                    setPreviewDocumentId(data.id);
                  }}
                  isSelected={data.id === previewDocumentId}
                />
              );
            }
          : undefined,
      [showPreview, previewDocumentId]
    );

  const openDocument = useCallback(
    ({
      documentId,
      cursor,
    }: {
      documentId: string | null;
      cursor: string | null;
    }): void => {
      if (!documentId) return;

      const params = new URLSearchParams();
      if (cursor) params.set('cursor', cursor);

      const path = `/${organizationSlug}${Routes.ECM_CONTRACTS}/${documentId}?${params}`;

      window.open(path, '_blank');
    },
    [organizationSlug]
  );

  const closePreview = useCallback(() => {
    setPreviewDocumentId(null);
    if (!previewUpsellSeen) {
      markPreviewUpsellSeen();
    }
  }, [markPreviewUpsellSeen, previewUpsellSeen]);

  const cursor = data.find(d => d.id === previewDocumentId)?.cursor ?? null;
  const documentPreviewDrawer = useMemo(() => {
    if (showDocumentPreviewPromo) {
      return (
        <DocumentPreviewUpsellDrawer
          isOpen={!!previewDocumentId}
          closePreviewDrawer={closePreview}
        />
      );
    }

    if (showDocumentPreview) {
      return (
        <DocumentPreviewDrawer
          documentPreviewEventLocation={DocumentPreviewEventLocations.ARCHIVE}
          closePreviewDrawer={closePreview}
          openDocument={() =>
            openDocument({ documentId: previewDocumentId, cursor })
          }
          documentId={previewDocumentId}
        />
      );
    }

    return null;
  }, [
    showDocumentPreviewPromo,
    showDocumentPreview,
    previewDocumentId,
    closePreview,
    openDocument,
    cursor,
  ]);

  const cellWrapper = useMemo(() => {
    if (!tableRowLinkFF) return;

    return getCellWrapperLink(getPath);
  }, [tableRowLinkFF, getPath]);

  const cellStyle = tableRowLinkFF ? () => ({ padding: 'unset' }) : undefined;

  const sortByFields: (keyof EcmDocumentsTableData)[] =
    consistentSortAndFilteringFF && shouldUseNewEs
      ? [
          ...defaultSortByFields,
          'contractType',
          'costCenter',
          'contact',
          'documentNumber',
          'documentName',
        ]
      : defaultSortByFields;

  return (
    <ArchiveViewLayout
      activeTab={TabView.ARCHIVE_ECM_CONTRACTS}
      rightSide={documentPreviewDrawer}
    >
      <EcmDocumentsTable
        context="contracts"
        columns={availableDocumentColumnIds}
        isLoadingConfigurations={isSavingConfigurations || isResetPending}
        data={data}
        isLoading={isLoading}
        defaultFilters={filters}
        defaultSortBy={sortBy}
        searchQuery={searchQuery}
        isTableFiltered={isTableFiltered}
        configurationsTable={configurationsTable}
        selectedDocumentsCount={selectedContractsCount}
        onSearchChange={handleSearchChange}
        onRowClick={handleRowClick}
        onFilter={onFilter}
        onEndReached={onLoadMore}
        onUpdateConfigurations={handleUpdateConfigurations}
        onSort={onSort}
        customEmptyState={customEmptyState}
        onResetTableConfigurations={handleResetTableConfigurations}
        rowOverlay={documentPreviewRowOverlay}
        cellWrapper={cellWrapper}
        getCellStyles={cellStyle}
        sortByFields={sortByFields}
      />
    </ArchiveViewLayout>
  );
};

const toFormattedFilters =
  (
    dateStringToIsoDateFilterFormat: (dateString: string) => string,
    extractDates: (dateFilter: string[]) => EcmDateFilter
  ) =>
  (acc: EcmFilterInput, filter: { id: string; value: unknown }) => {
    const columnId = filter.id;

    switch (columnId) {
      // The column is named 'contact' but the filter for the `getEcmDocuments` query is named 'contactId'.
      case 'contact': {
        acc.contactId = filter.value as Array<string>;

        break;
      }

      case 'contractStatus': {
        const filterValue = filter.value as Array<string>;

        // to filter for "Overdue" contracts, we set "Reminder date"
        // filter ending today and omit "Overdue" from "Status"
        const formattedFilterValue = filterValue.filter(
          value => value !== 'Overdue' && value !== 'Sensitive'
        ) as Array<EcmDocumentContractStatus>;

        if (filterValue.includes('Overdue')) {
          const formattedReminderDates = {
            fromDate: dateStringToIsoDateFilterFormat(
              DEFAULT_DATE_FROM.format(moment.HTML5_FMT.DATE)
            ),
            toDate: dateStringToIsoDateFilterFormat(
              moment().format(moment.HTML5_FMT.DATE)
            ),
          };

          acc.terminationReminderDate = formattedReminderDates;
        }

        if (filterValue.includes('Sensitive')) {
          acc.isSensitive = true;
        }

        // exclude status filter if we're only filtering for "Overdue" or "Sensitive" documents
        if (formattedFilterValue.length > 0) {
          acc.contractStatus = formattedFilterValue;
        }

        break;
      }

      // The column is named 'contractType' but the filter for the `getEcmDocuments` query is named 'documentSubCategory'.
      case 'contractType': {
        acc.documentSubCategory = filter.value as Array<string>;

        break;
      }

      // The column is named 'costCenter' but the filter for the `getEcmDocuments` query is named 'costCenterIds'.
      case 'costCenter': {
        acc.costCenterIds = filter.value as Array<string>;

        break;
      }

      case 'startDate': {
        const filterValue = filter.value as Array<string>;
        acc.startDate = extractDates(filterValue);

        break;
      }

      case 'endDate': {
        const filterValue = filter.value as Array<string>;
        acc.endDate = extractDates(filterValue);

        break;
      }

      case 'terminationDate': {
        const filterValue = filter.value as Array<string>;
        acc.terminationDate = extractDates(filterValue);

        break;
      }

      case 'terminationReminderDate': {
        const filterValue = filter.value as Array<string>;
        acc.terminationReminderDate = extractDates(filterValue);

        break;
      }

      case 'documentType': {
        acc.documentType = filter.value as Array<string>;

        break;
      }

      case 'responsiblePerson': {
        acc.responsiblePerson = filter.value as Array<string>;

        break;
      }

      case 'notifyPerson': {
        acc.notifyPerson = filter.value as Array<string>;

        break;
      }

      case 'tags': {
        // TODO: fix type definition
        // `filter.value` is type `unknown`, so we typecast here
        if ((filter.value as string).includes(UNTAGGED_ID)) {
          acc.tags = [];
        } else {
          acc.tags = filter.value as Array<string>;
        }
      }
    }

    return acc;
  };
