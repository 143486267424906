import { Flex, Grid } from '@candisio/design-system';
import {
  HistoryList,
  ImportHistoryItem,
} from 'components/HistoryList/HistoryList';
import { ImportHistoryLayout } from 'components/ImportHistoryLayout/ImportHistoryLayout';
import { INFO_PANEL_WIDTH } from 'components/InfoPanel/InfoPanel';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { TipCard } from 'views/Archive/Documents/DownloadedCSVModal';
import { useNavigateToCreateReport } from 'views/Archive/Documents/hooks/useNavigateToCreateReport';
import { useDocumentDownloadHistory } from './hooks/useDocumentDownloadHistory';

export const DocumentDownloadHistory = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ARCHIVE);
  const { documentHistoryItems, isLoading } = useDocumentDownloadHistory();

  const { handleNavigateToCreateReport } = useNavigateToCreateReport();
  const handleClick = () => {
    handleNavigateToCreateReport();
  };

  return (
    <Flex height="100%" padding="space40 space32 space32 space32">
      <ImportHistoryLayout
        title={t('download.history.title')}
        titleAs="h1"
        backButtonLabel={t('download.history.backToArchiveLinkText')}
      >
        <Grid templateColumns={`auto ${INFO_PANEL_WIDTH}`} gap="space36">
          <HistoryList
            data={documentHistoryItems as ImportHistoryItem[]}
            variant="downloadHistory"
            emptyState={{
              translation: 'archive:download.history.list.noData',
            }}
            isLoading={isLoading}
            searchPlaceholder={t('search.placeholder')}
            width="50rem"
          />
          <TipCard background="gray250" onClick={handleClick} />
        </Grid>
      </ImportHistoryLayout>
    </Flex>
  );
};
