import {
  ReimbursementError,
  ReimbursementErrorType,
} from 'generated-types/graphql.types';

const EXCLUDED_ERROR_TYPE_FIELD = 'totalAmount.amount';

export const isNotRelevantErrorForCase = (errorField: ReimbursementError) =>
  errorField.field !== EXCLUDED_ERROR_TYPE_FIELD &&
  !errorField.errors.includes(ReimbursementErrorType.InvalidArrayContent) &&
  !errorField.errors.includes(ReimbursementErrorType.NotFound);
