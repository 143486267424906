import { xor } from 'lodash';
import { forwardRef, ReactNode, useMemo, useState } from 'react';
import { useId } from 'react-aria';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button } from '../../../Atoms/Button';
import { Checkbox } from '../../../Atoms/Checkbox';
import { Flex } from '../../../Atoms/Flex';
import { Grid } from '../../../Atoms/Grid';
import { Popover, PopoverProps } from '../../../Atoms/Popover';
import { ScrollBox } from '../../../Atoms/ScrollBox';
import { Spinner } from '../../../Atoms/Spinner';
import { TextField } from '../../TextField';
import { FilterOption } from '../types';
import { PinnedOption } from './PinnedOption';

export const MIN_NUMBER_OF_FILTERS_TO_SHOW_SEARCH = 10;
const PER_PAGE = 20;
export interface FilterPopoverProps extends PopoverProps {
  filterValue: string[];
  onApply: (filters: string[]) => void;
  onReset: () => void;
  options: FilterOption[];
  filterInfoText?: ReactNode;
  pinnedOption?: FilterOption;
  resetFilterButton?: string;
  applyFilterButton?: string;
  searchFieldPlaceholder?: string;
  isLoading?: boolean;
}

export const FilterPopover = forwardRef<HTMLDivElement, FilterPopoverProps>(
  (
    {
      options,
      onReset,
      onApply,
      filterValue = [],
      filterInfoText,
      pinnedOption,
      applyFilterButton,
      resetFilterButton,
      searchFieldPlaceholder,
      isLoading,
      ...restProps
    },
    ref
  ) => {
    const [visibleOptionsCount, setVisibleOptionsCount] = useState(PER_PAGE);
    const [currentFilters, setCurrentFilters] = useState<string[]>(filterValue);
    const [searchStr, setSearchStr] = useState('');

    const sortedSelectedOptions = useMemo(() => {
      return options
        .filter(
          option =>
            filterValue.includes(option.id) &&
            option.label.toLowerCase().includes(searchStr.toLowerCase())
        )
        .sort((a, b) => a.label.localeCompare(b.label));
    }, [filterValue, options, searchStr]);

    const sortedOptionsWithoutSelected = useMemo(() => {
      return options
        .filter(
          option =>
            !filterValue.includes(option.id) &&
            option.label.toLowerCase().includes(searchStr.toLowerCase())
        )
        .sort((a, b) => a.label.localeCompare(b.label));
    }, [filterValue, options, searchStr]);

    const sortedVisibleOptionsWithoutSelected =
      sortedOptionsWithoutSelected.slice(0, visibleOptionsCount);

    const handleReset = () => {
      setCurrentFilters([]);
      setSearchStr('');
      onReset();
    };

    const handleApply = () => {
      setSearchStr('');
      onApply(currentFilters);
    };

    const scrollBoxId = useId();
    const showPinnedOption = pinnedOption && searchStr.length === 0;

    return (
      <Popover {...restProps} ref={ref} minWidth="space256" padding={0}>
        <Flex
          direction="column"
          padding="space16"
          maxHeight="inherit"
          gap="space16"
        >
          {options.length >= MIN_NUMBER_OF_FILTERS_TO_SHOW_SEARCH && (
            <TextField
              flex="none"
              // @TODO design system should support a “compact” variant
              // without padding
              style={{ paddingLeft: 0 }}
              input={{
                value: searchStr,
                placeholder: searchFieldPlaceholder,
                onChange: e => setSearchStr(e.target.value),
              }}
            />
          )}
          {pinnedOption && searchStr.length === 0 && (
            <PinnedOption
              currentFilters={currentFilters}
              setCurrentFilters={setCurrentFilters}
              id={pinnedOption.id}
              label={pinnedOption.label}
            />
          )}
          <ScrollBox
            id={scrollBoxId}
            scrollDirection="y"
            flex="1"
            maxHeight="space256"
          >
            <Grid alignContent="space-between" height="100%">
              <InfiniteScroll
                hasMore={
                  visibleOptionsCount < sortedOptionsWithoutSelected.length
                }
                loader={null}
                scrollableTarget={scrollBoxId}
                dataLength={visibleOptionsCount}
                next={() => setVisibleOptionsCount(prev => prev + PER_PAGE)}
              >
                <Grid
                  gap="space16"
                  paddingY={showPinnedOption ? 'space4' : 'space16'}
                  paddingLeft="space3"
                >
                  {sortedSelectedOptions.map(option => (
                    <Checkbox
                      key={option.id}
                      isSelected={currentFilters?.includes(option.id) ?? false}
                      onChange={() => {
                        setCurrentFilters(xor(currentFilters, [option.id]));
                      }}
                    >
                      {option.label}
                    </Checkbox>
                  ))}
                  {sortedVisibleOptionsWithoutSelected.map(option => (
                    <Checkbox
                      key={option.id}
                      isSelected={currentFilters?.includes(option.id) ?? false}
                      onChange={() => {
                        setCurrentFilters(xor(currentFilters, [option.id]));
                      }}
                    >
                      {option.label}
                    </Checkbox>
                  ))}
                </Grid>
              </InfiniteScroll>
            </Grid>
          </ScrollBox>
          {filterInfoText && filterInfoText}
          <Grid autoFlow="column" justifyContent="space-between" flex="none">
            <Button onClick={handleReset} size="medium" variant="tertiary">
              {resetFilterButton}
            </Button>
            {isLoading && <Spinner size="space24" />}
            {!isLoading && (
              <Button
                size="medium"
                variant="secondary"
                onClick={currentFilters?.length ? handleApply : handleReset}
              >
                {applyFilterButton}
              </Button>
            )}
          </Grid>
        </Flex>
      </Popover>
    );
  }
);
