import { Grid, Text, TruncatedText } from '@candisio/design-system';
import { Trans } from 'providers/LocaleProvider';
import { percentageFormat } from 'utils/format';

export interface PaymentConditionItemProps {
  id?: string;
  discountOffset?: number;
  discountPercentage?: number;
  dueDateOffset: number;
  conditionName?: string;
}

/** Individual items displayed by PaymentConditionField */
export const PaymentConditionItemCoreDataApi = ({
  discountOffset,
  discountPercentage,
  dueDateOffset,
  conditionName,
}: PaymentConditionItemProps) => {
  return (
    <Grid gap="space8" templateColumns="auto 1fr">
      {discountPercentage ? (
        <Trans
          parent={TruncatedText}
          i18nKey="settings.contacts.details.edit.paymentCondition.coreDataApiOptions.conditionWithDiscount"
          values={{
            discountOffset,
            discountPercentage: percentageFormat(discountPercentage),
            dueDateOffset,
            conditionName,
          }}
        >
          <Text color="gray500" fontSize="basic">
            {conditionName}
          </Text>
          <Text fontSize="basic">
            {{ discountOffset } as any}
            {{ discountPercentage: percentageFormat(discountPercentage) }},
            {{ dueDateOffset }}
          </Text>
        </Trans>
      ) : (
        <Trans
          parent={TruncatedText}
          i18nKey="settings.contacts.details.edit.paymentCondition.coreDataApiOptions.conditionWithoutDiscount"
          values={{ dueDateOffset, conditionName }}
        >
          <Text color="gray500" fontSize="basic">
            {conditionName}
          </Text>
          <Text fontSize="basic">{{ dueDateOffset } as any}</Text>
        </Trans>
      )}
    </Grid>
  );
};
