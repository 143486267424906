import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  MutationNew_RequestDatevAuthTokensArgs,
  useNew_RequestDatevAuthTokensMutation,
} from 'generated-types/graphql.types';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps } from 'react-router-dom';
import { DatevConnectionModal } from './DatevConnectionModal';

type AuthorizedAuthDatevProps = RouteComponentProps<{
  organizationSlug: string;
}>;

export const AuthorizedAuthDatev = ({
  history,

  match: {
    params: { organizationSlug },
  },
}: AuthorizedAuthDatevProps) => {
  const [t] = useTranslation();
  const { error } = useToastMessage();

  const redirectUserBack = useCallback(() => {
    history.push(`/${organizationSlug}/settings/datev`);
  }, [organizationSlug, history]);

  const [requestAccess] = useNew_RequestDatevAuthTokensMutation();

  const requestAccessCallback = useCallback(
    async (variables: MutationNew_RequestDatevAuthTokensArgs) => {
      try {
        await requestAccess({ variables });
      } catch {
        error(t('datev.authError'));
      } finally {
        redirectUserBack();
      }
    },
    [requestAccess, error, t, redirectUserBack]
  );

  useEffect(() => {
    void requestAccessCallback({ authUrl: window.location.href });
  }, [requestAccessCallback]);

  return <DatevConnectionModal isVisible />;
};
