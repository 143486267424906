import {
  HistoryList,
  ImportHistoryItem,
} from 'components/HistoryList/HistoryList';
import { ImportHistoryLayout } from 'components/ImportHistoryLayout/ImportHistoryLayout';
import { CoreDataTypes } from 'generated-types/graphql.types';
import { useOtherIntegration } from 'orgConfig/other';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CoreDataImportHistory } from 'views/CoreDataImportHistory/CoreDataImportHistory';
import { usePaymentConditionsImportHistoryData } from './hooks/usePaymentConditionsImportHistoryData';

export const PaymentConditionsImportHistory = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { shouldUseCoreDataApi } = useOtherIntegration();

  const {
    paymentConditionsHistoryItems,
    paymentConditionsImportUrl,
    paymentConditionsUrl,
    isLoading,
  } = usePaymentConditionsImportHistoryData();

  const title = t('paymentConditions.importHistory.title');

  const backButtonLabel = t('paymentConditions.importHistory.backButton');

  if (shouldUseCoreDataApi) {
    return (
      <CoreDataImportHistory
        title={title}
        coreDataType={CoreDataTypes.PaymentCondition}
        backButtonLink={paymentConditionsUrl}
        localeNamespace={LOCALE_NAME_SPACE.SETTINGS}
        backButtonLabel={backButtonLabel}
      />
    );
  }

  return (
    <ImportHistoryLayout
      title={t('paymentConditions.importHistory.title')}
      backButtonLabel={backButtonLabel}
      backButtonLink={paymentConditionsUrl}
      height="100%"
    >
      <HistoryList
        data={paymentConditionsHistoryItems as ImportHistoryItem[]}
        variant="errorImportHistory"
        emptyState={{
          translation: 'settings:paymentConditions.importHistory.noData',
          url: paymentConditionsImportUrl,
        }}
        isLoading={isLoading}
        searchPlaceholder={t(
          'paymentConditions.importHistory.searchPlaceholder'
        )}
        width="50rem"
      />
    </ImportHistoryLayout>
  );
};
