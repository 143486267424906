import { queryParameter } from 'components/Table/consts';
import { AppRouteParams, Routes } from 'models';
import { generatePath } from 'react-router-dom-v5-compat';

export const generateInboxTableRowPath = ({
  cursor,
  documentId,
  organizationSlug,
  route,
  searchParams,
}: {
  documentId: string;
  cursor: string | null | undefined;
  searchParams: URLSearchParams;
  organizationSlug: string;
  route: Routes;
}) => {
  const pathname = generatePath(
    `/:${AppRouteParams.organizationSlug}${route}/:documentId`,
    { organizationSlug, documentId }
  );

  if (cursor) {
    searchParams.set('cursor', cursor);
  }

  const queryStringFilter = searchParams.get(queryParameter) ?? '';
  if (queryStringFilter) {
    searchParams.set(queryParameter, queryStringFilter);
  }

  return {
    pathname,
    search: searchParams.toString(),
  };
};
