import { Button, Flex, Grid, Text, useId } from '@candisio/design-system';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import {
  Validity,
  validityFieldOptions,
} from '../../CreditCardDrawer/Tabs/Request/utils/validityFieldOptions';
import {
  useCreateCreditCardModalSetState,
  useCreateCreditCardModalState,
} from '../CreateCreditCardModalDataProvider';
import { CreateCreditCardTextField } from '../CreateCreditCardTextField';
import { ReadOnlyFields } from './CardUsageFormContainer';
import { cardUsageFormErrorMessages } from './cardUsageFormErrorMessages';
import {
  CardUsageFormFormValues,
  CardUsageFormOutput,
  CardUsageFormSchemaOptions,
  cardUsageFormSchema,
} from './cardUsageFormSchema';

interface CardUsageFormProps {
  defaultValues: CardUsageFormFormValues;
  readOnlyFields: Partial<Record<ReadOnlyFields, boolean>>;
  suggestedLabel?: string;
  onSubmit: (formValues: CardUsageFormOutput) => void;
}

export const CardUsageForm = ({
  defaultValues,
  readOnlyFields,
  suggestedLabel,
  onSubmit,
}: CardUsageFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { cardType } = useCreateCreditCardModalState();
  const setModalState = useCreateCreditCardModalSetState();
  const formId = useId();

  const isSingleUseCard = cardType === CardType.SingleUse;

  const context: CardUsageFormSchemaOptions = {
    isSingleUseCard,
  };

  const form = useForm<CardUsageFormOutput>({
    context,
    defaultValues,
    mode: 'onTouched',
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
      zodSchema: cardUsageFormSchema,
      errorMessages: cardUsageFormErrorMessages,
    }),
  });

  const validityHint = isSingleUseCard
    ? 'createCard.singleUseValidityHint'
    : 'createCard.validityHint';

  const defaultSelectedKey = isSingleUseCard
    ? Validity.months12
    : Validity.months36;

  const handleValidityChange = (value: Key | null) => {
    const validity = value?.toString();
    if (!validity) {
      return;
    }

    setModalState(prevState => ({
      ...prevState,
      cardDetails: {
        ...prevState.cardDetails,
        validity,
      },
    }));
  };

  return (
    <FormProvider {...form}>
      <Grid
        gap="space16"
        height="100%"
        padding="space24"
        templateRows="1fr auto"
        paddingBottom="0px"
      >
        <Grid
          as="form"
          id={formId}
          gap="space32"
          onSubmit={form.handleSubmit(onSubmit)}
          height="fit-content"
        >
          <Grid alignItems="center" gap="space16">
            <Text fontSize="xxlarge">
              {t(
                isSingleUseCard
                  ? 'ccRequestForm.fields.purpose.label'
                  : 'ccRequestForm.fields.name.label'
              )}
            </Text>
            <Grid templateColumns="1fr 2fr" alignItems="center" gap="space16">
              <CreateCreditCardTextField
                autoFocus
                suggestion={isSingleUseCard ? undefined : suggestedLabel}
                name={isSingleUseCard ? 'purpose' : 'cardLabel'}
                maxLength={isSingleUseCard ? 50 : 40}
                optionalHint={!isSingleUseCard ? 'optional' : undefined}
                label={t(
                  isSingleUseCard
                    ? 'ccRequestForm.fields.purpose.label'
                    : 'ccRequestForm.fields.name.label'
                )}
                placeholder={t(
                  isSingleUseCard
                    ? 'ccRequestForm.fields.purpose.placeholder'
                    : 'ccRequestForm.fields.name.placeholder'
                )}
                onChange={value => {
                  setModalState(prevState => ({
                    ...prevState,
                    cardDetails: {
                      ...prevState.cardDetails,
                      cardLabel: !isSingleUseCard ? value : undefined,
                      purpose: isSingleUseCard ? (value as string) : undefined,
                    },
                  }));
                }}
              />
              <Text color="gray600" fontSize="basic">
                {t('createCard.cardNameHint')}
              </Text>
            </Grid>
          </Grid>

          <Grid gap="space8" alignItems="center">
            <Text fontSize="xxlarge">
              {t('ccRequestForm.fields.validity.label')}
            </Text>
            <Grid templateColumns="1fr 2fr" alignItems="center" gap="space16">
              <HookFormSelectField
                name="validity"
                readOnly={readOnlyFields['validity']}
                defaultSelectedKey={defaultSelectedKey}
                label={t('ccRequestForm.fields.validity.label')}
                items={validityFieldOptions.map(option => ({
                  ...option,
                  children: t(option.children),
                }))}
                onChange={handleValidityChange}
              />
              <Text color="gray600" fontSize="basic">
                {t(validityHint)}
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Flex
          background="gray50"
          justifyContent="space-between"
          alignSelf="end"
          paddingY="space16"
          position="sticky"
          bottom={0}
        >
          <Button
            variant="secondary"
            icon="arrowLeft"
            iconPosition="left"
            onClick={() =>
              setModalState(prevState => ({
                ...prevState,
                formStep: 'CardTypeAndLimits',
              }))
            }
          >
            {t('ccRequestForm.goBackCTA')}
          </Button>
          <Button
            type="submit"
            form={formId}
            icon="arrowRight"
            iconPosition="right"
          >
            {t('ccRequestForm.nextStepCTA')}
          </Button>
        </Flex>
      </Grid>
    </FormProvider>
  );
};
