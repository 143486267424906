import { ReactNode } from 'react';
import { ColumnInstance } from 'react-table';
import { Button } from '../../../Atoms/Button';
import { usePopover } from '../../../Atoms/Popover';
import { FilterOption } from '../types';
import { FilterPopover } from './FilterPopover';

export interface FilterProps<TTableData extends object> {
  column: ColumnInstance<TTableData>;
  options: FilterOption[];
  filterInfoText?: ReactNode;
  pinnedOption?: FilterOption;
  resetFilterButton?: string;
  applyFilterButton?: string;
  searchFieldPlaceholder?: string;
  filterLabel?: string;
  isLoading?: boolean;
  onUpdateIsFilterBeingUsed?: (value: boolean) => void;
}

export const Filter = <TTableData extends object>({
  column: { setFilter, filterValue },
  options,
  filterInfoText,
  pinnedOption,
  applyFilterButton,
  resetFilterButton,
  filterLabel,
  searchFieldPlaceholder,
  isLoading,
  onUpdateIsFilterBeingUsed,
}: FilterProps<TTableData>) => {
  const { isOpen, close, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({
      placement: 'bottom',
      // The buttons (sort, filter) in the header are conditionally rendered only when the user hovers on top of them
      // and the popover would disappear as soon as the user moves the mouse in the filter popover.
      // This will allow us to prevent the behaviour described.
      onOpenChange: isOpen => onUpdateIsFilterBeingUsed?.(isOpen),
    });

  const buttonVariant =
    filterValue && filterValue.length > 0 ? 'primary' : 'secondary';

  return (
    <>
      <Button
        icon="filterOutlined"
        label={filterLabel}
        size="xsmall"
        variant={buttonVariant}
        color="blue"
        {...triggerProps}
        ref={triggerRef}
      />
      {isOpen && (
        <FilterPopover
          {...popoverProps}
          filterValue={filterValue}
          resetFilterButton={resetFilterButton}
          applyFilterButton={applyFilterButton}
          searchFieldPlaceholder={searchFieldPlaceholder}
          onApply={filters => {
            setFilter(filters);
            close();
          }}
          onReset={() => {
            setFilter(undefined);
            close();
          }}
          options={options}
          pinnedOption={pinnedOption}
          ref={popoverRef}
          filterInfoText={filterInfoText}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
