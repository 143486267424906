import { Button, Grid, useTheme } from '@candisio/design-system';
import { ImportHistoryItem } from 'components/HistoryList/HistoryList';
import { motion } from 'framer-motion';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper } from '../ButtonWrapper';
import { FileData } from '../FileData';
import { TruncatedTitle } from '../TruncatedTitle';

interface ImportHistoryProps {
  item: ImportHistoryItem;
  queryString: string;
  isSapVariant?: boolean;
}

const MotionButton = motion(Button);

export const ImportHistory = ({
  item,
  queryString,
}: ImportHistoryProps): ReactElement => {
  const [t] = useTranslation();
  const { colors } = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Grid
      padding="space8 space20"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Grid
        as="p"
        color="gray800"
        fontSize="basic"
        fontWeight="semibold"
        lineHeight="paragraph"
        gap="space4"
      >
        <TruncatedTitle
          dataString={item.file?.name ?? ''}
          queryString={queryString}
          maxCharacterLength={80}
        />
        <FileData item={item} isImportHistory />
      </Grid>

      {isHovered && (
        <ButtonWrapper>
          <MotionButton
            as="a"
            icon="arrowDown"
            iconPosition="left"
            variant="primary"
            href={item.file?.url}
            // TODO: fix this hack that ensures that the button text stays white on hover
            whileHover={{ color: colors.gray0 }}
          >
            {t('importHistoryList.downloadCta')}
          </MotionButton>
        </ButtonWrapper>
      )}
    </Grid>
  );
};
