import {
  Button,
  Grid,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isMac } from 'utils/osDetection';
import {
  COMMENT_CLEAR_BUTTON_TEST_ID,
  COMMENT_SUBMIT_BUTTON_TEST_ID,
} from './constants';
import { CommentFormValues } from './types';

type Props = {
  isSubmitting?: boolean;
};

export const CommentMentionsControls = ({ isSubmitting }: Props) => {
  const { t } = useTranslation();
  const form = useFormContext<CommentFormValues>();
  const hasValue = form.watch('mention').length > 0;
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      isDisabled: !hasValue,
      placement: 'top',
    });

  return (
    <Grid
      autoFlow="column"
      gap="space4"
      position="absolute"
      bottom="space4"
      right="space8"
    >
      {hasValue && (
        <Button
          data-cy={COMMENT_CLEAR_BUTTON_TEST_ID}
          onClick={() => form.reset()}
          icon="close"
          size="small"
          label={t('comment.clear')}
          tabIndex={-1}
          variant="tertiary"
        />
      )}
      <Button
        type="submit"
        data-cy={COMMENT_SUBMIT_BUTTON_TEST_ID}
        icon={isSubmitting ? 'sandclock' : 'send'}
        disabled={isSubmitting || !hasValue}
        size="small"
        variant="tertiary"
        {...triggerProps}
        ref={triggerRef}
      />
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {isSubmitting ? (
            <Text color="gray800" fontSize="basic">
              {t('comment.creation.submittingTitle')}
            </Text>
          ) : (
            <Grid>
              <Text color="gray800" fontSize="basic">
                {t('comment.creation.title')}
              </Text>
              <Text color="gray500" fontSize="small">
                {t(
                  isMac
                    ? 'comment.creation.shortcutInfoMac'
                    : 'comment.creation.shortcutInfo'
                )}
              </Text>
            </Grid>
          )}
        </Tooltip>
      )}
    </Grid>
  );
};
