import {
  Chip,
  Grid,
  Icon,
  TruncatedText,
  useTheme,
} from '@candisio/design-system';
import { AbsenceInfo } from 'components/AvatarWithStatus/AbsenceInfo';
import { AvatarWithStatus } from 'components/AvatarWithStatus/AvatarWithStatus';
import { useHookFormField } from 'components/HookFormFields/useHookFormField';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { useTranslation } from 'react-i18next';
import { HookFormUserFieldItem } from './HookFormUsersField';

export interface UserChipProps {
  readOnly: boolean;
  values: string[];
  value: string;
  onChange: ReturnType<typeof useHookFormField>['inputProps']['onChange'];
  defaultItems: HookFormUserFieldItem[];
  index: number;
}

export const UserChip = ({
  defaultItems,
  onChange,
  readOnly,
  values,
  value,
  index,
}: UserChipProps) => {
  const [t] = useTranslation();

  const { space } = useTheme();

  const userItem = defaultItems.find(({ key }) => key === value);

  if (!userItem) {
    return null;
  }

  return (
    <Chip
      key={value}
      maxWidth={userItem.absence?.substitute ? space.space256 : undefined}
      avatar={
        <AvatarWithStatus
          hasTooltip={
            Boolean(userItem.absence?.substitute) ||
            userItem.absence !== undefined
          }
          img={userItem.avatarUrl}
          name={userItem.children as string}
          size="xsmall"
          status={userItem.absence?.status}
          tooltipContent={
            userItem.absence ? (
              <AbsenceInfo
                fromDate={userItem.absence.fromDate}
                name={userItem.children as string}
                note={userItem.absence.note}
                status={userItem.absence.status}
                toDate={userItem.absence.toDate}
                substitute={userItem.absence.substitute}
              />
            ) : undefined
          }
        />
      }
      isDisabled={readOnly}
      onClickButton={() => {
        const newValue = [
          ...values.slice(0, index),
          ...values.slice(index + 1),
        ];

        onChange(newValue);
      }}
      removeLabel={t('common.remove')}
      size="xsmall"
      variant="lightGray"
      type="removable"
    >
      {userItem.absence?.status === 'AWAY' && userItem.absence?.substitute ? (
        <Grid autoFlow="column" alignItems="center" gap="space4">
          <Icon icon="arrowRight" size="space16" color="gray800" />
          <AvatarWithStatusContainer
            name={userItem.absence.substitute.name}
            size="small"
            img={userItem.absence.substitute.avatarUrl ?? undefined}
            hasTooltip={false}
            userId={userItem.absence.substitute.id}
          />
          <TruncatedText>{userItem.absence.substitute.name}</TruncatedText>
        </Grid>
      ) : (
        userItem.children
      )}
    </Chip>
  );
};
