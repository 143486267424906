import { Grid } from '@candisio/design-system';
import { IntegrationName } from 'generated-types/graphql.types';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { useOtherIntegration } from 'orgConfig/other';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DocumentDirection } from 'views/utils/DocumentDirection';
import {
  useIsPaymentDueDateReadOnly,
  useShowCashDiscountFields,
  useShowCreateTransferField,
  useShowCreditMemoFieldsProcessingForm,
  useShowDueDateField,
  useShowPaymentConditionField,
} from 'views/utils/useShouldShowField';
import { useBookingsFieldArrayContext } from './BookingsFieldArrayContext';
import { ProcessingFormFieldOptions } from './ProcessingForm';
import { ProcessingFormBankInfoFields } from './ProcessingFormBankInfoFields';
import { ProcessingFormCashDiscountFields } from './ProcessingFormCashDiscountFields';
import { ProcessingFormCheckboxField } from './ProcessingFormCheckboxField';
import { ProcessingFormDatePickerField } from './ProcessingFormDatePickerField';
import { ProcessingFormPaymentConditionField } from './ProcessingFormPaymentConditionField';
import { ProcessingFormValues } from './processingFormSchema';
import { useProcessingFormFieldMetadata } from './useProcessingFormFieldMetadata';

export interface ProcessingFormPaymentFieldsProps {
  /** Individual field options */
  fieldOptions?: ProcessingFormFieldOptions;
  /** Display fields as read only */
  readOnly?: boolean;
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean;
}

/**
 * Fields for entering payment details:
 *
 * - IBAN
 * - create transfer
 * - payment condition (number)
 * - due date
 * - cash discount fields:
 *   - discount date
 *   - discount percentage
 *   - discount amount
 *
 *
 * When gross amount amount comes from transaction, only the due date field will
 * be displayed.
 *
 * When direction of currently-selected document type is not incoming, payment
 * condition and cash discount fields are not displayed.
 *
 * Due date field is not displayed if bookings have different due dates.
 *
 */
export const NONE_PAYMENT_CONDITION = 'none';

export const ProcessingFormPaymentFields = ({
  fieldOptions,
  readOnly,
  ...restProps
}: ProcessingFormPaymentFieldsProps) => {
  const [t] = useTranslation();

  const [bookings, grossAmount, type] = useWatch<
    ProcessingFormValues,
    ['bookings', 'grossAmount', 'type']
  >({ name: ['bookings', 'grossAmount', 'type'] });

  const bookingsFieldArray = useBookingsFieldArrayContext();
  const splits = bookingsFieldArray?.fields ?? [];

  const grossAmountMetadata = useProcessingFormFieldMetadata('grossAmount');
  const grossAmountSource = grossAmountMetadata?.source;

  const { shouldUseCoreDataApi } = useOtherIntegration();

  const typeItem = fieldOptions?.type?.items?.find(({ key }) => key === type);
  const direction = typeItem?.direction;

  const hasPaymentConditionItems =
    (fieldOptions?.paymentCondition?.items || []).length > 0;

  const isIncomingInvoice = direction === DocumentDirection.invoices_received;
  const isInvoiceCorrection = grossAmount < 0;

  const allDueDatesAreSame = bookings.every(
    ({ dueDate }) => dueDate === bookings[0].dueDate
  );

  const paymentConditionValue = useWatch<
    ProcessingFormValues,
    'paymentCondition'
  >({
    name: 'paymentCondition',
  });

  const isNonePaymentCondition =
    paymentConditionValue === NONE_PAYMENT_CONDITION || !paymentConditionValue;

  const isDueDateReadOnly = useIsPaymentDueDateReadOnly({
    isFormReadOnly: readOnly,
    isNonePaymentCondition,
  });

  const showCreditMemoFieldsProcessingForm =
    useShowCreditMemoFieldsProcessingForm();

  const showCreateTransferField = useShowCreateTransferField();
  const showPaymentConditionField = useShowPaymentConditionField({
    isInvoiceCorrection,
    isIncomingInvoice,
  });

  const showCashDiscountFields = useShowCashDiscountFields({
    isInvoiceCorrection,
    isIncomingInvoice,
    hasTransaction: grossAmountSource === 'transaction',
    isNonePaymentCondition,
  });

  const showDueDateField = useShowDueDateField({ allDueDatesAreSame });

  const integrationSetting = useIntegrationSettings();

  if (showDueDateField && grossAmountSource === 'transaction') {
    return (
      <ProcessingFormDatePickerField
        key={splits[0].id} // used by React Hook Form to trigger rerenders
        name="bookings.0.dueDate"
        label={t('document.requestApproval.inputs.invoiceDueDate.label')}
        readOnly={isDueDateReadOnly}
        {...restProps}
        showStatusOnReadOnly={isDueDateReadOnly}
      />
    );
  }

  return (
    <Grid gap="space16">
      {showCreditMemoFieldsProcessingForm && (
        <ProcessingFormBankInfoFields
          readOnly={readOnly}
          fieldOptions={fieldOptions}
          {...restProps}
        />
      )}
      {showCreditMemoFieldsProcessingForm && showCreateTransferField && (
        <ProcessingFormCheckboxField
          name="createTransfer"
          label={t('document.requestApproval.inputs.createTransfer.label')}
          infoMessage={
            integrationSetting !== IntegrationName.Sap
              ? t('document.requestApproval.inputs.createTransfer.info')
              : t('document.requestApproval.inputs.createTransfer.sapInfo')
          }
          readOnly={readOnly}
          {...restProps}
        />
      )}
      {shouldUseCoreDataApi && !hasPaymentConditionItems ? null : (
        <>
          <Grid
            columnGap="space8"
            rowGap="space16"
            templateColumns={"repeat(auto-fit, minmax('10rem', 1fr))"}
          >
            {showPaymentConditionField && (
              <ProcessingFormPaymentConditionField
                fieldOptions={fieldOptions}
                readOnly={readOnly}
                {...restProps}
              />
            )}
            {showDueDateField && (
              <ProcessingFormDatePickerField
                key={splits[0].id} // used by React Hook Form to trigger rerenders
                name="bookings.0.dueDate"
                label={t(
                  'document.requestApproval.inputs.invoiceDueDate.label'
                )}
                readOnly={isDueDateReadOnly}
                {...restProps}
                showStatusOnReadOnly={isDueDateReadOnly}
              />
            )}
          </Grid>
          {showPaymentConditionField && showCashDiscountFields && (
            <ProcessingFormCashDiscountFields
              fieldOptions={fieldOptions}
              readOnly={readOnly}
              {...restProps}
            />
          )}
        </>
      )}
    </Grid>
  );
};
