import { Grid } from '@candisio/design-system';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { ComponentProps, useCallback, useState } from 'react';
import { PdfInvoiceSkeleton } from '../PdfInvoiceSkeleton';
import { DocumentWrapper } from './DocumentWrapper';
import { PageWrapper } from './PageWrapper';
import { A4_PAGE_HEIGHT } from './ProtectedPdf';
import { useDocumentPages } from './useDocumentPages';
import { useReactPdfControls } from './useReactPdfControls';
import { PdfStatus, getPageId, options } from './utils';

interface AttachmentProps
  extends Pick<
      ReturnType<typeof useReactPdfControls>,
      'width' | 'scale' | 'rotation' | 'height' | 'fitType'
    >,
    Pick<ReturnType<typeof useDocumentPages>, 'handleChangePageVisibility'> {
  attachment?: PDFDetails;
  pageNumberOffset: number;
  onDocumentPagesLoaded: (documentId: string, pagesCount: number) => void;
  uniqueId: string;
}

export const Attachment = ({
  attachment,
  width,
  scale,
  rotation,
  handleChangePageVisibility,
  pageNumberOffset,
  fitType,
  height,
  onDocumentPagesLoaded,
  uniqueId,
}: AttachmentProps) => {
  const [status, setStatus] = useState<PdfStatus>('idle');
  const [numPages, setNumPages] = useState<number>();

  const handleLoadProgress = useCallback(() => setStatus('loading'), []);
  const handleOnPassword = useCallback(() => {
    setStatus('loaded');
    if (attachment?.id) onDocumentPagesLoaded(attachment.id, 1);
  }, [attachment?.id, onDocumentPagesLoaded]);

  const onDocumentLoadSuccess: NonNullable<
    ComponentProps<typeof DocumentWrapper>['onLoadSuccess']
  > = useCallback(
    doc => {
      setNumPages(doc?.numPages ?? 0);
      if (attachment?.id) onDocumentPagesLoaded(attachment.id, doc.numPages);

      setStatus('loaded');
    },
    [attachment?.id, onDocumentPagesLoaded]
  );

  return (
    <>
      {(status === 'idle' || status === 'loading') && (
        <Grid height={A4_PAGE_HEIGHT}>
          <PdfInvoiceSkeleton />
        </Grid>
      )}
      {status !== 'error' && (
        <Grid visibility={status !== 'loaded' ? 'hidden' : 'visible'}>
          <DocumentWrapper
            file={attachment?.url}
            onLoadSuccess={onDocumentLoadSuccess}
            externalLinkTarget="_blank"
            fileUrl={attachment?.url}
            loading={null}
            onPassword={handleOnPassword}
            onLoadProgress={handleLoadProgress}
            changePageVisibility={handleChangePageVisibility}
            options={options}
            protectedPageId={getPageId(uniqueId, pageNumberOffset + 1)}
            protectedPageNumber={pageNumberOffset + 1}
          >
            <Grid gap="space16" id={attachment?.id}>
              {Array.from(new Array(numPages), (_el, index) => (
                <PageWrapper
                  key={getPageId(uniqueId, index + 1)}
                  pageNumber={index + 1}
                  width={width}
                  scale={scale}
                  rotation={rotation}
                  fitType={fitType}
                  height={height}
                  handleChangePageVisibility={handleChangePageVisibility}
                  pageNumberInTotal={pageNumberOffset + index + 1}
                  uniqueId={uniqueId}
                />
              ))}
            </Grid>
          </DocumentWrapper>
        </Grid>
      )}
    </>
  );
};
