import { Grid } from '@candisio/design-system';
import { Key, ReactNode } from 'react';
import {
  ArrayPath,
  FieldPath,
  FieldValues,
  useFieldArray,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOrdinalRules } from 'utils/useOrdinalRules';
import {
  HookFormUsersField,
  HookFormUsersFieldProps,
} from '../HookFormUsersField/HookFormUsersField';
import {
  HookFormNextApproversField,
  HookFormNextApproversFieldProps,
} from './HookFormNextApproversField';

export interface HookFormApproversFieldArrayProps<
  TFormValues extends FieldValues,
> {
  /* Approvers field options */
  approversFieldOptions?: Omit<HookFormUsersFieldProps<TFormValues>, 'name'>;
  /** Field name */
  name: ArrayPath<TFormValues>;
  /** Whether field is read-only */
  readOnly?: boolean;
  /** Custom render function for individual approval step */
  renderApprovalStep?: (
    props: HookFormUsersFieldProps<TFormValues> & { key: Key }
  ) => ReactNode;
  isLoading?: boolean | undefined;
}

export const HookFormApproversFieldArray = <TFormValues extends FieldValues>({
  name,
  readOnly,
  approversFieldOptions,
  isLoading,
  renderApprovalStep = props => <HookFormUsersField {...props} />,
}: HookFormApproversFieldArrayProps<TFormValues>) => {
  const [t] = useTranslation();
  const ordinalRules = useOrdinalRules();

  const { append, fields, remove } = useFieldArray<TFormValues>({
    name,
  });

  return (
    <Grid gap="space16">
      {fields.map(({ id }, index) => {
        const isLastField = index === fields.length - 1;
        const count = index + 1;

        return renderApprovalStep({
          key: id,
          name: `${name}.${index}.approvers` as FieldPath<TFormValues>,
          emptyListPlaceholder: t(
            'document.requestApproval.inputs.approvalStep.noData'
          ),
          label: t(
            `document.requestApproval.inputs.approvalStep.label_${ordinalRules.select(
              count
            )}`,
            { count }
          ),
          onChange: values => {
            if (isLastField && values.length < 1) {
              remove(fields.length - 1);
            }
          },
          readOnly,
          onRemove: () => remove(index),
          placeholder: t(
            'document.requestApproval.inputs.approvalStep.placeholder'
          ),
          ...approversFieldOptions,
        });
      })}
      {!readOnly && (
        <HookFormNextApproversField
          key={fields.length}
          name={name as FieldPath<TFormValues>}
          emptyListPlaceholder={t(
            'document.requestApproval.inputs.approvalStep.noData'
          )}
          label={t(
            `document.requestApproval.inputs.approvalStep.label_${ordinalRules.select(
              fields.length + 1
            )}`,
            { count: fields.length + 1 }
          )}
          onAppend={
            append as HookFormNextApproversFieldProps<TFormValues>['onAppend']
          }
          placeholder={t(
            'document.requestApproval.inputs.approvalStep.placeholder'
          )}
          {...approversFieldOptions}
          isLoading={isLoading}
          // Register input's ref to focus on error only for the 1st approval step
          forceInputFieldRef={fields.length === 0}
        />
      )}
    </Grid>
  );
};
