import { Link, mergeProps, useModal } from '@candisio/design-system';
import { ReactNode } from 'react';
import { WistiaModal } from './WistiaModal';

export interface WistiaModalLinkProps {
  wistiaId: string;
  isLink?: boolean;
  onClick?: () => void;
  children: ReactNode;
}

export const WistiaModalLink = ({
  children,
  wistiaId,
  isLink,
  ...restProps
}: WistiaModalLinkProps) => {
  const { modalProps, triggerProps } = useModal();

  return (
    <>
      <WistiaModal wistiaId={wistiaId} {...modalProps} />
      <Link
        as={isLink ? 'a' : 'button'}
        icon="playOutline"
        iconPosition="left"
        {...mergeProps(triggerProps, restProps)}
      >
        {children}
      </Link>
    </>
  );
};
