import { Query } from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import debounce from 'lodash/debounce';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mapToDocumentsTableData } from 'views/utils/mapToDocumentsTableData';
import { useIncludePurchaseOrderData } from 'views/utils/useShouldShowField';
import { getGqlSearchFromUrl } from '../../../../components/DocumentsTable/Filters/filters';
import {
  documentTableQuery,
  searchDocumentsTableQuery,
} from '../../Documents/queries';
import { useComputeQueryFilters } from './useComputeQueryFilters';

export interface ArchiveDocumentsDataParams {
  isUsingSearchQuery: boolean;
  previewDocumentId?: string | null;
}

/**
 * @deprecated use useArchiveDocumentsData instead
 */
export const useArchiveDocumentsDataDeprecated = ({
  isUsingSearchQuery,
  previewDocumentId,
}: ArchiveDocumentsDataParams) => {
  const searchQueryFromUrl = getGqlSearchFromUrl(window.location.search);

  const [debounceSearchValue, setDebounceSearchValue] = useState(
    searchQueryFromUrl?.query
  );

  const [t] = useTranslation();

  const gqlQuery = isUsingSearchQuery
    ? searchDocumentsTableQuery
    : documentTableQuery;

  const rootKey = isUsingSearchQuery ? 'searchDocuments' : 'archivedDocuments';

  const includePurchaseOrderData = useIncludePurchaseOrderData();

  const { data, loading, onLoadMore } = usePagination<
    Pick<Query, 'archivedDocuments' | 'searchDocuments'>
  >(gqlQuery, rootKey, {
    variables: {
      ...useComputeQueryFilters(),
      query: debounceSearchValue,
      withPurchaseOrderData: includePurchaseOrderData,
    },
  });

  const documentsList = data && data[rootKey];

  const documentEdges = documentsList?.edges || [];
  const documentsCount = documentsList?.pageInfo?.totalCount;
  const hasMoreData = documentsList?.pageInfo?.hasNextPage ?? false;
  const isTableEmpty = documentEdges.length === 0 && !loading;
  const endCursor = documentsList?.pageInfo?.endCursor;
  const documentsTableData = mapToDocumentsTableData(
    documentEdges,
    t,
    previewDocumentId
  );

  const selectedDocumentsCount =
    data?.archivedDocuments?.pageInfo?.totalCount ??
    data?.searchDocuments.edges?.length ??
    0;

  const handleDebounceSearch = useMemo(() => {
    return debounce(setDebounceSearchValue, DEFAULT_DEBOUNCE_TIME, {
      leading: true,
    });
  }, []);

  return {
    documentsCount,
    documentsTableData,
    endCursor,
    handleDebounceSearch,
    hasMoreData,
    isTableEmpty,
    loading,
    onLoadMore,
    selectedDocumentsCount,
  };
};
