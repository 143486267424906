import { Card, Flex, Icon, IconKey, Text } from '@candisio/design-system';
import { CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { opacBackgroundCandis } from './utils';

export const Overlay = ({
  cardType,
  overlayType,
  isCandisCreditCard,
}: {
  cardType: CardType;
  overlayType: {
    icon: IconKey;
    text: string;
  };
  isCandisCreditCard: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Card
      position="absolute"
      borderRadius="medium"
      height="280px"
      zIndex="10"
      width="180px"
      left="0"
      top="0"
      border="none"
      boxShadow="noShadow"
      color="gray0"
      background={opacBackgroundCandis}
    >
      <Flex
        gap="space8"
        justifyContent="start"
        alignItems="center"
        direction="column"
        height="100%"
      >
        <Icon size="space24" icon={overlayType.icon} color="gray0" />
        <Text
          fontSize="xlarge"
          textAlign="center"
          style={{ letterSpacing: '0.25rem' }}
          lineHeight="paragraph"
        >
          {t(overlayType.text)}
        </Text>
      </Flex>
    </Card>
  );
};
