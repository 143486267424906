import { RejectedIcon } from 'components/DocumentHistory/styles';
import { ReimbursementCaseItemExcludedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ApprovalStatus } from 'views/Inbox/models';
import closeImage from 'components/DocumentHistory/history-components/images/iconApproveNot.svg';
import { Flex, Text } from '@candisio/design-system';

export const ReimbursementCaseItemExcluded = ({
  properties,
  reimbursementItemMetadata,
}: ReimbursementCaseItemExcludedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const text = expenseTitle
    ? t('timeline.itemExcluded', { title: expenseTitle })
    : t('timeline.itemExcludedNoTitle');

  return (
    <Flex direction="column" gap="space4" paddingTop="space2">
      <Flex alignItems="center">
        <RejectedIcon src={closeImage} alt={ApprovalStatus.DECLINED} />
        <Text color="red500" fontSize="basic">
          {t('timeline.notToBeReimbursed')}
        </Text>
      </Flex>
      <Text>{text}</Text>
    </Flex>
  );
};
