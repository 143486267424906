import { FilterOptionsAccessor } from '@candisio/design-system';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { mapToFilterInput } from 'components/DocumentsTable/utils/mapToFilterInput';
import { mapToSortInput } from 'components/DocumentsTable/utils/mapToSortInput';
import {
  DocumentFilterInput,
  DocumentStatus,
  Query,
} from 'generated-types/graphql.types';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SortingRule, Filters } from 'react-table';
import { getApproximateNumberOfRowForTable } from 'views/utils/pagination-helper';
import { mapToArchiveInvoiceDocumentsTableData } from 'views/utils/mapToArchiveInvoiceDocumentsTableData';
import { useArtistSocialInsuranceCodeTranslationsMap } from 'views/utils/useArtistSocialInsuranceCodeTranslationsMap';
import { archiveInvoiceDocumentsTableQuery } from 'views/Archive/Documents/queries';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider/consts';

interface UseDocumentWithoutTransactionDataProps {
  sortBy: SortingRule<DocumentsTableData>[];
  filters: Filters<DocumentsTableData>;
}

const defaultFilters: Pick<DocumentFilterInput, 'isPaid' | 'status'> = {
  isPaid: false,
  status: [
    DocumentStatus.Open,
    DocumentStatus.Approved,
    DocumentStatus.New,
    DocumentStatus.Rejected,
  ],
};

export const useDocumentWithoutTransactionData = ({
  sortBy,
  filters,
}: UseDocumentWithoutTransactionDataProps) => {
  const pageSize = getApproximateNumberOfRowForTable();
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  const {
    loading: loadingDocuments,
    onLoadMore,
    data,
  } = usePagination<Pick<Query, 'archiveInvoiceDocuments'>>(
    archiveInvoiceDocumentsTableQuery,
    'archiveInvoiceDocuments',
    {
      variables: {
        query: '',
        limit: pageSize,
        sort: mapToSortInput(sortBy),
        filters: {
          status: defaultFilters.status,
          ...mapToFilterInput(filters),
          isPaid: defaultFilters.isPaid,
        },
      },
    }
  );

  const documentEdges = data?.archiveInvoiceDocuments?.edges || [];

  const isTableEmpty = documentEdges.length === 0 && !loadingDocuments;

  const isTableFiltered = useMemo(() => {
    return filters.length > 0;
  }, [filters]);

  const artistSocialInsuranceCodeTranslations =
    useArtistSocialInsuranceCodeTranslationsMap();
  const tableData = mapToArchiveInvoiceDocumentsTableData(
    documentEdges,
    artistSocialInsuranceCodeTranslations
  );

  const filterOptions: FilterOptionsAccessor<DocumentsTableData> = useMemo(
    () => ({
      status: {
        data: [
          {
            id: DocumentStatus.New,
            label: t('statuses.new'),
          },
          {
            id: DocumentStatus.Approved,
            label: t('statuses.approved'),
          },
          {
            id: DocumentStatus.Open,
            label: t('statuses.open'),
          },
          {
            id: DocumentStatus.Rejected,
            label: t('statuses.rejected'),
          },
        ],
      },
      invoiceDate: true,
    }),
    [t]
  );

  const hasMore = data?.archiveInvoiceDocuments.pageInfo?.hasNextPage ?? false;

  return {
    tableData,
    loadingDocuments,
    onLoadMore,
    hasMore,
    isTableEmpty,
    isTableFiltered,
    filterOptions,
  };
};
