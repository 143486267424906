import { Box, Flex } from '@candisio/design-system';
import { ReactNode } from 'react';

/** Used to render content of selected Tab in Inbox and Archive views */
export const TabViewLayout = ({
  tabs,
  children,
}: {
  tabs: ReactNode;
  children: ReactNode;
}) => {
  return (
    <Flex
      direction="column"
      background="gray200"
      height="100%"
      overflow="hidden"
    >
      <Box maxWidth="fit-content" paddingBottom="space24">
        {tabs}
      </Box>
      {children}
    </Flex>
  );
};
