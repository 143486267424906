import { Grid } from '@candisio/design-system';
import { useFormContext } from 'react-hook-form';
import { CreditCardOption } from 'views/Settings/Teams/toolkit/hooks/useCreditCardOptions';
import { CreditCardSetupFormValues } from 'views/Settings/Teams/toolkit/utils/creditCardSetupFormSchema';
import { CreditCardsField } from '../../components/CreditCardsField';

interface CreditCardsPanelProps {
  search: string;
  creditCardOptions: CreditCardOption[];
  isLoadingCreditCardOptions: boolean;
  savedOptions: string[];
  onSearch: (value: string) => void;
  onLoadMore: () => void;
}

export const CreditCardsPanel = ({
  creditCardOptions,
  search,
  isLoadingCreditCardOptions,
  savedOptions,
  onLoadMore,
  onSearch,
}: CreditCardsPanelProps) => {
  const { watch } = useFormContext<CreditCardSetupFormValues>();

  const isReadOnly = !(watch('isACardAssignedToTeam') ?? false);

  return (
    <Grid
      height="100%"
      paddingY="space16"
      background="gray100"
      borderBottomRadius="medium"
    >
      <CreditCardsField
        name="cards"
        creditCardOptions={creditCardOptions}
        search={search}
        isLoadingCreditCardOptions={isLoadingCreditCardOptions}
        savedOptions={savedOptions}
        isReadOnly={isReadOnly}
        onSearch={onSearch}
        onEndReached={onLoadMore}
      />
    </Grid>
  );
};
