import { Flex, Spinner } from '@candisio/design-system';

export const SplashScreen = () => {
  return (
    <Flex
      height="100%"
      alignItems="center"
      justifyContent="center"
      gridColumnStart={1}
      gridColumnEnd={3}
    >
      <Spinner data-cy="loading-spinner" size="space64" color="gray400" />
    </Flex>
  );
};
