import {
  Grid,
  Heading,
  useId,
  Text,
  Button,
  Flex,
  Box,
  Link,
  Card,
} from '@candisio/design-system';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { CardLimitRenewFrequency } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { textDescriptionLimitInterval } from '../CreditCardDrawer/Tabs/Request/consts';
import { limitPeriodOptions } from '../CreditCardDrawer/Tabs/Request/utils/limitPeriodOptions';
import { CreateCreditCardAmountField } from './CreateCreditCardAmountField';
import { useCreateCreditCardModalSetState } from './CreateCreditCardModalDataProvider';
import {
  PhysicalCardLimitsFormOutput,
  PhysicalCardLimitsFormSchemaOptions,
  PhysicalCardLimitsFormValues,
  physicalCardLimitsFormSchema,
} from './utils/physicalCardFormSchema';
import { physicalCardLimitsFormErrorMessages } from './utils/physicalCardLimitsFormErrorMessages';

export const limitLabel: Record<CardLimitRenewFrequency, string> = {
  MONTHLY: 'ccRequestForm.fields.limit.label.monthly',
  QUARTERLY: 'ccRequestForm.fields.limit.label.quarterly',
  ANNUAL: 'ccRequestForm.fields.limit.label.annual',
  TOTAL: 'ccRequestForm.fields.limit.label.total',
};

interface PhysicalCardLimitsFormProps {
  isCardManager: boolean;
  defaultValues: PhysicalCardLimitsFormValues;
  hasPhysicalCard?: boolean;
  suggestedAmount?: number;
  hasSelectedUserPhoneNumber: boolean;
  onSubmit: (formValues: PhysicalCardLimitsFormOutput) => void;
}

export const PhysicalCardLimitsForm = ({
  defaultValues,
  isCardManager,
  suggestedAmount,
  hasPhysicalCard,
  hasSelectedUserPhoneNumber,
  onSubmit,
}: PhysicalCardLimitsFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const organization = useFullOrganization();
  const setModalState = useCreateCreditCardModalSetState();
  const context: PhysicalCardLimitsFormSchemaOptions = {
    isCardManager,
  };

  const formId = useId();

  const form = useForm<PhysicalCardLimitsFormOutput>({
    context,
    defaultValues,
    mode: 'onTouched',
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
      zodSchema: physicalCardLimitsFormSchema,
      errorMessages: physicalCardLimitsFormErrorMessages,
    }),
  });

  const limitIntervalFieldValue = form.watch('limitInterval');

  const handleSetTransactionLimit = (newValue: number | null) => {
    if (newValue === null) {
      return;
    }

    form.setValue('transactionLimit', newValue);
    form.clearErrors('transactionLimit');
  };

  return (
    <FormProvider {...form}>
      <Grid gap="space16" alignContent="start" width="616px">
        {!hasSelectedUserPhoneNumber && !hasPhysicalCard ? (
          <Card background="yellowbg">
            <Text>{t('ccRequestForm.limitsForm.missingPhoneNumberHint')}</Text>
          </Card>
        ) : null}
        <Heading as="h2">{t('ccRequestForm.limitsForm.cardLimits')}</Heading>
        {hasPhysicalCard ? (
          <Text color="gray600">
            {t('dashboard.drawer.form.physicalCardHintPanel.description')}
          </Text>
        ) : (
          <Grid
            as="form"
            id={formId}
            onSubmit={form.handleSubmit(onSubmit)}
            gap="space16"
          >
            <Grid gap="space8" templateColumns="repeat(3, 200px)">
              <CreateCreditCardAmountField
                name="limit"
                autoFocus
                label={
                  limitIntervalFieldValue
                    ? t(
                        limitLabel[
                          limitIntervalFieldValue as CardLimitRenewFrequency
                        ]
                      )
                    : t('ccRequestForm.fields.limit.label.fallback')
                }
                placeholder={t('ccRequestForm.fields.limit.placeholder')}
                minimumFractionDigits={0}
                suggestion={suggestedAmount}
                maximumFractionDigits={0}
                minValue={0}
                onChange={handleSetTransactionLimit}
              />
              <CreateCreditCardAmountField
                name="transactionLimit"
                label={t('ccRequestForm.fields.transactionLimit.label')}
                placeholder={t(
                  'ccRequestForm.fields.transactionLimit.placeholder'
                )}
                minimumFractionDigits={0}
                maximumFractionDigits={0}
                suggestion={suggestedAmount}
                minValue={0}
              />
              <HookFormSelectField
                name="limitInterval"
                label={t('ccRequestForm.fields.limitFrequency.label')}
                placeholder={t(
                  'ccRequestForm.fields.limitFrequency.placeholder'
                )}
                defaultSelectedKey={CardLimitRenewFrequency.Monthly}
                items={limitPeriodOptions.map(option => ({
                  ...option,
                  children: t(option.children),
                }))}
              />
            </Grid>
            {!isCardManager ? (
              <HookFormTextField
                name="comment"
                label={t('ccRequestForm.fields.comment.label')}
                placeholder={t('ccRequestForm.fields.comment.placeholder')}
                maxLength={200}
              />
            ) : undefined}

            {limitIntervalFieldValue ? (
              <Text color="gray600">
                {t(
                  textDescriptionLimitInterval[
                    limitIntervalFieldValue as CardLimitRenewFrequency
                  ]
                )}
              </Text>
            ) : null}
          </Grid>
        )}

        {!isCardManager && organization?.name && (
          <Box justifySelf="start">
            <Trans
              t={t}
              parent="span"
              i18nKey="ccRequestForm.usageAgreement.text"
              values={{ orgName: organization?.name }}
            >
              I accept
              <Link
                href={t('ccRequestForm.usageAgreement.url')}
                target="_blank"
                rel="noopener noreferrer"
              >
                the usage agreement
              </Link>
              of Candis
            </Trans>
          </Box>
        )}
      </Grid>

      <Flex
        justifyContent="space-between"
        alignSelf="end"
        position="sticky"
        bottom={0}
        background="gray50"
        paddingY="space16"
        justifySelf={isCardManager ? 'normal' : 'end'}
      >
        {isCardManager && (
          <Button
            variant="secondary"
            icon="arrowLeft"
            iconPosition="left"
            onClick={() =>
              setModalState(prevState => ({
                ...prevState,
                formStep: 'TeamMember',
              }))
            }
          >
            {t('ccRequestForm.goBackCTA')}
          </Button>
        )}
        {Boolean(!hasPhysicalCard) && (
          <Button
            type="submit"
            form={formId}
            icon="arrowRight"
            iconPosition="right"
          >
            {t('ccRequestForm.limitsForm.cta')}
          </Button>
        )}
      </Flex>
    </FormProvider>
  );
};
