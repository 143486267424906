import { Button, useTheme } from '@candisio/design-system';
import { HelpCenterArticleLink } from 'components/HelpCenterArticleLink/HelpCenterArticleLink';
import { FormFileField } from 'components/HookFormFields/HookFormFileField';
import { HookFormRadioGroupField } from 'components/HookFormFields/HookFormRadioGroupField';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../../../../../providers/LocaleProvider';
import { SelectFileFormValues } from '../BookingAccountImport.schema';
import {
  Generation,
  HalfWidth,
  ProgressLabel,
  SelectFileDescriptionLayout,
  SelectFileFormContainer,
  SelectFileInfoLinksWrapper,
} from '../styles';
import { FilterState } from '../types';
import { ParsedBookingAccount } from '../utils/parseBookingAccountCSV';
import { InfoList } from './InfoList';
import { radioFields } from './SelectFileForm.helper';

export interface SelectFileFormProps {
  bookingAccounts: ParsedBookingAccount[];
  errorPreviewDelay: number;
  onUpdateFilter: (filter: FilterState) => void;
  showSummary: boolean;
  errors: string[];
  isSubmitting: boolean;
  control: Control<SelectFileFormValues>;
  disabledButton: boolean;
}

export const SelectFileForm = ({
  bookingAccounts,
  errorPreviewDelay,
  onUpdateFilter,
  showSummary,
  control,
  errors,
  isSubmitting,
  disabledButton,
}: SelectFileFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.BOOKING_ACCOUNTS);
  const { colors } = useTheme();
  const hasError = errors.length > 0;

  const showGeneration = isSubmitting || hasError;

  const [showError, setShowError] = useState(false);
  const progressBarLabelColor = showError ? colors.gray800 : undefined;

  useEffect(() => {
    setTimeout(() => setShowError(hasError), errorPreviewDelay);
  }, [hasError, errorPreviewDelay]);

  return (
    <SelectFileFormContainer>
      {!showGeneration && (
        <SelectFileDescriptionLayout>
          <p>{t('import.fileSelectionStep.description')}</p>
          <SelectFileInfoLinksWrapper>
            <HelpCenterArticleLink
              articleUrl={t(
                'import.fileSelectionStep.importHelpCenterLink.url'
              )}
            >
              {t('import.fileSelectionStep.importHelpCenterLink.text')}
            </HelpCenterArticleLink>
            <HelpCenterArticleLink
              articleUrl={t(
                'import.fileSelectionStep.exportHelpCenterLink.url'
              )}
            >
              {t('import.fileSelectionStep.exportHelpCenterLink.text')}
            </HelpCenterArticleLink>
          </SelectFileInfoLinksWrapper>
        </SelectFileDescriptionLayout>
      )}
      <HalfWidth>
        <FormFileField
          accept="text/csv"
          buttonLabel={t('import.upload.buttonCTA')}
          name="file"
          placeholder={t('import.upload.placeholder')}
          control={control}
        />
        {!showGeneration && (
          <>
            <HookFormRadioGroupField
              orientation="horizontal"
              showGroupLabel
              label={t('import.fileSelectionStep.chooseMaxDigits')}
              name="maxDigitsGeneralLedger"
              options={radioFields.map(radio => ({
                value: radio.value,
                children: t(radio.key),
              }))}
              control={control}
            />

            <div>
              <Button type="submit" disabled={disabledButton}>
                {t('import.fileSelectionStep.uploadButtonCTA')}
              </Button>
            </div>
          </>
        )}
      </HalfWidth>
      {showGeneration && (
        <Generation>
          <ProgressBar static={showError} error={showError}>
            <ProgressLabel labelColor={progressBarLabelColor}>
              {showError
                ? t('import.fileSelectionStep.encounteredErrorInFileReading')
                : t('import.fileSelectionStep.readingFile')}
            </ProgressLabel>
          </ProgressBar>
          {(showError || showSummary) && (
            <HalfWidth>
              <InfoList
                animate
                onUpdateFilter={onUpdateFilter}
                bookingAccounts={bookingAccounts}
                errors={showError ? errors : []}
              />
            </HalfWidth>
          )}
        </Generation>
      )}
    </SelectFileFormContainer>
  );
};
