import { Button, Grid, Icon, Link } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DefaultBookingKey } from './DefaultImport';

export interface DefaultImportFooterProps {
  handleImport: (defaultBookingKeys: DefaultBookingKey[]) => void;
  isImporting: boolean;
  defaultBookingKeys: DefaultBookingKey[];
  hasActiveBookingAccounts: boolean;
  goToBookingAccounts: () => void;
}

export const DefaultImportFooter = ({
  handleImport,
  isImporting,
  defaultBookingKeys,
  hasActiveBookingAccounts,
  goToBookingAccounts,
}: DefaultImportFooterProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TAX_CODES);

  return (
    <Grid background="gray0" padding="space32" borderBottomRadius="medium">
      {hasActiveBookingAccounts ? (
        <Button
          loading={isImporting}
          onClick={() => handleImport(defaultBookingKeys)}
          size="small"
          variant="primary"
          icon="arrowRight"
          iconPosition="right"
          justifySelf="end"
        >
          {t('import.preview.startImport')}
        </Button>
      ) : (
        <Grid
          columnGap="space4"
          color="red500"
          fontSize="small"
          templateColumns="auto 1fr"
          alignItems="center"
          justifyItems="start"
        >
          <Icon icon="warning" size="space24" />
          {t('import.warnings.noBookingAccounts')}
          <Link
            as="button"
            external
            onClick={goToBookingAccounts}
            gridColumn="2"
          >
            {t('import.warnings.goToBookingAccounts')}
          </Link>
        </Grid>
      )}
    </Grid>
  );
};
