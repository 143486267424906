import {
  IntegrationName,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import {
  createDatevSchema,
  datevAccountsPayableNumberSchema,
  datevAdjacentSchema,
  otherAccountsPayableNumberSchema,
} from 'utils/zodFormValidation/Schemas/accountsPayableNumber';
import { nonEmptyString } from 'utils/zodFormValidation/Schemas/nonEmptyString';
import { CheckAccountsNumber } from 'views/Contacts/ContactDetails/ContactForm/accountsNumberSchema';
import { z } from 'zod';

export interface ReimbursementFormSchemaContext {
  checkAccountsPayableNumber?: CheckAccountsNumber;
  integration?: IntegrationName;
  shouldRequireAccountsPayableNumber?: boolean;
  glaLength?: number;
  reimbursementStatus?: ReimbursementCaseStatus;
}

const selectFieldSchema = z.object({
  value: z.string().nullish(),
  inputValue: z.string().nullish(),
});

export const accountsPayableNumberSchema = (
  integration: IntegrationName,
  glaLength?: number,
  reimbursementStatus?: ReimbursementCaseStatus,
  shouldRequireAccountsPayableNumber?: boolean
) => {
  let schema = undefined;

  switch (integration) {
    case IntegrationName.Sap:
      schema = datevAccountsPayableNumberSchema();
      break;

    case IntegrationName.Other:
      schema = otherAccountsPayableNumberSchema(
        shouldRequireAccountsPayableNumber
      );
      break;

    case IntegrationName.DatevAdjacent:
      schema = datevAccountsPayableNumberSchema(datevAdjacentSchema).nullish();

      break;
    case IntegrationName.Datev:
    default:
      schema = datevAccountsPayableNumberSchema(
        createDatevSchema(glaLength)
      ).nullish();
      break;
  }

  if (
    reimbursementStatus === ReimbursementCaseStatus.Check ||
    reimbursementStatus === ReimbursementCaseStatus.Draft
  ) {
    return schema.nullish();
  }

  return schema;
};

export const accountsNumberSchemaWithDuplicateCheck = ({
  integration = IntegrationName.Datev,
  glaLength,
  reimbursementStatus,
  checkAccountsPayableNumber,
  shouldRequireAccountsPayableNumber,
}: ReimbursementFormSchemaContext) => {
  const schema = accountsPayableNumberSchema(
    integration,
    glaLength,
    reimbursementStatus,
    shouldRequireAccountsPayableNumber
  );

  return schema.superRefine(async (value, context) => {
    if (!checkAccountsPayableNumber || !value) {
      return;
    }

    const { isAvailable, contactName } =
      await checkAccountsPayableNumber(value);

    if (!isAvailable) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        params: { contactName },
      });
    }
  });
};

export const reimbursementFormSchema = ({
  reimbursementStatus,
  checkAccountsPayableNumber,
  glaLength,
  integration,
  shouldRequireAccountsPayableNumber,
}: ReimbursementFormSchemaContext) =>
  z.object({
    title: nonEmptyString,
    note: z.string().nullish(),
    iban: nonEmptyString,
    targetMembership: z.array(z.string()).nullish(),
    suggestedCostCenter: selectFieldSchema.nullish(),
    suggestedCostObject: selectFieldSchema.nullish(),
    accountsPayableNumber: accountsNumberSchemaWithDuplicateCheck({
      reimbursementStatus,
      checkAccountsPayableNumber,
      glaLength,
      integration,
      shouldRequireAccountsPayableNumber,
    }),
  });

export type ReimbursementFormValues = z.infer<
  ReturnType<typeof reimbursementFormSchema>
>;
