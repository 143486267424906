import { forwardRef } from 'react';
import { LayoutProps, StandardHTMLAttributes, Theme } from '../../types';
import { Box } from '../Box';

export const LIST_PADDING: keyof Theme['space'] = 'space16';

export interface ListProps
  extends StandardHTMLAttributes<HTMLUListElement>,
    LayoutProps {}

export const List = forwardRef<HTMLUListElement, ListProps>(
  ({ children, ...restProps }, ref) => {
    return (
      <Box
        as="ul"
        css={{ margin: 0, ':focus': { outline: 'none' } }}
        listStyle="none"
        padding={0}
        paddingY={LIST_PADDING}
        {...restProps}
        ref={ref}
      >
        {children}
      </Box>
    );
  }
);
