import { Card, Grid, Text, Icon } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/Reimbursement';
import { CreateExpenseActionMenuButton } from '../CreateExpenseActionMenuButton';
import { ReimbursementItemListLeftSection } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { ReimbursementItemsSummary } from './ReimbursementItemsSummary';

interface ExpenseDetailsCardWithActionProps {
  totalAmount: string;
  expenseCount: number;
  creatingExpense: boolean;
  onGeneralExpenseClick: () => void;
  onHospitalityExpenseClick: () => void;
  isPromotion?: boolean;
  backgroundColor?: 'gray0' | 'gray100';
  reimbursmentItems?: ReimbursementItemListLeftSection[];
}

export const ExpenseDetailsCardWithAction = ({
  totalAmount,
  expenseCount,
  creatingExpense,
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
  isPromotion = false,
  backgroundColor = 'gray0',
  reimbursmentItems = [],
}: ExpenseDetailsCardWithActionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { updateSearchParam } = useMutateSearchParams();

  const count = expenseCount || undefined;

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!count) return;
    e.stopPropagation();
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
  };

  const hasReimbursementItems = !!reimbursmentItems.length;

  return (
    <Grid gap="1px" alignContent="start">
      <Card
        padding="space24"
        borderBottomRadius="none"
        background={backgroundColor}
        hover={count ? { background: 'gray100' } : undefined}
        cursor={count ? 'pointer' : 'default'}
        onClick={handleClick}
      >
        <Grid templateColumns="auto 1fr auto" gap="space24" placeItems="start">
          <Grid
            background="green100"
            padding="space16"
            placeContent="center"
            borderRadius="medium"
          >
            <Icon icon="receipt" size="space20" />
          </Grid>
          <Grid gap="space8" justifySelf="left">
            <Grid
              autoFlow="column"
              width="fit-content"
              gap="space2"
              fontSize="xlarge"
              fontWeight="semibold"
            >
              <Text>
                {t('reimbursementView.middleSection.emptyDetails.title')}
              </Text>
              {count && <Text>({count})</Text>}
            </Grid>
            <Text fontSize="basic">
              {t('reimbursementView.middleSection.emptyDetails.description')}
            </Text>
          </Grid>
          {count && (
            <Text fontSize="basic" fontWeight="semibold">
              {totalAmount}
            </Text>
          )}
        </Grid>
      </Card>

      <Card padding={0} borderTopRadius="none" background={backgroundColor}>
        <Grid>
          {hasReimbursementItems && (
            <ReimbursementItemsSummary reimbursmentItems={reimbursmentItems} />
          )}
          <Grid
            placeItems="center"
            paddingY="space16"
            borderTop={hasReimbursementItems ? '1px solid gray250' : 'none'}
          >
            <CreateExpenseActionMenuButton
              count={count}
              creatingExpense={creatingExpense}
              onGeneralExpenseClick={onGeneralExpenseClick}
              onHospitalityExpenseClick={onHospitalityExpenseClick}
              isPromotion={isPromotion}
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
