import { useApolloClient } from '@apollo/client';
import { InternalRefetchQueriesInclude } from '@apollo/client/core/types';
import {
  Maybe,
  useAccountsPayableNumberSettingsQuery,
  useContactsImportHistoryErrorQuery,
  useCountAllContactsQuery,
  useCountWithoutAccountsPayableNumberContactsQuery,
} from 'generated-types/graphql.types';
import {
  useContactsImportedForCompanyQuery,
  useContactsImportHistoriesWithUnacknowledgedErrorsQuery,
  useNextFreeAccountsPayableNumberQuery,
  useOrganizationQuery,
} from 'generated-types/graphql.types';
import gql from 'graphql-tag';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';
import { isValidId } from 'utils/validateId';
import { contactFragment, getContactQuery } from '../Contacts/queries';

export const nextFreeAccountsPayableNumberQuery = gql`
  query nextFreeAccountsPayableNumber {
    nextFreeAccountsPayableNumber
  }
`;

export const contactsImportedForCompanyQuery = gql`
  query contactsImportedForCompany {
    contactsImportedForCompany
  }
`;

export const contactByNameQuery = gql`
  query contactByName($name: String!) {
    contactByName(name: $name) {
      id
      relationshipType
      name {
        value
      }
    }
  }
`;

export const contactByAccountsNumberQuery = gql`
  query contactByAccountsNumber($accountsNumber: String!) {
    contactByAccountsNumber(accountsNumber: $accountsNumber) {
      ...ContactData
    }
  }
  ${contactFragment}
`;

export const contactByAccountsPayableNumberQuery = gql`
  query contactByAccountsPayableNumber(
    $accountsPayableNumber: AccountsPayableNumber!
  ) {
    contactByAccountsPayableNumber(
      accountsPayableNumber: $accountsPayableNumber
    ) {
      ...ContactData
    }
  }
  ${contactFragment}
`;

export const setNextFreeAccountsPayableNumberForContactMutation = gql`
  mutation setNextFreeAccountsPayableNumberForContact(
    $id: ID!
    $accountsPayableNumber: AccountsPayableNumber
  ) {
    setNextFreeAccountsPayableNumberForContact(
      id: $id
      accountsPayableNumber: $accountsPayableNumber
    ) {
      ...ContactData
    }
  }
  ${contactFragment}
`;

export const countAllContactsQuery = gql`
  query countAllContacts {
    countContacts
  }
`;

export const countExportableContactsQuery = gql`
  query countExportableContacts {
    countExportableContacts
  }
`;

export const countWithoutAccountsPayableNumberContactsQuery = gql`
  query countWithoutAccountsPayableNumberContacts {
    countContacts(filters: { hasAccountsPayableNumber: false })
  }
`;

export const useContactRefetchQueries = ({
  contactId,
}: { contactId?: Maybe<string> } = {}) => {
  const client = useApolloClient();

  const queries: InternalRefetchQueriesInclude = [
    { query: contactsImportedForCompanyQuery },
    { query: getFullOrganizationQuery },
    { query: nextFreeAccountsPayableNumberQuery },
    { query: countAllContactsQuery },
    { query: countWithoutAccountsPayableNumberContactsQuery },
  ];

  if (isValidId(contactId)) {
    queries.push({
      query: getContactQuery,
      variables: { input: { id: contactId } },
    });
  }

  const evictPaginationResults = () => {
    client.cache.evict({ fieldName: 'contactsPagination' });
    client.cache.evict({ fieldName: 'contactByName' });
    client.cache.evict({ fieldName: 'contactByAccountsPayableNumber' });
    client.cache.evict({ fieldName: 'contactByAccountsNumber' });
    client.cache.evict({ fieldName: 'contactIbanFilter' });
    client.cache.evict({ fieldName: 'contactNameFilter' });
    client.cache.evict({ fieldName: 'contactTaxNumberFilter' });
    client.cache.evict({ fieldName: 'contactVatIdFilter' });
    client.cache.evict({ fieldName: 'contactAccountsPayableNumberFilter' });
    client.cache.evict({ fieldName: 'contactAccountsReceivableNumberFilter' });
  };

  return { queries, evictPaginationResults };
};

export const useRefetchContactsHook = () => {
  const contactHistoryErrors =
    useContactsImportHistoriesWithUnacknowledgedErrorsQuery();

  const contactImportError = useContactsImportHistoryErrorQuery();

  const countAllContacts = useCountAllContactsQuery();
  const countWithoutAccountsPayableNumberContacts =
    useCountWithoutAccountsPayableNumberContactsQuery();

  const contactsImported = useContactsImportedForCompanyQuery();
  const nextNumber = useNextFreeAccountsPayableNumberQuery();
  const organization = useOrganizationQuery();

  const apnSettings = useAccountsPayableNumberSettingsQuery();

  const { evictPaginationResults } = useContactRefetchQueries();

  const refetchContactData = async () => {
    await Promise.all([
      contactHistoryErrors.refetch(),
      countAllContacts.refetch(),
      countWithoutAccountsPayableNumberContacts.refetch(),
      contactsImported.refetch(),
      nextNumber.refetch(),
      organization.refetch(),
      contactImportError.refetch(),
      apnSettings.refetch(),
    ]);
  };

  return { refetchContactData, evictPaginationResults };
};
