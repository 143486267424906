import React from 'react';
import { Flex } from '../../Atoms/Flex';
import { LayoutProps, StandardHTMLAttributes } from '../../types';

export interface ChipGroupProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLDivElement> {
  label: string;
}

/**
 * The ChipGroup is used to wrap the Chip components.<br/>
 * [Storybook]{@link (https://candisio.github.io/design-system/?path=/docs/molecules-chipgroup--docs)}<br/>
 *
 * @param {string} label for the name of this chip collection
 */
export const ChipGroup = React.forwardRef<HTMLDivElement, ChipGroupProps>(
  ({ children, label, ...restProps }, ref) => {
    return (
      <Flex
        aria-label={label}
        ref={ref}
        role="listbox"
        gap="space4"
        {...restProps}
      >
        {children}
      </Flex>
    );
  }
);
