import {
  ContactPaymentMedium,
  ContactRelationshipType,
  ContactType,
  CreateContactInput,
  UpdateContactInput,
} from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { isNilOrEmpty } from 'utils/isNilOrEmpty';
import { useDatev } from '../../../orgConfig/datev';
import { ContactFormOutput } from './ContactForm/contactFormSchema';

const getMutateContactInput =
  (bdsConnected: boolean) =>
  (
    {
      contactType,
      createTransfer,
      name,
      relationshipType,
      accountsPayableNumber,
      accountsReceivableNumber,
      companyName,
      iban,
      individualFirstName,
      individualLastName,
      notSpecifiedName,
      teamMember,
      paymentMedium,
      paymentCondition,
      taxNumber,
      vatId,
      email,
      phoneNumber,
      bankAccountNumber,
      bankInfoType,
      swiftCode,
      addressInfoType,
      city,
      country,
      postOfficeBox,
      postalCode,
      street,
      customerNumber,
    }: ContactFormOutput,
    legacyDueDateOffset?: number
  ): UpdateContactInput => {
    const input: UpdateContactInput = {
      createTransfer,

      accountsPayableNumber: !isNilOrEmpty(accountsPayableNumber)
        ? accountsPayableNumber
        : null,
      accountsReceivableNumber: !isNilOrEmpty(accountsReceivableNumber)
        ? accountsReceivableNumber
        : null,
      customerNumber:
        relationshipType === ContactRelationshipType.Supplier ||
        relationshipType === ContactRelationshipType.SupplierCustomer
          ? customerNumber
          : null,
      companyName,
      relationshipType:
        contactType === 'EMPLOYEE'
          ? ContactRelationshipType.Employee
          : undefined,
      contactType:
        contactType === 'EMPLOYEE' ? ContactType.Individual : contactType,
      dueDateOffset:
        // clear legacy due date offset if payment condition is set
        paymentCondition === undefined ? legacyDueDateOffset : null,
      iban: bankInfoType === 'SEPA' ? iban : null,
      bankAccountNumber: bankInfoType === 'NON_SEPA' ? bankAccountNumber : null,
      swiftCode,
      individualFirstName,
      individualLastName,
      name,
      notSpecifiedName,
      paymentMedium,
      paymentConditionId: paymentCondition === 'none' ? null : paymentCondition,
      taxNumber,
      vatId,
      email,
      phoneNumber,
      street: addressInfoType === 'STREET' ? street : null,
      city,
      countryISOCode: country,
      postalCode,
      membershipId: teamMember,
      postOfficeBox:
        addressInfoType === 'POST_OFFICE_BOX' ? postOfficeBox : null,
    };

    if (relationshipType === ContactRelationshipType.Supplier) {
      input.accountsReceivableNumber = null;
    }

    if (relationshipType === ContactRelationshipType.Customer) {
      input.accountsPayableNumber = null;
    }

    input.relationshipType = relationshipType;

    if (bdsConnected) {
      input.createTransfer =
        paymentMedium === ContactPaymentMedium.SepaBulk ||
        paymentMedium === ContactPaymentMedium.SepaSingle;
    }

    return input;
  };

export const useContactMutationInput = () => {
  const { bdsConnected } = useDatev(); // BDS-checked

  const getMutateContactInputMemoized = useMemo(
    () => getMutateContactInput(bdsConnected),
    [bdsConnected]
  );

  return {
    getMutateContactInput: getMutateContactInputMemoized,
  };
};
