import { Modal } from '@candisio/design-system';
import { isNil } from 'lodash';
import {
  useDocumentUploadDispatch,
  useDocumentUploadState,
} from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { DocumentUploadModalContent } from './DocumentUploadModalContent';

export const DocumentUploadModal = () => {
  const [t] = useTranslation();
  const organizationSlug = useOrganizationId();
  const { modalVisibility, isSensitive } = useDocumentUploadState();
  const dispatch = useDocumentUploadDispatch();

  const handleClose = () => {
    isSensitive ? dispatch('hideSensitiveModal') : dispatch('hideModal');
  };

  const isModalOpen = !isNil(organizationSlug) && modalVisibility;

  return (
    <Modal
      title={
        isSensitive
          ? t('documentUploadModal.uploadSensitiveContracts.modalTitle')
          : t('documentUploadModal.title')
      }
      closeLabel={t('common.close')}
      onClose={handleClose}
      // We need to check whether the user has been redirected to an organization slug yet
      // as this component might render before that.
      isOpen={isModalOpen}
      width={'520px'}
      overflow="hidden"
      background="gray0"
      padding="0 space32 space32"
    >
      <DocumentUploadModalContent onModalClose={handleClose} />
    </Modal>
  );
};
