import { useReplaceCardMutation } from 'generated-types/graphql.types';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { ReplaceCardFormOutput } from '../ReplaceCardForm/utils/replaceCardFormSchema';

const defaultValidity = '36';

type ErrorMessage =
  | 'CANNOT_PERFORM_ACTION_BECAUSE_OF_MISMATCHED_REPLACEMENT_REASON'
  | 'CANNOT_PERFORM_ACTION_BECAUSE_OF_MISMATCHED_EXPIRY_DATE'
  | 'USER_NOT_ALLOWED_TO_PERFORM_ACTION'
  | 'INVALID_CARD_STATUS'
  | 'CANNOT_PERFORM_ACTION_BECAUSE_OF_WRONG_CARD_TYPE';

const mappedTranslationsToErrors: Record<ErrorMessage, string> = {
  CANNOT_PERFORM_ACTION_BECAUSE_OF_MISMATCHED_EXPIRY_DATE:
    'creditCardFormDataPreview.replaceCard.errorMessage.notExpiringSoon',
  CANNOT_PERFORM_ACTION_BECAUSE_OF_MISMATCHED_REPLACEMENT_REASON:
    'creditCardFormDataPreview.replaceCard.errorMessage.actionCannotBePerformed',
  CANNOT_PERFORM_ACTION_BECAUSE_OF_WRONG_CARD_TYPE:
    'creditCardFormDataPreview.replaceCard.errorMessage.actionCannotBePerformed',
  INVALID_CARD_STATUS:
    'creditCardFormDataPreview.replaceCard.errorMessage.notReplaceable',
  USER_NOT_ALLOWED_TO_PERFORM_ACTION:
    'creditCardFormDataPreview.replaceCard.errorMessage.noPermissions',
};

interface UseReplaceCardReturn {
  replaceCard: (formValues: ReplaceCardFormOutput) => Promise<{
    status: 'error' | 'success';
    cardId: string | undefined;
    refNumber: string | undefined;
    messages: string[] | undefined;
  }>;
  replacing: boolean;
}

export const useReplaceCard = (): UseReplaceCardReturn => {
  const { creditCardId } = useParams<{ creditCardId: string }>();
  const [replaceCard, { loading }] = useReplaceCardMutation();
  const { refetchQueries } = useCreditCardsRefetchQueries();

  return {
    replaceCard: async ({ replaceReason, validity }: ReplaceCardFormOutput) => {
      const { errors, data } = await replaceCard({
        awaitRefetchQueries: true,
        refetchQueries: [
          ...refetchQueries.cardIssuerCardsCardManager(creditCardId),
          ...refetchQueries.cardIssuerCards(),
        ],
        variables: {
          input: {
            cardId: creditCardId,
            expiryPeriodMonths: Number(validity ?? defaultValidity),
            terminateCardReason: replaceReason,
          },
        },
      });

      if (errors?.length) {
        return {
          status: 'error',
          cardId: undefined,
          refNumber: undefined,
          messages: errors.map(
            // XXX: This is not a good pattern and error message should be properly handled
            error => {
              const errorCode = error.message.split(' - ')[0];

              return mappedTranslationsToErrors[errorCode as ErrorMessage];
            }
          ),
        };
      }

      return {
        status: 'success',
        cardId: data?.replaceCard.id ?? '',
        refNumber: data?.replaceCard.refNum ?? '',
        messages: undefined,
      };
    },
    replacing: loading,
  };
};
