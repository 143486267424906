import { Grid, TruncatedText } from '@candisio/design-system';
import { AvailableBalanceContainer } from 'containers/credit-cards/CreditCardCarousel/components/AvailableBalance/AvailableBalance';
import { CreditCardSensitiveDataContainer } from 'containers/credit-cards/CreditCardCarousel/components/CardColumn/CreditCardSensitiveDataContainer';
import { LimitsContainer } from 'containers/credit-cards/CreditCardCarousel/components/Limits/Limits';
import { motion } from 'framer-motion';
import { CardType } from 'generated-types/graphql.types';
import { variants } from '../utils';
import { VirtualX1CardWasUsedInfo } from './VirtualX1CardWasUsedInfo';

interface CardThumbnailAndLimitProps {
  cardId: string;
  cardType: CardType;
  cardholderName: string;
  token?: string | null;
  uniqueToken?: string | null;
}

export const CardThumbnailAndLimit = ({
  cardId,
  cardType,
  cardholderName,
  token,
  uniqueToken,
}: CardThumbnailAndLimitProps) => {
  return (
    <Grid templateColumns="auto 1fr" gap="space20">
      <CreditCardSensitiveDataContainer
        cardId={cardId}
        keys={{ token, uniqueToken }}
      />

      <Grid gap="space16" templateRows="auto 1fr">
        <TruncatedText
          width="25ch"
          fontSize="large"
          fontWeight="semibold"
          lineHeight="paragraph"
        >
          {cardholderName}
        </TruncatedText>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Grid gap="space8">
            {cardType !== CardType.SingleUse && (
              <AvailableBalanceContainer cardId={cardId} />
            )}
            <LimitsContainer cardId={cardId} />
          </Grid>
        </motion.div>
        <VirtualX1CardWasUsedInfo cardId={cardId} />
      </Grid>
    </Grid>
  );
};
