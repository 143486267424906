import { HistoryList } from 'components/HistoryList/HistoryList';
import { ImportHistoryLayout } from 'components/ImportHistoryLayout/ImportHistoryLayout';
import { CoreDataTypes } from 'generated-types/graphql.types';
import { useOtherIntegration } from 'orgConfig/other';
import { useTranslation } from 'react-i18next';
import { CoreDataImportHistory } from 'views/CoreDataImportHistory/CoreDataImportHistory';
import { useSap } from '../../../../../orgConfig/sap';
import { useCostCenterImportHistoryData } from './hooks/useCostCenterImportHistoryData';

export const CostCenterImportHistory = () => {
  const [t] = useTranslation();
  const { isActiveIntegration: shouldUseSapCostCenters } = useSap();

  const { shouldUseCoreDataApi } = useOtherIntegration();

  const {
    costCenterHistoryItems,
    costCenterImportUrl,
    costCenterUrl,
    isLoading,
  } = useCostCenterImportHistoryData();

  const title = t('settings.costCenter.history.title');
  const backButtonLabel = t('settings.costCenter.history.backToCostCenter');

  if (shouldUseCoreDataApi) {
    return (
      <CoreDataImportHistory
        coreDataType={CoreDataTypes.CostCenter}
        title={title}
        backButtonLabel={backButtonLabel}
        backButtonLink={costCenterUrl}
      />
    );
  }

  return (
    <ImportHistoryLayout
      title={t('settings.costCenter.history.title')}
      backButtonLabel={t('settings.costCenter.history.backToCostCenter')}
      backButtonLink={costCenterUrl}
    >
      <HistoryList
        data={costCenterHistoryItems}
        variant="importHistory"
        emptyState={{
          translation: shouldUseSapCostCenters
            ? 'settings.costCenter.noDataSap'
            : 'settings.costCenter.history.noData',
          url: shouldUseSapCostCenters ? undefined : costCenterImportUrl,
        }}
        isLoading={isLoading}
        searchPlaceholder={t('settings.costCenter.searchPlaceholder')}
      />
    </ImportHistoryLayout>
  );
};
