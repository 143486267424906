import { Button, Icon, Text, useTheme } from '@candisio/design-system';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { RecurringPayment } from 'views/CreditCards/hooks/useRecurringPaymentData';
import { useRemindCardholderEmail } from 'views/CreditCards/hooks/useRemindCardholderEmail';

interface NoTransactionMadeStateProps {
  isHovered: boolean;
  vendorName: string;
  availableBalance: string | null;
  card: RecurringPayment['linkedCard']['card'];
}

export const NoTransactionMadeState = ({
  isHovered,
  card,
  vendorName,
  availableBalance,
}: NoTransactionMadeStateProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { colors } = useTheme();

  const organizationId = useOrganizationId();
  const { id, cardholder, refNum } = card;

  const emailLink = `/${organizationId}${Routes.DASHBOARD}?cardId=${id}`;

  const reminderDetails = {
    emailLink,
    availableBalance,
    vendorName,
    refNum: refNum,
    cardholderName: cardholder?.name,
  };

  const { emailSubject, emailBody } = useRemindCardholderEmail({
    ...reminderDetails,
  });

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  };

  if (isHovered)
    return (
      <Button
        as="a"
        size="small"
        icon="mail"
        iconPosition="left"
        onClick={(e: MouseEvent<HTMLAnchorElement>) => handleClick(e)}
        justifySelf="baseline"
        alignSelf="center"
        variant="secondary"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: colors.gray800 }}
        href={`mailto:${
          cardholder?.email
        }?subject=${emailSubject}&body=${encodeURIComponent(emailBody)}`}
      >
        {t('recurringPaymentList.reminderCTA', {
          cardholderName: cardholder?.name,
        })}
      </Button>
    );

  return (
    <>
      <Icon icon="warning" size="space14" color="yellow500" />
      <Text>{t('recurringPaymentList.CARD_CHECK_NOT_DONE')}</Text>
    </>
  );
};
