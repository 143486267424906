import { PDFDetails } from 'components/DocumentViewer/utils';
import { useGetDocumentFileQuery } from 'generated-types/graphql.types';
import { usePolling } from 'hooks/usePolling';
import { useMemo } from 'react';

const DOCUMENT_FILE_POLLING_INTERVAL = 3000;

interface UseDocumentFileProps {
  documentId: string;
  shouldPoll?: boolean;
}

export const useDocumentFile = ({
  documentId,
  shouldPoll,
}: UseDocumentFileProps) => {
  const {
    data: documentFileQuery,
    loading: isDocumentFileLoading,
    startPolling: startPollingDocumentFile,
    stopPolling: stopPollingDocumentFile,
  } = useGetDocumentFileQuery({
    // Document file URL expires after 12 hours but the user’s session can
    // last much longer. If we use the default `cache-first` fetch policy we
    // might try to download the PDF from an expired URL.
    //
    // We use `cache-and-network` instead so that we can still show the PDF
    // viewer immediately but then rerender if the URL has changed.
    fetchPolicy: 'cache-and-network',
    variables: { id: documentId },
    skip: !documentId,
  });

  usePolling({
    pollInterval: DOCUMENT_FILE_POLLING_INTERVAL,
    startPolling: startPollingDocumentFile,
    stopPolling: stopPollingDocumentFile,
    skip:
      !shouldPoll ||
      Boolean(documentFileQuery?.getDocument?.documentFile?.url) ||
      !documentId,
  });

  const documentData = documentFileQuery?.getDocument;

  const documentFile: PDFDetails = useMemo(
    () => ({
      id: documentData?.documentFile?.id ?? documentId,
      name: documentData?.documentFile?.name ?? '',
      url: documentData?.documentFile?.url ?? '',
      size: documentData?.documentFile?.size ?? 0,
      eInvoice: documentData?.documentFile?.eInvoice ?? undefined,
    }),
    [documentId, documentData]
  );

  const documentAttachments = documentData?.attachments;

  return { documentFile, isDocumentFileLoading, documentAttachments };
};
