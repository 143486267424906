import { Flex, Grid, Skeleton } from '@candisio/design-system';
import { IntercomLink } from 'components/InfoPanel/components/IntercomLink';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider/feature-flag-names';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { BankAccounts } from 'views/Settings/PaymentsSettings/BankAccounts';
import { useOrganizationOwnIbansData } from 'views/Settings/PaymentsSettings/useOrganizationOwnIbansData';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { OrganizationCard } from './components/OrganizationCard';
import { OrganizationLocale } from './components/OrganizationLocale';
import { OrganizationName } from './components/OrganizationName';
import { OrganizationSSOConfiguration } from './components/OrganizationSSOConfiguration';
import { TaxIdField } from './components/TaxIdField';
import { VatIdField } from './components/VatIdField';
import { useOrganizationData } from './hooks/useOrganizationData';
import { OrganizationOwner } from 'views/Settings/Organization/components/OrganizationOwner';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';

export const Organization = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const [ssoConfigFF, implementOwnerRoleFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.ssoConfig,
    FEATURE_FLAGS.implementOwnerRole,
  ]);

  const { hasOrgTaxDetails, isLoading: isOrgDataLoading } =
    useOrganizationData();

  const { isAdmin } = useUserRoles();

  const { showMissingIBANIndicator } = useOrganizationOwnIbansData();

  return (
    <SettingsLayout
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
      title={t('organisation.title')}
    >
      <Grid alignContent="start" gap="space32">
        <OrganizationName />
        <OrganizationLocale />
        {implementOwnerRoleFF && <OrganizationOwner />}
        <OrganizationCard
          title={t('organisation.numbers.title')}
          showIndicator={!hasOrgTaxDetails}
          paragraph={t('organisation.numbers.paragraph')}
          isLoading={isOrgDataLoading}
        >
          {isOrgDataLoading ? (
            <Skeleton height="space48" width="100%" />
          ) : (
            <Flex direction="column" gap="space18">
              <VatIdField />
              <TaxIdField />
            </Flex>
          )}
        </OrganizationCard>
        <BankAccounts
          showPulseIndicator={showMissingIBANIndicator}
          cardDescription={t('settings:organisation.numbers.paragraph')}
        />
        {ssoConfigFF && isAdmin && <OrganizationSSOConfiguration />}
      </Grid>
      <InfoPanel
        title={t('organisation.infoPanel.title')}
        sections={t('organisation.infoPanel.sections', {
          returnObjects: true,
        })}
        intercomLink={
          <IntercomLink
            translation={t('organisation.infoPanel.buttonTrigger.label')}
            id="settings-organisation-create-button"
          />
        }
        loading={isOrgDataLoading}
        skeletonLength={8}
      />
    </SettingsLayout>
  );
};
