import {
  Button,
  Flex,
  Grid,
  ListViewProps,
  MenuButton,
  MenuItem,
} from '@candisio/design-system';
import { GridView } from 'components/GridView/GridView';
import { SearchField } from 'components/SearchField/SearchField';
import { motion } from 'framer-motion';
import { Children, Key, ReactNode } from 'react';
import { useLocale } from 'utils/useLocale';
import { GridListSkeleton } from './GridListSkeleton';

export interface GridListProps {
  /** List Item to be rendered inside the component  */
  children: ListViewProps['children'];
  /** Empty state shown when the list is empty */
  emptyDataState?: ReactNode;
  /** Empty state shown when the list is being searched and there are no results */
  emptySearchState?: ReactNode;
  /** loading boolean when query is loading */
  isLoading?: boolean;
  /** Kebab menu component to show at the end of the header*/
  kebabMenu?: ReactNode;
  /** Text for the button that opens the drawer to create an entity for the list */
  createText: string;
  /** List of extra menuButtons to display to users */
  search: string;
  /** Placeholder for the search input field */
  searchPlaceholder: string;
  filtersAndSorts?: {
    /** List of items to show in the menuButton  */
    options: Iterable<MenuItem>;
    /** Function to select which sort or filter to use */
    onClick: (value: Key[]) => void;
    /** Selected item */
    value: Key[];
    /** Button text that changes depending the selected menuItem */
    text: string;
  }[];
  /** Callback that gets invoked when clicking on button to create an entity */
  onCreate: () => void;
  /** Callback to fetch more data with infinite scroll */
  onEndReached?: (index: number) => void;
  /** Callback that gets invoked when typing in the input field */
  onSearch: (search: string) => void;
  /** Tooltip for the clear search button */
  clearSearchTooltip?: string;
}

const MotionGrid = motion(Grid);

export const GridList = ({
  children,
  emptyDataState,
  emptySearchState,
  isLoading,
  kebabMenu,
  filtersAndSorts: filtersAndSortsProps,
  createText,
  search,
  searchPlaceholder,
  onSearch,
  onCreate,
  onEndReached,
  clearSearchTooltip,
}: GridListProps) => {
  const locale = useLocale();

  const hasData = Children.count(children) > 0;

  const emptyState = search.length > 0 ? emptySearchState : emptyDataState;

  const showLoadingState = isLoading && !hasData;
  const showDataList = hasData;
  const showEmptyState = !isLoading && !hasData;

  const filtersAndSorts = filtersAndSortsProps ?? [];

  return (
    <Grid
      background="gray0"
      borderRadius="medium"
      height="100%"
      overflow="hidden"
      templateRows="auto 1fr"
    >
      <Grid background="gray100" padding="space16">
        <Flex alignItems="center" justifyContent="space-between">
          <SearchField
            placeholder={searchPlaceholder}
            value={search}
            onChange={onSearch}
            clearTooltip={clearSearchTooltip}
            inputSize={locale.startsWith('en') ? 28 : 30}
          />

          <Flex alignItems="center" gap="space8">
            {filtersAndSorts.map((button, index) => (
              <MenuButton
                key={index}
                variant="tertiary"
                size="small"
                items={button.options}
                onChange={value => {
                  if (!value.length) {
                    return;
                  }

                  button.onClick(value);
                }}
                value={button.value as any}
                selectionMode="single"
              >
                {button.text}
              </MenuButton>
            ))}
            <Button onClick={onCreate}>{createText}</Button>
            {kebabMenu}
          </Flex>
        </Flex>
      </Grid>

      <MotionGrid
        alignItems="center"
        background="gray0"
        height="100%"
        paddingY="space16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: 'easeOut', duration: 0.8, delay: 0.25 }}
      >
        {showLoadingState && <GridListSkeleton />}
        {showDataList && (
          <GridView onEndReached={onEndReached}>{children}</GridView>
        )}
        {showEmptyState && emptyState}
      </MotionGrid>
    </Grid>
  );
};
