import { useCostCenterFieldOptions } from 'components/Form/hooks/useCostCenterFieldOptions';
import {
  CostCenterTypes,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { useUsersFieldOptions } from 'hooks/useUsersFieldOptions';
import {
  useShowCostCenterField,
  useShowCostObjectField,
} from 'views/utils/useShouldShowField';
import { ReimbursementFormFieldOptions } from './types';
import { Reimbursement } from './useFormattedReimbursement';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useSap } from 'orgConfig/sap';
import { useOtherIntegration } from 'orgConfig/other';
import { useCurrentUser } from 'providers/CurrentUserProvider';

export const useReimbursementFormFieldOptions = ({
  reimbursement,
}: {
  reimbursement?: Reimbursement;
}): ReimbursementFormFieldOptions => {
  const currentUser = useCurrentUser();
  const employeeContactTypeFF = useCandisFeatureFlags(
    FEATURE_FLAGS.employeeContactType
  );
  const showCostCenterField = useShowCostCenterField();
  const costCenter = useCostCenterFieldOptions({
    skip: !showCostCenterField,
    type: CostCenterTypes.CostCenter,
  });

  const { shouldUseSapContacts } = useSap();

  const { shouldUseCoreDataApi } = useOtherIntegration();

  const showCostObjectField = useShowCostObjectField();

  const costObjects = useCostCenterFieldOptions({
    skip: !showCostObjectField,
    type: CostCenterTypes.CostObject,
  });

  const isOwner = reimbursement?.targetMembership?.id === currentUser?.id;

  const isAddIbanButtonVisible =
    isOwner &&
    (reimbursement?.status === ReimbursementCaseStatus.Draft ||
      reimbursement?.status === ReimbursementCaseStatus.Check);

  const isEditIbanButtonVisible =
    isOwner &&
    (reimbursement?.status === ReimbursementCaseStatus.Draft ||
      reimbursement?.status === ReimbursementCaseStatus.Check) &&
    Boolean(reimbursement.iban);

  const { loading: isLoadingTargetMembers, users: targetMemberOptions } =
    useUsersFieldOptions({
      status: 'ActiveAndPending',
    });

  const fieldOptions: ReimbursementFormFieldOptions = {
    suggestedCostCenter: {
      isVisible:
        showCostCenterField &&
        reimbursement?.status === ReimbursementCaseStatus.Draft,
      options: costCenter?.props,
    },
    targetMembership: {
      isVisible: true,
      options: {
        defaultItems: targetMemberOptions,
        isLoading: isLoadingTargetMembers,
      },
    },
    suggestedCostObject: {
      isVisible:
        showCostObjectField &&
        reimbursement?.status === ReimbursementCaseStatus.Draft,
      options: costObjects?.props,
    },
    accountsPayableNumber: {
      isReadOnly: false,
      isVisible:
        reimbursement?.status !== ReimbursementCaseStatus.Draft &&
        reimbursement?.status !== ReimbursementCaseStatus.Check &&
        employeeContactTypeFF
          ? true
          : false,
    },
    iban: {
      isAddButtonVisible: isAddIbanButtonVisible,
      isEditButtonVisible: isEditIbanButtonVisible,
    },
  };

  return fieldOptions;
};
