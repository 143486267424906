import {
  Button,
  Flex,
  Icon,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { User } from 'generated-types/graphql.types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MobileAppInvitationModal } from './MobileAppInvitationModal';

const hasMobileApp = false; // TODO: Implement logic for last seen once BE is ready

// TODO: Replace with actual last seen days once BE is ready
const lastSeenDays = 7;

interface MobileAppPromotionProps {
  user: Pick<User, 'avatarUrl' | 'email' | 'name' | 'id'>;
}

export const MobileAppPromotion = ({ user }: MobileAppPromotionProps) => {
  const [t] = useTranslation();

  const {
    isOpen: isTooltipOpen,
    tooltipProps,
    tooltipRef,
    triggerProps,
    triggerRef,
  } = useTooltip({
    placement: 'top',
  });

  const [
    isMobileAppInvitationModalVisible,
    setIsMobileAppInvitationModalVisible,
  ] = useState(false);

  // TODO: Implement logic for hasMobileApp once BE is ready

  if (hasMobileApp) {
    return (
      <>
        <Flex padding="space8">
          <Icon
            icon="mobileApprove"
            size="space16"
            {...triggerProps}
            ref={triggerRef}
          />
        </Flex>
        {isTooltipOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {t('settings:teamMembers.inviteTeamMember.lastSeenTooltip', {
              days: lastSeenDays,
            })}
          </Tooltip>
        )}
      </>
    );
  }

  return (
    <>
      <Button
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          setIsMobileAppInvitationModalVisible(true);
        }}
        icon="mobileUpload"
        variant="tertiary"
        size="small"
        color="blue"
      >
        {t('settings:teamMembers.inviteTeamMember.buttonLabel')}
      </Button>
      <MobileAppInvitationModal
        user={user}
        visible={isMobileAppInvitationModalVisible}
        onCancel={() => setIsMobileAppInvitationModalVisible(false)}
      />
    </>
  );
};
