import { Grid } from '@candisio/design-system';
import { ReactNode } from 'react';

export const PromotionCardWrapper = ({
  children,
  dataCy,
}: {
  children: ReactNode;
  dataCy?: string;
}) => (
  <Grid
    style={{ isolation: 'isolate' }}
    autoFlow="column"
    templateColumns="min(45%, 16rem) 1fr"
    height="100%"
    background="gray0"
    boxShadow="elevatedShadow4"
    borderRadius="medium"
    overflow="hidden"
    data-cy={dataCy}
  >
    {children}
  </Grid>
);
