import { Button, Grid, InfoPanel, Paragraph } from '@candisio/design-system';
import { HelpCenterArticleLink } from 'components/HelpCenterArticleLink/HelpCenterArticleLink';
import { FormFileField } from 'components/HookFormFields/HookFormFileField';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { DownloadCSVTemplateLink } from './DownloadCSVTemplateLink';

const MotionGrid = motion(Grid);

export interface ContactImportFormProps {
  onSubmit: () => void;
}

export const ContactImportForm = ({ onSubmit }: ContactImportFormProps) => {
  const [t] = useTranslation();

  return (
    <Grid
      as="form"
      onSubmit={onSubmit}
      gap="space24"
      alignItems="start"
      maxWidth="37rem"
      paddingX="space32"
    >
      <MotionGrid
        gap="space12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <Paragraph>
          {t('contacts:import.fileSelectionStep.description')}
        </Paragraph>
        <InfoPanel variant="warning">
          {t('contacts:import.fileSelectionStep.firstWarning')}
        </InfoPanel>
        <InfoPanel variant="warning">
          {t('contacts:import.fileSelectionStep.secondWarning')}
        </InfoPanel>
        <Grid autoFlow="column" gap="space16" justifyContent="start">
          <HelpCenterArticleLink
            articleUrl={t(
              'contacts:import.fileSelectionStep.importHelpCenterLink.url'
            )}
          >
            {t('contacts:import.fileSelectionStep.importHelpCenterLink.text')}
          </HelpCenterArticleLink>
          <DownloadCSVTemplateLink
            downloadUrl={t(
              'contacts:import.fileSelectionStep.downloadCSVTemplate.url'
            )}
          >
            {t('contacts:import.fileSelectionStep.downloadCSVTemplate.text')}
          </DownloadCSVTemplateLink>
        </Grid>
      </MotionGrid>
      <FormFileField
        accept="text/csv"
        buttonLabel={t('contacts:import.steps.selectFile')}
        label={t('contacts:import.upload.label')}
        name="file"
        placeholder={t('contacts:import.upload.placeholder')}
      />
      <Button type="submit" width="fit-content">
        {t('contacts:import.fileSelectionStep.uploadButtonCTA')}
      </Button>
    </Grid>
  );
};
