import { Grid, useModal } from '@candisio/design-system';
import { useGetSapB1Credentials } from 'hooks/useGetSAPB1Credentials';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Fragment, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { ExportContext } from '../../Context';
import { ExportType } from '../../types';
import { useDatevSettings } from '../hooks/useDatevSettings';
import addisonIcon from '../images/icon-addison.svg';
import iconCandisCsv from '../images/icon-candis-csv.svg';
import iconDatev from '../images/icon-datev-api.svg';
import iconDatevCsvZip from '../images/icon-datev-csv.svg';
import iconDatevDxsoZip from '../images/icon-datev-xml.svg';
import iconSapB1 from '../images/icon-sap-b1.svg';
import iconSapCsv from '../images/icon-sap-csv.svg';
import iconZip from '../images/icon-zip.svg';
import { isDatevExportType } from '../utils';
import { ExportCard } from './ExportCard';
import { SapPromotionModal } from './SapPromotion';

export const SelectExportType = ({
  exportTypeOptions,
}: {
  exportTypeOptions: ExportType[];
}) => {
  const state = useContext(ExportContext);
  const orgId = useOrganizationId();
  const [t] = useTranslation();
  const [tExport] = useTranslation('exports');
  const { data } = useGetSapB1Credentials();
  const isConfigured = Boolean(data?.sapB1Credentials?.companyDbId);

  const history = useHistory();

  const onGoToDatevSettings = useCallback(
    (exportType: ExportType) => {
      const search =
        exportType === ExportType.DATEV_BDS
          ? '?datevConnectionType=DATEV_REWE'
          : '?datevConnectionType=DATEV_DUO';

      history.push(`/${orgId}${Routes.SETTINGS}${Routes.DATEV}${search}`);
    },
    [history, orgId]
  );

  const datevSettings = useDatevSettings();
  const isDatevConnected =
    !!datevSettings?.datevSettings?.client &&
    !!datevSettings?.datevSettings?.hasAuthTokenForExportDocuments;

  const { open, modalProps, isOpen } = useModal();

  const choose = (exportType: ExportType) => {
    if (exportType === ExportType.SAP_B1 && !isConfigured) {
      open();
    } else if (isDatevExportType(exportType) && !isDatevConnected) {
      onGoToDatevSettings(exportType);
    } else {
      state.setExportType(exportType);
    }
  };

  const exportMethods = [
    {
      type: ExportType.DATEV,
      label: 'export.exportForm.cards.datev.title',
      icon: iconDatev,
    },
    {
      type: ExportType.DATEV_BDS,
      label: 'export.exportForm.cards.datev_bds.title',
      icon: iconDatev,
    },
    {
      type: ExportType.DATEV_DXSO_ZIP,
      label: 'export.exportForm.cards.datev_dxso_zip.title',
      icon: iconDatevDxsoZip,
    },
    {
      type: ExportType.DATEV_CSV,
      label: 'export.exportForm.cards.datev_csv.title',
      icon: iconDatevCsvZip,
    },
    {
      type: ExportType.SAP_B1,
      label: 'export.exportForm.cards.sap_b1.title',
      icon: iconSapB1,
      tag: tExport('sapPromotion.betaTag'),
      customElement: isOpen ? <SapPromotionModal {...modalProps} /> : null,
    },
    {
      type: ExportType.SAP_CSV,
      label: 'export.exportForm.cards.sap_csv.title',
      icon: iconSapCsv,
      tag: tExport('sapPromotion.betaTag'),
    },
    {
      type: ExportType.CANDIS_CSV,
      label: 'export.exportForm.cards.candis_csv.title',
      icon: iconCandisCsv,
    },
    {
      type: ExportType.ADDISON_FORMAT,
      label: 'export.exportForm.cards.addison.title',
      icon: addisonIcon,
    },
    {
      type: ExportType.ZIP,
      label: 'export.exportForm.cards.zip.title',
      icon: iconZip,
    },
  ].filter(t => exportTypeOptions.includes(t.type));

  return (
    <Grid gap="space16" autoRows="max-content">
      {exportMethods.map(({ type, label, icon, tag, customElement }, index) => {
        const exportCard = (
          <ExportCard
            key={index}
            title={t(label)}
            icon={icon}
            tag={tag}
            onClick={() => choose(type)}
          />
        );

        const elementToRender = customElement ? (
          // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
          <Fragment>
            {customElement} {exportCard}
          </Fragment>
        ) : (
          exportCard
        );

        return elementToRender;
      })}
    </Grid>
  );
};
