import { Button, Card, Flex, Paragraph } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../../../providers/LocaleProvider';

export const SapFeedbackCard = ({
  setDismissed,
}: {
  setDismissed: (dismissed: boolean) => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);

  const onClick = () => {
    setDismissed(true);
  };

  return (
    <Card
      boxShadow="noShadow"
      width="100%"
      background="gray50"
      padding="0 16px"
      height="60px"
    >
      <Flex justifyContent="space-between" alignItems="center" height="60px">
        <Paragraph fontSize="medium">
          {t('contacts.feedback.content')}
        </Paragraph>
        <Flex>
          <Button
            variant="secondary"
            width="fit-content"
            id="sap-contacts-feedback"
          >
            {t('contacts.feedback.buttonCta')}
          </Button>
          <Button
            icon="close"
            variant="tertiary"
            label={t('contacts.feedback.dismissTooltip')}
            size="small"
            onClick={onClick}
          ></Button>
        </Flex>
      </Flex>
    </Card>
  );
};
