import { Card, Flex, Grid, Text, TruncatedText } from '@candisio/design-system';
import { ForeignCurrencyFees } from 'containers/credit-cards/components/ForeignCurrencyFees';
import {
  Document,
  DocumentCurrency,
  useOrganizationQuery,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { amountFormat } from 'utils/format';
import { LoadingDocumentDetailsPanel } from './LoadingDocumentDetailsPanel';

export const TransactionViewEmptyState = () => {
  const [tTransactions] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Text textAlign="center">
      {tTransactions('transactionAssociation.documentDetailsEmptyInfo')}
    </Text>
  );
};

export const DocumentDetailsPanel = ({
  document,
  emptyState,
  loadingDoc,
}: {
  document?: Document;
  emptyState?: ReactNode;
  loadingDoc: boolean;
}) => {
  const { data } = useOrganizationQuery();

  const { amount, contact, currency, extractedContact, invoiceNumber } =
    document || {};

  const formattedAmount = amountFormat(amount?.value, currency?.value);
  const showEmptyState = !document && emptyState;
  const documentCurrency = document?.currency?.value;
  const hasOrgCreditCards = Boolean(
    data?.organization?.creditCardsDetails?.paymentFrequency
  );

  const shouldShowForeignCurrencyFees =
    documentCurrency &&
    documentCurrency !== DocumentCurrency.Eur &&
    formattedAmount;

  if (showEmptyState) {
    return (
      <Flex
        direction="column"
        justifyContent="center"
        padding="space12"
        background="white"
        borderRadius="medium"
      >
        {showEmptyState}
      </Flex>
    );
  }

  return (
    <Card padding="space12">
      {loadingDoc ? (
        <LoadingDocumentDetailsPanel />
      ) : (
        <Grid gap="space8">
          <Grid>
            <Text>
              {contact?.value?.name?.value ??
                extractedContact?.name?.value ??
                '-'}
            </Text>
            <Grid
              autoFlow="column"
              gap="space8"
              alignItems="center"
              justifyContent="space-between"
              fontSize="basic"
              fontWeight="semibold"
            >
              <TruncatedText>{invoiceNumber?.value ?? '-'}</TruncatedText>

              <Grid alignItems="center" gap="space4" templateColumns="auto 1fr">
                {shouldShowForeignCurrencyFees && (
                  <ForeignCurrencyFees isPromo={!hasOrgCreditCards} />
                )}
                <TruncatedText wordBreak="break-word">
                  {formattedAmount ?? '-'}
                </TruncatedText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};
