import { Box, Flex } from '@candisio/design-system';
import { ReactNode } from 'react';

export const Comment = ({ children }: { children: ReactNode }) => {
  return (
    <Box padding="space4 space12 space4 0">
      <Flex
        background="gray100"
        padding="space8 space12"
        borderRadius="medium"
        border="1px solid gray250"
      >
        {children}
      </Flex>
    </Box>
  );
};
