import {
  Box,
  Flex,
  Grid,
  Separator,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import {
  DocumentCurrency,
  InsightsMonetaryDetail,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { amountFormat } from 'utils/format';
import { useCalculateResponsiveFontAmount } from './useCalculateResponsiveFontAmount';

type CurrencyConversionDetails = {
  rate: number;
  converted: {
    amount: number;
    currency: string;
  };
  base: {
    currency: string;
    amount: number;
  };
};

export const convertToCurrencyConversion = (
  monetaryDetail: InsightsMonetaryDetail
) => {
  return {
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    rate: monetaryDetail.exchangeRate?.rate!,
    converted: {
      amount: monetaryDetail.amount,
      currency: monetaryDetail.currency,
    },
    base: {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      amount: monetaryDetail.exchangeRate?.amountInBaseCurrency!,
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      currency: monetaryDetail.exchangeRate?.baseCurrency!,
    },
  };
};

export const CurrencyConversionRow = ({
  currencyConversionDetails,
}: {
  currencyConversionDetails: CurrencyConversionDetails;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  const base = t('widgets.currencyConversion.base', {
    formattedAmount: amountFormat(
      currencyConversionDetails.base.amount,
      currencyConversionDetails.base.currency
    ),
  });

  const details = t('widgets.currencyConversion.details', {
    convertedAmount: currencyConversionDetails.converted.amount,
    convertedCurrency: currencyConversionDetails.converted.currency,
    baseCurrency: currencyConversionDetails.base.currency,
    rate: currencyConversionDetails.rate,
  });

  return (
    <tr>
      <td>
        <Box color="gray800" textAlign="right">
          {base}
        </Box>
      </td>
      <td>
        <Box color="gray600" paddingLeft="space4">
          {details}
        </Box>
      </td>
    </tr>
  );
};

export interface CurrencyConversionProps {
  formattedBaseAmount: string;
  details: InsightsMonetaryDetail[];
}

export const CurrencyConversion = ({
  formattedBaseAmount,
  details,
}: CurrencyConversionProps) => {
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={1}>
            <Text as="div" textAlign="right" fontWeight="semibold">
              {formattedBaseAmount}
            </Text>
          </th>
        </tr>
      </thead>
      <tbody>
        {details.map((d, i) => {
          return (
            <CurrencyConversionRow
              key={i}
              currencyConversionDetails={convertToCurrencyConversion(d)}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export const FormattedAmountWithCurrencyConversion = ({
  formattedAmount,
  details,
}: {
  formattedAmount: string;
  details: InsightsMonetaryDetail[];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef, close } =
    useTooltip({ placement: 'bottom', delay: 300 });

  const responsiveAmountFontSize = useCalculateResponsiveFontAmount();

  const detailsWithoutBaseCurrency = details.filter(
    d => d.currency !== d.exchangeRate?.baseCurrency
  );

  // We have to show the detail with the base currency, but if there is no document with base currency (EUR), we need to have a fallback
  const detailWithBaseCurrency = details.find(
    d => d.currency === d.exchangeRate?.baseCurrency
  ) || { amount: 0, currency: DocumentCurrency.Eur };

  if (!detailsWithoutBaseCurrency.length) {
    return (
      <Flex
        color="gray800"
        fontSize={responsiveAmountFontSize}
        fontWeight="300"
      >
        {formattedAmount}
      </Flex>
    );
  }

  const formattedBaseAmount = amountFormat(
    detailWithBaseCurrency.amount,
    detailWithBaseCurrency.currency
  );

  return (
    <>
      <Flex
        {...triggerProps}
        ref={triggerRef}
        onMouseLeave={() => close(true)}
        justifyContent="center"
        fontSize={responsiveAmountFontSize}
        fontWeight="regular"
        color="gray800"
      >
        {`~${formattedAmount}`}
      </Flex>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Grid gap="space8" padding="space4 space16">
            {formattedBaseAmount ? (
              <CurrencyConversion
                formattedBaseAmount={formattedBaseAmount}
                details={detailsWithoutBaseCurrency}
              />
            ) : null}
            <Separator />
            <Text color="gray700" fontSize="small" fontWeight="regular">
              {t('widgets.currencyConversion.subDetail')}
            </Text>
          </Grid>
        </Tooltip>
      )}
    </>
  );
};
