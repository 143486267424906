import {
  Flex,
  Item,
  ListView,
  Separator,
  Skeleton,
} from '@candisio/design-system';

export const MissingInvoicesWidgetSkeleton = () => {
  return (
    <ListView>
      {Array.from({ length: 4 }, (_, index) => (
        <Item key={index} textValue={String(index)}>
          <Flex
            paddingY="space20"
            paddingX="space8"
            alignItems="center"
            background="gray50"
            justifyContent="space-between"
          >
            <Flex alignItems="center" gap="space8">
              <Skeleton height="space32" width="space32" borderRadius="full" />
              <Skeleton height="space20" width="space128" />
            </Flex>
            <Skeleton height="space20" width="space32" />
          </Flex>
          <Separator color="gray200" />
        </Item>
      ))}
    </ListView>
  );
};
