import { Button, Flex, Text } from '@candisio/design-system';
import { ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PdfStatus } from './utils';

const hasFeature = (feature: PDF_FEATURE, disabledControls?: PDF_FEATURE[]) => {
  if (!disabledControls) return true;

  return !disabledControls.includes(feature);
};

type PDF_FEATURE =
  | 'previousPage'
  | 'nextPage'
  | 'currentPage'
  | 'zoomIn'
  | 'zoomOut'
  | 'fitPageWidth'
  | 'fitPageHeight'
  | 'rotateLeft'
  | 'downloadFiles';

export interface PdfHeaderProps {
  onPreviousPageClick: () => void;
  onNextPageClick: () => void;
  onZoomInClick: () => void;
  onZoomOutClick: () => void;
  onFitPageWidthClick: () => void;
  onFitPageHeightClick: () => void;
  onHandleRotateLeftClick: () => void;
  downloadSection?: ReactNode;
  attachmentSection?: ReactNode;
  status: PdfStatus;
  currentPage: number;
  totalPages?: number;
  zoomInDisabled: boolean;
  zoomOutDisabled: boolean;
  disabledControls?: PDF_FEATURE[];
  pdfControlsWrapper?: (props: { children: ReactNode }) => JSX.Element;
}

const DefaultControlsWrapper: PdfHeaderProps['pdfControlsWrapper'] = ({
  children,
}) => (
  <Flex justifyContent="end" gap="space4">
    {children}
  </Flex>
);

export const PdfHeader = memo(
  ({
    downloadSection,
    onFitPageHeightClick,
    onFitPageWidthClick,
    onHandleRotateLeftClick,
    onNextPageClick,
    onPreviousPageClick,
    onZoomInClick,
    onZoomOutClick,
    attachmentSection,
    status,
    currentPage,
    totalPages,
    zoomInDisabled,
    zoomOutDisabled,
    disabledControls,
    pdfControlsWrapper: PdfControlsWrapper = DefaultControlsWrapper,
  }: PdfHeaderProps) => {
    const [t] = useTranslation();

    const previousPage = (
      <Button
        data-cy="page-control-prev"
        disabled={status !== 'loaded' || currentPage <= 1}
        icon="arrowUpCircle"
        label={t('document.prevPagePdf')}
        onClick={onPreviousPageClick}
        variant="tertiary"
        size="small"
      />
    );

    const nextPage = (
      <Button
        data-cy="page-control-next"
        disabled={Boolean(
          status !== 'loaded' || (totalPages && currentPage >= totalPages)
        )}
        icon="arrowDownCircle"
        label={t('document.nextPagePdf')}
        onClick={onNextPageClick}
        variant="tertiary"
        size="small"
      />
    );

    const currentPageText = status === 'loaded' && (
      <Text>{`${currentPage}/${totalPages}`}</Text>
    );

    const zoomIn = (
      <Button
        icon="zoomIn"
        label={t('document.zoomIn')}
        onClick={onZoomInClick}
        disabled={zoomInDisabled}
        variant="tertiary"
        size="small"
      />
    );

    const zoomOut = (
      <Button
        icon="zoomOut"
        label={t('document.zoomOut')}
        disabled={zoomOutDisabled}
        onClick={onZoomOutClick}
        variant="tertiary"
        size="small"
      />
    );

    const fitPageWidth = (
      <Button
        icon="expand"
        label={t('document.pageWidth')}
        onClick={onFitPageWidthClick}
        variant="tertiary"
        size="small"
      />
    );

    const fitPageHeight = (
      <Button
        icon="expand"
        label={t('document.pageHeight')}
        onClick={onFitPageHeightClick}
        variant="tertiary"
        size="small"
        style={{
          transform: 'rotate(90deg)',
        }}
      />
    );

    const rotateLeft = (
      <Button
        icon="rotateLeft"
        label={t('document.rotateLeft')}
        onClick={onHandleRotateLeftClick}
        variant="tertiary"
        size="small"
      />
    );

    return (
      <>
        <Flex alignItems="center" gap="space4" justifyContent="start">
          {hasFeature('previousPage', disabledControls) && previousPage}
          {hasFeature('nextPage', disabledControls) && nextPage}
          {hasFeature('currentPage', disabledControls) && currentPageText}
        </Flex>
        <PdfControlsWrapper>
          {hasFeature('zoomIn', disabledControls) && zoomIn}
          {hasFeature('zoomOut', disabledControls) && zoomOut}
          {hasFeature('fitPageWidth', disabledControls) && fitPageWidth}
          {hasFeature('fitPageHeight', disabledControls) && fitPageHeight}
          {hasFeature('rotateLeft', disabledControls) && rotateLeft}
          {hasFeature('downloadFiles', disabledControls) && downloadSection}
          {attachmentSection}
        </PdfControlsWrapper>
      </>
    );
  }
);
