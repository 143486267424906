import {
  ReimbursementItemStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { getDateConverted } from 'hooks/useDateConverter';
import { isNil } from 'lodash';
import { ExpenseSchema } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { GeneralExpense } from 'views/Reimbursement/utils/mapToGeneralExpense';
import { HospitalityExpense } from 'views/Reimbursement/utils/mapToHospitalityExpense';

const toDefaultValues = (
  item: HospitalityExpense | GeneralExpense
): ExpenseSchema => {
  const converter = getDateConverted();

  if (item.type === ReimbursementItemType.HospitalityExpense) {
    return {
      expenseId: item.id,
      reason: item.reason ?? '',
      expenseType: 'hospitality',
      itemStatus: item.status,
      invoiceNumber: item.invoiceNumber ?? '',
      isExpenseExcluded: item.status === ReimbursementItemStatus.Excluded,
      dayOfExpense: item.expenseDate
        ? converter.dateTimeStringToDateString(item.expenseDate)
        : '',
      totalAmount: !isNil(item.totalAmount) ? item.totalAmount : null,
      receiptAmount:
        'receiptAmount' in item && !isNil(item.receiptAmount)
          ? item.receiptAmount
          : null,
      location: 'location' in item && item.location ? item.location : '',
      tipAmount:
        'tipAmount' in item && !isNil(item.tipAmount) ? item.tipAmount : null,
      internalGuests:
        'internalGuests' in item && item.internalGuests
          ? item.internalGuests
          : null,
      externalGuests:
        'externalGuests' in item && item.externalGuests
          ? item.externalGuests
          : null,
      files:
        item.files?.map(file => ({
          id: file.id ?? '',
          name: file.name ?? '',
          url: file.url ?? '',
        })) ?? [],
    };
  }

  return {
    expenseId: item.id,
    reason: item.reason ?? '',
    expenseType: 'general',
    itemStatus: item.status,
    isExpenseExcluded: item.status === ReimbursementItemStatus.Excluded,
    totalAmount: item.totalAmount ?? null,
    invoiceNumber: item.invoiceNumber ?? '',
    expenseDate: item.expenseDate
      ? converter.dateTimeStringToDateString(item.expenseDate)
      : '',
    files: (item.files ?? []).map(file => ({
      id: file.id ?? '',
      name: file.name ?? '',
      url: file.url ?? '',
    })),
  };
};

interface UseExpensesFormDefaultValuesParams {
  expenses: (GeneralExpense | HospitalityExpense)[];
}

export const useExpensesFormDefaultValues = ({
  expenses,
}: UseExpensesFormDefaultValuesParams) => {
  const defaultValues = {
    expenses: expenses.map(toDefaultValues),
  };

  return defaultValues;
};
