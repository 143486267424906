import { Button, Grid, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export interface InactiveStatusBarProps {
  loading: boolean;
  onRestore: () => void;
  disabled?: boolean;
}

export const InactiveStatusBar = ({
  loading,
  onRestore,
  disabled,
}: InactiveStatusBarProps) => {
  const [t] = useTranslation();

  return (
    <Grid borderRadius="medium" padding="space16" background="bluebg">
      <Text fontSize="basic" fontWeight="semibold">
        {t('settings.teamMembers.form.disabledProgress')}
      </Text>
      <Text fontSize="small">
        {t('settings.teamMembers.form.disabledProgressDescription')}
      </Text>
      <Grid
        justifyContent="end"
        paddingTop="space16"
        gap="space8"
        templateColumns="repeat(2, max-content)"
      >
        <Button
          loading={loading}
          disabled={disabled}
          type="button"
          color="blue"
          onClick={onRestore}
        >
          {t('settings.teamMembers.form.actions.activateMembership')}
        </Button>
      </Grid>
    </Grid>
  );
};
