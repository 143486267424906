import {
  Button,
  Link,
  MenuItem,
  Text,
  useModal,
} from '@candisio/design-system';
import { KebabMenu } from 'components/KebabMenu/KebabMenu';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactSupplierFormContainer } from './ContactSupplierFormContainer';

export const ContactSupplierLink = ({
  documentId,
  selectedContactEmail,
}: {
  documentId: string;
  selectedContactEmail?: string;
}) => {
  const [t] = useTranslation();

  const {
    modalProps,
    open: contactSupplierModalOpen,
    close: contactSupplierModalClose,
  } = useModal();

  const contactSupplierLinkText = t('contactSupplierFromCandis.button.label');

  return (
    <>
      <Button
        icon="mail"
        variant="tertiary"
        label={contactSupplierLinkText}
        onPointerDown={contactSupplierModalOpen}
      />
      <ContactSupplierFormContainer
        documentId={documentId}
        selectedContactEmail={selectedContactEmail}
        onCloseModal={contactSupplierModalClose}
        modalProps={modalProps}
      />
    </>
  );
};

export const ContactSupplierMenu = ({
  documentId,
  selectedContactEmail,
}: {
  documentId: string;
  selectedContactEmail?: string;
}) => {
  const [t] = useTranslation();

  const {
    modalProps,
    open: contactSupplierModalOpen,
    close: contactSupplierModalClose,
  } = useModal();

  const contactSupplierLinkText = t('contactSupplierFromCandis.button.label');
  const contactSupplierLabel = t('contactSupplierFromCandis.button.tooltip');

  const renderContactSupplierLink = useCallback(
    () => (
      <Link
        color="gray"
        icon="mail"
        as="button"
        onPointerDown={contactSupplierModalOpen}
      >
        <Text fontSize="small" lineHeight="heading">
          {contactSupplierLinkText}
        </Text>
      </Link>
    ),
    [contactSupplierModalOpen, contactSupplierLinkText]
  );

  const items: MenuItem[] = useMemo(
    () => [
      {
        id: '1',
        label: contactSupplierLabel,
        renderItem: renderContactSupplierLink,
      },
    ],
    [contactSupplierLabel, renderContactSupplierLink]
  );

  return (
    <>
      <KebabMenu
        color="default"
        label={t('documentContextMenu.openMenu')}
        variant="tertiary"
        menuPlacement="right top"
        items={items}
      />
      <ContactSupplierFormContainer
        documentId={documentId}
        selectedContactEmail={selectedContactEmail}
        onCloseModal={contactSupplierModalClose}
        modalProps={modalProps}
      />
    </>
  );
};
